import {Component, OnInit, ViewChild} from '@angular/core';
import {
  DictionaryBaseDto,
  PortalBrokerDashboardDto,
  TaskCriteriaDto,
  TaskDto,
} from '../../../../bonding_shared/model/dtos';
import {TaskService} from '../../../../bonding_shared/services/task.service';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {TaskStatus, TaskType} from '../../../../bonding_shared/model/dictionary-ids';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table/a-table.component';
import {BrokerPortfolioService} from '../../../../portal/services/broker-portfolio.service';
import {GrowlService} from '../../../../bonding_shared/services/growl/growl.service';

@Component({
  selector: 'broker-dashboard',
  templateUrl: './broker-dashboard.component.pug',
})
export class BrokerDashboardComponent extends DetailsView implements OnInit {
  @ViewChild(ATableComponent, {static: true}) alertsTable: ATableComponent<TaskDto>;
  data: PortalBrokerDashboardDto;
  dataProvider: SearchDataProvider<TaskCriteriaDto, TaskDto>;

  constructor(
    private brokerPortfolioService: BrokerPortfolioService,
    private taskService: TaskService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    console.log('constructor');
    this.dataProvider = new SearchDataProvider<TaskCriteriaDto, TaskDto>(taskService);
    this.dataProvider.searchCriteria.criteria = <TaskCriteriaDto>{
      type: <DictionaryBaseDto>{
        id: TaskType.BROKER_ALERT,
      },
      status: <DictionaryBaseDto>{
        id: TaskStatus.OPEN,
      },
    };
  }

  ngOnInit(): void {
    this.brokerPortfolioService.getDashboardStats().subscribe((result) => {
      this.data = result;
    });
  }

  closeAlert(taskId: number) {
    console.log('Close alert id:' + taskId);
    this.taskService.closeTask(taskId).subscribe((result) => {
      const index = this.data.alerts.findIndex((d) => d.id === taskId); // find index in your array
      if (index !== -1) {
        this.data.alerts.splice(index, 1); // remove element from array
      }
    });
  }
}
