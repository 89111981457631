import {Directive, Input, OnInit} from '@angular/core';
import {BusinessPropertiesService} from '../../../bonding_shared/services/business-properties.service';
import {ChartRestLoader} from './chart-rest-loader';
import {SettingsService} from '../../../bonding_shared/services';
import {ApplicationModule} from '../../../bonding_shared/model/dictionary-ids';

@Directive()
export abstract class ChartComponent implements ChartRestLoader, OnInit {
  currency = 'EUR';
  currencyInitialized = false;

  @Input() applicationModuleId = 0;

  protected constructor(public businessProps: BusinessPropertiesService, public settingsService: SettingsService) {}

  ngOnInit() {}

  loadRestData(appModuleId?: number, mapType?: string): void {}

  onLabelShowFunction(countryData: any, currency: string): (event: any, label: any, code: string) => void {
    return null;
  }

  setActiveModule() {
    if (this.applicationModuleId === 0) {
      this.applicationModuleId = this.settingsService.getActiveModuleId();
    }
  }

  setActiveOrDefaultModule() {
    this.setActiveModule();
    if (
      ![
        ApplicationModule.BONDING,
        ApplicationModule.POLICY,
        ApplicationModule.CLAIM,
        ApplicationModule.LIMIT,
        ApplicationModule.SALES,
      ].includes(this.applicationModuleId)
    ) {
      this.applicationModuleId = ApplicationModule.BONDING;
    }
  }
}
