import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {ClientTemplateListComponent} from './client-template-list.component';
import {DocumentModule} from '../../bonding/document/document.module';
import {CompanySharedModule} from '../../bonding/company/components/shared/company-shared.module';
import {ContractSharedModule} from '../../bonding/bonding-contract/contract/components/shared/contract-shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {RepositoryDocumentListModule} from '../../bonding_shared/components/repository-document-list/repository-document-list.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    DocumentModule,
    ContractSharedModule,
    TranslateModule,
    RepositoryDocumentListModule,
  ],
  declarations: [ClientTemplateListComponent],
  providers: [],
})
export class ClientTemplateModule {}
