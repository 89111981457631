import {ChartDataset} from 'chart.js';

export class ChartData {
  maxTimePeriods = 50;
  dates: string[] = [];
  rightAxis = true;
  colors = [];

  buildChartData(): ChartDataset[] {
    return null;
  }

  clear() {}

  mapData(results: Array<any>) {}
}
