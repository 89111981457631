import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {BrokerContractVersionCriteriaDto, BrokerContractVersionSimpleDto} from '../../../../bonding_shared/model';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table';
import {RouterService} from '../../../../bonding_shared/services/router-service';

@Component({
  selector: 'broker-contract-version-list',
  templateUrl: './broker-contract-version-list.component.pug',
})
export class BrokerContractVersionListComponent {
  @ViewChild('brokerContracts', {static: true}) brokerContractTable: ATableComponent<BrokerContractVersionSimpleDto>;
  @Output() selectItem = new EventEmitter<BrokerContractVersionSimpleDto>();

  @Input() set dataProvider(dp: SearchDataProvider<BrokerContractVersionCriteriaDto, BrokerContractVersionSimpleDto>) {
    this.brokerContractTable.dataProvider = dp;
    this.brokerContractTable.search();
  }

  constructor(public router: RouterService) {}

  brokerContractTableSelectItem(item: BrokerContractVersionSimpleDto) {
    this.selectItem.emit(item);
  }
}
