import {Component, OnInit} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {MasterPolicyContractDto} from '../../../../../bonding_shared/model';
import {AppConfigService, MasterPolicyContractService} from '../../../../../bonding_shared/services';

@Component({
  selector: 'policy-years-section',
  templateUrl: './policy-years-section.component.pug',
})
export class PolicyYearsSectionComponent extends Section implements OnInit {
  masterPolicyContract: MasterPolicyContractDto;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected masterPolicyContractService: MasterPolicyContractService
  ) {
    super(policyContractViewService, appService);
  }

  ngOnInit() {
    if (this._policy.policyContract.masterPolicyContract.id) {
      this.masterPolicyContractService
        .getMasterPolicyContract(this._policy.policyContract.masterPolicyContract.id)
        .subscribe((masterPolicy) => (this.masterPolicyContract = masterPolicy));
    }
  }
}
