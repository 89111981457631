import {Component} from '@angular/core';
import {RouterService} from '../../../bonding_shared/services';
import {PortalUserService} from '../../services/portal-user.service';
import {LimitCoverGuiService} from '../../limit/limit-cover-gui.service';

@Component({
  selector: 'limit-cover-active-proposition-alert',
  templateUrl: './limit-cover-active-proposition-alert.component.pug',
  providers: [PortalUserService],
  styles: [
    `
      .bon-btn-danger {
        font-size: 10pt;
        font-weight: initial;
        padding: 2px 15px 0;
        margin-right: 0 !important;
      }
    `,
  ],
})
export class LimitCoverActivePropositionAlertComponent {
  constructor(public coverService: LimitCoverGuiService, private routerService: RouterService) {}

  goToCover(coverId: number) {
    this.routerService.toLimitCoverPlusProposal(coverId);
  }
}
