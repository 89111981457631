/**
 * Service for common logic of contract components.
 *
 */
import {Observable, of as observableOf} from 'rxjs';
import {Injectable} from '@angular/core';
import {
  AppConfigService,
  CompanyService,
  ContractVersionCriteriaDto,
  ContractVersionDto,
  ContractVersionService,
  DictionaryBaseDto,
  SearchDataProvider,
  TemplateService,
  TemplateSimpleDto,
} from '../../../../bonding_shared';
import {BusinessObjectType, DocumentType} from '../../../../bonding_shared/model/dictionary-ids';

@Injectable()
export class ContractGuiService {
  /**
   * Keeps selected criteria for contract search view
   */
  searchDataProvider: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto>;

  constructor(
    private contractVersionService: ContractVersionService,
    private companyService: CompanyService,
    private _templateService: TemplateService,
    private appService: AppConfigService
  ) {
    this.searchDataProvider = new SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto>(
      contractVersionService
    );
  }

  gerContractVersion(contractVersionId: number, contractId: number): Observable<ContractVersionDto> {
    if (contractVersionId && contractVersionId > 0) {
      return this.contractVersionService.getContractVersion(contractVersionId);
    } else if (contractId && contractId > 0) {
      return this.contractVersionService.getLastContractVersion(contractId);
    } else {
      return observableOf(<ContractVersionDto>{});
    }
  }

  createDataProvider(): SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto> {
    return new SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto>(this.contractVersionService);
  }

  getContractTemplates(
    documentType: DocumentType,
    dictionarySelectors: DictionaryBaseDto[]
  ): Observable<TemplateSimpleDto[]> {
    return this._templateService.findByType(documentType, BusinessObjectType.CONTRACT, undefined, dictionarySelectors);
  }
}
