import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {CustomValidators} from '../../validators';
import {ValueWithMultiplier} from './shared/value-with-multiplier';

@Directive({
  selector: '[lessOrEqualThanValue]',
  providers: [{provide: NG_VALIDATORS, useExisting: LessOrEqualThanValueDirective, multi: true}],
})
export class LessOrEqualThanValueDirective implements Validator {
  @Input('lessOrEqualThanValue') lessOrEqualThanValue: number | ValueWithMultiplier;

  validate(control: AbstractControl): ValidationErrors | null {
    const f =
      this.lessOrEqualThanValue instanceof ValueWithMultiplier
        ? CustomValidators.lessOrEqualThanValue(
            this.lessOrEqualThanValue.value,
            this.lessOrEqualThanValue.baseMultiplier
          )
        : CustomValidators.lessOrEqualThanValue(this.lessOrEqualThanValue);
    return f(control);
  }
}
