import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {InvoiceItemCriteriaDto, SearchCriteria} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class InvoiceItemMassService extends AbstractService {
  protected url = this.urlPrefix + 'invoiceItemMass';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  writeAllOff(invoiceItemCriteria: SearchCriteria<InvoiceItemCriteriaDto>): Observable<number> {
    console.log('invoiceItemMass/writeAllOff: http = ', this.http);
    return this.post1<SearchCriteria<InvoiceItemCriteriaDto>, number>(invoiceItemCriteria, this.url + '/writeAllOff');
  }
}
