import {Component, ViewChild} from '@angular/core';
import {ListEmitters} from '../../bonding_shared/components/details-view/list-emitters';
import {
  PolicyContractVersionCriteriaDto,
  ProductLimitListVersionCriteriaDto,
  ProductLimitListVersionSimpleDto,
} from '../../bonding_shared/model';
import {SearchViewComponent} from '../../bonding_shared/components/search';
import {PolicyLimitListListComponent} from '../../bonding/policy-limit-list/components/policylimitlist-list.component';
import {PolicyLimitListGuiService} from '../../bonding/policy-limit-list/services/policylimitlist-gui.service';
import {LoggedUserService, PolicyLimitListService, RouterService} from '../../bonding_shared/services';

@Component({
  selector: 'client-policylimitlist-search',
  templateUrl: './client-policylimitlist-search.component.pug',
})
export class ClientPolicyLimitListSearchComponent extends ListEmitters {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    ProductLimitListVersionCriteriaDto,
    ProductLimitListVersionSimpleDto
  >;
  @ViewChild(PolicyLimitListListComponent, {static: true}) policyLimitListList: PolicyLimitListListComponent;
  policySearchCriteria: PolicyContractVersionCriteriaDto = <PolicyContractVersionCriteriaDto>{};

  constructor(
    public guiService: PolicyLimitListGuiService,
    private policyLimitListService: PolicyLimitListService,
    public router: RouterService,
    private loggedUserService: LoggedUserService
  ) {
    super();
  }

  onChangePolicy(masterPolicyContractId: string) {
    this.dataProvider.searchCriteria.criteria.limitList.masterPolicyContract.id = +masterPolicyContractId;
  }

  get dataProvider() {
    return this.guiService.searchCriteria.dataProvider;
  }

  get criteria() {
    return this.guiService.searchCriteria.dataProvider.searchCriteria.criteria;
  }

  get portal() {
    return this.loggedUserService.portal;
  }
}
