import {StringUtils} from '../utils/string-utils';
import {Pipe, PipeTransform} from '@angular/core';
import {ContactPersonDto} from '../model/dtos';

@Pipe({
  name: 'contactPersonDisplayName',
})
export class ContactPersonDisplayNamePipe implements PipeTransform {
  transform(cp: ContactPersonDto) {
    return StringUtils.displayName(cp);
  }
}
