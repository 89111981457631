import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {RouterService} from '../../../bonding_shared/services/router-service';
import {LoggedUserService} from '../../../bonding_shared/services/logged-user.service';

@Component({
  selector: 'portal-top-nav-info',
  templateUrl: './portal-top-nav-info.component.pug',
})
export class PortalTopNavInfoComponent {
  public pages: {[bu: string]: {path: string; label: string}[]} = {
    de: [
      {path: 'Datenschutzerklarung', label: 'DATENSCHUTZERKLÄRUNG'},
      {path: 'Impressum', label: 'IMPRESSUM'},
      {path: 'Kontakt', label: 'KONTAKT'},
      {path: 'Support', label: 'SUPPORT'},
    ],
    it: [
      {path: 'Contatti', label: 'CONTATTI'},
      {path: 'Supporto', label: 'SUPPORTO'},
      {path: 'Termini e Condizioni', label: 'TERMINI E CONDIZIONI'},
      {path: 'Reclami', label: 'RECLAMI'},
    ],
    fr_BE: [
      {path: 'Protection des donnees', label: 'PROTECTION DES DONNÉES'},
      {path: 'Conditions d’utilisation', label: 'CONDITIONS D’UTILISATION'},
      {path: 'Kontakt', label: 'CONTACT'},
      {path: 'Support', label: 'AIDE'},
    ],
    nl_BE: [
      {path: 'Datenschutzerklarung', label: 'PRIVACYVERKLARING'},
      {path: 'Impressum', label: 'GEBRUIKSVOORWAARDEN'},
      {path: 'Kontakt', label: 'CONTACT'},
      {path: 'Support', label: 'HULP'},
    ],
    fr: [
      {path: 'Protection des donnees', label: 'PROTECTION DES DONNÉES'},
      {path: 'Conditions d’utilisation', label: 'CONDITIONS D’UTILISATION'},
      {path: 'Kontakt', label: 'CONTACT'},
      {path: 'Support', label: 'AIDE'},
    ],
    nl: [
      {path: 'Datenschutzerklarung', label: 'PRIVACYVERKLARING'},
      {path: 'Impressum', label: 'GEBRUIKSVOORWAARDEN'},
      {path: 'Kontakt', label: 'CONTACT'},
      {path: 'Support', label: 'HULP'},
    ],
  };

  public buCountryCode: string;

  constructor(
    private router: Router,
    private routerService: RouterService,
    private loggedUserService: LoggedUserService
  ) {
    this.buCountryCode = this.loggedUserService.getLoggedUserData().businessUnit.country.code.toLocaleLowerCase();
  }
}
