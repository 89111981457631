import {Component, Input} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {DictionaryBaseDto, LocalDateRange, TemporaryLimitDto} from '../../../bonding_shared/model';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';

@Component({
  selector: 'temporary-limit',
  templateUrl: './temporary-limit.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class TemporaryLimitComponent {
  @Input() readOnly = true;
  @Input() showErrors = false;
  @Input() currency: DictionaryBaseDto;
  @Input() temporaryLimit: TemporaryLimitDto;
  @Input() expandable: boolean;

  limitRequestValidity(): LocalDateRange {
    return BusinessUtils.validity(this.temporaryLimit.validFrom, this.temporaryLimit.validTo);
  }
}
