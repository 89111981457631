import {Language, PolicyInquiryStatementType} from '../../../bonding_shared/model/dictionary-ids';
import {DictionaryBaseDto, PolicyInquiryStatementDto, PolicyInquiryVersionDto} from '../../../bonding_shared/model';
import {Component, Input} from '@angular/core';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {VisibilityService} from '../services/visibility.service';
import {GrowlService, InquiryService, LoggedUserService, StatementsVisibility} from '../../../bonding_shared/services';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'inquiry-statements',
  templateUrl: './inquiry-statements.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class InquiryStatementsComponent extends DetailsView {
  private _inquiry: PolicyInquiryVersionDto = null;
  private _presentationMode: boolean | null = null;
  statements: {[key: string]: boolean | null};
  lockedAgreements = [];
  visible: any = {};
  @Input() showErrors: boolean;
  @Input() lockRequiredAgreements = false;
  readonly PolicyInquiryStatementType = PolicyInquiryStatementType;
  private readonly defaultStatementsVisibility: StatementsVisibility = {
    CONSENT_TO_CONTACT_THE_CONTRACTOR_USING_THE_CUSTOMERS_NAME: null,
    INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA: null,
  };
  statementsVisibility: StatementsVisibility = this.defaultStatementsVisibility;

  constructor(
    private loggedUserService: LoggedUserService,
    protected growlService: GrowlService,
    private visibilityService: VisibilityService,
    private inquiryService: InquiryService
  ) {
    super(growlService);
  }

  @Input()
  set inquiry(i: PolicyInquiryVersionDto) {
    this._inquiry = i;
    this.visible = this.visibilityService.getVisibility(this.getInquiryType());
    this.statements = {
      dataConsistentWithFacts: this.getStatement(PolicyInquiryStatementType.DATA_CONSISTENT_WITH_FACTS),
      documentaryConditions: this.getStatement(PolicyInquiryStatementType.FAMILIARIZATION_WITH_DOCUMENTARY_CONDITIONS),
      conditionsOfPolishness: this.getStatement(
        PolicyInquiryStatementType.GETTING_TO_KNOW_THE_CONDITIONS_OF_POLISHNESS
      ),
      consentToContact: this.getStatement(
        PolicyInquiryStatementType.CONSENT_TO_CONTACT_THE_CONTRACTOR_USING_THE_CUSTOMERS_NAME
      ),
      processingPersonalData: this.getStatement(
        PolicyInquiryStatementType.INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA
      ),
      sharingInformation: this.getStatement(
        PolicyInquiryStatementType.CLAUSE_OF_CONSENT_FOR_SHARING_INFORMATION_COVERED_BY_INSURANCE_SECRECY
      ),
      russianEmbargo: this.getStatement(PolicyInquiryStatementType.RUSSIAN_EMBARGO),
      marketingConsent: this.getStatement(PolicyInquiryStatementType.MARKETING_CONSENT),
    };

    this.initConsentVisibility();
  }

  @Input()
  set presentationMode(p: boolean) {
    this._presentationMode = p;
    this.initConsentVisibility();
  }

  private initConsentVisibility() {
    if (this._presentationMode === null || !this._inquiry) {
      this.statementsVisibility = this.defaultStatementsVisibility;
      return;
    }

    if (this.presentationMode) {
      this.statementsVisibility = {
        CONSENT_TO_CONTACT_THE_CONTRACTOR_USING_THE_CUSTOMERS_NAME: this.statements.consentToContact !== null,
        INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA: false,
      };
    } else {
      const clientId = this._inquiry.client && this._inquiry.client.company && this._inquiry.client.company.id;
      this.inquiryService.getConsentVisibility(clientId, this._inquiry.id).subscribe({
        next: (stv) => {
          this.statementsVisibility = stv;
        },
        error: () =>
          (this.statementsVisibility = {
            CONSENT_TO_CONTACT_THE_CONTRACTOR_USING_THE_CUSTOMERS_NAME: true,
            INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA: true,
          }),
      });
    }
  }

  get inquiry() {
    return this._inquiry;
  }

  get presentationMode() {
    return !!this._presentationMode;
  }

  agreementEditable(agreementId: number): boolean {
    if (this.lockRequiredAgreements) {
      return !this.lockedAgreements.includes(agreementId);
    }
    return true;
  }

  onStatementChange() {
    if (!this._inquiry.statements) {
      this._inquiry.statements = [];
    }

    this.rewriteStatement(
      PolicyInquiryStatementType.DATA_CONSISTENT_WITH_FACTS,
      this.statements.dataConsistentWithFacts
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.FAMILIARIZATION_WITH_DOCUMENTARY_CONDITIONS,
      this.statements.documentaryConditions
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.GETTING_TO_KNOW_THE_CONDITIONS_OF_POLISHNESS,
      this.statements.conditionsOfPolishness
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.CONSENT_TO_CONTACT_THE_CONTRACTOR_USING_THE_CUSTOMERS_NAME,
      this.statements.consentToContact
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA,
      this.statements.processingPersonalData
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.CLAUSE_OF_CONSENT_FOR_SHARING_INFORMATION_COVERED_BY_INSURANCE_SECRECY,
      this.statements.sharingInformation
    );

    this.rewriteStatement(PolicyInquiryStatementType.RUSSIAN_EMBARGO, this.statements.russianEmbargo);
    this.rewriteStatement(PolicyInquiryStatementType.MARKETING_CONSENT, this.statements.marketingConsent);
  }

  private rewriteStatement(typeId: number, value: boolean) {
    const statements = this._inquiry.statements;
    const index = statements.findIndex((st) => st.statementType.id === typeId);
    if (index === -1) {
      const statement: PolicyInquiryStatementDto = {
        id: null,
        statementType: <DictionaryBaseDto>{id: typeId},
        value: value,
        version: 1,
      };
      statements.push(statement);
    } else {
      statements[index].value = value;
    }
  }

  private getStatement(typeId: number) {
    const stats = this._inquiry && this._inquiry.statements;
    if (stats) {
      const stat = stats.find((st) => st.statementType.id === typeId);
      if (stat !== undefined) {
        if (stat.value === true) {
          this.lockedAgreements.push(typeId);
        }
        return stat.value;
      }
    }
    return null;
  }

  private getInquiryType() {
    return this._inquiry && this._inquiry.productType && this._inquiry.productType.id;
  }

  surveyNotEmpty() {
    const srv = this._inquiry && this._inquiry.survey;
    const isBoolean = (val) => 'boolean' === typeof val;

    return (
      srv &&
      (isBoolean(srv.clientInsolvencyOrPaymentDelayWorries) ||
        isBoolean(srv.deferredPaymentSales) ||
        isBoolean(srv.interestInDomesticReceivablesProtection) ||
        isBoolean(srv.interestInExportReceivablesProtection))
    );
  }

  getGdprLink() {
    if (this.loggedUserService.getLoggedUserData().language.id === Language.POLISH) {
      return 'https://kuke.com.pl/klauzula-informacyjna-o-przetwarzaniu-danych-osobowych-dla-klienta-kuke';
    }
    return 'https://kuke.com.pl/en/processing-of-personal-data';
  }
}
