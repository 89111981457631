import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import {UserAssignmentRuleDto} from '../model';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';

@Injectable()
export class UserAssignmentRuleService extends AbstractService {
  protected url = this.urlPrefix + 'userAssignmentRule';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getRules(profileCategoryId: number): Observable<UserAssignmentRuleDto[]> {
    return this.get<UserAssignmentRuleDto[]>(this.url + '/' + profileCategoryId);
  }

  update(dtos: UserAssignmentRuleDto[]): Observable<void> {
    return this.put1<UserAssignmentRuleDto[], void>(dtos, this.url);
  }
}
