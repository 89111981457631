import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services';
import {
  CollectionBaseDto,
  CollectionRequestDto,
  CollectionSimpleDto,
  CollectionVersionBaseDto,
  CollectionVersionCriteriaDto,
  CollectionVersionDto,
  CollectionVersionSimpleDto,
  ExternalProviderRelationDto,
  LocalDateRange,
  SearchCriteria,
} from '../../../bonding_shared/model';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {CollectionVersionService} from '../../../bonding_shared/services/collection-version.service';
import {CollectionType} from '../../../bonding_shared/model/dictionary-ids';

@Injectable()
export class CollectionGuiService {
  dataProvider: SearchDataProvider<CollectionVersionCriteriaDto, CollectionVersionBaseDto>;

  constructor(private service: CollectionVersionService) {
    this.dataProvider = CollectionGuiService.createProvider(service);
  }

  static getEmptyVersion(): CollectionVersionDto {
    return <CollectionVersionDto>{
      parent: {},
      collectionInvoices: [],
    };
  }

  static getNewRequest(): CollectionRequestDto {
    return <CollectionRequestDto>{};
  }

  static createProvider(
    service: CollectionVersionService
  ): SearchDataProvider<CollectionVersionCriteriaDto, CollectionVersionBaseDto> {
    const dataProvider = BusinessUtils.createDataProviderWithCriteria<
      CollectionVersionCriteriaDto,
      CollectionVersionBaseDto
    >(service, CollectionGuiService.createCollectionSearchCriteria());
    dataProvider.textSearch = false;
    return dataProvider;
  }

  static createCollectionSearchCriteria(): SearchCriteria<CollectionVersionCriteriaDto> {
    return <SearchCriteria<CollectionVersionCriteriaDto>>{
      criteria: {
        parent: <CollectionSimpleDto>{policy: {masterPolicyContract: {}}},
        externalProviderRelation: <ExternalProviderRelationDto>{},
        versionPhase: 'LAST',
        last: true,
        notificationDateRange: <LocalDateRange>{},
      },
    };
  }

  static isCreditInsurance(collection: CollectionBaseDto): boolean {
    return collection.type && CollectionType.CREDIT_INSURANCE.includes(collection.type.id);
  }

  static isRecoveryCreditInsurance(collection: CollectionBaseDto): boolean {
    return collection.type && CollectionType.RECOVERY_CREDIT_INSURANCE === collection.type.id;
  }

  static isRecoveryBonding(collection: CollectionBaseDto): boolean {
    return collection.type && CollectionType.RECOVERY_BONDING === collection.type.id;
  }

  static isDuePremiums(collection: CollectionBaseDto): boolean {
    return collection.type && CollectionType.DUE_PREMIUMS === collection.type.id;
  }

  static getCollectionBalanceMode(version: CollectionVersionSimpleDto): string {
    if (this.isCreditInsurance(version.parent)) {
      return 'overdue';
    }
    if (this.isRecoveryCreditInsurance(version.parent)) {
      return 'insurer';
    } else {
      return 'insured';
    }
  }

  public resetSearchCriteria() {
    this.dataProvider.searchCriteria = CollectionGuiService.createCollectionSearchCriteria();
  }
}
