import {Component, ViewChild} from '@angular/core';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table';
import {CompanySimpleDto, DictionaryBaseDto, PolicyContractRiskCompanyDto} from '../../../../bonding_shared/model';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {AppConfigService, RouterService} from '../../../../bonding_shared/services';
import {PolicyContractCompanyRole} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'special-risk-section',
  templateUrl: './special-risk-section.component.pug',
})
export class SpecialRiskSectionComponent extends Section {
  self = this;

  @ViewChild('specialRisksList', {static: true}) specialRisksList: ATableComponent<PolicyContractRiskCompanyDto>;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    public router: RouterService
  ) {
    super(policyContractViewService, appService);
  }

  addSpecialRisk(company: CompanySimpleDto) {
    this.specialRisksList.onAddItem(this.risksFactory(company));
    // Adding special risk with a-table.addItem works better here then with risks.push,
    // because it leaves the item in edit mode and requires user to confirm changes,
    // thus makes user less prone to trying to save policy without setting max claim to new risk.
  }

  risksFactory: any = (company: CompanySimpleDto) => {
    return <PolicyContractRiskCompanyDto>{
      role: <DictionaryBaseDto>{id: PolicyContractCompanyRole.RISK, name: 'Risk'},
      company: company,
    };
  };
}
