import {Component, Input} from '@angular/core';
import {
  AppConfigService,
  GrowlService,
  InquiryService,
  PolicyContractVersionDto,
  PolicyInquiryOfferDto,
  PolicyInquiryVersionDto,
  RouterService,
} from '../../../../bonding_shared';
import {PolicyContractViewService} from '../../../policy/services/policy-contract-view.service';
import {OFFER_ACCEPTED_STATUSES, PolicyContractVersionStatus} from '../../../../bonding_shared/model/dictionary-ids';
import {Section} from '../../../policy/components/sections/section';

@Component({
  selector: 'offer-selector-section',
  templateUrl: './offer-selector-section.component.pug',
})
export class OfferSelectorSectionComponent extends Section {
  constructor(
    protected inquiryService: InquiryService,
    protected policyContractViewService: PolicyContractViewService,
    public apiService: AppConfigService,
    private growlService: GrowlService,
    public router: RouterService
  ) {
    super(policyContractViewService, apiService);
  }

  _policy: PolicyContractVersionDto;
  metaOffers: MetaOffer[] = [];

  @Input() set policy(policy: PolicyContractVersionDto) {
    this._policy = policy;
    if (policy.inquiry) {
      this.inquiryService.getById<PolicyInquiryVersionDto>(policy.inquiry.id).subscribe((inquiry) => {
        this.inquiry = inquiry;
        this.initializeMetaOffers();
      });
    }
  }

  get policy() {
    return this._policy;
  }

  goToOffer(metaOffer: MetaOffer) {
    this.router.toInquiryOfferDetails(this.inquiry.id, metaOffer.offer.id);
  }

  initializeMetaOffers() {
    this.metaOffers = [];
    this.metaOffers.push(<MetaOffer>{selected: true, comment: 'No offer selected'});
    this.inquiry.offers
      .filter((o) => OFFER_ACCEPTED_STATUSES.includes(o.status.id))
      .forEach((o) => {
        if (this.policy.offer?.id === o.id) {
          this.metaOffers.push(<MetaOffer>{offer: o, selected: true});
          this.metaOffers[0].selected = false;
        } else {
          this.metaOffers.push(<MetaOffer>{offer: o, selected: false});
        }
      });
  }

  updateSelectedOffer() {
    const selectedOffers = this.metaOffers.filter((mo) => mo.selected);
    switch (selectedOffers.length) {
      case 0:
        console.log('ERROR, no meta offer selected');
        this.policy.offer = null;
        break;
      case 1:
        this.policy.offer = selectedOffers[0].offer;
        break;
      default:
        this.growlService.error('Multiple offers selected');
        break;
    }
  }

  canChangeOffer() {
    return (
      !this.readonly &&
      this.policy.status.id !== PolicyContractVersionStatus.ACTIVATED &&
      this.policy.status.id !== PolicyContractVersionStatus.ACTIVATED_LOCKED
    );
  }
}

class MetaOffer {
  offer: PolicyInquiryOfferDto;
  selected: boolean;
  comment: string;
}
