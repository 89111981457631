import {Injectable} from '@angular/core';
import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {DictionaryBaseDto, LimitBusinessParamPackageDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class LimitBusinessParamsService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'limitBusinessParam';
  }

  getPackage(countryRatingId: number, typeId: number, ratingTypeId: number): Observable<LimitBusinessParamPackageDto> {
    const params = UrlParams.new()
      .add('countryRatingId', countryRatingId)
      .add('typeId', typeId)
      .add('ratingTypeId', ratingTypeId);
    return this.get<LimitBusinessParamPackageDto>(this.buildUrl(this.url, null, params));
  }

  getTypes(): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/types');
  }

  getRatingTypes(): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/ratingTypes');
  }

  update(p: LimitBusinessParamPackageDto): Observable<LimitBusinessParamPackageDto> {
    return this.post<LimitBusinessParamPackageDto>(p, this.url);
  }
}
