import {ChangeDetectorRef, Component} from '@angular/core';
import {PortalInvoiceService, PortalRouterService} from '../services';
import {ActivatedRoute, Params} from '@angular/router';
import {
  BusinessPropertiesService,
  DictionaryBaseService,
  GrowlService,
  InvoiceDto,
  LoggedUserService,
  PropertyService,
  ReportDefinitionSimpleDto,
} from '../../bonding_shared';
import {AbstractInvoiceDetailsComponent} from '../../bonding_shared/components/shared/abstract-invoice-details.component';
import {InvoiceType} from '../../bonding_shared/model/dictionary-ids';
import {InvoiceUtils} from '../../bonding_shared/utils/invoice-utils';
import {BusinessReportPortalService} from '../services/portal-business-report.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'client-invoice-preview',
  templateUrl: './client-invoice-preview.component.html',
})
export class ClientInvoicePreviewComponent extends AbstractInvoiceDetailsComponent {
  readonly InvoiceType = InvoiceType;
  public invoice: InvoiceDto = <InvoiceDto>{};
  protected installmentsStr: string;
  protected reportDefinitions: ReportDefinitionSimpleDto[];

  constructor(
    protected propertyService: PropertyService,
    protected businessPropertiesService: BusinessPropertiesService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected route: ActivatedRoute,
    protected loggedUserService: LoggedUserService,
    private invoiceService: PortalInvoiceService,
    businessReportPortalService: BusinessReportPortalService,
    public router: PortalRouterService,
    private cd: ChangeDetectorRef
  ) {
    super(
      propertyService,
      businessPropertiesService,
      translateService,
      growlService,
      dictionaryBaseService,
      route,
      loggedUserService,
      businessReportPortalService
    );
    this.saveButton.hidden = true;
    this.cancelButton.hidden = true;
  }

  initializeView(params: Params) {
    super.initializeView(params);
    const id = +params['id'];
    console.log('ClientInvoiceDetailsComponent::initializeView ' + id);
    this.loadInvoice(id);
  }

  private loadInvoice(id: number) {
    this.invoiceService.getInvoice(id).subscribe({
      next: (invoice) => {
        this.setInvoice(invoice);
      },
      error: (error) => {
        console.log('Error during invoiceService.getInvoice for id = ' + id);
        this.serverErrors = error;
      },
      complete: () => {
        this.inProgress = false;
      },
    });
  }

  private setInvoice(invoice: InvoiceDto) {
    this.invoice = invoice;
    this.serverErrors = invoice.warnings;
    this.installmentsStr = InvoiceUtils.getInstallmentsCountStr(this.invoice);
    this.loadReportDefinitions();
    this.cd.detectChanges();
  }

  private loadReportDefinitions() {
    if (this.invoice && this.invoice.id) {
      this.invoiceService.getReportDefinitions(this.invoice.id).subscribe({
        next: (reportDefinitions) => {
          this.reportDefinitions = reportDefinitions;
        },
        error: (error) => {
          console.log('Error during portalInvoiceService.getReportDefinitions');
          this.serverErrors = error;
        },
      });
    } else {
      this.reportDefinitions = [];
    }
  }
}
