import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {PolicyClauseDto} from '../../../../bonding_shared/model/dtos';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {DictionaryService} from '../../../../bonding_shared/services/dictionary.service';
import {map} from 'rxjs/operators';
import {AppConfigService} from '../../../../bonding_shared/services/app-config.service';
import {DictionaryPropertyType} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'exclusions-section',
  templateUrl: './exclusions-section.component.pug',
})
export class ExclusionsSectionComponent extends Section implements OnChanges {
  self = this;

  clauses: PolicyClauseDto[];

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected dictionaryService: DictionaryService
  ) {
    super(policyContractViewService, appService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['policy']) {
      this.updateClauses();
    }
  }

  private updateClauses() {
    const p = this._policy;
    if (p && p.clauses) {
      this.clauses = p.clauses.filter((cs) => cs.exclusion);
    }
  }
}
