import {Component, Input} from '@angular/core';
import {VoteDto, VotingProcessDto, DictionaryBaseDto, UserGroupBaseDto} from '../../../../bonding_shared/model';
import {UserService} from '../../../../bonding_shared/services';
import {BondingContractOfferService} from '../../../../bonding_shared/services/bonding-contract-offer.service';

import {BondingContractOfferStatus} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'bonding-contract-offer-voting-section',
  templateUrl: './bonding-contract-offer-voting-section.component.pug',
})
export class BondingContractOfferVotingSectionComponent {
  @Input() voting: VotingProcessDto;
  @Input() votes: VoteDto[];
  @Input() offerStatus: DictionaryBaseDto;
  @Input() readOnly = false;

  readonly BondingContractOfferStatus = BondingContractOfferStatus;
}
