import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CompanyModule} from '../company/company.module';
import {InvoiceSearchComponent} from './invoice-search.component';
import {InvoiceDetailsComponent} from './invoice-details.component';
import {InvoiceDataComponent} from './components/invoice-data.component';
import {InvoiceGuiService} from './services/invoice-gui.service';
import {DocumentModule} from '../document/document.module';
import {InvoiceNumberingBagSelectorComponent} from './components/invoice-numbering-bag-selector.component';
import {InvoiceItemModule} from '../invoice-item/invoice-item.module';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {InvoiceSharedModule} from './components/shared/invoice-shared.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanyModule,
    DocumentModule,
    InvoiceItemModule,
    CompanySharedModule,
    InvoiceSharedModule,
    BsDropdownModule,
  ],
  declarations: [
    InvoiceSearchComponent,
    InvoiceDetailsComponent,
    InvoiceDataComponent,
    InvoiceNumberingBagSelectorComponent,
  ],
  exports: [InvoiceNumberingBagSelectorComponent],
  providers: [InvoiceGuiService],
})
export class InvoiceModule {}
