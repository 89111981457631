<div *ngIf="companyId">
  <div class="bon-row">
    <label class="bon-label">Related to</label>
    <dict-combo
      class="bon-input-size"
      [formControl]="relatedTo"
      nullLabel=" "
      dictionary="BusinessObjectType"
      [codeRegexp]="codeRegExp"
      [disabled]="disabled"
      parentDictionary="ApplicationModule"
      [parentDictionaryEntryId]="parentDictionaryEntryId"
      [allForEmptyParent]="true">
    </dict-combo>
    <span *ngIf="value?.relatedToId && !disabled">
      <i class="fa fa-trash-o" (click)="onClear()" title="Clear"></i>
    </span>
  </div>
  <div class="bon-row" [hidden]="!relatedTo.value">
    <label class="bon-label" *ngIf="!value">{{'common.objectSelect' | translate}}</label>
    <label class="bon-label" *ngIf="value?.relatedToId">{{'common.objectSelected' | translate}}</label>
    <div class="bon-input">
      <input
        [ngModel]="value"
        name="businessObjectAutoComplete"
        (ngModelChange)="changeItem($event)"
        [disabled]="disabled"
        autocomplete="new-password"
        auto-complete
        value-formatter="preciseDescription"
        list-formatter="preciseDescription"
        [match-formatted]="matchFormatted"
        [source]="autoCompleteSearch.bind(this)"
        [accept-user-input]="acceptUserInput"
        [min-chars]="0"
        [max-num-list]="30"
        style="width: 100%;border: none;outline: none;background: none"
        bonFocus="auto"
        blank-option-text=" "
      />
    </div>
  </div>
</div>
