import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ClaimCommissionDto} from '../model';
import {Observable} from 'rxjs';
import {AbstractAuditedService} from './abstract-audited.service';

@Injectable()
export class ClaimCommissionService extends AbstractAuditedService<ClaimCommissionDto> {
  protected url = this.urlPrefix + 'claim/commissions';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  initial(claimId: number): Observable<ClaimCommissionDto> {
    return this.get(this.url + '/initial?claimId=' + claimId);
  }

  saveAudited(_: number, dto: ClaimCommissionDto): Observable<ClaimCommissionDto> {
    return this.post(dto, this.url);
  }
}
