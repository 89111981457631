import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BondingContractInquiryVersionListComponent} from './bonding-contract-inquiry-version-list.component';
import {BondingContractInquiryVersionSelectorSimpleComponent} from './bonding-contract-inquiry-version-selector-simple.component';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
  declarations: [BondingContractInquiryVersionListComponent, BondingContractInquiryVersionSelectorSimpleComponent],
  exports: [BondingContractInquiryVersionListComponent, BondingContractInquiryVersionSelectorSimpleComponent],
})
export class BondingContractInquirySharedModule {}
