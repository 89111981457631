import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import Big from 'big.js';
import {isNumeric} from '../../utils/number-utils';

@Directive({
  selector: '[turnoversSumField]',
  providers: [{provide: NG_VALIDATORS, useExisting: TurnoversSumDirective, multi: true}],
})
export class TurnoversSumDirective implements Validator {
  @Input('turnoversSumField') turnoversSumField: any;

  private static sumVal(values: Array<number>): Big {
    let sum = Big(0);
    for (const v of values) {
      sum = sum.plus(v);
    }
    return sum;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (
      control.parent &&
      control.parent.get(this.turnoversSumField.domestic) &&
      control.parent.get(this.turnoversSumField.export)
    ) {
      let domValue = control.parent.get(this.turnoversSumField.domestic).value;
      let expValue = control.parent.get(this.turnoversSumField.export).value;
      if (!isNumeric(domValue) && !isNumeric(expValue)) {
        return null;
      }
      domValue = isNumeric(domValue) ? domValue : 0;
      expValue = isNumeric(expValue) ? expValue : 0;
      const sum = TurnoversSumDirective.sumVal([domValue, expValue]);
      return sum.eq(Big(control.value)) ? null : {sumInvalid: true};
    } else {
      return null;
    }
  }
}
