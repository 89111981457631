import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  DictionaryBaseDto,
  DictionaryDto,
  DocumentDescriptionDto,
  ErrorReason,
  LocalDateRange,
  RepositoryDocumentCriteriaDto,
  SearchResult,
} from '../../bonding_shared/model';
import {AppConfigService, RepositoryService, SearchDataProvider} from '../../bonding_shared/services';
import {ActivatedRoute} from '@angular/router';
import {ATableComponent} from '../../bonding_shared/components/aku-table';
import {TranslateService} from '@ngx-translate/core';
import {
  BondDocumentType,
  BondingContractDocumentType,
  BusinessObjectType,
  ClaimDocumentType,
  CollectionDocumentType,
  CompanyDocumentType,
  Feature,
  RepoDocumentCategory,
  RepositoryDocumentType,
  SalesDocumentType,
} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'repo-document-list',
  templateUrl: './repo-document-list.component.pug',
})
export class RepoDocumentListComponent implements OnInit, AfterViewInit {
  static hiddenDocumentTypeIds = [CompanyDocumentType.BROKER_LOGO];
  static hiddenKukeExternalDocumentTypeIds = [
    CompanyDocumentType.FINANCIAL_DATA_REQUEST_LETTER,
    CompanyDocumentType.MANAGMENT_OR_KRU_REQUEST,
    CompanyDocumentType.KPUE_REQUEST,
    CompanyDocumentType.RIF,
    SalesDocumentType.BOARD_APPLICATIONS,
    ClaimDocumentType.INVOICES,
    ClaimDocumentType.SUBJECT_OF_CONTRACT_STATEMENT,
    ClaimDocumentType.NO_DISPUTE_STATEMENT,
    ClaimDocumentType.DISPUTED_AMOUNT_STATEMENT,
    ClaimDocumentType.DEBT_ACKNOWLEDGEMENT,
    ClaimDocumentType.COURT_DEBT_ACKNOWLEDGEMENT,
    ClaimDocumentType.RECEPTION_ACKNOWLEDGEMENT,
    ClaimDocumentType.COLLATERALS,
    ClaimDocumentType.CREDIT_LIMIT_CONDITIONS_STATEMENT,
    ClaimDocumentType.DOCS_INSOLVENCY_DOCS_PROCEEDINGS,
    ClaimDocumentType.CORRESPONDENCE_DISCOUNTS_CLAIMS_COMPENSATIONS,
    ClaimDocumentType.ACCOUNT_TRACK_RECORD,
    ClaimDocumentType.STATEMENT_OF_ACCOUNT_TRACK_RECORD,
    ClaimDocumentType.DISCRETIONARY_COVER_STATEMENT,
    ClaimDocumentType.FURTHER_DELIVERIES_STATEMENT,
    ClaimDocumentType.ORDERS_AND_CONFIRMATIONS,
    ClaimDocumentType.PAYMENT_CORRESPONDENCE,
    ClaimDocumentType.SUBINSUREDS_STATEMENTS,
    ClaimDocumentType.CUSTOM_DOCUMENTS,
    ClaimDocumentType.CONTRACT,
    ClaimDocumentType.CONDITIONS_OF_SALESD,
    ClaimDocumentType.COUNTRY_OF_ORIGIN,
    ClaimDocumentType.POWER_OF_ATTORNEY,
    ClaimDocumentType.CORR_IN_OUT,
    ClaimDocumentType.OTHER,
    ClaimDocumentType.INCURRED_COSTS_CONFIRMATION,
    ClaimDocumentType.LOSS_OCCURENCE_CONFIRMATION,
    ClaimDocumentType.PRODUCTION_COSTS_RECORDS,
    ClaimDocumentType.WARRANTIES,
    ClaimDocumentType.CORRESPONDENCE_REG_LIQUIDATION_OF_CLAIM,
    ClaimDocumentType.APPEAL_FROM_A_DECISION,
    ClaimDocumentType.KUKE_ANSWER_TO_APPEAL,
    ClaimDocumentType.CLAIM_DECISION_SENT_TO_CLIENT,
    ClaimDocumentType.DOCUMENTATION_REG_CLAIM_DECISION,
    ClaimDocumentType.CORRESPONDENCE_WITH_BANK_FACTOR_OTHERS,
    ClaimDocumentType.OTHER_DOCUMENTS,
    ClaimDocumentType.CORRESPONDENCE_PRELIMINARY_ASSESSMENT,
    ClaimDocumentType.COMPENSATION_CLAIM_BONDING,
    ClaimDocumentType.AGREEMENT,
    ClaimDocumentType.INTERNAL_CONSULTATION,
    ClaimDocumentType.CORRESPONDENCE_WITH_EMBASSY,
    ClaimDocumentType.LETTER_EXPLAINING_THE_CLAIM,
    RepositoryDocumentType.BOARD_COMMITTEE_DECISION,
    RepositoryDocumentType.DOCUMENTATION_REGARDING_THE_DECISION_ON_LIQUIDATION_OF_THE_CLAIM,
    RepositoryDocumentType.CORRESPONDENCE_BANK_FACTOR_OTHER_AUTHORIZED_DUE_TO_ASSIGNMENT,
    RepositoryDocumentType.OTHER_DOCUMENTS,
    RepositoryDocumentType.INVOICE_CREDIT_NOTE,
    RepositoryDocumentType.INVOICES_DESCRIPTION,
    RepositoryDocumentType.EXTERNAL_PROVIDER_CORRESPONDENCE,
    CollectionDocumentType.INTERNAL_DOCUMENTS,
    CollectionDocumentType.INTERNAL_CORRESPONDENCE,
    CollectionDocumentType.COMPLAINS,
    CollectionDocumentType.SKIP_TRACING,
    CollectionDocumentType.EXTERNAL_PROVIDER_CORRESPONDENCE_IN,
    BondDocumentType.REQUEST_FROM,
    BondDocumentType.REQUEST_FOR_APPROVAL_KRU_KPUE_MANAGEMENT_BOARD_REINSURANCE,
    BondDocumentType.RESOLUTION_TO_THE_REQUEST,
    BondDocumentType.OFFER,
    BondDocumentType.BROKERAGE_AGREEMENT_FOR_THE_GUARANTEE,
    BondDocumentType.PROMISE,
    BondDocumentType.CONTRACT,
    BondDocumentType.CONTRACT_ANNEX,
    BondDocumentType.OTHER_DOCUMENTS,
    BondingContractDocumentType.REQUEST_FROM,
    BondingContractDocumentType.REQUEST_FOR_APPROVAL_KRU_KPUE_MANAGEMENT_BOARD_REINSURANCE,
    BondingContractDocumentType.RESOLUTION_TO_THE_REQUEST,
    BondingContractDocumentType.OFFER,
    BondingContractDocumentType.BROKERAGE_AGREEMENT_FOR_THE_GUARANTEE,
    BondingContractDocumentType.PROMISE,
    BondingContractDocumentType.CONTRACT,
    BondingContractDocumentType.CONTRACT_ANNEX,
    BondingContractDocumentType.OTHER_DOCUMENTS,
  ];

  static hiddenKukeInternalDocumentTypeIds = [
    SalesDocumentType.POLICY_INQUIRY,
    ClaimDocumentType.INVOICES,
    ClaimDocumentType.SUBJECT_OF_CONTRACT_STATEMENT,
    ClaimDocumentType.NO_DISPUTE_STATEMENT,
    ClaimDocumentType.DISPUTED_AMOUNT_STATEMENT,
    ClaimDocumentType.DEBT_ACKNOWLEDGEMENT,
    ClaimDocumentType.COURT_DEBT_ACKNOWLEDGEMENT,
    ClaimDocumentType.RECEPTION_ACKNOWLEDGEMENT,
    ClaimDocumentType.COLLATERALS,
    ClaimDocumentType.CREDIT_LIMIT_CONDITIONS_STATEMENT,
    ClaimDocumentType.DOCS_INSOLVENCY_DOCS_PROCEEDINGS,
    ClaimDocumentType.CORRESPONDENCE_DISCOUNTS_CLAIMS_COMPENSATIONS,
    ClaimDocumentType.ACCOUNT_TRACK_RECORD,
    ClaimDocumentType.STATEMENT_OF_ACCOUNT_TRACK_RECORD,
    ClaimDocumentType.DISCRETIONARY_COVER_STATEMENT,
    ClaimDocumentType.FURTHER_DELIVERIES_STATEMENT,
    ClaimDocumentType.ORDERS_AND_CONFIRMATIONS,
    ClaimDocumentType.PAYMENT_CORRESPONDENCE,
    ClaimDocumentType.SUBINSUREDS_STATEMENTS,
    ClaimDocumentType.CUSTOM_DOCUMENTS,
    ClaimDocumentType.CONTRACT,
    ClaimDocumentType.CONDITIONS_OF_SALESD,
    ClaimDocumentType.COUNTRY_OF_ORIGIN,
    ClaimDocumentType.POWER_OF_ATTORNEY,
    ClaimDocumentType.CORR_IN_OUT,
    ClaimDocumentType.OTHER,
    ClaimDocumentType.INCURRED_COSTS_CONFIRMATION,
    ClaimDocumentType.LOSS_OCCURENCE_CONFIRMATION,
    ClaimDocumentType.PRODUCTION_COSTS_RECORDS,
    ClaimDocumentType.WARRANTIES,
    ClaimDocumentType.CORRESPONDENCE_REG_LIQUIDATION_OF_CLAIM,
    ClaimDocumentType.APPEAL_FROM_A_DECISION,
    ClaimDocumentType.KUKE_ANSWER_TO_APPEAL,
    ClaimDocumentType.CLAIM_DECISION_SENT_TO_CLIENT,
    ClaimDocumentType.DOCUMENTATION_REG_CLAIM_DECISION,
    ClaimDocumentType.CORRESPONDENCE_WITH_BANK_FACTOR_OTHERS,
    ClaimDocumentType.OTHER_DOCUMENTS,
  ];

  @Input() labelKey: string;
  @Input() businessObjectTypeId: number;
  @Input() businessObjectId: number;
  @Input() categoryId: number;
  @Input() documentTypes: DictionaryDto[];

  selectedType: DictionaryBaseDto;
  documentTypeName: string;

  dataProvider: SearchDataProvider<RepositoryDocumentCriteriaDto, DocumentDescriptionDto>;

  @ViewChild(ATableComponent, {static: true}) documentTable: ATableComponent<DocumentDescriptionDto>;

  readonly Feature = Feature;

  get hiddenTypeIds() {
    return RepoDocumentListComponent.hiddenDocumentTypeIds;
  }

  ngOnInit() {
    this.repositoryService.documentTypeName(this.businessObjectTypeId).subscribe((n) => {
      this.documentTypeName = n;
    });
    this.setupCriteria();
  }

  ngAfterViewInit() {
    this.documentTable.search();
    this.documentTable.dataProviderSearchFinished.subscribe((searchResult: SearchResult<DocumentDescriptionDto>) => {
      this.onSearchFinished(searchResult);
    });
  }

  refreshDocumentList() {
    this.documentTable.search();
  }

  public get kuke(): boolean {
    return this.appConfigService.kuke;
  }

  constructor(
    private repositoryService: RepositoryService,
    private _route: ActivatedRoute,
    protected translateService: TranslateService,
    public appConfigService: AppConfigService
  ) {
    this.dataProvider = new SearchDataProvider<RepositoryDocumentCriteriaDto, DocumentDescriptionDto>(
      repositoryService
    );
  }

  setupCriteria() {
    this.criteria = <RepositoryDocumentCriteriaDto>{};
    this.criteria.businessObjectType = <DictionaryBaseDto>{id: this.businessObjectTypeId};
    this.criteria.businessObjectId = this.businessObjectId;
    this.criteria.documentCategory = this.categoryId ? <DictionaryBaseDto>{id: this.categoryId} : undefined;
    this.criteria.documentType = <DictionaryBaseDto>{};
    this.criteria.uploaded = <LocalDateRange>{};
    this.criteria.portal = false;
    this.criteria.registrant = undefined;
  }

  get criteria(): RepositoryDocumentCriteriaDto {
    return this.dataProvider.searchCriteria.criteria;
  }

  set criteria(c: RepositoryDocumentCriteriaDto) {
    this.dataProvider.searchCriteria.criteria = c;
  }

  getDocument(document: DocumentDescriptionDto) {
    this.repositoryService.getRepositoryDocument(document.repositoryId, document.name);
  }

  filterDocumentType() {
    this.search();
  }

  search() {
    this.documentTable.search();
  }

  get versionedObject() {
    return this.businessObjectTypeId !== BusinessObjectType.COMPANY;
  }

  onRemoveDocument(doc: DocumentDescriptionDto) {
    if (confirm(this.translateService.instant('common.confirm'))) {
      this.repositoryService.deleteRepositoryDocument(doc);
      this.refreshDocumentList();
    }
  }

  isExternal(): boolean {
    return this.categoryId === RepoDocumentCategory.EXTERNAL;
  }

  private onSearchFinished(searchResult: SearchResult<DocumentDescriptionDto>): void {
    if (!searchResult.result || searchResult.result.length === 0) {
      this.addWarning(this.translateService.instant('common.noResultFound'));
    }
  }

  private addWarning(message: string): void {
    if (!Array.isArray(this.documentTable.errorMessage)) {
      this.documentTable.errorMessage = [];
    }
    this.documentTable.errorMessage.push(<ErrorReason>{
      severity: 'WARNING',
      message: message,
    });
  }

  // check by method because this.categoryId inside this.isExternal() is visible with delay
  showDocumentSourceClientPortal(): boolean {
    return !this.kuke || (this.kuke && !this.isExternal());
  }

  getTypeName(document: DocumentDescriptionDto) {
    return document.repositoryDocumentTypeName || document.documentType?.name || '???';
  }
}
