import {filter, map, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AbstractService, AppConfigService, LoggedUserService} from '../../bonding_shared/services';
import {
  ContractBaseDto,
  ContractVersionCriteriaDto,
  ContractVersionDto,
  ContractVersionSimpleDto,
  SearchCriteria,
  SearchResult,
  StatisticsDto,
  UserDto,
} from '../../bonding_shared/model/dtos';
import {Observable, ReplaySubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContractStatus, ContractType} from '../../bonding_shared/model/dictionary-ids';
import {SearchDataProvider} from '../../bonding_shared';

@Injectable()
export class PortalContractVersionService extends AbstractService {
  protected url = this.urlPrefix + 'portal/contractVersion';

  private clientContractCache: ReplaySubject<SearchResult<ContractVersionSimpleDto>> = null;

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);

    loggedUserService.getUser().subscribe((user: UserDto) => {
      this.clearClientContractCache();
    });
  }

  public static isContractBooster(cv: ContractVersionSimpleDto): boolean {
    return (
      cv &&
      cv.contract &&
      cv.contract.type &&
      [ContractType.BOOSTER, ContractType.BANK_BOOSTER].includes(cv.contract.type.id)
    );
  }

  getClientContractsCriteria(runOffContracts = true): ContractVersionCriteriaDto {
    const contractCriteria = <ContractVersionCriteriaDto>{contract: <ContractBaseDto>{}};
    contractCriteria.statusIds = [ContractStatus.CONTRACT_ACTIVATED, ContractStatus.CONTRACT_TO_BE_CANCELLED];
    if (runOffContracts) {
      contractCriteria.statusIds.push(ContractStatus.RUN_OFF);
    }
    return contractCriteria;
  }

  getClientContractsFull(): Observable<ContractVersionSimpleDto> {
    if (this.clientContractCache === null) {
      const lClientContractCache = (this.clientContractCache = new ReplaySubject<
        SearchResult<ContractVersionSimpleDto>
      >(1));

      const dataProvider: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto> = new SearchDataProvider(
        this
      );
      dataProvider.searchCriteria = <SearchCriteria<ContractVersionCriteriaDto>>{};
      dataProvider.searchCriteria.criteria = this.getClientContractsCriteria();
      dataProvider.additionalQueryParams = [{key: 'single', val: 'true'}];
      dataProvider.searchAllInLoop().subscribe({
        next: (data) => {
          lClientContractCache.next(data);
          lClientContractCache.complete();
        },
        error: (error) => {
          lClientContractCache.error(error);
          this.clearClientContractCache();
        },
      });
    }

    return this.clientContractCache.pipe(
      mergeMap((contracts) => {
        return contracts.result;
      })
    );
  }

  getClientContracts(): Observable<IdAndNumber> {
    return this.getClientContractsFull().pipe(
      map((c: ContractVersionSimpleDto) => {
        return <IdAndNumber>{
          id: c.id,
          cvNumber: (c.contract.number ? c.contract.number : c.contract.requestNumber) + '/' + c.versionNumber,
        };
      })
    );
  }

  getClientContract(id: number): Observable<ContractVersionSimpleDto> {
    return this.getClientContractsFull().pipe(filter((cv) => cv.id === id));
  }

  getContractVersion(id: number): Observable<ContractVersionDto> {
    console.log('contract version details: id = ' + id);
    return this.get<ContractVersionDto>(this.url + '/' + id);
  }

  getContractStatistics(contractVersionId: number): Observable<StatisticsDto> {
    return this.get<StatisticsDto>(this.url + '/' + contractVersionId + '/statistics');
  }

  getContractVersionsByContractId(
    contractId: number,
    active?: boolean,
    statusId?: number
  ): Observable<SearchResult<ContractVersionDto>> {
    let query = this.url + '?contractId=' + contractId;
    if (active) {
      query += '&active=' + active;
    }
    if (statusId) {
      query += '&status=' + statusId;
    }
    return this.get<SearchResult<ContractVersionDto>>(query);
  }

  private clearClientContractCache() {
    this.clientContractCache = null;
  }
}

export interface IdAndNumber {
  id: number;
  cvNumber: string;
}

export interface GroupedIdAndNumber {
  groupName: string;
  elements: IdAndNumber[];
}
