/**
 * Created by jakubowski on 6.10.2017
 */
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {TreatyBaseDto} from '../../model';
import {TreatyVersionService} from '../../services';

const TREATY_AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TreatyAutoCompleteComponent),
  multi: true,
};

@Component({
  selector: 'treaty-auto-complete',
  template: `
    <input
      [ngModel]="_selectedItem"
      (ngModelChange)="selectedItem = $event"
      class="bon-input"
      [disabled]="disabled"
      autocomplete="off"
      auto-complete
      [value-formatter]="valueFormatter"
      [list-formatter]="listFormatter"
      [match-formatted]="matchFormatted"
      [source]="items"
      [accept-user-input]="acceptUserInput"
      [min-chars]="3"
      style="width: 100%"
    />
  `,
  providers: [TREATY_AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR],
})
export class TreatyAutoCompleteComponent implements ControlValueAccessor, OnInit {
  @HostBinding('class') class = 'bon-input-size';

  @Input() valueFormatter: ((arg: any) => string) | string;
  @Input() listFormatter: ((arg: any) => string) | string;
  @Input() matchFormatted = true;
  @Input() acceptUserInput = false;

  @Input() nullLabel: string;
  @Input() disabled = false;
  @Input() label = 'name';

  /**
   * The items that will not be shown in a list. This should be be stored as set,
   * i.e.  hiddenIds[12] = true;
   */
  @Input() hiddenIds: Set<number>;

  @Output() changeItem = new EventEmitter<TreatyBaseDto>();

  @ContentChild(TemplateRef, {static: true}) template: TemplateRef<any>;

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  private _selectedKey: number;
  public _selectedItem: TreatyBaseDto;

  public items: TreatyBaseDto[] = new Array<TreatyBaseDto>();
  public errorMessage: string;

  constructor(private treatyVersionService: TreatyVersionService) {}

  ngOnInit() {
    this.loadTreaties();
  }

  get selectedItem(): TreatyBaseDto {
    console.log('get SelectedItem() ', this._selectedItem);
    return this._selectedItem;
  }

  set selectedItem(it: TreatyBaseDto) {
    this._selectedItem = it;
    if (
      this.onChangeListeners &&
      (typeof this._selectedItem === 'object' || typeof this._selectedItem === 'undefined')
    ) {
      this.onChangeListeners(this._selectedItem);
      this.changeItem.emit(this._selectedItem);
    }
  }

  // From ControlValueAccessor interface
  writeValue(it: TreatyBaseDto): void {
    this._selectedItem = (it && this.items.find((i) => i.id === it.id)) || it;
    this._selectedKey = this._selectedItem && this._selectedItem.id;
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private loadTreaties() {
    this.treatyVersionService.getAllTreaties().subscribe(
      (items) => (this.items = items),
      (error) => (this.errorMessage = <any>error)
    );
  }
}
