import {DictionaryBaseDto} from './dtos';

export class Currency {
  static EURO_ID = 4000055;
  static PLN_ID = 4000130;

  static isEuro(currId: number) {
    if (!currId) {
      return false;
    }
    // TODO: seriously???
    return parseInt(currId + '', 10) === parseInt(Currency.EURO_ID + '', 10);
  }

  // TO BE DONE IN BON-2639
  static getSystemCurrency(): DictionaryBaseDto {
    return <DictionaryBaseDto>{id: this.PLN_ID, name: 'PLN', code: 'PLN'};
  }

  static getEuroCurrency(): DictionaryBaseDto {
    return <DictionaryBaseDto>{id: this.EURO_ID, name: 'Euro', code: 'EUR'};
  }

  static getUsdCurrency(): DictionaryBaseDto {
    return <DictionaryBaseDto>{id: 4000172, name: 'US dollar', code: 'USD'};
  }
}
