import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {DictionaryBaseDto} from '../../model/dtos';
import {DictionaryProfile} from '../../model/dictionary-ids';

const BOOLEAN_COMBO_ROW_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BooleanComboRowComponent),
  multi: true,
};

@Component({
  selector: 'boolean-combo-row',
  template: `
    <div class="bon-row">
      <label class="bon-label" translate>{{ labelKey }}</label>
      <boolean-combo
        class="bon-input-size"
        #model="ngModel"
        [disabled]="disabled"
        [ngModel]="value"
        [required]="required"
        [allowUndefined]="allowUndefined"
        (changeItem)="onChangeItem($event)"
      >
      </boolean-combo>
      <error-message [control]="model.control" [show]="showErrors"></error-message>
    </div>
  `,
  providers: [BOOLEAN_COMBO_ROW_CONTROL_VALUE_ACCESSOR],
})
export class BooleanComboRowComponent implements ControlValueAccessor {
  @Input() labelKey: string;
  @Input() disabled: any;
  @Input() showErrors: boolean;
  @Input() required: boolean;
  @Input() allowUndefined = true;

  @Output() changeItem = new EventEmitter<boolean>();

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  public value: any;

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onChangeItem(event: boolean) {
    this.value = event;
    if (this.onChangeListeners) {
      this.onChangeListeners(this.value);
    }
    this.changeItem.emit(this.value);
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
