import {Injectable} from '@angular/core';
import {SalesLeadSearchCriteria} from '../model/sales-lead-search-criteria';
import {SalesLeadService} from '../../../bonding_shared/services/sales-lead.service';
import {LoggedUserService} from '../../../bonding_shared/services/logged-user.service';

@Injectable()
export class SalesLeadGuiService {
  searchCriteria: SalesLeadSearchCriteria;

  constructor(private _salesLeadService: SalesLeadService, private _loggedUserService: LoggedUserService) {
    this.searchCriteria = new SalesLeadSearchCriteria(this._salesLeadService, this._loggedUserService);
  }

  get salesLeadService() {
    return this._salesLeadService;
  }
}
