import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  AddressDto,
  BondVersionDto,
  CompanyPortalDto,
  CompanySimpleDto,
  ContractVersionDto,
} from '../../../bonding_shared/model/dtos';
import {ClientBondSummaryWarnings} from '../clientbond-summary-warnings.domain';
import {TranslateService} from '@ngx-translate/core';
import {StringUtils} from '../../../bonding_shared/utils';
import {ClientBondFlowService} from '../domain/clientbond-flow.domain';

@Component({
  selector: 'clientbond-summary',
  templateUrl: 'clientbond-summary.component.html',
  styles: [
    'label { width: 200px; font-weight: 700; margin-bottom: 2px; margin-right: 10px;}',
    '.card-block {padding-top: 0px; padding-left: 35px;}',
    'label + span {vertical-align: top; display: inline-block; width: 600px;}',
  ],
})
export class ClientBondSummaryComponent implements OnInit, AfterViewInit, OnDestroy {
  SEND_POST_YES: string = this.translateService.instant('common.YES');
  SEND_POST_NO: string = this.translateService.instant('common.NO');

  get bond(): BondVersionDto {
    return this._bond;
  }

  @Input() set bond(bond: BondVersionDto) {
    this._bond = bond;
    this.bondFlow.setupBond(bond);
  }
  @Input() set contractVersion(contractVersion: ContractVersionDto) {
    this.bondFlow.contractVersion = contractVersion;
  }
  @Input() client: CompanySimpleDto;
  @Output() summaryClose = new EventEmitter<boolean>();

  postAddress: string;
  executionAddress: string;
  warnings: string[];
  private _bond: BondVersionDto;

  constructor(private translateService: TranslateService, public bondFlow: ClientBondFlowService) {}

  ngOnInit(): void {
    this.postAddressPrepare();
    this.executionAddressPrepare();
    this.warningsPrepare();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  onCancel() {
    this.summaryClose.emit(false);
  }

  onIssue() {
    this.summaryClose.emit(true);
  }

  getSendByPostValue(): string {
    const beneficiaryRaw = this.getBeneficiaryRaw();
    return beneficiaryRaw.sendToBeneficiaryByPost ? this.SEND_POST_YES : this.SEND_POST_NO;
  }

  private postAddressPrepare() {
    const beneficiaryRaw = this.getBeneficiaryRaw();
    if (this.isNotEmptyAddress(beneficiaryRaw.correnspondenceAddress)) {
      this.postAddress = StringUtils.prettyAddress(beneficiaryRaw.correnspondenceAddress);
    } else {
      this.postAddress = StringUtils.prettyAddress(beneficiaryRaw.registrationAddress);
    }
  }

  private isNotEmptyAddress(address: AddressDto) {
    return address && (address.street || address.streetNumber || address.town || address.zipCode);
  }

  private executionAddressPrepare() {
    this.executionAddress = StringUtils.prettyAddress(this.bond.executionAddress);
  }

  private getBeneficiaryRaw(): CompanyPortalDto {
    return <CompanyPortalDto>this.bond.beneficiaryRaw;
  }

  private warningsPrepare() {
    this.warnings = new ClientBondSummaryWarnings(this.bond, this.translateService, this.bondFlow).getWarnings();
  }
}
