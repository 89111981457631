<div class="login-page animate">
	<div class="row">
		<div class="col-md-4 col-lg-4 col-md-offset-4 col-lg-offset-4">
        	<a class="hvr-pulse" (click)="gotoHome()"><img src="assets/images/flat-avatar.png" class="user-avatar" /></a>
			<h1>Ani Theme </h1>
			<div class="four-container text-center">
				<h1>404</h1>
			</div>
			Oops! Page not found.
		</div>	
	</div>
</div>