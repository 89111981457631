import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';

const RADIO_ENTITY_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioEntityComponent),
  multi: true,
};

@Component({
  selector: 'radio-entity',
  template: `
    <ng-container *ngIf="control">
      <input
        *ngIf="!presentationMode"
        type="radio"
        [name]="name"
        [formControl]="control"
        style="width: auto; min-width: 0; margin-top: 0px"
        [class.no-top-margin]="resetTopMargin"
        [value]="value"
      />
      <input
        *ngIf="presentationMode"
        type="radio"
        [name]="name"
        [formControl]="control"
        disabled
        style="width: auto; min-width: 0; margin-top: 0px"
        [class.no-top-margin]="resetTopMargin"
        [value]="value"
      />
      <error-message [control]="control" [show]="showErrors"></error-message>
    </ng-container>
    <ng-container *ngIf="!control">
      <input
        *ngIf="!presentationMode"
        #model="ngModel"
        type="radio"
        [name]="name"
        [ngModel]="currentValue"
        (change)="onChangeItem($event)"
        (blur)="onTouchedListeners()"
        [disabled]="disabled || readonly"
        style="width: auto; min-width: 0;"
        [class.no-top-margin]="resetTopMargin"
        [value]="value"
      />
      <input
        *ngIf="presentationMode"
        type="radio"
        [name]="name"
        [ngModel]="currentValue"
        disabled
        style="width: auto; min-width: 0; margin-top: 0px"
        [class.no-top-margin]="resetTopMargin"
        [value]="value"
      />
      <error-message [control]="model" [show]="showErrors"></error-message>
    </ng-container>
  `,
  providers: [RADIO_ENTITY_CONTROL_VALUE_ACCESSOR],
})
export class RadioEntityComponent implements ControlValueAccessor {
  @Input() control: AbstractControl;
  @Input() disabled: any;
  @Input() readonly: any;
  @Input() presentationMode = false;
  @Input() showErrors: boolean;
  @Input() nullable: boolean; // set null instead of false
  @Input() resetTopMargin = false;
  @Input() name: string;
  @Input() value: any;

  @Output() changeState = new EventEmitter<any>();

  private onChangeListeners: Function;
  public onTouchedListeners: Function;
  public currentValue: any;

  writeValue(obj: any): void {
    this.currentValue = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onChangeItem(event: any) {
    console.log('radio clicked: ', this.value);
    console.log(event);
    this.currentValue = this.value;
    this.onChangeListeners(this.currentValue);
    this.changeState.emit(this.currentValue);
  }
}
