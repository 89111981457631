import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {CustomValidators} from '../../validators';

@Directive({
  selector: '[lastNameFormat]',
  providers: [{provide: NG_VALIDATORS, useExisting: LastNameFormatDirective, multi: true}],
})
export class LastNameFormatDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    return CustomValidators.userFamilyNameFormat(control);
  }
}
