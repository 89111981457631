import {BusinessUnitBaseDto} from './dtos';

export class BusinessUnit {
  static readonly AT: number = 12;
  static readonly BE: number = 1;
  static readonly CH: number = 13;
  static readonly DE: number = 4;
  static readonly ES: number = 7;
  static readonly FR: number = 3;
  static readonly IE: number = 11;
  static readonly IT: number = 5;
  static readonly NL: number = 8;
  static readonly PL: number = 2;

  static readonly KUKE_KOM = 100;
  static readonly KUKE_GSP = 200;
  public static readonly MEHIB_ST = 1000;
  public static readonly MEHIB_MLT = 2000;

  static readonly ECG_COM = 301;
  static readonly ECG_SBS = 302;

  public static isBelgianBU(dict: BusinessUnitBaseDto) {
    return dict && dict.id === BusinessUnit.BE;
  }
}
