import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, Input, forwardRef, OnInit, Output, EventEmitter} from '@angular/core';
import {Month} from '../../model/dtos';

const MONTH_SELECTOR_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MonthSelectorComponent),
  multi: true,
};

@Component({
  selector: 'month-selector',
  template: `
    <div *ngIf="presentationMode" class="bon-input-size">
      <span *ngIf="selectedItem" class="presentation">{{ 'month.' + selectedItem.month | translate }}</span>
    </div>
    <select
      *ngIf="!presentationMode"
      [(ngModel)]="selectedKey"
      (change)="selectedKey = $event.target.value"
      class="bon-select"
      [disabled]="disabled"
    >
      <option *ngFor="let item of items" [value]="item.id">
        <span>{{ 'month.' + item['month'] | translate }}</span>
      </option>
    </select>
  `,
  providers: [MONTH_SELECTOR_CONTROL_VALUE_ACCESSOR],
})
export class MonthSelectorComponent implements ControlValueAccessor, OnInit {
  @Input() disabled: boolean;
  @Input() presentationMode = false;
  @Input() returnval = 'id';
  @Output() change = new EventEmitter<any>();

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  private _selectedKey: any;
  private selectedItem: MonthType;
  private externalValue: any;

  public items: MonthType[] = [
    new MonthType(1, 'January', 'JANUARY'),
    new MonthType(2, 'February', 'FEBRUARY'),
    new MonthType(3, 'March', 'MARCH'),
    new MonthType(4, 'April', 'APRIL'),
    new MonthType(5, 'May', 'MAY'),
    new MonthType(6, 'June', 'JUNE'),
    new MonthType(7, 'July', 'JULY'),
    new MonthType(8, 'August', 'AUGUST'),
    new MonthType(9, 'September', 'SEPTEMBER'),
    new MonthType(10, 'October', 'OCTOBER'),
    new MonthType(11, 'November', 'NOVEMBER'),
    new MonthType(12, 'December', 'DECEMBER'),
  ];

  constructor() {}

  ngOnInit() {
    this.updateSelection();
  }

  get selectedKey(): any {
    return this._selectedKey;
  }

  set selectedKey(k: any) {
    if (k !== this._selectedKey) {
      this._selectedKey = k;
      if (this.returnval === 'id') {
        this.selectedItem = this.findSelectedItemById(k);
        this.onChangeListeners(this.selectedItem.id);
        this.change.emit(this.selectedItem.id);
      }
      if (this.returnval === 'month') {
        this.selectedItem = this.findSelectedItemById(k);
        this.onChangeListeners(this.selectedItem.month);
        this.change.emit(this.selectedItem.month);
      }
      if (this.returnval === 'month-type') {
        this.selectedItem = this.findSelectedItemById(k);
        this.onChangeListeners(this.selectedItem);
        this.change.emit(this.selectedItem);
      }
    }
  }

  // From ControlValueAccessor interface
  writeValue(it: any): void {
    this.externalValue = it;
    this.updateSelection();
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private updateSelection() {
    if (this.externalValue) {
      if (this.returnval === 'id') {
        this.selectedItem = this.findSelectedItemById(this.externalValue);
        this._selectedKey = this.externalValue;
      }
      if (this.returnval === 'month') {
        this.selectedItem = this.findSelectedItemByMonth(this.externalValue);
        this._selectedKey = this.selectedItem.id;
      }
      if (this.returnval === 'month-type') {
        this.selectedItem = this.externalValue;
        this._selectedKey = this.selectedItem.id;
      }
    } else {
      this.selectedItem = undefined;
      this._selectedKey = undefined;
    }
  }

  private findSelectedItemById(k: number): MonthType {
    if (k && this.items) {
      for (const it of this.items) {
        if (String(it.id) === String(k)) {
          return it;
        }
      }
    }
    return undefined;
  }

  private findSelectedItemByMonth(k: Month): MonthType {
    if (k && this.items) {
      for (const it of this.items) {
        if (String(it.month) === String(k)) {
          return it;
        }
      }
    }
    return undefined;
  }
}

export class MonthType {
  id: number;
  name: string;
  month: Month;

  constructor(id: number, name: string, month: Month) {
    this.id = id;
    this.name = name;
    this.month = month;
  }
}
