import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {ServiceContactsComponent} from './service-contacts.component';
import {BusinessObjectInfoModule} from '../business-object-info/business-object-info.module';
import {ServiceContactsGuard} from './_guards/service-contacts.guard';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    BusinessObjectInfoModule,
  ],
  declarations: [ServiceContactsComponent],
  providers: [ServiceContactsGuard],
})
export class ServiceContactsModule {}
