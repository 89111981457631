import {Component, OnInit} from '@angular/core';
import {BrokerGuiService} from '../../services/broker-gui.service';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {PortalRouterService} from '../../../services/portal-router.service';
import {CompanyRawDto, LocalDateRange, QuotationCriteriaDto, QuotationDto} from '../../../../bonding_shared/model/dtos';
import {UserDto} from '../../../../bonding_shared/model';
import {LoggedUserService} from '../../../../bonding_shared';
import {UserRole} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'portal-quotation-search',
  templateUrl: './quotation-search.component.pug',
})
export class QuotationSearchComponent implements OnInit {
  dataProvider: SearchDataProvider<QuotationCriteriaDto, QuotationDto>;

  readonly UserRole = UserRole;

  constructor(
    public loggedUserService: LoggedUserService,
    public router: PortalRouterService,
    private service: BrokerGuiService
  ) {
    this.dataProvider = service.searchDataProvider;
  }

  ngOnInit() {
    if (!this.dataProvider.searchCriteria.criteria) {
      this.initializeCriteria();
    }
  }

  initializeCriteria() {
    this.dataProvider.searchCriteria.criteria = <QuotationCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.expirationDateRange = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.contractValidFromRange = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.contractValidToRange = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.clientRaw = <CompanyRawDto>{};
    // this.dataProvider.searchCriteria.criteria.contract = <Contract>{};
    this.dataProvider.textSearch = false;
    // this.dataProvider.searchCriteria.criteria.activated = true;
  }

  creatorName(broker: UserDto): string {
    let name = '';
    if (broker) {
      if (broker.name) {
        name += broker.name;
      }

      if (broker.familyName) {
        name += ' ' + broker.familyName;
      }
    }

    return name;
  }
}
