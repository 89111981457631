import {Pipe, PipeTransform} from '@angular/core';
import {FormatService} from '../services';

@Pipe({
  name: 'datetime',
})
export class DateTimePipe implements PipeTransform {
  constructor(private formatService: FormatService) {}

  transform(date: Date) {
    return this.formatService.formatDateTime(date);
  }
}
