import {GlobalConditionsOfInsurance, InquiryProductType} from '../../../bonding_shared/model/dictionary-ids';
import {isProductPacked, isShortOffer} from './offer-allowance';
import {DictionaryBaseDto} from '../../../bonding_shared';

export function getInquiryVisibility(inqType: number, portalMode: boolean) {
  console.assert(!!inqType, 'getInquiryVisibility type empty');
  const v: any = {};

  const devMode = false;

  v.russianEmbargo = inqType === InquiryProductType.KUKE_GSP_PNW || devMode;
  v.selfRetention = inqType === InquiryProductType.KUKE_GSP_PNW || devMode;
  v.insurableTurnover =
    inqType === InquiryProductType.KUKE_KOM_OP || inqType === InquiryProductType.KUKE_KOM_SP || devMode;
  v.countries =
    inqType !== InquiryProductType.KUKE_GSP_PBG &&
    inqType !== InquiryProductType.KUKE_GSP_PNS &&
    inqType !== InquiryProductType.KUKE_KOM_EP &&
    inqType !== InquiryProductType.KUKE_KOM_FK;
  v.buyers = inqType === InquiryProductType.KUKE_KOM_OP || inqType === InquiryProductType.KUKE_KOM_SP;
  v.receivables = inqType === InquiryProductType.KUKE_KOM_OP || inqType === InquiryProductType.KUKE_KOM_SP;
  // Na WoP OP / SP / FK / FE / EP
  v.currentInsurer =
    inqType === InquiryProductType.KUKE_KOM_OP ||
    inqType === InquiryProductType.KUKE_KOM_SP ||
    inqType === InquiryProductType.KUKE_KOM_FK ||
    inqType === InquiryProductType.KUKE_KOM_FE ||
    inqType === InquiryProductType.KUKE_KOM_EP ||
    devMode;
  v.lastBalances = inqType === InquiryProductType.KUKE_KOM_SP || devMode;

  v.countriesSumDomestic =
    inqType === InquiryProductType.KUKE_KOM_OP ||
    inqType === InquiryProductType.KUKE_KOM_SP ||
    inqType === InquiryProductType.KUKE_KOM_FK ||
    inqType === InquiryProductType.KUKE_KOM_EP ||
    devMode;
  v.countriesSumExport =
    inqType === InquiryProductType.KUKE_KOM_OP ||
    inqType === InquiryProductType.KUKE_KOM_SP ||
    inqType === InquiryProductType.KUKE_KOM_FE ||
    inqType === InquiryProductType.KUKE_KOM_EP ||
    devMode;
  v.countriesSumLastYear = inqType === InquiryProductType.KUKE_KOM_SP || devMode;
  v.domesticInsurable = inqType !== InquiryProductType.KUKE_GSP_PBG && inqType !== InquiryProductType.KUKE_GSP_PNS;
  v.exportInsurable = inqType !== InquiryProductType.KUKE_KOM_FK;
  v.policyValidFrom = inqType === InquiryProductType.KUKE_KOM_EP;
  v.falconLimitAllowed =
    inqType === InquiryProductType.KUKE_KOM_OP ||
    inqType === InquiryProductType.KUKE_KOM_FK ||
    inqType === InquiryProductType.KUKE_KOM_FE ||
    inqType === InquiryProductType.KUKE_KOM_SP;
  v.divisionIntoFactorers =
    inqType === InquiryProductType.KUKE_KOM_FK || inqType === InquiryProductType.KUKE_KOM_FE || devMode;

  v.subinsureds =
    inqType !== InquiryProductType.KUKE_KOM_EP &&
    inqType !== InquiryProductType.KUKE_KOM_FK &&
    inqType !== InquiryProductType.KUKE_KOM_FE;

  v.statements = {};

  v.statements.isFamiliarizedWithPolishConditions = inqType !== InquiryProductType.KUKE_KOM_EP;
  v.statements.isFamiliarizedWithDocumentaryConditions = inqType !== InquiryProductType.KUKE_KOM_EP;
  v.statements.consenForSharingInformation =
    inqType === InquiryProductType.KUKE_GSP_PBG ||
    inqType === InquiryProductType.KUKE_GSP_PNW ||
    inqType === InquiryProductType.KUKE_GSP_PNS ||
    devMode;
  v.statements.paperInvoice =
    inqType === InquiryProductType.KUKE_KOM_OP ||
    inqType === InquiryProductType.KUKE_KOM_SP ||
    inqType === InquiryProductType.KUKE_KOM_FK ||
    inqType === InquiryProductType.KUKE_KOM_FE ||
    inqType === InquiryProductType.KUKE_KOM_EP ||
    inqType === InquiryProductType.KUKE_GSP_PBG ||
    devMode;

  v.ratedLimits =
    inqType === InquiryProductType.KUKE_KOM_OP ||
    inqType === InquiryProductType.KUKE_KOM_SP ||
    inqType === InquiryProductType.KUKE_KOM_FK ||
    inqType === InquiryProductType.KUKE_KOM_FE ||
    inqType === InquiryProductType.KUKE_GSP_PBG ||
    inqType === InquiryProductType.EXPORT_RECEIVABLES_C ||
    inqType === InquiryProductType.FACTORING_CF ||
    inqType === InquiryProductType.KUKE_KOM_EP ||
    inqType === InquiryProductType.GLOBAL_RISK ||
    inqType === InquiryProductType.SELECTIVE_RISK ||
    devMode;

  v.debtorBuyerUltimateUserSection =
    inqType === InquiryProductType.BUYER_CREDIT_V ||
    inqType === InquiryProductType.SUPPLIER_CREDIT_S ||
    inqType === InquiryProductType.SUPPLIER_CREDIT_DISCOUNTING_KV ||
    inqType === InquiryProductType.TIED_AID_TAI ||
    inqType === InquiryProductType.PRESHIPMENT_RISK_G ||
    devMode;

  v.transactionPartiesSection = inqType === InquiryProductType.FOREIGN_INVESTMENT_B || devMode;

  v.exportersSection =
    inqType === InquiryProductType.BUYER_CREDIT_V ||
    inqType === InquiryProductType.SUPPLIER_CREDIT_DISCOUNTING_KV ||
    inqType === InquiryProductType.TIED_AID_TAI ||
    devMode;

  v.guarantorsSection =
    inqType === InquiryProductType.BUYER_CREDIT_V ||
    inqType === InquiryProductType.SUPPLIER_CREDIT_S ||
    inqType === InquiryProductType.SUPPLIER_CREDIT_DISCOUNTING_KV ||
    inqType === InquiryProductType.FOREIGN_INVESTMENT_B ||
    inqType === InquiryProductType.TIED_AID_TAI ||
    inqType === InquiryProductType.PRESHIPMENT_RISK_G ||
    devMode;

  v.limitFeesSection =
    inqType === InquiryProductType.EXPORT_RECEIVABLES_C ||
    inqType === InquiryProductType.FACTORING_CF ||
    InquiryProductType.GLOBAL_RISK ||
    InquiryProductType.SELECTIVE_RISK ||
    devMode;

  return v;
}

export function getOfferVisibility(typeId: number, gciId: number) {
  console.assert(!!typeId, 'function type empty');
  const v: {[key: string]: boolean} = {};
  const isPacked = isProductPacked(<DictionaryBaseDto>{id: typeId});

  v.fixedPremium = typeId === InquiryProductType.KUKE_KOM_EU || typeId === InquiryProductType.KUKE_KOM_EP;
  v.specialRisks = isPacked;

  v.paymentMethod = isPacked || typeId === InquiryProductType.KUKE_KOM_EP || typeId === InquiryProductType.KUKE_KOM_OE;

  v.paymentMethodInDetails = typeId === InquiryProductType.KUKE_GSP_PBG;

  v.premiumSection = this.isKukeKom();

  v.payableInInstallments =
    typeId !== InquiryProductType.KUKE_GSP_PBG &&
    typeId !== InquiryProductType.KUKE_GSP_PNW &&
    typeId !== InquiryProductType.KUKE_GSP_PNS;

  v.ratedLimits =
    typeId === InquiryProductType.KUKE_KOM_EP ||
    typeId === InquiryProductType.KUKE_GSP_PBG ||
    typeId === InquiryProductType.KUKE_GSP_PNS;

  v.fixedMinPremiums = typeId === InquiryProductType.KUKE_GSP_PNW || typeId === InquiryProductType.KUKE_GSP_PBG;

  // Code disabled in IKI-3798. Code left as it will be moved to policies.
  // v.additionalInfo = !this.offerEnvelope.offer.edge && (isPacked || typeId === InquiryProductType.KUKE_GSP_PBG);

  v.subjectOfInsurance =
    (gciId !== null && gciId === GlobalConditionsOfInsurance.KUKE_OP_2016_01) ||
    (isPacked && typeId !== InquiryProductType.KUKE_KOM_OP);

  v.comment = isPacked || (typeId === InquiryProductType.KUKE_GSP_PBG && this.isEdge());

  v.countriesSums = typeId === InquiryProductType.KUKE_KOM_EP;

  v.turnoverDeclarationSection = isPacked || typeId === InquiryProductType.KUKE_GSP_PBG;

  v.turnoverAmendmentsNonStandard = typeId !== InquiryProductType.KUKE_GSP_PBG;

  v.topUp = this.isLongOffer() || typeId === InquiryProductType.KUKE_GSP_PBG;

  v.productionRisk = typeId === InquiryProductType.KUKE_GSP_PBG;

  v.nql = isLongOffer(typeId) || typeId === InquiryProductType.KUKE_KOM_EP;

  // synchronize with method pl.accuratus.isa.inquiry.model.dict.InquiryProductType#isDeclaredTurnover(int)
  v.declaredTurnover = typeId === InquiryProductType.KUKE_KOM_EP || typeId === InquiryProductType.KUKE_KOM_FK;

  return v;
}

function isLongOffer(typeId: number) {
  return isShortOffer(typeId && <DictionaryBaseDto>{id: typeId}) === false;
}
