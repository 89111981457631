import {DictionaryBaseDto, DictionaryDto, DictionaryIfc} from '../model/dtos';
import {DictionaryPropertyType} from '../model/dictionary-ids';

export class DictionaryUtils {
  static equalsDict(d1: DictionaryIfc, d2: DictionaryIfc): boolean {
    return d1 && d2 ? d1.id === d2.id : !d1 && !d2;
  }

  static equalsDictAndId(dict1: DictionaryIfc, dictId2: number): boolean {
    return dict1 && dictId2 ? dict1.id === dictId2 : !dict1 && !dictId2;
  }

  static in(dict: DictionaryIfc, ...dictIds: number[]): boolean {
    return DictionaryUtils.inArr(dict, dictIds);
  }

  static inArr(dict: DictionaryIfc, dictIds: number[]): boolean {
    return dictIds.some((dictId) => DictionaryUtils.equalsDictAndId(dict, dictId));
  }

  static toBasDto(d: DictionaryDto) {
    return <DictionaryBaseDto>{
      id: d.id,
      code: d.code,
      name: d.name,
      dictName: d.dictName,
      parentId: d.parentId,
    };
  }

  /**
   * Null save method that returns dictionary property.
   */
  static getProperty(
    dictionaryMap: {[index: string]: DictionaryDto},
    dict: DictionaryIfc,
    propertyType: DictionaryPropertyType
  ): string {
    if (!dict || !dict.id || !propertyType) {
      return undefined;
    }
    const dictionaryDto = dictionaryMap[dict.id];
    if (!dictionaryDto) {
      return undefined;
    }
    return dictionaryDto.properties[propertyType];
  }

  /**
   * Null save method that returns if dictionary property exists.
   */
  static hasProperty(
    dictionaryMap: {[index: string]: DictionaryDto},
    dict: DictionaryIfc,
    propertyType: DictionaryPropertyType
  ): boolean {
    const property = this.getProperty(dictionaryMap, dict, propertyType);
    return !!property && property.length > 0;
  }
}
