import {EMPTY, Observable, of, of as observableOf} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {DictionaryBaseDto, DictionaryCriteriaDto, DictionaryDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';
import {Cacheable} from 'ts-cacheable';

@Injectable()
export class DictionaryService extends AbstractService {
  protected url = this.urlPrefix + 'dictionary';

  // Stores data { dictionaryName : Dictionary[] }
  private dictionaries: {[key: string]: DictionaryDto[]} = {};

  // Observables { dictionaryName : Observable<Dictionary[]> } used when someone calls again when call is in progress
  private observables: {[key: string]: Observable<DictionaryDto[]>} = {};

  constructor(public http: HttpClient, appConfigService: AppConfigService, _loggedUserService: LoggedUserService) {
    super(http, appConfigService, _loggedUserService);
  }

  cleanDict() {
    this.dictionaries = {};
  }

  getDictionaryNoCache(dictionaryName: string): Observable<DictionaryDto[]> {
    console.log('loading dictionary: ' + dictionaryName);
    return this.get<DictionaryDto[]>(this.url + '/' + dictionaryName);
  }

  getDictionaryFilteredNoCache(dictionaryName: string, buId: number, profileId: number): Observable<DictionaryDto[]> {
    console.log('loading dictionary: ' + dictionaryName, ', buId = ' + buId);
    let params = '';
    if (buId) {
      params += 'buId=' + buId;
    }
    if (profileId) {
      if (buId) {
        params += '&profileId=' + profileId;
      } else {
        params += 'profileId=' + profileId;
      }
    }
    return this.get<DictionaryDto[]>(this.url + '/' + dictionaryName + '?' + params);
  }

  getDictionaryFilteredByUserRoleNoCache(
    dictionaryName: string,
    buId: number,
    userRoleId: number
  ): Observable<DictionaryDto[]> {
    console.log('loading dictionary: ' + dictionaryName, ', buId = ' + buId);
    let params = '';
    if (buId) {
      params += 'buId=' + buId;
    }
    if (userRoleId) {
      if (buId) {
        params += '&userRoleId=' + userRoleId;
      } else {
        params += 'userRoleId=' + userRoleId;
      }
    }
    return this.get<DictionaryDto[]>(this.url + '/role/' + dictionaryName + '?' + params);
  }

  getDictionaryFiltered(dictionaryName: string, buId: number, profileId: number): Observable<DictionaryDto[]> {
    if (buId || profileId) {
      return this.getDictionaryFilteredNoCache(dictionaryName, buId, profileId);
    }
    return this.getDictionary(dictionaryName);
  }

  getDictionaryByCriteria(criteria: DictionaryCriteriaDto): Observable<DictionaryDto[]> {
    return this.post1<DictionaryCriteriaDto, DictionaryDto[]>(criteria, this.url + '/search');
  }

  getDictionaryFilteredByUserRole(
    dictionaryName: string,
    buId: number,
    userRoleId: number
  ): Observable<DictionaryDto[]> {
    if (buId || userRoleId) {
      return this.getDictionaryFilteredByUserRoleNoCache(dictionaryName, buId, userRoleId);
    }
    return this.getDictionary(dictionaryName);
  }

  getDictionary(dictionaryName: string): Observable<DictionaryDto[]> {
    const dictionary: DictionaryDto[] = this.getDict(dictionaryName);
    if (dictionary) {
      // We have data already
      return observableOf([...dictionary]);
    }
    let observable: Observable<DictionaryDto[]> = this.getObs(dictionaryName);
    if (observable) {
      // Data are in progress;
      return observable;
    }

    // no data, we must fetch it from server;

    observable = this.getDictionaryNoCache(dictionaryName).pipe(
      tap(
        (entries) => {
          this.putDict(dictionaryName, entries);
          this.putObs(dictionaryName, undefined);
        },
        (error) => console.error('Cannot load dictionary ' + dictionaryName, error)
      ),
      share()
    );

    this.putObs(dictionaryName, observable);
    return observable;
  }

  getTypedDictionary<T>(dictionaryName: string): Observable<T[]> {
    console.log('get dictionary: name = ' + dictionaryName);

    return this.get<T[]>(this.url + '/' + dictionaryName);
  }

  @Cacheable({maxCacheCount: 50})
  getDictionaryEntry(
    dictionaryName: string,
    entryId: number,
    propertyCriteriaIds?: number[]
  ): Observable<DictionaryDto> {
    return this.get<DictionaryDto>(
      this.url +
        '/' +
        dictionaryName +
        '/' +
        entryId +
        (propertyCriteriaIds ? '?propertyCriteriaId=' + propertyCriteriaIds.join('&propertyCriteriaId=') : '')
    );
  }

  private putDict(dictionaryName: string, dict: DictionaryDto[]) {
    this.dictionaries[dictionaryName] = dict;
  }

  private getDict(dictionaryName: string): DictionaryDto[] {
    return this.dictionaries[dictionaryName];
  }

  private putObs(dictionaryName: string, obs: Observable<DictionaryDto[]>) {
    this.observables[dictionaryName] = obs;
  }

  private getObs(dictionaryName: string): Observable<DictionaryDto[]> {
    return this.observables[dictionaryName];
  }

  toDictionaryDto(dto: DictionaryBaseDto): Observable<DictionaryDto> {
    if (!dto.id || !dto.dictName) {
      return EMPTY;
    }
    if (this.isDictionaryDto(dto)) {
      return of(dto);
    }
    return this.getDictionary(dto.dictName).pipe(map((all) => all.find((d) => d.id === dto.id)));
  }

  isDictionaryDto(d: DictionaryBaseDto): d is DictionaryDto {
    // even if no property is set, there should be empty object
    return 'properties' in d;
  }

  customFields(clauseTypeId: number, all = true): Observable<DictionaryDto[]> {
    return this.get<DictionaryDto[]>(this.url + '/customFields/clause/' + clauseTypeId + '?all=' + all);
  }

  getDictionaryBusinessUnitIds(dictionaryId: number): Observable<number[]> {
    return this.get<number[]>(this.url + '/businessUnits/' + dictionaryId);
  }
}
