import {ChartDataset} from 'chart.js';
import {ChartData} from './chart-data';
import {ChartUtils} from '../chart-utils';

export class SalesLeadChartData extends ChartData {
  rightAxis = false;

  newLeads: number[];
  closedLeads: number[];
  openLeads: number[];
  convertedLeads: number[];

  colors = [
    {backgroundColor: ChartUtils.COLORS[0]},
    {backgroundColor: ChartUtils.COLORS[2]},
    {backgroundColor: ChartUtils.COLORS[1]},
    {backgroundColor: ChartUtils.COLORS[4]},
  ];

  buildChartData(): ChartDataset[] {
    return [
      {data: this.newLeads, label: 'New leads', yAxisID: 'yAxisLeft'},
      {data: this.closedLeads, label: 'Closed leads', yAxisID: 'yAxisLeft'},
      {data: this.openLeads, label: 'Open leads', yAxisID: 'yAxisLeft'},
      {data: this.convertedLeads, label: 'Converted leads', yAxisID: 'yAxisLeft'},
    ];
  }

  clear() {
    this.newLeads = [];
    this.closedLeads = [];
    this.openLeads = [];
    this.convertedLeads = [];
    this.dates = [];
  }

  mapData(results: Array<any>) {
    if (!results) {
      return;
    }
    results.forEach((result) => {
      this.dates.push(result.month + '-' + result.year);
      this.newLeads.push(<any>result.newLeads);
      this.closedLeads.push(<any>result.closedLeads);
      this.openLeads.push(<any>result.openLeads);
      this.convertedLeads.push(<any>result.convertedLeads);
    });
  }
}
