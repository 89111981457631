import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {
  ClaimRepaymentScheduleVersionBaseDto,
  ClaimRepaymentScheduleVersionCriteriaDto,
  ClaimRepaymentScheduleVersionDto,
  SearchCriteria,
  StateTransitionDto,
} from '../model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ClaimRepaymentScheduleVersionService extends AbstractService {
  protected url = this.urlPrefix + 'claim/repaymentSchedule';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  findClaimRepaymentScheduleVersions(repaymentScheduleId: number) {
    const crit = <SearchCriteria<ClaimRepaymentScheduleVersionCriteriaDto>>{
      criteria: {parent: {id: repaymentScheduleId}},
    };
    return this.searchByCriteria(crit).pipe(map((x) => <ClaimRepaymentScheduleVersionBaseDto[]>x.result));
  }

  getLastOrInitVersion(claimId: number): Observable<ClaimRepaymentScheduleVersionDto> {
    return this.get(this.url + '/getLastOrInitVersion?claimId=' + claimId);
  }

  getTransitions(repaymentScheduleVersionDto: ClaimRepaymentScheduleVersionDto): Observable<Array<StateTransitionDto>> {
    return this.get<StateTransitionDto[]>(this.url + '/transition/' + repaymentScheduleVersionDto.id);
  }
}
