import {Injectable} from '@angular/core';
import {AppConfigService} from '../../bonding_shared/services/app-config.service';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {AbstractService} from '../../bonding_shared/services/abstract.service';
import {
  MasterPolicyContractDto,
  MasterPolicyContractForLimitDto,
  Page,
  PolicyContractDto,
  PolicyContractPreviewDto,
  PolicyContractTechnicalDetailsAttendantsDto,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionDto,
  PolicyContractVersionSimpleDto,
  SearchCriteria,
  SearchResult,
  SortBy,
} from '../../bonding_shared/model/dtos';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PortalPolicyContractVersionService extends AbstractService {
  public clientPoliciesList: PolicyContractVersionSimpleDto[] = [];

  protected url = this.urlPrefix + 'portal/policyContractVersion';

  constructor(
    public http: HttpClient,
    public appConfigService: AppConfigService,
    loggedUserService: LoggedUserService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getClientPolicies(): Observable<SearchResult<PolicyContractVersionSimpleDto>> {
    const criteria = <SearchCriteria<PolicyContractVersionCriteriaDto>>{};
    criteria.criteria = <PolicyContractVersionCriteriaDto>{};
    criteria.criteria.policyYearVersionPhase = 'LAST';
    criteria.criteria.versionPhase = 'LAST';
    criteria.page = <Page>{start: 0, count: 300};
    criteria.sortBy = <SortBy>{column: 'mpc.number'};
    return this.searchByCriteria<PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto>(criteria);
  }

  getClientLastPolicies(active: boolean, count: number): Observable<PolicyContractVersionSimpleDto[]> {
    return this.get<PolicyContractVersionSimpleDto[]>(this.url + '/last/' + count + '/' + active);
  }

  getMasterPolicyContract(id: number): Observable<MasterPolicyContractDto> {
    console.log('master policy contract details: id = ' + id);
    return this.get<MasterPolicyContractDto>(this.url + '/masterPolicyContract/' + id);
  }

  getPolicyContract(id: number): Observable<PolicyContractDto> {
    console.log('contract details: id = ' + id);
    return this.get<PolicyContractDto>(this.url + '/policyContract/' + id);
  }

  getPolicyContractVersion(id: number): Observable<PolicyContractVersionDto> {
    console.log('policy contract version details: id = ' + id);
    return this.get<PolicyContractVersionDto>(this.url + '/' + id);
  }

  getPreview(id: number): Observable<PolicyContractPreviewDto> {
    return this.get<PolicyContractPreviewDto>(this.url + '/preview/' + id);
  }

  findMasterPolicyForClientAndCountry(countryId: number): Observable<MasterPolicyContractForLimitDto> {
    console.log('findMasterPolicyForClientAndCountry: countryId = ' + countryId);
    return this.get<MasterPolicyContractForLimitDto>(
      this.url + '/masterPolicyContractForClient?countryId=' + countryId
    );
  }

  getTechnicalAttendants(id: number): Observable<PolicyContractTechnicalDetailsAttendantsDto> {
    console.log('policy contract version details: id = ' + id);
    return this.get<PolicyContractTechnicalDetailsAttendantsDto>(this.url + '/policyContractAttendants/' + id);
  }
}
