import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfigService, LoggedUserService} from '../../bonding_shared/services';
import {ReportingPeriodDto} from '../../bonding_shared/model';
import {HttpClient} from '@angular/common/http';
import {DeclarationAbstractService} from '../../bonding_shared/services/declaration-abstract.service';

@Injectable()
export class PortalDeclarationService extends DeclarationAbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'declaration';
  }

  isAmendmentPossible(reportingPeriodId: number): Observable<boolean> {
    return this.get<boolean>(this.url + '/isAmendmentPossible?reportingPeriodId=' + reportingPeriodId);
  }

  findClientRPsForDeclarationAmendments(policyContractId: number): Observable<ReportingPeriodDto[]> {
    return this.get<ReportingPeriodDto[]>(
      this.url + '/findClientRPsForDeclarationAmendments?policyContractId=' + policyContractId
    );
  }
}
