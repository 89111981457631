import {Injectable} from '@angular/core';
import {AppConfigService} from '../../bonding_shared/services/app-config.service';
import {AbstractLoginService} from '../../login/login.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class LoginPortalService extends AbstractLoginService {
  protected url = this.urlPrefix + 'portal/login';

  constructor(public http: HttpClient, appConfigService: AppConfigService) {
    super(http, appConfigService, null);
  }
}
