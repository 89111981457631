import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {StringUtils} from '../../utils';

@Directive({
  selector: '[minValue]',
  providers: [{provide: NG_VALIDATORS, useExisting: MinValueDirective, multi: true}],
})
export class MinValueDirective implements Validator {
  @Input('minValue') minValue: number;

  validate(control: AbstractControl): ValidationErrors | null {
    const v = control.value;
    if (StringUtils.isNull(v) || StringUtils.isNull(this.minValue)) {
      return null;
    }
    if (v < this.minValue) {
      return {minValue: {minValue: this.minValue, actualValue: v, requiredValue: this.minValue}};
    } else {
      return null;
    }
  }
}
