/**
 * Service for common logic of policy contract components.
 *
 */
import {Injectable} from '@angular/core';
import {PolicyContractVersionService, SearchDataProvider} from '../../../bonding_shared/services';
import {
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionDto,
  PolicyCreationInfoDto,
} from '../../../bonding_shared/model';

@Injectable()
export class PolicyContractGuiService {
  /**
   * Keeps selected criteria for contract search view
   */
  searchDataProvider: SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionDto>;
  justCreatedPolicy: PolicyCreationInfoDto;

  constructor(private service: PolicyContractVersionService) {
    this.searchDataProvider = new SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionDto>(
      service
    );
  }

  createDataProvider(): SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionDto> {
    return new SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionDto>(this.service);
  }
}
