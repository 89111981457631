import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {AppConfigService, RouterService} from '../../../../bonding_shared/services';
import {ExternalProviderGuiService, SearchDataProviderType} from '../../services/external-provider-gui.service';
import {CompanySimpleDto, DictionaryBaseDto, ExternalProviderVersionBaseDto} from '../../../../bonding_shared/model';
import {Subject} from 'rxjs';
import {TabView} from 'primeng/tabview';
import {ListEmitters} from '../../../../bonding_shared/components/details-view/list-emitters';
import {ChangePasswordComponent} from '../../../../bonding_shared/components/user/change-password.component';

@Component({
  selector: 'external-provider-selector',
  templateUrl: './external-provider-selector.component.pug',
})
export class ExternalProviderSelectorComponent extends ListEmitters implements OnInit, AfterViewInit {
  @ViewChild(TabView, {static: true}) private tabView: TabView;

  dataProvider: SearchDataProviderType;
  @Input() open: Subject<boolean>;
  @Input() allowCompanyAsProvider = true;
  @Input() allowExternalProviderAsProvider = true;

  @Input() set typeId(typeId: number) {
    this.dataProvider.searchCriteria.criteria.parent.type = <DictionaryBaseDto>{id: typeId};
    this.typeSelectionDisabled = true;
  }

  typeSelectionDisabled = false;
  @Output() selectItem = new EventEmitter<ExternalProviderVersionBaseDto>();
  @Output() selectCompany = new EventEmitter<CompanySimpleDto>();
  visible = false;

  constructor(
    public router: RouterService,
    public guiService: ExternalProviderGuiService,
    private renderer: Renderer2,
    private appService: AppConfigService
  ) {
    super();
    this.selectorNameList = ['Company'];
    this.initializeSelectorEmitters(true);
    this.dataProvider = guiService.getDataProvider();
  }

  ngAfterViewInit(): void {
    this.renderer.removeClass(this.tabView.el.nativeElement.firstChild, 'ui-widget');
  }

  ngOnInit(): void {
    if (this.open) {
      this.open.subscribe((event: boolean) => {
        this.visible = event;
      });
    }
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }
}
