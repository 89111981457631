import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {FileImportDto, ParameterDefinitionDto} from '../model/dtos';
import {BinaryDownloaderService} from './binary-downloader.service';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable()
export class ImportService extends AbstractService {
  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    public binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'import';
  }

  getParameters(typeId) {
    return this.get<ParameterDefinitionDto[]>(this.url + '/parameters/' + typeId);
  }

  upload(file: FileImportDto): Observable<FileImportDto> {
    console.log('upload file:', file);
    return this.post<FileImportDto>(file, this.url + '/upload');
  }

  generateTemplate(
    typeId: number,
    outputFileName: string,
    errorCallback: (errMsg: string) => void,
    completeCallback: (f: File) => void
  ) {
    console.log('generateTemplate ', typeId);
    this.binaryDownloader.download(
      'GET',
      this.url + '/template/' + typeId,
      'application/json',
      'application/octet-stream',
      outputFileName,
      undefined,
      errorCallback,
      completeCallback
    );
  }

  downloadFile(
    fileId: number,
    input: boolean,
    outputFileName: string,
    errorCallback: (errMsg: string) => void,
    completeCallback: () => void
  ) {
    console.log('downloadFile ', fileId);
    this.binaryDownloader.download(
      'GET',
      this.url + '/file/' + fileId + '?input=' + input,
      'application/json',
      'application/octet-stream',
      outputFileName,
      undefined,
      errorCallback,
      completeCallback
    );
  }
}
