import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {ChangePasswordComponent} from './change-password.component';
import {SharedComponentsModule} from '../shared-components.module';
import {TranslateModule} from '@ngx-translate/core';
import {ChangeAbsenceComponent} from './change-absence.component';
import {FrontReloadWarningComponent} from '../topnav/front-reload-warning.component';

@NgModule({
  imports: [RouterModule, BrowserModule, ReactiveFormsModule, SharedComponentsModule, TranslateModule],
  declarations: [ChangeAbsenceComponent, ChangePasswordComponent, FrontReloadWarningComponent],
  exports: [ChangeAbsenceComponent, ChangePasswordComponent, FrontReloadWarningComponent],
  providers: [],
})
export class SharedUserModule {}
