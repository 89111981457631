import {
  DictionaryBaseDto,
  PolicyInquiryOfferDto,
  PolicyInquiryOfferSimpleDto,
  PolicyInquiryVersionDto,
  PolicyInquiryVersionSimpleDto,
  UserGroupBaseDto,
} from '../../../bonding_shared';
import {
  InquiryProductType,
  InquiryProductTypes,
  PolicyInquiryOfferStatus,
  UserGroupType,
} from '../../../bonding_shared/model/dictionary-ids';
import {Observable} from 'rxjs';

export class ClientOfferAllowance {
  static isClientOfferAllowed(ofeType: DictionaryBaseDto): boolean {
    if (!ofeType || !ofeType.id) {
      return false;
    }
    const tid = ofeType.id;
    return (
      tid === InquiryProductType.KUKE_KOM_OP ||
      tid === InquiryProductType.KUKE_KOM_SP ||
      tid === InquiryProductType.KUKE_KOM_FK ||
      tid === InquiryProductType.KUKE_KOM_FE ||
      tid === InquiryProductType.KUKE_GSP_PBG
    );
  }

  static isOfferAllowed(ofeType: DictionaryBaseDto, offersNotEmpty: boolean, isAnnex: boolean): boolean {
    return (
      isNotType(ofeType, InquiryProductType.KUKE_KOM_EP) &&
      (isNotType(ofeType, InquiryProductType.KUKE_GSP_PBG) || offersNotEmpty || isAnnex)
    );
  }
}

export function isType(ofeType: DictionaryBaseDto, typeId: InquiryProductType): boolean {
  return ofeType && ofeType.id === typeId;
}

export function isNotType(ofeType: DictionaryBaseDto, typeId: InquiryProductType): boolean {
  return isType(ofeType, typeId) === false;
}

export function isStandardEditable(businessStatus: PolicyInquiryOfferStatus) {
  return (
    businessStatus === PolicyInquiryOfferStatus.FOR_DECISION ||
    businessStatus === PolicyInquiryOfferStatus.NEW ||
    businessStatus === PolicyInquiryOfferStatus.REJESTERED ||
    businessStatus === PolicyInquiryOfferStatus.REJECTED_BY_INSURER
  );
}

export function isNarrowEditable(businessStatus: PolicyInquiryOfferStatus, ofeType: DictionaryBaseDto) {
  return businessStatus === PolicyInquiryOfferStatus.ACTIVE && isType(ofeType, InquiryProductType.KUKE_KOM_EP);
}

export function canCalculate(
  offer: PolicyInquiryOfferDto,
  isExistsLimitRequest: Observable<boolean>
): Observable<boolean> {
  const _can =
    offer &&
    offer.id &&
    offer.edge &&
    offer.type &&
    isNotType(offer.type, InquiryProductType.KUKE_GSP_PBG) &&
    (areClausesAvailable(offer.type) === false || isClauseExists(offer) === true || offer.noClauses === true);

  if (
    _can &&
    isProductPacked(offer && offer.type) &&
    offer.policyInquiryVersion.policyInquiry.inquiryType === 'POLICY_INQUIRY'
  ) {
    return isExistsLimitRequest;
  }

  return Observable.create((observer) => observer.next(_can));
}

export function areClausesAvailable(ofeType: DictionaryBaseDto) {
  return isNotType(ofeType, InquiryProductType.KUKE_KOM_EP);
}

export function isClauseExists(offer: PolicyInquiryOfferDto) {
  const clauses = offer && offer.clauses;
  return clauses && clauses.length > 0;
}

export function isProductPacked(ofeType: DictionaryBaseDto) {
  return ofeType && ofeType.id && InquiryProductTypes.KUKE_PRODUCT_PACKED.includes(ofeType.id);
}

export function isShortOffer(ofeType: DictionaryBaseDto) {
  return ofeType && ofeType.id && InquiryProductTypes.KUKE_SHORT.includes(ofeType.id);
}

const USER_GROUPS_TYPES = {
  ALL: [UserGroupType.POLICY_OFFER_VOTING],
  RISK: [UserGroupType.POLICY_OFFER_VOTING, UserGroupType.RISK_POLICY_OFFER_VOTING],
};

export function votingGroupFilter(
  ofeType: DictionaryBaseDto
): (value: UserGroupBaseDto, index: number, array: UserGroupBaseDto[]) => boolean {
  const groupTypes = getUserGroupTypes(ofeType);
  return (group) => group.active && groupTypes.indexOf(group.type.id) > -1;
}

function getUserGroupTypes(ofeType: DictionaryBaseDto): ReadonlyArray<UserGroupType> {
  if (isRiskVoting(ofeType)) {
    return USER_GROUPS_TYPES.RISK;
  }
  return USER_GROUPS_TYPES.ALL;
}

function isRiskVoting(ofeType: DictionaryBaseDto) {
  return isType(ofeType, InquiryProductType.SINGLE_RISK) || isType(ofeType, InquiryProductType.TURNOVER);
}

export function isInitialOffer(offer: PolicyInquiryOfferDto): boolean {
  const parent = offer.parent;
  if (parent && parent.policyInquiryVersion) {
    return isInitialInquiry(parent.policyInquiryVersion);
  }
  if (offer.policyInquiryVersion) {
    return isInitialInquiry(offer.policyInquiryVersion);
  }
  return false;
}

export function isInitialInquiry(inq: PolicyInquiryVersionSimpleDto): boolean {
  return inq && inq.policyInquiry && inq.policyInquiry.inquiryType === 'POLICY_INQUIRY';
}

export function isAnnexOffer(offer: PolicyInquiryOfferDto): boolean {
  const parent = offer.parent;
  if (parent && parent.policyInquiryVersion) {
    return isAnnexInquiry(parent.policyInquiryVersion);
  }
  if (offer.policyInquiryVersion) {
    return isAnnexInquiry(offer.policyInquiryVersion);
  }
  return false;
}

export function isAnnexInquiry(inq: PolicyInquiryVersionSimpleDto): boolean {
  return inq && inq.policyInquiry && inq.policyInquiry.inquiryType === 'POLICY_ANNEX';
}

export function isRenewalOffer(offer: PolicyInquiryOfferDto): boolean {
  const parent = offer.parent;
  if (parent && parent.policyInquiryVersion) {
    return isRenewalInquiry(parent.policyInquiryVersion);
  }
  if (offer.policyInquiryVersion) {
    return isRenewalInquiry(offer.policyInquiryVersion);
  }
  return false;
}

export function isRenewalInquiry(inq: PolicyInquiryVersionSimpleDto): boolean {
  return inq && inq.policyInquiry && inq.policyInquiry.inquiryType === 'RENEWAL_INQUIRY';
}

export function isRegistrationCompletedAvailable(portalMode: boolean, inquiry: PolicyInquiryVersionDto) {
  return portalMode && inquiry && inquiry.salesPerson && !inquiry.limitsRegistrationCompleted;
}
