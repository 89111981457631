import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {CustomValidators} from '../../validators';
import {ValueWithMultiplier} from './shared/value-with-multiplier';

@Directive({
  selector:
    // tslint:disable-next-line:max-line-length
    ':not([type=checkbox])[maxValue][formControlName],:not([type=checkbox])[maxValue][formControl],:not([type=checkbox])[maxValue][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: MaxValueDirective, multi: true}],
})
export class MaxValueDirective implements Validator {
  private _maxValue: number | ValueWithMultiplier;
  private _onChange?: () => void;

  /**
   * @description
   * Tracks changes to the maxValue attribute bound to this directive.
   */
  @Input()
  get maxValue() {
    return this._maxValue;
  }

  set maxValue(value: number | ValueWithMultiplier) {
    this._maxValue = value;
    if (this._onChange) {
      this._onChange();
    }
  }

  /**
   * Method that validates whether the control is empty.
   * Returns the validation result if enabled, otherwise null.
   * @nodoc
   */
  validate(control: AbstractControl): ValidationErrors | null {
    const f =
      this.maxValue instanceof ValueWithMultiplier
        ? CustomValidators.maxValue(this.maxValue.value, this.maxValue.baseMultiplier)
        : CustomValidators.maxValue(this.maxValue);
    return f(control);
  }

  /**
   * Registers a callback function to call when the validator inputs change.
   * @nodoc
   */
  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }
}
