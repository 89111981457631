import {StringUtils} from '../utils/string-utils';
import {Pipe, PipeTransform} from '@angular/core';
import {PhysicalPersonDto, UserIdDto} from '../model/dtos';

@Pipe({
  name: 'username',
})
export class UserNamePipe implements PipeTransform {
  transform(user: UserIdDto | PhysicalPersonDto) {
    return StringUtils.userName(user);
  }
}
