import {Component, OnInit} from '@angular/core';
import {
  DictionaryBaseDto,
  FileImportSimpleDto,
  DeclarationFileImportDto,
  DeclarationMetadataDto,
  DeclarationCriteriaDto,
  SearchResult,
} from '../../bonding_shared/model';
import {PortalPolicyContractService, PortalPolicyContractVersionService, PortalDeclarationService} from '../services';
import {TranslateService} from '@ngx-translate/core';
import {
  AppConfigService,
  FormatService,
  GrowlService,
  LimitListType,
  PolicyLimitListService,
  RouterService,
} from '../../bonding_shared/services';
import {ActivatedRoute} from '@angular/router';
import {ImportType} from '../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {DeclarationParams} from '../../bonding/declaration/shared/declaration-details-view';
import {DeclarationImportDetailsView} from '../../bonding/declaration/shared/declaration-import-details-view';
import {DeclarationFileService} from '../../bonding_shared/services/declaration-file.service';

@Component({
  selector: 'client-amendment-import',
  templateUrl: './client-amendment-import.component.pug',
})
export class ClientAmendmentImportComponent extends DeclarationImportDetailsView implements OnInit {
  constructor(
    private route: ActivatedRoute,
    protected appService: AppConfigService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected portalDeclarationService: PortalDeclarationService,
    protected declarationFileService: DeclarationFileService,
    protected portalPolicyService: PortalPolicyContractVersionService,
    public policyContractService: PortalPolicyContractService,
    private policyLimitListService: PolicyLimitListService,
    private formatService: FormatService,
    private routerService: RouterService
  ) {
    super(appService, translateService, growlService);
  }

  private static getRelatedImportFileTypes() {
    return [
      <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_INTRANET_AMENDMENT},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_PORTAL_AMENDMENT},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_NN_INTRANET_AMENDMENT},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_NN_PORTAL_AMENDMENT},
    ];
  }

  protected initializeImportDeclaration() {
    this.initializeFileDataProvider(this.declarationFileService);
    this.importDeclaration = <DeclarationFileImportDto>{
      type: <DictionaryBaseDto>{id: this.getImportTypeId()},
      metadata: <DeclarationMetadataDto>{},
    };
  }

  private initializeFileDataProvider(service: DeclarationFileService) {
    this.fileDataProvider = BusinessUtils.createFileImportDataProvider(service);
    this.fileDataProvider.searchCriteria.criteria.types = ClientAmendmentImportComponent.getRelatedImportFileTypes();
  }

  private getImportTypeId(): number {
    if (this.dcComponent && this.dcComponent.nnPolicy) {
      return ImportType.TURNOVER_KUKE_WITH_NN_PORTAL_AMENDMENT;
    } else {
      return ImportType.TURNOVER_KUKE_PORTAL_AMENDMENT;
    }
  }

  ngOnInit() {
    if (this.appService.kuke) {
      this.route.params.subscribe((params) => {
        const dcParams: DeclarationParams = <DeclarationParams>{
          paramRpId: +params['reportingPeriodId'],
          paramLlvId: +params['limitListVersionId'],
          paramDecOnId: +params['declaredOnId'],
        };
        this.amendmentView = true;
        this.dcComponent.initDeclarationImporterByPortal(true, dcParams);
      });
    }
  }

  onDeclarationCriteriaTouchedEvent() {
    this.inProgress_flag = true;
    this.confirmedDeclarationForSelectedParams = undefined;
    this.dcComponent.amendmentBlocked_flag = undefined;
    this.fileTable.handleSearchResult(<SearchResult<DeclarationFileImportDto>>{});
  }

  onDeclarationCriteriaChangedEvent(reloadDeclaration: boolean) {
    this.resetGenerateCriteria();
    this.portalDeclarationService.isAmendmentPossible(this.dc.selectedRp.id).subscribe({
      next: (possible) => (this.dcComponent.amendmentBlocked_flag = !possible),
      error: (e) => {
        console.error(e);
        this.growlService.error('declaration.importer.amendmentPossibleError');
      },
      complete: () => this.refreshFileTable(),
    });
  }

  // ############## FILE MANAGEMENT ##############
  amendmentBlocked() {
    return this.dcComponent.amendmentBlocked_flag;
  }

  generateLimitListFile() {
    this.importDeclaration.type = <DictionaryBaseDto>{id: this.getImportTypeId()};
    this.inProgress_flag = true;
    const fileName = [
      'obroty korekta',
      this.dc.selectedPolicy.number,
      this.dc.selectedRp.id,
      this.dc.selectedLlv.id,
      this.formatService.formatDateTime(new Date()),
    ].join(' ');
    this.declarationFileService.generateLimitListFile(
      this.generateCriteria.criteria,
      LimitListType.AMENDMENT,
      fileName + '.xlsx',
      (errorMsg) => this.errorCallback(errorMsg),
      (file) => this.exportCompleteCallback(file)
    );
  }

  downloadFile(file: FileImportSimpleDto, input: boolean) {
    this.declarationFileService.downloadFile(
      file.id,
      input,
      input ? file.fileName : file.outputFileName,
      (errorMsg) => this.errorCallback(errorMsg),
      () => this.downloadCompleteCallback(input ? file.fileName : file.outputFileName)
    );
  }

  public importAmendmentByFile() {
    this.importDeclaration.type = <DictionaryBaseDto>{id: this.getImportTypeId()};
    this.clearErrors();
    if (!this.selectedFile) {
      this.growlService.error('No file selected!');
      return;
    }
    this.inProgress = true;
    this.importDeclaration.metadata.rpId = this.dc.selectedRp.id;
    this.importDeclaration.metadata.llvId = this.dc.selectedLlv.id;
    this.importDeclaration.metadata.decOnId = this.dc.selectedDecOn.id;
    this.importDeclaration.metadata.amendment = true;
    this.importDeclaration.type = <DictionaryBaseDto>{id: this.getImportTypeId()};
    this.declarationFileService.importTurnoverByFile(this.importDeclaration).subscribe({
      next: () => {
        this.inProgress = false;
        this.showSavedMsg();
      },
      error: (error) => this.handleServerError(error),
      complete: () => {
        this.selectedFile = undefined;
        this.refreshFileTable();
      },
    });
  }

  // ############## SEARCH CRITERIA ##############

  protected resetGenerateCriteriaByIds(rpId: number, llvId: number, decOnId: number, finishInProgress = false) {
    this.generateCriteria.criteria = <DeclarationCriteriaDto>{
      reportingPeriodId: rpId,
      limitListVersionId: llvId,
      declaredOnId: decOnId,
    };

    // set proper values of amendmentImport and inProgress_flag flags
    if (finishInProgress) {
      this.inProgress_flag = false;
    }
  }

  refreshFileTable() {
    this.fileDataProvider.searchCriteria.criteria.types = ClientAmendmentImportComponent.getRelatedImportFileTypes();
    super.refreshFileTable();
  }
}
