import {Component} from '@angular/core';
import {AppConfigService, PropertyService} from '../../services/index';

@Component({
  selector: 'property-list',
  templateUrl: './property-list.component.pug',
})
export class PropertyListComponent {
  appProperties: {[index: string]: any};
  errorMessage: string;

  constructor(propertyService: PropertyService, appConfigService: AppConfigService) {
    this.appProperties = propertyService.properties;
  }
}
