import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  ATableComponent,
  CessionBaseDto,
  CessionVersionBaseDto,
  CessionVersionCriteriaDto,
  ContractVersionDto,
  ContractVersionSimpleDto,
  RouterService,
  SearchDataProvider,
} from '../../../../bonding_shared';

@Component({
  selector: 'policy-cession-version-list',
  templateUrl: './policy-cession-version-list.component.pug',
})
export class PolicyCessionVersionListComponent {
  @ViewChild(ATableComponent, {static: true}) cessionTable: ATableComponent<CessionVersionBaseDto>;
  @Input() selection: boolean;
  @Input() canUnselect: boolean;

  @Input() showNumberCol: boolean;
  @Input() showVersionNumberCol: boolean;
  @Input() showCompanyCol: boolean;
  @Input() showCreationData = true;

  @Output() selectItem = new EventEmitter<CessionVersionBaseDto>();
  @Output() refresh = new EventEmitter<void>();

  @Input() set dataProvider(dp: SearchDataProvider<CessionVersionCriteriaDto, CessionVersionBaseDto>) {
    this.cessionTable.dataProvider = dp;
    this.search();
  }

  @Input() selectedCessionVersion: CessionVersionBaseDto;

  constructor(private routerService: RouterService) {}

  search() {
    if (this.cessionTable) {
      this.cessionTable.search();
    }
  }

  onClickCessionNumber(cessionVersion: CessionVersionBaseDto) {
    this.routerService.toCessionDetails(cessionVersion.id);
  }

  onClickBeneficiaryName(cessionVersion: CessionVersionBaseDto) {
    this.routerService.toCompanyPreview(cessionVersion.cession.assignee.id);
  }

  selectPreviousItem() {
    this.selectedCessionVersion = this.cessionTable.items.find(
      (item) => item.versionNumber === this.selectedCessionVersion.versionNumber - 1
    );
    this.selectItem.emit(this.selectedCessionVersion);
  }
}
