import {Injectable} from '@angular/core';
import {BusinessPropertiesService} from './business-properties.service';
import {DictionaryBaseDto} from '../model';

@Injectable()
export class CurrencyExchangeRateService {
  systemCurrency: DictionaryBaseDto;
  currencyExchangeRates: {[index: string]: number};

  constructor(private businessPropertiesService: BusinessPropertiesService) {
    this.businessPropertiesService.getProperties().subscribe((bp) => {
      this.systemCurrency = bp.systemCurrency;
      this.currencyExchangeRates = bp.currencyExchangeRates;
    });
  }

  // value in system currency // or optionally in target currency
  calculate(value: number, originCurrencyId: number, targetCurrencyId?: number): number {
    if (value === 0 || originCurrencyId === targetCurrencyId) {
      return value;
    }
    let convertedValue = this.fromOriginCurrency(value, originCurrencyId);
    if (targetCurrencyId) {
      convertedValue = this.toTargetCurrency(convertedValue, targetCurrencyId);
    }
    return convertedValue;
  }

  fromOriginCurrency(value: number, originCurrencyId: number): number {
    if (originCurrencyId === this.systemCurrency.id) {
      return value;
    }
    return this.currencyExchangeRates && this.currencyExchangeRates[originCurrencyId] && value
      ? value / this.currencyExchangeRates[originCurrencyId]
      : undefined;
  }

  toTargetCurrency(value: number, targetCurrencyId: number): number {
    if (targetCurrencyId === this.systemCurrency.id) {
      return value;
    }
    return this.currencyExchangeRates && this.currencyExchangeRates[targetCurrencyId] && value
      ? value * this.currencyExchangeRates[targetCurrencyId]
      : undefined;
  }
}
