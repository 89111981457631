import {Directive, Input, ViewContainerRef} from '@angular/core';
import {CustomFieldValueDto} from '../../bonding_shared';

@Directive({
  selector: '[adHost]',
})
export class AdDirective {
  @Input() adHost: CustomFieldValueDto = null;

  constructor(public viewContainerRef: ViewContainerRef) {}
}
