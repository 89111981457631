import {map} from 'rxjs/operators';
import {Component, Input} from '@angular/core';
import {
  BusinessPropertiesService,
  DashboardService,
  DictionaryBaseDto,
  SettingsService,
  StringUtils,
} from '../../../../bonding_shared';
import {ApplicationModule} from '../../../../bonding_shared/model/dictionary-ids';
import {ChartComponent} from '../chart-component';
import {ChartRestLoader} from '../chart-rest-loader';
import {ChartConfiguration, ChartData, ChartType} from 'chart.js';
import {ChartUtils} from '../chart-utils';

@Component({
  selector: 'pie-chart-limit',
  templateUrl: 'piechart-limit.component.pug',
})
export class PieChartLimitComponent extends ChartComponent implements ChartRestLoader {
  @Input() set businessUnit(businessUnit: DictionaryBaseDto) {
    this._businessUnit = businessUnit;
    this.initialized = false;
    this.initChartOptions();
    this.loadRestData();
  }
  _businessUnit: DictionaryBaseDto;
  codes: string[] = [];
  exposure: number[] = [];
  exposurePercent: number[] = [];
  public pieChartData: ChartData<'pie', number[], string | string[]>;
  public pieChartType: ChartType = 'pie';
  initialized = false;

  public pieChartOptions: ChartConfiguration['options'];

  constructor(
    private service: DashboardService,
    public businessProps: BusinessPropertiesService,
    public settingsService: SettingsService
  ) {
    super(businessProps, settingsService);
  }

  loadData() {
    this.pieChartData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            ChartUtils.COLORS[2],
            ChartUtils.COLORS[1],
            ChartUtils.COLORS[0],
            ChartUtils.COLORS[3],
            ChartUtils.COLORS[4],
            ChartUtils.COLORS[5],
            ChartUtils.COLORS[6],
            ChartUtils.COLORS[7],
            ChartUtils.COLORS[8],
            ChartUtils.COLORS[9],
            ChartUtils.COLORS[10],
            ChartUtils.COLORS[11],
            ChartUtils.COLORS[12],
          ],
        },
      ],
    };
    for (let i = 0; i < this.codes.length; i++) {
      this.pieChartData.labels.push(this.codes[i]);
      this.pieChartData.datasets[0].data.push(this.exposure[i]);
    }
    this.initialized = true;
  }

  initChartOptions() {
    const currency = this.currency;
    this.pieChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const label = context.label || '';
              const value = <number>context.dataset.data[context.dataIndex];
              const sum = (<number[]>context.dataset.data).reduce((a, b) => a + b, 0);
              const ratio = value / sum;
              const valueF = StringUtils.formatNumber(value);
              const ratioF = StringUtils.formatNumber(ratio * 100);
              return label + ': ' + valueF + ' ' + currency + ', ' + ratioF + '%';
            },
          },
        },
      },
    };
  }

  loadRestData() {
    this.codes = [];
    this.exposure = [];
    this.exposurePercent = [];
    this.service
      .getEuMapData(this._businessUnit, ApplicationModule.LIMIT, 10)
      .pipe(
        map((results: Array<any>) => {
          if (results) {
            results.forEach((result) => {
              this.codes.push(result.country.code + '');
              this.exposure.push(result.exposure);
              this.exposurePercent.push(result.exposurePercent);
            });
          }
        })
      )
      .subscribe(
        () => console.log('Empty subscribe'),
        (error) => console.log('Error occurred while getting Pie Chart Limit data'),
        () => this.loadData()
      );
  }
}
