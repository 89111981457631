import Big from 'big.js';

export function isNumeric(val) {
  // parseFloat NaNs numeric-cast false positives (null|true|false|"")
  // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
  // subtraction forces infinities to NaN
  // adding 1 corrects loss of precision from parseFloat (#15100)
  return !Array.isArray(val) && val - parseFloat(val) + 1 >= 0;
}

export class NumberUtils {
  static isValidPercent(a: number, b: number) {
    return !isNaN(a / b);
  }

  static roundMoney(value: number): number {
    if (value) {
      return Number(value.toFixed(2));
    } else {
      return value;
    }
  }

  static round(value: number, scale: number): number {
    if (value) {
      return Number(value.toFixed(scale));
    } else {
      return value;
    }
  }

  static parseFloat(text: string): number {
    if (!text) {
      return 0.0;
    }
    if (text.indexOf(',') !== -1) {
      // If text is in our business format, we change to standard decimal point
      text = text.replace(/\./g, '').replace(/,/g, '.');
    }

    return Number.parseFloat(text);
  }

  static sum(a: number, b: number): number {
    if (!a) {
      return b;
    }
    if (!b) {
      return a;
    }
    return a + b;
  }

  static minus(a: number, b: number): number {
    if (!a) {
      return -b;
    }
    if (!b) {
      return a;
    }
    return Big(a).minus(Big(b));
  }

  static negate(a: number): number {
    return -1 * a;
  }

  static deleteItemFirstOccurrence(items: any[], item: any) {
    for (let i = 0; i < items.length; i++) {
      if (items[i] === item) {
        items.splice(i, 1);
        return;
      }
    }
  }

  static notNull(x: number): number {
    return x ? x : 0;
  }
}
