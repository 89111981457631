import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TreeNode} from 'primeng/api';
import {CompanyWithExposureDto} from '../../bonding_shared/model';
import {RouterService} from '../../bonding_shared/services';

@Component({
  selector: 'company-group-tree',
  template: `
    <p-tree [value]="tree" selectionMode="single" (onNodeSelect)="router.toCompanyPreview($event.node.data)"></p-tree>
  `,
})
export class CompanyGroupTreeComponent implements OnInit {
  @Input() companies: CompanyWithExposureDto[];
  @Input() groupHeadId: number;

  childCompanies: CompanyWithExposureDto[] = [];
  tree: TreeNode[] = [];

  ngOnInit(): void {
    const tree = this.companies.map((c) => this.createCompanyTreeNode(c));
    this.cleanChildCompanies(tree);
    // now the tree is not expanded on init but maybe it should be so I leave this code
    // this.tree.forEach(node => this.expandRecursive(node, true));
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  constructor(public router: RouterService) {}

  createCompanyTreeNode(company: CompanyWithExposureDto): TreeNode {
    const childCompanies = this.companies.filter((c) => c.parentOfBranch && c.parentOfBranch.id === company.id);
    this.childCompanies.push(...childCompanies);
    const childNodes = childCompanies.map((cc) => this.createCompanyTreeNode(cc));
    return this.createTreeNode(company.registrationName, company.id, childNodes);
  }

  cleanChildCompanies(tree: TreeNode[]) {
    this.tree = tree.filter((n) => !this.childCompanies.map((c) => c.id).includes(n.data));
  }

  createTreeNode(companyName: string, companyId: number, children: TreeNode[]): TreeNode {
    const node: TreeNode = {};
    node.label = companyName;
    node.data = companyId;
    node.selectable = true;
    node.icon = companyId === this.groupHeadId ? 'fa fa-institution fa-xs' : 'fa fa-home fa-sm';
    node.children = children;
    return node;
  }
}
