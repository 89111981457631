import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {RouterService} from '../../bonding_shared/services/router-service';
import {
  BrokerContractRelationVersionSimpleDto,
  CompanyBaseDto,
  DictionaryDto,
  ErrorReason,
  InvoiceIdDto,
  InvoiceItemDto,
} from '../../bonding_shared/model/dtos';
import {InvoiceItemDataComponent} from './components/invoice-item-data.component';
import {InvoiceItemService} from '../../bonding_shared/services/invoice-item.service';
import {TranslateService} from '@ngx-translate/core';
import {
  AppProperty,
  BusinessObjectType,
  DictionaryPropertyType,
  InvoiceCategory,
} from '../../bonding_shared/model/dictionary-ids';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {
  BrokerContractRelationService,
  BusinessPropertiesService,
  BusinessReportService,
  DictionaryBaseService,
  DictionaryService,
  PropertyService,
} from '../../bonding_shared/services';
import {AbstractInvoiceDetailsComponent} from '../../bonding_shared/components/shared/abstract-invoice-details.component';
import {InvoiceItemGuiService} from './services/invoice-item-gui.service';
import {ConfirmDialogComponent} from '../../bonding_shared/components/confirm-dialog';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';

@Component({
  selector: 'invoice-item-details',
  templateUrl: 'invoice-item-details.component.html',
})
export class InvoiceItemDetailsComponent extends AbstractInvoiceDetailsComponent implements OnInit {
  protected _invoiceItemDataCmp: InvoiceItemDataComponent;

  @ViewChild(InvoiceItemDataComponent)
  set invoiceItemDataCmp(invoiceItemDataCmp: InvoiceItemDataComponent) {
    this._invoiceItemDataCmp = invoiceItemDataCmp;
  }

  get invoiceItemDataCmp(): InvoiceItemDataComponent {
    return this._invoiceItemDataCmp;
  }

  @ViewChild('selectBrokerDialog', {static: true}) protected selectBrokerDialog: ConfirmDialogComponent;

  public invoiceItem: InvoiceItemDto = <InvoiceItemDto>{};
  selectBrokerDialogVisible = false;
  selectedBrokerContractRelationVersion: BrokerContractRelationVersionSimpleDto;
  protected invoiceItemTypeMap: {[index: string]: DictionaryDto} = {};

  readonly INVOICE_BROKER_NEW_BROKER_ITEM_RESTRICTIONS =
    this.propertyService.properties[AppProperty.INVOICE_BROKER_NEW_BROKER_ITEM_RESTRICTIONS];

  constructor(
    private invoiceItemService: InvoiceItemService,
    private invoiceItemGuiService: InvoiceItemGuiService,
    protected route: ActivatedRoute,
    private routerService: RouterService,
    protected propertyService: PropertyService,
    protected businessPropertiesService: BusinessPropertiesService,
    protected translateService: TranslateService,
    protected loggedUserService: LoggedUserService,
    protected growlService: GrowlService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected businessReportService: BusinessReportService,
    private brokerContractService: BrokerContractRelationService,
    private dictionaryService: DictionaryService
  ) {
    super(
      propertyService,
      businessPropertiesService,
      translateService,
      growlService,
      dictionaryBaseService,
      route,
      loggedUserService,
      businessReportService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadInvoiceItemTypes();
  }

  initializeView(params: Params, force?: boolean) {
    super.initializeView(params, force);
    const id = +params['id'];

    if (this.invoiceItem.id === id && !force) {
      return;
    }

    if (id > 0) {
      this.invoiceItemService.getInvoiceItem(id).subscribe((invoiceItem) => {
        this.invoiceItem = invoiceItem;
      });
    } else {
      const relatedToTypeId = BusinessObjectType[<string>params['relatedToType']];
      const relatedToId = params['relatedToId'] ? +params['relatedToId'] : undefined;
      const sourceItemId = params['sourceItemId'] ? +params['sourceItemId'] : undefined;
      console.log('new invoice item: ' + relatedToTypeId + ', ' + relatedToId + ', ' + sourceItemId);
      this.invoiceItemService.newInvoiceItem(this.categoryId, relatedToTypeId, relatedToId, sourceItemId).subscribe(
        (invoiceItem) => (this.invoiceItem = invoiceItem),
        (error) => this.handleError(error)
      );
    }
  }

  onSave() {
    delete this.serverErrors;
    this.invoiceItemDataCmp.showErrors = true;
    if (!this.invoiceItemDataCmp.form.valid) {
      console.log('save invoice item validation error');
      return;
    }

    this.invoiceItemDataCmp.onSave();

    if (this.invoiceItemDataCmp.invoiceItem.client) {
      const clientId: CompanyBaseDto = <CompanyBaseDto>{};
      clientId.id = this.invoiceItemDataCmp.invoiceItem.client.id;
      clientId.registrationName = this.invoiceItemDataCmp.invoiceItem.client.registrationName;
      this.invoiceItemDataCmp.invoiceItem.client = clientId;
    }

    this.invoiceItemService.save(this.invoiceItemDataCmp.invoiceItem).subscribe(
      (invoiceItem) => {
        this.invoiceItem = invoiceItem;
        if (invoiceItem.warnings) {
          this.serverErrors = invoiceItem.warnings;
          this.showErrors = true;
        } else {
          this.invoiceItemDataCmp.showErrors = false;
        }
        this.showSavedMsg();
      },
      (error) => this.handleError(error),
      () => this.routerService.toInvoiceItemDetails(this.categoryId, this.invoiceItem.id)
    );
  }

  onCancel() {
    this.invoiceItemDataCmp.reset();
    super.onCancel(this.route);
  }

  showSavedMsg() {
    this.growlService.notice('invoiceItem.details.saved');
  }

  showErrorMsg() {
    this.growlService.error('invoiceItem.details.saveError');
  }

  showFormError() {
    this.growlService.error('invoiceItem.details.formErrors');
  }

  handleError(errors: ErrorReason[]) {
    console.log('Save error: ', errors);
    this.serverErrors = errors;
  }

  getInvoiceItemCategoryLabel(): string {
    return this.invoiceItemGuiService.getInvoiceItemCategoryLabel(this.invoiceCategoryMap[this.categoryId], false);
  }

  toInvoice() {
    this.routerService.toInvoiceDetails(this.categoryId, this.invoiceItem.invoicePosition.invoice.id);
  }

  formatInvoiceNumber(invoice: InvoiceIdDto): string {
    return invoice.number ? invoice.number : this.translateService.instant('invoiceItem.details.draft');
  }

  private loadInvoiceItemTypes() {
    this.dictionaryService.getDictionary('InvoiceItemType').subscribe((invoiceItemTypes) => {
      for (const invoiceItemType of invoiceItemTypes) {
        this.invoiceItemTypeMap[invoiceItemType.id] = invoiceItemType;
      }
    });
  }

  protected newBrokerItem() {
    this.selectBrokerDialogVisible = true;
    return new Promise((resolve, reject) => {
      this.selectBrokerDialog.openAndExecuteOnConfirm(
        'invoice.details.newBrokerItemDialogTitle',
        'invoice.details.newBrokerItemDialogMessage',
        this.toNewBrokerItem.bind(this),
        () => {
          this.selectBrokerDialogVisible = false;
          this.selectedBrokerContractRelationVersion = undefined;
        }
      );
    });
  }

  protected brokerContractRelationVersionSelectItem(item: BrokerContractRelationVersionSimpleDto) {
    this.selectedBrokerContractRelationVersion = item;
  }

  get newBrokerItemButtonVisible() {
    return (
      this.categoryId === InvoiceCategory.INVOICE &&
      this.invoiceItem.id &&
      (!this.INVOICE_BROKER_NEW_BROKER_ITEM_RESTRICTIONS ||
        DictionaryUtils.hasProperty(
          this.invoiceItemTypeMap,
          this.invoiceItem.type,
          DictionaryPropertyType.BROKER_PROVISION_CALCULATION
        ))
    );
  }

  private toNewBrokerItem() {
    this.routerService.toNewInvoiceItem(
      InvoiceCategory.BROKER,
      BusinessObjectType.BROKER_CONTRACT_RELATION,
      this.selectedBrokerContractRelationVersion
        ? this.selectedBrokerContractRelationVersion.brokerRelation.id
        : undefined,
      this.invoiceItem.id
    );
  }

  get relatedToTypeId() {
    const link = this.invoiceItem.contractLink;
    return link.linkType.id === BusinessObjectType.POLICY ? BusinessObjectType.MASTER_POLICY : link.linkType.id;
  }

  get relatedToId() {
    const link = this.invoiceItem.contractLink;
    switch (link.linkType.id) {
      case BusinessObjectType.CONTRACT:
        return link.contract.id;
        break;
      case BusinessObjectType.POLICY:
        return link.policyContract.masterPolicyId;
        break;
      case BusinessObjectType.POLICY_INQUIRY:
        return link.policyInquiry.id;
        break;
      case BusinessObjectType.BROKER_CONTRACT:
        return link.brokerContract.id;
        break;
    }
    return undefined;
  }
}
