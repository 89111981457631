import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services';
import {RejectManagementService} from '../../../bonding_shared/services/reject-management.service';
import {RejectManagementCriteriaDto, RejectManagementDto} from '../../../bonding_shared/model';
import {Observable} from 'rxjs';

@Injectable()
export class RejectManagementGuiService {
  searchDataProvider: SearchDataProvider<RejectManagementCriteriaDto, RejectManagementDto>;

  constructor(private service: RejectManagementService) {
    this.searchDataProvider = new SearchDataProvider<RejectManagementCriteriaDto, RejectManagementDto>(service);
  }

  createDataProvider(): SearchDataProvider<RejectManagementCriteriaDto, RejectManagementDto> {
    return new SearchDataProvider<RejectManagementCriteriaDto, RejectManagementDto>(this.service);
  }

  complete(rejects: RejectManagementDto[]): Observable<RejectManagementDto[]> {
    return this.service.complete(rejects);
  }
}
