import {Component, ContentChild, Input, TemplateRef} from '@angular/core';
import {LifecycleDropdownAbstractComponent} from './lifecycle-dropdown-abstract.component';
import {StateTransitionDto} from '../../model';
import {Observable, Subscription} from 'rxjs';
import {AbstractService} from '../../services';

/**
 * Dropdown button, which changes status of the object, saves it and updates itself with new possible transitions
 * on successful save, or leaves the object with status unchanged when error occurs.
 *
 * It is very similar to LifecycleDropdownComponent. The difference is that is doesn't load the whole transition map on initialization
 * but loads transition list when selectedObject parameters change (it calls backend when new transition list is to be shown).
 * It is also more generic as it allows to load transition list based not only on statusId but on any property of the selectedObject
 *
 * @author wilk
 */
// TODO: if sufficient time has passed since removing lifecycle-dropdown and everything works fine, rename this to lifecycle-dropdown
// TODO: and merge with lifecycle-dropdown-abstract (remove lifecycle-dropdown-abstract as a result)
@Component({
  selector: 'lifecycle-dropdown-simple',
  templateUrl: './lifecycle-dropdown.component.pug',
})
export class LifecycleDropdownSimpleComponent extends LifecycleDropdownAbstractComponent {
  // service implementing method getTransitions (with statusId as argument)
  @Input() set service(service: {getTransitions: (obj) => Observable<StateTransitionDto[]>}) {
    this.unsubscribeAndReload(() => (this.getServiceTransitions = service.getTransitions.bind(service)));
  }

  // service implementing method getTransitionsForObject (with versionId as argument, for example bondVersionId)
  @Input() set serviceWithTransitionsForObject(service: AbstractService) {
    this.unsubscribeAndReload(() => (this.getServiceTransitions = service.getTransitionsForObject.bind(service)));
    this._service = service;
  }

  @Input() set getCustomTransitionsFunc(getCustomTransitionsFunc: (obj) => Observable<StateTransitionDto[]>) {
    this.unsubscribeAndReload(() => (this.getCustomTransitions = getCustomTransitionsFunc));
  }

  /** object with status field of type Dictionary */
  @Input() set selectedObject(s: any) {
    if (s !== this._selectedObject) {
      this.unsubscribeAndReload(() => (this._selectedObject = s));
    }
  }

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  private getServiceTransitions: (obj) => Observable<StateTransitionDto[]>;
  private getCustomTransitions: (obj) => Observable<StateTransitionDto[]>;
  private subscription: Subscription;

  private unsubscribeAndReload(updateTransitions: () => void) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    updateTransitions();
    this.reloadTransitions();
  }

  reloadTransitions() {
    const getTransitions = this._selectedObject && (this.getCustomTransitions || this.getServiceTransitions);
    if (getTransitions) {
      this.statusesReloading = true;
      this.subscription = getTransitions(this._selectedObject).subscribe(
        (transitions) => {
          this.transitions = transitions;
          this.mainTransition = this.selectMainTransition(true);
          this.firstTransition = this.selectMainTransition(false);
          this.statusesReloading = false;
        },
        (error) => {
          this.statusesReloading = false;
        }
      );
    }
  }
}
