import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {RatingModule} from 'ngx-bootstrap/rating';
import {ContactNoteDetailsComponent} from './contact-note-details.component';
import {CompanySharedModule} from '../../../company/components/shared/company-shared.module';
import {ContactNoteTaskComponent} from './embedded/contact-note-task.component';
import {ContactNoteLeadSourceComponent} from './embedded/contact-note-lead-source.component';
import {ContactNoteDetailsGuard} from './_guards/contact-note-details.guard';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    CompanySharedModule,
    RatingModule.forRoot(),
  ],
  declarations: [ContactNoteDetailsComponent, ContactNoteTaskComponent, ContactNoteLeadSourceComponent],
  exports: [ContactNoteDetailsComponent],
  providers: [ContactNoteDetailsGuard],
})
export class ContactNoteSharedModule {}
