import {ControlContainer, NgModelGroup} from '@angular/forms';
import {Component, Input, OnInit, SkipSelf, ViewChild} from '@angular/core';
import {DictionaryBaseDto} from '../../model';
import {DictionaryBaseService, PropertyService} from '../../services';
import {CurrencyExchangeRateService} from '../../services/currency-exchange-rate.service';
import {AppProperty, Currency} from '../../model/dictionary-ids';

@Component({
  selector: 'amount-row',
  template: `
    <form-row [labelKey]="labelKey" [showErrors]="showErrors" *ngIf="parent" [control]="amountModel?.control">
      <div
        [class.value-currency-with-multiplier]="showCurrencyCombo && multiplierSuffix"
        [class.value-currency-no-multiplier]="showCurrencyCombo && !multiplierSuffix"
        [class.value-currency-fixed-with-multiplier]="!showCurrencyCombo && multiplierSuffix"
        [class.value-currency-fixed]="!showCurrencyCombo && !multiplierSuffix"
      >
        <num-input
          #amountModelNotStandalone="ngModel"
          *ngIf="amountPropertyName && parent; else standalone"
          [(ngModel)]="parent[amountPropertyName]"
          [baseMultiplier]="multiplier"
          [type]="inputType"
          [class.vertical-align-bottom]="readOnly"
          [required]="required"
          [name]="amountPropertyName + fieldNameSuffixOrEmpty"
          [presentationMode]="readOnly"
        ></num-input>
        <ng-template #standalone>
          <num-input
            #amountModelStandalone="ngModel"
            [(ngModel)]="amountStandalone"
            [baseMultiplier]="multiplier"
            [type]="inputType"
            [class.vertical-align-bottom]="readOnly"
            [required]="required"
            [ngModelOptions]="{standalone: true}"
            [presentationMode]="readOnly"
          ></num-input>
        </ng-template>
        <span class="amount-multiplier">{{ multiplierSuffix }} {{ showCurrencyCombo ? '' : currencyCode }}</span>
        <dict-combo
          *ngIf="showCurrencyCombo && !currency"
          [(ngModel)]="parent[currencyPropertyName]"
          dictionary="Currency"
          label="code"
          [name]="'currency' + fieldNameSuffixOrEmpty"
          [increaseBottomPadding]="false"
        >
        </dict-combo>
        <dict-combo
          *ngIf="showCurrencyCombo && currency"
          [ngModel]="currency"
          dictionary="Currency"
          label="code"
          [name]="'currency' + fieldNameSuffixOrEmpty"
          [presentationMode]="true"
          [increaseBottomPadding]="false"
        >
        </dict-combo>
      </div>
    </form-row>
  `,
  // The provider makes the component reuse the existing parent ngForm or ngModelGroup
  // https://en.it1352.com/article/1887831.html
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class AmountRowComponent implements OnInit {
  static idSeq = 0;

  readonly SYSTEM_CURRENCY_CODE = this.propertyService.properties[AppProperty.SYSTEM_CURRENCY];
  @Input() labelKey: string;
  @Input() showErrors: boolean;
  @Input() readOnly = false;
  @Input() amountPropertyName: string;
  @Input() currencyPropertyName: string;
  @Input() fixedCurrencyAsCombo = false; // currency shown in combo in presentation mode instead of span,
  // so it is not right aligned and looks better when it goes
  // together with another amount-row with combo)
  @Input() parent: any;
  @Input() required = false;
  @Input() multiplier = 1;
  @Input() multiplierSuffix: string;
  @Input() type: 'decimal' | 'integer';
  @Input() autoUpdateValueInSysCurr = false;

  @Input() addFieldNameSuffix = true;

  @Input() set currencyId(id: number) {
    this._currencyId = id;
    this.currency = null;
    if (id) {
      this.dictionaryService.getDictionaryEntry('Currency', id).subscribe((e) => {
        this.currency = e;
        this.updateValueInSysCurr();
      });
    }
  }

  @Input() set originCurrencyId(id: number) {
    this._originCurrencyId = id;
    this.updateValueInSysCurr();
  }

  @Input() set originValue(v: number) {
    this._originValue = v;
    this.updateValueInSysCurr();
  }

  currency: DictionaryBaseDto;
  _amountModelNotStandalone: NgModelGroup;
  _amountModelStandalone: NgModelGroup;
  _originCurrencyId: number;
  _originValue: number;
  _currencyId: number;
  fieldNameSuffix: number;
  amountStandalone: number;

  @ViewChild('amountModelNotStandalone') set amountModelNotStandalone(amountModel: NgModelGroup) {
    setTimeout(() => (this._amountModelNotStandalone = amountModel));
  }
  @ViewChild('amountModelStandalone') set amountModelStandalone(amountModel: NgModelGroup) {
    setTimeout(() => (this._amountModelStandalone = amountModel));
  }

  get amountModel(): NgModelGroup {
    return this.amountPropertyName && this.parent ? this._amountModelNotStandalone : this._amountModelStandalone;
  }

  // @ViewChild('amountModel', {static: true}) set modelForAmount(model: NgModelGroup) {
  //   // timeout is set to avoid 'Expression has changed after it was checked Error'
  //   setTimeout(() => (this.amountModel = model));
  // }

  constructor(
    private dictionaryService: DictionaryBaseService,
    private propertyService: PropertyService,
    private currencyExchangeRateService: CurrencyExchangeRateService
  ) {
    this.fieldNameSuffix = AmountRowComponent.idSeq++;
  }

  ngOnInit() {
    this.updateValueInSysCurr();
  }

  get showCurrencyCombo(): boolean {
    return (!this.currency && !this.readOnly) || this.fixedCurrencyAsCombo;
  }

  get currencyCode() {
    return this.currency
      ? this.currency.code
      : this.currencyPropertyName
      ? this.parent[this.currencyPropertyName]
        ? this.parent[this.currencyPropertyName].code
        : ''
      : '';
  }

  get fieldNameSuffixOrEmpty() {
    return this.addFieldNameSuffix ? this.fieldNameSuffix : '';
  }

  get inputType() {
    return this.type ? this.type : this.multiplierSuffix ? 'integer' : 'decimal';
  }

  updateValueInSysCurr() {
    if (!this.autoUpdateValueInSysCurr || this.currencyCode !== this.SYSTEM_CURRENCY_CODE) {
      return;
    }
    const valueInSysCurr =
      this._originCurrencyId && this._originValue
        ? this.currencyExchangeRateService.calculate(this._originValue, this._originCurrencyId)
        : null;
    if (this.parent && this.amountPropertyName) {
      this.parent[this.amountPropertyName] = valueInSysCurr;
    } else {
      this.amountStandalone = valueInSysCurr;
    }
  }
}
