import {Component, OnInit} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {
  AppConfigService,
  LoggedUserService,
  PolicyContractVersionService,
} from '../../../../../bonding_shared/services';
import {
  InquiryProductType,
  Language,
  PolicyContractType,
  PolicyContractVersionStatus,
  PolicyInquiryStatementType,
} from '../../../../../bonding_shared/model/dictionary-ids';
import {DictionaryBaseDto, PolicyInquiryStatementDto} from '../../../../../bonding_shared/model';
import {ControlContainer, NgForm} from '@angular/forms';
import {VisibilityService} from '../../../../inquiry/services/visibility.service';

@Component({
  selector: 'statements-section',
  templateUrl: './statements-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class StatementsSectionComponent extends Section implements OnInit {
  self = this;

  visible: any = {};
  statements: {[key: string]: boolean | null} = {};

  get presentationMode() {
    const pl = this.policy;
    return (
      this.readonly ||
      !pl ||
      !pl.status ||
      !(
        pl.status.id === PolicyContractVersionStatus.REQUEST ||
        pl.status.id === PolicyContractVersionStatus.REQUEST_ACCEPTED
      )
    );
  }

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    private loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    protected policyContractVersionService: PolicyContractVersionService,
    private visibilityService: VisibilityService
  ) {
    super(policyContractViewService, appService, policyContractVersionService);
  }

  ngOnInit() {
    this.statements = {
      dataConsistentWithFacts: this.getStatement(PolicyInquiryStatementType.DATA_CONSISTENT_WITH_FACTS),
      documentaryConditions: this.getStatement(PolicyInquiryStatementType.FAMILIARIZATION_WITH_DOCUMENTARY_CONDITIONS),
      conditionsOfPolishness: this.getStatement(
        PolicyInquiryStatementType.GETTING_TO_KNOW_THE_CONDITIONS_OF_POLISHNESS
      ),
      consentToContact: this.getStatement(
        PolicyInquiryStatementType.CONSENT_TO_CONTACT_THE_CONTRACTOR_USING_THE_CUSTOMERS_NAME
      ),
      processingPersonalData: this.getStatement(
        PolicyInquiryStatementType.INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA
      ),
      sharingInformation: this.getStatement(
        PolicyInquiryStatementType.CLAUSE_OF_CONSENT_FOR_SHARING_INFORMATION_COVERED_BY_INSURANCE_SECRECY
      ),
      russianEmbargo: this.getStatement(PolicyInquiryStatementType.RUSSIAN_EMBARGO),
      marketingConsent: this.getStatement(PolicyInquiryStatementType.MARKETING_CONSENT),
      paperInvoice: this.getStatement(PolicyInquiryStatementType.PAPER_INVOICE),
    };

    this.visible = this.visibilityService.getVisibility(this.getInquiryType());
  }

  private getInquiryType() {
    return (
      this.policy.inquiry?.productType?.id ||
      this.policy.contractType?.id - PolicyContractType.KUKE_KOM_EP + InquiryProductType.KUKE_KOM_EP
    );
  }

  onStatementChange() {
    if (!this.policy.statements) {
      this.policy.statements = [];
    }

    this.rewriteStatement(
      PolicyInquiryStatementType.DATA_CONSISTENT_WITH_FACTS,
      this.statements.dataConsistentWithFacts
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.FAMILIARIZATION_WITH_DOCUMENTARY_CONDITIONS,
      this.statements.documentaryConditions
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.GETTING_TO_KNOW_THE_CONDITIONS_OF_POLISHNESS,
      this.statements.conditionsOfPolishness
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.CONSENT_TO_CONTACT_THE_CONTRACTOR_USING_THE_CUSTOMERS_NAME,
      this.statements.consentToContact
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA,
      this.statements.processingPersonalData
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.CLAUSE_OF_CONSENT_FOR_SHARING_INFORMATION_COVERED_BY_INSURANCE_SECRECY,
      this.statements.sharingInformation
    );

    this.rewriteStatement(PolicyInquiryStatementType.RUSSIAN_EMBARGO, this.statements.russianEmbargo);
    this.rewriteStatement(PolicyInquiryStatementType.MARKETING_CONSENT, this.statements.marketingConsent);
    this.rewriteStatement(PolicyInquiryStatementType.PAPER_INVOICE, this.statements.paperInvoice);
  }

  private rewriteStatement(typeId: number, value: boolean) {
    const statements = this.policy.statements;
    const index = statements.findIndex((st) => st.statementType.id === typeId);
    if (index === -1) {
      const statement: PolicyInquiryStatementDto = {
        id: null,
        statementType: <DictionaryBaseDto>{id: typeId},
        value: value,
        version: 1,
      };
      statements.push(statement);
    } else {
      statements[index].value = value;
    }
  }

  private getStatement(typeId: number) {
    const stats = this.policy && this.policy.statements;
    if (stats) {
      const stat = stats.find((st) => st.statementType.id === typeId);
      if (stat !== undefined) {
        return stat.value;
      }
    }
    return null;
  }

  getGdprLink() {
    if (this.loggedUserService.getLoggedUserData().language.id === Language.POLISH) {
      return 'https://kuke.com.pl/klauzula-informacyjna-o-przetwarzaniu-danych-osobowych-dla-klienta-kuke';
    }
    return 'https://kuke.com.pl/en/processing-of-personal-data';
  }
}
