import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {Component, forwardRef, Input} from '@angular/core';
import {
  ClaimService,
  ClaimSurveyAnswerDefinitionDto,
  ClaimSurveyGivenAnswerDto,
  ClaimSurveyQuestionDefinitionDto,
  ClaimVersionDto,
  DictionaryBaseDto,
  DictionaryService,
} from '../../../bonding_shared';
import {forkJoin} from 'rxjs';

const CLAIM_SURVEY_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ClaimSurveyComponent),
  multi: true,
};
const CLAIM_SURVEY_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ClaimSurveyComponent),
  multi: true,
};

@Component({
  selector: 'claim-survey',
  templateUrl: 'claim-survey.component.pug',
  providers: [CLAIM_SURVEY_CONTROL_VALUE_ACCESSOR, CLAIM_SURVEY_VALIDATOR],
})
export class ClaimSurveyComponent implements ControlValueAccessor, Validator {
  @Input()
  showErrors = false;
  @Input()
  skipValidation = false;
  disabled = false;
  claimSurveyFormGroup: UntypedFormGroup;
  surveyQuestionDefinitions: ClaimSurveyQuestionDefinitionDto[] = [];
  questionsDictionary: {[key: string]: DictionaryBaseDto};
  answersDictionary: {[key: string]: DictionaryBaseDto};
  answersControlConfiguration: {[key: string]: SurveyAnswerControlConfiguration};
  surveyFormInitialized = false;
  private _surveyAnswers: ClaimSurveyGivenAnswerDto[];
  notAnsweredQuestionIds: string[] = [];
  private readonly optionalQuestionIds: string[] = ['384000024'];

  constructor(
    private claimService: ClaimService,
    private dictionaryService: DictionaryService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.claimSurveyFormGroup = formBuilder.group({});
  }

  @Input()
  set claimVersion(claimVersion: ClaimVersionDto) {
    const initialSurveyAnswers = claimVersion.claim.surveyAnswers;
    this.disabled = !!claimVersion.claim.id;
    forkJoin([
      this.dictionaryService.getDictionary('ClaimSurveyQuestion'),
      this.dictionaryService.getDictionary('ClaimSurveyAnswer'),
      this.claimService.getSurveyDefinition(claimVersion),
    ]).subscribe(([questionsDict, answersDict, definitions]) => {
      this.questionsDictionary = this.groupById(questionsDict, (a) => '' + a.id);
      this.answersDictionary = this.groupById(answersDict, (a) => '' + a.id);
      this.surveyQuestionDefinitions = definitions;
      const allAvailableAnswers = definitions.reduce((acc, question) => acc.concat(question.availableAnswers), []);
      this.answersControlConfiguration = allAvailableAnswers.reduce((result, availableAnswer) => {
        const dictionaryEntry = this.answersDictionary[availableAnswer.answerId];
        result[availableAnswer.answerId] = {
          ...availableAnswer,
          label: dictionaryEntry.name,
          tooltip: dictionaryEntry.description,
        };
        return result;
      }, {} as {[key: string]: SurveyAnswerControlConfiguration});
      if (!this.surveyFormInitialized) {
        this.buildForm(initialSurveyAnswers);
      }
    });
  }

  registerOnChange(fn: any): void {
    this.claimSurveyFormGroup.valueChanges.subscribe((formData) => fn(Object.values(formData)));
  }

  registerOnTouched(fn: any): void {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.claimSurveyFormGroup.disabled || this.skipValidation) {
      return null;
    }
    const notAnsweredQuestions = this.surveyQuestionDefinitions.filter((question) => {
      const questionAnswered = question.availableAnswers
        .map((answer) => this.claimSurveyFormGroup.get(answer.answerId))
        .filter((answerControl) => answerControl !== null)
        .reduce(
          (answerChecked, currentAnswer) => (answerChecked = answerChecked || currentAnswer.value.checked),
          false
        );
      return !(questionAnswered || this.optionalQuestionIds.includes(question.questionId));
    });
    this.notAnsweredQuestionIds = notAnsweredQuestions.map((question) => question.questionId);

    if (this.claimSurveyFormGroup.invalid) {
      return {required: 'must be completed'};
    }

    if (notAnsweredQuestions.length > 0) {
      return {claimSurveyQuestionsNotAnswered: notAnsweredQuestions};
    }
    this.notAnsweredQuestionIds = [];
    return null;
  }

  writeValue(answers: ClaimSurveyGivenAnswerDto[]): void {
    if (!this.answersControlConfiguration) {
      return;
    }
    this.buildForm(answers);
  }

  isQuestionNotAnswered(questionId: string): boolean {
    return this.notAnsweredQuestionIds.indexOf(questionId) > -1;
  }

  private buildForm(answers: ClaimSurveyGivenAnswerDto[]) {
    if (answers) {
      Object.keys(this.claimSurveyFormGroup.controls).forEach((control) =>
        this.claimSurveyFormGroup.removeControl(control)
      );
      this._surveyAnswers = answers;
      this._surveyAnswers.forEach((surveyAnswer) => {
        if (this.claimSurveyFormGroup.contains(surveyAnswer.answerId)) {
          this.claimSurveyFormGroup.get(surveyAnswer.answerId).patchValue(surveyAnswer);
        } else {
          this.addAnswerControl(surveyAnswer);
        }
        Object.keys(this.claimSurveyFormGroup.controls)
          .filter((controlName) => !this._surveyAnswers.find((sa) => sa.answerId === controlName))
          .forEach((controlName) => {
            this.claimSurveyFormGroup.removeControl(controlName);
          });
      });
    } else {
      Object.keys(this.answersControlConfiguration).forEach((answerId) => {
        this.addAnswerControl({
          answerId: answerId,
          checked: false,
          comment: null,
          document: null,
        });
      });
    }
    this.surveyFormInitialized = !!Object.keys(this.claimSurveyFormGroup.controls)?.length;
  }

  private addAnswerControl(surveyAnswer: ClaimSurveyGivenAnswerDto) {
    const answerControl = new UntypedFormControl(surveyAnswer);
    if (this.disabled) {
      answerControl.disable();
    }
    answerControl.valueChanges.subscribe((value) => {
      if (value.checked) {
        const currentAnswer = this.answersControlConfiguration[surveyAnswer.answerId];
        const possibleAnswersForGivenQuestion = this.surveyQuestionDefinitions.find((d) =>
          d.availableAnswers.find((a) => a.answerId === surveyAnswer.answerId)
        ).availableAnswers;
        possibleAnswersForGivenQuestion
          .filter((a) => a.answerId !== surveyAnswer.answerId) // removes current answer
          .filter((a) => !(a.required || currentAnswer?.required)) // ignores required answers
          .filter((a) => currentAnswer?.exclusive || a.exclusive)
          .forEach((a) => this.resetAnswer(this.claimSurveyFormGroup, a.answerId));
      } else {
        this.resetAnswer(this.claimSurveyFormGroup, surveyAnswer.answerId);
      }
    });
    this.claimSurveyFormGroup.addControl(surveyAnswer.answerId, answerControl);
  }

  private groupById<Type>(elements: Type[], selector: (x: Type) => string) {
    return elements.reduce((result, entry) => {
      result[selector(entry)] = entry;
      return result;
    }, {} as {[key: string]: Type});
  }

  private resetAnswer(surveyFormGroup: UntypedFormGroup, answerId: string) {
    const answerControl = surveyFormGroup.get(answerId);
    if (answerControl.value.checked || !!answerControl.value.document) {
      answerControl.setValue({answerId: answerId, checked: false, comment: null, document: null});
      answerControl.updateValueAndValidity();
    }
  }
}

export interface SurveyAnswerControlConfiguration extends ClaimSurveyAnswerDefinitionDto {
  label: string;
  tooltip: string;
}
