import {Component, Input} from '@angular/core';
import {CompanyContactNoteDto} from '../../model';
import {ContactNoteService, RouterService, SettingsService} from '../../services';
import {BusinessObjectType} from '../../model/dictionary-ids';

@Component({
  selector: 'crm-dropdown',
  template: `
    <ng-container *hasRight="'COMPANY_CRM'">
      <div class="btn-group" dropdown [class.dropup]="dropup" [isDisabled]="disabled">
        <button type="button" [ngClass]="['dropdown-toggle', buttonClass]" dropdownToggle>
          {{ label | translate }} <span class="caret"></span>
        </button>
        <ul class="dropdown-menu" role="menu" max-height="360px;">
          <ng-container *ngIf="companies && companies.length > 0">
            <li *ngFor="let item of companies" role="menuitem">
              <a class="dropdown-item" (click)="click(item)">
                <span translate>company.crm.newNote</span>
                <span *ngIf="!item['contactNoteReasonType']?.name">
                  <span *ngIf="item['companyContactNoteRole']?.name">
                    {{ item['companyContactNoteRole'].name }}:&nbsp;
                  </span>
                  <span *ngIf="item['additionalInfo']"> {{ item['additionalInfo'] }}:&nbsp; </span>
                  <span> {{ item['company'][companyLabel] }} </span>
                </span>
                <span *ngIf="item['contactNoteReasonType']?.name">
                  {{ item['contactNoteReasonType'].name }}:&nbsp;
                  <span *ngIf="item['additionalInfo']"> {{ item['additionalInfo'] }}&nbsp; </span>
                </span>
              </a>
            </li>
          </ng-container>
          <li *ngIf="!createOnly"><a class="dropdown-item" (click)="allClick()" translate>company.crm.allNotes</a></li>
          <li *ngIf="createOnly && (!businessObjectTypeId || !businessObjectId)">
            <a class="dropdown-item" translate>company.crm.noteDetails.relatedTo</a>
          </li>
        </ul>
      </div>
    </ng-container>
  `,
})
export class CrmDropdownComponent {
  private _businessObjectTypeId: number;
  private _businessObjectId: number;
  @Input() label = 'CRM';
  @Input() disabled = false;
  @Input() createOnly = false;
  @Input() dropup = false;
  @Input() buttonClass = 'bon-btn-info';
  companies: CompanyContactNoteDto[] = [];
  readonly companyLabel = 'registrationName';
  @Input()
  set businessObjectTypeId(businessObjectTypeId: number) {
    this.setCrmCompanies(this._businessObjectTypeId, businessObjectTypeId, this.businessObjectId, true);
    this._businessObjectTypeId = businessObjectTypeId;
  }
  get businessObjectTypeId(): number {
    return this._businessObjectTypeId;
  }
  @Input()
  set businessObjectId(businessObjectId: number) {
    this.setCrmCompanies(this._businessObjectId, businessObjectId, this.businessObjectTypeId, false);
    this._businessObjectId = businessObjectId;
  }
  get businessObjectId(): number {
    return this._businessObjectId;
  }
  private setCrmCompanies(prevValue: number, newValue: number, otherPairValue: number, isType: boolean) {
    console.log('setCrmCompanies', prevValue, newValue, otherPairValue, isType);
    if (!prevValue && newValue && newValue > 0 && otherPairValue) {
      let boType = otherPairValue;
      let boId = newValue;
      if (isType) {
        boType = newValue;
        boId = otherPairValue;
      }
      this.contactNoteService.getCrmCompanies(boType, boId).subscribe(
        (result) => {
          this.companies = result;
        },
        (error) => console.log('getCrmCompanies server error or warning !', error)
      );
    }
  }

  constructor(
    public router: RouterService,
    private contactNoteService: ContactNoteService,
    private settingService: SettingsService
  ) {}

  click(item: CompanyContactNoteDto) {
    if (this.businessObjectTypeId === BusinessObjectType.COMPANY) {
      this.router.toContactNoteDetails(0, this.businessObjectId, this.businessObjectTypeId, this.businessObjectId);
    } else {
      this.router.toContactNoteDetails(
        0,
        item.company.id,
        this.businessObjectTypeId,
        this.businessObjectId,
        item.companyContactNoteRole.id,
        this.settingService.getActiveModuleId(),
        item.contactNoteReasonType?.id
      );
    }
  }

  allClick() {
    if (this.businessObjectTypeId === BusinessObjectType.COMPANY) {
      this.router.toCompanyCrm(this.businessObjectId);
    } else {
      this.router.toCompanyCrm(0, this.businessObjectTypeId, this.businessObjectId);
    }
  }
}
