import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {AbstractAuditedRelatedService} from './abstract-audited-related.service';

export abstract class CollectionAuditedService<T> extends AbstractAuditedRelatedService<T> {
  protected url = this.urlPrefix + 'collection';

  protected constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }
}
