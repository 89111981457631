import {Injectable} from '@angular/core';
import {getInquiryVisibility} from '../domain/type-features';
import {LoggedUserService} from '../../../bonding_shared';

@Injectable()
export class VisibilityService {
  private readonly portalMode;

  constructor(private loggedUserService: LoggedUserService) {
    this.portalMode = this.loggedUserService.portal;
  }

  getVisibility(inqType: number) {
    return getInquiryVisibility(inqType, this.portalMode);
  }
}
