import {Component, Input, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  CompanyBaseDto,
  PolicyImporterDto,
  RouterService,
} from '../../../../../bonding_shared';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';

@Component({
  selector: 'importers-section',
  templateUrl: './importers-section.component.pug',
})
export class ImportersSectionComponent extends Section {
  @ViewChild('importersList', {static: true}) importersList: ATableComponent<PolicyImporterDto>;
  self = this;

  @Input() showSelected = false;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    public router: RouterService
  ) {
    super(policyContractViewService, appService);
  }

  addPolicyImporter(company: CompanyBaseDto) {
    this.importersList.onAddItem(<PolicyImporterDto>{company: company, selected: false});
  }
}
