import {SalesLeadSearchComponent} from './sales-lead-search.component';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {CompanyModule} from '../company/company.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {SalesLeadGuiService} from './services/sales-lead-gui.service';
import {SalesLeadListComponent} from './components/sales-lead-list.component';
import {SalesLeadDetailsComponent} from './sales-lead-details.component';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanyModule,
    CompanySharedModule,
  ],
  declarations: [SalesLeadSearchComponent, SalesLeadListComponent, SalesLeadDetailsComponent],
  providers: [SalesLeadGuiService],
})
export class SalesLeadModule {}
