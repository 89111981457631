import {Component, Input, OnInit} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {CompanyIdDto, CompanySimpleDto} from '../../../../bonding_shared/model/dtos';
import {AppConfigService} from '../../../../bonding_shared/services';

@Component({
  selector: 'bank-account-section',
  templateUrl: './bank-account-section.component.pug',
})
export class BankAccountSectionComponent extends Section implements OnInit {
  self = this;

  selectedProfiterCompanyId: string;
  @Input() profiterCompanies: CompanySimpleDto[];

  constructor(protected policyContractViewService: PolicyContractViewService, public appService: AppConfigService) {
    super(policyContractViewService, appService);
  }

  ngOnInit() {
    if (!this.profiterCompanies) {
      this.profiterCompanies = [];
    }
    this.profiterCompanies.push(this.policy.client);
    this.policy.subinsured.forEach((value) => this.profiterCompanies.push(value.company));
    if (this.policy.profiterCompany) {
      this.selectedProfiterCompanyId = this.policy.profiterCompany.id.toString(10);
    }
  }

  public onSelectedProfiterCompany(selectedItem: string) {
    if (selectedItem !== undefined) {
      this.policy.profiterCompany = <CompanyIdDto>{};
      this.policy.profiterCompany.id = parseInt(selectedItem, 10);
    } else {
      this.policy.profiterCompany = undefined;
    }
  }
}
