import {Component, OnInit} from '@angular/core';
import {PolicyContractViewService} from '../../../../services/policy-contract-view.service';
import {Section} from '../../../sections/section';
import {AddressDto} from '../../../../../../bonding_shared/model';
import {AppConfigService} from '../../../../../../bonding_shared/services';

import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'address-section',
  templateUrl: './address-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class AddressSectionComponent extends Section implements OnInit {
  self = this;

  policyAddresses: AddressDto[] = [<AddressDto>{}];

  constructor(protected policyContractViewService: PolicyContractViewService, protected appService: AppConfigService) {
    super(policyContractViewService, appService);
  }

  ngOnInit() {
    if (!this.policy.addresses) {
      this.policy.addresses = [<AddressDto>{}];
    }
    this.policyAddresses = this.policy.addresses;
  }
}
