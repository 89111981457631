<div class="bon-card-group">
  <div class="bon-card-inner" style="width: 60%">
    <span *ngFor="let parameterDefinition of _parameterDefinitions; let i = index">
      <date-row *ngIf="isDate(parameterDefinition)" [labelKey]="getParameterLabelKey(parameterDefinition.label)"
                [(ngModel)]="parameterValues[parameterDefinition.name]" [name]="parameterDefinition.name"
                [type]="getType(parameterDefinition)" [showErrors]="showErrors"
                [required]="parameterDefinition.mandatory" [minDate]="parameterDefinition.minDate"
                [maxDate]="parameterDefinition.maxDate" (changeItem)="onDateRowUpdate()">
      </date-row>
      <input-row *ngIf="isText(parameterDefinition)" [labelKey]="getParameterLabelKey(parameterDefinition.label)" [type]="getType(parameterDefinition)"
                 [(ngModel)]="parameterValues[parameterDefinition.name]" [name]="parameterDefinition.name"
                 [showErrors]="showErrors" [required] = "parameterDefinition.mandatory">
      </input-row>
      <num-row *ngIf="isNumber(parameterDefinition)" [labelKey]="getParameterLabelKey(parameterDefinition.label)" [type]="getType(parameterDefinition)"
                 [(ngModel)]="parameterValues[parameterDefinition.name]" [name]="parameterDefinition.name"
                 [showErrors]="showErrors" [required] = "parameterDefinition.mandatory">
      </num-row>
      <ng-container *ngIf="isBoolean(parameterDefinition)">
        <div class="bon-row">
          <label class="bon-label">{{getParameterLabelKey(parameterDefinition.label) | translate}}</label>
          <string-combo class="bon-input-size" #model="ngModel" nullLabelKey="All" [items]="yesNoItems"
                        [(ngModel)]="parameterValues[parameterDefinition.name]"
                        [name]="parameterDefinition.name"
                        [required] = "parameterDefinition.mandatory">
          </string-combo>
          <error-message [control]="model" [show]="showErrors"></error-message>
        </div>
      </ng-container>
      <dict-row *ngIf="isDictionary(parameterDefinition)" [labelKey]="getParameterLabelKey(parameterDefinition.label)"
                [dictionary]="getDictionary(parameterDefinition)" [profileId]="parameterDefinition.dictionaryProfileId" nullLabel=" "
                [(ngModel)]="parameterValues[parameterDefinition.name]" [name]="parameterDefinition.name"
                [showErrors]="showErrors" [required] = "parameterDefinition.mandatory">
      </dict-row>
      <search-row #model="ngModel" *ngIf="isCompany(parameterDefinition)" [labelKey]="getParameterLabelKey(parameterDefinition.label)"
                  [(ngModel)]="companies[parameterDefinition.name]" displayedProperty="registrationName"
                  [name]="parameterDefinition.name" [control] = "model"
                  [openSelectorEmitter]="openSelectorEmitters[parameterDefinition.name]"
                  (searchBoxClear)="setCompany(parameterDefinition, undefined)" [addressInTitle]='true'
                  [showErrors]="showErrors" [required] = "parameterDefinition.mandatory">
      </search-row>
      <search-row #model="ngModel" *ngIf="isContract(parameterDefinition)" [labelKey]="getParameterLabelKey(parameterDefinition.label)"
                  [(ngModel)]="contractVersions[parameterDefinition.name]" displayedProperty="contract.number"
                  [name]="parameterDefinition.name" [control] = "model"
                  [openSelectorEmitter]="openSelectorEmitters[parameterDefinition.name]"
                  (searchBoxClear)="setContractVersion(parameterDefinition, undefined)"
                  [showErrors]="showErrors" [required] = "parameterDefinition.mandatory">
      </search-row>
      <ng-container *ngIf="isBusinessUnit(parameterDefinition)">
        <form-row label="{{getParameterLabelKey(parameterDefinition.label) | translate}}">
          <business-unit-selector #model="ngModel" class="bon-input-size"
                                  [userDefaultBU]="false" nullLabel=" " [(ngModel)]="businessUnits[parameterDefinition.name]" name="businessUnit"
                                  (changeItem)="setBusinessUnit(parameterDefinition, $event)">
          </business-unit-selector>
        </form-row>
      </ng-container>
      <input-row *ngIf="isCessionVersion(parameterDefinition)" type="number"
                 [labelKey]="getParameterLabelKey(parameterDefinition.label)" [(ngModel)]="parameterValues[parameterDefinition.name]"
                 [presentationMode]="paramsFixed" [presentationModeLinkAction]="backToCession.bind(this)"></input-row>
    </span>
  </div>
  <div class="bon-card-inner" style="width: 40%"></div>
</div>
<div class="bon-card-group">
  <div class="bon-card-inner">
    <company-selector-simple *ngFor="let parameterDefinition of companyParameterDefinitions"
                             (selectItem)="setCompany(parameterDefinition, $event)"
                             [open]="openSelectorEmitters[parameterDefinition.name]"
                             title="{{'common.searchCompany' | translate}}">
    </company-selector-simple>
    <contract-version-selector-simple *ngFor="let parameterDefinition of contractParameterDefinitions"
                                      (selectItem)="setContractVersion(parameterDefinition, $event)"
                                      labelKey="common.selectContract"
                                      [open]="openSelectorEmitters[parameterDefinition.name]"
                                      [onlyLastVersion]="true">
    </contract-version-selector-simple>
  </div>
</div>
