import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[requiredTrue]',
  providers: [{provide: NG_VALIDATORS, useExisting: RequiredTrueDirective, multi: true}],
})
export class RequiredTrueDirective implements Validator {
  @Input('requiredTrue') requiredTrue: boolean;

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.requiredTrue) {
      return control.value ? null : {requiredTrue: true};
    } else {
      return null;
    }
  }
}
