import {Component, Input} from '@angular/core';
import {ClaimService, ClaimVersionDto, DictionaryBaseDto, RecoveryDto} from '../../../bonding_shared';
import {ControlContainer, NgForm} from '@angular/forms';
import {ClaimStatus} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'claim-recoveries-reserves',
  templateUrl: 'claim-recoveries-reserves.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class ClaimRecoveriesReservesComponent {
  private static readonly CLAIM_APPROVED_STATUSES = [
    <DictionaryBaseDto>{id: ClaimStatus.APPROVED_CCOM},
    <DictionaryBaseDto>{id: ClaimStatus.WAITING_FOR_BANK_ACCOUNT_APPROVAL},
    <DictionaryBaseDto>{id: ClaimStatus.WAITING_FOR_PAYMENT},
    <DictionaryBaseDto>{id: ClaimStatus.WAITING_FOR_BANK_ACCOUNT_APPROVAL},
    <DictionaryBaseDto>{id: ClaimStatus.PAID},
    <DictionaryBaseDto>{id: ClaimStatus.CLOSED},
  ];
  claimVersion = <ClaimVersionDto>{};

  @Input()
  recovery = <RecoveryDto>{};
  @Input()
  disabled = true;
  @Input()
  showErrors = false;

  constructor(private claimService: ClaimService) {}

  @Input() set claimId(claimId: number) {
    if (claimId) {
      this.claimService
        .getLastClaimVersion(claimId, ClaimRecoveriesReservesComponent.CLAIM_APPROVED_STATUSES)
        .subscribe((cv) => (this.claimVersion = cv));
    }
  }

  get openClaimExpectedRecoveryAmount(): number {
    const percent = this.recovery?.openClaimExpectedRecoveryPercent ?? 0;
    const amount = this.claimVersion?.reserveAmount ?? 0;
    return (percent * amount) / 100;
  }

  get paidIndemnificationRecoveryAmount(): number {
    const percent = this.recovery?.paidIndemnificationRecoveryPercent ?? 0;
    const amount = this.claimVersion?.paid ?? 0;
    return (percent * amount) / 100;
  }
}
