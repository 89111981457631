<table class="table" [style.width]="'100%'" *ngIf="lossRatio">
  <thead class="table-header">
  <tr class="table-header">
    <th [style.width]="'31%'">
      <div></div>
    </th>
    <th [style.width]="'23%'">
      <div translate>policyContract.preview.lossRatio.lrCurrentYear</div>
    </th>
    <th [style.width]="'23%'">
      <div translate>policyContract.preview.lossRatio.lr3Years</div>
    </th>
    <th [style.width]="'23%'">
      <div translate>policyContract.preview.lossRatio.lrAllYears</div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr class="hover-table-row">
    <td class="bon-table-cell">
      <div translate>policyContract.preview.lossRatio.allClaims</div>
    </td>
    <td class="bon-table-cell">{{lossRatio.allClaims.lossRatioPercentCY | number}}</td>
    <td class="bon-table-cell">{{lossRatio.allClaims.lossRatioPercent3Y | number}}</td>
    <td class="bon-table-cell">{{lossRatio.allClaims.lossRatioPercentAY | number}}</td>
  </tr>
  <tr class="hover-table-row" *ngIf="!skipPaid">
    <td class="bon-table-cell">
      <div translate>policyContract.preview.lossRatio.claimsPaid</div>
    </td>
    <td class="bon-table-cell">{{lossRatio.paidClaims.lossRatioPercentCY | number}}</td>
    <td class="bon-table-cell">{{lossRatio.paidClaims.lossRatioPercent3Y | number}}</td>
    <td class="bon-table-cell">{{lossRatio.paidClaims.lossRatioPercentAY | number}}</td>
  </tr>
  </tbody>
</table>
