import {AbstractGuard} from '../../../bonding_shared/components/abstract-guard';
import {LoggedUserService} from '../../../bonding_shared/services';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {CompanyElementaryRight} from '../../../bonding_shared/model/dictionary-ids';
import {Injectable} from '@angular/core';

@Injectable()
export class CompanyManagersAccessGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router) {
    super(loggedUserService, router);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRight(state.url, CompanyElementaryRight.COMPANY_MANAGERS_ACCESS);
  }
}
