import {Component} from '@angular/core';
import {
  CompanySimpleDto,
  DictionaryBaseDto,
  PolicyContractBaseInsurerCompanyDto,
  PolicyContractCompanyDto,
} from '../../../../../bonding_shared/model';
import {Section} from '../section';
import {AppConfigService, RouterService} from '../../../../../bonding_shared/services';
import {CompanyType, PolicyContractCompanyRole} from '../../../../../bonding_shared/model/dictionary-ids';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';

@Component({
  selector: 'fl-insurer-section',
  templateUrl: './fl-insurer-section.component.pug',
})
export class FlInsurerSectionComponent extends Section {
  self = this;
  readonly CompanyType = CompanyType;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    public router: RouterService
  ) {
    super(policyContractViewService, appService);
  }

  baseInsurersFactory: any = (company: CompanySimpleDto) => {
    return <PolicyContractBaseInsurerCompanyDto>{
      role: <DictionaryBaseDto>{id: PolicyContractCompanyRole.BASE_INSURER, name: 'Base Insurer'},
      company: company,
    };
  };

  addInsurer(company: CompanySimpleDto) {
    this.policy.baseInsurers.push(this.baseInsurersFactory(company));
  }
}
