import {CompanyRawDto, CompanySimpleDto, ThirdPartyBaseDto} from '../../model';
import {CompanySelectorAbstractComponent} from './company-selector-abstract.component';
import {CompanySelectedEvent} from '../../../bonding/company/components/shared/extended-selectors/company-selector-extended.component';
import {ThirdPartyService} from '../../services/thirdparty.service';
import {Input, Directive} from '@angular/core';
import {AbstractService, AppConfigService} from '../../services';

@Directive()
export abstract class CompanySelectorThirdPartyAwareAbstractComponent extends CompanySelectorAbstractComponent<
  CompanySimpleDto,
  CompanySelectedEvent
> {
  @Input() thirdParty: ThirdPartyBaseDto;
  @Input() initThirdParty = false;

  protected constructor(
    companyService: AbstractService,
    private thirdPartyService: ThirdPartyService,
    appConfigService: AppConfigService
  ) {
    super(companyService, appConfigService);
  }

  onSelectItem(c: CompanySimpleDto | CompanyRawDto) {
    const isRaw = !c.id;

    if (isRaw) {
      const tp = this.thirdParty ? this.thirdParty : <ThirdPartyBaseDto>{};
      tp.newCompanyRaw = <CompanyRawDto>c;
      tp.company = undefined;
      this.selectItem.emit({company: c, isRaw: isRaw, thirdParty: tp});
    } else {
      if (this.thirdParty) {
        this.thirdParty.company = c;
        this.thirdParty.newCompanyRaw = undefined;
        this.selectItem.emit({company: c, isRaw: isRaw, thirdParty: this.thirdParty});
      } else if (this.initThirdParty) {
        this.thirdPartyService.initialThirdParty(c.id).subscribe(
          (tp) => {
            this.selectItem.emit({company: c, isRaw: isRaw, thirdParty: tp});
            console.log('initialThirdParty', tp);
          },
          (error) => console.log(error)
        );
      } else {
        this.selectItem.emit({company: c, isRaw: isRaw, thirdParty: <ThirdPartyBaseDto>{company: c}});
      }
    }
    this.open.next(false);
    console.log('onSelectItem - shared:', c);
  }
}
