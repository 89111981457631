import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {LimitRequestDto} from '../../../bonding_shared/model';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {DictionaryBaseService} from '../../../bonding_shared/services';
import {RepositoryService} from '../../../bonding_shared/services/repository.service';

@Component({
  selector: 'limit-attachments-section',
  templateUrl: './limit-attachments-section.component.pug',
})
export class LimitAttachmentsSectionComponent extends ListEmitters implements OnInit {
  self = this;

  @Input() limitForm: UntypedFormGroup;
  @Input() showErrors: boolean;
  @Input() hidden = false;

  @Input() set limitRequest(req: LimitRequestDto) {
    this._limitRequest = req;
  }

  form: UntypedFormGroup;
  _limitRequest: LimitRequestDto;

  constructor(private dictionaryBaseService: DictionaryBaseService, private alfrescoService: RepositoryService) {
    super();
    this.initializeSelectorEmittersByNames(true, ['Attachment']);
    this.form = new UntypedFormGroup({});
  }

  ngOnInit() {
    setTimeout(() => {
      this.limitForm.addControl('attachmentForm', this.form);
    });
  }

  get limitRequest() {
    return this._limitRequest;
  }
}
