import {Subject} from 'rxjs';
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table';
import {BrokerContractVersionService, RouterService, SearchDataProvider} from '../../../../bonding_shared/services';
import {BrokerContractVersionCriteriaDto, BrokerContractVersionSimpleDto} from '../../../../bonding_shared/model';
import {TextSearchCriteria} from '../../../../bonding_shared/components/search/model/text-search-criteria';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';

@Component({
  selector: 'broker-contract-version-selector',
  templateUrl: './broker-contract-version-selector.component.pug',
})
export class BrokerContractVersionSelectorComponent {
  @ViewChild(ATableComponent, {static: true}) brokerContractTable: ATableComponent<BrokerContractVersionSimpleDto>;

  dataProvider: SearchDataProvider<BrokerContractVersionCriteriaDto, BrokerContractVersionSimpleDto>;

  @Input() title: string;
  @Input() labelKey: string;
  @Input() open: Subject<boolean>;
  @Input() textSearch = false;
  @Output() itemSelected = new EventEmitter<BrokerContractVersionSimpleDto>();

  @Input() set textCriteria(c: TextSearchCriteria) {
    this.dataProvider.textSearch = true;
    this.dataProvider.textSearchCriteria = c;
  }

  fixedSearch = false;
  visibleOnInit = false;

  constructor(public router: RouterService, private brokerContractService: BrokerContractVersionService) {
    this.dataProvider = BusinessUtils.createBrokerContractVersionDataProvider(brokerContractService);
    this.dataProvider.searchCriteria.criteria.last = true;
    this.dataProvider.textSearch = false;
  }
}
