import {Injectable} from '@angular/core';
import {
  CessionVersionCriteriaDto,
  CessionVersionDto,
  PolicyCessionVersionService,
  SearchDataProvider,
} from '../../../bonding_shared';

@Injectable()
export class PolicyCessionGuiService {
  searchDataProvider: SearchDataProvider<CessionVersionCriteriaDto, CessionVersionDto>;

  constructor(private service: PolicyCessionVersionService) {
    this.searchDataProvider = new SearchDataProvider<CessionVersionCriteriaDto, CessionVersionDto>(service);
  }
}
