import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {
  BondingContractInquiryVersionDto,
  BondingContractOfferBaseDto,
  BondingContractOfferCriteriaDto,
  BondingContractOfferSimpleDto,
} from '../../../../bonding_shared/model/dtos';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table/a-table.component';
import {BusinessPropertiesService} from '../../../../bonding_shared/services/business-properties.service';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {AppConfigService} from '../../../../bonding_shared/services';
import {BondingContractOfferService} from '../../../../bonding_shared/services/bonding-contract-offer.service';
import {BondingContractOfferViewParams} from '../bonding-contract-offer-details.component';
import * as _ from 'lodash';
import {BondingContractInquiryStatus} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'bonding-contract-offer-list',
  templateUrl: './bonding-contract-offer-list.component.pug',
})
export class BondingContractOfferListComponent implements AfterViewInit {
  initialSearchDone = false;
  dataProvider: SearchDataProvider<BondingContractOfferCriteriaDto, BondingContractOfferSimpleDto>;
  inquiryV: BondingContractInquiryVersionDto;

  @Input() set inquiryVersion(inqV: BondingContractInquiryVersionDto) {
    this.inquiryV = inqV;
    this.dataProvider.searchCriteria.criteria.inquiryVersion.id = inqV.id;
    if (this.initialSearchDone) {
      this.offerTable.search();
    }
  }

  get inquiryVersionId() {
    return this.dataProvider && this.dataProvider.searchCriteria.criteria.inquiryVersion.id;
  }

  @Input() set edge(edge: boolean) {
    this.dataProvider.searchCriteria.criteria.edge = edge;
  }

  get edge() {
    return this.dataProvider && this.dataProvider.searchCriteria.criteria.edge;
  }

  @ViewChild(ATableComponent, {static: true}) offerTable: ATableComponent<BondingContractOfferBaseDto>;

  constructor(
    public router: RouterService,
    private businessPropertiesService: BusinessPropertiesService,
    private offerService: BondingContractOfferService,
    private appService: AppConfigService
  ) {
    this.dataProvider = BusinessUtils.createBondingContractOfferDataProvider(this.offerService);
  }

  ngAfterViewInit() {
    this.offerTable.dataProvider = this.dataProvider;
    this.offerTable.search();
    this.initialSearchDone = true;
  }

  newOffer() {
    const getNewOfferParams = () => {
      const items = this.offerTable.items;
      // if edge offer exists then copy previous one
      if (items && items.length > 0) {
        return <BondingContractOfferViewParams>{
          copyOfferId: items[items.length - 1].id,
        };
      } else {
        return <BondingContractOfferViewParams>{
          inquiryVersionId: this.inquiryVersionId,
        };
      }
    };

    this.router.toBondingContractOfferDetails(null, getNewOfferParams());
  }

  get newOfferPossible() {
    return (
      (this.edge && this.inquiryV.businessStatus.id === BondingContractInquiryStatus.LIMIT_GRANTED) ||
      (!this.kuke && this.inquiryV && this.inquiryV.businessStatus.id === BondingContractInquiryStatus.ACCEPTED)
    );
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  getRange(n: number): number[] {
    return _.range(n);
  }
}
