import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DictionaryBaseDto, DictionaryDto, PolicyContractCountryDto} from '../../../../../bonding_shared/model';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService, CountryRatingService, DictionaryService} from '../../../../../bonding_shared/services';
import {concatMap} from 'rxjs/operators';
import {from as observableFrom} from 'rxjs';
import {
  IPasteFromExcelColumnConfig,
  IPasteFromExcelConfig,
} from '../../../../../bonding_shared/components/aku-table/services/paste-from-excel.service';
import {IMultiSelectSettings} from '../../../../../bonding_shared/components/multiselect-dropdown';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'kuke-countries-section',
  templateUrl: './kuke-countries-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KukeCountriesSectionComponent extends Section implements OnInit {
  self = this;

  private countryOptions: DictionaryDto[];
  private countriesById: DictionaryDto[];

  private nonMarketableCountryIds = [];
  private marketableCountryIds = [];

  private countryRatingsByCountryId: number[];

  // minimum acceptable country rating, which does not generate warning (the higher the number, the lower the rating)
  minRating = 4;

  @Output() countryTurnoverChanged = new EventEmitter();

  countryMultiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    showCheckAll: true,
    showUncheckAll: true,
  };

  pasteCountriesFromExcelConfigs: IPasteFromExcelConfig[] = [
    {
      columns: [
        <IPasteFromExcelColumnConfig>{
          type: 'dictionary',
          property: 'country',
          dictionary: 'Country',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'number',
          property: 'turnover',
        },
      ],
    },
  ];

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected dictionaryService: DictionaryService,
    protected countryRatingService: CountryRatingService
  ) {
    super(policyContractViewService, appService);
  }

  ngOnInit() {
    this.dictionaryService.getDictionary('Country').subscribe((data) => {
      this.countryOptions = data;
      this.countriesById = [];
      this.countryOptions.forEach((country) => (this.countriesById[country.id] = country));
    });

    this.countryRatingService
      .getLastCountryRatings()
      .pipe(
        concatMap((ratings) => {
          this.countryRatingsByCountryId = [];
          this.nonMarketableCountryIds = [];
          this.marketableCountryIds = [];
          return observableFrom(ratings);
        })
      )
      .subscribe((ratingVersion) => {
        this.countryRatingsByCountryId[ratingVersion.countryRating.country.id] = +ratingVersion.oecdRating.name;
        if (ratingVersion.nonMarketable) {
          this.nonMarketableCountryIds.push(ratingVersion.countryRating.country.id);
        } else {
          this.marketableCountryIds.push(ratingVersion.countryRating.country.id);
        }
      });
  }

  setNonMarketableCountries() {
    this.policy.insuredCountries = [];
    this.policy.insuredCountries = this.nonMarketableCountryIds.map(
      (nonMarkId) =>
        <PolicyContractCountryDto>{
          country: <DictionaryBaseDto>{
            id: nonMarkId,
            name: this.countriesById[nonMarkId].name,
          },
        }
    );
  }

  setMarketableCountries() {
    this.policy.insuredCountries = [];
    this.policy.insuredCountries = this.marketableCountryIds.map(
      (markId) =>
        <PolicyContractCountryDto>{
          country: <DictionaryBaseDto>{
            id: markId,
            name: this.countriesById[markId].name,
          },
        }
    );
  }

  emitCountryTurnoverChanged() {
    this.countryTurnoverChanged.emit();
  }
}
