import {Component, Input} from '@angular/core';
import {
  CollectionInvoiceDto,
  CollectionPaymentDto,
  CollectionVersionDto,
  DictionaryBaseDto,
} from '../../../bonding_shared/model';
import {UntypedFormGroup} from '@angular/forms';
import {CollectionPaymentAllocation, CollectionType} from '../../../bonding_shared/model/dictionary-ids';
import {CollectionGuiService} from '../services/collection-gui.service';
import {NumberUtils} from '../../../bonding_shared/utils/number-utils';

@Component({
  selector: 'collection-payments-table',
  templateUrl: 'collection-payments-table.component.pug',
})
export class CollectionPaymentsTableComponent {
  readonly CollectionType = CollectionType;

  @Input() payments: CollectionPaymentDto[];
  @Input() version: CollectionVersionDto;
  @Input() formModel: UntypedFormGroup;
  @Input() editable: boolean;
  @Input() showErrors: boolean;
  @Input() deleteButton: boolean;
  @Input() rowEditable: (item: CollectionPaymentDto) => boolean;
  @Input() allocation: 'INSURER' | 'INSURED' | 'GENERAL';

  areTheSameCurrencies(array: any[]): boolean {
    return (
      this.version.currency &&
      array &&
      array.every((inv) => inv.currency && inv.currency.id === this.version.currency.id)
    );
  }

  get unpaidInvoices(): CollectionInvoiceDto[] {
    return this.version.collectionInvoices.filter((e) => e.balance > 0);
  }

  maxAmount(payment: CollectionPaymentDto): number {
    return (
      this.payments &&
      payment &&
      payment.invoice &&
      payment.invoice.openAmountGross -
        NumberUtils.roundMoney(
          this.payments
            .filter((p) => p !== payment)
            .filter((p) => p.invoice && p.invoice.id === payment.invoice.id)
            .map((p) => p.amountGross)
            .reduce((a1, a2) => a1 + a2, 0)
        )
    );
  }

  setDefault(p: CollectionPaymentDto) {
    p.registrationDate = new Date();
    p.allocation = <DictionaryBaseDto>{id: CollectionPaymentAllocation[this.allocation]};
  }

  isCreditInsurance() {
    return CollectionGuiService.isCreditInsurance(this.version.parent);
  }

  isRecoveryCreditInsurance() {
    return CollectionGuiService.isRecoveryCreditInsurance(this.version.parent);
  }

  get dateOfPaymentLabel() {
    if (
      this.isRecoveryCreditInsurance() &&
      this.allocation === CollectionPaymentAllocation[CollectionPaymentAllocation.INSURER]
    ) {
      return 'collection.dateOfPaymentRecoveryInsurer';
    }
    return !this.isCreditInsurance() ? 'collection.dateOfPayment' : 'collection.dateOfPaymentInCredit';
  }

  get isRecoveryAndAllocationInsurer(): boolean {
    return (
      this.version.parent?.type?.id === CollectionType.RECOVERY_CREDIT_INSURANCE &&
      this.allocation === CollectionPaymentAllocation[CollectionPaymentAllocation.INSURER]
    );
  }
}
