import {Directive, Input, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ListEmitters} from '../../../../../bonding_shared/components/details-view/list-emitters';
import {PolicyContractDataSectionComponent} from '../../sections/policy-contract-data-section.component';
import {BankAccountSectionComponent} from '../../sections/bank-account-section.component';
import {PolicyContractVersionDto, PolicyProductConfigurationDto} from '../../../../../bonding_shared/model';
import {PolicyContractViewSection} from '../../../services/policy-contract-view.service';
import {PolicyContractVersionService} from '../../../../../bonding_shared/services';

@Directive()
export class CrPolicyProduct extends ListEmitters {
  @ViewChild('policyContractDataSectionComponent', {static: true})
  policyContractDataSectionComponent: PolicyContractDataSectionComponent;
  @ViewChild('bankAccountSectionComponent', {static: true}) bankAccountSectionComponent: BankAccountSectionComponent;

  self = this;

  viewSection = PolicyContractViewSection;
  productConfig: PolicyProductConfigurationDto;

  _policy: PolicyContractVersionDto;

  @Input() set policy(policy: PolicyContractVersionDto) {
    this._policy = policy;
  }

  get policy(): PolicyContractVersionDto {
    return this._policy;
  }

  @Input() policyForm: UntypedFormGroup;
  @Input() showErrors: boolean;

  constructor(policyContractVersionService: PolicyContractVersionService) {
    super();
    policyContractVersionService.getProductConfiguration().subscribe((conf) => (this.productConfig = conf));
  }
}
