import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {CompanyDto, DictionaryBaseDto, SearchResult} from '../../../../../bonding_shared/model';
import {CompanySelectorSharedComponent} from './company-selector-shared.component';
import {ChangePasswordComponent} from '../../../../../bonding_shared/components/user/change-password.component';
import {LoggedUserService} from '../../../../../bonding_shared';

@Component({
  selector: 'company-selector-bi',
  templateUrl: './company-selector-bi.component.pug',
})
/**
 * Selector for searching in given BI (must be passed to input)
 */
export class CompanySelectorBiComponent {
  @ViewChild(CompanySelectorSharedComponent, {static: true}) sharedComponent: CompanySelectorSharedComponent;

  @Input() biSource: DictionaryBaseDto;
  @Input() title: string;
  @Input() labelKey = 'common.searchForClient';
  @Input() open: Subject<boolean>;
  @Input() showErrors: boolean;
  @Input() disableOrderReportButton: boolean;
  @Output() orderReportButtonClicked = new EventEmitter<any>();
  @Output() selectItem = new EventEmitter<CompanyDto>();
  @Output() searchStart = new EventEmitter<void>();
  @Output() searchFinish = new EventEmitter<SearchResult<CompanyDto>>();
  @Input() countryId: number;
  inProgress = false;

  constructor(protected userService: LoggedUserService) {
    const user = userService.getLoggedUserData();
    if (user.company && user.company.address) {
      const country = user.company.address.country;
      if (country) {
        this.countryId = country.id;
      }
    }
  }

  orderReport() {
    this.orderReportButtonClicked.emit('Report ordered');
    this.open.next(false);
  }

  showOrderReportButton(): boolean {
    return !this.disableOrderReportButton && this.sharedComponent.searchDone;
  }

  onSearchStart() {
    this.searchStart.emit();
    this.inProgress = true;
  }

  onSearchFinish() {
    this.searchFinish.emit();
    this.inProgress = false;
  }
}
