import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {CustomValidators} from '../../validators';
import {ValueWithMultiplier} from './shared/value-with-multiplier';

@Directive({
  selector: '[greaterOrEqualThanValue]',
  providers: [{provide: NG_VALIDATORS, useExisting: GreaterOrEqualThanValueDirective, multi: true}],
})
export class GreaterOrEqualThanValueDirective implements Validator {
  @Input('greaterOrEqualThanValue') greaterOrEqualThanValue: number | ValueWithMultiplier;

  validate(control: AbstractControl): ValidationErrors | null {
    const f =
      this.greaterOrEqualThanValue instanceof ValueWithMultiplier
        ? CustomValidators.greaterOrEqualThanValue(
            this.greaterOrEqualThanValue.value,
            this.greaterOrEqualThanValue.baseMultiplier
          )
        : CustomValidators.greaterOrEqualThanValue(this.greaterOrEqualThanValue);
    return f(control);
  }
}
