export class RelatedObjectAction {
  static SEARCH = 'Search';
  static TOGGLE_LIST = 'ToggleList';
  static ACTIVATE_LIST = 'ActivateList';
  static HIDE_LIST = 'HideList';
  static CLEAR_SELECTED = 'ClearSelected';

  constructor(private _listType: string, private _action: string, private _searchText?: string) {}

  get listType() {
    return this._listType;
  }
  get action() {
    return this._action;
  }
  get searchText() {
    return this._searchText;
  }
}
