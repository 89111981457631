import {NgModule} from '@angular/core';
import {BondingContractRatesTableComponent} from './bonding-contract-rates-table/bonding-contract-rates-table.component';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [BondingContractRatesTableComponent],
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
})
export class BondingContractAdministrationModule {}
