import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BondVersionDto} from '../../../bonding_shared/model/index';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BondSubType, BondType} from '../../../bonding_shared/model/dictionary-ids';
import {CustomValidators} from '../../../bonding_shared/validators/index';

@Component({
  selector: 'it-bid-bond',
  templateUrl: 'it-bid-bond.component.html',
})
export class ItBidBondComponent implements OnInit {
  @Input() bond: BondVersionDto;

  public fixedPercentages = [{id: 0.4}, {id: 0.5}, {id: 0.6}, {id: 0.7}, {id: 0.8}, {id: 0.9}, {id: 1.0}, {id: 2.0}];

  // bidBondForm: UntypedFormGroup;
  @Input() bondForm: UntypedFormGroup;
  @Input() showErrors: boolean = false;
  inProgress: boolean = false;

  @Output() onValidationError = new EventEmitter<BondVersionDto>();

  constructor() {}

  ngOnInit(): void {
    this.bondForm.addControl(
      'fixedPercentage',
      new UntypedFormControl(
        {value: ''},
        Validators.compose([CustomValidators.minValue(0.4), CustomValidators.maxValue(2.0)])
      )
    );
    this.bondForm.addControl(
      'freePercentage',
      new UntypedFormControl(
        {value: ''},
        Validators.compose([CustomValidators.minValue(0.0), CustomValidators.maxValue(100.0)])
      )
    );
    this.bondForm.addControl('chargesNotSubjectToDiscount', new UntypedFormControl({value: ''}, Validators.required));
    this.bondForm.addControl(
      'minDiscountPercentage',
      new UntypedFormControl(
        {value: ''},
        Validators.compose([CustomValidators.minValue(0.0), CustomValidators.maxValue(100.0)])
      )
    );
    this.bondForm.addControl(
      'maxDiscountPercentage',
      new UntypedFormControl(
        {value: ''},
        Validators.compose([CustomValidators.minValue(0.0), CustomValidators.maxValue(100.0)])
      )
    );
  }

  @Input()
  set fixedPercentage(fixedPercentage: any) {
    if (fixedPercentage) {
      this.bond.fixedPercentage = fixedPercentage.id;
      this.calculate();
    }
  }

  calculate() {
    if (this.bond.fixedPercentage) {
      this.bond.values[0].value = this.bond.contractAmount * (this.bond.fixedPercentage / 100);
    }
  }

  bidBond(): boolean {
    return this.bond.type.id === BondType.BID_BOND_IT;
  }

  bidBondFixedPercentageSubtype(): boolean {
    return (
      this.bond.subtype && this.bond.subtype.id && this.bond.subtype.id === BondSubType.BID_BOND_WITH_FIXED_PERCENTAGE
    );
  }

  bidBondFreePercentageSubtype(): boolean {
    return (
      this.bond.subtype && this.bond.subtype.id && this.bond.subtype.id === BondSubType.BID_BOND_WITH_FREE_PERCENTAGE
    );
  }
}
