/**
 * Created by siminski on 03.08.2016.
 */
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {DictionaryBaseDto} from '../../model/dtos';
import {DictionaryProfile, LimitCategory} from '../../model/dictionary-ids';

const DICT_ROW_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DictRowComponent),
  multi: true,
};

@Component({
  selector: 'dict-row',
  template: `
    <div *ngIf="control" class="bon-row">
      <label class="bon-label">{{ labelKey ? (labelKey | translate) : label }}</label>
      <dict-combo
        [dictionary]="dictionary"
        [nullLabel]="nullLabel"
        [nullLabelKey]="nullLabelKey"
        [label]="itemLabel"
        (changeItem)="onChangeItem($event)"
        [parentDictionary]="parentDictionary"
        [parentDictionaryEntryId]="parentDictionaryEntryId"
        [disabled]="disabled"
        [codeRegexp]="codeRegexp"
        [ngModel]="value"
        [hiddenIds]="hiddenIds"
        [entries]="entries"
        [buId]="buId"
        [ngClass]="getCssClasses(control)"
        [profileId]="profileId"
        [floatRight]="floatRight"
        [presentationMode]="presentationMode"
      >
      </dict-combo>
      <error-message [control]="control" [show]="showErrors"></error-message>
    </div>
    <div *ngIf="!control" class="bon-row">
      <label class="bon-label">{{ labelKey ? (labelKey | translate) : label }}</label>
      <dict-combo
        #model="ngModel"
        [dictionary]="dictionary"
        [nullLabel]="nullLabel"
        [nullLabelKey]="nullLabelKey"
        [label]="itemLabel"
        (changeItem)="onChangeItem($event)"
        [parentDictionary]="parentDictionary"
        [parentDictionaryEntryId]="parentDictionaryEntryId"
        [allForEmptyParent]="allForEmptyParent"
        [disabled]="disabled"
        [ngClass]="getCssClasses(model.control)"
        [codeRegexp]="codeRegexp"
        [ngModel]="value"
        [entries]="entries"
        [hiddenIds]="hiddenIds"
        [buId]="buId"
        [chooseRequired]="chooseRequired"
        [required]="required"
        [profileId]="profileId"
        [floatRight]="floatRight"
        [presentationMode]="presentationMode"
      >
      </dict-combo>
      <error-message [control]="model.control" [show]="showErrors"></error-message>
    </div>
  `,
  providers: [DICT_ROW_CONTROL_VALUE_ACCESSOR],
})
export class DictRowComponent implements ControlValueAccessor {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() labelKey: string;
  @Input() disabled: any;
  @Input() presentationMode: boolean;
  @Input() showErrors: boolean;

  @Input() errorClass = '';
  @Input() warning = false;
  @Input() nullLabel: string;
  @Input() nullLabelKey: string;
  @Input() dictionary: string;
  @Input() parentDictionary: string;
  @Input() parentDictionaryEntryId: number;
  @Input() allForEmptyParent;
  @Input() itemLabel = 'name';
  @Input() codeRegexp: RegExp;

  @Input() buId: number;
  @Input() profileId: DictionaryProfile;

  // can be provided instead of dictionary
  @Input() entries: DictionaryBaseDto[];

  @Input() required: boolean;
  @Input() chooseRequired: boolean;

  @Input() floatRight = false;

  _hiddenIds: Set<number>;

  @Input() set hiddenIds(hiddenIds: Set<number> | Array<number>) {
    this._hiddenIds = hiddenIds instanceof Set ? hiddenIds : new Set(hiddenIds);
  }

  get hiddenIds() {
    return this._hiddenIds;
  }

  @Output() changeItem = new EventEmitter<DictionaryBaseDto>();

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  public value: any;

  private getCssClasses(control: AbstractControl) {
    const cssClasses: any = {};
    cssClasses['bon-input-size'] = true;
    if (this.warning) {
      cssClasses['bon-input-size-red'] = true;
    }
    if (control && control.invalid && this.showErrors) {
      cssClasses[this.errorClass] = true;
    }
    return cssClasses;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onChangeItem(event: DictionaryBaseDto) {
    if (event === undefined || event.id) {
      this.value = event;
    } else {
      this.value = event.code;
    }
    if (this.onChangeListeners) {
      this.onChangeListeners(this.value);
    }
    this.changeItem.emit(event);
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
