import {
  ClaimDto,
  ClaimLawsuitBaseDto,
  ClaimLawsuitDto,
  ClaimService,
  GrowlService,
  RouterService,
} from '../../bonding_shared';
import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {ActivatedRoute, Params} from '@angular/router';
import {NgForm} from '@angular/forms';
import {ClaimLawsuitService} from '../../bonding_shared/services/claim-lawsuit.service';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {
  BusinessObjectType,
  ClaimElementaryRight,
  ClaimLawsuitStatus,
  ServiceNoteElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'claim-lawsuit',
  templateUrl: 'claim-lawsuit.component.pug',
})
export class ClaimLawsuitComponent extends DetailsView implements OnInit {
  lawsuit = <ClaimLawsuitDto>{};
  versions: ClaimLawsuitBaseDto[];
  claim = <ClaimDto>{};
  isNewVersionCreation = false;
  firstVersionId: number;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  readonly BusinessObjectType = BusinessObjectType;
  readonly ClaimElementaryRight = ClaimElementaryRight;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  public constructor(
    private _route: ActivatedRoute,
    private service: ClaimLawsuitService,
    private claimService: ClaimService,
    public router: RouterService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.newVersionButton.hidden = true;
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
  }

  ngOnInit(): void {
    this.form = this.ngForm.form;
    this._route.queryParams.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    super.initializeView(params);
    if (params['claimId']) {
      const claimId = +params['claimId'];
      this.service.getLastVersionByClaimId(claimId).subscribe((lastLawsuitVersion) => {
        this.inProgress = false;
        if (lastLawsuitVersion) {
          this.setLawsuit(lastLawsuitVersion);
          this.fetchVersions(claimId);
          this.isNewVersionCreation = false;
        } else {
          this.service.getInitialVersion(claimId).subscribe((newLawsuit) => {
            this.isNewVersionCreation = true;
            this.setLawsuit(newLawsuit);
          });
        }
      });
      this.fetchClaim(claimId);
    } else {
      this.service.getById<ClaimLawsuitDto>(+params['versionId']).subscribe((version) => {
        this.inProgress = false;
        this.setLawsuit(version);
        const claimId = version.claim.id;
        this.fetchVersions(claimId);
        this.fetchClaim(claimId);
      });
    }
  }

  setLawsuit(lawsuit: ClaimLawsuitDto) {
    this.lawsuit = lawsuit;
    this.refreshButtons();
  }

  get isLawsuitLost(): boolean {
    return DictionaryUtils.equalsDictAndId(this.lawsuit.status, ClaimLawsuitStatus.LAWSUIT_LOST);
  }

  get isLawsuitClosed(): boolean {
    return !DictionaryUtils.equalsDictAndId(this.lawsuit.status, ClaimLawsuitStatus.IN_PROGRESS);
  }

  onCreateNewVersion() {
    this.service.newVersion<ClaimLawsuitDto>(this.lawsuit.claim.id).subscribe((newVersion) => {
      this.isNewVersionCreation = true;
      this.setLawsuit(newVersion);
    });
  }

  onSelectLawsuitVersion(lawsuitVersionId: number) {
    this.service.getById<ClaimLawsuitDto>(lawsuitVersionId).subscribe((lawsuit) => this.setLawsuit(lawsuit));
  }

  onSave() {
    this.serverErrors = undefined;
    this.showErrors = false;
    if (this.formValidates()) {
      this.inProgress = true;
      this.service.save(this.lawsuit).subscribe({
        next: (savedLawsuit) => {
          this.afterObjectSaved();
          this.isNewVersionCreation = false;
          this.setLawsuit(savedLawsuit);
          this.fetchVersions(savedLawsuit.claim.id);
        },
        error: (error) => {
          this.handleServerError(error);
          this.inProgress = false;
        },
      });
    }
  }

  onCancel() {
    super.onCancel();
    this.initializeView(this._route.snapshot.queryParams);
    this.refreshButtons();
  }

  refreshButtons() {
    const lastVersionSelected = this.lawsuit && this.lawsuit.last;
    this.saveButton.hidden = !(lastVersionSelected || this.isNewVersionCreation);
    this.cancelButton.hidden = !(lastVersionSelected || this.isNewVersionCreation);
    this.newVersionButton.hidden = !(
      lastVersionSelected &&
      this.lawsuit.id &&
      !DictionaryUtils.equalsDictAndId(this.lawsuit.status, ClaimLawsuitStatus.IN_PROGRESS)
    );
  }

  get newOrActiveVersion() {
    return this.lawsuit && (!this.lawsuit.id || this.lawsuit.last);
  }

  private fetchVersions(claimId: number) {
    this.service.findLawsuitVersionsByClaimId(claimId).subscribe((lawsuitVersions) => {
      this.versions = lawsuitVersions;
      this.firstVersionId = this.versions[0].id;
    });
  }

  private fetchClaim(claimId: number) {
    this.claimService.getClaim(claimId).subscribe((claim) => {
      this.claim = claim;
    });
  }
}
