import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {GroupAssignmentRulesComponent} from './group-assignment-rules.component';
import {LimitAssignmentCriteriaComponent} from './limit-assignment-criteria.component';
import {CollectionAssignmentCriteriaComponent} from './collection-assignment-criteria.component';
import {DictionaryAssignmentCriteriaComponent} from './dictionary-assignment-criteria.component';
import {UserAssignmentRulesComponent} from './user-assignment-rules.component';
import {ClaimAssignmentCriteriaComponent} from './claim-assignment-criteria.component';
import {SalesRegionAssignmentCriteriaComponent} from './sales-region-assignment-criteria.component';
import {AssignmentRuleAccessGuard} from './assignment-rule-access-guard';
import {PolicyUnderwritingAssignmentCriteriaComponent} from './policy-underwriting-assignment-criteria.component';
import {UserAssignmentCriteriaComponent} from './user-assignment-criteria.component';
import {VipCriteriaComponent} from './vip-criteria.component';
import {PolicyAssignmentCriteriaComponent} from './policy-assignment-criteria.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    BsDropdownModule,
  ],
  declarations: [
    GroupAssignmentRulesComponent,
    LimitAssignmentCriteriaComponent,
    CollectionAssignmentCriteriaComponent,
    ClaimAssignmentCriteriaComponent,
    PolicyUnderwritingAssignmentCriteriaComponent,
    PolicyAssignmentCriteriaComponent,
    DictionaryAssignmentCriteriaComponent,
    SalesRegionAssignmentCriteriaComponent,
    UserAssignmentCriteriaComponent,
    UserAssignmentRulesComponent,
    VipCriteriaComponent,
  ],
  exports: [],
  providers: [AssignmentRuleAccessGuard],
})
export class AssignmentRuleModule {}
