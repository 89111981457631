import {Component, Input} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {CollectionRequestDto} from '../../../bonding_shared/model';
import {TranslateService} from '@ngx-translate/core';
import {DateUtils} from '../../../bonding_shared/utils/date-utils';
import {PolicyContractType} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'collection-request-section',
  templateUrl: 'collection-request-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class CollectionRequestSectionComponent {
  @Input() formModel: NgForm;
  @Input() request: CollectionRequestDto;
  @Input() showErrors: boolean;
  @Input() policyTypeId: number;

  get minValidFrom(): Date {
    if (this.request?.acceptFurtherDeliveriesRequest) {
      if (this.policyTypeId === PolicyContractType.KUKE_GSP_PBG) {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), 1);
      }
      return DateUtils.addDay(new Date(), -30);
    }
    return null;
  }

  constructor(private translateService: TranslateService) {}

  getCommentTooltip(): string {
    let result = '';
    if (this.request.putOnHoldRequest) {
      result += this.translateService.instant('collection.requests.putOnHoldRequestTooltip') + ' ';
    }
    if (this.request.otherRequest) {
      result += this.translateService.instant('collection.requests.otherRequestTooltip');
    }
    return result;
  }
}
