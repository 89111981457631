import {Component, Input} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {LimitCoverPlusBaseDto} from '../../../bonding_shared/model';
import {RouterService} from '../../../bonding_shared/services/router-service';
import {LimitCoverPlusStatus} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'limit-cover-plus',
  templateUrl: './limit-cover-plus.component.pug',
  styles: [
    '.bon-bcg{ background-color: #4f922217;}',
    '.bon-bcg-unset{ background-color: unset;}',
    '.bon-font-worn{font-size: 1.05rem; color: #770b08; font-weight: 600;}',
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class LimitCoverPlusComponent {
  @Input() readOnly = true;
  @Input() showErrors = false;
  @Input() portalMode = true;
  @Input() cover: LimitCoverPlusBaseDto;
  @Input() showAdditionalLimitFlag = false;
  @Input() expandable: boolean;

  LimitCoverPlusStatus = LimitCoverPlusStatus;

  constructor(public router: RouterService) {}

  coverProposal(): boolean {
    return [
      LimitCoverPlusStatus.PROPOSAL,
      LimitCoverPlusStatus.OUTDATED_PROPOSAL,
      LimitCoverPlusStatus.CANCELLED_PROPOSAL,
    ].includes(this.cover.status.id);
  }
}
