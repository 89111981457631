import {
  AppConfigService,
  BondVersionService,
  ClaimService,
  ClaimVersionDto,
  CollectionVersionService,
  DictionaryBaseDto,
  DictionaryBaseService,
  DocumentService,
  GrowlService,
  LimitService,
  LoggedUserService,
  PolicyCessionVersionService,
  PolicyContractVersionService,
  RouterService,
  TemplateService,
} from '../../bonding_shared';
import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {ClaimGuiService} from './services/claim-gui.service';
import {ClaimRiskType, CollectionType} from '../../bonding_shared/model/dictionary-ids';
import {ClaimDetailsComponent} from './claim-details.component';
import {CollectionListComponent} from '../collection/components/shared/collection-list.component';
import {ClaimListComponent} from './components/shared/claim-list.component';
import {CollectionGuiService} from '../collection/services/collection-gui.service';
import {Location} from '@angular/common';
import {PolicyDrawdownVersionService} from '../../bonding_shared/services/policy-drawdown-version.service';
import {ClaimRecoveriesService} from '../../bonding_shared/services/claim-recoveries.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'claim-linked-claims',
  templateUrl: 'claim-linked-claims.component.pug',
})
export class ClaimLinkedClaimsComponent extends ClaimDetailsComponent {
  readonly ClaimRiskType = ClaimRiskType;
  @ViewChild('otherClaimsFromGivenCollection') otherClaimsFromGivenCollection: ClaimListComponent;
  @ViewChild('claimsForSameRiskAndPolicyAndDifferentCollection')
  claimsForSameRiskAndPolicyAndDifferentCollection: ClaimListComponent;
  @ViewChild('allCollections') allCollections: CollectionListComponent;
  @ViewChild('claimsWhereCurrentRiskIsBeneficiary')
  claimsWhereCurrentRiskIsBeneficiary: ClaimListComponent;
  @ViewChild('recoveryCollections') recoveryCollections: CollectionListComponent;
  @ViewChild('productionRiskOtherClaims') productionRiskOtherClaims: ClaimListComponent;
  @ViewChild('suretyOtherClaims') suretyOtherClaims: ClaimListComponent;
  @ViewChild('allClaimsForSamePolicyRiskPair') allClaimsForSamePolicyRiskPair: ClaimListComponent;
  @ViewChild('allSuretyClaimsFromSameBond') allSuretyClaimsFromSameBond: ClaimListComponent;

  constructor(
    _route: ActivatedRoute,
    claimService: ClaimService,
    guiService: ClaimGuiService,
    templateService: TemplateService,
    router: RouterService,
    loggedUserService: LoggedUserService,
    translateService: TranslateService,
    growlService: GrowlService,
    appService: AppConfigService,
    dictService: DictionaryBaseService,
    cessionService: PolicyCessionVersionService,
    policyVersionService: PolicyContractVersionService,
    policyDrawdownVersionService: PolicyDrawdownVersionService,
    documentService: DocumentService,
    location: Location,
    bondService: BondVersionService,
    limitService: LimitService,
    claimRecoveriesService: ClaimRecoveriesService,
    private collectionVersionService: CollectionVersionService
  ) {
    super(
      _route,
      claimService,
      guiService,
      templateService,
      router,
      loggedUserService,
      translateService,
      growlService,
      appService,
      dictService,
      cessionService,
      policyVersionService,
      policyDrawdownVersionService,
      documentService,
      location,
      bondService,
      limitService,
      claimRecoveriesService
    );
  }

  initializeView(params: Params) {
    this.clearErrors();
    this.loadClaimVersion(+params['claimVersionId']);
  }

  setClaimVersion(claimVersion: ClaimVersionDto) {
    super.setClaimVersion(claimVersion);
    setTimeout(() => {
      this.performSearch(claimVersion);
    });
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }

  private performSearch(claimVersion: ClaimVersionDto) {
    if (this.kuke) {
      switch (claimVersion.claim.riskType.id) {
        case ClaimRiskType.CREDIT_INSURANCE:
          this.searchForOtherClaimsFromGivenCollection(claimVersion);
          this.searchForClaimsForSameRiskAndPolicyAndDifferentCollection(claimVersion);
          this.searchForAllCollections(claimVersion);
          this.searchForClaimsWhereRiskIsBeneficiary(claimVersion);
          this.searchForRecoveryCollections(claimVersion);
          this.searchForProductionRiskClaims(claimVersion);
          break;
        case ClaimRiskType.PROD_RISK:
          this.searchForClaimsForSameRiskAndPolicyAndDifferentCollection(claimVersion);
          this.searchForAllCollections(claimVersion);
          this.searchForClaimsWhereRiskIsBeneficiary(claimVersion);
          this.searchForRecoveryCollections(claimVersion);
          this.searchForProductionRiskClaims(claimVersion);
          break;
        case ClaimRiskType.SURETY:
          this.searchForSuretyClaims(claimVersion);
          break;
      }
    } else if (this.ecg) {
      switch (claimVersion.claim.riskType.id) {
        case ClaimRiskType.CREDIT_INSURANCE:
          this.searchForAllClaimsForSamePolicyRiskPair(claimVersion);
          break;
        case ClaimRiskType.SURETY:
          this.searchForAllSuretyClaimsFromSameBond(claimVersion);
          break;
      }
    }
  }

  private searchForOtherClaimsFromGivenCollection(claimVersion: ClaimVersionDto) {
    const provider = ClaimGuiService.createDataProvider(this.claimService);
    const criteria = provider.searchCriteria.criteria;
    criteria.collectionNumber = claimVersion.claim.collection.number;
    criteria.notClaimId = claimVersion.claim.id;
    setTimeout(() => {
      this.otherClaimsFromGivenCollection.dataProvider = provider;
    });
  }

  private searchForClaimsForSameRiskAndPolicyAndDifferentCollection(claimVersion: ClaimVersionDto) {
    const provider = ClaimGuiService.createDataProvider(this.claimService);
    const criteria = provider.searchCriteria.criteria;
    criteria.masterPolicyContractId = claimVersion.claim.policyContract.masterPolicyId;
    criteria.claim.risk = claimVersion.claim.risk;
    criteria.notCollectionNumber = claimVersion.claim.collection?.number;
    setTimeout(() => {
      this.claimsForSameRiskAndPolicyAndDifferentCollection.dataProvider = provider;
    });
  }

  private searchForClaimsWhereRiskIsBeneficiary(claimVersion: ClaimVersionDto) {
    const provider = ClaimGuiService.createDataProvider(this.claimService);
    const criteria = provider.searchCriteria.criteria;
    criteria.claim.mainBeneficiary = claimVersion.claim.risk;
    setTimeout(() => {
      this.claimsWhereCurrentRiskIsBeneficiary.dataProvider = provider;
    });
  }

  private searchForAllCollections(claimVersion: ClaimVersionDto) {
    const provider = CollectionGuiService.createProvider(this.collectionVersionService);
    const criteria = provider.searchCriteria.criteria;
    criteria.parent.policy.masterPolicyContract.id = claimVersion.claim.policyContract.masterPolicyId;
    criteria.parent.debtor = claimVersion.claim.risk;
    criteria.types = CollectionType.CREDIT_INSURANCE;
    setTimeout(() => {
      this.allCollections.table.dataProvider = provider;
      this.allCollections.table.search();
    });
  }

  private searchForRecoveryCollections(claimVersion: ClaimVersionDto) {
    const provider = CollectionGuiService.createProvider(this.collectionVersionService);
    const criteria = provider.searchCriteria.criteria;
    criteria.parent.debtor = claimVersion.claim.mainBeneficiary;
    criteria.types = CollectionType.RECOVERY_TYPES;
    setTimeout(() => {
      this.recoveryCollections.table.dataProvider = provider;
      this.recoveryCollections.table.search();
    });
  }

  private searchForProductionRiskClaims(claimVersion: ClaimVersionDto) {
    const provider = ClaimGuiService.createDataProvider(this.claimService);
    const criteria = provider.searchCriteria.criteria;
    criteria.masterPolicyContractId = claimVersion.claim.policyContract.masterPolicyId;
    criteria.claim.risk = claimVersion.claim.risk;
    criteria.claim.riskType = <DictionaryBaseDto>{id: ClaimRiskType.PROD_RISK};
    criteria.notClaimId = claimVersion.claim.id;
    setTimeout(() => {
      this.productionRiskOtherClaims.dataProvider = provider;
    });
  }

  private searchForSuretyClaims(claimVersion: ClaimVersionDto) {
    const provider = ClaimGuiService.createDataProvider(this.claimService);
    const criteria = provider.searchCriteria.criteria;
    criteria.bondId = claimVersion.claim.bondVersion.bond.id;
    criteria.notClaimId = claimVersion.claim.id;
    setTimeout(() => {
      this.suretyOtherClaims.dataProvider = provider;
    });
  }

  private searchForAllSuretyClaimsFromSameBond(claimVersion: ClaimVersionDto) {
    const provider = ClaimGuiService.createDataProvider(this.claimService);
    const criteria = provider.searchCriteria.criteria;
    criteria.bondId = claimVersion.claim.bondVersion.bond.id;
    setTimeout(() => {
      this.allSuretyClaimsFromSameBond.dataProvider = provider;
    });
  }

  private searchForAllClaimsForSamePolicyRiskPair(claimVersion: ClaimVersionDto) {
    const provider = ClaimGuiService.createDataProvider(this.claimService);
    const criteria = provider.searchCriteria.criteria;
    criteria.claim.risk = claimVersion.claim.risk;
    criteria.policyContractId = claimVersion.claim.policyContract.id;
    setTimeout(() => {
      this.allClaimsForSamePolicyRiskPair.dataProvider = provider;
    });
  }
}
