import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {InvoicePaymentCriteriaDto, InvoicePaymentSimpleDto, SearchCriteria, SearchResult} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class InvoicePaymentService extends AbstractService {
  protected url = this.urlPrefix + 'invoicePayment';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  search(criteria: SearchCriteria<InvoicePaymentCriteriaDto>): Observable<SearchResult<InvoicePaymentSimpleDto>> {
    console.log('searchInvoicePayments: http = ' + this.http);
    return this.post1<SearchCriteria<InvoicePaymentCriteriaDto>, SearchResult<InvoicePaymentSimpleDto>>(
      criteria,
      this.url + 'search'
    );
  }
}
