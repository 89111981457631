<details-view [title]="'Report'" [objectName]="'Report'" [versionListHidden]="true" [fixedBlockHidden]="false" [parentComponent]="self">
    <div class="details-content">
        <form>
            <div class="bon-header">Reports details</div>
            <div class="bon-card-group">
                <div class="bon-card-inner">
                    <input-row label="Id" [(ngModel)]="report.id" readonly="true" [ngModelOptions]="{standalone: true}"></input-row>
                    <dict-row label="Type" [(ngModel)]="report.type" dictionary="ReportType" [disabled]="true"
                              [ngModelOptions]="{standalone: true}"></dict-row>
                    <dict-row label="Status" [(ngModel)]="report.status" dictionary="ReportStatus" [disabled]="true"
                              [ngModelOptions]="{standalone: true}"></dict-row>
                </div>
                <div class="bon-card-inner">
                    <date-row label="Date from" [(ngModel)]="report.dateFrom" [disabled]="true"
                              [ngModelOptions]="{standalone: true}"></date-row>
                    <date-row label="Date to" [(ngModel)]="report.dateTo" [disabled]="true"
                              [ngModelOptions]="{standalone: true}"></date-row>
                    <date-row label="Creation date" [(ngModel)]="report.creationDate" [disabled]="true"
                              [ngModelOptions]="{standalone: true}"></date-row>
                </div>
                <div class="bon-card-inner">
                    <text-row label="Created by" [value]="report.creationUser?.fullName")></text-row>
                    <div class="card-block">
                        <div (click)="getFileCredits(report)" class="btn btn-secondary">Get credits report</div>
                        <div (click)="getFileDebtors(report)" class="btn btn-secondary">Get debtors report</div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</details-view>
