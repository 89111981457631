import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ClaimVersionDto, ClaimVersionValueDto} from '../../../bonding_shared/model';
import {ControlContainer, NgForm} from '@angular/forms';
import {
  ClaimAlgorithm,
  ClaimCauseOfLoss,
  ClaimRiskType,
  ClaimVersionValueType,
  PolicyContractType,
} from '../../../bonding_shared/model/dictionary-ids';
import {AppConfigService} from '../../../bonding_shared';
import {DictionaryUtils} from '../../../bonding_shared/utils/dictionary-utils';

@Component({
  selector: 'credendo-claim-calculation',
  templateUrl: 'credendo-claim-calculation.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class CredendoClaimCalculationComponent {
  @Input() form: NgForm;
  @Input() claimVersion: ClaimVersionDto;
  @Input() showErrors: boolean;
  @Input() suretyType: boolean;
  @Input() readonly: boolean;
  @Input() commentsReadonly: boolean;

  @Output() calculate = new EventEmitter<void>();

  constructor(public appService: AppConfigService) {}

  isSaved(): boolean {
    return this.claimVersion && this.claimVersion.id && this.claimVersion.id > 0;
  }

  isValueEditable(value: ClaimVersionValueDto): boolean {
    if (this.isSpecialSrAlgorithm) {
      return true;
    } else if (this.isBasicSrAlgorithm) {
      return (
        DictionaryUtils.in(
          value.type,
          ClaimVersionValueType.AFTER_RECOVERY,
          ClaimVersionValueType.INSURED_AMOUNT,
          ClaimVersionValueType.SHARE_PERCENT
        ) ||
        (this.claimVersion?.specialConditions &&
          DictionaryUtils.in(value.type, ClaimVersionValueType.INSURANCE_COVER, ClaimVersionValueType.DEDUCTIBLES))
      );
    } else {
      return (
        this.claimVersion?.specialConditions &&
        DictionaryUtils.in(
          value.type,
          ClaimVersionValueType.NQL,
          ClaimVersionValueType.INSURANCE_COVER,
          ClaimVersionValueType.DEDUCTIBLES
        )
      );
    }
  }

  valueChange(value: ClaimVersionValueDto) {
    value.customized = true;
    this.calculate.emit();
  }

  cleanCustomValuesAndRecalculate() {
    this.claimVersion.values = undefined;
    this.calculate.emit();
  }

  clearSelectedConditions(check: boolean) {
    if (!check) {
      for (const value of this.claimVersion.values) {
        value.customized = false;
      }
      this.calculate.emit();
    }
  }

  isThreat() {
    if (this.claimVersion && this.claimVersion.claimCauseOfLoss) {
      return this.claimVersion.claimCauseOfLoss.id === ClaimCauseOfLoss.LOSS_THREAT;
    }
    return false;
  }

  get policyContractType() {
    return DictionaryUtils.in(this.claimVersion?.claim?.riskType, ClaimRiskType.CREDIT_INSURANCE)
      ? this.claimVersion?.claim?.policyContractVersion?.contractType
      : undefined;
  }

  get isSpecialSrAlgorithm() {
    return DictionaryUtils.in(this.claimVersion?.algorithm, ClaimAlgorithm.SPECIAL_SR);
  }

  get isBasicSrAlgorithm() {
    return DictionaryUtils.in(this.claimVersion?.algorithm, ClaimAlgorithm.BASIC_SR);
  }

  get valuesEditable() {
    return this.isSpecialSrAlgorithm || this.isBasicSrAlgorithm || this.claimVersion?.specialConditions;
  }

  isTopUpPolicy() {
    return PolicyContractType.TOP_UP === this.policyContractType?.id;
  }

  get isSingleRiskOrTurnoverPolicy() {
    return [PolicyContractType.SINGLE_RISK, PolicyContractType.TURNOVER].includes(this.policyContractType?.id);
  }

  isSuretyType(): boolean {
    return this.suretyType;
  }

  onChangeVerifiedLossAmount() {
    if (this.claimVersion.verifiedLossAmount) {
      this.claimVersion.insuredAmount = this.claimVersion.verifiedLossAmount;
    }
  }
}
