import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {InquirySelectorSimpleComponent} from './inquiry-selector-simple.component';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';

import {CompanySharedModule} from '../../company/components/shared/company-shared.module';
import {OfferTabLayoutComponent} from './offer-tab-layout.component';
import {PolicyContractSharedModule} from '../../policy/components/shared/policy-contract-shared.module';
import {KukeInquiryOffersComponent} from './kuke-inquiry-offers.component';
import {RatedLimitsComponent} from './rated-limits.component';
import {OfferCountriesSumComponent} from './offer-countries-sum.component';
import {PolicyClauseModule} from '../../policy/clause/policy-clause.module';
import {TabViewModule} from 'primeng/tabview';
import {OfferDecisionsComponent} from './offer-decisions.component';
import {InquirySurveyComponent} from '../inquiry-survey.component';

import {InquiryLimitsComponent} from './inquiry-limits.component';
import {InquirySubinsuredsListComponent} from './inquiry-subinsureds-list.component';
import {OfferHistoryComponent} from './offer-history.component';
import {InquiryLifecycleDropdownComponent} from './inquiry-lifecycle-dropdown.component';
import {InquiryNewLimitDropdownComponent} from './inquiry-new-limit-dropdown.component';
import {KukeOfferLifecycleComponent} from './kuke-offer-lifecycle.component';
import {PlannedSalesGeographicalComponent} from './planned-sales-geographical.component';
import {InquiryCountriesComponent} from './inquiry-countries.component';
import {OfferSelectorSectionComponent} from '../forms/sections/offer-selector-section.component';
import {InquiryBalancesComponent} from './inquiry-balances.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    CompanySharedModule,
    PolicyContractSharedModule,
    PolicyClauseModule,
    TabViewModule,
  ],
  declarations: [
    InquirySelectorSimpleComponent,
    OfferTabLayoutComponent,
    KukeInquiryOffersComponent,
    RatedLimitsComponent,
    OfferDecisionsComponent,
    OfferCountriesSumComponent,
    InquirySurveyComponent,
    InquiryLimitsComponent,
    OfferHistoryComponent,
    InquirySubinsuredsListComponent,
    InquiryLifecycleDropdownComponent,
    InquiryNewLimitDropdownComponent,
    KukeOfferLifecycleComponent,
    PlannedSalesGeographicalComponent,
    InquiryCountriesComponent,
    OfferSelectorSectionComponent,
    InquiryBalancesComponent,
  ],
  exports: [
    InquirySelectorSimpleComponent,
    OfferCountriesSumComponent,
    KukeInquiryOffersComponent,
    InquirySurveyComponent,
    RatedLimitsComponent,
    InquiryLimitsComponent,
    InquirySubinsuredsListComponent,
    OfferTabLayoutComponent,
    OfferDecisionsComponent,
    OfferHistoryComponent,
    InquiryLifecycleDropdownComponent,
    InquiryNewLimitDropdownComponent,
    KukeOfferLifecycleComponent,
    PlannedSalesGeographicalComponent,
    InquiryCountriesComponent,
    OfferSelectorSectionComponent,
    InquiryBalancesComponent,
  ],
})
export class InquirySharedModule {}
