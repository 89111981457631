import {Component, Input} from '@angular/core';
import {
  PolicyInquiryOfferDto,
  PolicyInquiryOfferSimpleDto,
  PolicyInquiryVersionDto,
} from '../../../bonding_shared/model';
import {RouterService} from '../../../bonding_shared/services';
import {
  PolicyElementaryRight,
  PolicyInquiryOfferStatus,
  PolicyInquiryStatus,
} from '../../../bonding_shared/model/dictionary-ids';
import {StringUtils} from '../../../bonding_shared/utils';
import {ClientOfferAllowance, isAnnexInquiry} from '../domain/offer-allowance';

@Component({
  selector: 'kuke-inquiry-offers',
  templateUrl: 'kuke-inquiry-offers.component.pug',
  styles: [],
})
export class KukeInquiryOffersComponent {
  readonly PolicyElementaryRight = PolicyElementaryRight;

  private _inquiry: PolicyInquiryVersionDto;
  private _parentOffer: PolicyInquiryOfferDto;
  offers: PolicyInquiryOfferSimpleDto[];
  subOffers: SubOffer[] = [];

  @Input() addOfferButton = true;
  @Input() addOfferEdge = true;

  @Input() set inquiry(value: PolicyInquiryVersionDto) {
    this._inquiry = value;
    if (this._inquiry) {
      const inqOffers = this._inquiry.offers;
      this.offers = inqOffers;

      if (inqOffers && inqOffers.length > 0) {
        const flatMapChildren = (acc: SubOffer[], val: PolicyInquiryOfferDto) => {
          const children = val.children;
          if (children && children.length > 0) {
            return acc.concat(children.map((v) => <SubOffer>{offer: v, parent: val}));
          }
          return acc;
        };
        this.subOffers = inqOffers.reduce(flatMapChildren, []);
      } else {
        this.subOffers = [];
      }
    } else {
      this.offers = [];
    }
  }

  @Input() set parentOffer(value: PolicyInquiryOfferDto) {
    this._parentOffer = value;
    if (this._parentOffer && this._parentOffer.children) {
      this.offers = this._parentOffer.children;
      this.offers.sort((a, b) => (b.number < a.number ? 1 : -1));
    } else {
      this.offers = [];
    }
  }

  static createdBy(item: PolicyInquiryOfferSimpleDto): string {
    return StringUtils.userName(item.createdBy);
  }

  constructor(public router: RouterService) {}

  goToOffer(offerId: number) {
    this.router.toKukeInquiryOfferDetails(offerId);
  }

  isNewOfferAllowed(): boolean {
    const inq = this._inquiry;
    const inquiryEmptyOrAccepted =
      !inq ||
      (inq &&
        inq.status &&
        (inq.status.id === PolicyInquiryStatus.POLICY_INQUIRY_ACCEPTED ||
          inq.status.id === PolicyInquiryStatus.POLICY_INQUIRY_REGISTERED));
    const allowed =
      !inq ||
      ClientOfferAllowance.isOfferAllowed(
        inq && inq.productType,
        inq &&
          inq.offers &&
          inq.offers.length > 0 &&
          inq.offers.filter((o) => o.status.id !== PolicyInquiryOfferStatus.EXPIRED).length > 0,
        isAnnexInquiry(inq)
      );
    return this.addOfferButton && inquiryEmptyOrAccepted && allowed;
  }

  newOffer() {
    const isNewEdge = this.addOfferEdge && this.isClientOfferAllowed();
    if (this._inquiry) {
      this.router.toNewKukeInquiryOfferDetails(this._inquiry.id, this._inquiry.productType.id, isNewEdge, null);
    } else if (this._parentOffer) {
      this.router.toNewKukeInquiryOfferDetails(
        this._parentOffer.policyInquiryVersion.id,
        this._parentOffer.type.id,
        isNewEdge,
        this._parentOffer.id
      );
    }
  }

  isClientOfferAllowed() {
    return ClientOfferAllowance.isClientOfferAllowed(this._inquiry && this._inquiry.productType);
  }

  getNewClientOfferLabelKey() {
    if (this._parentOffer && this._parentOffer.edge) {
      return 'offer.newClientOffer';
    }
    return 'offer.newOffer';
  }
}

interface SubOffer {
  offer: PolicyInquiryOfferSimpleDto;
  parent: PolicyInquiryOfferSimpleDto;
}
