import {ClientInvoiceListComponent} from './client-invoice-list.component';
import {ClientInvoicePreviewComponent} from './client-invoice-preview.component';

export const ClientInvoiceRoutes = [
  {
    path: 'client-invoice-list',
    component: ClientInvoiceListComponent,
  },
  {
    path: 'client-invoice-list/:businessObjectTypeId/:businessObjectId',
    component: ClientInvoiceListComponent,
  },
  {
    path: 'client-invoice-list/:businessObjectTypeId/:businessObjectId/:businessObjectNumber',
    component: ClientInvoiceListComponent,
  },
  {
    path: 'client-invoice-preview/:category/:id',
    component: ClientInvoicePreviewComponent,
  },
];
