import {Injectable} from '@angular/core';
import {LoggedUserService} from '../../../bonding_shared/services';
import {PolicyLimitListService} from '../../../bonding_shared/services/policy-limit-list.service';
import {PolicyLimitListSearchCriteria} from '../model/policylimitlist-search-criteria';

@Injectable()
export class PolicyLimitListGuiService {
  searchCriteria: PolicyLimitListSearchCriteria;

  constructor(private policyLimitListService: PolicyLimitListService, loggedUserService: LoggedUserService) {
    this.searchCriteria = new PolicyLimitListSearchCriteria(policyLimitListService);
  }
}
