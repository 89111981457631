import {Observable} from 'rxjs';

import {publishLast} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CityBaseDto, PostCodeDto, ProvinceDto} from '../model';
import {GeoDictService} from './geo-dict.service';
import {GeoDictBaseService} from './geo-dict-base.service';

@Injectable()
export class AddressAutocompleteService {
  constructor(private geoDictService: GeoDictService, private geoDictBaseService: GeoDictBaseService) {}

  private citiesPerCountry: {[key: string]: Observable<CityBaseDto[]>} = {};
  private postCodesPerCountry: {[key: string]: Observable<PostCodeDto[]>} = {};
  private provincesPerCountry: {[key: string]: Observable<ProvinceDto[]>} = {};

  public getCityBaseGeoDict(countryId: number): Observable<CityBaseDto[]> {
    if (!this.citiesPerCountry[countryId]) {
      const cities = this.geoDictBaseService.getCities('country', countryId).pipe(publishLast());
      (<any>cities).connect(); // https://github.com/ReactiveX/rxjs/issues/2972
      this.citiesPerCountry[countryId] = cities;
    }
    return this.citiesPerCountry[countryId];
  }

  public getPostCodeGeoDict(countryId: number): Observable<PostCodeDto[]> {
    if (!this.postCodesPerCountry[countryId]) {
      const countryPostCodes = this.geoDictService.getPostCodes('country', countryId).pipe(publishLast());
      (<any>countryPostCodes).connect(); // https://github.com/ReactiveX/rxjs/issues/2972
      this.postCodesPerCountry[countryId] = countryPostCodes;
    }
    return this.postCodesPerCountry[countryId];
  }

  public getProvinceGeoDict(countryId: number): Observable<ProvinceDto[]> {
    if (!this.provincesPerCountry[countryId]) {
      const provinces = this.geoDictBaseService.getProvinces('country', countryId).pipe(publishLast());
      (<any>provinces).connect(); // https://github.com/ReactiveX/rxjs/issues/2972
      this.provincesPerCountry[countryId] = provinces;
    }
    return this.provincesPerCountry[countryId];
  }
}
