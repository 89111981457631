import {NG_VALUE_ACCESSOR, ControlValueAccessor, AbstractControl} from '@angular/forms';
import {forwardRef, Component, Input} from '@angular/core';

const INPUT_ENTITY_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputEntityComponent),
  multi: true,
};

@Component({
  selector: 'input-entity',
  template: `
    <ng-container *ngIf="control">
      <input
        *ngIf="!presentationMode"
        [type]="type"
        [ngModel]="value"
        (input)="onInput($event)"
        (blur)="onTouchedListeners()"
        [formControl]="control"
        [disabled]="disabled"
        [readonly]="readonly"
        [ngClass]="getCssClasses()"
        [maxlength]="maxlength"
        [ngStyle]="inputStyle"
        [min]="min"
        [max]="max"
      />
      <span *ngIf="presentationMode" class="presentation">{{ value }}</span>
      <error-message [control]="control" [show]="showErrors"></error-message>
    </ng-container>
    <ng-container *ngIf="!control">
      <input
        *ngIf="!presentationMode"
        #model="ngModel"
        [type]="type"
        [ngModel]="value"
        (input)="onInput($event)"
        (blur)="onTouchedListeners()"
        [disabled]="disabled"
        [readonly]="readonly"
        class="bon-input"
        [maxlength]="maxlength"
        [minlength]="minlength"
        [required]="required"
        [ngStyle]="inputStyle"
        [min]="min"
        [max]="max"
      />
      <span *ngIf="presentationMode" class="presentation">{{ value }}</span>
      <error-message [control]="model" [show]="showErrors"></error-message>
    </ng-container>
  `,
  providers: [INPUT_ENTITY_CONTROL_VALUE_ACCESSOR],
})
export class InputEntityComponent implements ControlValueAccessor {
  @Input() control: AbstractControl;
  @Input() disabled: any;
  @Input() readonly: any;
  @Input() presentationMode = false;
  @Input() showErrors: boolean;
  @Input() maxlength = 255;
  @Input() minlength = 0;
  @Input() min: number;
  @Input() max: number;
  @Input() required: boolean;
  @Input() errorClass = '';
  @Input() type = 'string';
  @Input() inputStyle: {[key: string]: string};

  private onChangeListeners: Function;
  public onTouchedListeners: Function;
  public value: string;

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onInput(event: Event) {
    if (this.value && (this.min || this.max)) {
      const numVal = this.toNumber((<any>event.target).value);
      if (isNaN(numVal)) {
        (<any>event.target).value = '0';
      } else if (this.max && numVal > this.max) {
        (<any>event.target).value = this.max + '';
      } else if (this.min && numVal < this.min) {
        (<any>event.target).value = this.min + '';
      }
    }
    this.value = (<any>event.target).value;
    this.onChangeListeners(this.value);
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  // TODO ANGULAR MIGRATION check if it works with removing external toNumber
  private toNumber(value: string): number {
    if (!value) {
      return undefined;
    }
    const v: string = value.replace(',', '.');
    return Number(v);
  }

  private getCssClasses() {
    const cssClasses: {[key: string]: any} = {};
    cssClasses['bon-input'] = true;
    if (this.control && this.control.invalid && this.showErrors) {
      cssClasses[this.errorClass] = true;
    }
    return cssClasses;
  }
}
