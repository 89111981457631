import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from '../../credendo/src/environments/environment';
import {enableProdMode} from '@angular/core';
import {AppPortalModule} from './app/app-portal.module';

console.log('Portal main ts file loading...');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppPortalModule)
  .catch((err) => console.log(err));
