import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FormDialogParent} from '../../../../bonding_shared/components/form-dialog/FormDialogParent';
import {FormDialogComponent} from '../../../../bonding_shared';

@Component({
  selector: 'contract-suspension-dialog',
  templateUrl: './contract-suspension-dialog.component.pug',
})
export class ContractSuspensionDialogComponent extends FormDialogParent implements OnInit {
  @ViewChild(FormDialogComponent, {static: true}) formDialogComponent: FormDialogComponent;
  @Output() suspensionEmitter = new EventEmitter<string>();
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;

  suspensionReason: string;

  ngOnInit(): void {
    this.form = this.ngForm.form;
  }

  openDialog() {
    this.formDialogComponent.openAndExecuteOnConfirm(() => this.confirmSuspension());
  }

  confirmSuspension() {
    this.showErrors = true;
    if (!this.form.valid) {
      return;
    }
    this.suspensionEmitter.emit(this.suspensionReason);
  }
}
