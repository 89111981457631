import {ChartDataset} from 'chart.js';
import {ChartData} from './chart-data';
import {ChartUtils} from '../chart-utils';

export class SalesCreditInsuranceChartData extends ChartData {
  rightAxis = false;
  newInsuranceLeads: number[];
  activePolicies: number[];
  newOffers: number[];
  newPolicies: number[];

  colors = [
    {backgroundColor: ChartUtils.COLORS[2]},
    {backgroundColor: ChartUtils.COLORS[0]},
    {backgroundColor: ChartUtils.COLORS[1]},
    {backgroundColor: ChartUtils.COLORS[3]},
  ];

  buildChartData(): ChartDataset[] {
    return [
      {data: this.newInsuranceLeads, label: 'New insurance leads', yAxisID: 'yAxisLeft'},
      {data: this.activePolicies, label: 'Active policies', yAxisID: 'yAxisLeft'},
      {data: this.newOffers, label: 'New offers', yAxisID: 'yAxisLeft'},
      {data: this.newPolicies, label: 'New policies', yAxisID: 'yAxisLeft'},
    ];
  }

  clear() {
    this.newInsuranceLeads = [];
    this.activePolicies = [];
    this.newOffers = [];
    this.newPolicies = [];
    this.dates = [];
  }

  mapData(results: Array<any>) {
    if (!results) {
      return;
    }
    results.forEach((result) => {
      this.dates.push(result.month + '-' + result.year);
      this.newInsuranceLeads.push(<any>result.newInsuranceLeads);
      this.activePolicies.push(<any>result.activePolicies);
      this.newPolicies.push(<any>result.newPolicies);
      this.newOffers.push(<any>result.newOffers);
    });
  }
}
