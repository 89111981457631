import {Component, Input} from '@angular/core';
import {SectionSelector} from '../../../../bonding_shared/components/details-view/section-selector';
import {CollectionVersionCriteriaDto} from '../../../../bonding_shared/model';

@Component({
  selector: 'collection-section-selector',
  template: `
    <ng-template #section let-openEmitter="openEmitter" let-object="object" let-disabled="disabled">
      <collection-section
        [collectionVersion]="object[property]"
        [searchable]="!disabled"
        [openSelectorEmitter]="openEmitter"
        [showCreditor]="showCreditor"
        [creditorLabelKey]="creditorLabelKey"
      >
      </collection-section>
    </ng-template>
    <ng-template #selector let-openEmitter="openEmitter" let-onChange="onChange">
      <collection-version-selector
        [labelKey]="selectorLabel"
        [open]="openEmitter"
        (selectItem)="onChange($event)"
        [criteria]="criteria"
      ></collection-version-selector>
    </ng-template>
  `,
  providers: [{provide: SectionSelector, useExisting: CollectionSectionSelectorComponent}],
})
export class CollectionSectionSelectorComponent extends SectionSelector {
  @Input() criteria: CollectionVersionCriteriaDto;
  @Input() showCreditor = false;
  @Input() creditorLabelKey = 'collection.creditor';
}
