import {AbstractControl} from '@angular/forms';
import {Component, Input} from '@angular/core';
import {HooverColor} from '../hover-info';

@Component({
  selector: 'form-row',
  template: `
    <div class="bon-row" [class.bon-top-padding]="topPadding">
      <label
        class="bon-label"
        [class.bold-label]="boldLabel"
        [class.bon-one-indent]="indent === 1"
        [class.bon-two-indent]="indent === 2"
      >
        <span *ngIf="labelPrefix" style="width:2em; display: inline-block">{{ labelPrefix }}</span
        >{{ labelKey ? (labelKey | translate) : (label | translate) }}
        <hover-info *ngIf="hooverMsg" [message]="hooverMsg" [color]="hooverColor"></hover-info>
      </label>
      <ng-content></ng-content>
      <error-message *ngIf="control" [control]="control" [show]="showErrors"></error-message>
    </div>
  `,
  styles: ['.bold-label { font-weight: 700; }'],
})
export class FormRowComponent {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() labelPrefix = '';
  @Input() labelKey: string;
  @Input() showErrors: boolean;
  @Input() boldLabel = false;
  @Input() indent = 0;
  @Input() topPadding = false;
  @Input() hooverMsg: string;
  @Input() hooverColor: HooverColor;
}
