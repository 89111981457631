import {Component, Input} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {ControlContainer, NgForm} from '@angular/forms';
import {AppConfigService, PolicyContractVersionService} from '../../../../../bonding_shared/services';
import {InvestmentForm, RatingScale} from '../../../../../bonding_shared/model/dictionary-ids';
import {PolicyContractVersionDto} from '../../../../../bonding_shared';

@Component({
  selector: 'mehib-fi-covered-transaction-section',
  templateUrl: './mehib-fi-covered-transaction-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class MehibFiCoveredTransactionSectionComponent extends Section {
  readonly RatingScale = RatingScale;
  ownershipLoan = false;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected policyContractVersionService: PolicyContractVersionService
  ) {
    super(policyContractViewService, appService, policyContractVersionService);
  }

  @Input() set policy(policy: PolicyContractVersionDto) {
    super.policy = policy;
    this.onInvestmentFormChange();
  }

  get policy() {
    return this._policy;
  }

  onInvestmentFormChange() {
    if (this.policy.investmentForm?.id === InvestmentForm.OWNERSHIP_LOAN) {
      this.ownershipLoan = true;
    } else {
      this.ownershipLoan = false;
      this.policy.ownershipLoanRepaymentDate = null;
    }
  }
}
