import {Observable, of as observableOf, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {
  BackendError,
  HttpErrorLog,
  IdDto,
  isInternalError,
  Page,
  SearchCriteria,
  SearchResult,
  StateChangeDto,
  StateTransitionDto,
} from '../model';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {TextSearchCriteria} from '../components/search/model/text-search-criteria';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {StringUtils} from '../utils/string-utils';
import * as sha from 'js-sha256';
import {DateUtils} from '../utils/date-utils';

export abstract class AbstractService {
  static readonly MAX_COUNT = 100;
  static readonly MAX_IN_COUNT = 1000;

  // Do not change urlPrefix here. Manual configuration available in WEB-INF/api-config.js
  protected urlPrefix: string;

  // to be overridden in the specific service implementation
  protected url: string;

  protected constructor(
    public http: HttpClient,
    public apiService: AppConfigService,
    public loggedUserService: LoggedUserService
  ) {
    this.urlPrefix = this.apiService.backendUrl;
  }

  getUrlPrefix(): string {
    return this.urlPrefix;
  }

  get<T>(url: string, params?: HttpParams | {[param: string]: string | string[]}) {
    this.resetSessionTimer();
    return this.http
      .get(url, {
        params: params,
        responseType: 'text',
        headers: this.defaultHeaders(),
      })
      .pipe(
        map((res) => this.jsonParseText(res)),
        map((res) => <T>res),
        catchError((e) => this.handleError(e))
      );
  }

  getAsStr<T>(url: string) {
    this.resetSessionTimer();
    return this.http.get(url, {headers: this.xmlHeaders()}).pipe(
      map((res) => JSON.stringify(res)),
      catchError((e) => this.handleError(e))
    );
  }

  put<T>(object: T, url: string): Observable<T> {
    this.resetSessionTimer();
    return this.http.put(url, JSON.stringify(object), {responseType: 'text', headers: this.defaultHeaders()}).pipe(
      map((res) => this.jsonParseText(res)),
      map((res) => <T>res),
      catchError((e) => this.handleError(e))
    );
  }

  put1<T, K>(object: T, url: string): Observable<K> {
    this.resetSessionTimer();
    return this.http.put(url, JSON.stringify(object), {responseType: 'text', headers: this.defaultHeaders()}).pipe(
      map((res) => this.jsonParseText(res)),
      map((res) => <K>res),
      catchError((e) => this.handleError(e))
    );
  }

  post<T>(object: T, url: string, params?: HttpParams | {[param: string]: string | string[]}): Observable<T> {
    this.resetSessionTimer();
    return this.http
      .post(url, JSON.stringify(object), {params: params, responseType: 'text', headers: this.defaultHeaders()})
      .pipe(
        map((res) => this.jsonParseText(res)),
        map((res) => <T>res),
        catchError((e) => this.handleError(e))
      );
  }

  post1<T, K>(object: T, url: string, params?: HttpParams | {[param: string]: string | string[]}): Observable<K> {
    this.resetSessionTimer();
    return this.http
      .post(url, JSON.stringify(object), {params: params, responseType: 'text', headers: this.defaultHeaders()})
      .pipe(
        map((res) => this.jsonParseText(res)),
        catchError((e) => this.handleError(e))
      );
  }

  postEmpty<K>(url: string): Observable<K> {
    this.resetSessionTimer();
    return this.http.post(url, {}, {responseType: 'text', headers: this.defaultHeaders()}).pipe(
      map((res) => this.jsonParseText(res)),
      catchError((e) => this.handleError(e))
    );
  }

  saveObj<T extends IdDto>(object: T, url: string): Observable<T> {
    if (object.id && object.id > 0) {
      console.log('update object: ' + url, object);
      return this.post<T>(object, url);
    } else {
      console.log('create object: ' + url, object);
      return this.put<T>(object, url);
    }
  }

  exclusiveStateTransition<T extends IdDto>(sc: StateChangeDto): Observable<T> {
    return this.post1<StateChangeDto, T>(sc, this.url + '/exclusiveStateTransition');
  }

  save<T extends IdDto>(object: T): Observable<T> {
    return this.saveObj(object, this.url);
  }

  getById<T>(id: number): Observable<T> {
    console.log('getById id = ' + id);
    return this.get<T>(this.url + '/' + id);
  }

  newVersion<T>(parentId: number): Observable<T> {
    return this.get<T>(this.url + '/newVersion/' + parentId);
  }

  private jsonParseText(resText: string) {
    if (!resText) {
      return resText;
    }
    try {
      return JSON.parse(resText, this.dateReviver);
    } catch (e) {
      return JSON.parse('"' + resText + '"', this.dateReviver);
    }
  }

  postBinary<T>(object: Blob, url: string): Observable<T> {
    this.resetSessionTimer();
    const headers = this.defaultHeaders().set('Content-Type', 'application/octet-stream');
    return this.http.post(url, object, {responseType: 'text', headers: headers}).pipe(
      map((res) => this.jsonParseText(res)),
      catchError((e) => this.handleError(e))
    );
  }

  postText<T>(text: string, url: string): Observable<T> {
    this.resetSessionTimer();
    const headers = this.defaultHeaders().set('Content-Type', 'text/plain');
    return this.http.post(url, text, {responseType: 'text', headers: headers}).pipe(
      map((res) => this.jsonParseText(res)),
      catchError((e) => this.handleError(e))
    );
  }

  delete<T>(url: string): Observable<T> {
    this.resetSessionTimer();
    console.log('delete object: ' + url);
    return this.http.delete(url, {responseType: 'text', headers: this.defaultHeaders()}).pipe(
      map((res) => this.jsonParseText(res)),
      catchError((e) => this.handleError(e))
    );
  }

  deleteObj<T>(id: number): Observable<T> {
    return this.delete<T>(this.url + '/' + id);
  }

  getFingerprint(): string {
    const frpKey = 'isaFRP';
    let fingerprintRandomPart = localStorage.getItem(frpKey);
    if (!fingerprintRandomPart) {
      fingerprintRandomPart = StringUtils.randomString(32);
      localStorage.setItem(frpKey, fingerprintRandomPart);
    }
    return sha.sha256(fingerprintRandomPart + JSON.stringify(navigator || ''));
  }

  getTransitionsForObject(v: IdDto): Observable<Array<StateTransitionDto>> {
    if (v && v.id) {
      return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + v.id);
    }
    return observableOf([]);
  }

  defaultHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: (this.getToken() && 'Bearer ' + this.getToken()) || 'Fingerprint ' + this.getFingerprint(),
      'Access-Control-Allow-Origin': '*',
    });
  }

  xmlHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    if (this.getToken()) {
      headers.append('Authorization', 'Bearer ' + this.getToken());
    }
    headers.append('Content-Type', 'application/xml');
    headers.append('Accept', 'application/xml');
    headers.append('Access-Control-Allow-Origin', '*');
    return headers;
  }

  searchByTextCriteria<R>(criteria: TextSearchCriteria, page: Page): Observable<SearchResult<R>> {
    this.resetSessionTimer();
    let query = 'search?text=' + criteria.text;
    query += this.addCriteria(criteria, 'duplicate');
    query += this.addCriteria(criteria, 'last');
    query += this.addCriteria(criteria, 'active');
    query += this.addCriteria(criteria, 'physicalPersonIndicator');
    query += this.addCriteria(criteria, 'isBranch');
    query += this.addCriteria(criteria, 'groupHead');
    query += this.addCriteria(criteria, 'lastPolicyYear');
    query += this.addCriteria(criteria, 'excludeTypeId');
    query += this.addCriteria(criteria, 'typeIds');
    query += this.addCriteria(criteria, 'countryId');

    if (criteria.companyType) {
      criteria.companyType.forEach((ct) => (query += '&companyTypeId=' + ct));
    }
    if (page) {
      query += '&start=' + page.start + '&count=' + page.count;
    }
    return this.get<SearchResult<R>>(this.url + '/' + query);
  }

  addCriteria(criteria: any, field: string): string {
    return criteria[field] !== undefined && criteria[field] !== null ? '&' + field + '=' + criteria[field] : '';
  }

  searchByCriteria<C, R>(
    criteria: SearchCriteria<C>,
    queryParams?: {key: string; val: string}[]
  ): Observable<SearchResult<R>> {
    this.resetSessionTimer();
    const searchUrl = this.buildSearchUrl(queryParams);
    return this.post1<SearchCriteria<C>, SearchResult<R>>(criteria, searchUrl);
  }

  private buildSearchUrl(queryParams?: {key: string; val: string}[]) {
    if (!queryParams || queryParams.length === 0) {
      return this.url + '/search';
    }
    let url = this.url + '/search?';

    queryParams.forEach((p) => {
      url += p.key + '=' + p.val + '&';
    });
    return url.substring(0, url.length - 1);
  }

  getToken() {
    return this.loggedUserService?.accessToken;
  }

  resetSessionTimer() {
    if (this.loggedUserService && this.loggedUserService.sessionTimer && !this.apiService.oauth2) {
      if (this.apiService.kuke) {
        this.loggedUserService.sessionTimer.reset(3600);
      } else {
        this.loggedUserService.sessionTimer.reset();
      }
    }
  }

  public dateReviver(key: string, value: string) {
    // var datePattern =/^(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)(\.\d\d?\d?)$/;
    const datePattern = /^(\d\d\d\d)-(\d\d)-(\d\d)\S*$/;
    if (
      (/.*bondValidTo/.exec(key) ||
        /.*validFrom/.exec(key) ||
        /.*validTo/.exec(key) ||
        /.*Date/.exec(key) ||
        /.*reportDownloadTime/.exec(key) ||
        /.*biReportTimeliness/.exec(key) ||
        /lastProcedure/.exec(key) ||
        /nextReview/.exec(key) ||
        /dateOfBirth/.exec(key) ||
        /protractedDefaultTerm/.exec(key) ||
        /dateFrom/.exec(key) ||
        /date/.exec(key) ||
        /dateTo/.exec(key) ||
        /periodFrom/.exec(key) ||
        /periodTo/.exec(key) ||
        /reportingTo/.exec(key) ||
        /.*ValidFrom/.exec(key) ||
        /.*ValidTo/.exec(key) ||
        /from/.exec(key) ||
        /to/.exec(key) ||
        /absentFrom/.exec(key) ||
        /absentTo/.exec(key) ||
        /creationTime/.exec(key) ||
        /reportReceptionTime/.exec(key) ||
        /lastGRCCheck/.exec(key) ||
        /startOfBusiness/.exec(key) ||
        /endOfBusiness/.exec(key) ||
        /allDocumentsOk/.exec(key) ||
        /lossOccurrence/.exec(key) ||
        /masterDataUpdated/.exec(key) ||
        /masterDataSynchronised/.exec(key) ||
        /masterDataSynchronisedSecond/.exec(key) ||
        /biReportTimeliness/.exec(key) ||
        /reportDownloadTime/.exec(key) ||
        /employmentStart/.exec(key) ||
        /employmentEnd/.exec(key) ||
        /undertakingPeriodFrom/.exec(key) ||
        /undertakingPeriodTo/.exec(key) ||
        /orderTime/.exec(key) ||
        /allInformationReceived/.exec(key)) &&
      value &&
      datePattern.exec(value)
    ) {
      return DateUtils.toDate(value);
    }
    return value;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 0 && error.error instanceof ProgressEvent) {
      return throwError(() => new Error('errorCode.NETWORK_ERROR'));
    }
    let backendError: BackendError;
    try {
      backendError = JSON.parse(error.error);
    } catch (err) {
      console.log('Backend parser error', err);
      // here if backend did not return ErrorReason[] or InternalServerErrorDto
    }
    if (backendError && isInternalError(backendError)) {
      this.logError(error.url, error.status, backendError.message, backendError.uuid);
    } else if (!backendError && error.status !== 401) {
      this.logError(error.url, error.status, error?.message ?? '');
    }
    return throwError(() => backendError || 'Server error!');
  }

  private logError(url: string, httpStatus: number, details: string, correlationId?: string) {
    if (!this.loggedUserService || !this.loggedUserService.isLoggedIn()) {
      return;
    }
    const errorLog: HttpErrorLog = {url, httpStatus, details, correlationId};
    this.http
      .post(`${this.urlPrefix}logger/error`, JSON.stringify(errorLog), {
        responseType: 'text',
        headers: this.defaultHeaders(),
      })
      .subscribe();
  }

  protected get portalPrefix() {
    return this.loggedUserService.portal ? 'portal/' : '';
  }

  protected buildUrl(url: string, operation: string, urlParams: UrlParams): string {
    let query = url;
    if (operation) {
      query += '/' + operation;
    }
    const params = urlParams.parameters;
    const keys = Object.keys(params);
    if (keys.length > 0) {
      query += '?';
      keys.filter((p) => !!params[p] || params[p] === false).forEach((p) => (query += p + '=' + params[p] + '&'));
      if (query.endsWith('&')) {
        query = query.substr(0, query.length - 1);
      }
    }
    return query;
  }

  protected buildUrlParams(httpParams: HttpParams) {
    return httpParams
      .keys()
      .map((k) => k + '=' + httpParams.get(k))
      .join('&');
  }
}

export class UrlParams {
  private params = {};

  static new() {
    return new UrlParams();
  }

  add(key: string, value: string | number | boolean) {
    if (!this.params) {
      this.params = {};
    }
    this.params[key] = value;
    return this;
  }

  addIf(condition: boolean, key: string, valueFn: () => string | number | boolean) {
    return condition ? this.add(key, valueFn()) : this;
  }

  get parameters() {
    return this.params;
  }
}
