import {Pipe, PipeTransform} from '@angular/core';
import {FormatService} from '../services';

@Pipe({
  name: 'date',
})
export class DatePipe implements PipeTransform {
  constructor(private formatService: FormatService) {}

  transform(date: Date) {
    return this.formatService.formatDate(date);
  }
}
