import {AfterViewInit, Component, Input} from '@angular/core';
import {ExternalLimitDto} from '../../../../bonding_shared/model/dtos';
import {ContractService, RouterService} from '../../../../bonding_shared/services';

@Component({
  selector: 'external-limit-list',
  templateUrl: './external-limit-list.component.pug',
})
/**
 * Limit history migrated from SI Kuke
 */
export class ExternalLimitListComponent implements AfterViewInit {
  @Input() contractId: number;

  limits: ExternalLimitDto[];
  pageSize = 20;

  constructor(private contractService: ContractService, public router: RouterService) {}

  ngAfterViewInit() {
    this.contractService.getExternalLimits(this.contractId).subscribe((limits) => (this.limits = limits));
  }
}
