import {filter, mergeMap, toArray} from 'rxjs/operators';
import {
  AddressAutocompleteService,
  AddressDto,
  AppConfigService,
  CompanyBIIdentifierDto,
  CompanyCriteriaDto,
  CompanyDto,
  CompanyIdentifierDto,
  CompanyService,
  CompanySimpleDto,
  CompanySimpleWithIdentifiersDto,
  DictionaryBaseDto,
  DictionaryBaseService,
  FeatureService,
  LoggedUserService,
  PhysicalPersonDto,
  ProvinceBaseDto,
  RouterService,
  SearchDataProvider,
  StringUtils,
} from '../../bonding_shared';
import {Component, OnInit, ViewChild} from '@angular/core';
import {CompanyGuiService} from './services/company-gui.service';
import {SearchViewComponent} from '../../bonding_shared/components/search';
import {
  BISource,
  CompanyElementaryRight,
  CompanyIdentifierType,
  CompanyType,
  Country,
  ElementaryRight,
  Feature,
  PolicyContractTypes,
  ReinsuranceMode,
} from '../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'company-search',
  templateUrl: './company-search.component.pug',
  providers: [AddressAutocompleteService],
})
export class CompanySearchComponent implements OnInit {
  @ViewChild(SearchViewComponent, {static: true}) public searchView: SearchViewComponent<
    CompanyCriteriaDto,
    CompanySimpleDto
  >;
  public readonly CompanyIdentifierType = CompanyIdentifierType;
  public dataProvider: SearchDataProvider<CompanyCriteriaDto, CompanySimpleDto>;
  public companySpecialTypes: DictionaryBaseDto[] = [];
  public possibleBiSources: DictionaryBaseDto[];
  public provinces: ProvinceBaseDto[];
  private externalSearchAllowed: boolean;
  private selectExternalDictionaryId: number;
  private selectExternalNumber: string;

  public showShortNameIfExists: boolean; // show short company name instead of registration name when it exists on search view

  // Used by search form
  public get externalId(): string {
    if (this.dataProvider.searchCriteria.criteria.identifier) {
      return this.dataProvider.searchCriteria.criteria.identifier.identifier;
    }
    return undefined;
  }

  // Used by search form
  public set externalId(extId: string) {
    if (extId) {
      this.dataProvider.searchCriteria.criteria.identifier = <CompanyIdentifierDto>{
        type: <DictionaryBaseDto>{id: CompanyIdentifierType.EXTERNAL_ID},
        biSource: this.guiService.getCompanySource(),
        identifier: extId,
        id: undefined,
        version: undefined,
      };
    } else {
      this.dataProvider.searchCriteria.criteria.identifier = undefined;
    }
  }

  // Used by  search form
  public get legacyId(): string {
    if (this.dataProvider.searchCriteria.criteria.identifier) {
      return this.dataProvider.searchCriteria.criteria.identifier.identifier;
    }
    return undefined;
  }

  get hiddenCompanyIdentifierTypeIds() {
    const ids = new Set<number>();
    ids.add(CompanyIdentifierType.TAX_ID);
    ids.add(CompanyIdentifierType.STATISTICAL_NUMBER);
    ids.add(CompanyIdentifierType.NATIONAL_ID);
    ids.add(CompanyIdentifierType.EXT_INFO_ID);
    ids.add(CompanyIdentifierType.EXT_INFO_ID_2);
    return ids;
  }

  //  Used by search form
  public set legacyId(extId: string) {
    if (extId) {
      this.dataProvider.searchCriteria.criteria.identifier = <CompanyIdentifierDto>{
        type: <DictionaryBaseDto>{id: CompanyIdentifierType.LEGACY_ID},
        biSource: null,
        identifier: extId,
        id: undefined,
        version: null,
      };
    } else {
      this.dataProvider.searchCriteria.criteria.identifier = undefined;
    }
  }

  public get allExternalId(): string {
    if (this.dataProvider.searchCriteria.criteria.identifier) {
      return this.dataProvider.searchCriteria.criteria.identifier.identifier;
    }
    return undefined;
  }

  //  Used by search form
  public set allExternalId(extId: string | DictionaryBaseDto) {
    if (typeof extId === 'string') {
      this.selectExternalNumber = extId;
    } else {
      this.selectExternalDictionaryId = extId?.id;
    }
    if (this.selectExternalNumber) {
      this.dataProvider.searchCriteria.criteria.identifier = <CompanyIdentifierDto>{
        type: <DictionaryBaseDto>{id: this.selectExternalDictionaryId},
        biSource: null,
        identifier: this.selectExternalNumber,
        id: undefined,
        version: null,
      };
    } else {
      this.dataProvider.searchCriteria.criteria.identifier = undefined;
    }
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }

  public get credendo(): boolean {
    return this.appService.credendo;
  }

  public get ecg(): boolean {
    return this.appService.ecg;
  }

  public get mehib(): boolean {
    return this.appService.mehib;
  }

  get portal(): boolean {
    return this.userService.portal;
  }

  public get internal(): boolean {
    return !this.guiService.getCompanySource();
  }

  public performInitialSearch(): boolean {
    return (!this.kuke || this.portal) && this.internal;
  }

  constructor(
    private addressAutocompleteService: AddressAutocompleteService,
    private dictionaryBaseService: DictionaryBaseService,
    public companyService: CompanyService,
    public guiService: CompanyGuiService,
    public appService: AppConfigService,
    private userService: LoggedUserService,
    private translateService: TranslateService,
    public router: RouterService,
    featureService: FeatureService
  ) {
    this.dataProvider = guiService.searchDataProvider;
    this.showShortNameIfExists = featureService.get(Feature.COMPANY_SHOW_SHORT_NAME_IF_EXISTS);
    this.dictionaryBaseService
      .getDictionaryBase('CompanyType')
      .pipe(
        mergeMap((types) => types),
        filter((type) => type.id !== CompanyType.COMPANY),
        toArray()
      )
      .subscribe((types) => (this.companySpecialTypes = types));
  }

  public ngOnInit(): void {
    this.initializeView();
  }

  public searchExecuted(): void {
    const criteria = this.dataProvider.searchCriteria.criteria;
    if (criteria.address.country && !criteria.hasContract && !criteria.hasPolicy) {
      this.externalSearchAllowed = true;
    }
  }

  public toExternalSearch(): void {
    if (!this.externalSearchAllowed) {
      this.searchView.frontendErrorMsg = this.getTranslation('company.search.localSearchFirst');
      // 'To perform an external search, first search in local database selecting country ' +
      // 'and clearing "Has insurance/bonding contract" options.';
      return;
    }
    this.searchView.internalSeachText = false;
    this.getPossibleBIs(true);
  }

  protected getTranslation(key: string): string | any {
    if (this.translateService) {
      return this.translateService.instant(key);
    }
    return key;
  }

  public toInternalSearch(): void {
    this.searchView.internalSeachText = true;
    this.setCompanySource(null);
  }

  public toCompanyPreview(company: CompanySimpleDto): void {
    if (company.id) {
      // If company exists in our database we rout to full company preview
      this.router.toCompanyPreview(company.id);
    } else {
      const source = this.guiService.getCompanySource();
      // External search returns company with identifiers and external id should be one
      const companyWithIdentifiers = <CompanySimpleWithIdentifiersDto>company;
      const externalId = BusinessUtils.findExternalId(companyWithIdentifiers.identifiers, undefined);
      const vatNumber = companyWithIdentifiers.vatNumber;
      const nationalId = companyWithIdentifiers.nationalId;
      const statNumber = companyWithIdentifiers.statNumber;

      let countryId;
      if (!company.address) {
        company.address = <AddressDto>{};
        company.address.country = this.dataProvider.searchCriteria.criteria.address.country;
        countryId = company.address.country.id;
      }
      const identifiers = <CompanyBIIdentifierDto>{
        biSourceId: source?.id,
        external: externalId,
        countryId: countryId,
        vat: vatNumber,
        national: nationalId,
        statistical: statNumber,
      };

      this.companyService.matchByIdentifiers(identifiers).subscribe((matchedCompany) => {
        if (matchedCompany?.id) {
          company = matchedCompany;
        }

        this.guiService.setCompany(externalId, <CompanyDto>company);
        const searchCriteriaCountry = this.dataProvider.searchCriteria.criteria.address.country;

        this.router.toExternalCompanyPreview(
          source.id,
          searchCriteriaCountry ? searchCriteriaCountry.id : countryId,
          externalId
        );
      });
    }
  }

  public changeSource(biSource: DictionaryBaseDto): void {
    this.setCompanySource(biSource);
    this.provincesByCountry();
  }

  public countryChanged(): void {
    this.setCompanySource(null);
    this.provincesByCountry();
    this.externalSearchAllowed = false;
  }

  public prettyAddress(a: AddressDto): string {
    return StringUtils.prettyAddress(a, false);
  }

  public searchByTownVisible(): boolean {
    return this.internal || (!this.isBisnode() && !this.isWiserfunding());
  }

  public searchByZipcodeVisible(): boolean {
    return this.internal || (!this.isBisnode() && !this.isWiserfunding());
  }

  public companyCreateRight(): boolean {
    return (
      this.appService.credendo ||
      this.appService.mehib ||
      this.appService.ecg ||
      (this.appService.kuke && this.userService.hasRight(ElementaryRight.COMPANY_CREATE))
    );
  }

  public specialCompanyCreateRight(): boolean {
    return this.appService.credendo || this.appService.ecg || this.appService.mehib
      ? this.userService.hasRight(ElementaryRight.SPEC_COMPANY_EDIT)
      : this.userService.hasRight(ElementaryRight.COMPANY_SPECIAL_CREATE);
  }

  public companyIdentifierType(company: CompanyDto, identifierType: number): string {
    if (company.identifiers) {
      return _(company.identifiers)
        .filter((identifier: CompanyIdentifierDto) => identifier.type.id === identifierType)
        .map((identifier: CompanyIdentifierDto) => identifier.identifier)
        .head();
    }
    return null;
  }

  private initializeView(): void {
    this.searchView.enableSearchViewError = true;
    const companySource = this.guiService.getCompanySource();
    this.setCompanySource(companySource);
    if (companySource) {
      this.getPossibleBIs(false);
    }
    if (!this.dataProvider.searchCriteria.criteria) {
      this.initializeCriteria();
    }
  }

  private initializeCriteria(): void {
    this.dataProvider.searchCriteria.criteria = <CompanyCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.address = <AddressDto>{};
    if (this.ecg) {
      this.dataProvider.searchCriteria.criteria.companyType = <DictionaryBaseDto>{};
    } else {
      this.dataProvider.searchCriteria.criteria.companyType = <DictionaryBaseDto>{id: CompanyType.COMPANY};
    }
    this.dataProvider.searchCriteria.criteria.physicalPerson = <PhysicalPersonDto>{};
    this.dataProvider.textSearch = false;
  }

  private getPossibleBIs(setSource: boolean) {
    const country = this.dataProvider.searchCriteria.criteria.address.country;
    if (country) {
      this.guiService.getPossibleBI(country.id).subscribe((res) => {
        this.possibleBiSources = res;
        if (res.length === 0) {
          this.searchView.frontendErrorMsg = 'No business intelligence agency defined for  ' + country.name;
        } else if (setSource) {
          this.setCompanySource(res[0]);
        }
      });
    }
  }

  private setCompanySource(source: DictionaryBaseDto) {
    this.guiService.setCompanySource(source);
    if (source) {
      this.searchView.searchHeader = this.translateService.instant('company.search.externalSearch') + ' ' + source.name;
      this.searchView.redHeader = true;
      this.searchView.searchModeSwitchable = false;
    } else {
      this.searchView.searchHeader = 'searchView.criteria.title';
      this.searchView.redHeader = false;
      this.searchView.searchModeSwitchable = true;
    }
  }

  public isBisnode(): boolean {
    return this.guiService.getCompanySourceId() === BISource.BISNODE;
  }

  private isCreditreform(): boolean {
    return this.guiService.getCompanySourceId() === BISource.CREDITREFORM;
  }

  private isCoface(): boolean {
    return this.guiService.getCompanySourceId() === BISource.COFACE;
  }

  public isWiserfunding(): boolean {
    return this.guiService.getCompanySourceId() === BISource.WISERFUNDING;
  }

  public isCofaceRest(): boolean {
    return this.guiService.getCompanySourceId() === BISource.COFACE_REST;
  }

  private isCerved(): boolean {
    return this.guiService.getCompanySourceId() === BISource.CERVED;
  }

  private isGraydon(): boolean {
    return this.guiService.getCompanySourceId() === BISource.GRAYDON;
  }

  canSelectBISource(): boolean {
    return this.userService.hasRight(CompanyElementaryRight.COMPANY_BI_SELECT);
  }

  provinceCondition(): boolean {
    const countryId = this.dataProvider.searchCriteria.criteria?.address?.country?.id;
    return this.appService.kuke && this.isBisnode() && (countryId === Country.US || countryId === Country.CA);
  }

  provincesByCountry() {
    if (this.provinceCondition()) {
      const countryId = this.dataProvider.searchCriteria.criteria?.address?.country?.id;
      this.addressAutocompleteService.getProvinceGeoDict(countryId).subscribe((p) => (this.provinces = p));
    }
  }

  onSelectProvince(p: ProvinceBaseDto) {
    this.dataProvider.searchCriteria.criteria.address.provinceCode = p.code;
  }
}
