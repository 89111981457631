<!--<link href='https://fonts.googleapis.com/css?family=Abel' rel='stylesheet'>-->
<!--<link href='https://fonts.googleapis.com/css?family=Archivo Black' rel='stylesheet'>-->
<style>

	@font-face {
		font-family: 'ITCAvantGardeGothicBk';
		src: url('assets/fonts/ITCAvantGardeGothicBook.woff') format('woff');
    font-weight: 100;
	}
	@font-face {
		font-family: 'ITCAvantGardeGothicBd';
		src: url('assets/fonts/ITCAvantGardeBold.woff') format('woff');
	}
	@font-face {
		font-family: 'ITCAvantGardeGothicXLt';
		src: url('assets/fonts/ITCAvantGardeStd-XLt.woff') format('woff');
	}

  .credendo-light {
    font-size: 82px;
    font-family: "ITCAvantGardeGothicXLt", Arial, sans-serif;
  }
  .credendo-bold {
    font-size: 89px;
    font-family: "ITCAvantGardeGothicBd", Arial, sans-serif;
    color: #ff561c;
  }
  .credendo-bold-orange {
    font-size: 89px;
    font-family: "ITCAvantGardeGothicBd", Arial, sans-serif;
  }
  .login-box {
    background-color: rgba(215, 210, 203, 0.9);
    margin-top: 70px;
    padding-bottom: 20px !important;
  }

  .caption-txt {
    font-size: 17px !important;
    color: #342b24;
    padding-top: 30px;
  }

  input {
    width: 70%;
    color: #342b24 !important;
    margin-left: 15%;
  }
  .input-underline {
    border-bottom: 2px solid rgba(52,43,36,0.5) !important;
  }
  .input-underline:focus {
    border-bottom: 2px solid #342b24 !important;
  }
  .form-group input::-webkit-input-placeholder, .login-page .bon-row input::-webkit-input-placeholder {
      color: rgba(52,43,36,0.5) !important; }
  .form-group input:-moz-placeholder, .login-page .bon-row input:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(52,43,36,0.5) !important; }
  .form-group input::-moz-placeholder, .login-page .bon-row input::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(52,43,36,0.5) !important; }
  .form-group input:-ms-input-placeholder, .login-page .bon-row input:-ms-input-placeholder {
      color: rgba(52,43,36,0.5) !important; }
  .main-logo {
    top: 50%;
    position: absolute;
    margin-top: -350px;
  }

  .input-result {
    display: inline-block; width: 60%; margin-left: 5px;
    text-align: right;
  }

  .label-result {
    color: #342b24;
  }

</style>

<div class="login-page animate portal-login" style="background-image: url('assets/images/credendo/Credendo_Level2.jpg'); background-size: cover; padding: 0; line-height: 1">
  <div style="height: 100%; background-color: rgba(104, 86, 72, 0.2)">
    <div class="row">
      <div class="col-lg-12 main-logo">
        <div class="col-lg-12">
          <span class="credendo-bold-orange">POLICY AUTHENTICITY VALIDATOR</span><br>
        </div>
        <div class="col-lg-4 col-lg-offset-4 login-box">

          <form role="form" *ngIf="!result && !errorMessage">
            <label class="caption-txt">{{'Please, add the required information' | translate}}</label>
            <div class="form-content">
              <div class="form-group">
                <input id="policyInput" name="policyInput" type="text" [(ngModel)]="bondNumber"
                       class="form-control input-underline input-lg" placeholder="{{'Bond number'|translate}}">
              </div>
              <div class="form-group">
                <input id="codeInput" name="codeInput" type="text" [(ngModel)]="code"
                       class="form-control input-underline input-lg" placeholder="{{'Verification code'|translate}}"
                       style="background-color: rgba(255, 255, 255, 0)">
              </div>
            </div>
            <div *ngIf="errorMessage" class="card-block">
              <span class="text-danger"><h4>{{errorMessage}}</h4></span>
            </div>
            <button id="loginBtn" class="btn rounded-btn" (click)="doVerify()" [disabled]="pending">Verify</button>
          </form>

          <form role="form" *ngIf="result && !errorMessage">
            <label class="caption-txt">The {{bondNumber}} is present in Credendo system</label>
            <div class="form-content">
              <div class="form-group">
                <label class="bon-label label-result" translate>Bond value</label>
                <input id="resultValueInput" name="resultValueInput" type="text" [ngModel]="(result.value | number) + ' ' + result.currency?.code"
                       class="form-control input-underline input-lg input-result" disabled>
              </div>
              <div class="form-group">
                <label class="bon-label label-result" translate>Beneficiary</label>
                <input id="resultBeneficiaryInput" name="resultBeneficiaryInput" type="text" [ngModel]="result.beneficiary"
                       class="form-control input-underline input-lg input-result"
                       style="background-color: rgba(255, 255, 255, 0)"  disabled>
              </div>
            </div>
            <button id="downloadBtn" class="btn rounded-btn" (click)="doDownload()" [disabled]="pending">Download</button>
          </form>

          <form role="form" *ngIf="errorMessage">
            <label class="caption-txt" [innerHTML]="errorMessage"></label>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
