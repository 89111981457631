import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  BondingContractInquiryClientDutyDto,
  BondingContractInquiryClientFinancialDataDto,
  BondingContractInquiryClientInformationDto,
  DictionaryBaseDto,
} from '../../../../bonding_shared/model';
import {ControlContainer, NgForm} from '@angular/forms';
import {BondingContractInquirySectionClientInformationComponent} from './bonding-contract-inquiry-section-client-information';
import {Currency} from '../../../../bonding_shared/model/dictionary-ids';
import {ChangePasswordComponent} from '../../../../bonding_shared/components/user/change-password.component';

@Component({
  selector: 'bonding-contract-inquiry-tab-client-information',
  templateUrl: './bonding-contract-inquiry-tab-client-information.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondingContractInquiryTabClientInformationComponent {
  @Input() showErrors = false;
  @Input() clientInformation: BondingContractInquiryClientInformationDto;
  @Input() newInquiry = false;

  @ViewChild(BondingContractInquirySectionClientInformationComponent, {static: true})
  clientInformationCmp: BondingContractInquirySectionClientInformationComponent;

  clearData() {
    setTimeout(() => {
      if (!this.clientInformation.asAttachment) {
        this.clientInformationCmp.updateSwitches();
      }
    });
    this.clientInformation.capitalGroup.name = undefined;
    this.clientInformation.capitalGroup.members = [];
    this.clientInformation.subsidiaries = [];
    this.clientInformation.claims = [];
    this.clientInformation.limits = [];
    this.clientInformation.grantedGuaranties = [];
    this.clientInformation.totalGuaranty = undefined;
    this.clientInformation.guarantyCurrency = <DictionaryBaseDto>{id: Currency.PLN};
    this.clientInformation.balance.currency = <DictionaryBaseDto>{id: Currency.PLN};
    this.clientInformation.balance.duty = <BondingContractInquiryClientDutyDto>{};
    this.clientInformation.balance.charge = <BondingContractInquiryClientDutyDto>{};
    this.clientInformation.credits = [];
    this.clientInformation.completedContracts = [];
    this.clientInformation.ongoingContracts = [];
    this.clientInformation.financialData = <BondingContractInquiryClientFinancialDataDto>{currency: {id: Currency.PLN}};
  }

  label(label: string): string {
    return 'bondingContractInquiry.details.clientInformation.' + label;
  }
}
