import {Observable, of} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SalesRepRelationVersionDto, StateTransitionDto, UserDto} from '../model';

@Injectable()
export class SalesRepRelationVersionService extends AbstractService {
  protected url = this.urlPrefix + 'salesRepRelationVersion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  initialVersion(
    salesRepId: number,
    relatedToTypeId: number,
    relatedToId: number
  ): Observable<SalesRepRelationVersionDto> {
    return this.get<SalesRepRelationVersionDto>(
      this.url +
        '/initialVersion?salesRepId=' +
        salesRepId +
        '&relatedToTypeId=' +
        relatedToTypeId +
        '&relatedToId=' +
        relatedToId
    );
  }

  getTransitions(version: SalesRepRelationVersionDto): Observable<Array<StateTransitionDto>> {
    if (version?.id) {
      return this.get<Array<StateTransitionDto>>(this.url + '/transitions/' + version.id);
    }
    return of([]);
  }

  getSalesRepUsers(inquiryId: number): Observable<Array<UserDto>> {
    return this.get<Array<UserDto>>(this.url + '/salesRepUsers?inquiryId=' + inquiryId);
  }

  deleteRelation(salesRepRelationVersionId: number): Observable<SalesRepRelationVersionDto> {
    return this.deleteObj(salesRepRelationVersionId);
  }
}
