import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService} from '../../../../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {FieldValueType} from '../../../../../bonding_shared/model/dictionary-ids';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'kuke-indemnification-section',
  templateUrl: './kuke-indemnification-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KukeIndemnificationSectionComponent extends Section {
  self = this;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected translateService: TranslateService
  ) {
    super(policyContractViewService, appService);
  }
}
