import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import {DictionaryBaseDto, GroupAssignmentRuleDto, SalesRegionDto, UserGroupIdDto, UserIdDto} from '../model';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';

@Injectable()
export class GroupAssignmentRuleService extends AbstractService {
  protected url = this.urlPrefix + 'groupAssignmentRule';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getGroupsForCategory(profileCategoryId: number): Observable<UserGroupIdDto[]> {
    return this.get(this.url + '/categoryGroups/' + profileCategoryId);
  }

  getMainNaceCodes(): Observable<DictionaryBaseDto[]> {
    return this.get(this.url + '/mainNaceCodes');
  }

  getRule(ruleId: number): Observable<GroupAssignmentRuleDto> {
    return this.get(this.url + '/' + ruleId);
  }

  getRules(groupId: number): Observable<GroupAssignmentRuleDto[]> {
    return this.get(this.url + '/' + groupId + '/rules');
  }
}
