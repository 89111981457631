/**
 * Created by szkrabko on 10.09.2018 by copying code fro ATable created by siminski on 13.07.2016.
 */
import {AfterContentInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {PasteFromExcelService} from './services/paste-from-excel.service';
import {TableComponent} from './table.component';
import {HasId, SearchResult} from '../../model';
import {ColumnComponent} from './column.component';
import {AbstractControl, UntypedFormBuilder} from '@angular/forms';
import {StringUtils} from '../../utils';
import {FormatService} from '../../services';
import {TranslateService} from '@ngx-translate/core';

export class Row<T> {
  isArchetype: boolean;
  isInEdition: boolean;
  isNew: boolean;
  rowData = {};
  archetypeItem: T;
  item: T;
}

@Component({
  selector: 'b-table',
  templateUrl: 'table.component.html',
  providers: [PasteFromExcelService],
})
export class BTableComponent<T extends HasId> extends TableComponent<T> implements AfterContentInit {
  // rows array contains extra info about items. every item is in _items and in _rows.item as same object
  protected _rows: Row<T>[];
  // _rows: Row<T>[];

  // array contains only checked items, which stays in model
  protected inputItems: T[];
  // inputItems: T[];
  private isAnyRowInEdition = false;
  private alwaysInEdition = false;
  private hasUniqueColumn = false;
  private hasRadioColumn = false;
  private _allowMultiEdition = false;
  @Input() checkAllVisible = false;
  @Input() set allowMultiEdition(allow: boolean) {
    this._allowMultiEdition = allow;
  }
  get allowMultiEdition(): boolean {
    return this._allowMultiEdition && !this.hasUniqueColumn && !this.hasRadioColumn;
  }

  @Input() confirmationButtons: boolean;
  @Input() archetypeColumnProperty;
  @Input() disableCheckOnRowClick: boolean;

  @Input() rowOnCheckCallback: (item: T, items: T[]) => {item: T; value: boolean}[];
  @Input() rowOnUncheckCallback: (item: T, items: T[]) => {item: T; value: boolean}[];

  @Output() itemEdition = new EventEmitter<T>();

  private _archetypeEntries: T[];

  get archetypeEntries(): T[] {
    return this._archetypeEntries;
  }

  @Input() set archetypeEntries(entries: T[]) {
    if (entries) {
      console.log('setArchetypeDictEntries', entries, entries.length);
      this._archetypeEntries = entries;
      this._allowMultiEdition = true;
      this.generateRows();
    }
  }

  constructor(
    fb: UntypedFormBuilder,
    pasteFromExcelService: PasteFromExcelService<T>,
    formatService: FormatService,
    translateService: TranslateService
  ) {
    super(fb, pasteFromExcelService, formatService, translateService);
  }

  protected setItemsCallback(items: T[]) {
    this.inputItems = items;
    this.generateRows();
  }

  protected initColumn(column) {
    super.initColumn(column);
    if (column.type === 'archetypeCheckbox') {
      this.deleteButton = false;
      this.confirmationButtons = false;
      this.alwaysInEdition = true;
    }
    if (column.unique) {
      this.hasUniqueColumn = true;
    }
    if (column.type === 'radio') {
      this.hasRadioColumn = true;
    }
  }

  protected initDictionary(column) {
    this._allowMultiEdition = true;
    this.addButton = false;
    if (!this.archetypeEntries && !this.dataProvider) {
      if (column.dictionaryEntries && column.dictionaryEntries.length > 0) {
        this.archetypeEntries = column.dictionaryEntries;
      } else {
        // TODO: change dictionaryReady to emit even when subscribing after it has completed
        // TODO: this way 5 lines above can be removed
        column.dictionaryReady.subscribe(() => {
          if (!this.archetypeEntries) {
            this.updateHiddenDictItems();
            if (column.type === 'archetype') {
              this.archetypeEntries = column.dictionaryEntries;
            }
          }
        });
      }
    }
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
    this.generateRows();
  }

  onArchetypeCheckboxClicked(item: T, column: ColumnComponent<T>, event: any) {
    const on = this.getRow(item).isArchetype;
    console.log('onArchetypeCheckboxClicked: ', on);

    const items = this.designateCustomItems(on, item);

    if (items && items.length > 0) {
      this.customizeBehaviour(items, column, event);
    } else {
      this.standardBehaviour(on, item, column);
    }

    event.stopPropagation();
  }

  private designateCustomItems(on: boolean, item: T) {
    let items: {item: T; value: boolean}[];

    if (on && this.rowOnCheckCallback) {
      items = this.rowOnCheckCallback(item, []);
    } else if (this.rowOnUncheckCallback) {
      items = this.rowOnUncheckCallback(item, this.findCheckedItems());
    }
    return items;
  }

  private standardBehaviour(on: boolean, item: T, column: ColumnComponent<T>) {
    if (on) {
      this.onSelectedRow(item, column);
    } else {
      this.onDelete(item);
    }
  }

  private customizeBehaviour(items: {item: T; value: boolean}[], column: ColumnComponent<T>, event: any) {
    const archColumn = this.findArchetypeColumn();
    for (const t of items) {
      this._items
        .filter((r) => r[archColumn.property].id === t.item[archColumn.property].id)
        .forEach((rf) => {
          if (t.value) {
            this.onSelectedRow(rf, column);
          } else if (t.value === false) {
            this.onDelete(rf);
          } else {
            event.preventDefault();
          }
        });
    }
  }

  private findCheckedItems(): T[] {
    return this._items.filter((i) => this.isChecked(i));
  }

  isDeleteButtonVisible(item: T) {
    return this.getRow(item).isArchetype && super.isDeleteButtonVisible(item);
  }

  isDeleteButtonDisabled(item: T): boolean {
    return super.isDeleteButtonDisabled(item) || (!this.allowMultiEdition && this.isAnyRowInEdition);
  }

  areConfirmButtonsVisible(item: T): boolean {
    return super.areConfirmButtonsVisible(item) && this.confirmationButtons !== false;
  }

  isAddVisible() {
    return !(this.archetypeEntries && this.archetypeEntries.length > 0) && super.isAddVisible();
  }

  add(item: T) {
    const newRow: Row<T> = <Row<T>>{};
    newRow.rowData = {};
    newRow.isNew = true;
    newRow.item = item;
    this._rows.push(newRow);
    this.inputItems.push(item);
  }

  canSelectRow(item: T): boolean {
    return (this.allowMultiEdition && !this.isRowInEdition(item)) || !this.isAnyRowInEdition;
  }

  delete(item: T) {
    if (!(this.rowOnDeleteCallback && this.rowOnDeleteCallback(item, this.inputItems))) {
      const inputItemNo = this.inputItems.indexOf(item);
      if (inputItemNo > -1) {
        this.inputItems.splice(inputItemNo, 1);
        const row = this.getRow(item);
        const itemNo = this._items.indexOf(item);
        if (this.archetypeEntries) {
          row.isArchetype = true;
          row.isInEdition = false;
          row.item = structuredClone(row.archetypeItem);
          this._items[itemNo] = row.item;
        } else {
          this._rows.splice(this._rows.indexOf(row), 1);
          this._items.splice(itemNo, 1);
        }
      }
    }
    this.updateHiddenDictItems();
  }

  getRowData(item: T): any {
    return this.getRow(item).rowData;
  }

  isRowInEdition(item?: T): boolean {
    return (
      super.isRowInEdition(item) || (this.editable && (item ? this.getRow(item).isInEdition : this.isAnyRowInEdition))
    );
  }

  onCancel(item: T) {
    const row = this.getRow(item);
    if (row.isNew) {
      this.restoreData(item, true);
      this.onDelete(item);
    } else {
      this.restoreData(item, false);
    }
    row.isInEdition = false;
    row.isNew = false;
    this.updateAnyRowInEdition();
    this.cancel.emit();
  }

  onDone(item: T) {
    const row = this.getRow(item);
    if (this.formModel) {
      const rowGroup = this.getRowControlGroup(item);
      rowGroup.updateValueAndValidity();
      if (rowGroup.valid) {
        row.isInEdition = false;
        row.isNew = false;
        this.showErrors = false;
      } else {
        StringUtils.logFormInvalidFields(rowGroup);
        this.showErrors = true;
      }
    } else {
      row.isInEdition = false;
      row.isNew = false;
    }
    // The container for newly added object already exists (was created in addItem method, due to lack of ngModel mapping
    // for dictionaries lists the object is added to collectin manually here, the container is already there for it.
    if (this.addDictRow !== null) {
      this._items[this._items.length - 1] = this.newDictRow;
    }

    this.updateHiddenDictItems();
    this.updateModelFromTemplate();
    this.updateAnyRowInEdition();
    this.addUpdateDelete.emit(row.item);
    this.done.emit(row.item);
  }

  protected pushItemsFromExcel(items: T[]) {
    this.inputItems.push(...items);
    this.generateRows();
  }

  startEdition(item: T) {
    const row = this.getRow(item);
    if (row.isArchetype) {
      delete row.isArchetype;
      this.inputItems.push(item);
      row.isNew = true;
      const rowGroup = this.getRowControlGroup(item);
      if (rowGroup) {
        rowGroup.updateValueAndValidity();
      }
      this.updateModelFromTemplate();
    }
    row.isInEdition = true;
    super.startEdition(item);
    this.updateAnyRowInEdition();
    this.itemEdition.emit(item);
  }

  protected canAddToHidden(col: ColumnComponent<T>): boolean {
    return col.type !== 'archetype' && super.canAddToHidden(col);
  }

  public findArchetypeColumn(): ColumnComponent<T> {
    return (this.columns && this.columns.find((col) => col.type === 'archetype')) || null;
  }

  private generateRows() {
    if (!this.inputItems) {
      return;
    }
    const archetypeColumn: ColumnComponent<T> = this.findArchetypeColumn();
    console.log(
      'generateRows',
      this.archetypeEntries,
      (this._archetypeEntries && this._archetypeEntries.length) || 'no entries'
    );
    this._rows = [];
    this._items = [];
    if (this.archetypeEntries) {
      let entries = this.archetypeEntries;
      if (archetypeColumn) {
        entries = entries.filter((entry) => !archetypeColumn.hiddenIds.has(entry.id));
      }
      for (const ad of entries) {
        const newRow = <Row<T>>{rowData: {}};
        if (archetypeColumn) {
          newRow.archetypeItem = <T>{};
          StringUtils.setPropertyRaw(newRow.archetypeItem, archetypeColumn.property, ad);
          newRow.item = this.inputItems.find(
            (item) => StringUtils.getPropertyRaw(item, archetypeColumn.property).id === ad.id
          );
        } else {
          newRow.archetypeItem = ad;
          newRow.item = this.inputItems.find((item) => item.id === ad.id);
        }
        if (!newRow.item) {
          newRow.item = JSON.parse(JSON.stringify(newRow.archetypeItem), this.formatService.dateReviver);
          newRow.isArchetype = true;
        } else {
          newRow.isInEdition = this.alwaysInEdition && this.editable;
        }
        this._rows.push(newRow);
        this._items.push(newRow.item);
      }
    } else {
      for (const item of this.inputItems) {
        this._rows.push(<Row<T>>{rowData: {}, item: item});
        this._items.push(item);
      }
    }
    this.updateAnyRowInEdition();
  }

  private updateAnyRowInEdition() {
    this.isAnyRowInEdition = !!this._rows.find((row) => row.isInEdition);
  }

  getRow(item: T): Row<T> {
    return this._rows.find((row) => row.item === item);
  }

  getControl(item: T, column: ColumnComponent<T>): AbstractControl {
    if (!this.isChecked(item)) {
      return;
    }
    return super.getControl(item, column);
  }

  isChecked(item: T): boolean {
    return this._rows && this.getRow(item) && !this.getRow(item).isArchetype;
  }

  showColumnTitle(column: ColumnComponent<T>): boolean {
    return super.showColumnTitle(column) && column.type !== 'archetypeCheckbox';
  }

  onRowClick(item: T, column: ColumnComponent<T>) {
    if (!this.disableCheckOnRowClick) {
      this.onSelectedRow(item, column);
    }
  }

  protected removeRowControlGroup(item: T) {
    if (!this.formModel) {
      return;
    }
    const rowNo = '' + this.getItemIndex(item);
    this.form.removeControl(rowNo);
    this.form.updateValueAndValidity();
    this.form.markAsDirty();
  }

  areButtonsVisible(item?: T): boolean {
    return (
      !this.columnMode &&
      this.isRowEditable(item) &&
      !!(this.moveTopButton === true || this.deleteButton === true || this.customButtons)
    );
  }

  handleSearchResult(searchResult: SearchResult<T>) {
    this.archetypeEntries = searchResult.result;
  }

  onCheckAll() {
    this.onUncheckAll();
    for (let item of this.items) {
      const row = this.getRow(item);
      delete row.isArchetype;
      this.inputItems.push(item);
      this.addUpdateDelete.emit(item);
    }
  }

  onUncheckAll() {
    for (let item of this.items) {
      this.delete(item);
      this.addUpdateDelete.emit(item);
    }
  }
}
