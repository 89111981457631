import {Injectable} from '@angular/core';
import {ContextsRoles} from '../../../../../src/client/app/bonding_shared/model/contexts-roles';
import {LoggedUserService} from '../../../../../src/client/app/bonding_shared/services/logged-user.service';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from '../../../../../src/client/app/bonding_shared';

@Injectable()
export class UserSelectedContextService {
  context: ContextsRoles.CTX = null;

  constructor(
    loggedUserService: LoggedUserService,
    private translateService: TranslateService,
    private appService: AppConfigService
  ) {
    const defaultContextCode: ContextsRoles.UserContextName = <ContextsRoles.UserContextName>(
      loggedUserService.getLoggedUserData().defaultContextCode
    );
    if (!defaultContextCode) {
      return null;
    }
    this.setContextByCode(defaultContextCode);
  }

  public setContextByCode(code: string) {
    // IKI-8162 §4
    if (this.appService.kuke && this.appService.envCode === 'PROD' && code === 'PORTAL_CTX_BONDING') {
      return;
    }
    this.context = ContextsRoles.getContextByCode(code, this.translateService);
  }
}
