import {Component, Input} from '@angular/core';
import {SectionSelector} from './details-view/section-selector';

@Component({
  selector: 'dictionary-section-selector',
  template: `
    <ng-template #section let-object="object" let-disabled="disabled" let-onChange="onChange">
      <selectable-object-section [labelKey]="sectionLabel" [clearable]="false" [searchable]="false">
        <div content="body">
          <dict-combo
            class="bon-input-size"
            [(ngModel)]="object[property]"
            name="type"
            (changeItem)="onChange($event)"
            [dictionary]="dictionary"
            [hiddenIds]="hiddenIds"
            style="width: 100%;"
            [disabled]="disabled"
          ></dict-combo>
          <ng-content></ng-content>
        </div>
      </selectable-object-section>
    </ng-template>
  `,
  providers: [{provide: SectionSelector, useExisting: DictionarySectionSelectorComponent}],
})
export class DictionarySectionSelectorComponent extends SectionSelector {
  @Input() dictionary: string;
  @Input() hiddenIds: Set<number>;
}
