/**
 * Created by wilk on 01.12.2016.
 *
 * To be used for buttons to add icon in front of button title
 * CSS class of icon should be provided as parameter
 */
import {Directive, ElementRef, AfterViewInit, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[btnIcon]',
})
export class BtnIconDirective implements AfterViewInit {
  @Input() btnIcon: string;

  constructor(public card: ElementRef, public renderer: Renderer2) {}

  ngAfterViewInit() {
    this.setBtnIcon();
  }

  setBtnIcon() {
    if (!this.btnIcon) {
      return;
    }
    const element = this.card.nativeElement;
    const btnName = element.innerHTML;
    element.textContent = '';
    const i = __ngRendererCreateElementHelper(this.renderer, element, 'i');
    this.renderer.addClass(i, 'fa');
    this.renderer.addClass(i, this.btnIcon);
    this.renderer.setStyle(i, 'padding-right', '8px');
    __ngRendererCreateTextHelper(this.renderer, element, btnName);
  }
}

type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
  if (name[0] === ':') {
    const match = name.match(/^:([^:]+):(.+)$/);
    return [match[1], match[2]];
  }
  return ['', name];
}

function __ngRendererCreateElementHelper(
  renderer: AnyDuringRendererMigration,
  parent: AnyDuringRendererMigration,
  namespaceAndName: AnyDuringRendererMigration
) {
  const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
  const node = renderer.createElement(name, namespace);
  if (parent) {
    renderer.appendChild(parent, node);
  }
  return node;
}

function __ngRendererCreateTextHelper(
  renderer: AnyDuringRendererMigration,
  parent: AnyDuringRendererMigration,
  value: AnyDuringRendererMigration
) {
  const node = renderer.createText(value);
  if (parent) {
    renderer.appendChild(parent, node);
  }
  return node;
}
