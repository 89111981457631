import {Component, Input} from '@angular/core';
import {SectionSelector} from '../../../../bonding_shared/components/details-view/section-selector';
import {PolicyContractVersionCriteriaDto, PolicySectionAdditionalDataDto} from '../../../../bonding_shared/model';
import {PolicyContractService} from '../../../../bonding_shared/services';

@Component({
  selector: 'policy-contract-section-selector',
  template: `
    <ng-template #section let-openEmitter="openEmitter" let-object="object" let-disabled="disabled">
      <policy-contract-section
        [policyContract]="object[property]"
        [additionalData]="additionalData"
        [labelKey]="sectionLabel"
        [clearable]="false"
        [searchable]="!disabled"
        [openSelectorEmitter]="openEmitter"
        [showFirstInsurance]="showFirstInsurance"
        [showInsuranceYear]="showInsuranceYear"
        [showProtractedDefault]="showProtractedDefault"
        [showStatus]="showStatus"
        [showBusinessStatus]="showBusinessStatus"
      >
      </policy-contract-section>
    </ng-template>
    <ng-template #selector let-openEmitter="openEmitter" let-onChange="onChange">
      <policy-version-selector-simple
        (selectItem)="onChange($event.policyContract); handleAddtionalData($event.policyContract.id)"
        [labelKey]="selectorLabel"
        [open]="openEmitter"
        [criteria]="criteria"
        [fixedSearch]="true"
        [showStatus]="showStatus"
        [showBusinessStatus]="showBusinessStatus"
      >
      </policy-version-selector-simple>
    </ng-template>
  `,
  providers: [{provide: SectionSelector, useExisting: PolicyContractSectionSelectorComponent}],
})
export class PolicyContractSectionSelectorComponent extends SectionSelector {
  @Input() criteria: PolicyContractVersionCriteriaDto;
  @Input() getAdditionalData = false;
  @Input() showProtractedDefault = true;
  @Input() showFirstInsurance = true;
  @Input() showInsuranceYear = true;
  @Input() showStatus = true;
  @Input() showBusinessStatus = false;
  private _additionalContractId: number;
  @Input() set additionalDataContractId(id: number) {
    if (!id) {
      return;
    }
    this._additionalContractId = id;
    this.policyService.getPolicySectionAdditionalData(id).subscribe((r) => (this.additionalData = r));
  }
  public additionalData: PolicySectionAdditionalDataDto;

  constructor(private policyService: PolicyContractService) {
    super();
  }

  handleAddtionalData(id: number) {
    if (this.getAdditionalData) {
      this.policyService.getPolicySectionAdditionalData(id).subscribe((r) => (this.additionalData = r));
    }
  }
}
