import {Component, Input} from '@angular/core';
import {SectionSelector} from '../../../../bonding_shared/components/details-view/section-selector';
import {ClaimVersionCriteriaDto} from '../../../../bonding_shared/model';
import {RouterService} from '../../../../bonding_shared/services';

@Component({
  selector: 'claim-section-selector',
  template: `
    <ng-template #section let-openEmitter="openEmitter" let-object="object" let-disabled="disabled">
      <selectable-object-section
        [labelKey]="sectionLabel"
        [clearable]="false"
        [searchable]="!disabled"
        [openSelectorEmitter]="openEmitter"
      >
        <div class="bon-row" *ngIf="object[property]">
          <b class="a-link font-larger" (click)="router.toClaimDetailsByClaimId(object[property].id)">{{
            object[property].number
          }}</b>
        </div>
      </selectable-object-section>
      <company-section
        *ngIf="object[property]"
        labelKey="claim.mainBeneficiary"
        [companyId]="object[property].mainBeneficiary?.id"
        [clearable]="false"
        [showLink]="true"
        [readOnly]="true"
      >
      </company-section>
    </ng-template>
    <ng-template #selector let-openEmitter="openEmitter" let-onChange="onChange">
      <claim-version-selector
        [labelKey]="selectorLabel"
        [open]="openEmitter"
        (selectItem)="onChange($event.claim)"
        [criteria]="criteria"
      ></claim-version-selector>
    </ng-template>
  `,
  providers: [{provide: SectionSelector, useExisting: ClaimSectionSelectorComponent}],
})
export class ClaimSectionSelectorComponent extends SectionSelector {
  @Input() criteria: ClaimVersionCriteriaDto;

  constructor(public router: RouterService) {
    super();
  }
}
