import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  ClaimIndemnificationVersionBaseDto,
  ClaimIndemnificationVersionCriteriaDto,
  ClaimVersionCriteriaDto,
} from '../../../../bonding_shared/model';
import {SearchDataProvider} from '../../../../bonding_shared/services';
import {Subject} from 'rxjs';
import {ClaimIndemnificationVersionService} from '../../../../bonding_shared/services/claim-indemnification-version.service';
import * as _ from 'lodash';

@Component({
  selector: 'claim-indemnification-selector',
  templateUrl: './claim-indemnification-selector.component.pug',
})
export class ClaimIndemnificationSelectorComponent {
  dataProvider: SearchDataProvider<ClaimIndemnificationVersionCriteriaDto, ClaimIndemnificationVersionBaseDto>;

  @Input() labelKey: string;
  @Input() open: Subject<boolean>;
  @Input() searchOnOpen: boolean;
  @Output() selectItem = new EventEmitter<ClaimIndemnificationVersionBaseDto>();

  @Input() set criteria(c: ClaimVersionCriteriaDto) {
    _.merge(this.dataProvider.searchCriteria.criteria, c);
  }

  constructor(protected service: ClaimIndemnificationVersionService) {
    this.dataProvider = ClaimIndemnificationVersionService.createDataProvider(service);
  }

  selected(claimIndemnificationVersion: ClaimIndemnificationVersionBaseDto) {
    this.open.next(false);
    this.selectItem.emit(claimIndemnificationVersion);
  }
}
