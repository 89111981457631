import {PolicyContractVersionService} from '../../../../../bonding_shared/services';
import {Directive, Input} from '@angular/core';
import {PolicyContractVersionDto, PolicyProductConfigurationDto} from '../../../../../bonding_shared';
import {UntypedFormGroup} from '@angular/forms';
import {ListEmitters} from '../../../../../bonding_shared/components/details-view/list-emitters';
import {FrontingType} from '../../../../../bonding_shared/model/dictionary-ids';

@Directive()
export abstract class MehibPolicyProduct extends ListEmitters {
  productConfig: PolicyProductConfigurationDto;
  readonly FrontingType = FrontingType;

  @Input() policy: PolicyContractVersionDto;
  @Input() policyForm: UntypedFormGroup;
  @Input() showErrors: boolean;

  protected constructor(policyContractVersionService: PolicyContractVersionService) {
    super();
    policyContractVersionService.getProductConfiguration().subscribe((conf) => (this.productConfig = conf));
  }

  showClausesTab() {
    return this.policy && this.policy.id > 0;
  }
}
