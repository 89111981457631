import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Page, SearchResult} from '../model/dtos';
import {ContractVersionDto, ContractVersionSimpleDto} from '../model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ContractVersionService extends AbstractService {
  protected url = this.urlPrefix + 'contractVersion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getContractVersion(id: number): Observable<ContractVersionDto> {
    console.log('contract version details: id = ' + id);
    return this.get<ContractVersionDto>(this.url + '/' + id);
  }

  getContractVersionsByContractId(contractId: number): Observable<ContractVersionDto> {
    const query = this.url + '/findByParent/' + contractId + '?lastIssued=true';
    return this.get<ContractVersionDto>(query);
  }

  getLastContractVersion(contractId: number): Observable<ContractVersionDto> {
    const query = this.url + '/last/' + contractId;
    return this.get<ContractVersionDto>(query);
  }

  getAllVersions(
    page: Page,
    sortBy: string,
    desc: boolean,
    active: boolean
  ): Observable<SearchResult<ContractVersionSimpleDto>> {
    const query: string =
      this.url +
      '?start=' +
      page.start +
      '&count=' +
      page.count +
      '&sortBy=' +
      sortBy +
      '&desc=' +
      desc +
      '&active=' +
      active;
    return this.get<SearchResult<ContractVersionSimpleDto>>(query);
  }

  deleteContractVersion(id: number): Observable<ContractVersionDto> {
    console.log('delete contract version: id = ' + id);
    return this.delete<ContractVersionDto>(this.url + '/' + id);
  }

  unsuspend(contractVersionId: number): Observable<ContractVersionDto> {
    console.log('unsuspend contract: id = ' + contractVersionId);
    return this.postEmpty<ContractVersionDto>(`${this.url}/${contractVersionId}/unsuspend`);
  }

  createFromOffer(offerId: number): Observable<ContractVersionDto> {
    return this.postEmpty<ContractVersionDto>(this.url + '/createFromOffer/' + offerId);
  }
}
