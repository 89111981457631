/**
 * Created by awilk on 04.12.2018.
 * Simple checkbox row without ngValue accessor
 */
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'checkbox-row-simple',
  template: `
    <div class="bon-row">
      <label class="bon-label" [class.long-label]="longLabel">
        {{ label || labelKey | translate }}
        <hover-info *ngIf="hooverMsg" [message]="hooverMsg"></hover-info>
      </label>
      <input
        *ngIf="!control"
        type="checkbox"
        [ngModel]="value"
        (change)="onChangeItem($event)"
        [disabled]="disabled"
        [readonly]="readonly"
        class="bon-checkbox"
        style="width: auto; min-width: 0;"
        [class.no-top-margin]="resetTopMargin"
        [class.align-right-local]="alignRight"
      />
      <ng-container *ngIf="control">
        <input
          type="checkbox"
          [ngModel]="value"
          [formControl]="control"
          (change)="onChangeItem($event)"
          [disabled]="disabled"
          class="bon-checkbox"
          style="width: auto; min-width: 0;"
          [class.no-top-margin]="resetTopMargin"
          [class.align-right-local]="alignRight"
        />
        <error-message [control]="control" [show]="showErrors"></error-message>
      </ng-container>
    </div>
  `,
  styles: ['.align-right-local {float: right; margin-right: 20px;}'],
})
export class CheckboxRowSimpleComponent {
  @Input() label: string;
  @Input() labelKey: string;
  @Input() longLabel = false;
  @Input() disabled: any;
  @Input() readonly: any;
  @Input() resetTopMargin = false;
  @Input() alignRight = false;
  @Input() hooverMsg: boolean;
  @Input() value = false;
  @Input() showErrors: boolean;
  @Input() control: UntypedFormGroup;

  @Output() changeState = new EventEmitter<boolean>();

  onChangeItem(event: any) {
    console.log('checkbox checked: ', event.target.checked);
    this.value = event.target.checked ? true : false;
    this.changeState.emit(this.value);
  }
}
