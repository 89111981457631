import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {TreatyDetailsComponent} from './treaty-details.component';
import {TreatyGuiService} from './services/treaty-gui.service';
import {TreatySearchComponent} from './treaty-search.component';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {TreatySearchGuard} from './_guards';

@NgModule({
  imports: [BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule, CompanySharedModule],
  declarations: [TreatyDetailsComponent, TreatySearchComponent],
  providers: [TreatyGuiService, TreatySearchGuard],
})
export class TreatyModule {}
