import {Component, Input} from '@angular/core';
import {ClaimVersionDto, UserRange} from '../../../bonding_shared';
import {ClaimUtilsService} from '../services/claim-utils.service';
import {ClaimRiskType, ClaimStatus, ClaimVersionType} from '../../../bonding_shared/model/dictionary-ids';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {ControlContainer, NgForm} from '@angular/forms';
import {DictionaryUtils} from '../../../bonding_shared/utils/dictionary-utils';

@Component({
  selector: 'kuke-claim-general-information',
  templateUrl: './kuke-claim-general-information.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KukeClaimGeneralInformationComponent extends ListEmitters {
  @Input() intranet = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() showErrors = false;

  readonly intranetUserRange: UserRange = 'intranet';
  readonly ClaimVersionType = ClaimVersionType;
  readonly ClaimUtilsService = ClaimUtilsService;

  private _claimVersion: ClaimVersionDto;

  private _otherCreditor = false;
  private _otherDebtor = false;

  constructor() {
    super();
    this.selectorNameList = ['CurrentCreditor', 'CurrentDebtor'];
    this.initializeSelectorEmitters(true);
  }

  @Input()
  set claimVersion(claimVersion: ClaimVersionDto) {
    this._claimVersion = claimVersion;
    this._otherCreditor = this.claimVersion.currentCreditor !== undefined;
    this._otherDebtor = this.claimVersion.currentDebtor !== undefined;
  }

  get claimVersion() {
    return this._claimVersion;
  }

  get isCreditInsuranceType() {
    return ClaimUtilsService.isExpectedRiskType(this.claimVersion, ClaimRiskType.CREDIT_INSURANCE);
  }

  get isSuretyType() {
    return ClaimUtilsService.isExpectedRiskType(this.claimVersion, ClaimRiskType.SURETY);
  }

  get isProdRiskType() {
    return ClaimUtilsService.isExpectedRiskType(this.claimVersion, ClaimRiskType.PROD_RISK);
  }

  get isCreditInsuranceOrProdRiskType() {
    return this.isCreditInsuranceType || this.isProdRiskType;
  }

  get otherCreditor(): boolean {
    return this._otherCreditor;
  }

  set otherCreditor(v: boolean) {
    if (!v) {
      this.claimVersion.currentCreditor = null;
    }
    this._otherCreditor = v;
  }

  get otherDebtor(): boolean {
    return this._otherDebtor;
  }

  set otherDebtor(v: boolean) {
    if (!v) {
      this.claimVersion.currentDebtor = null;
    }
    this._otherDebtor = v;
  }

  get isNewVersion(): boolean {
    return !(this.claimVersion && this.claimVersion.id);
  }

  get isFirstClaimVersion(): boolean {
    return this.claimVersion.versionNumber === 1;
  }

  get isClosedClaim(): boolean {
    return DictionaryUtils.in(
      this.claimVersion?.status,
      ClaimStatus.CLOSED_PAID,
      ClaimStatus.CLOSED_REJECTED,
      ClaimStatus.CLOSED_DEBT_PAID,
      ClaimStatus.CLOSED_CLAIM_REATRETED
    );
  }

  isFieldEditableWhenCreatingNewVersionOfType(...typeIds: number[]): boolean {
    return (
      this.intranet &&
      this.isNewVersion &&
      (!this.isSuretyType || ClaimUtilsService.isExpectedVersionType(this.claimVersion, ...typeIds))
    );
  }

  get hiddenVersionTypeIds(): number[] {
    return DictionaryUtils.in(this.claimVersion?.claim?.riskType, ClaimRiskType.SURETY)
      ? [ClaimVersionType.ENTIRE_CLAIM_CORRECTION]
      : [];
  }
}
