/**
 * Created by szkrabko on 24.03.2017.
 */
import {map} from 'rxjs/operators';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, Input, forwardRef, OnInit, Output, EventEmitter, TemplateRef, ContentChild} from '@angular/core';
import {DictionaryService} from '../../services/dictionary.service';
import {DictionaryDto, DictionaryBaseDto} from '../../model/dtos';
import {DictionaryBaseService} from '../../services/dictionary-base.service';
import {Observable, BehaviorSubject} from 'rxjs';

const DICT_AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DictAutoCompleteComponent),
  multi: true,
};

@Component({
  selector: 'dict-auto-complete',
  template: `
    <input
      *ngIf="!presentationMode"
      [ngModel]="_selectedItem"
      name="dictAutoComplete"
      (ngModelChange)="selectedItem = $event"
      class="bon-input"
      [disabled]="disabled"
      autocomplete="off"
      auto-complete
      [value-formatter]="valueFormatter"
      [list-formatter]="listFormatter"
      [match-formatted]="matchFormatted"
      [source]="items"
      [accept-user-input]="acceptUserInput"
      [min-chars]="minCharacterCount"
      style="width: 100%;"
      [bonFocus]="focus"
    />
    <div *ngIf="presentationMode" class="bon-input-size">
      <span [class.presentation]="true">{{ valueFormatter(_selectedItem) }}</span>
    </div>
    <div *ngIf="errorMessage" class="invalid">Error: errorMessage</div>
  `,
  providers: [DICT_AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR],
})
export class DictAutoCompleteComponent implements ControlValueAccessor, OnInit {
  @Input() valueFormatter: (arg: any) => string;
  @Input() listFormatter: (arg: any) => string;
  @Input() matchFormatted = true;
  @Input() acceptUserInput = false;

  @Input() nullLabel: string;
  @Input() parentDictionary: string;
  @Input() dictionary: string;
  @Input() disabled = false;
  @Input() label = 'name';
  @Input() focus = 'auto';
  @Input() minCharacterCount = 3;

  @Input() entries: DictionaryBaseDto[];
  @Input() presentationMode = false;

  /**
   * The items that will not be shown in a list. This should be be stored as set,
   * i.e.  hiddenIds[12] = true;
   */
  @Input() hiddenIds: Set<number>;

  @Output() changeItem = new EventEmitter<DictionaryBaseDto>();

  @ContentChild(TemplateRef, {static: true}) template: TemplateRef<any>;

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  private _selectedKey: number;
  public _selectedItem: DictionaryBaseDto;
  // Item set from model. May be possibly incorrect.
  private selectedItemFromExternal: DictionaryBaseDto;

  public items: DictionaryBaseDto[] = new Array<DictionaryBaseDto>();
  public errorMessage: string;
  private parentEntries: DictionaryDto[];
  private _parentDictionaryEntryId: number;

  private subjectItems = new BehaviorSubject<DictionaryBaseDto[]>(null);

  constructor(private dictService: DictionaryService, private dictBaseService: DictionaryBaseService) {}

  @Input() set parentDictionaryEntryId(parentDictionaryEntryId: number) {
    if (this._parentDictionaryEntryId) {
      this._selectedItem = undefined;
      this.selectedItemFromExternal = undefined;
    }
    this._parentDictionaryEntryId = parentDictionaryEntryId;
    this.updateEntriesForParentDictionaryEntryId();
  }

  ngOnInit() {
    if (!this.entries) {
      this.loadDictionaryEntries();
    } else {
      this.initEntries(this.entries);
    }
  }

  get selectedItem(): DictionaryBaseDto {
    return this._selectedItem;
  }

  set selectedItem(it: DictionaryBaseDto) {
    this._selectedItem = it;
    if (this.onChangeListeners) {
      this.onChangeListeners(this._selectedItem);
      this.changeItem.emit(this._selectedItem);
    }
  }

  // From ControlValueAccessor interface
  writeValue(it: DictionaryDto): void {
    if (it) {
      let key: number | string;
      if (typeof it === 'string') {
        key = it;
      } else {
        key = it.id;
      }
      this.findSelectedItem(key).subscribe((item) => {
        this.selectedItemFromExternal = item;
        this.updateSelection();
      });
    } else {
      this.updateSelection();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private updateEntriesForParentDictionaryEntryId() {
    if (this.parentEntries && this._parentDictionaryEntryId) {
      this.initEntries(
        this.parentEntries
          .filter((entry) => entry.id === this._parentDictionaryEntryId)
          .map((entry) => entry.relatedDictionaries[this.dictionary])
          .reduce(function (prev, cur) {
            return prev.concat(cur);
          })
      );
    }
  }

  private loadDictionaryEntries() {
    if (this.parentDictionary) {
      this.dictService.getDictionary(this.parentDictionary).subscribe(
        (entries) => {
          this.parentEntries = entries;
          this.updateEntriesForParentDictionaryEntryId();
        },
        (error) => (this.errorMessage = error)
      );
    } else {
      this.dictBaseService
        .getDictionaryBase(this.dictionary)
        // .map(entries => {
        //   let baseEntries: DictionaryBaseDto[] = [];
        //   for (let entry of entries) {
        //     let outputItem = <DictionaryBaseDto>{};
        //     outputItem.id = entry.id;
        //     outputItem.code = entry.code;
        //     outputItem.name = entry.name;
        //     baseEntries.push(outputItem);
        //   }
        //   return baseEntries;
        // })
        .subscribe(
          (entries) => this.initEntries(entries),
          (error) => (this.errorMessage = error)
        );
    }
  }

  private initEntries(items: DictionaryBaseDto[]) {
    if (this.hiddenIds && this.hiddenIds.size > 0) {
      this.items = [];
      for (const it of items) {
        if (!this.hiddenIds.has(it.id)) {
          this.items.push(it);
        }
      }
    } else {
      this.items = items;
    }

    this.subjectItems.next(items);
  }

  private updateSelection() {
    if (this.selectedItemFromExternal) {
      this.findSelectedItem(this.selectedItemFromExternal.id).subscribe((item) => {
        this._selectedItem = item;
        if (this.selectedItemFromExternal) {
          this._selectedKey = this.selectedItemFromExternal.id;
        }
      });
    } else {
      this._selectedItem = undefined;
      this._selectedKey = undefined;
    }
  }

  private findSelectedItem(k: number | string): Observable<DictionaryBaseDto> {
    return this.subjectItems.pipe(
      map((items) => {
        if (k && items) {
          for (const it of items) {
            if ((typeof k === 'number' && String(it.id) === String(k)) || (typeof k === 'string' && it.name === k)) {
              return it;
            }
          }
        }
        return undefined;
      })
    );
  }
}
