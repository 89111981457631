import {Component, Input, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  CompanyBaseDto,
  PolicyInvestorDto,
  RouterService,
} from '../../../../../bonding_shared';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';

@Component({
  selector: 'investors-section',
  templateUrl: './investors-section.component.pug',
})
export class InvestorsSectionComponent extends Section {
  @ViewChild('investorsList', {static: true}) investorsList: ATableComponent<PolicyInvestorDto>;
  self = this;

  @Input() showSelected = false;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    public router: RouterService
  ) {
    super(policyContractViewService, appService);
  }

  addPolicyInvestor(company: CompanyBaseDto) {
    this.investorsList.onAddItem(<PolicyInvestorDto>{company: company, selected: false});
  }
}
