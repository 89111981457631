import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {LoggedUserService} from '../services/logged-user.service';
import {UserDto} from '../model/dtos';

@Directive({
  selector: '[ifBU],[ifBUnot]',
})
export class BusinessUnitDirective implements OnInit {
  private businessUnitName: string | string[];
  private negation: boolean;

  @Input() set ifBU(businessUnitName) {
    this.businessUnitName = businessUnitName;
    this.negation = false;
  }

  @Input() set ifBUnot(businessUnitName) {
    this.businessUnitName = businessUnitName;
    this.negation = true;
  }

  constructor(
    private loggedUserService: LoggedUserService,
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<any>
  ) {}

  ngOnInit() {
    if (this.negation ? !this.isBusinessUnitFromCountry() : this.isBusinessUnitFromCountry()) {
      this.viewContainer.createEmbeddedView(this.template);
    } else {
      this.viewContainer.clear();
    }
  }

  isBusinessUnitFromCountry(): boolean {
    const user: UserDto = this.loggedUserService.getLoggedUserData();
    const userBUNameUpperCase = user.businessUnit.name.toUpperCase();
    if (user && user.businessUnit && user.businessUnit.name) {
      if (typeof this.businessUnitName === 'string') {
        return this.businessUnitName.toUpperCase() === userBUNameUpperCase;
      } else {
        return this.businessUnitName.map((item) => item.toUpperCase()).includes(user.businessUnit.name.toUpperCase());
      }
    }
    return false;
  }
}
