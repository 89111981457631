import {Injectable} from '@angular/core';
import {from as observableFrom, Observable, of as observableOf} from 'rxjs';
import {distinct, map, mergeMap, share, take, tap} from 'rxjs/operators';
import {
  AddressDto,
  BondDto,
  BondVersionCriteriaDto,
  BondVersionDto,
  BondVersionService,
  BusinessUnitBaseDto,
  CompanyDto,
  CompanyPortalDto,
  CompanySimpleDto,
  ContractBondDto,
  ContractVersionDto,
  ContractVersionSimpleDto,
  DictionaryBaseDto,
  LocalDateRange,
  Page,
  SearchDataProvider,
  SearchResult,
  SortBy,
  TemplateService,
  TemplateSimpleDto,
} from '../../../bonding_shared';
import {
  BondStatus,
  BusinessObjectType,
  ContractType,
  DocumentType,
  VersionType,
} from '../../../bonding_shared/model/dictionary-ids';
import {PortalCompanyService, PortalContractVersionService} from '../../services';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';

@Injectable()
export class ClientBondGuiService {
  anyNotRejectedRequests = false;
  /**
   * Keeps selected criteria for bond search view
   */
  searchDataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionDto>;
  bondValuesVisible = false;
  private searchDataProviders: SearchDataProvider<BondVersionCriteriaDto, BondVersionDto>[] = [];
  private contractVersion: ContractVersionDto;
  private client: CompanyDto;
  private beneficiary: CompanyDto;
  private customTemplate: TemplateSimpleDto;

  constructor(
    private _companyService: PortalCompanyService,
    private _contractVersionService: PortalContractVersionService,
    private _bondService: BondVersionService,
    private _templateService: TemplateService
  ) {
    this.searchDataProvider = new SearchDataProvider<BondVersionCriteriaDto, BondVersionDto>(_bondService);
  }

  get bondService() {
    return this._bondService;
  }

  static newSearchCriteria(contractVersion?: ContractVersionDto): BondVersionCriteriaDto {
    const criteria = <BondVersionCriteriaDto>{};
    criteria.bond = <BondDto>{};
    criteria.validFrom = <LocalDateRange>{};
    criteria.validTo = <LocalDateRange>{};
    criteria.contractType = <DictionaryBaseDto>{};
    if (contractVersion) {
      criteria.contractId = contractVersion.contract.id;
      criteria.contractBusinessUnit = <BusinessUnitBaseDto>{id: contractVersion.contract.businessUnit.id};
    }
    return criteria;
  }

  getSearchDataProviderForContractVersion(contractVersionId: number): Observable<any> {
    if (this.searchDataProviders[contractVersionId]) {
      return observableOf(this.searchDataProviders[contractVersionId]);
    } else {
      return this.getContractVersion(contractVersionId).pipe(
        map((contractVersion) => {
          const dp = (this.searchDataProviders[contractVersionId] = this.createDataProvider());
          dp.searchCriteria.criteria = ClientBondGuiService.newSearchCriteria(contractVersion);
          dp.textSearch = false;
          return dp;
        })
      );
    }
  }

  createDataProvider(): SearchDataProvider<BondVersionCriteriaDto, BondVersionDto> {
    return new SearchDataProvider<BondVersionCriteriaDto, BondVersionDto>(this._bondService);
  }

  initialVersion(
    clientId: number,
    subtypeId: number,
    buId: number,
    replacedBondVersionId: number,
    contractVersionId?: number
  ): Observable<BondVersionDto> {
    return this._bondService.getInitialVersion(
      clientId,
      null,
      subtypeId,
      buId,
      contractVersionId,
      replacedBondVersionId
    );
  }

  newBondVersionForBond(bondId: number) {
    return this._bondService.getNewBondVersion(bondId);
  }

  getBondVersion(bondVersionId: number): Observable<BondVersionDto> {
    return this._bondService.getBondVersion(bondVersionId).pipe(share());
  }

  getCompany(clientId: number): Observable<CompanyDto> {
    if (this.client && this.client.id === clientId) {
      console.log('Got company from cache:');
      return observableOf(this.client);
    }
    console.log('Got company from backend:');
    return this._companyService.getById<CompanyDto>(clientId).pipe(
      tap((c) => (this.client = c)),
      share()
    );
  }

  getContractVersion(contractVersionId: number): Observable<ContractVersionDto> {
    if (this.contractVersion && this.contractVersion.id === contractVersionId) {
      console.log('Got contractVersion from cache:');
      return observableOf(this.contractVersion);
    }
    console.log('Got contractVersion from backend:');
    return this._contractVersionService.getContractVersion(contractVersionId).pipe(
      tap((c) => (this.contractVersion = c)),
      share()
    );
  }

  saveBondVersion(bondVersion: BondVersionDto): Observable<BondVersionDto> {
    return this._bondService.saveBondVersion(bondVersion).pipe(share());
  }

  getContractBondTypeById(types: ContractBondDto[], bondTypeId: number): ContractBondDto {
    if (!types) {
      return null;
    }
    const cTypes = types.filter((t) => parseInt(t.bondType.id + '', 10) === parseInt(bondTypeId + '', 10));
    if (cTypes.length !== 1) {
      console.log(
        '!!! No or more than one active and activated  contract versions  found = ' +
          cTypes.length +
          ', bondTypeId = ' +
          bondTypeId,
        types
      );
      return null;
    }
    return cTypes[0];
  }

  getLastBondVersionForBond(bondId: number): Observable<BondVersionDto> {
    return this._bondService.getLastBondVersionForBond(bondId);
  }

  setRelatedObjectIdsInBondVersion(
    bv: BondVersionDto,
    company: CompanySimpleDto,
    contractVersion: ContractVersionSimpleDto,
    bondType: ContractBondDto
  ) {
    bv.clientId = company.id;
    bv.contractVersionId = contractVersion.id;
    bv.type = bondType.bondType;
  }

  getClientBondTemplates(
    businessUnitId: number,
    dictionarySelectors: DictionaryBaseDto[]
  ): Observable<TemplateSimpleDto[]> {
    return this._templateService.findByType(
      DocumentType.BOND,
      BusinessObjectType.BOND_VERSION,
      undefined,
      dictionarySelectors,
      undefined,
      undefined,
      businessUnitId
    );
  }

  getClientBondCustomTemplates(
    dictionarySelectors: DictionaryBaseDto[],
    clientId: number,
    contractId: number
  ): Observable<TemplateSimpleDto[]> {
    return this._templateService.getClientBondOwnTemplates(dictionarySelectors, clientId, contractId);
  }

  setBondValuesVisible(bond: BondVersionDto) {
    this.bondValuesVisible = false;
  }

  isAnnex(bond: BondVersionDto) {
    return bond && bond.versionType && bond.versionType.id === VersionType.ANNEX;
  }

  isBondRequest(bond: BondVersionDto, statusChanged: boolean): boolean {
    return (
      bond &&
      bond.status &&
      (BusinessUtils.isRequest(bond.status.id) || (statusChanged && bond.status.id === BondStatus.BOND))
    );
  }
  isNotActiveBond(bond: BondVersionDto): boolean {
    return !bond || !bond.status || BusinessUtils.isRequest(bond.status.id);
  }

  clearCache() {
    console.log('Cache cleared ...');
    this.clearCacheExceptBondVersion();
  }

  clearCacheExceptBondVersion() {
    console.log('Cache cleared ...');
    this.contractVersion = undefined;
    this.client = undefined;
    this.beneficiary = undefined;
    this.anyNotRejectedRequests = false;
  }

  getLastBeneficiaries(): Observable<NamedBeneficiaryRaw> {
    const searchLimit = 20;
    const resultLimit = 10;
    const page = <Page>{count: searchLimit, start: 0};
    const sortBy = <SortBy>{};
    sortBy.column = 'creationDate';
    sortBy.direction = 'DOWN';

    return initDataProvider(this.createDataProvider())
      .search(page, sortBy)
      .pipe(
        mergeMap((searchBondResult: SearchResult<BondVersionDto>) => {
          return observableFrom(searchBondResult.result);
        }),
        map((bondVersion: BondVersionDto) => {
          let name: string;
          const beneficiaryRaw = bondVersion.beneficiaryRaw;
          if (!beneficiaryRaw) {
            return null;
          }
          if (beneficiaryRaw.physicalPersonIndicator) {
            name = beneficiaryRaw.physicalPerson.firstName;
          } else {
            name = beneficiaryRaw.registrationName;
          }
          return <NamedBeneficiaryRaw>{
            name: name,
            beneficiaryRaw: beneficiaryRaw,
            executionAddress: bondVersion.executionAddress,
          };
        }),
        distinct((value: NamedBeneficiaryRaw) => {
          return value && value.name;
        }),

        take(resultLimit)
      );

    function initDataProvider(
      lastBeneficieriesDAtaProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionDto>
    ): SearchDataProvider<BondVersionCriteriaDto, BondVersionDto> {
      const crt = (lastBeneficieriesDAtaProvider.searchCriteria.criteria = <BondVersionCriteriaDto>{});
      crt.bond = <BondDto>{};
      crt.contractType = <DictionaryBaseDto>{};
      crt.contractType.id = ContractType.BOOSTER;
      crt.validFrom = <LocalDateRange>{};
      crt.validTo = <LocalDateRange>{};
      crt.activated = true;
      lastBeneficieriesDAtaProvider.textSearch = false;

      return lastBeneficieriesDAtaProvider;
    }
  }

  setCustomTemplate(ct: TemplateSimpleDto) {
    this.customTemplate = ct;
  }

  getCustomTemplate() {
    return this.customTemplate;
  }
}

export type NamedBeneficiaryRaw = {name: string; beneficiaryRaw: CompanyPortalDto; executionAddress: AddressDto};
