import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm, UntypedFormBuilder} from '@angular/forms';
import {
  AppConfigService,
  ClientCompanyDto,
  ClientCompanyService,
  CompanyService,
  DictionaryService,
  GrowlService,
  LoggedUserService,
  RouterService,
} from '../../../bonding_shared';
import {ActivatedRoute} from '@angular/router';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';

@Component({
  selector: 'client-company-details',
  templateUrl: './client-company-details.component.pug',
})
export class ClientCompanyDetailsComponent extends DetailsView implements OnInit {
  id: number;
  portalMode = false;
  clientCompany: ClientCompanyDto;
  viewParams: ClientCompanyViewParams;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  constructor(
    private _route: ActivatedRoute,
    private clientCompanyService: ClientCompanyService,
    public appConfigService: AppConfigService,
    public router: RouterService,
    private loggedUserService: LoggedUserService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.portalMode = this.loggedUserService.portal;
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this.commonInit<ClientCompanyViewParams>(this._route);
  }

  initViewWithId(id: number) {
    this.id = id;
    this.clearErrors();
    this.clientCompanyService.getById<ClientCompanyDto>(this.id).subscribe(
      (tp) => {
        this.clientCompany = tp;
        this.handleButtons();
      },
      (error) => (this.objectNotFound = true)
    );
  }

  initViewWithCustomParams(q: ClientCompanyViewParams) {
    this.viewParams = q;
    this.clearErrors();
    this.setCreateAndReturnMode();
    if (q.id) {
      this.clientCompanyService.getById<ClientCompanyDto>(+q.id).subscribe({
        next: (tp) => {
          this.clientCompany = tp;
          this.handleButtons();
        },
        error: () => (this.objectNotFound = true),
      });
    } else if (q.companyId) {
      this.clientCompanyService.getInitialClientCompany(+q.companyId).subscribe({
        next: (tp) => {
          this.clientCompany = tp;
          this.handleButtons();
        },
        error: (error) => console.log(error),
      });
    } else {
      this.objectNotFound = true;
    }
  }

  onSave() {
    console.log('onSave clientCompany::  form = ', this.form);
    this.clearErrors();
    this.inProgress = true;
    if (this.formValidates()) {
      this.clientCompanyService.save(this.clientCompany).subscribe(
        (tp) => {
          this.afterSave(tp);
        },
        (error) => this.handleServerError(error)
      );
    }
  }

  afterSave(tp: ClientCompanyDto) {
    console.log('clientCompanyService saved:', tp);
    this.clientCompany = tp;
    this.clearErrors();
    this.showSavedMsg();
    this.redirect(tp);
  }

  redirect(tp: ClientCompanyDto): void {
    if (this.viewParams && this.viewParams.returnViewId) {
      this.router.toCustomView(
        +this.viewParams.returnViewId,
        !this.viewParams.returnObjectId,
        this.viewParams.returnObjectId ? +this.viewParams.returnObjectId : undefined,
        tp.id
      );
    } else {
      // to show proper id in url
      this.router.toClientCompanyDetails(tp.id);
    }
  }

  handleButtons() {
    this.saveButton.hidden = false;
    this.cancelButton.hidden = this.saveButton.hidden;
  }

  onCancel() {
    super.onCancel(this._route);
  }

  private setCreateAndReturnMode(): void {
    if (this.viewParams && this.viewParams.returnViewId) {
      this.saveButton.name = 'common.button.saveAndReturn';
    } else {
      this.saveButton.name = 'common.button.save';
    }
  }
}

export class ClientCompanyViewParams {
  // string when reading
  id: number | string;
  companyId: number | string;
  returnViewId?: number | string;
  returnObjectId: number | string;
}
