import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlContainer, NgForm, UntypedFormGroup} from '@angular/forms';
import {
  ATableComponent,
  BondVersionDto,
  BondVersionService,
  BondVersionSimpleDto,
  CompanyIdDto,
  CompanyRawDto,
  CompanySimpleDto,
  ContractBondDto,
  ContractVersionService,
  Country,
  Currency,
  DictionaryBaseDto,
  DictionaryDto,
  DictionaryService,
  LoggedUserService,
  RouterService,
  UserService,
  UserSimpleDto,
} from '../../../bonding_shared';
import {BondStatus, DictionaryProfile, DictionaryPropertyType} from '../../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {BusinessUnit} from '../../../bonding_shared/model/business-unit';
import {DateUtils} from '../../../bonding_shared/utils/date-utils';
import {BehaviorSubject} from 'rxjs';
import {NumberUtils} from '../../../bonding_shared/utils/number-utils';

@Component({
  selector: 'kuke-bond-form',
  templateUrl: 'kuke-bond-form.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KukeBondFormComponent implements OnInit {
  self = this;
  public _bondVersion: BondVersionDto;
  public _bondIssueDate: Date;
  private deliveryTypes: {[key: string]: DictionaryDto} = {};
  readonly BondStatus = BondStatus;
  readonly CompanyListEmitter = CompanyListEmitter;
  invoicedCompanies: CompanySimpleDto[];
  isGSP = false;
  bondCurrencyDictProfile = DictionaryProfile.KUKE_BOND_CURRENCY;

  bondBankAccountCurrencyCodeRegExp: RegExp = /PLN|EUR|USD|GBP/;

  @ViewChild('consortiumClientCompaniesTable') consortiumClientCompaniesTable: ATableComponent<CompanyIdDto>;
  @ViewChild('consortiumBeneficiaryCompaniesTable') consortiumBeneficiaryCompaniesTable: ATableComponent<CompanyIdDto>;

  @Input() set bondVersion(bond: BondVersionDto) {
    this._bondVersion = bond;
    this.setPremiumAndFeesCheckboxes(bond);
    this.loadContractData(bond);
  }

  get bondVersion() {
    return this._bondVersion;
  }

  @Input() set bondIssueDate(bondIssueDate: Date) {
    this._bondIssueDate = bondIssueDate;
  }

  get bondIssueDate() {
    return this._bondIssueDate;
  }

  @Input() showDetails = false;
  bondForm: UntypedFormGroup;
  @Input() showErrors = false;
  @Input() loadTemplates: Function;
  @Input() bondVersions: BondVersionSimpleDto[];
  @Input() bondSubtypes: {[key: number]: DictionaryDto} = {};
  @Input() contractTypeId: number;
  @Input() isPromesa: boolean;

  @Input() disabled = true;

  @Input() set contractBond(cb: ContractBondDto) {
    this._contractBond = cb;
  }

  private _contractBond: ContractBondDto;

  listEmitters: ListEmitters = new ListEmitters();

  bondPaymentCheckbox = false;
  otherPaymentCheckbox = false;
  issueBondPaymentCheckbox = false;
  issueAnnexPaymentCheckbox = false;
  annexPaymentCheckbox = false;
  private _approvalUsers: BehaviorSubject<UserSimpleDto[]> = null;

  constructor(
    public router: RouterService,
    public ngForm: NgForm,
    private dictService: DictionaryService,
    private contractVersionService: ContractVersionService,
    private userService: UserService,
    private loggedUserService: LoggedUserService
  ) {
    this.bondForm = ngForm.form;
    this.listEmitters.selectorNameList = [
      CompanyListEmitter.CLIENT,
      CompanyListEmitter.BENEFICIARY,
      CompanyListEmitter.CURRENT_CLIENT,
    ];
    this.listEmitters.initializeSelectorEmitters(true);
  }

  ngOnInit() {
    this.dictService.getDictionary('DeliveryType').subscribe((entries) => {
      for (const entry of entries) {
        this.deliveryTypes[entry.id] = entry;
      }
    });
  }

  private updateDeliveryFee(deliveryType: DictionaryBaseDto) {
    console.log('updateDeliveryFee');
    if (this.bondVersion) {
      if (deliveryType) {
        const fullEntry = this.deliveryTypes[deliveryType.id];
        if (fullEntry && this.isEuro(this.bondVersion.currency)) {
          this.bondVersion.deliveryFee = parseFloat(fullEntry.properties[DictionaryPropertyType.DELIVERY_FEE]);
        }
      } else {
        this.bondVersion.deliveryFee = undefined;
      }
    }
  }

  private setPremiumAndFeesCheckboxes(bond: BondVersionDto) {
    this.bondPaymentCheckbox = bond.bondPayment != null;
    this.otherPaymentCheckbox = bond.otherPayment != null;
    this.issueBondPaymentCheckbox = bond.issueBondPayment != null;
    this.issueAnnexPaymentCheckbox = bond.issueAnexPayment != null;
    this.annexPaymentCheckbox = bond.anexPayment != null;
  }

  onCurrencyChanged(currency: DictionaryBaseDto) {
    this.updateDeliveryFee(this.bondVersion.deliveryType);

    if (this.isGSP) {
      if (this.isCurrencyMatchingRegex()) {
        this.bondVersion.bankAccount = this.bondVersion.currency;
      } else {
        this.bondVersion.bankAccount = Currency.getUsdCurrency();
      }
    }
  }

  isCurrencyMatchingRegex() {
    return (
      this.bondVersion.currency == null || this.bondBankAccountCurrencyCodeRegExp.test(this.bondVersion.currency.code)
    );
  }

  onDeliveryTypeChanged(deliveryType: DictionaryBaseDto) {
    this.updateDeliveryFee(deliveryType);
  }

  isEuro(currency: DictionaryBaseDto) {
    return currency && Currency.isEuro(currency.id);
  }

  get readOnly() {
    return this.disabled;
  }

  onLanguageChanged(language: DictionaryBaseDto) {
    this.loadTemplates();
  }

  isCityFromDict(country: DictionaryBaseDto): boolean {
    return this.isGermanCountry(country) || this.isItalianCountry(country);
  }

  isGermanCountry(country: DictionaryBaseDto): boolean {
    const b = this.bondVersion;
    return b && Country.isGermany(country);
  }

  isItalianCountry(country: DictionaryBaseDto): boolean {
    const b = this.bondVersion;
    return b && Country.isItaly(country);
  }

  setDefaultCityOfJurisdiction(country: DictionaryBaseDto) {
    if (Country.isItaly(country)) {
      this.bondVersion.cityOfJurisdiction.id = Country.MILAN;
    }

    if (Country.isGermany(country)) {
      this.bondVersion.cityOfJurisdiction.id = Country.KOLN;
    }
  }

  isConsortium(): boolean {
    return BusinessUtils.isConsortiumContractType(this.contractTypeId);
  }

  onSelectConsortiumClient(company: CompanySimpleDto | CompanyRawDto) {
    if (!this.bondVersion.consortiumClientCompanies.map((c) => c.id).includes(company.id)) {
      this.consortiumClientCompaniesTable.onAddItem(company);
    }
  }

  onSelectConsortiumBeneficiary(company: CompanySimpleDto | CompanyRawDto) {
    if (!this.bondVersion.consortiumBeneficiaryCompanies.map((c) => c.id).includes(company.id)) {
      this.consortiumBeneficiaryCompaniesTable.onAddItem(company);
    }
  }

  onConsortiumClientStateChanged(checked: boolean) {
    if (!checked) {
      this.consortiumClientCompaniesTable.deleteAllClicked();
    }
  }

  onConsortiumBeneficiaryStateChanged(checked: boolean) {
    if (!checked) {
      this.consortiumBeneficiaryCompaniesTable.deleteAllClicked();
    }
  }

  loadContractData(bond: BondVersionDto) {
    if (bond.contractVersionId) {
      this.contractVersionService.getContractVersion(bond.contractVersionId).subscribe((cv) => {
        this.invoicedCompanies = cv.contractCompanies.map((c) => c.company);
        this.isGSP = cv.contract.businessUnit.id === BusinessUnit.KUKE_GSP;
      });
    }
  }

  onPremiumInstallmentCheckboxChange() {
    this.bondVersion.bondInstallmentPayments = [];
  }

  isFirstBondVersion() {
    return !this.bondVersion.bond.id || this.bondVersion.versionNumber < 2;
  }

  get cancellationDateEditable() {
    return (
      this.bondVersion.bond.id &&
      this.bondVersion.versionNumber < 2 &&
      this.bondVersion.businessStatus.id === BondStatus.BOND
    );
  }

  get releaseDateEditable() {
    return this.bondVersion.bond.id && this.bondVersion.businessStatus.id === BondStatus.BOND_ACTIVE;
  }

  updateBondVersionValidity() {
    console.log('amountValidityPeriodChanged');
    if (this.isFirstBondVersion()) {
      const firstValueValidFrom = this.bondVersion.values[0].validFrom;
      const firstSupplementaryValueValidFrom =
        this.bondVersion.supplementaryValues && this.bondVersion.supplementaryValues.length > 0
          ? this.bondVersion.supplementaryValues[0].validFrom
          : undefined;
      this.bondVersion.validFrom = DateUtils.minNotNull(firstValueValidFrom, firstSupplementaryValueValidFrom);
    }
    const lastValueValidTo = this.bondVersion.values[this.bondVersion.values.length - 1].validTo;
    const lastSupplementaryValueValidTo =
      this.bondVersion.supplementaryValues && this.bondVersion.supplementaryValues.length > 0
        ? this.bondVersion.supplementaryValues[this.bondVersion.supplementaryValues.length - 1].validTo
        : undefined;
    this.bondVersion.validTo = DateUtils.maxNotNull(lastValueValidTo, lastSupplementaryValueValidTo);
  }

  private loadApprovalUsers() {
    if (this._approvalUsers) {
      return;
    }
    const au = new BehaviorSubject<UserSimpleDto[]>(null);
    this._approvalUsers = au;
    this.userService.getIntranetUsers().subscribe((users) => {
      au.next(users);
    });
  }

  get approvalUserEditable() {
    if (this.bondVersion?.status?.id !== BondStatus.BOND_REQUEST) {
      return false;
    }
    return !!this.approvalUsers.getValue();
  }

  get approvalUsers() {
    this.loadApprovalUsers();
    return this._approvalUsers;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  calculatePremiumReturn() {
    if (!this.bondVersion.premiumReturn) {
      this.bondVersion.premiumReturnAmount = undefined;
      return;
    }
    this.bondVersion.premiumReturnAmount = 0;
    if (this.bondVersion.releaseDate && this.bondVersion.invoiceSummary.amounts) {
      const originalAmount = this.bondVersion.invoiceSummary.amounts.reduce((total, item) => total + item.netAmount, 0);
      const originalPeriodDays = DateUtils.daysBetween(this.bondVersion.bond.validFrom, this.bondVersion.bond.validTo);
      const shortenedPeriodDays = DateUtils.daysBetween(this.bondVersion.bond.validFrom, this.bondVersion.releaseDate);
      console.log('calculatePremiumReturn:' + originalPeriodDays + ', ' + shortenedPeriodDays);
      this.bondVersion.premiumReturnAmount = NumberUtils.roundMoney(
        (originalAmount * shortenedPeriodDays) / originalPeriodDays
      );
    }
  }
}

export enum CompanyListEmitter {
  CLIENT = 'ConsortiumClient',
  BENEFICIARY = 'ConsortiumBeneficiary',
  CURRENT_CLIENT = 'CurrentClient',
}
