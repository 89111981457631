import {Component, Input, OnInit} from '@angular/core';
import {ContractExposures} from '../../home.component';
import {StringUtils} from '../../../../bonding_shared';
import {ChartDataset, ChartOptions, ChartType} from 'chart.js';
import {ChartUtils, Label} from '../chart-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'pie-chart-contract',
  templateUrl: 'piechart-contract.component.pug',
})
export class PieChartContractComponent implements OnInit {
  @Input() contractExposures: ContractExposures;

  public pieChartOptions: ChartOptions;
  public pieChartLabels: Label[];
  public pieChartData: ChartDataset[];
  public pieChartType: ChartType = 'pie';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.pieChartLabels = [
      this.translateService.instant('dashboard.Available'),
      this.translateService.instant('dashboard.Used'),
    ];
    this.pieChartData = [
      {
        data: [
          this.contractExposures.contractAvailableExposureEUR > 0
            ? this.contractExposures.contractAvailableExposureEUR
            : 0,
          this.contractExposures.currentExposureEUR,
        ],
        backgroundColor: [ChartUtils.COLORS[2], ChartUtils.COLORS[1]],
      },
    ];
    this.initOptions();
  }

  initOptions() {
    const availableExposure = this.contractExposures.contractAvailableExposureEUR;
    const currentExposure = this.contractExposures.currentExposureEUR;
    const sum = availableExposure + currentExposure;
    this.pieChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        datalabels: {
          formatter: function (value) {
            return StringUtils.formatNumber(value);
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const label = context.label || '';
              const value = context.dataIndex === 0 ? availableExposure : currentExposure;
              const valueF = StringUtils.formatNumber(value);
              const ratioF = StringUtils.formatNumber((value * 100) / sum);
              return label + ': ' + valueF + ', ' + ratioF + ' %';
            },
          },
        },
      },
    };
  }
}
