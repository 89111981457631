import {Component, OnInit} from '@angular/core';
import {ControlContainer, NgForm, UntypedFormControl, Validators} from '@angular/forms';
import {Section} from '../section';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {
  AppConfigService,
  DictionaryService,
  PolicyContractVersionService,
} from '../../../../../bonding_shared/services';
import {ComboItem} from '../../../../../bonding_shared/components/combos';

@Component({
  selector: 'kuke-policy-contract-data-section',
  templateUrl: './kuke-policy-contract-data-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KukePolicyContractDataSectionComponent extends Section implements OnInit {
  self = this;

  policySubYears: ComboItem[] = [];
  maxSubYears = 1;

  premiumSubinsured: RegExp;

  protected generateNumberDisabled = false;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected policyContractVersionService: PolicyContractVersionService,
    protected dictionaryService: DictionaryService
  ) {
    super(policyContractViewService, appService, policyContractVersionService, dictionaryService);
  }

  ngOnInit() {
    if (this.policy.policyContract.number !== undefined) {
      this.generateNumberDisabled = true;
    }
    this.onSetPolicyContractVersion();
  }

  onSetPolicyContractVersion() {
    this.policySubYears = [];
    for (let i = this.policy.policyContract.policySubYear; i <= this.maxSubYears; i++) {
      this.policySubYears.push({label: i + '', value: i + ''});
    }
  }

  onPolicySave() {
    this.generateNumberDisabled = true;
  }

  // policy contract data
  onGenerateNumberChange(checked: boolean) {
    if (!checked) {
      this.policyForm.addControl('policyContractNumber', new UntypedFormControl('', Validators.required));
    } else if (this.policyForm.contains('policyContractNumber')) {
      this.policy.policyContract.number = null;
      this.policyForm.removeControl('policyContractNumber');
    }
  }
}
