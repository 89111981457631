import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {CountryRatingVersionBaseDto, CountryRatingVersionCriteriaDto} from '../../../bonding_shared/model/dtos';
import {CountryRatingService} from '../../../bonding_shared/services/country-rating.service';

@Injectable()
export class CountryRatingGuiService {
  dataProvider: SearchDataProvider<CountryRatingVersionCriteriaDto, CountryRatingVersionBaseDto>;

  constructor(private countryRatingService: CountryRatingService) {
    this.dataProvider = new SearchDataProvider(countryRatingService);
  }
}
