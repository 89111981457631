<div class="bon-card" style="width: 100%">

  <div>
    <div class="bon-header"><span translate="clientbond.summary.client"></span></div>
    <div class="card-block ">
      <company-info [company]="client" [showLink]="false"></company-info>
    </div>
  </div>

  <div>
    <div class="bon-header"><span translate="clientbond.summary.beneficiary.title"></span></div>
    <div class="card-block ">
      <company-info [company]="bond.beneficiaryRaw" [showLink]="false" [rawCompany]="true" [fullDetails]="false"></company-info>
      <div class="bon-row">
        <label translate="clientbond.summary.beneficiary.email" style="width: initial;"></label>
        <span>{{bond.beneficiaryRaw.contactPerson.email}}</span>
      </div>
      <div class="bon-row">
        <label translate="clientbond.summary.beneficiary.sendByPost" style="width: initial;"></label>
        <span>{{getSendByPostValue()}}</span>
      </div>
      <div class="bon-row">
        <label translate="clientbond.summary.beneficiary.correspondenceAddress" style="width: initial;"></label>
        <span>{{postAddress}}</span>
      </div>
    </div>
  </div>

  <div>
    <div class="bon-header"><span translate="clientbond.summary.details.title"></span></div>
    <div class="card-block ">
      <div class="bon-row"><label translate="clientbond.summary.details.bondType"></label><span>{{bond.type.name}}</span></div>
      <div class="bon-row"><label translate="clientbond.summary.details.value"></label><span>{{bond.values[0].value | number}} {{bond.currency.code}}</span>
      </div>
    </div>
  </div>


  <div>
    <div class="bon-header"><span translate="clientbond.summary.validityPeriod.title"></span></div>
    <div class="card-block ">
      <div class="bon-row"><label translate="clientbond.summary.validityPeriod.validFrom"></label><span>{{bond.validFrom | datetime }}</span></div>
      <div *ngIf="bond.bondValidTo" class="bon-row">
        <label translate="clientbond.summary.validityPeriod.validTo"></label>
        <span>{{bond.bondValidTo | datetime }}</span>
      </div>
      <div *ngIf="!bond.bondValidTo" class="bon-row">
        <label translate="clientbond.summary.validityPeriod.validTo"></label>
        <span translate="clientbond.summary.validityPeriod.unlimited"></span>
      </div>
    </div>
  </div>

  <div>
    <div class="bon-header"><span
      translate>Contract</span></div>
    <div class="card-block ">
      <div class="bon-row"><label translate="clientbond.summary.contract.date"></label><span>{{bond.contractDate | datetime }}</span></div>
      <div class="bon-row"><label translate="clientbond.summary.contract.amount"></label><span>{{bond.contractAmount | number}} {{bond.currency.code}}</span></div>
      <div class="bon-row"><label translate="clientbond.summary.contract.executionAddress"></label><span>{{executionAddress}}</span></div>
      <div class="bon-row"><label translate="clientbond.summary.contract.subject"></label><span>{{bond.contractualObligations}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="bond.specialClauses?.length > 0">
    <div class="bon-header"><span translate="clientbond.summary.specialClauses"></span></div>
    <div class="card-block ">
      <div class="bon-row" *ngFor="let i of bond.specialClauses"><span>{{i.clause.name}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="warnings?.length > 0">
    <div class="bon-header"><span translate="clientbond.summary.warnings"></span></div>
    <div class="card-block ">
      <div class="bon-row" *ngFor="let i of warnings"><span class="warning inline">{{i}}</span>
      </div>
    </div>
  </div>


  <div>
    <div class="card-block ">
      <button class="bon-btn-warning" type="button" (click)="onCancel()" translate="clientbond.summary.button.back"></button>
      <button class="bon-btn-info" type="button" (click)="onIssue()"
              [translate]="bondFlow.canSendIssue() ? 'clientbond.summary.button.acceptAndIssue' : 'clientbond.summary.button.acceptAndSendRequest'"></button>
    </div>
  </div>

</div>

