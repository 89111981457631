import {Component, EmbeddedViewRef, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ColumnComponent} from './column.component';
import {UntypedFormControl} from '@angular/forms';

/**
 * Created by siminski on 15.07.2016.
 */

@Component({
  selector: 'column-template',
  template: ``,
})
export class ColumnTemplateComponent<T> implements OnInit {
  @Input() item: T;
  @Input() column: ColumnComponent<T>;
  @Input() edition: boolean;
  @Input() formControlModel: UntypedFormControl;

  view: EmbeddedViewRef<any>;

  constructor(private viewContainer: ViewContainerRef) {}

  ngOnInit() {
    const context = {
      $implicit: this.column,
      item: this.item,
      edition: this.edition,
      formControlModel: this.formControlModel,
    };
    this.view = this.viewContainer.createEmbeddedView(this.column.template, context);
  }

  get content() {
    let text = '';
    for (const node of this.view.rootNodes) {
      if (node.innerText && node.innerText.trim()) {
        text = node.innerText.trim();
        break;
      }
      if (node.textContent && node.textContent.trim()) {
        text = node.textContent.trim();
        break;
      }
    }

    return text;
  }
}
