import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {QuotationValidators} from '../quotation-validators';

@Directive({
  selector: '[tariffCalculated]',
  providers: [{provide: NG_VALIDATORS, useExisting: TariffCalculatedDirective, multi: true}],
})
export class TariffCalculatedDirective implements Validator {
  /**
   * Method that validates whether the control is empty.
   * Returns the validation result if enabled, otherwise null.
   * @nodoc
   */
  validate(control: AbstractControl): ValidationErrors | null {
    return QuotationValidators.tariffCalculated(control);
  }
}
