import {Injectable} from '@angular/core';
import {Feature} from '../model/dictionary-ids';
import {UserDto} from '../model';
import {LoggedUserService} from './logged-user.service';
import {FeatureService} from './feature.service';

@Injectable({
  providedIn: 'root',
})
export class UserResponsibleService {
  constructor(private featureService: FeatureService, private loggedUserService: LoggedUserService) {}

  getUserResponsibleSearchCriteria(): UserDto {
    if (
      this.featureService.get(Feature.USER_RESPONSIBLE_SEARCH_CRITERIA_VISIBLE) &&
      this.featureService.get(Feature.USER_RESPONSIBLE_SEARCH_CRITERIA_INITIATED) &&
      !this.loggedUserService.portal
    ) {
      return this.loggedUserService.getLoggedUserData();
    }
    return null;
  }
}
