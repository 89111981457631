import {Observable, of, of as observableOf} from 'rxjs';

import {share, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {CompanyBaseDto, CompanyWithExposureDto, SearchResult} from '../../../bonding_shared/model';
import {GroupService} from '../../../bonding_shared/services/group.service';
import {CompanyService} from '../../../bonding_shared/services/company.service';
import {BusinessPropertiesService} from '../../../bonding_shared/services/business-properties.service';
import {
  CompanyGroupDto,
  CompanyGroupIdDto,
  CompanyGroupVersionCriteriaDto,
  CompanyGroupVersionDto,
} from '../../../bonding_shared/model/dtos';

@Injectable()
export class GroupGuiService {
  searchDataProvider: SearchDataProvider<CompanyGroupVersionCriteriaDto, CompanyGroupVersionDto>;

  groupHead: CompanyBaseDto;
  groupMembers: SearchResult<CompanyBaseDto>;

  constructor(
    private service: GroupService,
    private _companyService: CompanyService,
    private businessPopoertiesService: BusinessPropertiesService
  ) {
    this.searchDataProvider = new SearchDataProvider<CompanyGroupVersionCriteriaDto, CompanyGroupVersionDto>(service);
  }

  createDataProvider(): SearchDataProvider<CompanyGroupVersionCriteriaDto, CompanyGroupVersionDto> {
    const result: SearchDataProvider<CompanyGroupVersionCriteriaDto, CompanyGroupVersionDto> = new SearchDataProvider<
      CompanyGroupVersionCriteriaDto,
      CompanyGroupVersionDto
    >(this.service);
    return result;
  }

  initialCompanyGroup(): CompanyGroupDto {
    return <CompanyGroupDto>{companyGroupVersions: []};
  }

  initialCompanyGroupVersion(): Observable<CompanyGroupVersionDto> {
    return of(<CompanyGroupVersionDto>{
      companyGroup: <CompanyGroupIdDto>{},
      groupCompanies: [],
      validFrom: new Date(),
      last: true,
    });
  }
  newCompanyGroupVersion(version: CompanyGroupVersionDto): CompanyGroupVersionDto {
    const result = <CompanyGroupVersionDto>{};
    result.groupCompanies = [];
    result.companyGroup = version.companyGroup;
    return result;
  }

  getGroupHead(groupHeadId: number): Observable<CompanyBaseDto> {
    if (this.groupHead && this.groupHead.id === groupHeadId) {
      console.log('Got member from cache:');
      return observableOf(this.groupHead);
    }

    console.log('Got member from server');
    return this._companyService.getCompanyBaseDto(groupHeadId).pipe(
      tap((h) => (this.groupHead = h)),
      share()
    );
  }

  getGroupMembers(companyId: number): Observable<SearchResult<CompanyWithExposureDto>> {
    return this.service.getGroupMembers(companyId).pipe(
      tap((mbs) => (this.groupMembers = mbs)),
      share()
    );
  }
}
