import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {ControlContainer, NgForm, UntypedFormBuilder, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {
  AddressDto,
  BaseInsurersLimitDto,
  CompanySimpleDto,
  ContactPersonDto,
  DictionaryBaseDto,
  DictionaryDto,
  PolicyInquiryCompanyDto,
  PolicyInquiryVersionDto,
} from '../../bonding_shared/model/dtos';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  BusinessObjectType,
  ContactNoteElementaryRight,
  ContactPersonType,
  Country,
  PolicyElementaryRight,
  PolicyInquiryCompanyRole,
  PolicyInquiryStatus,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
  ServiceNoteElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {
  AppConfigService,
  CacheService,
  CompanyService,
  DictionaryBaseService,
  DictionaryService,
  DocumentService,
  GrowlService,
  LoggedUserService,
  RouterService,
  UserService,
} from '../../bonding_shared/services';
import {InquiryAbstractService} from '../../bonding_shared/services/inquiry-abstract.service';
import {TranslateService} from '@ngx-translate/core';
import {RelatedObject} from '../../bonding_shared/components/related-object-info/model';
import {InquiryCountryInitialiazerService} from './services/inquiry-countries-initialiazer.service';
import {VisibilityService} from './services/visibility.service';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';

declare var $: JQueryStatic;

@Component({
  selector: 'annex-inquiry-details',
  templateUrl: './annex-inquiry-details.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
  providers: [InquiryCountryInitialiazerService],
})
export class AnnexInquiryDetailsComponent extends DetailsView implements OnInit, AfterViewInit {
  inquiry: PolicyInquiryVersionDto;

  readonly: boolean;
  statements: {[key: string]: boolean | null};

  readonly PolicyElementaryRight = PolicyElementaryRight;
  readonly BusinessObjectType = BusinessObjectType;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  public addressToChange: AddressDto;
  private regsisteredStatus: DictionaryDto;

  getCustomTransitions = this.inquiryService.getTransitionsForVersion.bind(this.inquiryService);
  visible: any = {};

  constructor(
    protected route: ActivatedRoute,
    public routerService: RouterService,
    protected documentService: DocumentService,
    protected dictionaryService: DictionaryService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected companyService: CompanyService,
    protected formBuilder: UntypedFormBuilder,
    protected userService: UserService,
    protected loggedUserService: LoggedUserService,
    protected inquiryService: InquiryAbstractService,
    protected growlService: GrowlService,
    public appService: AppConfigService,
    protected cacheService: CacheService,
    protected countryInitialiazerService: InquiryCountryInitialiazerService,
    protected visibilityService: VisibilityService
  ) {
    super(growlService);
    this.hideButtons(false);
    this.deleteButton.hidden = true;
    this.newVersionButton.hidden = true;
    this.newVersionButton.disabled = true;

    this.selectorNameList = ['Factor', 'Cessionary', RelatedObject.SUBINSUREDS];
    this.initializeSelectorEmitters(true);
  }

  noInfoNotExistValidator(group: UntypedFormGroup): ValidationErrors {
    const noInfo = group.get('noInfo');
    const notExist = group.get('notExist');
    return noInfo.value === true || notExist.value === true ? null : {required: true};
  }

  ngOnInit() {
    console.log('START ngOnInit()');
    this.saveButton.hidden = true;
    this.serverErrors = undefined;
    this.route.params.subscribe((params) => this.initializeView(params));
    this.form = this.ngForm.form;
    console.log('END ngOnInit()');
  }

  private getInquiryType() {
    return this.inquiry && this.inquiry.productType;
  }

  ngAfterViewInit() {
    this.form = this.ngForm.form;
  }

  initializeView(params: Params, force?: boolean) {
    console.log('initializeView, params', params);
    const id = +params['id'];
    const clientId = +params['clientId'];
    const readFromCache = params['readFromCache'] && params['readFromCache'].toLowerCase() === 'true';
    if (this.inquiry && id === this.inquiry.id && !force) {
      return;
    }
    this.dictionaryService
      .getDictionaryEntry('PolicyInquiryStatus', PolicyInquiryStatus.POLICY_INQUIRY_REGISTERED)
      .subscribe((s) => (this.regsisteredStatus = s));
    this.initializeInquiry(id, clientId, readFromCache);
  }

  initializeInquiry(inquiryVersionId: number, clientId?: number, readFromCache?: boolean) {
    console.log('initializeInquiry, params', inquiryVersionId, clientId, readFromCache);
    if (!inquiryVersionId) {
      console.log('initialize version');
      if (!readFromCache) {
        this.inquiryService.initialVersion(clientId).subscribe((inqV) => {
          this.setInquiry(inqV);
        });
      } else {
        this.setInquiry(this.cacheService.inquiry);
      }
    } else {
      this.loadInquiry(inquiryVersionId);
    }
  }

  onSave() {
    if (this.inProgress) {
      console.warn('Parallel save clicks');
      return;
    }
    console.log('onSave, inquiry:', this.inquiry);
    this.resetTableValidators();
    this.showErrors = true;
    if (this.addressToChange && !this.addressToChange.street) {
      // if address was not set we need to clear the dto
      this.inquiry.addresses.pop();
      this.inquiry.annexData.addressChange = false;
    }
    if (this.formValidates()) {
      this.inProgress = true;
      this.inquiryService.save(this.inquiry).subscribe({
        next: (inquiry) => {
          this.setInquiry(inquiry);
          this.serverErrors = undefined;
          this.showErrors = false;
          if (inquiry.warnings && inquiry.warnings.length) {
            this.growlService.warning(inquiry.warnings.map((e) => e.message).join('\n'));
          }
          this.showSavedMsg();
        },
        error: (error) => this.handleServerError(error),
        complete: () => {
          this.inProgress = false;
          this.routerService.toPolicyAnnexInquiryDetails(this.inquiry.id);
        },
      });
      this.showErrors = false;
    }
  }

  onCancel(): void {
    super.onCancel(this.route);
  }

  onDelete() {
    console.log('Delete inquiry button pressed!');
    this.inProgress = true;
    this.showErrors = false;
    this.inquiryService.deleteInquiry(this.inquiry.policyInquiry.id).subscribe({
      next: (_) => {
        this.inProgress = false;
        this.growlService.notice('inquiry.annex.deleted');
        this.toPolicy();
      },
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      },
    });
  }

  resetTableValidators(): void {
    if (!this.inquiry.annexData.naceCodeChange && this.form.controls.naceTable) {
      this.form.removeControl('naceTable');
    }
    if (!this.inquiry.annexData.subinsuredChange && this.form.controls.subinsureds) {
      this.form.removeControl('subinsureds');
    }
    if (!this.inquiry.annexData.emailAddressChange && this.form.controls.contactPersonsForm) {
      this.form.removeControl('contactPersonsForm');
    }
  }

  loadInquiry(versionId: number) {
    this.inProgress = true;
    this.inquiryService.getById<PolicyInquiryVersionDto>(versionId).subscribe({
      next: (inquiry) => {
        this.setInquiry(inquiry);
        this.showSaveButton();
        this.inProgress = false;
      },
      error: (error) => {
        this.handleServerError(error);
      },
    });
  }

  formValidates(): boolean {
    return super.formValidates() && this.changeNotEmpty();
  }

  private changeNotEmpty(): boolean {
    if (
      !(
        this.isSignificantChange() ||
        this.inquiry.annexData.emailAddressChange ||
        this.inquiry.annexData.agreementsChange
      )
    ) {
      this.showFormError('inquiry.annex.formEmpty');
      return false;
    } else {
      return true;
    }
  }

  showSavedMsg() {
    this.growlService.notice('inquiry.saved');
  }

  showFormError(extractInfo?: string) {
    this.growlService.error('The form has errors!');
    if (extractInfo) {
      this.growlService.error(extractInfo);
    }
    this.inProgress = false;
  }

  private setInquiry(inqV: PolicyInquiryVersionDto) {
    this.inquiry = inqV;
    if (inqV.addresses.length > 0) {
      this.addressToChange = inqV.addresses[0];
    }
    this.cacheService.inquiry = inqV;
    this.newVersionButton.disabled = !inqV || !inqV.id;
    this.readonly =
      (this.inquiry && this.inquiry.status && this.inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_ACCEPTED) ||
      (this.inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_REGISTERED && this.portal);
    this.handleButtons();

    this.countryInitialiazerService.initMarketNonMarketCountries(this.getInquiryType(), null, this.inquiry, true);

    this.visible = this.visibilityService.getVisibility(this.inquiry.productType.id);
  }

  handleButtons() {
    this.saveButton.hidden = this.readonly;
    this.deleteButton.hidden = this.readonly || this.inquiry.status.id !== PolicyInquiryStatus.POLICY_INQUIRY;
    this.cancelButton.hidden = this.readonly;
  }

  showSaveButton() {
    const inq = this.inquiry;
    if (inq && inq.status && inq.status.id === PolicyInquiryStatus.POLICY_INQUIRY_CLOSED) {
      return;
    }
    this.saveButton.hidden = false;
  }

  generateAddress() {
    if (this.inquiry.annexData.addressChange && this.inquiry.addresses.length === 0) {
      this.addressToChange = <AddressDto>{};
      this.addressToChange.country = <DictionaryBaseDto>{};
      this.addressToChange.country.id = Country.PL; // default country, as not visible on screen
      this.inquiry.addresses.push(this.addressToChange);
    }
  }

  addSubinsured(company: CompanySimpleDto) {
    const subinsured = <PolicyInquiryCompanyDto>{company: company};
    this.inquiryService.setCompanyRole(subinsured, PolicyInquiryCompanyRole.SUBINSURED);

    if (!this.inquiry.subinsureds) {
      this.inquiry.subinsureds = [];
    }
    this.inquiry.subinsureds = [...this.inquiry.subinsureds, subinsured];
  }

  getIndex(item: BaseInsurersLimitDto): number {
    return this.inquiry.baseInsurersLimits.indexOf(item) + 1;
  }

  goToServiceDetails() {
    const salesPerson = this.inquiry.salesPerson;
    const mainSalesRepUserId = salesPerson && salesPerson.id;
    this.routerService.toPolicyInquiryTechnicalDetails(
      this.inquiry.id,
      mainSalesRepUserId,
      this.inquiry?.businessStatus?.id
    );
  }

  get portal(): boolean {
    return false;
  }

  get canCreateAnnex(): boolean {
    return (
      !this.portal &&
      this.inquiry &&
      this.inquiry.id &&
      (this.inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_REGISTERED ||
        this.inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_ACCEPTED)
    );
  }

  get annexNeedsOffer(): boolean {
    return this.isSignificantChange();
  }

  private isSignificantChange(): boolean {
    const annex = this.inquiry.annexData;
    return (
      annex.addressChange ||
      annex.naceCodeChange ||
      annex.installmentPlanChange ||
      annex.turnoverDeclarationChange ||
      annex.factoringChange ||
      annex.cessionaryChange ||
      annex.coverPlusChange ||
      annex.maxPaymentTermChange ||
      annex.falconChange ||
      annex.subinsuredChange ||
      annex.limitListChange ||
      annex.otherChange
    );
  }

  toPolicy() {
    this.routerService.toPolicyContractPreview(this.inquiry.basePolicyContractVersion.id);
  }

  createAnnex() {
    this.inProgress = true;
    this.inquiryService.completeAnnex(this.inquiry.id).subscribe({
      next: (i) => {
        if (i.policyVersionId) {
          this.routerService.toPolicyContractDetails(i.policyVersionId);
        } else {
          this.routerService.toKukeInquiryOfferDetails(i.offerId, this.inquiry.id);
        }
        this.inProgress = false;
      },
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      },
    });
  }

  showOffer() {
    return this.inquiry && this.inquiry.id && !this.portal;
  }

  toPolicyInquiryServiceContacts() {
    this.routerService.toServiceContacts({
      boTypeId: BusinessObjectType.POLICY_INQUIRY,
      boId: this.inquiry.policyInquiry.id,
    });
  }

  subinsuredEmpty(): boolean {
    return this.inquiryService.subinsuredEmpty(this.inquiry);
  }

  contactPersonRowEditable(cp: ContactPersonDto): boolean {
    return !(
      this.appService.kuke &&
      this.loggedUserService.portal &&
      DictionaryUtils.in(cp.type, ContactPersonType.MAIN, ContactPersonType.PORTAL_ADMIN)
    );
  }
}
