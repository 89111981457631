import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {DictionaryBaseDto, DocumentContentDto, DocumentDescriptionDto} from '../../model/dtos';
import {
  AppConfigService,
  DictionaryBaseService,
  GrowlService,
  LoggedUserService,
  PropertyService,
} from '../../../bonding_shared/services';
import {RepositoryService} from '../../services/repository.service';
import {FileUtils} from '../../../bonding_shared/utils/file-utils';
import {FileSelectRowComponent} from '../form-row/file-select-row.component';
import {TranslateService} from '@ngx-translate/core';
import {DictionaryProfile} from '../../model/dictionary-ids';

@Component({
  selector: 'attachment-list',
  templateUrl: './attachment-list.component.pug',
})
export class AttachmentListComponent extends ListEmitters {
  self = this;
  MAX_TOTAL_SIZE = FileSelectRowComponent.MAX_FILE_BYTES;

  @Input() form: UntypedFormGroup;
  @Input() showErrors: boolean;
  @Input() hidden = false;
  _documents: DocumentContentDto[];
  @Input() deleteButton = true;
  @Input() addButton = true;
  @Input() parentId: number;
  @Input() typeEditable = true;
  @Input() allowedFileTypes = (<string>this.propertyService.properties['alfresco.acceptedFileTypes'])
    .split(',')
    .map((v) => '.' + v)
    .join(',');

  attachmentTypes: DictionaryBaseDto[];

  @Input() set documents(d: DocumentContentDto[]) {
    this._documents = d;
    setTimeout(() => this.setTypesInControl(d), 0);
  }

  get documents() {
    return this._documents;
  }

  @Input() set type(typeName: string) {
    const profileId = this.portal ? DictionaryProfile.SHOW_IN_PORTAL : undefined;
    if (typeName) {
      this.dictionaryBaseService
        .getDictionaryBaseFiltered(typeName, undefined, undefined, profileId)
        .subscribe((res) => {
          this.attachmentTypes = res;
        });
    }
  }

  constructor(
    private dictionaryBaseService: DictionaryBaseService,
    private repositoryService: RepositoryService,
    private growlService: GrowlService,
    private appService: AppConfigService,
    private propertyService: PropertyService,
    private loggedUserService: LoggedUserService,
    private translateService: TranslateService
  ) {
    super();
    this.initializeSelectorEmittersByNames(true, ['Attachment']);
  }

  // control value is set in order for validation for required field to pass
  setTypesInControl(d: DocumentContentDto[]) {
    if (d) {
      for (let i = 0; i < d.length; i++) {
        const row = (<any>this.form.controls['table']).controls[i];
        if (row && row.controls[2]) {
          row.controls[2].setValue(d[i].documentTypeId);
        }
      }
    }
  }

  addAttachments(files: File[]) {
    const totalNewFilesSize = files.map((f) => f.size).reduce((total, size) => total + size, 0);
    const totalAlreadyAddedFilesSize = this.documents
      .map((f) => f.size)
      .filter((s) => s)
      .reduce((total, size) => total + size, 0);
    if (totalNewFilesSize + totalAlreadyAddedFilesSize > this.MAX_TOTAL_SIZE) {
      this.translateService.get('errorMessage.fileSizeSumExceeded').subscribe((message) => {
        this.growlService.error(message + ' ' + this.MAX_TOTAL_SIZE / 1024 / 1024 + ' MB');
      });
    } else {
      FileUtils.addDocuments(files, this.documents);
    }
  }

  getDocument(document: DocumentDescriptionDto) {
    if (document.repositoryId) {
      this.repositoryService.getRepositoryDocument(document.repositoryId, document.name);
    }
  }

  get portal() {
    return this.loggedUserService.portal;
  }
}
