/**
 * Created by szkrabko on 06.08.2018 based on DictAutoCompleteComponent created by szkrabko on 24.03.2017.
 */
import {map} from 'rxjs/operators';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {GeoDictBaseDto} from '../../model';
import {BehaviorSubject, Observable} from 'rxjs';
import {GeoDictBaseService} from '../../services';

const GEO_DICT_AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => GeoDictAutoCompleteComponent),
  multi: true,
};

@Component({
  selector: 'geo-dict-auto-complete',
  template: `
    <input
      [ngModel]="_selectedItem"
      name="dictAutoComplete"
      (ngModelChange)="selectedItem = $event"
      class="bon-input"
      [disabled]="disabled || loading"
      [class.loading]="loading"
      autocomplete="new-password"
      auto-complete
      [value-formatter]="valueFormatter"
      [list-formatter]="listFormatter"
      [match-formatted]="matchFormatted"
      [source]="items"
      [accept-user-input]="acceptUserInput"
      [min-chars]="3"
      style="width: 100%;"
      [bonFocus]="focus"
    />
    <div *ngIf="errorMessage" class="invalid">Error: {{ errorMessage }}</div>
  `,
  providers: [GEO_DICT_AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR],
})
export class GeoDictAutoCompleteComponent implements ControlValueAccessor, OnInit {
  @Input() valueFormatter: (arg: any) => string;
  @Input() listFormatter: (arg: any) => string;
  @Input() matchFormatted = true;
  @Input() acceptUserInput = false;

  @Input() nullLabel: string;
  @Input() parentDictionary: 'city' | 'postCode' | 'country';
  @Input() geoDict: 'city' | 'postCode';
  @Input() disabled = false;
  @Input() label = 'name';
  @Input() focus = 'auto';

  /**
   * The items that will not be shown in a list. This should be be stored as set,
   * i.e.  hiddenIds[12] = true;
   */
  @Input() hiddenIds: Set<number>;

  @Output() changeItem = new EventEmitter<GeoDictBaseDto>();

  @ContentChild(TemplateRef, {static: true}) template: TemplateRef<any>;

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  private _selectedKey: number;
  public _selectedItem: GeoDictBaseDto;
  // Item set from model. May be possibly incorrect.
  private selectedItemFromExternal: GeoDictBaseDto;

  public items: GeoDictBaseDto[] = [];
  public errorMessage: string;
  public loading = false;
  private _parentDictionaryEntryId: number;

  private subjectItems = new BehaviorSubject<GeoDictBaseDto[]>(null);

  private comboInitialized = false;

  constructor(private geoDictBaseService: GeoDictBaseService) {}

  @Input() set parentDictionaryEntryId(parentDictionaryEntryId: number) {
    if (this._parentDictionaryEntryId) {
      this._selectedItem = undefined;
      this.selectedItemFromExternal = undefined;
    }
    this._parentDictionaryEntryId = parentDictionaryEntryId;
    if (this.comboInitialized) {
      this.loadDictionaryEntries();
    }
  }

  ngOnInit() {
    if (this._parentDictionaryEntryId) {
      this.loadDictionaryEntries();
    }
    this.comboInitialized = true;
  }

  get selectedItem(): GeoDictBaseDto {
    return this._selectedItem;
  }

  set selectedItem(it: GeoDictBaseDto) {
    this._selectedItem = it;
    if (this.onChangeListeners && (typeof this._selectedItem === 'object' || !this._selectedItem)) {
      this.onChangeListeners(this._selectedItem);
      this.changeItem.emit(this._selectedItem);
    }
  }

  // From ControlValueAccessor interface
  writeValue(it: GeoDictBaseDto): void {
    if (it) {
      let key: number | string;
      if (typeof it === 'string') {
        key = it;
      } else {
        key = it.id;
      }
      this.findSelectedItem(key).subscribe((item) => {
        this.selectedItemFromExternal = item;
        this.updateSelection();
      });
    } else {
      this.updateSelection();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private loadDictionaryEntries() {
    this.loading = true;
    const observable =
      this.geoDict === 'postCode'
        ? this.geoDictBaseService.getPostCodes(this.parentDictionary, this._parentDictionaryEntryId)
        : this.geoDictBaseService.getCities(this.parentDictionary, this._parentDictionaryEntryId);
    observable.subscribe(
      (entries) => this.initEntries(entries),
      (error) => {
        this.errorMessage = error;
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  private initEntries(items: GeoDictBaseDto[]) {
    if (this.hiddenIds && this.hiddenIds.size > 0) {
      this.items = [];
      for (const it of items) {
        if (!this.hiddenIds.has(it.id)) {
          this.items.push(it);
        }
      }
    } else {
      this.items = items;
    }

    this.subjectItems.next(items);
  }

  private updateSelection() {
    if (this.selectedItemFromExternal) {
      this.findSelectedItem(this.selectedItemFromExternal.id).subscribe((item) => {
        this._selectedItem = item;
        if (this.selectedItemFromExternal) {
          this._selectedKey = this.selectedItemFromExternal.id;
        }
      });
    } else {
      this._selectedItem = undefined;
      this._selectedKey = undefined;
    }
  }

  private findSelectedItem(k: number | string): Observable<GeoDictBaseDto> {
    return this.subjectItems.pipe(
      map((items) => {
        if (k && items) {
          for (const it of items) {
            if ((typeof k === 'number' && String(it.id) === String(k)) || (typeof k === 'string' && it.name === k)) {
              return it;
            }
          }
        }
        return undefined;
      })
    );
  }
}
