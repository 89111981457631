import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.pug',
})
export class ConfirmDialogComponent {
  @Input() showConfirmButton = true;
  @Input() confirmButtonDisabled = false;
  @Input() showRejectButton = true;
  @Input() showCloseButton = false;
  @Input() showCancelButton = false;
  @Input() showCloseIcon = true;
  @Input() validInput: () => boolean;
  @Input() class: string;

  dialogConfirmation: () => void;
  dialogRejection: () => void;

  dialogHeader: string;
  dialogMessage: string;
  modalRef: BsModalRef;

  @ViewChild(TemplateRef, {static: true}) template: TemplateRef<any>;

  constructor(private translateService: TranslateService, private modalService: BsModalService) {}

  open(titleBar: string, message: string, messageParams?: {[key: string]: string}): Promise<boolean> {
    console.log('open dialog with params: ', messageParams);
    this.dialogHeader = this.translateService.instant(titleBar, messageParams);
    if (message) {
      this.dialogMessage = this.translateService.instant(message, messageParams);
    }
    return new Promise<boolean>((resolve, reject) => {
      this.dialogConfirmation = () => resolve(true);
      this.dialogRejection = () => resolve(false);
      console.log('show confirmation dialog ..');
      this.modalRef = this.modalService.show(this.template, {
        class: this.class || 'modal-sm',
        backdrop: true,
        ignoreBackdropClick: true,
      });
    });
  }

  openAndExecuteOnConfirm(
    titleBar: string,
    message: string,
    confirmAction: Function,
    cancelAction?: Function,
    messageParams?: {[key: string]: string},
    actionParams?: any[]
  ) {
    console.log('Params ', titleBar, message);
    const confirmationPromise: Promise<boolean> = this.open(titleBar, message, messageParams);
    confirmationPromise.then((confirmed) => {
      if (confirmed && confirmAction) {
        confirmAction(actionParams);
      }
      if (!confirmed && cancelAction) {
        cancelAction(actionParams);
      }
    });
  }

  confirm() {
    console.log('dialog:: confirmed');
    if (this.validInput && !this.validInput()) {
      return;
    }
    this.dialogConfirmation();
    this.modalRef.hide();
  }

  reject() {
    console.log('dialog:: rejected');
    this.dialogRejection();
    this.modalRef.hide();
  }

  hide() {
    this.dialogRejection();
    this.modalRef.hide();
  }
}
