import {Injectable} from '@angular/core';
import {AbstractAuditedService} from '../../../bonding_shared/services/abstract-audited.service';
import {CustomRevisionEntityDto, TaskDto} from '../../../bonding_shared/model';
import {HttpClient} from '@angular/common/http';
import {AppConfigService, LoggedUserService} from '../../../bonding_shared/services';
import {Observable} from 'rxjs';

@Injectable()
export class TaskAuditService extends AbstractAuditedService<TaskDto> {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getRevisions(id: number): Observable<CustomRevisionEntityDto[]> {
    return this.get(this.urlPrefix + 'taskAudit/' + id + '/revisions');
  }

  getRelated(id: number, revisionId?: number): Observable<TaskDto> {
    return this.get(this.urlPrefix + 'taskAudit/' + id + (revisionId ? '?revisionId=' + revisionId : ''));
  }
}
