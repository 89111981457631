import {Component, OnInit} from '@angular/core';
import {ContractVersionDto, StatisticsDto} from '../../../../../bonding_shared/model/dtos';
import {PortalContractVersionService} from '../../../../services/portal-contract-version.service';
import {ActivatedRoute, Params} from '@angular/router';
import {PortalRouterService} from '../../../../services/portal-router.service';
import {BackendError, BondVersionSimpleDto} from '../../../../../bonding_shared/model';
import {BondVersionService} from '../../../../../bonding_shared';

@Component({
  selector: 'broker-portfolio-contract-version-preview',
  templateUrl: './contract-version-preview-on-broker-portfolio-component.pug',
})
export class ContractVersionPreviewOnBrokerPortfolioComponent implements OnInit {
  contractVersion: ContractVersionDto;
  bonds: BondVersionSimpleDto[];
  statistics: StatisticsDto;
  serverErrors: BackendError;

  constructor(
    private contractService: PortalContractVersionService,
    private bondService: BondVersionService,
    private route: ActivatedRoute,
    private router: PortalRouterService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const contractVersionId = +params['contractVersionId'];
    console.log('onInit: contractVersionId = ' + contractVersionId);
    this.contractService.getContractVersion(contractVersionId).subscribe(
      (contract) => this.afterContractReceived(contract),
      (error) => (this.serverErrors = error)
    );
    this.loadStatistics(contractVersionId);
  }

  afterContractReceived(contract: ContractVersionDto) {
    this.setContractVersion(contract);
    console.log('contract version loaded:: versionId = ' + this.contractVersion.id);
  }

  searchContractBonds(cv: ContractVersionDto) {
    console.log('Search bonds: contractId' + cv.contract.id);
  }

  get contractNumber(): string {
    if (this.contractVersion) {
      const num = this.contractVersion.contract.number
        ? this.contractVersion.contract.number
        : this.contractVersion.contract.requestNumber;
      return num + '/' + this.contractVersion.versionNumber;
    }
    return undefined;
  }

  setContractVersion(c: ContractVersionDto) {
    this.contractVersion = c;
  }

  loadStatistics(id: number) {
    return this.contractService.getContractStatistics(id).subscribe((s) => (this.statistics = s));
  }
}
