import {EventEmitter, Input, Output, TemplateRef, ViewChild, Directive} from '@angular/core';
import {StringUtils} from '../../utils';

@Directive()
export class SectionSelector {
  @Input() property: string;
  @Input() sectionLabel: string;
  @Input() selectorLabel: string;
  @Input() label230 = false;
  @Input() openOnInit = false;
  @ViewChild('section', {static: true}) section: TemplateRef<any>;
  @ViewChild('selector', {static: true}) selector: TemplateRef<any>;

  @Output() change = new EventEmitter<any>();

  propertyValue(object: any) {
    return StringUtils.getPropertyRaw(object, this.property);
  }

  // overridden in subclass
  closeSubSelectors() {}
}
