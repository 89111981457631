import {Injectable} from '@angular/core';
import {ContractNoticeReason} from '../../../../bonding_shared/model/dictionary-ids';
import {ContractTerminationDto, DictionaryBaseDto} from '../../../../bonding_shared/model/dtos';
import {ContractVersionDto} from '../../../../bonding_shared/model';
import {Moment} from 'moment';
import * as moment from 'moment';

@Injectable()
export class ContractNoticeService {
  constructor() {}

  hiddenContractNoticeReasonIds(cv: ContractVersionDto): Set<number> {
    const ct = cv.contractTermination;
    const hidden = new Set<number>();

    if (!cv.validTo) {
    } else if (ct.tacitRenewal) {
      // limited and renewal, validation <= valid to and set as valid to
    } else {
      // limited not reneval, nothing to do
      hidden.add(ContractNoticeReason.CLIENT_TERMINATES);
    }

    return hidden;
  }

  updateCancellationDate(cv: ContractVersionDto) {
    const ct = cv.contractTermination;

    if (!cv.validTo) {
      // unlimited, no validation, set compute cancel date
      cv.cancellationDate = this.calcNotificationDate(ct.notificationOfTerminationDate, ct).toDate();
    } else if (ct.tacitRenewal) {
      // limited and renewal, validation <= valid to and set as valid to
      if (this.calcNotificationDate(ct.notificationOfTerminationDate, ct).isSameOrBefore(this.getMoment(cv.validTo))) {
        cv.cancellationDate = cv.validTo;
      }
    } else {
      // limited not reneval, nothing to do
      return;
    }
  }

  private calcNotificationDate(startDate: Date, ct: ContractTerminationDto): Moment {
    const p = ct.noticePeriodUnit.name.toLowerCase();
    return this.addToDatePeriod(startDate, ct.noticePeriodValue, p);
  }

  private addToDatePeriod(startDate: Date, n: number, p): Moment {
    return this.getMoment(startDate).startOf('day').add(p, n).endOf(p);
  }

  private getMoment(d?: Date): Moment {
    return moment(d);
  }

  isNotificationOfTerminationDateEditable(noticeReason: DictionaryBaseDto) {
    return noticeReason && noticeReason.id === ContractNoticeReason.CLIENT_TERMINATES;
  }
}
