import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {CustomValidators} from '../../validators';

@Directive({
  selector:
    // tslint:disable-next-line:max-line-length
    ':not([type=checkbox])[chooseRequired][formControlName],:not([type=checkbox])[chooseRequired][formControl],:not([type=checkbox])[chooseRequired][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: ChooseRequiredDirective, multi: true}],
})
export class ChooseRequiredDirective implements Validator {
  private _chooseRequired = false;
  private _onChange?: () => void;

  /**
   * @description
   * Tracks changes to the chooseRequired attribute bound to this directive.
   */
  @Input()
  get chooseRequired(): boolean | string {
    return this._chooseRequired;
  }

  set chooseRequired(value: boolean | string) {
    this._chooseRequired = value != null && value !== false && `${value}` !== 'false';
    if (this._onChange) {
      this._onChange();
    }
  }

  /**
   * Method that validates whether the control is empty.
   * Returns the validation result if enabled, otherwise null.
   * @nodoc
   */
  validate(control: AbstractControl): ValidationErrors | null {
    return this.chooseRequired ? CustomValidators.chooseRequired(control) : null;
  }

  /**
   * Registers a callback function to call when the validator inputs change.
   * @nodoc
   */
  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }
}
