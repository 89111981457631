import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {AppConfigService} from '../../../../bonding_shared';

@Component({
  selector: 'discretionary-cover-section',
  templateUrl: './discretionary-cover-section.component.pug',
})
export class DiscretionaryCoverSectionComponent extends Section {
  self = this;

  constructor(protected policyContractViewService: PolicyContractViewService, public appService: AppConfigService) {
    super(policyContractViewService, appService);
  }

  discretionaryCoverChanged(val: boolean) {
    if (!val) {
      this.policy.dcMaxPaymentTermDays = undefined;
      this.policy.dcMaxLimit = undefined;
      this.policy.dcInsuranceCoverPercent = undefined;
      this.policy.dcInsuredAmount = undefined;
    }
  }
}
