import {Component} from '@angular/core';
import {AuditedViewAbstract} from '../../bonding_shared/components/details-view/audited-view-abstract';
import {
  AppConfigService,
  ClaimCommissionDto,
  ClaimDto,
  ClaimRecoveryCommissionDto,
  ClaimRecoveryDto,
  ClaimService,
  DictionaryBaseDto,
  GrowlService,
  LoggedUserService,
  RouterService,
  StringUtils,
  TemplateService,
  TemplateSimpleDto,
} from '../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {ClaimCommissionService} from '../../bonding_shared/services/claim-commission.service';
import {forkJoin} from 'rxjs';
import {ClaimRecoveriesService} from '../../bonding_shared/services/claim-recoveries.service';
import {map, switchMap} from 'rxjs/operators';
import {BusinessObjectType, ClaimElementaryRight} from '../../bonding_shared/model/dictionary-ids';
import {DateUtils} from '../../bonding_shared/utils/date-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'claim-commission-details',
  templateUrl: './claim-commission-details.component.pug',
})
export class ClaimCommissionDetailsComponent extends AuditedViewAbstract<ClaimCommissionDto> {
  claim = <ClaimDto>{};
  recoveriesAfterIndemnification: ClaimRecoveryDto[];
  readonly ClaimElementaryRight = ClaimElementaryRight;
  templates: TemplateSimpleDto[];
  claimRecoveryCommissionForTemplate: ClaimRecoveryCommissionDto;

  constructor(
    route: ActivatedRoute,
    private claimCommissionService: ClaimCommissionService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    private claimService: ClaimService,
    private claimRecoveriesService: ClaimRecoveriesService,
    private templateService: TemplateService,
    public appService: AppConfigService
  ) {
    super(
      route,
      claimCommissionService,
      router,
      translateService,
      growlService,
      loggedUserService,
      'claimCommissionId'
    );
  }

  initializeView(params: Params) {
    super.initializeView(params);
    this.id = +params[this.idParamName];
    this.serverErrors = undefined;
    if (this.id) {
      this.initWithId();
    } else {
      this._route.queryParams
        .pipe(
          map((queryParams) => +queryParams['claimId']),
          switchMap((claimId) => this.claimCommissionService.initial(claimId))
        )
        .subscribe((claimCommission) => {
          this.current = claimCommission;
          this.newVersion = true;
          this.onLoad();
        });
    }
  }

  onLoad() {
    super.onLoad();
    forkJoin([
      this.claimService.getClaim(this.current.claim.id),
      this.claimRecoveriesService.getFromRevision(this.current.claim.id),
    ]).subscribe(([claim, recovery]) => {
      this.claim = claim;
      if (this.appService.mehib) {
        this.loadTemplates();
        this.claimRecoveryCommissionForMehibTemplate();
      }
      this.recoveriesAfterIndemnification = recovery.claimRecoveries.filter(
        (r) => r.afterIndemnification && !r.eradicated
      );
    });
  }

  onCreateNewVersion() {
    super.onCreateNewVersion();
    const recoveryIds = this.recoveriesAfterIndemnification.map((recovery) => recovery.id);
    const remainingCommissions = this.current.commissions.filter((commission) =>
      recoveryIds.includes(commission.claimRecovery.id)
    );
    const remainingCommissionRecoveryIds = remainingCommissions.map((commission) => commission.claimRecovery.id);
    const newCommissions = this.recoveriesAfterIndemnification
      .filter((recovery) => !remainingCommissionRecoveryIds.includes(recovery.id))
      .map((recovery) => {
        const commission = <ClaimRecoveryCommissionDto>{};
        commission.claimRecovery = recovery;
        commission.salesTax = 27;
        return commission;
      });
    this.current.commissions = remainingCommissions.concat(newCommissions);
    this.claimRecoveryCommissionForTemplate = undefined;
  }

  toDetailsRouterFunction({id}: ClaimCommissionDto) {
    this.router.toClaimCommission(id);
  }

  afterObjectSaved(originalId?: number, toDetailsRouterFunction?: Function) {
    super.afterObjectSaved(originalId, toDetailsRouterFunction);
    this.initWithId();
    if (this.appService.mehib) {
      this.claimRecoveryCommissionForMehibTemplate();
    }
  }

  public recalculateRecoveryCommission(recoveryCommission: ClaimRecoveryCommissionDto) {
    const fxRateFactor = recoveryCommission.claimRecovery.fxRate / 100;
    const commissionRateFactor = recoveryCommission.commissionRate ? recoveryCommission.commissionRate / 100 : 0;
    const salesTaxFactor = recoveryCommission.salesTax ? 1 + recoveryCommission.salesTax / 100 : 1;
    const amountBeforeRounding =
      this.calculateHsbAmount(recoveryCommission) * fxRateFactor * commissionRateFactor * salesTaxFactor;
    this.current.commissions = this.current.commissions.map((commission) => {
      if (commission.id === recoveryCommission.id) {
        commission.commissionAmount = Number(amountBeforeRounding.toFixed(0));
      }
      return commission;
    });
  }

  public hsbAmountFormatted(recoveryCommission: ClaimRecoveryCommissionDto) {
    return StringUtils.formatNumber(this.calculateHsbAmount(recoveryCommission), '1.2-2');
  }

  private calculateHsbAmount(recoveryCommission: ClaimRecoveryCommissionDto): number {
    if (recoveryCommission?.claimRecovery?.hsbAmount) {
      return (
        (recoveryCommission.claimRecovery.hsbAmount * recoveryCommission.claimRecovery.insuranceCoverPercent) / 100
      );
    } else {
      return 0;
    }
  }

  private loadTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    dictionarySelectors.push(this.claim.riskType);

    this.templateService
      .findByType(
        undefined,
        BusinessObjectType.CLAIM_RECOVERY_COMMISSION,
        undefined,
        dictionarySelectors,
        undefined,
        undefined,
        this.claim.businessUnit.id,
        true
      )
      .subscribe({
        next: (result) => (this.templates = result),
        error: (error) => console.log('Error while loading commission templates'),
      });
  }

  claimRecoveryCommissionForMehibTemplate() {
    this.claimRecoveryCommissionForTemplate = undefined;
    this.claimRecoveryCommissionForTemplate = this.current.commissions
      .filter((c) => c.id)
      .filter((c) => !c.requested)
      .sort((c1, c2) => DateUtils.compare(c1.claimRecovery.valueDate, c2.claimRecovery.valueDate))[0];
    if (!this.claimRecoveryCommissionForTemplate) {
      this.growlService.warning('errorMessage.noDataForDocument');
    }
  }

  onPrintSelected(template: TemplateSimpleDto) {
    this.router.toDocumentDetailsNew(template.id, this.claimRecoveryCommissionForTemplate.id);
  }

  documentButtonDisabled() {
    return !(this.claimRecoveryCommissionForTemplate && this.templates?.length > 0);
  }
}
