import {forkJoin as observableForkJoin} from 'rxjs';
import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, NgForm, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CompanySimpleDto, TaskDto} from '../../../bonding_shared/model/index';
import {TaskGuiService} from '../services/index';
import {DictionaryBaseDto} from '../../../bonding_shared/model/dtos';
import {UserGroupBaseDto, UserSimpleDto} from '../../../bonding_shared/model';
import {
  ApplicationModule,
  DictionaryPropertyType,
  ElementaryRight,
  TaskStatus,
  UserType,
} from '../../../bonding_shared/model/dictionary-ids';
import {BusinessObjectUtils} from '../../../bonding_shared/utils/business-object-utils';
import {map} from 'rxjs/operators';
import {
  AppConfigService,
  DictionaryService,
  LoggedUserService,
  RouterService,
  UserService,
} from '../../../bonding_shared/services';
import {BusinessObjectSelectorComponent} from '../../../bonding_shared/components/business-object-selector/business-object-selector.component';

@Component({
  selector: 'task-data',
  templateUrl: 'task-data.component.html',
})
export class TaskDataComponent implements OnInit, AfterViewInit {
  readonly ElementaryRight = ElementaryRight;
  @Input() disabled: boolean;
  _task: TaskDto = <TaskDto>{creationDate: new Date()};
  @ViewChild(BusinessObjectSelectorComponent) businessObjectSelector: BusinessObjectSelectorComponent;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  form: UntypedFormGroup;
  users: UserSimpleDto[];
  public errorMessage: string;
  showErrors = false;

  company: CompanySimpleDto;
  companies: CompanySimpleDto[] = [];
  groups: UserGroupBaseDto[];
  groupsForBu: UserGroupBaseDto[];
  usersForBu: UserSimpleDto[];
  @Input() assignmentUnlocked = true;
  @Input() dueDateUnlocked = true;
  historyMode = false;

  boRequired = true;
  relatedToRegExp: RegExp;
  hiddenModulesIds = new Set<number>([ApplicationModule.SALES]);
  typeEditable = true;

  static validateUserAndGroup(group: AbstractControl, appConfig: AppConfigService) {
    const user = group.get('user');
    const userGroup = group.get('userGroup');
    if (!user || !userGroup) {
      return null;
    }
    if (appConfig.kuke && user.value && userGroup.value) {
      return {missingAssignee: true};
    }
    return user.value || userGroup.value ? null : {missingAssignee: true};
  }

  constructor(
    private routerService: RouterService,
    private userService: UserService,
    private dictionaryService: DictionaryService,
    private taskGuiService: TaskGuiService,
    private formBuilder: UntypedFormBuilder,
    public appConfig: AppConfigService,
    private cd: ChangeDetectorRef,
    public loggedUserService: LoggedUserService,
    private router: Router
  ) {
    if (appConfig.kuke) {
      this.relatedToRegExp = BusinessObjectUtils.getKukeBoRegexp();
    }
  }

  ngOnInit() {
    this.loadUsersAndGroups();
  }

  ngAfterViewInit(): void {
    this.form = this.ngForm.form;
  }

  get task(): TaskDto {
    return this._task;
  }

  @Input()
  set task(t: TaskDto) {
    this._task = t;
    if (t && t.status && t.status.id !== TaskStatus.OPEN) {
      this.historyMode = true;
    }
    if (!t.id || (this.appConfig.kuke && !t.autoGenerated)) {
      this.typeEditable = true;
    } else {
      this.typeEditable = false;
    }
  }

  loadUsersAndGroups() {
    observableForkJoin(this.userService.getIntranetUsers(), this.userService.getUserGroups()).subscribe((res) => {
      this.users = <UserSimpleDto[]>res[0];
      this.groups = <UserGroupBaseDto[]>res[1];
      this.setGroupsAndUsersForBu();
    });
  }

  public setGroupsAndUsersForBu() {
    if (this.task && this.task.businessUnit) {
      this.groupsForBu = this.groups.filter((g) => g.businessUnit.id === this.task.businessUnit.id);
      this.usersForBu = this.users.filter(
        (g) => g.businessUnit.id === this.task.businessUnit.id && g.userType.id === UserType.INTRANET
      );
    }
  }

  onBUChange(bu: DictionaryBaseDto) {
    this.task.user = undefined;
    this.task.userGroup = undefined;
    this.setGroupsAndUsersForBu();
  }

  taskTypeChanged(type: DictionaryBaseDto) {
    this.dictionaryService
      .toDictionaryDto(type)
      .pipe(map((d) => d.properties && d.properties[DictionaryPropertyType.TASK_BO_NOT_MANDATORY]))
      .subscribe((r) => {
        this.boRequired = !r;
      });
  }
}
