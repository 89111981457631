import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {ClaimListComponent} from './claim-list.component';
import {ClaimSectionSelectorComponent} from './claim-section-selector.component';
import {ClaimVersionSelectorComponent} from './claim-version-selector.component';
import {ClaimIndemnificationSelectorComponent} from './claim-indemnification-selector.component';
import {ClaimIndemnificationSectionSelectorComponent} from './claim-indemnification-section-selector.component';
import {ClaimBondInformationComponent} from './claim-bond-information';
import {CompanySharedModule} from '../../../company/components/shared/company-shared.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    CompanySharedModule,
  ],
  declarations: [
    ClaimListComponent,
    ClaimSectionSelectorComponent,
    ClaimVersionSelectorComponent,
    ClaimIndemnificationSelectorComponent,
    ClaimIndemnificationSectionSelectorComponent,
    ClaimBondInformationComponent,
  ],
  exports: [
    ClaimListComponent,
    ClaimSectionSelectorComponent,
    ClaimVersionSelectorComponent,
    ClaimIndemnificationSelectorComponent,
    ClaimIndemnificationSectionSelectorComponent,
    ClaimBondInformationComponent,
  ],
})
export class ClaimSharedModule {}
