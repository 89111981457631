import {Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService, VersionInfoService} from '../../bonding_shared/services';
import {VersionSummaryComponent} from '../../bonding_shared/components/version-summary';

@Component({
  selector: 'version-info',
  templateUrl: './version-info.component.pug',
  providers: [VersionInfoService],
})
export class VersionInfoComponent extends VersionSummaryComponent {
  constructor(http: HttpClient, versionInfoService: VersionInfoService, protected appService: AppConfigService) {
    super(http, versionInfoService, appService);
  }
}
