import {Component, Input, OnInit} from '@angular/core';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {CompanyService} from '../../../../bonding_shared/services/company.service';
import {ClientCompanyBaseDto, CompanyDto, CompanyRawDto} from '../../../../bonding_shared/model/dtos';
import {LoggedUserService} from '../../../../bonding_shared/services/logged-user.service';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {ClientCompanyViewParams} from '../../../client-company/components/client-company-details.component';

@Component({
  selector: 'company-physical-person-info',
  templateUrl: './company-physical-person-info.component.pug',
  styles: ['label { font-weight: 700; margin-bottom: 2px; }'],
})
export class CompanyPhysicalPersonInfoComponent implements OnInit {
  @Input() company: CompanyDto | CompanyRawDto;
  @Input() thirdPartyId: number;
  @Input() showLink = true;
  @Input() rawCompany = false;
  @Input() clientCompany;
  @Input() showInternalNumber = false;
  @Input() showVatNumber = true;
  @Input() showStatNumber = true;
  @Input() viewId: number; // useful when return to the current view is needed after redirection to another view
  @Input() parentObjectId: number; // useful when return to the current view is needed after redirection to another view

  portalMode = false;

  constructor(
    private companyService: CompanyService,
    public router: RouterService,
    private userService: LoggedUserService
  ) {}

  ngOnInit(): void {
    this.portalMode = this.userService.portal;
  }

  get prettyAddress() {
    return BusinessUtils.prettyAddress(this.company);
  }

  toClientCompanyNew() {
    this.router.toClientCompanyDetailsWithParams(<ClientCompanyViewParams>{
      companyId: this.company.id,
      returnViewId: this.viewId,
      returnObjectId: this.parentObjectId,
    });
  }
}
