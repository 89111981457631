import {ClientPolicyPreviewComponent} from './client-policy-preview.component';
import {ClientAnnexInquiryDetailsComponent} from '../inquiry/client-annex-inquiry-details.component';
import {PolicyContractTechnicalDetailsComponent} from '../../bonding/policy';
import {ClientAnnexInquiryDetailsGuard} from '../inquiry/_guards';
import {PolicyMonitoringVersionDetailsComponent} from '../../bonding/policy/policy-monitoring-version-details.component';
import {PolicyContractTechnicalDetailsGuard} from '../../bonding/policy/_guards';

export const ClientPolicyRoutes = [
  {
    path: 'client-policy-preview/:id',
    component: ClientPolicyPreviewComponent,
  },
  {
    path: 'policy-contract-technical-details/:id',
    component: PolicyContractTechnicalDetailsComponent,
  },
  {
    path: 'client-annex-inquiry-details/:id',
    component: ClientAnnexInquiryDetailsComponent,
    canActivate: [ClientAnnexInquiryDetailsGuard],
  },
];
