import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {UserRoleQuotasComponent} from './user-role-quotas.component';
import {SearchIndexComponent} from './search-index.component';
import {BusinessLogComponent} from '../business-log/components/business-log.component';
import {BusinessLogGuiService} from '../business-log/services/business-log-gui.service';
import {BusinessParamsComponent} from './business-params.component';
import {RejectManagementComponent} from '../reject-management/components/reject-management.component';
import {RejectManagementGuiService} from '../reject-management/services/reject-management-gui.service';
import {RejectManagementService} from '../../bonding_shared/services/reject-management.service';
import {BusinessLogGuard} from '../business-log/business-log-guard';
import {UserRoleQuotasGuard} from './_guards/user-role-quotas.guard';
import {RejectManagementGuard} from '../reject-management/components/reject-management-guard';
import {BusinessParamsGuard} from './_guards/business-params.guard';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
  declarations: [
    UserRoleQuotasComponent,
    SearchIndexComponent,
    BusinessLogComponent,
    BusinessParamsComponent,
    RejectManagementComponent,
  ],
  providers: [
    UserRoleQuotasGuard,
    BusinessParamsGuard,
    RejectManagementGuard,
    BusinessLogGuiService,
    RejectManagementGuiService,
    RejectManagementService,
    BusinessLogGuard,
  ],
})
export class AdministrationModule {}
