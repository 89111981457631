import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {ReportService} from '../../bonding_shared/services/index';
import {ReportDto} from '../../bonding_shared/model/index';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';

@Component({
  selector: 'report-details',
  templateUrl: 'report-details.component.html',
})
export class ReportDetailsComponent extends DetailsView implements OnInit {
  report: ReportDto;

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
    protected growlService: GrowlService
  ) {
    super(growlService);
  }

  ngOnInit() {
    this.report = <ReportDto>{};
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    super.initializeView(params);
    const id = +params['id'];
    console.log('initializeView: id = ' + id);
    this.reportService.getReport(id).subscribe({
      next: (report) => this.setReport(report),
      error: (errors) => this.handleServerError(errors),
    });
  }

  setReport(report: ReportDto) {
    this.report = report;
  }

  getFileDebtors(report: ReportDto) {
    this.reportService.createDebtorReportById(report.id);
  }

  getFileCredits(report: ReportDto) {
    this.reportService.createCreditReportById(report.id);
  }
}
