/**
 * Created by awilk on 26.10.2016.
 */
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {HooverColor} from '../hover-info';

const CHECKBOX_ROW_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxRowComponent),
  multi: true,
};

@Component({
  selector: 'checkbox-row',
  styles: ['div.inline { display: inline; }'],
  template: `
    <div *ngIf="control" class="bon-row" [class.inline]="inline">
      <label class="bon-label" [class.long-label]="longLabel">
        {{ labelKey ? (labelKey | translate) : (label | translate) }}
        <hover-info *ngIf="hooverMsg" [message]="hooverMsg" [color]="hooverColor" ]></hover-info>
      </label>
      <input
        *ngIf="!presentationMode"
        type="checkbox"
        [ngModel]="value"
        (change)="onChangeItem($event)"
        (blur)="onTouchedListeners()"
        [formControl]="control"
        [disabled]="disabled || readonly"
        class="bon-checkbox"
        style="width: auto; min-width: 0; margin-top: 0px"
        [class.no-top-margin]="resetTopMargin"
      />
      <input
        *ngIf="presentationMode"
        type="checkbox"
        [ngModel]="value"
        [formControl]="control"
        disabled
        class="bon-checkbox"
        style="width: auto; min-width: 0; margin-top: 0px"
        [class.no-top-margin]="resetTopMargin"
      />
      <error-message [control]="control" [show]="showErrors"></error-message>
    </div>
    <div *ngIf="!control" class="bon-row" [class.inline]="inline">
      <label class="bon-label" [class.long-label]="longLabel">
        {{ labelKey ? (labelKey | translate) : (label | translate) }}
        <hover-info *ngIf="hooverMsg" [message]="hooverMsg" [color]="hooverColor"></hover-info>
      </label>
      <input
        *ngIf="!presentationMode"
        type="checkbox"
        [ngModel]="value"
        (change)="onChangeItem($event)"
        (blur)="onTouchedListeners()"
        [disabled]="disabled || readonly"
        class="bon-checkbox"
        style="width: auto; min-width: 0;"
        [class.no-top-margin]="resetTopMargin"
      />
      <input
        *ngIf="presentationMode"
        type="checkbox"
        [ngModel]="value"
        disabled
        class="bon-checkbox"
        style="width: auto; min-width: 0; margin-top: 0px"
        [class.no-top-margin]="resetTopMargin"
      />
    </div>
  `,
  providers: [CHECKBOX_ROW_CONTROL_VALUE_ACCESSOR],
})
export class CheckboxRowComponent implements ControlValueAccessor {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() labelKey: string;
  @Input() longLabel = false;
  @Input() disabled: any;
  @Input() readonly: any;
  @Input() presentationMode = false;
  @Input() showErrors: boolean;
  @Input() nullable: boolean; // set null instead of false
  @Input() resetTopMargin = false;
  @Input() hooverMsg: string;
  @Input() hooverColor: HooverColor;
  @Input() inline = false;

  @Output() changeState = new EventEmitter<boolean>();

  private onChangeListeners: Function;
  public onTouchedListeners: Function;
  public value: boolean;

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onChangeItem(event: any) {
    console.log('checkbox checked: ', event.target.checked);
    this.value = event.target.checked ? true : this.nullable ? null : false;
    this.onChangeListeners(this.value);
    this.changeState.emit(this.value);
  }
}
