import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto} from '../../../bonding_shared/model/dtos';
import {InquiryService} from '../../../bonding_shared/services/inquiry.service';

@Injectable()
export class InquiryGuiService {
  dataProvider: SearchDataProvider<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>;

  constructor(private inquiryService: InquiryService) {
    this.dataProvider = new SearchDataProvider<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>(
      inquiryService
    );
  }
}
