import {Component, Input, OnInit} from '@angular/core';
import {PolicyInquiryBalanceDto, PolicyInquiryVersionDto} from '../../../bonding_shared/model';
import {ControlContainer, NgForm, UntypedFormGroup} from '@angular/forms';
import {DictionaryBaseService, DictionaryService, LoggedUserService} from '../../../bonding_shared';
import {VisibilityService} from '../services/visibility.service';
import {DateUtils} from '../../../bonding_shared/utils/date-utils';

@Component({
  selector: 'inquiry-balances',
  templateUrl: 'inquiry-balances.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class InquiryBalancesComponent implements OnInit {
  self = this;

  @Input()
  set inquiry(inq: PolicyInquiryVersionDto) {
    this._inquiry = inq;

    if (!(inq.balances && inq.balances.length > 0)) {
      this.initBalances(inq);
    }
  }

  @Input() readonly = false;
  @Input() form: UntypedFormGroup;
  @Input() showErrors = false;

  get inquiry() {
    return this._inquiry;
  }

  portalMode = false;
  private _inquiry: PolicyInquiryVersionDto;

  constructor(
    protected dictionaryBaseService: DictionaryBaseService,
    protected loggedUserService: LoggedUserService,
    protected dictionaryService: DictionaryService,
    protected visibilityService: VisibilityService
  ) {
    this.portalMode = this.loggedUserService.portal;
  }

  ngOnInit(): void {}

  private initBalances(inq: PolicyInquiryVersionDto) {
    const now = new Date();
    const dateMinus = (m: number) => DateUtils.addMonth(now, -m);

    inq.balances = <PolicyInquiryBalanceDto[]>[
      {date: dateMinus(9), value: null},
      {date: dateMinus(6), value: null},
      {date: dateMinus(3), value: null},
      {date: now, value: null},
    ];
  }

  changedBalance0Date(date: Date) {
    if (!date) {
      return;
    }
    const balances = this._inquiry.balances;
    const datePlus = (m: number) => DateUtils.addMonth(date, m);

    balances[1].date = datePlus(3);
    balances[2].date = datePlus(6);
    balances[3].date = datePlus(9);
  }
}
