import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {BusinessObjectDto, DocumentDto, DocumentEmailDto, IdsDto, TemplateDto} from '../model';
import {BinaryDownloaderService} from './binary-downloader.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {DateUtils} from '../utils/date-utils';

@Injectable()
export class DocumentService extends AbstractService {
  newDocument: DocumentDto;

  protected url = this.urlPrefix + 'document';
  protected contentUrl = this.url + '/content/';
  protected rmContentUrl = this.url + '/rm/content/';
  protected zipUrl = this.url + '/genzip/';
  protected pdfUrl = this.url + '/genpdf/';
  protected focusUrl = this.url + '/focus';
  protected focusOutputFileName = 'Focus.xlsm';
  protected collectionInvoiceSpreadsheetURL = this.url + '/collectionInvoiceSpreadsheet';
  protected collectionInvoiceSpreadsheetFileNameKey = 'collection.invoice.sheetFilename';
  protected asymmetricTransactionsSuffixKey = 'WithAsymmetricTransactions';
  protected inquiryUrlPrefix = this.urlPrefix + 'policyInquiryVersion/';
  protected inquiryReportUrlPrefix = this.inquiryUrlPrefix + 'offerReport/';
  protected claimVersionUrlPrefix = this.urlPrefix + 'claimVersion/';
  protected inquiryReportFilename = 'focusReport.xlsx';
  protected sendEmailUrl = this.url + '/sendEmail/';
  protected saveWithoutSendingUrl = this.url + '/saveWithoutSending/';
  protected proposalsUrl = this.url + '/proposals';

  readonly MEDIA_TYPE_APPLICATION_PDF = 'application/pdf';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    public binaryDownloader: BinaryDownloaderService,
    private translateService: TranslateService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getDocument(id: number): Observable<DocumentDto> {
    return this.get<DocumentDto>(this.url + '/' + id);
  }

  downloadZip(idList: IdsDto, outputFileName: string) {
    this.binaryDownloader.download(
      'POST',
      this.zipUrl,
      'application/json',
      'application/octet-stream',
      outputFileName,
      idList
    );
  }

  /**
   * Currently uses only in invoices.If someone need to use it, he must add appropriate right to RolesAllowed on backend.
   * returns .zip with pdfs created out of documents specified by input business objects
   * */
  downloadBoZip(boList: BusinessObjectDto[], outputFileName: string, errorCallback?: (errMsg: string) => void) {
    this.binaryDownloader.download(
      'POST',
      this.url + '/genBoZip/',
      'application/json',
      'application/octet-stream',
      outputFileName,
      boList,
      errorCallback
    );
  }

  downloadPdf(docId: number, filename: string) {
    this.binaryDownloader.download(
      'GET',
      this.pdfUrl + docId,
      this.MEDIA_TYPE_APPLICATION_PDF,
      'application/octet-stream',
      filename
    );
  }

  downloadInquiryReport(inquiryVersionId: number) {
    this.binaryDownloader.download(
      'GET',
      this.inquiryReportUrlPrefix + inquiryVersionId + '/' + this.inquiryReportFilename,
      'application/json',
      'application/octet-stream',
      this.inquiryReportFilename
    );
  }

  downloadCCWReport(id: number) {
    this.binaryDownloader.download(
      'GET',
      this.claimVersionUrlPrefix + 'ccwReport/' + id,
      'application/json',
      'application/octet-stream',
      'CCW report.xlsx'
    );
  }

  downloadOfferComparisonReport(id: number, errorCallback?: (errMsg: string) => void) {
    this.binaryDownloader.download(
      'GET',
      this.inquiryUrlPrefix + 'offerComparison/' + id,
      'application/json',
      'application/octet-stream',
      'Offer comparison.xlsx',
      undefined,
      errorCallback
    );
  }

  downloadContent(docId: number, contentType: string, extension?: string) {
    const fileName = docId + '.' + (extension ? extension : this.getExtension(contentType));
    this.binaryDownloader.download('GET', this.contentUrl + docId, contentType, 'application/octet-stream', fileName);
  }

  downloadRMContent(docId: number, contentType: string, filename?: string) {
    const fileName = filename ? filename : docId + '.' + this.getExtension(contentType);
    this.binaryDownloader.download('GET', this.rmContentUrl + docId, contentType, 'application/octet-stream', fileName);
  }

  create(dto: DocumentDto): Observable<DocumentDto> {
    return this.put<DocumentDto>(dto, this.url);
  }

  generate(
    templateId: number,
    businessObjectId: number,
    additionalValuesBoTypeId?: number,
    additionalValuesBoId?: number,
    copyFor?: number
  ): Observable<DocumentDto> {
    let url = this.url + '/generate?templateId=' + templateId + '&businessObjectId=' + businessObjectId;
    if (additionalValuesBoTypeId && additionalValuesBoId) {
      url += '&additionalValuesBoTypeId=' + additionalValuesBoTypeId + '&additionalValuesBoId=' + additionalValuesBoId;
    }
    if (copyFor) {
      url += '&copyTypeId=' + copyFor;
    }
    return this.get<DocumentDto>(url);
  }

  generateAndSave(templateId: number, businessObjectId: number): Observable<number> {
    return this.get<any>(
      this.url + '/generateAndSave?templateId=' + templateId + '&businessObjectId=' + businessObjectId
    );
  }

  generateFromTemplate(
    template: TemplateDto,
    businessObjectId: number,
    additionalValuesBoTypeId?: number,
    additionalValuesBoId?: number
  ): Observable<number> {
    let params = new HttpParams();
    params = params.append('businessObjectId', businessObjectId + '');
    if (additionalValuesBoTypeId) {
      params = params.append('additionalValuesBoTypeId', additionalValuesBoTypeId + '');
    }
    if (additionalValuesBoId) {
      params = params.append('additionalValuesBoId', additionalValuesBoId + '');
    }
    return this.post1(template, this.url + '/generateFromTemplate', params);
  }

  generatePdf(
    templateId: number,
    businessObjectId: number,
    fileName: string,
    sendToRepo?: boolean,
    errorCallback?: (errMsg: string) => void,
    additionalValuesBoTypeId?: number,
    additionalValuesBoId?: number
  ) {
    let url =
      this.url +
      '/generatepdf?templateId=' +
      templateId +
      '&businessObjectId=' +
      businessObjectId +
      '&sendToRepo=' +
      !!sendToRepo;
    if (additionalValuesBoTypeId && additionalValuesBoId) {
      url += '&additionalValuesBoTypeId=' + additionalValuesBoTypeId + '&additionalValuesBoId=' + additionalValuesBoId;
    }
    this.binaryDownloader.download(
      'GET',
      url,
      this.MEDIA_TYPE_APPLICATION_PDF,
      'application/octet-stream',
      fileName,
      false,
      errorCallback
    );
  }

  generatePdfPost(templateId: number, requestObject: any, fileName: string, errorCallback?: (errMsg: string) => void) {
    const url = this.url + '/generatepdf?templateId=' + templateId;

    this.binaryDownloader.download(
      'POST',
      url,
      'application/json',
      'application/octet-stream',
      fileName,
      requestObject,
      errorCallback
    );
  }

  proposals(dto: DocumentDto): Observable<DocumentDto> {
    return this.post<DocumentDto>(dto, this.proposalsUrl);
  }

  wordings(
    templateId: number,
    businessObjectId: number,
    docTypeId: number,
    fileName: string,
    errorCallback?: (errMsg: string) => void
  ): Observable<string> {
    let urlStr = this.url + '/wordings?templateId=' + templateId + '&businessObjectId=' + businessObjectId;
    if (docTypeId) {
      urlStr += '&docTypeId=' + docTypeId;
    }
    // this.binaryDownloader.download(
    //   'GET',
    //   urlStr,
    //   'application/html',
    //   'application/octet-stream',
    //   fileName,
    //   errorCallback
    // );

    return this.postEmpty(urlStr);
  }

  signing(
    templateId: number,
    businessObjectId: number,
    docTypeId: number,
    fileName: string,
    errorCallback?: (errMsg: string) => void
  ) {
    this.binaryDownloader.download(
      'GET',
      this.url +
        '/signings?templateId=' +
        templateId +
        '&businessObjectId=' +
        businessObjectId +
        '&docTypeId=' +
        docTypeId,
      'application/html',
      'application/octet-stream',
      fileName,
      errorCallback
    );
  }

  update(dto: DocumentDto): Observable<DocumentDto> {
    return this.post<DocumentDto>(dto, this.url);
  }

  saveWithoutSending(dto: DocumentDto): Observable<DocumentDto> {
    return this.post<DocumentDto>(dto, this.saveWithoutSendingUrl);
  }

  remove(id: number): Observable<DocumentDto> {
    return this.delete<DocumentDto>(this.url + '/' + id);
  }

  sendEmail(dto: DocumentEmailDto): Observable<DocumentDto> {
    return this.post1<DocumentEmailDto, DocumentDto>(dto, this.sendEmailUrl);
  }

  createHttpHeaders() {
    return new HttpHeaders({
      Authorization: 'Bearer ' + this.getToken(),
    });
  }

  downloadPDF(url: string): any {
    return this.http.get(url, {headers: this.createHttpHeaders(), responseType: 'blob'}).pipe(
      map((res) => {
        return new Blob([res], {type: this.MEDIA_TYPE_APPLICATION_PDF});
      })
    );
  }

  getBinaryContentUrl(docId: number, contentType: string, extension?: string): string {
    if (this.isText(contentType) || this.isPdf(contentType)) {
      this.downloadPDF(this.pdfUrl + docId).subscribe((res: any) => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL);
      });
      return this.pdfUrl + docId;
    } else {
      this.downloadContent(docId, contentType, extension);
      return this.contentUrl + docId;
    }
  }

  getExtension(contentType: string): string {
    if (contentType) {
      const parts = contentType.split('/');
      return parts.length > 1 ? parts[1] : parts[0];
    } else {
      return '';
    }
  }

  isText(contentType: string): boolean {
    if (contentType) {
      const patt = new RegExp('text/');
      return patt.test(contentType);
    } else {
      return false;
    }
  }

  isPdf(contentType: string): boolean {
    return contentType === this.MEDIA_TYPE_APPLICATION_PDF;
  }

  downloadFocus() {
    this.binaryDownloader.download(
      'GET',
      this.focusUrl,
      'application/json',
      'application/octet-stream',
      'Focus ' + DateUtils.getCurrentTimestamp() + '.xlsm'
    );
  }

  downloadCollectionInvoiceSpreadsheet(withAsymmetricTransactions: boolean) {
    const params = UrlParams.new().add('withAsymmetricTransactions', withAsymmetricTransactions);
    const filenameKey =
      this.collectionInvoiceSpreadsheetFileNameKey +
      (withAsymmetricTransactions ? this.asymmetricTransactionsSuffixKey : '');
    const filename = this.translateService.instant(filenameKey) + '.xlsx';
    this.binaryDownloader.download(
      'GET',
      this.buildUrl(this.collectionInvoiceSpreadsheetURL, null, params),
      'application/json',
      'application/octet-stream',
      filename
    );
  }

  generateDocumentsPack(boTypeId: number, boId: number): Observable<number[]> {
    return this.postEmpty(this.url + '/generateDocumentsPacks/' + boTypeId + '/' + boId);
  }

  wordingDocumentsPack(boTypeId: number, boId: number, documentId: number): Observable<void> {
    const fullUrl = this.url + '/wordingProcess/' + boTypeId + '/' + boId + '/' + documentId;
    console.log('url with params = ' + fullUrl);
    return this.postEmpty<void>(fullUrl);
  }

  sendDocumentsPackToAutenti(boTypeId: number, boId: number, packIds: number[]): Observable<void> {
    const params = UrlParams.new().add('packIds', packIds.join(','));
    const fullUrl = this.buildUrl(
      this.url + '/' + boTypeId + '/' + boId + '/sendDocumentsPacksToAutenti',
      null,
      params
    );
    console.log('url with params = ' + fullUrl);
    return this.postEmpty<void>(fullUrl);
  }

  sendDocumentsPackToClient(boTypeId: number, boId: number, packIds: number[]): Observable<number[]> {
    const params = UrlParams.new().add('packIds', packIds.join(','));
    const fullUrl = this.buildUrl(this.url + '/' + boTypeId + '/' + boId + '/sendDocumentsPacksToClient', null, params);
    console.log('url with params = ' + fullUrl);
    return this.postEmpty<number[]>(fullUrl);
  }

  markAsSent(docId: number): Observable<DocumentDto> {
    return this.post1<number, DocumentDto>(docId, this.url + '/markAsSent');
  }
}
