import {Pipe, PipeTransform} from '@angular/core';
import {ClaimVersionValueDto} from '../../../../bonding_shared';

@Pipe({
  name: 'claimValuesOrderPipe',
})
export class ClaimValuesOrderPipe implements PipeTransform {
  transform(values: ClaimVersionValueDto[]): ClaimVersionValueDto[] {
    return values?.sort((a, b) => a.row - b.row);
  }
}
