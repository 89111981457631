import {Component, ViewChild} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {PolicyContractVersionService} from '../../../../../bonding_shared';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {TurnoverReportingSectionComponent} from '../../sections/kuke/turnover-reporting-section.component';
import {EcgPolicyProduct} from './ecg-policy-product';

@Component({
  selector: 'ecg-product',
  templateUrl: './ecg-policy-product.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class EcgPolicyProductComponent extends EcgPolicyProduct {
  @ViewChild(TurnoverReportingSectionComponent, {static: true})
  private turnoverReportingSectionComponent: TurnoverReportingSectionComponent;

  constructor(policyContractVersionService: PolicyContractVersionService) {
    super(policyContractVersionService);
  }

  limitFeesSectionDisabled() {
    return !this.policy?.businessStatus || PolicyContractViewService.policyAtLeastActivated(this.policy.businessStatus);
  }

  onValidFromChanged(validFrom: Date) {
    this.turnoverReportingSectionComponent.recalculateReportingPeriods(true);
  }
}
