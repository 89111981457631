import {Observable, of as observableOf} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {FINoteDto, ReportDefinitionSimpleDto, StateTransitionDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class FINoteService extends AbstractService {
  protected url = this.urlPrefix + 'fiNote';
  protected urlReportDefinitions = this.url + '/reportDefinitions';

  constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getFiNote(id: number): Observable<FINoteDto> {
    console.log('FINote details: id = ' + id);
    return this.get<FINoteDto>(this.url + '/' + id);
  }

  getTransitions(fiNoteDto: FINoteDto): Observable<Array<StateTransitionDto>> {
    if (fiNoteDto && fiNoteDto.status && fiNoteDto.category) {
      return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + fiNoteDto.id);
    }
    return observableOf([]);
  }

  create(invoice: FINoteDto): Observable<FINoteDto> {
    console.log('Create FINote:' + JSON.stringify(invoice));
    return this.put<FINoteDto>(invoice, this.url);
  }

  update(invoice: FINoteDto): Observable<FINoteDto> {
    console.log('Update FINoteDto id = ' + JSON.stringify(invoice));
    return this.post<FINoteDto>(invoice, this.url);
  }

  getReportDefinitions(fiNoteId: number): Observable<ReportDefinitionSimpleDto[]> {
    return this.get<ReportDefinitionSimpleDto[]>(this.urlReportDefinitions + '/' + fiNoteId);
  }
}
