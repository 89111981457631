import {Component, OnInit, Input} from '@angular/core';
import {InstalmentCalendarEntryDto, PolicyContractVersionDto} from '../../../../bonding_shared/model';
import {NumberUtils} from '../../../../bonding_shared/utils/number-utils';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {CustomValidators} from '../../../../bonding_shared/validators';
import * as moment from 'moment';
import {PolicyContractType} from '../../../../bonding_shared/model/dictionary-ids';
import {AppConfigService} from '../../../../bonding_shared/services';

@Component({
  selector: 'instalments-wizard',
  templateUrl: './instalments-wizard.component.pug',
})
export class InstalmentsWizardComponent implements OnInit {
  private _policyContractVersion: PolicyContractVersionDto;
  // private firstPaymentDateMax: Date;
  public maxInstalments: number;
  // private showErrors: boolean;
  public form: UntypedFormGroup;

  private _generatorOn: boolean;

  private _premium: number[] = [];

  get premium() {
    return this._premium;
  }

  constructor(public appService: AppConfigService) {
    this.form = new UntypedFormGroup({
      numberOfInstalments: new UntypedFormControl(''),
      issueDate: new UntypedFormControl('', Validators.required),
    });
  }

  // get firstPaymentDateMax() {
  //   return moment.max(
  //     moment(this.policyContractVersion.validFrom),
  //     moment(this.policyContractVersion.validTo).subtract(1, 'months')
  //   ).toDate();
  // }

  @Input() policyForm: UntypedFormGroup;

  @Input() set policyContractVersion(policyContractVersion: PolicyContractVersionDto) {
    if (!this.issueDate) {
      this.maxInstalments = moment(policyContractVersion.validTo).diff(
        moment(policyContractVersion.validFrom),
        'month'
      );
      this.form.setControl(
        'numberOfInstalments',
        new UntypedFormControl(this.numberOfInstalments, CustomValidators.maxValue(this.maxInstalments))
      );
    }
    if (this.appService.kuke) {
      this.recalculateMaxInstalmentsDueToKukePolicyContractType(policyContractVersion);
    }
    this._policyContractVersion = policyContractVersion;
  }

  @Input() set policyValidFrom(validFrom: Date) {
    this.recalculateMaxInstalmentsValidator(validFrom);
  }

  @Input() set policyValidTo(validTo: Date) {
    this.recalculateMaxInstalmentsValidator(this.issueDate || this.policyContractVersion.validFrom);
  }

  get policyContractVersion() {
    return this._policyContractVersion;
  }

  get generatorOn(): boolean {
    return this._generatorOn;
  }

  get issueDate() {
    if (this.form && this.form.contains('issueDate')) {
      return this.form.get('issueDate').value;
    }
    return null;
  }

  set issueDate(date: Date) {
    if (this.form) {
      this.form.setControl('issueDate', new UntypedFormControl(date, Validators.required));
    }
  }

  get numberOfInstalments() {
    if (this.form && this.form.contains('numberOfInstalments')) {
      return this.form.get('numberOfInstalments').value;
    }
    return null;
  }

  set numberOfInstalments(instNumber: number) {
    if (this.form) {
      this.form.setControl(
        'numberOfInstalments',
        new UntypedFormControl(instNumber, CustomValidators.maxValue(this.maxInstalments))
      );
    }
  }

  ngOnInit() {
    if (this.policyContractVersion) {
      this.form.setControl(
        'numberOfInstalments',
        new UntypedFormControl(1, CustomValidators.maxValue(this.maxInstalments))
      );
      this.form.setControl(
        'issueDate',
        new UntypedFormControl(this.policyContractVersion.validFrom, Validators.required)
      );
    }
    this.recalculatePremiums();
  }

  turnOn() {
    this._generatorOn = true;
  }

  turnOff() {
    this._generatorOn = false;
  }

  generate() {
    this.generateAll();
    this.updateInsuredsValidity();
    this.turnOff();
  }

  onIssueDateChange(issueDate: Date) {
    this.recalculateMaxInstalmentsValidator(issueDate);
  }

  recalculateMaxInstalmentsDueToKukePolicyContractType(pcv: PolicyContractVersionDto) {
    if (pcv && pcv.contractType) {
      if (pcv.contractType.id === PolicyContractType.KUKE_KOM_EP && this.maxInstalments && this.maxInstalments > 2) {
        this.maxInstalments = 2;
      }
      if (pcv.contractType.id === PolicyContractType.KUKE_KOM_EU) {
        this.maxInstalments = 1;
      }
    }
  }

  recalculateMaxInstalmentsValidator(issueDate: Date) {
    if (issueDate || !this.issueDate) {
      this.issueDate = moment(issueDate || this.policyContractVersion.validFrom).toDate();
    }
    this.issueDate.setDate(1);
    if (this.issueDate > this.policyContractVersion.validTo) {
      this.issueDate = moment(this.policyContractVersion.validTo).set('date', 1).toDate();
    }
    if (this.issueDate < this.policyContractVersion.validFrom) {
      this.issueDate =
        this.policyContractVersion.validFrom.getDate() === 1
          ? moment(this.policyContractVersion.validFrom).toDate()
          : moment(this.policyContractVersion.validFrom).set('date', 1).add(1, 'months').toDate();
    }
    const momentPcvVt = moment(this.policyContractVersion.validTo);
    const momentGsId = moment(this.issueDate);
    this.maxInstalments = 1 + Math.max(0, momentPcvVt.diff(momentGsId, 'month'));
    if (this.appService.kuke) {
      this.recalculateMaxInstalmentsDueToKukePolicyContractType(this.policyContractVersion);
    }
    this.form.setControl(
      'numberOfInstalments',
      new UntypedFormControl(this.numberOfInstalments, CustomValidators.maxValue(this.maxInstalments))
    );
  }

  recalculatePremiums(): number {
    let turnoverSumWithClient: number = this.policyContractVersion.clientTurnover
      ? this.policyContractVersion.clientTurnover
      : 0;
    for (let i = 0; i < this.policyContractVersion.subinsured.length; i++) {
      if (this.policyContractVersion.subinsured[i].insuredTurnover) {
        turnoverSumWithClient += this.policyContractVersion.subinsured[i].insuredTurnover;
      }
    }

    this._premium = [];
    let premiumAdd: number;
    let premiumSum = 0;
    for (let i = 0; i < this.policyContractVersion.subinsured.length; i++) {
      if (this.policyContractVersion.subinsured[i].insuredTurnover) {
        premiumAdd = Math.floor(
          (this.policyContractVersion.minPremiumFixed * this.policyContractVersion.subinsured[i].insuredTurnover) /
            turnoverSumWithClient
        );
      } else {
        premiumAdd = 0;
      }
      premiumSum += premiumAdd;
      this._premium.push(premiumAdd);
    }
    return premiumSum;
  }

  private generateAll() {
    const dates: Date[] = [];
    dates.push(this.issueDate);
    if (this.numberOfInstalments > 1) {
      let months = moment(this.policyContractVersion.validTo).diff(this.issueDate, 'months') + 1;
      months = Math.floor(months / this.numberOfInstalments);
      let dateToAdd = this.issueDate;
      for (let i = 1; i < this.numberOfInstalments; i++) {
        dateToAdd = moment(dateToAdd).utc().add(months, 'months').toDate();
        dates.push(dateToAdd);
      }
    }
    const premiumSum = this.recalculatePremiums();
    for (let i = 0; i < this.policyContractVersion.subinsured.length; i++) {
      if (this._premium[i] === 0) {
        this.policyContractVersion.subinsured[i].premiumInstalementCalendar = [];
      } else {
        this.policyContractVersion.subinsured[i].premiumInstalementCalendar = this.generateOne(
          this._premium[i],
          (this.policyContractVersion.subinsured[i].insuredTurnoverDomestic /
            this.policyContractVersion.subinsured[i].insuredTurnover) *
            100,
          dates
        );
      }
    }

    let mainInsuredDomesticPercent: number;
    if (
      NumberUtils.isValidPercent(
        this.policyContractVersion.clientTurnoverDomestic,
        this.policyContractVersion.clientTurnover
      )
    ) {
      mainInsuredDomesticPercent =
        (this.policyContractVersion.clientTurnoverDomestic / this.policyContractVersion.clientTurnover) * 100;
    } else {
      mainInsuredDomesticPercent =
        (this.policyContractVersion.insuredTurnoverDomestic / this.policyContractVersion.insuredTurnover) * 100;
    }

    if (this.policyContractVersion.minPremiumFixed === premiumSum) {
      this.policyContractVersion.premiumInstalementCalendar = [];
    } else {
      this.policyContractVersion.premiumInstalementCalendar = this.generateOne(
        this.policyContractVersion.minPremiumFixed - premiumSum,
        mainInsuredDomesticPercent,
        dates
      );
    }
  }

  private generateOne(sum: number, domesticPercent: number, dates: Date[]): InstalmentCalendarEntryDto[] {
    const calendar = [];
    const value = Math.ceil(sum / this.numberOfInstalments);
    for (let i = 0; i < this.numberOfInstalments; i++) {
      const instalment: InstalmentCalendarEntryDto = <InstalmentCalendarEntryDto>{};
      instalment.issueDate = dates[i];
      if (i === this.numberOfInstalments - 1) {
        instalment.value = Math.round((sum - i * value) * 100) / 100;
      } else {
        instalment.value = value;
      }
      instalment.insuredTurnoverDomesticPercent = domesticPercent;
      calendar.push(instalment);
    }
    return calendar;
  }

  updateInsuredsValidity() {
    const calendarFormGroup = <UntypedFormGroup>this.policyForm.controls['insureds'];
    calendarFormGroup.controls['premiumInstalmentCalendar'].setValue(
      this.policyContractVersion.premiumInstalementCalendar
    );
    calendarFormGroup.controls['subinsured'].setValue(this.policyContractVersion.subinsured);
    if (calendarFormGroup.controls['mainInsuredShare']) {
      calendarFormGroup.controls['mainInsuredShare'].setValue(this.policyContractVersion.mainInsuredShare);
    }
    calendarFormGroup.updateValueAndValidity({
      onlySelf: false,
      emitEvent: false,
    });
  }
}
