import {Component, Input} from '@angular/core';
import {Country} from '../../../bonding_shared/model/dictionary-ids';
import {PolicyInquiryVersionDto} from '../../../bonding_shared/model';
import {InquiryCountries} from '../domain/inquiry-countries';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'offer-countries-sum',
  templateUrl: 'offer-countries-sum.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class OfferCountriesSumComponent {
  self = this;

  private _inquiry: PolicyInquiryVersionDto;

  get inquiry() {
    return this._inquiry;
  }

  @Input()
  set inquiry(inq: PolicyInquiryVersionDto) {
    this._inquiry = inq;
  }

  @Input()
  visible: any = {};
  @Input()
  showErrors = false;

  countriesSumDomestic() {
    return InquiryCountries.countriesSumPlannedTO(this.inquiry, InquiryCountries.domesticPredicate);
  }

  countriesSumExport() {
    return InquiryCountries.countriesSumPlannedTO(this.inquiry, InquiryCountries.exportPredicate);
  }
}
