import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContactPersonDto, ContactPersonGroupDto} from '../model';
import {ContactPersonType} from '../model/dictionary-ids';

@Injectable()
export class ContactPersonService extends AbstractService {
  protected url = this.urlPrefix + 'contactPerson';

  static isMain(contact: ContactPersonDto) {
    return contact.type && contact.type.id === ContactPersonType.MAIN;
  }

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  create(person: ContactPersonDto): Observable<ContactPersonDto> {
    return super.put<ContactPersonDto>(person, this.url);
  }

  isPortalAdmin(contact: ContactPersonDto): boolean {
    return contact.type && contact.type.id === ContactPersonType.PORTAL_ADMIN;
  }

  updateContactPersons(
    persons: ContactPersonGroupDto[],
    boId: number,
    boTypeId: number
  ): Observable<ContactPersonGroupDto[]> {
    return this.post(persons, this.url + '/updateContactPersons?boId=' + boId + '&boTypeId=' + boTypeId);
  }

  getContactPersonGroups(boId: number, boTypeId: number): Observable<ContactPersonGroupDto[]> {
    return this.get(this.url + '/getContactPersonGroups?boId=' + boId + '&boTypeId=' + boTypeId);
  }

  getContactPersons(boId: number, boTypeId: number): Observable<ContactPersonDto[]> {
    return this.get(this.url + '/getContactPersons?boId=' + boId + '&boTypeId=' + boTypeId);
  }

  getContactPersonGroupsForDocuments(boId: number, boTypeId: number): Observable<ContactPersonGroupDto[]> {
    return this.get(this.url + '/getContactPersonGroupsForDocuments?boId=' + boId + '&boTypeId=' + boTypeId);
  }
}
