import {Component, Input} from '@angular/core';

import {PolicyInquirySurveyDto} from '../../bonding_shared/model';

@Component({
  selector: 'inquiry-survey',
  templateUrl: './inquiry-survey.component.pug',
})
export class InquirySurveyComponent {
  @Input() showErrors = true;
  @Input() survey: PolicyInquirySurveyDto;
  @Input() readonly = false;

  setValue(event: {name: string; value: boolean}) {
    this.survey[event.name] = event.value;
  }
}
