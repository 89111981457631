import {ClientOutstandingsDetailsComponent} from './client-outstandings-details.component';
import {ClientOutstandingsImportComponent} from './client-outstandings-import.component';

export const ClientOutstandingsRoutes = [
  {
    path: 'client-outstandings-details',
    component: ClientOutstandingsDetailsComponent,
  },
  {
    path: 'client-outstandings-details/:reportingPeriodId/:limitListVersionId/:declaredOnId',
    component: ClientOutstandingsDetailsComponent,
  },
  {
    path: 'client-outstandings-import/:reportingPeriodId/:limitListVersionId/:declaredOnId',
    component: ClientOutstandingsImportComponent,
  },
];
