import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {
  CountryCategoryLimitVersionBaseDto,
  CountryCategoryLimitVersionCriteriaDto,
  CountryCategoryLimitVersionDto,
  Page,
  SearchCriteria,
  SearchResult,
} from '../model';

@Injectable()
export class CountryCategoryLimitsService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'countryCategoryLimit';
  }

  getInitialVersion(): Observable<CountryCategoryLimitVersionDto> {
    return this.get<CountryCategoryLimitVersionDto>(this.url + '/initial');
  }

  getLastVersion(): Observable<SearchResult<CountryCategoryLimitVersionBaseDto>> {
    const criteria = <SearchCriteria<CountryCategoryLimitVersionCriteriaDto>>{};
    criteria.criteria = <CountryCategoryLimitVersionCriteriaDto>{};
    criteria.criteria.last = true;
    criteria.page = <Page>{start: 0, count: 300};
    return this.searchByCriteria<CountryCategoryLimitVersionCriteriaDto, CountryCategoryLimitVersionBaseDto>(criteria);
  }
}
