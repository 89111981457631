import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AfterViewChecked, Component, ElementRef, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {GrowlService} from '../../services';
import {TranslateService} from '@ngx-translate/core';
import {saveAs} from 'file-saver-es';

const FILE_SELECT_ROW_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileSelectRowSimpleComponent),
  multi: true,
};

@Component({
  selector: 'file-select-row-simple',
  template: `
    <div class="bon-row" style="padding-top: 3px;">
      <label class="bon-label">{{ labelKey ? (labelKey | translate) : label }}</label>
      <div class="searchable-value">
        <span class="a-link" *ngIf="value" style="padding-right: 15px; font-size: 13px;" (click)="showFile()">{{
          value.name
        }}</span>
        <span *ngIf="!disabled">
          <i
            class="fa fa-cloud-download"
            (click)="openFileSelectionDialog()"
            title="{{ 'common.selectFile' | translate }}"
            style="font-size: 17px; cursor: pointer;"
          ></i>
        </span>
        <input
          class="file-input"
          *ngIf="!control"
          type="file"
          (change)="onChange($event)"
          accept="{{ allowedFileTypes }}"
          hidden
        />
        <input
          class="file-input"
          *ngIf="control"
          type="file"
          [formControl]="control"
          (change)="onChange($event)"
          accept="{{ allowedFileTypes }}"
          hidden
        />
      </div>
      <error-message [control]="control" [show]="showErrors"></error-message>
    </div>
  `,
  providers: [FILE_SELECT_ROW_CONTROL_VALUE_ACCESSOR],
})
export class FileSelectRowSimpleComponent implements ControlValueAccessor, AfterViewChecked {
  static readonly MAX_FILE_BYTES: number = 10485760;

  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() labelKey = 'common.file';
  @Input() disabled: any;
  @Input() allowedFileTypes: string;
  @Input() showLabel = true;
  @Input() showErrors = false;

  @Output() fileSelected = new EventEmitter<File>();

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  public value: File;
  fileInput: any;

  ngAfterViewChecked() {
    this.fileInput = this.view.nativeElement.querySelector('.file-input');
  }

  constructor(
    private growlService: GrowlService,
    public view: ElementRef,
    private translateService: TranslateService
  ) {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  openFileSelectionDialog() {
    if (this.fileInput) {
      this.fileInput.click();
    }
  }

  onChange(event: any) {
    const file = event.target.files[0];
    if (file.size > FileSelectRowSimpleComponent.MAX_FILE_BYTES) {
      this.growlService.error(
        this.translateService.instant('common.maximumSize') +
          FileSelectRowSimpleComponent.MAX_FILE_BYTES / 1024 / 1024 +
          ' MB'
      );
    } else {
      this.value = file;
      if (this.onChangeListeners) {
        this.onChangeListeners(this.value);
      }
      this.fileSelected.emit(this.value);
    }
  }

  showFile() {
    saveAs(this.value, this.value.name);
  }
}
