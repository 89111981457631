import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {OfferCalculationEnvelopeDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class InquiryPbgService extends AbstractService {
  constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'policyInquiryVersionPbG';
  }

  calculate(offerCalculation: OfferCalculationEnvelopeDto): Observable<OfferCalculationEnvelopeDto> {
    return this.post<OfferCalculationEnvelopeDto>(offerCalculation, this.url + '/calculate/').pipe();
  }

  getCalculate(): Observable<OfferCalculationEnvelopeDto> {
    return this.get<OfferCalculationEnvelopeDto>(this.url + '/calculate/').pipe();
  }
}
