import {NG_VALUE_ACCESSOR, ControlValueAccessor, AbstractControl} from '@angular/forms';
import {forwardRef, Component, Input} from '@angular/core';
/**
 * Created by wilk on 04.04.2018.
 */

const INTEGER_INPUT_ROW_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IntegerInputRowComponent),
  multi: true,
};

@Component({
  selector: 'integer-input-row',
  template: `
    <div *ngIf="control" class="bon-row">
      <label class="bon-label">{{ labelKey ? (labelKey | translate) : label }}</label>
      <input
        class="bon-input"
        type="number"
        [ngModel]="value"
        (input)="onInput($event)"
        (blur)="onTouchedListeners()"
        [formControl]="control"
        [disabled]="disabled"
        [readonly]="readonly"
        [min]="min"
        [max]="max"
      />
      <error-message [control]="control" [show]="showErrors"></error-message>
    </div>
    <div *ngIf="!control" class="bon-row">
      <label class="bon-label">{{ labelKey ? (labelKey | translate) : label }}</label>
      <input
        class="bon-input"
        #model="ngModel"
        type="number"
        [ngModel]="value"
        (input)="onInput($event)"
        (blur)="onTouchedListeners()"
        [disabled]="disabled"
        [readonly]="readonly"
        class="bon-input"
        [min]="min"
        [max]="max"
        [required]="required"
      />
      <error-message [control]="model" [show]="showErrors"></error-message>
    </div>
  `,
  providers: [INTEGER_INPUT_ROW_CONTROL_VALUE_ACCESSOR],
})
export class IntegerInputRowComponent implements ControlValueAccessor {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() labelKey: string;
  @Input() disabled: any;
  @Input() readonly: any;
  @Input() showErrors: boolean;
  @Input() required: boolean;
  @Input() min: number;
  @Input() max: number;

  private onChangeListeners: Function;
  public onTouchedListeners: Function;
  public value: string;

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onInput(event: Event) {
    this.value = (<any>event.target).value;
    this.onChangeListeners(this.value);
  }
}
