import {Component} from '@angular/core';
import {AuditedViewAbstract} from '../../bonding_shared/components/details-view/audited-view-abstract';
import {
  AppConfigService,
  ClaimService,
  ClaimSimpleDto,
  ClaimSimplifiedCalculationDto,
  ClaimSimplifiedCalculationInvoiceDto,
  GrowlService,
  IdDto,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared';
import {ActivatedRoute} from '@angular/router';
import {ClaimSimplifiedCalculationService} from '../../bonding_shared/services/claim-simplified-calculation.service';
import {ClaimRiskType} from '../../bonding_shared/model/dictionary-ids';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'claim-simplified-calculation',
  templateUrl: 'claim-simplified-calculation.component.pug',
})
export class ClaimSimplifiedCalculationComponent extends AuditedViewAbstract<ClaimSimplifiedCalculationDto> {
  claim = <ClaimSimpleDto>{};

  constructor(
    route: ActivatedRoute,
    service: ClaimSimplifiedCalculationService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    private appService: AppConfigService,
    private claimService: ClaimService
  ) {
    super(route, service, router, translateService, growlService, loggedUserService, 'versionId');
  }

  extraInitialization() {
    super.extraInitialization();
    this.claimService.getClaimSimpleByClaimVersionId(this.id).subscribe((c) => (this.claim = c));
  }

  get isNotProductionRisk() {
    return !DictionaryUtils.in(this.claim.riskType, ClaimRiskType.PROD_RISK, ClaimRiskType.MLT_PRODUCTION_RISK);
  }

  get amountToBeIndemnified() {
    const baseIndemnificationAmount = this.current?.totals?.baseIndemnificationAmount;
    const insuranceCoverPercent = this.current?.insuranceCoverPercent;
    return (baseIndemnificationAmount * insuranceCoverPercent) / 100;
  }

  public refreshBaseIndemnificationAmount(invoice: ClaimSimplifiedCalculationInvoiceDto) {
    if (!!invoice.lossAmount && !!invoice.recoveriesBeforeIndemnification && !!invoice.deductibles) {
      invoice.baseIndemnificationAmount =
        invoice.lossAmount - invoice.recoveriesBeforeIndemnification - invoice.deductibles;
    }
  }

  initWithId() {
    this.auditedService.getRevisions(this.id).subscribe(
      (revisions) => {
        this.revisions = revisions;
        if (!revisions.length) {
          this.newVersion = true;
          this.current = this.getEmpty();
        } else {
          this.load();
        }
      },
      (error) => {
        this.handleServerError(error);
      }
    );
  }

  protected getEmpty(): ClaimSimplifiedCalculationDto {
    return <ClaimSimplifiedCalculationDto>{
      claimVersion: <IdDto>{id: this.id},
      invoices: [],
    };
  }
}
