import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {CompanyIdentifierDto, DictionaryBaseDto} from '../../../model';
import {BISource, CompanyElementaryRight, CompanyIdentifierType, UserRole} from '../../../model/dictionary-ids';
import {AppConfigService, LoggedUserService} from '../../../services';

@Component({
  selector: 'standalone-company-identifier',
  template: `
    <standalone-table-item
      [items]="_items"
      [itemBuilder]="itemBuilder.bind(this)"
      [itemFindPredicate]="itemFindPredicate.bind(this)"
      [itemGetValuePredicate]="itemGetValuePredicate.bind(this)"
      [itemSetValueFunc]="itemSetValueFunc.bind(this)"
      [keepEmptyValue]="keepEmptyValue"
      [control]="control"
      [disabled]="disabled"
      [presentationMode]="presentationMode"
    ></standalone-table-item>
  `,
})
export class StandaloneCompanyIdentifierComponent {
  // @Input() items: CompanyIdentifierDto[];
  @Input() identifierTypeId: CompanyIdentifierType;
  @Input() biSourceId: BISource;
  @Input() keepEmptyValue = false;
  @Input() control: AbstractControl;
  @Input() disabled = false;
  _readOnlyMode = false;
  presentationMode = true;
  _items: CompanyIdentifierDto[];

  @Input() set items(items: CompanyIdentifierDto[]) {
    this._items = items;
    this.presentationMode = this.companyIdentifierReadOnly();
  }

  @Input() set readOnlyMode(readOnlyMode: boolean) {
    this._readOnlyMode = readOnlyMode;
    this.presentationMode = this.companyIdentifierReadOnly();
  }

  constructor(public loggedUserService: LoggedUserService, public appService: AppConfigService) {
    // console.log('this.companyIdentifierReadOnly();', this.companyIdentifierReadOnly());
    //  this.presentationMode = this.companyIdentifierReadOnly();
  }

  itemBuilder(value: string): CompanyIdentifierDto {
    return <CompanyIdentifierDto>{
      type: <DictionaryBaseDto>{id: this.identifierTypeId},
      biSource: this.biSourceId && ({id: this.biSourceId} as DictionaryBaseDto),
      identifier: value,
    };
  }

  itemFindPredicate(item: CompanyIdentifierDto, index: number, obj: CompanyIdentifierDto[]): boolean {
    return item.type.id === this.identifierTypeId && item.biSource?.id === this.biSourceId;
  }

  itemGetValuePredicate(item: CompanyIdentifierDto): string {
    return item.identifier;
  }

  itemSetValueFunc(item: CompanyIdentifierDto, value: string) {
    item.identifier = value;
  }

  companyIdentifierReadOnly(): boolean {
    let itemValue: CompanyIdentifierDto;

    if (this._items) {
      itemValue = this._items.find((it) => {
        // find only where number is from information agency
        if (it.type.id === this.identifierTypeId && it && it.biSource && it.biSource.id === this.biSourceId) {
          return it.identifier;
        }
      });
    }

    if (this.kuke || (!this.kuke && this._readOnlyMode)) {
      return this._readOnlyMode || !this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_IDENTIFIERS_EDIT);
    } else {
      // change (number exists) for Super User for information agency
      if (itemValue) {
        return !this.loggedUserService.hasLoggedUserRole(UserRole.SUPER_USER);
      } else {
        return false;
      }
    }
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }
}
