import {Component, Input} from '@angular/core';
import {PolicyContractVersionDto, PolicyInquiryVersionDto} from '../../../../../bonding_shared/model';
import {InquiryService} from '../../../../../bonding_shared/services';

@Component({
  selector: 'kuke-policy-offers',
  templateUrl: './kuke-policy-offers.component.pug',
})
export class KukePolicyOffersComponent {
  inquiry: PolicyInquiryVersionDto;

  constructor(protected inquiryService: InquiryService) {}

  @Input()
  set policy(policy: PolicyContractVersionDto) {
    if (policy.inquiry) {
      this.inquiryService
        .getById<PolicyInquiryVersionDto>(policy.inquiry.id)
        .subscribe((inquiry) => (this.inquiry = inquiry));
    }
  }
}
