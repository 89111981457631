import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ActivationEnd, Data, Router} from '@angular/router';
import {
  AppConfigService,
  ConfirmDialogComponent,
  DictionaryBaseDto,
  DictionaryBaseService,
  LoggedUserService,
  UserDto,
} from '../../bonding_shared';
import {UserRole} from '../../bonding_shared/model/dictionary-ids';
import {PortalRouterService} from '../services';
import {ChangePasswordComponent} from '../../bonding_shared/components/user/change-password.component';
import {PortalUserAgreementsComponent} from './portal-user-agreements.component';
import {AdminNotificationsComponent} from './admin-notifications.component';
import {filter} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'portal-topnav',
  templateUrl: 'portal-topnav.component.html',
})
export class PortalTopNavComponent implements OnInit {
  @ViewChild(ChangePasswordComponent) changePassCmp: ChangePasswordComponent;
  @ViewChild(PortalUserAgreementsComponent) agreementsComponent: PortalUserAgreementsComponent;
  @ViewChild(AdminNotificationsComponent) adminNotificationsComponent: AdminNotificationsComponent;
  @ViewChild('sessionTimerDialog', {static: true}) public sessionTimerDialog: ConfirmDialogComponent;
  routerData: Data = {};
  status: {isopen: boolean} = {isopen: false};
  lang: {[key: string]: string} = {};
  roles: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public loggedUserService: LoggedUserService,
    public appConfigService: AppConfigService,
    private dictBaseService: DictionaryBaseService,
    private portalRouterService: PortalRouterService
  ) {}

  ngOnInit(): void {
    this.routerData = this.route.snapshot.firstChild.data;
    this.router.events
      .pipe(filter((e) => e instanceof ActivationEnd && e.snapshot.url[0].path !== 'bon'))
      .subscribe((e) => {
        this.routerData = (<ActivationEnd>e).snapshot.data;
      });
    this.refreshRoles();
  }

  getName(): string {
    const user: UserDto = this.loggedUserService.getLoggedUserData();
    return user.name + ' ' + user.familyName;
  }

  getRoles(dictRoles: DictionaryBaseDto[]): string {
    const user: UserDto = this.loggedUserService.getLoggedUserData();
    let text = '';
    let count = 0;
    for (const role of user.roles) {
      if (role.id === UserRole.PORTAL_USER) {
        continue;
      }
      if (count === 2) {
        return text + ', ...';
      }
      if (text.length > 0) {
        text += ', ';
      }
      text += dictRoles.find((r) => r.id === role.id).name;
      count++;
    }
    return text;
  }

  refreshRoles() {
    this.dictBaseService
      .getDictionaryBase('UserRole')
      .subscribe((dictRoles) => (this.roles = this.getRoles(dictRoles)));
  }

  sidebarToggler(): void {
    const sidebar = $('#sidebar');
    const mainContainer = $('.main-container-portal');
    sidebar.toggleClass('sidebar-left-zero');
    mainContainer.toggleClass('main-container-ml-zero');
  }

  gotoLogin(): void {
    console.log('logout...');
    this.router.navigate(['']);
  }

  goToPassChange(): void {
    this.changePassCmp.openChangePasswordDialog();
  }

  gotoHome(): void {
    this.portalRouterService.toStartPage();
  }

  goToAgreementsDialog() {
    this.agreementsComponent.openAgreementsDialog();
  }

  goToNotificationsDialog() {
    this.adminNotificationsComponent.openNotificationsDialog();
  }

  resetTimer() {
    if (this.appConfigService.kuke) {
      this.sessionTimerDialog.openAndExecuteOnConfirm(
        'topnav.sessionTimeout.dialogTitle',
        'topnav.sessionTimeout.dialogMessage',
        () => this.loggedUserService.sessionTimer.reset(600)
      );
    }
  }
}
