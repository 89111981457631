import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContactPersonGroupDto} from '../../bonding_shared/model';
import {AppConfigService, ContactPersonService, LoggedUserService} from '../../bonding_shared/services';

@Injectable()
export class PortalContactPersonService extends ContactPersonService {
  protected url = this.urlPrefix + 'portal/contactPerson';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getContactPersonGroups(boId: number, boTypeId: number): Observable<ContactPersonGroupDto[]> {
    return this.get(this.url + '/getContactPersonGroups?boId=' + boId + '&boTypeId=' + boTypeId);
  }
}
