import {CommonContractPreviewComponent} from './common-contract-preview.component';
import {ClientContractVersionSearchComponent} from './client-contract-version-search.component';

export const CommonContractRoutes = [
  {
    path: 'common-contract-preview/:contractVersionId',
    component: CommonContractPreviewComponent,
  },
  {
    path: 'client-contract-version-search',
    component: ClientContractVersionSearchComponent,
  },
];
