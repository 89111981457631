/**
 * Created by siminski on 09.12.2016.
 */
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BusinessReportGenerationComponent} from './business-report-generation.component';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {ContractSharedModule} from '../bonding-contract/contract/components/shared/contract-shared.module';
import {BusinessReportGenerateGuard} from './_guards';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    ContractSharedModule,
  ],
  declarations: [BusinessReportGenerationComponent],
  providers: [BusinessReportGenerateGuard],
})
export class BusinessReportModule {}
