import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';

/**
 * Created by jakubowski on 14.01.2020.
 */

const ID_ROW_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IdRowComponent),
  multi: true,
};

@Component({
  selector: 'id-row',
  template: `
    <num-row
      [formatting]="false"
      [labelKey]="labelKey"
      [label]="label"
      [ngModel]="value"
      [maxlength]="maxlength"
      type="integer"
      min="1"
      [max]="validateMax ? 2147483646 : undefined"
      [alignLeft]="true"
      (changeValue)="onInput($event)"
      (keydown)="onKeyDown($event)"
    >
    </num-row>
  `,
  providers: [ID_ROW_CONTROL_VALUE_ACCESSOR],
})
export class IdRowComponent implements ControlValueAccessor {
  @Input() disabled: any;
  @Input() label: string;
  @Input() labelKey: string;
  @Input() validateMax = true;
  @Output() changeValue = new EventEmitter<number>();
  @Output() keyDown = new EventEmitter<ClipboardEvent>();
  @Input() maxlength = 255;
  public onChangeListeners: Function;
  public onTouchedListeners: Function;
  public value: number;

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onInput(value: number) {
    this.value = value;
    if (this.onChangeListeners) {
      this.onChangeListeners(this.value);
    }
    this.changeValue.emit(this.value);
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onKeyDown(e: ClipboardEvent) {
    this.keyDown.emit(e);
  }
}
