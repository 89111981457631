<details-view [parentComponent]="self" [fixedBlockHidden]="false" [versionListHidden]="true" modelObjectName="invoice"
              [objectName]="'invoice.guiService.category' | cattranslate: category"
              [title]="'invoice.guiService.category' | cattranslate: category">
  <div class="additional-buttons">
    <item-dropdown *ngIf="invoice.id > 0" [items]="reportDefinitions" (menuSelect)="onReportDefinitionSelected($event, invoice.id)" caption="invoice.details.reports"
                   [dropup]="false" [disabled]="inProgress">
    </item-dropdown>
  </div>
  <div class="details-content">
    <form *ngIf="invoice" #ngForm="ngForm">
      <div class="bon-card-inner-group">
        <div class="bon-card-inner" style="width: 25%;">
          <div class="bon-row">
            <company-section *ngIf="invoice" labelKey="invoice.shared.data.client" [(ngModel)]="invoice.client"
                             [showLink]="false" [ngModelOptions]="{standalone: true}"
                             [readOnly]="true">
            </company-section>
          </div>
        </div>
        <div class="bon-card-inner" style="width: 25%;">
          <invoice-contract-link [contractLink]="invoice.contractLink"></invoice-contract-link>
        </div>
        <div class="bon-card-inner" style="width: 50%;">
          <div *ngIf="invoice.client">
            <form-row labelKey="invoice.shared.data.registrationName">
              <span class="bon-input">{{invoice.clientName}}</span>
            </form-row>
            <form-row labelKey="invoice.shared.data.address">
          <span class="bon-input">
            <span *ngIf="invoice.address">{{invoice.address.street}} {{invoice.address.streetNumber}}
              , {{invoice.address.zipCode || ''}} {{invoice.address.town}}</span>
            <span *ngIf="invoice.address && invoice.address.country">, {{invoice.address.country.name}}</span>
          </span>
            </form-row>

            <form-row labelKey="invoice.shared.data.vatNumber">
              <span class="bon-input">{{invoice.vatNumber}}</span>
            </form-row>
            <form-row labelKey="invoice.shared.data.nationalId">
              <span class="bon-input">{{invoice.nationalId}}</span>
            </form-row>
            <input-row [(ngModel)]="invoice.iban" labelKey="invoice.shared.data.iban" name="iban"
                       [readonly]="true">
            </input-row>
            <input-row [(ngModel)]="invoice.bic" labelKey="invoice.shared.data.bic" name="bic"
                       [readonly]="true">
            </input-row>
            <input-row [(ngModel)]="invoice.bankName" labelKey="invoice.shared.data.bankName" name="bankName"
                       [readonly]="true">
            </input-row>
          </div>
        </div>
      </div>


      <div *ngIf="invoice.client">

        <bon-section labelKey="invoice.shared.data.details">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <form-row labelKey="invoice.shared.data.number">
                <span class="bon-input">{{invoice.number}}</span>
              </form-row>
              <form-row *ngIf="invoice.numberingBag?.documentType" labelKey="invoice.shared.data.documentType">
                <span class="bon-input">{{invoice.numberingBag?.documentType?.name}}</span>
              </form-row>
              <form-row labelKey="invoice.shared.data.type">
                <span class="bon-input">{{invoice.invoiceType?.name}}</span>
              </form-row>
              <form-row *ngIf="invoice.invoiceType.id === InvoiceType.CREDIT_NOTE"
                        labelKey="invoice.shared.data.correctedInvoice">
                <span class="bon-input">
                  <span *ngIf="invoice.correctedInvoice">
                    <a class="a-link"
                       (click)="router.toClientInvoicePreview(categoryId, invoice.correctedInvoice.id)">{{invoice.correctedInvoice.number}}</a>
                  </span>
                </span>
              </form-row>
              <form-row *ngIf="invoice.invoiceType.id === InvoiceType.CREDIT_NOTE"
                        labelKey="invoice.shared.data.correctionNumber">
                <span class="bon-input">{{invoice.correctionNumber}}</span>
              </form-row>
              <form-row labelKey="invoice.shared.data.status">
                <span class="bon-input">{{invoice.portalBusinessStatus?.name}}</span>
              </form-row>

              <form-row *ngIf="installmentsStr" labelKey="invoice.shared.data.installmentsStr">
                <span class="bon-input">{{installmentsStr}}</span>
              </form-row>

            </div>
            <div class="bon-card-inner">
              <form-row *ngIf="invoice.correctionReason" labelKey="invoice.shared.data.correctionReason">
                <span class="bon-input">{{invoice.correctionReason.name}}</span>
              </form-row>
              <form-row *ngIf="invoice.comment" [labelKey]="invoice.invoiceType.id === InvoiceType.CREDIT_NOTE ? 'invoice.shared.data.correctionReason' : 'Comment'">
                <span class="bon-input">{{invoice.comment}}</span>
              </form-row>
              <form-row labelKey="invoice.shared.data.netAmount">
                <span class="bon-input" style="text-align: right">{{invoice.netAmount | number}}</span>
              </form-row>
              <form-row labelKey="invoice.shared.data.grossAmount">
                <span class="bon-input" style="text-align: right">{{invoice.grossAmount | number}}</span>
              </form-row>
              <form-row labelKey="invoice.shared.data.currency">
                <span class="bon-input">{{invoice.currency.code}}</span>
              </form-row>
              <form-row *ngIf="invoice.interestsAmount" labelKey="invoice.shared.data.interestsAmount">
                <span class="bon-input" style="text-align: right">{{invoice.interestsAmount | number}}</span>
              </form-row>
              <form-row *ngIf="invoice.interestsAmount" labelKey="invoice.shared.data.grossAndInterestsAmount">
                <span class="bon-input" style="text-align: right">{{invoice.grossAndInterestsAmount | number}}</span>
              </form-row>
            </div>

            <div class="bon-card-inner">
              <date-row labelKey="invoice.shared.data.issueDate" [(ngModel)]="invoice.issueDate"
                        name="issueDate" [disabled]="true">
              </date-row>
              <date-row labelKey="invoice.shared.data.dueDate" [(ngModel)]="invoice.dueDate"
                        name="dueDate" [disabled]="true">
              </date-row>
              <date-row labelKey="invoice.shared.data.dateFrom" [(ngModel)]="invoice.dateFrom" name="dateFrom"
                        disabled="true"></date-row>
              <date-row labelKey="invoice.shared.data.dateTo" [(ngModel)]="invoice.dateTo" name="dateTo"
                        disabled="true"></date-row>
              <date-row labelKey="invoice.shared.data.clearingDate" [(ngModel)]="invoice.clearingDate"
                        name="clearingDate" disabled="true"></date-row>
              <input-row labelKey="invoice.shared.data.contractNumber" [(ngModel)]="invoice.contractNumber"
                         name="contractNumber" disabled="true"></input-row>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="invoice.shared.data.documents">
          <div class="bon-card-group" *ngIf="invoice.id">
            <div class="bon-card-inner">
              <document-list [relatedToId]="invoice.id" [relatedToType]="'invoice'" [documentLink]="false"
                             [hiddenColumns]="['type', 'createdBy', 'relatedTo', 'lastSentDate', 'lastSentTo', 'status']"></document-list>
            </div>
          </div>
        </bon-section>

      </div>
    </form>


  </div>

</details-view>
