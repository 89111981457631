import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {CompanyFinancialDataDto, CompanyFinancialDataVersionDto, DictionaryBaseDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class CompanyFinancialDataService extends AbstractService {
  protected url = this.urlPrefix + 'companyFinancialData';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getFinancialData(
    companyId: number,
    manual?: boolean,
    biSourceId?: number,
    versionTypeId?: number,
    periodId?: number,
    currencyId?: number,
    unitId?: number,
    years?: number
  ): Observable<CompanyFinancialDataDto> {
    console.log('company financial data : companyId = ' + companyId);
    let separator = '?';
    let params = '';
    if (manual) {
      params = separator + 'manual=' + manual;
      separator = '&';
    }
    if (biSourceId) {
      params += separator + 'biSourceId=' + biSourceId;
      separator = '&';
    }

    if (versionTypeId) {
      params += separator + 'versionTypeId=' + versionTypeId;
      separator = '&';
    }

    if (periodId) {
      params += separator + 'periodId=' + periodId;
      separator = '&';
    }

    if (currencyId) {
      params += separator + 'currencyId=' + currencyId;
      separator = '&';
    }

    if (unitId) {
      params += separator + 'unitId=' + unitId;
      separator = '&';
    }

    if (years) {
      params += separator + 'years=' + years;
      separator = '&';
    }

    return this.get<CompanyFinancialDataDto>(this.url + '/' + companyId + params);
  }

  getFinancialDataVersion(id: number): Observable<CompanyFinancialDataVersionDto> {
    return this.get<CompanyFinancialDataVersionDto>(this.url + '/version/' + id);
  }

  // saveFinancialData (fd: CompanyFinancialDataDto) : Observable<CompanyFinancialDataDto>  {
  //   console.log('update financial data: ', fd);
  //   return this.post<CompanyFinancialDataDto>(fd, this.url);
  // }

  saveFinancialDataVersion(fdv: CompanyFinancialDataVersionDto): Observable<CompanyFinancialDataVersionDto> {
    console.log('save financial data: ', fdv);
    if (fdv.id && fdv.id > 0) {
      return this.post<CompanyFinancialDataVersionDto>(fdv, this.url);
    } else {
      return this.put<CompanyFinancialDataVersionDto>(fdv, this.url);
    }
  }

  getExchangeableCurrencies(): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/exchangeableCurrency');
  }

  getFinDataTypeByName<T>(dictionaryName: string, finDataRangeId?: number): Observable<T[]> {
    console.log('get dictionary: name = ' + dictionaryName);
    const finDataRangeParam = finDataRangeId ? '?' + 'finDataRangeId=' + finDataRangeId : '';

    return this.get<T[]>(this.url + '/filtered/' + dictionaryName + finDataRangeParam);
  }
}
