<!--<link href='https://fonts.googleapis.com/css?family=Abel' rel='stylesheet'>-->
<!--<link href='https://fonts.googleapis.com/css?family=Archivo Black' rel='stylesheet'>-->
<style>

	@font-face {
		font-family: 'ITCAvantGardeGothicBk';
		src: url('assets/fonts/ITCAvantGardeGothicBook.woff') format('woff');
    font-weight: 100;
	}
	@font-face {
		font-family: 'ITCAvantGardeGothicBd';
		src: url('assets/fonts/ITCAvantGardeBold.woff') format('woff');
	}
	@font-face {
		font-family: 'ITCAvantGardeGothicXLt';
		src: url('assets/fonts/ITCAvantGardeStd-XLt.woff') format('woff');
	}

  .credendo-light {
    font-size: 82px;
    font-family: "ITCAvantGardeGothicXLt", Arial, sans-serif;
  }
  .credendo-bold {
    font-size: 89px;
    font-family: "ITCAvantGardeGothicBd", Arial, sans-serif;
    color: #ff561c;
  }
  .credendo-bold-orange {
    font-size: 89px;
    font-family: "ITCAvantGardeGothicBd", Arial, sans-serif;
  }
  .login-box {
    background-color: rgba(215, 210, 203, 0.9);
    margin-top: 70px;
    padding-bottom: 20px !important;
  }

  .caption-txt {
    font-size: 17px !important;
    color: #342b24;
    padding-top: 30px;
  }

  input {
    width: 70%;
    color: #342b24 !important;
    margin-left: 15%;
  }
  .input-underline {
    border-bottom: 2px solid rgba(52,43,36,0.5) !important;
  }
  .input-underline:focus {
    border-bottom: 2px solid #342b24 !important;
  }
  .form-group input::-webkit-input-placeholder, .login-page .bon-row input::-webkit-input-placeholder {
      color: rgba(52,43,36,0.5) !important; }
  .form-group input:-moz-placeholder, .login-page .bon-row input:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(52,43,36,0.5) !important; }
  .form-group input::-moz-placeholder, .login-page .bon-row input::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(52,43,36,0.5) !important; }
  .form-group input:-ms-input-placeholder, .login-page .bon-row input:-ms-input-placeholder {
      color: rgba(52,43,36,0.5) !important; }
  .main-logo {
    top: 35%;
  }

</style>

<div class="login-page animate" style="background-image: url('assets/images/credendo/Credendo_Level2.jpg'); background-size: cover; padding: 0; line-height: 1">
  <div style="height: 100%; background-color: rgba(104, 86, 72, 0.2)">
    <div class="row">
      <div class="col-lg-12 main-logo">
        <div class="col-lg-12">
          <span class="credendo-light" translate>login.welcomeToYour</span><br>
          <span class="credendo-bold">CREDENDO </span>
          <!--<span class="credendo-bold-orange">{{appConfigService.displayName | uppercase}}</span><br>-->
          <span class="credendo-bold-orange">BOOSTER</span><br>
          <span class="credendo-light">PORTAL</span>
        </div>
        <!--<div class="col-lg-4 col-lg-offset-4" style="background-color: rgba(215, 210, 203, 0.8)">-->
        <!--&lt;!&ndash;<div class="col-lg-4 col-lg-offset-4" style="height: 200px; background-color: rgba(215, 210, 203, 0.8); clear: both; margin: 20px; width: 33%">&ndash;&gt;-->
          <!--&nbsp;-->
        <!--</div>-->
        <div class="col-lg-4 col-lg-offset-4 login-box">
          <!--<strong>{{appConfigService.displayName}}</strong>-->

          <form [formGroup]='form' >
            <label class="caption-txt" translate>login.remindPassword.title</label>
            <div class="form-content" (keyup.enter)="sentPasswordEmail()">
              <div class="form-group">
                <input id="recoveryLoginInput" name="sentPassloginInput" type="text" [formControl]="form.controls.login"
                       class="form-control input-underline input-lg" [placeholder]="'login.loginPlaceholder' | translate">
                <error-message [control]="form.controls.login" [show] = "showErrors"></error-message>
              </div>
              <div class="form-group">
                <input id="emailInput" name="sentPassEmailInput" type="email" [formControl]="form.controls.email"
                       class="form-control input-underline input-lg" [placeholder]="'common.email' | translate">
                <error-message [control]="form.controls.email" [show] = "showErrors"></error-message>
              </div>
            </div>
            <span class="invalid font-medium" *ngFor="let err of serverErrors">
                <h5>{{err.message | translate}}</h5>
            </span>
            <div><a id="loginBtn" class="btn rounded-btn" (click)="sentPasswordEmail()" tabindex="0" translate>login.remindPassword.sendButton</a></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
