export class RelatedObject {
  static CLIENT = 'Client';
  static BENEFICIARY = 'Beneficiary';
  static BROKER = 'Broker';
  static AGENT = 'Agent';
  static SALESMAN = 'Salesman';
  static CONTACT_PERSON = 'ContactPerson';
  static BOND_TYPE = 'BondType';
  static CONTRACT = 'Contract';
  static POLICY = 'Policy';
  static INSURED = 'Insured';
  static BUYER = 'Buyer';
  static CLAIM_RISK_TYPE = 'RiskType';
  static FRONTER = 'Fronter';
  static RISK_ASSESSMENT_AGENCY = 'RiskAssessmentAgency';
  static FL_INSURERS = 'BaseInsurers';
  static CO_INSURERS = 'CoInsurers';
  static SUBINSUREDS = 'Subinsureds';
  static SPECIAL_RISKS = 'SpecialRisks';
  static COLLECTION_COMPANIES = 'CollectionCompanies';
  static INQUIRY = 'Inquiry';
  static GROUP_HEAD = 'GroupHead';
  static GROUP_MEMBER = 'Member';
  static CURRENT_INSURER = 'CurrentInsurer';
  static GUARANTOR = 'PolicyGuarantor';
  static EXPORTER = 'PolicyExporter';
  static IMPORTER = 'PolicyImporter';
  static INVESTOR = 'PolicyInvestor';
  static SPONSOR = 'PolicySponsor';
  static DEBTOR = 'Debtor';
  static ULTIMATE_USER = 'UltimateUser';
  static PROJECT_COMPANY = 'ProjectCompany';
  static TARGET_COMPANY = 'TargetCompany';
  static TARGET_COMPANY_OWNER = 'TargetCompanyOwner';
  static MAIN_CONTRACTOR = 'MainContractor';

  constructor(private _object: Object, private _type: string) {}

  get type() {
    return this._type;
  }

  get object() {
    return this._object;
  }
}
