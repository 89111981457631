import {Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {
  BondVersionCriteriaDto,
  BondVersionOutputDto,
  DictionaryDto,
  DocumentDescriptionDto,
  SearchResult,
} from '../model/dtos';
import {BondVersionDto, BondVersionSimpleDto} from '../model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BinaryDownloaderService} from './binary-downloader.service';

@Injectable()
export class BondVersionService extends AbstractService {
  _bondUrl: string;

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'bondVersion';
    this._bondUrl = this.urlPrefix + this.portalPrefix + 'bond';
  }

  getCompanyBondVersions(clientId: number): Observable<SearchResult<BondVersionSimpleDto>> {
    return this.get<SearchResult<BondVersionSimpleDto>>(this.url + '?clientId=' + clientId);
  }

  getAutoselectedBondProductSymbol(bondTypeId: number, contractTypeId: number): Observable<DictionaryDto> {
    const query = '/autoselectedBondProductSymbol/' + bondTypeId + '/' + contractTypeId;
    return this.get<DictionaryDto>(this.url + query);
  }

  getCompanyLastBondVersions(
    clientId: number,
    beneficiaryId: number,
    requests: boolean
  ): Observable<SearchResult<BondVersionSimpleDto>> {
    let query = '?last=true';
    if (clientId) {
      query += '&clientId=' + clientId;
    }
    if (beneficiaryId) {
      query += '&beneficiaryId=' + beneficiaryId;
    }
    if (requests) {
      query += '&activated=' + false;
    } else {
      query += '&activated=' + true;
    }
    return this.get<SearchResult<BondVersionSimpleDto>>(this.url + query);
  }
  getCompanyLastBondVersions1(clientId: number, beneficiaryId: number): Observable<SearchResult<BondVersionSimpleDto>> {
    let query = '?last=true';
    if (clientId) {
      query += '&clientId=' + clientId;
    }
    if (beneficiaryId) {
      query += '&beneficiaryId=' + beneficiaryId;
    }

    return this.get<SearchResult<BondVersionSimpleDto>>(this.url + query);
  }

  getContractLastBondVersions1(contractId: number): Observable<SearchResult<BondVersionSimpleDto>> {
    return this.get<SearchResult<BondVersionSimpleDto>>(this.url + '?contractId=' + contractId + '&last=true');
  }

  getContractLastBondVersions2(contractId: number, clientId: number): Observable<SearchResult<BondVersionSimpleDto>> {
    let query = '?contractId=' + contractId + '&last=true';
    if (clientId) {
      query += '&clientId=' + clientId;
    }
    return this.get<SearchResult<BondVersionSimpleDto>>(this.url + query);
  }

  getContractLastBondVersions(
    contractId: number,
    requests: boolean,
    clientId?: number
  ): Observable<SearchResult<BondVersionSimpleDto>> {
    let query = '?contractId=' + contractId + '&last=true';
    if (clientId) {
      query += '&clientId=' + clientId;
    }
    if (requests) {
      query += '&activated=' + false;
    } else {
      query += '&activated=' + true;
    }
    return this.get<SearchResult<BondVersionSimpleDto>>(this.url + query);
  }

  getBondVersions(bondId: number): Observable<SearchResult<BondVersionSimpleDto>> {
    return this.get<SearchResult<BondVersionSimpleDto>>(this.url + '?bondId=' + bondId);
  }

  getAllVersions(
    start: number,
    count: number,
    sortBy: string,
    desc: boolean
  ): Observable<SearchResult<BondVersionSimpleDto>> {
    const query: string = this.url + '?start=' + start + '&count=' + count + '&sortBy=' + sortBy;
    return this.get<SearchResult<BondVersionSimpleDto>>(query);
  }

  getLastBondVersionForBond(bondId: number): Observable<BondVersionDto> {
    return this.get<SearchResult<BondVersionDto>>(this.url + '?bondId=' + bondId + '&last=true').pipe(
      map((searchResult) => {
        if (searchResult.size === 1) {
          return searchResult.result.pop();
        } else {
          console.log(
            'ERROR in BondVersionService::getLastBondVersionForBond() -> ' +
              searchResult.size +
              ' element(s) found !!!!!!'
          );
          return null;
        }
      })
    );
  }

  getBondVersion(bondVersionId: number): Observable<BondVersionDto> {
    return this.get<BondVersionDto>(this.url + '/' + bondVersionId);
  }

  deleteBondVersion(bondVersionId: number): Observable<BondVersionDto> {
    return this.delete<BondVersionDto>(this.url + '/' + bondVersionId);
  }

  getNewBondVersion(bondId: number): Observable<BondVersionDto> {
    return this.get<BondVersionDto>(this._bondUrl + '/' + bondId + '/newVersion');
  }

  getInitialVersion(
    clientId: number,
    typeId: number,
    subtypeId: number,
    buId: number,
    contractVersionId: number,
    replacedBondVersionId?: number
  ): Observable<BondVersionDto> {
    let urlParams = '/initialVersion?clientId=' + clientId + '&buId=' + buId;
    if (subtypeId) {
      urlParams = urlParams + '&subtypeId=' + subtypeId;
    }
    if (typeId) {
      urlParams = urlParams + '&typeId=' + typeId;
    }
    if (contractVersionId) {
      urlParams = urlParams + '&contractVersionId=' + contractVersionId;
    }
    if (replacedBondVersionId) {
      urlParams = urlParams + '&replacedBondVersionId=' + replacedBondVersionId;
    }
    return this.get<BondVersionDto>(this._bondUrl + urlParams);
  }

  saveBondVersion(bond: BondVersionDto): Observable<BondVersionDto> {
    if (bond.id && bond.id > 0) {
      return this.updateBondVersion(bond);
    } else {
      return this.createBondVersion(bond);
    }
  }

  private createBondVersion(bond: BondVersionDto): Observable<BondVersionDto> {
    console.log('crete bond version:', bond);
    return this.put<BondVersionDto>(bond, this.url);
  }

  private updateBondVersion(bond: BondVersionDto): Observable<BondVersionDto> {
    console.log('update bond version: ', bond);
    return this.post<BondVersionDto>(bond, this.url);
  }

  getSpecialTextDto(bond: BondVersionDto): Observable<DocumentDescriptionDto> {
    console.log('get special text from alfresco: ' + JSON.stringify(bond));
    return this.get<DocumentDescriptionDto>(this.url + '/' + bond.id + '/specialText');
  }

  getSpecialTextContent(bond: BondVersionDto, outputFilename: string) {
    console.log('get special text content from alfresco: ' + JSON.stringify(bond));
    this.binaryDownloader.download(
      'GET',
      this.url + '/' + bond.id + '/specialTextContent',
      'application/octet-stream',
      'application/octet-stream',
      outputFilename
    );
  }

  getSpecialTextContentFromContract(contractVersionId: number, repositoryId: string, outputFilename: string) {
    console.log('get special text content from alfresco by contract');
    this.binaryDownloader.download(
      'GET',
      this.url + '/' + contractVersionId + '/' + repositoryId + '/specialTextContent',
      'application/octet-stream',
      'application/octet-stream',
      outputFilename
    );
  }

  export(
    criteria: BondVersionCriteriaDto,
    outputFileName: string,
    errorCallback: (errMsg: string) => void,
    completeCallback: (f: File) => void
  ) {
    console.log('export ', criteria);
    this.binaryDownloader.download(
      'POST',
      this.url + '/export/',
      'application/json',
      'application/octet-stream',
      outputFileName,
      criteria,
      errorCallback,
      completeCallback
    );
  }

  getBondOutput(typeId: number, subtypeId: number, contractVersionId: number): Observable<BondVersionOutputDto> {
    let params = new HttpParams();
    if (typeId) {
      params = params.append('typeId', typeId);
    }
    if (subtypeId) {
      params = params.append('subtypeId', subtypeId);
    }
    if (subtypeId) {
      params = params.append('contractVersionId', contractVersionId);
    }
    return this.get<BondVersionOutputDto>(this.url + '/bondOutput', params);
  }
}
