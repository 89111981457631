import {Component} from '@angular/core';
import {KukePolicyProduct} from '../kuke-policy-product';
import {ControlContainer, NgForm} from '@angular/forms';
import {PolicyContractVersionService} from '../../../../../../bonding_shared/services';

@Component({
  selector: 'kom-sp-product',
  templateUrl: './kom-sp-product.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KomSpProductComponent extends KukePolicyProduct {
  constructor(policyContractVersionService: PolicyContractVersionService) {
    super(policyContractVersionService);
  }
}
