import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TopNavComponent} from './topnav.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {UserAgendaComponent} from './user-agenda.component';
import {SharedUserModule} from '../user/shared-user.module';
import {TranslateModule} from '@ngx-translate/core';
import {SharedTopNavModule} from '../../../shared/topnav';
import {SessionTimerComponent} from './session-timer.component';

@NgModule({
  imports: [RouterModule, CommonModule, BsDropdownModule, SharedUserModule, TranslateModule, SharedTopNavModule],
  declarations: [TopNavComponent, UserAgendaComponent, SessionTimerComponent],
  exports: [TopNavComponent, UserAgendaComponent, SessionTimerComponent],
})
export class TopNavModule {}
