import {Component, Input} from '@angular/core';
/**
 * Created by wilk on 29.03.2019.
 */
import {Button} from '../details-view/button';

@Component({
  selector: 'bon-button',
  template: `
    <button
      *ngIf="button; else customButtonTemplate"
      type="button"
      [class]="button.class ? button.class : defaultClass"
      (click)="button.onClick()"
      [hidden]="button.hidden"
      [disabled]="inProgress || button.disabled"
      [title]="button.title | translate"
      [btnIcon]="button.icon ? button.icon : defaultIcon"
      translate
    >
      {{ button.name }}
    </button>
    <ng-template #customButtonTemplate> <ng-content></ng-content> </ng-template>
    <i class="fa fa-spinner fa-pulse fa-2x" *ngIf="spinner && inProgress"></i>
    <span *ngIf="spinner && (!button || !button.hidden)" class="spinner-container"></span>
  `,
  styles: [
    'i.fa-spinner { display: none; position: fixed; margin-top: 5px; margin-left: 4px; } span.spinner-container { display: none; }',
  ],
})
export class BonButtonComponent {
  @Input() inProgress: boolean;
  @Input() button: Button;
  @Input() defaultClass: string;
  @Input() defaultIcon: string;
  @Input() spinner: boolean;
}
