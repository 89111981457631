import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {PolicyDrawdownPreviewDto} from '../model';

@Injectable()
export class PolicyDrawdownPreviewService extends AbstractService {
  protected url = this.urlPrefix + 'masterPolicyContract/drawdowns';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getDrawdownPreview(masterPolicyId: number): Observable<PolicyDrawdownPreviewDto> {
    return this.get<PolicyDrawdownPreviewDto>(this.url + '/summary?masterPolicyId=' + masterPolicyId);
  }
}
