import {Component, Input} from '@angular/core';
import {
  BusinessPropertiesService,
  ClaimVersionDto,
  Currency,
  DictionaryBaseDto,
  LoggedUserService,
  UserRange,
} from '../../../bonding_shared';
import {DictionaryUtils} from '../../../bonding_shared/utils/dictionary-utils';
import {
  ClaimCauseOfLoss,
  ClaimDecisionType,
  ClaimLegalStatus,
  ClaimRiskType,
  ClaimStatus,
  ClaimVersionType,
  DictionaryProfile,
  UnderwritingType,
  UserRole,
} from '../../../bonding_shared/model/dictionary-ids';
import {ClaimUtilsService} from '../services/claim-utils.service';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'credendo-claim-general-information',
  templateUrl: './credendo-claim-general-information.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class CredendoClaimGeneralInformationComponent {
  _claimVersion: ClaimVersionDto;

  @Input() set claimVersion(claimVersion: ClaimVersionDto) {
    this._claimVersion = claimVersion;
    this.hiddenClaimRiskTypeIds = new Set<number>(this.getHiddenClaimRiskTypeIds());
    this.hiddenClaimLegalStatusIds = new Set<number>(this.getHiddenClaimLegalStatusIds());
  }

  get claimVersion() {
    return this._claimVersion;
  }

  hiddenClaimRiskTypeIds = new Set<number>(this.getHiddenClaimRiskTypeIds());
  hiddenClaimLegalStatusIds = new Set<number>(this.getHiddenClaimLegalStatusIds());

  @Input() produktType: DictionaryBaseDto;
  @Input() fullEditMode = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() showErrors = false;
  readonly profileId = DictionaryProfile.CREDENDO_CLAIM_INDEMNIFICATION_CURRENCY;
  readonly claimUserRange: UserRange = 'claim';
  isComMember = false;
  systemCurrency: Currency;

  constructor(
    private businessPropertiesService: BusinessPropertiesService,
    private loggedUserService: LoggedUserService
  ) {
    this.businessPropertiesService.getProperties().subscribe((p) => (this.systemCurrency = p.systemCurrency));
    this.isComMember = this.loggedUserService.hasLoggedUserRole(UserRole.CLAIMS_COM_MEMBER);
  }

  get isSuretyType() {
    return ClaimUtilsService.isExpectedRiskType(this.claimVersion, ClaimRiskType.SURETY);
  }

  get isCreditInsuranceType() {
    return ClaimUtilsService.isExpectedRiskType(this.claimVersion, ClaimRiskType.CREDIT_INSURANCE);
  }

  get isThreatCauseOfLoss() {
    return ClaimUtilsService.isThreatCauseOfLoss(this.claimVersion);
  }

  get hiddenDecisionIds() {
    const ids = new Set<number>();
    if (DictionaryUtils.in(this.claimVersion.versionType, ClaimVersionType.CLAIM_ADVICE)) {
      ids.add(ClaimDecisionType.ACCEPTED);
    }
    return ids;
  }

  get hiddenClaimVersionTypeIds() {
    const ids = new Set<number>();
    if (this.isClaimVersionTypeEditable) {
      ids.add(ClaimVersionType.MIGRATION);
    }
    if (!(!this.claimVersion?.claim?.id || this.claimVersion.versionNumber === 1)) {
      ids.add(ClaimVersionType.CLAIM);
    }
    return ids;
  }

  get isClaimVersionTypeEditable() {
    return (
      !this.readonly &&
      DictionaryUtils.in(this.claimVersion.claim.riskType, ClaimRiskType.CREDIT_INSURANCE) &&
      this.isSingleRiskOrTurnover &&
      !DictionaryUtils.equalsDictAndId(this.claimVersion.versionType, ClaimVersionType.MIGRATION) &&
      DictionaryUtils.in(this.claimVersion.status, ClaimStatus.UNDER_ASSESSMENT, ClaimStatus.WAITING_FOR_FIRST_APPROVAL)
    );
  }

  get isNotificationDateWarning() {
    return (
      this.claimVersion?.claim?.bondVersion &&
      this.claimVersion.notificationDate > this.claimVersion?.claim?.bondVersion.releaseDate
    );
  }

  get isFormDisabled() {
    return this.disabled || (this.claimVersion && this.claimVersion.status.id !== ClaimStatus.UNDER_ASSESSMENT);
  }

  get isDecisionDisabled() {
    return this.disabled || (this.claimVersion && this.claimVersion.status.id === ClaimStatus.UNDER_ASSESSMENT);
  }

  get isSingleRiskOrTurnover() {
    return ClaimUtilsService.isSingleRiskOrTurnover(this.claimVersion);
  }

  getHiddenClaimRiskTypeIds(): number[] {
    if (this.isSingleRiskOrTurnover) {
      return [
        ClaimCauseOfLoss.NON_FULLFILMENT,
        ClaimCauseOfLoss.LOSS_THREAT,
        ClaimCauseOfLoss.INSOLVENCY,
        ClaimCauseOfLoss.PROTRACTED_DEFAULT,
      ];
    } else {
      return [ClaimCauseOfLoss.DRAFT, ClaimCauseOfLoss.POTENTIAL_CLAIM, ClaimCauseOfLoss.CLAIM];
    }
  }

  getHiddenClaimLegalStatusIds(): number[] {
    if (this.isSingleRiskOrTurnover) {
      return [
        ClaimLegalStatus.LEGAL_ACTIONS,
        ClaimLegalStatus.VOLUNTARLY_AGREEMENT,
        ClaimLegalStatus.INSOLVENCY_ALL_TYPES,
        ClaimLegalStatus.EQUALS_TO_INSOLVENCY,
        ClaimLegalStatus.PAYMENT_PLAN,
      ];
    } else {
      return [
        ClaimLegalStatus.OVERDUE_OR_LOSS_THREAT,
        ClaimLegalStatus.PROTRACTED_OVERDUES,
        ClaimLegalStatus.RESTRUCTURING_REQUESTED,
        ClaimLegalStatus.RESTRUCTURING_AGREED,
        ClaimLegalStatus.CHAPTER_11_OR_UDICIAL_REORGANISATION,
        ClaimLegalStatus.INSOLVENCY,
        ClaimLegalStatus.BANKRUPTCY,
        ClaimLegalStatus.ARBITRATION_OR_LEGAL_ACTIONS,
        ClaimLegalStatus.FRAUD,
        ClaimLegalStatus.OTHER,
      ];
    }
  }

  changeStatus(status: DictionaryBaseDto) {
    this.claimVersion.status = status;
  }

  onNotificationDateChanged() {
    if (
      this.claimVersion &&
      this.claimVersion.notificationDate &&
      this.claimVersion?.claim?.riskType?.id === ClaimRiskType.SURETY
    ) {
      if (
        this.claimVersion?.claim?.bondVersion &&
        this.claimVersion?.claim?.bondVersion?.underwritingType?.id !== UnderwritingType.NON_RENEWABLE
      ) {
        this.claimVersion.underwritingYear = new Date(this.claimVersion.notificationDate).getFullYear();
      }
      this.claimVersion.lossOccurrence = this.claimVersion.notificationDate;
    }
  }

  get isNewOrLastVersion() {
    return this._claimVersion && (!this._claimVersion.id || this._claimVersion.last);
  }
}
