import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PortalTopNavComponent} from './portal-topnav.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TranslateModule} from '@ngx-translate/core';
import {TimePipe} from './time-pipe';
import {SharedUserModule} from '../../bonding_shared/components/user/shared-user.module';
import {PortalTopNavInfoComponent} from './components/portal-top-nav-info.component';
import {SharedTopNavModule} from '../../shared/topnav';
import {PortalSessionTimerComponent} from './components/portal-session-timer.component';
import {PortalUserAgreementsComponent} from './portal-user-agreements.component';
import {AdminNotificationsComponent} from './admin-notifications.component';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {ReactiveFormsModule} from '@angular/forms';
import {LimitCoverActivePropositionAlertComponent} from './components/limit-cover-active-proposition-alert.component';

@NgModule({
  imports: [
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    BsDropdownModule,
    TranslateModule,
    SharedUserModule,
    SharedTopNavModule,
    SharedComponentsModule,
  ],
  declarations: [
    AdminNotificationsComponent,
    PortalTopNavComponent,
    TimePipe,
    PortalTopNavInfoComponent,
    PortalSessionTimerComponent,
    PortalUserAgreementsComponent,
    LimitCoverActivePropositionAlertComponent,
  ],
  exports: [PortalTopNavComponent],
})
export class PortalTopNavModule {}
