import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {NumberFormatDto} from '../model';

@Injectable()
export class NumberFormatService extends AbstractService {
  private _numberFormatUrl = this.urlPrefix + 'numberformat';

  constructor(public http: HttpClient, appConfigService: AppConfigService, _loggedUserService: LoggedUserService) {
    super(http, appConfigService, _loggedUserService);
  }

  getNumberFormat(countryId: number, numberTypeId: number): Observable<NumberFormatDto> {
    console.log('get NumberFormat: countryId = ' + countryId + ', numberTypeId = ' + numberTypeId);
    return this.get<NumberFormatDto>(
      this._numberFormatUrl + '?countryId=' + countryId + '&numberTypeId=' + numberTypeId
    );
  }
}
