import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {TaskCriteriaDto, TaskDto, TaskMassAssignDto, TaskMassClosureDto, UserAgendaDto} from '../model';
import {HttpClient} from '@angular/common/http';
import {BinaryDownloaderService} from './binary-downloader.service';

// modification
@Injectable()
export class TaskService extends AbstractService {
  protected url = this.urlPrefix + 'task';

  userAgendaChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getTask(id: number): Observable<TaskDto> {
    console.log('task details: id = ' + id);
    return this.get<TaskDto>(this.url + '/' + id);
  }

  createTask(task: TaskDto): Observable<TaskDto> {
    const userId = task.user ? task.user.id : null;
    const groupId = task.userGroup ? task.userGroup.id : null;
    if (this.loggedUserService.isThisUserOrBelongsToGroup(userId, groupId)) {
      setTimeout(() => {
        this.userAgendaChange.emit('change');
      }, 3000);
    }
    console.log('crete task:', task);
    return this.put<TaskDto>(task, this.url);
  }

  updateTask(task: TaskDto): Observable<TaskDto> {
    console.log('update task:', task);
    return this.post<TaskDto>(task, this.url);
  }

  massAssignTasks(req: TaskMassAssignDto) {
    const assignUrl = this.url + '/massAssign';
    return this.post1<TaskMassAssignDto, number>(req, assignUrl);
  }

  massClosureTasks(req: TaskMassClosureDto) {
    const assignUrl = this.url + '/massClosure';
    return this.post1<TaskMassClosureDto, number>(req, assignUrl);
  }

  deleteTask(id: number): Observable<TaskDto> {
    console.log('delete task: id = ' + id);
    return this.delete<TaskDto>(this.url + '/' + id);
  }

  userAgenda(moduleId: number): Observable<UserAgendaDto> {
    return this.get<UserAgendaDto>(this.url + '/userAgenda/' + moduleId);
  }

  getCorrespondingTasks(boTypeId: number, boId: number): Observable<Array<TaskDto>> {
    console.log('getCorrespondinTasks : type = ' + boTypeId + ' id = ' + boId);
    return this.get<Array<TaskDto>>(this.url + '/associatedTasks/' + boTypeId + '/' + boId);
  }

  closeTask(id: number): Observable<TaskDto> {
    setTimeout(() => {
      this.userAgendaChange.emit('close');
    }, 3000);
    return this.get<TaskDto>(this.url + '/close/' + id);
  }

  generateReport(criteria: TaskCriteriaDto): Observable<TaskCriteriaDto> {
    return this.post<TaskCriteriaDto>(criteria, this.url + '/generateHistoryReport');
  }

  initialVersion(relatedToTypeId: number, relatedToId: number): Observable<TaskDto> {
    return this.get<TaskDto>(this.url + '/initialVersion/' + relatedToTypeId + '/' + relatedToId);
  }
}
