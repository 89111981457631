import {Component, OnInit} from '@angular/core';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {RouterService} from '../../bonding_shared/services/router-service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {LimitService} from '../../bonding_shared/services/limit.service';
import {TranslateService} from '@ngx-translate/core';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {ActivatedRoute, Params} from '@angular/router';
import {
  AmountDto,
  DictionaryBaseDto,
  LimitCoverPlusDto,
  LimitCoverPlusValueDto,
  LimitRequestBaseDto,
} from '../../bonding_shared/model';
import {Button} from '../../bonding_shared/components/details-view/button';
import {LimitCoverPlusStatus} from '../../bonding_shared/model/dictionary-ids';
import {DateRangeUtils} from '../../bonding_shared/utils/date-range-utils';
import {DateUtils} from '../../bonding_shared/utils/date-utils';
import {BusinessPropertiesService} from '../../bonding_shared/services';
import {LimitCoverGuiService} from './limit-cover-gui.service';

@Component({
  selector: 'client-limit-cover-plus-view',
  templateUrl: './client-limit-cover-plus-view.component.pug',
  styles: ['.bon-row{@extend .bon-row; padding-top: 6px;}'],
})
export class ClientLimitCoverPlusViewComponent extends DetailsView implements OnInit {
  readonly ULTIMATE_MIN_VALUE = 1000;
  readonly COVER_DURATION = 90;

  cover: LimitCoverPlusDto;
  minValidFrom = new Date();
  maxValidFrom = new Date();
  minValue = this.ULTIMATE_MIN_VALUE;
  systemCurrency: DictionaryBaseDto;

  readonly LimitCoverPlusStatus = LimitCoverPlusStatus;

  constructor(
    private _route: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private limitService: LimitService,
    public router: RouterService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected businessPropertiesService: BusinessPropertiesService,
    public coverService: LimitCoverGuiService
  ) {
    super(growlService);
    this.form = new UntypedFormGroup({});
    this.saveButton = new Button('limitCover.buyButton.name', this.onBuy.bind(this), false, false);
    this.deleteButton = new Button('limitCover.abandonButton.name', this.onAbandon.bind(this), false, false);
    this.cancelButton.hidden = false;
    this.saveButton.title = 'limitCover.buyButton.title';
    this.deleteButton.title = 'limitCover.abandonButton.title';
    this.cancelButton.title = 'limitCover.cancelButton.title';
    businessPropertiesService.getProperties().subscribe((p) => (this.systemCurrency = p.systemCurrency));
  }

  ngOnInit() {
    this._route.params.subscribe((params) => this.initializeView(params));
  }
  onBuy() {
    this.cover.status.id = LimitCoverPlusStatus.ACTIVE;
    this.onDecision('limitCover.activateMsg');
  }
  onAbandon() {
    this.cover.status.id = LimitCoverPlusStatus.ABANDONED;
    this.onDecision('limitCover.abandonMsg');
  }

  onCancel() {
    this.router.toLimitDetails(this.cover.limitRequest.id);
  }

  onDecision(msg: string) {
    this.inProgress = true;
    this.limitService.decideOnCover(this.cover).subscribe(
      (c) => {
        console.log('cover status changed: ', c);
        this.cover = c;
        this.showSavedMsg(msg);
        this.router.toLimitDetails(c.limitRequest.id);
        this.inProgress = false;
        this.coverService.removeProposal(this.cover.id);
      },
      (error) => this.handleServerError(error)
    );
  }

  handleButtons() {
    this.saveButton.hidden = this.cover.businessStatus.id !== LimitCoverPlusStatus.PROPOSAL;
    this.deleteButton.hidden = this.saveButton.hidden;
  }

  initializeView(params: Params) {
    const id = +params['id'];
    this.limitService.getCover(id).subscribe(
      (c) => {
        this.cover = c;
        const validFrom = c.proposedValue.validFrom;
        const validTo = DateUtils.addDay(validFrom, this.COVER_DURATION - 1);
        this.minValidFrom = validFrom;
        this.cover.value = <LimitCoverPlusValueDto>{
          validFrom: validFrom,
          validTo: validTo,
          amount: c.proposedValue.amount,
          fee: this.cloneAmount(c.proposedValue.fee),
          compensatedFee: this.cloneAmount(c.proposedValue.compensatedFee),
        };
        this.setCompensatedFee();
        this.minValue = this.calculateMinCoverValue();
        this.handleButtons();
      },
      (error) => this.handleServerError(error)
    );
  }

  cloneAmount(a: AmountDto): AmountDto {
    return <AmountDto>{value: a.value, valueInRefCurrency: a.valueInRefCurrency};
  }

  setCompensatedFee() {
    console.log(
      'unusedDays: ' +
        this.unusedDays +
        ', fee: ' +
        this.cover.value.fee.valueInRefCurrency +
        ', unusedFee: ' +
        this.previousCoverUnusedFee.valueInRefCurrency
    );
    this.cover.value.compensatedFee.value = this.cover.value.fee.value - this.previousCoverUnusedFee.value;
    this.cover.value.compensatedFee.valueInRefCurrency =
      this.cover.value.fee.valueInRefCurrency - this.previousCoverUnusedFee.valueInRefCurrency;
  }

  get previousCoverUnusedFee(): AmountDto {
    if (!this.isPreviousCoverShortened()) {
      return <AmountDto>{value: 0, valueInRefCurrency: 0};
    }
    const p = this.unusedDays / this.COVER_DURATION;
    const v = p * this.cover.previousActiveCoverValue.fee.value;
    const vc = p * this.cover.previousActiveCoverValue.fee.valueInRefCurrency;
    return <AmountDto>{value: v, valueInRefCurrency: vc};
  }

  isPreviousCoverShortened() {
    return (
      this.cover.previousActiveCoverValue &&
      DateUtils.compare(this.cover.previousActiveCoverValue.validTo, this.cover.value.validFrom) >= 0
    );
  }

  onAmountChanged(e: number | any) {
    let v = e;
    if (isNaN(v)) {
      v = e.value;
    }
    this.recalculateFee(v);
    this.setCompensatedFee();
  }

  recalculateFee(v: number) {
    const p = v / this.cover.proposedValue.amount;
    this.cover.value.fee.value = this.cover.proposedValue.fee.value * p;
    this.cover.value.fee.valueInRefCurrency = this.cover.proposedValue.fee.valueInRefCurrency * p;
  }

  onValidFromChanged(newValidFrom: Date) {
    this.cover.value.validTo = DateUtils.addDay(newValidFrom, this.COVER_DURATION - 1);

    this.minValue = this.calculateMinCoverValue();
    if (this.cover.value.amount < this.minValue) {
      this.cover.value.amount = this.minValue;
      this.recalculateFee(this.cover.value.amount);
    }
    this.setCompensatedFee();
  }

  get sliderDisabled() {
    return this.minValue >= this.cover.proposedValue.amount;
  }

  calculateMinCoverValue(): number {
    const previousActiveCoverAmount = this.cover.previousActiveCoverValue
      ? this.cover.previousActiveCoverValue.amount
      : 0;
    if (!this.isPreviousCoverShortened()) {
      return previousActiveCoverAmount > 0 ? previousActiveCoverAmount : this.ULTIMATE_MIN_VALUE;
    }

    const p = this.unusedDays / this.COVER_DURATION;
    let minV =
      (p * (this.cover.previousActiveCoverValue.fee.value * this.cover.proposedValue.amount)) /
      this.cover.proposedValue.fee.value;

    minV = Math.ceil(minV / 1000) * 1000;
    console.log('minV: ' + minV + ', unusedDays: ' + this.unusedDays);
    minV = Math.max(minV, this.ULTIMATE_MIN_VALUE, previousActiveCoverAmount);
    return minV;
  }

  get unusedDays() {
    if (!this.cover.previousActiveCoverValue) {
      return 0;
    }
    const unusedDays = DateRangeUtils.daysBetween(
      this.cover.value.validFrom,
      this.cover.previousActiveCoverValue.validTo
    );
    return unusedDays > this.COVER_DURATION ? this.COVER_DURATION : unusedDays;
  }

  get proposalText() {
    const msgParams = {};
    msgParams['buyerName'] = (<LimitRequestBaseDto>this.cover.limitRequest).limit.buyer.company.registrationName;
    msgParams['proposalValidTo'] = DateUtils.format(this.cover.proposalExpirationDate);
    return this.translateService.instant('limitCover.propositionMsg', msgParams);
  }
}
