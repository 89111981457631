import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {RouterService} from '../../bonding_shared/services/router-service';
import {ActivatedRoute, Params} from '@angular/router';
import {LimitGuiService} from './shared/services/limit-gui.service';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  CompanyBaseDto,
  DictionaryBaseDto,
  IdDto,
  LimitBaseDto,
  LimitRequestCriteriaDto,
  LimitRequestDto,
  TemplateBaseDto,
} from '../../bonding_shared/model/dtos';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {LimitService} from '../../bonding_shared/services/limit.service';
import {
  AppConfigService,
  BTableComponent,
  CompanyService,
  SearchDataProvider,
  TemplateService,
} from '../../bonding_shared';
import {Button} from '../../bonding_shared/components/details-view/button';
import {LimitCategory, LimitDecisionStatus, LimitStatus} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'limit-mailing-list',
  templateUrl: './limit-mailing-multilist.component.pug',
})
export class LimitMailingMultilistComponent extends DetailsView implements OnInit {
  @ViewChild('limitTable') limitTable: BTableComponent<LimitRequestDto>;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    private limitGuiService: LimitGuiService,
    private companyService: CompanyService,
    private limitService: LimitService,
    public router: RouterService,
    protected growlService: GrowlService,
    private appService: AppConfigService,
    private templateService: TemplateService
  ) {
    super(growlService);
    this.dataProvider = limitGuiService.createDataProvider();
    this.sendEmailsButton = new Button('limitMailingList.sendEmail', this.onSendEmails.bind(this), false, true);
    this.cancelButton.hidden = false;
  }

  company: CompanyBaseDto;
  template: TemplateBaseDto;
  dataProvider: SearchDataProvider<LimitRequestCriteriaDto, LimitRequestDto>;
  selectedLimitRequests: LimitRequestDto[] = [];
  sendEmailsButton: Button;

  ngOnInit() {
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const templateId = +params['templateId'];
    const companyId = +params['companyId'];
    this.companyService.getCompanyBaseDto(companyId).subscribe((result) => {
      this.company = result;
      this.refreshList();
    });
    this.templateService.getById(templateId).subscribe((template) => (this.template = <TemplateBaseDto>template));
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  onSendEmails() {
    this.limitService
      .sendClientEmails(
        this.selectedLimitRequests.map((r) => <IdDto>{id: r.id}),
        this.template.id,
        this.company.id
      )
      .subscribe({
        next: (r) => {
          if (r.warnings.length === 0) {
            this.growlService.notice('limitMailingList.emailsSent');
            this.serverErrors = undefined;
          } else {
            this.serverErrors = r.warnings;
          }
        },
        error: (error) => (this.serverErrors = error),
      });
  }

  refreshList() {
    this.dataProvider.searchCriteria.criteria.buyerCompanyId = this.company.id;
    this.dataProvider.searchCriteria.criteria.decisionStatus = <DictionaryBaseDto>{id: LimitDecisionStatus.ACTIVE};
    this.dataProvider.searchCriteria.criteria.limit = <LimitBaseDto>{};
    this.dataProvider.searchCriteria.criteria.limit.category = <DictionaryBaseDto>{id: LimitCategory.POLICY};
    this.dataProvider.searchCriteria.criteria.limit.businessStatus = <DictionaryBaseDto>{id: LimitStatus.ACTIVE};
    if (this.limitTable) {
      this.limitTable.search();
    }
  }

  updateSendEmailsButton() {
    this.sendEmailsButton.disabled = this.selectedLimitRequests.length === 0;
  }

  onCancel() {
    this.limitTable.onUncheckAll();
    this.updateSendEmailsButton();
    this.serverErrors = undefined;
  }

  lastCoverStatus(limitRequest: LimitRequestDto): string {
    if (limitRequest && limitRequest.lastCover && limitRequest.lastCover.businessStatus) {
      return limitRequest.lastCover.businessStatus.name;
    }
    return null;
  }
}
