import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {SharedComponentsModule} from '../../../../bonding_shared/components/shared-components.module';
import {CompanySharedModule} from '../../../company/components/shared/company-shared.module';
import {ExternalProviderSelectorComponent} from './external-provider-selector.component';
import {TabViewModule} from 'primeng/tabview';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, CompanySharedModule, TabViewModule],
  declarations: [ExternalProviderSelectorComponent],
  exports: [ExternalProviderSelectorComponent],
})
export class ExternalProviderSharedModule {}
