import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {ClaimDetailsComponent} from './claim-details.component';
import {ClaimGuiService} from './services/claim-gui.service';
import {ClaimSearchComponent} from './claim-search.component';
import {PolicyModule} from '../policy/policy.module';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {ContractSharedModule} from '../bonding-contract/contract/components/shared/contract-shared.module';
import {BondSharedModule} from '../bond/components/shared/bond-shared.module';
import {ClaimSharedModule} from './components/shared/claim-shared.module';
import {RepositoryDocumentListModule} from '../../bonding_shared/components/repository-document-list/repository-document-list.module';
import {PolicyContractSharedModule} from '../policy/components/shared/policy-contract-shared.module';
import {CollectionSharedModule} from '../collection/components/shared/collection-shared.module';
import {PolicyLimitListSharedModule} from '../policy-limit-list/shared/policy-limit-list-shared.module';
import {ClaimDocumentsComponent} from './claim-documents.component';
import {CredendoClaimCalculationComponent} from './components/credendo-claim-calculation.component';
import {ClaimSelectorsComponent} from './components/claim-selectors.component';
import {ClaimCalculationComponent} from './claim-calculation.component';
import {ClaimIndemnificationComponent} from './claim-indemnification.component';
import {ClaimTreatOrderComponent} from './claim-treat-order.component';
import {PolicyClauseModule} from '../policy/clause/policy-clause.module';
import {ClaimCalculationDocumentsComponent} from './claim-calculation-documents.component';
import {ClaimRecoveriesDetailsComponent} from './claim-recoveries-details.component';
import {ClaimExternalProvidersComponent} from './claim-external-providers.component';
import {ExternalProviderSharedModule} from '../external-provider/components/shared/external-provider-shared.module';
import {DocumentModule} from '../document/document.module';
import {ClaimCalculationPayoutPreparationComponent} from './claim-calculation-payout-preparation.component';
import {ClaimLawsuitComponent} from './claim-lawsuit.component';
import {ClaimLinkedClaimsComponent} from './claim-linked-claims.component';
import {ClaimSurveyAnswerComponent} from './components/claim-survey-answer.component';
import {ClaimSurveyComponent} from './components/claim-survey.component';
import {KukeClaimAdditionalInformationComponent} from './components/kuke-claim-additional-information.component';
import {ClaimNavigationLinkComponent} from './components/shared/claim-navigation-link.component';
import {
  ClaimCalculationGuard,
  ClaimDetailsGuard,
  ClaimDocumentsGuard,
  ClaimIndemnificationGuard,
  ClaimLawsuitGuard,
  ClaimLinkedClaimsGuard,
  ClaimSearchGuard,
} from './_guards';
import {
  ClaimCalculationDocumentsGuard,
  ClaimCalculationPayoutPreparationGuard,
  ClaimExternalProvidersGuard,
  ClaimRecoveriesDetailsGuard,
  ClaimTreatOrderGuard,
} from './_emptyGuards';
import {MehibClaimCalculationComponent} from './components/mehib-claim-calculation.component';
import {ClientPolicySharedModule} from '../../portal/policy/shared/client-policy-shared.module';
import {CommonContractModule} from '../../portal/contract/common-contract.module';
import {CredendoClaimGeneralInformationComponent} from './components/credendo-claim-general-information.component';
import {KukeClaimGeneralInformationComponent} from './components/kuke-claim-general-information.component';
import {EcgClaimGeneralInformationComponent} from './components/ecg-claim-general-information.component';
import {ClaimSimplifiedCalculationComponent} from './claim-simplified-calculation.component';
import {KukeCompletenessCheckDirective} from './validators/kuke-completenessCheck.directive';
import {ClaimRecoveriesReservesComponent} from './components/claim-recoveries-reserves.component';
import {ClaimRepaymentScheduleComponent} from './claim-repayment-schedule.component';
import {ClaimRepaymentScheduleGuard} from './_emptyGuards/claim-repayment-schedule.guard';
import {ClaimValuesOrderPipe} from './components/shared/claim-values-order-pipe';
import {ClaimCommissionDetailsComponent} from './claim-commission-details.component';
import {ClaimRelatedPartiesComponent} from './components/claim-related-parties.component';
import {ClaimRelatedPartyComponent} from './components/claim-related-party.component';
import {EcgClaimAdditionalInformationComponent} from './components/ecg-claim-additional-information.component';
import {CompanyExtendedSelectorModule} from '../company/components/shared/extended-selectors/company-extended-selector.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    PolicyModule,
    ClaimSharedModule,
    RepositoryDocumentListModule,
    PolicyContractSharedModule,
    ContractSharedModule,
    BondSharedModule,
    CollectionSharedModule,
    PolicyClauseModule,
    PolicyLimitListSharedModule,
    ClientPolicySharedModule,
    ExternalProviderSharedModule,
    DocumentModule,
    CommonContractModule,
    CompanyExtendedSelectorModule,
  ],
  declarations: [
    ClaimDetailsComponent,
    ClaimSearchComponent,
    ClaimCommissionDetailsComponent,
    ClaimRecoveriesDetailsComponent,
    ClaimRecoveriesReservesComponent,
    ClaimDocumentsComponent,
    CredendoClaimCalculationComponent,
    ClaimSelectorsComponent,
    ClaimCalculationComponent,
    ClaimSimplifiedCalculationComponent,
    ClaimCalculationPayoutPreparationComponent,
    ClaimIndemnificationComponent,
    ClaimLawsuitComponent,
    ClaimLinkedClaimsComponent,
    ClaimNavigationLinkComponent,
    ClaimTreatOrderComponent,
    ClaimCalculationDocumentsComponent,
    ClaimExternalProvidersComponent,
    ClaimSurveyAnswerComponent,
    ClaimSurveyComponent,
    ClaimValuesOrderPipe,
    KukeClaimAdditionalInformationComponent,
    MehibClaimCalculationComponent,
    CredendoClaimGeneralInformationComponent,
    KukeClaimGeneralInformationComponent,
    EcgClaimAdditionalInformationComponent,
    EcgClaimGeneralInformationComponent,
    KukeCompletenessCheckDirective,
    ClaimRepaymentScheduleComponent,
    ClaimRelatedPartyComponent,
    ClaimRelatedPartiesComponent,
  ],
  providers: [
    ClaimGuiService,
    ClaimCalculationGuard,
    ClaimCalculationDocumentsGuard,
    ClaimCalculationPayoutPreparationGuard,
    ClaimDetailsGuard,
    ClaimDocumentsGuard,
    ClaimExternalProvidersGuard,
    ClaimIndemnificationGuard,
    ClaimLawsuitGuard,
    ClaimLinkedClaimsGuard,
    ClaimRecoveriesDetailsGuard,
    ClaimRepaymentScheduleGuard,
    ClaimSearchGuard,
    ClaimTreatOrderGuard,
  ],
})
export class ClaimModule {}
