import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LoggedUserService} from '../services';
import {ElementaryRight} from '../model/dictionary-ids';
import {Observable} from 'rxjs';

export abstract class AbstractGuard implements CanActivate {
  protected constructor(protected loggedUserService: LoggedUserService, protected router: Router) {}

  abstract canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean;

  protected checkLogin(url: string): boolean {
    if (this.loggedUserService.isLoggedIn()) {
      return true;
    }
    // Store the attempted URL for redirecting
    this.loggedUserService.redirectUrl = url;
    // Navigate to the login page with extras
    console.log('User not logged in. Redirect to login ..');
    this.router.navigate(['login']);
    return false;
  }

  protected checkRight(url: string, right: ElementaryRight | string): boolean {
    return this.checkLogin(url) && this.loggedUserService.hasRight(right);
  }

  protected checkAnyRight(url: string, rights: string[]): boolean {
    return this.checkLogin(url) && this.loggedUserService.hasAnyRight(rights);
  }
}
