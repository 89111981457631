import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {
  ProductLimitListForLimitDto,
  ProductLimitListVersionCriteriaDto,
  ProductLimitListVersionSimpleDto,
} from '../../../../bonding_shared/model/dtos';
import {Subject} from 'rxjs';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {PolicyLimitListService} from '../../../../bonding_shared/services';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table/a-table.component';
import {BackendError} from '../../../../bonding_shared/model';

@Component({
  selector: 'policy-limit-list-selector',
  templateUrl: './policy-limit-list-selector.component.pug',
})
export class PolicyLimitListSelectorComponent implements OnInit {
  @ViewChild(ATableComponent, {static: true}) atable: ATableComponent<ProductLimitListVersionSimpleDto>;
  /**
   *  flag on which depends whether or not links will be displayed
   *  by default the links are displayed
   */
  @Input() showLinks = true;

  @Input() label: string;

  // download limit list and emit it instead of limit list version
  @Input() emitParent = false;

  @Input() noResultWarningKey: string;

  /**
   * Open selector event emitter
   * The same emitter instance should be injected into this component and the component that opens this selector
   */
  @Input() open: Subject<boolean>;

  /**
   * when fixedSearch = true, search is done by injected, predefined criteria, user cannot modify them
   */
  @Input() fixedSearch = false;

  @Input() oneResultAutoSelection = false;

  /**
   * to be able to switch between advanced/basic search mode
   * TODO: advanced search mode not implemented yet
   */

  @Input() searchModeSwitchable = false;

  @Input() set criteria(c: ProductLimitListVersionCriteriaDto) {
    this.dataProvider.searchCriteria.criteria = c;
  }

  @Output() selectItem = new EventEmitter<ProductLimitListVersionSimpleDto>();
  @Output() selectItemParent = new EventEmitter<ProductLimitListForLimitDto>();

  @Output() backendError = new EventEmitter<BackendError>();

  dataProvider: SearchDataProvider<ProductLimitListVersionCriteriaDto, ProductLimitListVersionSimpleDto>;

  emptyResult = true;

  constructor(public router: RouterService, private service: PolicyLimitListService) {
    this.dataProvider = BusinessUtils.createPolicyLimitListDataProvider(service);
    this.dataProvider.textSearch = false;
  }

  ngOnInit() {
    if (this.oneResultAutoSelection) {
      this.atable.dataProviderSearchFinished.subscribe((result) => {
        if (result.size < 1) {
          this.emptyResult = true;
        }
        if (result.size === 1) {
          this.onSelectList(result.result[0]);
        }
      });
    }
  }

  onSelectList(plv: ProductLimitListVersionSimpleDto) {
    if (this.emitParent) {
      this.service.getListForLimit(plv.limitList.id).subscribe(
        (pl) => {
          this.selectItemParent.emit(pl);
        },
        (error) => this.backendError.emit(error)
      );
    } else {
      this.selectItem.emit(plv);
    }
    this.open.next(false);
  }
}
