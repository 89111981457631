import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  PortalDeclarationService,
  PortalPolicyContractService,
  PortalPolicyContractVersionService,
  PortalRouterService,
} from '../services';
import {TranslateService} from '@ngx-translate/core';
import {
  AppConfigService,
  DeclarationFileService,
  FeatureService,
  FormatService,
  GrowlService,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared/services';
import {DeclarationBuyerCriteriaDto, Page} from '../../bonding_shared/model';
import {ConfirmDialogComponent} from '../../bonding_shared/components/confirm-dialog';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import * as moment from 'moment';
import {
  DeclarationCriteria,
  DeclarationDetailsView,
  DeclarationParams,
} from '../../bonding/declaration/shared/declaration-details-view';
import {DeclarationsSaveUpdateOptions} from '../../bonding/declaration/shared/DeclarationsSaveUpdateOptions';
import {NgForm} from '@angular/forms';
import {BusinessReportPortalService} from '../services/portal-business-report.service';
import {Feature, PolicyElementaryRight} from '../../bonding_shared/model/dictionary-ids';
import {BusinessPropertiesService} from '../../bonding_shared';

@Component({
  selector: 'client-turnover-details',
  templateUrl: './client-turnover-details.component.pug',
})
export class ClientTurnoverDetailsComponent extends DeclarationDetailsView implements OnInit {
  readonly PolicyElementaryRight = PolicyElementaryRight;
  @ViewChild('saveDialog', {static: true}) saveDialog: ConfirmDialogComponent;
  @ViewChild('zeroDialog', {static: true}) zeroDialog: ConfirmDialogComponent;
  @ViewChild('k12Dialog', {static: true}) k12Dialog: ConfirmDialogComponent;
  @ViewChild('cleanDialog', {static: true}) cleanDialog: ConfirmDialogComponent;
  @ViewChild('mehibPaymentTermDaysDialog', {static: true}) mehibPaymentTermDaysDialog: ConfirmDialogComponent;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  showLimitExport = false;
  canPortalUserDeclareAmendment = false;

  constructor(
    private route: ActivatedRoute,
    private routerService: PortalRouterService,
    public appService: AppConfigService,
    private portalPolicyService: PortalPolicyContractVersionService,
    public policyContractService: PortalPolicyContractService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    private router: RouterService,
    protected portalDeclarationService: PortalDeclarationService,
    protected declarationFileService: DeclarationFileService,
    protected loggedUserService: LoggedUserService,
    private businessReportPortalService: BusinessReportPortalService,
    protected formatService: FormatService,
    protected businessPropertiesService: BusinessPropertiesService,
    protected featureService: FeatureService
  ) {
    super(
      translateService,
      growlService,
      portalDeclarationService,
      loggedUserService,
      businessReportPortalService,
      appService,
      businessPropertiesService,
      formatService,
      declarationFileService
    );
    this.canPortalUserDeclareAmendment = featureService.get(Feature.DECLARATION_CAN_PORTAL_USER_DECLARE_AMENDMENT);
    this.showLimitExport = featureService.get(Feature.DECLARATION_EXPORT_LIMITS_IN_PORTAL);
  }

  // ############## DECLARATION INITIALIZATION ##############
  ngOnInit() {
    this.form = this.ngForm.form;
    if (this.appService.kuke || this.appService.ecg) {
      this.dc = <DeclarationCriteria>{buyerCriteria: <DeclarationBuyerCriteriaDto>{}};
      this.clearEntryValuesMap();
      this.route.params.subscribe((params) => {
        const dcParams: DeclarationParams = <DeclarationParams>{
          paramRpId: +params['reportingPeriodId'],
          paramLlvId: +params['limitListVersionId'],
          paramDecOnId: +params['declaredOnId'],
        };
        if (dcParams.paramRpId) {
          this.onInitDeclarationView(dcParams);
        } else {
          this.onInitDeclarationView();
        }
      });
    }
  }

  private onInitDeclarationView(dcParams?: DeclarationParams) {
    this.amendmentView = false;
    this.dcComponent.initByPortal(dcParams);
  }

  // ############## EVENTS HANDLING ##############
  onPolicyChanged() {
    // do nothing
  }

  onDeclarationCriteriaTouchedEvent() {
    this.inProgress_flag = true;
    this.clearDeclaration();
  }

  onDeclarationCriteriaChangedEvent(reloadDeclaration: boolean) {
    if (reloadDeclaration) {
      if (this.dc.selectedRp && this.dc.selectedLlv && this.dc.selectedDecOn) {
        this.resetSearchCriteriaWithFirstPage(this.dc);
        this.initOrFindExistingDeclarationBySearchCriteria(true, false);
      } else {
        this.setDeclarationUndefined();
      }
    }
    this.applyFiltersOnDeclarationHistory();
  }

  onFilteringChanged() {
    this.resetSearchCriteriaWithFirstPage(this.dc);
    if (this.canUpdate()) {
      this.inProgress_flag = true;
      this.onFilteringChangedInnerDeclaration();
    }
  }

  // ############## SAVE/UPDATE ACTIONS ##############

  public saveAction() {
    if (!this.canUpdate() || this.amendment || !this.formValidates()) {
      return;
    }
    const finalStep = this.resolveFunction(DeclarationsSaveUpdateOptions.SAVE_DECLARATION);

    const thirdCondition: boolean = this.appService.mehib && this.withWarningDialog();
    const thirdDialog: Function = this.openMehibPaymentTermDaysDialog.bind(
      this,
      DeclarationsSaveUpdateOptions.SAVE_DECLARATION
    );
    const thirdStep: Function = this.conditionalDialog(thirdCondition, thirdDialog, finalStep);

    const secondStep: Function = this.openSaveDialog.bind(this, thirdStep);

    const firstCondition: boolean = this.declaration && this.declaration.id && true;
    const firstDialog: Function = this.openK12Dialog.bind(this, secondStep);
    const firstStep: Function = this.conditionalDialog(firstCondition, firstDialog, secondStep);

    return firstStep();
  }

  public confirmAction() {
    if (!this.canUpdate() || this.amendment || !this.formValidates()) {
      return;
    }
    const finalStep = this.resolveFunction(DeclarationsSaveUpdateOptions.CONFIRM_DECLARATION);

    const secondCondition: boolean = this.appService.mehib && this.withWarningDialog();
    const secondDialog: Function = this.openMehibPaymentTermDaysDialog.bind(
      this,
      DeclarationsSaveUpdateOptions.CONFIRM_DECLARATION
    );
    const secondStep: Function = this.conditionalDialog(secondCondition, secondDialog, finalStep);

    const firstStep: Function = this.openSaveDialog.bind(this, secondStep);
    return firstStep();
  }

  public saveZeroAction() {
    if (!this.canUpdate() || this.amendment || !this.formValidates()) {
      return;
    }
    const finalStep = this.resolveFunction(DeclarationsSaveUpdateOptions.SAVE_ZERO_DECLARATION);

    const secondCondition: boolean = this.appService.mehib && this.withWarningDialog();
    const secondDialog: Function = this.openMehibPaymentTermDaysDialog.bind(
      this,
      DeclarationsSaveUpdateOptions.SAVE_ZERO_DECLARATION
    );
    const secondStep: Function = this.conditionalDialog(secondCondition, secondDialog, finalStep);

    const firstStep: Function = this.openZeroDeclarationDialog.bind(this, secondStep);
    return firstStep();
  }

  public cleanAction() {
    if (this.declaration && this.canUpdate() && !this.amendment) {
      this.cleanDialog.openAndExecuteOnConfirm(
        'declaration.details.cleanDialog.title',
        'declaration.details.cleanDialog.message',
        () => {
          this.clearErrors();
          this.inProgress_flag = true;
          this.portalDeclarationService.cleanDeclaration(this.declaration.id).subscribe(
            (dec) => {
              this.dcComponent.clearSelections();
              this.clearDeclaration();
              this.inProgress_flag = false;
            },
            (error) => {
              this.serverErrors = error;
              this.inProgress_flag = false;
            }
          );
        },
        undefined,
        undefined
      );
    }
  }

  private resolveFunction(option: DeclarationsSaveUpdateOptions): Function {
    switch (option) {
      case DeclarationsSaveUpdateOptions.SAVE_DECLARATION:
        return this.saveDeclaration.bind(this);
      case DeclarationsSaveUpdateOptions.CONFIRM_DECLARATION:
        return this.confirmDeclaration.bind(this);
      case DeclarationsSaveUpdateOptions.SAVE_ZERO_DECLARATION:
        return this.saveZeroDeclaration.bind(this);
    }
  }

  // ############## SAVE DIALOGS ##############

  private openK12Dialog(nextStep: Function) {
    return this.k12Dialog.openAndExecuteOnConfirm(
      'declaration.details.confirmDialog.title',
      'declaration.criteria.k12message',
      nextStep,
      undefined,
      undefined
    );
  }

  private openSaveDialog(nextStep: Function) {
    const msgParams = {};
    msgParams['systemCurrencyCode'] = this.systemCurrency.code;
    this.saveDialog.openAndExecuteOnConfirm(
      'declaration.details.confirmDialog.title',
      'declaration.details.confirmDialog.msgTurnoversPortal',
      nextStep,
      undefined,
      msgParams
    );
  }

  private openMehibPaymentTermDaysDialog(option: DeclarationsSaveUpdateOptions) {
    if (option === DeclarationsSaveUpdateOptions.SAVE_DECLARATION) {
      return this.mehibPaymentTermDaysDialog.openAndExecuteOnConfirm(
        'declaration.details.paymentTerDaysDialog.saveDeclarationTitle',
        'declaration.details.paymentTerDaysDialog.msg',
        this.saveDeclaration.bind(this),
        undefined,
        undefined
      );
    }
    if (option === DeclarationsSaveUpdateOptions.CONFIRM_DECLARATION) {
      return this.mehibPaymentTermDaysDialog.openAndExecuteOnConfirm(
        'declaration.details.paymentTerDaysDialog.confirmTitle',
        'declaration.details.paymentTerDaysDialog.msg',
        this.confirmDeclaration.bind(this),
        undefined,
        undefined
      );
    }
    if (option === DeclarationsSaveUpdateOptions.SAVE_ZERO_DECLARATION) {
      return this.mehibPaymentTermDaysDialog.openAndExecuteOnConfirm(
        'declaration.details.paymentTerDaysDialog.saveZeroTitle',
        'declaration.details.paymentTerDaysDialog.msg',
        this.saveZeroDeclaration.bind(this),
        undefined,
        undefined
      );
    }
  }

  private openZeroDeclarationDialog(nextStep: Function) {
    this.zeroDialog.openAndExecuteOnConfirm(
      'declaration.details.zeroTurnoverDialog.title',
      'declaration.details.zeroTurnoverDialog.msg',
      nextStep,
      undefined,
      undefined
    );
  }

  // ############## SAVE OR UPDATE ##############
  saveDeclaration() {
    if (this.declaration && this.canUpdate() && !this.amendment) {
      this.inProgress_flag = true;
      this.clearErrors();
      this.portalDeclarationService.saveDeclarationStub(this.declarationToStub()).subscribe(
        (decSimple) => {
          this.edition_flag = false;
          this.searchCriteria.criteria.basedOnDeclarationId = decSimple.id;
          this.clearBuyerFilter();
          this.initOrFindExistingDeclarationBySearchCriteria(true);
          if (decSimple.effectiveDate) {
            this.translateService
              .get('declaration.details.declarationSavedPortal', {
                effectiveDate: decSimple.effectiveDate.toLocaleDateString(),
              })
              .subscribe((transMsg) => this.showSavedMsg(transMsg));
          } else {
            this.showSavedMsg('declaration.details.declarationSavedIntranet');
          }
        },
        (error) => {
          this.serverErrors = error;
          this.inProgress_flag = false;
        }
      );
    }
  }

  saveZeroDeclaration() {
    if (this.declaration && this.canUpdate() && !this.amendment) {
      this.inProgress_flag = true;
      this.clearEntryValuesMap();
      this.clearErrors();
      this.portalDeclarationService.saveZeroDeclarationStub(this.declarationToStub()).subscribe(
        (decSimple) => {
          this.edition_flag = false;
          this.searchCriteria.criteria.basedOnDeclarationId = decSimple.id;
          this.clearBuyerFilter();
          this.initOrFindExistingDeclarationBySearchCriteria(true);
          if (decSimple.effectiveDate) {
            this.translateService
              .get('declaration.details.declarationSavedPortal', {
                effectiveDate: decSimple.effectiveDate.toLocaleDateString(),
              })
              .subscribe((transMsg) => this.showSavedMsg(transMsg));
          } else {
            this.showSavedMsg('declaration.details.declarationSavedIntranet');
          }
        },
        (error) => {
          this.serverErrors = error;
          this.inProgress_flag = false;
        }
      );
    }
  }

  confirmDeclaration() {
    if (this.declaration && this.canUpdate() && !this.amendment) {
      this.inProgress_flag = true;
      this.clearErrors();
      this.portalDeclarationService.confirmDeclarationStub(this.declarationToStub()).subscribe(
        (decSimple) => {
          this.edition_flag = false;
          this.clearBuyerFilter();
          const dcParams: DeclarationParams = <DeclarationParams>{
            paramRpId: this.dc.selectedRp.id,
            paramLlvId: this.dc.selectedLlv.id,
            paramDecOnId: this.dc.selectedDecOn !== undefined ? this.dc.selectedDecOn.id : undefined,
          };
          this.dcComponent.onSelectedPolicyByParams(dcParams);
          this.showSavedMsg('declaration.details.declarationConfirmed');
          this.showK8Dialog();
        },
        (error) => {
          this.serverErrors = error;
          this.inProgress_flag = false;
        }
      );
    }
  }

  // ############## GUI CONTROL ##############
  updateCanUpdateFlag() {
    if (this.declaration) {
      const now = new Date();
      this.canUpdate_flag =
        moment(this.declaration.reportingPeriod.periodTo).endOf('day').isBefore(now) &&
        (!this.declaration.effectiveDate || moment(this.declaration.effectiveDate).isAfter(now)) &&
        moment(this.declaration.reportingPeriod.reportingTo).endOf('day').isAfter(now);
    } else {
      this.canUpdate_flag = false;
    }
  }

  onPageChange(event: PageChangedEvent) {
    this.inProgress_flag = true;
    this.resetPage(<Page>{start: (event.page - 1) * event.itemsPerPage, count: event.itemsPerPage});
    this.initOrFindExistingDeclarationBySearchCriteria(false);
  }

  // ############## CANCELING ##############
  cancelEdition() {
    this.inProgress_flag = true;
    this.showErrors = false;
    this.edition_flag = false;
    this.clearBuyerFilter();
    this.initOrFindExistingDeclarationBySearchCriteria(undefined);
  }
}
