import {Component, Input} from '@angular/core';
import {
  CompanySimpleDto,
  DictionaryBaseDto,
  PolicyInquiryCompanyDto,
  PolicyInquiryVersionDto,
  RouterService,
  TemplateBaseDto,
  TemplateService,
} from '../../../bonding_shared';
import {Subject} from 'rxjs';
import {fromInqCompanyToCompany} from '../domain/inquiry-subinsureds';
import {CustomButton, CustomButtonEvent} from '../../../bonding_shared/components/aku-table/table.component';
import {BusinessObjectType, DocumentType} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'subinsureds-list',
  templateUrl: 'inquiry-subinsureds-list.component.pug',
})
export class InquirySubinsuredsListComponent {
  self = this;

  private _inquiry: PolicyInquiryVersionDto;

  @Input() set inquiry(inquiry: PolicyInquiryVersionDto) {
    this._inquiry = inquiry;
    this.loadTemplates();
  }
  get inquiry() {
    return this._inquiry;
  }

  @Input()
  set items(inqCompanies: PolicyInquiryCompanyDto[]) {
    this._inqCompanies = inqCompanies;
    this.fromInqCompaniesToTableItems();
  }

  @Input()
  addButtonEventEmitter: Subject<boolean> = null;

  get tableItems() {
    return this._items;
  }

  private _items: TableItem[];
  private _inqCompanies: PolicyInquiryCompanyDto[];
  customButtons: CustomButton[];

  constructor(public router: RouterService, private templateService: TemplateService) {}

  deleteItem(ti: TableItem) {
    if (!ti) {
      return;
    }
    const index = this._inqCompanies.indexOf(ti.inqCompany);
    if (index > -1) {
      this._inqCompanies.splice(index, 1);
    }

    this.fromInqCompaniesToTableItems();
  }

  private fromInqCompaniesToTableItems() {
    this._items = this._inqCompanies.map((ic) => this.fromInqCompanyToTableItem(ic));
  }

  private fromInqCompanyToTableItem(inqCompany: PolicyInquiryCompanyDto): TableItem {
    return {company: fromInqCompanyToCompany(inqCompany), inqCompany: inqCompany};
  }

  linkClick(ti: TableItem) {
    if (!ti) {
      return;
    }

    if (ti.company && ti.company.id) {
      this.router.toCompanyPreview(ti.company.id);
    } else if (ti.inqCompany && ti.inqCompany.thirdParty && ti.inqCompany.thirdParty.id) {
      this.router.toThirdPartyDetails(ti.inqCompany.thirdParty.id);
    } else {
      console.warn('Not found id to dails view: ', ti);
    }
  }

  initializeDocumentsButton(templates: TemplateBaseDto[]) {
    const documentsButton = <CustomButton>{
      name: 'Draft oświadczeń',
      title: 'Draft oświadczeń',
      class: 'bon-btn-info',
      icon: 'fa-file-text-o',
      alignment: 'right',
      dropdownItems: templates,
    };
    this.customButtons = [documentsButton];
  }

  loadTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    if (this.inquiry && this.inquiry.policyInquiry.productType) {
      dictionarySelectors.push(this.inquiry.policyInquiry.productType);
    }
    this.templateService
      .findByType(DocumentType.DEC_AGREE_SUBIN, BusinessObjectType.POLICY_INQUIRY_VERSION, null, dictionarySelectors)
      .subscribe((templates) => {
        console.log('TEMPLATES for offer statement result length = ' + templates.length);
        if (templates.length > 0) {
          this.initializeDocumentsButton(templates);
        }
      });
  }

  toDocumentView(event: CustomButtonEvent<PolicyInquiryCompanyDto>) {
    this.router.toDocumentDetailsNewWithAdditionalValues(
      event.dropdownItem.id,
      this.inquiry.id,
      BusinessObjectType.COMPANY,
      event.item.company.id
    );
  }
}

interface TableItem {
  company: CompanySimpleDto;
  inqCompany: PolicyInquiryCompanyDto;
}
