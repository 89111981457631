import {DictionaryBaseDto, ErrorReason} from '../../model/dtos';
import {ElementaryRight, FinancialDocumentCategory, FINoteCategory, InvoiceCategory} from '../../model/dictionary-ids';
import {DictionaryBaseService, InvoiceCoreService, LoggedUserService} from '../../services';
import {ActivatedRoute, Params} from '@angular/router';
import {Directive, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InvoiceUtils} from '../../utils/invoice-utils';
import {SearchViewComponent} from '../search';

/**
 * Common logic for Invoice Category solution
 */

@Directive()
export abstract class AbstractInvoiceComponent implements OnInit {
  categoryId: FinancialDocumentCategory;
  invoiceCategoryMap: {[index: string]: DictionaryBaseDto} = {};
  public exportInProgress = false;

  protected constructor(
    protected dictionaryBaseService: DictionaryBaseService,
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected invoiceCoreService: InvoiceCoreService,
    protected loggedUserService: LoggedUserService
  ) {}

  get category(): string {
    return InvoiceCategory[this.categoryId] || FINoteCategory[this.categoryId];
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
    this.loadInvoiceCategories();
  }

  get invoiceStatusRegexp() {
    return InvoiceUtils.getInvoiceStatusRegexp(this.categoryId);
  }

  get invoiceItemStatusRegexp() {
    return InvoiceUtils.getInvoiceItemStatusRegexp(this.categoryId);
  }

  get newDisabled() {
    return !this.loggedUserService.hasRight(ElementaryRight.INVOICE_CREATE_UPDATE);
  }

  get writeAllOffDisabled() {
    return !this.loggedUserService.hasRight(ElementaryRight.INVOICE_MASS_WRITEALLOFF);
  }

  get massExportDisabled() {
    if (this.categoryId === InvoiceCategory.BROKER) {
      return !this.loggedUserService.hasRight(ElementaryRight.INTERMEDIARY_INVOICE_MASS_EXPORT);
    } else {
      return !this.loggedUserService.hasRight(ElementaryRight.CLIENT_INVOICE_MASS_EXPORT);
    }
  }

  protected abstract getSearchView(): SearchViewComponent<any, any>;

  protected abstract initializeView(params: Params): void;

  protected loadInvoiceCategories() {
    this.dictionaryBaseService.getDictionaryBase('FinancialDocumentCategory').subscribe(
      (invoiceCategories) => {
        for (const invoiceCategory of invoiceCategories) {
          this.invoiceCategoryMap[invoiceCategory.id] = invoiceCategory;
        }
      },
      (error) => {
        console.log('loadInvoiceCategories error: ', error);
      }
    );
  }

  protected getCategoryTranslationKey(prefix: string): string {
    return prefix + '.' + (InvoiceCategory[this.categoryId] || FINoteCategory[this.categoryId]);
  }

  protected exportCompleteCallback(): void {
    console.log('exportCompleteCallback');
    this.getSearchView().serverErrors = undefined;
    this.exportInProgress = false;
  }

  protected downloadErrorCallback(err: string): void {
    const error = <ErrorReason[]>JSON.parse(err);
    console.log('Invoice item export error', error);
    this.getSearchView().serverErrors = error;
    this.exportInProgress = false;
  }
}
