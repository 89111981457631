import {Component} from '@angular/core';
import {CrPolicyProduct} from './cr-policy-product';
import {ControlContainer, NgForm} from '@angular/forms';
import {PolicyContractVersionService} from '../../../../../bonding_shared/services';

@Component({
  selector: 'excess-of-loss-product',
  templateUrl: './excess-of-loss-product.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class ExcessOfLossProductComponent extends CrPolicyProduct {
  constructor(policyContractVersionService: PolicyContractVersionService) {
    super(policyContractVersionService);
  }
}
