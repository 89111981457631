import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FormDialogParent} from '../../../bonding_shared/components/form-dialog/FormDialogParent';
import {FormDialogComponent} from '../../../bonding_shared/components/form-dialog';
import {LimitRequestReductionDto} from '../../../bonding_shared/model';

@Component({
  selector: 'limit-reduction-dialog',
  templateUrl: './limit-reduction-dialog.component.pug',
})
export class LimitReductionDialogComponent extends FormDialogParent implements AfterViewInit {
  @ViewChild(FormDialogComponent, {static: true}) formDialogComponent: FormDialogComponent;
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;

  @Input() currencyCode: string;
  @Input() maxAmount: number;

  @Output() saveReductionEmitter = new EventEmitter<LimitRequestReductionDto>();

  reductionForRequest = <LimitRequestReductionDto>{};

  public ngAfterViewInit(): void {
    this.form = this.ngForm.form;
  }

  openDialog() {
    this.reductionForRequest = <LimitRequestReductionDto>{};
    this.formDialogComponent.openAndExecuteOnConfirm(() => this.saveReduction());
  }

  saveReduction() {
    console.log('save limit reduction:', this.reductionForRequest);
    this.saveReductionEmitter.emit(this.reductionForRequest);
  }
}
