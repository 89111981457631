import {Injectable} from '@angular/core';
import {AppConfigService} from '../../bonding_shared/services/app-config.service';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {HttpClient} from '@angular/common/http';
import {CompanyAbstractService} from '../../bonding_shared/services/company-abstract.service';
import {Observable} from 'rxjs';

@Injectable()
export class PortalCompanyService extends CompanyAbstractService {
  protected url = this.urlPrefix + 'portal/company';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getBrokerContractCompanyType(contractVersion: number): Observable<number> {
    return this.get<number>(this.url + '/brokerCompanyType/' + contractVersion);
  }

  getBrokerWebPage(contractVersion: number): Observable<string> {
    return this.get<string>(this.url + '/brokerWebPage/' + contractVersion);
  }
}
