import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const STATEMENT_ROW_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StatementRowComponent),
  multi: true,
};

@Component({
  selector: 'statement-row',
  templateUrl: 'statement-row.component.html',
  styles: [],
  providers: [STATEMENT_ROW_CONTROL_VALUE_ACCESSOR],
})
export class StatementRowComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() labelKey: string;

  @Input() presentationMode = false;
  @Input() required = false;

  @Output() changeState = new EventEmitter<any>();
  @Input() control: AbstractControl;
  @Input() showErrors: boolean;

  value: boolean;
  private onChangeListeners: Function;
  onTouchedListeners: Function;

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onChangeItem(event: any) {
    this.value = !!event.target.checked;
    this.onChangeListeners(this.value);
    this.changeState.emit(this.value);
  }
}
