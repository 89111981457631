import {Component} from '@angular/core';
import {BlockchainService} from './services/blockchain.service';

declare var TMiningWallet: any;

@Component({
  selector: 'tmining-test',
  templateUrl: './blockchain-test.component.pug',
})
export class BlockchainTestComponent {
  constructor(private walletService: BlockchainService) {}

  public reference: string;
  public response: string;

  getTokenAndCallWallet(func: Function, arg?: any) {
    this.walletService.getJWT().subscribe(
      (jwt) => (arg ? func(jwt, arg) : func(jwt)),
      (error) => console.log(error)
    );
  }

  requestActivation(token: string) {
    console.log('calling requestActivation with token: ', token);
    TMiningWallet.API.requestActivation(token).then((v) => {
      console.log('response: ', v);
      this.response += JSON.stringify(v) + '\n';
    });
  }

  confirmActivation(token: string) {
    console.log('calling confirmActivation with token: ', token);
    TMiningWallet.API.confirmActivation(token).then((v) => {
      console.log('confirmActivationResponse: ', v);
      this.response += JSON.stringify(v) + '\n';
    });
  }

  getAddressAndKeyForReference(token: string, reference: string) {
    console.log('calling getAddressAndKeyForReference with token: ', token);
    TMiningWallet.API.getAddressAndKeyForReference(token, reference).then((v) => {
      console.log('getAddressAndKeyForReference: ', v);
      this.response += JSON.stringify(v) + '\n';
    });
  }
}
