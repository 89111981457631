<a-table [items]="cessionVersions" [selection]="false">
  <column property="id" title="common.id" [link]="true" (linkClick)="toCessionVersionPreview($event)"></column>
  <column property="cession.type.name" title="policy.shared.cessionList.type" sort="cession.type.sortOrder"></column>
  <column property="validFrom" title="policy.shared.cessionList.validFrom" type="date"></column>
  <column property="validTo" title="policy.shared.cessionList.validTo" type="date"></column>
  <column property="businessStatus" title="common.status" type="dictionary" dictionary="CessionStatus"></column>
  <column property="cession.assignee.registrationName" title="policy.shared.cessionList.assignee" sort="cession.assignee.registrationName"></column>
  <column property="iban" title="common.iban" sort="iban"></column>
  <column property="bic" title="common.bic" sort="bic"></column>
</a-table>
