import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {CustomValidators} from '../../validators';

@Directive({
  selector: '[validFromNotAfterValidTo]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidFromNotAfterValidToDirective, multi: true}],
})
export class ValidFromNotAfterValidToDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return CustomValidators.validFromNotAfterValidTo(control);
  }
}
