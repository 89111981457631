import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {InvoiceListComponent} from './invoice-list.component';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule, CollapseModule],
  declarations: [InvoiceListComponent],
  exports: [InvoiceListComponent],
})
export class InvoiceSharedModule {}
