import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {BusinessObjectDto, ContractVersionDto, LegalVersionDto} from '../model/dtos';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable()
export class BusinessObjectService extends AbstractService {
  protected url = this.urlPrefix + 'businessObject';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getRelatedContractVersion(relatedToTypeId: number, id: number): Observable<LegalVersionDto> {
    return this.get<ContractVersionDto>(
      this.url + '/relatedContractVersion?relatedToTypeId=' + relatedToTypeId + '&id=' + id
    );
  }

  getRelatedBusinessObjects(
    relatedToTypeId: number,
    clientId: number,
    searchPhrase: string,
    limitResults?: boolean
  ): Observable<BusinessObjectDto[]> {
    let params = new HttpParams();
    if (relatedToTypeId) {
      params = params.append('relatedToTypeId', relatedToTypeId);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (searchPhrase) {
      params = params.append('searchPhrase', searchPhrase);
    }
    if (limitResults) {
      params = params.append('limitResults', limitResults);
    }
    return this.get<BusinessObjectDto[]>(this.url + '/getRelatedBusinessObjects', params);
  }

  getDto(relatedToTypeId: number, id: number): Observable<BusinessObjectDto> {
    return this.get<BusinessObjectDto>(this.url + '/' + relatedToTypeId + '/' + id);
  }
}
