<a-table [pagination]="pagination" [dataProvider]="searchDataProvider" [selection]="selection" [canUnselect]="selection"
         (selectItem)="onSelectItem($event)" (dataProviderSearchFinished)="searchFinished($event)" [rowHidden]="isHidden.bind(this)">
  <column *ngIf="showPack" labelKey="documentList.packId" property="pack.id"></column>
  <column *ngIf="showPack" labelKey="documentList.packType" property="pack.type" dictionary="DocumentPackType"></column>
  <column *ngIf="showPack" labelKey="documentList.packCreationDate" property="pack.creationDate" type="datetime"></column>
  <column labelKey="documentList.type" property="type" sort="type.name" dictionary="GeneratedDocumentType"
          (linkClick)="gotoDocumentDetail($event)" [link]="isDocumentLinkFunc()" *ngIf="!hiddenColumns.includes('type')"></column>
  <column labelKey="documentList.templateName"  property="template.name" sort="template.name" *ngIf="!hiddenColumns.includes('templateName')"></column>
  <column labelKey="documentList.creationDate" property="creationDate" sort="creationDate" type="datetime" initialSort="down"
          *ngIf="!hiddenColumns.includes('creationDate')"></column>
  <column labelKey="documentList.createdBy" sort="createdBy.familyName" *ngIf="!hiddenColumns.includes('createdBy')">
    <ng-template let-item="item">
      {{item.createdBy | username}}
    </ng-template>
  </column>
  <column labelKey="documentList.relatedTo" property="businessObject.description" *ngIf="!hiddenColumns.includes('relatedTo')"></column>
  <column [labelKey]="companyLabelKey" property="company.registrationName" *ngIf="!hiddenColumns.includes('registrationName')" >
    <ng-template let-item="item" let-edition="false">{{item?.company?.shortName && showShortNameIfExists ? item?.company?.shortName : item?.company?.registrationName}}</ng-template>
  </column>
  <column labelKey="documentList.lastSentDate" property="lastSentDate" sort="lastSentDate" type="datetime" initialSort="down"
          *ngIf="!hiddenColumns.includes('lastSentDate')"></column>
  <column labelKey="documentList.lastSentTo" property="lastSentTo" sort="lastSentTo" *ngIf="!hiddenColumns.includes('lastSentTo')"></column>
  <column property="status" dictionary="DocumentStatus" dictLabel="name" labelKey="common.status" *ngIf="!hiddenColumns.includes('status')"></column>
  <column *ngIf="credendo" title="Copy for" property="copyType" dictionary="RepoDocumentCategory" dictLabel="name"></column>
  <column labelKey="documentList.documentContent" *ngIf="!hiddenColumns.includes('documentContent')">
    <ng-template let-item="item">
      <a (click)="getBinaryContentUrl(item)" class="a-link" target="_blank">{{item.id}}.{{item.extension || 'pdf'}}</a>
    </ng-template>
  </column>
  <column *ngIf="kuke && selectMain " labelKey="documentList.repoStatus">
    <ng-template let-item="item">
      <span>{{(item.process ? item.process.status.name : '')}}</span>
    </ng-template>
  </column>
  <column *ngIf="kuke && selectMain" labelKey="documentList.main" property="main">
    <ng-template let-item="item">
      <span>{{(item.main ? 'common.yes' : 'common.no') | translate}}</span>
    </ng-template>
  </column>
  <column *ngIf="credendo && showCertificate" title="Certificate" cellWidth="10%">
    <ng-template let-item="item">
      <a *ngIf="item.certLink" href="{{item.certLink}}" target="_blank" class="btn-sm bon-btn-info">
        <i class="fa fa-file-text-o"></i>
        Open
      </a>
      <button *ngIf="!item.certLink" type="button" class="btn-sm bon-btn-cancel" btnIcon="fa-file-text-o">No cert</button>
    </ng-template>
  </column>
</a-table>
