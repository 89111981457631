import {Component, Input} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {
  AppConfigService,
  CompanyService,
  DictionaryService,
  RouterService,
} from '../../../../../bonding_shared/services';
import {DictionaryBaseDto, PolicyClauseDto, PolicyContractVersionDto} from '../../../../../bonding_shared/model';
import {StringUtils} from '../../../../../bonding_shared/utils';
import {PolicyClauseCategory} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'kuke-individual-clause-section',
  templateUrl: './kuke-individual-clause-section.component.pug',
})
export class KukeIndividualClauseSectionComponent extends Section {
  individualClauses: PolicyClauseDto[];
  selectedClause: PolicyClauseDto;
  UNKNOWN_CLAUSE = 99;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected router: RouterService,
    protected appService: AppConfigService,
    protected dictionaryService: DictionaryService,
    protected companyService: CompanyService
  ) {
    super(policyContractViewService, appService);
  }

  @Input()
  set policy(pol: PolicyContractVersionDto) {
    this.individualClauses = pol.clauses.filter((cl) => cl.category.id === PolicyClauseCategory.ADDITIONAL);
  }

  shortCode(d: DictionaryBaseDto): string {
    return StringUtils.shortCode(d.code);
  }
}
