import {Subject} from 'rxjs';

export class ListEmitters {
  /**
   * The list of selector names should be initialized in the component that extends this class.
   * That component should also call initializeSelectorEmitters() function to initialize emitters with the given names.
   */
  selectorNameList: string[] = [];

  /**
   * Map of selector emitters. It is initialized automatically by the initializeSelectorEmitters function based on selectorNameList
   */
  openSelectorEmitters: {[key: string]: Subject<boolean>} = {};

  initializeSelectorEmitters(closeOtherSelectorsOnOpen: boolean) {
    this.selectorNameList.forEach((selectorName) => {
      this.openSelectorEmitters[selectorName] = new Subject<boolean>();

      if (closeOtherSelectorsOnOpen) {
        this.openSelectorEmitters[selectorName].subscribe((open: boolean) => {
          if (open) {
            // if  one selector is open other selector emitters emit close event
            this.selectorNameList
              .filter((sn) => selectorName !== sn)
              .forEach((sn) => this.openSelectorEmitters[sn].next(false));
          }
        });
      }
    });
  }

  openSelector(selectorName: string) {
    // timeout is set to delay selector open until its parameters are injected by angular
    setTimeout(() => this.openSelectorEmitters[selectorName].next(true), 0);
  }

  closeSelector(selectorName: string) {
    this.openSelectorEmitters[selectorName].next(false);
  }

  initializeSelectorEmittersByNames(closeOtherSelectorsOnOpen: boolean, selectorNameList: string[]) {
    this.selectorNameList = selectorNameList;
    this.initializeSelectorEmitters(closeOtherSelectorsOnOpen);
  }

  closeAllSelectors() {
    this.selectorNameList.forEach((listName) => this.closeSelector(listName));
  }
}
