import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ClauseDto, DictionaryBaseDto} from '../../../bonding_shared/model';
import {BondingContractInquiryService} from '../../../bonding_shared/services/bonding-contract-inquiry.service';
import {DictionaryService} from '../../../bonding_shared/services';
import {CustomFormComponent} from '../../../common/custom-form/custom-form.component';
import {NumberUtils} from '../../../bonding_shared/utils/number-utils';
import {BusinessObjectType} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'bonding-contract-collaterals',
  templateUrl: './bonding-contract-collaterals.pug',
})
export class BondingContractCollateralsComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() showErrors = false;
  @Input() collaterals: ClauseDto[];
  @Input() tabView = true;
  @Input() dropup = false;
  @Input() readOnly = false;
  @Input() businessObjectType: BusinessObjectType;

  collateralTypes: DictionaryBaseDto[];

  constructor(private inquiryService: BondingContractInquiryService, private dictionaryService: DictionaryService) {}

  ngOnInit(): void {
    this.dictionaryService
      .getDictionary('BondingContractCollateralType')
      .subscribe((dicts) => (this.collateralTypes = dicts));
  }

  deleteCollateral(c: {clause: ClauseDto; internalId: number}) {
    this.form.removeControl(CustomFormComponent.getClauseGroupName(c.internalId));
    NumberUtils.deleteItemFirstOccurrence(this.collaterals, c.clause);
  }

  addCollateral(c: DictionaryBaseDto) {
    this.collaterals.push(<ClauseDto>{values: [], type: c});
  }
}
