/**
 * Created by siminski on 04.07.2016.
 */

import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, ElementRef, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {HolidayService} from '../../services/holiday.service';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {AppConfigService, FormatService} from '../../services';
import {DateUtils} from '../../utils/date-utils';

const DT_PICKER_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerComponent),
  multi: true,
};

@Component({
  selector: 'date-picker',
  template: `
    <div [id]="uniqueId" class="input-group date" style="width: 100%; overflow: visible">
      <input
        [type]="(presentationMode && 'hidden') || 'text'"
        class="form-control"
        #dp="bsDatepicker"
        bsDatepicker
        (bsValueChange)="onValueChange($event)"
        [(bsValue)]="date"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [disabled]="_disabled"
        [bsConfig]="dpConfig"
        [title]="dateFormatTooltip"
        (keyup.arrowdown)="setCurrentDate()"
      />
      <span *ngIf="!presentationMode" class="input-group-addon fa fa-calendar" (click)="showCalendar(dp)"> </span>
      <span *ngIf="presentationMode" class="presentation" [class.float-right]="floatRight">
        <ng-container [ngSwitch]="type">
          <ng-container *ngSwitchCase="'datetime'"> {{ date | datetime }} </ng-container>
          <ng-container *ngSwitchDefault> {{ date | date }} </ng-container>
        </ng-container>
      </span>
    </div>
  `,
  providers: [DT_PICKER_CONTROL_VALUE_ACCESSOR],
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() type = 'date';
  @Input() presentationMode = false;
  @Output() changeItem = new EventEmitter<Date>();
  @Output() changeAsString = new EventEmitter<string>();
  @Input() floatRight = false;

  uniqueId: string;

  _disabled: any;
  _notPast: boolean;
  _notFuture: boolean;

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  private _date: Date;
  private _minDate: Date;
  private _maxDate: Date;
  public dateFormatTooltip = '';
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  constructor(
    private element: ElementRef,
    private translateService: TranslateService,
    private config: AppConfigService,
    private formatService: FormatService
  ) {
    this.uniqueId = 'dt_' + Math.floor(Math.random() * 100000);
    this.dpConfig.dateInputFormat = formatService.dateFormat.toUpperCase();
    this.dpConfig.containerClass = 'theme-orange';
    this.dpConfig.showWeekNumbers = false;
    this.dateFormatTooltip = config.kuke ? translateService.instant('datePickerTooltip') : '';
  }

  get date() {
    return this._date;
  }

  @Input()
  set date(_date: Date) {
    this._date = _date;
  }

  get minDate() {
    return this._minDate;
  }

  get minDateStr() {
    return this.minDate instanceof Date ? this.formatService.formatDate(this.minDate) : this.minDate;
  }

  @Input()
  set minDate(_minDate: Date) {
    if ((this._notPast && !_minDate) || _minDate === this._minDate || moment(_minDate).isSame(this._minDate)) {
      return;
    }
    if (this._maxDate && moment(_minDate).isAfter(this.maxDate)) {
      this.maxDate = _minDate;
    }
    this._minDate = _minDate;
  }

  get maxDate() {
    return this._maxDate;
  }

  get maxDateStr() {
    return this.maxDate instanceof Date ? this.formatService.formatDate(this.maxDate) : this.maxDate;
  }

  @Input()
  set maxDate(_maxDate: Date) {
    if ((this._notFuture && !_maxDate) || _maxDate === this._maxDate || moment(_maxDate).isSame(this._maxDate)) {
      return;
    }
    if (this._minDate && moment(_maxDate).isBefore(this.minDate)) {
      this.minDate = _maxDate;
    }
    this._maxDate = _maxDate;
  }

  get disabled() {
    return this._disabled;
  }

  @Input()
  set disabled(_disabled: any) {
    this._disabled = _disabled;
  }

  @Input() set notPast(b: boolean) {
    this._notPast = b;
    if (b) {
      this._minDate = new Date();
    }
  }

  @Input() set notFuture(b: boolean) {
    this._notFuture = b;
    if (b) {
      this._maxDate = new Date();
    }
  }

  onValueChange(value: Date): void {
    value = this.fixTimezoneBug(value);
    if ((!value && !this.date) || value === this.date) {
      return;
    }
    if (!DateUtils.isDateValid(value)) {
      value = undefined;
    }
    this.date = value;
    // Check if it used in form context
    if (this.onChangeListeners) {
      this.onChangeListeners(value);
    }
    this.changeItem.emit(value);
    this.changeAsString.emit(this.formatService.formatDate(value));
  }

  /**
   * If month is changed date picker passes date like this:
   * Tue Jun 11 2019 00:00:00 GMT+0200 (czas środkowoeuropejski letni)
   * So UTC is actually 22:00 day before, and backend treats it this way.
   * Method changes this date to:
   * Tue Jun 11 2019 02:00:00 GMT+0200 (czas środkowoeuropejski letni)
   */
  fixTimezoneBug(value: Date) {
    if (moment(value).startOf('day').isSame(value)) {
      return moment(value).utc(true).toDate();
    }
    return value;
  }

  setCurrentDate() {
    this.onValueChange(new Date());
  }
  showCalendar(dp: any) {
    if (this.disabled) {
    } else {
      dp.toggle();
    }
  }

  // From ControlValueAccessor interface
  writeValue(date: Date): void {
    if ((!date && !this.date) || date === this.date) {
      return;
    }

    this.date = date;
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
