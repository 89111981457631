<search-view objectName="Invoice" customTitle="clientInvoice.search.title" [dataProvider]="dataProvider" [atable]="invoices" [searchModeSwitchable]="false" [newButton]="false">
  <div class="additional-buttons">
    <button class="bon-btn-info" (click)="applyFilter(PredefinedFilter.CLEARED)" type="button" translate="clientInvoice.search.predefinedFilter.settled"></button>
    <button class="bon-btn-info" (click)="applyFilter(PredefinedFilter.UNCLEARED)" type="button" translate="clientInvoice.search.predefinedFilter.unsettled"></button>
  </div>
  <div class="search-criteria">
    <div *ngIf="businessObjectTypeId"  class="bon-card-group bon-label-150">
      <div class="bon-card-inner">
        <form-row  labelKey="clientInvoice.search.criteria.policyYear">
          <item-combo class="bon-input-size" [(ngModel)]="dataProvider.searchCriteria.criteria.contractLink.policyContract"
                      [items]="policyContracts" label="policyYearFormatted">
          </item-combo>
        </form-row>
      </div>
      <div class="bon-card-inner">
      </div>
    </div>
    <div *ngIf="!businessObjectTypeId" class="bon-card-group bon-label-150">
      <div class="bon-card-inner">
        <input-row labelKey="clientInvoice.search.criteria.number" [(ngModel)]="dataProvider.searchCriteria.criteria.number" name="number"></input-row>
        <input-row labelKey="clientInvoice.search.criteria.contractNumber" [(ngModel)]="dataProvider.searchCriteria.criteria.contractNumber" name="contractNumber"></input-row>
        <div class="bon-row">
          <ss-multiselect-dropdown labelKey="clientInvoice.search.criteria.status" [(ngModel)]="dataProvider.searchCriteria.criteria.statuses" name="statuses"
                                   optionsDictName="InvoiceStatus" [optionIds]="availableStatusIds" [settings]="statusMultiselectSettings"></ss-multiselect-dropdown>
        </div>
        <input-row labelKey="clientInvoice.search.criteria.titles" [(ngModel)]="dataProvider.searchCriteria.criteria.titles" name="titles"></input-row>
      </div>
      <div class="bon-card-inner">
        <date-range-row labelKey="clientInvoice.search.criteria.issueDateRange" [value]="dataProvider.searchCriteria.criteria.issueDateRange"></date-range-row>
        <date-range-row labelKey="clientInvoice.search.criteria.dueDateRange" [value]="dataProvider.searchCriteria.criteria.dueDateRange"></date-range-row>
      </div>
    </div>
  </div>
  <div class="search-results">
    <a-table [pagination]="true" [pageSize]="20" [selection]="false" [dataProvider]="dataProvider">
      <column labelKey="clientInvoice.search.result.number" property="number" sort="number"
              [link]="loggedUserService.hasRight(ElementaryRight.PORTAL_INVOICE_DETAILS_VIEW)"
              (linkClick)="router.toClientInvoicePreview($event.category.id, $event.id)"></column>
      <column labelKey="clientInvoice.search.result.titles" property="titles" sort="titles"></column>
      <column labelKey="clientInvoice.search.result.documentType" property="numberingBag.documentType" sort="numberingBag.documentType" dictionary="FinancialDocumentType"></column>
      <column *ngIf="!ecg" labelKey="clientInvoice.search.result.invoiceType" property="invoiceType" sort="invoiceType" dictionary="InvoiceType"></column>
      <column *ngIf="ecg" labelKey="clientInvoice.search.result.invoiceType">
        <ng-template>{{"common.invoice" | translate}}</ng-template>
      </column>
      <column labelKey="clientInvoice.search.result.status" property="portalBusinessStatus" sort="status" dictionary="InvoiceStatus"></column>
      <column labelKey="clientInvoice.search.result.contractNumber" property="contractNumber" sort="contractNumber"></column>
      <column labelKey="clientInvoice.search.result.dateFrom" property="dateFrom" sort="dateFrom" type="date"></column>
      <column labelKey="clientInvoice.search.result.dateTo" property="dateTo" sort="dateTo" type="date"></column>
      <column labelKey="clientInvoice.search.result.grossAmount" property="grossAmount" sort="netAmount" type="number"></column>
      <column labelKey="clientInvoice.search.result.currency" property="currency" sort="currency" dictionary="Currency" dictLabel="code"></column>
      <column labelKey="clientInvoice.search.result.issueDate" property="issueDate" sort="issueDate" type="date"></column>
      <column labelKey="clientInvoice.search.result.dueDate" property="dueDate" sort="dueDate" type="date"></column>
      <column *ngIf="!ecg" title="">
        <ng-template let-item="item"><button *hasRight="'PORTAL_INVOICE_DOWNLOAD'" class="bon-btn" btnIcon="fa-file-pdf-o"
                                             (click)="this.invoiceService.downloadInvoiceDocument(item, warningCallback.bind(this))"
                                             translate="common.button.download"></button></ng-template>
      </column>
      <column title="">
        <ng-template let-item="item">
          <ng-container *hasRight="'PORTAL_INVOICE_SPEC'">
            <button *ngIf="item.reportDefinitions.length == 1" class="bon-btn" btnIcon="fa-file-excel-o"
                    (click)="onReportDefinitionSelected(item.reportDefinitions[0], item.id)"
                    translate="clientInvoice.search.result.singleReport"></button>
            <item-dropdown *ngIf="item.reportDefinitions.length > 1" [items]="item.reportDefinitions"
                           (menuSelect)="onReportDefinitionSelected($event, item.id)"
                           caption="clientInvoice.search.result.reports"
                           [dropup]="false" [disabled]="false">
            </item-dropdown>
          </ng-container>
        </ng-template>
      </column>
    </a-table>
  </div>
</search-view>
