import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {
  BusinessObjectDto,
  SalesRepDto,
  SalesRepRelationVersionDto,
  SalesRepVersionCriteriaDto,
  SalesRepVersionDto,
} from '../../../bonding_shared/model';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {SalesRepVersionService} from '../../../bonding_shared/services/sales-rep-version.service';

@Injectable()
export class SalesRepGuiService {
  dataProvider: SearchDataProvider<SalesRepVersionCriteriaDto, SalesRepVersionDto>;

  constructor(private service: SalesRepVersionService) {
    this.dataProvider = BusinessUtils.createDataProvider(service);
    this.dataProvider.searchCriteria.criteria.salesRep = <SalesRepDto>{};
    this.dataProvider.searchCriteria.criteria.businessObject = <BusinessObjectDto>{relatedTo: {}};
    this.dataProvider.searchCriteria.criteria.versionPhase = 'LAST';
    this.dataProvider.textSearch = false;
  }

  static emptyRelationVersion(): SalesRepRelationVersionDto {
    return <SalesRepRelationVersionDto>{
      commissionFee: {fee: {}},
      salesRepRelation: {salesRep: {}, businessObject: {relatedTo: {}}},
    };
  }
}
