import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {HooverColor} from '../hover-info';

/**
 * Created by wilk on 30.11.2016.
 */

const TEXTAREA_ROW_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextareaRowComponent),
  multi: true,
};

@Component({
  selector: 'textarea-row',
  template: `
    <div>
      <div class="bon-row" *ngIf="label || labelKey">
        <label class="bon-label long-label" style="margin-top:5px;"
          >{{ labelKey ? (labelKey | translate) : label }}
          <hover-info *ngIf="hooverMsg" [message]="hooverMsg" [color]="hooverColor"></hover-info>
        </label>
      </div>
      <div *ngIf="control" class="bon-row">
        <textarea
          [ngModel]="value"
          (input)="onInput($event)"
          (blur)="onTouchedListeners()"
          [disabled]="disabled || presentationMode"
          [rows]="rows"
          [readonly]="readonly || presentationMode"
          [minlength]="minlength + ''"
          [maxlength]="maxlength + ''"
          style="width: 95%;"
        ></textarea>
        <error-message [control]="control" [show]="showErrors"></error-message>
      </div>
      <div *ngIf="!control" class="bon-row">
        <textarea
          #model="ngModel"
          [(ngModel)]="value"
          (input)="onInput($event)"
          (blur)="onTouchedListeners()"
          [rows]="rows"
          [required]="required"
          [disabled]="disabled || presentationMode"
          [readonly]="readonly || presentationMode"
          [maxlength]="maxlength + ''"
          [minlength]="minlength + ''"
          style="width: 95%;"
        ></textarea>
        <error-message [control]="model" [show]="showErrors"></error-message>
      </div>
      <div *ngIf="showCounter" class="bon-row">
        <div style="width: 95%; text-align: right; font-size: 0.875rem">
          {{ 'common.charsLeft' | translate }}:{{ maxlength - value?.length }}
        </div>
      </div>
    </div>
  `,
  providers: [TEXTAREA_ROW_CONTROL_VALUE_ACCESSOR],
})
export class TextareaRowComponent implements ControlValueAccessor {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() labelKey: string;
  @Input() required: boolean;
  @Input() disabled: any;
  @Input() readonly: any;
  @Input() showErrors: boolean;
  @Input() rows = 2;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() showCounter = false;
  @Input() presentationMode = false;
  @Input() hooverMsg: string;
  @Input() hooverColor: HooverColor = 'blue';
  @Output() change = new EventEmitter<string>();

  private onChangeListeners: Function;
  public onTouchedListeners: Function;
  public value: string;

  writeValue(obj: any): void {
    this.value = obj;
    this.change.emit(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onInput(event: Event) {
    this.value = (<any>event.target).value;
    this.onChangeListeners(this.value);
    this.change.emit(this.value);
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
