import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {
  AppConfigService,
  BackendError,
  BondDto,
  BondVersionCriteriaDto,
  BondVersionService,
  BondVersionSimpleDto,
  BusinessReportService,
  ContractService,
  ContractVersionDto,
  DictionaryBaseDto,
  FeatureService,
  InvoiceCriteriaDto,
  InvoiceDto,
  InvoiceService,
  LoggedUserService,
  PropertyService,
  RouterService,
  SearchCriteria,
  SearchDataProvider,
  StatisticsDto,
  TemplateSimpleDto,
} from '../../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {ContractGuiService} from './services/contract-gui.service';
import {InvoiceListComponent} from '../../invoice/components/shared/invoice-list.component';
import {
  AppProperty,
  BondingElementaryRight,
  BusinessObjectType,
  ContactNoteElementaryRight,
  ContractStatus,
  DocumentType,
  Feature,
  InvoiceCategory,
  KukeRepoOperation,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
  ServiceNoteElementaryRight,
  Source,
} from '../../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {HttpParams} from '@angular/common/http';
import {ReportDefinition} from '../../../bonding_shared/model/report-ids';
import {BusinessUnit} from '../../../bonding_shared/model/business-unit';

@Component({
  selector: 'contract-preview',
  templateUrl: './contract-preview.component.pug',
})
export class ContractPreviewComponent implements OnInit {
  contractVersion: ContractVersionDto;
  bonds: BondVersionSimpleDto[];
  serverErrors: BackendError;
  bondDataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>;
  readonly BondingElementaryRight = BondingElementaryRight;
  showContractReportButton = false;

  @ViewChild(InvoiceListComponent, {static: true}) invoiceList: InvoiceListComponent;
  invoiceDataProvider: SearchDataProvider<InvoiceCriteriaDto, InvoiceDto>;

  invoiceCategoryId = InvoiceCategory.INVOICE;
  statistics: StatisticsDto;
  readonly BusinessObjectType = BusinessObjectType;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  readonly Source = Source;

  wordingTemplate: TemplateSimpleDto;

  constructor(
    private contractService: ContractService,
    public guiService: ContractGuiService,
    private bondService: BondVersionService,
    private route: ActivatedRoute,
    public router: RouterService,
    private invoiceService: InvoiceService,
    private cdRef: ChangeDetectorRef,
    public appService: AppConfigService,
    public loggedUserService: LoggedUserService,
    private apiService: AppConfigService,
    private propertyService: PropertyService,
    private businessReportService: BusinessReportService,
    private featureService: FeatureService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
    this.showContractReportButton = this.featureService.get(Feature.SHOW_CONTRACT_REPORT_BUTTON);
  }

  initializeView(params: Params) {
    const contractVersionId = +params['contractVersionId'];
    const contractId = +params['contractId'];
    console.log('onInit: contractVersionId = ' + contractVersionId + ', contractId = ' + contractId);
    this.contractVersion = <ContractVersionDto>{};
    this.contractVersion.id = contractVersionId;
    this.guiService.gerContractVersion(contractVersionId, contractId).subscribe(
      (contract) => this.afterContractReceived(contract),
      (error) => (this.serverErrors = error)
    );

    // const kukedictionarySelectors: DictionaryBaseDto[] = [];
    // kukedictionarySelectors.push(this.contractVersion.contract.type);

    if (this.apiService.kuke) {
      const dictionarySelectors: DictionaryBaseDto[] = [];
      dictionarySelectors.push(this.contractVersion.contract.type);
      this.guiService.getContractTemplates(DocumentType.CONTRACT, dictionarySelectors).subscribe((result) => {
        if (result && result.length > 1) {
          console.error('More than one bond template found');
        }
        if (result && result.length > 0) {
          this.wordingTemplate = result[0];
        }
      });
    }
  }

  afterContractReceived(contract: ContractVersionDto) {
    this.setContractVersion(contract);
    console.log('contract version loaded:: versionId = ' + this.contractVersion.id);
    this.createBondDataProvider(contract.contract.id);
    this.createInvoiceDataProvider(contract);
    // this.searchContractBonds(contract);
  }

  newBond() {
    console.log('goToBondDetails:: contractVersionId: ' + this.contractVersion.id);
    this.router.toBondDetailsNew(this.contractVersion.clientId, this.contractVersion.contract.id);
  }

  // searchContractBonds(cv: ContractVersion) {
  //   console.log('Search bonds: contractId' + cv.contract.id);
  //   this.bondService.getContractLastBondVersions1(cv.contract.id)
  //     .subscribe(
  //       searchResult => {	this.bonds = searchResult.result;});
  // }

  get contractNumber(): string {
    return BusinessUtils.contractNumber(this.contractVersion);
  }

  setContractVersion(c: ContractVersionDto) {
    this.contractVersion = c;
    this.cdRef.detectChanges();

    return this.contractService.getContractStatistics(c.contract.id).subscribe((s) => (this.statistics = s));
  }

  createBondDataProvider(contractId: number) {
    this.bondDataProvider = new SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>(this.bondService);
    this.bondDataProvider.searchCriteria = <SearchCriteria<BondVersionCriteriaDto>>{};
    this.bondDataProvider.searchCriteria.criteria = <BondVersionCriteriaDto>{};
    this.bondDataProvider.searchCriteria.criteria.bond = <BondDto>{};
    this.bondDataProvider.searchCriteria.criteria.contractId = contractId;
  }

  createInvoiceDataProvider(contract: ContractVersionDto) {
    this.invoiceDataProvider = new SearchDataProvider<InvoiceCriteriaDto, InvoiceDto>(this.invoiceService);
    this.invoiceDataProvider.searchCriteria = <SearchCriteria<InvoiceCriteriaDto>>{};
    this.invoiceDataProvider.searchCriteria.criteria = <InvoiceCriteriaDto>{};
    this.invoiceDataProvider.searchCriteria.criteria.contractNumber = contract.contract.number;
    this.invoiceDataProvider.searchCriteria.criteria.category = <DictionaryBaseDto>{id: InvoiceCategory.INVOICE};
    this.invoiceDataProvider.textSearch = false;
  }

  isRequest(): boolean {
    if (
      this.contractVersion &&
      this.contractVersion.status &&
      this.contractVersion.status.id !== ContractStatus.CONTRACT_PROSPECT &&
      this.contractVersion.status.id !== ContractStatus.CONTRACT_REQUEST_ACCEPTED &&
      this.contractVersion.status.id !== ContractStatus.CONTRACT_REQUEST &&
      this.contractVersion.status.id !== ContractStatus.CONTRACT_REQUEST_REJECTED &&
      this.contractVersion.status.id !== ContractStatus.CONTRACT_REQUEST_WITHOUT_RESULT
    ) {
      return false;
    }
    return true;
  }

  getAvailableLimit(): number {
    return Math.max(0, this.contractVersion.maxTotalLiability - this.statistics.currentExposure);
  }

  showSysCurr(): boolean {
    const systemCurrencyCode = this.propertyService.properties[AppProperty.SYSTEM_CURRENCY];
    return this.contractVersion?.maxTotalLiabilityCurrency?.code !== systemCurrencyCode;
  }

  onWordingSelected(template: TemplateSimpleDto) {
    this.router.toDocumentDetailsNewOperations(
      KukeRepoOperation.WORDINGS,
      template.id,
      this.contractVersion.id,
      template.type.id
    );
  }
  toServiceContacts() {
    this.router.toServiceContacts({
      boTypeId: BusinessObjectType.CONTRACT,
      boId: this.contractVersion.contract.id,
    });
  }

  createNewRenewalInquiry() {
    this.router.toBondingContractInquiryRenewalFromPolicy(this.contractVersion.id);
  }

  generateContractReport() {
    const params = new HttpParams().append('contractID', this.contractVersion.contract.id);

    this.businessReportService.generate(
      this.contractVersion.contract.businessUnit.id === BusinessUnit.KUKE_GSP
        ? ReportDefinition.CONTRACT_PARAMETERS_GSP
        : ReportDefinition.CONTRACT_PARAMETERS_KOM,
      `contract_params_${this.contractVersion.contract.id}.xlsx`,
      params,
      (errorMsg) => (this.serverErrors = errorMsg)
    );
  }

  get kuke() {
    return this.appService.kuke;
  }
}
