import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {BusinessObjectDto} from '../model';

@Injectable()
export class BusinessLogService extends AbstractService {
  protected url = this.urlPrefix + 'businessLog';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getLoginAlert(): Observable<string> {
    return this.get<string>(this.url + '/failedLoginAlert/get');
  }

  clearLoginAlert() {
    return this.get<string>(this.url + '/failedLoginAlert/clear');
  }
}
