import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {
  BinaryDownloaderService,
  DictionaryBaseService,
  LoggedUserService,
  SettingsService,
  UserService,
} from '../../services/index';
import {UserDto} from '../../model/dtos';
import {AppConfigService} from '../../services/app-config.service';
import {ChangePasswordComponent} from '../user/change-password.component';
import * as $ from 'jquery';
import {ChangeAbsenceComponent} from '../user/change-absence.component';
import {FrontReloadWarningComponent} from './front-reload-warning.component';

@Component({
  selector: 'bon-topnav',
  templateUrl: 'topnav.component.html',
})
export class TopNavComponent implements OnInit {
  @ViewChild(ChangePasswordComponent, {static: true}) changePassCmp: ChangePasswordComponent;
  @ViewChild(ChangeAbsenceComponent, {static: true}) changeAbsenceCmp: ChangeAbsenceComponent;
  @ViewChild(FrontReloadWarningComponent, {static: true}) frontReloadWarning: FrontReloadWarningComponent;
  status: {isopen: boolean} = {isopen: false};

  user: UserDto;
  userRolesShort: string;
  userRoles: string;
  userName: string;
  availableHelps = [
    {url: 'userManual', fileName: 'UserManual.pdf', nameKey: 'common.help.userManual'},
    {url: 'reports', fileName: 'Reports.pdf', nameKey: 'common.help.reports'},
    {url: 'invoicing', fileName: 'Invoicing.pdf', nameKey: 'common.help.invoicing'},
    {
      url: 'userManualSpecialConcept',
      fileName: 'UserManualSpecialConcept.pdf',
      nameKey: 'common.help.userManualSpecialConcept',
    },
  ];
  constructor(
    private _router: Router,
    public _settings: SettingsService,
    public loggedUserService: LoggedUserService,
    public appConfigService: AppConfigService,
    private userService: UserService,
    public binaryDownloader: BinaryDownloaderService,
    private dictService: DictionaryBaseService
  ) {}

  initUserName(user: UserDto) {
    this.userName = user.name + ' ' + user.familyName;
    if (user.title) {
      this.dictService
        .getDictionaryEntry('UserTitle', user.title.id, user.language.id)
        .subscribe((title) => (this.userName = title.name + ' ' + user.name + ' ' + user.familyName));
    } else {
      this.userName = user.name + ' ' + user.familyName;
    }
  }

  ngOnInit(): void {
    this.initRoles(this.loggedUserService.getLoggedUserData());
    this.initUserName(this.loggedUserService.getLoggedUserData());
    this.loggedUserService.loggedUsersSubject.subscribe((user) => {
      this.initRoles(user);
      this.initUserName(user);
    });
  }

  initRoles(user: UserDto) {
    this.dictService.getDictionaryBase('UserRole').subscribe((roles) => {
      this.userRoles = '';
      let count = 0;
      for (const role of user.roles) {
        if (count === 2) {
          this.userRolesShort = this.userRoles + ', ...';
        }
        if (this.userRoles.length > 0) {
          this.userRoles += ', ';
        }
        this.userRoles += roles.find((r) => r.id === role.id).name;
        count++;
      }
    });
  }

  sidebarToggler(): void {
    const sidebar: any = $('#sidebar');
    const mainContainer: any = $('.main-container');
    sidebar.toggleClass('sidebar-left-zero');
    mainContainer.toggleClass('main-container-ml-zero');
  }

  gotoLogin(): void {
    this._router.navigate(['']);
  }

  goToPassChange(): void {
    this.changePassCmp.openChangePasswordDialog();
  }

  gotoHome(): void {
    this._router.navigate(['bon', 'home']);
  }

  gotoProfile(): void {
    this._router.navigate(['Profile']);
  }

  setToPresent() {
    this.userService.setToPresent().subscribe((res) => this.loggedUserService.handleUser(res));
  }
  changeUserAvailability() {
    this.changeAbsenceCmp.openChangeAbsenceDialog();
  }

  downloadSpec(url: string, fileName: string) {
    this.binaryDownloader.download(
      'GET',
      this.appConfigService.backendUrl + 'specification/' + url,
      'application/pdf',
      'application/octet-stream',
      fileName
    );
  }

  isAbsent() {
    const absentFrom = this.loggedUserService.getLoggedUserData().absentFrom;
    const absentTo = this.loggedUserService.getLoggedUserData().absentTo;
    if (absentTo && absentFrom) {
      const now = new Date();
      const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
      const absentFromUTC = new Date(
        Date.UTC(absentFrom.getUTCFullYear(), absentFrom.getUTCMonth(), absentFrom.getUTCDate())
      );
      const absentToUTC = new Date(Date.UTC(absentTo.getUTCFullYear(), absentTo.getUTCMonth(), absentTo.getUTCDate()));
      new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
      return absentFromUTC && absentToUTC && absentFromUTC <= today && absentToUTC >= today;
    }
    return false;
  }
}
