export const environment = {
  production: true,
};
export const APP_CONFIG = {
  apiConfig: {
    selectedProfile: 'uat',
    profiles: {
      uat: {
        backendUrlInfix: 'cesar',
        themeColour: 'credendo-brown',
        displayName: 'Booster UAT',
      },
    },
  },
  appClientConfig: 'credendo',
  performInitialSearch: false,
};
