import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {QuotationDetailsComponent} from './quotation-details-component';
import {PortalRouterService, QuotationService} from '../../../services';
import {
  AppConfigService,
  DictionaryBaseService,
  DictionaryService,
  DocumentService,
  GrowlService,
  LanguageService,
  LoggedUserService,
  NumberFormatService,
} from '../../../../bonding_shared';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'portal-quotation',
  templateUrl: './quotation-details.component.pug',
})
export class CompanyQuotationDetailsComponent extends QuotationDetailsComponent {
  contactPersonsTooltipMessageKey = 'quotation.details.contactPersons.tooltipMessage';

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected routerService: PortalRouterService,
    protected quotationService: QuotationService,
    protected loggedUserService: LoggedUserService,
    protected dictionaryService: DictionaryService,
    protected numberFormatService: NumberFormatService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected languageService: LanguageService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected documentService: DocumentService,
    public apiService: AppConfigService
  ) {
    super(
      router,
      route,
      routerService,
      quotationService,
      loggedUserService,
      dictionaryService,
      numberFormatService,
      dictionaryBaseService,
      languageService,
      translateService,
      growlService,
      documentService,
      apiService
    );
  }

  clientKnownSinceBoundaryLabelKey = 'quotation.details.clientKnownSinceBoundary';
}
