import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TemplateSearchComponent} from './template-search.component';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {DocumentListComponent} from './components';
import {DocumentSearchComponent} from './document-search.component';
import {DocumentDetailsComponent} from './document-details.component';
import {TemplateDetailsComponent} from './template-details.component';
import {HtmlEditorModule} from '../../bonding_shared/components/html-editor/html-editor.module';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {BusinessObjectInfoModule} from '../business-object-info/business-object-info.module';
import {TemplateDetailsGuard} from './_guards';
import {CKEditorModule} from 'ckeditor4-angular';
import {DocumentsPackComponent} from './documents-pack.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    HtmlEditorModule,
    CKEditorModule,
    CompanySharedModule,
    BusinessObjectInfoModule,
  ],
  declarations: [
    TemplateSearchComponent,
    DocumentSearchComponent,
    DocumentListComponent,
    DocumentDetailsComponent,
    TemplateDetailsComponent,
    DocumentsPackComponent,
  ],
  exports: [DocumentListComponent, DocumentsPackComponent],
  providers: [TemplateDetailsGuard],
})
export class DocumentModule {}
