export class TextSearchCriteria {
  text: string;
  duplicate: boolean;
  last: boolean;
  active: boolean;
  companyType: number[];
  physicalPersonIndicator: boolean;
  isBranch: boolean;
  groupHead: boolean;
  lastPolicyYear: boolean;
  excludeTypeId: number;
  typeIds: string;
  countryId: number;
}
