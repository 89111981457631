import {AfterViewInit, Input, ViewChild, Directive} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {RouterService} from '../../../../bonding_shared/services/router-service';

import {SalesRepRelationVersionDto} from '../../../../bonding_shared/model/dtos';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table/a-table.component';
import {SalesRepRelationVersionService} from '../../../../bonding_shared/services';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {SalesRepGuiService} from '../../services/sales-rep-gui.service';
import {PolicyElementaryRight} from '../../../../bonding_shared/model/dictionary-ids';

@Directive()
export abstract class SalesRepRelationListComponent implements AfterViewInit {
  readonly PolicyElementaryRight = PolicyElementaryRight;

  @ViewChild('relationTable', {static: true}) resultList: ATableComponent<SalesRepRelationVersionDto>;

  @Input() showSalesRepColumns = false;
  @Input() relatedToId: number;
  @Input() salesRepId: number;
  @Input() mainSalesRepUserId: number;
  @Input() showPolicyType = true;

  relatedToTypeId: number; // BusinessObjectType.CONTRACT or BusinessObjectType.MASTER_POLICY
  type: 'policy' | 'contract' | 'inquiry' | 'bondingContractInquiry';

  protected constructor(
    protected salesRepRelationVersionService: SalesRepRelationVersionService,
    public router: RouterService,
    protected translateService: TranslateService
  ) {}

  ngAfterViewInit() {
    const dp = BusinessUtils.createDataProvider<SalesRepRelationVersionDto, SalesRepRelationVersionDto>(
      this.salesRepRelationVersionService
    );
    dp.searchCriteria.criteria = SalesRepGuiService.emptyRelationVersion();
    dp.searchCriteria.criteria.salesRepRelation.businessObject.relatedTo.id = this.relatedToTypeId;
    dp.searchCriteria.criteria.salesRepRelation.businessObject.relatedToId = this.relatedToId;
    dp.searchCriteria.criteria.salesRepRelation.salesRep.id = this.salesRepId;
    dp.searchCriteria.criteria.last = true;
    this.resultList.dataProvider = dp;
    this.resultList.search(20);
  }
}
