/**
 * Created by szkrabko on 02.03.2023.
 * It is similar to TextRowComponent, but it is fine-tuned for displaying currencies
 */
import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DictionaryBaseService, PropertyService} from '../../services';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppProperty} from '../../model/dictionary-ids';
import {DictionaryBaseDto} from '../../model';
import {StringUtils} from '../../utils';

@Component({
  selector: 'currency-row',
  template: `
    <div class="bon-row" [class.first-bon-row]="firstTextRow">
      <label [class]="labelWithoutFontSize ? 'bon-label-without-font-size' : 'bon-label'">
        {{ labelKey ? (labelKey | translate) : label }}
      </label>
      <span *ngIf="!presentationMode" [class.align-right]="true"
        >{{ amount | number }} {{ currencyCode$ | async }}</span
      >
      <div *ngIf="presentationMode" class="bon-input-size">
        <span [class.presentation]="true" [class.float-right]="floatRight"
          >{{ amount | number }} {{ currencyCode$ | async }}</span
        >
      </div>
    </div>
  `,
  styles: ['.bold-value { font-weight: 700; }'],
})
export class CurrencyRowComponent {
  @Input() label: string;
  @Input() labelKey: string;
  @Input() amount: number;
  @Input() firstTextRow = false;
  @Input() labelWithoutFontSize = false;
  @Input() presentationMode = false;
  @Input() floatRight = true;

  @Input() set currencyId(currencyId: number) {
    this.currencyCode$ = this.dictionaryBaseService
      .getDictionaryEntry('Currency', currencyId)
      .pipe(map((dict) => dict.code));
  }

  @Input() set currencyCode(currencyCode: string) {
    this.currencyCode$ = of(currencyCode);
  }

  @Input() set currency(currency: DictionaryBaseDto) {
    if (!StringUtils.isEmpty(currency?.code)) {
      this.currencyCode = currency.code;
    } else if (currency?.id) {
      this.currencyId = currency.id;
    } else {
      this.currencyCode$ = of('');
    }
  }

  currencyCode$ = of(this.propertyService.properties[AppProperty.SYSTEM_CURRENCY] || '');

  constructor(
    private translateService: TranslateService,
    private propertyService: PropertyService,
    private dictionaryBaseService: DictionaryBaseService
  ) {}
}
