import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {PortalPolicyContractVersionService} from '../../services/portal-policy-contract-version.service';
import {ComboItem} from '../../../bonding_shared/components/combos/index';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PortalUserService} from '../../services/portal-user.service';
import {PortalUserContextDto} from '../../../bonding_shared/model';

const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ClientInsuredsComboComponent),
  multi: true,
};

@Component({
  selector: 'client-insured-combo',
  template: `
    <div class="bon-row">
      <label class="bon-label" [translate]="labelKey"></label>
      <string-combo
        class="bon-input-size"
        [nullLabel]="nullLabel"
        [nullLabelKey]="nullLabelKey"
        [(ngModel)]="selectedCompanyId"
        [items]="companies"
        (changeItem)="onChange($event)"
      ></string-combo>
    </div>
  `,
  providers: [CONTROL_VALUE_ACCESSOR],
})
export class ClientInsuredsComboComponent implements ControlValueAccessor {
  companies: ComboItem[] = [];
  selectedCompanyId: number;
  onChangeListener: Function;

  @Input() labelKey: string;
  @Input() nullLabel: string;
  @Input() nullLabelKey: string;
  @Output() changeItem = new EventEmitter<number>();

  @Input() set userContext(ctx: PortalUserContextDto) {
    ctx.insureds.forEach((p) => this.companies.push(new ComboItem(p.id + '', p.registrationName)));
  }

  registerOnChange(fn: any): void {
    this.onChangeListener = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.selectedCompanyId = obj;
  }

  onChange(id: string) {
    if (this.onChangeListener) {
      this.onChangeListener(id);
    }
    this.changeItem.emit(+id);
  }

  reset() {
    this.writeValue(undefined);
  }
}
