import {Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Cacheable} from 'ts-cacheable';
import {AbstractService, AppConfigService, LoggedUserService} from '../../bonding_shared/services';
import {SalesRegionDto} from '../../bonding_shared/model';

@Injectable()
export class PortalSalesRegionService extends AbstractService {
  protected url = this.urlPrefix + 'portal/salesRegion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  @Cacheable()
  getAll(): Observable<SalesRegionDto[]> {
    return this.get<SalesRegionDto[]>(this.url + '/all');
  }
}
