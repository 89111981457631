import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {BondingContractRateDto} from '../model';

@Injectable()
export class BondingContractRatesService extends AbstractService {
  protected url = this.urlPrefix + 'bondingContract/rates';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  findRates(businessUnitId: number, bondTypeId: number, ratingId: number): Observable<BondingContractRateDto> {
    return this.get<BondingContractRateDto>(
      this.url +
        `?businessUnitId=${businessUnitId}` +
        (bondTypeId ? `&bondTypeId=${bondTypeId}` : '') +
        (ratingId ? `&ratingId=${ratingId}` : '')
    );
  }
}
