import {AuditedViewAbstract} from '../../bonding_shared/components/details-view/audited-view-abstract';
import {ClaimService, GrowlService, LoggedUserService, RouterService} from '../../bonding_shared/services';
import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ClaimExternalProvidersService} from '../../bonding_shared/services/claim-external-providers.service';
import {ActivatedRoute} from '@angular/router';
import {ClaimDto, CompanyBaseDto, ExternalProviderRelationDto} from '../../bonding_shared/model';

@Component({
  selector: 'claim-external-providers',
  templateUrl: 'claim-external-providers.component.pug',
})
export class ClaimExternalProvidersComponent extends AuditedViewAbstract<ExternalProviderRelationDto[]> {
  public claim = <ClaimDto>{};

  constructor(
    route: ActivatedRoute,
    parentService: ClaimExternalProvidersService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    private claimService: ClaimService
  ) {
    super(route, parentService, router, translateService, growlService, loggedUserService, 'claimId');
    this.selectorNameList = ['Providers'];
    this.initializeSelectorEmitters(true);
  }

  extraInitialization() {
    super.extraInitialization();
    this.claimService.getClaim(this.id).subscribe((claim: ClaimDto) => (this.claim = claim));
  }

  protected getEmpty(): ExternalProviderRelationDto[] {
    return [];
  }

  addCompany(companyDto: CompanyBaseDto) {
    if (!this.current.map((x) => (x.company ? x.company.id : x.provider.company.id)).includes(companyDto.id)) {
      this.current.push(<ExternalProviderRelationDto>{company: companyDto, orderDate: new Date()});
    }
  }
}
