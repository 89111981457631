import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {ThirdPartyBaseDto, ThirdPartyDto} from '../model';
import {Observable} from 'rxjs';

@Injectable()
export class ThirdPartyService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'thirdparty';
  }

  update(object: ThirdPartyBaseDto): Observable<ThirdPartyDto> {
    console.log('third party update: ', object);
    return this.post1<ThirdPartyBaseDto, ThirdPartyDto>(object, this.url + '/update');
  }

  orderReport(thirdPartyId: number): Observable<ThirdPartyDto> {
    console.log('third party order report: ', thirdPartyId);
    return this.postEmpty<ThirdPartyDto>(this.url + '/' + thirdPartyId + '/orderReport');
  }

  initialThirdParty(companyId: number): Observable<ThirdPartyBaseDto> {
    console.log('third party init:  companyId = ' + companyId);
    return this.get<ThirdPartyBaseDto>(this.url + '/initial?companyId=' + companyId);
  }
}
