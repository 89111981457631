import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {PortalPolicyContractVersionService} from '../../services/portal-policy-contract-version.service';
import {ComboItem} from '../../../bonding_shared/components/combos/index';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PortalUserService} from '../../services/portal-user.service';
import {LoggedUserService} from '../../../bonding_shared/services';
import {PortalUserContextDto} from '../../../bonding_shared/model';

const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ClientPolicyComboComponent),
  multi: true,
};

@Component({
  selector: 'client-policy-combo',
  template: `
    <div class="bon-row">
      <label class="bon-label" [translate]="labelKey"></label>
      <string-combo
        *ngIf="policies"
        class="bon-input-size"
        [nullLabel]="nullLabel"
        [nullLabelKey]="nullLabelKey"
        [(ngModel)]="selectedMasterPolicyId"
        [items]="policies"
        (changeItem)="onChange($event)"
      ></string-combo>
    </div>
  `,
  providers: [CONTROL_VALUE_ACCESSOR],
})
export class ClientPolicyComboComponent implements ControlValueAccessor, OnInit {
  policies: ComboItem[] = [];
  selectedMasterPolicyId: number;
  onChangeListener: Function;

  @Input() labelKey: string;
  @Input() nullLabel: string;
  @Input() nullLabelKey: string;
  @Output() changeItem = new EventEmitter<number>();

  constructor(private userService: PortalUserService) {}

  ngOnInit() {
    this.userService
      .getPortalUserContext()
      .subscribe((ctx) => ctx.policies.forEach((p) => this.policies.push(new ComboItem(p.id + '', p.number))));
  }

  registerOnChange(fn: any): void {
    this.onChangeListener = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.selectedMasterPolicyId = obj;
  }

  onChange(masterPolicyId: string) {
    if (this.onChangeListener) {
      this.onChangeListener(masterPolicyId);
    }
    this.changeItem.emit(+masterPolicyId);
  }

  reset() {
    this.writeValue(undefined);
  }
}
