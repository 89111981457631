import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  BondVersionCriteriaDto,
  BondVersionSimpleDto,
  DictionaryBaseDto,
  SearchCriteria,
} from '../../../bonding_shared/model/index';
import {Subject} from 'rxjs';
import {
  AppConfigService,
  BondVersionService,
  PropertyService,
  SearchDataProvider,
} from '../../../bonding_shared/services/index';
import {BondDto} from '../../../bonding_shared/model/dtos';
import {AppProperty, BondType} from '../../../bonding_shared/model/dictionary-ids';
import {TextSearchCriteria} from '../../../bonding_shared/components/search/model/text-search-criteria';

@Component({
  selector: 'it-bid-bond-simple-selector',
  templateUrl: 'it-bid-bond-simple-selector.component.html',
})
export class ItBidBondSimpleSelectorComponent {
  readonly SYSTEM_CURRENCY_CODE = this.propertyService.properties[AppProperty.SYSTEM_CURRENCY];

  @Input() labelKey: string;
  @Input() items: BondVersionSimpleDto[];
  /**
   * Open selector event emitter
   * The same emitter instance should be injected into this component and the component that opens this selector
   */
  @Input() open: Subject<boolean>;

  /**
   * to be able to switch between advanced/basic search mode
   */
  _searchModeSwitchable = false;

  @Input() set searchModeSwitchable(value: boolean) {
    this._searchModeSwitchable = value;
  }

  get searchModeSwitchable(): boolean {
    // IKI 3305
    return this._searchModeSwitchable && !this.appService.kuke;
  }

  /**
   * when fixedSearch = true, search is done by injected, predefined criteria, user cannot modify them
   */
  @Input() fixedSearch = false;

  dataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>;

  @Output() selectItem = new EventEmitter<BondVersionSimpleDto>();

  constructor(
    private service: BondVersionService,
    private propertyService: PropertyService,
    private appService: AppConfigService
  ) {
    this.dataProvider = new SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>(service);
    this.dataProvider.searchCriteria = <SearchCriteria<BondVersionCriteriaDto>>{};
    this.dataProvider.searchCriteria.criteria = <BondVersionCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.bond = <BondDto>{};
    this.dataProvider.searchCriteria.criteria.versionPhase = 'ACTIVE';
    this.dataProvider.searchCriteria.criteria.type = <DictionaryBaseDto>{id: BondType.BID_BOND_IT};
  }

  @Input() set criteria(c: BondVersionCriteriaDto) {
    this.dataProvider.textSearch = false;
    this.dataProvider.searchCriteria.criteria = c;
  }

  @Input() set textCriteria(c: TextSearchCriteria) {
    this.dataProvider.textSearch = true;
    this.dataProvider.textSearchCriteria = c;
  }
}
