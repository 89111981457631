import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SalesRepStatsDto, SalesRepVersionCriteriaDto, SalesRepVersionDto, SearchCriteria} from '../model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class SalesRepVersionService extends AbstractService {
  protected url = this.urlPrefix + 'salesRepVersion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getStatsByParentId(id: number): Observable<SalesRepStatsDto> {
    return this.get<SalesRepStatsDto>(this.url + '/stats/' + id);
  }

  getLastByParentId(id: number): Observable<SalesRepVersionDto> {
    const criteria = <SearchCriteria<SalesRepVersionCriteriaDto>>{criteria: {salesRep: {id: id}, last: true}};
    return this.searchByCriteria<SalesRepVersionCriteriaDto, SalesRepVersionDto>(criteria).pipe(
      map((x) => x.result[0])
    );
  }

  initialVersion(): Observable<SalesRepVersionDto> {
    return this.get<SalesRepVersionDto>(this.url + '/initialVersion/');
  }
}
