import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  BusinessUnitDto,
  BusinessUnitIdDto,
  DictionaryBaseDto,
  InvoiceNumberingBagCriteriaDto,
  InvoiceNumberingBagDto,
  SearchCriteria,
  SearchResult,
} from '../../../bonding_shared/model/dtos';
import {InvoiceCategory} from '../../../bonding_shared/model/dictionary-ids';
import {InvoiceNumberingBagService} from '../../../bonding_shared/services/invoice-numbering-bag.service';

const INVOICE_NUMBERING_BAG_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InvoiceNumberingBagSelectorComponent),
  multi: true,
};

@Component({
  selector: 'invoice-numbering-bag-selector',
  templateUrl: 'invoice-numbering-bag-selector.component.html',
  providers: [INVOICE_NUMBERING_BAG_CONTROL_VALUE_ACCESSOR],
})
export class InvoiceNumberingBagSelectorComponent implements ControlValueAccessor {
  _businessUnit: BusinessUnitDto;
  _categoryId: InvoiceCategory;

  @Input() set categoryId(categoryId: InvoiceCategory) {
    if (categoryId) {
      this._categoryId = categoryId;
      this.load();
    }
  }

  @Input() set businessUnit(businessUnit: BusinessUnitDto) {
    if (businessUnit) {
      this._businessUnit = businessUnit;
      this.load();
    }
  }

  @Input() showErrors: boolean;
  @Input() required: boolean;

  @Output() changeItem = new EventEmitter<InvoiceNumberingBagDto>();

  public form: UntypedFormGroup;
  invoiceNumberingBagsResult: SearchResult<InvoiceNumberingBagDto>;
  _selectedNumberingBag: InvoiceNumberingBagDto;

  onChangeModel: Function;
  onTouchedModel: Function;

  get selectedNumberingBag(): InvoiceNumberingBagDto {
    return this._selectedNumberingBag;
  }

  set selectedNumberingBag(k: InvoiceNumberingBagDto) {
    this._selectedNumberingBag = k;
    this.onChangeModel(k);
  }

  constructor(
    private invoiceNumberingBagService: InvoiceNumberingBagService,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.form = _formBuilder.group({});
  }

  get businessUnit() {
    return this._businessUnit;
  }

  writeValue(item: InvoiceNumberingBagDto): void {
    this._selectedNumberingBag = item;
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedModel = fn;
  }

  onChangeItem(event: InvoiceNumberingBagDto) {
    this._selectedNumberingBag = event;
    this.onChangeModel(this._selectedNumberingBag);
    this.changeItem.emit(event);
  }

  private load() {
    if (this._businessUnit && this._categoryId) {
      const criteria = <SearchCriteria<InvoiceNumberingBagCriteriaDto>>{
        criteria: <InvoiceNumberingBagCriteriaDto>{
          businessUnit: <BusinessUnitIdDto>{id: this._businessUnit.id},
          invoiceCategory: <DictionaryBaseDto>{id: this._categoryId},
        },
      };
      this.invoiceNumberingBagService
        .search(criteria)
        .subscribe((invoiceNumberingBagsResult) => (this.invoiceNumberingBagsResult = invoiceNumberingBagsResult));
    }
  }
}
