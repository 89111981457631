import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {BrokerContractRelationVersionDto, StateTransitionDto} from '../model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class BrokerContractRelationService extends AbstractService {
  protected url = this.urlPrefix + 'brokerContractRelation';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  newRelationVersion(brokerRelationId: number): Observable<BrokerContractRelationVersionDto> {
    return this.get<BrokerContractRelationVersionDto>(this.url + '/newVersion/' + brokerRelationId);
  }

  getLastRelationVersion(brokerRelationId: number): Observable<BrokerContractRelationVersionDto> {
    return this.get<BrokerContractRelationVersionDto>(this.url + '/lastVersion/' + brokerRelationId);
  }

  initialRelationVersion(
    brokerContractId: number,
    relatedToTypeId: number,
    relatedToId: number
  ): Observable<BrokerContractRelationVersionDto> {
    return this.get<BrokerContractRelationVersionDto>(
      `${this.url}/initialVersion?brokerContractId=${brokerContractId}&relatedToTypeId=${relatedToTypeId}&relatedToId=${relatedToId}`
    );
  }

  getTransitions(bv: BrokerContractRelationVersionDto): Observable<Array<StateTransitionDto>> {
    if (bv?.status) {
      return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + bv.status.id);
    }
    return Observable.create([]);
  }

  public deleteRelationVersion(relationId: number): Observable<void> {
    return this.delete(`${this.url}/${relationId}`);
  }

  redirectInfo(id: number): Observable<Object> {
    return this.get<Object>(this.url + '/redirectInfo/' + id);
  }
}
