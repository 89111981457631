/**
 * Created by wilk on 13.04.2017.
 * It is similar to InputRowComponent but it is read only and does not use ngModel
 */
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'text-row',
  template: `
    <div class="bon-row" [class.first-bon-row]="firstTextRow">
      <label class="bon-label">{{ actualLabel }}</label>
      <span
        *ngIf="!presentationMode"
        [class.a-link]="showLink"
        [class.align-right]="alignRight"
        (click)="onClick($event)"
        [class.bold-value]="boldValue"
        [class.font-larger]="largerFontForValue"
        >{{ actualValue }}</span
      >
      <div *ngIf="presentationMode" class="bon-input-size">
        <span
          class="presentation"
          [class.a-link]="showLink"
          [class.align-right]="alignRight"
          (click)="onClick($event)"
          [class.bold-value]="boldValue"
          >{{ actualValue }}</span
        >
      </div>
    </div>
  `,
  styles: ['.bold-value { font-weight: 700; }'],
})
export class TextRowComponent {
  @Input() label: string;
  @Input() labelKey: string;
  @Input() value: string;
  @Input() intValue: number;
  @Input() alignRight = false;
  @Input() boldValue = false;
  @Input() largerFontForValue = false;
  @Input() empty = false;
  @Input() firstTextRow = false;
  @Input() nullValue = '';
  @Input() showLink = false;
  @Input() presentationMode = false;

  @Output() clickItem = new EventEmitter<boolean>();

  constructor(private translateService: TranslateService) {}

  get actualLabel() {
    if (this.empty) {
      return '';
    }
    return this.labelKey ? this.translateService.instant(this.labelKey) : this.label;
  }

  get actualValue() {
    if (this.empty) {
      return '';
    }
    return this.value ? this.value : this.nullValue;
  }

  onClick(event: any) {
    if (this.showLink) {
      this.clickItem.emit(true);
    }
  }
}
