import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {BusinessParamDto, BusinessParamHistoryDto, BusinessPropertiesDto} from '../model';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';

@Injectable()
export class BusinessParamService extends AbstractService {
  protected url = this.urlPrefix + 'businessParam';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  findByApplicationModule(applicationModuleId: number): Observable<BusinessParamDto[]> {
    return this.get<BusinessParamDto[]>(this.url + '/findByApplicationModule/' + applicationModuleId);
  }

  getHistory(applicationModuleId: number, typeId: number): Observable<BusinessParamHistoryDto[]> {
    return this.get<BusinessParamHistoryDto[]>(this.url + '/history/' + applicationModuleId + '/' + typeId);
  }
}
