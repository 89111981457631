import {Component, Input} from '@angular/core';
import {BondingContractInquiryVersionDto} from '../../../../bonding_shared/model';

@Component({
  selector: 'bonding-contract-inquiry-tab-offer',
  templateUrl: './bonding-contract-inquiry-tab-offer.pug',
})
export class BondingContractInquiryTabOfferComponent {
  @Input() inquiryVersion: BondingContractInquiryVersionDto;

  constructor() {}
}
