/**
 * Created by wilk on 17.11.2016.
 */
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';

const STRING_COMBO_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StringComboComponent),
  multi: true,
};

@Component({
  selector: 'string-combo',
  template: `
    <select
      *ngIf="!presentationMode"
      [(ngModel)]="selectedKey"
      (change)="onChange($event)"
      [disabled]="disabled"
      class="bon-select"
    >
      <option *ngIf="nullLabel || nullLabelKey" value="undefined">
        {{ nullLabel ? nullLabel : (nullLabelKey | translate) }}
      </option>
      <option *ngIf="divider || dividerKey" disabled>
        <span *ngIf="dividerDecorator">{{ dividerDecorator }}</span>
        <span>{{ divider ? divider : (dividerKey | translate) }}</span>
        <span *ngIf="dividerDecorator">&nbsp;{{ dividerDecorator }}</span>
      </option>
      <option *ngFor="let item of items" [value]="item.value" [selected]="isSelected(item.value) === true">
        <span translate>{{ item.label }}</span>
      </option>
    </select>
    <ng-container *ngIf="presentationMode">
      <span class="presentation" translate>{{ selectedItemLabel }}</span>
    </ng-container>
  `,
  providers: [STRING_COMBO_CONTROL_VALUE_ACCESSOR],
})
export class StringComboComponent implements ControlValueAccessor {
  @Input() nullLabel: String;
  @Input() nullLabelKey: String;
  @Input() disabled: any;
  @Input() items: ComboItem[];
  @Input() camelCase: boolean;
  @Input() presentationMode = false;
  @Input() divider: String;
  @Input() dividerDecorator = '-';
  @Input() dividerKey: String;
  @Input() preSelectedValue: String;

  @Output() changeItem = new EventEmitter<string>();

  private onChangeModel: Function;
  private onTouchedModel: Function;
  private _selectedKey: string;

  get selectedKey(): string {
    return this.normalizedString(this._selectedKey);
  }

  get selectedItemLabel(): string {
    const labels = this.items.filter((item) => item.value === this.selectedKey).map((item) => item.label);
    return labels.length > 0 ? labels[0] : '';
  }

  normalizedString(s: string) {
    if (s === 'undefined') {
      return undefined;
    }
    return s;
  }

  set selectedKey(k: string) {
    this._selectedKey = String(k);
    this.onChangeModel(this.normalizedString(this._selectedKey));
    // this.onChange.emit(this._selectedKey);
  }

  writeValue(it: any): void {
    this._selectedKey = it + '';
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedModel = fn;
  }

  onChange(event: any) {
    this.selectedKey = event.target.value;
    this.changeItem.emit(this.normalizedString(event.target.value));
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  isSelected(value: string) {
    return this._selectedKey === value || this.preSelectedValue === value;
  }
}

export class ComboItem {
  constructor(public value: string, public label: string) {}
}
