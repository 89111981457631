import {Injectable} from '@angular/core';
import {LoggedUserService} from './logged-user.service';
import {saveAs} from 'file-saver-es';
import {ErrorReason} from '../model';

@Injectable()
export class BinaryDownloaderService {
  public pending = false;

  constructor(private _loggedUserService: LoggedUserService) {}

  public download(
    method: string,
    urlStr: string,
    inputContentType: string,
    outputContentType: string,
    outputFileName: string,
    requestObject?: any,
    errorCallback?: ((errMsg: string) => void) | ((errReasons: ErrorReason[]) => void),
    completeCallback?: (file: File) => void
  ) {
    console.log('download: ' + urlStr);
    const self = this;
    this.pending = true;
    const xhr = this.createXMLHttpRequest(method, urlStr, inputContentType);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        self.pending = false;
        if (xhr.status === 200) {
          const blob = new Blob([this.response], {type: outputContentType});
          console.log('BinaryDownloaderService response: ' + xhr.status + ' response ', this.response);
          // tslint:disable-next-line:no-unused-expression
          completeCallback && completeCallback(new File([blob], outputFileName));
          saveAs(blob, outputFileName);
        } else {
          console.log('BinaryDownloaderService error ' + xhr.status + ' response ', this.response);
          self.errorHandler(this.response, errorCallback);
        }
      }
    };
    if (requestObject) {
      xhr.send(JSON.stringify(requestObject));
    } else {
      xhr.send();
    }
  }

  errorHandler(res: Blob, errorCallback?: (errMsg: string | ErrorReason[]) => void) {
    if (!errorCallback) {
      return;
    }
    if (!(res instanceof Blob)) {
      errorCallback(res);
      return;
    }

    const reader = new FileReader();
    // This fires after the blob has been read/loaded.
    reader.addEventListener('loadend', (e) => {
      // const text = e.srcElement.textContent; // .result;
      // const text = e.target.result; //
      // Dont know what to do with arraybuffer
      const text = reader.result;
      if (typeof text === 'string') {
        const json = JSON.parse(text);
        if (json instanceof Array) {
          errorCallback(json);
        } else {
          errorCallback(text);
        }
      }
    });

    // Start reading the blob as text.
    reader.readAsText(res);
  }

  public getBinary(
    method: string,
    urlStr: string,
    inputContentType: string,
    outputContentType: string,
    onLogoFileChanged: any
  ) {
    const self = this;
    this.pending = true;
    const xhr = this.createXMLHttpRequest(method, urlStr, inputContentType);
    xhr.onreadystatechange = function () {
      setTimeout(() => {
        self.pending = false;
      }, 0);
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const blob = new Blob([this.response], {type: outputContentType});
          if (onLogoFileChanged !== undefined) {
            onLogoFileChanged.call(this, URL.createObjectURL(blob));
          }
        }
      }
    };
    xhr.send();
  }

  private createXMLHttpRequest(method: string, url: string, inputContentType: string): XMLHttpRequest {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.open(method, url, true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + this._loggedUserService.accessToken);
    xhr.setRequestHeader('Content-Type', inputContentType);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    return xhr;
  }
}
