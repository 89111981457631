import {Observable} from 'rxjs';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {InvoiceCriteriaDto, SearchCriteria} from '../model/dtos';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';

@Injectable()
export class InvoiceMassService extends AbstractService {
  protected url = this.urlPrefix + 'invoiceMass';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  writeAllOff(invoiceCriteria: SearchCriteria<InvoiceCriteriaDto>): Observable<number> {
    console.log('invoiceMass/writeAllOff: http = ', this.http);
    return this.post1<SearchCriteria<InvoiceCriteriaDto>, number>(invoiceCriteria, this.url + '/writeAllOff');
  }
}
