import {map} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import {
  BusinessPropertiesService,
  DashboardService,
  DictionaryBaseDto,
  SettingsService,
  StringUtils,
} from '../../../../bonding_shared';
import {ChartComponent} from '../chart-component';
import {ChartRestLoader} from '../chart-rest-loader';
import {ChartUtils} from '../chart-utils';
import {ChartConfiguration, ChartData, ChartType} from 'chart.js';
import {ApplicationModule} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'donut-chart',
  templateUrl: 'donutchart.component.pug',
})
export class DonutChartComponent extends ChartComponent implements OnInit, ChartRestLoader {
  @Input() set businessUnit(businessUnit: DictionaryBaseDto) {
    this._businessUnit = businessUnit;
    this.setActiveOrDefaultModule();
    this.loadRestData();
  }

  readonly MAX_ELEMENT_NUM = 10;

  _businessUnit: DictionaryBaseDto;

  numbers: number[] = [];
  values: number[] = [];
  types: string[] = [];

  public doughnutChartData: ChartData<'doughnut'>;
  public doughnutChartType: ChartType = 'doughnut';
  public chartOptions: ChartConfiguration['options'];
  initialized = false;

  constructor(
    private service: DashboardService,
    public settingsService: SettingsService,
    public businessProps: BusinessPropertiesService
  ) {
    super(businessProps, settingsService);
    this.initChartOptions();
  }

  initChartOptions() {
    const currencyCode = this.currency;
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const label = context.label || '';
              const value = <number>context.dataset.data[context.dataIndex];
              const sum = (<number[]>context.dataset.data).reduce((a, b) => a + b, 0);
              const ratio = value / sum;
              const valueF = StringUtils.formatNumber(value);
              const ratioF = StringUtils.formatNumber(ratio * 100);
              return label + ': ' + valueF + ' ' + currencyCode + ', ' + ratioF + ' %';
            },
          },
        },
      },
    };
  }

  loadData() {
    const numElements = this.types.length > this.MAX_ELEMENT_NUM ? this.MAX_ELEMENT_NUM : this.types.length;
    this.doughnutChartData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            ChartUtils.COLORS[2],
            ChartUtils.COLORS[1],
            ChartUtils.COLORS[0],
            ChartUtils.COLORS[3],
            ChartUtils.COLORS[4],
            ChartUtils.COLORS[5],
            ChartUtils.COLORS[6],
            ChartUtils.COLORS[7],
            ChartUtils.COLORS[8],
            ChartUtils.COLORS[9],
          ],
        },
      ],
    };
    for (let i = 0; i < numElements; i++) {
      this.doughnutChartData.labels.push(this.types[i]);
      this.doughnutChartData.datasets[0].data.push(this.values[i]);
    }
    this.initialized = true;
  }

  loadRestData() {
    this.initialized = false;
    this.types = [];
    this.numbers = [];
    this.values = [];

    this.service
      .getTypeChartData(this._businessUnit, this.applicationModuleId)
      .pipe(map((results: Array<any>) => this.mapData(results)))
      .subscribe(
        () => console.log('Empty subscribe'),
        (error) => console.log('Error occurred while getting Donut Chart data'),
        () => this.loadData()
      );
  }

  mapData(results: Array<any>) {
    if (!results) {
      return;
    }
    switch (this.applicationModuleId) {
      case ApplicationModule.BONDING:
        results.sort((a, b) => (b.bondsValue > a.bondsValue ? 1 : -1));
        results.forEach((result) => {
          this.types.push(result.bondTypeLabel);
          this.numbers.push(result.bondsNumber);
          this.values.push(result.bondsValue);
        });
        break;
      case ApplicationModule.POLICY:
        results.forEach((result) => {
          this.types.push(result.policyType.name);
          this.numbers.push(result.policiesNumber);
          this.values.push(result.premium);
        });
        break;
      case ApplicationModule.CLAIM:
        results.forEach((result) => {
          this.types.push(result.claimRiskType.name);
          this.numbers.push(result.claimsNumber ? result.claimsNumber : 0);
          this.values.push(result.reserve ? result.reserve : 0);
        });
        break;
    }
  }
}
