import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {
  Page,
  SearchCriteria,
  SearchResult,
  UserHolidaySettingsCriteriaDto,
  UserHolidaySettingsDto,
  UserSimpleDto,
} from '../model';
import {Observable} from 'rxjs';
import {AvailableYear} from '../../bonding/hr/user-holiday-settings-details.component';

@Injectable()
export class UserHolidaySettingsService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'userHolidaySettings';
  }

  public getUserHolidaySettings(userId: number): Observable<SearchResult<UserHolidaySettingsDto>> {
    const criteria = <SearchCriteria<UserHolidaySettingsCriteriaDto>>{};
    criteria.criteria = <UserHolidaySettingsCriteriaDto>{};
    criteria.criteria.user = <UserSimpleDto>{id: userId};
    criteria.page = <Page>{start: 0, count: 300};
    return this.searchByCriteria(criteria);
  }

  public prepareAvailableYears(): AvailableYear[] {
    const y = new Date().getFullYear();
    const ay: AvailableYear[] = [];
    for (let i = 2022; i <= y; i++) {
      ay.push({id: i, name: i.toString()});
    }
    return ay;
  }
}
