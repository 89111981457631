import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SalesRepSearchComponent} from './sales-rep-search.component';
import {SalesRepDetailsComponent} from './sales-rep-details.component';
import {SalesRepGuiService} from './services/sales-rep-gui.service';
import {ContractSharedModule} from '../bonding-contract/contract/components/shared/contract-shared.module';
import {PolicyContractSharedModule} from '../policy/components/shared/policy-contract-shared.module';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {SalesRepPreviewComponent} from './sales-rep-preview.component';
import {SalesRepRelationDetailsComponent} from './sales-rep-relation-details.component';
import {SalesRepSharedModule} from './components/shared/sales-rep-shared.module';
import {InquirySharedModule} from '../inquiry/shared/inquiry-shared.module';
import {SalesRepDetailsGuard, SalesRepPreviewGuard, SalesRepRelationDetailsGuard, SalesRepSearchGuard} from './_guards';
import {BondingContractInquirySharedModule} from '../bonding-contract/inquiry/components/shared/bonding-contract-inquiry-shared.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    ContractSharedModule,
    PolicyContractSharedModule,
    SalesRepSharedModule,
    InquirySharedModule,
    BondingContractInquirySharedModule,
  ],
  declarations: [
    SalesRepSearchComponent,
    SalesRepDetailsComponent,
    SalesRepPreviewComponent,
    SalesRepRelationDetailsComponent,
  ],
  exports: [],
  providers: [
    SalesRepGuiService,
    SalesRepDetailsGuard,
    SalesRepPreviewGuard,
    SalesRepRelationDetailsGuard,
    SalesRepSearchGuard,
  ],
})
export class SalesRepModule {}
