import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {DocumentModule} from '../document/document.module';
import {TranslateModule} from '@ngx-translate/core';
import {GroupSearchComponent} from './group-search.component';
import {GroupGuiService} from './services/group-gui.service';
import {GroupPreviewComponent} from './group-preview.component';
import {GroupMemberListComponent} from './components/group-member-list.component';
import {GroupDetailsComponent} from './group-details.component';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {GroupSelectorSimpleComponent} from './components/shared/group-selector-simple.component';
import {CompanyGroupTreeComponent} from './company-group-tree.component';
import {TreeModule} from 'primeng/tree';
import {CompanyGroupAccessGuard} from '../company/_guards/company-group-access.guard';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    BsDropdownModule,
    DocumentModule,
    TranslateModule,
    TreeModule,
  ],
  declarations: [
    GroupSearchComponent,
    GroupPreviewComponent,
    GroupDetailsComponent,
    GroupMemberListComponent,
    GroupSelectorSimpleComponent,
    CompanyGroupTreeComponent,
  ],
  exports: [GroupSearchComponent, GroupSelectorSimpleComponent],
  providers: [GroupGuiService, CompanyGroupAccessGuard],
})
export class GroupModule {}
