import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {DictionaryBaseDto} from '../model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class NaceCodeService extends AbstractService {
  protected url = this.urlPrefix + this.portalPrefix + 'naceCode';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getIndustry(naceCodeId: number): Observable<DictionaryBaseDto> {
    return this.get<DictionaryBaseDto>(this.url + '/' + naceCodeId + '/industry');
  }
}
