import {ChartDataset} from 'chart.js';
import {ChartData} from './chart-data';
import {ChartUtils} from '../chart-utils';
import {TranslateService} from '@ngx-translate/core';

export class LimitChartData extends ChartData {
  activeLimits: number[];
  newLimits: number[];
  exposure: number[];
  newExposure: number[];

  constructor(private translateService: TranslateService) {
    super();
  }

  colors = [
    {backgroundColor: ChartUtils.COLORS[2]},
    {backgroundColor: ChartUtils.COLORS[0]},
    {backgroundColor: ChartUtils.COLORS[1]},
    {backgroundColor: ChartUtils.COLORS[3]},
  ];

  buildChartData(): ChartDataset[] {
    return [
      {
        data: this.activeLimits,
        label: this.translateService.instant('limitDashboard.activeLimits'),
        yAxisID: 'yAxisLeft',
      },
      {data: this.newLimits, label: this.translateService.instant('limitDashboard.newLimits'), yAxisID: 'yAxisLeft'},
      {
        data: this.exposure,
        label: this.translateService.instant('limitDashboard.exposure'),
        type: 'line',
        yAxisID: 'yAxisRight',
        fill: false,
        borderColor: ChartUtils.COLORS[1],
        pointBorderColor: ChartUtils.COLORS[1],
        pointBackgroundColor: ChartUtils.COLORS[1],
      },
      {
        data: this.newExposure,
        label: this.translateService.instant('limitDashboard.newExposure'),
        yAxisID: 'yAxisRight',
      },
    ];
  }

  clear() {
    this.activeLimits = [];
    this.newLimits = [];
    this.exposure = [];
    this.newExposure = [];
    this.dates = [];
  }

  mapData(results: Array<any>) {
    console.log('limit results', results);
    if (!results) {
      return;
    }
    results.forEach((result) => {
      this.dates.push(result.month + '-' + result.year);
      this.activeLimits.push(result.activeLimits);
      this.newLimits.push(result.newLimits);
      this.exposure.push(result.exposure);
      if (result.newExposure) {
        this.newExposure.push(result.newExposure);
      } else {
        this.newExposure.push(0);
      }
    });
  }
}
