import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {BiReportListComponent} from './components';
import {BiReportSearchComponent} from './bi-report-search.component';
import {BiReportGuiService} from './services/bi-report-gui.service';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
  declarations: [BiReportListComponent, BiReportSearchComponent],
  exports: [BiReportListComponent],
  providers: [BiReportGuiService],
})
export class BiReportModule {}
