import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PortalDeclarationService, PortalPolicyContractService, PortalRouterService} from '../services';
import {TranslateService} from '@ngx-translate/core';
import {
  AppConfigService,
  BusinessPropertiesService,
  DeclarationFileService,
  FormatService,
  GrowlService,
  LoggedUserService,
} from '../../bonding_shared/services';
import {
  DeclarationBuyerCriteriaDto,
  DeclarationDto,
  Page,
  PolicyContractSimpleIdDataDto,
} from '../../bonding_shared/model';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {
  DeclarationCriteria,
  DeclarationDetailsView,
  DeclarationParams,
} from '../../bonding/declaration/shared/declaration-details-view';
import {NgForm} from '@angular/forms';
import {BusinessReportPortalService} from '../services/portal-business-report.service';
import {DeclarationHistoryCriteriaComponent} from './declaration-history-criteria.component';

@Component({
  selector: 'client-declaration-history-details',
  templateUrl: './client-declaration-history-details.component.pug',
})
export class ClientDeclarationHistoryDetailsComponent extends DeclarationDetailsView implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private routerService: PortalRouterService,
    public appService: AppConfigService,
    public policyContractService: PortalPolicyContractService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected portalDeclarationService: PortalDeclarationService,
    protected loggedUserService: LoggedUserService,
    private businessReportPortalService: BusinessReportPortalService,
    protected businessPropertyService: BusinessPropertiesService,
    protected formatService: FormatService,
    protected declarationFileService: DeclarationFileService
  ) {
    super(
      translateService,
      growlService,
      portalDeclarationService,
      loggedUserService,
      businessReportPortalService,
      appService,
      businessPropertyService,
      formatService,
      declarationFileService
    );
  }

  @ViewChild(DeclarationHistoryCriteriaComponent, {static: true})
  criteriaComponent: DeclarationHistoryCriteriaComponent;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  ngOnInit() {
    this.form = this.ngForm.form;
    if (this.appService.kuke || this.appService.ecg) {
      this.route.params.subscribe((params) => {
        this.dc = <DeclarationCriteria>{buyerCriteria: <DeclarationBuyerCriteriaDto>{}};
        this.clearEntryValuesMap();
        this.criteriaComponent.initHistoryByPortal(params['outstandings'] === 'true');
      });
    }
  }
  onPolicyChanged() {
    this.initializeOrReloadDeclarationHistory(this.dc.selectedPolicy, undefined);
  }

  onDeclarationCriteriaTouchedEvent() {
    this.inProgress_flag = true;
    this.criteriaComponent.amendmentBlocked_flag = undefined;
    this.clearDeclaration();
  }

  onDeclarationCriteriaChangedEvent(reloadDeclaration: boolean) {
    if (reloadDeclaration) {
      if (this.dc.selectedRp && this.dc.selectedLlv && this.dc.selectedDecOn) {
        this.resetSearchCriteriaWithoutPageAndBaseDeclaration(this.dc);
        this.initOrFindExistingDeclarationBySearchCriteria(true, false);
      } else {
        this.setDeclarationUndefined();
      }
    }
    this.applyFiltersOnDeclarationHistory();
  }

  // ############## GUI CONTROL ##############
  updateCanUpdateFlag() {
    this.canUpdate_flag = this.declaration && !this.declaration.id;
    this.computeLastConfirmedDeclaration(this.criteriaComponent.singleFinalConfirmedDeclarationsForPolicy);
  }

  onPageChange(event: PageChangedEvent) {
    this.resetPage(<Page>{start: (event.page - 1) * event.itemsPerPage, count: event.itemsPerPage});
    this.initOrFindExistingDeclarationBySearchCriteria(false);
  }

  // ############## DECLARATION HISTORY CONTROL ##############
  private initializeOrReloadDeclarationHistory(policy: PolicyContractSimpleIdDataDto, selectedDeclarationId?: number) {
    if (this.declarationHistorySectionComponent) {
      this.declarationHistorySectionComponent.initializeOrReloadDeclarationHistory(
        policy,
        this.dc.selectedRp,
        this.dc.selectedLlv,
        this.dc.selectedDecOn,
        selectedDeclarationId
      );
    }
  }

  public onDeclarationSelectFromHistory(declarationStub: DeclarationDto) {
    this.criteriaComponent.clearSelections();
    this.onDeclarationCriteriaTouchedEvent();
    if (declarationStub && declarationStub.id) {
      this.resetSearchCriteriaForDeclaration(declarationStub);
      this.resetPage();
      this.blockDeclarationSection();
      this.portalDeclarationService.findOrInitializeDeclarationByCriteria(this.searchCriteria).subscribe((tSR) => {
        this.reloadDeclarationSearchResult(tSR, true);
        this.criteriaComponent.reloadLlVAndDecOnListsAndSetParamsSelections(
          this.declarationToDeclarationParams(this.declaration)
        );
      });
    }
  }

  // same as in policy-details => move to helper od dc
  private declarationToDeclarationParams(declaration: DeclarationDto) {
    if (declaration) {
      return <DeclarationParams>{
        paramRpId: declaration.reportingPeriod ? declaration.reportingPeriod.id : undefined,
        paramLlvId: declaration.limitListVersion ? declaration.limitListVersion.id : undefined,
        paramDecOnId: declaration.declaredOn ? declaration.declaredOn.id : undefined,
      };
    } else {
      return undefined;
    }
  }

  onFilteringChanged() {
    this.resetSearchCriteriaWithoutPageAndBaseDeclaration(this.dc);
    this.onFilteringChangedInnerDeclaration();
  }
}
