import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'item-grouped-dropdown',
  template: `
    <div *ngIf="items && items.length > 0" class="btn-group" dropdown [class.dropup]="dropup" [isDisabled]="disabled">
      <button type="button" [ngClass]="['dropdown-toggle', buttonClass]" dropdownToggle>
        {{ caption | translate }} <span class="caret"></span>
      </button>
      <ul [class]="'dropdown-menu dropdown-menu-' + alignment" role="menu">
        <span *ngFor="let item of items">
          <li
            *ngIf="isVisible(item) && item.children.length === 1"
            class="dropdown-item"
            (click)="click(item.children[0])"
          >
            {{ item.children[0].name | translate }}
          </li>
          <li class="dropdown-submenu" *ngIf="isVisible(item) && item.children.length > 1">
            <a class="dropdown-item">{{ item.name | translate }}</a>
            <ul class="dropdown-menu" role="menu">
              <li *ngFor="let child of item.children" role="menuitem">
                <a class="dropdown-item" (click)="click(child)">{{ child.name | translate }}</a>
              </li>
            </ul>
          </li>
        </span>
      </ul>
    </div>
  `,
})
export class ItemGroupedDropdownComponent {
  @Input() caption: string;
  @Input() disabled = false;
  @Input() dropup = false;
  @Input() label = 'name';
  @Input() buttonClass = 'bon-btn-info';
  @Input() alignment: 'left' | 'right' = 'left';
  @Input() items: GroupedItem[] = [];
  @Input() excludedTypeIds: number[] = [];
  @Output() menuSelect = new EventEmitter<GroupedItem>();

  click(item: GroupedItem) {
    this.menuSelect.emit(item);
  }

  isVisible(item: GroupedItem): boolean {
    return !this.excludedTypeIds.includes(item.typeId);
  }
}

export interface GroupedItem {
  name: string;
  children?: GroupedItem[];
  id?: number;
  typeId?: number;
  params?: {key: string; value: any}[];
}
