import {BusinessReportGenerationComponent} from './business-report-generation.component';
import {BusinessReportGenerateGuard} from './_guards';
/**
 * Created by siminski on 09.12.2016.
 */
export const BusinessReportRoutes = [
  {
    path: 'business-report-generation',
    component: BusinessReportGenerationComponent,
    canActivate: [BusinessReportGenerateGuard],
  },
];
