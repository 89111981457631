import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollectionDetailsComponent} from './collection-details.component';
import {CollectionSearchComponent} from './collection-search.component';
import {CollectionGuiService} from './services/collection-gui.service';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {PolicyContractSharedModule} from '../policy/components/shared/policy-contract-shared.module';
import {ContractSharedModule} from '../bonding-contract/contract/components/shared/contract-shared.module';
import {CollectionSelectorsComponent} from './components/collection-selectors.component';
import {CollectionPaymentsComponent} from './collection-payments.component';
import {CollectionPaymentScheduleComponent} from './collection-payment-schedule.component';
import {ClaimSharedModule} from '../claim/components/shared/claim-shared.module';
import {CollectionPaymentsTableComponent} from './components/collection-payments-table.component';
import {CollectionQuestionnaireComponent} from './components/collection-questionnaire.component';
import {CollectionRequestSectionComponent} from './components/collection-request-section.component';
import {ThirdPartyModule} from '../thirdparty/thirdparty.module';
import {CollectionExternalProviderRelationsComponent} from './collection-external-provider-relations.component';
import {ExternalProviderSharedModule} from '../external-provider/components/shared/external-provider-shared.module';
import {BondSharedModule} from '../bond/components/shared/bond-shared.module';
import {ClientPolicySharedModule} from '../../portal/policy/shared/client-policy-shared.module';
import {CollectionSharedModule} from './components/shared/collection-shared.module';
import {CollectionBalanceSummaryComponent} from './components/collection-balance-summary.component';
import {CollectionEstimatedCostsComponent} from './collection-estimated-costs.component';
import {CollectionAccountedCostsComponent} from './collection-accounted-costs.component';
import {CollectionInvoicesComponent} from './components/collection-invoices.component';
import {BusinessUnitModule} from '../business-unit/business-unit.module';
import {CollectionRequestsComponent} from './collection-requests.component';
import {CollectionHistoryComponent} from './collection-history.component';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {CollectionCostsInvoicingComponent} from './collection-costs-invoicing.component';
import {CollectionRelatedCasesComponent} from './collection-related-cases.component';
import {BusinessObjectInfoModule} from '../business-object-info/business-object-info.module';
import {
  CollectionCostsGuard,
  CollectionDetailsGuard,
  CollectionExternalProviderRelationsGuard,
  CollectionHistoryGuard,
  CollectionPaymentScheduleGuard,
  CollectionPaymentsGuard,
  CollectionRelatedCasesGuard,
  CollectionRequestsGuard,
  CollectionSearchGuard,
} from './_guards';
import {CollectionRecoveryBondingPaymentsSummaryComponent} from './components/collection-recovery-bonding-payments-summary';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    PolicyContractSharedModule,
    ContractSharedModule,
    ClaimSharedModule,
    ThirdPartyModule,
    ExternalProviderSharedModule,
    BondSharedModule,
    ClientPolicySharedModule,
    BusinessUnitModule,
    PaginationModule,
    BusinessObjectInfoModule,
    CollectionSharedModule,
  ],
  declarations: [
    CollectionSearchComponent,
    CollectionDetailsComponent,
    CollectionRelatedCasesComponent,
    CollectionSelectorsComponent,
    CollectionPaymentsComponent,
    CollectionPaymentScheduleComponent,
    CollectionPaymentsTableComponent,
    CollectionQuestionnaireComponent,
    CollectionRequestSectionComponent,
    CollectionExternalProviderRelationsComponent,
    CollectionInvoicesComponent,
    CollectionBalanceSummaryComponent,
    CollectionEstimatedCostsComponent,
    CollectionAccountedCostsComponent,
    CollectionCostsInvoicingComponent,
    CollectionRequestsComponent,
    CollectionHistoryComponent,
    CollectionRecoveryBondingPaymentsSummaryComponent,
  ],
  exports: [],
  providers: [
    CollectionGuiService,
    CollectionCostsGuard,
    CollectionDetailsGuard,
    CollectionExternalProviderRelationsGuard,
    CollectionHistoryGuard,
    CollectionPaymentScheduleGuard,
    CollectionPaymentsGuard,
    CollectionRelatedCasesGuard,
    CollectionRequestsGuard,
    CollectionSearchGuard,
  ],
})
export class CollectionModule {}
