import {Component, Input, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  CompanyBaseDto,
  PolicySponsorDto,
  RouterService,
} from '../../../../../bonding_shared';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';

@Component({
  selector: 'sponsors-section',
  templateUrl: './sponsors-section.component.pug',
})
export class SponsorsSectionComponent extends Section {
  @ViewChild('sponsorsList', {static: true}) sponsorsList: ATableComponent<PolicySponsorDto>;
  self = this;

  @Input() showSelected = false;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    public router: RouterService
  ) {
    super(policyContractViewService, appService);
  }

  addPolicySponsor(company: CompanyBaseDto) {
    this.sponsorsList.onAddItem(<PolicySponsorDto>{company: company});
  }
}
