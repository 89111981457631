import {Component} from '@angular/core';
import {Section} from '../section';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {AppConfigService, TreatyVersionService} from '../../../../../bonding_shared/services';
import {CompanySimpleDto} from '../../../../../bonding_shared/model';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'risk-assessment-section',
  templateUrl: './risk-assessment-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class RiskAssessmentSectionComponent extends Section {
  self = this;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected treatyService: TreatyVersionService
  ) {
    super(policyContractViewService, appService);
  }

  onSelectRiskAssessmentAgency(riskAssessmentAgency: CompanySimpleDto) {
    this.policy.riskAssessmentAgency = riskAssessmentAgency;
    delete this.activeList;
  }
}
