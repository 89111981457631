import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {
  MasterPolicyContractForLimitDto,
  ProductLimitListVersionCriteriaDto,
  ProductLimitListDto,
  ProductLimitListVersionSimpleDto,
} from '../../../bonding_shared/model/dtos';
import {PolicyLimitListService} from '../../../bonding_shared/services/policy-limit-list.service';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';

export class PolicyLimitListSearchCriteria {
  dataProvider: SearchDataProvider<ProductLimitListVersionCriteriaDto, ProductLimitListVersionSimpleDto>;
  masterPolicy: MasterPolicyContractForLimitDto;

  constructor(private policyLimitListService: PolicyLimitListService) {
    this.dataProvider = BusinessUtils.createPolicyLimitListDataProvider(policyLimitListService);
    this.dataProvider.searchCriteria.criteria.last = true;
    this.dataProvider.textSearch = false;
  }

  setPolicy(mpc: MasterPolicyContractForLimitDto) {
    this.dataProvider.searchCriteria.criteria.limitList.masterPolicyContract.id = mpc ? mpc.id : undefined;
    this.masterPolicy = mpc;
  }
}
