import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {
  BIReportCriteriaDto,
  BIReportSimpleDto,
  CompanyCriteriaDto,
  CompanyRawDto,
  ErrorReason,
  ThirdPartyBaseDto,
  ThirdPartyDto,
} from '../../../bonding_shared/model/dtos';
import {RouterService, View} from '../../../bonding_shared/services/router-service';
import {ActivatedRoute} from '@angular/router';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {GrowlService} from '../../../bonding_shared/services/growl/growl.service';
import {
  AppConfigService,
  CacheService,
  LoggedUserService,
  SearchDataProvider,
} from '../../../bonding_shared/services/index';
import {ThirdPartyService} from '../../../bonding_shared/services/thirdparty.service';
import {
  BusinessObjectType,
  CompanyElementaryRight,
  CompanyRawStatus,
  LegalForm,
} from '../../../bonding_shared/model/dictionary-ids';
import {Button} from '../../../bonding_shared/components/details-view/button';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {CompanyViewParams} from '../../company';
import {BIReportService} from '../../../bonding_shared/services/bi-report.service';

@Component({
  selector: 'thirdparty-details',
  templateUrl: './thirdparty-details.component.pug',
})
export class ThirdPartyDetailsComponent extends DetailsView implements OnInit {
  id: number;
  portalMode = false;
  thirdParty: ThirdPartyDto;
  rawCompany: CompanyRawDto;
  rawCompanies: CompanyRawDto[];
  companySettled = true;
  orderReportButton: Button;
  createCompanyButton: Button;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  companyChanged = false;

  companySearchCriteria = <CompanyCriteriaDto>{address: {}};

  reportsDataProvider: SearchDataProvider<BIReportCriteriaDto, BIReportSimpleDto>;

  readonly LegalForm = LegalForm;
  readonly BusinessObjectType = BusinessObjectType;
  cachedWarnings: ErrorReason[];

  constructor(
    private _route: ActivatedRoute,
    private thirdPartyService: ThirdPartyService,
    public appService: AppConfigService,
    private cacheService: CacheService,
    private biReportService: BIReportService,
    public router: RouterService,
    private loggedUserService: LoggedUserService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.deleteButton = undefined;

    this.portalMode = this.loggedUserService.portal;

    this.initializeSelectorEmittersByNames(true, ['Company']);

    this.orderReportButton = new Button('thirdParty.details.orderReport', this.onOrderReport.bind(this), true, false);
    this.createCompanyButton = new Button(
      'thirdParty.details.createCompany',
      this.createCompany.bind(this),
      true,
      false
    );
  }

  ngOnInit() {
    this.commonInit<ThirdPartyViewParams>(this._route);
    this.form = this.ngForm.form;
  }

  initializeReportDataProvider() {
    this.reportsDataProvider = BusinessUtils.createBiReportDataProvider(this.biReportService);
    this.reportsDataProvider.searchCriteria.criteria.thirdParty.id = this.id;
  }

  initViewWithId(id: number, force = true) {
    this.setThirdParty(id);
  }

  initViewWithCustomParams(q: ThirdPartyViewParams) {
    this.setThirdParty(+q.id, +q.newCompanyId);
  }

  setThirdParty(id: number, newCompanyId?: number) {
    this.id = id;
    this.initializeReportDataProvider();
    this.thirdPartyService.getById<ThirdPartyDto>(id).subscribe({
      next: (tp) => {
        this.companyChanged = false;
        this.thirdParty = tp;
        this.setRawCompanies();
        this.handleButtons();
        this.updateErrors(this.cachedWarnings);
        this.cachedWarnings = undefined;
        if (newCompanyId) {
          this.companySearchCriteria.id = newCompanyId;
          setTimeout(() => this.openSelectorEmitters['Company'].next(true));
        }
      },
      error: () => (this.objectNotFound = true),
    });
  }

  onSave() {
    console.log('onSave thirdParty::  form = ', this.form);
    this.clearErrors();
    if (this.formValidates()) {
      this.inProgress = true;
      this.thirdPartyService.update(this.thirdPartyUpdate).subscribe({
        next: (tp) => {
          this.afterSaveOrOrder(tp, false);
        },
        error: (error) => this.handleServerError(error),
      });
    }
  }

  afterSaveOrOrder(tp: ThirdPartyDto, order: boolean) {
    this.clearErrors();
    this.inProgress = false;
    if (tp.targetThirdParty != null) {
      console.log('third party saved and moving to same:', tp);
      this.cachedWarnings = tp.warnings;
      this.router.toThirdPartyDetails(tp.targetThirdParty.id);
      this.showSavedMsg('thirdParty.details.mergedObjectWarning');
    } else {
      this.thirdParty = tp;
      this.companyChanged = false;
      this.setRawCompanies();
      console.log('third party saved or ordered:', tp);
      this.showSavedMsg();
      this.handleButtons();
      if (order) {
        this.reportsDataProvider.search();
      }
    }
  }

  get thirdPartyUpdate(): ThirdPartyBaseDto {
    const updateObject = <ThirdPartyBaseDto>{
      id: this.thirdParty.id,
      company: this.thirdParty.company,
      newCompanyRaw: this.thirdParty.newCompanyRaw,
      version: this.thirdParty.version,
    };
    updateObject.newCompanyRaw = this.rawCompany && !this.rawCompany.id ? this.rawCompany : undefined;
    return updateObject;
  }

  onSelectCompany() {
    this.companyChanged = true;
    this.rawCompany = this.thirdParty.newCompanyRaw;
    this.handleButtons();
  }

  selectCompanyRaw(cRaw: CompanyRawDto) {
    this.rawCompany = cRaw;
    this.thirdParty.newCompanyRaw = undefined;
    this.handleButtons();
  }

  handleButtons() {
    this.saveButton.hidden =
      !this.thirdParty ||
      (!this.thirdParty.company && !this.thirdParty.newCompanyRaw) ||
      (this.thirdParty.company && !this.companyChanged);
    this.cancelButton.hidden = this.saveButton.hidden;
    this.orderReportButton.hidden =
      this.portalMode ||
      !this.rawCompany ||
      !this.rawCompany.dataStatus ||
      (this.rawCompany.dataStatus.id !== CompanyRawStatus.TO_BE_VERIFIED &&
        this.rawCompany.dataStatus.id !== CompanyRawStatus.ERROR);
    this.createCompanyButton.hidden = this.orderReportButton.hidden;
  }

  setRawCompanies() {
    if (!this.thirdParty) {
      return;
    }
    this.companySettled = !!this.thirdParty.company;
    this.rawCompanies = [];
    this.thirdParty.provisionalThirdParties.forEach(
      (p) => (this.rawCompanies = this.rawCompanies.concat(p.rawCompanies))
    );
    this.rawCompany = this.rawCompanies.length > 0 ? this.rawCompanies[this.rawCompanies.length - 1] : undefined;
  }

  onCancel() {
    this.commonInit<ThirdPartyViewParams>(this._route);
  }

  toBusinessObjectDetails() {
    this.router.goToBusinessObject(this.originBusinessObject.relatedTo.id, this.originBusinessObject.relatedToId);
  }

  get originBusinessObject() {
    const provisionalTP =
      !this.thirdParty || !this.rawCompany || !this.thirdParty.provisionalThirdParties
        ? undefined
        : this.thirdParty.provisionalThirdParties.find(
            (pth) => !!pth.rawCompanies.find((rc) => rc.id === this.rawCompany.id)
          );
    return provisionalTP ? provisionalTP.originBusinessObject : undefined;
  }

  onOrderReport() {
    this.inProgress = true;
    this.thirdPartyService.orderReport(this.thirdParty.id).subscribe({
      next: (tp) => {
        this.afterSaveOrOrder(tp, true);
      },
      error: (error) => this.handleServerError(error),
    });
  }

  createCompany() {
    this.cacheService.companyInCreation = BusinessUtils.companyRawToCompany(this.rawCompany);
    this.router.toCompanyDetailsWithParams(<CompanyViewParams>{
      returnViewId: View.THIRD_PARTY_DETAILS.id,
      returnObjectId: this.id,
      readFromCache: true,
    });
  }

  canOrderReport(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_BI_REPORT_ORDER);
  }

  prettyAddress(c: CompanyRawDto) {
    return BusinessUtils.prettyAddress(c);
  }
}

export class ThirdPartyViewParams {
  id?: number | string;
  newCompanyId?: number | string;
}
