import {Component} from '@angular/core';
import {DashboardService} from '../../../../bonding_shared/services/dashboard.service';
import {ChartRestLoader} from '../chart-rest-loader';
import {BusinessPropertiesService} from '../../../../bonding_shared/services/business-properties.service';
import {ApplicationModule} from '../../../../bonding_shared/model/dictionary-ids';
import {VMap} from './vmap';
import {SettingsService} from '../../../../bonding_shared/services';
import {StringUtils} from '../../../../bonding_shared/utils';

@Component({
  selector: 'policy-map',
  template: `<div id="{{mapType}}"  style="height: 400px;"></div>`
})
export class PolicyMapComponent extends VMap implements ChartRestLoader {

  constructor(public service: DashboardService, public businessProps: BusinessPropertiesService, public settingsService: SettingsService) {
    super(service, businessProps, settingsService);
    super.applicationModuleId = ApplicationModule.POLICY;
  }

  onLabelShowFunction(countryData: PolicyCountryPerformance, currency: string): (event: any, label: any, code: string) => void {
    function f(event: any, label: any, code: string): void {
      label.html(
        '<h2>' + ((label[0].firstChild.textContent) ? label[0].firstChild.textContent : label[0].innerHTML) + '</h2>' +
        '<p>Policy premium: ' + StringUtils.formatNumber(countryData[code] && countryData[code].premium ? countryData[code].premium : 0) + '&nbsp;' + currency + '</p>' +
        '<p>Policy premium percent: ' + (countryData[code] && countryData[code].premiumPercent ? countryData[code].premiumPercent : 0) + '</p>' +
        '<p>Policy count: ' + (countryData[code] && countryData[code].policiesNumber ? countryData[code].policiesNumber : 0) + '</p>' +
        '<p>Policies percent: ' + (countryData[code] && countryData[code].policiesNumberPercent ? countryData[code].policiesNumberPercent : 0) + '</p>'
      );
    }
    return f;
  }

  saveRestResult(result: any) {
    const code: string = result.country.code.toLowerCase();
    this.countryPerformance[code] = {
      premium: result.premium,
      premiumPercent: result.premiumPercent,
      policiesNumber: result.policiesNumber,
      policiesNumberPercent: result.policiesNumberPercent
    };
  }

  getPerformanceValues (policyCountryPerformance: PolicyCountryPerformance): {[key: string]: number} {
    const values: {[key: string]: number} = {};
    for (const k in policyCountryPerformance) {
      if (k) {
        values[k] = policyCountryPerformance[k].premium;
      }
    }
    return values;
  }
}

export class PolicyCountryPerformance {
  [code: string]: {
    premium: number;
    premiumPercent: number;
    policiesNumber: number;
    policiesNumberPercent: number;
  };
}
