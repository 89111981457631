/**
 * Created by siminski on 24.05.2016.
 */
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TaskGuiService} from '../services/index';
import {RouterService} from '../../../bonding_shared/services/router-service';
import {ATableComponent} from '../../../bonding_shared/components/aku-table/a-table.component';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {TaskCriteriaDto, TaskSimpleDto} from '../../../bonding_shared/model/dtos';
import {AppConfigService, FeatureService} from '../../../bonding_shared/services';
import {Feature} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'task-list',
  templateUrl: 'task-list.component.html',
})
export class TaskListComponent {
  @Input() dataProvider: SearchDataProvider<TaskCriteriaDto, TaskSimpleDto>;

  @Output() selected = new EventEmitter<TaskSimpleDto>();
  @Input() pageSize: number;

  @ViewChild('taskTable', {static: true}) taskTable: ATableComponent<TaskSimpleDto>;

  selectedItem: TaskSimpleDto;
  public showShortNameIfExists: boolean; // show short company name instead of registration name when it exists on search view

  constructor(
    public router: RouterService,
    private taskGuiService: TaskGuiService,
    public appConfig: AppConfigService,
    private featureService: FeatureService
  ) {
    this.showShortNameIfExists = featureService.get(Feature.COMPANY_SHOW_SHORT_NAME_IF_EXISTS);
  }

  gotoTaskDetail(task: TaskSimpleDto) {
    this.router.toTaskDetails(task.id);
  }

  onSelectedRow(task: TaskSimpleDto) {
    this.selectedItem = task;
    this.selected.emit(task);
  }

  goToBusinessObject(task: TaskSimpleDto) {
    this.taskGuiService.goToBusinessObject(task);
  }
}
