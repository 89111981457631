export class Button {
  disabled = false;
  hidden: boolean;
  title: string;
  name: string;
  icon: string;
  class: string;

  onClick: Function;

  constructor(
    name: string,
    onClick: Function,
    hidden: boolean,
    disabled?: boolean,
    icon?: string,
    clazz?: string,
    title?: string
  ) {
    this.name = name;
    this.title = title || '';
    this.onClick = onClick;
    this.hidden = hidden;
    this.disabled = disabled;
    if (icon) {
      this.icon = icon.startsWith('fa-') ? icon : 'fa-' + icon;
    }
    this.class = clazz;
  }
}
