import {Component} from '@angular/core';
import {
  AppConfigService,
  CompanySimpleDto,
  DictionaryBaseDto,
  PolicyContractCoInsurerCompanyDto,
  RouterService,
} from '../../../../../bonding_shared';
import {Section} from '../section';
import {PolicyContractCompanyRole} from '../../../../../bonding_shared/model/dictionary-ids';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';

@Component({
  selector: 'policy-co-insurers-section',
  templateUrl: './policy-co-insurers-section.component.pug',
})
export class PolicyCoInsurersSectionComponent extends Section {
  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected appService: AppConfigService,
    public router: RouterService
  ) {
    super(policyContractViewService, appService);
  }

  addCoInsurer(company: CompanySimpleDto) {
    this.policy.coInsurers.push(<PolicyContractCoInsurerCompanyDto>{
      role: <DictionaryBaseDto>{id: PolicyContractCompanyRole.CO_INSURER},
      company: company,
    });
  }
}
