import {Component, OnInit, ViewChild} from '@angular/core';
import {FormDialogComponent} from '../form-dialog';
import {FormDialogParent} from '../form-dialog/FormDialogParent';
import {Subscription, timer} from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';
import {Version} from '../../model/version';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'front-reload-warning',
  templateUrl: './front-reload-warning.component.pug',
})
export class FrontReloadWarningComponent extends FormDialogParent implements OnInit {
  @ViewChild(FormDialogComponent, {static: true}) reloadWarningDialog: FormDialogComponent;

  private logoutSubscription: Subscription;

  private frontCheckingSubscription: Subscription;
  private lastKnownVersion: string;

  constructor(private http: HttpClient) {
    super();
    this.form = new UntypedFormGroup({});
  }

  ngOnInit() {
    console.log('init front checking timer');
    const checkingTimer = timer(30 * 60000, 3 * 60000);
    this.frontCheckingSubscription = checkingTimer.subscribe((t) => {
      this.http.get<Version>('./assets/git-rev-info.json').subscribe((res) => {
        if (!this.lastKnownVersion) {
          this.lastKnownVersion = res.gitCommitIdAbbrev;
        } else if (this.lastKnownVersion !== res.gitCommitIdAbbrev) {
          this.frontCheckingSubscription.unsubscribe();
          this.openWarning();
        }
      });
    });
  }

  openWarning() {
    this.reloadWarningDialog.openAndExecuteOnConfirm(
      () => {
        this.reload(); // ok -> reload instantly
      },
      () => {
        const logoutTimer = timer(120000); // cancel -> wait a bit and force reload
        this.logoutSubscription = logoutTimer.subscribe((t) => {
          this.reload();
        });
      }
    );
  }

  reload() {
    window.location.reload();
  }
}
