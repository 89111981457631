import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {BinaryDownloaderService} from './binary-downloader.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ImageService extends AbstractService {
  protected url = this.urlPrefix + 'image';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getCompanyLogo(id: number, onLogoFileChanged: any) {
    console.log('company image: id = ' + id);
    const urlStr = this.url + '/company/' + id + '/logo';
    this.binaryDownloader.getBinary('GET', urlStr, 'image/png', 'image/png', onLogoFileChanged);
  }

  getBrokerLogo(contractVersionId: number, onLogoFileChanged: any) {
    console.log('get broker logo for contractVersionId: id = ' + contractVersionId);
    const urlStr = this.url + '/brokerfromcontract/' + contractVersionId + '/logo';
    this.binaryDownloader.getBinary('GET', urlStr, 'image/png', 'image/png', onLogoFileChanged);
  }

  getBrokerLogoUrl(contractVersionId: number): string {
    return this.url + '/brokerfromcontract/' + contractVersionId + '/logo';
  }

  getCompanyLogoUrl(id: number) {
    console.log('company image: id = ' + id);
    return this.url + '/company/' + id + '/logo';
  }

  getBanner(onBannerFileChanged: any) {
    this.binaryDownloader.getBinary('GET', this.url + '/banner', 'image/png', 'image/png', onBannerFileChanged);
  }

  getBannerUrl(): string {
    return this.url + '/banner';
  }
}
