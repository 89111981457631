import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {AbstractAuditedService} from './abstract-audited.service';
import {Observable} from 'rxjs';

export abstract class AbstractAuditedRelatedService<T> extends AbstractAuditedService<T> {
  protected midUrl: string;

  protected constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  protected build(parentId: number): string {
    return super.build(parentId) + '/' + this.midUrl;
  }

  saveAudited(parentId: number, dto: T): Observable<T> {
    return this.put(dto, this.build(parentId));
  }
}
