import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {
  ClaimCalculationBaseDto,
  ClaimCalculationCriteriaDto,
  ClaimCalculationDto,
  SearchCriteria,
  SearchResult,
  StateTransitionDto,
  UserSimpleDto,
} from '../model/dtos';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Cacheable} from 'ts-cacheable';

@Injectable()
export class ClaimCalculationService extends AbstractService {
  protected url = this.urlPrefix + 'claimCalculation';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getLastCalc(claimId: number): Observable<ClaimCalculationBaseDto> {
    const crit = <SearchCriteria<ClaimCalculationCriteriaDto>>{
      criteria: {last: true, claim: {id: claimId}, claimVersionLast: true},
    };
    return this.searchByCriteria(crit).pipe(map((x) => <ClaimCalculationBaseDto>x.result[0]));
  }

  getCalculations(claimVersionId: number): Observable<ClaimCalculationBaseDto[]> {
    const crit = <SearchCriteria<ClaimCalculationCriteriaDto>>{criteria: {claimVersion: {id: claimVersionId}}};
    return this.searchByCriteria(crit).pipe(map((x) => <ClaimCalculationBaseDto[]>x.result));
  }

  getCalc(calcId: number): Observable<ClaimCalculationDto> {
    return this.getById(calcId);
  }

  saveCalc(calc: ClaimCalculationDto): Observable<ClaimCalculationDto> {
    return this.save(calc);
  }

  getNewVersion(parentId: number): Observable<ClaimCalculationDto> {
    return this.get(this.url + '/newVersion/' + parentId);
  }

  getAvailableUsersForApproval(calculationId: number): Observable<SearchResult<UserSimpleDto>> {
    return this.get<SearchResult<UserSimpleDto>>(this.url + '/' + calculationId + '/availableUsersForApproval');
  }

  getTransitions(claimCalculation: ClaimCalculationDto): Observable<Array<StateTransitionDto>> {
    return this.get<StateTransitionDto[]>(this.url + '/transition/' + claimCalculation.businessStatus.id);
  }

  @Cacheable()
  getTransitionsMap(): Observable<Map<number, Array<StateTransitionDto>>> {
    return this.get<Map<number, Array<StateTransitionDto>>>(this.url + '/transition');
  }
}
