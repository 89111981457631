<search-view labelKey="reportExecution.search.caption" [dataProvider]="dataProvider" [searchModeSwitchable]="false" [newButton]="false">
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner bon-label-150">
      <form-row *ngIf="!portal" labelKey="reportExecution.search.reportType">
        <item-combo class="bon-input-size" [items]="reportDefinitions" nullLabel="{{'reportExecution.search.nullLabel' | translate}}"
                    [(ngModel)]="criteria.reportDefinition" >
          <ng-template let-item="item">{{item.name}}</ng-template>
        </item-combo>
      </form-row>
      <input-row labelKey="reportExecution.search.fileName"
                 [(ngModel)]="criteria.fileName" name="fileName"></input-row>
      <date-range-row labelKey="reportExecution.search.creationDateBetween" [value]="criteria.creationDate"></date-range-row>
      <date-range-row labelKey="reportExecution.search.reportOnDateBetween" [value]="criteria.reportOnDate"></date-range-row>
    </div>
    <div class="bon-card-inner">
    </div>
    <div class="bon-card-inner">
    </div>
  </div>
  <div class="search-results">
    <a-table [pagination]="true" [pageSize]="20" [selection]="false"
             [sortable]="true">
      <column labelKey="reportExecution.search.result.fileName" property="fileName" sort="fileName">
        <ng-template let-item="item">
          <a class="a-link" *ngIf="item.status?.id === BusinessReportStatus.COMPLETED; else noFile;" (click)="showDocument(item)">{{ item.fileName }}</a>
          <ng-template #noFile>{{ item.fileName }}</ng-template>
        </ng-template>
      </column>
      <column labelKey="reportExecution.search.result.reportType" property="reportDefinition.name" sort="reportDefinition.name"></column>
      <column labelKey="reportExecution.search.result.reportOnDate" property="reportOnDate" type="date" sort="reportOnDate"></column>
      <column labelKey="reportExecution.search.result.creationDate" property="creationDate" type="datetime"
              sort="creationDate" initialSort="down"></column>
      <column labelKey="reportExecution.search.itemCount" property="allCount" type="integer"></column>
      <column labelKey="reportExecution.search.processedPercent" property="processedPercent" type="integer"></column>
      <column labelKey="common.status" property="status" type="dictionary" dictionary="BusinessReportStatus"></column>

      <column *ngIf="!portal" property="createdBy" labelKey="limitDetails.createdBy">
      <ng-template let-item="item">
        <span>{{item.createdBy | username}}</span>
      </ng-template>
      </column>

    </a-table>
  </div>

</search-view>

