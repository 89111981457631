import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';

/**
 * Created by cichocki.
 */

const NUM_ROW_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PercentRowComponent),
  multi: true,
};

@Component({
  selector: 'percent-row',
  template: `
    <div *ngIf="control" class="bon-row">
      <label class="bon-label">{{ labelKey ? (labelKey | translate) : label }}</label>
      <num-input
        [ngModel]="inputValue"
        (changeValue)="onInput($event)"
        [type]="type"
        [formControl]="control"
        [disabled]="disabled"
        [allowNegative]="allowNegative"
        [nullLabel]="nullLabel"
        [infinity]="infinity"
        [min]="min"
        [max]="max"
        [numberOfDecimalPlaces]="numberOfDecimalPlaces"
        class="bon-input-size"
      >
      </num-input
      >%
      <error-message [control]="control" [show]="showErrors"></error-message>
    </div>
    <div *ngIf="!control" class="bon-row">
      <label class="bon-label">{{ labelKey ? (labelKey | translate) : label }}</label>
      <num-input
        #model="ngModel"
        [ngModel]="inputValue"
        (changeValue)="onInput($event)"
        [type]="type"
        [disabled]="disabled"
        [required]="required"
        [allowNegative]="allowNegative"
        [nullLabel]="nullLabel"
        [infinity]="infinity"
        [min]="min"
        [max]="max"
        [numberOfDecimalPlaces]="numberOfDecimalPlaces"
        class="bon-input-size"
      >
      </num-input
      >%
      <error-message [control]="model" [show]="showErrors"></error-message>
    </div>
  `,
  providers: [NUM_ROW_CONTROL_VALUE_ACCESSOR],
})
export class PercentRowComponent implements ControlValueAccessor {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() labelKey: string;
  @Input() disabled: any;
  @Input() showErrors: boolean;
  @Input() required: boolean;
  @Input() errorClass = '';
  @Input() type: 'decimal' | 'integer' = 'decimal';
  @Input() allowNegative = false;
  @Input() nullLabel: string;
  @Input() infinity: boolean;
  @Input() min: number;
  @Input() max: number;
  @Input() numberOfDecimalPlaces = 2;

  @Output() changeValue = new EventEmitter<number>();
  public onChangeListeners: Function;
  public onTouchedListeners: Function;
  private value: number;
  public inputValue: number;

  constructor() {}

  private static isNumber(v) {
    return typeof v === 'number' && isFinite(v);
  }

  writeValue(obj: any): void {
    this.value = obj;

    if (PercentRowComponent.isNumber(this.value)) {
      this.inputValue = this.value * 100;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onInput(value: number) {
    this.inputValue = value;
    if (PercentRowComponent.isNumber(this.inputValue)) {
      this.value = this.inputValue / 100;
    }
    if (this.onChangeListeners) {
      this.onChangeListeners(this.value);
    }
    this.changeValue.emit(this.value);
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private getCssClasses() {
    const cssClasses: {[key: string]: any} = {};
    cssClasses['bon-input'] = true;
    if (this.control && this.control.invalid && this.showErrors) {
      cssClasses[this.errorClass] = true;
    }
    return cssClasses;
  }
}
