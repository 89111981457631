import {DomainObjectBaseDto, ErrorReason, StateChangeDto, StateTransitionDto} from '../../model/dtos';
import {Button} from './button';
import {StringUtils} from '../../utils/string-utils';
import {ErrorCode} from '../../model/error-code';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {ListEmitters} from './list-emitters';
import {ActivatedRoute, Params} from '@angular/router';
import {GrowlService} from '../../services/growl/growl.service';
import {BackendError, isErrorReasons} from '../../model/backend-error';
import {AfterViewInit, ChangeDetectorRef, Directive, ViewChild, ViewContainerRef} from '@angular/core';
import {TabView} from 'primeng/tabview';
import {AbstractService} from '../../services';

/**
 * Created by wilk on 14.11.2016.
 */

@Directive()
export class DetailsView extends ListEmitters implements AfterViewInit {
  @ViewChild(TabView, {read: ViewContainerRef, static: true}) tabView: ViewContainerRef;

  serverErrors: BackendError;
  frontendErrors: FrontendError[] = [];
  tempNoGrowl = false;
  readOnlyMode = false;

  objectNotFound: boolean;
  inProgress = false;
  showErrors = false;

  self = this;

  saveButton: Button;
  editButton: Button;
  deleteButton: Button;
  newVersionButton: Button;
  newVersion2Button: Button;
  cancelButton: Button;

  form: UntypedFormGroup;
  tabViewChangeDetector: ChangeDetectorRef;

  /**
   ** Warnings store for just created object.
   * Keeps warnings after object creation,
   * they are shown when page is reloaded using new id in url
   */
  createdObjectWarnings: {id: number; warnings: ErrorReason[]};

  static addControl(parentForm: UntypedFormGroup, formName: string, form: UntypedFormGroup | UntypedFormControl) {
    if (!parentForm.controls[formName]) {
      parentForm.addControl(formName, form);
    }
  }

  static enableControl(parentForm: UntypedFormGroup, formName: string, on: boolean) {
    if (on) {
      parentForm.controls[formName].enable();
    } else {
      parentForm.controls[formName].disable();
    }
  }

  commonInit<V>(route: ActivatedRoute) {
    route.params.subscribe((params) => {
      if (+params['id'] && +params['id'] > 0) {
        this.initViewWithId(+params['id']);
      } else {
        route.queryParams.subscribe((q: V) => this.initViewWithCustomParams(q));
      }
    });
  }

  initViewWithId(id: number, force = true) {}

  initViewWithCustomParams<V>(q: any) {}

  protected handleButtons() {}

  constructor(protected growlService: GrowlService) {
    super();
    this.saveButton = new Button('common.button.save', this.onSave.bind(this), true, false);
    this.editButton = new Button('common.button.edit', this.onEdit.bind(this), true, false);
    this.deleteButton = new Button('common.button.delete', this.onDelete.bind(this), true, false, 'trash');
    this.newVersionButton = new Button(
      'common.button.newVersion',
      this.onCreateNewVersion.bind(this),
      true,
      false,
      'paperclip'
    );
    this.cancelButton = new Button('common.button.cancel', this.onCancel.bind(this), true, false);
  }

  hideButtons(hidden: boolean) {
    if (this.saveButton) {
      this.saveButton.hidden = hidden;
    }
    if (this.cancelButton) {
      this.cancelButton.hidden = hidden;
    }
    if (this.newVersionButton) {
      this.newVersionButton.hidden = hidden;
    }
    if (this.newVersion2Button) {
      this.newVersion2Button.hidden = hidden;
    }
    if (this.deleteButton) {
      this.deleteButton.hidden = hidden;
    }
  }

  setWarnings(warnings: ErrorReason[], id: number) {
    if (!warnings && this.createdObjectWarnings && this.createdObjectWarnings.id === id) {
      this.updateErrors(this.createdObjectWarnings.warnings);
    } else {
      this.updateErrors(warnings);
    }
    this.createdObjectWarnings = undefined;
  }

  updateErrors(errors: ErrorReason[]) {
    this.serverErrors = errors;
  }

  storeCreationWarnings(warnings: ErrorReason[], id: number) {
    this.createdObjectWarnings = {id, warnings};
  }

  initializeView(params: Params, force?: boolean) {
    this.clearErrors();
  }

  onSave(): void {
    console.log('onSave:: ');
  }

  onEdit(): void {
    console.log('onEdit:: ');
    this.readOnlyMode = false;
    this.handleButtons();
  }

  onDelete(): void {}

  onCreateNewVersion(): void {}

  onCancel(route?: ActivatedRoute) {
    console.log('onCancel:: ');
    this.clearErrors();
    this.closeAllSelectors();
    if (route) {
      this.initializeView(route.snapshot.params, true);
      this.commonInit(route);
    }

    this.readOnlyMode = true;
    this.handleButtons();
  }

  // Use case of method is routing to url with new id after creating object
  afterObjectSaved(originalId?: number, toDetailsRouterFunction?: Function) {
    this.showSavedMsg();
    this.serverErrors = undefined;
    this.inProgress = false;
    if ((!originalId || originalId < 1) && toDetailsRouterFunction) {
      toDetailsRouterFunction();
    }
  }

  handleServerError(error: BackendError, showGrowl = true, checkNotFoundError = true) {
    this.tempNoGrowl = !showGrowl;
    this.inProgress = false;
    setTimeout(() => this.handleServerErrorInternal(error, checkNotFoundError), 0);
    setTimeout(() => (this.tempNoGrowl = false), 0);
    console.error('Server error or warning !', error);
  }

  private handleServerErrorInternal(error: BackendError, checkNotFoundError: boolean) {
    this.objectNotFound = checkNotFoundError && this.isObjectNotFoundError(error);
    this.serverErrors = error;
    this.inProgress = false;
  }

  isObjectNotFoundError(error: BackendError) {
    return this.isSpecificError(error, ErrorCode.OBJECT_NOT_EXISTS);
  }

  isSpecificError(error: BackendError, code: ErrorCode) {
    return isErrorReasons(error) && error.filter((e) => e.type === ErrorCode[code]).length > 0;
  }

  getSpecificError(error: BackendError, code: ErrorCode): ErrorReason {
    return isErrorReasons(error) ? error.find((e) => e.type === ErrorCode[code]) : undefined;
  }

  showSavedMsg(msg?: string) {
    const m = msg ? msg : 'Object is saved!';
    this.growlService.notice(m);
  }

  showErrorMsg() {
    this.growlService.error('Error when saving!');
  }

  showFormError() {
    this.showErrors = true;
    this.growlService.error('The form has errors!');
  }

  setAndUpdateValidator(controlName: string, validator: ValidatorFn) {
    this.form.get(controlName).validator = validator;
    this.form.get(controlName).updateValueAndValidity();
  }

  formValidates(): boolean {
    if (!this.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFieldsRecursive(this.form);
      console.log('form:', this.form);
      this.inProgress = false;
      return false;
    }
    return true;
  }

  clearErrors() {
    this.inProgress = false;
    this.serverErrors = undefined;
    this.frontendErrors = [];
    this.showErrors = false;
    this.objectNotFound = false;
  }

  enableControl(controlName: string, on: boolean) {
    DetailsView.enableControl(this.form, controlName, on);
  }

  addFrontendError(msgKey: string) {
    this.addFrontendErrorOrWarning(msgKey, false);
  }

  addFrontendWarning(msgKey: string) {
    this.addFrontendErrorOrWarning(msgKey, true);
  }

  private addFrontendErrorOrWarning(msgKey: string, warning: boolean) {
    if (!this.frontendErrors.map((x) => x.msgKey).includes(msgKey)) {
      this.frontendErrors.push({msgKey: msgKey, warning: warning});
      if (warning) {
        this.growlService.warning(msgKey);
      } else {
        this.growlService.error(msgKey);
      }
    }
  }

  tabHeaderStyle(formGroupName: string): string {
    this.tabViewChangeDetector?.markForCheck();
    return this.tabHeaderStyleClass(this.showErrors && this.form.invalid && this.form.controls[formGroupName].invalid);
  }

  tabHeaderStyleClass(error: boolean): string {
    return error ? 'bon-tab-header-error' : 'bon-tab-header';
  }

  public downloadErrorCallback(err: string): void {
    const error = <ErrorReason[]>JSON.parse(err);
    this.handleServerError(error);
  }

  ngAfterViewInit(): void {
    this.tabViewChangeDetector = this.tabView?.injector.get(ChangeDetectorRef);
  }

  // only status is modified on backend, other changes made on gui are ignored (modified object is overridden by the object received from backend)
  makeExclusiveTransition(transition: StateTransitionDto, object: DomainObjectBaseDto, service: AbstractService) {
    const sc = <StateChangeDto>{id: object.id, status: transition.newStatus, version: object.version};
    service.exclusiveStateTransition<DomainObjectBaseDto>(sc).subscribe(
      (bcv) => {
        this.afterExclusiveStateTransition(bcv);
      },
      (error) => {
        this.handleServerError(error, true, false);
      }
    );
  }

  // to be overridden
  afterExclusiveStateTransition(object: DomainObjectBaseDto) {}
}

export interface FrontendError {
  msgKey: string;
  warning: boolean;
}
