import {LimitDetailsComponent} from './limit-details.component';
import {LimitSearchComponent} from './limit-search.component';
import {BuyerLimitMassDecisionComponent} from './buyer-limit-mass-decision.component';
import {LimitMassDecisionComponent} from './limit-mass-decision.component';
import {LimitBusinessParamsDetailsComponent} from './business-params/limit-business-params-details.component';
import {LimitMailingListComponent} from './limit-mailing-list.component';
import {LimitMailingMultilistComponent} from './limit-mailing-multilist.component';
import {ExternalLimitComponent} from './shared/components/external-limit.component';
import {LimitAccessGuard} from './guard/limit-access-guard';
import {LimitMassGuard} from './guard/limit-mass-guard';
import {LimitBusinessParamsGuard} from './guard/limit-business-params-guard';

export const LimitRoutes = [
  {
    path: 'limit-details/:id',
    component: LimitDetailsComponent,
    canActivate: [LimitAccessGuard],
    data: {module: 'limits'},
  },
  {
    path: 'limit-details',
    component: LimitDetailsComponent,
    canActivate: [LimitAccessGuard],
    data: {module: 'limits'},
  },
  {
    path: 'limit-search',
    component: LimitSearchComponent,
    canActivate: [LimitAccessGuard],
    data: {module: 'limits'},
  },
  {
    path: 'buyer-limit-mass-decision/:companyId',
    component: BuyerLimitMassDecisionComponent,
    canActivate: [LimitMassGuard],
    data: {module: 'limits'},
  },
  {
    path: 'limit-mass-decision',
    component: LimitMassDecisionComponent,
    canActivate: [LimitMassGuard],
    data: {module: 'limits'},
  },
  {
    path: 'limit-business-params-details',
    component: LimitBusinessParamsDetailsComponent,
    canActivate: [LimitBusinessParamsGuard],
    data: {module: 'limits'},
  },
  {
    path: 'limit-mailing-list/:companyId',
    component: LimitMailingListComponent,
    canActivate: [LimitAccessGuard],
    data: {module: 'limits'},
  },
  {
    path: 'limit-mailing-multilist/:templateId/:companyId',
    component: LimitMailingMultilistComponent,
    canActivate: [LimitAccessGuard],
    data: {module: 'limits'},
  },
  {
    path: 'external-limit/:id',
    component: ExternalLimitComponent,
    canActivate: [LimitAccessGuard],
    data: {module: 'limits'},
  },
];
