import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService} from '../../../../../bonding_shared/services/app-config.service';

@Component({
  selector: 'credit-limits-section',
  templateUrl: './credit-limits-section.component.pug',
})
export class CreditLimitsSectionComponent extends Section {
  self = this;

  constructor(protected policyContractViewService: PolicyContractViewService, public appService: AppConfigService) {
    super(policyContractViewService, appService);
  }

  onNegativeLimitDecValidDaysChanged(checked: boolean) {
    if (!checked) {
      delete this.policy.negativeLimitDecValidDaysValue;
    }
  }

  onTopUpChanged(checked: boolean) {
    if (!checked) {
      delete this.policy.topUpPremiumRate;
      delete this.policy.topUpComment;
    }
  }
}
