import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {CommonContractPreviewComponent} from './common-contract-preview.component';
import {CompanySharedModule} from '../../bonding/company/components/shared/company-shared.module';
import {ContractSharedModule} from '../../bonding/bonding-contract/contract/components/shared/contract-shared.module';
import {BondSharedModule} from '../../bonding/bond/components/shared/bond-shared.module';
import {ContractModule} from '../../bonding/bonding-contract/contract/contract.module';
import {RepositoryDocumentListModule} from '../../bonding_shared/components/repository-document-list/repository-document-list.module';
import {ClientContractComboComponent} from './client-contract-combo.component';
import {ClientContractVersionSearchComponent} from './client-contract-version-search.component';
import {ClientContractGuiService} from './services/client-contract-gui.service';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    CompanySharedModule,
    ContractSharedModule,
    BondSharedModule,
    ContractModule,
    RepositoryDocumentListModule,
  ],
  declarations: [CommonContractPreviewComponent, ClientContractComboComponent, ClientContractVersionSearchComponent],
  exports: [ClientContractComboComponent],
  providers: [ClientContractGuiService],
})
export class CommonContractModule {}
