import {Injectable} from '@angular/core';
import {AbstractGuard} from '../../../bonding_shared/components/abstract-guard';
import {ActivatedRouteSnapshot, ParamMap, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AppConfigService, LoggedUserService, TemplateService} from '../../../bonding_shared/services';
import {ElementaryRight} from '../../../bonding_shared/model/dictionary-ids';
import {map} from 'rxjs/operators';

@Injectable()
export class TemplateDetailsGuard extends AbstractGuard {
  constructor(
    loggedUserService: LoggedUserService,
    router: Router,
    public apiService: AppConfigService,
    private templateService: TemplateService
  ) {
    super(loggedUserService, router);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.apiService.kuke) {
      const params: ParamMap = route.paramMap;
      const id: number = +params.get('id');
      return this.templateService.isEditionAllowed(id);
    }
    return true;
  }
}
