import {Component, Input} from '@angular/core';
import {BondVersionDto} from '../../../../bonding_shared/model';
import {ControlContainer, NgForm} from '@angular/forms';
import {BondDifferentPriceType} from '../../../../bonding_shared/model/dictionary-ids';
import {DictionaryUtils} from '../../../../bonding_shared/utils/dictionary-utils';

@Component({
  selector: 'different-price',
  templateUrl: 'different-price.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: NgForm,
    },
  ],
})
export class DifferentPriceComponent {
  readonly BondDifferentPriceType = BondDifferentPriceType;
  @Input() showErrors = false;
  @Input() bond: BondVersionDto;
  @Input() disabled = false;

  isDifferentPriceType(differentPriceTypeId: number): boolean {
    if (!this.bond) {
      return false;
    }
    return DictionaryUtils.equalsDictAndId(this.bond.differentPriceType, differentPriceTypeId);
  }
}
