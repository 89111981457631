import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {TaskDataComponent} from '../../components';
import {AppConfigService} from '../../../../bonding_shared';

@Directive({
  selector: '[userOrGroup]',
  providers: [{provide: NG_VALIDATORS, useExisting: UserOrGroupDirective, multi: true}],
})
export class UserOrGroupDirective implements Validator {
  constructor(private appConfig: AppConfigService) {}

  validate(control: AbstractControl): ValidationErrors {
    return TaskDataComponent.validateUserAndGroup(control, this.appConfig);
  }
}
