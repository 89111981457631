import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {CompanySharedModule} from '../../bonding/company/components/shared/company-shared.module';
import {PolicyContractSharedModule} from '../../bonding/policy/components/shared/policy-contract-shared.module';
import {ClientPolicySharedModule} from '../policy/shared/client-policy-shared.module';
import {ClientUserSearchComponent} from './client-user-search.component';
import {ClientUserDataComponent} from './components/client-user-data.component';
import {ClientUserDetailsComponent} from './client-user-details.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    PolicyContractSharedModule,
    ClientPolicySharedModule,
  ],
  declarations: [ClientUserSearchComponent, ClientUserDataComponent, ClientUserDetailsComponent],
})
export class AdminModule {}
