      <a-table #taskTable
               (selectItem)="onSelectedRow($event)"
               [editable]="false"
               [moveTopButton]="false"
               [pagination]="true"
               [pageSize]="pageSize"
               [footer]="false"
               [dataProvider]="dataProvider" [sortable]="!dataProvider.textSearch"
      >
        <column labelKey="task.search.result.id" property="id" sort="id" [link]="true" (linkClick)="gotoTaskDetail($event)"></column>
        <column labelKey="task.search.result.title" property="title" sort="UPPER(c.title)"></column>
        <column labelKey="task.search.result.type" property="type" sort="textsType.name" dictionary="TaskType"></column>
        <column labelKey="task.search.result.businessObject"(linkClick)="goToBusinessObject($event)" [link]="true">
          <ng-template let-item="item">
            {{item.businessObject?.relatedTo?.name}} / {{item.businessObject?.description}}
            <ng-container *ngIf="item.taskBusinessObjectProduct">
              / {{item.taskBusinessObjectProduct.name}}
            </ng-container>
          </ng-template>
        </column>
        <column labelKey="task.search.result.closureDate" sort="closureDate" property="closureDate" type="date"></column>
        <column labelKey="task.search.result.automatic" property="autoGenerated" type="checkbox" sort="autoGenerated"></column>
        <column labelKey="task.search.result.important" property="important" type="checkbox" sort="important"></column>
        <column labelKey="task.search.result.status" property="status" dictionary="TaskStatus" sort="textsStatus.name"></column>
        <column labelKey="task.search.result.user" sort="user.familyName">
          <ng-template let-item="item" let-edition="edition">{{item.user | username}}</ng-template>
        </column>
        <column labelKey="task.search.result.group" sort="userGroup.name" property="userGroup.name">
        </column>
        <column labelKey="task.search.result.dueDate" property="dueDate" type="date" sort="dueDate" initialSort="UP"></column>
        <column labelKey="task.search.result.dueDateFilter" *ngIf="appConfig.kuke">
          <ng-template let-item="item" let-edition="edition">
            <ng-container [ngSwitch]="item.overdue">
              <i class="fa fa-2x fa-align-center fa-history" style="color:red" *ngSwitchCase="'OVERDUE'"></i>
              <i class="fa fa-2x fa-align-center fa-history" style="color:green" *ngSwitchCase="'BEFORE'"></i>
              <i class="fa fa-2x fa-align-center fa-history" style="color:orange" *ngSwitchCase="'ON_TIME'"></i>
            </ng-container>
          </ng-template>
        </column>
        <column labelKey="task.search.result.creationDate" property="creationDate" type="date" sort="creationDate"></column>
        <column labelKey="task.search.result.companyName" *ngIf="appConfig.credendo || appConfig.mehib">
          <ng-template let-item="item" let-edition="false">{{item?.businessObject?.reference?.shortName && showShortNameIfExists ? item?.businessObject?.reference?.shortName : item?.businessObject?.reference?.registrationName}}</ng-template>
        </column>
        <column labelKey="task.search.result.connectedCompanies1" tooltip="taskCompanyFirstFull" *ngIf="appConfig.kuke">
          <ng-template let-item="item">
            <ng-container *ngIf="!item.taskCompanyFirstVip">{{ item.taskCompanyFirstShort }}</ng-container>
            <ng-container *ngIf="item.taskCompanyFirstVip"><b>{{ item.taskCompanyFirstShort }}</b></ng-container>
          </ng-template>
        </column>
        <column labelKey="task.search.result.connectedCompanies2" tooltip="taskCompanySecondFull" *ngIf="appConfig.kuke">
            <ng-template let-item="item">
              <ng-container *ngIf="!item.taskCompanySecondVip">{{ item.taskCompanySecondShort }}</ng-container>
              <ng-container *ngIf="item.taskCompanySecondVip"><b>{{ item.taskCompanySecondShort }}</b></ng-container>
            </ng-template>
        </column>
        <column labelKey="task.search.result.businessUnit" property="businessUnit.name"></column>
      </a-table>
