<details-view [parentComponent]="self" [fixedBlockHidden]="false" [versionListHidden]="true" modelObjectName="invoice" [objectName]="getInvoiceCategoryLabel()" [title]="getInvoiceCategoryLabel()">
  <div class="additional-buttons">
    <lifecycle-dropdown-simple [service]="invoiceService" [selectedObject]="invoice" [disabled] = "createUpdateDisabled"
                               [saveFunc]="onSave.bind(self)" [inProgress]='inProgress'>
    </lifecycle-dropdown-simple>
    <item-dropdown *ngIf="invoice.id > 0" [items]="templates" (menuSelect)="onPrintSelected($event)"
                   [caption]="draft ? 'common.button.newDraftDocument' : 'common.button.newDocument'"
                   [dropup]="false" [disabled]="newDocumentDisabled">
    </item-dropdown>
    <button class="bon-btn-info" *ngIf="createCorrectionVisible" [btnIcon]="'fa-edit'"
            (click)="createCorrection()" type="button" [disabled]="createUpdateDisabled"
            translate>invoice.details.createCorrection
    </button>
    <button class="bon-btn-info" *ngIf="createCorrectionWithoutSpecificationVisible" [btnIcon]="'fa-edit'"
            (click)="createCorrectionWithoutSpecification()" type="button" [disabled]="createUpdateDisabled"
            translate>invoice.details.createCorrectionWithoutSpecification
    </button>
    <div class="btn-group" *ngIf="createCorrectionToZeroVisible" dropdown [isDisabled]="createUpdateDisabled">
      <button type="button" class="bon-btn-info dropdown-toggle" dropdownToggle translate>
        invoice.details.createCorrectionToZero
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu" role="menu">
        <li role="menuitem">
          <a class="dropdown-item" (click)="createCorrectionToZero(false)" translate>invoice.details.noRecalculation</a>
        </li>
        <li role="menuitem">
          <a class="dropdown-item" (click)="createCorrectionToZero(true)" translate>invoice.details.doRecalculation</a>
        </li>
      </ul>
    </div>
    <button class="bon-btn-info" *ngIf="createNewClientCorrectionVisible" [btnIcon]="'fa-edit'"
            (click)="newClientSelection()" type="button" [disabled]="createUpdateDisabled"
            translate>invoice.details.noRecalculationNewClient
    </button>

    <button class="bon-btn-info"
            *ngIf="invoice.number && invoice.id && SEND_LAST_EMAIL_STATUSES.indexOf(invoice.status.id) > -1"
            [btnIcon]="'fa-at'"
            (click)="sendLastInvoice()" type="button" [disabled]="sendEmailDisabled" translate>
      invoice.details.emailLastInvoice
    </button>
    <item-dropdown *ngIf="invoice.id > 0" [items]="reportDefinitions" (menuSelect)="onReportDefinitionSelected($event, invoice.id)" caption="invoice.details.reports"
                   [dropup]="false" [disabled]="inProgress">
    </item-dropdown>
    <button class="bon-btn-info" *ngIf="invoice.id && invoice.contractLink?.policyContract" [btnIcon]="'fa-at'"
            (click)="routerService.toPolicyContractPreviewByPolicyContractId(invoice.contractLink.policyContract.id)"
            [disabled]="inProgress" type="button" translate>policy.details.title
    </button>
    <button class="bon-btn-info" *ngIf="invoice.id && invoice.contractLink?.contract" [btnIcon]="'fa-at'"
            (click)="routerService.toContractPreviewByContractId(invoice.contractLink.contract.id)"
            [disabled]="inProgress" type="button" translate>contract.title
    </button>
    <ng-container
            *hasRight="ContactNoteElementaryRight.CONTACT_NOTE_READ_ALL + ',' + ContactNoteElementaryRight.CONTACT_NOTE_READ_FINANCE">
      <crm-dropdown *ngIf="invoice.id && invoice.id > 0" [businessObjectId]="invoice.id"
                    [businessObjectTypeId]="BusinessObjectTypeINVOICE"
                    [disabled]="inProgress"></crm-dropdown>
    </ng-container>
    <button class="bon-btn-info" *ngIf="invoice?.id"
            (click)="routerService.toTaskSearchWithBo(BusinessObjectTypeINVOICE, invoice.id)"
            [disabled]="inProgress" translate>common.tasks
    </button>
    <button *ngIf="writeAllOffVisible" [btnIcon]="'fa-download'"
            (click)="openWriteAllOffConfirmDialog()" type="button" class="bon-btn-info" [disabled]="inProgress"
            translate>invoice.details.writeAllOff
    </button>
  </div>
  <div class="details-content">
    <invoice-data *ngIf="invoiceInitialized" [header]="'Invoice Details'" [invoice]="invoice"
                  [invoiceAutomatically]="invoiceAutomatically" [categoryId]="categoryId"
                  (logError)="logError($event)" (initialisationFinished)="invoiceDataInitialisationFinished()">
    </invoice-data>
  </div>

  <confirm-dialog #confirmDialog></confirm-dialog>
  <confirm-dialog #infoDialog [showRejectButton]="false"></confirm-dialog>

  <confirm-dialog #newClientSelectionDialog [confirmButtonDisabled]="!selectedNewClient" class="modal-lg">
    <form role="form" >
      <div class="bon-card">
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <div class="bon-row">
              <company-section *ngIf="invoice" labelKey="invoice.shared.data.client" [clearable]="false"
                               [(ngModel)]="selectedNewClient" [ngModelOptions]="{standalone: true}"
                               [openSelectorEmitter]="listEmitters.openSelectorEmitters['Client']" [showLink]="true"
                               [readOnly]="false">
              </company-section>
            </div>
            <div class="bon-row">
              <company-selector-simple (selectItem)="onNewClientSelect($event)" labelKey="invoice.shared.data.companySearch"
                                       [open]="listEmitters.openSelectorEmitters['Client']" [showDuplicates]="false"
                                       [searchModeSwitchable]="true" [visible]="true">
              </company-selector-simple>
            </div>
          </div>
        </div>
      </div>
    </form>
  </confirm-dialog>
</details-view>
