import {Injectable} from '@angular/core';
import {CompanyFinancialDataType} from '../../../bonding_shared/model/dictionary-ids';
import {RatioFormat} from '../model/company-financial-data-format';

@Injectable()
export class CompanyFinDataFormatterService {
  ratioFormat: number[] = [];

  constructor() {
    this.ratioFormat[CompanyFinancialDataType.CURRENT_RATIO] = RatioFormat.E23_NUMBER_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.QUICK_RATIO] = RatioFormat.E23_NUMBER_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.CASH_RATIO] = RatioFormat.E23_NUMBER_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.SALES_PROFIT] = RatioFormat.E24_PERCENT_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.OPERATION_PROFIT] = RatioFormat.E24_PERCENT_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.NET_PROFIT_ROS] = RatioFormat.E24_PERCENT_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.RETURN_FROM_PROPERTY_ROA] =
      RatioFormat.E24_PERCENT_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.RETURN_FROM_PROPERTY_ROE] =
      RatioFormat.E24_PERCENT_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.GENERAL_DEBT] = RatioFormat.E24_PERCENT_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.INDEBTEDNESS_OF_EQUITY] = RatioFormat.E23_NUMBER_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.COVERAGE_RATIO_FOR_RECEIVABLES] =
      RatioFormat.E23_NUMBER_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.CREDIT_INDEBTEDNESS] = RatioFormat.E23_NUMBER_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.SHORT_TERM_DEBT_EBITDA] = RatioFormat.E23_NUMBER_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.NET_DEBT_EBITDA] = RatioFormat.E23_NUMBER_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.DEBT_TURNOVER_IN_DAYS] = RatioFormat.E25_NUMBER;
    this.ratioFormat[CompanyFinancialDataType.STOCK_ROTATION_IN_DAYS] = RatioFormat.E25_NUMBER;
    this.ratioFormat[CompanyFinancialDataType.TURNOVER_OF_LIABILITIES_IN_DAYS] = RatioFormat.E25_NUMBER;
    this.ratioFormat[CompanyFinancialDataType.WILCOX_FORMULA] = RatioFormat.E23_NUMBER_WITH_TWO_DECIMAL_PLACES;
    this.ratioFormat[CompanyFinancialDataType.THE_BASIC_INDICATOR_OF_THE_LIABILITIES_STRUCTURE] =
      RatioFormat.E26_PERCENT;
    this.ratioFormat[CompanyFinancialDataType.THE_GOLDEN_RULE_OF_THE_BALANCE] = RatioFormat.E26_PERCENT;
  }

  getAllFormats(): number[] {
    return this.ratioFormat;
  }
}
