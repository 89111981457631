import {Injectable} from '@angular/core';
import {
  AppConfigService,
  BinaryDownloaderService,
  ExtranetAdminNotificationType,
  ExtranetClientAgreementType,
  LoggedUserService,
  PolicyCompanyDto,
  PortalUserContextDto,
  UserService,
} from '../../bonding_shared';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Language} from '../../bonding_shared/model/dictionary-ids';
import {Cacheable} from 'ts-cacheable';

export const portalUserContextCacheBuster$ = new Subject<void>();

@Injectable()
export class PortalUserService extends UserService {
  protected url = this.urlPrefix + 'portal/user';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService, binaryDownloader);
    loggedUserService.loggedUsersSubject.subscribe(() => portalUserContextCacheBuster$.next());
  }

  @Cacheable({maxCacheCount: 100, maxAge: 60000})
  getAvailablePolicyCompanies(): Observable<PolicyCompanyDto[]> {
    return this.get<PolicyCompanyDto[]>(this.url + '/accessiblePolicyCompanies');
  }

  @Cacheable({
    cacheBusterObserver: portalUserContextCacheBuster$,
  })
  getPortalUserContext(): Observable<PortalUserContextDto> {
    return this.get<PortalUserContextDto>(this.url + '/context');
  }

  getAgreements(login: string): Observable<ExtranetClientAgreementType[]> {
    return this.get<ExtranetClientAgreementType[]>(this.url + '/agreements/' + login);
  }

  saveAgreements(login: string, agreements: ExtranetClientAgreementType[]): Observable<boolean> {
    return this.post1(agreements, this.url + '/agreements/' + login);
  }

  getNotifications(login: string): Observable<ExtranetAdminNotificationType[]> {
    return this.get<ExtranetAdminNotificationType[]>(this.url + '/notifications/' + login);
  }

  saveNotifications(login: string, notifications: ExtranetAdminNotificationType[]): Observable<boolean> {
    return this.post1(notifications, this.url + '/notifications/' + login);
  }

  getUserHistoryReport(userId: number, login: string) {
    this.binaryDownloader.download(
      'POST',
      this.url + '/generateHistoryReport',
      'application/json',
      'application/octet-stream',
      this.loggedUserService.getLoggedUserData().language.id === Language.POLISH
        ? login + ' - raport zmian.xlsx'
        : login + ' - history changes.xlsx',
      userId
    );
  }
}
