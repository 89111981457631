import {Component, Input, OnInit} from '@angular/core';
import {InquiryProductType} from '../../../bonding_shared/model/dictionary-ids';
import {PolicyInquiryVersionDto} from '../../../bonding_shared/model';
import {ControlContainer, UntypedFormGroup, NgForm} from '@angular/forms';
import {DictionaryBaseService, DictionaryService, LoggedUserService} from '../../../bonding_shared';
import {isType} from '../domain/offer-allowance';
import {VisibilityService} from '../services/visibility.service';

@Component({
  selector: 'inquiry-countries',
  templateUrl: 'inquiry-countries.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class InquiryCountriesComponent implements OnInit {
  self = this;

  @Input()
  set inquiry(inq: PolicyInquiryVersionDto) {
    this._inquiry = inq;

    if (inq) {
      this.visible = this.visibilityService.getVisibility(this.getType());
    }
  }

  @Input() readonly = false;
  @Input() form: UntypedFormGroup;
  @Input() showErrors = false;

  visible: any = {};

  get inquiry() {
    return this._inquiry;
  }

  portalMode = false;
  private _inquiry: PolicyInquiryVersionDto;

  constructor(
    protected dictionaryBaseService: DictionaryBaseService,
    protected loggedUserService: LoggedUserService,
    protected dictionaryService: DictionaryService,
    protected visibilityService: VisibilityService
  ) {
    this.portalMode = this.loggedUserService.portal;
  }

  ngOnInit(): void {}

  exportInsurableLabelKey() {
    if (this.isType(InquiryProductType.KUKE_GSP_PBG)) {
      return 'inquiry.countriesSum';
    }
    return 'inquiry.countriesSumExport';
  }

  private isType(typeId: InquiryProductType): boolean {
    return isType(this.getType(), typeId);
  }

  private getType() {
    const cp: any = this.inquiry;
    return cp && cp.productType && cp.productType.id;
  }
}
