import {Component} from '@angular/core';
import {
  CellChangeEvent,
  GrowlService,
  LoggedUserService,
  PolicyContractVersionService,
  PolicyDrawdownBaseDto,
  PolicyDrawdownPaymentDto,
  PolicyDrawdownPaymentsDto,
  RouterService,
} from '../../../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {PolicyDrawdownPaymentsService} from '../../../../bonding_shared/services/policy-drawdown-payments.service';
import {PolicyDrawdownVersionService} from '../../../../bonding_shared/services/policy-drawdown-version.service';
import {PolicyDrawdownAbstractComponent} from './policy-drawdown-abstract-component';
import {IPasteFromExcelConfig} from '../../../../bonding_shared/components/aku-table/services/paste-from-excel.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'policy-drawdown-payments',
  templateUrl: './policy-drawdown-payments.component.pug',
})
export class PolicyDrawdownPaymentsComponent extends PolicyDrawdownAbstractComponent<PolicyDrawdownPaymentsDto> {
  pasteDrawdownsFromExcelConfig: IPasteFromExcelConfig[];

  drawdowns: PolicyDrawdownBaseDto[];

  constructor(
    route: ActivatedRoute,
    protected service: PolicyDrawdownPaymentsService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    policyContractVersionService: PolicyContractVersionService,
    private drawdownVersionService: PolicyDrawdownVersionService
  ) {
    super(
      route,
      service,
      router,
      translateService,
      growlService,
      loggedUserService,
      policyContractVersionService,
      'drawdownPaymentId'
    );
  }

  initializeView(params: Params) {
    super.initializeView(params);
  }

  initWithoutId() {
    this.service.initial(this.policyContractPreview.masterPolicyId).subscribe((d) => {
      this.current = d;
      this.newVersion = true;
      this.loadDrawdowns();
    });
  }

  onLoad() {
    super.onLoad();
    this.loadDrawdowns();
  }

  private loadDrawdowns() {
    this.drawdownVersionService
      .getApprovedDrawdownsByMasterPolicyId(this.masterPolicyId)
      .subscribe((drawdownVersions) => {
        this.drawdowns = drawdownVersions.map((dv) => dv.parent);
        this.initializeAvailableDrawdownTypes(drawdownVersions);
        this.initExcelConfig(this.drawdowns);
      });
  }

  toDetailsRouterFunction(saved: PolicyDrawdownPaymentsDto) {
    this.router.toPolicyDrawdownPaymentsDetails(saved.id);
  }

  changeDrawdown(event: CellChangeEvent<PolicyDrawdownPaymentDto>): void {
    const payment = event.item;
    payment.currency = this.policyContractPreview.currency;
    payment.registrationDate = new Date();
    this.clearErrors();
  }

  sendConfirmationTask() {
    this.service.sendConfirmationTask(this.current.id).subscribe({
      next: () => this.showSavedMsg('policyDrawdown.confirmationRequestSent'),
      error: (error) => this.handleServerError(error),
    });
  }

  confirm() {
    this.service.confirm(this.current.id).subscribe({
      next: () => this.initWithId(),
      error: (error) => this.handleServerError(error),
    });
  }

  afterObjectSaved(originalId?: number, toDetailsRouterFunction?: Function) {
    super.afterObjectSaved(originalId, toDetailsRouterFunction);
    this.initWithId();
  }

  initExcelConfig(drawdowns: PolicyDrawdownBaseDto[]) {
    this.pasteDrawdownsFromExcelConfig = [
      {
        columns: [
          {
            type: 'combo',
            property: 'drawdown',
            comboItems: drawdowns,
          },
          {
            type: 'date',
            property: 'registrationDate',
          },
          {
            type: 'date',
            property: 'receivedDate',
          },
          {
            type: 'date',
            property: 'paymentDate',
          },
          {
            type: 'number',
            property: 'amount',
          },
          {
            type: 'dictionary',
            property: 'currency',
            dictionary: 'Currency',
          },
          {
            type: 'dictionary',
            property: 'type',
            dictionary: 'PolicyDrawdownType',
          },
        ],
      },
    ];
  }
}
