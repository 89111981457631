import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {
  BusinessObjectDto,
  CompanyBaseDto,
  DictionaryBaseDto,
  InvoiceCommonCriteria,
  InvoiceItemCriteriaDto,
  InvoiceItemDto,
  InvoiceItemInvoiceCriteriaDto,
  InvoiceItemSimpleDto,
  LocalDateRange,
} from '../../../bonding_shared/model/dtos';
import {InvoiceItemService} from '../../../bonding_shared/services/invoice-item.service';
import {InvoiceCategory, InvoiceItemStatus} from '../../../bonding_shared/model/dictionary-ids';
import {BusinessUnitIdDto} from '../../../bonding_shared/model';
import {LoggedUserService} from '../../../bonding_shared/services/logged-user.service';
import {TranslateService} from '@ngx-translate/core';

/**
 * Service for common logic of invoice components.
 *
 */

@Injectable()
export class InvoiceItemGuiService {
  dataProviders: SearchDataProvider<InvoiceItemCriteriaDto, InvoiceItemDto>[] = [];
  trasnsferDataProvider: SearchDataProvider<InvoiceItemCriteriaDto, InvoiceItemDto>;

  createInvoiceIndicator = false;
  deleteInvoiceItemsIndicator = false;

  constructor(
    private service: InvoiceItemService,
    private loggedUserService: LoggedUserService,
    private translateService: TranslateService
  ) {
    this.trasnsferDataProvider = this.initializeDataProvider(service);
  }

  initializeDataProvider(iservice: InvoiceItemService): SearchDataProvider<InvoiceItemCriteriaDto, InvoiceItemDto> {
    const iDataProvider: SearchDataProvider<InvoiceItemCriteriaDto, InvoiceItemDto> = new SearchDataProvider<
      InvoiceItemCriteriaDto,
      InvoiceItemDto
    >(iservice);
    iDataProvider.searchCriteria.criteria = <InvoiceItemCriteriaDto>{};
    iDataProvider.searchCriteria.criteria.client = <CompanyBaseDto>{};
    iDataProvider.searchCriteria.criteria.businessObject = <BusinessObjectDto>{};
    iDataProvider.searchCriteria.criteria.dateFromRange = <LocalDateRange>{};
    iDataProvider.searchCriteria.criteria.dateToRange = <LocalDateRange>{};
    iDataProvider.searchCriteria.criteria.status = <DictionaryBaseDto>{id: InvoiceItemStatus.AUTOMATIC};
    iDataProvider.searchCriteria.criteria.sourceItem = <InvoiceItemSimpleDto>{};
    iDataProvider.searchCriteria.criteria.sourceInvoiceObject = <InvoiceCommonCriteria>{};
    iDataProvider.searchCriteria.sumBy = ['netAmount'];
    iDataProvider.textSearch = false;

    const businessUnit = this.loggedUserService.getLoggedUserData().businessUnit;
    iDataProvider.searchCriteria.criteria.businessUnit = businessUnit
      ? <BusinessUnitIdDto>{id: businessUnit.id}
      : businessUnit;

    return iDataProvider;
  }

  createDataProvider(): SearchDataProvider<InvoiceItemCriteriaDto, InvoiceItemDto> {
    return new SearchDataProvider<InvoiceItemCriteriaDto, InvoiceItemDto>(this.service);
  }

  public getDataProvider(category: InvoiceCategory): SearchDataProvider<InvoiceItemCriteriaDto, InvoiceItemDto> {
    let dataProvider = this.dataProviders[category];
    if (!dataProvider) {
      dataProvider = this.initializeDataProvider(this.service);
      dataProvider.searchCriteria.criteria.category = <DictionaryBaseDto>{id: category};
      dataProvider.searchCriteria.criteria.invoiceCriteria = <InvoiceItemInvoiceCriteriaDto>{};
      this.dataProviders[category] = dataProvider;
    }
    return dataProvider;
  }

  public getInvoiceItemCategoryLabel(invoiceCategory: DictionaryBaseDto, plural: boolean): string {
    if (!invoiceCategory) {
      return '';
    }

    let labelKey = 'invoiceItem.guiService.';
    labelKey += plural ? 'categories.' : 'category.';
    labelKey += invoiceCategory.code;

    return this.translateService.instant(labelKey);
  }
}
