/**
 * Service for common logic of contract components.
 *
 */
import {Injectable} from '@angular/core';
import {ContractVersionCriteriaDto, ContractVersionDto, SearchDataProvider} from '../../../bonding_shared';
import {PortalContractVersionService} from '../../services';

@Injectable()
export class ClientContractGuiService {
  /**
   * Keeps selected criteria for contract search view
   */
  searchDataProvider: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto>;

  constructor(private contractVersionService: PortalContractVersionService) {
    this.searchDataProvider = new SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto>(
      contractVersionService
    );
    this.searchDataProvider.searchCriteria.criteria = this.contractVersionService.getClientContractsCriteria(true);
    this.searchDataProvider.additionalQueryParams = [{key: 'single', val: 'true'}];
  }
}
