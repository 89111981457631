import {AfterViewInit, Component, Input} from '@angular/core';
import {CessionVersionDto, PolicyCessionVersionService, RouterService} from '../../../../bonding_shared';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';

@Component({
  selector: 'policy-cession-list',
  templateUrl: 'policy-cession-list.component.html',
})
export class PolicyCessionListComponent implements AfterViewInit {
  @Input() cessionVersions: CessionVersionDto[];
  @Input() masterPolicyContractId: number;
  @Input() policyContractId: number;

  constructor(private routerService: RouterService, private cessionService: PolicyCessionVersionService) {}

  ngAfterViewInit(): void {
    if (!this.cessionVersions) {
      const dataProvider = BusinessUtils.createPolicyCessionDataProvider(this.cessionService);
      dataProvider.searchCriteria.criteria.cession.masterPolicyContract.id = this.masterPolicyContractId;
      dataProvider.searchCriteria.criteria.last = true;
      dataProvider.search(null, null).subscribe((list) => {
        console.log('search all master policy cession versions', {criteria: dataProvider.searchCriteria, result: list});
        this.cessionVersions = list.result;
      });
    }
  }

  toCessionVersionPreview(cessionVersion: CessionVersionDto) {
    if (cessionVersion && cessionVersion.id) {
      this.routerService.toCessionVersionPreview(cessionVersion.id);
    }
  }
}
