import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {BrokerContractVersionService, GrowlService, RouterService} from '../../bonding_shared/services';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  BrokerContractDto,
  BrokerContractRepresentativePersonDto,
  BrokerContractRepresentativeVersionBaseDto,
  BrokerContractRepresentativeVersionDto,
} from '../../bonding_shared/model';
import {BrokerContractRepresentativeService} from '../../bonding_shared/services/broker-contract-representative.service';
import * as _ from 'lodash';
import {TextSearchCriteria} from '../../bonding_shared/components/search/model/text-search-criteria';
import {CompanyType} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'broker-contract-representative-details',
  templateUrl: './broker-contract-representative-details.component.pug',
})
export class BrokerContractRepresentativeDetailsComponent extends DetailsView implements OnInit {
  public brokerContract: BrokerContractDto = <BrokerContractDto>{};
  public representativeVersion: BrokerContractRepresentativeVersionDto = <BrokerContractRepresentativeVersionDto>{
    agent: <BrokerContractRepresentativePersonDto>{},
  };
  public representativeVersions: Array<BrokerContractRepresentativeVersionDto> = [];
  public insurerSearchCriteria: TextSearchCriteria = <TextSearchCriteria>{};
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;

  constructor(
    private brokerContractService: BrokerContractVersionService,
    private representativeService: BrokerContractRepresentativeService,
    private route: ActivatedRoute,
    public routerService: RouterService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.insurerSearchCriteria.companyType = [CompanyType.INSURANCE];
    this.selectorNameList = ['insurer'];
    this.initializeSelectorEmitters(false);
  }

  public ngOnInit(): void {
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) => this.getBrokerContract(+params['brokerContractId']));
  }

  public onSave(): void {
    if (this.formValidates()) {
      this.clearErrors();
      this.closeAllSelectors();
      this.representativeService.save(this.representativeVersion).subscribe({
        next: (representativeVersion: BrokerContractRepresentativeVersionDto) =>
          this.onNewRepresentativeVersion(representativeVersion),
        error: (error) => this.handleServerError(error),
      });
    }
  }

  public onCancel(): void {
    this.initializeLastRepresentative();
  }

  public onCreateNewVersion(): void {
    this.newVersion(this.brokerContract.id);
  }

  public formDisabled(): boolean {
    return !!this.representativeVersion && !!this.representativeVersion.id;
  }

  public onSelectVersion(version: BrokerContractRepresentativeVersionDto): void {
    this.representativeVersion = version;
    this.handleButtons();
  }

  private onNewRepresentativeVersion(representativeVersion: BrokerContractRepresentativeVersionDto): void {
    this.representativeVersion = representativeVersion;
    this.getBrokerContractRepresentativeVersions();
    this.handleButtons();
  }

  private getBrokerContract(brokerContractId: number): void {
    this.brokerContractService
      .getBrokerContract(brokerContractId)
      .subscribe((brokerContract: BrokerContractDto) => this.onNewBrokerContract(brokerContract));
  }

  private onNewBrokerContract(brokerContract: BrokerContractDto): void {
    this.brokerContract = brokerContract;
    if (_.isEmpty(brokerContract.representatives)) {
      this.initialVersion(brokerContract.id);
    } else {
      this.initializeLastRepresentative();
    }
  }

  private initializeLastRepresentative(): void {
    const lastRepresentativeVersion = _(
      <Array<BrokerContractRepresentativeVersionBaseDto>>this.brokerContract.representatives
    )
      .filter((representativeVersion) => representativeVersion.last)
      .first();
    this.getBrokerContractRepresentativeVersion(lastRepresentativeVersion.id);
  }

  private getBrokerContractRepresentativeVersion(representativeVersionId: number): void {
    this.representativeService
      .getById(representativeVersionId)
      .subscribe((representativeVersion: BrokerContractRepresentativeVersionDto) =>
        this.onNewRepresentativeVersion(representativeVersion)
      );
  }

  private getBrokerContractRepresentativeVersions() {
    this.representativeService
      .getBrokerContractRepresentativesByBrokerContractId(this.representativeVersion.brokerContract.id)
      .subscribe((versions) => (this.representativeVersions = versions));
  }

  protected handleButtons(): void {
    this.saveButton.hidden = false;
    this.saveButton.disabled = this.formDisabled();
    this.cancelButton.hidden = false;
    this.cancelButton.disabled = this.formDisabled();
    this.newVersionButton.hidden = !this.formDisabled();
    this.newVersionButton.disabled = !this.representativeVersion || !this.representativeVersion.last;
  }

  private newVersion(brokerContractId: number): void {
    this.representativeService
      .newVersion(brokerContractId)
      .subscribe((representativeVersion: BrokerContractRepresentativeVersionDto) =>
        this.onNewRepresentativeVersion(representativeVersion)
      );
  }

  private initialVersion(brokerContractId: number): void {
    this.representativeService
      .initialVersion(brokerContractId)
      .subscribe((representativeVersion: BrokerContractRepresentativeVersionDto) =>
        this.onNewRepresentativeVersion(representativeVersion)
      );
  }
}
