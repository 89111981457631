<div class="bon-card">
  <div class="bon-fixed-header-no-block">
    <div class="card-upper-spacer"></div>
    <div class="card-upper-bar"></div>
    <div class="bon-title" translate>import.title</div>
  </div>
  <div style="margin-top: 100px;">
    <backend-error [errors]="serverErrors"></backend-error>
    <form #form="ngForm">
      <div class="bon-header">{{'import.dataFile' | translate }}</div>
      <div class="bon-card-group">
        <div class="bon-card-inner" style="width: 40%">
          <dict-row *ngIf="!portal || !!portalUserCtx" labelKey="import.type" dictionary="ImportType" [hiddenIds]="hiddenImportTypes" [codeRegexp]="regExp" nullLabel=" "
            [(ngModel)]="form.value.type" [showErrors]="showErrors" name="type" [profileId]="importTypeProfileId"
            (changeItem)="updateParameterDefinitions()" [required]="true" [presentationMode]="params?.cessionVersionId">
          </dict-row>
          <div class="bon-row">
            <label class="bon-label">{{'import.fileToUpload' | translate}}</label>
            <file-select-row label="{{'Select file' | translate}}" [control]="form.controls.filesInput"
                             [disabled]="false" [(ngModel)]="selectedFile"
                             name="filesInput" allowedFileTypes=".xls,.xlsx">
            </file-select-row>
            <div *ngIf="showErrors && !selectedFile" class="invalid">{{'import.selectFileError' | translate }}</div>
          </div>
        </div>

        <div class="bon-card-inner" style="width: 60%">
          <parameter-list [(ngModel)]="parameterValues" name="parameterList" [paramsFixed]="params?.cessionVersionId" [showErrors]="showErrors"></parameter-list>
        </div>
      </div>
      <div class="card-block">
        <button class="bon-btn-success" type="button" [disabled]="inProgress" (click)="save()">{{'import.button.upload' | translate}}
        </button>
        <button class="bon-btn-cancel" type="button" (click)="cancel()">{{'Cancel' | translate}}
        </button>
        <button class="bon-btn-cancel" type="button" [disabled]="importService.binaryDownloader.pending"
                (click)="generateEmptyTemplate()" *ngIf="form.value.type">{{'import.button.generateTemplate' | translate}}
        </button>
      </div>
    </form>
  </div>
 <import-list [importTypeId]="form.value.type?.id" [metadata]="metadata" [profileId]="importTypeProfileId"></import-list>
</div>

