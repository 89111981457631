import {Pipe, PipeTransform} from '@angular/core';
import {CustomFieldValueDto} from '../../bonding_shared/model';

@Pipe({
  name: 'fieldName',
})
export class FieldNamePipe implements PipeTransform {
  transform(v: CustomFieldValueDto) {
    const suffix = v.id ? '' : '*';
    return v.field.name + suffix;
  }
}
