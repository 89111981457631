import {Injectable} from '@angular/core';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {CityDto, PostCodeDto, ProvinceBaseDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';
import {AbstractGeoDictService} from './abstract-geo-dict.service';

@Injectable()
export class GeoDictService extends AbstractGeoDictService<CityDto, PostCodeDto, ProvinceBaseDto> {
  protected url = this.urlPrefix + 'geo';

  constructor(public http: HttpClient, appConfigService: AppConfigService, _loggedUserService: LoggedUserService) {
    super(http, appConfigService, _loggedUserService);
  }
}
