import {Injectable} from '@angular/core';
import {LoggedUserService} from '../../../../../src/client/app/bonding_shared/services/logged-user.service';
import {
  AbstractService,
  AppConfigService,
  BondVerifyParamDto,
  BondVerifyResultDto,
} from '../../../../../src/client/app/bonding_shared';
import {Observable} from 'rxjs';
import {share, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class BondVerifyService extends AbstractService {
  constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'bondVerify';
  }

  verify(bondNumber: string, code: string): Observable<BondVerifyResultDto> {
    const param = <BondVerifyParamDto>{
      bondNumber: bondNumber,
      code: code,
    };

    return this.post1<BondVerifyParamDto, BondVerifyResultDto>(param, this.url).pipe(
      tap((result: BondVerifyResultDto) => {
        if (!result) {
          return;
        }
        console.log('result bond verify', result);
      }),
      share()
    );
  }

  downloadLink(bondNumber: string, code: string) {
    const param = {bondNumber: bondNumber, code: code} as BondVerifyParamDto;
    return this.post1<BondVerifyParamDto, string>(param, this.url + '/downloadLink');
  }
}
