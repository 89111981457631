/**
 * Created by siminski on 30.06.2016.
 */
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, ContentChild, EventEmitter, forwardRef, Input, Output, TemplateRef} from '@angular/core';

const ITEM_COMBO_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ItemComboComponent),
  multi: true,
};

@Component({
  selector: 'item-combo',
  template: `
    <select *ngIf="!presentationMode" [(ngModel)]="selectedKey" [disabled]="disabled" class="bon-select">
      <option *ngIf="nullLabel || nullLabelKey" [ngValue]="undefined">
        {{ nullLabel ? nullLabel : (nullLabelKey | translate) }}
      </option>
      <option *ngFor="let item of items" [ngValue]="item[key]">
        <span *ngIf="!template">{{
          (toStringFunc && toStringFunc(item)) || item[label] || (item[labelKey] && (item[labelKey] | translate))
        }}</span>
        <span *ngIf="template">
          <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{item: item}"></ng-container>
        </span>
      </option>
    </select>
    <ng-container *ngIf="presentationMode">
      <span class="presentation" *ngIf="!template">{{
        (selectedItem &&
          ((toStringFunc && toStringFunc(selectedItem)) ||
            selectedItem[label] ||
            (selectedItem[labelKey] && (selectedItem[labelKey] | translate)))) ||
          nullLabel
      }}</span>
      <span class="presentation" *ngIf="template">
        <ng-container
          *ngIf="selectedItem"
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{item: selectedItem}"
        ></ng-container>
        <ng-container *ngIf="!selectedItem"><span>---</span></ng-container>
      </span>
    </ng-container>
  `,
  providers: [ITEM_COMBO_CONTROL_VALUE_ACCESSOR],
})
export class ItemComboComponent<T> implements ControlValueAccessor {
  @Input() key = 'id';
  @Input() label: String;
  @Input() labelKey = 'labelKey';
  @Input() nullLabel: String;
  @Input() nullLabelKey: String;
  @Input() disabled: any;
  @Input() presentationMode = false;
  @Input() toStringFunc: (item: T) => string;

  @Output() selectItem = new EventEmitter<T>();

  @ContentChild(TemplateRef, {static: true}) template: TemplateRef<any>;

  private onChangeModel: Function;
  private onTouchedModel: Function;
  private _items: Array<T>;
  private _selectedKey: string;
  selectedItem: T;
  private selectedItemFromExternal: T;

  @Input() set items(items: Array<T>) {
    this._items = items;
    if (
      this.selectedItem &&
      this.selectedItem[this.key] !== undefined &&
      this._items.findIndex((i) => i[this.key] === this.selectedItem[this.key]) === -1
    ) {
      this._items.push(this.selectedItem);
    }
    this.updateSelection();
  }

  get items(): Array<T> {
    return this._items;
  }

  get selectedKey(): any {
    return this._selectedKey;
  }

  set selectedKey(k: any) {
    if (k !== this._selectedKey) {
      this._selectedKey = k;
      this.selectedItem = this.findSelectedItem(k);
      if (this.onChangeModel) {
        this.onChangeModel(this.selectedItem);
      }
      this.selectItem.emit(this.selectedItem);
      this.selectedItemFromExternal = this.selectedItem;
    }
  }

  writeValue(it: T): void {
    this.selectedItemFromExternal = it;
    if (
      this.selectedItemFromExternal &&
      this._items &&
      this._items.findIndex((i) => i[this.key] === this.selectedItemFromExternal[this.key]) === -1
    ) {
      this._items.push(this.selectedItemFromExternal);
    }
    this.updateSelection();
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedModel = fn;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private updateSelection() {
    if (this.selectedItemFromExternal) {
      this.selectedItem =
        this.findSelectedItem((<any>this.selectedItemFromExternal)[this.key]) || this.selectedItemFromExternal;
      this._selectedKey = (<any>this.selectedItemFromExternal)[this.key];
    } else {
      this.selectedItem = undefined;
      this._selectedKey = undefined;
    }
  }

  private findSelectedItem(k: any): T {
    if (k && this._items) {
      for (const it of this._items) {
        if (String((<any>it)[this.key]) === String(k)) {
          return it;
        }
      }
    }
    return undefined;
  }
}
