import {Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {BusinessUnitDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';
import {SearchResult} from '../model';
import {Cacheable} from 'ts-cacheable';

@Injectable()
export class BusinessUnitService extends AbstractService {
  protected url = this.urlPrefix + 'businessUnit';

  constructor(public http: HttpClient, appConfigService: AppConfigService, _loggedUserService: LoggedUserService) {
    super(http, appConfigService, _loggedUserService);
  }

  getBusinessUnit(id: number): Observable<BusinessUnitDto> {
    return this.getBusinessUnits().pipe(map((bus) => bus.find((bu) => bu.id === id)));
  }

  @Cacheable()
  getBusinessUnits(): Observable<BusinessUnitDto[]> {
    console.log('loading business units..');
    return this.get<SearchResult<BusinessUnitDto>>(this.url).pipe(map((data) => data.result));
  }
}
