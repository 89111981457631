import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from '../../bonding_shared/services/abstract.service';
import {AppConfigService} from '../../bonding_shared/services/app-config.service';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {QuotationDto, QuotationTariffDto, StateTransitionDto} from '../../bonding_shared/model/dtos';
import {CompanyDto, QuotationConfigurationDto} from '../../bonding_shared/model';
import {BinaryDownloaderService} from '../../bonding_shared/services/binary-downloader.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class QuotationService extends AbstractService {
  protected url = this.urlPrefix + 'portal/quotation';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getQuotation(id: number): Observable<QuotationDto> {
    console.log('quotation details: id = ' + id);
    return this.get<QuotationDto>(this.url + '/' + id);
  }

  getQuotationPreconfiguration(clientId: number, clientTurnover: number): Observable<QuotationConfigurationDto> {
    return this.get<QuotationConfigurationDto>(
      this.url + '/preconfiguration?clientId=' + clientId + '&turnover=' + clientTurnover
    );
  }

  getQuotationTemplate(contractId: number, anonymous: boolean): Observable<QuotationDto> {
    return this.get<QuotationDto>(this.url + '/template/' + contractId + '/' + anonymous);
  }

  saveQuotation(quotation: QuotationDto): Observable<QuotationDto> {
    if (quotation.id > 0) {
      return this.updateQuotation(quotation);
    } else {
      return this.createQuotation(quotation);
    }
  }

  createQuotation(quotation: QuotationDto): Observable<QuotationDto> {
    console.log('crete quotation:', quotation);
    return this.put<QuotationDto>(quotation, this.url);
  }

  updateQuotation(quotation: QuotationDto): Observable<QuotationDto> {
    console.log('update quotation:', quotation);
    return this.post<QuotationDto>(quotation, this.url);
  }

  deleteQuotation(id: number): Observable<QuotationDto> {
    console.log('delete quotation: id = ' + id);
    return this.delete<QuotationDto>(this.url + '/' + id);
  }

  getTransitions(quotation: QuotationDto): Observable<Array<StateTransitionDto>> {
    return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + quotation.businessStatus.id);
  }

  getTransitionsMap(): Observable<Map<number, Array<StateTransitionDto>>> {
    return this.get<Map<number, Array<StateTransitionDto>>>(this.url + '/transition');
  }

  issueContract(quotationId: number): Observable<QuotationDto> {
    return this.get<QuotationDto>(this.url + '/' + quotationId + '/issueContract');
  }

  calculateTariff(
    quotationId: number,
    facilityFee: number,
    collateral: number,
    discount: number
  ): Observable<QuotationTariffDto> {
    let query = this.url + '/tariff/' + quotationId;
    let paramEmpty = true;
    if (facilityFee !== null && collateral === null) {
      query += '?fee=' + facilityFee;
      paramEmpty = false;
    }
    if (collateral !== null && facilityFee === null) {
      query += '?collateral=' + collateral;
      paramEmpty = false;
    }
    if (!isNaN(discount)) {
      query += paramEmpty ? '?' : '&';
      query += 'discount=' + discount;
    }
    return this.get<QuotationTariffDto>(query);
  }

  addCoinsured(quotationId: number, coinsuredExternalId: string, clientExternalId: string): Observable<QuotationDto> {
    return this.post1<any, QuotationDto>(
      {quotationId: quotationId, coinsuredExternalId: coinsuredExternalId, clientExternalId: clientExternalId},
      this.url + '/coinsured/'
    );
  }

  removeCoinsured(quotationId: number, companyId: number): Observable<QuotationDto> {
    return this.delete<QuotationDto>(this.url + '/coinsured/' + quotationId + '/' + companyId);
  }

  getDraftPackage(id: number, langId: number) {
    const fileName = 'draft_package.zip';
    this.binaryDownloader.download(
      'GET',
      this.url + '/getdraftpackage/' + id + '?langId=' + langId,
      'application/octet-stream',
      'application/octet-stream',
      fileName
    );
  }

  getCompany(biSourceId: number, externalId: string): Observable<CompanyDto> {
    return this.get<CompanyDto>(this.url + '/company/' + biSourceId + '/' + externalId);
  }

  getBEBankClientScores(): Observable<number[]> {
    return this.get<number[]>(this.url + '/beBankClientScores');
  }
}
