<div class="bon-row">
  <label class="bon-label font-larger" translate="bond.mode.creation.title"></label>
  <string-combo class="bon-input-size" [(ngModel)]="modeCreationKey" [items]="modeCreationKeyItems"
                [presentationMode]="disabled || subtypeInitiated" (changeItem)="onChangeModeCreationKey()">
  </string-combo>
</div>

<ng-container *ngIf="showBondSubType" [ngSwitch]="modeCreationKey">
  <ng-container *ngSwitchCase="'Standard'">
    <form-row labelKey="bond.shared.subtypeSelector.subtype">
      <dict-combo class="bon-input-size" dictionary="BondSubtype" nullLabel=" " [(ngModel)]="bond.subtype"
                  (changeItem)="onSubtypeChange($event)" parentDictionary="BondType"
                  [parentDictionaryEntryId]="parentBondTypeId"
                  [ngModelOptions]="{standalone: true}"
                  [presentationMode]="disabled"></dict-combo>
    </form-row>
  </ng-container>
  <ng-container *ngSwitchCase="'UserTemplate'"> <!-- todoa krzywo custome template -->
    <div class="bon-row"><label class="font-larger" translate="bond.shared.subtypeSelector.customTemplate"></label></div>
    <div class="bon-row">
      <input-row *ngIf="disabled" labelKey="bond.shared.subtypeSelector.template"
                 [(ngModel)]="selectedCustomTemplate.name" name="selectedCustomTemplate.name"
                 [ngModelOptions]="{standalone: true}" [disabled]="true"></input-row>
      <ng-container *ngIf="!disabled">
        <label class="bon-label" for="customTemplates" translate="bond.shared.subtypeSelector.template"></label>
        <item-combo class="bon-input-size" id="customTemplates" nullLabel=" " label="name"
                    [items]="customTemplates" [(ngModel)]="selectedCustomTemplate"
                    [ngModelOptions]="{standalone: true}" (selectItem)="onChangeCustomTemplate($event)"
                    [presentationMode]="disabled"></item-combo>
        <div class="invalid" *ngIf="showErrors && !selectedCustomTemplate"
             translate="bond.shared.subtypeSelector.pleaseSelectCustomTemplate">
        </div>
      </ng-container>
    </div>

  </ng-container>
  <ng-container *ngSwitchCase="'SpecialText'">
    <div *ngIf="specialTextUploadFile" class="bon-row">
      <label class="bon-label" style="vertical-align: top !important;" translate="bond.shared.subtypeSelector.specialTextFile"></label>
      <a class="a-link" style=" width: 160px; display: inline-block; word-wrap: break-word;" (click)="getSpecialTextFile()">{{specialTextUploadFile.name}}</a>
      <span *ngIf="!disabled" class="expandableContent" style="margin-left: 8px; vertical-align: top !important; display: inline-block;">
        <i class="fa fa-search" title="Search" (click)="onFileListOpen()"></i>
      </span>
    </div>
  </ng-container>
</ng-container>

