import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractAuditedRelatedService} from './abstract-audited-related.service';
import {RecoveryDto, RecoveryRevisionEntityDto} from '../model';
import {Observable} from 'rxjs';

@Injectable()
export class ClaimRecoveriesService extends AbstractAuditedRelatedService<RecoveryDto> {
  protected url = this.urlPrefix + 'claim';
  protected midUrl = 'recoveries';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getRevisions(id: number): Observable<RecoveryRevisionEntityDto[]> {
    return this.get(this.build(id) + '/revisions');
  }

  sendConfirmationTask(id: number) {
    return this.postEmpty(this.build(id) + '/requestConfirmation');
  }

  confirm(id: number) {
    return this.postEmpty(this.build(id) + '/confirm');
  }
}
