import {Component, Input, OnInit} from '@angular/core';
import {AmountDto, DictionaryBaseDto} from '../../../bonding_shared/model';
import {BusinessPropertiesService} from '../../../bonding_shared/services/business-properties.service';
import {AppConfigService, LoggedUserService} from '../../../bonding_shared/services';

@Component({
  selector: 'limit-value-display',
  templateUrl: './limit-value-display.component.pug',
})
export class LimitValueDisplayComponent implements OnInit {
  @Input() amount: AmountDto;
  @Input() currency: DictionaryBaseDto;
  @Input() singleRiskOrTurnover: boolean;
  systemCurrency: DictionaryBaseDto;

  constructor(
    private businessPropertiesService: BusinessPropertiesService,
    private appService: AppConfigService,
    private loggedUserService: LoggedUserService
  ) {}

  ngOnInit() {
    this.setSystemCurrency();
  }

  setSystemCurrency() {
    this.businessPropertiesService.getProperties().subscribe((p) => (this.systemCurrency = p.systemCurrency));
  }

  selectLabelForValue() {
    if (this.appService.credendo && this.singleRiskOrTurnover) {
      return 'limitDetails.specialLimitAmount';
    }
    return 'limitDetails.amount';
  }

  get portal() {
    return this.loggedUserService.portal;
  }
}
