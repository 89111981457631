import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {CountryRatingDto, CountryRatingVersionDto, DictionaryBaseDto, DocumentDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class CountryRatingService extends AbstractService {
  protected url = this.urlPrefix + 'countryRating';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getCountryRating(id: number): Observable<CountryRatingDto> {
    return this.get<CountryRatingDto>(this.url + '/' + id);
  }

  getCountryRatingVersion(id: number): Observable<CountryRatingVersionDto> {
    return this.get<CountryRatingVersionDto>(this.url + '/getVersion?versionId=' + id);
  }

  getLastCountryRatings(): Observable<CountryRatingVersionDto[]> {
    return this.get<CountryRatingVersionDto[]>(this.url + '/lastVersions');
  }

  getLastVersion(countryId: number): Observable<CountryRatingVersionDto> {
    return this.get<CountryRatingVersionDto>(this.url + '/lastVersion?countryId=' + countryId);
  }

  getCurrentVersion(countryId: number, type: number): Observable<CountryRatingVersionDto> {
    return this.get<CountryRatingVersionDto>(this.url + '/currentVersion?countryId=' + countryId + '&type=' + type);
  }

  getLastVersionForCompany(companyId: number): Observable<CountryRatingVersionDto> {
    return this.get<CountryRatingVersionDto>(this.url + '/lastVersionForCompany?companyId=' + companyId);
  }

  saveCountryRating(rating: CountryRatingDto): Observable<CountryRatingDto> {
    return this.post<CountryRatingDto>(rating, this.url);
  }

  newRatingVersion(id: number, type: number): Observable<CountryRatingVersionDto> {
    return this.get<CountryRatingVersionDto>(this.url + '/newVersion?countryId=' + id + '&type=' + type);
  }

  createRatingVersion(rating: CountryRatingVersionDto): Observable<CountryRatingVersionDto> {
    return this.put<CountryRatingVersionDto>(rating, this.url);
  }

  uploadRatingsCSV(text: string): Observable<number> {
    return this.postText<number>(text, this.url + '/upload');
  }

  getDocument(id: number): Observable<DocumentDto> {
    return this.get<DocumentDto>(this.url + '/' + id);
  }

  getMarketableCountries(): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/marketableCountries');
  }

  getNonMarketableCountries(): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/nonMarketableCountries');
  }
}
