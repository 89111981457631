import {Component, forwardRef, Input} from '@angular/core';
import {ClaimSurveyGivenAnswerDto} from '../../../bonding_shared';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {SurveyAnswerControlConfiguration} from './claim-survey.component';

const CLAIM_SURVEY_ANSWER_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ClaimSurveyAnswerComponent),
  multi: true,
};
const CLAIM_SURVEY_ANSWER_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ClaimSurveyAnswerComponent),
  multi: true,
};

@Component({
  selector: 'claim-survey-answer',
  templateUrl: 'claim-survey-answer.component.pug',
  providers: [CLAIM_SURVEY_ANSWER_CONTROL_VALUE_ACCESSOR, CLAIM_SURVEY_ANSWER_VALIDATOR],
})
export class ClaimSurveyAnswerComponent implements ControlValueAccessor, Validator {
  @Input()
  presentationMode = false;
  @Input()
  showErrors = false;
  @Input()
  answerConfig: SurveyAnswerControlConfiguration;
  @Input()
  skipValidation = false;

  formGroup: UntypedFormGroup;

  constructor(formBuilder: UntypedFormBuilder) {
    this.formGroup = formBuilder.group({
      answerId: [''],
      checked: [''],
      comment: [''],
      document: [''],
    });
  }

  writeValue(answer: ClaimSurveyGivenAnswerDto) {
    if (answer) {
      this.formGroup.patchValue(answer);
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched() {}

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }

  validate(form: UntypedFormGroup): ValidationErrors {
    if (this.skipValidation) {
      return;
    }
    if (this.answerConfig.required && form.value && !form.value.checked) {
      return {required: 'mustBeChecked'};
    }
    return null;
  }
}
