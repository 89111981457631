import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {CompanySharedModule} from '../../bonding/company/components/shared/company-shared.module';
import {PolicyContractSharedModule} from '../../bonding/policy/components/shared/policy-contract-shared.module';
import {ClientOutstandingsDetailsComponent} from './client-outstandings-details.component';
import {BrokerContractModule} from '../../bonding/broker-contract/broker-contract.module';
import {ClientOutstandingsImportComponent} from './client-outstandings-import.component';
import {PortalPolicyContractService} from '../services';
import {DeclarationSharedModule} from '../../bonding/declaration/shared/declaration-shared.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    PolicyContractSharedModule,
    BrokerContractModule,
    DeclarationSharedModule,
  ],
  declarations: [ClientOutstandingsDetailsComponent, ClientOutstandingsImportComponent],
  providers: [PortalPolicyContractService],
})
export class ClientOutstandingsModule {}
