import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DictionaryBaseDto, PolicyClauseDto} from '../../../../bonding_shared/model';
import {AppConfigService, RouterService} from '../../../../bonding_shared/services';
import {StringUtils} from '../../../../bonding_shared/utils';

@Component({
  selector: 'policyclause-list',
  templateUrl: './policyclause-list.component.pug',
})
export class PolicyClauseListComponent {
  @Input() clauses: PolicyClauseDto[];
  @Input() addButton = false;
  @Input() showAllColumns = true;

  @Output() addClauseClick = new EventEmitter<void>();

  constructor(public router: RouterService, public appService: AppConfigService) {}

  shortCode(d: DictionaryBaseDto): string {
    return StringUtils.shortCode(d.code);
  }
}
