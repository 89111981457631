<search-view objectName="Company Group" customTitle="searchView.groupSearch" newObjectLabelKey="group.search.new"
             [dataProvider]="dataProvider" [enableSearchViewError]="true">
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner">
      <id-row labelKey="group.search.id" [(ngModel)]="dataProvider.searchCriteria.criteria.companyGroup.id" name="id"></id-row>
      <input-row labelKey="group.search.name" [(ngModel)]="dataProvider.searchCriteria.criteria.name"
                 name="name"></input-row>
    </div>
    <div class="bon-card-inner">
      <input-row labelKey="group.search.groupHead"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.groupHead.registrationName"
                 name="groupHead"></input-row>
      <input-row labelKey="group.search.memberCompany"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.memberCompany.registrationName"
                 name="memberCompany"></input-row>
    </div>
  </div>
  <div class="search-results">
    <a-table [pagination]="true" [selection]="false" [sortable]="!dataProvider.textSearch" [showBackendErrors]="false">
      <column property="companyGroup.id" labelKey="group.search.id" sort="id" initialSort="up"></column>
      <column property="name" labelKey="group.search.name" [link]="true" (linkClick)="toCompanyGroupPreview($event)"
              sort="name"></column>
      <column property="groupHead.registrationName" labelKey="group.search.groupHead" [link]="true"
              (linkClick)="toCompanyGroupPreview($event)" sort="groupHead.registrationName"></column>
      <column property="memberCount" labelKey="group.search.membersCount"></column>
      <column *ngIf="kuke" property="insuranceLiability" labelKey="group.search.insuranceLiability"></column>
      <column *ngIf="kuke" property="bondLiability" labelKey="group.search.bondLiability"></column>
      <column *ngIf="kuke" property="liabilityCurrency" labelKey="common.currency" dictionary="Currency" dictLabel="code"></column>
    </a-table>
  </div>
</search-view>
