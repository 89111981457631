import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  BackendError,
  BondDto,
  BondVersionCriteriaDto,
  BondVersionSimpleDto,
  ContractBondCriteriaDto,
  ContractBondExtendedDto,
  ContractCompanyDto,
  ContractVersionCriteriaDto,
  ContractVersionDto,
  ContractVersionSimpleDto,
  SearchCriteria,
  StatisticsDto,
} from '../../bonding_shared/model';
import {PortalContractVersionService} from '../services/portal-contract-version.service';
import {PortalRouterService} from '../services/portal-router.service';
import {BondVersionService, FormatService, LoggedUserService, SearchDataProvider} from '../../bonding_shared/services';
import {ClientBondGuiService, ContractBondPortalService} from '../clientbond/services';
import {ContractVersionListComponent} from '../../bonding/bonding-contract/contract/components/shared/contract-version-list.component';
import {
  BondingContractDocumentType,
  BusinessObjectType,
  ContractCompanyRole,
  ContractStatus,
  ContractType,
  ElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';

@Component({
  selector: 'common-contract-preview',
  templateUrl: './common-contract-preview.component.pug',
})
export class CommonContractPreviewComponent implements OnInit {
  @ViewChild(ContractVersionListComponent, {static: true})
  contractVersionListComponent: ContractVersionListComponent;

  @Input() versionListHidden = true;
  contractVersion: ContractVersionDto;
  bonds: BondVersionSimpleDto[];
  statistics: StatisticsDto;
  serverErrors: BackendError;
  bondDataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>;
  bondTypeDataProvider: SearchDataProvider<ContractBondCriteriaDto, ContractBondExtendedDto>;
  validFromStr: string;
  validToStr: string;
  isClient: boolean;
  coinsureds: ContractCompanyDto[];
  contractVersionDP: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>;

  readonly ContractType = ContractType;
  readonly ContractStatus = ContractStatus;
  readonly BusinessObjectType = BusinessObjectType;
  readonly BondingContractDocumentType = BondingContractDocumentType;

  constructor(
    private contractService: PortalContractVersionService,
    private bondService: BondVersionService,
    private contractBondService: ContractBondPortalService,
    private route: ActivatedRoute,
    private router: PortalRouterService,
    public guiService: ClientBondGuiService,
    private _loggedUserService: LoggedUserService,
    private formatService: FormatService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const contractVersionId = +params['contractVersionId'];
    this.isClient = this._loggedUserService.hasRight(ElementaryRight.CLIENT_BONDS);
    console.log('onInit: contractVersionId = ' + contractVersionId);
    this.contractService.getContractVersion(contractVersionId).subscribe({
      next: (contract) => this.afterContractReceived(contract),
      error: (error) => (this.serverErrors = error),
    });
    this.loadStatistics(contractVersionId);
  }

  afterContractReceived(contract: ContractVersionDto) {
    this.setContractVersion(contract);
    console.log('contract version loaded:: versionId = ' + this.contractVersion.id);
    this.coinsureds = this.contractVersion.contractCompanies.filter((c) => c.role.id === ContractCompanyRole.CLIENT);
    if (this.isClient) {
      this.createBondDataProvider(contract.contract.id);
    }

    this.createBondTypeDataProvider(this.contractVersion.id);
    if (this.contractVersion.validFrom !== undefined) {
      this.validFromStr = this.formatService.formatDate(this.contractVersion.validFrom);
    } else {
      this.validFromStr = '';
    }

    if (this.contractVersion.validTo !== undefined) {
      this.validToStr = this.formatService.formatDate(this.contractVersion.validTo);
    } else {
      this.validToStr = '';
    }
  }

  get contractNumber(): string {
    return BusinessUtils.contractNumber(this.contractVersion);
  }

  setContractVersion(c: ContractVersionDto) {
    this.contractVersion = c;
    this.setContractVersionDataProvider(c.contract.id);
  }

  loadStatistics(id: number) {
    return this.contractService.getContractStatistics(id).subscribe((s) => (this.statistics = s));
  }

  createBondDataProvider(contractId: number) {
    this.bondDataProvider = new SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>(this.bondService);
    this.bondDataProvider.searchCriteria = <SearchCriteria<BondVersionCriteriaDto>>{};
    this.bondDataProvider.searchCriteria.criteria = <BondVersionCriteriaDto>{};
    this.bondDataProvider.searchCriteria.criteria.bond = <BondDto>{};
    this.bondDataProvider.searchCriteria.criteria.contractId = contractId;
  }

  createBondTypeDataProvider(contractVersionId: number) {
    this.bondTypeDataProvider = new SearchDataProvider<ContractBondCriteriaDto, ContractBondExtendedDto>(
      this.contractBondService
    );
    this.bondTypeDataProvider.searchCriteria = <SearchCriteria<ContractBondCriteriaDto>>{};
    this.bondTypeDataProvider.searchCriteria.criteria = <ContractBondCriteriaDto>{};
    this.bondTypeDataProvider.searchCriteria.criteria.contractVersionId = contractVersionId;
  }

  onSelectContractVersion(contractVersion: ContractVersionDto) {
    console.log('onSelectContractVersion :: validFrom: ', typeof contractVersion.validFrom, contractVersion.validFrom);
    this.contractService.getContractVersion(contractVersion.id).subscribe((cv) => {
      this.afterContractReceived(cv);
      if (this.isClient) {
        this.router.toClientContractPreview(this.contractVersion.id);
      } else {
        this.router.toBrokerPortfolioContractVersionPreview(this.contractVersion.id);
      }
    });
  }

  updateButtons() {}

  setContractVersionDataProvider(contractId: number) {
    this.contractVersionDP = BusinessUtils.createContractVersionDataProvider(this.contractService);
    this.contractVersionDP.searchCriteria.criteria.contract.id = contractId;
  }
}
