import {Component, Input, ViewChild} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {
  AppConfigService,
  ATableComponent,
  CompanyBaseDto,
  DictionaryBaseDto,
  PolicyGuarantorDto,
  RouterService,
} from '../../../../bonding_shared';
import {PolicyGuarantorRelationType} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'collaterals-section',
  templateUrl: './collaterals-section.component.pug',
})
export class CollateralsSectionComponent extends Section {
  self = this;

  @ViewChild('guarantorsList', {static: true}) guarantorsList: ATableComponent<PolicyGuarantorDto>;

  @Input() showSelected = false;
  @Input() showRelationType = false;
  @Input() showMehibColumns = false;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    public router: RouterService
  ) {
    super(policyContractViewService, appService);
  }

  addPolicyGuarantor(company: CompanyBaseDto) {
    this.guarantorsList.onAddItem(<PolicyGuarantorDto>{
      company: company,
      selected: false,
      relationType: <DictionaryBaseDto>{id: PolicyGuarantorRelationType.PUBLIC, name: 'Public'},
    });
  }

  addMainContractor(company: CompanyBaseDto) {
    this.policy.mainContractor = company;
  }
}
