import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  BrokerContractRelationService,
  BrokerContractRelationVersionSimpleDto,
  DictionaryBaseDto,
  FeatureService,
  RouterService,
  SearchResult,
} from '../../../../bonding_shared';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {BrokerType, BusinessObjectType} from '../../../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'broker-contract-relation-list',
  templateUrl: './broker-contract-relation-list.component.pug',
})
export class BrokerContractRelationListComponent implements AfterViewInit {
  @ViewChild('relationTable', {static: true}) resultList: ATableComponent<BrokerContractRelationVersionSimpleDto>;

  @Input() showContractColumns = false;
  @Input() showBrokerColumns = false;
  @Input() showPolicyColumns = false;
  @Input() relatedToTypeId: number; // BusinessObjectType.CONTRACT or BusinessObjectType.MASTER_POLICY
  @Input() relatedToId: number;
  @Input() brokerType: DictionaryBaseDto;
  @Input() brokerContractId: number;
  @Input() notStatusId: number = null;
  @Input() businessStatusId: number = null;
  @Input() selection = false;

  @Output() dataProviderSearchFinished = new EventEmitter<SearchResult<BrokerContractRelationVersionSimpleDto>>();
  @Output() selectItem = new EventEmitter<BrokerContractRelationVersionSimpleDto>();

  public get kuke(): boolean {
    return this.appService.kuke;
  }

  constructor(
    private brokerRelationService: BrokerContractRelationService,
    public router: RouterService,
    protected translateService: TranslateService,
    public appService: AppConfigService,
    public featureService: FeatureService
  ) {}

  ngAfterViewInit() {
    const dp = BusinessUtils.createBrokerRelationDataProvider(this.brokerRelationService);
    dp.searchCriteria.criteria.brokerRelation.businessObject.relatedTo.id = this.relatedToTypeId;
    dp.searchCriteria.criteria.brokerRelation.businessObject.relatedToId = this.relatedToId;
    dp.searchCriteria.criteria.brokerRelation.brokerContract.id = this.brokerContractId;
    dp.searchCriteria.criteria.last = true;
    if (this.notStatusId) {
      dp.searchCriteria.criteria.notStatus = <DictionaryBaseDto>{id: this.notStatusId};
    }
    if (this.businessStatusId) {
      dp.searchCriteria.criteria.businessStatus = <DictionaryBaseDto>{id: this.businessStatusId};
    }
    this.resultList.dataProvider = dp;
    this.resultList.search(20);
    if (!this.relatedToTypeId) {
      this.relatedToTypeId = this.showContractColumns ? BusinessObjectType.CONTRACT : BusinessObjectType.MASTER_POLICY;
    }
  }

  isBrokerTypeAgent(): boolean {
    return !!this.brokerType && this.brokerType.id === BrokerType.AGENT;
  }

  brokerContractRelationTableSelectItem(item: BrokerContractRelationVersionSimpleDto) {
    this.selectItem.emit(item);
  }
}
