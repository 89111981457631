import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {SearchResult, UserIdDto, UserSimpleDto} from '../../model';
import {ItemComboComponent} from './item-combo.component';
import {LoggedUserService, UserRange, UserService} from '../../services';
import {Observable} from 'rxjs';
import * as _ from 'lodash';

const USER_COMBO_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UserComboComponent),
  multi: true,
};

@Component({
  selector: 'user-combo',
  template: `
    <select
      *ngIf="!presentationMode"
      [(ngModel)]="selectedKey"
      (change)="selectedKey = $event.target.value"
      [disabled]="disabled"
      class="bon-select"
    >
      <option *ngIf="nullLabel || nullLabelKey" value="undefined">
        {{ nullLabel ? nullLabel : (nullLabelKey | translate) }}
      </option>
      <option *ngFor="let item of items" [value]="item[key]">
        <span>{{ item | username }}</span>
      </option>
    </select>
    <ng-container *ngIf="presentationMode">
      <span class="presentation">{{ selectedItem?.fullName || nullLabel }}</span>
    </ng-container>
  `,
  providers: [USER_COMBO_CONTROL_VALUE_ACCESSOR],
})
export class UserComboComponent extends ItemComboComponent<UserIdDto> implements OnInit {
  @Input() range: UserRange = 'intranet';

  @Input() customLoadUsersFunc: (...args) => Observable<SearchResult<UserSimpleDto>>;

  @Input() set customLoadUsersFuncArgs(customLoadUsersFuncArgs) {
    console.log('customLoadUsersFuncArgs', customLoadUsersFuncArgs);
    if (!_.isEqual(this._customLoadUsersFuncArgs, customLoadUsersFuncArgs)) {
      this._customLoadUsersFuncArgs = customLoadUsersFuncArgs;
      this.loadUsersUsingCustomFunc();
    }
  }

  private _customLoadUsersFuncArgs: any[];
  private initialized = false;

  constructor(protected userService: UserService, private loggedUserService: LoggedUserService) {
    super();
  }

  ngOnInit(): void {
    this.initialized = true;
    if (!this.loggedUserService.portal) {
      if (this.customLoadUsersFunc) {
        this.loadUsersUsingCustomFunc();
      } else {
        this.userService.getUsers(this.range).subscribe((users) => (this.items = users));
      }
    } else {
      this.items = [];
    }
  }

  public loadUsersUsingCustomFunc() {
    if (this.initialized && this._customLoadUsersFuncArgs) {
      this.customLoadUsersFunc(...this._customLoadUsersFuncArgs).subscribe((result) => (this.items = result.result));
    }
  }
}
