import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivitySimpleDto, CollectionCostsViewDto, CollectionEstimatedCostDto} from '../model';
import {CollectionAuditedService} from './collection-audited.service';
import {Observable} from 'rxjs';

@Injectable()
export class CollectionEstimatedCostsService extends CollectionAuditedService<CollectionCostsViewDto> {
  protected midUrl = 'estimatedCosts';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  sendToAcceptance(parentId: number, userId: number): Observable<ActivitySimpleDto> {
    return this.post1(userId, this.build(parentId) + '/sendToAcceptance');
  }

  reject(parentId: number, dto: CollectionCostsViewDto): Observable<CollectionCostsViewDto> {
    return this.post(dto, this.build(parentId) + '/reject');
  }

  getCostById(costId: number): Observable<CollectionEstimatedCostDto> {
    return this.get(this.build(undefined) + '/' + costId);
  }
}
