import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {
  LimitBaseDto,
  LimitRequestCriteriaDto,
  LimitRequestSimpleDto,
  ProductLimitListDto,
} from '../../../../bonding_shared/model/dtos';
import {Subject} from 'rxjs';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {AppConfigService, LimitService} from '../../../../bonding_shared/services';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table/a-table.component';

@Component({
  selector: 'limit-request-selector',
  templateUrl: './limit-request-selector.component.pug',
})
export class LimitRequestSelectorComponent {
  @ViewChild(ATableComponent, {static: true}) atable: ATableComponent<LimitRequestSimpleDto>;

  @Input() labelKey: string;

  /**
   * Open selector event emitter
   * The same emitter instance should be injected into this component and the component that opens this selector
   */
  @Input() open: Subject<boolean>;

  /**
   * when fixedSearch = true, search is done by injected, predefined criteria, user cannot modify them
   */
  @Input() fixedSearch = false;

  @Input() oneResultAutoSelection = false;

  _searchModeSwitchable = false;

  @Input() set searchModeSwitchable(value: boolean) {
    this._searchModeSwitchable = value;
  }

  get searchModeSwitchable(): boolean {
    // IKI 3305
    return this._searchModeSwitchable && !this.appService.kuke;
  }

  @Input() set criteria(c: LimitRequestCriteriaDto) {
    this.dataProvider.searchCriteria.criteria = c;
  }

  @Output() selectItem = new EventEmitter<ProductLimitListDto>();

  dataProvider: SearchDataProvider<LimitRequestCriteriaDto, LimitRequestSimpleDto>;

  constructor(public router: RouterService, private service: LimitService, private appService: AppConfigService) {
    this.dataProvider = BusinessUtils.createLimitRequestDataProvider(service);
    this.dataProvider.textSearch = false;
  }

  getBuyerName(limit: LimitBaseDto) {
    const company = limit.buyer.company || limit.buyer.lastCompanyRaw;
    return BusinessUtils.companyName(company);
  }
}
