import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormDialogParent} from './FormDialogParent';
import {TranslateService} from '@ngx-translate/core';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {BackendError} from '../../model';
import {UntypedFormGroup} from '@angular/forms';
import {StringUtils} from '../../utils';

@Component({
  selector: 'form-dialog',
  templateUrl: './form-dialog.component.pug',
})
export class FormDialogComponent {
  dialogConfirmation: () => void | Promise<void>;
  dialogRejection: () => void;
  modalRef: BsModalRef;

  @ViewChild(TemplateRef, {static: true}) template: TemplateRef<any>;

  @Input() parentComponent: FormDialogParent;
  @Input() form: UntypedFormGroup;
  @Input() size = 'lg';
  @Input() showFooter = true;
  @Input() saveDisabled = false;
  @Input() titleKey: string;
  @Input() saveLabelKey = 'common.button.save';
  @Input() cancelLabelKey = 'common.button.cancel';
  @Input() showCloseIcon = true;
  @Input() modelObjectName: string;
  @Output() formValidates = new EventEmitter<boolean>();

  public errors: BackendError;
  showErrors = false;

  constructor(private translateService: TranslateService, private modalService: BsModalService) {}

  openAndExecuteOnConfirm(dialogConfirmation: () => void | Promise<void>, dialogRejection?: () => void): void {
    this.openInternal();
    this.dialogConfirmation = dialogConfirmation;
    this.dialogRejection = dialogRejection;
  }

  open(titleKey?: string, opts?: ModalOptions): Promise<boolean> {
    this.openInternal(titleKey, opts);
    return new Promise<boolean>((resolve, reject) => {
      this.dialogConfirmation = () => resolve(true);
      this.dialogRejection = () => resolve(false);
    });
  }

  private openInternal(titleKey?: string, opts?: ModalOptions) {
    this.errors = undefined;
    this.showErrors = false;
    if (titleKey) {
      this.titleKey = titleKey;
    }
    const cssClass = 'modal-' + this.size;
    console.log('show form dialog ..');
    this.modalRef = this.modalService.show(this.template, Object.assign({class: cssClass}, opts));
  }

  isFormValid(): boolean {
    const formValid = this.form ? this.form.valid : this.parentComponent ? this.parentComponent.form.valid : true;
    this.formValidates.emit(formValid);
    return formValid;
  }

  save() {
    console.log('dialog:: saved');
    if (!this.isFormValid()) {
      const form = this.form ? this.form : this.parentComponent && this.parentComponent.form;
      if (form) {
        StringUtils.logFormInvalidFieldsRecursive(form);
      }
      if (this.parentComponent) {
        this.parentComponent.showErrors = true;
      }
      this.showErrors = true;
      return;
    }
    Promise.resolve(this.dialogConfirmation())
      .then(() => this.modalRef.hide())
      .catch((errors) => {
        this.errors = errors;
        this.showErrors = true;
      });
  }

  hide() {
    if (this.dialogRejection) {
      this.dialogRejection();
    }
    this.modalRef.hide();
  }
}
