/**
 * Created by siminski on 15.07.2016.
 */
import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'error-message',
  template: `
    <div *ngIf="errorMessage && show" class="invalid" [ngStyle]="{'padding-left': leftPadding}">{{ errorMessage }}</div>
  `,
})
export class ErrorMessageComponent {
  @Input() control: AbstractControl;
  @Input() show = true;
  @Input() leftPadding = '';
  // Show only one type of error by given precedence
  @Input() errorTypePrecedence: string[] = [];

  constructor(private translateService: TranslateService) {}

  get errorMessage(): string {
    if (this.control && !this.control.valid) {
      let message = '';
      if (!this.control.errors) {
        return message;
      }
      for (const error of this.errorTypePrecedence) {
        if (Object.keys(this.control.errors).includes(error)) {
          return this.translateService.instant('errorMessage.' + error, this.control.errors[error]);
        }
      }
      for (const error of Object.keys(this.control.errors)) {
        if (message.length > 0) {
          message += ', ';
        }
        message += this.translateService.instant('errorMessage.' + error, this.control.errors[error]);
      }
      return message;
    } else {
      return undefined;
    }
  }
}
