import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PortalDeclarationService, PortalPolicyContractService, PortalRouterService} from '../services';
import {TranslateService} from '@ngx-translate/core';
import {
  AppConfigService,
  BusinessPropertiesService,
  DeclarationFileService,
  FeatureService,
  FormatService,
  GrowlService,
  LoggedUserService,
} from '../../bonding_shared/services';
import {
  DeclarationBuyerCriteriaDto,
  DeclarationDto,
  DeclarationWithoutEntriesDto,
  Page,
  PolicyContractSimpleIdDataDto,
} from '../../bonding_shared/model';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {
  DeclarationCriteria,
  DeclarationDetailsView,
  DeclarationParams,
} from '../../bonding/declaration/shared/declaration-details-view';
import {DeclarationCriteriaComponent} from '../../bonding/declaration/shared/components/declaration-criteria.component';
import {ConfirmDialogComponent} from '../../bonding_shared';
import {NgForm} from '@angular/forms';
import {BusinessReportPortalService} from '../services/portal-business-report.service';
import {Feature} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'client-amendment-details',
  templateUrl: './client-amendment-details.component.pug',
})
export class ClientAmendmentDetailsComponent extends DeclarationDetailsView implements OnInit {
  canPortalUserDeclareAmendment = false;

  constructor(
    private route: ActivatedRoute,
    private routerService: PortalRouterService,
    public appService: AppConfigService,
    public policyContractService: PortalPolicyContractService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected portalDeclarationService: PortalDeclarationService,
    protected loggedUserService: LoggedUserService,
    private businessReportPortalService: BusinessReportPortalService,
    protected businessPropertyService: BusinessPropertiesService,
    protected formatService: FormatService,
    protected declarationFileService: DeclarationFileService,
    protected featureService: FeatureService
  ) {
    super(
      translateService,
      growlService,
      portalDeclarationService,
      loggedUserService,
      businessReportPortalService,
      appService,
      businessPropertyService,
      formatService,
      declarationFileService
    );
    this.showLimitExport = featureService.get(Feature.DECLARATION_EXPORT_LIMITS_IN_PORTAL);
    this.canPortalUserDeclareAmendment = this.featureService.get(Feature.DECLARATION_CAN_PORTAL_USER_DECLARE_AMENDMENT);
  }

  @ViewChild(DeclarationCriteriaComponent, {static: true}) dcComponent: DeclarationCriteriaComponent;
  @ViewChild('k11Dialog', {static: true}) k11Dialog: ConfirmDialogComponent;
  @ViewChild('mehibPaymentTermDaysDialog', {static: true}) mehibPaymentTermDaysDialog: ConfirmDialogComponent;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  showLimitExport = false;

  ngOnInit() {
    this.form = this.ngForm.form;
    this.dc = <DeclarationCriteria>{buyerCriteria: <DeclarationBuyerCriteriaDto>{}};
    this.amendmentView = true;
    if (this.canPortalUserDeclareAmendment) {
      this.route.params.subscribe((params) => {
        const baseDeclarationIdParam = +params['baseDeclarationId'];
        const policyContractIdParam = +params['policyContractId'];
        const dcParams: DeclarationParams = <DeclarationParams>{
          paramRpId: +params['reportingPeriodId'],
          paramLlvId: +params['limitListVersionId'],
          paramDecOnId: +params['declaredOnId'],
        };
        if (policyContractIdParam) {
          this.dcComponent.initAmendmentByPortalByPolicy(policyContractIdParam);
        } else if (baseDeclarationIdParam) {
          this.portalDeclarationService
            .getById<DeclarationWithoutEntriesDto>(baseDeclarationIdParam)
            .subscribe((t) =>
              this.dcComponent.initAmendmentByPortalByDcParams(this.declarationWithoutEntriesToDeclarationParams(t))
            );
        } else if (dcParams.paramRpId && dcParams.paramLlvId) {
          this.dcComponent.initAmendmentByPortalByDcParams(dcParams);
        } else {
          this.dcComponent.initAmendmentByPortalWithoutSelection();
        }
      });
    }
  }

  onPolicyChanged() {
    this.setDeclarationUndefined();
    this.initializeOrReloadDeclarationHistory(this.dc.selectedPolicy, undefined);
  }

  onDeclarationCriteriaTouchedEvent() {
    this.inProgress_flag = true;
    this.dcComponent.amendmentBlocked_flag = undefined;
    this.clearDeclaration();
  }

  onDeclarationCriteriaChangedEvent(reloadDeclaration: boolean) {
    if (reloadDeclaration) {
      if (this.dc.selectedRp && this.dc.selectedLlv && this.dc.selectedDecOn) {
        this.resetSearchCriteriaWithFirstPage(this.dc);
        // nie przeładowuj amendment mode bo to psuje wyświetlanie kryteriów
        this.initOrFindExistingDeclarationBySearchCriteria(true, false);
      } else {
        this.setDeclarationUndefined();
      }
    }
    if (this.dc.selectedRp && this.dc.selectedLlv && this.dc.selectedDecOn) {
      this.portalDeclarationService.isAmendmentPossible(this.dc.selectedRp.id).subscribe((possible) => {
        this.dcComponent.amendmentBlocked_flag = !possible;
      });
    }
    this.applyFiltersOnDeclarationHistory();
  }

  onFilteringChanged() {
    this.resetSearchCriteriaWithFirstPage(this.dc);
    if (this.edition()) {
      this.inProgress_flag = true;
      this.onFilteringChangedInnerAmendment();
    }
  }

  public saveAction() {
    if (!this.canUpdate() || !this.formValidates()) {
      return;
    }
    const finalStep = this.saveAmendment.bind(this);

    const secondCondition: boolean = this.appService.mehib && this.withWarningDialog();
    const secondDialog: Function = this.openMehibPaymentTermsDialog.bind(this, finalStep);
    const secondStep: Function = this.conditionalDialog(secondCondition, secondDialog, finalStep);

    const firstStep: Function = this.openK11Dialog.bind(this, secondStep);
    return firstStep();
  }

  openK11Dialog(nextStep: Function) {
    this.k11Dialog.openAndExecuteOnConfirm(
      'declaration.details.amendmentDialog.title',
      'declaration.details.amendmentDialog.k11message',
      nextStep,
      undefined,
      undefined
    );
  }

  openMehibPaymentTermsDialog(nextStep: Function) {
    this.mehibPaymentTermDaysDialog.openAndExecuteOnConfirm(
      'declaration.details.saveAmendmentWarningDialog.title',
      'declaration.details.saveAmendmentWarningDialog.msg',
      nextStep,
      undefined,
      undefined
    );
  }

  private saveAmendment() {
    if (!this.formValidates()) {
      return;
    }
    this.inProgress_flag = true;
    if (this.declaration && this.canUpdate()) {
      this.clearErrors();
      this.portalDeclarationService.confirmAmendmentStub(this.declarationToStub()).subscribe(
        (decSimple) => {
          this.edition_flag = false;
          this.searchCriteria.criteria.basedOnDeclarationId = decSimple.id;
          this.clearBuyerFilter();
          this.initOrFindExistingDeclarationBySearchCriteria(true);
          this.initializeOrReloadDeclarationHistory(decSimple.reportingPeriod.policyContractData, decSimple.id);
          this.showSavedMsg('declaration.details.amendmentSaved');
        },
        (error) => {
          this.serverErrors = error;
          this.inProgress_flag = false;
        }
      );
    }
  }

  // ############## GUI CONTROL ##############
  updateCanUpdateFlag() {
    this.canUpdate_flag = this.edition() && this.declaration && !this.declaration.id;
  }

  declarationSetFinished() {
    if (!this.edition()) {
      // kryterii nie zmieniaj w trakcie edycji
      this.resetSearchCriteriaForAmendmentByDeclarationId(this.declaration.id);
    }
  }

  // TODO: to be checked
  onPageChange(event: PageChangedEvent) {
    this.inProgress_flag = true;
    this.resetPage(<Page>{start: (event.page - 1) * event.itemsPerPage, count: event.itemsPerPage});
    if (!this.searchCriteria.criteria.basedOnDeclarationId) {
      this.initAmendmentViewBySearchCriteria();
    } else {
      if (this.edition()) {
        if (this.searchCriteria.criteria.basedOnDeclarationId || this.searchCriteria.criteria.reportingPeriodId) {
          this.portalDeclarationService.initializeAmendmentByCriteria(this.searchCriteria).subscribe(
            (tSR) => this.reloadDeclarationSearchResult(tSR, false),
            () => this.growlService.error('Error during initialization of amendment')
          );
        }
      } else {
        this.initOrFindExistingDeclarationBySearchCriteria(false);
      }
    }
  }

  amendmentBlocked() {
    return this.dcComponent.amendmentBlocked_flag;
  }

  // ############## BUTTONS - NEW AMENDMENT BASED ON LAST DECLARATION ##############
  newDeclarationAmendmentDetails() {
    this.edition_flag = true;
    this.previousDeclarationId = this.declaration.id; // po anulowaniu żeby wrócic do właściwej deklaracji

    this.resetPage();
    this.clearEntryValuesMap();

    this.resetSearchCriteriaForAmendmentByDeclarationId(this.declaration ? this.declaration.id : undefined);
    this.initAmendmentViewBySearchCriteria(true);
  }

  private initAmendmentViewBySearchCriteria(clearSessionEntries = false) {
    if (this.searchCriteria.criteria.basedOnDeclarationId || this.searchCriteria.criteria.reportingPeriodId) {
      this.portalDeclarationService.initializeAmendmentByCriteria(this.searchCriteria).subscribe(
        (tSR) => this.reloadDeclarationSearchResult(tSR, clearSessionEntries),
        () => this.growlService.error('Error during initialization of amendment')
      );
    } else {
      this.initOrFindExistingDeclarationBySearchCriteria();
    }
  }

  // ############## CANCELING ##############
  // TODO: to be checked when multipage
  cancelEdition() {
    this.inProgress_flag = true;
    this.showErrors = false;
    this.edition_flag = false;
    this.clearBuyerFilter();
    if (this.previousDeclarationId) {
      // new amendment based on declaration
      this.resetSearchCriteriaForDeclarationId(this.previousDeclarationId);

      this.portalDeclarationService.findOrInitializeDeclarationByCriteria(this.searchCriteria).subscribe(
        (dec) => this.reloadDeclarationSearchResult(dec, true),
        () => this.growlService.error('Error during initialization of amendment for declaration')
      );
    } else if (this.dc.selectedRp) {
      this.resetSearchCriteriaWithoutPageAndBaseDeclaration(this.dc);
      this.portalDeclarationService.initializeAmendmentByCriteria(this.searchCriteria).subscribe(
        (decSR) => this.reloadDeclarationSearchResult(decSR, true),
        () => this.growlService.error('Error during initialization of amendment')
      );
    }
  }

  // ############## DECLARATION HISTORY CONTROL ##############
  private initializeOrReloadDeclarationHistory(policy: PolicyContractSimpleIdDataDto, selectedDeclarationId?: number) {
    if (this.declarationHistorySectionComponent) {
      this.declarationHistorySectionComponent.initializeOrReloadDeclarationHistory(
        policy,
        this.dc.selectedRp,
        this.dc.selectedLlv,
        this.dc.selectedDecOn,
        selectedDeclarationId
      );
    }
  }

  public onDeclarationSelectFromHistory(declarationStub: DeclarationDto) {
    this.dcComponent.clearSelections();
    this.onDeclarationCriteriaTouchedEvent();
    if (declarationStub && declarationStub.id) {
      this.resetSearchCriteriaForDeclaration(declarationStub);
      this.resetPage();
      this.blockDeclarationSection();
      this.portalDeclarationService.findOrInitializeDeclarationByCriteria(this.searchCriteria).subscribe((tSR) => {
        this.reloadDeclarationSearchResult(tSR, true);
        this.dcComponent.reloadLlVAndDecOnListsAndSetParamsSelections(
          this.declarationToDeclarationParams(this.declaration)
        );
      });
    }
  }

  // same as in policy-details => move to helper od dc
  private declarationToDeclarationParams(declaration: DeclarationDto) {
    if (declaration) {
      return <DeclarationParams>{
        paramRpId: declaration.reportingPeriod ? declaration.reportingPeriod.id : undefined,
        paramLlvId: declaration.limitListVersion ? declaration.limitListVersion.id : undefined,
        paramDecOnId: declaration.declaredOn ? declaration.declaredOn.id : undefined,
      };
    } else {
      return undefined;
    }
  }

  private declarationWithoutEntriesToDeclarationParams(declaration: DeclarationWithoutEntriesDto) {
    if (declaration) {
      return <DeclarationParams>{
        paramRpId: declaration.reportingPeriod ? declaration.reportingPeriod.id : undefined,
        paramLlvId: declaration.limitListVersion ? declaration.limitListVersion.id : undefined,
        paramDecOnId: declaration.declaredOn ? declaration.declaredOn.id : undefined,
      };
    } else {
      return undefined;
    }
  }
}
