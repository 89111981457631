import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {AppConfigService, TaskService} from '../../bonding_shared/services/index';
import {TaskDataComponent} from './components/index';
import {StringUtils} from '../../bonding_shared/utils/index';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomRevisionEntityDto, DictionaryBaseDto, TaskDto} from '../../bonding_shared/model';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {ElementaryRight, TaskStatus} from '../../bonding_shared/model/dictionary-ids';
import {TaskAuditService} from './services/task-audit.service';

@Component({
  selector: 'task-details',
  templateUrl: 'task-details.component.html',
})
export class TaskDetailsComponent extends DetailsView implements OnInit {
  readonly OPEN = TaskStatus.OPEN;
  readonly ElementaryRight = ElementaryRight;

  _taskDataCmp: TaskDataComponent;

  selectedTask: TaskDto;
  assignmentUnlocked = true;
  dueDateUnlocked = true;
  dateTo = new Date();
  self = this;
  isCreator = false;
  isAssignee = false;
  canClose = false;
  canCancel = false;
  historyMode = false;

  private revisions: CustomRevisionEntityDto[];

  constructor(
    private _route: ActivatedRoute,
    protected _taskService: TaskService,
    private loggedUserService: LoggedUserService,
    protected translateService: TranslateService,
    public appConfigService: AppConfigService,
    protected growlService: GrowlService,
    private taskAuditService: TaskAuditService
  ) {
    super(growlService);
    this.saveButton.hidden = !loggedUserService
      .getLoggedUserData()
      .rights.find((item) => item.code === 'TASKENDPOINT_UPDATE');
    this.cancelButton.hidden = false;
  }

  ngOnInit() {
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  applyRights(task: TaskDto) {
    this.isCreator = this.loggedUserService.getLoggedUserData().login === task.createdBy.login;
    this.isAssignee = task.user && this.loggedUserService.getLoggedUserData().login === task.user.login;
    this.canCancel =
      !this.appConfigService.kuke || this.loggedUserService.hasRight(ElementaryRight.TASK_CANCEL) || this.isCreator;
    this.canClose =
      !this.appConfigService.kuke || this.loggedUserService.hasRight(ElementaryRight.TASK_CLOSE) || this.isAssignee;
    const canEdit = !this.appConfigService.kuke || this.isCreator;
    this.dueDateUnlocked = this.loggedUserService.hasRight(ElementaryRight.TASK_EDIT_DUE_DATE) || canEdit;
    this.assignmentUnlocked = this.loggedUserService.hasRight(ElementaryRight.TASK_CHANGE_ASSIGNMENT) || canEdit;
  }

  initializeView(params: Params, force?: boolean) {
    const id = params['id'];
    if (id > 0) {
      this.inProgress = true;
      this._taskService.getTask(id).subscribe((task) => {
        this.selectedTask = task;
        this.applyRights(task);
        this.taskAuditService.getRevisions(id).subscribe((r) => (this.revisions = r));
        if (task.status.id !== TaskStatus.OPEN) {
          // Turn off edition
          this.saveButton.hidden = true;
          this.cancelButton.hidden = true;
          this.historyMode = true;
        }
        this.inProgress = false;
      });
    } else {
      this.createNewTask(params);
    }
  }

  closeTask() {
    this.onSave(TaskStatus.CLOSED);
  }

  cancelTask() {
    this.onSave(TaskStatus.CANCELLED);
  }

  private createNewTask(params: Params) {
    if (params['boTypeId'] && params['boId']) {
      const boTypeId = +params['boTypeId']; // relatedToTypeId
      const boId = +params['boId']; // relatedToId
      this._taskService.initialVersion(boTypeId, boId).subscribe((dto) => {
        this.selectedTask = dto;
      });
    } else {
      this.selectedTask = <TaskDto>{creationDate: new Date()};
      this.selectedTask.status = <DictionaryBaseDto>{id: 17000001};
    }
  }

  get taskDataCmp() {
    return this._taskDataCmp;
  }

  @ViewChild(TaskDataComponent, {static: false})
  set userDataCmp(cmp: TaskDataComponent) {
    if (!cmp) {
      return;
    }
    this._taskDataCmp = cmp;
  }

  onSave(changeStatus?: number) {
    this.taskDataCmp.showErrors = true;
    if (
      !this.taskDataCmp.form.valid ||
      (this.taskDataCmp.boRequired &&
        (!this.taskDataCmp.task.businessObject || !this.taskDataCmp.task.businessObject.relatedToId))
    ) {
      StringUtils.logFormInvalidFields(this.taskDataCmp.form);
      this.showFormError();
      return;
    }
    this.taskDataCmp.errorMessage = null;
    if (this.taskDataCmp.task.id > 0) {
      const oldStatus = this.taskDataCmp.task.status;
      if (changeStatus) {
        this.taskDataCmp.task.status = <DictionaryBaseDto>{id: changeStatus};
      }
      this.inProgress = true;
      this._taskService.updateTask(this.taskDataCmp.task).subscribe({
        next: (task) => {
          this.selectedTask = task;
          this.taskDataCmp.task = task;
          this.applyRights(task);
          if (task.status.id !== TaskStatus.OPEN) {
            // Turn off edition
            this.saveButton.hidden = true;
            this.cancelButton.hidden = true;
            this.historyMode = true;
          }
          this.showSavedMsg();
          this.taskAuditService.getRevisions(task.id).subscribe((r) => (this.revisions = r));
          this.inProgress = false;
          this.serverErrors = null;
        },
        error: (error) => {
          this.taskDataCmp.task.status = oldStatus;
          this.inProgress = false;
          this.handleServerError(error);
        },
      });
    } else {
      this.inProgress = true;
      this._taskService.createTask(this.taskDataCmp.task).subscribe({
        next: (task) => {
          this.taskDataCmp.task = task;
          this.selectedTask = task;
          this.showSavedMsg();
          this.applyRights(task);
          this.taskAuditService.getRevisions(task.id).subscribe((r) => (this.revisions = r));
          this.inProgress = false;
          this.serverErrors = null;
        },
        error: (error) => {
          this.inProgress = false;
          this.handleServerError(error);
        },
      });
    }
  }

  onCancel() {
    this.editModeButtons();
    this.taskDataCmp.businessObjectSelector.remove();
    super.onCancel(this._route);
  }

  showSavedMsg() {
    this.growlService.notice('Task is saved!');
  }

  showFormError() {
    this.growlService.error('The form has errors!');
  }

  onAssignToMe(): void {
    this.taskDataCmp.task.user = this.loggedUserService.getLoggedUserData();
    this.taskDataCmp.task.businessUnit = this.loggedUserService.getLoggedUserData().businessUnit;
    if (this.appConfigService.kuke) {
      this.taskDataCmp.task.userGroup = undefined;
    }
    this.taskDataCmp.setGroupsAndUsersForBu();
    this.onSave();
  }

  hideAssignToMe(): boolean {
    return !(
      this.taskDataCmp &&
      this.isUserInSelectedGroup() &&
      !this.isSelectedCurrentUser() &&
      this.taskDataCmp.task.status.id === TaskStatus.OPEN
    );
  }

  isUserInSelectedGroup(): boolean {
    return !this.taskDataCmp.task.userGroup || this.loggedUserService.isUserInGroup(this.taskDataCmp.task.userGroup.id);
  }

  isSelectedCurrentUser(): boolean {
    return (
      this.taskDataCmp.task.user && this.loggedUserService.getLoggedUserData().id === this.taskDataCmp.task.user.id
    );
  }

  load(revision: CustomRevisionEntityDto) {
    this.taskAuditService.getRelated(this.selectedTask.id, revision.revisionId).subscribe((x) => {
      this.selectedTask = x;
      this.historyModeButtons();
    });
  }

  private historyModeButtons() {
    this.historyMode = true;
    this.taskDataCmp.historyMode = true;
    this.saveButton.hidden = true;
    this.cancelButton.hidden = false;
    this.cancelButton.name = this.translateService.instant('task.details.restore');
    this.cancelButton.class = 'bon-btn-danger';
  }

  private editModeButtons() {
    this.historyMode = false;
    this.taskDataCmp.historyMode = false;
    this.saveButton.hidden = false;
    this.cancelButton.name = this.translateService.instant('common.button.cancel');
    this.cancelButton.class = 'bon-btn-cancel';
  }
}
