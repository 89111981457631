import {APP_INITIALIZER, NgModule} from '@angular/core';
import {LanguageService} from '../../bonding_shared';
import {Router} from '@angular/router';

export function appInitializerFactory(languageService: LanguageService, router: Router) {
  return () =>
    new Promise<any>((resolve, reject) => {
      languageService.initializeLanguage().subscribe({
        error: () => {
          languageService.setBestLanguageForLogin(['en', 'de', 'es', 'fr', 'it', 'nl', 'hu', 'pl']);
          reject();
        },
        complete: () => {
          resolve(null);
        },
      });
    }).catch(() => {
      router.navigate(['login', {errorCode: 'NETWORK_ERROR'}]);
    });
}

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: appInitializerFactory,
      deps: [LanguageService, Router],
    },
  ],
})
export class AppInitializerModule {}
