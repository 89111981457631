import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {AbstractService, AppConfigService, LoggedUserService} from '../../../bonding_shared/services';
import {Observable} from 'rxjs';
import {BlockchainRequestDto, BlockchainResponseDto, UserDto} from '../../../bonding_shared/model';

@Injectable()
export class BlockchainService extends AbstractService {
  protected url = this.urlPrefix + 'portal/blockchain';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getJWT(): Observable<string> {
    return this.get<string>(this.url + '/jwt');
  }

  createIdentity(request: BlockchainRequestDto): Observable<BlockchainResponseDto> {
    return this.post1<BlockchainRequestDto, BlockchainResponseDto>(request, this.url + '/createIdentity');
  }

  confirmIdentityCreation(): Observable<UserDto> {
    return this.get<UserDto>(this.url + '/confirmIdentityCreation');
  }
}
