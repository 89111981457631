import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {
  BondingContractInquiryVersionCriteriaDto,
  BondingContractInquiryVersionSimpleDto,
  DictionaryBaseDto,
} from '../../../../bonding_shared/model';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {BondingContractInquiryService} from '../../../../bonding_shared/services/bonding-contract-inquiry.service';
import {ContractType} from '../../../../bonding_shared/model/dictionary-ids';
import {AppConfigService, LoggedUserService} from '../../../../bonding_shared/services';
import {UserResponsibleService} from '../../../../bonding_shared/services/user-responsible.service';

@Injectable()
export class BondingContractInquiryGuiService {
  dataProvider: SearchDataProvider<BondingContractInquiryVersionCriteriaDto, BondingContractInquiryVersionSimpleDto>;

  constructor(
    private service: BondingContractInquiryService,
    private appConfigService: AppConfigService,
    private loggedUserService: LoggedUserService,
    private userResponsibleService: UserResponsibleService
  ) {
    this.dataProvider = BusinessUtils.createBondingContractInquiryVersionDataProvider(service, loggedUserService);
    this.dataProvider.searchCriteria.criteria.last = true;
    this.dataProvider.textSearch = false;
    if (appConfigService.credendo) {
      this.dataProvider.searchCriteria.criteria.inquiry.productType = <DictionaryBaseDto>{id: ContractType.INDIVIDUAL};
    }
    if (appConfigService.ecg) {
      this.dataProvider.searchCriteria.criteria.inquiry.productType = <DictionaryBaseDto>{
        id: ContractType.ECG_INDIVIDUAL,
      };
    }

    this.dataProvider.searchCriteria.criteria.userResponsible =
      this.userResponsibleService.getUserResponsibleSearchCriteria();
  }
}
