/**
 * Created by siminski on 07.07.2016.
 *
 * Service for common logic of task components.
 *
 */
import {Injectable} from '@angular/core';
import {ActivitySimpleDto, LocalDateRange, TaskDto} from '../../../../bonding_shared/model/index';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {TaskCriteriaDto} from '../../../../bonding_shared/model/dtos';
import {TaskService} from '../../../../bonding_shared/services/task.service';
import {BusinessObjectDto, DictionaryBaseDto} from '../../../../bonding_shared/model';
import {TaskStatus} from '../../../../bonding_shared/model/dictionary-ids';
import {AppConfigService, SettingsService} from '../../../../bonding_shared/services';

@Injectable()
export class TaskGuiService {
  /**
   * Keeps state of TaskSearchComponent
   */
  searchDataProvider: SearchDataProvider<TaskCriteriaDto, ActivitySimpleDto>;
  _moduleId: number;

  get moduleId() {
    return this._moduleId;
  }

  constructor(
    private router: RouterService,
    private taskService: TaskService,
    private settingsService: SettingsService,
    private appConfig: AppConfigService
  ) {
    this.searchDataProvider = new SearchDataProvider<TaskCriteriaDto, ActivitySimpleDto>(taskService);
    this._moduleId = settingsService.getActiveModuleId();
    this.settingsService.viewChange.subscribe((moduleId) => {
      this.updateApplicationModule(moduleId);
    });
  }

  private updateApplicationModule(moduleId: number) {
    if (
      this.searchDataProvider &&
      this.searchDataProvider.searchCriteria &&
      this.searchDataProvider.searchCriteria.criteria
    ) {
      this.searchDataProvider.searchCriteria.criteria.selectedApplicationModule = <DictionaryBaseDto>{id: moduleId};
      if (this.appConfig.kuke) {
        this.searchDataProvider.searchCriteria.criteria.type = undefined;
      }
    }
    this._moduleId = moduleId;
  }

  goToBusinessObject(task: TaskDto | ActivitySimpleDto) {
    this.router.goToBusinessObject(task.businessObject.relatedTo.id, task.businessObject.relatedToId);
  }

  resetCriteria() {
    this.searchDataProvider.searchCriteria.criteria = <TaskCriteriaDto>{};
    this.searchDataProvider.searchCriteria.criteria.status = <DictionaryBaseDto>{id: TaskStatus.OPEN};
    this.searchDataProvider.searchCriteria.criteria.assignedToMe = true;
    this.searchDataProvider.searchCriteria.criteria.businessObject = <BusinessObjectDto>{};
    this.searchDataProvider.searchCriteria.criteria.dueDateRange = <LocalDateRange>{};
    this.searchDataProvider.searchCriteria.criteria.selectedApplicationModule = <DictionaryBaseDto>{
      id: this.settingsService.getActiveModuleId(),
    };
    this.searchDataProvider.searchCriteria.criteria.applicationModule = <DictionaryBaseDto>{
      id: this.settingsService.getActiveModuleId(),
    };
  }

  setSearchContext(businessObjectTypeId: number, businessObjectId: number, taskTypeId: number) {
    this.resetCriteria();
    this.searchDataProvider.searchCriteria.criteria.assignedToMe = false;
    this.searchDataProvider.searchCriteria.criteria.businessObject.relatedTo = <DictionaryBaseDto>{
      id: businessObjectTypeId,
    };
    this.searchDataProvider.searchCriteria.criteria.businessObject.relatedToId = businessObjectId;
    this.searchDataProvider.searchCriteria.criteria.type = <DictionaryBaseDto>{id: taskTypeId};
  }
}
