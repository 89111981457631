export enum ErrorCode {
  OBJECT_NOT_EXISTS,
  USER_QUOTA_EXCEEDED,
  LIMIT_MISSING_POLICY_FOR_BUYER,
  LIMIT_WITH_COVER_REDUCTION,
  LIMIT_NEW_POLICY_NEEDED,
  LIMIT_NEW_POLICY_ANNEX_NEEDED,
  LIMIT_SMALLER_THAN_COVER_AND_UW_MASTER,
  BONDING_CONTRACT_INQUIRY_CLIENT_INFO_REQUIRED,
  TOTAL_EXPOSURE_EXEEDED,
  BOND_TYPE_EXPOSURE_EXEEDED,
  EXPOSURE_EXCEEDED_LIMIT,
}
