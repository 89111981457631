import {Component, Input} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {AppConfigService, ClaimIndemnificationVersionBaseDto, ClaimVersionDto} from '../../../bonding_shared';
import {BusinessUnit} from '../../../bonding_shared/model/business-unit';
import {ClaimVersionType, DictionaryProfile} from '../../../bonding_shared/model/dictionary-ids';
import {DictionaryUtils} from '../../../bonding_shared/utils/dictionary-utils';
import {ClaimIndemnificationVersionService} from '../../../bonding_shared/services/claim-indemnification-version.service';

@Component({
  selector: 'mehib-claim-calculation',
  templateUrl: 'mehib-claim-calculation.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class MehibClaimCalculationComponent {
  private _claimVersion: ClaimVersionDto;
  @Input() showErrors: boolean;
  _disabled: boolean;
  @Input() readonly: boolean;
  allIndemnifications: ClaimIndemnificationVersionBaseDto[];
  isMLT: boolean;
  canAddSplitPayment: boolean;
  splitPaymentVisible: boolean;
  readonly profileId = DictionaryProfile.MEHIB_MAIN_CURRENCY;

  public constructor(
    private claimIndemnificationVersionService: ClaimIndemnificationVersionService,
    private appService: AppConfigService
  ) {}

  @Input() set claimVersion(cv: ClaimVersionDto) {
    this._claimVersion = cv;
    if (cv.claim?.id) {
      this.getAllIndemnifications(cv.claim?.id);
    }
    this.refreshControlFlags();
  }

  get claimVersion() {
    return this._claimVersion;
  }

  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.refreshControlFlags();
  }

  get disabled() {
    return this._disabled;
  }

  recalculate() {
    const indemnificationBaseAmount =
      this.claimVersion.lossAmount +
      this.claimVersion.claimWaitPeriodContractualInterest -
      this.claimVersion.recoveredBeforeIndemnification -
      this.claimVersion.calculationDeductibles -
      this.claimVersion.calculationOtherDeductibles;
    this.claimVersion.reserveAmount = (indemnificationBaseAmount * this.claimVersion.insuranceCoverPercent) / 100;
  }

  getAllIndemnifications(claimId: number) {
    this.claimIndemnificationVersionService.getLastVersions(claimId).subscribe((c) => (this.allIndemnifications = c));
  }

  private refreshControlFlags() {
    this.isMLT = this.claimVersion?.claim?.businessUnit?.id === BusinessUnit.MEHIB_MLT;
    this.splitPaymentVisible =
      this.appService.mehib &&
      DictionaryUtils.in(this.claimVersion?.versionType, ClaimVersionType.MEHIB_CLAIM_SETTLEMENT);
    this.canAddSplitPayment = !this.disabled && this.splitPaymentVisible;
  }

  get indemnificationsVisible() {
    return this.allIndemnifications && this.allIndemnifications.length;
  }
}
