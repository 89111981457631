/**
 * Created by wilk on 14.06.2017.
 */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchDataProvider} from '../../../../../bonding_shared/services/search-data-provider';
import {
  ContractVersionCriteriaDto,
  ContractVersionSimpleDto,
  SearchResult,
} from '../../../../../bonding_shared/model/dtos';
import {TextSearchCriteria} from '../../../../../bonding_shared/components/search/model/text-search-criteria';
import {Subject} from 'rxjs';
import {AppConfigService, ContractVersionService} from '../../../../../bonding_shared/services';

@Component({
  selector: 'contract-version-selector-simple',
  templateUrl: './contract-version-selector-simple.component.pug',
})
export class ContractVersionSelectorSimpleComponent implements OnInit {
  @Input() title: string;
  @Input() labelKey: string;
  /**
   * Open selector event emitter
   * The same emitter instance should be injected into this component and the component that opens this selector
   */
  @Input() open: Subject<boolean>;

  /**
   * when fixedSearch = true, search is done by injected, predefined criteria, user cannot modify them
   */
  @Input() fixedSearch = false;

  /**
   * to be able to switch between advanced/basic search mode
   * TODO: advanced search mode not implemented yet
   */
  _searchModeSwitchable = false;

  @Input() set searchModeSwitchable(value: boolean) {
    this._searchModeSwitchable = value;
  }

  get searchModeSwitchable(): boolean {
    // IKI 3305
    return this._searchModeSwitchable && !this.appService.kuke;
  }

  /**
   * If data providers gave one row it is selected by default.
   */
  @Input() autoSelectOne = false;

  /**
   *
   */
  @Input() autoSelectId: number = null;

  /**
   * don't use together with textCriteria param
   */
  @Input() set onlyLastVersion(ol: boolean) {
    this.dataProvider.textSearchCriteria.last = ol;
    this.dataProvider.searchCriteria.criteria.last = ol;
  }

  /**
   * don't use together with textCriteria param
   */
  @Input() set onlyActiveVersion(ol: boolean) {
    this.dataProvider.textSearchCriteria.active = ol;
    this.dataProvider.searchCriteria.criteria.presentNow = ol;
  }

  @Input() set criteria(c: ContractVersionCriteriaDto) {
    this.dataProvider.textSearch = false;
    this.dataProvider.searchCriteria.criteria = c;
  }

  @Input() set textSearch(ts: boolean) {
    this.dataProvider.textSearch = ts;
  }
  /**
   * don't use together with onlyLastVersion or onlyActiveVersion param
   */
  @Input() set textCriteria(c: TextSearchCriteria) {
    this.dataProvider.textSearch = true;
    this.dataProvider.textSearchCriteria = c;
  }

  /**
   *  To show only contract properties, not contract version properties
   */
  @Input() onlyContractFields = false;

  @Output() selectItem = new EventEmitter<ContractVersionSimpleDto>();

  @Input() set remoteDataProvider(dp: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>) {
    this.dataProvider = dp;
  }

  pageSize = 10;

  dataProvider: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>;

  constructor(private service: ContractVersionService, private appService: AppConfigService) {
    this.dataProvider = new SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>(this.service);
    this.dataProvider.textSearch = true;
    this.dataProvider.textSearchCriteria = new TextSearchCriteria();
    this.dataProvider.searchCriteria.criteria = <ContractVersionCriteriaDto>{};
  }

  ngOnInit() {
    if (this.autoSelectId) {
      this.pageSize = null; // if autoselect then no paging because it can break autoselect
    }
  }

  onChange(contract: ContractVersionSimpleDto) {
    this.selectItem.emit(contract);
    this.open.next(false);
  }

  onSearchContractsFinished(searchResult: SearchResult<ContractVersionSimpleDto>): void {
    const contracts = searchResult.result;
    if (contracts) {
      if (this.autoSelectId) {
        const autoSelectedContract = contracts.find((cv) => cv.id === this.autoSelectId);
        if (autoSelectedContract) {
          this.onChange(autoSelectedContract);
          return;
        }
      }
      if (this.autoSelectOne && searchResult.size === 1) {
        this.onChange(contracts[0]);
      }
    }
  }
}
