import {Component} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {MehibPolicyProduct} from './mehib/mehib-policy-product';
import {PolicyContractVersionService} from '../../../../bonding_shared/services';

@Component({
  selector: 'mehib-policy-product',
  templateUrl: './mehib-policy-product.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class MehibPolicyProductComponent extends MehibPolicyProduct {
  constructor(policyContractVersionService: PolicyContractVersionService) {
    super(policyContractVersionService);
  }
}
