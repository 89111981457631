import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SectionSelector} from '../../../bonding_shared/components/details-view/section-selector';
import {
  BackendError,
  CompanySimpleDto,
  MasterPolicyContractBaseDto,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionIdDto,
  PolicyContractVersionSimpleDto,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionSimpleDto,
  ProductLimitListBaseDto,
  ProductLimitListSimpleDto,
} from '../../../bonding_shared/model';
import {ProductLimitListVersionStatus} from '../../../bonding_shared/model/dictionary-ids';
import {LoggedUserService} from '../../../bonding_shared/services';
import {Subject} from 'rxjs';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';

@Component({
  selector: 'policy-limit-list-section-selector',
  template: `
    <ng-template #section let-openEmitter="openEmitter" let-object="object" let-disabled="disabled">
      <selectable-object-section
        [labelKey]="sectionLabel"
        [clearable]="false"
        [searchable]="!readOnly && !disabled"
        [openSelectorEmitter]="openEmitter"
      >
        <policy-limit-list-info
          [policyLimitList]="object[property]"
          [showFactorer]="false"
          [farmer]="farmer"
        ></policy-limit-list-info>
      </selectable-object-section>
    </ng-template>
    <ng-template #selector let-openEmitter="openEmitter" let-onChange="onChange">
      <policy-and-inquiry-version-selector
        (selectPolicy)="onSelectPolicy($event, onChange)"
        (selectInquiry)="onSelectInquiry($event, onChange)"
        [open]="openEmitter"
        [showLinks]="false"
        [showBU]="!portal"
        [showClient]="!portal"
        [showBusinessStatus]="!portal"
        [showYear]="!portal"
        [searchModeSwitchable]="false"
        [oneResultAutoSelection]="onePolicyOrInquiryAutoSelection && onlyPolicyMode"
        [onlyPolicyMode]="onlyPolicyMode"
        [policyInitialCriteria]="policyInitialCriteria"
        [inquiryInitialCriteria]="inquiryInitialCriteria"
        [fixedClient]="fixedClient"
        [fixedMasterPolicyId]="fixedMasterPolicyId"
      >
      </policy-and-inquiry-version-selector>

      <policy-limit-list-selector
        (selectItemParent)="onChange($event)"
        label="limitDetails.selectPolicyList"
        [open]="openLimitListSelector"
        [criteria]="policyLimitListSearchCriteria"
        [fixedSearch]="true"
        [emitParent]="true"
        noResultWarningKey="limitDetails.errorMessage.noLimitListForPortalUser"
        [oneResultAutoSelection]="true"
        (backendError)="backendError.emit($event)"
      >
      </policy-limit-list-selector>
    </ng-template>
  `,
  providers: [{provide: SectionSelector, useExisting: PolicyLimitListSectionSelectorComponent}],
})
export class PolicyLimitListSectionSelectorComponent extends SectionSelector {
  @Input() searchModeSwitchable = true;
  @Input() readOnly = false;
  @Input() onePolicyOrInquiryAutoSelection = false;
  @Input() onlyPolicyMode = false;
  @Input() policyInitialCriteria: PolicyContractVersionCriteriaDto;
  @Input() inquiryInitialCriteria: PolicyInquiryVersionCriteriaDto;
  @Input() fixedClient = false;
  @Input() fixedMasterPolicyId;
  @Input() farmer = false;

  @Output() backendError = new EventEmitter<BackendError>();

  openLimitListSelector = new Subject<boolean>();

  policyLimitListSearchCriteria = {
    owner: <CompanySimpleDto>{},
    limitList: <ProductLimitListBaseDto>{masterPolicyContract: <MasterPolicyContractBaseDto>{}},
    status: {id: ProductLimitListVersionStatus.ACTIVE},
  };

  constructor(private loggedUserService: LoggedUserService) {
    super();
  }

  onSelectPolicy(p: PolicyContractVersionSimpleDto, onChange: any) {
    if (p.limitMultiListAllowed && BusinessUtils.isFactoringPolicyType(p.contractType.id)) {
      this.createLimitListForPolicy(p, onChange);
    } else {
      this.policyLimitListSearchCriteria.limitList.masterPolicyContract.id = p.policyContract.masterPolicyContract.id;
      this.policyLimitListSearchCriteria.owner.id = this.portal ? this.callerCompany.id : undefined;
      this.openLimitListSelector.next(true);
    }
  }

  createLimitListForPolicy(p: PolicyContractVersionSimpleDto, onChange: any) {
    const pll = <ProductLimitListSimpleDto>{
      masterPolicyContract: {
        id: p.policyContract.masterPolicyContract.id,
        client: p.client,
        number: p.number,
        status: p.status,
        productType: p.contractType,
        validity: {dateFrom: p.validFrom, dateTo: p.validTo},
        lastVersion: <PolicyContractVersionIdDto>{id: p.id},
        limitMultiListAllowed: true,
      },
    };
    onChange(pll);
  }

  createLimitListForInquiry(c: PolicyInquiryVersionSimpleDto, onChange: any) {
    const pll = BusinessUtils.initialPolicyListFromPolicyInquiryVersion(c);
    onChange(pll);
  }

  onSelectInquiry(c: PolicyInquiryVersionSimpleDto, onChange: any) {
    this.createLimitListForInquiry(c, onChange);
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  get callerCompany() {
    return this.loggedUserService.getLoggedUserData().company;
  }

  closeSubSelectors() {
    this.openLimitListSelector.next(false);
  }
}
