import {Component, Input} from '@angular/core';
import {
  PolicyContractBaseDto,
  PolicySectionAdditionalDataDto,
  ProductLimitListIdDto,
} from '../../../../bonding_shared/model';
import {Subject} from 'rxjs';
import {RouterService} from '../../../../bonding_shared/services';

@Component({
  selector: 'policy-contract-section',
  template: `
    <selectable-object-section
      [labelKey]="labelKey"
      [clearable]="clearable"
      [searchable]="searchable"
      [openSelectorEmitter]="openSelectorEmitter"
    >
      <div class="bon-row" *ngIf="policyContract" content="body">
        <b
          class="a-link col-md-8 font-larger"
          *ngIf="showLink; else noLink"
          (click)="router.toPolicyContractPreviewByPolicyContractId(policyContract.id)"
        >
          {{ policyContract.number }}/{{ policyContract.policyYearFormatted }}
        </b>
        <ng-template #noLink>
          <b class="col-md-8 font-larger">{{ policyContract.number }}/{{ policyContract.policyYearFormatted }}</b>
        </ng-template>
        <text-row labelKey="common.type" [value]="policyContract.type?.name" [alignRight]="true"></text-row>
        <text-row
          labelKey="policyContract.preview.gci"
          [value]="policyContract.globalConditionsOfInsurance?.name"
          [alignRight]="true"
        ></text-row>
        <date-range-row
          labelKey="common.validity"
          [value]="{dateFrom: policyContract.validFrom, dateTo: policyContract.validTo}"
          [readOnly]="true"
          [alignRight]="true"
        >
        </date-range-row>
        <text-row
          labelKey="policyContract.preview.insuranceYear"
          [value]="policyContract.policyYear"
          [alignRight]="true"
          *ngIf="showInsuranceYear"
        ></text-row>
        <text-row
          labelKey="policyContract.preview.firstInsurance"
          [value]="policyContract.firstInsurancePeriodValidFrom | date"
          [alignRight]="true"
          *ngIf="showFirstInsurance"
        ></text-row>
        <text-row
          labelKey="policy.details.scopeSection.protractedDefault"
          [value]="policyContract.protractedDefault"
          [alignRight]="true"
          *ngIf="showProtractedDefault"
        ></text-row>
        <text-row
          *ngIf="showStatus"
          labelKey="common.status"
          [value]="policyContract.lastVersionStatus?.name"
          [alignRight]="true"
        ></text-row>
        <text-row
          *ngIf="showBusinessStatus"
          labelKey="common.businessStatus"
          [value]="policyContract.lastVersionBusinessStatus?.name"
          [alignRight]="true"
        ></text-row>
        <text-row
          *ngIf="policyLimitList"
          labelKey="policyLimitList.masterPolicy.limitList"
          [value]="policyLimitList.name"
          [alignRight]="true"
          [showLink]="showLink"
          (clickItem)="router.toPolicyLimitListDetails(policyLimitList.id)"
        ></text-row>
        <div *ngIf="additionalData">
          <checkbox-row-simple
            labelKey="policy.details.scopeSection.agreementForReceivableTransfer"
            [disabled]="true"
            [value]="additionalData.agreementForReceivableTransfer"
          ></checkbox-row-simple>
          <checkbox-row-simple
            labelKey="policy.details.scopeSection.powerToCollect"
            [disabled]="true"
            [value]="additionalData.powerToCollect"
          ></checkbox-row-simple>
          <checkbox-row-simple
            labelKey="policy.details.scopeSection.activeCession"
            [disabled]="true"
            [value]="additionalData.activeCession"
          ></checkbox-row-simple>
        </div>
      </div>
    </selectable-object-section>
  `,
})
export class PolicyContractSectionComponent {
  @Input() policyContract: PolicyContractBaseDto;
  @Input() policyLimitList: ProductLimitListIdDto;
  @Input() additionalData: PolicySectionAdditionalDataDto;
  @Input() labelKey: string;
  @Input() clearable: boolean;
  @Input() searchable: boolean;
  @Input() openSelectorEmitter: Subject<boolean>;
  @Input() showInsuranceYear = true;
  @Input() showFirstInsurance = true;
  @Input() showProtractedDefault = true;
  @Input() showStatus = false;
  @Input() showBusinessStatus = true;
  @Input() showLink = true;

  constructor(public router: RouterService) {}
}
