import {Component, Input} from '@angular/core';
import {ClaimVersionDto, DictionaryBaseDto, LoggedUserService, UserRange} from '../../../bonding_shared';
import {DictionaryUtils} from '../../../bonding_shared/utils/dictionary-utils';
import {ClaimRiskType, ClaimStatus, ClaimVersionType} from '../../../bonding_shared/model/dictionary-ids';
import {ClaimUtilsService} from '../services/claim-utils.service';
import {ControlContainer, NgForm} from '@angular/forms';
import {DateUtils} from '../../../bonding_shared/utils/date-utils';

@Component({
  selector: 'ecg-claim-general-information',
  templateUrl: './ecg-claim-general-information.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class EcgClaimGeneralInformationComponent {
  @Input() claimVersion: ClaimVersionDto;
  @Input() intranet = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() showErrors = false;
  readonly claimUserRange: UserRange = 'claim';
  readonly ClaimVersionType = ClaimVersionType;

  get isFormDisabled() {
    return this.disabled || !DictionaryUtils.equalsDictAndId(this.claimVersion?.status, ClaimStatus.UNDER_ASSESSMENT);
  }

  get isOverdueNotification() {
    return DictionaryUtils.equalsDictAndId(this.claimVersion?.versionType, ClaimVersionType.ECG_OVERDUE_NOTIFICATION);
  }

  get causeOfLossDisabled() {
    return this.isFormDisabled || this.isOverdueNotification;
  }

  get isDecisionDisabled() {
    return this.disabled || (this.claimVersion && this.claimVersion.status.id === ClaimStatus.UNDER_ASSESSMENT);
  }

  get isCreditInsuranceType() {
    return ClaimUtilsService.isExpectedRiskType(this.claimVersion, ClaimRiskType.CREDIT_INSURANCE);
  }

  get isFirstInvoiceDueDateVisible() {
    return ClaimUtilsService.isExpectedRiskType(
      this.claimVersion,
      ClaimRiskType.CREDIT_INSURANCE,
      ClaimRiskType.PROD_RISK
    );
  }

  get isCreditInsurance() {
    return ClaimUtilsService.isExpectedRiskType(this.claimVersion, ClaimRiskType.CREDIT_INSURANCE);
  }

  get isSurety() {
    return ClaimUtilsService.isExpectedRiskType(this.claimVersion, ClaimRiskType.SURETY);
  }

  get notificationDateRequired() {
    return DictionaryUtils.in(this.claimVersion.versionType, ClaimVersionType.ECG_CLAIM_NOTIFICATION);
  }

  get notificationDateDisabled() {
    return this.isFormDisabled || !this.intranet;
  }

  claimCaseCurrencyChanged(claimCaseCurrency: DictionaryBaseDto) {
    this.claimVersion.indemnificationCurrency = claimCaseCurrency;
  }

  get isNewVersion(): boolean {
    return !this.claimVersion?.id;
  }

  get isInitialVersionCreation(): boolean {
    const isFirstClaimVersion = !this.claimVersion?.claim?.id || this.claimVersion.versionNumber === 1;
    return this.isNewVersion && isFirstClaimVersion;
  }

  get isChangeDisabledForPortalUser(): boolean {
    return !(this.intranet || this.isInitialVersionCreation);
  }
}
