export enum DtoClass {
  BondVersionDto = 'pl.accuratus.isa.bond.dto.BondVersionDto',
  BrokerContractRelationVersionDto = 'pl.accuratus.isa.broker.dto.BrokerContractRelationVersionDto',
  BrokerContractVersionDto = 'pl.accuratus.isa.broker.dto.BrokerContractVersionDto',
  ContractVersionDto = 'pl.accuratus.isa.bondingcontract.contract.dto.ContractVersionDto',
  PolicyContractVersionDto = 'pl.accuratus.isa.policy.dto.PolicyContractVersionDto',
  PolicyInquiryVersionDto = 'pl.accuratus.isa.policy.dto.PolicyInquiryVersionDto',
  TreatyVersionDto = 'pl.accuratus.isa.treaty.dto.TreatyVersionDto',
  SalesRepRelationVersionDto = 'pl.accuratus.isa.sales.dto.SalesRepRelationVersionDto',
  SalesRepRelationVersionCriteriaDto = 'pl.accuratus.isa.sales.dto.SalesRepRelationVersionCriteriaDto',
  SalesRepVersionDto = 'pl.accuratus.isa.sales.dto.SalesRepVersionDto',
  SalesRepVersionCriteriaDto = 'pl.accuratus.isa.sales.dto.SalesRepVersionCriteriaDto',
  DocumentTemplateDto = 'pl.accuratus.isa.document.template.dto.DocumentTemplateDto',
  ReportTemplateDto = 'pl.accuratus.isa.document.template.dto.ReportTemplateDto',
}
