import {Observable} from 'rxjs';
import {UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BondingContractOfferDto, VoteDto} from '../model';
import {BondingContractOfferAbstractService} from './bonding-contract-offer-abstract.service';

@Injectable()
export class BondingContractOfferService extends BondingContractOfferAbstractService {
  protected url = this.urlPrefix + 'bondingContractOffer';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  initialOffer(
    inquiryVersionId: number,
    edgeOfferId: number,
    copyOfferId: number
  ): Observable<BondingContractOfferDto> {
    const params = UrlParams.new()
      .add('inquiryVersionId', inquiryVersionId)
      .add('edgeOfferId', edgeOfferId)
      .add('copyOfferId', copyOfferId);
    return this.get<BondingContractOfferDto>(this.buildUrl(this.url, 'initialOffer', params));
  }

  doVote(offerId: number, vote: VoteDto) {
    return this.post1<VoteDto, BondingContractOfferDto>(vote, this.url + '/' + offerId + '/doVote');
  }

  markCreatedExternally(offerId: number) {
    return this.postEmpty<BondingContractOfferDto>(this.url + '/markCreatedExternally/' + offerId);
  }
}
