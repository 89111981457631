import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'standalone-table-item',
  template: `
    <div *ngIf="presentationMode" class="bon-input-size">
      <span class="presentation">{{ dynamicValue }}</span>
    </div>
    <input-entity
      *ngIf="!presentationMode"
      [(ngModel)]="dynamicValue"
      [control]="control"
      [disabled]="disabled"
    ></input-entity>
  `,
})
export class StandaloneTableItemComponent<T> {
  @Input() itemFindPredicate: (item: T, index: number, obj: T[]) => boolean;
  @Input() itemGetValuePredicate: (item: T) => string;
  @Input() itemBuilder: (value: string) => T;
  @Input() itemSetValueFunc: (item: T, value: string) => void;
  @Input() keepEmptyValue = false;
  @Input() control: AbstractControl;
  @Input() items: T[];
  @Input() disabled = false;
  @Input() presentationMode = false;

  value: string;

  get dynamicValue() {
    const item = this.items && this.items.find(this.itemFindPredicate);
    return (item && this.itemGetValuePredicate(item)) || '';
  }

  set dynamicValue(value: string) {
    this.onValueChange(value);
  }

  onValueChange(value: string) {
    const itemIndex = this.items.findIndex(this.itemFindPredicate);
    if (itemIndex >= 0) {
      const item = this.items[itemIndex];
      if (value || this.keepEmptyValue) {
        this.itemSetValueFunc(item, value);
      } else {
        this.items.splice(itemIndex, 1);
      }
    } else {
      this.items.push(this.itemBuilder(value));
    }
  }
}
