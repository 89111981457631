/**
 * Created by szkrabko on 06.08.2018 from DictComboComponent created by siminski on 30.06.2016.
 */
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {GeoDictBaseDto} from '../../model';
import {GeoDictBaseService} from '../../services';

const GEO_DICT_COMBO_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => GeoDictComboComponent),
  multi: true,
};

@Component({
  selector: 'geo-dict-combo',
  template: `
    <select
      [(ngModel)]="selectedKey"
      (change)="selectedKey = $event.target.value"
      class="bon-select"
      [disabled]="disabled || loading"
      [class.loading]="loading"
    >
      <option *ngIf="nullLabel" value="undefined">{{ nullLabel }}</option>
      <option *ngFor="let item of itemsFiltered" [value]="item.id">
        <span *ngIf="!template">{{ item[label] }}</span>
        <span *ngIf="template">
          <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{item: item}"></ng-container>
        </span>
      </option>
    </select>
    <div *ngIf="errorMessage" class="invalid">Error: {{ errorMessage }}</div>
  `,
  providers: [GEO_DICT_COMBO_CONTROL_VALUE_ACCESSOR],
})
export class GeoDictComboComponent implements ControlValueAccessor, OnInit {
  @Input() nullLabel: string;
  @Input() parentDictionary: 'city' | 'postCode' | 'country';
  @Input() geoDict: 'city' | 'postCode';
  @Input() disabled: boolean;
  @Input() label = 'name';

  @Input() countryId: number;

  /**
   * The items that will not be shown in a list. This should be be stored as set,
   * i.e.  hiddenIds[12] = true;
   */
  _hiddenIds: Set<number>;

  @Input() set hiddenIds(hiddenIds: Set<number>) {
    if (hiddenIds && this._hiddenIds !== hiddenIds) {
      this._hiddenIds = hiddenIds;
      if (this.items) {
        this.filterEntries();
      }
    }
  }

  get hiddenIds() {
    return this._hiddenIds;
  }

  @Output() changeItem = new EventEmitter<GeoDictBaseDto>();

  @ContentChild(TemplateRef, {static: true}) template: TemplateRef<any>;

  private _codeRegexp: RegExp;
  private _nameRegexp: RegExp;
  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  private _selectedKey: number;
  private selectedItem: GeoDictBaseDto;
  // Item set from model. May be possibly incorrect.
  private selectedItemFromExternal: GeoDictBaseDto;

  public items: GeoDictBaseDto[];
  public itemsFiltered: GeoDictBaseDto[] = [];
  public errorMessage: string;
  public loading = false;
  private _parentDictionaryEntryId: number;

  private comboInitialized = false;

  constructor(private geoDictBaseService: GeoDictBaseService) {}

  @Input() set entries(entries: GeoDictBaseDto[]) {
    this.initEntries(entries);
  }

  get nameRegexp(): RegExp {
    return this._nameRegexp;
  }

  @Input() set nameRegexp(nameRegexp: RegExp) {
    if (this._nameRegexp !== nameRegexp) {
      this._nameRegexp = nameRegexp;
      if (this.items) {
        this.filterEntries();
      }
    }
  }

  get parentDictionaryEntryId(): number {
    return this._parentDictionaryEntryId;
  }

  @Input() set parentDictionaryEntryId(parentDictionaryEntryId: number) {
    console.log('set parentDictionaryEntryId: ', parentDictionaryEntryId);
    if (this._parentDictionaryEntryId) {
      this.selectedKey = undefined;
      this.selectedItemFromExternal = undefined;
    }
    this._parentDictionaryEntryId = parentDictionaryEntryId;
    if (this.comboInitialized) {
      this.loadDictionaryEntries();
    }
  }

  ngOnInit() {
    if (this.parentDictionaryEntryId) {
      this.loadDictionaryEntries();
    }
    this.comboInitialized = true;
  }

  get selectedKey(): number {
    return this._selectedKey;
  }

  set selectedKey(k: number) {
    if (k !== this._selectedKey) {
      this._selectedKey = k;
      this.selectedItem = this.findSelectedItem(k);
      this.onChangeListeners(this.selectedItem);
      this.changeItem.emit(this.selectedItem);
    }
  }

  // From ControlValueAccessor interface
  writeValue(it: GeoDictBaseDto): void {
    this.selectedItemFromExternal = it;
    this.updateSelection();
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private loadDictionaryEntries() {
    this.loading = true;
    const observable =
      this.geoDict === 'postCode'
        ? this.geoDictBaseService.getPostCodes(this.parentDictionary, this._parentDictionaryEntryId)
        : this.geoDictBaseService.getCities(this.parentDictionary, this._parentDictionaryEntryId);

    observable.subscribe(
      (entries) => (this.entries = entries),
      (error) => {
        this.errorMessage = error;
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  private initEntries(items: GeoDictBaseDto[]) {
    this.items = items;
    this.filterEntries();
  }

  private filterEntries() {
    let itemsFiltered: GeoDictBaseDto[] = [];
    this.itemsFiltered = [];
    if (this.nameRegexp) {
      for (const it of this.items) {
        if (it.name.match(this.nameRegexp)) {
          itemsFiltered.push(it);
        }
      }
    } else {
      itemsFiltered = this.items;
    }
    if (this.hiddenIds && this.hiddenIds.size > 0) {
      for (const it of itemsFiltered) {
        if (!this.hiddenIds.has(it.id)) {
          this.itemsFiltered.push(it);
        }
      }
    } else {
      this.itemsFiltered = itemsFiltered;
    }
    this.updateSelection();
  }

  private updateSelection() {
    if (this.selectedItemFromExternal) {
      this.selectedItem = this.findSelectedItem(this.selectedItemFromExternal.id);
      this._selectedKey = this.selectedItemFromExternal.id;
    } else {
      this.selectedItem = undefined;
      this._selectedKey = undefined;
    }
  }

  private findSelectedItem(k: number): GeoDictBaseDto {
    if (k && this.items) {
      for (const it of this.items) {
        if (String(it.id) === String(k)) {
          return it;
        }
      }
    }
    return undefined;
  }
}
