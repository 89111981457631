import {Component, Input} from '@angular/core';
import {
  BondingContractInquiryForLimitDto,
  ContractForLimitDto,
  MasterPolicyContractForLimitDto,
  PolicyContractVersionBaseDto,
  PolicyInquiryForLimitDto,
  ProductLimitListDto,
  ProductLimitListForLimitDto,
} from '../../../bonding_shared/model';
import {RouterService} from '../../../bonding_shared/services';

@Component({
  selector: 'policy-limit-list-info',
  templateUrl: './policy-limit-list-info.component.pug',
})
export class PolicyLimitListInfoComponent {
  @Input() policyLimitList: ProductLimitListForLimitDto;
  @Input() policyVersion: PolicyContractVersionBaseDto;
  @Input() showPolicyList = true;
  @Input() showFactorer = true;
  @Input() showLimitListsLink = false;
  @Input() farmer = false;

  constructor(public router: RouterService) {}

  get masterPolicyContract(): MasterPolicyContractForLimitDto {
    return this.policyLimitList ? this.policyLimitList.masterPolicyContract : undefined;
  }
  get policyInquiry(): PolicyInquiryForLimitDto {
    return this.policyLimitList ? this.policyLimitList.policyInquiry : undefined;
  }

  get bondingContractInquiry(): BondingContractInquiryForLimitDto {
    return this.policyLimitList ? this.policyLimitList.bondingContractInquiry : undefined;
  }

  get bondingContract(): ContractForLimitDto {
    return this.policyLimitList ? this.policyLimitList.bondingContract : undefined;
  }
}
