import {Injectable} from '@angular/core';
import {
  CompanyCriteriaDto,
  CompanyDto,
  CompanyExtendedSearchParamsDto,
  DictionaryBaseDto,
  DictionaryBaseService,
  PolicyClauseTypeCriteriaDto,
  PolicyClauseTypeSimpleDto,
  RouterService,
  SearchDataProvider,
} from '../../../../bonding_shared';
import {AddressType} from '../../../../bonding_shared/model/dictionary-ids';
import {Observable} from 'rxjs';
import {PolicyClauseTypeService} from '../../../../bonding_shared/services/policy-clause-type.service';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';

@Injectable()
export class PolicyClauseTypeGuiService {
  dataProvider: SearchDataProvider<PolicyClauseTypeCriteriaDto, PolicyClauseTypeSimpleDto>;

  constructor(
    private dictionaryBaseService: DictionaryBaseService,
    private policyClauseTypeService: PolicyClauseTypeService,
    public router: RouterService
  ) {
    this.dataProvider = new SearchDataProvider<PolicyClauseTypeCriteriaDto, PolicyClauseTypeSimpleDto>(
      policyClauseTypeService
    );
    this.dataProvider.searchCriteria = BusinessUtils.createPolicyClauseTypeSearchCriteria();
  }
}
