import {Component, Input} from '@angular/core';
import {SectionSelector} from '../../../../bonding_shared/components/details-view/section-selector';
import {ClaimVersionCriteriaDto} from '../../../../bonding_shared/model';
import {RouterService} from '../../../../bonding_shared/services';

@Component({
  selector: 'claim-indemnification-section-selector',
  template: `
    <ng-template #selector let-openEmitter="openEmitter" let-onChange="onChange">
      <claim-indemnification-selector
        [labelKey]="selectorLabel"
        [open]="openEmitter"
        (selectItem)="onChange($event)"
        [criteria]="criteria"
      ></claim-indemnification-selector>
    </ng-template>
  `,
  providers: [{provide: SectionSelector, useExisting: ClaimIndemnificationSectionSelectorComponent}],
})
export class ClaimIndemnificationSectionSelectorComponent extends SectionSelector {
  @Input() criteria: ClaimVersionCriteriaDto;

  constructor(protected router: RouterService) {
    super();
  }
}
