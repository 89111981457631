import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {UserDataComponent} from './components/user-data.component';
import {UserGuiService} from './services/user-gui.service';
import {UserDetailsComponent} from './user-details.component';
import {UserSearchComponent} from './user-search.component';
import {UserCreationComponent} from './comands/user-creation.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PasswordConfirmDirective} from './directives/validators/password-confirm.directive';
import {LoginFormatDirective} from './directives/validators/login-format.directive';
import {UserAccessGuard} from './components/user-access-guard';
import {UserSearchGuard} from './components/user-search-guard';
import {AdUserDetailsComponent} from './ad-user-details.component';
import {AdUserDataComponent} from './components/ad-user-data.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    BsDropdownModule,
  ],
  declarations: [
    AdUserDetailsComponent,
    AdUserDataComponent,
    UserDataComponent,
    UserDetailsComponent,
    UserSearchComponent,
    UserCreationComponent,
    PasswordConfirmDirective,
    LoginFormatDirective,
  ],
  exports: [],
  providers: [UserGuiService, UserAccessGuard, UserSearchGuard],
})
export class UserModule {}
