import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CollectionHistoryEntryDto, CommentDto} from '../model';
import {BinaryDownloaderService} from './binary-downloader.service';
import {PortalCollectionHistoryService} from '../../portal/services/portal-collection-history.service';

@Injectable()
export class CollectionHistoryService extends PortalCollectionHistoryService {
  protected url = this.urlPrefix + 'collectionHistory';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService, binaryDownloader);
  }

  getCollectionHistory(id: number): Observable<CollectionHistoryEntryDto[]> {
    return this.get<CollectionHistoryEntryDto[]>(this.url + '/' + id + '/history');
  }

  saveEntry(collectionId: number, dto: CollectionHistoryEntryDto): Observable<CollectionHistoryEntryDto> {
    return this.put<CollectionHistoryEntryDto>(dto, this.url + '/' + collectionId);
  }

  setPrintability(id: number, printOnForm: boolean): Observable<CollectionHistoryEntryDto> {
    return this.post1<Boolean, CollectionHistoryEntryDto>(printOnForm, this.url + '/' + id + '/setPrintability');
  }

  storeHistoryComment(collectionId: number, dto: CommentDto): Observable<CommentDto> {
    return this.put<CommentDto>(dto, this.url + '/' + collectionId + '/historyComment');
  }
}
