import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {timer as observableTimer, Subscription} from 'rxjs';

@Injectable()
export class AppReloadService {
  private lastMarker: string = null;
  private checkUrl: string;

  private readonly markerFile = '/favicon.ico';
  private timeSubscription: Subscription;

  private readonly sec1 = 1000;
  private readonly sec3 = 3 * this.sec1;
  private readonly min30 = 30 * 60 * this.sec1;
  private readonly checkPeriod = this.min30;

  constructor(private http: HttpClient) {}

  runChecking() {
    console.log('Run checking');
    this.checkUrl = window.location.href.split('#')[0] + this.markerFile;
    console.log('Before time subscription');
    this.timeSubscription = observableTimer(this.checkPeriod, this.checkPeriod).subscribe((t) =>
      this.checkModification()
    );
    console.log('After time subscription');
  }

  private checkModification() {
    this.http.head(this.checkUrl, {observe: 'response', responseType: 'blob'}).subscribe({
      next: (res) => this.onMarkerResponse(res),
      error: (error) => {
        console.error('AppReloadService error', error);
        this.timeSubscription.unsubscribe();
      },
    });
  }

  private onMarkerResponse(res) {
    const marker = res.headers.get('ETag') || res.headers.get('Last-Modified');

    if (!marker) {
      return;
    }

    if (this.lastMarker === null) {
      this.lastMarker = marker;
    } else {
      if (this.lastMarker !== marker) {
        window.location.reload();
      }
    }
  }
}
