<div *ngIf="performanceBondSubtype()">
  <num-row labelKey="clientbond.shared.itPerformanceBond.discountPercentage" [(ngModel)] = "bond.discountPercentage" name="discountPercentage"
           [control]="bondForm.controls.discountPercentage" [showErrors]="showErrors"></num-row>
  <!-- <it-bid-bond-link-list title="Active bid bonds" [dataProvider]="bidBondDataProvider"></it-bid-bond-link-list> -->
  <selectable-object-section labelKey="clientbond.shared.itPerformanceBond.linkedBidBond" [clearable]="false"
                             [searchable]="true" [openSelectorEmitter]="openSelectorEmitters['BidBond']">
    <div class="bon-row" *ngIf="bond.releasedBidBond" content="body">
        <b class="a-link col-md-8 font-larger" (click)="router.toBondDetails(bond.releasedBidBond.id)">
          {{bond.releasedBidBond.bond.number}}/{{bond.releasedBidBond.versionNumber}}
        </b>
    </div>
  </selectable-object-section>

  <it-bid-bond-simple-selector (selectItem)="onSelectBidBond($event)" labelKey="clientbond.shared.itPerformanceBond.selectBidBondToLink"
                               [open]="openSelectorEmitters['BidBond']" [criteria]="bondCriteria" [fixedSearch]="true">
  </it-bid-bond-simple-selector>

</div>

