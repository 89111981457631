import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';

@Injectable()
export class PropertyService extends AbstractService {
  protected url = this.urlPrefix + 'property';
  properties: PropertyMap = {};

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  initProperties(): Observable<PropertyMap> {
    console.log('get all properties');
    return this.get<PropertyMap>(this.url).pipe(tap((properties) => (this.properties = properties)));
  }
}

export interface PropertyMap {
  [index: string]: Object;
}
