import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {CustomValidators} from '../../validators';

@Directive({
  selector: '[firstNameFormat]',
  providers: [{provide: NG_VALIDATORS, useExisting: FirstNameFormatDirective, multi: true}],
})
export class FirstNameFormatDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    return CustomValidators.userFirstNameFormat(control);
  }
}
