import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {
  AddressDto,
  BankAccountDto,
  ClientInfoDto,
  CompanyBaseDto,
  CompanyBIIdentifierDto,
  CompanyDto,
  CompanyIdDto,
  CompanyIdentifierDto,
  CompanySimpleDto,
  CompanyStatisticsDto,
  ErrorReason,
  OtherCompanyRoleDto,
  TaskSimpleDto,
} from '../model';
import {HttpClient} from '@angular/common/http';
import {CompanyAbstractService} from './company-abstract.service';
import {AbstractControl} from '@angular/forms';

@Injectable()
export class CompanyService extends CompanyAbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'company';
  }

  getCompany(id: number): Observable<CompanyDto> {
    return this.get<CompanyDto>(this.url + '/' + id);
  }

  getCompanyIdDto(id: number): Observable<CompanyIdDto> {
    return this.get<CompanyDto>(this.url + '/id/' + id);
  }

  getCompanyAddresses(id: number): Observable<AddressDto[]> {
    return this.get<AddressDto[]>(this.url + '/' + id + '/addresses');
  }

  getCompanyBaseDto(id: number): Observable<CompanyBaseDto> {
    return this.get<CompanyBaseDto>(this.url + '/base/' + id);
  }

  getStatistics(id: number): Observable<CompanyStatisticsDto> {
    return this.get<CompanyStatisticsDto>(this.url + '/statistics/' + id);
  }

  getOtherCompanyRoles(id: number): Observable<OtherCompanyRoleDto[]> {
    return this.get<OtherCompanyRoleDto[]>(this.url + '/otherCompanyRoles/' + id);
  }

  getExpectsFinancialData(id: number): Observable<TaskSimpleDto> {
    return this.get<TaskSimpleDto>(this.url + '/expectsFinancialData/' + id);
  }

  createCompany(company: CompanyDto): Observable<CompanyDto> {
    return this.put<CompanyDto>(company, this.url);
  }

  updateCompany(company: CompanyDto): Observable<CompanyDto> {
    return this.post<CompanyDto>(company, this.url);
  }

  saveCompany(company: CompanyDto): Observable<CompanyDto> {
    if (company.id > 0) {
      return this.updateCompany(company);
    } else {
      return this.createCompany(company);
    }
  }

  validateBankAccounts(accounts: BankAccountDto[]): Observable<BankAccountDto[]> {
    return this.post<BankAccountDto[]>(accounts, this.url + '/validate/bankAccounts');
  }

  validateBankAccount(bic: string, iban: string): Observable<BankAccountDto> {
    return this.get<BankAccountDto>(this.url + '/validate/bankAccount?iban=' + iban + '&bic=' + bic);
  }

  validateCompanyStatus(companyId: number): Observable<ErrorReason[]> {
    return this.get<ErrorReason[]>(this.url + '/validate/companyStatus?companyId=' + companyId);
  }

  async validateBic(control: AbstractControl) {
    let bankAccountDto: any;
    await this.get<BankAccountDto>(this.url + '/validate/bic?bic=' + control.value)
      .toPromise()
      .then((value) => (bankAccountDto = value));
    return control && control.value && (!bankAccountDto.warnings || bankAccountDto.warnings.length === 0)
      ? undefined
      : {bicInvalid: true};
  }

  async validateIban(control: AbstractControl) {
    let bankAccountDto: any;
    await this.get<BankAccountDto>(this.url + '/validate/iban?iban=' + control.value)
      .toPromise()
      .then((value) => (bankAccountDto = value));
    return control && control.value && (!bankAccountDto.warnings || bankAccountDto.warnings.length === 0)
      ? undefined
      : {ibanInvalid: true};
  }

  getClientInfo(clientId: number): Observable<ClientInfoDto> {
    return this.get<ClientInfoDto>(this.url + '/' + clientId + '/info');
  }

  public matchByIdentifiers(identifiers: CompanyBIIdentifierDto): Observable<CompanySimpleDto> {
    return this.post1(identifiers, this.url + '/matchByIdentifiers');
  }

  public addManualRole(companyId: number): Observable<CompanyDto> {
    return this.post1(null, this.url + '/addManualRole/' + companyId);
  }

  public removeManualRole(companyId: number): Observable<CompanyDto> {
    return this.postEmpty(this.url + '/removeManualRole/' + companyId);
  }
}
