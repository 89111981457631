import {DictionaryBaseDto} from '../../../../../bonding_shared/model';

export class PolicyAndInquiryVersionCriteria {
  // false means policy search, true means inquiry search
  inquirySearch = false;

  number: string;
  inquiryProductType: DictionaryBaseDto;
  policyProductType: DictionaryBaseDto;
  clientId: number;
  clientName: string;
}
