import {Observable, of as observableOf} from 'rxjs';

import {share, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {DictionaryBaseDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DictionaryBaseService extends AbstractService {
  protected url = this.urlPrefix + 'dictionaryBase';

  // Stores data { dictionaryName : Dictionary[] }
  private dictionaries: {[key: string]: DictionaryBaseDto[]} = {};

  // Observables { dictionaryName : Observable<Dictionary[]> } used when someone calls again when call is in progress
  private observables: {[key: string]: Observable<DictionaryBaseDto[]>} = {};

  constructor(public http: HttpClient, appConfigService: AppConfigService, _loggedUserService: LoggedUserService) {
    super(http, appConfigService, _loggedUserService);
  }

  cleanCache() {
    this.dictionaries = {};
    this.observables = {};
  }
  private getDictionaryBaseNoCache(dictionaryName: string): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/' + dictionaryName);
  }

  private getDictionaryBaseFilteredNoCache(
    dictionaryName: string,
    buId: number,
    parentId: number,
    profileId: number
  ): Observable<DictionaryBaseDto[]> {
    console.log(
      'loading dictionaryBase (1): ' + dictionaryName,
      ', buId = ' + buId + ', parentId = ' + parentId + ', profileId = ' + profileId
    );
    const params = [];
    if (buId) {
      params.push('buId=' + buId);
    }
    if (profileId) {
      params.push('profileId=' + profileId);
    }
    if (parentId) {
      params.push('parentId=' + parentId);
    }
    return this.get<DictionaryBaseDto[]>(this.url + '/' + dictionaryName + '?' + params.join('&'));
  }

  private getDictionaryBaseFilteredByUserRoleNoCache(
    dictionaryName: string,
    buId: number,
    parentId: number,
    userRoleId: number
  ): Observable<DictionaryBaseDto[]> {
    console.log(
      'loading dictionaryBase (2): ' + dictionaryName,
      ', buId = ' + buId + ', parentId = ' + parentId + ', userRoleId = ' + userRoleId
    );
    const params = [];
    if (buId) {
      params.push('buId=' + buId);
    }
    if (userRoleId) {
      params.push('userRoleId=' + userRoleId);
    }
    if (parentId) {
      params.push('parentId=' + parentId);
    }
    return this.get<DictionaryBaseDto[]>(this.url + '/role/' + dictionaryName + '?' + params.join('&'));
  }

  getDictionaryBaseFiltered(
    dictionaryName: string,
    buId: number,
    parentId: number,
    profileId: number
  ): Observable<DictionaryBaseDto[]> {
    if (buId || parentId || profileId) {
      return this.getDictionaryBaseFilteredNoCache(dictionaryName, buId, parentId, profileId);
    }
    return this.getDictionaryBase(dictionaryName);
  }

  getDictionaryBaseFilteredByUserRole(
    dictionaryName: string,
    buId: number,
    parentId: number,
    userRoleId: number
  ): Observable<DictionaryBaseDto[]> {
    if (buId || parentId || userRoleId) {
      return this.getDictionaryBaseFilteredByUserRoleNoCache(dictionaryName, buId, parentId, userRoleId);
    }
    return this.getDictionaryBase(dictionaryName);
  }

  getDictionaryBase(dictionaryName: string): Observable<DictionaryBaseDto[]> {
    const dictionary: DictionaryBaseDto[] = this.getDict(dictionaryName);
    if (dictionary) {
      // We have data already
      return observableOf(dictionary);
    }
    let observable: Observable<DictionaryBaseDto[]> = this.getObs(dictionaryName);
    if (observable) {
      // Data are in progress;
      return observable;
    }

    // no data, we must fetch it from server;

    observable = this.getDictionaryBaseNoCache(dictionaryName).pipe(
      tap(
        (entries) => {
          this.putDict(dictionaryName, entries);
          this.putObs(dictionaryName, undefined);
        },
        (error) => console.error('Cannot load dictionary ' + dictionaryName, error)
      ),
      share()
    );

    this.putObs(dictionaryName, observable);
    return observable;
  }

  getDictionaryEntry(
    dictionaryName: string,
    entryId: number,
    langId?: number,
    parentClass?: string
  ): Observable<DictionaryBaseDto> {
    console.assert(!!entryId, 'getDictionaryEntry null entryId');

    const params = [];
    if (langId) {
      params.push('langId=' + langId);
    }
    if (parentClass) {
      params.push('parentClass=' + parentClass);
    }
    const queryParamsJoined = params.length > 0 ? '?' + params.join('&') : '';
    return this.get<DictionaryBaseDto>(this.url + '/' + dictionaryName + '/' + entryId + queryParamsJoined);
  }

  private putDict(dictionaryName: string, dict: DictionaryBaseDto[]) {
    this.dictionaries[dictionaryName] = dict;
  }

  private getDict(dictionaryName: string): DictionaryBaseDto[] {
    return this.dictionaries[dictionaryName];
  }

  private putObs(dictionaryName: string, obs: Observable<DictionaryBaseDto[]>) {
    this.observables[dictionaryName] = obs;
  }

  private getObs(dictionaryName: string): Observable<DictionaryBaseDto[]> {
    return this.observables[dictionaryName];
  }
}
