import {Injectable, Optional} from '@angular/core';
import {LimitCoverPlusBaseDto, UserService} from '../../bonding_shared';
import {portalUserContextCacheBuster$, PortalUserService} from '../services/portal-user.service';

@Injectable()
export class LimitCoverGuiService {
  coverProposals: LimitCoverPlusBaseDto[];

  constructor(@Optional() private userService: PortalUserService) {
    if (!userService) {
      return;
    }
    this.refreshCovers();
    portalUserContextCacheBuster$.subscribe(() => this.refreshCovers());
  }

  refreshCovers() {
    if (!this.userService) {
      return;
    }
    this.userService.getPortalUserContext().subscribe((ctx) => {
      this.coverProposals = ctx.limitCoverActiveProposals;
    });
  }

  removeProposal(coverId: number) {
    this.coverProposals = this.coverProposals.filter((p) => p.id !== coverId);
  }
}
