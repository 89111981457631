import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';

const OBJECT_AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ObjectAutoCompleteComponent),
  multi: true,
};

@Component({
  selector: 'object-auto-complete',
  template: `
    <input
      [ngModel]="_selectedItem"
      (ngModelChange)="selectedItem = $event"
      class="bon-input"
      [disabled]="disabled"
      autocomplete="off"
      auto-complete
      [value-formatter]="valueFormatter"
      [list-formatter]="listFormatter"
      [match-formatted]="matchFormatted"
      [source]="items"
      [accept-user-input]="acceptUserInput"
      [min-chars]="minChars"
      style="width: 100%"
    />
  `,
  providers: [OBJECT_AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR],
})
export class ObjectAutoCompleteComponent<T extends {id: number}> implements ControlValueAccessor, OnInit {
  @HostBinding('class') class = 'bon-input-size';

  @Input() valueFormatter: ((arg: any) => string) | string;
  @Input() listFormatter: ((arg: any) => string) | string;
  @Input() matchFormatted = true;
  @Input() acceptUserInput = false;
  @Input() minChars = 3;

  @Input() nullLabel: string;
  @Input() disabled = false;
  @Input() label = 'name';

  /**
   * The items that will not be shown in a list. This should be be stored as set,
   * i.e.  hiddenIds[12] = true;
   */
  @Input() hiddenIds: Set<number>;
  @Input() loadItemsObs$: Observable<T[]>;

  @Output() changeItem = new EventEmitter<T>();

  @ContentChild(TemplateRef, {static: true}) template: TemplateRef<any>;

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  private _selectedKey: number;
  public _selectedItem: T;

  public items: T[] = [];
  public errorMessage: string;

  constructor() {}

  ngOnInit() {
    this.loadItems();
  }

  get selectedItem(): T {
    console.log('get SelectedItem() ', this._selectedItem);
    return this._selectedItem;
  }

  set selectedItem(it: T) {
    this._selectedItem = it;
    if (
      this.onChangeListeners &&
      (typeof this._selectedItem === 'object' || typeof this._selectedItem === 'undefined')
    ) {
      this.onChangeListeners(this._selectedItem);
      this.changeItem.emit(this._selectedItem);
    }
  }

  // From ControlValueAccessor interface
  writeValue(it: T): void {
    this._selectedItem = (it && this.items.find((i) => i.id === it.id)) || it;
    this._selectedKey = this._selectedItem && this._selectedItem.id;
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  reload(): void {
    this.loadItems();
  }

  private loadItems(): void {
    if (this.loadItemsObs$) {
      console.log('ObjectAutoComplete::loadItemsFunc!');
      this.loadItemsObs$.subscribe(
        (items) => (this.items = items),
        (error) => (this.errorMessage = <any>error)
      );
    } else {
      console.log('ObjectAutoComplete::loadItemsFunc is undefined');
    }
  }
}
