import {AfterViewInit, Component, ContentChild, Input, Renderer2, ViewChild} from '@angular/core';
import {TabView} from 'primeng/tabview';
import {PolicyContractVersionDto} from '../../../bonding_shared/model';
import {PolicyContractViewSection, PolicyContractViewService} from '../services/policy-contract-view.service';
import {KukeInsuredsSectionComponent} from './sections/kuke/kuke-insureds-section.component';

@Component({
  selector: 'kuke-policy-tab-layout',
  templateUrl: './kuke-policy-tab-layout.component.pug',
})
export class KukePolicyTabLayoutComponent implements AfterViewInit {
  @ViewChild(TabView) private tabView: TabView;
  @ContentChild(KukeInsuredsSectionComponent, {static: true}) insuredsTab;

  viewSection = PolicyContractViewSection;

  public _policy: PolicyContractVersionDto;
  @Input() set policy(policy: PolicyContractVersionDto) {
    this._policy = policy;
  }

  constructor(private renderer: Renderer2, protected policyContractViewService: PolicyContractViewService) {}

  ngAfterViewInit() {
    this.renderer.removeClass(this.tabView.el.nativeElement.firstChild, 'ui-widget');
  }

  get isPolicySaved() {
    return this._policy && this._policy.id > 0;
  }

  isTabVisible(viewSection: PolicyContractViewSection, defaultVisibility = false) {
    if (this._policy && this._policy.contractType && this._policy.contractType.id) {
      return this.policyContractViewService.isSectionVisible(
        this._policy.contractType.id,
        viewSection,
        defaultVisibility
      );
    }
  }
}
