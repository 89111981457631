import {Component, ViewChild} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {ActivatedRoute} from '@angular/router';
import {SearchViewComponent} from '../../bonding_shared/components/search/search-view/search-view.component';
import {InvoicePaymentCriteriaDto, InvoicePaymentSimpleDto} from '../../bonding_shared/model';
import {InvoicePaymentGuiService} from './services/invoice-payment-gui.service';
import {DictionaryBaseService} from '../../bonding_shared/services';
import {ChangePasswordComponent} from '../../bonding_shared/components/user/change-password.component';

@Component({
  selector: 'invoice-payment-search',
  templateUrl: 'invoice-payment-search.component.html',
})
export class InvoicePaymentSearchComponent {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    InvoicePaymentCriteriaDto,
    InvoicePaymentSimpleDto
  >;
  dataProvider: SearchDataProvider<InvoicePaymentCriteriaDto, InvoicePaymentSimpleDto>;

  constructor(
    public router: RouterService,
    protected route: ActivatedRoute,
    public guiService: InvoicePaymentGuiService,
    protected dictionaryBaseService: DictionaryBaseService
  ) {
    this.dataProvider = guiService.getDataProvider();
  }

  public get criteria(): InvoicePaymentCriteriaDto {
    return this.dataProvider.searchCriteria.criteria;
  }
}
