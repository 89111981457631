import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {PolicyLimitListSelectorComponent} from './components/policy-limit-list-selector.component';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';
import {PolicyLimitListSectionSelectorComponent} from '../components/policy-limit-list-section-selector.component';
import {PolicyLimitListInfoComponent} from '../components/policy-limit-list-info.component';
import {PolicyContractSharedModule} from '../../policy/components/shared/policy-contract-shared.module';
import {LimitListSectionSelectorComponent} from '../components/limit-list-section-selector.component';
import {PolicyLimitListListComponent} from '../components/policylimitlist-list.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    PolicyContractSharedModule,
  ],
  declarations: [
    PolicyLimitListSelectorComponent,
    PolicyLimitListSectionSelectorComponent,
    PolicyLimitListInfoComponent,
    LimitListSectionSelectorComponent,
    PolicyLimitListListComponent,
  ],
  exports: [
    PolicyLimitListSelectorComponent,
    PolicyLimitListSectionSelectorComponent,
    PolicyLimitListInfoComponent,
    LimitListSectionSelectorComponent,
    PolicyLimitListListComponent,
  ],
})
export class PolicyLimitListSharedModule {}
