import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {HomeComponent} from './home.component';
import {DonutChartComponent} from './charts/donutchart/donutchart.component';
import {PieChartComponent} from './charts/piechart/piechart.component';
import {TimeChartComponent} from './charts/timechart/timechart.component';
import {BondingMapComponent} from './charts/v-maps/bonding-map.component';
import {PolicyMapComponent} from './charts/v-maps/policy-map.component';
import {LimitMapComponent} from './charts/v-maps/limit-map.component';
import {ClaimMapComponent} from './charts/v-maps/claim-map.component';
import {WorldMapLimitComponent} from './charts/worldmap-limit/worldmap-limit.component';
import {PieChartLimitComponent} from './charts/piechart-limit/piechart-limit.component';
import {RatingLimitComponent} from './charts/rating-limit/rating-limit.component';
import {PieChartContractComponent} from './charts/piechart-contract/piechart-contract.component';
import {BarChartContractFreeCapacityComponent} from './charts/barchart-contract-free-capacity/barchart-contract-free-capacity.component';
import {BrokerDashboardComponent} from './dashboards/broker-dashboard/broker-dashboard.component';
import {BrokerPortfolioService} from '../../portal/services/broker-portfolio.service';
import {NgChartsConfiguration, NgChartsModule} from 'ng2-charts';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    TranslateModule,
  ],
  declarations: [
    HomeComponent,
    DonutChartComponent,
    PieChartComponent,
    PieChartLimitComponent,
    TimeChartComponent,
    BondingMapComponent,
    WorldMapLimitComponent,
    PolicyMapComponent,
    LimitMapComponent,
    ClaimMapComponent,
    RatingLimitComponent,
    PieChartContractComponent,
    BarChartContractFreeCapacityComponent,
    BrokerDashboardComponent,
  ],
  providers: [
    BrokerPortfolioService,
    {
      provide: NgChartsConfiguration,
      useValue: {generateColors: false, defaults: {datasets: {line: {pointHitRadius: 10, tension: 0.5}}}},
    },
  ],
  exports: [BarChartContractFreeCapacityComponent, PieChartContractComponent, LimitMapComponent],
})
export class DashboardModule {}
