import {Component, Input} from '@angular/core';
import {
  BusinessPropertiesService,
  DictionaryBaseDto,
  DictionaryBaseService,
  LimitCoverPlusValueDto,
  RouterService,
} from '../../../bonding_shared';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';

@Component({
  selector: 'limit-cover-plus-value',
  templateUrl: './limit-cover-plus-value.component.pug',
})
export class LimitCoverPlusValueComponent {
  @Input() status: DictionaryBaseDto;
  @Input() label: string;
  @Input() value: LimitCoverPlusValueDto;
  @Input() set currencyId(id: number) {
    this.dictionaryService.getDictionaryEntry('Currency', id).subscribe((e) => (this.currency = e));
  }
  @Input() showStatus = false;
  @Input() proposal = true;
  @Input() expirationDate: Date;

  currency: DictionaryBaseDto;
  systemCurrency: DictionaryBaseDto;

  constructor(
    public router: RouterService,
    private dictionaryService: DictionaryBaseService,
    private businessPropertiesService: BusinessPropertiesService
  ) {
    businessPropertiesService.getProperties().subscribe((p) => (this.systemCurrency = p.systemCurrency));
  }

  getValidity(v: LimitCoverPlusValueDto) {
    return BusinessUtils.validity(v.validFrom, v.validTo);
  }
}
