import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {
  AppConfigService,
  DictionaryService,
  PolicyContractVersionService,
} from '../../../../../bonding_shared/services';
import {PeriodUnit} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'kuke-scope-of-cover-section',
  templateUrl: './kuke-scope-of-cover-section.component.pug',
})
export class KukeScopeOfCoverSectionComponent extends Section {
  self = this;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected pcvService: PolicyContractVersionService,
    protected dictionaryService: DictionaryService
  ) {
    super(policyContractViewService, appService, pcvService, dictionaryService);
  }

  onCommercialRiskChanged(checked: boolean) {
    if (!checked) {
      delete this.policy.selfRetentionCommercialRisk;
      delete this.policy.selfRetentionNonPaymentAndCommercialRisk;
      delete this.policy.selfRetentionProductionAndCommercialRisks;
    }
  }

  onNonPaymentRiskChanged(checked: boolean) {
    if (!checked) {
      delete this.policy.selfRetentionNonPaymentAndCommercialRisk;
      delete this.policy.selfRetentionNonPaymentAndPoliticalRisks;
      delete this.policy.protractedDefaultValue;
      delete this.policy.protractedDefault;
      this.dictionaryService
        .getDictionaryEntry('PeriodUnit', PeriodUnit.DAY)
        .subscribe((d) => (this.policy.protractedDefaultPeriodUnit = d));
    }
  }

  onProductionRiskChanged(checked: boolean) {
    if (!checked) {
      delete this.policy.selfRetentionProductionAndCommercialRisks;
    }
  }

  onPoliticalRiskChangedSOB(checked: boolean) {
    if (!checked) {
      delete this.policy.selfRetentionPoliticalRisk;
      delete this.policy.selfRetentionNonPaymentAndPoliticalRisks;
    }
  }
}
