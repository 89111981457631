import {combineLatest, Observable, of as observableOf} from 'rxjs';

import {map, share, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {
  AppConfigService,
  BondVersionBaseDto,
  BondVersionCriteriaDto,
  BondVersionDto,
  BondVersionService,
  BondVersionSimpleDto,
  CompanyDto,
  CompanyPortalDto,
  CompanyService,
  CompanySimpleDto,
  ContractBondDto,
  ContractService,
  ContractVersionDto,
  ContractVersionService,
  DictionaryBaseDto,
  DictionaryDto,
  DictionaryService,
  LoggedUserService,
  SearchDataProvider,
  StatisticsDto,
  TemplateService,
  TemplateSimpleDto,
} from '../../../bonding_shared';
import {
  BondStatus,
  BondType,
  BusinessObjectType,
  DictionaryProperty,
  DocumentType,
  Language,
  VersionType,
} from '../../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {DictionaryUtils} from '../../../bonding_shared/utils/dictionary-utils';

@Injectable()
export class BondGuiService {
  /**
   * Keeps selected criteria for bond search view
   */
  searchDataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>;

  justCreatedBond: BondVersionDto;

  private bondVersion: BondVersionDto;
  private contractVersion: ContractVersionDto;
  private client: CompanyDto;
  private beneficiary: CompanyDto;
  private bondSubtypes: DictionaryDto[];
  private customTemplate: TemplateSimpleDto;

  constructor(
    protected _companyService: CompanyService,
    protected _contractVersionService: ContractVersionService,
    protected contractService: ContractService,
    protected _bondService: BondVersionService,
    protected _templateService: TemplateService,
    protected dictionaryService: DictionaryService,
    protected loggedUserService: LoggedUserService,
    protected appService: AppConfigService
  ) {
    this.searchDataProvider = new SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>(_bondService);
  }

  static isBondRequest(bond: BondVersionBaseDto, statusChanged: boolean): boolean {
    return (
      bond &&
      bond.status &&
      (BusinessUtils.isRequest(bond.status.id) || (statusChanged && bond.status.id === BondStatus.BOND))
    );
  }

  createDataProvider(): SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto> {
    return new SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>(this._bondService);
  }

  get bondService() {
    return this._bondService;
  }

  getInitialVersion(
    clientId: number,
    typeId: number,
    subtypeId: number,
    buId: number,
    contractVersionId: number
  ): Observable<BondVersionDto> {
    return this._bondService.getInitialVersion(clientId, typeId, subtypeId, buId, contractVersionId);
  }

  newBondVersionForBond(bondId: number) {
    return this._bondService.getNewBondVersion(bondId);
  }

  getBondVersion(bondVersionId: number): Observable<BondVersionDto> {
    console.log('Got bondVersion : id = ' + bondVersionId + ', cached = ', this.bondVersion);
    if (this.bondVersion && this.bondVersion.id === bondVersionId) {
      console.log('Got bondVersion from cache:');
      return observableOf(this.bondVersion);
    }
    console.log('Got bondVersion from backend:');
    return this._bondService.getBondVersion(bondVersionId).pipe(
      tap((bv) => (this.bondVersion = bv)),
      share()
    );
  }

  getCompany(clientId: number): Observable<CompanyDto> {
    if (this.client && this.client.id === clientId) {
      console.log('Got company from cache:');
      return observableOf(this.client);
    }
    console.log('Got company from backend:');
    return this._companyService.getCompany(clientId).pipe(
      tap((c) => (this.client = c)),
      share()
    );
  }

  getBeneficiary(clientId: number): Observable<CompanyDto> {
    if (this.beneficiary && this.beneficiary.id === clientId) {
      console.log('Got beneficiary from cache:');
      return observableOf(this.beneficiary);
    }
    console.log('Got beneficiary from backend:');
    return this._companyService.getCompany(clientId).pipe(
      tap((c) => (this.beneficiary = c)),
      share()
    );
  }

  getContractVersion(contractId: number): Observable<ContractVersionDto> {
    if (this.contractVersion && this.contractVersion.contract && this.contractVersion.contract.id === contractId) {
      console.log('Got contractVersion from cache:');
      return observableOf(this.contractVersion);
    }
    console.log('Got contractVersion from backend: ' + contractId);
    return this._contractVersionService.getContractVersionsByContractId(contractId);
  }

  // portal contract version service returns Observable<SearchResult<ContractVersionDto>>
  getActiveContractVersionForContract(contractId: number): Observable<ContractVersionDto> {
    return this._contractVersionService.getContractVersionsByContractId(contractId);
  }

  getBondVersions(bondId: number) {
    return this._bondService.getBondVersions(bondId).pipe(share());
  }

  saveBondVersion(bondVersion: BondVersionDto) {
    console.log('save bondVersion in gui-service:: ');
    return this._bondService.saveBondVersion(bondVersion).pipe(
      tap((bv) => (this.bondVersion = bv)),
      share()
    );
  }

  getContractBondTypeById(types: ContractBondDto[], bondTypeId: number): ContractBondDto {
    if (!types) {
      return null;
    }
    const cTypes = types.filter((t) => parseInt(t.bondType.id + '', 10) === parseInt(bondTypeId + '', 10));
    if (cTypes.length !== 1) {
      console.log(
        '!!! No or more than one active and activated contract versions found = ' +
          cTypes.length +
          ', bondTypeId = ' +
          bondTypeId,
        types
      );
      return null;
    }
    return cTypes[0];
  }

  getLastBondVersionForBond(bondId: number): Observable<BondVersionDto> {
    return this._bondService.getLastBondVersionForBond(bondId);
  }

  getContractStatistics(contractId: number): Observable<StatisticsDto> {
    return this.contractService.getContractStatistics(contractId);
  }

  deleteBondVersion(bondVersionId: number): Observable<BondVersionDto> {
    return this._bondService.deleteBondVersion(bondVersionId);
  }

  setRelatedObjectIdsInBondVersion(
    bv: BondVersionDto,
    company: CompanySimpleDto,
    contractVersion: ContractVersionDto,
    bondType: ContractBondDto,
    beneficiary: CompanySimpleDto | CompanyPortalDto
  ) {
    bv.clientId = company.id;
    bv.contractVersionId = contractVersion.id;
    bv.type = bondType.bondType;
    bv.beneficiaryId = (<CompanyDto>beneficiary).id;
  }

  getBondTemplates(
    language: DictionaryBaseDto,
    dictionarySelectors: DictionaryBaseDto[],
    getGenericAppendixTemplate = false,
    allDocuments = false
  ): Observable<TemplateSimpleDto[]> {
    const langId = language ? language.id : Language.ENGLISH;

    const bondTemplates = this._templateService.findByType(
      allDocuments ? null : DocumentType.BOND,
      BusinessObjectType.BOND_VERSION,
      allDocuments ? null : langId,
      dictionarySelectors
    );
    if (getGenericAppendixTemplate) {
      const genericAppendixTemplate = this._templateService.findByType(
        DocumentType.GENERIC_APPENDIX,
        BusinessObjectType.BOND_VERSION,
        langId
      );
      return combineLatest([bondTemplates, genericAppendixTemplate]).pipe(
        map(([data1, data2]) => {
          return [...data1, ...data2];
        })
      );
    }
    return bondTemplates;
  }

  isAnnex(bond: BondVersionDto) {
    return bond && bond.versionType && bond.versionType.id === VersionType.ANNEX;
  }

  isNotActiveBond(bond: BondVersionDto): boolean {
    return !bond || !bond.status || BusinessUtils.isRequest(bond.status.id);
  }

  isBond(bond: BondVersionDto): boolean {
    return bond && bond.status && bond.status.id === BondStatus.BOND;
  }

  clearCache() {
    console.log('Cache cleared ...');
    this.bondVersion = undefined;
    this.contractVersion = undefined;
    this.client = undefined;
    this.beneficiary = undefined;
  }

  private isSubtypeFromDictionary(bond: BondVersionDto): boolean {
    const val = this.bondSubtypes.find((entry) => entry.id === bond.subtype.id).properties[
      DictionaryProperty.BOND_VALUE_EDITABLE
    ];
    if (!val) {
      return false;
    }
    return JSON.parse(val);
  }

  getClientBondOwnTemplates(
    dictionarySelectors: DictionaryBaseDto[],
    clientId: number,
    contractId: number
  ): Observable<TemplateSimpleDto[]> {
    return this._templateService.getClientBondOwnTemplates(dictionarySelectors, clientId, contractId);
  }

  setCustomTemplate(ct: TemplateSimpleDto) {
    this.customTemplate = ct;
  }

  getCustomTemplate() {
    return this.customTemplate;
  }

  isPromesa(bondType: DictionaryBaseDto): boolean {
    return DictionaryUtils.in(
      bondType,
      BondType.PROMESA_WADIUM,
      BondType.PROMESA_NALEZYTE_WYKONANIE_KONTRAKTU,
      BondType.PROMESA_USUNIECIA_WAD_I_USTEREK,
      BondType.PROMESA_NALEZYTE_WYKONANIE_KONTRAKTU_I_USUNIECIE_WAD_I_USTEREK,
      BondType.PROMESA_CELNA,
      BondType.PROMESA_ZWROT_ZALICZKI,
      BondType.PROMESA_PLATNICZA,
      BondType.PROMESA_KOSZTY_USUNIECIA_ODPADOW,
      BondType.PROMESA_ZARZADZANIE_SKLADOWISKIEM_ODPADOW,
      BondType.PROMESA_SZKODY_SRODOWISKOWE,
      BondType.PROMESA_MIEDZYNARODOWE_PRZEMIESZCZANIE_ODPADOW
    );
  }
}
