import {UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {AbstractBusinessObjectSelector} from './abstract-business-object-selector';
import {AppConfigService, BusinessObjectService, GrowlService, RouterService} from '../../services';
import {BusinessObjectDto} from '../../model';
import {DictionaryProfile} from '../../model/dictionary-ids';

const BUSINESS_OBJECT_SELECTOR_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BusinessObjectSelectorComponent),
  multi: true,
};

@Component({
  selector: 'business-object-selector',
  templateUrl: 'business-object-selector.component.html',
  providers: [BUSINESS_OBJECT_SELECTOR_CONTROL_VALUE_ACCESSOR],
})
export class BusinessObjectSelectorComponent extends AbstractBusinessObjectSelector implements OnInit {
  searchPhrase = new UntypedFormControl();
  bo = new UntypedFormControl();

  @Input() emitOnlyType = false;
  @Input() showLabel = false;
  @Input() profileId = DictionaryProfile.LEGAL_BUSINESS_OBJECT;
  @Output() businessObjectSelected = new EventEmitter<Boolean>();

  readonly DictionaryProfile = DictionaryProfile;

  constructor(
    protected businessObjectService: BusinessObjectService,
    public router: RouterService,
    protected growlService: GrowlService,
    public appConfigService: AppConfigService
  ) {
    super(businessObjectService, router, growlService, appConfigService);
  }

  executeSearch() {
    this.search(this.relatedTo.value.id, this.searchPhrase.value);
  }

  ngOnInit(): void {
    this.relatedTo.valueChanges.subscribe((value) => {
      if (this.emitOnlyType) {
        this.value = <BusinessObjectDto>{relatedTo: value};
        this.onChange(this.value);
      }
    });
    this.bo.valueChanges.subscribe((value) => {
      this.value = value;
      this.onChange(this.value);
      this.businessObjectSelected.emit(true);
    });
  }

  change() {
    const oldValue = this.value;
    this.value = undefined;
    this.onChange(this.value);
    this.relatedTo.patchValue(oldValue.relatedTo);
    this.bo.patchValue(undefined, {emitEvent: true});
  }

  remove() {
    this.value = undefined;
    this.bo.patchValue(undefined);
    this.relatedTo.patchValue(undefined);
    this.searchPhrase.patchValue(undefined);
    this.businessObjectsResults = [];
    this.tooManyResults = false;
  }
}
