import {Component, Input} from '@angular/core';
import {ContractVersionCriteriaDto, RouterService} from '../../../../../bonding_shared';
import {SectionSelector} from '../../../../../bonding_shared/components/details-view/section-selector';

@Component({
  selector: 'contract-section-selector',
  template: `
    <ng-template #section let-openEmitter="openEmitter" let-object="object" let-disabled="disabled">
      <selectable-object-section
        [labelKey]="sectionLabel"
        [clearable]="false"
        [searchable]="!disabled"
        [openSelectorEmitter]="openEmitter"
      >
        <div class="bon-row" *ngIf="object[property]" content="body">
          <b class="a-link col-md-8 font-larger" (click)="router.toContractPreview(object[property].id)"
            >{{ object[property].contract.number }}/{{ object[property].versionNumber }}</b
          >
        </div>
      </selectable-object-section>
      <company-section
        *ngIf="object[property]"
        labelKey="contract.common.mainClient"
        [companyId]="object[property].client?.id"
        [clearable]="false"
        [showLink]="true"
        [readOnly]="true"
      >
      </company-section>
    </ng-template>
    <ng-template #selector let-openEmitter="openEmitter" let-onChange="onChange">
      <contract-version-selector-simple
        [labelKey]="selectorLabel"
        [open]="openEmitter"
        (selectItem)="onChange($event)"
        [criteria]="criteria"
      >
      </contract-version-selector-simple>
    </ng-template>
  `,
  providers: [{provide: SectionSelector, useExisting: ContractSectionSelectorComponent}],
})
export class ContractSectionSelectorComponent extends SectionSelector {
  @Input() criteria: ContractVersionCriteriaDto;

  constructor(public router: RouterService) {
    super();
  }
}
