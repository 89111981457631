import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  BondingContractExposureDto,
  ComboItem,
  ContractVersionCriteriaDto,
  ContractVersionSimpleDto,
  PropertyService,
  RouterService,
  SearchDataProvider,
  VersionPhase,
} from '../../../../../bonding_shared';
import {AppProperty, ContractStatus} from '../../../../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'filterable-contract-version-list',
  templateUrl: './filterable-contract-version-list.component.pug',
})
export class FilterableContractVersionListComponent {
  readonly SYSTEM_CURRENCY_CODE = this.propertyService.properties[AppProperty.SYSTEM_CURRENCY];
  @ViewChild(ATableComponent, {static: true}) contractTable: ATableComponent<ContractVersionSimpleDto>;
  @Output() selectItem = new EventEmitter<ContractVersionSimpleDto>();

  @Input() showExposure = false;
  @Input() pageSize = 20;
  @Input() pagination = false;
  @Input() exposure: BondingContractExposureDto;
  @Input() set dataProvider(dp: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>) {
    if (!dp) {
      return;
    }
    dp.searchCriteria.criteria.versionPhase = 'ACTIVE';
    this.contractTable.dataProvider = dp;
    this.filter = FilterItem[FilterItem.ACTIVE];
    this.contractTable.pagination = true;
    this.applyFilter();
    this.contractTable.search();
  }

  filters: ComboItem[];
  filter: String = FilterItem[FilterItem.ACTIVE];

  constructor(
    public router: RouterService,
    public appService: AppConfigService,
    private propertyService: PropertyService,
    translateService: TranslateService
  ) {
    this.filters = [
      new ComboItem(FilterItem[FilterItem.REQUEST], translateService.instant('common.requests')),
      new ComboItem(FilterItem[FilterItem.CONTRACT], translateService.instant('Contracts')),
      new ComboItem(FilterItem[FilterItem.ACTIVE], translateService.instant('Active contracts')),
    ];
  }

  onChange(item: string) {
    this.applyFilter();
  }

  isRequest(cv: ContractVersionSimpleDto) {
    return cv.status.id < ContractStatus.CONTRACT_ACTIVATED;
  }

  applyFilter() {
    console.log('apply filter:' + this.filter);
    switch (this.filter) {
      case FilterItem[FilterItem.REQUEST]:
        this.setCriteria(false, undefined);
        break;
      case FilterItem[FilterItem.CONTRACT]:
        this.setCriteria(true, 'LAST');
        break;
      case FilterItem[FilterItem.ACTIVE]:
        this.setCriteria(true, 'ACTIVE');
        break;
      default:
        this.setCriteria(undefined, undefined);
        break;
    }
    this.contractTable.search();
  }

  setCriteria(activated: boolean, versionPhase: VersionPhase) {
    this.dataProvider.searchCriteria.criteria.activated = activated;
    this.dataProvider.searchCriteria.criteria.versionPhase = versionPhase;
  }

  get dataProvider(): SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto> {
    return <SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>>this.contractTable.dataProvider;
  }

  emptyList(): boolean {
    return !this.contractTable.items || this.contractTable.items.length < 1;
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }
}

export enum FilterItem {
  REQUEST,
  CONTRACT,
  ACTIVE,
}
