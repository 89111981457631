import {AbstractService} from './abstract.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {LegalInsolvencyDto, LegalInsolvencyVersionDto} from '../model';

@Injectable()
export class CompanyLegalInsolvencyService extends AbstractService {
  protected url = this.urlPrefix + 'legalInsolvencyVersion';
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getLegalInsolvency(insolvencyId: number): Observable<LegalInsolvencyDto> {
    return this.get<LegalInsolvencyDto>(this.url + '/insolvency/' + insolvencyId);
  }

  getLastVersion(parentId: number): Observable<LegalInsolvencyVersionDto> {
    return this.get<LegalInsolvencyVersionDto>(this.url + '/lastVersion?parentId=' + parentId);
  }

  public getLastVersionByCompanyId(companyId: number): Observable<LegalInsolvencyVersionDto> {
    return this.get<LegalInsolvencyVersionDto>(this.url + '/companyLastVersion?companyId=' + companyId);
  }

  initialVersion(companyId: number): Observable<LegalInsolvencyVersionDto> {
    return this.get<LegalInsolvencyVersionDto>(this.url + '/initialVersion?companyId=' + companyId);
  }
}
