import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  PolicyContractDto,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionDto,
  PolicyContractVersionSimpleDto,
  SearchCriteria,
} from '../../../../bonding_shared/model/dtos';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table/a-table.component';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {AbstractService} from '../../../../bonding_shared/services/abstract.service';
import {PolicyContractViewSection, PolicyContractViewService} from '../../services/policy-contract-view.service';

@Component({
  selector: 'policy-contract-version-list',
  templateUrl: './policy-contract-version-list.component.pug',
})
export class PolicyContractVersionListComponent {
  constructor(public policyContractViewService: PolicyContractViewService) {}
  public viewSection = PolicyContractViewSection;

  _selectedPolicyContractVersion: PolicyContractVersionDto;
  @ViewChild(ATableComponent, {static: true}) policyTable: ATableComponent<PolicyContractVersionSimpleDto>;

  @Output() selectItem = new EventEmitter<PolicyContractVersionSimpleDto>();
  @Output() ready = new EventEmitter();

  @Input() set service(service: AbstractService) {
    this.policyTable.dataProvider = new SearchDataProvider<
      PolicyContractVersionCriteriaDto,
      PolicyContractVersionSimpleDto
    >(service);
    (<SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto>>(
      this.policyTable.dataProvider
    )).searchCriteria = <SearchCriteria<PolicyContractVersionCriteriaDto>>{};
    (<SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto>>(
      this.policyTable.dataProvider
    )).searchCriteria.criteria = <PolicyContractVersionCriteriaDto>{};
    (<SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto>>(
      this.policyTable.dataProvider
    )).searchCriteria.criteria.policyContract = <PolicyContractDto>{};
    this.setPolicyIdInCriteria();
  }

  @Input() set selectedPolicyContractVersion(p: PolicyContractVersionDto) {
    console.log('selected policy = ' + p.policyContract.id);
    this._selectedPolicyContractVersion = p;
    this.setPolicyIdInCriteria();
  }

  setVersionNumber(versionNumber: number) {
    if (versionNumber <= this.policyTable.items.length) {
      this.setSelectedItem(this.policyTable.items.find((item) => item.versionNumber === +versionNumber));
    }
  }

  setSelectedItem(version: PolicyContractVersionSimpleDto) {
    this.selectItem.emit(version);
  }

  setPolicyIdInCriteria() {
    if (
      !this._selectedPolicyContractVersion ||
      !this._selectedPolicyContractVersion.policyContract ||
      !this._selectedPolicyContractVersion.policyContract.id ||
      !this.policyTable.dataProvider
    ) {
      return;
    }
    (<SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto>>(
      this.policyTable.dataProvider
    )).searchCriteria.criteria.policyContract.id = this._selectedPolicyContractVersion.policyContract.id;
    this.policyTable.search();
  }

  isTurnoverVisible() {
    return (
      this.policyContractViewService.isSectionVisible(
        this._selectedPolicyContractVersion.contractType.id,
        this.viewSection.POLICY_CONTRACT_DATA__DOMESTIC_TURNOVER,
        true
      ) ||
      this.policyContractViewService.isSectionVisible(
        this._selectedPolicyContractVersion.contractType.id,
        this.viewSection.POLICY_CONTRACT_DATA__EXPORT_TURNOVER,
        true
      )
    );
  }
}
