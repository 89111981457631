import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  ExternalProviderBaseDto,
  ExternalProviderVersionBaseDto,
  ExternalProviderVersionCriteriaDto,
  ExternalProviderVersionDto,
  SearchCriteria,
  SearchResult,
  StateTransitionDto,
} from '../model';
import {map} from 'rxjs/operators';

@Injectable()
export class ExternalProviderVersionService extends AbstractService {
  protected url = this.urlPrefix + 'externalProviderVersion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  initialVersion(parent: ExternalProviderBaseDto): Observable<ExternalProviderVersionDto> {
    return this.post1<ExternalProviderBaseDto, ExternalProviderVersionDto>(parent, this.url + '/initialVersion/');
  }

  getTransitions(externalProviderVersion: ExternalProviderVersionDto): Observable<Array<StateTransitionDto>> {
    return this.get<StateTransitionDto[]>(this.url + '/transition/' + externalProviderVersion.businessStatus.id);
  }

  getTransitionsMap(): Observable<Map<number, Array<StateTransitionDto>>> {
    return this.get(this.url + '/transitions');
  }

  getVersionsByParentId(parentId: number): Observable<SearchResult<ExternalProviderVersionBaseDto>> {
    const criteria = <SearchCriteria<ExternalProviderVersionCriteriaDto>>{criteria: {parent: {id: parentId}}};
    return this.searchByCriteria(criteria);
  }

  getLastVersionByParentId(parentId: number): Observable<ExternalProviderVersionBaseDto> {
    const criteria = <SearchCriteria<ExternalProviderVersionCriteriaDto>>{
      criteria: {parent: {id: parentId}, versionPhase: 'LAST'},
    };
    return this.searchByCriteria(criteria).pipe(
      map((res) => (<SearchResult<ExternalProviderVersionBaseDto>>res).result[0])
    );
  }

  getCommission(versionId: number, countryId: number, amount: number, daysPastDue: number): Observable<number> {
    return this.get(
      this.url +
        '/' +
        versionId +
        '/commission?countryId=' +
        countryId +
        '&amount=' +
        amount +
        '&daysPastDue=' +
        daysPastDue
    );
  }
}
