import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {AppConfigService, GrowlService, SearchDataProvider} from '../../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {AfterViewInit, Directive, ViewChild} from '@angular/core';
import {DeclarationCriteriaComponent} from './components/declaration-criteria.component';
import {ATableComponent} from '../../../bonding_shared/components/aku-table';
import {
  CompanyInDeclarationDto,
  DeclarationBuyerCriteriaDto,
  DeclarationCriteriaDto,
  DeclarationFileImportDto,
  DeclarationMetadataDto,
  DeclarationWithoutEntriesDto,
  ErrorReason,
  FileImportCriteriaDto,
  FileImportSimpleDto,
  LimitListVersionInDeclarationDto,
  ReportingPeriodDto,
  SearchCriteria,
} from '../../../bonding_shared/model';

import {DeclarationCriteria} from './declaration-details-view';

@Directive()
export class DeclarationImportDetailsView extends DetailsView implements AfterViewInit {
  @ViewChild(DeclarationCriteriaComponent, {static: true}) dcComponent: DeclarationCriteriaComponent;
  @ViewChild(ATableComponent, {static: true}) fileTable: ATableComponent<FileImportSimpleDto>;
  fileDataProvider: SearchDataProvider<FileImportCriteriaDto, FileImportSimpleDto>;

  selectedFile: File;
  importDeclaration: DeclarationFileImportDto;

  generateCriteria: SearchCriteria<DeclarationCriteriaDto>;

  confirmedDeclarationForSelectedParams: DeclarationWithoutEntriesDto;
  inProgress_flag = false;

  dc: DeclarationCriteria;

  amendment_flag: boolean;

  set amendmentView(ai: boolean) {
    this.dcComponent.pureAmendmentView = ai; // propagate also to declaration criteria component
    this.amendment_flag = ai;
  }

  set amendment(ai: boolean) {
    this.dcComponent.amendmentDeclaration = ai; // propagate also to declaration criteria component
    this.amendment_flag = ai;
  }

  get amendment() {
    return this.amendment_flag;
  }

  constructor(
    protected appService: AppConfigService,
    protected translateService: TranslateService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.initializeGenerateCriteria();
  }

  protected initializeImportDeclaration() {} // abstract

  ngAfterViewInit(): void {
    this.initializeImportDeclaration();
    this.refreshFileTable();
  }

  public onPolicyChanged() {}

  // ############## FILE MANAGEMENT ##############
  loadFile() {
    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = (e: any) => {
      this.importDeclaration.content = e.target.result.split(',')[1];
      this.importDeclaration.fileName = this.selectedFile.name;
    };
  }

  refreshFileTable() {
    if (this.dc.selectedRp && this.dc.selectedLlv) {
      this.fileDataProvider.searchCriteria.criteria.metadata = <DeclarationMetadataDto>{
        rpId: this.dc.selectedRp.id,
        llvId: this.dc.selectedLlv.id,
      };
    } else {
      this.fileDataProvider.searchCriteria.criteria.metadata = undefined;
    }
    this.fileTable.search();
  }

  cancel() {
    this.selectedFile = undefined;
    // this.clearFieldsWithoutRpList();
  }

  // ############## HELPERS ##############
  inProgressMode(): boolean {
    return this.inProgress_flag;
  }

  criteriaSelectedAndNotInProgress(): boolean {
    return this.dc.selectedRp && this.dc.selectedLlv && this.dc.selectedDecOn && !this.inProgressMode();
  }

  protected downloadCompleteCallback(fileName: string): void {
    this.growlService.notice('File ' + fileName + ' downloaded');
  }

  protected exportCompleteCallback(file: File): void {
    if (file) {
      this.growlService.notice('File ' + file.name + ' exported!');
    }
    this.inProgress_flag = false;
    this.selectedFile = undefined;
  }

  protected errorCallback(err: string): void {
    this.serverErrors = <ErrorReason[]>JSON.parse(err);
    this.inProgress_flag = false;
    return;
  }

  // ############## SEARCH CRITERIA ##############
  initializeGenerateCriteria() {
    this.dc = <DeclarationCriteria>{buyerCriteria: <DeclarationBuyerCriteriaDto>{}};
    this.generateCriteria = <SearchCriteria<DeclarationCriteriaDto>>{};
    this.resetGenerateCriteriaByParams(undefined, undefined, undefined);
  }

  protected resetGenerateCriteria() {
    this.resetGenerateCriteriaByParams(this.dc.selectedRp, this.dc.selectedLlv, this.dc.selectedDecOn, true);
  }

  private resetGenerateCriteriaByParams(
    rp: ReportingPeriodDto,
    llv: LimitListVersionInDeclarationDto,
    decOn: CompanyInDeclarationDto,
    finishInProgress = false
  ) {
    this.resetGenerateCriteriaByIds(
      rp ? rp.id : undefined,
      llv ? llv.id : undefined,
      decOn ? decOn.id : undefined,
      finishInProgress
    );
  }

  // abstract
  protected resetGenerateCriteriaByIds(rpId: number, llvId: number, decOnId: number, finishInProgress = false) {}
}
