import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  AddressDto,
  AppConfigService,
  BackendError,
  CessionDto,
  CessionVersionBaseDto,
  CessionVersionCriteriaDto,
  CessionVersionDto,
  CompanyBaseDto,
  CompanyCriteriaDto,
  CompanySimpleDto,
  ConfirmDialogComponent,
  DictionaryBaseDto,
  DocumentService,
  GrowlService,
  PolicyCessionVersionService,
  RelatedObject,
  RouterService,
  SearchDataProvider,
  StringUtils,
  TemplateService,
  TemplateSimpleDto,
} from '../../bonding_shared';
import {TextSearchCriteria} from '../../bonding_shared/components/search/model/text-search-criteria';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {
  BusinessObjectType,
  CessionStatus,
  CessionType,
  DocumentType,
  ImportType,
  PolicyContractTypes,
} from '../../bonding_shared/model/dictionary-ids';
import {PolicyCessionVersionListComponent} from './components/shared/policy-cession-version-list.component';
import * as _ from 'lodash';

@Component({
  selector: 'policy-cession-details',
  templateUrl: 'policy-cession-details.component.html',
})
export class PolicyCessionDetailsComponent extends DetailsView implements OnInit {
  @ViewChild(PolicyCessionVersionListComponent) versionList: PolicyCessionVersionListComponent;
  @ViewChild('deleteCessionConfirmDialog') deleteCessionConfirmDialog: ConfirmDialogComponent;
  readonly CessionStatus = CessionStatus;
  readonly RelatedObject = RelatedObject;
  readonly ImportType = ImportType;

  cessionVersion: CessionVersionDto;
  initialVersion: CessionVersionDto;

  assigneeCriteria: CompanyCriteriaDto;
  assigneeTextCriteria: TextSearchCriteria;
  buyerCriteria: CompanyCriteriaDto;
  buyerTextCriteria: TextSearchCriteria;

  policyContractId: number;

  serverErrors: BackendError;
  showErrors = false;
  editable = true;
  assignors: CompanyBaseDto[];

  cessionVersionDP: SearchDataProvider<CessionVersionCriteriaDto, CessionVersionBaseDto>;

  cessionTemplates: TemplateSimpleDto[];

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  constructor(
    public cessionVersionService: PolicyCessionVersionService,
    private templateService: TemplateService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    public routerService: RouterService,
    private router: Router,
    protected growlService: GrowlService,
    private appService: AppConfigService
  ) {
    super(growlService);

    this.selectorNameList = [RelatedObject.BENEFICIARY, RelatedObject.BUYER];
    this.initializeSelectorEmitters(true);
    this.initializeSearchCriteria();
    this.cessionVersionDP = BusinessUtils.createPolicyCessionDataProvider(cessionVersionService);
  }

  get mehib(): boolean {
    return this.appService.mehib;
  }

  ngOnInit(): void {
    console.log('Before initializing View');
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  updateVersionList() {
    console.log('Cession - Update version list');
    if (this.cessionVersion.cession.id) {
      this.cessionVersionDP.searchCriteria.criteria.cession.id = this.cessionVersion.cession.id;
      if (this.versionList) {
        this.versionList.search();
      }
    }
  }

  initializeSearchCriteria() {
    this.assigneeCriteria = <CompanyCriteriaDto>{};
    this.assigneeCriteria.address = <AddressDto>{};
    this.assigneeTextCriteria = <TextSearchCriteria>{};
    this.buyerCriteria = <CompanyCriteriaDto>{};
    this.buyerCriteria.address = <AddressDto>{};
    this.buyerTextCriteria = <TextSearchCriteria>{};
  }

  initializeView(params: Params, force?: boolean) {
    const id: number = +params['id'];
    if (this.cessionVersion && this.cessionVersion.id === id && !force) {
      return;
    }
    console.log('START initializing View for id = ' + id);
    this.policyContractId = params['policyContractId'];
    this.initializeCession(id);
  }

  initializeCession(id: number) {
    if (id > 0) {
      this.cessionVersionService.getCessionVersion(id).subscribe({
        next: (cv) => this.onSetCessionVersion(cv),
        error: () => console.log('Error on getById (' + id + ')'),
      });
    } else {
      this.cessionVersionService.getInitialVersion(this.policyContractId).subscribe({
        next: (cv) => {
          console.log('onSetCessionForInitialVersion', cv);
          this.initialVersion = _.cloneDeep(cv);
          this.onSetCessionVersion(cv);
        },
        error: () => console.log('Error on getInitialVersion(' + this.policyContractId + ')'),
      });
    }
  }

  onSetCessionVersion(cessionVersion: CessionVersionDto) {
    this.cessionVersion = cessionVersion;
    this.handleButtons();
    this.updateVersionList();
    this.cessionVersionService
      .getAssignors(this.cessionVersion.cession.masterPolicyContract.id)
      .subscribe((assignors) => (this.assignors = assignors));
    this.initializeTemplates();
  }

  initializeTemplates() {
    if (!this.cessionTemplates) {
      this.cessionTemplates = [];
    }
    const dictionarySelectors: DictionaryBaseDto[] = [];
    dictionarySelectors.push(this.cessionVersion.cession.masterPolicyContract.productType);
    // for DocumentType.POLICY_CESSION documentType
    this.templateService
      .findByType(DocumentType.POLICY_CESSION, BusinessObjectType.CESSION_VERSION, null, dictionarySelectors)
      .subscribe((templates) => {
        if (templates?.length > 0) {
          for (let i = 0; i < templates.length; i++) {
            this.cessionTemplates.push(templates[i]);
          }
        }
      });
    // for DocumentType.POLICY_CESSION_ATTACHMENT documentType
    this.templateService
      .findByType(DocumentType.POLICY_CESSION_ATTACHMENT, BusinessObjectType.CESSION_VERSION, null, dictionarySelectors)
      .subscribe((templates) => {
        if (templates?.length > 0) {
          for (let i = 0; i < templates.length; i++) {
            this.cessionTemplates.push(templates[i]);
          }
        }
      });
  }

  onSelectAssignee(assignee: CompanySimpleDto) {
    if (assignee) {
      this.cessionVersion.cession.assignee = assignee;
    }
  }

  onSelectBuyer(buyer: CompanyBaseDto) {
    if (buyer) {
      if (this.cessionVersion.buyers.map((lb) => lb.id).includes(buyer.id)) {
        this.growlService.error('cession.message.buyerAlreadyOnTheList');
      } else {
        this.cessionVersion.buyers.push(buyer);
      }
    }
  }

  onSelectedType(type: DictionaryBaseDto) {
    if (type.id === CessionType.CHANGE_OF_THE_MAIN_INSURED) {
      const comment = this.cessionVersion.comment;
      const emptyVersion = _.cloneDeep(this.initialVersion);
      emptyVersion.comment = comment;
      emptyVersion.cession.type = type;
      this.cessionVersion = emptyVersion;
    }
  }

  onSave() {
    console.log('Saving...');
    this.save();
  }

  save() {
    this.showErrors = false;
    if (!this.form.valid) {
      this.showErrors = true;
      this.inProgress = false;
      this.showFormError();
      StringUtils.logFormInvalidFields(this.form);
      return;
    }
    this.serverErrors = null;

    this.cessionVersionService.save(this.cessionVersion).subscribe({
      next: (cv) => this.afterSave(cv),
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
        this.handleButtons();
      },
    });
    this.handleButtons();
  }

  afterSave(cessionVersion: CessionVersionDto) {
    this.onSetCessionVersion(cessionVersion);
    this.inProgress = false;
    this.showErrors = false;
    this.showSavedMsg();
    this.handleButtons();
    super.setWarnings(cessionVersion.warnings, cessionVersion.id);
    if (this.route.snapshot.params['id'] !== cessionVersion.id) {
      this.router.navigateByUrl(
        this.router.url.replace(
          'policy-cession-details/' + this.route.snapshot.params['id'],
          'policy-cession-details/' + cessionVersion.id
        ),
        {replaceUrl: true}
      );
    }
  }

  onCancel() {
    super.onCancel(this.route);
  }

  onDelete() {
    this.deleteCessionConfirmDialog.openAndExecuteOnConfirm(
      'cession.delete.confirmationDialog.title',
      'cession.delete.confirmationDialog.message',
      this.doDelete.bind(this)
    );
  }

  doDelete() {
    this.inProgress = true;
    this.showErrors = false;
    this.cessionVersionService.deleteCession(this.cessionVersion.cession.id).subscribe({
      next: (c) => this.afterDelete(c),
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      },
    });
  }

  afterDelete(cession: CessionDto) {
    this.inProgress = false;
    this.growlService.notice('cession.delete.success');
    this.routerService.toPolicyContractTechnicalDetails(this.cessionVersion.returnPolicyContractId);
  }

  onCreateNewVersion() {
    this.inProgress = true;
    super.onCreateNewVersion();
    this.cessionVersionService.getNewVersion(this.cessionVersion.cession.id).subscribe({
      next: (cv) => {
        this.onSetCessionVersion(cv);
      },
      error: () => (this.inProgress = false),
      complete: () => (this.inProgress = false),
    });
  }

  handleButtons() {
    this.deleteButton.disabled = !this.editable;
    this.deleteButton.title = 'Delete';
    this.hideButtons(false);
    this.newVersionButton.hidden = !this.cessionVersion || !this.cessionVersion.id;
    this.deleteButton.hidden =
      !this.cessionVersion ||
      !this.cessionVersion.id ||
      !this.canDeleteCession() ||
      (this.cessionVersionDP.lastSearchResult && this.cessionVersionDP.lastSearchResult.size > 1);
    this.saveButton.hidden = !this.cessionVersion || this.cessionVersion.status.id !== CessionStatus.NOT_ACTIVE;
    this.cancelButton.hidden = !this.cessionVersion || this.cessionVersion.status.id !== CessionStatus.NOT_ACTIVE;
  }

  disableButtons() {
    this.saveButton.disabled = true;
    this.deleteButton.disabled = true;
    this.cancelButton.disabled = true;
  }

  previewLink(): boolean {
    return this.cessionVersion && this.cessionVersion.id > 0;
  }

  backToServiceDetails() {
    this.routerService.toPolicyContractTechnicalDetails(
      this.policyContractId || this.cessionVersion.returnPolicyContractId
    );
  }

  onSelectCessionVersion(cessionVersion: CessionVersionBaseDto) {
    this.cessionVersionService.getCessionVersion(cessionVersion.id).subscribe((cv) => {
      this.onSetCessionVersion(cv);
      this.routerService.toCessionDetails(cessionVersion.id);
    });
  }

  get cessionFieldsDisabled() {
    return this.cessionVersion.status.id !== CessionStatus.NOT_ACTIVE;
  }

  toBuyersImporter() {
    this.routerService.toImporterWithParams({
      typeId: ImportType.CESSION_BUYERS,
      cessionVersionId: this.cessionVersion.id,
    });
  }

  canDeleteCession() {
    return this.cessionVersion.status.id === CessionStatus.NOT_ACTIVE && this.cessionVersion.validFrom > new Date();
  }

  get showFullDetails(): boolean {
    return (
      this.cessionVersion &&
      this.cessionVersion.cession.type &&
      this.cessionVersion.cession.type.id &&
      this.cessionVersion.cession.type.id !== CessionType.CHANGE_OF_THE_MAIN_INSURED
    );
  }

  showBuyers(): boolean {
    return (
      this.cessionVersion &&
      this.showFullDetails &&
      !PolicyContractTypes.MEHIB_MLT.includes(this.cessionVersion.cession.masterPolicyContract.productType.id)
    );
  }

  generateDocumentsStandard(template: TemplateSimpleDto) {
    this.clearErrors();
    this.documentService.generatePdf(
      template.id,
      this.cessionVersion.id,
      template.name + ' ' + this.cessionVersion.cession.id + '.pdf',
      false,
      (errors) => {
        this.handleServerError(JSON.parse(errors), false);
      }
    );
  }

  generateDocumentsCustom(template: TemplateSimpleDto) {
    this.routerService.toDocumentDetailsNew(template.id, this.cessionVersion.id);
  }
}
