import {Component} from '@angular/core';
import {AppConfigService} from '../../../../bonding_shared/services';
import {Section} from '../sections/section';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'policy-limit-lists-settings-section',
  templateUrl: './policy-limit-lists-settings-section.component.pug',
})
export class PolicyLimitListSettingsSectionComponent extends Section {
  self = this;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected appService: AppConfigService,
    protected translateService: TranslateService
  ) {
    super(policyContractViewService, appService);
  }
}
