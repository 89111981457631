import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RepositoryDocumentListComponent} from './repository-document-list.component';
import {SharedComponentsModule} from '../shared-components.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule, TranslateModule],
  declarations: [RepositoryDocumentListComponent],
  exports: [RepositoryDocumentListComponent],
})
export class RepositoryDocumentListModule {}
