import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrokerGuiService} from './services/broker-gui.service';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {CompanySharedModule} from '../../bonding/company/components/shared/company-shared.module';
import {CompanyQuotationDetailsComponent} from './components/quotation-details/company-quotation-details.component';
import {QuotationSearchComponent} from './components/quotation-search/quotation-search.component';
import {BondTypeListOnQuotationDetailsComponent} from './components/quotation-details/components/bond-type-list-on-quotation-details.component';
import {BrokerPortfolioComponent} from './components/portfolio/broker-portfolio.component';
import {ContractVersionPreviewOnBrokerPortfolioComponent} from './components/portfolio/components/contract-version-preview-on-broker-portfolio-component';
import {BondSharedModule} from '../../bonding/bond/components/shared/bond-shared.module';
import {QuotationContactPersonComponent} from './components/quotation-details/components/quotation-contact-person.component';
import {CompanyExtendedSelectorModule} from '../../bonding/company/components/shared/extended-selectors/company-extended-selector.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BankQuotationDetailsComponent} from './components/quotation-details/bank-quotation-details.component';
import {QuotationContractDataComponent} from './components/quotation-details/components/quotation-contract-data.component';
import {BankQuotationContractDataComponent} from './components/quotation-details/components/bank-quotation-contract-data.component';
import {TariffCalculatedDirective} from './components/quotation-details/components/validators/directives/tariff-calculated.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    CompanySharedModule,
    BondSharedModule,
    CompanyExtendedSelectorModule,
    BsDropdownModule,
  ],
  declarations: [
    CompanyQuotationDetailsComponent,
    QuotationContractDataComponent,
    BankQuotationContractDataComponent,
    QuotationSearchComponent,
    BankQuotationDetailsComponent,
    BondTypeListOnQuotationDetailsComponent,
    BrokerPortfolioComponent,
    ContractVersionPreviewOnBrokerPortfolioComponent,
    QuotationContactPersonComponent,
    TariffCalculatedDirective,
  ],
  exports: [
    CompanyQuotationDetailsComponent,
    QuotationContractDataComponent,
    BankQuotationContractDataComponent,
    QuotationSearchComponent,
    BankQuotationDetailsComponent,
    BondTypeListOnQuotationDetailsComponent,
    BrokerPortfolioComponent,
    ContractVersionPreviewOnBrokerPortfolioComponent,
    QuotationContactPersonComponent,
    TariffCalculatedDirective,
  ],
  providers: [BrokerGuiService],
})
export class BrokerModule {}
