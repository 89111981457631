import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {
  PolicyMonitoringVersionDto,
  PolicyMonitoringVersionBaseDto,
  PolicyMonitoringVersionCriteriaDto,
  SearchResult,
} from '../model';

@Injectable()
export class PolicyMonitoringVersionService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'policyMonitoring';
  }

  getInitialVersion(policyContractId: number, typeId: number): Observable<PolicyMonitoringVersionDto> {
    console.log('policyContractId: ', policyContractId);
    return this.get<PolicyMonitoringVersionDto>(
      this.url + '/initial?policyContractId=' + policyContractId + '&typeId=' + typeId
    );
  }
}
