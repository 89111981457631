import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {LoggedUserService} from '../../../bonding_shared';
import {Subscription} from 'rxjs';

@Component({
  selector: 'session-timer',
  template: `
    <div style="color: white">
      <span>{{ 'topnav.sessionTimer' | translate }}</span
      >&nbsp;<span #time></span>
    </div>
  `,
})
export class SessionTimerComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'session-timer';

  @ViewChild('time') time: ElementRef<HTMLSpanElement>;

  @Output() timeUp = new EventEmitter<void>();

  public timerSubscription: Subscription;

  constructor(private loggedUserService: LoggedUserService, private zone: NgZone) {}

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.timerSubscription = this.loggedUserService.sessionTimer.sessionTimerObservable.subscribe(
        (remainingSeconds: number) => {
          this.time.nativeElement.innerText = '' + remainingSeconds;
          if (remainingSeconds < 1) {
            this.zone.run(() => {
              this.timeUp.emit();
            });
          }
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.timerSubscription.unsubscribe();
  }
}
