import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {PolicyElementaryRight} from '../../../bonding_shared/model/dictionary-ids';
import {AbstractGuard} from '../../../bonding_shared/components/abstract-guard';
import {LoggedUserService} from '../../../bonding_shared';

@Injectable()
export class ClientAmendmentDetailsGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router) {
    super(loggedUserService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkRight(state.url, PolicyElementaryRight.POLICY_DECLARATION_AMEND);
  }
}
