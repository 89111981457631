import {Component, Input} from '@angular/core';
import {ClaimSimpleDto, RouterService} from '../../../../bonding_shared';

@Component({
  selector: 'claim-navigation-link',
  templateUrl: './claim-navigation-link.component.pug',
})
export class ClaimNavigationLinkComponent {
  @Input()
  claim: ClaimSimpleDto;

  constructor(public router: RouterService) {}
}
