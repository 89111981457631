import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {
  PolicyContractBaseDto,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionSimpleDto,
  SearchResult,
} from '../../../../bonding_shared/model/dtos';
import {TextSearchCriteria} from '../../../../bonding_shared/components/search/model/text-search-criteria';
import {Subject} from 'rxjs';
import {PolicyContractVersionService} from '../../../../bonding_shared/services/policy-contract-version.service';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {AppConfigService} from '../../../../bonding_shared/services';

@Component({
  selector: 'policy-version-selector-simple',
  templateUrl: './policy-version-selector-simple.component.pug',
})
export class PolicyVersionSelectorSimpleComponent {
  /**
   *  flag on which depends whether or not links will be displayed
   *  by default the links are displayed
   */
  @Input() showLinks = true;

  @Input() title: string;
  @Input() labelKey: string;

  /**
   * Open selector event emitter
   * The same emitter instance should be injected into this component and the component that opens this selector
   */
  @Input() open: Subject<boolean>;

  /**
   * when fixedSearch = true, search is done by injected, predefined criteria, user cannot modify them
   */
  @Input() fixedSearch = false;

  /**
   * to be able to switch between advanced/basic search mode
   */
  _searchModeSwitchable = false;

  @Input() set searchModeSwitchable(value: boolean) {
    this._searchModeSwitchable = value;
  }

  get searchModeSwitchable(): boolean {
    // IKI 3305
    return this._searchModeSwitchable && !this.appService.kuke;
  }

  @Input() oneResultAutoSelection = false;

  @Input() set criteria(c: PolicyContractVersionCriteriaDto) {
    this.dataProvider.searchCriteria.criteria = c;
    if (!c.policyContract) {
      this.dataProvider.searchCriteria.criteria.policyContract = <PolicyContractBaseDto>{};
    }
  }

  @Input() set textCriteria(c: TextSearchCriteria) {
    this.dataProvider.textSearchCriteria = c;
  }

  @Input() set textSearch(ts: boolean) {
    console.log('set text search: ', ts);
    this.dataProvider.textSearch = ts;
  }

  @Input() showBU = true;
  @Input() showClient = true;
  @Input() showStatus = false;
  @Input() showBusinessStatus = true;
  @Input() showYear = true;
  @Input() pagination = true;

  @Output() selectItem = new EventEmitter<PolicyContractVersionSimpleDto>();
  @Output() emptySearchResult = new EventEmitter<boolean>();

  dataProvider: SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto>;

  constructor(
    public router: RouterService,
    private service: PolicyContractVersionService,
    private appService: AppConfigService
  ) {
    this.dataProvider = new SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto>(
      this.service
    );
    this.dataProvider.searchCriteria = BusinessUtils.createPolicyVersionSearchCriteria();
    this.dataProvider.textSearch = false;
  }

  searchFinished(result: SearchResult<PolicyContractVersionSimpleDto>) {
    if (result.size < 1) {
      this.emptySearchResult.emit(true);
    } else if (result.size === 1 && this.oneResultAutoSelection) {
      this.selectItem.emit(result.result[0]);
      this.open.next(false);
    }
  }
}
