import {Directive, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from './details-view';
import {CustomRevisionEntityDto} from '../../model';
import {TranslateService} from '@ngx-translate/core';
import {GrowlService, LoggedUserService, RouterService} from '../../services';
import {AbstractAuditedService} from '../../services/abstract-audited.service';
import {NgForm} from '@angular/forms';

@Directive()
export abstract class AuditedViewAbstract<T> extends DetailsView implements OnInit {
  id: number;
  revisions: CustomRevisionEntityDto[];
  currentRevision: number;
  private _current: T = this.getEmpty();
  get current(): T {
    return this._current;
  }

  set current(value: T) {
    this._current = value;
    this.onCurrentSet();
  }

  private _newVersion: boolean;
  get newVersion(): boolean {
    return this._newVersion;
  }

  set newVersion(value: boolean) {
    this._newVersion = value;
    this.saveButton.disabled = !value;
    this.newVersionButton.disabled = value;
  }

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  protected constructor(
    public _route: ActivatedRoute,
    protected auditedService: AbstractAuditedService<T>,
    public router: RouterService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected loggedUserService: LoggedUserService,
    protected idParamName = 'id'
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.newVersionButton.hidden = false;
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  // no new version is done, we want just to show user that save gonna leave mark in history
  onCreateNewVersion() {
    this.newVersion = true;
  }

  initializeView(params: Params) {
    super.initializeView(params);
    this.id = +params[this.idParamName];
    this.serverErrors = undefined;
    if (this.id) {
      this.initWithId();
    } else {
      this.initWithoutId();
    }
    this.extraInitialization();
  }

  initWithId() {
    this.getRevisions();
    this.load();
  }

  initWithoutId() {
    this.current = this.getEmpty();
    this.newVersion = true;
  }

  protected extraInitialization() {}

  protected beforeSave(after: () => void) {
    after();
  }

  onSave() {
    this.clearErrors();
    if (this.formValidates()) {
      this.beforeSave(() => {
        this.inProgress = true;
        this.auditedService.saveAudited(this.id, this.current).subscribe({
          next: (saved) => this.handleSaved(saved),
          error: (error) => this.handleServerError(error),
        });
      });
    }
  }

  protected handleSaved(saved: T) {
    if (saved['warnings'] && saved['warnings'].length) {
      this.inProgress = false;
      this.growlService.warning(saved['warnings'].map((e) => e.message).join('\n'));
    } else {
      this.afterObjectSaved(this.id, () => this.toDetailsRouterFunction(saved));
    }
    this.current = saved;
    if (this.id) {
      this.getRevisions();
    }
  }

  onCancel() {
    super.onCancel(this._route);
  }

  getRevisions() {
    this.auditedService.getRevisions(this.id).subscribe((revisions) => {
      this.revisions = revisions;
      this.newVersion = !revisions.length;
    });
  }

  onRevisionSelect(revision: CustomRevisionEntityDto) {
    if (Math.max(...this.revisions.map((value) => value.revisionId)) === revision.revisionId) {
      super.onCancel(this._route);
    } else {
      this.load(revision.revisionId);
    }
  }

  load(revisionId?: number) {
    this.auditedService.getFromRevision(this.id, revisionId).subscribe((x) => {
      this.current = x || this.getEmpty();
      this.currentRevision = revisionId;
      if (!this.newVersion) {
        this.newVersionButton.disabled = !this.lastVersionSelected();
      }
      this.onLoad();
    });
  }

  lastVersionSelected(): boolean {
    return !this.currentRevision;
  }

  protected getEmpty(): T {
    return <T>{};
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  onCurrentSet() {}
  onLoad() {}
  toDetailsRouterFunction(saved: T) {}
}
