import {Injectable} from '@angular/core';
import {AppConfigService, LoggedUserService} from '../../bonding_shared/services';

import {HttpClient} from '@angular/common/http';
import {AbstractService} from './index';
import {Observable} from 'rxjs';
import {DeclarationCriteriaDto, DeclarationFileImportDto} from '../model';
import {BinaryDownloaderService} from './index';
import {FilterItem} from '../../bonding/bond/components/shared/filterable-bond-version-list.component';

@Injectable()
export class DeclarationFileService extends AbstractService {
  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    protected binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'declarationFile';
  }

  downloadFile(
    fileId: number,
    input: boolean,
    outputFileName: string,
    errorCallback: (errMsg: string) => void,
    completeCallback: () => void
  ) {
    this.binaryDownloader.download(
      'GET',
      this.url + '/file/' + fileId + '?input=' + input,
      'application/json',
      'application/octet-stream',
      outputFileName,
      undefined,
      errorCallback,
      completeCallback
    );
  }

  generateLimitListFile(
    criteria: DeclarationCriteriaDto,
    listType: LimitListType,
    outputFileName: string,
    errorCallback: (errMsg: string) => void,
    completeCallback: (f: File) => void
  ) {
    let endpoint = '';
    switch (listType) {
      case LimitListType.AMENDMENT:
        endpoint = 'generateAmendmentFile';
        break;
      case LimitListType.DECLARATION:
        endpoint = 'generateDeclarationFile';
        break;
      case LimitListType.ALL_LIMITS:
        endpoint = 'generateAllLimitsFile';
        break;
      default:
        endpoint = 'generateAllLimitsFile';
    }
    this.binaryDownloader.download(
      'POST',
      this.url + '/' + endpoint + '/',
      'application/json',
      'application/octet-stream',
      outputFileName,
      criteria,
      errorCallback,
      completeCallback
    );
  }

  importTurnoverByFile(declaration: DeclarationFileImportDto): Observable<DeclarationFileImportDto> {
    return this.post<DeclarationFileImportDto>(declaration, this.url + '/importDeclarationByFile');
  }

  importOutstandingsByFile(declaration: DeclarationFileImportDto): Observable<DeclarationFileImportDto> {
    return this.post<DeclarationFileImportDto>(declaration, this.url + '/importDeclarationByFile');
  }
}

export enum LimitListType {
  AMENDMENT,
  DECLARATION,
  ALL_LIMITS,
}
