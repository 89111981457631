<div class="bon-card" style="width: 100%">
  <div class="bon-fixed-header-no-block">
    <div class="bon-title">{{ 'sidebar.dashboard' | translate }} {{contractVersionNumber}}</div>
  </div>
  <div style='margin-top: 48px;'>
    <div *hasRight="'USER'">
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <div class="bon-row">
            <label class="bon-label" translate>dashboard.businessUnit</label>
            <business-unit-selector class="bon-input-size" [ngModelOptions] = "{standalone: true}" [(ngModel)]="selectedBusinessUnit"
                                    [userDefaultBU]="false" name="businessUnit" nullLabelKey="dashboard.allBusinessUnits"></business-unit-selector>
          </div>
        </div>
        <div class="bon-card-inner">
        </div>
        <div class="bon-card-inner">
        </div>
        <div class="bon-card-inner">
        </div>
      </div>
      <!--##################  Bonding/Default Dashboards ##########################3-->
      <div
        *ngIf="!settingsService.administrationViews && !settingsService.policyViews && !settingsService.limitViews && !settingsService.claimViews && !settingsService.salesViews">
        <div class="bon-card-group" style="padding: 0px;">
          <div class="bon-card-inner">
            <div class="bon-header" translate>dashboard.world</div>
            <bonding-map [mapType]="'world_en'" [businessUnit]="selectedBusinessUnit"></bonding-map>
          </div>
          <div class="bon-card-inner">
            <div class="bon-header" translate>dashboard.europe</div>
            <bonding-map [mapType]="'europe_en'" [businessUnit]="selectedBusinessUnit"></bonding-map>
          </div>
        </div>
        <div class="bon-card-group" style="padding: 0px;">
          <div class="bon-card-inner">
            <div class="bon-header">Top 10 Bond performance (count)</div>
            <pie-chart [businessUnit]="selectedBusinessUnit"></pie-chart>
          </div>
          <div class="bon-card-inner">
            <div class="bon-header">Top 10 Bond performance (value)</div>
            <donut-chart [businessUnit]="selectedBusinessUnit"></donut-chart>
          </div>
        </div>
        <div class="bon-card-group" style="padding: 0px;">
          <div class="bon-card-inner">
            <div class="bon-header" translate>dashboard.history</div>
            <time-chart [businessUnit]="selectedBusinessUnit"></time-chart>
          </div>
        </div>
      </div>

      <!--##################  Policy Dashboards ##########################3-->
      <div *ngIf="settingsService.policyViews">
        <div class="bon-card-group" style="padding: 0px;">
          <div class="bon-card-inner">
            <div class="bon-header" translate>dashboard.europe</div>
            <policy-map [mapType]="'europe_en'" [businessUnit]="selectedBusinessUnit"></policy-map>
          </div>
          <div class="bon-card-inner">
            <div class="bon-header" translate>policyDashboard.policyPerformanceValue</div>
            <donut-chart [businessUnit]="selectedBusinessUnit"></donut-chart>
          </div>
        </div>
        <div class="bon-card-group" style="padding: 0px;">
          <div class="bon-card-inner">
            <div class="bon-header" translate>dashboard.history</div>
            <time-chart [businessUnit]="selectedBusinessUnit"></time-chart>
          </div>
        </div>
      </div>

      <!--##################  Claim Dashboards ##########################3-->
      <div *ngIf="settingsService.claimViews">
        <div class="bon-card-group" style="padding: 0px;">
          <div class="bon-card-inner">
            <div class="bon-header" translate>dashboard.europe</div>
            <claim-map [mapType]="'europe_en'" [businessUnit]="selectedBusinessUnit"></claim-map>
          </div>
          <div class="bon-card-inner">
            <div class="bon-header" translate>claimsDashboard.claimsPerformance</div>
            <donut-chart [businessUnit]="selectedBusinessUnit"></donut-chart>
          </div>
        </div>
        <div class="bon-card-group" style="padding: 0px;">
          <div class="bon-card-inner">
            <div class="bon-header" translate>dashboard.history</div>
            <time-chart [businessUnit]="selectedBusinessUnit"></time-chart>
          </div>
        </div>
      </div>

      <!--##################  Limit Dashboards ##########################3-->
      <div *ngIf="settingsService.limitViews">
        <div class="bon-card-group" style="padding: 0px;">
          <div class="bon-card-inner">
            <div class="bon-header" translate>dashboard.world</div>
            <limit-map [mapType]="'world_en'" [businessUnit]="selectedBusinessUnit"></limit-map>
          </div>
          <div class="bon-card-inner">
            <div class="bon-header" translate>dashboard.europe</div>
            <limit-map [mapType]="'europe_en'" [businessUnit]="selectedBusinessUnit"></limit-map>
          </div>
        </div>

        <div class="bon-card-group" style="padding: 0px;">
          <div class="bon-card-inner">
            <div class="bon-header" translate>limitDashboard.limitExposure</div>
            <pie-chart-limit [businessUnit]="selectedBusinessUnit"></pie-chart-limit>
          </div>
          <div class="bon-card-inner">
            <div class="bon-header" translate>limitDashboard.ratingLimit</div>
            <rating-limit [businessUnit]="selectedBusinessUnit"></rating-limit>
          </div>
        </div>
        <div class="bon-card-group" style="padding: 0px;">
          <div class="bon-card-inner">
            <div class="bon-header" translate>dashboard.history</div>
            <time-chart [businessUnit]="selectedBusinessUnit"></time-chart>
          </div>
        </div>
      </div>
    </div>

    <!--##################  Broker Dashboards ##########################3-->
    <div *hasRight="'QUOTATIONS'">
      <div *ngIf="settingsService.extranetViews">
        <broker-dashboard></broker-dashboard>
      </div>
    </div>

    <!--##################  Client Dashboards ##########################3-->
    <div *hasRight="'CLIENT_BONDS'">
      <ng-container *ngIf="settingsService.extranetViews">
      <div class="bon-card-group" style="padding: 0px;" [hidden]="!logoLoaded">
        <div class="bon-card-inner">
          <div class="bon-header" [ngSwitch]="brokerType">
            <ng-container *ngSwitchCase="102000003">{{'clientDashboard.yourBroker' | translate}}</ng-container>
            <ng-container *ngSwitchCase="102000005">{{'clientDashboard.yourBank' | translate}}</ng-container>
            <ng-container *ngSwitchCase="102000006">{{'clientDashboard.yourSavingBank' | translate}}</ng-container>
          </div>
          <div style="padding-left: 20px;">
            <img src="{{logoURL}}" width="300" (load)="onLogoLoaded()"/>
            <div *ngIf="brokerWebPage" style="padding-top: 20px;">
              <a [href]="brokerWebPage | url" target="_blank">{{brokerWebPage}}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="bon-card-group" style="padding: 0px;">
        <div class="bon-card-inner">
          <div class="bon-header" [translate]="isIndividualContract && 'clientDashboard.limitIn' || 'clientDashboard.line'"
               [translateParams]="isIndividualContract && {currency: contractCurrencyCode}"></div>
          <pie-chart-contract *ngIf="contractExposures" [contractExposures]="contractExposures"></pie-chart-contract>
        </div>
        <div class="bon-card-inner" style="width:66%">
          <div class="bon-header">
            <span translate="clientDashboard.bondTypeCapacity"></span>
            <hover-info [message]="isIndividualContract && 'clientDashboard.infoMessage.clickOnBarToCreateANewBondRequest' || 'clientDashboard.infoMessage.clickOnBarToCreateATemplate'"></hover-info>
          </div>
          <bar-chart-contract-free-capacity *ngIf="contractBondTypes" [contractBondTypes]="contractBondTypes"></bar-chart-contract-free-capacity>
        </div>
      </div>
      </ng-container>
    </div>

    <!--##################  Sales Dashboards ##########################3-->
    <ng-container *ngIf="settingsService.salesViews">
      <div class="bon-card-group" style="padding: 0px;">
        <div class="bon-card-inner"> <!-- todoac tlumaczenia -->
          <div class="bon-header">{{'Leads' | translate}}</div>
          <time-chart [businessUnit]="selectedBusinessUnit" chartPath="leads"></time-chart>
        </div>
      </div>
      <div class="bon-card-group" style="width: 25%;">
        <div class="bon-card-inner">
          <div class="bon-row" *ngIf="salesLeadProducts">
            <label class="bon-label">Product line</label>
            <item-combo [(ngModel)]="selectedSalesLeadProduct" [items]="salesLeadProducts" label="name"
                        [ngModelOptions]="{standalone: true}" [nullLabel]="''" style="display: inline-block;">
            </item-combo>
          </div>
        </div>
      </div>
      <ng-container [ngSwitch]="selectedSalesLeadProduct?.code">
        <ng-container *ngSwitchCase="'BON'">
          <div class="bon-card-group" style="padding: 0px;">
            <div class="bon-card-inner">
              <div class="bon-header">{{'Bonds' | translate}}</div>
              <!--TODO: contractTypes is null so it is not shown; remove or refactor to use it (in time-chart too) -->
              <div class="bon-row" *ngIf="contractTypes" style="padding-left: 35px;">
                <label class="bon-label">Filter</label>
                <item-combo [(ngModel)]="selectedContractType" [items]="contractTypes" label="name"
                            [ngModelOptions]="{standalone: true}" [nullLabel]="''" style="display: inline-block;">
                </item-combo>
              </div>
              <time-chart [businessUnit]="selectedBusinessUnit" chartPath="bonds" [extraParams]="salesBondExtraParams()"></time-chart>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'POL'">
          <div class="bon-card-group" style="padding: 0px;">
            <div class="bon-card-inner">
              <div class="bon-header">{{'Credit insurance' | translate}}</div>
              <!--TODO: globalConditionsOfInsurances is null so it is not shown; remove or refactor to use it (in time-chart too) -->
              <div class="bon-row" *ngIf="globalConditionsOfInsurances" style="padding-left: 35px;">
                <label class="bon-label">Filter</label>
                <item-combo [(ngModel)]="selectedGlobalConditionsOfInsurance" [items]="globalConditionsOfInsurances" label="name"
                            [ngModelOptions]="{standalone: true}" [nullLabel]="''" style="display: inline-block;">
                </item-combo>
              </div>
              <time-chart [businessUnit]="selectedBusinessUnit" chartPath="creditInsurance" [extraParams]="salesPolicyExtraParams()"></time-chart>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

  </div>
</div>
