import {
  AppConfigService,
  BinaryDownloaderService,
  BondVersionDto,
  BondVersionService,
  CacheService,
  ClaimService,
  ContractService,
  DictionaryService,
  DocumentService,
  FeatureService,
  GrowlService,
  LoggedUserService,
  PropertyService,
  RouterService,
} from '../../bonding_shared';
import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {ClaimListComponent} from 'src/client/app/bonding/claim/components/shared/claim-list.component';
import {BondDetailsComponent} from './bond-details.component';
import {ClaimGuiService} from '../claim/services/claim-gui.service';
import {TranslateService} from '@ngx-translate/core';
import {BondGuiService} from './services';

@Component({
  selector: 'bond-linked-claims',
  templateUrl: 'bond-linked-claims.component.pug',
})
export class BondLinkedClaimsComponent extends BondDetailsComponent {
  @ViewChild('suretyOtherClaims') suretyOtherClaims: ClaimListComponent;

  constructor(
    documentService: DocumentService,
    _route: ActivatedRoute,
    bondDetailsService: BondGuiService,
    router: RouterService,
    dictService: DictionaryService,
    contractService: ContractService,
    growlService: GrowlService,
    binaryDownloader: BinaryDownloaderService,
    apiService: AppConfigService,
    bondService: BondVersionService,
    propertyService: PropertyService,
    cacheService: CacheService,
    loggedUserService: LoggedUserService,
    appService: AppConfigService,
    claimService: ClaimService,
    featureService: FeatureService,
    translateService: TranslateService
  ) {
    super(
      documentService,
      _route,
      bondDetailsService,
      router,
      dictService,
      contractService,
      growlService,
      binaryDownloader,
      apiService,
      bondService,
      propertyService,
      cacheService,
      loggedUserService,
      appService,
      claimService,
      featureService,
      translateService
    );
  }

  initializeView(params: Params) {
    this.clearErrors();
    this.getBondVersion(+params['bondVersionId']);
  }

  setBond(bondVersion: BondVersionDto) {
    super.setBond(bondVersion);
    setTimeout(() => {
      this.searchForSuretyClaims(bondVersion);
    });
  }

  private searchForSuretyClaims(bondVersion: BondVersionDto) {
    const provider = ClaimGuiService.createDataProvider(this.claimService);
    const criteria = provider.searchCriteria.criteria;
    criteria.bondId = bondVersion.bond.id;
    setTimeout(() => {
      this.suretyOtherClaims.dataProvider = provider;
    });
  }
}
