import {Component, OnInit} from '@angular/core';
import {
  BonusCalculationDto,
  BonusRequestDto,
  DictionaryBaseDto,
  MalusCalculationDto,
  MalusRequestDto,
  PolicyContractPreviewDto,
} from '../../../../bonding_shared/model';
import {
  AppConfigService,
  GrowlService,
  LoggedUserService,
  PolicyContractService,
  PropertyService,
  RouterService,
} from '../../../../bonding_shared/services';
import {ActivatedRoute, Params} from '@angular/router';
import {BonusMalusRequestService} from '../../../../bonding_shared/services/bonus-malus-request.service';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {AppProperty} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'bonus-malus-request-list',
  templateUrl: './bonus-malus-request-list.component.pug',
})
export class BonusMalusRequestListComponent<
    T extends MalusRequestDto | BonusRequestDto,
    C extends BonusCalculationDto | MalusCalculationDto
  >
  extends DetailsView
  implements OnInit
{
  data: PolicyContractPreviewDto;
  policyContractId: number;
  bonusMalusRequestClass: string;
  bonusMalusRequests: T[];
  selectedbonusMalusRequest: T;
  longLabel = false;
  bonusMalusRequestTypes: DictionaryBaseDto[] = [];

  appProperties: {[index: string]: any} = {};
  readonly AppProperty = AppProperty;

  constructor(
    public bonusMalusRequestService: BonusMalusRequestService<T, C>,
    private policyContractService: PolicyContractService,
    private route: ActivatedRoute,
    public router: RouterService,
    protected growlService: GrowlService,
    public loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    protected propertyService: PropertyService
  ) {
    super(growlService);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.initializeView(params));
    this.appProperties = this.propertyService.properties;
    this.bonusMalusRequestService.getBonusMalusRequestTypes(this.bonusMalusRequestClass).subscribe({
      next: (value) => {
        this.bonusMalusRequestTypes = value;
      },
      error: (error) => {
        this.serverErrors = error;
      },
    });
  }

  initializeView(params: Params) {
    this.bonusMalusRequestClass = params['className'];
    const masterPolicyId = params['masterPolicyId'];
    this.policyContractId = params['policyContractId'];
    this.policyContractService.getPreview(this.policyContractId).subscribe((res) => {
      this.data = res;
      this.bonusMalusRequestService
        .getAllRequests(this.bonusMalusRequestClass, this.data.masterPolicyId)
        .subscribe((requests) => {
          this.bonusMalusRequests = requests;
          if (this.bonusMalusRequests && this.bonusMalusRequests.length > 0) {
            this.selectedbonusMalusRequest = this.bonusMalusRequests[0];
          }
        });
    });
  }

  onRequestSelected(request: T) {
    if (!this.selectedbonusMalusRequest || !(this.selectedbonusMalusRequest.id === request.id)) {
      this.selectedbonusMalusRequest = request;
    }
  }

  openBonusMalusRequest(typeId: number) {
    this.router.toBonusMalusRequestDetailsTemplate(this.bonusMalusRequestClass, this.policyContractId, typeId);
  }

  isBonusRequest(className: string): boolean {
    return this.bonusMalusRequestService.isBonusRequest(className);
  }
}
