import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  AppConfigService,
  DictionaryBaseService,
  LoggedUserService,
  TemplateService,
} from '../../bonding_shared/services';
import {InquiryAbstractService} from '../../bonding_shared/services/inquiry-abstract.service';

@Injectable()
export class PortalInquiryService extends InquiryAbstractService {
  constructor(
    http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    protected templateService: TemplateService,
    protected dictionaryBaseService: DictionaryBaseService
  ) {
    super(http, appConfigService, loggedUserService, templateService, dictionaryBaseService);
    this.url = this.urlPrefix + this.portalPrefix + 'policyInquiryVersion';
  }
}
