/**
 * Created by siminski on 28.07.2016.
 */
import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {LoggedUserService} from '../services';
import {UserDto} from '../model';

@Directive({
  selector: '[hasRight]',
})
export class HasRightDirective {
  _hasRight: string;
  viewInitialized = false;

  @Input() set hasRight(hasRight: string) {
    if (hasRight && hasRight !== this._hasRight) {
      this.updateView(hasRight);
    }
  }

  private rights: string[] = [];

  constructor(
    private loggedUserService: LoggedUserService,
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<any>
  ) {
    const user: UserDto = loggedUserService.getLoggedUserData();
    for (const r of user.rights) {
      this.rights.push(r.code);
    }
  }

  updateView(hasRight: string) {
    if (this.viewInitialized) {
      this.viewContainer.clear();
    } else {
      this.viewInitialized = true;
    }
    const hasAnyRight = hasRight.split(',');
    if (this.rights.findIndex((r) => hasAnyRight.includes(r)) !== -1) {
      this.viewContainer.createEmbeddedView(this.template);
    } else {
      this.viewContainer.clear();
    }
  }
}
