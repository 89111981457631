import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {ContractSharedModule} from '../bonding-contract/contract/components/shared/contract-shared.module';
import {DocumentModule} from '../document/document.module';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {BusinessObjectInfoModule} from '../business-object-info/business-object-info.module';
import {ServiceNotesComponent} from './service-notes.component';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {ServiceNoteService} from './service-note.service';
import {ServiceNotesGuard} from './_guards/service-notes.guard';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    DocumentModule,
    ContractSharedModule,
    BusinessObjectInfoModule,
    PaginationModule,
  ],
  declarations: [ServiceNotesComponent],
  exports: [ServiceNotesComponent],
  providers: [ServiceNoteService, ServiceNotesGuard],
})
export class ServiceNotesModule {}
