<b-table [items]="bond.customFields" [editable]="true" [formModel]="bondForm" [deleteButton]="false" [showAllErrors]="showErrors"
         groupName="customFieldsForm" [archetypeEntries]="customFieldsEntries" *ngIf="originalSubtype || bond.subtype">
  <column property="fieldType" dictionary="BondCustomFieldType" parentDictionary="BondSubtype"
          [parentDictionaryEntryId]="originalSubtype ? originalSubtype.id : bond.subtype.id"
          type="archetype" unique cellWidth="45%" labelKey="bond.shared.customFields.fieldType"></column>
  <column property="value" type="custom" cellWidth="45%" labelKey="bond.shared.customFields.value">
    <ng-template let-item="item" let-edition="edition" let-control="formControlModel">
      <div [ngSwitch]="fieldTemplate(item)">
        <ng-container *ngSwitchCase="'TEXT_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <input style="width: 100%" class="bon-table-input" type="text" [ngModel]="item.value" (input)="item.value = $event.target.value" [formControl]="control" [required]="isRequired(item)">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'NUMBER_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <num-input [ngModel]="item.value" [formControl]="control" [required]="isRequired(item)" (changeValue)="item.value = $event"></num-input>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'DATE_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <date-picker [ngModel]="toDate(item.value)" [formControl]="control" [required]="isRequired(item)" (changeAsString)="item.value = $event"></date-picker>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'DISABLED_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <input style="width: 100%" class="bon-table-input" type="text" [ngModel]="item.value" (input)="item.value = $event.target.value" disabled="disabled">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'BOOLEAN_SI_NO_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <string-combo
              class="bon-input-size" [ngModel]="item.value" [items]='italianBooleanItems'
              (changeItem)="item.value = $event" [formControl]="control" [required]="isRequired(item)"></string-combo>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'ISCRITTO_ALBO_SI_NO_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <string-combo
              class="bon-input-size" [ngModel]="item.value" [items]='italianBooleanItems'
              (changeItem)="item.value = $event" (change)="iscrizioneAlboChange($event)" [formControl]="control" [required]="isRequired(item)"></string-combo>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'ISCRITTO_REGISTRO_IMPRESE_SI_NO_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <string-combo
              class="bon-input-size" [ngModel]="item.value" [items]='italianBooleanItems'
              (changeItem)="item.value = $event" (change)="iscrizioneRegistroChange($event)" [formControl]="control" [required]="isRequired(item)"></string-combo>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'PERIODO_DICHIARAZIONE_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <string-combo
              class="bon-input-size" [ngModel]="item.value" [items]='periodoDichiarazioneItems' (changeItem)="item.value = $event" [formControl]="control" [required]="isRequired(item)">
            </string-combo>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'TIPOLOGIA_RIMBORSO_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <string-combo class="bon-input-size" [ngModel]="item.value" [items]='tipologiaRimborsoItems' (changeItem)="item.value = $event"
              (change)="tipologiaRimborsoChange($event)" [formControl]="control" [required]="isRequired(item)">
            </string-combo>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'DATE_PRESENTAZIONE_DICHIARAZIONE_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <date-picker *ngIf="isTipologiaAnnuale() && item.value !== NOT_REQUESTED" [ngModel]="toDate(item.value)"
              (changeItem)="item.value = $event" (changeAsString)="item.value = $event" [formControl]="control" [required]="isRequired(item)">
            </date-picker>
            <input *ngIf="!isTipologiaAnnuale()" type="text" [(ngModel)]="item.value"
              style="width: 100%" class="bon-table-input" (input)="control.patchValue($event)" disabled="disabled">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'YEAR_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <input style="width: 100%" class="bon-table-input" type="text" [ngModel]="item.value" (input)="item.value = $event.target.value" [formControl]="control" [required]="isRequired(item)">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'TIPOLOGIA_PROCEDURA_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <string-combo class="bon-input-size" [ngModel]="item.value" [items]="tipologiaProceduraItems"
                          (changeItem)="item.value = $event" (change)="tipologiaProceduraChange($event)" [formControl]="control" [required]="isRequired(item)">
            </string-combo>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'INTERESSI_SEMPLIFICATA_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <num-input *ngIf="isProdecuraSemplificata()" [ngModel]="item.value" (changeValue)="item.value = $event" [formControl]="control" [required]="isRequired(item)"></num-input>

            <input *ngIf="!isProdecuraSemplificata()" type="text" [(ngModel)]="item.value"
                   style="width: 100%" class="bon-table-input" (input)="control.patchValue($event)" disabled="disabled">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'INTERESSI_ORDINARIA_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <num-input *ngIf="!isProdecuraSemplificata()" [ngModel]="item.value" (changeValue)="item.value = $event" [formControl]="control" [required]="isRequired(item)"></num-input>
            <input *ngIf="isProdecuraSemplificata()" type="text" [(ngModel)]="item.value"
                   style="width: 100%" class="bon-table-input" (input)="control.patchValue($event)" disabled="disabled">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'PERIODO_RIMBORSO_INFRANNUALE_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <string-combo *ngIf="!isTipologiaAnnuale()" class="bon-input-size" [ngModel]="item.value" (change)="table.resetControls()"
                          [items]='periodoRimborsoInfrannuale' (changeItem)="item.value = $event" [formControl]="control" [required]="isRequired(item)"></string-combo>
            <input *ngIf="isTipologiaAnnuale()" type="text" [(ngModel)]="item.value"
                   style="width: 100%" class="bon-table-input" (input)="control.patchValue($event)" disabled="disabled">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'YEAR_INFRANNUALE_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <input *ngIf="!isTipologiaAnnuale()" style="width: 100%" class="bon-table-input" type="text" [ngModel]="item.value" (input)="item.value = $event.target.value" [formControl]="control" [required]="isRequired(item)">
            <input *ngIf="isTipologiaAnnuale()" type="text" [(ngModel)]="item.value"
                   style="width: 100%" class="bon-table-input" (input)="control.patchValue($event)" disabled="disabled">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'NUMBER_INFRANNUALE_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <num-input *ngIf="!isTipologiaAnnuale()" [ngModel]="item.value" (changeValue)="item.value = $event" [formControl]="control" [required]="isRequired(item)"></num-input>
            <input *ngIf="isTipologiaAnnuale()" type="text" [(ngModel)]="item.value"
                   style="width: 100%" class="bon-table-input" (input)="control.patchValue($event)" disabled="disabled">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'TEXTAREA_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <textarea-row class="required" [ngModel]="item.value" rows="2" (change)="item.value = $event"
                          [maxlength]="TEXTAREAMAXLENGTH" [control]="control" [formControl]="control" [required]="isRequired(item)"></textarea-row>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'TEXT_ISCRIZIONE_ALBO_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <input *ngIf="isIscrittoAlbo()" style="width: 100%" class="bon-table-input" type="text" [ngModel]="item.value" (input)="item.value = $event.target.value" [formControl]="control" [required]="isRequired(item)">
            <input *ngIf="!isIscrittoAlbo()" type="text" [(ngModel)]="item.value"
                   style="width: 100%" class="bon-table-input" (input)="control.patchValue($event)" disabled="disabled">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'TEXT_REGISTRO_IMPRESE_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <input *ngIf="isIscrittoRegistro()" style="width: 100%" class="bon-table-input" type="text" [ngModel]="item.value" (input)="item.value = $event.target.value" [formControl]="control" [required]="isRequired(item)">
            <input *ngIf="!isIscrittoRegistro()" type="text" [(ngModel)]="item.value"
                   style="width: 100%" class="bon-table-input" (input)="control.patchValue($event)" disabled="disabled">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'DATE_OF_AUCTION_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <date-picker [ngModel]="toDate(item.value)" (changeItem)="dateOfAuctionValidityChange($event)" [formControl]="control" [required]="isRequired(item)" (changeAsString)="item.value = $event"></date-picker>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'BE_PERFORMANCE_TYPE_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <string-combo
              class="bon-input-size" [ngModel]="item.value" [items]='bePerformanceTypeItems' (changeItem)="item.value = $event" [formControl]="control" [required]="isRequired(item)">
            </string-combo>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'BE_PERFORMANCE_RELEASE_DATE_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <string-combo
              class="bon-input-size" [ngModel]="item.value" [items]='bePerformanceReleaseDateItems' (changeItem)="item.value = $event" [formControl]="control" [required]="isRequired(item)">
            </string-combo>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'BE_PERFORMANCE_RELEASE_TYPE_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <string-combo
              class="bon-input-size" [ngModel]="item.value" [items]='bePerformanceReleaseTypeItems'
              (change)="bePerformanceReleaseTypeChange($event)"
              (changeItem)="item.value = $event" [formControl]="control" [required]="isRequired(item)">
            </string-combo>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'BE_PUBLIC_BENEFICIARY_Y_N'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <string-combo
              class="bon-input-size" [ngModel]="item.value" [items]='bePublicBeneficiaryYN' (changeItem)="item.value = $event" [formControl]="control" [required]="isRequired(item)">
            </string-combo>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'BE_PERFORMANCE_END_DATE_1_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <date-picker *ngIf="!isBePerformanceEndDate1Blocked() && item.value !== NOT_REQUESTED_EN" [ngModel]="toDate(item.value)"
                         (changeItem)="item.value = $event" (changeAsString)="item.value = $event" [formControl]="control" [required]="isRequired(item)">
            </date-picker>
            <input *ngIf="isBePerformanceEndDate1Blocked()" type="text" [(ngModel)]="item.value"
                   style="width: 100%" class="bon-table-input" (input)="control.patchValue($event)" disabled="disabled">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'BE_PERFORMANCE_END_DATE_2_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <date-picker *ngIf="!isBePerformanceEndDate2Blocked() && item.value !== NOT_REQUESTED_EN" [ngModel]="toDate(item.value)"
                         (changeItem)="item.value = $event" (changeAsString)="item.value = $event" [formControl]="control" [required]="isRequired(item)">
            </date-picker>
            <input *ngIf="isBePerformanceEndDate2Blocked()" type="text" [(ngModel)]="item.value"
                   style="width: 100%" class="bon-table-input" (input)="control.patchValue($event)" disabled="disabled">
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'TIMBER_PAYMENT_DAYS_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <string-combo
              class="bon-input-size" [ngModel]="item.value" [items]='timberPaymentDays'
              (changeItem)="onTimberPaymentDaysChange(item, $event)" [formControl]="control" [required]="isRequired(item)"></string-combo>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'DAMAGE_CLAUSE_T'">
          <span *ngIf="!edition; then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <num-input [ngModel]="item.value" [formControl]="control" [required]="isRequired(item)" (changeValue)="item.value = $event" [disabled]="damageClauseDisabled"></num-input>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'DAMAGE_CLAUSE_TYPE_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>
            <string-combo
              class="bon-input-size" [ngModel]="item.value" [items]='damageClauseTypes'
              [required]="isRequired(item)" presentationMode="true">
            </string-combo>
          </ng-template>
          <ng-template #edit_content>
            <string-combo
              class="bon-input-size" [ngModel]="item.value" [items]='damageClauseTypes'
              (changeItem)="item.value = $event" [formControl]="control" [required]="isRequired(item)"
              (change)="recalculateDamageClause(true)">
            </string-combo>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'TIMBER_LOT_COST_T'">
          <span *ngIf="!edition;then view_content else edit_content">here is ignored</span>
          <ng-template #view_content>{{item.value}}</ng-template>
          <ng-template #edit_content>
            <num-input [(ngModel)]="item.value" [formControl]="control" [required]="isRequired(item)"
                       (changeValue)="recalculateDamageClause(false)"></num-input>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span>{{item.value}}</span>
        </ng-container>
      </div>
    </ng-template>
  </column>
</b-table>
