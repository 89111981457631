<bon-section labelKey="invoiceItem.shared.link.contractLink" *ngIf="contractLink">
  <div class="bon-card-group">
    <div class="bon-card-inner">
      <input-row [readonly]="true" labelKey="invoiceItem.shared.link.contractType" [ngModel]="contractLink.linkType?.name" [ngModelOptions]="{standalone: true}"></input-row>
      <form-row labelKey="invoiceItem.shared.link.contractNumber">
        <ng-container *ngIf="contractLink.linkType?.code === 'contract'">
          <a *ngIf="bondingContractLink" (click)="toContract()" class="a-link">{{contractLink.contract?.number}}</a>
          <span *ngIf="!bondingContractLink">{{contractLink.contract?.number}}</span>
        </ng-container>
        <ng-container *ngIf="contractLink.linkType?.code === 'policyContract'">
          <a *ngIf="policyContractLink" (click)="toPolicyContract()" class="a-link">{{contractLink.policyContract?.number}}</a>
          <span *ngIf="!policyContractLink">{{contractLink.policyContract?.number}}</span>
        </ng-container>
        <ng-container *ngIf="contractLink.linkType?.code === 'brokerContract'">
          <a *ngIf="brokerContractLink" (click)="toBrokerContract()" class="a-link">{{contractLink.brokerContract?.number}}</a>
          <span *ngIf="!brokerContractLink">{{contractLink.brokerContract?.number}}</span>
        </ng-container>
        <ng-container *ngIf="contractLink.linkType?.code === 'policyInquiry'">
          <a *ngIf="policyInquiryLink" (click)="toPolicyInquiry()" class="a-link">{{contractLink.policyInquiry?.number}}</a>
          <span *ngIf="!policyInquiryLink">{{contractLink.policyInquiry?.number}}</span>
        </ng-container>
      </form-row>
    </div>
  </div>
</bon-section>
