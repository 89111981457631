import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'selectable-object-section',
  templateUrl: './selectable-object-section.component.pug',
})
export class SelectableObjectSectionComponent implements OnInit {
  @Input() title: string;
  @Input() labelKey: string;
  @Input() titleWidth = 'auto';
  @Input() iconsFloatRight = true;
  @Input() expandable: string | boolean = 'disabled';

  // TODO: change searchable to readyOnly
  @Input() searchable = true;
  @Input() canClose = true;
  @Input() clearable = false;
  @Input() invalid = false;
  @Input() errorMessage = 'errorMessage.required';

  @Input() openSelectorEmitter = new Subject<boolean>();
  @Output() clearObjectEmitter = new EventEmitter<boolean>();

  opened = false;

  ngOnInit() {
    if (this.openSelectorEmitter) {
      this.openSelectorEmitter.subscribe((event: boolean) => {
        this.opened = event;
      });
    }
  }

  onOpen() {
    console.log('onOpen');
    this.openSelectorEmitter.next(true);
    this.opened = true;
  }
  onClose() {
    console.log('onClose');
    this.openSelectorEmitter.next(false);
    this.opened = false;
  }
  onClear() {
    console.log('onClear');
    this.clearObjectEmitter.emit(false);
    this.opened = false;
  }
}
