import {Injectable} from '@angular/core';
import {TranslateFakeCompiler} from '@ngx-translate/core';

@Injectable()
export class CustomTranslateCompiler extends TranslateFakeCompiler {
  compileTranslations(translations: any, lang: string): any {
    return this.compileRecursively(translations, translations, [], lang);
  }

  private compileRecursively(translations: any, target: any, keyParts: string[], lang: string): any {
    if (target) {
      Object.keys(target).forEach((key) => {
        const value = target[key];
        const keyPartsWithLast = [...keyParts, key];
        if (typeof value === 'string') {
          if (value.startsWith('$')) {
            target[key] = this.getValue(
              translations,
              value.substring(1),
              new Set<string>([keyPartsWithLast.join('.')]),
              lang
            );
          }
        } else {
          this.compileRecursively(translations, value, keyPartsWithLast, lang);
        }
      });
    }
    return translations;
  }

  private getValue(translations: any, key: string, seenKeys: Set<string>, lang: string): string {
    if (seenKeys.has(key)) {
      console.error(`Circular reference for key '${key}' and lang '${lang}' detected during compilation`);
      return key;
    }
    let value = translations;
    for (const keyPart of key.split('.')) {
      if (value) {
        value = value[keyPart];
      } else {
        console.error(`No translation for key '${key}' and lang '${lang}' found during compilation`);
        return value;
      }
    }
    if (value?.startsWith('$')) {
      seenKeys.add(key);
      return this.getValue(translations, value.substring(1), seenKeys, lang);
    }
    return value;
  }
}
