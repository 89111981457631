import {ChartDataset} from 'chart.js';
import {ChartData} from './chart-data';
import {ChartUtils} from '../chart-utils';
import {TranslateService} from '@ngx-translate/core';

export class PolicyChartData extends ChartData {
  activePolicies: number[];
  newPolicies: number[];
  renewedPolicies: number[];
  premium: number[];
  newPremium: number[];

  constructor(private translateService: TranslateService) {
    super();
  }

  colors = [
    {backgroundColor: ChartUtils.COLORS[0]},
    {backgroundColor: ChartUtils.COLORS[1]},
    {backgroundColor: ChartUtils.COLORS[3]},
    {backgroundColor: ChartUtils.COLORS[4]},
    {backgroundColor: ChartUtils.COLORS[2]},
  ];

  buildChartData(): ChartDataset[] {
    return [
      {
        data: this.newPolicies,
        label: this.translateService.instant('policyDashboard.newPolicies'),
        yAxisID: 'yAxisLeft',
      },
      {
        data: this.renewedPolicies,
        label: this.translateService.instant('policyDashboard.renewedPolicies'),
        yAxisID: 'yAxisLeft',
      },
      {
        data: this.premium,
        label: this.translateService.instant('policyDashboard.premium'),
        type: 'line',
        yAxisID: 'yAxisRight',
        fill: false,
        borderColor: ChartUtils.COLORS[3],
        pointBorderColor: ChartUtils.COLORS[3],
        pointBackgroundColor: ChartUtils.COLORS[3],
      },
      {
        data: this.newPremium,
        label: this.translateService.instant('policyDashboard.newPremium'),
        yAxisID: 'yAxisRight',
      },
      {
        data: this.activePolicies,
        label: this.translateService.instant('policyDashboard.activePolicies'),
        type: 'line',
        yAxisID: 'yAxisLeft',
        fill: false,
        borderColor: ChartUtils.COLORS[2],
        pointBorderColor: ChartUtils.COLORS[2],
        pointBackgroundColor: ChartUtils.COLORS[2],
      },
    ];
  }

  clear() {
    this.activePolicies = [];
    this.newPolicies = [];
    this.renewedPolicies = [];
    this.premium = [];
    this.newPremium = [];
    this.dates = [];
  }

  mapData(results: Array<any>) {
    if (!results) {
      return;
    }
    results.forEach((result) => {
      this.dates.push(result.month + '-' + result.year);
      this.activePolicies.push(result.activePolicies);
      this.newPolicies.push(result.newPolicies);
      this.renewedPolicies.push(result.renewedPolicies);
      this.premium.push(result.premium);
      if (result.newPremium) {
        this.newPremium.push(result.newPremium);
      } else {
        this.newPremium.push(0);
      }
    });
  }
}
