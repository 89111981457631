import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {LimitDecisionCriteriaDto, LimitDecisionDto} from '../../../../bonding_shared/model/dtos';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table/a-table.component';

@Component({
  selector: 'limit-decision-list',
  templateUrl: './limit-decision-list.component.pug',
})
export class LimitDecisionListComponent implements AfterViewInit {
  @Input() pageSize: number = 20;
  @Input() dataProvider: SearchDataProvider<LimitDecisionCriteriaDto, LimitDecisionDto>;

  @Input() showBuyer: boolean = true;
  @Input() showInsured: boolean = true;

  @ViewChild(ATableComponent, {static: true}) limitTable: ATableComponent<LimitDecisionDto>;

  constructor(public router: RouterService) {}

  ngAfterViewInit() {
    console.log('LimitDecisionListComponent::', this.dataProvider);
  }
}
