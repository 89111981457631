import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService, AppConfigService, LoggedUserService} from '../../bonding_shared/services';
import {Observable} from 'rxjs';
import {UserDto} from '../../bonding_shared';

@Injectable()
export class PortalSalesRepRelationVersionService extends AbstractService {
  protected url = this.urlPrefix + this.portalPrefix + 'salesRepRelationVersion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getSalesRepUsers(inquiryId: number): Observable<Array<UserDto>> {
    return this.get<Array<UserDto>>(this.url + '/salesRepUsers?inquiryId=' + inquiryId);
  }
}
