import {DocumentContentDto, DocumentDescriptionDto, DocumentDictionaryDto} from '../model';

export class FileUtils {
  static readDocumentFromFile(file: File, callback: (document: DocumentContentDto) => void) {
    const document = <DocumentContentDto>{
      clazz: '.DocumentContentDto',
      name: file.name,
      fileType: file.type,
      modificationDate: new Date(),
      documentType: <DocumentDictionaryDto>{},
      size: file.size,
    };

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      document.content = e.target.result.split(',')[1];
      callback(document);
    };
  }

  static addDocuments(files: File[], targetDocuments: DocumentDescriptionDto[]) {
    if (!files) {
      return;
    }
    files.forEach((file) => {
      FileUtils.readDocumentFromFile(file, (document) => {
        targetDocuments.push(document);
      });
    });
  }
}
