import {Injectable} from '@angular/core';
import {AbstractAuditedService} from './abstract-audited.service';
import {PolicyDrawdownPaymentsDto} from '../model';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';

@Injectable()
export class PolicyDrawdownPaymentsService extends AbstractAuditedService<PolicyDrawdownPaymentsDto> {
  protected url = this.urlPrefix + 'masterPolicyContract/drawdowns/payments';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  initial(masterPolicyId: number): Observable<PolicyDrawdownPaymentsDto> {
    return this.get(this.url + '/initial?masterPolicyId=' + masterPolicyId);
  }

  saveAudited(id: number, dto: PolicyDrawdownPaymentsDto): Observable<PolicyDrawdownPaymentsDto> {
    return this.post(dto, this.url);
  }

  sendConfirmationTask(id: number) {
    return this.postEmpty(this.url + `/${id}/requestConfirmation`);
  }

  confirm(id: number) {
    return this.postEmpty(this.url + `/${id}/confirm`);
  }
}
