import {Component, OnInit} from '@angular/core';
import {DictionaryDto} from '../../../../../bonding_shared/model';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService, DictionaryService} from '../../../../../bonding_shared/services';
import {NumberUtils} from '../../../../../bonding_shared/utils/number-utils';
import {DictionaryPropertyType, EconomicCountryGroup} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'credendo-countries-section',
  templateUrl: './credendo-countries-section.component.pug',
})
export class CredendoCountriesSectionComponent extends Section implements OnInit {
  self = this;
  private countriesById: DictionaryDto[];

  turnoverSum: number;
  turnoverOECDSum: number;
  turnoverNonOECDSum: number;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected dictionaryService: DictionaryService
  ) {
    super(policyContractViewService, appService);
  }

  ngOnInit() {
    this.dictionaryService.getDictionary('Country').subscribe((data) => {
      this.countriesById = [];
      data.forEach((country) => (this.countriesById[country.id] = country));
      this.recalculateOECDToTurnoverSplit();
    });
  }

  public recalculateOECDToTurnoverSplit() {
    this.turnoverOECDSum = 0;
    this.turnoverNonOECDSum = 0;
    this.turnoverSum = 0;

    this.policy.insuredCountries.forEach((value) => {
      this.turnoverSum = this.turnoverSum + value.turnover;
      this.countriesById[value.country.id].properties[DictionaryPropertyType.COUNTRY_OECD] === 'true'
        ? (this.turnoverOECDSum = this.turnoverOECDSum + value.turnover)
        : (this.turnoverNonOECDSum = this.turnoverNonOECDSum + value.turnover);
    });

    this.policy.insuredCountryGroups.forEach((value) => {
      this.turnoverSum = this.turnoverSum + value.turnover;
      value.countryGroup.id === EconomicCountryGroup.OECD
        ? (this.turnoverOECDSum = this.turnoverOECDSum + value.turnover)
        : (this.turnoverNonOECDSum = this.turnoverNonOECDSum + value.turnover);
    });
  }

  percentOfTurnover(value: number) {
    return NumberUtils.roundMoney((value * 100) / this.turnoverSum);
  }
}
