import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SalesRepVersionSelectorComponent} from './sales-rep-version-selector.component';
import {SalesRepRelationContractListComponent} from './sales-rep-relation-contract-list.component';
import {SalesRepRelationPolicyListComponent} from './sales-rep-relation-policy-list.component';
import {SalesRepRelationInquiryListComponent} from './sales-rep-relation-inquiry-list.component';
import {SalesRepRelationBondingContractInquiryListComponent} from './sales-rep-relation-bonding-contract-inquiry-list.component';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
  declarations: [
    SalesRepRelationContractListComponent,
    SalesRepRelationPolicyListComponent,
    SalesRepVersionSelectorComponent,
    SalesRepRelationInquiryListComponent,
    SalesRepRelationBondingContractInquiryListComponent,
  ],
  exports: [
    SalesRepRelationContractListComponent,
    SalesRepRelationPolicyListComponent,
    SalesRepVersionSelectorComponent,
    SalesRepRelationInquiryListComponent,
    SalesRepRelationBondingContractInquiryListComponent,
  ],
})
export class SalesRepSharedModule {}
