import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[dateNotInFuture]',
  providers: [{provide: NG_VALIDATORS, useExisting: DateNotInFutureDirective, multi: true}],
})
export class DateNotInFutureDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const date: Date = <Date>control.value;
    if (typeof date !== 'undefined' && date !== null) {
      return new Date().valueOf() - date.valueOf() >= 0 ? null : {futureDate: true};
    }
    return null;
  }
}
