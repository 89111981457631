import {map} from 'rxjs/operators';
import {DictionaryBaseDto, PolicyContractVersionIdDto} from '../../../../bonding_shared/model/dtos';
import {DashboardService} from '../../../../bonding_shared/services/dashboard.service';
import {ChartComponent} from '../chart-component';
import {BusinessPropertiesService} from '../../../../bonding_shared/services/business-properties.service';
import {ChartRestLoader} from '../chart-rest-loader';
import {OnInit, Directive, Input, AfterViewInit} from '@angular/core';
import {SettingsService} from '../../../../bonding_shared/services';

@Directive()
export abstract class VMap extends ChartComponent implements ChartRestLoader, OnInit, AfterViewInit {
  private _businessUnit: DictionaryBaseDto;
  private _policyVersion: PolicyContractVersionIdDto;
  @Input() mapType: string;
  @Input() hoverColor = '#239023';
  @Input() selectedColor = '#145214';
  @Input() startColorRGB = [200, 238, 255];
  @Input() endColorRGB = [0, 100, 145];

  dafaultColor = '#CCCCCC';
  colors: ChartColors;
  countryPerformance: any = {};
  initialized = false;
  vmap: any;

  protected constructor(
    public service: DashboardService,
    public businessProps: BusinessPropertiesService,
    public settingsService: SettingsService) {
      super(businessProps, settingsService);
  }

  @Input() set businessUnit(businessUnit: DictionaryBaseDto) {
    this._businessUnit = businessUnit;
    if (this.initialized) {
      this.reload();
    }
  }

  @Input() set policyVersion(policyVersion: PolicyContractVersionIdDto) {
    this._policyVersion = policyVersion;
    if (this.initialized) {
      this.reload();
    }
  }

  ngAfterViewInit(): void {
    this.reload();
  }

  getPerformanceValues(countryData: any): any {
    return null;
  }

  calculateColors(countryData: {[key: string]: number}): ChartColors {
    let max = 0,
      min = Number.MAX_VALUE,
      cc: string,
      hex: string;

    const startColor = this.startColorRGB,
      endColor = this.endColorRGB,
      colors: ChartColors = {};

    for (cc in countryData) {
      if (cc) {
        if (countryData[cc] > max) {
          max = countryData[cc];
        }
        if (countryData[cc] < min) {
          min = countryData[cc];
        }
      }
    }

    for (cc in countryData) {
      if (countryData[cc] > 0) {
        colors[cc] = '#';
        for (let i = 0; i < 3; i++) {
          hex = Math.abs(
            Math.round(startColor[i] + (endColor[i] - startColor[i]) * (countryData[cc] / (max - min)))
          ).toString(16);
          if (hex.length === 1) {
            hex = '0' + hex;
          }
          colors[cc] += (hex.length === 1 ? '0' : '') + hex;
        }
      }
    }
    return colors;
  }

  resetColors(colorsToReset: ChartColors): ChartColors {
    const colors: ChartColors = {};
    let cc: string;
    for (cc in colorsToReset) {
      if (cc) {
        colors[cc] = this.dafaultColor;
      }
    }
    return colors;
  }

  initializeMap(mapType: string, countryData: any, colors: ChartColors, currency: string) {
    this.vmap.vectorMap({
      map: mapType,
      backgroundColor: '#FFF',
      borderColor: '#D9D9D9',
      borderOpacity: 0.25,
      borderWidth: 1,
      color: this.dafaultColor,
      colors: colors,
      enableZoom: true,
      hoverColor: this.hoverColor,
      hoverOpacity: null,
      scaleColors: ['#C8EEFF', '#006491'],
      normalizeFunction: 'polynomial',
      selectedColor: this.selectedColor,
      selectedRegions: [],
      showTooltip: true,
      onLabelShow: this.onLabelShowFunction(countryData, currency)
    });
  }

  updateMap(resetColors: ChartColors, countryData: any, colors: ChartColors, currency: string) {
    this.vmap.vectorMap('set', 'colors', resetColors);
    this.vmap.vectorMap('set', 'colors', colors);
    this.vmap.bind('labelShow.jqvmap', this.onLabelShowFunction(countryData, currency));
  }

  loadRestData() {
    this.countryPerformance = {};
    this.service
      .getEuMapData(this._businessUnit, this.applicationModuleId, null, this._policyVersion)
      .pipe(
        map((results: Array<any>) => {
          if (results) {
            results.forEach(result => this.saveRestResult(result));
          }
        })
      )
      .subscribe(
        () => this.loadData(),
        error => console.log('Error occurred while getting Map data', error)
      );
  }

  saveRestResult(result: any) {
    console.log('saving results...');
  }

  loadData() {
    if (!this.vmap || this.vmap.length === 0) {
      this.vmap = $('#' + this.mapType);
    }
    const colorsReset = this.resetColors(this.colors);
    this.colors = this.calculateColors(this.getPerformanceValues(this.countryPerformance));
    if (!this.initialized) {
      this.initializeMap(this.mapType, this.countryPerformance, this.colors, this.currency);
    } else {
      this.updateMap(colorsReset, this.countryPerformance, this.colors, this.currency);
    }
    this.initialized = true;
  }

  private reload() {
    if (!this.currencyInitialized) {
      this.businessProps.getProperties().subscribe((props) => {
        this.currency = props.systemCurrency.code;
        this.currencyInitialized = true;
        this.loadRestData();
      });
    } else {
      this.loadRestData();
    }
  }
}

class ChartColors {
  [key: string]: string;
}


