import {Component} from '@angular/core';
import {ContractVersionCriteriaDto, ContractVersionDto} from '../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {ClientContractGuiService} from './services/client-contract-gui.service';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {AppConfigService} from '../../bonding_shared/services';
import {PortalRouterService} from '../services';

@Component({
  selector: 'client-contract-version-search',
  templateUrl: './client-contract-version-search.component.pug',
})
export class ClientContractVersionSearchComponent extends SearchView {
  dataProvider: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionDto>;

  constructor(
    public router: PortalRouterService,
    service: ClientContractGuiService,
    public appService: AppConfigService
  ) {
    super();
    this.dataProvider = service.searchDataProvider;
  }
}
