import * as moment from 'moment';
import {PeriodUnit} from '../model/dictionary-ids';
import {DictionaryBaseDto} from '../model/dtos';
import {LocalDateRange} from '../model';

export class DateRangeUtils {
  static daysBetween(dateFrom: Date, dateTo: Date): number {
    if (!dateFrom || !dateTo) {
      return undefined;
    }
    const from = moment(dateFrom).startOf('day');
    const to = moment(dateTo).startOf('day');
    return to.diff(from, 'days') + 1;
  }

  static calculatePeriodFactor(dateFrom: Date, dateTo: Date): number {
    if (!dateFrom || !dateTo) {
      return undefined;
    }
    const from = moment(dateFrom).startOf('day');
    const to = moment(dateTo).startOf('day');
    const daysInYear = from.isLeapYear() ? 366 : 365;
    const days = to.diff(from, 'days') + 1;
    return days / daysInYear;
  }

  static calculatePreviousPeriod(end: Date, period: DictionaryBaseDto): LocalDateRange {
    const dateRange = <LocalDateRange>{};
    switch (period.id) {
      case PeriodUnit.YEAR:
        return DateRangeUtils.calculatePreviousRange(end, 11);
      case PeriodUnit.QUARTER:
        return DateRangeUtils.calculatePreviousRange(end, 2);
      case PeriodUnit.MONTH:
        return DateRangeUtils.calculatePreviousRange(end, 0);
      case PeriodUnit.DAY:
        dateRange.dateFrom = end;
        dateRange.dateTo = end;
        break;
    }
    return dateRange;
  }

  private static calculatePreviousRange(dateTo: Date, factor: number): LocalDateRange {
    const dateFrom = new Date();
    if (dateTo.getMonth() > 0 && dateTo.getDate() < moment(dateTo).daysInMonth()) {
      dateTo.setMonth(dateTo.getMonth() - 1);
    }
    if (
      (dateTo.getMonth() === factor && dateTo.getDate() < moment(dateTo).daysInMonth()) ||
      dateTo.getMonth() < factor
    ) {
      dateTo.setFullYear(dateTo.getFullYear() - 1);
      dateTo.setMonth(11);
    }

    dateFrom.setFullYear(dateTo.getFullYear());
    dateFrom.setMonth(dateTo.getMonth() - factor);
    dateFrom.setDate(1);

    dateTo.setDate(moment(dateTo).daysInMonth());

    return <LocalDateRange>{dateFrom: dateFrom, dateTo: dateTo};
  }

  static calculatePeriodEnd(start: Date, period: DictionaryBaseDto): Date {
    let validTo;
    switch (period.id) {
      case PeriodUnit.YEAR:
        validTo = moment(start).add(1, 'year').toDate();
        break;
      case PeriodUnit.QUARTER:
        validTo = moment(start).add(3, 'month').toDate();
        break;
      case PeriodUnit.MONTH:
        validTo = moment(start).add(1, 'month').toDate();
        break;
      case PeriodUnit.DAY:
        validTo = moment(start).add(1, 'day').toDate();
        break;
    }

    validTo = moment(validTo).subtract(1, 'day').toDate();
    return validTo;
  }

  static calculateTotalAmount(yearlyAmount: number, dateFrom: Date, dateTo: Date): number {
    if (!dateFrom || !dateTo || !yearlyAmount) {
      return undefined;
    }
    const factor = this.calculatePeriodFactor(dateFrom, dateTo);
    return yearlyAmount * factor;
  }

  static calculateYearlyAmount(totalAmount: number, dateFrom: Date, dateTo: Date): number {
    if (!dateFrom || !dateTo || !totalAmount) {
      return undefined;
    }
    const factor = this.calculatePeriodFactor(dateFrom, dateTo);
    return totalAmount / factor;
  }
}
