import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {Subject} from 'rxjs';
import {
  CompanyCriteriaDto,
  CompanyExtendedSearchParamsDto,
  CompanyRawDto,
  CompanySimpleDto,
  DictionaryBaseDto,
  SearchResult,
  ThirdPartyBaseDto,
} from '../../../../../bonding_shared/model';
import {CompanySelectorSharedComponent} from './company-selector-shared.component';
import {BiService} from '../../../../../bonding_shared/services/bi.service';
import {RouterService} from '../../../../../bonding_shared/services/router-service';
import {AppConfigService, LoggedUserService} from '../../../../../bonding_shared/services';
import {CompanyElementaryRight, ElementaryRight} from '../../../../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';

export interface CompanySelectedEvent {
  company: CompanySimpleDto | CompanyRawDto;
  isRaw: boolean;
  thirdParty: ThirdPartyBaseDto;
}

@Component({
  selector: 'company-selector-extended',
  templateUrl: './company-selector-extended.component.pug',
})
/**
 * Component for complex searching in portal and intranet
 * 1. Portal searching:
 *    First step is DB search.
 *    If no result is found, then BI of first choice search is performed.
 *    If no result is found then BI of second choice search is performed etc.
 *    until something is found or no other BI's.
 *    BI sources are acquired based on choosen country.
 * 2. Intranet searching:
 *    Two mode of searching internal and external.
 *    Internal mode allows to search in  local db.
 *    External search allows to search in selected BI source.
 *    It is possible to switch between modes
 * Possible to enter data for unexisting company.
 */
export class CompanySelectorExtendedComponent implements AfterViewInit {
  @Input() titleKeys: string;
  @Input() companySearchInternalIdKey: string;
  @Input() open: Subject<boolean>;
  @Input() showErrors: boolean;
  @Input() closeable = true;
  @Input() initialCriteria = <CompanyCriteriaDto>{address: {}};
  @Input() showPersonalData = true;

  @Input() thirdParty: ThirdPartyBaseDto;
  @Input() initThirdParty = true;

  @Output() selectItem = new EventEmitter<CompanySelectedEvent>();
  @Output() createCompanyClicked = new EventEmitter<void>();

  @ViewChild(CompanySelectorSharedComponent, {static: true}) sharedComponent: CompanySelectorSharedComponent;

  @Input() fixedCountry = false;
  @Input() externalSearch = true;
  @Input() fixedFiltersVisibility = false;
  @Input() companyRawFormEnabled = false;
  @Input() companyCreationPossible = true;
  @Input() searchOnOpen = false;
  @Input() infoMsgKey: string;
  @Input() enableSearchViewError = false;
  @Input() newCompanyAllFields = false;

  orderReportMode = false;
  portalMode = false;
  possibleBiSources: DictionaryBaseDto[] = [];
  externalSearchAllowed = false; // allowed if internal search was already performed
  noMoreSourceToBeSearched = false;

  constructor(
    public biService: BiService,
    public router: RouterService,
    private appConfigService: AppConfigService,
    private translateService: TranslateService,
    private loggedUserService: LoggedUserService
  ) {
    this.portalMode = loggedUserService.portal;
  }

  ngAfterViewInit(): void {
    this.open.subscribe((o) => this.reset());
  }

  reset() {
    if (this.portalMode) {
      this.setSourceIdx(0);
      this.noMoreSourceToBeSearched = false;
    }
  }

  onSearchDone(result: SearchResult<CompanySimpleDto>) {
    this.clearErrors();
  }

  onSearchFinished(result: SearchResult<CompanySimpleDto>) {
    if (!this.portalMode) {
      const sc = this.sharedComponent.dataProvider.searchCriteria;
      this.externalSearchAllowed =
        sc && sc.criteria && sc.criteria.address.country && !sc.criteria.hasContract && !sc.criteria.hasPolicy;
    } else {
      const lastSearchSourceIdx = result ? (<CompanyExtendedSearchParamsDto>result.params).continuesSearchSourceIdx : 1;
      if (lastSearchSourceIdx || lastSearchSourceIdx === 0) {
        // set source idx for the next search
        this.setSourceIdx(lastSearchSourceIdx > 0 ? 0 : 1);
      }
      this.noMoreSourceToBeSearched = lastSearchSourceIdx && lastSearchSourceIdx > 0;
    }
  }

  // used only in portal
  setSourceIdx(idx: number) {
    this.sharedComponent.searchParams.continuesSearchSourceIdx = idx;
    this.sharedComponent.searchParams.biSourceId = undefined;
    this.refreshSearchTitle();
  }

  setSourceId(id: number) {
    this.sharedComponent.searchParams.continuesSearchSourceIdx = undefined;
    this.sharedComponent.searchParams.biSourceId = id;
  }

  onCriteriaChanged(event: any): void {
    this.criteriaChanged();
  }

  onCountryChanged(event: any): void {
    this.criteriaChanged();
    if (!this.portalMode) {
      this.loadBIsForCountry(false);
    }
    this.externalSearchAllowed = false;
  }

  criteriaChanged(): void {
    this.clearErrors();
    if (this.portalMode) {
      this.setSourceIdx(0);
    }
  }

  onPageChange(event: any): void {
    this.onCriteriaChanged(event);
  }

  // used only in portal
  refreshSearchTitle() {
    const sourceIdx = this.sharedComponent.searchParams.continuesSearchSourceIdx;
    this.sharedComponent.simpleSelector.searchButtonTitle = !sourceIdx
      ? this.initialSearchButtonTitle
      : 'common.button.searchExternal';
  }

  toExternalSearch() {
    if (this.externalSearchAllowed) {
      this.sharedComponent.simpleSelector.frontendErrorMsg = undefined;
      this.loadBIsForCountry(true);
      this.sharedComponent.simpleSelector.searchButtonTitle = 'common.button.searchExternal';
    } else {
      this.setLocalError('company.search.localSearchFirst');
    }
  }

  setLocalError(key: string) {
    this.sharedComponent.simpleSelector.frontendErrorMsg = this.translateService.instant(key);
  }
  setLocalErrorMsg(msg: string) {
    this.sharedComponent.simpleSelector.frontendErrorMsg = msg;
  }

  toInternalSearch() {
    this.clearErrors();
    this.setSourceId(undefined);
    this.sharedComponent.simpleSelector.searchButtonTitle = 'common.button.search';
  }

  clearErrors() {
    this.sharedComponent.simpleSelector.frontendErrorMsg = undefined;
    this.sharedComponent.simpleSelector.resultList.errorMessage = undefined;
  }

  private loadBIsForCountry(setSourceId: boolean) {
    if (this.sharedComponent.getCountry()) {
      this.biService.getPossibleBI(this.sharedComponent.getCountry().id).subscribe((bis) => {
        this.possibleBiSources = bis;
        if (bis.length === 0) {
          this.setLocalErrorMsg(
            'No business intelligence agency defined for ' + this.sharedComponent.getCountry().name
          );
        } else {
          this.setSourceId(setSourceId ? bis[0].id : undefined);
        }
      });
    }
  }

  onSelectItem(c: CompanySimpleDto | CompanyRawDto) {
    this.orderReportMode = false;
    this.sharedComponent.onSelectItem(c);
  }

  onNewCompany() {
    if (this.companyRawFormEnabled) {
      this.orderReportMode = true;
    } else {
      this.createCompanyClicked.emit();
    }
  }

  canCreateCompany(): boolean {
    return (
      ((this.portalMode && this.noMoreSourceToBeSearched) ||
        this.ecg ||
        (!this.portalMode && this.loggedUserService.hasRight(ElementaryRight.COMPANY_CREATE))) &&
      this.companyCreationPossible
    );
  }

  canSelectBISource(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_BI_SELECT);
  }

  get internal() {
    return (
      this.sharedComponent.searchParams &&
      this.sharedComponent.searchParams.continuesSearchSourceIdx === undefined &&
      this.sharedComponent.searchParams.biSourceId === undefined
    );
  }

  get internalFirst() {
    return (
      (this.sharedComponent.searchParams && this.sharedComponent.searchParams.continuesSearchSourceIdx === 0) ||
      this.internal
    );
  }

  get ecg(): boolean {
    return this.appConfigService.ecg;
  }

  get initialSearchButtonTitle() {
    return this.portalMode ? 'common.button.searchInternal' : 'common.button.search';
  }

  get pageSize() {
    return this.portalMode || this.sharedComponent.searchParams.biSourceId ? 100 : 10;
  }
}
