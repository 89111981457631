import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {GrowlService} from '../../../../bonding_shared/services';
import {
  PolicyContractSimpleIdDataDto,
  ReportingPeriodDto,
  DeclarationDto,
  DeclarationWithoutEntriesDto,
  LimitListVersionInDeclarationDto,
  CompanyInDeclarationDto,
} from '../../../../bonding_shared/model';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table';
import {DeclarationService} from '../../../../bonding_shared/services/declaration.service';
import * as moment from 'moment';

@Component({
  selector: 'declaration-history-section',
  templateUrl: './declaration-history-section.component.pug',
})
export class DeclarationHistorySectionComponent {
  @ViewChild(ATableComponent) declarationHistoryTable: ATableComponent<DeclarationDto>;

  constructor(protected growlService: GrowlService, private declarationService: DeclarationService) {}

  rpFilter: ReportingPeriodDto;
  llvFilter: LimitListVersionInDeclarationDto;
  declaredOnFilter: CompanyInDeclarationDto;

  @Input() selectionPossible = true;
  @Input() futureDeclarationsSelectable = false;
  @Input() showLlvFilter = false;
  @Input() showDeclaredOnFilter = false;

  @Input() onlyConfirmed = true;

  @Output() declarationSelect = new EventEmitter<DeclarationDto>();

  filterByReportingPeriod: true;
  filterByLimitListVersion: true;
  filterByDeclaredOn: true;
  filterOnlyDeclarations: false;
  filterOnlyAmendments: false;

  declarationHistoryList: DeclarationWithoutEntriesDto[] = [];
  filteredDeclarationHistoryList: DeclarationWithoutEntriesDto[] = [];

  private currentlySelectedItemId: number;

  public initializeOrReloadDeclarationHistory(
    policy: PolicyContractSimpleIdDataDto,
    rpFilter: ReportingPeriodDto,
    llVFilter: LimitListVersionInDeclarationDto,
    declaredOnFilter: CompanyInDeclarationDto,
    selectedDeclarationId?: number
  ) {
    if (policy) {
      this.declarationService.findDeclarationsForPolicy(policy.id, this.onlyConfirmed).subscribe(
        (declarations) => {
          this.declarationHistoryList = declarations;
          this.applyFilters(rpFilter, llVFilter, declaredOnFilter);
          if (selectedDeclarationId) {
            this.selectItem(selectedDeclarationId);
          }
        },
        () => {
          this.clearHistory();
          this.growlService.error('Error while getting declaration history for policy');
        }
      );
    } else {
      this.clearHistory();
    }
  }

  public selectItem(selectedItemId: number) {
    if (
      this.declarationHistoryTable &&
      selectedItemId &&
      this.filteredDeclarationHistoryList &&
      this.filteredDeclarationHistoryList.length > 1
    ) {
      this.declarationHistoryTable.selectedItemId = selectedItemId;
      this.currentlySelectedItemId = selectedItemId;
    }
  }

  public unSelect() {
    if (this.declarationHistoryTable) {
      this.declarationHistoryTable.selectedItemId = undefined;
      this.currentlySelectedItemId = undefined;
    }
  }

  onSelectDeclarationFromHistory(declaration: DeclarationDto) {
    if (this.selectionPossible) {
      const now = new Date();
      if (
        this.futureDeclarationsSelectable ||
        moment(declaration.reportingPeriod.periodTo).endOf('day').isBefore(now)
      ) {
        this.currentlySelectedItemId = declaration.id;
        this.declarationSelect.emit(declaration);
      } else {
        this.growlService.error('Future declaration cannot be selected!');
        if (this.currentlySelectedItemId) {
          this.selectItem(this.currentlySelectedItemId); // reset selection to previous
        } else {
          this.unSelect();
        }
      }
    }
  }

  public onFilterChange() {
    this.applyFilters(this.rpFilter, this.llvFilter, this.declaredOnFilter);
  }

  public applyFilters(
    rpFilter: ReportingPeriodDto,
    llvFilter: LimitListVersionInDeclarationDto,
    declaredOnFilter: CompanyInDeclarationDto
  ) {
    this.rpFilter = rpFilter;
    this.llvFilter = llvFilter;
    this.declaredOnFilter = declaredOnFilter;
    let tempDeclarations = this.declarationHistoryList;
    tempDeclarations = tempDeclarations.filter((t) => (this.filterOnlyDeclarations ? !t.amendment : true));
    tempDeclarations = tempDeclarations.filter((t) => (this.filterOnlyAmendments ? t.amendment : true));
    tempDeclarations = tempDeclarations.filter((t) =>
      rpFilter && this.filterByReportingPeriod && t.reportingPeriod ? t.reportingPeriod.id === rpFilter.id : true
    );
    tempDeclarations = tempDeclarations.filter((t) =>
      llvFilter && this.filterByLimitListVersion && t.limitListVersion ? t.limitListVersion.id === llvFilter.id : true
    );
    tempDeclarations = tempDeclarations.filter(
      (tempDec) =>
        this.filterByDeclaredOn
          ? this.declaredOnFilter // FILTER
            ? tempDec.declaredOn // THERE IS DECLARED ON FILTER (check if equal values or equally undefined)
              ? tempDec.declaredOn.id === declaredOnFilter.id // CHECK IF (DECLARED ON FILTER === DECLARATION DECLARED ON)
              : false // this.declaredOnFilter === !t.declaredOn
            : !tempDec.declaredOn // change to !t.declaredOn !this.declaredOnFilter === !t.declaredOn
          : true // DO NOT FILTER
    );
    this.filteredDeclarationHistoryList = tempDeclarations;
    this.currentlySelectedItemId =
      tempDeclarations.filter((tempDec) => tempDec.id === this.currentlySelectedItemId).length === 1
        ? this.currentlySelectedItemId
        : undefined;
  }

  public clearHistory() {
    this.declarationHistoryList = [];
    this.filteredDeclarationHistoryList = [];
  }
}
