import {DictionaryBaseDto, DictionaryDto} from './dtos';
import {TranslateService} from '@ngx-translate/core';
import {ElementaryRight} from './dictionary-ids';
import {LoggedUserService} from '../services';

export namespace ContextsRoles {
  export function contexts(translateService: TranslateService): CTX[] {
    const ctxs: CTX[] = [
      {code: 'PORTAL_CTX_POLICIES', label: '', value: false, accessRight: ElementaryRight.ACCESS_MODULE_POLICIES},
      {code: 'PORTAL_CTX_BONDING', label: '', value: false, accessRight: ElementaryRight.ACCESS_MODULE_BOND},
    ];
    translate(ctxs, translateService);
    return ctxs;
  }

  function translate(ctxs: CTX[], translateService: TranslateService) {
    const labelTrans = (c) => {
      const tr = translateService.instant('userContexts.enum.' + c.code);
      if (tr) {
        c.label = tr;
      }
    };
    ctxs.forEach(labelTrans);
  }

  function contextMap(contextsList: CTX[]): Map<UserContextName, CTX> {
    return new Map<UserContextName, CTX>(contextsList.map((x) => [x.code, x] as [UserContextName, CTX]));
  }

  export function updateAvailability(cxs: CTX[], userService: LoggedUserService): CTX[] {
    for (const cx of cxs) {
      cx.value = userService.hasRight(cx.accessRight);
    }
    return cxs;
  }

  export function contextToRoles(cxs: CTX[], rolesMap: Map<string, DictionaryDto>): DictionaryBaseDto[] {
    const roles = [];
    for (const cx of cxs) {
      if (cx.value) {
        const r = rolesMap.get(cx.code);
        if (r) {
          roles.push(r);
        }
      }
    }
    return roles;
  }

  const exclusionSet = new Set(['PORTAL_CTX_BROKER', 'PORTAL_CTX_INVOICE']);

  export function mutuallyExclusiveContexts(selectContext: ContextsRoles.CTX, cxs: CTX[]) {
    if (!selectContext || !cxs || !selectContext.value) {
      return;
    }

    const ex = selectContext.code;
    if (exclusionSet.has(ex)) {
      cxs.forEach((c) => {
        c.value = c.code === ex;
      });
    } else {
      cxs
        .filter((c) => exclusionSet.has(c.code))
        .forEach((c) => {
          c.value = false;
        });
    }
  }

  export function getContextByCode(defaultContextCode, translateService: TranslateService): CTX {
    return contextMap(contexts(translateService)).get(defaultContextCode);
  }

  export interface CTX {
    code: UserContextName;
    label: string;
    value: boolean;
    accessRight: ElementaryRight;
  }

  export type UserContextName = 'PORTAL_CTX_POLICIES' | 'PORTAL_CTX_BONDING';
}
