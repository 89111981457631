import {Observable, of as observableOf, zip as observableZip} from 'rxjs';
import {Component, Input} from '@angular/core';
import {ErrorReason} from '../../model/dtos';
import {StringUtils} from '../../utils/string-utils';
import {GrowlService} from '../../services/growl/growl.service';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {BackendError, isErrorReasons, isInternalError} from '../../model/backend-error';
import {FormatService} from '../../services';

@Component({
  selector: 'backend-error',
  templateUrl: './backend-error.component.pug',
})
export class BackendErrorComponent {
  @Input() objectName: string;
  @Input() modelObjectName: string;
  @Input() warningsTitle = 'errorMessage.warningTitle';
  @Input() errorsTitle = 'errorMessage.title';
  @Input() tempNoGrowl = false;
  @Input() serverErrorPrefix = 'Server error!';
  showGrowl = true;
  showTitle = true;
  serverError: string;

  public warnings: ErrorReason[];
  public _errors: ErrorReason[];

  constructor(
    private growlService: GrowlService,
    private translateService: TranslateService,
    private formatService: FormatService
  ) {}

  @Input() set noGrowlNoTitle(s: boolean) {
    this.showGrowl = s;
    this.showTitle = !s;
  }

  @Input()
  set errors(errors: BackendError) {
    this.handleErrors(errors);
    if (errors && this.showGrowl && !this.tempNoGrowl) {
      this.displayGrowl();
    }
  }

  handleErrors(errors: BackendError) {
    this._errors = [];
    this.warnings = [];
    this.serverError = undefined;
    if (errors) {
      console.log('Backend errors: ', errors);
      if (isErrorReasons(errors)) {
        for (const reason of errors) {
          if (!reason.silent) {
            reason.message = reason.message.replace(/\n/g, '<br>');
            if (reason.severity !== 'WARNING') {
              this._errors.push(reason);
            } else {
              this.warnings.push(reason);
            }
          }
        }
      } else if (isInternalError(errors)) {
        this.serverError = errors.formatted
          ? errors.message
          : this.formatService.formatDateTime(errors.date) +
            ', ' +
            this.formatService.formatDateTime(new Date()) +
            ', ID: ' +
            errors.uuid;
      } else {
        this.serverError = errors;
      }
    }
  }

  displayGrowl() {
    if (this.serverError || (this._errors && this._errors.length > 0)) {
      this.getGrowlMessage('Error').subscribe((message) => this.growlService.error(message));
    } else if (this.warnings && this.warnings.length > 0) {
      this.getGrowlMessage('Warning').subscribe((message) => this.growlService.warning(message));
    }
  }

  getGrowlMessage(type: 'Error' | 'Warning'): Observable<string> {
    if (this.serverError) {
      return observableOf(this.getServerErrorString());
    } else if (this.objectName) {
      // For pl conjugation is needed so it is used simpler type of translation
      const sufix = (objName) => {
        const isPl =
          this.translateService.currentLang === 'pl' ||
          (!this.translateService.currentLang && this.translateService.defaultLang === 'pl');
        if (isPl) {
          return '';
        }
        return ' ' + objName;
      };
      return observableZip(
        this.translateService.get(type + ' when saving'),
        this.translateService.get(this.objectName)
      ).pipe(map((arr) => arr[0] + sufix(arr[1]) + '!'));
    } else if (this.warnings && this.warnings.length > 0) {
      return observableOf(this.warnings[0].message);
    } else {
      return observableOf(this._errors[0].message);
    }
  }

  format(msg: string): string {
    if (msg) {
      if (this.modelObjectName) {
        const key = 'model.' + this.modelObjectName + '.' + msg;
        const fieldLabel = this.translateService.instant(key);

        if (fieldLabel !== key) {
          return fieldLabel + ': ';
        }
      }
      return this.translateService.instant(StringUtils.camelCaseToText(msg)) + ': ';
    } else {
      return '';
    }
  }

  getServerErrorString() {
    if (this.serverError) {
      if (typeof this.serverError === 'object') {
        const args: any = this.serverError;
        if (args.error) {
          return args.error;
        }
      }
    }
    return this.serverError;
  }
}
