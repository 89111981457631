import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {BrokerContractVersionService} from '../../../bonding_shared/services/broker-contract-version.service';
import {
  AddressDto,
  BrokerContractVersionCriteriaDto,
  BrokerContractVersionSimpleDto,
  BusinessObjectDto,
  CompanyCriteriaDto,
  LocalDateRange,
} from '../../../bonding_shared/model';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';

/**
 * Service for common logic of broker contracts components.
 *
 */

@Injectable()
export class BrokerContractGuiService {
  dataProvider: SearchDataProvider<BrokerContractVersionCriteriaDto, BrokerContractVersionSimpleDto>;

  constructor(private service: BrokerContractVersionService) {
    this.dataProvider = BusinessUtils.createBrokerContractVersionDataProvider(service);
    this.dataProvider.searchCriteria.criteria.company = <CompanyCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.company.address = <AddressDto>{};
    this.dataProvider.searchCriteria.criteria.validFrom = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.validTo = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.versionPhase = 'LAST';
    this.dataProvider.searchCriteria.criteria.businessObject = <BusinessObjectDto>{};
    this.dataProvider.textSearch = false;
  }
}
