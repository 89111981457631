import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  BondingContractInquiryClientDto,
  BondingContractInquiryVersionDto,
  BusinessObjectDto,
  CompanySimpleDto,
  ContactPersonCriteriaDto,
  ContactPersonDto,
  ContractVersionCriteriaDto,
  ContractVersionDto,
  DictionaryBaseDto,
  DictionaryDto,
  UserSimpleDto,
} from '../../../../bonding_shared/model';
import {ControlContainer, NgForm, NgModelGroup} from '@angular/forms';
import {GrowlService, RouterService, UserService} from '../../../../bonding_shared/services';
import {
  BondingContractInquiryStatus,
  BusinessObjectType,
  ContractCompanyRole,
  ContractStatus,
  Currency,
} from '../../../../bonding_shared/model/dictionary-ids';
import {ListEmitters} from '../../../../bonding_shared/components/details-view/list-emitters';
import {RelatedObject} from '../../../../bonding_shared/components/related-object-info/model';
import {StringUtils} from '../../../../bonding_shared/utils';
import {BondingContractInquiryService} from '../../../../bonding_shared/services/bonding-contract-inquiry.service';
import {ContactPersonDisplayNamePipe} from '../../../../bonding_shared/pipes/contact-person-display-name.pipe';

@Component({
  selector: 'bonding-contract-inquiry-tab-main-credendo',
  templateUrl: './bonding-contract-inquiry-tab-main-credendo.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondingContractInquiryTabMainCredendoComponent extends ListEmitters implements OnInit {
  @Input() showErrors = false;
  @ViewChild('tabModel') tabModel: NgModelGroup;

  inquiryVersion: BondingContractInquiryVersionDto;
  contactPersonCriteria = <ContactPersonCriteriaDto>{boTypeId: BusinessObjectType.COMPANY};
  businessObject = <BusinessObjectDto>{};
  underwriters: UserSimpleDto[] = null;
  contractCriteria: ContractVersionCriteriaDto;

  readonly Currency = Currency;
  readonly BondingContractInquiryStatus = BondingContractInquiryStatus;

  @Input() set version(v: BondingContractInquiryVersionDto) {
    this.inquiryVersion = v;
    this.contactPersonCriteria.boId = this.mainClient ? this.mainClient.id : null;
    this.contactPersonCriteria.companyId = this.contactPersonCriteria.boId;
    this.businessObject.relatedToId = this.contactPersonCriteria.boId;
    this.loadUnderwriters();
  }

  constructor(
    public router: RouterService,
    private growlService: GrowlService,
    private userService: UserService,
    private inquiryService: BondingContractInquiryService
  ) {
    super();
    this.initializeSelectorEmittersByNames(true, [
      RelatedObject.CLIENT,
      RelatedObject.BROKER,
      RelatedObject.CONTACT_PERSON,
      RelatedObject.CONTRACT,
    ]);
    this.businessObject.relatedTo = <DictionaryBaseDto>{id: BusinessObjectType.COMPANY};

    this.initializeContractSearchCriteria();
  }

  get inquiry() {
    return this.inquiryVersion && this.inquiryVersion.inquiry;
  }

  ngOnInit() {}

  onSelectClient(company: CompanySimpleDto) {
    if (company) {
      console.log('contractCompanySelect onSelected');

      // Check uniqueness
      for (const c of this.inquiryVersion.clients) {
        if (c.company.id === company.id) {
          this.growlService.warning('Company already added!');
          return;
        }
      }
      const contractCompany = this.createContractCompany(company);
      this.inquiryVersion.clients.push(contractCompany);
    }
  }

  clientIrremovable(c: BondingContractInquiryClientDto): boolean {
    return c.role.id === ContractCompanyRole.MAIN_CLIENT;
  }

  createContractCompany(c: CompanySimpleDto): BondingContractInquiryClientDto {
    const cc = <BondingContractInquiryClientDto>{};
    cc.company = c;
    cc.role = <DictionaryBaseDto>{id: ContractCompanyRole.CLIENT, name: 'Client'};
    return cc;
  }

  get brokerAddress() {
    return this.inquiryVersion.broker ? StringUtils.prettyAddress(this.inquiryVersion.broker.address) : '';
  }

  get mainClient(): CompanySimpleDto {
    return this.inquiryVersion.clients
      ? this.inquiryVersion.clients.find((c) => c.role.id === ContractCompanyRole.MAIN_CLIENT).company
      : null;
  }

  getContactPersonFullName(cp: ContactPersonDto) {
    return new ContactPersonDisplayNamePipe().transform(cp);
  }

  private loadUnderwriters() {
    this.userService.getBondingSupport().subscribe((res) => {
      this.underwriters = res;
    });
  }

  get readOnly(): boolean {
    return this.inquiryVersion.status && this.inquiryVersion.status.id !== BondingContractInquiryStatus.REGISTERED;
  }

  onSelectContract(contractVersion: ContractVersionDto) {
    this.inquiryVersion.inquiry.contractVersion = contractVersion;
  }

  initializeContractSearchCriteria() {
    this.contractCriteria = <ContractVersionCriteriaDto>{};
    this.contractCriteria.presentNow = true;
    this.contractCriteria.status = <DictionaryDto>{};
    this.contractCriteria.status.id = ContractStatus.CONTRACT_ACTIVATED;
  }
}
