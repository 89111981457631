import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {BusinessLogService} from '../../services';
import {ConfirmDialogComponent} from '../confirm-dialog';

@Component({
  selector: 'failed-login-alert',
  templateUrl: './failed-login-alert.pug',
})
export class FailedLoginAlertComponent implements OnInit, OnDestroy {
  private subscriptionGet: Subscription;
  private subscriptionClear: Subscription;

  constructor(private businessLogService: BusinessLogService) {}

  @ViewChild('alertDialog') alertDialog: ConfirmDialogComponent;

  ngOnInit() {
    this.subscriptionGet = this.businessLogService.getLoginAlert().subscribe((alert) => {
      if (alert) {
        this.alertDialog.openAndExecuteOnConfirm('securityAlert.title', alert, undefined, this.clearAlert.bind(this));
      }
    });
  }

  ngOnDestroy() {
    if (this.subscriptionGet) {
      this.subscriptionGet.unsubscribe();
    }
    if (this.subscriptionClear) {
      this.subscriptionClear.unsubscribe();
    }
  }

  clearAlert() {
    this.subscriptionClear = this.businessLogService.clearLoginAlert().subscribe();
  }
}
