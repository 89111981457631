import {Component} from '@angular/core';

@Component({
  selector: 'minimum-documentary',
  template: `
    <div>
      <a (click)="goToLink('assets/Minimum%20dokumentowe.pdf')" class="a-link" translate>Pobierz minimum dokumentowe</a>
    </div>
  `,
  providers: [],
})
export class MinimumDocumentary {
  constructor() {}

  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
