import {ImportComponent} from './import.component';

export const ImportRoutes = [
  {
    path: 'import/:importTypeId',
    component: ImportComponent,
  },
  {
    path: 'import',
    component: ImportComponent,
  },
];
