import {CompanyBaseDto} from '../model';

export class Mapper {
  static companyToBaseDto(company: CompanyBaseDto): CompanyBaseDto {
    return <CompanyBaseDto>{
      id: company.id,
      partnerNumber: company.partnerNumber,
      registrationName: company.registrationName,
      address: company.address,
      companyType: company.companyType,
      endFiscalYear: company.endFiscalYear,
      groupHead: company.groupHead,
      holder: company.holder,
      language: company.language,
      legalForm: company.legalForm,
      legalStatus: company.legalStatus,
      nationalId: company.nationalId,
      physicalPerson: company.physicalPerson,
      physicalPersonIndicator: company.physicalPersonIndicator,
      shortName: company.shortName,
      startOfBusiness: company.startOfBusiness,
      statNumber: company.statNumber,
      tradeName: company.tradeName,
      tradeName2: company.tradeName2,
      tradeName3: company.tradeName3,
      vatNumber: company.vatNumber,
      version: company.version,
    };
  }
}
