import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  BackendError,
  BondVersionBaseDto,
  BondVersionCriteriaDto,
  BondVersionSimpleDto,
  ClaimSimpleDto,
  ClaimVersionDto,
  CollectionVersionBaseDto,
  CollectionVersionCriteriaDto,
  CompanyBaseDto,
  CompanyCriteriaDto,
  DictionaryBaseDto,
  LocalDateRange,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionDto,
  ProductLimitListForLimitDto,
  ProductLimitListVersionCriteriaDto,
} from '../../../bonding_shared/model';
import {
  ClaimElementaryRight,
  ClaimRiskType,
  CollectionType,
  ElementaryRight,
  POLICY_CONTRACT_ACTIVATED_STATUSES,
  PolicyContractType,
} from '../../../bonding_shared/model/dictionary-ids';
import {
  AppConfigService,
  ClaimService,
  CollectionVersionService,
  LoggedUserService,
  RouterService,
} from '../../../bonding_shared/services';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {BondGuiService} from '../../bond/services';
import {ActivatedRoute} from '@angular/router';
import {Observable, of, switchMap} from 'rxjs';
import {CompanySelectedEvent} from '../../company/components/shared/extended-selectors/company-selector-extended.component';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'claim-selectors',
  templateUrl: 'claim-selectors.component.pug',
})
export class ClaimSelectorsComponent extends ListEmitters {
  private static readonly PROD_RISK_PRODUCT_IDS = [
    PolicyContractType.KUKE_GSP_PBG,
    PolicyContractType.EXPORT_RECEIVABLES_C,
  ];

  @Input() claim: ClaimSimpleDto;
  @Input() serverErrorHandler: (error: BackendError) => void;
  @Input() clearErrorsHandler: () => void;
  @Input() readonly: boolean;
  @Input() policyLinkSearchableInEditFieldMode: boolean;

  @Output() initialized = new EventEmitter<ClaimVersionDto>();
  @Output() claimRiskTypeChanged = new EventEmitter<DictionaryBaseDto>();
  @Output() policyContractVersionEdited = new EventEmitter<PolicyContractVersionDto>();

  readonly ClaimRiskType = ClaimRiskType;
  readonly ClaimSelectorsNames = ClaimSelectorSteps;
  policySearchCriteria: PolicyContractVersionCriteriaDto;
  bondVersionSearchCriteria: BondVersionCriteriaDto;
  collectionSearchCriteria: CollectionVersionCriteriaDto;
  policyLimitListSearchCriteria: ProductLimitListVersionCriteriaDto;
  riskSearchCriteria: CompanyCriteriaDto;
  hiddenIds: Set<number>;

  private riskTypeIsSetFromParams = false;

  constructor(
    private loggedUserService: LoggedUserService,
    private claimService: ClaimService,
    public appService: AppConfigService,
    public router: RouterService,
    private _route: ActivatedRoute,
    private collectionVersionService: CollectionVersionService
  ) {
    super();
    const isEcgPortal = this.appService.ecg && this.loggedUserService.portal;
    this.selectorNameList = Object.values(ClaimSelectorSteps);
    if (this.appService.kuke) {
      this.hiddenIds = new Set<number>(
        loggedUserService.hasRight(ClaimElementaryRight.CLAIM_CREATE_INITIAL_PBG) ? null : [ClaimRiskType.PROD_RISK]
      );
    } else if (isEcgPortal) {
      this.hiddenIds = new Set<number>([ClaimRiskType.PROD_RISK, ClaimRiskType.SURETY]);
    } else {
      this.hiddenIds = new Set<number>();
    }
    this.initializeCriteria();
    this.initializeSelectorEmitters(true);
  }

  initializeCriteria() {
    if (this.kuke) {
      this.policySearchCriteria = <PolicyContractVersionCriteriaDto>{
        oneVersionForMaster: true,
        versionValidFrom: <LocalDateRange>{dateTo: new Date()},
        statusIds: POLICY_CONTRACT_ACTIVATED_STATUSES,
      };
    } else {
      // for Credendo we need to be able to select particular policy year
      this.policySearchCriteria = <PolicyContractVersionCriteriaDto>{
        lastActivated: true,
      };
    }
    this.bondVersionSearchCriteria = <BondVersionCriteriaDto>{bond: {}};
    this.collectionSearchCriteria = <CollectionVersionCriteriaDto>{
      types: CollectionType.CREDIT_INSURANCE,
      hasDebtor: true,
    };
    this.policyLimitListSearchCriteria = <ProductLimitListVersionCriteriaDto>{limitList: {}};
    this.riskSearchCriteria = <CompanyCriteriaDto>{};
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }

  get kukePortal(): boolean {
    return this.kuke && this.portal;
  }

  get areSectionsSearchable(): boolean {
    return !(this.claim && this.claim.id);
  }

  arePolicySectionsSearchable(): boolean {
    if (this.policyLinkSearchableInEditFieldMode) {
      this.policySearchCriteria.linkedCompanyId = this.claim.mainBeneficiary.id;
    }
    return !(this.claim && this.claim.id) || this.policyLinkSearchableInEditFieldMode;
  }

  private searchForClaimType() {
    if (this.claim?.riskType?.id) {
      if (this.isClaimType(ClaimRiskType.PROD_RISK)) {
        this.policySearchCriteria.contractTypeIds = ClaimSelectorsComponent.PROD_RISK_PRODUCT_IDS;
      } else if (this.isClaimType(ClaimRiskType.MLT_PRODUCTION_RISK)) {
        this.policySearchCriteria.contractTypeIds = [PolicyContractType.PRESHIPMENT_RISK_G];
      } else if (this.isClaimType(ClaimRiskType.CREDIT_INSURANCE) && this.appService.mehib) {
        this.policySearchCriteria.contractTypeIds = [
          PolicyContractType.EXPORT_RECEIVABLES_C,
          PolicyContractType.FACTORING_CF,
        ];
      } else if (this.isClaimType(ClaimRiskType.MLT_CREDIT_INSURANCE)) {
        this.policySearchCriteria.contractTypeIds = [
          PolicyContractType.BUYER_CREDIT_V,
          PolicyContractType.SUPPLIER_CREDIT_S,
          PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV,
          PolicyContractType.TIED_AID_TAI,
        ];
      } else if (this.isClaimType(ClaimRiskType.MLT_INVESTMENT_INSURANCE)) {
        this.policySearchCriteria.contractTypeIds = [PolicyContractType.FOREIGN_INVESTMENT_B];
      }
    }
  }

  onSelectMainBeneficiary(company: CompanyBaseDto) {
    this.claim.mainBeneficiary = company;
    this.claim.policyContractVersion = undefined;
    this.policySearchCriteria.linkedCompanyId = company.id;
    this.openSelectorEmitters[ClaimSelectorSteps.Policy].next(true);
  }

  onSelectPolicy(c: PolicyContractVersionDto) {
    if (this.policyLinkSearchableInEditFieldMode) {
      this.tryInitialVersionFromEditedPolicy(c);
    } else {
      this.claim.policyContractVersion = c;
      if (this.isClaimType(ClaimRiskType.PROD_RISK)) {
        this.policyLimitListSearchCriteria.limitList.masterPolicyContract = c.policyContract.masterPolicyContract;
        this.policyLimitListSearchCriteria.last = true;
        this.openSelectorEmitters[ClaimSelectorSteps.PolicyLimitList].next(true);
      } else {
        if (!this.claim.risk) {
          this.openSelectorEmitters[ClaimSelectorSteps.Risk].next(true);
        }
        this.tryInitialVersionFromPolicy();
      }
    }
  }

  onSelectBond(bondVersion: BondVersionSimpleDto) {
    this.claim.bondVersion = bondVersion;
    this.initialVersionFromBond(bondVersion.id);
  }

  onSelectRisk(c: CompanyBaseDto) {
    this.claim.risk = c;
    this.tryInitialVersionFromPolicy();
  }

  onSelectThirdParty(c: CompanySelectedEvent) {
    this.claim.thirdParty = c.thirdParty;
    this.tryInitialVersionFromPolicy();
  }

  onSelectCollection(c: CollectionVersionBaseDto) {
    this.claim.collectionVersion = c;
    this.collectionVersionService.getLastVersionSimpleByParentId(c.parent.id).subscribe((x) => {
      this.policyLimitListSearchCriteria.limitList.masterPolicyContract = x.parent.policy.masterPolicyContract;
      this.initialVersionFromCollection(this.claim.collectionVersion.parent.id);
    });
  }

  onSelectPolicyLimitList(limitList: ProductLimitListForLimitDto) {
    this.claim.policyLimitList = limitList;
    this.riskSearchCriteria.isLimitBuyerForMasterPolicyId = limitList.masterPolicyContract.id;
    this.openSelectorEmitters[ClaimSelectorSteps.Risk].next(true);
  }

  initialVersionFromBond(bondVersionId: number) {
    this.clearErrorsHandler();
    this.claimService
      .getInitialVersionFromBond(bondVersionId)
      .subscribe((v) => this.initialized.emit(v), this.serverErrorHandler);
  }

  initialVersionFromCollection(collectionId: number) {
    this.clearErrorsHandler();
    this.claimService
      .getInitialVersionFromCollection(collectionId)
      .subscribe((v) => this.initialized.emit(v), this.serverErrorHandler);
  }

  tryInitialVersionFromEditedPolicy(c: PolicyContractVersionDto) {
    this.policyContractVersionEdited.emit(c);
  }

  tryInitialVersionFromPolicy() {
    if (this.claim.mainBeneficiary && this.claim.policyContractVersion && (this.claim.risk || this.claim.thirdParty)) {
      this.clearErrorsHandler();
      of(!!this.claim.risk)
        .pipe(
          switchMap((riskFilled) => {
            if (riskFilled) {
              return this.claimService.getInitialVersionFromPolicy(
                this.claim.riskType.id,
                this.claim.risk.id,
                this.claim.mainBeneficiary.id,
                this.claim.policyContractVersion.policyContract.id,
                this.claim.policyLimitList?.id
              );
            } else {
              // third party
              return this.claimService
                .initialize(this.claim)
                .pipe(tap((cv) => (cv.claim.thirdParty = this.claim.thirdParty)));
            }
          })
        )
        .subscribe({
          next: (claimVersion) => this.initialized.emit(claimVersion),
          error: this.serverErrorHandler,
        });
    }
  }

  newClaim() {
    this._route.queryParams.subscribe((params: ClaimInitialParams) => {
      if (+params.bondVersionId) {
        this.initialVersionFromBond(+params.bondVersionId);
      } else if (+params.collectionId) {
        this.claim.riskType = <DictionaryBaseDto>{id: ClaimRiskType.CREDIT_INSURANCE};
        this.getCollection(+params.collectionId).subscribe((c) => this.onSelectCollection(c));
      } else if (+params.claimRiskType) {
        this.claim.riskType = <DictionaryBaseDto>{id: +params.claimRiskType};
        this.riskTypeIsSetFromParams = true;
        this.onClaimRiskTypeSelected(this.claim.riskType);
      }
    });
  }

  get riskTypeSelectorDisabled(): boolean {
    return !this.areSectionsSearchable || this.riskTypeIsSetFromParams;
  }

  getCollection(collectionId: number): Observable<CollectionVersionBaseDto> {
    return this.collectionVersionService.getLastVersionSimpleByParentId(collectionId);
  }

  goToContract(id: number) {
    this.router.toContractPreview(id);
  }

  goToBond(id: number) {
    this.router.toBondDetails(id);
  }

  isBondRequest(bondVersion: BondVersionBaseDto) {
    return BondGuiService.isBondRequest(bondVersion, false);
  }

  private isClaimType(expectedType: ClaimRiskType): boolean {
    return this.claim.riskType && this.claim.riskType.id === expectedType;
  }

  onClaimRiskTypeSelected(riskType: DictionaryBaseDto) {
    this.initializeCriteria();
    this.claimRiskTypeChanged.emit(riskType);
    this.claim = <ClaimSimpleDto>{riskType: riskType};
    let emitter = null;
    switch (riskType.id) {
      case ClaimRiskType.CREDIT_INSURANCE:
        emitter = this.appService.kuke ? ClaimSelectorSteps.Collection : ClaimSelectorSteps.MainBeneficiary;
        break;
      case ClaimRiskType.SURETY:
        emitter = ClaimSelectorSteps.Bond;
        break;
      case ClaimRiskType.PROD_RISK:
      case ClaimRiskType.MLT_CREDIT_INSURANCE:
      case ClaimRiskType.MLT_INVESTMENT_INSURANCE:
      case ClaimRiskType.MLT_PRODUCTION_RISK:
        emitter = ClaimSelectorSteps.MainBeneficiary;
        break;
    }
    this.searchForClaimType();
    this.openSelectorEmitters[emitter].next(true);
  }

  hasFieldsUpdateRight(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.CLAIM_FIELDS_EDITION);
  }

  getRiskCompanyOrThirdParty() {
    return this.claim?.thirdParty
      ? BusinessUtils.getThirdPartyCompany(this.claim?.thirdParty)?.company
      : this.claim?.risk;
  }
}

// string when reading
export interface ClaimInitialParams {
  collectionId?: string | number;
  bondVersionId?: string | number;
  claimRiskType?: DictionaryBaseDto | number;
}

export enum ClaimSelectorSteps {
  MainBeneficiary = 'MainBeneficiary',
  Bond = 'Bond',
  Policy = 'Policy',
  Risk = 'Risk',
  Collection = 'Collection',
  PolicyLimitList = 'PolicyLimitList',
}
