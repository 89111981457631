import {Component, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../bonding_shared/validators/custom.validators';
import {FormDialogComponent} from '../../bonding_shared/components/form-dialog/form-dialog.component';
import {FormDialogParent} from '../../bonding_shared/components/form-dialog/FormDialogParent';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {
  ChangePasswordDto,
  ExtranetAdminNotificationType,
  ExtranetClientAgreementType,
} from '../../bonding_shared/model/dtos';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {AbstractLoginService} from '../../login/login.service';
import {AppConfigService, UserService} from '../../bonding_shared/services';
import {PortalUserService} from '../services/portal-user.service';

@Component({
  selector: 'admin-notifications',
  templateUrl: 'admin-notifications.component.pug',
})
export class AdminNotificationsComponent extends FormDialogParent {
  @ViewChild(FormDialogComponent) aggrementsDialog: FormDialogComponent;

  constructor(
    private userService: PortalUserService,
    private loggedUserService: LoggedUserService,
    private growlService: GrowlService,
    public appService: AppConfigService
  ) {
    super();
    this.form = new UntypedFormGroup({
      FIRST_LOGIN: new UntypedFormControl(''),
      FAILED_LOGIN: new UntypedFormControl(''),
      ACCOUNT_BLOCKED: new UntypedFormControl(''),
    });
  }

  openNotificationsDialog() {
    this.userService.getNotifications(this.loggedUserService.getLoggedUserData().login).subscribe((agr) => {
      this.form.get('FIRST_LOGIN').setValue(agr.indexOf('FIRST_LOGIN') >= 0);
      this.form.get('FAILED_LOGIN').setValue(agr.indexOf('FAILED_LOGIN') >= 0);
      this.form.get('ACCOUNT_BLOCKED').setValue(agr.indexOf('ACCOUNT_BLOCKED') >= 0);
      this.aggrementsDialog.open('topnav.notifications').then((result) => {
        this.showErrors = false;
        this.form.reset();
        this.aggrementsDialog.hide();
      });
    });
  }

  getNotification(name: ExtranetAdminNotificationType, notifications: ExtranetAdminNotificationType[]) {
    if (this.form.get(name).value) {
      notifications.push(name);
    }
  }

  save() {
    const notifications: ExtranetAdminNotificationType[] = new Array();
    this.getNotification('FIRST_LOGIN', notifications);
    this.getNotification('FAILED_LOGIN', notifications);
    this.getNotification('ACCOUNT_BLOCKED', notifications);
    this.userService
      .saveNotifications(this.loggedUserService.getLoggedUserData().login, notifications)
      .subscribe((ret) => {
        this.growlService.notice('topnav.notificationsSaved');
        this.aggrementsDialog.dialogConfirmation();
      });
  }
}
