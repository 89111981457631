import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {CessionBaseDto, CessionDto, CessionVersionDto, CompanyBaseDto, StateTransitionDto} from '../model';

@Injectable()
export class PolicyCessionVersionService extends AbstractService {
  protected url = this.urlPrefix + 'cessionVersion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getInitialVersion(policyContractId: number): Observable<CessionVersionDto> {
    return this.get<CessionVersionDto>(this.url + '/initialVersion/' + policyContractId);
  }

  getNewVersion(cessionId: number): Observable<CessionVersionDto> {
    return this.get<CessionVersionDto>(this.url + '/newVersion/' + cessionId);
  }

  getCessionVersion(id: number): Observable<CessionVersionDto> {
    console.log('cessionVersion: id = ' + id);
    return this.get<CessionVersionDto>(this.url + '/' + id);
  }

  getCessionBase(id: number): Observable<CessionBaseDto> {
    return this.get<CessionBaseDto>(this.url + '/cession/base/' + id);
  }

  deleteCession(cessionId: number): Observable<CessionDto> {
    return this.delete<CessionDto>(this.url + '/cession/' + cessionId);
  }

  getTransitions(cv: CessionVersionDto): Observable<Array<StateTransitionDto>> {
    if (cv && cv.status) {
      return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + cv.businessStatus.id);
    }
    return Observable.create([]);
  }

  getAssignors(masterPolicyContractId: number): Observable<Array<CompanyBaseDto>> {
    return this.get<Array<CompanyBaseDto>>(this.url + '/assignors/' + masterPolicyContractId);
  }
}
