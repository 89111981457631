import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {InfoComponent} from './info.component';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
  declarations: [InfoComponent],
})
export class InfoModule {}
