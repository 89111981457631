import {DictionaryUtils} from '../../../bonding_shared/utils/dictionary-utils';
import {ClaimVersionDto} from '../../../bonding_shared';
import {ClaimCauseOfLoss, ClaimRiskType, PolicyContractType} from '../../../bonding_shared/model/dictionary-ids';

export class ClaimUtilsService {
  static isExpectedRiskType(claimVersion: ClaimVersionDto, ...expectedRiskTypes: number[]): boolean {
    return claimVersion?.claim?.riskType && DictionaryUtils.in(claimVersion?.claim?.riskType, ...expectedRiskTypes);
  }

  static isExpectedVersionType(claimVersion: ClaimVersionDto, ...expectedVersionType: number[]): boolean {
    return claimVersion?.versionType && DictionaryUtils.in(claimVersion?.versionType, ...expectedVersionType);
  }

  static isThreatCauseOfLoss(claimVersion: ClaimVersionDto) {
    return (
      claimVersion?.claimCauseOfLoss && DictionaryUtils.in(claimVersion?.claimCauseOfLoss, ClaimCauseOfLoss.LOSS_THREAT)
    );
  }

  static isSingleRiskOrTurnover(claimVersion: ClaimVersionDto) {
    return (
      ClaimUtilsService.isExpectedRiskType(claimVersion, ClaimRiskType.CREDIT_INSURANCE) &&
      claimVersion?.claim?.policyContractVersion?.policyContract?.masterPolicyContract &&
      [PolicyContractType.SINGLE_RISK, PolicyContractType.TURNOVER].includes(
        claimVersion.claim.policyContractVersion.policyContract.masterPolicyContract.productType.id
      )
    );
  }
}
