import {Directive, Input, OnInit} from '@angular/core';
import {CompanySelectorSimpleComponent} from '../company-selector-simple.component';
import {
  COMPANY_TYPE_BROKERS,
  COMPANY_TYPE_INSURERS,
  COMPANY_TYPE_MEHIB_REINSURERS,
} from '../../../../../bonding_shared/model/dictionary-ids';
import {AppConfigService} from '../../../../../bonding_shared';

@Directive({selector: 'company-selector-simple[companyType]'})
export class CompanyTypeDirective implements OnInit {
  private _companyType: 'broker' | 'insurer' | 'brokerInsurer';
  @Input() set companyType(companyType: 'broker' | 'insurer' | 'brokerInsurer') {
    if (companyType !== this._companyType) {
      this._companyType = companyType;
      this.updateSetup();
    }
  }

  constructor(private host: CompanySelectorSimpleComponent, public appService: AppConfigService) {}

  ngOnInit(): void {
    this.updateSetup();
  }

  private updateSetup() {
    if (this._companyType === 'broker') {
      this.setupBroker();
    } else if (this._companyType === 'insurer') {
      this.setupInsurer();
    } else if (this._companyType === 'brokerInsurer') {
      this.setupBrokerInsurer();
    } else {
      this.setupNone();
    }
  }

  private setupBroker() {
    this.host.dataProvider.searchCriteria.criteria.companyTypes = COMPANY_TYPE_BROKERS;
    this.host.dataProvider.textSearchCriteria.companyType = COMPANY_TYPE_BROKERS;
  }

  private setupInsurer() {
    if (this.appService.mehib) {
      this.host.dataProvider.searchCriteria.criteria.companyTypes = COMPANY_TYPE_MEHIB_REINSURERS;
      this.host.dataProvider.textSearchCriteria.companyType = COMPANY_TYPE_MEHIB_REINSURERS;
    } else {
      this.host.dataProvider.searchCriteria.criteria.companyTypes = COMPANY_TYPE_INSURERS;
      this.host.dataProvider.textSearchCriteria.companyType = COMPANY_TYPE_INSURERS;
    }
  }

  private setupNone() {
    this.host.dataProvider.searchCriteria.criteria.companyTypes = undefined;
    this.host.dataProvider.textSearchCriteria.companyType = undefined;
  }

  private setupBrokerInsurer() {
    this.host.dataProvider.searchCriteria.criteria.companyTypes = COMPANY_TYPE_BROKERS.concat(COMPANY_TYPE_INSURERS);
    this.host.dataProvider.textSearchCriteria.companyType = COMPANY_TYPE_BROKERS.concat(COMPANY_TYPE_INSURERS);
  }
}
