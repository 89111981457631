import {Component, OnInit, ViewChild} from '@angular/core';
import {ClaimGuiService} from './services/claim-gui.service';
import {
  AppConfigService,
  BackendError,
  ClaimService,
  ClaimVersionBaseDto,
  ClaimVersionCriteriaDto,
  ComboItem,
  DictionaryBaseDto,
  DictionaryDto,
  DictionaryService,
  GrowlService,
  IMultiSelectSettings,
  LoggedUserService,
  RouterService,
  UserRange,
} from '../../bonding_shared';
import {ClaimListComponent} from './components/shared/claim-list.component';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {
  ClaimElementaryRight,
  ClaimIndemnificationStatus,
  ClaimRiskType,
  ClaimStatus,
  ClaimVersionType,
  Feature,
} from '../../bonding_shared/model/dictionary-ids';
import {SearchViewComponent} from '../../bonding_shared/components/search';

@Component({
  selector: 'claim-search',
  templateUrl: './claim-search.component.pug',
})
export class ClaimSearchComponent extends SearchView implements OnInit {
  @ViewChild(ClaimListComponent, {static: true}) claimList: ClaimListComponent;
  @ViewChild(SearchViewComponent, {static: true}) private searchView: SearchViewComponent<
    ClaimVersionCriteriaDto,
    ClaimVersionBaseDto
  >;
  versionPhases: ComboItem[] = [new ComboItem('ACTIVE', 'Current'), new ComboItem('LAST', 'Last')];
  serverErrors: BackendError;
  readonly ClaimElementaryRight = ClaimElementaryRight;
  readonly PredefinedFilter = PredefinedFilter;
  readonly statusMultiselectSettings = <IMultiSelectSettings>{
    dynamicTitleMaxItems: 1,
    showUncheckAll: true,
    minWidth: '300px',
    maxHeight: '310px',
  };
  readonly activeClaimStatuses = [
    <DictionaryBaseDto>{id: ClaimStatus.CLAIM_REGISTRED},
    <DictionaryBaseDto>{id: ClaimStatus.DOCUMENTS_APPLICATION},
    <DictionaryBaseDto>{id: ClaimStatus.DOCUMENTS_APPLICATION_COMPLIMENT},
    <DictionaryBaseDto>{id: ClaimStatus.DOCUMENTS_APPLICATION_REMAINDER},
    <DictionaryBaseDto>{id: ClaimStatus.CLAIM_ASSESMENT},
    <DictionaryBaseDto>{id: ClaimStatus.DOCUMENTS_ASSESMENT},
    <DictionaryBaseDto>{id: ClaimStatus.DECISION},
  ];
  readonly notActiveClaimStatusesIntranet = [
    <DictionaryBaseDto>{id: ClaimStatus.CLOSED_PAID},
    <DictionaryBaseDto>{id: ClaimStatus.CLOSED_REJECTED},
    <DictionaryBaseDto>{id: ClaimStatus.CLOSED_DEBT_PAID},
    <DictionaryBaseDto>{id: ClaimStatus.CLOSED_CLAIM_REATRETED},
    <DictionaryBaseDto>{id: ClaimStatus.KUKE_USER_ERROR},
  ];
  readonly notActiveClaimStatusesPortal = this.notActiveClaimStatusesIntranet.filter(
    (s) => s.id !== ClaimStatus.KUKE_USER_ERROR
  );
  readonly claimUserRange: UserRange = 'claim';
  readonly Feature = Feature;

  openOnly: boolean;
  closedOnly: boolean;
  claimRelatedPartyTypes: DictionaryDto[];
  selectorsInitialized = false;
  mine = false;

  ngOnInit() {
    if (this.appService.ecg && this.isPortal) {
      return;
    }
    this.dictionaryService.getDictionary('ClaimRelatedPartyType').subscribe({
      next: (partyType) => this.initSelectors(partyType),
      error: (error) => {
        console.error('Server error or warning !', error);
        this.initSelectors([]);
      },
    });
  }

  private initSelectors(claimRelatedPartyTypes: DictionaryDto[]) {
    this.claimRelatedPartyTypes = claimRelatedPartyTypes;
    this.selectorNameList = this.claimRelatedPartyTypes
      .map((type) => type.code)
      .concat(['Risk', 'Policy', 'Contract', 'MainBeneficiary', 'CurrentCreditor']);
    this.initializeSelectorEmitters(true);
    this.selectorsInitialized = true;
  }

  constructor(
    private claimService: ClaimService,
    public guiService: ClaimGuiService,
    public router: RouterService,
    private growlService: GrowlService,
    public loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    private dictionaryService: DictionaryService
  ) {
    super();
  }

  get criteria() {
    return this.guiService.dataProvider.searchCriteria.criteria;
  }

  get isCreditInsurance(): boolean {
    return this.criteria.claim.riskType && this.criteria.claim.riskType.id === ClaimRiskType.CREDIT_INSURANCE;
  }

  get isBond(): boolean {
    return this.criteria.claim.riskType && this.criteria.claim.riskType.id === ClaimRiskType.SURETY;
  }

  get notActiveClaimStatuses() {
    return this.isPortal ? this.notActiveClaimStatusesPortal : this.notActiveClaimStatusesIntranet;
  }

  onOpenOnlyStateChange(checked: boolean) {
    if (checked) {
      this.closedOnly = false;
      this.criteria.statuses = [...this.activeClaimStatuses];
    } else {
      this.criteria.statuses = [];
    }
  }

  onClosedOnlyStateChange(checked: boolean) {
    if (checked) {
      this.openOnly = false;
      this.criteria.statuses = [...this.notActiveClaimStatuses];
    } else {
      this.criteria.statuses = [];
    }
  }

  onOtherCreditorOnlyChange() {
    this.criteria.currentCreditor = null;
  }

  resetSearchCriteria() {
    this.guiService.resetSearchCriteria();
  }

  applyFilter(filter: PredefinedFilter) {
    this.resetSearchCriteria();
    switch (filter) {
      case PredefinedFilter.ACTIVE:
        this.criteria.statuses = [...this.activeClaimStatuses];
        this.openOnly = true;
        this.closedOnly = false;
        break;
      case PredefinedFilter.NOT_ACTIVE:
        this.criteria.statuses = [...this.notActiveClaimStatuses];
        this.openOnly = false;
        this.closedOnly = true;
        break;
      default:
        const f: never = filter;
    }
    this.searchView.search();
  }

  get isEcg(): boolean {
    return this.appService.ecg;
  }

  get isKuke(): boolean {
    return this.appService.kuke;
  }

  get isPortal(): boolean {
    return this.loggedUserService.portal;
  }

  get isKukeAndPortal(): boolean {
    return this.isKuke && this.isPortal;
  }

  get isKukeAndIntranet(): boolean {
    return this.isKuke && !this.isPortal;
  }

  get isEcgAndPortal(): boolean {
    return this.isEcg && this.isPortal;
  }

  get hiddenClaimStatusIds(): number[] {
    if (this.isKukeAndPortal) {
      return [ClaimStatus.KUKE_USER_ERROR];
    } else if (this.isEcg) {
      return this.isPortal
        ? [ClaimStatus.WAITING_FOR_FIRST_APPROVAL, ClaimStatus.APPROVED_CCOM, ClaimStatus.USER_ERROR]
        : [ClaimStatus.REGISTERED];
    }
    return [];
  }

  get hiddenClaimVersionTypes(): number[] {
    return this.isKukeAndPortal
      ? [ClaimVersionType.ENTIRE_CLAIM_CORRECTION, ClaimVersionType.PARTIAL_CLAIM_CORRECTION]
      : [];
  }

  get hiddenIndemnificationStatusIds(): number[] {
    return this.isKuke ? [ClaimIndemnificationStatus.WAITING_FOR_FIRST_APPROVAL] : [];
  }

  get predefinedFilterVisible(): boolean {
    return this.loggedUserService.portal && this.appService.kuke;
  }

  onStatusesModelChange(model: DictionaryBaseDto[]) {
    const statusIds = model.map((s) => s.id);
    this.openOnly =
      statusIds.length === this.activeClaimStatuses.length &&
      this.activeClaimStatuses.every((s) => statusIds.includes(s.id));
    const notActiveClaimStatuses = this.notActiveClaimStatuses;
    this.closedOnly =
      statusIds.length === notActiveClaimStatuses.length &&
      notActiveClaimStatuses.every((s) => statusIds.includes(s.id));
  }

  mineChanged(p: boolean) {
    if (p) {
      this.criteria.claimOfficer = this.loggedUserService.getLoggedUserData();
    } else {
      this.criteria.claimOfficer = null;
    }
  }
}

enum PredefinedFilter {
  ACTIVE,
  NOT_ACTIVE,
}
