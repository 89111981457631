import {PolicyCessionPreviewComponent} from './policy-cession-preview-component';
import {PolicyCessionListComponent} from './components/shared/policy-cession-list.component';
import {PolicyCessionDetailsComponent} from './policy-cession-details.component';

export const PolicyCessionRoutes = [
  {
    path: 'policy-cession-list/:masterPolicyContractId',
    component: PolicyCessionListComponent,
  },
  {
    path: 'policy-cession-preview/:id',
    component: PolicyCessionPreviewComponent,
  },
  {
    path: 'policy-cession-details/:id/:policyContractId',
    component: PolicyCessionDetailsComponent,
  },
  {
    path: 'policy-cession-details/:id',
    component: PolicyCessionDetailsComponent,
  },
];
