import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {
  SearchResult,
  StateTransitionDto,
  TreatyBaseDto,
  TreatySimpleDto,
  TreatyVersionDto,
  TreatyVersionSimpleDto,
} from '../model';

@Injectable()
export class TreatyVersionService extends AbstractService {
  protected url = this.urlPrefix + 'treatyVersion';

  constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getInitialVersion(): Observable<TreatyVersionDto> {
    return this.get<TreatyVersionDto>(this.url + '/initialVersion');
  }

  getLastVersion(treatyId: number): Observable<TreatyVersionDto> {
    return this.get<TreatyVersionDto>(this.url + '/last/' + treatyId);
  }

  getAll(): Observable<SearchResult<TreatyVersionSimpleDto>> {
    return this.get<SearchResult<TreatyVersionSimpleDto>>(this.url);
  }

  getAllTreaties(): Observable<TreatyBaseDto[]> {
    return this.get<TreatyBaseDto[]>(this.url + '/treaties/');
  }

  getTransitions(treaty: TreatyVersionDto): Observable<Array<StateTransitionDto>> {
    return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + treaty.status.id);
  }

  versionsToTreatyBaseDtos(dtos: TreatyVersionSimpleDto[]): TreatyBaseDto[] {
    return dtos.map((tv) => tv.treaty);
  }

  newVersionNewTreaty(treatyId: number): Observable<TreatyVersionDto> {
    return this.get<TreatyVersionDto>(this.url + '/newVersionNewTreaty/' + treatyId);
  }
}
