import {Component, DoCheck, Optional} from '@angular/core';
import {CustomFieldValueDto} from '../../bonding_shared';
import {CustomFormSimpleComponent} from './custom-form-simple.component';
import {LimitDecisionComponent} from '../../bonding/limit/components/limit-decision.component';
import {CustomField, DeductibleType} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'credendo-lol-custom-field',
  template: `
    <num-row
      *ngIf="fieldValue"
      [label]="fieldValue | fieldName"
      [showErrors]="showErrors"
      [ngModel]="fieldValue.value"
      [name]="fieldValue.field.name"
      type="decimal"
      [title]="shortCode(fieldValue.field.code)"
      [required]="fieldAttributes[fieldValue.field.id].required"
      [disabled]="true"
    >
    </num-row>
  `,
  styles: [],
})
export class CredendoLolCustomFieldComponent implements /*AfterViewInit, AfterViewChecked,*/ DoCheck {
  fieldValue: CustomFieldValueDto;
  parent: CustomFormSimpleComponent;

  constructor(@Optional() public decisionComponent?: LimitDecisionComponent) {}

  get showErrors() {
    return this.parent.showErrors;
  }

  get disabled() {
    return this.parent.disabled;
  }

  shortCode(code: string) {
    return this.parent.shortCode(code);
  }

  get fieldAttributes() {
    return this.parent.fieldAttributes;
  }

  ngDoCheck() {
    this.fieldValue.value = '' + this.calc();
  }

  private calc() {
    // calculate credendo LOL based on deductible type:
    // flat: credendo LOL = max exposure-deductible amount * coverage
    // rest: credendo LOL = max exposure * coverage

    const maxExposure = this.decisionComponent?.request?.amount?.value;
    if (!maxExposure) {
      return null;
    }

    const values = this.decisionComponent.decision.conditions.reduce((acc, val) => acc.concat(val.values), []);

    const findVal = (typeId) => {
      const v = values.filter((vv) => vv.field.id === typeId);
      if (v.length === 0) {
        return null;
      }
      return v[0].value;
    };

    let lol = maxExposure;

    const deductibleAmount = findVal(CustomField.DEDUCTIBLE_AMOUNT);
    const deductibleType = +findVal(CustomField.LDC_DEDUCTIBLE_TYPE);
    if (deductibleType && deductibleType === DeductibleType.FLAT && deductibleAmount) {
      lol -= deductibleAmount;
    }

    const cover = findVal(CustomField.LDC_INSURANCE_COVER);
    if (cover) {
      lol *= cover / 100;
    } else {
      lol = null;
    }

    return lol;
  }
}
