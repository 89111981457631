import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {ClientBondVersionSearchComponent} from './clientbond-version-search.component';
import {ClientBondDetailsComponent} from './clientbond-details.component';
import {ClientBondGuiService} from './services/clientbond-gui.service';
import {DocumentModule} from '../../bonding/document/document.module';
import {CompanySharedModule} from '../../bonding/company/components/shared/company-shared.module';
import {ContractSharedModule} from '../../bonding/bonding-contract/contract/components/shared/contract-shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {ClientBondSummaryComponent} from './components/clientbond-summary.component';
import {ClientBondFormComponent} from './components/clientbond-form.component';
import {ClientBondSubtypeSelectorComponent} from './components/clientbond-subtype-selector.component';
import {RepositoryDocumentListModule} from '../../bonding_shared/components/repository-document-list/repository-document-list.module';
import {BondSharedModule} from '../../bonding/bond/components/shared/bond-shared.module';
import {ClientBondGuard} from './clientbond-guard';
import {ClientBondAttachmentsSectionComponent} from './components/clientbond-attachments-section.component';
import {ItBidBondComponent} from './components/it-bid-bond.component';
import {ItPerformanceBondComponent} from './components/it-performance-bond.component';
import {ItBidBondLlinkListComponent} from './components/it-bid-bond-llink-list.component';
import {ItBidBondSimpleSelectorComponent} from './components/it-bid-bond-simple-selector.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    DocumentModule,
    ContractSharedModule,
    TranslateModule,
    RepositoryDocumentListModule,
    BondSharedModule,
  ],
  declarations: [
    ClientBondVersionSearchComponent,
    ClientBondDetailsComponent,
    ClientBondSummaryComponent,
    ClientBondFormComponent,
    ClientBondSubtypeSelectorComponent,
    ClientBondAttachmentsSectionComponent,
    ItBidBondComponent,
    ItPerformanceBondComponent,
    ItBidBondLlinkListComponent,
    ItBidBondSimpleSelectorComponent,
  ],
  providers: [ClientBondGuiService, ClientBondGuard],
})
export class ClientBondModule {}
