import {Component} from '@angular/core';

import {ClaimService, GrowlService, LoggedUserService, RouterService} from '../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {AuditedViewAbstract} from '../../bonding_shared/components/details-view/audited-view-abstract';
import {
  ClaimCalculationDocumentDto,
  ClaimCalculationDocumentsDto,
  ClaimCalculationDto,
  ClaimSimpleDto,
  CompanyBaseDto,
} from '../../bonding_shared/model';
import {ClaimCalculationService} from '../../bonding_shared/services/claim-calculation.service';
import {ActivatedRoute} from '@angular/router';
import {ClaimCalculationDocumentsService} from '../../bonding_shared/services/claim-calculation-documents.service';

@Component({
  selector: 'claim-calculation-documents',
  templateUrl: 'claim-calculation-documents.component.pug',
})
export class ClaimCalculationDocumentsComponent extends AuditedViewAbstract<ClaimCalculationDocumentsDto> {
  public readonly TODAY = new Date();
  public calculation = <ClaimCalculationDto>{};
  public claim = <ClaimSimpleDto>{};

  constructor(
    route: ActivatedRoute,
    claimCalculationDocumentsService: ClaimCalculationDocumentsService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    private claimCalculationService: ClaimCalculationService,
    private claimService: ClaimService
  ) {
    super(route, claimCalculationDocumentsService, router, translateService, growlService, loggedUserService, 'calcId');
    this.initializeSelectorEmittersByNames(true, ['Beneficiary']);
  }

  extraInitialization() {
    super.extraInitialization();
    this.claimCalculationService.getCalc(this.id).subscribe((calculation) => {
      this.calculation = calculation;
      this.claimService.getClaimSimple(this.calculation.claim.id).subscribe((claim) => (this.claim = claim));
    });
  }

  public newBeneficiarySelected(beneficiary: CompanyBaseDto): void {
    const newItem = <ClaimCalculationDocumentDto>{};
    newItem.beneficiary = beneficiary;
    newItem.receivedDate = this.TODAY;
    newItem.sendDate = this.TODAY;
    this.current.documents.push(newItem);
  }

  public generateDocument(event): void {
    // todo nothing to do here until templates are not available
    console.log(`Generating document`);
  }
}
