<div class="btn-group" dropdown>
  <a dropdownToggle>
    <img *feature="Feature.SHOW_FLAG_IN_USER_LANGUAGE_SELECTOR" src="assets/images/flags/gif/{{getUserLang()}}.gif" class="topnav-img" alt="">
    <span class="hidden-md-down">{{getUserLang().toUpperCase()}}</span>
  </a>
  <ul class="dropdown-menu" aria-labelledby="lang">
    <li *ngFor="let language of availableLanguages" role="menuitem">
      <a class="dropdown-item" href="javascript:void(0)" (click)="changeLang(eat$(language))">{{lang[language]}}</a>
    </li>
  </ul>
</div>

