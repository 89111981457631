import {Component, Input} from '@angular/core';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {PolicyLimitListService} from '../../../../bonding_shared/services/policy-limit-list.service';
import {
  LocalDateRange,
  MasterPolicyContractBaseDto,
  MasterPolicyContractDto,
  MasterPolicyContractForLimitDto,
  PolicyContractVersionBaseDto,
  ProductLimitListDto,
  ProductLimitListForLimitDto,
} from '../../../../bonding_shared/model';
import {
  BusinessObjectType,
  CustomFieldValueType,
  TaskType,
  PolicyContractVersionStatus,
} from '../../../../bonding_shared/model/dictionary-ids';
import {TaskGuiService} from '../../../task';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {AppConfigService, LoggedUserService} from '../../../../bonding_shared/services';

@Component({
  selector: 'master-policy-info',
  templateUrl: './master-policy-info.component.pug',
})
export class MasterPolicyInfoComponent {
  @Input() masterPolicy: MasterPolicyContractForLimitDto | MasterPolicyContractBaseDto;
  @Input() policyLimitList: ProductLimitListForLimitDto;
  @Input() showPolicyList = true;
  @Input() showFactorer = true;
  @Input() showLimitListsLink = false;
  @Input() policyVersion: PolicyContractVersionBaseDto;
  @Input() farmer = false;
  readonly PolicyContractVersionStatus = PolicyContractVersionStatus;

  constructor(
    private policyLimitListService: PolicyLimitListService,
    private taskGuiService: TaskGuiService,
    public router: RouterService,
    private loggedUserService: LoggedUserService
  ) {}

  toPolicyTaskSearch() {
    this.taskGuiService.setSearchContext(
      BusinessObjectType.MASTER_POLICY,
      this.masterPolicy.id,
      TaskType.PREPARE_POLICY_ANNEX
    );
    this.router.toTaskSearch();
  }

  get masterPolicyContract(): MasterPolicyContractForLimitDto | MasterPolicyContractBaseDto {
    return this.policyLimitList && this.showPolicyList ? this.policyLimitList.masterPolicyContract : this.masterPolicy;
  }

  getNumber(p: PolicyContractVersionBaseDto) {
    return p.number;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}
