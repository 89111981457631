import {Component, Input} from '@angular/core';
import {SectionSelector} from '../../bonding_shared/components/details-view/section-selector';
import {BusinessUnitService} from '../../bonding_shared/services/index';

@Component({
  selector: 'business-unit-section-selector',
  template: `
    <ng-template #section let-object="object" let-disabled="disabled" let-onChange="onChange">
      <selectable-object-section labelKey="common.businessUnit" [searchable]="false" [clearable]="false">
        <business-unit-selector
          [(ngModel)]="object[property]"
          content="header"
          name="businessUnit"
          nullLabel=""
          (changeItem)="onChange($event)"
          [disabled]="disabled"
          [userDefaultBU]="userDefaultBU"
        ></business-unit-selector>
        <company-info
          *ngIf="object[property]"
          [company]="(buService.getBusinessUnit(object[property].id) | async)?.company"
          [showLink]="true"
          content="body"
        ></company-info>
      </selectable-object-section>
    </ng-template>
  `,
  providers: [{provide: SectionSelector, useExisting: BusinessUnitSectionSelectorComponent}],
})
export class BusinessUnitSectionSelectorComponent extends SectionSelector {
  @Input() userDefaultBU: boolean;

  constructor(protected buService: BusinessUnitService) {
    super();
  }
}
