import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {BondVersionDto, DocumentContentDto, DocumentDescriptionDto} from '../../../bonding_shared/model';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {RepositoryService, DictionaryBaseService} from '../../../bonding_shared/services';
import {FileUtils} from '../../../bonding_shared/utils/file-utils';
import {BusinessObjectType} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'clientbond-attachments-section',
  templateUrl: './clientbond-attachments-section.component.pug',
})
export class ClientBondAttachmentsSectionComponent extends ListEmitters implements OnInit {
  self = this;

  @Input() bondVersionForm: UntypedFormGroup;
  @Input() showErrors: boolean;
  @Input() hidden = false;
  @Input() showWhenEmpty = false;
  @Input() showDuringCreation = false;
  @Input() collectionName: string;
  @Input() labelKey = 'clientbond.details.clientAttachments';

  get bondVersion() {
    return this._bondVersion;
  }

  get collection(): DocumentContentDto[] {
    return this._bondVersion && this._bondVersion[this.collectionName];
  }

  set collection(collection) {
    this._bondVersion[this.collectionName] = collection;
  }

  @Input() set bondVersion(bv: BondVersionDto) {
    this._bondVersion = bv;
  }

  form: UntypedFormGroup;
  _bondVersion: BondVersionDto;

  constructor(private dictionaryBaseService: DictionaryBaseService, private repositoryService: RepositoryService) {
    super();
    this.initializeSelectorEmittersByNames(true, ['Attachment']);
    this.form = new UntypedFormGroup({
      bikRequestAgreementAttached: new UntypedFormControl(''),
      informationClauseAcknowledgeConfirmationAttached: new UntypedFormControl(''),
      surveyCopyAttached: new UntypedFormControl(''),
    });
  }

  ngOnInit() {
    this.bondVersionForm.addControl('attachmentForm', this.form);
  }

  addBondVersionAttachments(files: File[]) {
    if (files && files.length > 0) {
      if (!this.collection) {
        this.collection = [];
      }
      files.forEach((file) => {
        FileUtils.readDocumentFromFile(file, (document) => {
          this.collection.push(document);
        });
      });
    }
  }

  getDocument(document: DocumentDescriptionDto) {
    if (document.repositoryId) {
      this.repositoryService.getRepositoryDocumentFromPortal(
        BusinessObjectType.BOND,
        this.bondVersion.bond.id,
        document.repositoryId,
        document.name
      );
    }
  }

  showSection() {
    if (!this.bondVersion) {
      return false;
    }
    return (
      this.showWhenEmpty ||
      (this.collection && this.collection.length > 0) ||
      (!this.bondVersion.id && this.showDuringCreation)
    );
  }
}
