import {AbstractService, UrlParams} from './abstract.service';
import {Injectable} from '@angular/core';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {CompanyGroupDto, CompanyWithExposureDto, SearchResult} from '../model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CompanyGroupVersionDto} from '../model/dtos';

@Injectable()
export class GroupService extends AbstractService {
  protected url = this.urlPrefix + 'companyGroup';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  public create(g: CompanyGroupDto): Observable<CompanyGroupDto> {
    return super.put(g, this.url);
  }

  getGroupMembers(companyId: number): Observable<SearchResult<CompanyWithExposureDto>> {
    return this.get<SearchResult<CompanyWithExposureDto>>(this.url + '/search/members/' + companyId);
  }

  newGroupVersion(versionId: number): Observable<CompanyGroupVersionDto> {
    return this.get<CompanyGroupVersionDto>(this.url + '/nextVersion/' + versionId);
  }

  getMember(id: number): Observable<CompanyWithExposureDto> {
    return this.get<CompanyWithExposureDto>(this.url + '/member/' + id);
  }

  companyTreeAvailableForGroup(companyId: number, groupId: number): Observable<CompanyWithExposureDto[]> {
    const params = UrlParams.new().add('companyId', companyId).add('groupId', groupId);
    return this.get<CompanyWithExposureDto[]>(this.buildUrl(this.url, 'companyTreeAvailableForGroup', params));
  }
}
