import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {LimitBuyerMassDecisionDto} from '../model/dtos';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class LimitDecisionService extends AbstractService {
  protected url = this.urlPrefix + 'limitDecision';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  massDecision(decision: LimitBuyerMassDecisionDto): Observable<LimitBuyerMassDecisionDto> {
    console.log('mass decision:', decision);
    return this.post<LimitBuyerMassDecisionDto>(decision, this.url + '/buyerMassDecision');
  }
}
