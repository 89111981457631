import {Component, OnDestroy, OnInit} from '@angular/core';
import {TaskService} from '../../services/task.service';
import {RouterService} from '../../services/router-service';
import {Subscription, timer} from 'rxjs';
import {ActivitySimpleDto, UserAgendaDto} from '../../model';
import {SettingsService} from '../../services';

@Component({
  selector: 'user-agenda',
  templateUrl: './user-agenda.component.pug',
})
export class UserAgendaComponent implements OnInit, OnDestroy {
  agenda: UserAgendaDto;
  private subscription: Subscription;
  private agendaChangeSubscription: Subscription;

  constructor(
    public router: RouterService,
    private taskService: TaskService,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    console.log('init agenda');
    const agendaTimer = timer(2500, 60000);
    this.subscription = agendaTimer.subscribe((t) => {
      this.refreshAgenda();
    });
    this.agendaChangeSubscription = this.taskService.userAgendaChange.subscribe(() => this.refreshAgenda());
  }

  ngOnDestroy() {
    console.log('destroy agenda');
    this.subscription.unsubscribe();
    this.agendaChangeSubscription.unsubscribe();
  }

  refreshAgenda() {
    this.taskService.userAgenda(this.settingsService.getActiveModuleId()).subscribe((agenda) => (this.agenda = agenda));
  }

  goToBusinessObject(task: ActivitySimpleDto) {
    this.router.goToBusinessObject(task.businessObject.relatedTo.id, task.businessObject.relatedToId);
  }
}
