import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {BatchExecutionDto, BatchParamDto, StringKeyValueDto} from '../model';
import {Observable} from 'rxjs';

@Injectable()
export class BatchService extends AbstractService {
  protected url = this.urlPrefix + 'batch';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  public getExecution(id: number): Observable<BatchExecutionDto> {
    return this.get<BatchExecutionDto>(this.url + '/jobs/execution/' + id);
  }

  public getLastExecution(name: string): Observable<BatchExecutionDto> {
    return this.get<BatchExecutionDto>(this.url + '/jobs/execution/last/' + name);
  }

  public getJobNames(): Observable<StringKeyValueDto[]> {
    return this.get<StringKeyValueDto[]>(this.url + '/jobs/names');
  }

  public run(name: string, params: BatchParamDto[]): Observable<string[]> {
    const s: string = params
      .filter((param) => param.key !== 'CallerUser' && param.key !== 'ExecutionTime')
      .map((p) => p.key + '=' + p.value)
      .join('&');
    return this.get<string[]>(this.url + '/run/' + name + '?' + s);
  }

  public rebuildIndexes(): Observable<string> {
    return this.get<string>(this.urlPrefix + 'search/rebuild');
  }
}
