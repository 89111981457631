import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  ContractVersionCriteriaDto,
  ContractVersionDto,
  ContractVersionSimpleDto,
} from '../../../../../bonding_shared/model';
import {RouterService} from '../../../../../bonding_shared/services/router-service';
import {SearchDataProvider} from '../../../../../bonding_shared/services/search-data-provider';
import {ATableComponent} from '../../../../../bonding_shared/components/aku-table/a-table.component';

@Component({
  selector: 'contract-version-list',
  templateUrl: './contract-version-list.component.pug',
})
export class ContractVersionListComponent {
  @ViewChild(ATableComponent, {static: true}) contractTable: ATableComponent<ContractVersionSimpleDto>;
  @Input() selection: boolean;
  @Input() canUnselect: boolean;

  @Input() showNumberCol: boolean;
  @Input() showVersionNumberCol: boolean;
  @Input() showCompanyCol: boolean;
  @Input() showCreationData = true;

  @Output() selectItem = new EventEmitter<ContractVersionSimpleDto>();
  @Output() refresh = new EventEmitter<void>();

  @Input() set dataProvider(dp: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>) {
    this.contractTable.dataProvider = dp;
    this.search();
  }

  @Input() selectedContractVersion: ContractVersionSimpleDto;

  constructor(private routerService: RouterService) {}

  search() {
    if (this.contractTable) {
      this.contractTable.search();
    }
  }

  onClickContractNumber(contractVersion: ContractVersionDto) {
    this.routerService.toContractDetails(contractVersion.id);
  }

  onClickClientName(contractVersion: ContractVersionDto) {
    this.routerService.toCompanyPreview(contractVersion.client.id);
  }

  selectPreviousItem() {
    this.selectedContractVersion = this.contractTable.items.find(
      (item) => item.versionNumber === this.selectedContractVersion.versionNumber - 1
    );
    this.selectItem.emit(this.selectedContractVersion);
  }
}
