import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {CompanySimpleDto, EmployeeDto, UserCriteriaDto, UserSimpleDto} from '../../../bonding_shared/model/dtos';
import {UserService} from '../../../bonding_shared/services/user.service';

@Injectable()
export class UserGuiService {
  constructor(private service: UserService) {}

  initializeDataProvider(): SearchDataProvider<UserCriteriaDto, UserSimpleDto> {
    const iDataProvider: SearchDataProvider<UserCriteriaDto, UserSimpleDto> = new SearchDataProvider<
      UserCriteriaDto,
      UserSimpleDto
    >(this.service);
    iDataProvider.searchCriteria.criteria = <UserCriteriaDto>{};
    iDataProvider.searchCriteria.criteria.company = <CompanySimpleDto>{};
    iDataProvider.searchCriteria.criteria.userStatusFilter = 'ACTIVE';
    iDataProvider.searchCriteria.criteria.employee = <EmployeeDto>{};
    iDataProvider.textSearch = false;

    return iDataProvider;
  }
}
