import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {ClaimVersionSimpleDto} from '../model';
import {Observable} from 'rxjs';

@Injectable()
export class ClaimTreatOrderService extends AbstractService {
  protected url = this.urlPrefix + 'claimTreatOrder';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getClaims(policyContractId: number): Observable<ClaimVersionSimpleDto[]> {
    return this.get(this.url + '/' + policyContractId);
  }

  update(claims: ClaimVersionSimpleDto[]): Observable<void> {
    return this.post1(claims, this.url);
  }
}
