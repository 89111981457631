import {Observable, of as observableOf} from 'rxjs';
import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {
  BusinessStatusDto,
  InvoiceCriteriaDto,
  InvoiceDto,
  ReportDefinitionSimpleDto,
  SearchCriteria,
  SearchResult,
  StateTransitionDto,
} from '../model/dtos';
import {HttpClient} from '@angular/common/http';
import {PolicyContractService} from './policy-contract.service';
import {BusinessReportService} from './business-report.service';
import {BinaryDownloaderService} from './binary-downloader.service';

@Injectable()
export class InvoiceService extends AbstractService {
  protected url = this.urlPrefix + 'invoice';
  protected urlSendInvoiceEndpoint = this.url + '/sendInvoice';
  protected urlReportDefinitions = this.url + '/reportDefinitions';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    private policyContractService: PolicyContractService,
    loggedUserService: LoggedUserService,
    private businessReportService: BusinessReportService,
    private binaryDownloaderService: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getInvoice(id: number): Observable<InvoiceDto> {
    console.log('Invoice details: id = ' + id);
    return this.get<InvoiceDto>(this.url + '/' + id);
  }

  getInvoiceCorrection(
    id: number,
    nullify: boolean,
    newItemStatusId?: number,
    newClientId?: number
  ): Observable<InvoiceDto> {
    console.log('Invoice correction: id = ' + id);
    const urlWithParams = this.buildUrl(
      this.url,
      id + '/newCorrection',
      UrlParams.new().add('nullify', nullify).add('newItemStatusId', newItemStatusId).add('newClientId', newClientId)
    );
    return this.get<InvoiceDto>(urlWithParams);
  }

  getInvoiceInitialVersion(countryId: number, clientId: number, categoryId: number): Observable<InvoiceDto> {
    console.log('Invoice Initial Version for: countryId = ' + countryId + ' and clientId = ' + clientId);
    return this.get<InvoiceDto>(
      this.url + '/initialVersion?countryId=' + countryId + '&clientId=' + clientId + '&categoryId=' + categoryId
    );
  }

  createInvoice(invoice: InvoiceDto): Observable<InvoiceDto> {
    console.log('create Invoice:' + JSON.stringify(invoice));
    return this.put<InvoiceDto>(invoice, this.url);
  }

  updateInvoice(invoice: InvoiceDto): Observable<InvoiceDto> {
    console.log('update Invoice id = ' + JSON.stringify(invoice));
    return this.post<InvoiceDto>(invoice, this.url);
  }

  deleteInvoice(id: number): Observable<InvoiceDto> {
    console.log('delete Invoice: id = ' + id);
    return this.delete<InvoiceDto>(this.url + '/' + id);
  }

  search(invoiceCriteria: SearchCriteria<InvoiceCriteriaDto>): Observable<SearchResult<InvoiceDto>> {
    console.log('searchInvoices: http = ' + this.http);
    return this.post1<SearchCriteria<InvoiceCriteriaDto>, SearchResult<InvoiceDto>>(
      invoiceCriteria,
      this.url + 'search'
    );
  }

  // Returns new invoice status
  sendLastInvoice(id: number): Observable<BusinessStatusDto> {
    console.log('sendLastInvoice');
    return this.get<BusinessStatusDto>(this.urlSendInvoiceEndpoint + '/' + id);
  }

  getTransitions(invoiceDto: InvoiceDto): Observable<Array<StateTransitionDto>> {
    if (invoiceDto && invoiceDto.status && invoiceDto.category) {
      return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + invoiceDto.id);
    }
    return observableOf([]);
  }

  getMaxPolicyYear(policyContactId: number): Observable<number> {
    return this.policyContractService.getMaxPolicyYear(policyContactId);
  }

  getReportDefinitions(invoiceId: number): Observable<ReportDefinitionSimpleDto[]> {
    return this.get<ReportDefinitionSimpleDto[]>(this.urlReportDefinitions + '/' + invoiceId);
  }

  export(
    criteria: InvoiceCriteriaDto,
    outputFileName: string,
    errorCallback: (errMsg: string) => void,
    completeCallback: () => void
  ) {
    console.log('export ', criteria);
    this.binaryDownloaderService.download(
      'POST',
      this.url + '/export',
      'application/json',
      'application/octet-stream',
      outputFileName,
      criteria,
      errorCallback,
      completeCallback
    );
  }
}
