import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CompanyModule} from '../company/company.module';
import {DocumentModule} from '../document/document.module';
import {InvoiceItemListComponent} from './components/invoice-item-list.component';
import {InvoiceItemDataComponent} from './components/invoice-item-data.component';
import {InvoiceItemGuiService} from './services/invoice-item-gui.service';
import {InvoiceItemSearchComponent} from './invoice-item-search.component';
import {InvoiceItemDetailsComponent} from './invoice-item-details.component';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {InvoiceContractLinkComponent} from './components/invoice-contract-link.component';
import {BrokerContractSharedModule} from '../broker-contract/components/shared/broker-contract-shared.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanyModule,
    DocumentModule,
    CompanySharedModule,
    BrokerContractSharedModule,
  ],
  declarations: [
    InvoiceItemSearchComponent,
    InvoiceItemListComponent,
    InvoiceContractLinkComponent,
    InvoiceItemDetailsComponent,
    InvoiceItemDataComponent,
  ],
  exports: [InvoiceItemListComponent, InvoiceContractLinkComponent],
  providers: [InvoiceItemGuiService],
})
export class InvoiceItemModule {}
