import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject, Subscription, switchMap, throwError, timer} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ExtranetClientAgreementType, GetTokenParamDto, GetTokenResultDto, UserDto} from '../model';
import {ElementaryRight, Language, ProfileCategory, UserRole} from '../model/dictionary-ids';
import {AbstractLoginService} from '../../login/login.service';
// noinspection ES6PreferShortImport
import {LoginPortalService} from '../../portal/services/login-portal.service';
import {SessionTimer} from './session-timer';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';

import {defineLocale} from 'ngx-bootstrap/chronos';
import {deLocale, esLocale, frLocale, huLocale, itLocale, nlLocale, plLocale} from 'ngx-bootstrap/locale';
import {AppConfigService} from './app-config.service';
import * as moment from 'moment';

plLocale.invalidDate = 'Niepoprawna data';

defineLocale('pl', plLocale);
defineLocale('de', deLocale);
defineLocale('it', itLocale);
defineLocale('fr', frLocale);
defineLocale('nl', nlLocale);
defineLocale('hu', huLocale);
defineLocale('es', esLocale);

@Injectable()
export class LoggedUserService {
  // url to redirect after successful login
  redirectUrl: string;

  private loggedUser: UserDto;
  private _accessToken: string;
  private _refreshToken: string;
  private _refreshTokenSubscription: Subscription;
  private _userRightCodes: string[] = [];
  readonly _portal;
  private reloadNecessary = false;

  sessionTimer: SessionTimer;

  readonly loggedUsersSubject = new Subject<UserDto>();

  constructor(
    public loginService: AbstractLoginService,
    private router: Router,
    private appService: AppConfigService,
    private bsLocaleService: BsLocaleService
  ) {
    this._portal = loginService instanceof LoginPortalService;
    if (this._portal) {
      if (appService.kuke) {
        // also change value in abstract-service
        this.sessionTimer = new SessionTimer(60 * 60);
      } else {
        this.sessionTimer = new SessionTimer(30 * 60);
      }
    }
  }

  get portal() {
    return this._portal;
  }

  getLoggedUserData(): UserDto {
    return this.loggedUser;
  }

  getLoggedUserDefaultModuleId(): number {
    return this.loggedUser.defaultModule && this.loggedUser.defaultModule.id;
  }

  isThisUserOrBelongsToGroup(userId: number, groupId: number): boolean {
    return this.isThisUser(userId) && this.belongsToGroup(groupId);
  }

  belongsToGroup(groupId: number): boolean {
    return this.loggedUser.groups && this.loggedUser.groups.filter((g) => g.id === groupId).length > 0;
  }

  belongsToProfileCategoryGroup(categoryId: number): boolean {
    return (
      this.loggedUser.groups &&
      this.loggedUser.groups.filter((g) => g.profileCategory && g.profileCategory.id === categoryId).length > 0
    );
  }

  isThisUser(userId: number) {
    return this.loggedUser.id === userId;
  }

  get accessToken() {
    return this._accessToken;
  }

  setAccessToken(token: string) {
    this._accessToken = token;
  }

  setToken(tokenData: GetTokenResultDto) {
    this._accessToken = tokenData.token;
    this._refreshToken = tokenData.refreshToken;
    this._refreshTokenSubscription = timer(tokenData.accessTokenExpiresIn * 750)
      .pipe(switchMap(() => this.loginService.refreshAccessToken(this.loggedUser.login, this._refreshToken)))
      .subscribe({
        next: (newTokenData) => this.setToken(newTokenData),
        error: (err) =>
          console.error('Unexpected error when trying to refresh accessToken. Details: ' + JSON.stringify(err)),
      });
  }

  isLoggedIn(): boolean {
    return !!this._accessToken;
  }

  clearToken() {
    console.log('clear login token ...');
    this._accessToken = null;
    this._refreshToken = null;
    if (this._refreshTokenSubscription) {
      this._refreshTokenSubscription.unsubscribe();
    }
  }

  hasRight(right: ElementaryRight | string): boolean {
    const rightCode = typeof right === 'string' ? right : ElementaryRight[right];
    return this._userRightCodes.indexOf(rightCode) > -1;
  }

  hasAllRights(rights: (ElementaryRight | string)[]): boolean {
    for (const right of rights) {
      if (!this.hasRight(right)) {
        return false;
      }
    }
    return true;
  }

  hasAnyRight(rights: string[]): boolean {
    return rights.findIndex((right) => this._userRightCodes.includes(right)) > -1;
  }

  login(
    login: string,
    password: string,
    emailCode?: string,
    answer?: string,
    agreements?: ExtranetClientAgreementType[]
  ): Observable<UserDto> {
    const tokenParam = <GetTokenParamDto>{
      login: login,
      password: password,
      emailCode: emailCode,
      activationQuestionAnswer: answer,
      agreements: agreements,
    };
    return this.loadUser(true, tokenParam);
  }

  handleUser(user: UserDto) {
    moment.locale(user.language.code);
    this.bsLocaleService.use(user.language.code);
    this.loggedUser = user;
    this.setUserRightCodes(user);
    this.loggedUsersSubject.next(user);
  }

  refreshUserLogged(user: UserDto) {
    this.loggedUser = user;
    this.setUserRightCodes(user);
    this.loggedUsersSubject.next(user);
  }

  private loadUser(saveToken: boolean, dto: GetTokenParamDto): Observable<UserDto> {
    let loginObservable: Observable<GetTokenResultDto>;
    loginObservable = this.loginService.doLogin(dto);
    return loginObservable.pipe(
      map((getTokenResultDto) => {
        this.handleUserAndToken(getTokenResultDto, saveToken);
        return getTokenResultDto.userDto;
      }),
      catchError((error: any) => this.handleError(error))
    );
  }

  public handleUserAndToken(getTokenResultDto: GetTokenResultDto, saveToken: boolean) {
    if (getTokenResultDto.redirect && getTokenResultDto.redirectType === 'PASSWORD') {
      this.router.navigate([
        'resetPassword',
        getTokenResultDto.redirectData.login,
        getTokenResultDto.redirectData.expiryTime,
        getTokenResultDto.redirectData.hash,
        getTokenResultDto.redirectData.lang,
      ]);
      throw new Error('Redirect to reset password page');
    }
    if (getTokenResultDto.redirectType === 'RULES') {
      throw new Error('RULES');
    }
    if (getTokenResultDto.redirectType === 'QUESTION') {
      const error = new Error('QUESTION');
      error.stack = getTokenResultDto.activationQuestion;
      throw error;
    }
    if (!getTokenResultDto) {
      throw new Error('No user token');
    }
    if (getTokenResultDto.errorCode) {
      throw new Error(getTokenResultDto.errorCode);
    }
    if (!getTokenResultDto || !getTokenResultDto.userDto || !getTokenResultDto.token) {
      throw new Error('No user token details');
    }
    if (this._refreshTokenSubscription) {
      this._refreshTokenSubscription.unsubscribe();
    }
    this.setToken(getTokenResultDto);
    return this.handleUser(getTokenResultDto.userDto);
  }

  private handleError(error: any) {
    this.clearToken();
    return throwError(() => error || 'Login error');
  }

  private setUserRightCodes(user: UserDto) {
    if (user) {
      this._userRightCodes = [];
      for (const r of user.rights) {
        this._userRightCodes.push(r.code);
      }
    }
  }

  getUser(): Observable<UserDto> {
    return this.loggedUsersSubject.asObservable();
  }

  isReloadNecessary(): boolean {
    const tmp: boolean = this.reloadNecessary;
    this.reloadNecessary = false;
    return tmp;
  }

  setReloadNecessary(reloadNecessary: boolean) {
    this.reloadNecessary = reloadNecessary;
  }

  public hasLoggedUserRole(role: number) {
    if (this.loggedUser) {
      for (const r of this.loggedUser.roles) {
        if (r.id === role) {
          return true;
        }
      }
    }
    return false;
  }

  longLabelLanguage(): boolean {
    const user: UserDto = this.getLoggedUserData();
    return user && user.language && (user.language.id === Language.GERMAN || user.language.id === Language.POLISH);
  }

  public isUserInGroup(groupId: number): boolean {
    return (
      this.getLoggedUserData().groups &&
      this.getLoggedUserData()
        .groups.map((gr) => gr.id)
        .includes(groupId)
    );
  }

  isCollectionUser(): boolean {
    return this.belongsToProfileCategoryGroup(
      this.appService.kuke ? ProfileCategory.KUKE_COLLECTIONS : ProfileCategory.CREDENDO_COLLECTION
    );
  }

  isHRRole(): boolean {
    return (
      this.hasLoggedUserRole(UserRole.COUNTRY_MANAGER) ||
      this.hasLoggedUserRole(UserRole.DIRECT_MANAGER) ||
      this.hasLoggedUserRole(UserRole.BOARD_MANAGER) ||
      this.hasLoggedUserRole(UserRole.HR_MANAGER)
    );
  }
}
