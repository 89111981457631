import {Component, Input, SkipSelf} from '@angular/core';
import {BondingContractInquiryClientInformationDto} from '../../../../bonding_shared/model';
import {ControlContainer, NgModelGroup} from '@angular/forms';

@Component({
  selector: 'bonding-contract-inquiry-section-client-information',
  templateUrl: './bonding-contract-inquiry-section-client-information.pug',
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class BondingContractInquirySectionClientInformationComponent {
  @Input() showErrors = false;
  @Input() newInquiry = false;

  clientInfo: BondingContractInquiryClientInformationDto;
  subsidiariesSwitch: boolean;
  limitsSwitch: boolean;
  claimsSwitch: boolean;
  capitalGroupSwitch: boolean;
  guarantiesSwitch: boolean;
  creditsSwitch: boolean;

  @Input() set clientInformation(ci: BondingContractInquiryClientInformationDto) {
    this.clientInfo = ci;
    this.capitalGroupSwitch = !this.newInquiry
      ? this.clientInfo.capitalGroup && !!this.clientInfo.capitalGroup.name
      : undefined;
    this.subsidiariesSwitch = !this.newInquiry ? this.clientInfo.subsidiaries?.length > 0 : undefined;
    this.claimsSwitch = !this.newInquiry
      ? this.clientInfo.claims?.length > 0 || this.clientInfo.claimsAsAttachment
      : undefined;
    this.limitsSwitch = !this.newInquiry
      ? this.clientInfo.limits?.length > 0 || this.clientInfo.limitsAsAttachment
      : undefined;
    this.creditsSwitch = !this.newInquiry
      ? this.clientInfo.credits?.length > 0 || this.clientInfo.creditsAsAttachment
      : undefined;
    this.guarantiesSwitch = !this.newInquiry
      ? this.clientInfo.grantedGuaranties?.length > 0 || this.clientInfo.grantedGuarantiesAsAttachment
      : undefined;
  }

  constructor(public form: NgModelGroup) {}

  updateSwitches() {
    this.capitalGroupSwitch = undefined;
    this.subsidiariesSwitch = undefined;
    this.claimsSwitch = undefined;
  }

  label(label: string): string {
    return 'bondingContractInquiry.details.clientInformation.' + label;
  }

  capitalGroupSwitched(v: boolean) {
    this.capitalGroupSwitch = v;
    if (!v) {
      this.clientInfo.capitalGroup.members = [];
      this.clientInfo.capitalGroup.name = undefined;
    }
  }

  subsidiariesSwitched(v: boolean) {
    this.subsidiariesSwitch = v;
    if (!v) {
      this.clientInfo.subsidiaries = [];
    }
  }

  limitsSwitched(v: boolean) {
    this.limitsSwitch = v;
    if (!v) {
      this.updateLimits();
      this.clientInfo.limitsAsAttachment = v;
    }
  }

  updateLimits() {
    this.clientInfo.limits = [];
  }

  claimsSwitched(v: boolean) {
    this.claimsSwitch = v;
    if (!v) {
      this.updateClaims();
      this.clientInfo.claimsAsAttachment = v;
    }
  }

  updateClaims() {
    this.clientInfo.claims = [];
  }

  guarantiesSwitched(v: boolean) {
    this.guarantiesSwitch = v;
    if (!v) {
      this.updateGuaranties();
      this.clientInfo.grantedGuarantiesAsAttachment = v;
    }
  }

  updateGuaranties() {
    this.clientInfo.grantedGuaranties = [];
    this.clientInfo.totalGuaranty = undefined;
  }

  creditsSwitched(v: boolean) {
    this.creditsSwitch = v;
    if (!v) {
      this.updateCredits();
      this.clientInfo.creditsAsAttachment = v;
    }
  }

  updateCredits() {
    this.clientInfo.credits = [];
  }

  updateCompletedContracts(v: boolean) {
    if (!v) {
      this.clientInfo.completedContracts = [];
    }
  }

  updateOngoingContracts(v: boolean) {
    if (!v) {
      this.clientInfo.ongoingContracts = [];
    }
  }
}
