import {map} from 'rxjs/operators';
import {Component, Input} from '@angular/core';
import {AppConfigService, DashboardService, DictionaryBaseDto, StringUtils} from '../../../../bonding_shared';
import {ChartDataset, ChartOptions, ChartType} from 'chart.js';
import {ChartUtils} from '../chart-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'rating-limit',
  templateUrl: 'rating-limit.component.pug',
})
export class RatingLimitComponent {
  @Input() set businessUnit(businessUnit: DictionaryBaseDto) {
    this._businessUnit = businessUnit;
    this.initialized = false;
    this.initChartOptions();
    this.loadRestData();
  }
  _businessUnit: DictionaryBaseDto;
  rating: string[] = [];
  exposure: number[] = [];
  buyerCount: number[] = [];

  public chartOptions: ChartOptions;
  public chartType: ChartType = 'bar';
  public data: ChartDataset[];
  public labels: string[];
  initialized = false;

  public get mehib(): boolean {
    return this.appService.mehib;
  }

  constructor(
    private service: DashboardService,
    public translateService: TranslateService,
    public appService: AppConfigService
  ) {}

  loadData() {
    this.labels = this.rating;
    this.data = [
      {
        data: this.buyerCount,
        label: this.translateService.instant('limitDashboard.buyerCount'),
        yAxisID: 'yAxisLeft',
        backgroundColor: ChartUtils.COLORS[0],
      },
      {
        data: this.exposure,
        label: this.translateService.instant('limitDashboard.exposure'),
        yAxisID: 'yAxisRight',
        type: 'line',
        fill: false,
        borderColor: ChartUtils.COLORS[1],
        backgroundColor: ChartUtils.COLORS[1],
      },
    ];
    this.initialized = true;
  }

  loadRestData() {
    this.rating = [];
    this.exposure = [];
    this.buyerCount = [];
    this.service
      .getRatingChartDataLimit(this._businessUnit)
      .pipe(
        map((results: Array<any>) => {
          if (results) {
            results.forEach((result) => {
              this.rating.push(result.rating.name);
              this.buyerCount.push(result.buyerCount);
              this.exposure.push(result.exposure);
            });
          }
        })
      )
      .subscribe({
        next: () => console.log('Empty subscribe'),
        error: () => console.log('Error occured while getting Rating Chart Limit data'),
        complete: () => this.loadData(),
      });
  }

  initChartOptions() {
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const label = context.dataset.label || '';
              const value = <number>context.dataset.data[context.dataIndex];
              const valueF = context.dataset.yAxisID === 'yAxisRight' ? StringUtils.formatNumber(value) : value;
              return label + ': ' + valueF;
            },
          },
        },
      },
      scales: {
        yAxisLeft: {
          position: 'left',
          type: 'linear',
          beginAtZero: true,
          title: {
            display: true,
            text: this.translateService.instant('dashboard.count'),
          },
        },
        yAxisRight: {
          position: 'right',
          type: 'linear',
          display: true,
          beginAtZero: true,
          ticks: {
            callback: function (label, index, labels) {
              return StringUtils.formatNumber(<number>label);
            },
          },
          title: {
            display: true,
            text: this.translateService.instant(
              'limitDashboard.exposureWithCurr',
              this.mehib ? {currencyCode: 'HUF'} : {currencyCode: 'EUR'}
            ),
          },
        },
      },
    };
  }
}
