import {Component, OnInit} from '@angular/core';
import {CacheService, ContractService, GrowlService, RouterService} from '../../../../bonding_shared/services';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {ActivatedRoute} from '@angular/router';
import {ExternalLimitDto} from '../../../../bonding_shared/model';
import {LimitViewParams} from '../../limit-details.component';

@Component({
  selector: 'external-limit',
  templateUrl: './external-limit.component.pug',
})
export class ExternalLimitComponent extends DetailsView implements OnInit {
  contractId: number;

  limits: ExternalLimitDto[];
  limit: ExternalLimitDto;

  constructor(
    private contractService: ContractService,
    public router: RouterService,
    private route: ActivatedRoute,
    protected growlService: GrowlService,
    protected cacheService: CacheService
  ) {
    super(growlService);
  }

  ngOnInit() {
    this.commonInit<LimitViewParams>(this.route);
  }

  initViewWithId(id: number) {
    this.contractId = id;
    this.contractService.getExternalLimits(this.contractId).subscribe((limits) => {
      this.limits = limits;
      this.limit = this.limits[0];
    });
  }
}
