import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';
import {CustomFormModule} from '../../../common/custom-form/custom-form.module';
import {PolicyClauseDetailsComponent} from './components/policyclause-details.component';
import {HtmlEditorModule} from '../../../bonding_shared/components/html-editor/html-editor.module';
import {PolicyClauseListComponent} from './components/policyclause-list.component';
import {PolicyClauseDetailsGuard} from './_guards';
import {PolicyClauseTypeSearchComponent} from './components/policyclausetype-search.component';
import {PolicyClauseTypeGuiService} from './services/policyclausetype-gui.service';
import {PolicyClauseTypePreviewComponent} from './components/policyclausetype-preview.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormModule,
    HtmlEditorModule,
  ],
  exports: [PolicyClauseListComponent],
  declarations: [
    PolicyClauseDetailsComponent,
    PolicyClauseListComponent,
    PolicyClauseTypePreviewComponent,
    PolicyClauseTypeSearchComponent,
  ],
  providers: [PolicyClauseDetailsGuard, PolicyClauseTypeGuiService],
})
export class PolicyClauseModule {}
