import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {NgForm, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {map} from 'rxjs/operators';
import {
  AddressDto,
  CompanyRawDto,
  DictionaryBaseDto,
  NumberFormatDto,
  PhysicalPersonDto,
} from '../../../../../bonding_shared/model';
import {AppConfigService, DictionaryService, NumberFormatService} from '../../../../../bonding_shared/services';
import {
  Country,
  DictionaryPropertyType,
  Field,
  LegalForm,
  NumberType,
} from '../../../../../bonding_shared/model/dictionary-ids';
import {StringUtils} from '../../../../../bonding_shared/utils';
import {CompanyDetailsComponent} from '../../../company-details.component';
import * as _ from 'lodash';
import {CustomValidators} from '../../../../../bonding_shared/validators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'company-order-report',
  templateUrl: './company-order-report.component.pug',
})
export class CompanyOrderReportComponent implements OnInit {
  public nationalIdNumberFormat: NumberFormatDto;
  public vatNumberFormat: NumberFormatDto;
  public statIdFormat: NumberFormatDto;
  company = <CompanyRawDto>{address: {}};
  @Input() open: Subject<boolean>;
  @Input() title: string;
  @Input() closeable = true;
  @Input() fixedCountry = false;
  @Input() physicalPerson = false;
  @Input() showAllFields = false;
  _showPersonalData: boolean;

  @Input() set countryId(countryId: number) {
    if (countryId) {
      this.dictService.getDictionaryEntry('Country', countryId).subscribe((c) => {
        this.company.address.country = c;
        this.refreshValidators(c);
      });
    }
  }

  @Output() dataEntered = new EventEmitter<CompanyRawDto>();
  @Output() searchClicked = new EventEmitter<void>();
  @Output() cancelClicked = new EventEmitter<void>();

  fieldsRequired = {};
  form: UntypedFormGroup;
  showErrors = false;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  readonly StringUtils = StringUtils;

  constructor(
    protected dictService: DictionaryService,
    private fb: UntypedFormBuilder,
    private numberFormatService: NumberFormatService,
    protected translateService: TranslateService,
    private appService: AppConfigService
  ) {}

  ngOnInit(): void {
    console.log('showPersonalData0' + this.showPersonalData);
    this.form = this.ngForm.form;
    this.form.validator = this.anyIdSetValidator.bind(this);
  }

  @Input() set companyData(c: CompanyRawDto) {
    if (c) {
      // make copy
      c = JSON.parse(JSON.stringify(c));
    }
    this.company = c;
    if (this.company) {
      if (!this.company.address) {
        this.company.address = <AddressDto>{};
      }
      if (!this.company.physicalPerson) {
        this.company.physicalPerson = <PhysicalPersonDto>{};
      }
      if (this.company.address.country) {
        this.countryId = this.company.address.country.id;
      }
      if (this.showPersonalData) {
        this.company.legalForm = <DictionaryBaseDto>{id: LegalForm.FP010};
      }
    }
  }

  @Input()
  set showPersonalData(s: boolean) {
    this._showPersonalData = s;
    if (s && this.company) {
      this.company.legalForm = <DictionaryBaseDto>{id: LegalForm.FP010};
    }
  }

  get showPersonalData() {
    return this._showPersonalData;
  }

  submitClicked() {
    if (this.form.valid) {
      this.showErrors = false;
      if (this.appService.kuke && this.showAllFields && this.form.get('personalIdentity').value === undefined) {
        this.company.legalForm = undefined;
      }
      this.dataEntered.emit(this.company);
      this.open.next(false);
    } else {
      StringUtils.logFormInvalidFieldsRecursive(this.form);
      this.showErrors = true;
    }
  }

  refreshValidators(country: DictionaryBaseDto) {
    this.loadRequiredFields();
    if (!country) {
      return;
    }
    this.numberFormatService.getNumberFormat(country.id, NumberType.NATIONALID).subscribe(
      (numberFormat) => {
        this.nationalIdNumberFormat = numberFormat;
      },
      () => {},
      () => this.updateNationalIdValidator()
    );
    this.numberFormatService.getNumberFormat(country.id, NumberType.TAXID).subscribe(
      (numberFormat) => (this.vatNumberFormat = numberFormat),
      () => {},
      () => this.updateTaxValidator()
    );
    this.numberFormatService.getNumberFormat(country.id, NumberType.REGISTRYNUMBER).subscribe(
      (numberFormat) => (this.statIdFormat = numberFormat),
      () => {},
      () => this.updateStatNoValidator()
    );
  }

  private updateStatNoValidator(): void {
    if (this.statIdFormat) {
      if (!_.isEmpty(this.statIdFormat.regExp)) {
        this.form.controls['statId'].validator = Validators.compose([
          CustomValidators.numberFormatValidator(this.statIdFormat, false),
        ]);
      } else {
        this.form.controls['statId'].validator = null;
      }
      this.form.controls['statId'].updateValueAndValidity();
    }
  }

  private updateTaxValidator(): void {
    if (this.vatNumberFormat) {
      if (!_.isEmpty(this.vatNumberFormat.regExp)) {
        if (this.showPersonalData || this.isFarmerValidation()) {
          this.form.controls['vatNumber'].validator = Validators.compose([
            CustomValidators.numberFormatValidator(this.vatNumberFormat, true),
          ]);
        } else {
          this.form.controls['vatNumber'].validator = Validators.compose([
            Validators.required,
            CustomValidators.numberFormatValidator(this.vatNumberFormat, false),
          ]);
        }
      } else {
        this.form.controls['vatNumber'].validator = null;
      }
      this.form.controls['vatNumber'].updateValueAndValidity();
    }
  }

  private updateNationalIdValidator(): void {
    if (this.nationalIdNumberFormat) {
      if (!_.isEmpty(this.nationalIdNumberFormat.regExp)) {
        if (this.showPersonalData || this.isFarmerValidation()) {
          this.form.controls['nationalId'].validator = Validators.compose([
            CustomValidators.numberFormatValidator(this.nationalIdNumberFormat, true),
          ]);
        } else {
          this.form.controls['nationalId'].validator = Validators.compose([
            Validators.required,
            CustomValidators.numberFormatValidator(this.nationalIdNumberFormat, false),
          ]);
        }
      } else {
        this.form.controls['nationalId'].validator = null;
      }
      this.form.controls['nationalId'].updateValueAndValidity();
    }
  }

  loadRequiredFields() {
    if (this.company.address.country) {
      this.fieldsRequired = {};
      this.setRequired(Field.BI_REGISTRATION_NAME, 'registrationName');
      this.setRequired(Field.BI_NATIONAL_ID, 'nationalId');
      this.setRequired(Field.BI_TAX_ID, 'vatNumber');
      this.setRequired(Field.BI_STAT_ID, 'statId');
      this.setRequired(Field.BI_ZIP_CODE, 'zipCode');
      this.setRequired(Field.BI_TOWN, 'town');
      this.setRequired(Field.BI_STREET, 'street');
    }
  }

  setRequired(id: Field, control: string) {
    this.dictService
      .getDictionaryEntry('Field', id, [this.company.address.country.id])
      .pipe(map((dict) => dict.properties[DictionaryPropertyType.FIELD_REQUIRED] === 'true'))
      .subscribe((req) => {
        this.fieldsRequired[control] = req;
      });
  }

  anyIdSetValidator(group: UntypedFormGroup): ValidationErrors | null {
    if (!group.get('personalIdentity') || !group.get('vatNumber') || !this.isFarmerValidation()) {
      // if personalIdentity field is not visible skip validation
      return null;
    }
    const personalIdAndVatNo = group.get('personalIdentity').value && group.get('vatNumber').value;
    const neitherPersonalIdNorVatNo = !group.get('personalIdentity').value && !group.get('vatNumber').value;
    if (personalIdAndVatNo || neitherPersonalIdNorVatNo) {
      return {personalIdOrTaxIdRequired: true};
    }
    return null;
  }

  preventSpaces(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  }

  public trimNationalIdSpaces(event: ClipboardEvent): void {
    this.company.nationalId = CompanyDetailsComponent.trimWhitespaces(event);
  }

  public trimVatSpaces(event: ClipboardEvent): void {
    this.company.vatNumber = CompanyDetailsComponent.trimWhitespaces(event);
  }

  public isFarmerValidation(): boolean {
    const address = this.company?.address;
    return address?.country?.id === Country.PL;
  }
}
