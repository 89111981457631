import {Component, ContentChild, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ATableComponent} from '../../aku-table/a-table.component';
import {SearchDataProvider} from '../../../services/search-data-provider';
import {Subject} from 'rxjs';
import {AbstractControl} from '@angular/forms';
import {ErrorReason, SearchResult} from '../../../model';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from '../../../services';

/**
 * Created by wilk on 15.05.2017.
 */

@Component({
  selector: 'simple-selector',
  templateUrl: './simple-selector.component.pug',
})
export class SimpleSelectorComponent<C, R> implements OnInit {
  @ContentChild(ATableComponent, {static: true}) resultList: ATableComponent<R>;

  _searchTextInput: any;

  @ViewChild('searchTextInput')
  set searchTextInput(searchTextInput: any) {
    this._searchTextInput = searchTextInput;
  }

  get searchTextInput(): any {
    return this._searchTextInput;
  }

  @Input() title = 'Give me a title, please';
  @Input() labelKey: string;
  @Input() noResultWarningKey = 'common.noResultFound';
  @Input() showNoResultWarningOnError = false;

  @Input() pageSize = 10;

  /**
   * Open selector event emitter
   * The same emitter instance should be injected into this component and the component that opens this selector
   */
  @Input() open: Subject<boolean>;

  /**
   * to be able to switch between advanced/basic search mode
   */
  _searchModeSwitchable = false;

  @Input() set searchModeSwitchable(value: boolean) {
    this._searchModeSwitchable = value;
  }

  get searchModeSwitchable(): boolean {
    // IKI 3305
    return this._searchModeSwitchable && !this.appService.kuke;
  }

  /**
   * when fixedSearch = true, search is done by injected, predefined criteria, user cannot modify them
   */
  @Input() fixedSearch = false;

  // /**
  //  * When uniqueResultNotShown = true, selector is shown only if there exist not unique search results or no results,
  //  * For unique result selector is not shown and the unique result is to be emitted as selectedItem
  //  * Setting this flag makes sense only for fixedSearch = true and searchOnOpen = true
  //  */
  // @Input() uniqueResultNotShown = false;

  @Input() showSearchInput = true;
  @Input() searchOnOpen = true;
  @Input() closeable = true;
  @Input() showErrors: boolean;
  @Input() control: AbstractControl;
  @Input() searchButtonTitle = 'common.button.search';
  /**
   * provided items are displayed in selector, if items are injected no data provider is needed,
   */
  @Input() set items(items: R[]) {
    this.resultList.items = items;
  }

  /**
   * if dataProvider is injected search is done by the criteria from dataProvider
   */
  @Input() set dataProvider(dp: SearchDataProvider<C, R>) {
    this.resultList.dataProvider = dp;
  }

  get dataProvider(): SearchDataProvider<C, R> {
    return <SearchDataProvider<C, R>>this.resultList.dataProvider;
  }

  @Input() visible = false;

  frontendErrorMsg: string;

  constructor(
    private renderer: Renderer2,
    private translateService: TranslateService,
    private appService: AppConfigService
  ) {}

  get searchDisabled(): boolean {
    if (this.dataProvider && this.dataProvider.inProgress) {
      return true;
    }
    if (
      this.dataProvider &&
      this.dataProvider.textSearch &&
      (!this.dataProvider.textSearchCriteria.text || this.dataProvider.textSearchCriteria.text.length < 1)
    ) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    console.log('open: ', this.open);
    if (this.open) {
      this.open.subscribe((event: boolean) => {
        this.openSearchBox(event);
      });
    }
    if (this.resultList) {
      this.resultList.pageSize = this.pageSize;
      this.resultList.pagination = true;
      this.resultList.canUnselect = false;
      this.resultList.selection = true;
      this.resultList.dataProviderSearchFinished.subscribe((searchResult: SearchResult<R>) => {
        this.onSearchFinished(searchResult);
      });
      this.resultList.dataProviderSearchError.subscribe(() => {
        this.onSearchError();
      });
    } else {
      console.log('problem when setting a-table properties');
    }
  }

  openSearchBox(open: boolean) {
    console.log('open search box: ', open);
    this.frontendErrorMsg = undefined;
    this.visible = open;

    if (this.visible) {
      this.resultList.selectedItem = undefined;
      this.resultList.selectedItemId = undefined;
      if (this.dataProvider) {
        if (this.dataProvider.textSearch) {
          // focus can be set after the element becomes visible
          // ANGULAR MIGRATION TURN OFF NATIVE ELEMENT
          if (this.searchTextInput) {
            setTimeout(() => this.searchTextInput.nativeElement.focus(), 0);
          }
        } else if (this.searchOnOpen) {
          this.resultList.search();
        }
      }
    }
  }

  hide() {
    this.open.next(false);
    // this.visible = false;
  }

  onSearch() {
    if (this.dataProvider.textSearch && !this.textInputValid()) {
      return;
    }
    this.resultList.search();
  }

  textInputValid(): boolean {
    this.frontendErrorMsg = undefined;
    const text = (<SearchDataProvider<C, R>>this.resultList.dataProvider).textSearchCriteria.text;
    if (!text || text.length < 1) {
      return false;
    } else if (text.length < 3) {
      this.frontendErrorMsg = this.translateService.instant('errorMessage.notEnoughCharacters');
      return false;
    }
    return true;
  }

  switchSearchMode() {
    this.dataProvider.switchSearchMode();
    this.frontendErrorMsg = undefined;
  }

  private onSearchFinished(searchResult: SearchResult<R>): void {
    if (!searchResult.result || searchResult.result.length === 0) {
      this.addWarning(this.translateService.instant(this.noResultWarningKey));
    }
  }

  private onSearchError(): void {
    if (this.showNoResultWarningOnError) {
      this.addWarning(this.translateService.instant(this.noResultWarningKey));
    }
  }

  private addWarning(message: string): void {
    if (!Array.isArray(this.resultList.errorMessage)) {
      this.resultList.errorMessage = [];
    }
    this.resultList.errorMessage.push(<ErrorReason>{
      severity: 'WARNING',
      message: message,
    });
  }
}
