import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {
  PolicyContractClaimsStatsDto,
  PolicyContractDeclarationsConfigurationDto,
  PolicyContractDto,
  PolicyContractIdDto,
  PolicyContractPreviewDto,
  PolicyContractSimpleIdDataDto,
  PolicyContractTechnicalDetailsDto,
  PolicyContractVersionDto,
  SearchResult,
  TaskDto,
} from '../model';
import {PortalPolicyContractService} from '../../portal/services/portal-policy-contract.service';

@Injectable()
export class PolicyContractService extends PortalPolicyContractService {
  protected url = this.urlPrefix + 'policyContract';

  static toPolicyContractSimpleIdDto(policyContract: PolicyContractDto): PolicyContractSimpleIdDataDto {
    return <PolicyContractSimpleIdDataDto>{
      id: policyContract.id,
      number: policyContract.number,
      yearNumber: policyContract.yearNumber,
      productType: policyContract.masterPolicyContract.productType,
      validFrom: policyContract.validFrom,
      validTo: policyContract.validTo,
    };
  }

  constructor(public http: HttpClient, appConfigService: AppConfigService, _loggedUserService: LoggedUserService) {
    super(http, appConfigService, _loggedUserService);
  }

  getPolicyContract(id: number): Observable<PolicyContractDto> {
    console.log('contract details: id = ' + id);
    return this.get<PolicyContractDto>(this.url + '/' + id);
  }

  getPolicyContractIdDto(id: number): Observable<PolicyContractIdDto> {
    console.log('contract details: id = ' + id);
    return this.get<PolicyContractIdDto>(this.url + '/policyContractId/' + id);
  }

  getPolicyContractAnnexTemplate(id: number): Observable<PolicyContractVersionDto> {
    return this.get<PolicyContractVersionDto>(this.url + '/' + id + '/newVersion');
  }

  getPolicyContractReplaceAnnexTemplate(id: number): Observable<PolicyContractVersionDto> {
    return this.get<PolicyContractVersionDto>(this.url + '/' + id + '/newReplaceVersion');
  }

  getNewPolicyYearTemplate(id: number): Observable<PolicyContractVersionDto> {
    return this.get<PolicyContractVersionDto>(this.url + '/' + id + '/newPolicyYear');
  }

  getInitialContractVersion(busienssUnitId: number, countryId: number, policyContractTypeId: number) {
    return this.get<PolicyContractVersionDto>(
      this.url +
        '/initialVersion/' +
        busienssUnitId +
        '/country/' +
        countryId +
        '/policyContractType/' +
        policyContractTypeId
    );
  }

  getActiveOrLastNotErrorPolicyContractVersion(policyContractId: number): Observable<PolicyContractVersionDto> {
    const query = this.url + '/' + policyContractId + '/activeOrLastNotErrorVersion/';
    return this.get<PolicyContractVersionDto>(query);
  }

  getCompanyContracts(clientId: string): Observable<SearchResult<PolicyContractDto>> {
    // return this.http.get(this._contractUrl + '?clientId=' + clientId + '&start=' + start + '&count=' + count)
    return this.get<SearchResult<PolicyContractDto>>(this.url + '?clientId=' + clientId);
  }

  searchPolicyContracts(text: string, start: number, count: number): Observable<SearchResult<PolicyContractDto>> {
    return this.get<SearchResult<PolicyContractDto>>(
      this.url + '/search?text=' + text + '&start=' + start + '&count=' + count
    );
  }

  createPolicyContract(contract: PolicyContractDto): Observable<PolicyContractDto> {
    console.log('crete contract:' + JSON.stringify(contract));
    return this.put<PolicyContractDto>(contract, this.url);
  }

  updateContract(contract: PolicyContractDto): Observable<PolicyContractDto> {
    console.log('update contract: id = ' + contract.id);
    return this.post<PolicyContractDto>(contract, this.url);
  }

  deleteContract(id: number): Observable<PolicyContractDto> {
    console.log('delete contract: id = ' + id);
    return this.delete<PolicyContractDto>(this.url + '/' + id);
  }

  getPolicyContractClaimsStats(id: number): Observable<PolicyContractClaimsStatsDto> {
    return this.get<PolicyContractClaimsStatsDto>(this.url + '/claimsStats/' + id);
  }

  getTechnicalDetails(policyContractId: number): Observable<PolicyContractTechnicalDetailsDto> {
    return this.get<PolicyContractTechnicalDetailsDto>(this.url + '/technicalDetails/' + policyContractId);
  }

  updateTechnicalDetails(
    technicalDetails: PolicyContractTechnicalDetailsDto
  ): Observable<PolicyContractTechnicalDetailsDto> {
    return this.post<PolicyContractTechnicalDetailsDto>(technicalDetails, this.url + '/technicalDetails');
  }

  getMaxPolicyYear(policyContractId: number): Observable<number> {
    let maxYearUrl = this.url + '/maxPolicyYear';
    if (policyContractId) {
      maxYearUrl = maxYearUrl + '?id=' + policyContractId;
    }
    return this.get<number>(maxYearUrl);
  }

  createTask(policyContractId: number, taskTypeId: number): Observable<TaskDto> {
    return this.get<TaskDto>(this.url + '/createPolicyContractTask/' + policyContractId + '/taskType/' + taskTypeId);
  }

  getPreview(id: number): Observable<PolicyContractPreviewDto> {
    return this.get<PolicyContractPreviewDto>(this.url + '/preview/' + id);
  }

  getReportingPeriods(policyContractId: number) {
    return this.get<PolicyContractDeclarationsConfigurationDto>(this.url + '/reportingPeriods/' + policyContractId);
  }

  saveReportingPeriods(config: PolicyContractDeclarationsConfigurationDto) {
    return this.post<PolicyContractDeclarationsConfigurationDto>(config, this.url + '/reportingPeriods/');
  }

  reinitializeReportingPeriods(config: PolicyContractDeclarationsConfigurationDto) {
    return this.post<PolicyContractDeclarationsConfigurationDto>(
      config,
      this.url + '/reportingPeriods/reinitializeReportingPeriods'
    );
  }
}
