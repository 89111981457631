import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  BankAccountDto,
  CollectionHistoryEntryDto,
  CollectionSimpleDto,
  CollectionVersionBaseDto,
  CollectionVersionCriteriaDto,
  CollectionVersionDto,
  CollectionVersionSimpleDto,
  SearchCriteria,
  SearchResult,
  StateTransitionOrGroupDto,
  UserSimpleDto,
} from '../model';

@Injectable()
export class CollectionVersionService extends AbstractService {
  protected url = this.urlPrefix + 'collectionVersion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  initialVersion(parent: CollectionSimpleDto): Observable<CollectionVersionDto> {
    return this.post1<CollectionSimpleDto, CollectionVersionDto>(parent, this.url + '/initialVersion/');
  }

  getTransitions(version: CollectionVersionDto): Observable<StateTransitionOrGroupDto[]> {
    return this.get(this.url + '/transition/' + version.parent.type.id);
  }

  getVersionsByParentId(parentId: number): Observable<SearchResult<CollectionVersionBaseDto>> {
    const criteria = <SearchCriteria<CollectionVersionCriteriaDto>>{criteria: {parent: {id: parentId}}};
    return this.searchByCriteria(criteria);
  }

  getLastVersionSimpleByParentId(parentId: number): Observable<CollectionVersionSimpleDto> {
    return this.get(this.url + '/lastVersionSimple/' + parentId);
  }

  accept(id: number): Observable<CollectionVersionDto> {
    return this.postEmpty(this.url + '/' + id + '/accept/');
  }

  reject(id: number): Observable<CollectionVersionDto> {
    return this.postEmpty(this.url + '/' + id + '/reject/');
  }

  cancelWithClaimWaiver(dto: CollectionVersionDto): Observable<CollectionVersionDto> {
    return this.post1(dto, this.url + '/cancelWithClaimWaiver/');
  }

  cancelWithoutClaimWaiver(id: number): Observable<CollectionVersionDto> {
    return this.postEmpty(this.url + '/' + id + '/cancelWithoutClaimWaiver/');
  }

  changeBankAccount(parentId: number, data: BankAccountDto): Observable<CollectionVersionDto> {
    return this.post1(data, this.url + '/changeBankAccount/' + parentId);
  }

  loadUsersWithAvailableQuota(id: number, quotaTypeId: number): Observable<SearchResult<UserSimpleDto>> {
    return this.get<SearchResult<UserSimpleDto>>(this.url + '/' + id + '/usersWithAvailableQuota/' + quotaTypeId);
  }

  getCollectionHistory(id: number): Observable<CollectionHistoryEntryDto[]> {
    return this.get<CollectionHistoryEntryDto[]>(this.url + '/' + id + '/history');
  }
}
