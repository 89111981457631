import {
  BondingContractInquiryVersionDto,
  CollectionSimpleDto,
  CompanyDto,
  LimitRequestDto,
  PolicyInquiryCompanyBaseDto,
  PolicyInquiryCompanyDto,
  PolicyInquiryVersionDto,
  ProductLimitListVersionDto,
  ThirdPartyBaseDto,
} from '../model';
import {Injectable} from '@angular/core';
import {BusinessUtils} from '../utils/business-utils';
import {PolicyInquiryCompanyRole} from '../model/dictionary-ids';
import {DictionaryBaseService} from './dictionary-base.service';

@Injectable()
export class CacheService {
  limitRequestInCreation: LimitRequestDto;
  inquiry: PolicyInquiryVersionDto;
  collection: CollectionSimpleDto;
  policyLimitList: ProductLimitListVersionDto;
  bondingContractInquiry: BondingContractInquiryVersionDto;
  companyInCreation: CompanyDto;

  constructor(private dictionaryBaseService: DictionaryBaseService) {}

  setObject(settableObjectType: SettableObjectType, obj: Object) {
    console.log('cacheService set settableObjectType = ' + settableObjectType + ', object', obj);
    switch (settableObjectType) {
      case SettableObjectType.LIMIT_BUYER:
        this.limitRequestInCreation.limit.buyer = <ThirdPartyBaseDto>{
          company: BusinessUtils.toCompanySimpleDto(<CompanyDto>obj),
        };
        break;
      case SettableObjectType.LIMIT_GUARANTOR:
        this.limitRequestInCreation.guarantors.push(<ThirdPartyBaseDto>{
          company: BusinessUtils.toCompanySimpleDto(<CompanyDto>obj),
        });
        break;
      case SettableObjectType.INQUIRY_CLIENT:
        this.inquiry.client = <ThirdPartyBaseDto>{company: BusinessUtils.toCompanySimpleDto(<CompanyDto>obj)};
        break;
      case SettableObjectType.INQUIRY_SUBINSURED:
        if (!this.inquiry.subinsureds) {
          this.inquiry.subinsureds = [];
        }
        const thirdParty = <ThirdPartyBaseDto>{company: BusinessUtils.toCompanySimpleDto(<CompanyDto>obj)};
        const subinsured = <PolicyInquiryCompanyDto>{company: null, thirdParty: thirdParty};
        this.setCompanyRole(subinsured, PolicyInquiryCompanyRole.SUBINSURED);
        this.inquiry.subinsureds.push(subinsured);
        break;
      case SettableObjectType.COLLECTION_DEBTOR:
        this.collection.thirdParty = <ThirdPartyBaseDto>{company: BusinessUtils.toCompanySimpleDto(<CompanyDto>obj)};
        break;
      case SettableObjectType.POLICY_LIMIT_LIST_FACTORER:
        this.policyLimitList.limitList.factorer = <ThirdPartyBaseDto>{
          company: BusinessUtils.toCompanySimpleDto(<CompanyDto>obj),
        };
        break;
      case SettableObjectType.LIMIT_FACTORER:
        this.limitRequestInCreation.limit.policyLimitList.factorer = <ThirdPartyBaseDto>{
          company: BusinessUtils.toCompanySimpleDto(<CompanyDto>obj),
        };
        break;
      case SettableObjectType.BONDING_CONTRACT_INQUIRY_CLIENT:
        this.bondingContractInquiry.inquiry.client = <ThirdPartyBaseDto>{
          company: BusinessUtils.toCompanySimpleDto(<CompanyDto>obj),
        };
        break;
      case SettableObjectType.NEW_COMPANY:
        this.companyInCreation = <CompanyDto>obj;
        break;
    }
  }

  private setCompanyRole(company: PolicyInquiryCompanyBaseDto, roleId: number) {
    this.dictionaryBaseService.getDictionaryEntry('PolicyInquiryCompanyRole', roleId).subscribe((entry) => {
      company.role = entry;
    });
  }
}

export enum SettableObjectType {
  LIMIT_BUYER = 1,
  INQUIRY_CLIENT = 2,
  COLLECTION_DEBTOR = 3,
  POLICY_LIMIT_LIST_FACTORER = 4,
  LIMIT_FACTORER = 5,
  BONDING_CONTRACT_INQUIRY_CLIENT = 6,
  NEW_COMPANY = 7,
  LIMIT_GUARANTOR = 8,
  INQUIRY_SUBINSURED = 9,
}
