import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {LoggedUserService} from '../bonding_shared/services';
import {AbstractGuard} from '../bonding_shared/components/abstract-guard';

@Injectable()
export class AuthenticationGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router) {
    super(loggedUserService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin(state.url);
  }
}
