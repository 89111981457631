import {Observable, of as observableOf} from 'rxjs';

import {share, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CityBaseDto, PostCodeBaseDto, ProvinceBaseDto} from '../model';

@Injectable()
export abstract class AbstractGeoDictService<
  C extends CityBaseDto,
  P extends PostCodeBaseDto,
  PR extends ProvinceBaseDto
> extends AbstractService {
  private citiesSubService: GeoDictSubService<C>;
  private postCodesSubService: GeoDictSubService<P>;
  private provincesSubService: GeoDictSubService<PR>;

  protected constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    _loggedUserService: LoggedUserService
  ) {
    super(http, appConfigService, _loggedUserService);
    this.citiesSubService = new GeoDictSubService<C>('cities', super.get.bind(this));
    this.postCodesSubService = new GeoDictSubService<P>('postCodes', super.get.bind(this));
    this.provincesSubService = new GeoDictSubService<PR>('provinces', super.get.bind(this));
  }

  public getCities(byType: 'city' | 'postCode' | 'country', byId: number): Observable<C[]> {
    return this.citiesSubService.getGeoDict(this.url, byType, byId);
  }

  public getPostCodes(byType: 'city' | 'postCode' | 'country', byId: number): Observable<P[]> {
    return this.postCodesSubService.getGeoDict(this.url, byType, byId);
  }

  public getProvinces(byType: 'city' | 'postCode' | 'country', byId: number): Observable<PR[]> {
    return this.provincesSubService.getGeoDict(this.url, byType, byId);
  }
}

class GeoDictSubService<G extends CityBaseDto | PostCodeBaseDto | ProvinceBaseDto> {
  constructor(
    private specificUrlPart: string,
    private get: <T>(url: string, params?: HttpParams | {[param: string]: string | string[]}) => Observable<T>
  ) {}

  // Stores data
  private cacheData: {[typeBy: string]: {[byId: number]: G[]}} = {};
  // Observables used when someone calls again when call is in progress
  private cacheObservables: {[typeBy: string]: {[byId: number]: Observable<G[]>}} = {};

  private static prepareCache(cache: any, byType: 'city' | 'postCode' | 'country') {
    if (!cache[byType]) {
      cache[byType] = {};
    }
  }

  public getGeoDict(mainUrlPart: string, byType: 'city' | 'postCode' | 'country', byId: number) {
    const geoDict: G[] = this.getGeoDictFromCache(byType, byId);
    if (geoDict) {
      return observableOf(geoDict);
    }
    let observable: Observable<G[]> = this.getObsFromCache(byType, byId);
    if (observable) {
      return observable;
    }

    observable = this.getGeoDictNoCache(mainUrlPart, byType, byId).pipe(
      tap(
        (entries) => {
          this.putGeoDictToCache(byType, byId, entries);
          this.putObsToCache(byType, byId, undefined);
        },
        (error) =>
          console.error('Cannot load geoDict from url ' + this.specificUrlPart + ':' + byType + ':' + byId, error)
      ),
      share()
    );

    this.putObsToCache(byType, byId, observable);
    return observable;
  }

  private getGeoDictNoCache(
    mainUrlPart: string,
    byType: 'city' | 'postCode' | 'country',
    byId: number
  ): Observable<G[]> {
    return this.get<G[]>(mainUrlPart + '/' + this.specificUrlPart + '/?' + byType + 'Id=' + byId);
  }

  private putGeoDictToCache(byType: 'city' | 'postCode' | 'country', byId: number, geoDict: G[]) {
    GeoDictSubService.prepareCache(this.cacheData, byType);
    this.cacheData[byType][byId] = <G[]>geoDict;
  }

  private getGeoDictFromCache(byType: 'city' | 'postCode' | 'country', byId: number): G[] {
    return <G[]>this.getFromCache(this.cacheData, byType, byId);
  }

  private putObsToCache(byType: 'city' | 'postCode' | 'country', byId: number, obs: Observable<G[]>) {
    GeoDictSubService.prepareCache(this.cacheObservables, byType);
    this.cacheObservables[byType][byId] = <Observable<G[]>>obs;
  }

  private getObsFromCache(byType: 'city' | 'postCode' | 'country', byId: number): Observable<G[]> {
    return <Observable<G[]>>this.getFromCache(this.cacheObservables, byType, byId);
  }

  private getFromCache(cache: any, byType: 'city' | 'postCode' | 'country', byId: number): G[] | Observable<G[]> {
    return cache && cache[byType] && cache[byType][byId];
  }
}
