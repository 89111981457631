import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoggedUserService} from '../../../../../src/client/app/bonding_shared/services/index';
import {AppConfigService} from '../../../../../src/client/app/bonding_shared/services/app-config.service';
import {ErrorReason, ForgotPasswordRequestDto} from '../../../../../src/client/app/bonding_shared/model/dtos';
import {PasswordResetService} from '../../../../../src/client/app/portal/services/password-reset.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../../../src/client/app/bonding_shared/validators/custom.validators';
import {GrowlService} from '../../../../../src/client/app/bonding_shared/services/growl/growl.service';

@Component({
  selector: 'remind-password',
  templateUrl: 'remind-password.component.html',
})
export class RemindPasswordComponent implements OnInit {
  public serverErrors: ErrorReason[];
  public form: UntypedFormGroup;
  public showErrors: boolean;

  constructor(
    private _router: Router,
    private _loggedUserService: LoggedUserService,
    public appConfigService: AppConfigService,
    private passwordResetService: PasswordResetService,
    private growlService: GrowlService
  ) {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.compose([Validators.required, CustomValidators.emailFormat])),
      login: new UntypedFormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this._loggedUserService.clearToken();
  }

  sentPasswordEmail() {
    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }
    const dto: ForgotPasswordRequestDto = <ForgotPasswordRequestDto>{
      email: this.form.get('email').value,
      login: this.form.get('login').value,
    };
    this.passwordResetService.remindPassword(dto).subscribe({
      next: () => {
        this.growlService.notice('remainPassEmailSent');
        this.serverErrors = [];
        this._router.navigate(['login']);
      },
      error: (error) => {
        this.serverErrors = error;
      },
    });
  }
}
