import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Page, SearchCriteria, SearchResult, UserHolidayCriteriaDto, UserHolidayDto, UserSimpleDto} from '../model';
import {Observable} from 'rxjs';

@Injectable()
export class UserHolidayService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'userHoliday';
  }

  public getUserHolidays(userId: number): Observable<SearchResult<UserHolidayDto>> {
    const criteria = <SearchCriteria<UserHolidayCriteriaDto>>{};
    criteria.criteria = <UserHolidayCriteriaDto>{};
    criteria.criteria.user = <UserSimpleDto>{id: userId};
    criteria.page = <Page>{start: 0, count: 300};
    return this.searchByCriteria(criteria);
  }
}
