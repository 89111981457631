import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {PortalSalesRegionService} from '../../portal/services';

@Injectable()
export class SalesRegionService extends PortalSalesRegionService {
  protected url = this.urlPrefix + 'salesRegion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, _loggedUserService: LoggedUserService) {
    super(http, appConfigService, _loggedUserService);
  }

  getUserRegion(userId: number): Observable<String> {
    return this.get<String>(this.url + '/getUserRegion/' + userId);
  }
}
