import {Component, OnInit} from '@angular/core';
import {AnnexInquiryDetailsComponent} from '../../bonding/inquiry/annex-inquiry-details.component';
import {ActivatedRoute} from '@angular/router';
import {
  AppConfigService,
  CacheService,
  CompanyService,
  DictionaryBaseService,
  DictionaryService,
  DocumentService,
  GrowlService,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared/services';
import {UntypedFormBuilder} from '@angular/forms';
import {InquiryAbstractService} from '../../bonding_shared/services/inquiry-abstract.service';
import {PortalRouterService} from '../services';
import {InquiryCountryInitialiazerService} from '../../bonding/inquiry/services/inquiry-countries-initialiazer.service';
import {VisibilityService} from '../../bonding/inquiry/services/visibility.service';

@Component({
  selector: 'client-annex-inquiry-details',
  templateUrl: '../../bonding/inquiry/annex-inquiry-details.component.pug',
  providers: [InquiryCountryInitialiazerService],
})
export class ClientAnnexInquiryDetailsComponent extends AnnexInquiryDetailsComponent implements OnInit {
  constructor(
    protected route: ActivatedRoute,
    public routerService: RouterService,
    protected portalRouterService: PortalRouterService,
    protected documentService: DocumentService,
    protected dictionaryService: DictionaryService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected companyService: CompanyService,
    protected formBuilder: UntypedFormBuilder,
    protected loggedUserService: LoggedUserService,
    protected portalInquiryService: InquiryAbstractService,
    protected growlService: GrowlService,
    public appService: AppConfigService,
    protected cacheService: CacheService,
    protected countryInitialiazerService: InquiryCountryInitialiazerService,
    protected visibilityService: VisibilityService
  ) {
    super(
      route,
      routerService,
      documentService,
      dictionaryService,
      dictionaryBaseService,
      companyService,
      formBuilder,
      null,
      loggedUserService,
      portalInquiryService,
      growlService,
      appService,
      cacheService,
      countryInitialiazerService,
      visibilityService
    );
  }

  get portal(): boolean {
    return true;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  toPolicy() {
    this.portalRouterService.toClientPolicyPreviewKuke(this.inquiry.basePolicyContractVersion.id);
  }
}
