import {ControlValueAccessor, UntypedFormControl} from '@angular/forms';
import {BackendError, BusinessObjectDto} from '../../model';
import {AppConfigService, BusinessObjectService, GrowlService, RouterService} from '../../services';
import {Input, Directive} from '@angular/core';

@Directive()
export abstract class AbstractBusinessObjectSelector implements ControlValueAccessor {
  value: BusinessObjectDto;
  onChange: Function;
  onTouched: Function;
  businessObjectsResults: BusinessObjectDto[];
  tooManyResults = false;
  serverErrors: BackendError;

  @Input() codeRegExp: RegExp;
  @Input() parentDictionaryEntryId: number;
  @Input() disabled = false;

  relatedTo = new UntypedFormControl();

  protected constructor(
    protected businessObjectService: BusinessObjectService,
    protected router: RouterService,
    protected growlService: GrowlService,
    protected appService: AppConfigService
  ) {}

  search(businessObjectTypeId: number, searchPhrase: string, companyId?: number) {
    this.serverErrors = null;
    const limitResults = !this.appService.kuke;
    this.businessObjectService
      .getRelatedBusinessObjects(businessObjectTypeId, companyId, searchPhrase, limitResults)
      .subscribe({
        next: (result) => {
          this.businessObjectsResults = result;
          if (this.businessObjectsResults.length > 50) {
            this.businessObjectsResults.slice(0, 49);
            this.tooManyResults = true;
          } else {
            this.tooManyResults = false;
          }
          if (result.length === 0) {
            this.growlService.warning('No results found for phrase ' + searchPhrase);
          } else {
            this.growlService.notice(Math.min(50, result.length) + ' results found for phrase ' + searchPhrase);
          }
        },
        error: (error) => {
          this.serverErrors = error;
        },
      });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: BusinessObjectDto): void {
    this.value = obj;
    if (obj && obj.relatedTo) {
      this.relatedTo.patchValue(obj.relatedTo, {emitEvent: false});
    }
  }
}
