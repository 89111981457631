import {GroupSearchComponent} from './group-search.component';
import {GroupPreviewComponent} from './group-preview.component';
import {GroupDetailsComponent} from './group-details.component';
import {CompanyGroupAccessGuard} from '../company/_guards/company-group-access.guard';

export const GroupRoutes = [
  {
    path: 'group-search',
    component: GroupSearchComponent,
    canActivate: [CompanyGroupAccessGuard],
  },
  {
    path: 'group-preview/:id',
    component: GroupPreviewComponent,
    canActivate: [CompanyGroupAccessGuard],
  },
  {
    path: 'group-details/:id',
    component: GroupDetailsComponent,
    canActivate: [CompanyGroupAccessGuard],
  },
];
