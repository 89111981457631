import {Observable} from 'rxjs';
import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {
  IdsDto,
  InvoiceItemCriteriaDto,
  InvoiceItemDto,
  InvoiceItemSimpleDto,
  InvoiceItemTreatyDto,
  SearchCriteria,
  SearchResult,
  TreatySimpleDto,
} from '../model/dtos';
import {HttpClient} from '@angular/common/http';
import {BinaryDownloaderService} from '../utils';
import {FormatService} from './format.service';
import {BusinessObjectType, FinancialDocumentCategory} from '../model/dictionary-ids';

@Injectable()
export class InvoiceItemService extends AbstractService {
  protected url = this.urlPrefix + 'invoiceItem';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private binaryDownloader: BinaryDownloaderService,
    private formatService: FormatService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  newInvoiceItem(
    categoryId: FinancialDocumentCategory,
    relatedToTypeId: BusinessObjectType,
    relatedToId: number,
    sourceItemId: number
  ): Observable<InvoiceItemDto> {
    return this.get<InvoiceItemDto>(
      this.buildUrl(
        this.url,
        'newInvoiceItem',
        UrlParams.new()
          .add('categoryId', categoryId)
          .add('relatedToTypeId', relatedToTypeId)
          .add('relatedToId', relatedToId)
          .add('sourceItemId', sourceItemId)
      )
    );
  }

  getInvoiceItem(id: number): Observable<InvoiceItemDto> {
    console.log('InvoiceItem details: id = ' + id);
    return this.get<InvoiceItemDto>(this.url + '/' + id);
  }

  deleteInvoiceItem(id: number): Observable<InvoiceItemDto> {
    console.log('delete InvoiceItem: id = ' + id);
    return this.delete<InvoiceItemDto>(this.url + '/' + id);
  }

  search(invoiceItemCriteria: SearchCriteria<InvoiceItemCriteriaDto>): Observable<SearchResult<InvoiceItemSimpleDto>> {
    console.log('searchInvoiceItems: http = ' + this.http);
    return this.post1<SearchCriteria<InvoiceItemCriteriaDto>, SearchResult<InvoiceItemSimpleDto>>(
      invoiceItemCriteria,
      this.url + 'search'
    );
  }

  deleteInvoiceItems(ids: IdsDto): Observable<IdsDto> {
    console.log('deleteInvoiceItems');
    return this.post<IdsDto>(ids, this.url + '/removeItems');
  }

  searchAndDeleteInvoiceItems(invoiceItemCriteria: SearchCriteria<InvoiceItemCriteriaDto>): Observable<number> {
    console.log('searchAndDeleteInvoiceItems');
    return this.post1<SearchCriteria<InvoiceItemCriteriaDto>, number>(
      invoiceItemCriteria,
      this.url + '/searchAndRemove'
    );
  }

  calculateTreaties(
    contractTypeId: number,
    contractId: number,
    relatedToTypeId: number,
    relatedToId: number,
    onDate: Date,
    dateFrom: Date,
    dateTo: Date,
    itemTypeId: number
  ): Observable<InvoiceItemTreatyDto[]> {
    return this.get<InvoiceItemTreatyDto[]>(
      this.url +
        '/treaty?contractTypeId=' +
        contractTypeId +
        '&contractId=' +
        contractId +
        '&relatedToTypeId=' +
        relatedToTypeId +
        '&relatedToId=' +
        relatedToId +
        '&onDate=' +
        this.formatService.formatDate(onDate) +
        '&dateFrom=' +
        this.formatService.formatDate(dateFrom) +
        '&dateTo=' +
        this.formatService.formatDate(dateTo) +
        '&itemTypeId=' +
        itemTypeId
    );
  }

  export(
    criteria: InvoiceItemCriteriaDto,
    outputFileName: string,
    errorCallback: (errMsg: string) => void,
    completeCallback: () => void
  ) {
    console.log('export ', criteria);
    this.binaryDownloader.download(
      'POST',
      this.url + '/export',
      'application/json',
      'application/octet-stream',
      outputFileName,
      criteria,
      errorCallback,
      completeCallback
    );
  }
}
