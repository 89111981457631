/**
 * Created by wilk on 23.02.2017.
 */
import {Component, Input} from '@angular/core';
import {DateRangeGenericIfc} from '../../model/dtos';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'date-range-row',
  template: `
    <div class="bon-row">
      <label class="bon-label"
        >{{ actualLabel }}
        <span *ngIf="autoAddBetween" style="font-weight: normal;">&nbsp;&nbsp;&nbsp;{{ 'between' | translate }}</span>
      </label>
      <div class="bon-date-range" *ngIf="!readOnly">
        <date-picker
          [(ngModel)]="value.dateFrom"
          [type]="'date'"
          [disabled]="disabled"
          class="bon-input-size"
          ngDefaultControl
        >
        </date-picker>
        <span>{{ 'and' | translate }}</span>
        <date-picker
          [(ngModel)]="value.dateTo"
          [type]="'date'"
          [disabled]="disabled"
          class="bon-input-size"
          ngDefaultControl
        >
        </date-picker>
      </div>
      <div *ngIf="readOnly && !empty" style="display: inherit" [class.align-right]="alignRight">
        <span [ngClass]="valueClass">{{ value.dateFrom | date }}</span> <span>&nbsp;&#x2012;&nbsp;</span>
        <span [ngClass]="valueClass">{{ value.dateTo | date }}</span>
      </div>
    </div>
  `,
})
export class DateRangeRowComponent {
  @Input() label: string;
  @Input() labelKey: string;
  @Input() disabled: any;
  @Input() value: DateRangeGenericIfc;
  @Input() readOnly = false;
  @Input() empty = false;
  @Input() autoAddBetween = false;
  @Input() alignRight = false;
  @Input() valueClass: string;

  constructor(private translateService: TranslateService) {}

  get actualLabel() {
    if (this.empty) {
      return '';
    }
    return this.labelKey ? this.translateService.instant(this.labelKey) : this.label;
  }
}
