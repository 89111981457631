import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
@Pipe({
  name: 'cattranslate',
  pure: false, // required to update the value when the promise is resolved
})
export class CatTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(key: string, category: any, ...args: any[]): any {
    const customCategoryKey = key + '.' + category;
    const customCategoryLabel = this.translateService.instant(customCategoryKey, ...args);
    if (customCategoryLabel === customCategoryKey) {
      // Default translation for invoice modules
      return this.translateService.instant(key + '.DEFAULT', ...args);
    } else {
      return customCategoryLabel;
    }
  }
}
