import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {
  AppConfigService,
  CompanyService,
  DictionaryService,
  RouterService,
} from '../../../../../bonding_shared/services';
import {
  CustomFieldValueDto,
  DictionaryBaseDto,
  PolicyClauseDto,
  PolicyContractVersionDto,
} from '../../../../../bonding_shared/model';
import {StringUtils} from '../../../../../bonding_shared/utils';
import {CustomFieldValueType, PolicyClauseCategory} from '../../../../../bonding_shared/model/dictionary-ids';
import {forkJoin, Observable} from 'rxjs';

@Component({
  selector: 'kuke-special-clause-section',
  templateUrl: './kuke-special-clause-section.component.pug',
})
export class KukeSpecialClauseSectionComponent extends Section {
  public standardClauses: PolicyClauseDto[];
  private nonStandardClauses: PolicyClauseDto[];
  policyVersion: PolicyContractVersionDto;
  private clauseParamsMap = new Map<number, string>();
  UNKNOWN_CLAUSE = 99;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected router: RouterService,
    protected appService: AppConfigService,
    protected dictionaryService: DictionaryService,
    protected companyService: CompanyService
  ) {
    super(policyContractViewService, appService);
  }

  @Input()
  set policy(pol: PolicyContractVersionDto) {
    this.clauseParamsMap.clear();
    this.standardClauses = pol.clauses.filter((cl) => cl.category.id === PolicyClauseCategory.STANDARD);
    this.nonStandardClauses = pol.clauses.filter((cl) => cl.category.id === PolicyClauseCategory.NON_STANDARD);
    this.standardClauses.forEach((sc) => this.prepareClauseValues(sc));
    this.nonStandardClauses.forEach((sc) => this.prepareClauseValues(sc));
    this.policyVersion = pol;
  }

  prepareClauseValues(sc: PolicyClauseDto) {
    sc.values.map((cv) => {
      switch (cv.field.parentId) {
        case CustomFieldValueType.COMPANY:
          this.companyService
            .getCompany(+cv.value)
            .subscribe((c) => this.setClauseValues(sc.id, cv.field.name + ': ' + c.registrationName));
          break;
        case CustomFieldValueType.DICTIONARY:
          this.dictionaryService.getDictionaryEntry(cv.field.dictName, +cv.value).subscribe((d) => {
            this.setClauseValues(sc.id, cv.field.name + ': ' + d.name);
          });
          break;
        case CustomFieldValueType.MULTISELECT:
          const multiselectValue = JSON.parse(cv.value) as DictionaryBaseDto[];
          const futures = [] as Observable<DictionaryBaseDto>[];
          multiselectValue.forEach((e) => futures.push(this.dictionaryService.getDictionaryEntry(e.dictName, e.id)));
          forkJoin<DictionaryBaseDto[]>(futures).subscribe((dictionaries) => {
            this.setClauseValues(sc.id, cv.field.name + ': ' + dictionaries.map((d) => d.name).join(', '));
          });
          break;
        default:
          this.setClauseValues(sc.id, cv.field.name + ' ' + cv.value);
      }
    });
  }

  private setClauseValues(id: number, text: string) {
    let s = this.clauseParamsMap.get(id);
    if (s) {
      s += '. ' + text;
    } else {
      s = text;
    }
    this.clauseParamsMap.set(id, s);
  }

  shortCode(category: DictionaryBaseDto, d: DictionaryBaseDto): string {
    return category.code + StringUtils.shortCode(d.code);
  }

  shortCodeNonStandard(d: DictionaryBaseDto): string {
    return StringUtils.shortCode(d.code);
  }

  getFormattedValues(clause: PolicyClauseDto): string {
    return this.clauseParamsMap.get(clause.id);
  }
}
