import {Component, EventEmitter, Input, Output, SkipSelf} from '@angular/core';
import {ControlContainer} from '@angular/forms';

@Component({
  selector: 'radio-boolean-switch',
  templateUrl: './radio-boolean-switch.pug',
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class RadioBooleanSwitchComponent {
  private onChangeListeners: Function;
  public onTouchedListeners: Function;
  @Input() value: boolean;
  @Input() required = false;
  @Input() disabled = false;

  @Input() switchTitle: string;
  @Input() switchTitlePrefix: string;
  @Input() showErrors = false;
  @Input() name: string;
  @Input() indent = 0;
  @Input() readonly = false;
  @Output() switch = new EventEmitter<{name: string; value: boolean}>();

  onSwitch() {
    this.switch.emit({name: this.name, value: this.value});
  }

  onInput(event: Event) {
    this.value = (<any>event.target).value;
    this.onChangeListeners(this.value);
  }
}
