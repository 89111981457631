<div class="bon-row" *ngIf="!hideModeCreation">
  <label class="bon-label font-larger">{{bondTypeName}}</label>
  <select [(ngModel)]="modeCreationKey" (change)="onChangeModeCreationKey()"
          class="bon-select" style="width: calc(100% - 220px) !important;" [disabled]="disabled">
    <option *ngFor="let item of modeCreationKeyItems" [value]="item">
      <span translate>bond.mode.creation.{{item}}</span>
    </option>
  </select>
</div>

<ng-container [ngSwitch]="modeCreationKey">
  <ng-container *ngSwitchCase="'Standard'">
    <dict-radio-row labelKey="bond.shared.subtypeSelector.subtype"
                dictionary="BondSubtype" [(ngModel)]="subtype"
                (changeItem)="onSubtypeChange($event)" parentDictionary="BondType"
                [parentDictionaryEntryId]="parentBondType?.id"
                [ngModelOptions]="{standalone: true}"
                [disabled]="_subtypeDisabled">
    </dict-radio-row>
  </ng-container>
  <ng-container *ngSwitchCase="'UserTemplate'">
    <div class="bon-row"><label class="font-larger" translate="bond.shared.subtypeSelector.customTemplate"></label></div>
    <div class="bon-row">
      <input-row *ngIf="selectedCustomTemplate" labelKey="bond.shared.subtypeSelector.template"
                 [(ngModel)]="selectedCustomTemplate.name" name="selectedCustomTemplate.name"
                 [ngModelOptions]="{standalone: true}" [disabled]="true"></input-row>
      <ng-container *ngIf="!disabled">
        <label class="bon-label" for="customTemplates" translate="bond.shared.subtypeSelector.template"></label>
        <item-combo class="bon-input-size" id="customTemplates" nullLabel=" " label="name"
                    [items]="customTemplates" [(ngModel)]="selectedCustomTemplate"
                    [ngModelOptions]="{standalone: true}" (selectItem)="onChangeCustomTemplate($event)"
                    [disabled]="disabled"></item-combo>
        <div class="invalid" *ngIf="showErrors && !selectedCustomTemplate"
             translate="bond.shared.subtypeSelector.pleaseSelectCustomTemplate">
        </div>
      </ng-container>
    </div>

  </ng-container>
  <ng-container *ngSwitchCase="'SpecialText'">
    <div *ngIf="specialTextUploadFile" class="bon-row">
      <label class="bon-label" translate="bond.shared.subtypeSelector.specialTextFile"></label>
      <a class="a-link" style=" width: 160px; display: inline-block; word-wrap: break-word;" (click)="getSpecialTextFile()">{{specialTextUploadFile.name}}</a>
    </div>
  </ng-container>
</ng-container>

