import {Component, OnInit, ViewChild} from '@angular/core';
import {
  CellChangeEvent,
  DictionaryBaseDto,
  GrowlService,
  PolicyContractPreviewDto,
  PolicyContractVersionService,
  PolicyDrawdownBaseDto,
  PolicyDrawdownExpectedRepaymentDto,
  PolicyDrawdownRepaymentScheduleVersionBaseDto,
  PolicyDrawdownRepaymentScheduleVersionDto,
  PolicyDrawdownVersionDto,
  RouterService,
} from '../../../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {PolicyDrawdownVersionService} from '../../../../bonding_shared/services/policy-drawdown-version.service';
import {PolicyDrawdownRepaymentScheduleVersionService} from '../../../../bonding_shared/services/policy-drawdown-repayment-schedule-version.service';
import {DictionaryUtils} from '../../../../bonding_shared/utils/dictionary-utils';
import {
  IPasteFromExcelColumnConfig,
  IPasteFromExcelConfig,
} from '../../../../bonding_shared/components/aku-table/services/paste-from-excel.service';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {
  BusinessObjectType,
  PolicyDrawdownRepaymentScheduleVersionStatus,
} from '../../../../bonding_shared/model/dictionary-ids';
import {NgForm} from '@angular/forms';
import * as _ from 'lodash';
import {combineLatest, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

@Component({
  selector: 'policy-drawdown-schedule',
  templateUrl: './policy-drawdown-schedule.component.pug',
})
export class PolicyDrawdownScheduleComponent extends DetailsView implements OnInit {
  readonly BusinessObjectType = BusinessObjectType;
  pasteScheduleFromExcelConfig: IPasteFromExcelConfig[];
  repaymentScheduleVersion = <PolicyDrawdownRepaymentScheduleVersionDto>{};
  versions: PolicyDrawdownRepaymentScheduleVersionBaseDto[];
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  drawdowns: PolicyDrawdownBaseDto[];
  drawdownTypeFilter: DictionaryBaseDto;
  policyContractPreview: PolicyContractPreviewDto;
  availableDrawdownTypes: DictionaryBaseDto[];

  constructor(
    private _route: ActivatedRoute,
    private service: PolicyDrawdownRepaymentScheduleVersionService,
    public router: RouterService,
    growlService: GrowlService,
    private policyContractVersionService: PolicyContractVersionService,
    private drawdownVersionService: PolicyDrawdownVersionService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.newVersionButton.name = 'policyDrawdownSchedule.newVersionButton';
    this.saveButton.name = 'policyDrawdownSchedule.saveButton';
  }

  ngOnInit(): void {
    this.form = this.ngForm.form;
    this._route.queryParams.subscribe({
      next: (params) => this.initializeView(params),
      error: (error) => this.handleServerError(error),
    });
  }

  initializeView(params: Params) {
    super.initializeView(params);
    this._route.queryParams.subscribe({
      next: (queryParams) => this.fetchData(this.service.getLastOrInitVersion(+queryParams['masterPolicyId'])),
      error: (error) => this.handleServerError(error),
    });
  }

  onSave() {
    this.clearErrors();
    if (this.formValidates()) {
      this.inProgress = true;
      const repaymentScheduleId = this.repaymentScheduleVersion?.parent?.id;
      this.service.save(this.repaymentScheduleVersion).subscribe({
        next: (savedVersion) => {
          this.afterObjectSaved(repaymentScheduleId, () =>
            this.router.toPolicyDrawdownScheduleDetails(savedVersion.parent.masterPolicyContract.id)
          );
          this.setPolicyDrawdownRepaymentScheduleVersion(savedVersion);
          this.fetchVersions(savedVersion.parent.id);
        },
        error: (error) => this.handleServerError(error),
      });
    }
  }

  onCreateNewVersion() {
    super.onCreateNewVersion();
    const repaymentScheduleId = this.repaymentScheduleVersion?.parent?.id;
    if (!repaymentScheduleId) {
      return;
    }
    this.service.newVersion<PolicyDrawdownRepaymentScheduleVersionDto>(repaymentScheduleId).subscribe({
      next: (newVersion) => this.setPolicyDrawdownRepaymentScheduleVersion(newVersion),
      error: (error) => this.handleServerError(error),
    });
  }

  onCancel() {
    super.onCancel();
    this.initializeView(this._route.snapshot.queryParams);
  }

  onSelectPolicyDrawdownRepaymentScheduleVersion(policyDrawdownRepaymentScheduleVersionId: number) {
    this.service
      .getById<PolicyDrawdownRepaymentScheduleVersionDto>(policyDrawdownRepaymentScheduleVersionId)
      .subscribe({
        next: (drawdownVersion) => this.setPolicyDrawdownRepaymentScheduleVersion(drawdownVersion),
        error: (error) => this.handleServerError(error),
      });
  }

  setPolicyDrawdownRepaymentScheduleVersion(repaymentScheduleVersion: PolicyDrawdownRepaymentScheduleVersionDto) {
    this.repaymentScheduleVersion = repaymentScheduleVersion;
    this.handleButtons();
  }

  handleButtons() {
    this.saveButton.hidden = !this.isEditable;
    this.cancelButton.hidden = !this.isEditable;
    this.newVersionButton.hidden = !this.canCreateNewVersion;
  }

  changeDrawdown(event: CellChangeEvent<PolicyDrawdownExpectedRepaymentDto>): void {
    const expectedRepayment = event.item;
    expectedRepayment.currency = this.policyContractPreview.currency;
    expectedRepayment.registrationDate = new Date();
    this.clearErrors();
  }

  get visibleExpectedRepayments() {
    if (this.repaymentScheduleVersion?.expectedRepayments) {
      return !!this.drawdownTypeFilter
        ? this.repaymentScheduleVersion.expectedRepayments.filter((r) =>
            DictionaryUtils.equalsDict(r.type, this.drawdownTypeFilter)
          )
        : this.repaymentScheduleVersion.expectedRepayments;
    }
  }

  initExcelConfig(drawdowns: PolicyDrawdownBaseDto[]) {
    this.pasteScheduleFromExcelConfig = [
      {
        columns: [
          <IPasteFromExcelColumnConfig>{
            type: 'combo',
            property: 'drawdown',
            comboItems: drawdowns,
          },
          <IPasteFromExcelColumnConfig>{
            type: 'date',
            property: 'registrationDate',
          },
          <IPasteFromExcelColumnConfig>{
            type: 'date',
            property: 'repaymentDate',
          },
          <IPasteFromExcelColumnConfig>{
            type: 'number',
            property: 'amount',
          },
          <IPasteFromExcelColumnConfig>{
            type: 'dictionary',
            property: 'currency',
            dictionary: 'Currency',
          },
          <IPasteFromExcelColumnConfig>{
            type: 'dictionary',
            property: 'type',
            dictionary: 'PolicyDrawdownType',
          },
        ],
      },
    ];
  }

  get isEditable() {
    return (
      !this.repaymentScheduleVersion.id ||
      (this.repaymentScheduleVersion.last &&
        this.repaymentScheduleVersion.status.id === PolicyDrawdownRepaymentScheduleVersionStatus.REGISTERED)
    );
  }

  get canCreateNewVersion() {
    return this.repaymentScheduleVersion.id && this.repaymentScheduleVersion.last;
  }

  get isApproved() {
    return DictionaryUtils.in(
      this.repaymentScheduleVersion.status,
      PolicyDrawdownRepaymentScheduleVersionStatus.APPROVED
    );
  }

  private initializeAvailableDrawdownTypes(drawdownVersions: PolicyDrawdownVersionDto[]) {
    this.availableDrawdownTypes = _.uniqBy(
      drawdownVersions
        .map((dv) => dv.constituents.map((c) => c.type))
        .reduce((accumulator, value) => accumulator.concat(value), []),
      (x) => x.id
    );
  }

  private fetchData(resultObs: Observable<PolicyDrawdownRepaymentScheduleVersionDto>) {
    resultObs
      .pipe(
        mergeMap((repaymentScheduleVersion) => {
          return combineLatest([
            of(repaymentScheduleVersion),
            this.policyContractVersionService.getPreviewByMasterPolicyId(
              repaymentScheduleVersion.parent.masterPolicyContract.id
            ),
          ]);
        })
      )
      .subscribe({
        next: ([repaymentScheduleVersion, policyContractPreview]) => {
          this.setPolicyDrawdownRepaymentScheduleVersion(repaymentScheduleVersion);
          this.policyContractPreview = policyContractPreview;
          const repaymentScheduleId = repaymentScheduleVersion.parent.id;
          if (repaymentScheduleId) {
            this.fetchVersions(repaymentScheduleId);
          }
          this.fetchApprovedDrawdowns(repaymentScheduleVersion.parent.masterPolicyContract.id);
        },
        error: (error) => this.handleServerError(error),
      });
  }

  private fetchVersions(policyDrawdownRepaymentScheduleId: number) {
    this.service.findPolicyDrawdownRepaymentScheduleVersions(policyDrawdownRepaymentScheduleId).subscribe({
      next: (versions) => (this.versions = versions),
      error: (error) => this.handleServerError(error),
    });
  }

  private fetchApprovedDrawdowns(masterPolicyContractId: number) {
    this.drawdownVersionService.getApprovedDrawdownsByMasterPolicyId(masterPolicyContractId).subscribe({
      next: (drawdownVersions) => {
        this.drawdowns = drawdownVersions.map((dv) => dv.parent);
        this.initializeAvailableDrawdownTypes(drawdownVersions);
        this.initExcelConfig(this.drawdowns);
      },
      error: (error) => this.handleServerError(error),
    });
  }
}
