import {TaskSearchComponent} from './task-search.component';
import {TaskDetailsComponent} from './task-details.component';
import {TaskAccessGuard} from './components/task-access-guard';

export const TaskRoutes = [
  {
    path: 'task-search',
    component: TaskSearchComponent,
  },
  {
    path: 'task-search/:boTypeId/:boId',
    component: TaskSearchComponent,
  },
  {
    path: 'task-details/:id',
    component: TaskDetailsComponent,
    canActivate: [TaskAccessGuard],
  },
  {
    path: 'task-details/:boTypeId/:boId',
    component: TaskDetailsComponent,
    canActivate: [TaskAccessGuard],
  },
];
