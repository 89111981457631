import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {MasterPolicyContractDto, MasterPolicyContractRevisionsDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MasterPolicyContractService extends AbstractService {
  protected url = this.urlPrefix + 'masterPolicyContract';

  constructor(public http: HttpClient, appConfigService: AppConfigService, _loggedUserService: LoggedUserService) {
    super(http, appConfigService, _loggedUserService);
  }

  getMasterPolicyContract(id: number): Observable<MasterPolicyContractDto> {
    console.log('master policy contract details: id = ' + id);
    return this.get<MasterPolicyContractDto>(this.url + '/' + id);
  }

  getMasterPolicyContractRevisionsDto(id: number): Observable<MasterPolicyContractRevisionsDto[]> {
    return this.get(this.url + '/' + id + '/getMasterPolicyContractRevisionsDto');
  }
}
