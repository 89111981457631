import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlContainer, NgForm, UntypedFormGroup} from '@angular/forms';
import {
  BondingExternalContractDto,
  BondVersionDto,
  CompanyIdDto,
  CompanyRawDto,
  CompanySimpleDto,
} from '../../../bonding_shared/model';
import {LoggedUserService, RouterService} from '../../../bonding_shared/services';
import {ContractType} from '../../../bonding_shared/model/dictionary-ids';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {ATableComponent} from '../../../bonding_shared';

@Component({
  selector: 'bonding-external-contract',
  templateUrl: './bonding-external-contract.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondingExternalContractComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() showErrors = false;
  @Input() readOnly = false;
  @Input() bondVersion: BondVersionDto;

  _contract: BondingExternalContractDto;

  @Input() productTypeId: number;

  selectors: ListEmitters = new ListEmitters();
  ContractType = ContractType;

  @ViewChild('consortiumClientCompaniesTable') consortiumClientCompaniesTable: ATableComponent<CompanyIdDto>;
  @ViewChild('consortiumBeneficiaryCompaniesTable') consortiumBeneficiaryCompaniesTable: ATableComponent<CompanyIdDto>;

  constructor(public router: RouterService, private loggedUserService: LoggedUserService) {
    this.selectors.initializeSelectorEmittersByNames(true, [
      'beneficiary',
      'buyer',
      'consortiumBeneficiary',
      'consortiumClient',
    ]);
  }

  consortiumClient = false;
  consortiumBeneficiary = false;

  @Input() set contract(value: BondingExternalContractDto) {
    this._contract = value;
    if (value) {
      this.consortiumClient = this._contract.consortiumClientCompanies.length > 0;
      this.consortiumBeneficiary = this._contract.consortiumBeneficiaryCompanies.length > 0;
    }
  }

  get contract() {
    return this._contract;
  }

  ngOnInit(): void {}

  isGSPGeneralPaymentContract() {
    return this.productTypeId === ContractType.GSP_GENERAL_PAYMENT;
  }

  showGSPIndividualContractFields() {
    return this.productTypeId === ContractType.GSP_INDIVIDUAL_CONTRACT;
  }

  onConsortiumClientStateChanged(checked: boolean) {
    if (!checked) {
      this.consortiumClientCompaniesTable.deleteAllClicked();
    }
  }

  onConsortiumBeneficiaryStateChanged(checked: boolean) {
    if (!checked) {
      this.consortiumBeneficiaryCompaniesTable.deleteAllClicked();
    }
  }

  onSelectBeneficiary(beneficiary: CompanySimpleDto): void {
    this.contract.beneficiary = beneficiary;
  }

  onSelectBuyer(buyer: CompanySimpleDto): void {
    this.contract.buyer = buyer;
  }

  onSelectConsortiumClient(company: CompanySimpleDto | CompanyRawDto) {
    if (!this.contract.consortiumClientCompanies.map((c) => c.id).includes(company.id)) {
      this.consortiumClientCompaniesTable.onAddItem(company);
    }
  }

  onSelectConsortiumBeneficiary(company: CompanySimpleDto | CompanyRawDto) {
    if (!this.contract.consortiumBeneficiaryCompanies.map((c) => c.id).includes(company.id)) {
      this.consortiumBeneficiaryCompaniesTable.onAddItem(company);
    }
  }

  get portal() {
    return this.loggedUserService.portal;
  }
}
