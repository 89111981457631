import {Component, ViewChild} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {MehibPolicyProduct} from '../mehib-policy-product';
import {PolicyContractVersionService} from '../../../../../../bonding_shared/services';
import {PolicyContractViewService} from '../../../../services/policy-contract-view.service';
import {TurnoverReportingSectionComponent} from '../../../sections/kuke/turnover-reporting-section.component';

@Component({
  selector: 'st-product',
  templateUrl: './st-policy-product.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class StPolicyProductComponent extends MehibPolicyProduct {
  @ViewChild(TurnoverReportingSectionComponent, {static: true})
  private turnoverReportingSectionComponent: TurnoverReportingSectionComponent;

  constructor(policyContractVersionService: PolicyContractVersionService) {
    super(policyContractVersionService);
  }

  limitFeesSectionDisabled() {
    return !this.policy?.businessStatus || PolicyContractViewService.policyAtLeastActivated(this.policy.businessStatus);
  }

  onValidFromChanged(validFrom: Date) {
    this.turnoverReportingSectionComponent.recalculateReportingPeriods(true);
  }
}
