import {Component, OnInit, ViewChild} from '@angular/core';
import {
  BusinessReportService,
  LoggedUserService,
  RouterService,
  SearchDataProvider,
  SettingsService,
} from '../../bonding_shared/services';
import {ReportDefinitionSimpleDto, SearchCriteria} from '../../bonding_shared/model';
import {ReportExecutionService} from '../../bonding_shared/services/report-execution.service';
import {
  DictionaryBaseDto,
  LocalDateRange,
  LocalDateTimeRange,
  ReportDefinitionCriteriaDto,
  ReportExecutionCriteriaDto,
  ReportExecutionSimpleDto,
} from '../../bonding_shared/model/dtos';
import {RepositoryService} from '../../bonding_shared/services/repository.service';
import {SearchViewComponent} from '../../bonding_shared/components/search/search-view/search-view.component';
import {ApplicationModule, BusinessReportStatus, UserType} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'report-execution-search',
  templateUrl: 'report-execution-search.component.html',
})
export class ReportExecutionSearchComponent implements OnInit {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    ReportExecutionCriteriaDto,
    ReportExecutionSimpleDto
  >;
  dataProvider: SearchDataProvider<ReportExecutionCriteriaDto, ReportExecutionSimpleDto>;
  reportDefinitions: ReportDefinitionSimpleDto[];
  readonly BusinessReportStatus = BusinessReportStatus;

  constructor(
    public router: RouterService,
    private reportExecutionService: ReportExecutionService,
    private repositoryService: RepositoryService,
    private settingsService: SettingsService,
    private loggedUserService: LoggedUserService,
    private businessReportService: BusinessReportService
  ) {
    this.dataProvider = new SearchDataProvider<ReportExecutionCriteriaDto, ReportExecutionSimpleDto>(
      reportExecutionService
    );
    this.criteria = <ReportExecutionCriteriaDto>{};
    this.criteria.creationDate = <LocalDateTimeRange>{};
    this.criteria.reportOnDate = <LocalDateRange>{};
    this.criteria.creatorTypes = this.portal ? [UserType.EXTRANET] : [UserType.INTRANET, UserType.TECHNICAL];
  }

  ngOnInit(): void {
    this.settingsService.viewChange.subscribe((e) => {
      console.log('Module changed:', e);
      this.loadModuleData();
      this.searchView.search();
    });

    this.loadModuleData();
  }

  private loadModuleData() {
    const applicationModuleIds = this.settingsService.getActiveModuleIds();
    const applicationModuleId = applicationModuleIds.length === 1 ? applicationModuleIds[0] : null;
    this.criteria.applicationModules = this.portal ? [ApplicationModule.LIMIT] : applicationModuleIds;
    const searchCriteria = <SearchCriteria<ReportDefinitionCriteriaDto>>{};
    searchCriteria.criteria = <ReportDefinitionCriteriaDto>{};
    if (applicationModuleId) {
      searchCriteria.criteria.applicationModule = <DictionaryBaseDto>{id: applicationModuleId};
    }
    // searchCriteria.criteria.scheduleCronNotNull = true;

    if (!this.portal) {
      this.businessReportService.searchByCriteria(searchCriteria).subscribe(
        (searchResult) => {
          this.reportDefinitions = <ReportDefinitionSimpleDto[]>searchResult.result;
        },
        (error) => {
          this.searchView.resultList.errorMessage = error;
          this.searchView.resultList.showErrors = true;
        }
      );
    }
  }

  public get criteria(): ReportExecutionCriteriaDto {
    return this.dataProvider.searchCriteria.criteria;
  }
  public set criteria(criteria: ReportExecutionCriteriaDto) {
    this.dataProvider.searchCriteria.criteria = criteria;
  }

  showDocument(reportExecution: ReportExecutionSimpleDto) {
    this.repositoryService.getRepositoryDocument(
      reportExecution.repositoryId,
      reportExecution.fileName + '.' + reportExecution.extension
    );
  }

  get portal() {
    return this.loggedUserService.portal;
  }
}
