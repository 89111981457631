import {Component, OnInit} from '@angular/core';
import {
  DictionaryBaseDto,
  FileImportSimpleDto,
  DeclarationFileImportDto,
  DeclarationMetadataDto,
  DeclarationCriteriaDto,
  SearchResult,
} from '../../bonding_shared/model';
import {PortalPolicyContractService, PortalDeclarationService} from '../services';
import {TranslateService} from '@ngx-translate/core';
import {
  AppConfigService,
  FormatService,
  GrowlService,
  LimitListType,
  RouterService,
} from '../../bonding_shared/services';
import {ActivatedRoute} from '@angular/router';
import {ImportType} from '../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {DeclarationParams} from '../../bonding/declaration/shared/declaration-details-view';
import * as moment from 'moment';
import {DeclarationImportDetailsView} from '../../bonding/declaration/shared/declaration-import-details-view';
import {DeclarationFileService} from '../../bonding_shared/services/declaration-file.service';

@Component({
  selector: 'client-outstandings-import',
  templateUrl: './client-outstandings-import.component.pug',
})
export class ClientOutstandingsImportComponent extends DeclarationImportDetailsView implements OnInit {
  constructor(
    private route: ActivatedRoute,
    protected appService: AppConfigService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected portalDeclarationService: PortalDeclarationService,
    protected declarationFileService: DeclarationFileService,
    public policyContractService: PortalPolicyContractService, // used in pug
    private formatService: FormatService,
    private routerService: RouterService
  ) {
    super(appService, translateService, growlService);
  }

  private static getRelatedImportFileTypes() {
    return [
      <DictionaryBaseDto>{id: ImportType.OUTSTANDINGS_KUKE_INTRANET_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.OUTSTANDINGS_KUKE_PORTAL_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.OUTSTANDINGS_KUKE_INTRANET_AMENDMENT},
      <DictionaryBaseDto>{id: ImportType.OUTSTANDINGS_WITH_NN_KUKE_INTRANET_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.OUTSTANDINGS_WITH_NN_KUKE_PORTAL_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.OUTSTANDINGS_WITH_NN_KUKE_INTRANET_AMENDMENT},
    ];
  }

  protected initializeImportDeclaration() {
    this.initializeFileDataProvider(this.declarationFileService);
    this.importDeclaration = <DeclarationFileImportDto>{
      type: <DictionaryBaseDto>{id: this.getImportTypeId()},
      metadata: <DeclarationMetadataDto>{},
    };
  }

  private initializeFileDataProvider(service: DeclarationFileService) {
    this.fileDataProvider = BusinessUtils.createFileImportDataProvider(service);
    this.fileDataProvider.searchCriteria.criteria.types = ClientOutstandingsImportComponent.getRelatedImportFileTypes();
  }

  private getImportTypeId(): number {
    if (this.dcComponent && this.dcComponent.nnPolicy) {
      return ImportType.OUTSTANDINGS_WITH_NN_KUKE_PORTAL_DECLARATION;
    } else {
      return ImportType.OUTSTANDINGS_KUKE_PORTAL_DECLARATION;
    }
  }

  ngOnInit() {
    if (this.appService.kuke) {
      this.route.params.subscribe((params) => {
        const dcParams: DeclarationParams = <DeclarationParams>{
          paramRpId: +params['reportingPeriodId'],
          paramLlvId: +params['limitListVersionId'],
          paramDecOnId: +params['declaredOnId'],
        };
        this.dcComponent.initByPortal(dcParams, true);
      });
    }
  }

  onDeclarationCriteriaTouchedEvent() {
    this.inProgress_flag = true;
    this.confirmedDeclarationForSelectedParams = undefined;
    this.fileTable.handleSearchResult(<SearchResult<DeclarationFileImportDto>>{});
  }

  onDeclarationCriteriaChangedEvent(reloadDeclaration: boolean) {
    this.resetGenerateCriteria();
    this.refreshFileTable();
  }

  // ############## FILE MANAGEMENT ##############
  generateLimitListFile() {
    this.importDeclaration.type = <DictionaryBaseDto>{id: this.getImportTypeId()};
    this.inProgress_flag = true;
    const fileName = [
      'salda zgłoszenia',
      this.dc.selectedPolicy.number,
      this.dc.selectedRp.id,
      this.dc.selectedLlv.id,
      this.formatService.formatDateTime(new Date()),
    ].join(' ');
    if (!this.amendment) {
      // importing outstandings amendments forbidden from portal!!
      this.declarationFileService.generateLimitListFile(
        this.generateCriteria.criteria,
        LimitListType.DECLARATION,
        fileName + '.xlsx',
        (errorMsg) => this.errorCallback(errorMsg),
        (file) => this.exportCompleteCallback(file)
      );
    } else {
      this.growlService.error('declaration.details.outstandingsImportAmendmentError');
    }
  }

  downloadFile(file: FileImportSimpleDto, input: boolean) {
    this.declarationFileService.downloadFile(
      file.id,
      input,
      input ? file.fileName : file.outputFileName,
      (errorMsg) => this.errorCallback(errorMsg),
      () => this.downloadCompleteCallback(input ? file.fileName : file.outputFileName)
    );
  }

  importOutstandingsByFile() {
    this.importDeclaration.type = <DictionaryBaseDto>{id: this.getImportTypeId()};
    this.clearErrors();
    if (!this.selectedFile) {
      this.growlService.error('No file selected!');
      return;
    }
    if (this.amendment) {
      this.growlService.error('declaration.details.outstandingsImportAmendmentError');
      return;
    }
    this.inProgress = true;
    this.importDeclaration.metadata.rpId = this.dc.selectedRp.id;
    this.importDeclaration.metadata.llvId = this.dc.selectedLlv.id;
    this.importDeclaration.metadata.decOnId = this.dc.selectedDecOn ? this.dc.selectedDecOn.id : undefined;
    this.importDeclaration.metadata.amendment = false;
    this.importDeclaration.type = <DictionaryBaseDto>{
      id: this.dcComponent.nnPolicy
        ? ImportType.OUTSTANDINGS_WITH_NN_KUKE_PORTAL_DECLARATION
        : ImportType.OUTSTANDINGS_KUKE_PORTAL_DECLARATION,
    };
    this.declarationFileService.importOutstandingsByFile(this.importDeclaration).subscribe(
      () => {
        this.inProgress = false;
        this.showSavedMsg();
      },
      (error) => this.handleServerError(error),
      () => {
        this.selectedFile = undefined;
        this.refreshFileTable();
      }
    );
  }

  // ############## SEARCH CRITERIA ##############

  protected resetGenerateCriteriaByIds(rpId: number, llvId: number, subId: number, finishInProgress = false) {
    this.generateCriteria.criteria = <DeclarationCriteriaDto>{
      reportingPeriodId: rpId,
      limitListVersionId: llvId,
      declaredOnId: subId,
    };

    // set proper values of amendmentImport and inProgress_flag flags
    if (this.dcComponent && this.dcComponent.singleFinalConfirmedDeclarationsForPolicy) {
      this.confirmedDeclarationForSelectedParams = this.dcComponent.singleFinalConfirmedDeclarationsForPolicy.find(
        (t) =>
          t.reportingPeriod.id === rpId &&
          t.limitListVersion.id === llvId &&
          (t.declaredOn ? t.declaredOn.id === subId : subId === undefined)
      );
      const now = new Date();
      this.amendment =
        this.confirmedDeclarationForSelectedParams !== undefined &&
        moment(this.confirmedDeclarationForSelectedParams.effectiveDate).isBefore(now);
      if (finishInProgress) {
        this.inProgress_flag = false;
      }
    }
  }

  refreshFileTable() {
    this.fileDataProvider.searchCriteria.criteria.type = <DictionaryBaseDto>{id: this.getImportTypeId()};
    this.fileDataProvider.searchCriteria.criteria.types = ClientOutstandingsImportComponent.getRelatedImportFileTypes();
    super.refreshFileTable();
  }
}
