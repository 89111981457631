import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services';
import {BatchExecutionDto, BatchSearchCriteriaDto} from '../../../bonding_shared/model';
import {BatchService} from '../../../bonding_shared/services/batch.service';

@Injectable()
export class BatchGuiService {
  searchDataProvider: SearchDataProvider<BatchSearchCriteriaDto, BatchExecutionDto>;
  constructor(private batchService: BatchService) {
    this.searchDataProvider = new SearchDataProvider<BatchSearchCriteriaDto, BatchExecutionDto>(this.batchService);
  }
}
