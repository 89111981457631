<p class="statement">
  <ng-content></ng-content>
</p>

<div class="bon-row">
  <input
    type="checkbox"
    [ngModel]="value"
    [disabled]="presentationMode"
    (change)="onChangeItem($event)"
    (blur)="onTouchedListeners()"
    [required]="required"
    class="bon-checkbox"
    style="width: auto; min-width: 0; margin-top: 0; vertical-align: top !important;"
  />  &nbsp;
  <span style="display: inline-block; width: calc(100% - 40px);">
    {{ labelKey ? (labelKey | translate) : (label | translate) }}
  </span>
  <error-message [control]="control" [show]="showErrors"></error-message>
</div>
