import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {
  AppConfigService,
  BrokerContractCompensationDto,
  BrokerContractDto,
  BrokerContractVersionDto,
  BrokerContractVersionService,
  BrokerContractVersionSimpleDto,
  CompanyDto,
  CompanyService,
  CompanySimpleDto,
  DictionaryBaseDto,
  DictionaryBaseService,
  GrowlService,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared';
import {ActivatedRoute} from '@angular/router';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  BrokerContractPowerOfAttorney,
  BrokerContractStatus,
  BrokerType,
  BusinessObjectType,
  DictionaryProfile,
  ElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import * as _ from 'lodash';

@Component({
  selector: 'broker-contract-details',
  templateUrl: './broker-contract-details.component.pug',
})
export class BrokerContractDetailsComponent extends DetailsView implements OnInit, AfterViewInit {
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;
  public readonly BusinessObjectTypeBROKER_CONTRACT = BusinessObjectType.BROKER_CONTRACT;
  public readonly DictionaryProfile = DictionaryProfile;
  public brokerContract: BrokerContractDto = <BrokerContractDto>{};
  public brokerContractVersion: BrokerContractVersionDto = <BrokerContractVersionDto>{brokerContract: {}};
  public mode: string;
  public showDetails = false;
  private companySelected = false;
  private licenseNumberRequired = true;

  constructor(
    public brokerContractService: BrokerContractVersionService,
    public routerService: RouterService,
    protected growlService: GrowlService,
    private loggedUserService: LoggedUserService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    public appService: AppConfigService,
    public dictionaryBaseService: DictionaryBaseService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.newVersionButton.hidden = false;
    this.initializeSelectors();
  }

  public get broker(): boolean {
    return (
      this.brokerContractVersion.brokerContract &&
      this.brokerContractVersion.brokerContract.brokerType &&
      this.brokerContractVersion.brokerContract.brokerType.id === BrokerType.BROKER
    );
  }

  public get brokerDictionaryProfile(): number {
    return DictionaryProfile.BROKER;
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }

  public get mehib(): boolean {
    return this.appService.mehib;
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }

  public ngAfterViewInit(): void {
    this.form = this.ngForm.form;
  }

  public ngOnInit() {
    this.route.params.subscribe((params) =>
      this.initView(+params['brokerContractVersionId'], +params['companyId'], params['mode'])
    );
  }

  public onSave(): void {
    if (!this.formValidates()) {
      return;
    }
    this.inProgress = true;
    const originalId = this.brokerContractVersion.id;
    this.brokerContractService.save(this.brokerContractVersion).subscribe(
      (brokerContractVersion: BrokerContractVersionDto) => {
        this.setBrokerContractVersion(brokerContractVersion, true);
        this.afterObjectSaved(originalId, () =>
          this.routerService.toBrokerContractDetailsEditByBrokerContractVersionId(brokerContractVersion.id)
        );
      },
      (error) => {
        this.handleServerError(error);
      }
    );
  }

  setMode(mode: string) {
    this.mode = mode;
  }

  public onCancel(): void {
    this.showErrors = undefined;
    if (this.brokerContractVersion.id) {
      this.initView(this.brokerContractVersion.id, 0);
    } else {
      this.initView(0, 0);
    }
  }

  public onCreateNewVersion(): void {
    this.serverErrors = undefined;
    this.newVersion(this.brokerContractVersion.brokerContract.id, 0, 0);
  }

  public onSelectVersion(brokerContractVersion: BrokerContractVersionSimpleDto) {
    this.loadVersion(brokerContractVersion.id, false);
  }

  public onSelectCompany(company: CompanySimpleDto): void {
    const brokerContract = this.brokerContractVersion.brokerContract;
    brokerContract.company = company;
    if (!this.companySelected && !this.showDetails) {
      this.newVersion(0, brokerContract.company.id, brokerContract.businessUnit.id);
    }
    this.companySelected = true;
  }

  public otherTypeOfPowerOfAttorneyChosen(): boolean {
    return _(this.brokerContractVersion.brokerContract.typesOfPowerOfAttorney)
      .map((typeOfPowerOfAttorney: DictionaryBaseDto) => typeOfPowerOfAttorney.id)
      .includes(BrokerContractPowerOfAttorney.OTHER);
  }

  public onBrokerTypeChange(): void {
    if (this.broker) {
      this.brokerContractVersion.brokerContract.typesOfPowerOfAttorney = [];
      this.onTypeOfPowerOfAttorneyChange();
      this.onCompensationChange();
    }
    this.isLicenseNumberRequired();
  }

  public onTypeOfPowerOfAttorneyChange(): void {
    if (!this.otherTypeOfPowerOfAttorneyChosen()) {
      this.brokerContractVersion.brokerContract.otherTypeOfPowerOfAttorney = null;
    }
  }

  public agreementSignedStateChanged(agreementSigned: boolean): void {
    if (!agreementSigned) {
      this.brokerContractVersion.brokerContract.agreementSignedOnDate = null;
    }
  }

  public onCompensationChange(): void {
    _.forEach(
      this.brokerContractVersion.brokerContract.compensations,
      (compensation: BrokerContractCompensationDto) => (compensation.compensationValue = 0)
    );
    this.brokerContractVersion.brokerContract.compensationAmount = 0;
  }

  public recalculateCompensationAmount(): void {
    this.brokerContractVersion.brokerContract.compensationAmount = _.sumBy(
      this.brokerContractVersion.brokerContract.compensations,
      (compensation: BrokerContractCompensationDto) => compensation.compensationValue
    );
  }

  public isCancellationSectionEdited(): boolean {
    return (
      this.brokerContractVersion.terminationNotificationDate != null ||
      this.brokerContractVersion.terminationDate != null ||
      this.brokerContractVersion.terminationReason != null ||
      !_.isEmpty(this.brokerContractVersion.noticePeriod) ||
      this.brokerContractVersion.noticePeriodUnit != null ||
      !_.isEmpty(this.brokerContractVersion.terminationComment)
    );
  }

  public hasStatus(statusId: number): boolean {
    return (
      this.brokerContractVersion &&
      this.brokerContractVersion.status &&
      this.brokerContractVersion.status.id === statusId
    );
  }

  public isRegistered(): boolean {
    return this.hasStatus(BrokerContractStatus.REGISTERED);
  }

  public isActive(): boolean {
    return this.hasStatus(BrokerContractStatus.ACTIVE);
  }

  public isBrokerContractClosed(): boolean {
    return this.hasStatus(BrokerContractStatus.CLOSED);
  }

  public isBrokerContractTerminated(): boolean {
    return this.hasStatus(BrokerContractStatus.TERMINATED);
  }

  public isBrokerTypeAgent(): boolean {
    return (
      this.brokerContract && this.brokerContract.brokerType && this.brokerContract.brokerType.id === BrokerType.AGENT
    );
  }

  public isBrokerTypeAgentBank(): boolean {
    return (
      this.brokerContractVersion.brokerContract &&
      this.brokerContractVersion.brokerContract.brokerType &&
      this.brokerContractVersion.brokerContract.brokerType.id === BrokerType.AGENT_BANK
    );
  }

  public isBrokerTypeMultiAgent(): boolean {
    return (
      this.brokerContract &&
      this.brokerContract.brokerType &&
      this.brokerContract.brokerType.id === BrokerType.MULTI_AGENT
    );
  }

  public inCloseMode(): boolean {
    return this.mode === 'close';
  }

  public compensationSectionVisible(): boolean {
    return (
      (this.isBrokerContractClosed() || this.isBrokerContractTerminated() || this.inCloseMode()) &&
      this.kuke &&
      !this.broker &&
      !!this.brokerContractVersion.id
    );
  }

  public isLicenseNumberRequired() {
    this.licenseNumberRequired =
      (this.kuke && (this.isBrokerTypeAgent() || this.isBrokerTypeMultiAgent())) ||
      this.credendo ||
      this.ecg ||
      (this.mehib && !this.isBrokerTypeAgentBank());
  }

  private initializeSelectors(): void {
    this.selectorNameList = ['Broker'];
    this.initializeSelectorEmitters(true);
  }

  private initView(id: number, companyId: number, mode: string = 'edit'): void {
    this.setMode(mode);
    this.serverErrors = undefined;
    this.showDetails = false;
    this.companySelected = false;
    this.brokerContractVersion = <BrokerContractVersionDto>{brokerContract: {}};
    this.brokerContractVersion.brokerContract.businessUnit = this.loggedUserService.getLoggedUserData().businessUnit;
    if (id > 0) {
      this.loadVersion(id, true);
    } else if (companyId > 0) {
      this.companyService.getCompany(companyId).subscribe((company: CompanyDto) => this.onSelectCompany(company));
    }
    this.handleButtons();
  }

  private loadVersion(versionId: number, refreshBrokerContract: boolean): void {
    this.brokerContractService
      .getById(versionId)
      .subscribe((brokerContractVersion: BrokerContractVersionDto) =>
        this.setBrokerContractVersion(brokerContractVersion, refreshBrokerContract)
      );
  }

  private setBrokerContractVersion(
    brokerContractVersion: BrokerContractVersionDto,
    refreshBrokerContract: boolean
  ): void {
    this.showDetails = true;
    this.brokerContractVersion = brokerContractVersion;
    this.handleButtons();
    if (refreshBrokerContract) {
      this.refreshBrokerContract();
    }
  }

  private newVersion(brokerContractId: number, companyId: number, businessUnitId: number): void {
    const newBrokerContractVersionObservable =
      brokerContractId > 0
        ? this.brokerContractService.newVersion<BrokerContractVersionDto>(brokerContractId)
        : this.brokerContractService.initialVersion<BrokerContractVersionDto>(companyId || 0, businessUnitId || 0);
    newBrokerContractVersionObservable.subscribe((brokerContractVersion: BrokerContractVersionDto) => {
      this.setBrokerContractVersion(brokerContractVersion, false);
      this.handleButtons();
    });
  }

  private refreshBrokerContract(): void {
    this.brokerContractService
      .getBrokerContract(this.brokerContractVersion.brokerContract.id)
      .subscribe((brokerContract: BrokerContractDto) => (this.brokerContract = brokerContract));
  }

  closeContract() {
    this.setMode('close');
    this.dictionaryBaseService
      .getDictionaryEntry('BrokerContractStatus', BrokerContractStatus.TERMINATED)
      .subscribe((d) => (this.brokerContractVersion.status = d));
  }

  protected handleButtons(): void {
    this.saveButton.disabled = this.brokerContractVersion.id && !this.brokerContractVersion.last;
    this.newVersionButton.disabled =
      !this.brokerContractVersion.id || !this.brokerContractVersion.last || this.isBrokerContractClosed();
  }

  public userHasCloseRights(): boolean {
    return this.userHasUpdateRights() && this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACT_UPDATE_CLOSE);
  }

  public userHasUpdateRights(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACT_UPDATE);
  }
}
