import {BlockchainTestComponent} from './blockchain-test.component';
import {BlockchainComponent} from './blockchain.component';

export const BlockchainRoutes = [
  {
    path: 'blockchain',
    component: BlockchainComponent,
  },
  {
    path: 'blockchain-test',
    component: BlockchainTestComponent,
  },
];
