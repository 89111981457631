import {Component, NgZone, OnDestroy, OnInit, Optional} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  ContractBondCriteriaDto,
  ContractBondExtendedDto,
  ContractBondExtendedSearchResult,
  DashboardService,
  DictionaryBaseDto,
  DictionaryService,
  ImageService,
  LoggedUserService,
  RouterService,
  SearchCriteria,
  SettingsService,
  UserDto,
} from '../../bonding_shared';
import {ContractBondPortalService} from '../../portal/clientbond';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {PortalCompanyService} from '../../portal/services';
import {CompanyType, ContractType} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'home',
  templateUrl: 'home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
  contractBondTypes: ContractBondExtendedDto[];
  contractCurrencyCode: string;
  contractExposures: ContractExposures;
  contractTypeId: number;
  contractVersionNumber: string;
  selectedBusinessUnit: DictionaryBaseDto;
  logoURL: string;
  logoLoaded = false;
  logoDivStyle = '{"display": "none"}';
  brokerType = CompanyType.BROKER;
  brokerWebPage: string;

  salesLeadProducts: DictionaryBaseDto[];
  selectedSalesLeadProduct: DictionaryBaseDto;
  contractTypes: DictionaryBaseDto[];
  selectedContractType: DictionaryBaseDto;
  globalConditionsOfInsurances: DictionaryBaseDto[];
  selectedGlobalConditionsOfInsurance: DictionaryBaseDto;

  private paramSubscription: Subscription;

  constructor(
    public settingsService: SettingsService,
    private route: ActivatedRoute,
    public router: RouterService,
    private imageService: ImageService,
    private loggedUserService: LoggedUserService,
    @Optional() private companyEndpoint: PortalCompanyService,
    private zone: NgZone,
    @Optional() private contractBondService: ContractBondPortalService,
    private dictionaryService: DictionaryService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    if (this.loggedUserService.isReloadNecessary()) {
      this.reloadPage();
      return;
    }
    const user: UserDto = this.loggedUserService.getLoggedUserData();
    if (user && user.businessUnit) {
      this.selectedBusinessUnit = <DictionaryBaseDto>{};
      this.selectedBusinessUnit.id = user.businessUnit.id;
    }
    this.paramSubscription = this.route.params.subscribe((params) => {
      const contractVersionId = +params['contractVersionId'];
      if (contractVersionId) {
        this.loadDataForSubCharts(contractVersionId);
        this.companyEndpoint
          .getBrokerContractCompanyType(contractVersionId)
          .subscribe((res) => (this.brokerType = res));
        this.companyEndpoint.getBrokerWebPage(contractVersionId).subscribe((res) => (this.brokerWebPage = res));
      }
    });

    this.dictionaryService.getDictionary('SalesLeadProduct').subscribe((types) => {
      this.salesLeadProducts = types.filter((t) => t.code !== 'GEN');
      if (this.salesLeadProducts && this.salesLeadProducts.length > 0) {
        this.selectedSalesLeadProduct = this.salesLeadProducts[0];
      }
    });

    this.dashboardService.resetCountryPerformance();
    // todoac odkomentować po prezentacji, teraz puste dane
    // this.dictionaryService.getDictionary('ContractType').subscribe(types => {
    //   this.contractTypes = types.filter(t => t.code !== 'OLIMPIA');
    // });
    // this.dictionaryService.getDictionary('GlobalConditionsOfInsurance').subscribe(types => {
    //   this.globalConditionsOfInsurances = types;
    // });
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
  }

  private loadDataForSubCharts(contractVersionId: number) {
    if (this.contractBondService) {
      const searchCriteria = <SearchCriteria<ContractBondCriteriaDto>>{};
      searchCriteria.criteria = <ContractBondCriteriaDto>{};
      searchCriteria.criteria.contractVersionId = contractVersionId;
      this.contractBondTypes = null;
      this.contractExposures = null;

      this.contractBondService
        .searchByCriteria<ContractBondCriteriaDto, ContractBondExtendedDto>(searchCriteria)
        .pipe(map((data) => <ContractBondExtendedSearchResult>data))
        .subscribe(
          (data) => {
            this.contractBondTypes = data.result;
            this.contractCurrencyCode = data.contractCurrencyCode;
            this.contractTypeId = data.contractTypeId;
            this.contractVersionNumber = data.contractNumber + '/' + data.versionNumber;
            this.contractExposures = <ContractExposures>{
              currentExposureEUR: data.currentExposureEUR,
              contractAvailableExposureEUR: data.contractAvailableExposureEUR,
            };
          },
          (error) => console.warn('LoadDataForSubCharts failed', error)
        );
      this.logoLoaded = false;
      this.logoDivStyle = '{"display": "none"}';
      this.logoURL = this.imageService.getBrokerLogoUrl(contractVersionId);
    }
  }

  onLogoLoaded() {
    this.logoLoaded = true;
    this.logoDivStyle = '';
  }

  reloadPage() {
    // click handler or similar
    this.zone.runOutsideAngular(() => {
      location.reload();
    });
  }

  salesPolicyExtraParams() {
    return {
      globalConditionsOfInsuranceId:
        (this.selectedGlobalConditionsOfInsurance && this.selectedGlobalConditionsOfInsurance.id) || null,
    };
  }

  salesBondExtraParams() {
    return {contractTypeId: (this.selectedContractType && this.selectedContractType.id) || null};
  }

  get isIndividualContract() {
    return this.contractTypeId && ![ContractType.BOOSTER, ContractType.BANK_BOOSTER].includes(this.contractTypeId);
  }
}

export type ContractExposures = {
  currentExposureEUR: number;
  contractAvailableExposureEUR: number;
};
