import {Route} from '@angular/router';
import {CompanyQuotationDetailsComponent} from './components/quotation-details/company-quotation-details.component';
import {QuotationSearchComponent} from './components/quotation-search/quotation-search.component';
import {BrokerPortfolioComponent} from './components/portfolio/broker-portfolio.component';
import {ContractVersionPreviewOnBrokerPortfolioComponent} from './components/portfolio/components/contract-version-preview-on-broker-portfolio-component';
import {BankQuotationDetailsComponent} from './components/quotation-details/bank-quotation-details.component';

export const BrokerRoutes: Route[] = [
  {
    path: 'company-quotation-details/:id',
    component: CompanyQuotationDetailsComponent,
  },
  {
    path: 'company-quotation-details/:id/:contractId',
    component: CompanyQuotationDetailsComponent,
  },
  {
    path: 'bank-quotation-details/:id',
    component: BankQuotationDetailsComponent,
  },
  {
    path: 'bank-quotation-details/:id/:contractId',
    component: BankQuotationDetailsComponent,
  },
  {
    path: 'quotation-search',
    component: QuotationSearchComponent,
  },
  {
    path: 'broker-portfolio',
    component: BrokerPortfolioComponent,
  },
  {
    path: 'broker-portfolio/contract-version-preview/:contractVersionId',
    component: ContractVersionPreviewOnBrokerPortfolioComponent,
  },
];
