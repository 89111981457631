import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import {NgForm, UntypedFormGroup} from '@angular/forms';
import {
  AddressDto,
  ATableComponent,
  BondPaymentDto,
  BondValueDto,
  BondVersionDto,
  BusinessUnitDto,
  BusinessUnitSimpleDto,
  CellChangeEvent,
  ComboItem,
  CompanyPortalDto,
  ContactPersonDto,
  ContractBondDto,
  Country,
  CurrencyExchangeRateService,
  DictionaryBaseDto,
  DictionaryDto,
  DictionaryService,
  GrowlService,
  IMultiSelectSettings,
  LanguageService,
  LoggedUserService,
  NumberFormatDto,
  PropertyService,
  StringUtils,
  TemplateDto,
  TemplateService,
} from '../../../bonding_shared';
import {ClientBondGuiService, NamedBeneficiaryRaw} from '../services';
import {
  AppProperty,
  BondSubType,
  ContractType,
  CurrencyTable,
  DictionaryProfile,
  DictionaryPropertyType,
} from '../../../bonding_shared/model/dictionary-ids';
import {ItPerformanceBondComponent} from './it-performance-bond.component';
import {ItBidBondComponent} from './it-bid-bond.component';
import {BondCustomFieldsComponent} from '../../../bonding/bond/components/shared/bond-custom-fields.component';
import {ClientBondFlowService} from '../domain/clientbond-flow.domain';
import {DateUtils} from '../../../bonding_shared/utils/date-utils';
import {BusinessUnit} from '../../../bonding_shared/model/business-unit';

@Component({
  selector: 'clientbond-form',
  templateUrl: 'clientbond-form.component.html',
  styles: [
    'label { width: 200px; font-weight: 700; margin-bottom: 2px; margin-right: 10px;}',
    '.card-block {padding-top: 0px; padding-left: 35px;}',
    'label + span {vertical-align: top; display: inline-block; width: 600px;}',
  ],
})
export class ClientBondFormComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  readonly VALIDITY_PERIOD_LIMITED: number = 114000001;
  readonly VALIDITY_PERIOD_UNLIMITED: number = 114000002;
  readonly DEFAULT_TAX_NAME: string = 'PB.TaxID';
  readonly DEFAULT_NATIONAL_ID_NAME: string = 'PB.CommercialID';
  readonly MAX_LENGTH_CONTRACT_SUBJECT: number = 540;
  readonly CurrencyTable = CurrencyTable;
  readonly ContractType = ContractType;
  readonly SYSTEM_CURRENCY_CODE = this.propertyService.properties[AppProperty.SYSTEM_CURRENCY];

  self = this;

  @Input() bond: BondVersionDto;
  @Input() showErrors: boolean;
  @Input() canIssue: boolean;
  @Input() canFullIssue: boolean;
  @Input() subtype: DictionaryDto;
  @Input() newBond: boolean;
  @Input() selectedContractBond: ContractBondDto;
  @Input() bondTypes: DictionaryDto[] = [];
  @Input() initialDuration: number;
  @Input() isIndividualContract: boolean;

  bondForm: UntypedFormGroup;
  private deliveryTypes: {[key: string]: DictionaryDto} = {};
  public bondSubtypes: {[key: number]: DictionaryDto} = {};
  public originalSubtypeCustomTemplate: DictionaryBaseDto;
  private customTemplate: TemplateDto;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  @ViewChild('bondValues', {static: true}) bondValuesTable: ATableComponent<BondValueDto>;
  @ViewChild('bondPayments', {static: false}) bondPaymentsTable: ATableComponent<BondPaymentDto>;
  @ViewChild('itBidBondComponent', {static: true}) itBidBondComponent: ItBidBondComponent;
  @ViewChild('itPerformanceBondComponent', {static: true}) itPerformanceBondComponent: ItPerformanceBondComponent;
  @ViewChild('bondCustomFieldsComponent') bondCustomFieldsComponent: BondCustomFieldsComponent;

  selectedBeneficiary: string;
  last10Beneficiaries: ComboItem[] = [];
  last10BeneficiariesMapByName: {[key: string]: CompanyPortalDto} = {};
  last10ExecutionMapByName: {[key: string]: AddressDto} = {};
  private last10BondVersionsSelected = false;

  languages: DictionaryBaseDto[] = [];
  visibleValidToDate = true;
  noValidToConfirmed = true;

  validityPeriod: DictionaryBaseDto;
  validityPeriodDisabled = false;
  visibleCancellationAfterDate = false;

  taxNumberFormat: NumberFormatDto;
  businessUnit: BusinessUnitDto;
  contractBusinessUnit: BusinessUnitSimpleDto;
  nationalIdNumberFormat: NumberFormatDto;
  availableSpecialClauses: DictionaryBaseDto[] = [];

  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    confirmAdditionIds: [84000004],
  };

  formChangedAfterPreview = false;
  sendByPostDisabled = false;

  postalAddress = false;
  contractAddress = false;

  validityPeriodComboHiddenIds: Set<number>;

  @Input() validToDisabled = false;
  readonly DictionaryProfile = DictionaryProfile;

  @Input() showLanguage = true;

  constructor(
    private dictService: DictionaryService,
    public guiService: ClientBondGuiService,
    private cd: ChangeDetectorRef,
    protected growlService: GrowlService,
    private loggedUserService: LoggedUserService,
    private languageService: LanguageService,
    private templateService: TemplateService,
    private currencyExchangeRateService: CurrencyExchangeRateService,
    private propertyService: PropertyService,
    public bondFlow: ClientBondFlowService
  ) {}

  ngOnInit(): void {
    console.log('Client Bond INITIALISATION');
    console.log('Client Bond INITIALISATION this.bond.contractId = ' + this.bond.contractId);
    console.log('Client Bond INITIALISATION this.bond.contractVersionId = ' + this.bond.contractVersionId);

    this.bondForm = this.ngForm.form;
    this.bondForm.valueChanges.subscribe((val) => this.formChanged(val));

    this.businessUnit = this.loggedUserService.getLoggedUserData().businessUnit;

    this.dictService.getDictionary('DeliveryType').subscribe((entries) => {
      for (const entry of entries) {
        this.deliveryTypes[entry.id] = entry;
      }
    });

    if (this.isLangEditable()) {
      this.languageService
        .getTemplateLanguagesPerSubtype(
          this.bond.subtype.id,
          this.bond.contractBusinessUnit ? this.bond.contractBusinessUnit.id : null
        )
        .subscribe((entries) => {
          this.languages = entries;
        });
    }

    this.dictService.getDictionary('BondSubtype').subscribe((entries) => {
      for (const entry of entries) {
        this.bondSubtypes[entry.id] = entry;
      }
    });

    this.visibleCancellationAfterDate = true;
    this.validityPeriod = <DictionaryBaseDto>{id: this.VALIDITY_PERIOD_LIMITED};
    this.bond.cityOfJurisdictionText = null;

    this.initCompanyNumberFormats();
    this.bondInputChange();

    this.sendByPostDisabled = this.isUserSpecialText();
    this.onCountryChange();

    this.customTemplate = <TemplateDto>this.guiService.getCustomTemplate();
    if (this.customTemplate && !this.customTemplate.dictionarySelectors) {
      this.templateService.getById(this.customTemplate.id).subscribe((t) => {
        this.customTemplate = <TemplateDto>t;
      });
    }
  }

  isLangEditable(): boolean {
    return !this.bond.contractType || this.bond.contractType.id === ContractType.BOOSTER || this.isIndividualContract;
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    for (const propName of Object.keys(changes)) {
      const changedProp = changes[propName];
      if (!changedProp.isFirstChange()) {
        if (
          propName === 'canIssue' ||
          propName === 'canFullIssue' ||
          propName === 'subtype' ||
          propName === 'newBond'
        ) {
          this.bondInputChange();
        }
      }
    }
  }

  private bondInputChange() {
    this.initEmptyBeneficiaryRaw();

    this.availableSpecialClauses.length = 0;
    // if (this.canIssue && this.bondTypes)
    if (this.bondTypes) {
      const bondType = this.selectedContractBond ? this.selectedContractBond.bondType : this.bond.type;
      this.availableSpecialClauses.push(
        ...this.bondTypes.find((entry) => entry.id === bondType.id).relatedDictionaries['BondSpecialClause']
      );
    }

    this.last10Beneficiaries = [];
    this.last10BeneficiariesMapByName = {};
    this.last10ExecutionMapByName = {};

    if (this.canFullIssue) {
      this.initLast10Beneficiaries();
    }
    this.setValidityPeriodCombo();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  // used on bond value table for each row
  isValueDeletable(v: BondValueDto): boolean {
    // first bond value is not editable, it is original value
    if (!this.bond || !this.bond.values || !this.bond.values[0]) {
      return true;
    }
    return this.bond.values[0].id !== v.id;
  }

  valueChange(event: CellChangeEvent<BondValueDto>) {
    event.item.valueInSysCurr = this.isSysCurr(this.bond.currency)
      ? event.value
      : this.currencyExchangeRateService.calculate(event.value, this.bond.currency.id);
    const idx = this.bondValuesTable.getItemIndex(event.item);
    (<UntypedFormGroup>(<UntypedFormGroup>this.bondForm.controls['valuesTable']).controls[idx]).controls[3].setValue(
      event.item.valueInSysCurr
    );
    this.updateDeliveryFee(this.bond.deliveryType);
  }

  paymentChange(event: CellChangeEvent<BondPaymentDto>) {
    event.item.amountInSysCurr = this.isSysCurr(this.bond.currency)
      ? event.value
      : this.currencyExchangeRateService.calculate(event.value, this.bond.currency.id);
    const idx = this.bondPaymentsTable.getItemIndex(event.item);
    (<UntypedFormGroup>(<UntypedFormGroup>this.bondForm.controls['paymentsTable']).controls[idx]).controls[2].setValue(
      event.item.amountInSysCurr
    );
    this.updateDeliveryFee(this.bond.deliveryType);
  }

  isSysCurr(currency: DictionaryBaseDto) {
    return currency.code === this.SYSTEM_CURRENCY_CODE;
  }

  isItaly(country) {
    return Country.isItaly(country);
  }

  private updateDeliveryFee(deliveryType: DictionaryBaseDto) {
    if (this.bond) {
      if (deliveryType) {
        const fullEntry = this.deliveryTypes[deliveryType.id];
        if (fullEntry && this.isSysCurr(this.bond.currency)) {
          this.bond.deliveryFee = parseFloat(fullEntry.properties[DictionaryPropertyType.DELIVERY_FEE]);
        }
      } else {
        this.bond.deliveryFee = undefined;
      }
    }
  }

  syncBondFormControllersAndBondDto(pressedSave: boolean): boolean {
    this.setValueInSysCurr();
    if (!this.contractAddress) {
      this.copyFromRegistrationAddress(this.bond.executionAddress);
    }
    if (!this.postalAddress) {
      this.copyFromRegistrationAddress(this.bond.beneficiaryRaw.correnspondenceAddress);
    }

    if (!this.bondForm.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFieldsRecursive(this.bondForm);
      console.log(this.bondForm);
      return false;
    }

    if (pressedSave && this.formChangedAfterPreview) {
      this.growlService.warning('Press preview before issue!');
      return false;
    }

    return true;
  }

  private setValueInSysCurr() {
    this.bond.values[0].valueInSysCurr = this.isSysCurr(this.bond.currency)
      ? this.bond.values[0].value
      : this.currencyExchangeRateService.calculate(this.bond.values[0].value, this.bond.currency.id);
  }

  canBeneficiaryEdit(): boolean {
    return this.canFullIssue && !this.last10BondVersionsSelected;
  }

  onSelectLastBeneficiary(beneficiaryName: string) {
    if (beneficiaryName) {
      this.bond.beneficiaryRaw = this.last10BeneficiariesMapByName[beneficiaryName];
      this.bond.executionAddress = this.last10ExecutionMapByName[beneficiaryName];
      this.last10BondVersionsSelected = true;
    } else {
      this.bond.beneficiaryRaw = null;
      this.last10BondVersionsSelected = false;
    }
    this.initEmptyBeneficiaryRaw();
    this.onCountryChange();
    this.cd.detectChanges();
    if (this.isUserSpecialText()) {
      this.bond.beneficiaryRaw.sendToBeneficiaryByPost = true;
    }
  }

  initLast10Beneficiaries() {
    this.guiService.getLastBeneficiaries().subscribe(
      (namedBeneficiaryRaw: NamedBeneficiaryRaw) => {
        if (namedBeneficiaryRaw) {
          const benName = namedBeneficiaryRaw.name;
          this.last10Beneficiaries.push(new ComboItem(benName, benName));
          this.last10BeneficiariesMapByName[benName] = namedBeneficiaryRaw.beneficiaryRaw;
          this.last10ExecutionMapByName[benName] = namedBeneficiaryRaw.executionAddress;
        }
      },
      (error) => console.log('Last beneficiaries loading problem', error)
    );
  }

  initEmptyBeneficiaryRaw() {
    this.bond.beneficiaryRaw = this.bond.beneficiaryRaw || <CompanyPortalDto>{};

    const beneficiaryRaw = this.bond.beneficiaryRaw;
    beneficiaryRaw.registrationAddress = beneficiaryRaw.registrationAddress || <AddressDto>{country: {}, type: {}};
    beneficiaryRaw.correnspondenceAddress =
      beneficiaryRaw.correnspondenceAddress || <AddressDto>{country: {}, type: {}};
    beneficiaryRaw.contactPerson = beneficiaryRaw.contactPerson || <ContactPersonDto>{};
    this.bond.executionAddress = this.bond.executionAddress || <AddressDto>{country: {}, type: {}};

    this.setDefaultBeneficiaryCountry();
  }

  setDefaultBeneficiaryCountry() {
    if (!this.bond.beneficiaryRaw.registrationAddress.country?.id) {
      this.bond.beneficiaryRaw.registrationAddress.country = this.bond.courtCountry;
    }
    if (!this.bond.beneficiaryRaw.correnspondenceAddress.country?.id) {
      this.bond.beneficiaryRaw.correnspondenceAddress.country = this.bond.courtCountry;
    }
    if (!this.bond.executionAddress.country?.id) {
      this.bond.executionAddress.country = this.bond.courtCountry;
    } else {
      this.bond.courtCountry = this.bond.executionAddress.country;
    }
  }

  showFormError() {
    this.showErrors = true;
    this.growlService.error('The form has errors!');
  }

  onBondValidityPeriodSelect(bondValidityPeriod: DictionaryBaseDto) {
    if (this.isBUIt(this.businessUnit)) {
      if (bondValidityPeriod !== undefined) {
        this.bond.estimatedValidTo = bondValidityPeriod.id !== this.VALIDITY_PERIOD_LIMITED;
      }
    } else {
      if (bondValidityPeriod !== undefined) {
        if (bondValidityPeriod.id === this.VALIDITY_PERIOD_LIMITED) {
          this.visibleValidToDate = true;
        } else if (this.noValidToConfirmed) {
          this.visibleValidToDate = false;
        }
      } else {
        this.visibleValidToDate = true;
      }
    }
  }

  isBUIt(bu: BusinessUnitDto) {
    return !!(bu && bu.name === 'IT');
  }

  setValidityPeriodCombo() {
    this.visibleCancellationAfterDate = false;

    if (this.bond.id && this.bond.bondValidTo) {
      this.setValidityPeriodLimited();
    } else {
      this.dictService.getDictionary('BondSubtype').subscribe((result) => {
        // this.subtype = result.find(i => i.id === this.bond.subtype.id);
        this.validityPeriod = undefined;
        if (this.subtype.properties[DictionaryPropertyType.VALID_PERIOD] === 'VALID_CUSTOM') {
          // Custom (Kundigungsklausel)
          this.visibleCancellationAfterDate = true;
          this.setValidityPeriodUnlimited(true);
          this.bond.bondValidTo = undefined;
        } else if (this.subtype.properties[DictionaryPropertyType.VALID_PERIOD] === 'VALID_BOTH') {
          this.setValidityPeriodUnlimited();
        } else if (this.subtype.properties[DictionaryPropertyType.VALID_PERIOD] === 'VALID_UNLIMITED') {
          this.setValidityPeriodUnlimited(true);
        } else if (this.subtype.properties[DictionaryPropertyType.VALID_PERIOD] === 'VALID_LIMITED') {
          this.setValidityPeriodLimited(true);
        } else {
          this.validityPeriodComboHiddenIds = new Set([this.VALIDITY_PERIOD_UNLIMITED, this.VALIDITY_PERIOD_LIMITED]);
        }
      });
    }
  }

  setValidityPeriodUnlimited(disabled = false) {
    this.validityPeriod = <DictionaryBaseDto>{id: this.VALIDITY_PERIOD_UNLIMITED};
    this.validityPeriodDisabled = disabled;
    if (!this.isBUIt(this.businessUnit)) {
      this.visibleValidToDate = false;
      this.bond.bondValidTo = undefined;
    } else {
      this.bond.estimatedValidTo = true;
    }
  }

  setValidityPeriodLimited(disabled = false) {
    this.validityPeriod = <DictionaryBaseDto>{id: this.VALIDITY_PERIOD_LIMITED};
    this.validityPeriodDisabled = disabled;
    this.visibleValidToDate = true;
    if (this.isBUIt(this.businessUnit)) {
      this.bond.estimatedValidTo = false;
    }
  }

  // used on bond value field (not in value table)
  isSingleValueEditable(statusChanged: boolean) {
    return !this.guiService.bondValuesVisible;
  }

  isCityFromDict(): boolean {
    return (
      this.isGermanCountry(this.bond.executionAddress.country) ||
      this.isItalianCountry(this.bond.executionAddress.country) ||
      this.isBelgianCountry(this.bond.executionAddress.country)
    );
  }

  isGermanCountry(country: DictionaryBaseDto): boolean {
    return this.bond && Country.isGermany(country);
  }

  isItalianCountry(country: DictionaryBaseDto): boolean {
    return this.bond && Country.isItaly(country);
  }

  isBelgianCountry(country: DictionaryBaseDto): boolean {
    return this.bond && Country.isBelgium(country);
  }

  isCountryConst(): boolean {
    return !this.canFullIssue;
  }

  private initCompanyNumberFormats() {
    this.taxNumberFormat = <NumberFormatDto>{};
    this.taxNumberFormat.numberName = this.DEFAULT_TAX_NAME;

    this.nationalIdNumberFormat = <NumberFormatDto>{};
    this.nationalIdNumberFormat.numberName = this.DEFAULT_NATIONAL_ID_NAME;
  }

  getContractSubjectCounterValue() {
    return this.bond.contractualObligations
      ? this.getContractSubjectMaxLength() - this.bond.contractualObligations.length
      : this.getContractSubjectMaxLength();
  }

  getContractSubjectMaxLength() {
    return this.MAX_LENGTH_CONTRACT_SUBJECT;
  }

  getClientCommentCounterValue() {
    return 4096 - ((this.bond.clientComment && this.bond.clientComment.length) || 0);
  }

  onValidFromChanged(validFrom: Date) {
    // if value table is not visible there is no way to change validFrom of bond value manually
    // so it is done automatically  here
    // this must be done so the validFrom validation passes
    if ((!this.bond.versionNumber || this.bond.versionNumber === 1) && !this.guiService.bondValuesVisible) {
      this.bond.values[0].validFrom = validFrom;
      if (this.initialDuration && validFrom) {
        this.bond.bondValidTo = new Date(validFrom.getTime() + this.initialDuration);
      }
    }
    this.recalculateBondValidTo();
  }

  private formChanged(val: any) {
    this.formChangedAfterPreview = true;
  }

  invoiceAmount(): string {
    if (this.isUserSpecialText()) {
      return '75';
    }
    return '25';
  }

  private isUserSpecialText(): boolean {
    if (this.subtype) {
      if (this.subtype.id === BondSubType.TECHNICAL_USER_SPECIAL_TEXT) {
        return true;
      }
    }
    return false;
  }

  onCountryChange() {
    console.log('onCountryChange');
    this.updateExecutionAddress();
    this.updatePostalAddress();
    this.bond.cityOfJurisdiction = <DictionaryBaseDto>{};
    this.bond.cityOfJurisdictionText = null;

    if (this.isCityFromDict()) {
      this.setDefaultCityOfJurisdiction();
    }
  }

  setDefaultCityOfJurisdiction() {
    if (Country.isItaly(this.bond.executionAddress.country)) {
      this.bond.cityOfJurisdiction.id = Country.MILAN;
    }

    if (Country.isGermany(this.bond.executionAddress.country)) {
      this.bond.cityOfJurisdiction.id = Country.KOLN;
    }

    if (Country.isBelgium(this.bond.executionAddress.country)) {
      this.bond.cityOfJurisdiction.id = Country.BRUSSELS;
    }
  }

  public showSpecialClauses(): boolean {
    if (this.bond && this.bond.subtype && this.bond.subtype.id) {
      if (
        this.bond.subtype.id === BondSubType.TECHNICAL_USER_SPECIAL_TEXT ||
        this.bond.subtype.id === BondSubType.TECHNICAL_USER_TEMPLATE
      ) {
        return false;
      }
    }
    return this.bond && this.bond.specialClauses && this.bond.specialClauses.length > 0;
  }

  contractAmountChange(contractAmount: number) {
    console.log('START contractAmountChange...');
    if (this.itBidBondComponent.bidBondFixedPercentageSubtype()) {
      this.itBidBondComponent.calculate();
    }
    console.log('END contractAmountChange.');
  }

  private updatePostalAddress() {
    this.copyFromRegistrationAddress(this.bond.beneficiaryRaw.correnspondenceAddress);
  }

  postalAddressClicked(checked: boolean) {
    if (!checked) {
      this.bondForm.removeControl('correspondenceAddress');
    }
    this.updatePostalAddress();
  }

  executionAddressClicked(checked: boolean) {
    if (!checked) {
      this.bondForm.removeControl('executionAddress');
    }
    this.updateExecutionAddress();
  }

  private updateExecutionAddress() {
    this.copyFromRegistrationAddress(this.bond.executionAddress);
  }

  private copyFromRegistrationAddress(address: AddressDto) {
    if (this.canBeneficiaryEdit()) {
      Object.assign(address, this.bond.beneficiaryRaw.registrationAddress);
    }
  }

  onChangeBeneficiaryPost(value: boolean) {
    this.bond.beneficiaryRaw.sendToBeneficiaryByPost = value;
    if (value && this.bond.beneficiaryRaw.sendToClientByPost) {
      this.bond.beneficiaryRaw.sendToClientByPost = false;
    }
  }

  onChangeClientPost(value: boolean) {
    this.bond.beneficiaryRaw.sendToClientByPost = value;
    if (value && this.bond.beneficiaryRaw.sendToBeneficiaryByPost) {
      this.bond.beneficiaryRaw.sendToBeneficiaryByPost = false;
    }
  }

  hideValidTo(): boolean {
    if (this.bond.subtype.id === BondSubType.BE_PERFORMANCE_BOND && !this.bond.id) {
      return true;
    }
    return (
      !this.visibleValidToDate ||
      (this.validityPeriod?.id === this.VALIDITY_PERIOD_UNLIMITED && this.businessUnit?.id !== BusinessUnit.IT)
    );
  }

  bondSubtypeHasCustomFields(): boolean {
    if (this.bond.subtype && this.bondSubtypes[this.bond.subtype.id]) {
      if (this.bond.subtype.id === BondSubType.TECHNICAL_USER_TEMPLATE) {
        if (this.isSetSubtypeFromCustomTemplate()) {
          return true;
        }
      } else {
        if (this.bondSubtypes[this.bond.subtype.id].relatedDictionaries['BondCustomFieldType'].length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  isSetSubtypeFromCustomTemplate() {
    if (this.customTemplate?.dictionarySelectors?.filter((bs) => bs.dictName === 'BondSubtype').length === 1) {
      this.originalSubtypeCustomTemplate = this.customTemplate.dictionarySelectors.find(
        (bs) => bs.dictName === 'BondSubtype'
      );
      if (
        this.bondSubtypes[this.originalSubtypeCustomTemplate.id].relatedDictionaries['BondCustomFieldType'].length > 0
      ) {
        return true;
      }
    }
  }

  recalculateBondValidTo() {
    const damageClauseTypeCustomField = this.bondCustomFieldsComponent.getDamageClauseTypeCustomField();
    if (damageClauseTypeCustomField) {
      const isDamageClause =
        damageClauseTypeCustomField.value !== this.bondCustomFieldsComponent.NO_DAMAGE_CLAUSE.value;
      const lastPaymentDate = this.getLastPaymentDate();
      if (isDamageClause) {
        this.bond.bondValidTo = DateUtils.addMonth(lastPaymentDate, 12);
        this.bond.estimatedValidTo = true;
      } else {
        this.bond.bondValidTo = lastPaymentDate;
      }
    }
  }

  getLastPaymentDate() {
    const payments = this.bond.payments;
    if (payments && payments.length > 0) {
      const sortedPayments = payments.sort(DateUtils.sorter('expectedDate'));
      return this.getBondValueDate(sortedPayments[payments.length - 1]);
    } else {
      return null;
    }
  }

  getBondValueDate(payment: BondPaymentDto) {
    if (payment.effectiveDate) {
      return payment.effectiveDate;
    }
    return DateUtils.addDay(payment.expectedDate, this.bondCustomFieldsComponent.getPaymentTermCustomFieldValue());
  }

  getExecutionAddressLabelKey() {
    if (this.bond.subtype.id === BondSubType.PROMESSE) {
      return 'clientbond.details.beneficiary.placeOfSale';
    } else if (this.bond.subtype.id === BondSubType.TIMBER_SALES) {
      return 'clientbond.details.beneficiary.salesAddress';
    } else {
      return 'clientbond.details.beneficiary.contractExecutionAddressOfBeneficiary';
    }
  }
}
