import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {BusinessObjectDto, ContactPersonCriteriaDto, ContactPersonDto} from '../../../../bonding_shared/model/dtos';
import {Subject} from 'rxjs';
import {TextSearchCriteria} from '../../../../bonding_shared/components/search/model/text-search-criteria';
import {ContactPersonService} from '../../../../bonding_shared/services/contact-person.service';
import {FormDialogComponent} from '../../../../bonding_shared/components/form-dialog/form-dialog.component';
import {NgForm} from '@angular/forms';
import {FormDialogParent} from '../../../../bonding_shared/components/form-dialog/FormDialogParent';
import {SimpleSelectorComponent} from '../../../../bonding_shared/components/search/simple-selector/simple-selector.component';
import {AppConfigService} from '../../../../bonding_shared/services';
import {StringUtils} from '../../../../bonding_shared/utils/string-utils';

@Component({
  selector: 'contact-person-selector-simple',
  templateUrl: './contact-person-selector-simple.component.pug',
})
export class ContactPersonSelectorSimpleComponent extends FormDialogParent implements OnInit {
  @ViewChild(FormDialogComponent, {static: true}) addNewContactPersonDialog: FormDialogComponent;
  @ViewChild(SimpleSelectorComponent, {static: true}) contactPersonSelector: SimpleSelectorComponent<
    ContactPersonCriteriaDto,
    ContactPersonDto
  >;

  @Input() title: string;

  @Input() businessObject: BusinessObjectDto;

  /**
   * Open selector event emitter
   * The same emitter instance should be injected into this component and the component that opens this selector
   */
  @Input() open: Subject<boolean>;

  /**
   * to be able to switch between advanced/basic search mode
   */
  _searchModeSwitchable = false;

  readonly StringUtils = StringUtils;

  @Input() set searchModeSwitchable(value: boolean) {
    this._searchModeSwitchable = value;
  }

  get searchModeSwitchable(): boolean {
    // IKI 3305
    return this._searchModeSwitchable && !this.appService.kuke;
  }

  /**
   * when fixedSearch = true, search is done by injected, predefined criteria, user cannot modify them
   */
  @Input() fixedSearch = false;

  @Input() addNew = true;

  @Input() set criteria(c: ContactPersonCriteriaDto) {
    this.dataProvider.textSearch = false;
    this.dataProvider.searchCriteria.criteria = c;
    console.log('contact person search initial criteria: ', c);
  }

  @Input() set textCriteria(c: TextSearchCriteria) {
    this.dataProvider.textSearch = true;
    this.dataProvider.textSearchCriteria = c;
  }

  @Output() selectItem = new EventEmitter<ContactPersonDto>();

  @Output() create = new EventEmitter<ContactPersonDto>();

  dataProvider: SearchDataProvider<ContactPersonCriteriaDto, ContactPersonDto>;

  newContactPerson: ContactPersonDto;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  constructor(
    private service: ContactPersonService,
    private appService: AppConfigService,
    protected contactPersonService: ContactPersonService
  ) {
    super();
    this.dataProvider = new SearchDataProvider<ContactPersonCriteriaDto, ContactPersonDto>(service);
    this.dataProvider.searchCriteria.criteria = <ContactPersonCriteriaDto>{};
    this.newContactPerson = <ContactPersonDto>{};
  }

  ngOnInit(): void {
    this.form = this.ngForm.form;
    this.addNewContactPersonDialog.parentComponent = this;
  }

  onSelectContactPerson(c: ContactPersonDto) {
    // TODO: perhaps a-table should be corrected: when page is changed in a-table empty object is emitted
    if (c !== undefined && c !== null) {
      this.selectItem.emit(c);
      this.open.next(false);
    }
  }

  saveNewContactPerson(shouldAdd: boolean) {
    console.log('saveNewContactPerson::' + shouldAdd);
    this.addNewContactPersonDialog.showErrors = false;
    if (shouldAdd) {
      this.newContactPerson.company = {
        id: this.dataProvider.searchCriteria.criteria.companyId,
        registrationName: '',
        partnerNumber: null,
      };
      this.newContactPerson.businessObject = this.businessObject;
      // this.newContactPerson.lastName = '';
      // this.newContactPerson.email = '';
      console.log('Adding new contact person ::' + JSON.stringify(this.newContactPerson));
      this.service.create(this.newContactPerson).subscribe(
        (success) => {
          this.contactPersonSelector.onSearch();
          this.create.emit(success);
          this.newContactPerson = <ContactPersonDto>{};
        },
        (error) => {
          this.addNewContactPerson();
          this.addNewContactPersonDialog.errors = error;
          this.addNewContactPersonDialog.showErrors = true;
        }
      );
    } else {
      console.log('Cancel adding new contact person');
      this.newContactPerson = <ContactPersonDto>{};
      this.showErrors = false;
    }
  }

  addNewContactPerson() {
    this.newContactPerson = <ContactPersonDto>{};
    this.showErrors = false;
    const confirmationPromise: Promise<boolean> = this.addNewContactPersonDialog.open(
      'company.crm.addNewContactPerson'
    );
    confirmationPromise.then((result) => this.saveNewContactPerson(result));
  }

  kuke() {
    return this.appService.kuke;
  }
}
