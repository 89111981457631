import {Injectable} from '@angular/core';
import {ClaimService} from '../../../bonding_shared/services/claim.service';
import {
  ClaimAdditionalInformationDto,
  ClaimVersionCriteriaDto,
  ClaimVersionDto,
  SearchCriteria,
  SortBy,
  UserIdDto,
} from '../../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {UserResponsibleService} from '../../../bonding_shared/services/user-responsible.service';

@Injectable()
export class ClaimGuiService {
  dataProvider: SearchDataProvider<ClaimVersionCriteriaDto, ClaimVersionDto>;

  constructor(claimService: ClaimService, private userResponsibleService: UserResponsibleService) {
    this.dataProvider = ClaimGuiService.createDataProvider(
      claimService,
      userResponsibleService.getUserResponsibleSearchCriteria()
    );
  }

  static createDataProvider(claimService: ClaimService, userResponsible?: UserIdDto) {
    const dp: SearchDataProvider<ClaimVersionCriteriaDto, ClaimVersionDto> =
      BusinessUtils.createDataProviderWithCriteria(
        claimService,
        ClaimGuiService.createClaimSearchCriteria(userResponsible)
      );
    dp.textSearch = false;
    return dp;
  }

  static createClaimSearchCriteria(userResponsible?: UserIdDto): SearchCriteria<ClaimVersionCriteriaDto> {
    return <SearchCriteria<ClaimVersionCriteriaDto>>{
      criteria: {
        claim: {
          businessUnit: {},
        },
        notificationDateRange: {},
        notificationReceivedDateRange: {},
        createDateRange: {},
        claimRelatedParties: {},
        last: true,
        versionPhase: 'LAST',
        userResponsible: userResponsible,
      },
      sortBy: <SortBy>{column: 'treatOrderSorting', direction: 'UP'},
    };
  }

  static emptyVersion(): ClaimVersionDto {
    return <ClaimVersionDto>{claim: {}};
  }

  static emptyAdditionalInformation(): ClaimAdditionalInformationDto {
    return <ClaimAdditionalInformationDto>{
      bankAccountHolder: null,
      bankAccountNumber: null,
      cession: false,
      cessionAssignee: null,
      creditLimitAmount: null,
      reportedBy: null,
      thirdPartyName: null,
    };
  }

  public resetSearchCriteria() {
    this.dataProvider.searchCriteria = ClaimGuiService.createClaimSearchCriteria(
      this.userResponsibleService.getUserResponsibleSearchCriteria()
    );
  }
}
