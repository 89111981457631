import {Injectable} from '@angular/core';
import {
  CompanyCriteriaDto,
  CompanyDto,
  CompanyExtendedSearchParamsDto,
  CompanyService,
  DictionaryBaseDto,
  SearchDataProvider,
} from '../../../bonding_shared';
import {AddressType} from '../../../bonding_shared/model/dictionary-ids';
import {Observable} from 'rxjs';
import {BiService} from '../../../bonding_shared/services/bi.service';

/**
 * Service for common logic of company components.
 *
 */

@Injectable()
export class CompanyGuiService {
  /**
   * Keeps selected criteria for company search view
   */
  searchDataProvider: SearchDataProvider<CompanyCriteriaDto, CompanyDto>;

  private companySource: DictionaryBaseDto;
  private biCompanies = new Map<string, {value: CompanyDto}>([]);
  addressTypes = [
    AddressType.REGISTRATION,
    AddressType.LEGAL_RESIDENCE,
    AddressType.CORRESPONDENCE,
    AddressType.EXECUTION,
    AddressType.OTHER,
  ];

  constructor(private biIntraService: BiService) {
    this.searchDataProvider = new SearchDataProvider<CompanyCriteriaDto, CompanyDto>(biIntraService);
    this.searchDataProvider.searchCriteria.params = <CompanyExtendedSearchParamsDto>{};
  }

  setCompany(id: string, company: CompanyDto) {
    this.biCompanies.set(id, {value: company});
  }

  getCompany(id: string): CompanyDto {
    const v = this.biCompanies.get(id);
    return v ? v.value : undefined;
  }

  createDataProvider(): SearchDataProvider<CompanyCriteriaDto, CompanyDto> {
    return new SearchDataProvider<CompanyCriteriaDto, CompanyDto>(this.biIntraService);
  }

  get searchParams(): CompanyExtendedSearchParamsDto {
    return this.searchDataProvider.searchCriteria.params;
  }

  setCompanySource(biSource: DictionaryBaseDto) {
    this.companySource = biSource;
    this.searchParams.biSourceId = biSource ? biSource.id : undefined;
  }

  getCompanySource(): DictionaryBaseDto {
    return this.companySource;
  }

  getCompanySourceId(): number {
    return this.companySource ? this.companySource.id : undefined;
  }

  getPossibleBI(country: number): Observable<DictionaryBaseDto[]> {
    return this.biIntraService.getPossibleBI(country);
  }

  public getCompanyCountry(company: CompanyDto): DictionaryBaseDto {
    if (company.address && company.address.country) {
      return company.address.country;
    }

    let country: DictionaryBaseDto;
    let idx = 0;
    while (!country && idx < this.addressTypes.length) {
      country = this.getCountry(company, this.addressTypes[idx]);
      idx++;
    }

    if (!country && company.addresses && company.addresses.length > 0) {
      country = company.addresses[0].country;
    }
    return country;
  }

  private getCountry(company: CompanyDto, addressTypeId: number): DictionaryBaseDto {
    if (company.addresses) {
      const companyAddress = company.addresses.find((a) => a.type.id === addressTypeId);
      return companyAddress.country;
    }
    return undefined;
  }
}
