import {PolicyContractVersionSearchComponent} from './policy-contract-version-search.component';
import {PolicyContractDetailsComponent} from './policy-contract-details.component';
import {PolicyContractTechnicalDetailsComponent} from './policy-contract-technical-details.component';
import {PolicyContractPreviewComponent} from './policy-contract-preview.component';
import {BonusMalusRequestDetailsComponent} from './components/premium-return/bonus-malus-request-details.component';
import {BonusMalusRequestListComponent} from './components/premium-return/bonus-malus-request-list.component';
import {PolicyDeclarationDetailsComponent} from './policy-declaration-details.component';
import {PolicyDeclarationImportComponent} from './components/sections/kuke/policy-declaration-import.component';
import {
  PolicyContractDetailsGuard,
  PolicyContractPreviewGuard,
  PolicyContractTechnicalDetailsGuard,
  PolicyContractVersionSearchGuard,
  PolicyDeclarationDetailsGuard,
} from './_guards';
import {PolicyDrawdownSummaryComponent} from './components/drawdowns/policy-drawdown-summary.component';
import {PolicyDrawdownDetailsComponent} from './components/drawdowns/policy-drawdown-details.component';
import {PolicyDrawdownPaymentsComponent} from './components/drawdowns/policy-drawdown-payments.component';
import {PolicyDrawdownScheduleComponent} from './components/drawdowns/policy-drawdown-schedule.component';
import {PolicyMonitoringVersionDetailsComponent} from './policy-monitoring-version-details.component';
import {PolicyContractReportingPeriodsGuard} from './_guards/policy-contract-reporting-periods.guard';
import {PolicyContractReportingPeriodsDetailsComponent} from './components/policy-contract-reporting-periods-details.component';

export const PolicyContractRoutes = [
  {
    path: 'policy-contract-preview/:id',
    component: PolicyContractPreviewComponent,
    canActivate: [PolicyContractPreviewGuard],
  },
  {
    path: 'policy-contract-details/:id',
    component: PolicyContractDetailsComponent,
    canActivate: [PolicyContractDetailsGuard],
  },
  {
    path: 'policy-contract-details/:id/:clientId',
    component: PolicyContractDetailsComponent,
    canActivate: [PolicyContractDetailsGuard],
  },
  {
    path: 'policy-contract-display/:id/:presentationMode',
    component: PolicyContractDetailsComponent,
    canActivate: [PolicyContractDetailsGuard],
  },
  {
    path: 'policy-contract-details/:id/:clientId/:offerVersionId',
    component: PolicyContractDetailsComponent,
    canActivate: [PolicyContractDetailsGuard],
  },
  {
    path: 'policy-contract-version-search',
    component: PolicyContractVersionSearchComponent,
    canActivate: [PolicyContractVersionSearchGuard],
  },
  {
    path: 'policy-contract-technical-details/:id',
    component: PolicyContractTechnicalDetailsComponent,
    canActivate: [PolicyContractTechnicalDetailsGuard],
  },
  {
    path: 'bonus-malus-request-details/:className/:id',
    component: BonusMalusRequestDetailsComponent,
  },
  {
    path: 'bonus-malus-request-list/:className/:policyContractId',
    component: BonusMalusRequestListComponent,
  },
  {
    path: 'policy-declaration-details/:policyContractId',
    component: PolicyDeclarationDetailsComponent,
    canActivate: [PolicyDeclarationDetailsGuard],
  },
  {
    path: 'policy-declaration-import/:policyContractId/:reportingPeriodId/:limitListVersionId/:declaredOnId',
    component: PolicyDeclarationImportComponent,
    canActivate: [PolicyDeclarationDetailsGuard],
  },
  {
    path: 'policy-drawdown-summary/:masterPolicyId',
    component: PolicyDrawdownSummaryComponent,
  },
  {
    path: 'policy-drawdown-details/:drawdownId',
    component: PolicyDrawdownDetailsComponent,
  },
  {
    path: 'policy-drawdown-payments/:drawdownPaymentId',
    component: PolicyDrawdownPaymentsComponent,
  },
  {
    path: 'policy-drawdown-schedule/:drawdownScheduleId',
    component: PolicyDrawdownScheduleComponent,
  },
  {
    path: 'policy-drawdown-details',
    component: PolicyDrawdownDetailsComponent,
  },
  {
    path: 'policy-drawdown-payments',
    component: PolicyDrawdownPaymentsComponent,
  },
  {
    path: 'policy-drawdown-schedule',
    component: PolicyDrawdownScheduleComponent,
  },
  {
    path: 'policy-monitoring-new/:policyContractId/:policyMonitoringTypeId',
    component: PolicyMonitoringVersionDetailsComponent,
    canActivate: [PolicyContractTechnicalDetailsGuard],
  },
  {
    path: 'policy-monitoring-version/:versionId',
    component: PolicyMonitoringVersionDetailsComponent,
    canActivate: [PolicyContractTechnicalDetailsGuard],
  },
  {
    path: 'policy-reporting-periods-details/:policyContractId',
    component: PolicyContractReportingPeriodsDetailsComponent,
    canActivate: [PolicyContractReportingPeriodsGuard],
  },
];
