import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {ClientInquiryDetailsComponent} from './client-inquiry-details.component';
import {SharedComponentsModule} from '../../../../../src/client/app/bonding_shared/components/shared-components.module';
import {CompanySharedModule} from '../../../../../src/client/app/bonding/company/components/shared/company-shared.module';
import {ClientOfferDetailsComponent} from './client-offer-details.component';
import {InquirySharedModule} from '../../../../../src/client/app/bonding/inquiry/shared/inquiry-shared.module';
import {ClientAnnexInquiryDetailsComponent} from '../../../../../src/client/app/portal/inquiry/client-annex-inquiry-details.component';
import {ClientInquirySearchComponent} from './client-inquiry-search.component';
import {InquiryFormsModule} from '../../../../../src/client/app/bonding/inquiry/forms/inquiry-forms.module';
import {InquiriesDashboardComponent} from './inquiries-dashboard.component';
import {ClientOffersComponent} from './client-offers.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    InquirySharedModule,
    InquiryFormsModule,
  ],
  declarations: [
    ClientInquiryDetailsComponent,
    ClientOfferDetailsComponent,
    ClientAnnexInquiryDetailsComponent,
    ClientInquirySearchComponent,
    ClientOffersComponent,
    InquiriesDashboardComponent,
  ],
  exports: [ClientAnnexInquiryDetailsComponent],
})
export class ClientInquiryModule {}
