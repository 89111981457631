import {Component, OnInit} from '@angular/core';
import {
  CompanyDto,
  CompanyResponsiblePersonDto,
  DictionaryBaseDto,
  LegalEventDto,
  UserWithProfileDto,
} from '../../bonding_shared/model/dtos';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BackendError} from '../../bonding_shared/model/index';
import {CompanyService} from '../../bonding_shared/services/company.service';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {AppConfigService} from '../../bonding_shared/services/app-config.service';
import {UserService} from '../../bonding_shared/services/user.service';
import {
  CompanyElementaryRight,
  CompanyResponsiblePersonRole,
  ProfileCategory,
} from '../../bonding_shared/model/dictionary-ids';
import {LoggedUserService} from '../../bonding_shared/services';

@Component({
  selector: 'company-managers',
  templateUrl: 'company-managers.component.html',
})
export class CompanyManagersComponent extends DetailsView implements OnInit {
  serverErrors: BackendError;
  companyForm: UntypedFormGroup;
  showErrors = false;
  company: CompanyDto;
  underwritersForAssignment: UserWithProfileDto[];
  underwriter: UserWithProfileDto;
  policyManagersForAssignment: UserWithProfileDto[];
  policyManager: UserWithProfileDto;
  bondingManagersForAssignment: UserWithProfileDto[];
  bondingManager: UserWithProfileDto;

  constructor(
    private _companyService: CompanyService,
    private _route: ActivatedRoute,
    _formBuilder: UntypedFormBuilder,
    protected growlService: GrowlService,
    public appConfigService: AppConfigService,
    protected userService: UserService,
    protected loggedUserService: LoggedUserService
  ) {
    super(growlService);
    this.readOnlyMode = true;
    this.handleButtons();
    this.companyForm = _formBuilder.group({});
  }

  ngOnInit() {
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  splitResponsiblePersons(company: CompanyDto) {
    const underwriterCR = company.responsiblePersons.filter(
      (p) => p.role.id === CompanyResponsiblePersonRole.UNDERWRITER
    )[0];
    if (underwriterCR) {
      this.underwriter = underwriterCR.user;
    } else {
      this.underwriter = null;
    }
    const policyManagerCR = company.responsiblePersons.filter(
      (p) => p.role.id === CompanyResponsiblePersonRole.POLICY_MANAGER
    )[0];
    if (policyManagerCR) {
      this.policyManager = policyManagerCR.user;
    } else {
      this.policyManager = null;
    }
    const bondManagerCR = company.responsiblePersons.filter(
      (p) => p.role.id === CompanyResponsiblePersonRole.BONDING_MANAGER
    )[0];
    if (bondManagerCR) {
      this.bondingManager = bondManagerCR.user;
    } else {
      this.bondingManager = null;
    }
  }

  initializeView(params: Params, force?: boolean) {
    const id: number = +params['id'];
    if (id > 0) {
      this._companyService.getCompany(id).subscribe(
        (company) => {
          this.company = company;
          this.setCompany(company);
          this.splitResponsiblePersons(company);
        },
        () => console.log('Error on getCompany with id ' + id)
      );
    }
    this.downloadUsersForAssignment();
  }

  downloadUsersForAssignment() {
    if (!this.appConfigService.kuke) {
      this.userService.getUsersForCompanyManagers(ProfileCategory.CREDENDO_BONDING, false).subscribe({
        next: (users) => (this.underwritersForAssignment = users),
        error: (error) => this.handleServerError(error),
      });
      this.userService.getUsersForCompanyManagers(ProfileCategory.CREDENDO_POLICIES, false).subscribe({
        next: (users) => (this.policyManagersForAssignment = users),
        error: (error) => this.handleServerError(error),
      });
    } else {
      this.userService.getUsersForCompanyManagers(ProfileCategory.KUKE_LIMITS, false).subscribe({
        next: (users) => {
          this.policyManagersForAssignment = users;
          this.bondingManagersForAssignment = users;
        },
        error: (error) => this.handleServerError(error),
      });
    }
  }
  goBack() {
    window.history.back();
  }

  onSave() {
    this.inProgress = true;
    this.serverErrors = null;
    this.handleCompanyResponsiblePersons();
    this._companyService.saveCompany(this.company).subscribe({
      next: (company) => this.afterSave(company),
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      },
      complete: () => this.showCompany(),
    });
  }

  createCompanyResponsiblePersonDto(user: UserWithProfileDto, roleId: number): CompanyResponsiblePersonDto {
    return <CompanyResponsiblePersonDto>{user: user, role: <DictionaryBaseDto>{id: roleId}};
  }

  handleCompanyResponsiblePerson(roleId: number, user: UserWithProfileDto) {
    const index = this.company.responsiblePersons.findIndex((p) => p.role.id === roleId);
    if (index !== -1) {
      if (user) {
        this.company.responsiblePersons[index].user = user;
      } else {
        this.company.responsiblePersons.splice(index, 1);
      }
    } else if (user) {
      this.company.responsiblePersons.push(this.createCompanyResponsiblePersonDto(user, roleId));
    }
  }

  handleCompanyResponsiblePersons() {
    this.handleCompanyResponsiblePerson(CompanyResponsiblePersonRole.UNDERWRITER, this.underwriter);
    this.handleCompanyResponsiblePerson(CompanyResponsiblePersonRole.POLICY_MANAGER, this.policyManager);
    this.handleCompanyResponsiblePerson(CompanyResponsiblePersonRole.BONDING_MANAGER, this.bondingManager);
  }

  onCancel() {
    super.onCancel(this._route);
  }

  afterSave(company: CompanyDto) {
    this.setCompany(company);
    this.showErrors = false;
    this.showSavedMsg();
  }
  showCompany() {
    this.inProgress = false;
    if (this.serverErrors) {
      return;
    }
  }

  showSavedMsg() {
    this.growlService.notice('Company is saved!');
    this.inProgress = false;
  }

  showFormError() {
    this.growlService.error('The form has errors!');
    this.inProgress = false;
  }
  private setCompany(company: CompanyDto) {
    if (company && !company.legalEvent) {
      company.legalEvent = <LegalEventDto>{};
    }
    this.company = company;
  }

  protected handleButtons() {
    this.saveButton.hidden = this.readOnlyMode;
    this.saveButton.disabled = !this.canManagersEdit() && this.appConfigService.kuke;
    this.cancelButton.hidden = this.readOnlyMode;
    this.cancelButton.disabled = !this.canManagersEdit() && this.appConfigService.kuke;
    this.editButton.hidden = !this.readOnlyMode;
    this.editButton.disabled = !this.canManagersEdit() && this.appConfigService.kuke;
  }

  get readOnly() {
    return this.readOnlyMode;
  }

  canManagersEdit(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_MANAGERS_EDIT);
  }
}
