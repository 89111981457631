import {ClientTurnoverDetailsComponent} from './client-turnover-details.component';
import {ClientAmendmentDetailsComponent} from './client-amendment-details.component';
import {ClientTurnoverImportComponent} from './client-turnover-import.component';
import {ClientAmendmentImportComponent} from './client-amendment-import.component';
import {
  ClientAmendmentDetailsGuard,
  ClientTurnoverDetailsGuard,
  ClientTurnoverImportGuard,
  ClientAmendmentImportGuard,
  ClientDeclarationHistoryDetailsGuard,
} from './_guards';
import {ClientDeclarationHistoryDetailsComponent} from './client-declaration-history-details.component';

export const ClientDeclarationRoutes = [
  {
    path: 'client-turnover-details',
    component: ClientTurnoverDetailsComponent,
    canActivate: [ClientTurnoverDetailsGuard],
  },
  {
    path: 'client-turnover-details/:reportingPeriodId/:limitListVersionId/:declaredOnId',
    component: ClientTurnoverDetailsComponent,
    canActivate: [ClientTurnoverDetailsGuard],
  },
  {
    path: 'client-turnover-details/:policyContractId',
    component: ClientTurnoverDetailsComponent,
    canActivate: [ClientTurnoverDetailsGuard],
  },
  {
    path: 'client-amendment-details',
    component: ClientAmendmentDetailsComponent,
    canActivate: [ClientAmendmentDetailsGuard],
  },
  {
    path: 'client-amendment-details/:baseDeclarationId',
    component: ClientAmendmentDetailsComponent,
    canActivate: [ClientAmendmentDetailsGuard],
  },
  {
    path: 'client-amendment-details-by-policy/:policyContractId',
    component: ClientAmendmentDetailsComponent,
    canActivate: [ClientAmendmentDetailsGuard],
  },
  {
    path: 'client-amendment-details/:reportingPeriodId/:limitListVersionId/:declaredOnId',
    component: ClientAmendmentDetailsComponent,
    canActivate: [ClientAmendmentDetailsGuard],
  },
  {
    path: 'client-turnover-history-details/:outstandings',
    component: ClientDeclarationHistoryDetailsComponent,
    canActivate: [ClientDeclarationHistoryDetailsGuard],
  },
  {
    path: 'client-outstandings-history-details/:outstandings',
    component: ClientDeclarationHistoryDetailsComponent,
    canActivate: [ClientDeclarationHistoryDetailsGuard],
  },
  {
    path: 'client-turnover-import/:reportingPeriodId/:limitListVersionId/:declaredOnId',
    component: ClientTurnoverImportComponent,
    canActivate: [ClientTurnoverImportGuard],
  },
  {
    path: 'client-amendment-import/:reportingPeriodId/:limitListVersionId/:declaredOnId',
    component: ClientAmendmentImportComponent,
    canActivate: [ClientAmendmentImportGuard],
  },
];
