import {Component, Input} from '@angular/core';
import {RepoDocumentElementaryRight, ElementaryRight, RepoDocumentCategory} from '../../model/dictionary-ids';
import {DictionaryBaseDto} from '../../model/dtos';
import {AppConfigService, DictionaryService, LoggedUserService, RouterService} from '../../services';

@Component({
  selector: 'document-button',
  template: `
    <button
      *ngIf="showButton && !categorySelection"
      type="button"
      class="bon-btn-info"
      (click)="toDocuments(primaryCategoryId)"
      [title]="'documents.button' | translate"
      btnIcon="fa-info"
      translate
      [disabled]="disabled"
    >
      documents.button
    </button>
    <item-dropdown
      *ngIf="showButton && categorySelection"
      [items]="documentCategories"
      (menuSelect)="toDocuments($event.id)"
      caption="documents.button"
    ></item-dropdown>
  `,
})
export class DocumentButtonComponent {
  @Input() rightToRead: RepoDocumentElementaryRight;
  @Input() rightToWrite: RepoDocumentElementaryRight;
  @Input() businessObjectTypeId: number;
  @Input() businessObjectId: number;
  @Input() returnBusinessObjectId: number;
  @Input() returnBusinessObjectTypeId: number;
  @Input() showReturnBusinessObjectDocuments = false;
  @Input() disabled = false;
  @Input() allowedFileTypes: string;

  documentCategories: DictionaryBaseDto[];

  constructor(
    private dictionaryService: DictionaryService,
    public router: RouterService,
    public appService: AppConfigService,
    private loggedUserService: LoggedUserService
  ) {
    this.dictionaryService
      .getDictionary('RepoDocumentCategory')
      .subscribe(
        (entries) => (this.documentCategories = entries.filter((t) => t.id !== RepoDocumentCategory.BENEFICIARY))
      );
  }

  get categorySelection() {
    return this.appService.kuke && !this.loggedUserService.portal;
  }

  get primaryCategoryId() {
    return this.appService.kuke && this.loggedUserService.portal ? RepoDocumentCategory.EXTERNAL : undefined;
  }

  get showButton() {
    return (
      this.loggedUserService.portal ||
      this.loggedUserService.hasRight(RepoDocumentElementaryRight.REPO_DOCUMENT_READ_ALL) ||
      (this.rightToRead && this.loggedUserService.hasRight(this.rightToRead))
    );
  }

  toDocuments(categoryId: number) {
    this.router.toRepositoryDocumentsWithParams({
      boTypeId: this.businessObjectTypeId,
      boId: this.businessObjectId,
      returnBoTypeId: this.returnBusinessObjectTypeId,
      returnBoId: this.returnBusinessObjectId,
      categoryId: this.getBusinessCategory(categoryId),
      showReturnBoDocuments: this.showReturnBusinessObjectDocuments,
      rightToRead: this.rightToRead,
      rightToWrite: this.rightToWrite,
      allowedFileTypes: this.allowedFileTypes,
    });
  }

  getBusinessCategory(categoryId: number) {
    if (this.appService.kuke) {
      return categoryId === RepoDocumentCategory.INTERNAL ? undefined : categoryId;
    }
    return categoryId;
  }
}
