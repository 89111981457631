import {
  AfterContentInit,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import {PageChangedEvent, PaginationComponent} from 'ngx-bootstrap/pagination';
import {StringUtils} from '../../utils/string-utils';
import {DictionaryBaseDto, Page, SearchResult, SortBy} from '../../model/index';
import {ColumnComponent} from './column.component';
import {ColumnTemplateComponent} from './column-template.component';
import {DataProvider} from './data-provider';
import {Subject} from 'rxjs';
import {RowFormat} from './row-format';
import {IPasteFromExcelConfig, PasteFromExcelResult, PasteFromExcelService} from './services/paste-from-excel.service';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {TableValidators} from './table.validators';
import {NumberUtils} from '../../utils/number-utils';
import {FormatService, SearchDataProvider} from '../../services';
import {Direction} from '../../model/dtos';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

export class CustomButton {
  name: string;
  title: string;
  class: string;
  icon: string;
  param: any;
  visible: true;
  dropdownItems: any[];
  alignment: 'left' | 'right';
  isAllwaysVisible = false;
}

export class CustomButtonEvent<T> {
  button: CustomButton;
  item: T;
  dropdownItem?: any;
}

@Directive()
export abstract class TableComponent<T> implements AfterContentInit {
  @ContentChildren(ColumnComponent) columns: QueryList<ColumnComponent<T>>;
  @ViewChildren(ColumnTemplateComponent) columnTemplates: QueryList<ColumnTemplateComponent<T>>;
  @ViewChild('pasteFromExcelConfirmDialog', {static: true}) pasteFromExcelConfirmDialog: ConfirmDialogComponent;
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;

  private _editable: boolean;

  get editable(): boolean {
    return this._editable;
  }

  @Input() set editable(editable: boolean) {
    this._editable = editable;
    this.resetControls();
    if (this.form) {
      this.form.updateValueAndValidity();
    }
  }

  @Input() pageSize = 20;
  @Input() rowEditable: (item: T) => boolean;
  @Input() rowHidden: (item: T) => boolean;
  @Input() rowDeletionDisabled: (item: T) => boolean;
  @Input() rowDeletionDisabledHint: (item: T) => string;
  @Input() pagination = false;
  @Input() moveTopButton: boolean;
  @Input() deleteButton: boolean;
  @Input() deleteAllButton: boolean;
  @Input() addButton: boolean;
  @Input() addDisabled = false;
  @Input() footer: boolean;
  @Input() selectedItem: T;
  @Input() canUnselect = true;
  @Input() selection = true;
  @Input() buttonsCellWidth = '10%';
  @Input() customButtons: CustomButton[];
  @Input() customHoverButtons: CustomButton[];
  @Input() customButtonVisible: (item: T) => boolean;
  @Input() spacer = true;
  @Input() restoreDataId = 'id';
  @Input() formElementErrorClass = '';
  @Input() autoUpdateValidators: boolean | 'reset' = false;
  @Input() customHeaderLink = false;
  _pasteFromExcelButton = false;
  @Input() pasteFromExcelConfigs: IPasteFromExcelConfig[];
  @Input() sortable = true;
  @Input() headerRowCount = 1;
  @Input() hideHeader = false;
  @Input() columnMode = false;
  @Input() permEditMode = false;
  @Input() uniqueColumns: string[];
  @Input() minLength: number;
  @Output() columnSelect = new EventEmitter<{index: number; selected: boolean}>();
  @Input() formGroupValidator: (formGroup: UntypedFormGroup) => ValidationErrors | null;
  @Input() initialSortByDataProvider = false;

  _inProgress = false;
  get inProgress() {
    return this._inProgress;
  }
  set inProgress(inProgress: boolean) {
    this._inProgress = inProgress;
    this.inProgressChange.emit(inProgress);
  }

  checkAllVisible = false;
  /**
   * The form [ngFormModel] from parent component
   */
  @Input() formModel: UntypedFormGroup;
  /**
   * The name of sub-group variable in formModel used for this table. Mandatory when you add several tables
   * in one form
   *
   * @type {string}
   */
  @Input() groupName = 'table';
  @Input() showAllErrors = false;

  // dictionary list
  @Input() addDictRow: string = null;
  @Input() addDictColSpan = '1';

  /**
    This may be used for debugging purpose, to identify table, if there are multiple tables in parent component
   */
  @Input() tableName: string;

  private ngAfterContentInitCompleted = false;

  @Input() set pasteFromExcelButton(pasteFromExcelButton: boolean) {
    this._pasteFromExcelButton = pasteFromExcelButton;
    if (this.ngAfterContentInitCompleted) {
      this.initPasteFromExcelConfig();
    }
  }

  get pasteFromExcelButton() {
    return this._pasteFromExcelButton;
  }

  /**
   * Data provider to retrieve data (optional).
   */
  @Input() set dataProvider(dataProvider: DataProvider<T>) {
    this._inProgress = true;
    this._dataProvider = dataProvider;
    if (!this.initialDataProvider && dataProvider) {
      this.initialDataProvider = dataProvider.clone();
    }
  }

  get dataProvider(): DataProvider<T> {
    return this._dataProvider;
  }

  @Input() showBackendErrors = true;
  @Input() emitDeleteButton = false;
  @Input() validToButton = false;

  @Output() pageChange = new EventEmitter<PageChangedEvent>();
  @Output() selectItem = new EventEmitter<T>();
  @Output() moveTop = new EventEmitter<T>();
  @Output() addItem = new EventEmitter<T>();
  @Output() inProgressChange = new EventEmitter<boolean>();
  @Output() deleteButtonClick = new EventEmitter<T>();
  @Output() addUpdateDelete = new EventEmitter<T>();
  @Output() customButtonClick = new EventEmitter<CustomButtonEvent<T>>();
  @Output() done = new EventEmitter<T>();
  @Output() cancel = new EventEmitter();
  @Output() dataProviderSearchFinished = new EventEmitter<SearchResult<T>>();
  @Output() searchButtonClick = new EventEmitter<string>();
  @Output() dataProviderSearchError = new EventEmitter<any>();
  @Output() addButtonClick = new EventEmitter();
  @Output() itemsCountChange = new EventEmitter<number>();
  sums: {[index: string]: number};

  newDictRow = <T>{};

  @Input() page = 1;
  sortBy = <SortBy>{};
  showErrors = false;
  errorMessage: any;
  addButtonLabel = 'Add';

  private _dataProvider: DataProvider<T>;
  // https://jira.accuratus-software.pl/browse/BON-4231
  // initialDataProvider - used to avoid researching when changing page or sorting results
  private initialDataProvider: DataProvider<T>;

  private sortColumn: ColumnComponent<T>;
  protected _items: T[];
  private _selectedItemId: any;
  private _totalCount = 0;

  private pasteFromExcelButtonDisabled = false;
  public pasteFromExcelWindowVisible = false;
  public pasteFromExcelResult: PasteFromExcelResult<T>;

  private _addButtonEventEmitter: Subject<boolean>;

  private _addButtonEventEmitterActive: boolean;
  protected rowInEdition = false;
  private hoverItem: T;

  /**
   * Function that determines if row is editable
   * To be used to switch off edition only for some rows
   */
  @Input() rowEditableCallback: (item: T) => boolean;

  /**
   * Function that determines if row is deletable
   * To be used to switch off deletion only for some rows
   */
  @Input() rowDeletableCallback: (item: T) => boolean;

  @Input() rowOnDeleteCallback: (item: T, items: T[]) => boolean;

  @Input() headerClickCallback: (columnNumber: number) => void;

  /**
   * Function that returns FormatRow object describing format style to be used for the row
   */
  @Input() rowFormatCallback: (item: T) => RowFormat = function (item: T): RowFormat {
    return new RowFormat();
  };

  @Input()
  set addButtonEventEmitterActive(active: boolean) {
    this._addButtonEventEmitterActive = active;
  }

  get addButtonEventEmitterActive(): boolean {
    return this._addButtonEventEmitterActive;
  }

  get totalCount(): number {
    return this._totalCount;
  }

  @Input()
  set totalCount(totalCount: number) {
    if (this._totalCount !== totalCount) {
      this._totalCount = totalCount;
      if (totalCount && totalCount > 0 && this.page !== 1) {
        const pagEv = <PageChangedEvent>{itemsPerPage: this.pageSize, page: 1};
        this.paginationPageChanged(pagEv);
      }
    }
  }

  get items(): T[] {
    return this._items;
  }

  @Input()
  set items(items: T[]) {
    this._items = items;
    if (this.sortColumn && !this.pagination) {
      this.performSort(false);
    }
    if (this._selectedItemId) {
      this.selectById();
    }
    this.updateHiddenDictItems();
    this.resetControls();
    this.setItemsCallback(items);
  }

  protected abstract setItemsCallback(items: T[]);

  get selectedItemId(): any {
    return (<any>this.selectedItem)['id'];
  }

  public getSelectedItem(): any {
    return <any>this.selectedItem;
  }

  @Input()
  set selectedItemId(selectedItemId: any) {
    this._selectedItemId = selectedItemId;
    if (this._items) {
      this.selectById();
    }
  }

  @Input()
  set addButtonEventEmitter(e: Subject<boolean>) {
    this._addButtonEventEmitter = e;
    if (this.addButton === undefined) {
      this.addButton = true;
    }
  }

  public resetControls() {
    if (this.formModel && this.formModel.controls) {
      delete this.formModel.controls[this.groupName];
    }
  }

  public updateControls() {
    if (this.formModel && this.formModel.controls && this.formModel.controls[this.groupName]) {
      const rowControls = (<UntypedFormGroup>this.formModel.controls[this.groupName]).controls;
      Object.keys(rowControls).forEach((rowKey) => {
        const cellControls = (<UntypedFormGroup>rowControls[rowKey]).controls;
        Object.keys(cellControls).forEach((cellKey) => {
          cellControls[cellKey].updateValueAndValidity();
        });
      });
    }
  }

  get form(): UntypedFormGroup {
    if (!this.formModel) {
      return undefined;
    }
    if (!this.formModel.controls[this.groupName]) {
      // FIXME This does not work for #form="ngForm". Due to old angular version ???
      const validatorsList = [];
      const uniqValidator = TableValidators.uniqueColumns(this, this.uniqueColumns, this.formatService);
      if (uniqValidator) {
        validatorsList.push(uniqValidator);
      }
      if (this.minLength) {
        validatorsList.push(TableValidators.minLength(this.minLength));
      }
      if (this.formGroupValidator) {
        validatorsList.push(this.formGroupValidator);
      }
      const group = this.fb.group({}, {validator: validatorsList});
      this.formModel.addControl(this.groupName, group);
      // This works #form="ngForm" but parent form return true even there is error
      if (!this.formModel.controls[this.groupName]) {
        this.formModel.controls[this.groupName] = group;
      }
    }
    return <UntypedFormGroup>this.formModel.controls[this.groupName];
  }

  protected constructor(
    private fb: UntypedFormBuilder,
    private pasteFromExcelService: PasteFromExcelService<T>,
    protected formatService: FormatService,
    protected translateService: TranslateService
  ) {
    this.addButtonLabel = this.translateService.instant('common.add');
  }

  private initPasteFromExcelConfig() {
    if (this.pasteFromExcelButton) {
      this.pasteFromExcelService.columns = this.columns.toArray();
      if (this.pasteFromExcelConfigs) {
        this.pasteFromExcelService.configs = this.pasteFromExcelConfigs;
      }
    }
  }

  ngAfterContentInit() {
    this.initPasteFromExcelConfig();
    for (const column of this.columns.toArray()) {
      this.initColumn(column);
    }
    if (
      this.initialSortByDataProvider &&
      this.dataProvider instanceof SearchDataProvider &&
      this.dataProvider.searchCriteria &&
      this.dataProvider.searchCriteria.sortBy
    ) {
      for (const column of this.columns.toArray()) {
        if (column.sort === this.dataProvider.searchCriteria.sortBy.column) {
          this.sortColumn = column;
          this.sortBy = this.dataProvider.searchCriteria.sortBy;
          this.performSort(false);
          break;
        }
      }
    } else {
      for (const column of this.columns.toArray()) {
        if (column.initialSort) {
          this.sortColumn = column;
          this.sortBy.column = column.sort;
          this.sortBy.direction = <Direction>column.initialSort.toUpperCase();
          this.performSort(false);
          break;
        }
      }
    }
    this.updateHiddenDictItems();
    this.ngAfterContentInitCompleted = true;
  }

  protected initColumn(column) {
    if (column.isDictionary()) {
      this.initDictionary(column);
    }
  }

  protected abstract initDictionary(column);

  get count(): number {
    if (this._items) {
      return this._items.length;
    }
    return 0;
  }

  getRowControlGroup(item: T): UntypedFormGroup {
    if (!this.formModel) {
      return undefined;
    }

    const rowNo = '' + this.getItemIndex(item);
    let rowGroup = this.form.controls[rowNo];
    if (!rowGroup) {
      rowGroup = this.fb.group([]);
      this.form.addControl(rowNo, rowGroup);
    }
    return <UntypedFormGroup>rowGroup;
  }

  getControl(item: T, column: ColumnComponent<T>): AbstractControl {
    if (!this.formModel) {
      return undefined;
    }

    const rowGroup = this.getRowControlGroup(item);
    const cellId = '' + this.getColumnIndex(column);
    let control = rowGroup.controls[cellId];
    if (!control) {
      control = new UntypedFormControl(this.getProperty(item, column), {
        validators: column.getValidators(this, item),
        asyncValidators: column.getAsyncValidators(),
        updateOn: column.updateOn,
      });
      rowGroup.addControl(cellId, control);
    }
    return control;
  }

  getColumnIndex(column: ColumnComponent<T>): number {
    return this.columns.toArray().indexOf(column);
  }

  getItemIndex(item: T): number {
    return this._items.indexOf(item);
  }

  onRowClick(item: T, column: ColumnComponent<T>) {
    this.onSelectedRow(item, column);
  }

  onSelectedRow(item: T, column: ColumnComponent<T>) {
    if (this.columnMode) {
      this.onColumnClick(column);
      return;
    }
    if (this.canSelectRow(item)) {
      this.selectRow(item, column);
    }
  }

  protected selectRow(item: T, column: ColumnComponent<T>) {
    const selectionChanged = this.selectedItem !== item;
    // We perform selection
    if (this.selectedItem === item && !this.editable && this.canUnselect) {
      this.selectedItem = undefined;
      this.selectedItemId = undefined;
    } else {
      const id = <any>item['id'];
      if (id) {
        this.selectedItemId = id;
      } else {
        this.selectedItem = item;
      }
    }

    if (selectionChanged) {
      this.showErrors = false;
    }
    this.selectItem.emit(this.selectedItem);

    if (!this.isCellInEdition(column, item)) {
      column.cellClicked(item);
    }

    if (this.editable && (!this.rowEditableCallback || this.rowEditableCallback(item))) {
      this.startEdition(item);
    }
  }

  abstract canSelectRow(item: T): boolean;

  onLinkClicked(item: T, column: ColumnComponent<T>, event: Event) {
    column.linkClick.emit(item);
    event.stopPropagation();
  }

  /**
   * indicates if set selection color on row
   * @param item
   * @returns {boolean}
   */
  useSelectedClass(item: T): boolean {
    return this.selectedItem === item && !this.editable && this.selection;
  }

  isCellInEdition(column: ColumnComponent<T>, item: T): boolean {
    if ((column.property || column.template) && this.editable && column.isEditable(item)) {
      return this.isRowInEdition(item);
    } else {
      return false;
    }
  }

  hoverOverRow(item: T) {
    this.hoverItem = item;
  }

  isRowHover(item: T): boolean {
    return this.hoverItem === item;
  }

  isRowInEdition(item?: T): boolean {
    return this.permEditMode && this.editable && this.isRowEditable(item);
  }

  isRowEditable(item?: T): boolean {
    return !(item && this.rowEditable && !this.rowEditable(item));
  }

  areButtonsVisible(item?: T): boolean {
    let customButtonsVisible = false;
    if (this.customButtons) {
      this.customButtons.forEach((b) => {
        if (b.isAllwaysVisible) {
          customButtonsVisible = true;
        }
      });
    }
    return (
      !this.columnMode &&
      (this.isRowEditable(item) || customButtonsVisible) &&
      !!(
        this.editable ||
        this.moveTopButton === true ||
        this.deleteButton === true ||
        (this.customButtons && this.customButtons.length > 0)
      )
    );
  }

  anyButtonsVisible(): boolean {
    return this.areButtonsVisible() || this.isAddVisible() || this.isPasteFromExcelVisible() || this.checkAllVisible;
  }

  isDeleteButtonVisible(item: T): boolean {
    if (
      this.columnMode ||
      this.deleteButton === false ||
      (this.rowDeletableCallback && !this.rowDeletableCallback(item))
    ) {
      return false;
    } else {
      return (
        this.isRowEditable(item) &&
        ((!this.isRowInEdition(item) && this.editable) || (this.deleteButton === true && !this.editable)) &&
        (!this.rowEditableCallback || this.rowEditableCallback(item))
      );
    }
  }

  isDeleteButtonDisabled(item: T): boolean {
    return this.isRowInEdition(item) || this.isRowDeletionDisabled(item);
  }

  isRowDeletionDisabled(item: T): boolean {
    return this.rowDeletionDisabled && this.rowDeletionDisabled(item);
  }

  areConfirmButtonsVisible(item: T): boolean {
    return this.isRowEditable(item) && !this.permEditMode && this.isRowInEdition(item);
  }

  isMoveTopButtonVisible(item: T) {
    return (
      this.moveTopButton === true &&
      (!this.editable || !this.isRowInEdition(item)) &&
      (!this.rowEditableCallback || this.rowEditableCallback(item))
    );
  }

  isValidToButtonVisible(item: T) {
    return (
      this.isRowEditable(item) &&
      this.validToButton === true &&
      (!this.editable || !this.isRowInEdition(item)) &&
      (!this.rowEditableCallback || this.rowEditableCallback(item))
    );
  }

  getItemText(column: ColumnComponent<T>, item: T): string {
    if (column.property) {
      const value = this.getProperty(item, column, false);
      return this.formatText(column, value);
    } else {
      return '';
    }
  }

  getItemTooltip(column: ColumnComponent<T>, item: T): string {
    if (item && column.tooltip) {
      return StringUtils.getPropertyRaw(item, column.tooltip);
    }
    return this.getItemText(column, item);
  }

  forceChangePage(event: PageChangedEvent) {
    if (this.paginationComponent) {
      if (event) {
        this.paginationComponent.selectPage(event.page);
        this.paginationComponent.itemsPerPage = event.itemsPerPage;
      }
    }
  }

  paginationPageChanged(event: PageChangedEvent) {
    this.page = event.page;
    this.pageChange.emit(event);
    this.performSearch(true);
    if (this.selectedItem) {
      this.selectedItem = undefined;
      this.selectedItemId = undefined;
      this.selectItem.emit(this.selectedItem);
    }
  }

  getProperty(object: T, column: ColumnComponent<T>, normalized = true, sort = false): any {
    const property: string = (sort && column.sort) || column.property;
    if (object && property) {
      let v = StringUtils.getPropertyRaw(object, property);
      if (v === 0) {
        return 0;
      }
      if (!v) {
        if (column.isBoolean()) {
          v = false;
        } else if (column.isText()) {
          v = '';
        }
      }
      if (v && column.isType('dateYear')) {
        const year: string = v.getFullYear() + '';
        v = year;
      }
      if (v && column.isNumber() && normalized) {
        v = v / column.numberBaseMultiplier;
      }
      return v;
    } else {
      return '';
    }
  }

  setProperty(object: T, column: ColumnComponent<T>, event: Event) {
    const property: string = column.property;
    let value = (<any>event.target).value;
    if (column.isBoolean()) {
      value = (<any>event.target).checked;

      if (column.isType('radio') && value) {
        this.uncheckOtherRadios(object, property);
      }
    }

    if (column.isNumber()) {
      value = parseFloat(value) * column.numberBaseMultiplier;
      value = Number(value.toFixed(column.numberPrecision));
      if (this.columns.filter((col) => property === col.min || property === col.max).reduce((col) => true, false)) {
        this.removeRowControlGroup(object);
      }
    }

    if (column.isType('dateYear')) {
      value = this.dateYear(value);
    }

    this.setPropertySimple(object, column, value);
  }

  private setPropertySimple(object: T, column: ColumnComponent<T>, value: any) {
    StringUtils.setPropertyRaw(object, column.property, value);
    column.cellChanged(object, value);
    if (this.autoUpdateValidators) {
      if (this.autoUpdateValidators === 'reset') {
        this.resetControls();
      } else {
        this.updateControls();
      }
    }
    if (column.groupRadios) {
      const radio = this.columns.find((col) => col.isType('radio'));
      StringUtils.setPropertyRaw(object, radio.property, false);
    }
  }

  private uncheckOtherRadios(object: T, property: string) {
    const groupingColumn = this.columns.find((col) => col.groupRadios);

    this._items
      .filter((t) => t !== object)
      .filter((t) => !groupingColumn || _.isEqual(t[groupingColumn.property], object[groupingColumn.property]))
      .forEach((t) => StringUtils.setPropertyRaw(t, property, false));
  }

  dateYear(yearStr: string) {
    const date = new Date();
    const year = parseInt(yearStr, 10);
    if (year > 0 && year < 10000) {
      date.setFullYear(year, 0, 1);
    }
    return date;
  }

  onDelete(item: T) {
    this.removeRowControlGroup(item);
    if (this.emitDeleteButton) {
      this.deleteButtonClick.emit(item);
    } else {
      this.delete(item);
    }
    this.addUpdateDelete.emit(item);
    this.itemsCountChange.emit(this._items.length);
  }

  abstract delete(item: T);

  deleteAllClicked() {
    this.items.forEach((i) => this.removeRowControlGroup(i));
    this.items.length = 0;
  }

  addItemClicked() {
    if (this._addButtonEventEmitter) {
      this._addButtonEventEmitter.next(true);
    } else {
      if (this.addButtonEventEmitterActive === true) {
        this.addButtonClick.emit();
      } else {
        const item: T = <any>{};
        this.onAddItem(item);
      }
    }
  }

  onAddItem(item: T) {
    if (!this._items) {
      console.log('Items is null !');
    } else if (!this.isRowInEdition()) {
      this.add(item);
      this._items.push(item);
      this.selectedItem = item;
      this.addItem.emit(item);
      this.startEdition(item);
      this.itemsCountChange.emit(this._items.length);
    }
  }

  public insertItem(item: T, index: number) {
    if (!this._items) {
      throw new Error('Items are null!');
    } else if (index >= this._items.length) {
      throw new Error(
        'table.component::inserItem: Index ' + index + ' out of bounds; items length: ' + this._items.length
      );
    } else {
      this._items.splice(index, 0, item);
      this.resetControls();
      this.addItem.emit(item);
      this.itemsCountChange.emit(this._items.length);
    }
  }

  abstract add(item: T);

  isAddDisabled(): boolean {
    return this.addDisabled || this.isRowInEdition();
  }

  isAddVisible() {
    if (this.addButton === false) {
      return false;
    }
    return !this.columnMode && (this.editable || this.addButton === true);
  }

  isDeleteAllVisible() {
    return this.deleteAllButton;
  }

  isPasteFromExcelDisabled() {
    return this.pasteFromExcelButtonDisabled || this.isRowInEdition();
  }

  isPasteFromExcelVisible() {
    if (this.pasteFromExcelButton === false) {
      return false;
    }
    return !this.columnMode && (this.editable || this.pasteFromExcelButton === true);
  }

  onPasteFromExcelClick() {
    this.pasteFromExcelWindowVisible = !this.pasteFromExcelWindowVisible;
  }

  onPasteFromExcel(text: string) {
    this.pasteFromExcelService.columns = this.columns.toArray();
    this._items.length = 0;
    this.pasteFromExcelResult = this.pasteFromExcelService.parseData(text);
    if (this.pasteFromExcelResult.hasErrors()) {
      this.openPasteFromExcelConfirmDialog(this.pasteFromExcelResult);
    } else {
      this.pushItemsFromExcel(this.pasteFromExcelResult.items);
    }
    this.pasteFromExcelWindowVisible = false;
  }

  protected pushItemsFromExcel(items: T[]) {
    this._items.push(...items);
    this.itemsCountChange.emit(this._items.length);
  }

  openPasteFromExcelConfirmDialog(barrelRollResult) {
    const titleKey = barrelRollResult.itemsCount > 0 ? 'table.partialParse' : 'table.nothingParsed';
    const msg =
      barrelRollResult.itemsCount > 0
        ? this.translateService.instant('table.parsePartially', {
            left: barrelRollResult.itemsCount,
            total: barrelRollResult.totalCount,
          })
        : this.translateService.instant('table.pasteCorrectData');
    const confirmationPromise: Promise<boolean> = this.pasteFromExcelConfirmDialog.open(
      this.translateService.instant(titleKey),
      msg
    );
    confirmationPromise.then((result) => {
      if (result) {
        this._items.push(...barrelRollResult.items);
      }
    });
  }

  startEdition(item: T) {
    this.showErrors = false;
    for (const column of this.columns.toArray()) {
      let keyName = column.property;
      if (!keyName) {
        keyName = column.title;
      }
      if (column.isType('radio')) {
        this.getRowData(item)[keyName] = this.findCheckedItem(column);
      } else {
        this.getRowData(item)[keyName] = this.getProperty(this.selectedItem, column, false);
      }
    }
  }

  abstract getRowData(item: T): any;

  abstract onDone(item: T);

  /*
   * Used to populate [checked] in model
   */
  updateModelFromTemplate() {
    for (const col of this.columns.toArray()) {
      if (col.type === 'radio' && this.items && this.items.length === 1) {
        StringUtils.setPropertyRaw(this.selectedItem, col.property, true);
      }
    }
  }

  abstract onCancel(item: T);

  setValidTo(item: T) {
    if ((<any>item).id) {
      StringUtils.setPropertyRaw(item, 'validTo', new Date());
    } else {
      this.onDelete(item);
    }
  }

  onMoveTop(item: T) {
    const i = this._items.indexOf(item);
    if (i > 0) {
      this._items.splice(i, 1);
      this._items.splice(0, 0, item);
      this.moveTop.emit(item);
    }
  }

  isColumnSortable(column: ColumnComponent<T>): boolean {
    if (!this.sortable) {
      return false;
    }
    if (column.sort) {
      return true;
    }
    return !this.pagination;
  }

  sortClass(column: ColumnComponent<T>) {
    if (this.isColumnSortable(column) && this.isSortedBy(column)) {
      if (String(this.sortBy.direction) === String('UP')) {
        return 'fa fa-sort-up';
      } else {
        return 'fa fa-sort-down';
      }
    }
    return '';
  }

  doSortBy(column: ColumnComponent<T>) {
    if (!this.sortable) {
      return;
    }

    if (this.isColumnSortable(column)) {
      if (this.isSortedBy(column)) {
        if (String(this.sortBy.direction) === String('UP')) {
          this.sortBy.direction = 'DOWN';
        } else {
          this.sortBy.direction = 'UP';
        }
      } else {
        this.sortBy.column = column.sort;
        this.sortBy.direction = 'UP';
      }
    }
    this.sortColumn = column;
    this.performSort(true);
  }

  onColumnHeaderClick(column: ColumnComponent<T>) {
    if (!this.columnMode) {
      this.doSortBy(column);
    }
    this.onColumnClick(column);
  }

  onCustomHeaderClick(column: ColumnComponent<T>) {
    if (this.headerClickCallback) {
      console.log('headerClickCallback = ' + this.headerClickCallback);
      this.headerClickCallback(this.indexOf(column));
    }
  }

  indexOf(column: ColumnComponent<T>): number {
    return this.columns.toArray().indexOf(column);
  }

  getFooterValue(column: ColumnComponent<T>): string {
    if (column.footerValue) {
      return column.footerValue;
    }
    const sumInFooter = column.sumPropertyInFooter || column.sumInFooter;
    const propertyName = column.sumPropertyInFooter ? column.sumPropertyInFooter : column.property;
    if (this._items && (column.isType('number') || column.isType('stringCombo')) && sumInFooter) {
      if (!this.rowInEdition) {
        if (this.pagination) {
          if (this.sums) {
            const sum = this.sums[propertyName];
            return StringUtils.formatNumber(sum);
          }
        } else {
          let sum = 0;
          for (const item of this._items) {
            let value;
            if (column.template) {
              const templateContent = this.getColumnContent(column, item);
              value = NumberUtils.parseFloat(templateContent);
            } else {
              value = <number>this.getProperty(item, column);
            }
            if (value && !Number.isNaN(value)) {
              if (column.sumFloat) {
                sum += parseFloat(value);
              } else {
                sum += value;
              }
            }
          }
          return StringUtils.formatNumber(sum);
        }
      }
    }
    return '';
  }

  search(pageSize?: number) {
    if (pageSize) {
      this.pageSize = pageSize;
    }
    this.page = 1;
    this.updateInitialDataProvider();
    this.performSearch();
  }

  getHiddenIds(item: T, column: ColumnComponent<T>) {
    const hiddenIds = new Set<number>(column.hiddenIds);
    if (column.isUnique()) {
      const thisValue = this.getProperty(item, column);
      if (thisValue && thisValue.id) {
        hiddenIds.delete(thisValue.id);
      }
    }
    return hiddenIds;
  }

  getPage(): Page {
    if (this.pagination) {
      const page = <Page>{};
      page.count = this.pageSize;
      page.start = (this.page - 1) * this.pageSize;
      return page;
    }
    return undefined;
  }

  onCustomButtonClick(button: CustomButton, item: T, dropdownItem?: any) {
    const event = new CustomButtonEvent<T>();
    event.button = button;
    event.item = item;
    if (dropdownItem) {
      event.dropdownItem = dropdownItem;
    }
    this.customButtonClick.emit(event);
  }

  autoCompleteChange(object: T, column: ColumnComponent<T>, value: any) {
    this.setPropertySimple(object, column, value);
    this.removeRowControlGroup(this.selectedItem);
    this.getRowControlGroup(this.selectedItem);
    this.form.updateValueAndValidity();
  }

  /**
   ***************** Column mode *******************
   */
  onColumnClick(column: ColumnComponent<T>) {
    if (!this.columnMode) {
      return;
    }
    const cIdx = this.columns.toArray().indexOf(column);
    if (column.selectable) {
      if (column.selected) {
        if (this.canUnselect) {
          column.selected = false;
          this.columnSelect.emit({index: cIdx, selected: false});
        }
        return;
      }
      this.columns.forEach((c) => (c.selected = false));
      column.selected = true;
      this.columnSelect.emit({index: cIdx, selected: true});
      return;
    }
  }

  useSelectedClassForCell(column: ColumnComponent<T>): boolean {
    return column.selected;
  }

  get headerRowIndexes(): number[] {
    return Array.from(Array(this.headerRowCount).keys());
  }

  /*
   ***************  End column mode **************
   */

  protected abstract removeRowControlGroup(item: T);

  updateHiddenDictItems() {
    if (this.columns && this._items) {
      if (this.editable) {
        this.addDisabled = false;
      }
      for (const col of this.columns.toArray()) {
        col.hiddenIds = col.dictHiddenIds ? new Set<number>(col.dictHiddenIds) : new Set<number>();
        if (this.canAddToHidden(col)) {
          for (const item of this._items) {
            const value = this.getProperty(item, col);
            if (value && value.id) {
              col.hiddenIds.add(value.id);
            }
          }
        }
        if (col.isDictionary() && col.isUnique()) {
          this.addDisabled = col.hiddenIds && col.hiddenIds.size >= col.dictionaryCount;
        }
      }
    }
  }

  protected canAddToHidden(col: ColumnComponent<T>): boolean {
    return col.isUnique() && col.isRequired();
  }

  private performSearch(initialDataProvider = false) {
    if (initialDataProvider) {
      this.actualPerformSearch(this.initialDataProvider);
    } else {
      this.actualPerformSearch(this.dataProvider);
    }
  }

  // protected actualPerformSearch(dataProviderParam: DataProvider<T>) {
  private actualPerformSearch(dataProviderParam: DataProvider<T>) {
    if (dataProviderParam) {
      this.searchButtonClick.emit('search start');
      this.inProgress = true;
      dataProviderParam.inProgress = true;
      dataProviderParam.search(this.getPage(), this.sortBy).subscribe(
        (searchResult) => {
          this.handleSearchResult(searchResult);
          console.log('search result', searchResult);
          this.totalCount = searchResult.size;
          this.sums = searchResult.sums;
          if (searchResult.warnings && searchResult.warnings.length > 0) {
            this.errorMessage = searchResult.warnings;
          } else {
            this.errorMessage = undefined;
          }
          if (dataProviderParam) {
            dataProviderParam.inProgress = false;
          }
          this.inProgress = false;
          this.dataProviderSearchFinished.emit(searchResult);
        },
        (error) => {
          this.errorMessage = error;
          console.log('Table::performSearch error ', this.errorMessage);
          this.dataProviderSearchError.emit(error);
          if (dataProviderParam) {
            dataProviderParam.inProgress = false;
          }
          this.inProgress = false;
        }
      );
    }
  }

  handleSearchResult(searchResult: SearchResult<T>) {}

  private formatText(column: ColumnComponent<T>, value: any): string {
    if (!value && value !== 0) {
      if (column.isType('number') && column.infinity) {
        return this.translateService.instant('common.infinity');
      }
      return column.isDictionary() && column.nullLabel ? column.nullLabel : '';
    }
    if (column.isDictionary() || column.isAutoComplete()) {
      const dict = <DictionaryBaseDto>value;
      return (<any>dict)[column.dictLabel];
    } else if (column.isDate()) {
      const date: Date = <Date>value;
      if (column.isType('datetime')) {
        return this.formatService.formatDateTime(date);
      } else {
        return this.formatService.formatDate(date);
      }
    } else if (column.isType('number')) {
      if (!column.numberFormat && column.numberType === 'integer') {
        return StringUtils.formatNumber(value, StringUtils.NUMBER_INT_FORMAT);
      }
      const numberFormat = column.numberOfDecimalPlaces
        ? `1.${column.numberOfDecimalPlaces}-${column.numberOfDecimalPlaces}`
        : column.numberFormat;
      return StringUtils.formatNumber(value, numberFormat);
    } else if (column.type === 'combo') {
      const valueFromComboItems = column.comboItems && column.comboItems.find((item) => item.id === value.id);
      if (valueFromComboItems) {
        value = valueFromComboItems;
      }
      return (column.toStringFunc && column.toStringFunc(value)) || (column.comboLabel && value[column.comboLabel]);
    } else if (column.type === 'archetype') {
      return value[column.archetypeLabel];
    }
    return column.replaceUnderscoresWithSpaces ? column.doReplaceUnderscoresWithSpaces(value) : value;
  }

  private selectById() {
    this.selectedItem = undefined;
    if (this._items && this._selectedItemId) {
      const item = this.findItemById(this._selectedItemId);
      if (item) {
        this.selectedItem = item;
      }
    }
  }

  private performSort(performSearch: boolean) {
    if (this.pagination) {
      // Server side sort
      if (this.sortColumn && this.sortColumn.sort) {
        if (performSearch) {
          this.performSearch(true);
        }
        if (this.selectedItem) {
          this.selectedItem = undefined;
          this.selectItem.emit(this.selectedItem);
        }
      }
    } else {
      // local sort
      const sortOrder = String(this.sortBy.direction) === String('UP') ? 1 : -1;
      if (this._items && this._items.length > 1) {
        let first: T;
        if (this.moveTopButton) {
          first = this._items[0];
          this._items.splice(0, 1);
        }

        this._items.sort((i1, i2) => {
          const r1 = this.getSortValue(this.sortColumn, i1);
          const r2 = this.getSortValue(this.sortColumn, i2);

          const result = r1 < r2 ? -1 : r1 > r2 ? 1 : i1['id'] < i2['id'] ? -1 : i1['id'] > i2['id'] ? 1 : 0;
          return result * sortOrder;
        });

        if (this.moveTopButton) {
          this._items.splice(0, 0, first);
        }
      }
      this.resetControls();
    }
  }

  private findItemById(id: any) {
    for (const item of this._items) {
      if (String((<any>item)['id']) === String(id)) {
        return item;
      }
    }
    return undefined;
  }

  private getSortValue(column: ColumnComponent<T>, item: T) {
    if (column.template && !column.sort) {
      return !!column.customSortValueFunc
        ? column.customSortValueFunc(item) || null
        : this.getColumnContent(column, item).toLocaleUpperCase();
    } else {
      const value = this.getProperty(item, column, true, true);
      if (column.isNumber() || typeof value === 'number') {
        return value || 0;
      } else if (column.isBoolean()) {
        return value || false;
      } else if (column.isDate()) {
        return value || null;
      } else {
        return this.formatText(column, value).toUpperCase();
      }
    }
  }

  private isSortedBy(column: ColumnComponent<T>) {
    if (this.pagination) {
      return String(this.sortBy.column) === String(column.sort);
    } else {
      return this.sortColumn === column;
    }
  }

  private findCheckedItem(column: ColumnComponent<T>): T {
    for (const item of this._items) {
      const value = this.getProperty(item, column);
      if (value) {
        return item;
      }
    }
    return undefined;
  }

  protected restoreData(item: T, deletion: boolean) {
    if (this.getRowData(item)[this.restoreDataId]) {
      StringUtils.setPropertyRaw(item, this.restoreDataId, this.getRowData(item)[this.restoreDataId]);
    }

    for (const column of this.columns.toArray()) {
      if (column.property) {
        let keyName = column.property;
        if (!keyName) {
          keyName = column.title;
        }
        let value = this.getRowData(item)[keyName];
        if (!value) {
          value = StringUtils.getPropertyRaw(this.getRowData(item), keyName);
        }
        if (column.isType('radio')) {
          if (!deletion) {
            StringUtils.setPropertyRaw(item, column.property, false);
          }
          if (value) {
            // In case of radio in rowData we have previously checked item
            StringUtils.setPropertyRaw(value, column.property, true);
          }
        } else {
          if (!deletion) {
            if (column.isType('dateYear')) {
              value = this.dateYear(value);
            }
            StringUtils.setPropertyRaw(item, column.property, value);
          }
        }
      }
    }
    if (deletion) {
      this.removeRowControlGroup(item);
    } else {
      this.resetControls();
    }
  }

  private getCssStyles(control: AbstractControl, cssClass: string) {
    const cssClasses: any = {};
    cssClasses[cssClass] = true;
    if (control && control.invalid && (this.showErrors || this.showAllErrors)) {
      cssClasses[this.formElementErrorClass] = true;
    }
    return cssClasses;
  }

  private getInputCssStyle(control: AbstractControl) {
    return this.getCssStyles(control, 'bon-table-input');
  }

  protected getDictComboCssStyle(control: AbstractControl) {
    return this.getCssStyles(control, 'bon-table-select');
  }

  private getColumnContent(column: ColumnComponent<T>, item: T): string {
    if (!this.columnTemplates) {
      return '';
    }
    for (const t of this.columnTemplates.toArray()) {
      if (t.column === column && t.item === item) {
        return t.content;
      }
    }
    return '';
  }

  showColumnTitle(column: ColumnComponent<T>): boolean {
    return true;
  }

  isChecked(item: T): boolean {
    throw new Error('Method is not implemented for this class');
  }

  onArchetypeCheckboxClicked(item: T, column: ColumnComponent<T>, $event) {
    throw new Error('Method is not implemented for this class');
  }

  showRow(item: T): boolean {
    return !(this.rowHidden && this.rowHidden(item));
  }

  // https://jira.accuratus-software.pl/browse/BON-4231
  // used to avoid researching when changing page or sorting results
  public updateInitialDataProvider() {
    if (this.dataProvider) {
      this.initialDataProvider = this.dataProvider.clone();
    }
  }
}
