import {Component, Input} from '@angular/core';
import {PolicyContractVersionDto, PolicyInquiryVersionDto} from '../../../../../bonding_shared/model';
import {InquiryCountries} from '../../../../inquiry/domain/inquiry-countries';
import {DictionaryService, InquiryService} from '../../../../../bonding_shared/services';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';

@Component({
  selector: 'kuke-policy-contract-info',
  templateUrl: './kuke-policy-contract-info.component.pug',
})
export class KukePolicyContractInfoComponent {
  _data: PolicyContractVersionDto;

  @Input()
  longLabel: boolean;
  @Input()
  showDateFromTo = false;

  nonStandardCountries: boolean;

  constructor(
    private dictionaryService: DictionaryService,
    private inquiryService: InquiryService,
    private policyContractViewService: PolicyContractViewService
  ) {}

  @Input()
  set data(pcv: PolicyContractVersionDto) {
    this._data = pcv;
    if (pcv && pcv.inquiry && pcv.inquiry.id) {
      this.inquiryService.getById<PolicyInquiryVersionDto>(this.data.inquiry.id).subscribe((inquiry) => {
        InquiryCountries.getProductsCountries(this.dictionaryService, inquiry).subscribe((productCountries) => {
          this.nonStandardCountries =
            inquiry.countries.filter((c) => !productCountries.find((p) => p.id === c.country.id)).length > 0;
        });
      });
    }
  }

  get data(): PolicyContractVersionDto {
    return this._data;
  }

  get readonly() {
    return this.policyContractViewService.readonly;
  }

  get readonlyOrNotFirstVersion() {
    return this.readonly || this.data.versionNumber !== 1;
  }
}
