import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TaxIfc, TaxRateDto} from '../model';
import {StringUtils} from '../utils';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class InvoiceCoreService extends AbstractService {
  protected url = this.urlPrefix + 'invoiceCore';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private translateService: TranslateService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  /**
   * map[taxTypeId][taxCategoryId][countryId] -> Vat rate value
   */
  getAllTaxRates(): Observable<{[index: string]: {[index: string]: {[index: string]: TaxRateDto[]}}}> {
    return this.get<{[index: string]: {[index: string]: {[index: string]: TaxRateDto[]}}}>(this.url + '/allTaxRates');
  }

  formatTax(dto: TaxIfc): string {
    if (!dto) {
      return '';
    }
    if (dto.taxNotApplicable) {
      return this.translateService.instant('invoice.core.service.taxNotApplicable');
    }

    return StringUtils.formatNumber(dto.taxRateValue) + '%';
  }

  formatTaxes(taxes: {[index: string]: TaxIfc}): string {
    let txt = '';
    for (const tax of Object.values(taxes)) {
      if (txt !== '') {
        txt += ', ';
      }
      txt += tax.taxCategory.name + ': ' + this.formatTax(tax);
    }
    return txt;
  }
}
