import {ErrorReason, InternalServerErrorDto} from './dtos';

export type BackendError = ErrorReason[] | InternalServerErrorDto | string;

export function isInternalError(e: BackendError): e is InternalServerErrorDto {
  return (<InternalServerErrorDto>e).uuid !== undefined;
}

export function isErrorReasons(e: BackendError): e is ErrorReason[] {
  return Array.isArray(e);
}

export function isString(e: BackendError): e is string {
  return typeof e === 'string';
}

export interface HttpErrorLog {
  correlationId?: string;
  httpStatus: number;
  url: string;
  details: string;
}
