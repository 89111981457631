import {StateTransitionDto, StateTransitionOrGroupDto} from '../../model';
import {Action} from '../../model/dictionary-ids';
import {Directive, forwardRef, Inject, Input, ViewChild} from '@angular/core';
import {ConfirmDialogComponent} from '../confirm-dialog';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {isPromise} from '../../utils/object-utils';
import {AbstractService} from '../../services';

@Directive()
export abstract class LifecycleDropdownAbstractComponent {
  /** function which saves the object after status change, must be passed with .bind(self) where self is parent component
   * Return promise if saveFunc is asynchronous
   * */
  @Input() saveFunc: Function;

  @Input() makeExclusiveTransitionFunc: Function;

  @Input() beforeTransitionFunc: Function;

  @Input() inProgress = false;

  @Input() disabled = false;

  @Input() validInput: (transition: StateTransitionDto) => () => boolean;

  @Input() showConfirmation: (transition: StateTransitionDto) => boolean = null;

  @Input() customConfirmationTextKey: (transition: StateTransitionDto) => string = null;

  @Input() customTitleConfirmationTextKey: (transition: StateTransitionDto) => string = null;

  @Input() provideActionForSaveFunc = false;

  @Input() labelKey: string;

  @ViewChild('lifecycleActionConfirm', {static: true}) confirmDialog: ConfirmDialogComponent;

  customConfirmationForm = false;

  transitions: StateTransitionOrGroupDto[] = [];
  _selectedObject: any;

  statusesReloading = false;
  mainTransition: StateTransitionDto;
  firstTransition: StateTransitionDto;
  transitionToConfirm: StateTransitionDto = null;
  _service: AbstractService;

  constructor(@Inject(forwardRef(() => TranslateService)) protected translateService: TranslateService) {}

  changeStatus(transition: StateTransitionDto) {
    if (!transition?.userHasRight) {
      return;
    }
    if (this.beforeTransitionFunc) {
      const result = this.beforeTransitionFunc();
      if (isPromise(result)) {
        result.then(
          (answer) => {
            if (answer) {
              this.doChangeStatus(transition);
            }
          },
          (error) => console.log(error)
        );
      }
    } else {
      this.doChangeStatus(transition);
    }
  }

  doChangeStatus(transition: StateTransitionDto) {
    console.log('confirmDialog: ', this.confirmDialog);
    this.transitionToConfirm = null;

    this.customConfirmationForm = this.showConfirmation && this.showConfirmation(transition);

    if (this.customConfirmationForm || transition.dangerous) {
      this.transitionToConfirm = transition;
      const confirmationPromise: Promise<boolean> = this.confirmDialog.open(
        this.titleText(transition),
        this.confirmationText(transition)
      );
      confirmationPromise.then((result) => {
        if (result === true) {
          // when pressed Yes
          this.doTransition(transition);
        } else if (result === false) {
          // when pressed No
        } else {
          // When closing the modal without no or yes
        }
      });
    } else {
      this.doTransition(transition);
    }
  }

  selectMainTransition(withRight = true): StateTransitionDto {
    return !this.labelKey && this.transitions?.length > 0
      ? _.chain(this.transitions)
          .flatMap((t) => t.children || [t])
          .filter((t) => (withRight ? t.userHasRight : true))
          .first()
          .value()
      : undefined;
  }

  doTransition(transition: StateTransitionDto) {
    if (transition.exclusive) {
      if (!this.makeExclusiveTransitionFunc) {
        console.error('makeExclusiveTransitionFunc is not provided !!!');
      }
      this.makeExclusiveTransitionFunc(transition, this._selectedObject, this._service);
      return;
    }
    const newStatus = transition.newStatus;
    const oldStatus = this._selectedObject.status;
    this._selectedObject.status = newStatus;
    const result = this.provideActionForSaveFunc ? this.saveFunc(transition.action, oldStatus) : this.saveFunc();
    if (isPromise(result)) {
      // change to old status only when save promise rejected
      result.then(null, () => {
        this._selectedObject.status = oldStatus;
      });
    } else {
      this._selectedObject.status = oldStatus;
    }
  }

  private confirmationText(transition: StateTransitionDto): string {
    if (this.customConfirmationTextKey && this.customConfirmationTextKey(transition)) {
      return this.translateService.instant(this.customConfirmationTextKey(transition));
    } else {
      let confirmationText: string;
      if (transition.action.id === Action.CLOSE_QUOTATION) {
        confirmationText = this.translateService.instant('quotation.Are you sure you want to');
      } else {
        confirmationText = this.translateService.instant('confirmationDialog.query');
      }
      return `${confirmationText} ${transition.action.name.toLowerCase()}?`;
    }
  }

  private titleText(transition: StateTransitionDto): string {
    if (this.customTitleConfirmationTextKey && this.customTitleConfirmationTextKey(transition)) {
      return this.translateService.instant(this.customTitleConfirmationTextKey(transition));
    } else {
      return 'Action confirmation';
    }
  }
}
