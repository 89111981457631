import {DictionaryBaseDto} from './dtos';

export class Country {
  static readonly BE: number = 3000021;
  static readonly PL: number = 3000180;
  static readonly GB: number = 3000214;
  static readonly DE: number = 3000058;
  static readonly IT: number = 3000111;

  static readonly MILAN: number = 111004016;
  static readonly KOLN: number = 58000936;
  static readonly BRUSSELS: number = 21000001;

  public static isGermany(dict: DictionaryBaseDto) {
    return dict && dict.id === Country.DE;
  }

  public static isItaly(dict: DictionaryBaseDto) {
    return dict && dict.id === Country.IT;
  }

  public static isBelgium(dict: DictionaryBaseDto) {
    return dict && dict.id === Country.BE;
  }
}
