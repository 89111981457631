import {Component, Input} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {
  AppConfigService,
  DictionaryService,
  InquiryService,
  RouterService,
} from '../../../../../bonding_shared/services';
import {
  PolicyContractVersionDto,
  PolicyInquiryBaseDto,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionSimpleDto,
  SearchCriteria,
} from '../../../../../bonding_shared/model';
import {PolicyElementaryRight} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'kuke-policy-inquiries-section',
  templateUrl: './kuke-policy-inquiries-section.component.pug',
})
export class KukePolicyInquiriesSectionComponent extends Section {
  readonly PolicyElementaryRight = PolicyElementaryRight;

  public renewalInquiries: PolicyInquiryVersionSimpleDto[];
  public annexInquiries: PolicyInquiryVersionSimpleDto[];

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected inquiryService: InquiryService,
    protected router: RouterService,
    protected appService: AppConfigService,
    protected dictionaryService: DictionaryService
  ) {
    super(policyContractViewService, appService);
  }

  @Input()
  set policy(pol: PolicyContractVersionDto) {
    const criteria = <SearchCriteria<PolicyInquiryVersionCriteriaDto>>{};
    criteria.criteria = <PolicyInquiryVersionCriteriaDto>{};
    criteria.criteria.policyInquiry = <PolicyInquiryBaseDto>{};
    criteria.criteria.policyInquiry.policyContract = pol.policyContract;
    this.inquiryService
      .searchByCriteria<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>(criteria)
      .subscribe((inqs) => {
        this.renewalInquiries = inqs.result.filter((i) => i.policyInquiry.inquiryType === 'RENEWAL_INQUIRY');
        this.annexInquiries = inqs.result.filter((i) => i.policyInquiry.inquiryType === 'POLICY_ANNEX');
      });
  }

  goToAnnexInquiry(annexId: number) {
    this.router.toPolicyAnnexInquiryDetails(annexId);
  }

  goToRenewalInquiry(inquiryId: number) {
    this.router.toPolicyRenewalInquiryDetails(inquiryId);
  }
}
