import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {CompanySharedModule} from '../../bonding/company/components/shared/company-shared.module';
import {CustomFormComponent} from './custom-form.component';
import {HtmlEditorModule} from '../../bonding_shared/components/html-editor/html-editor.module';
import {CustomFormSimpleComponent} from './custom-form-simple.component';
import {FieldNamePipe} from './field-name-pipe';
import {AdDirective} from './ad.directive';
import {CredendoLolCustomFieldComponent} from './credendo-lol-custom-field.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    CompanySharedModule,
    HtmlEditorModule,
  ],
  declarations: [
    CustomFormComponent,
    CustomFormSimpleComponent,
    FieldNamePipe,
    AdDirective,
    CredendoLolCustomFieldComponent,
  ],
  exports: [CustomFormComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomFormModule {}
