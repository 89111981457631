import {Component, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../bonding_shared/validators/custom.validators';
import {FormDialogComponent} from '../../bonding_shared/components/form-dialog/form-dialog.component';
import {FormDialogParent} from '../../bonding_shared/components/form-dialog/FormDialogParent';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {
  ChangePasswordDto,
  ExtranetAdminNotificationType,
  ExtranetClientAgreementType,
} from '../../bonding_shared/model/dtos';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {AbstractLoginService} from '../../login/login.service';
import {AppConfigService, UserService} from '../../bonding_shared/services';
import {PortalUserService} from '../services/portal-user.service';

@Component({
  selector: 'portal-user-agreements',
  templateUrl: 'portal-user-agreements.component.pug',
})
export class PortalUserAgreementsComponent extends FormDialogParent {
  @ViewChild(FormDialogComponent) aggrementsDialog: FormDialogComponent;

  constructor(
    private userService: PortalUserService,
    private loggedUserService: LoggedUserService,
    private growlService: GrowlService,
    public appService: AppConfigService
  ) {
    super();
    this.form = new UntypedFormGroup({
      MARKETING_SEND: new UntypedFormControl(''),
      MARKETING_PROCESS: new UntypedFormControl(''),
      MARKETING_USE: new UntypedFormControl(''),
    });
  }

  openAgreementsDialog() {
    this.userService.getAgreements(this.loggedUserService.getLoggedUserData().login).subscribe((agr) => {
      this.form.get('MARKETING_SEND').setValue(agr.indexOf('MARKETING_SEND') >= 0);
      this.form.get('MARKETING_PROCESS').setValue(agr.indexOf('MARKETING_PROCESS') >= 0);
      this.form.get('MARKETING_USE').setValue(agr.indexOf('MARKETING_USE') >= 0);
      this.aggrementsDialog.open('topnav.agreements').then((result) => {
        this.showErrors = false;
        this.form.reset();
        this.aggrementsDialog.hide();
      });
    });
  }

  getAgreement(name: ExtranetClientAgreementType, agreements: ExtranetClientAgreementType[]) {
    if (this.form.get(name).value) {
      agreements.push(name);
    }
  }

  save() {
    const agreements: ExtranetClientAgreementType[] = new Array();
    this.getAgreement('MARKETING_SEND', agreements);
    this.getAgreement('MARKETING_USE', agreements);
    this.getAgreement('MARKETING_PROCESS', agreements);
    this.userService.saveAgreements(this.loggedUserService.getLoggedUserData().login, agreements).subscribe((ret) => {
      this.growlService.notice('topnav.agreementsSaved');
      this.aggrementsDialog.dialogConfirmation();
    });
  }
}
