import {Observable} from 'rxjs';
import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BondingContractInquiryBaseDto, BondingContractInquiryVersionDto, StateTransitionDto} from '../model';

@Injectable()
export class BondingContractInquiryService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'bondingContractInquiryVersion';
  }

  getParentByVersionId(id: number): Observable<BondingContractInquiryBaseDto> {
    console.log('BondingContractInquiryService getParentByVersionId id = ' + id);
    return this.get<BondingContractInquiryBaseDto>(this.url + '/parentByVersion/' + id);
  }

  setManager(id: number, userId: number): Observable<BondingContractInquiryBaseDto> {
    console.log('BondingContractInquiryService setManager id = ' + id);
    return this.get<BondingContractInquiryBaseDto>(this.url + '/setManager/' + id + '/' + userId);
  }

  initialVersion(companyId: number): Observable<BondingContractInquiryVersionDto> {
    const params = UrlParams.new().addIf(!!companyId, 'clientId', () => companyId);
    return this.get<BondingContractInquiryVersionDto>(this.buildUrl(this.url + '/initialVersion', null, params));
  }

  initialVersionRenewal(contractVersionId: number): Observable<BondingContractInquiryVersionDto> {
    const params = UrlParams.new().addIf(!!contractVersionId, 'contractVersionId', () => contractVersionId);
    return this.get<BondingContractInquiryVersionDto>(this.buildUrl(this.url + '/initialVersionRenewal', null, params));
  }

  getTransitions(bv: BondingContractInquiryVersionDto): Observable<Array<StateTransitionDto>> {
    if (bv && bv.status) {
      return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + bv.status.id);
    }
    return Observable.create([]);
  }

  renewalInquiryVersion(versionId: number): Observable<BondingContractInquiryVersionDto> {
    const params = UrlParams.new().add('originVersionId', versionId);
    return this.get<BondingContractInquiryVersionDto>(this.buildUrl(this.url + '/renewalInquiryVersion', null, params));
  }

  getLastVersionByParentId(parentId: number): Observable<BondingContractInquiryVersionDto> {
    return this.get<BondingContractInquiryVersionDto>(this.url + '/lastVersion/' + parentId);
  }
}
