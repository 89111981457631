import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BusinessUnitService} from '../../../bonding_shared/services/business-unit.service';
import {BusinessUnitDto, UserDto} from '../../model/dtos';
import {LoggedUserService} from '../../services/logged-user.service';

const BUSINESS_UNIT_SELECTOR_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BusinessUnitSelectorComponent),
  multi: true,
};

@Component({
  selector: 'business-unit-selector',
  templateUrl: 'business-unit-selector.component.html',
  providers: [BUSINESS_UNIT_SELECTOR_CONTROL_VALUE_ACCESSOR],
})
export class BusinessUnitSelectorComponent implements ControlValueAccessor, OnInit {
  @Input() nullLabel: String;
  @Input() nullLabelKey: String;
  @Input() disabled = false;
  @Input() userDefaultBU = false;
  @Output() changeItem = new EventEmitter<BusinessUnitDto>();
  businessUnitResults: BusinessUnitDto[];
  selectedBusinessUnit: BusinessUnitDto;

  businessUnit: BusinessUnitDto;
  onChangeModel: Function;
  onTouchedModel: Function;

  constructor(private loggedUserService: LoggedUserService, private businessUnitService: BusinessUnitService) {}

  ngOnInit() {
    this.businessUnitService.getBusinessUnits().subscribe(
      (businessUnitResult) => (this.businessUnitResults = businessUnitResult),
      (error) => console.log('Error on businessUnitService.getBusinessUnits ', error)
    );
  }

  setUserDefaultBU() {
    if (this.userDefaultBU) {
      const user: UserDto = this.loggedUserService.getLoggedUserData();
      if (user && user.businessUnit) {
        this.onChangeItem(user.businessUnit);
      }
    }
  }

  writeValue(item: BusinessUnitDto): void {
    if (!item) {
      this.setUserDefaultBU();
    } else {
      this.selectedBusinessUnit = item;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedModel = fn;
  }

  onChangeItem(event: BusinessUnitDto) {
    this.selectedBusinessUnit = event;
    if (this.onChangeModel) {
      this.onChangeModel(event);
    }
    this.changeItem.emit(event);
  }
}
