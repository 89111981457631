import {AfterViewInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {ListEmitters} from './list-emitters';
import {SectionSelector} from './section-selector';
import {StringUtils} from '../../utils';

@Component({
  selector: 'section-selectors',
  templateUrl: './section-selectors.component.pug',
})
export class SectionSelectorsComponent extends ListEmitters implements AfterViewInit {
  @Output() allSelectorsSet = new EventEmitter<any>();
  @Input() openSelectorOnObjectChange = false;

  @ContentChildren(SectionSelector) allSelectors: QueryList<SectionSelector>;

  private _object;
  get object() {
    return this._object;
  }
  @Input()
  set object(value) {
    this._object = value;
    this.refreshSelectors();
    if (this.openSelectorOnObjectChange && this.currentSelectors) {
      this.openNextUnset();
    }
  }

  /**
   * can be set to fill space with empty grids
   */
  @Input()
  minGridNumber: number;

  @Input()
  disabled: boolean;

  @Input()
  selectorChooser: (allSelectors: SectionSelector[]) => SectionSelector[];

  @Input()
  openOnInit = false;

  @Input()
  emptySectionHidden = false;

  currentSelectors: SectionSelector[];

  ngAfterViewInit(): void {
    this.selectorNameList = this.allSelectors.map((s) => s.property);
    this.initializeSelectorEmitters(true);
    this.currentSelectors = this.selectorChooser
      ? this.selectorChooser(this.allSelectors.toArray())
      : this.allSelectors.toArray();
    if (this.openOnInit) {
      this.openNextUnset();
    } else {
      this.openNextMarked();
    }
  }

  onSelectorChange(value: any, property: string) {
    StringUtils.setPropertyRaw(this._object, property, value);
    this.refreshSelectors();
    const index = this.currentSelectors.findIndex((x) => x.property === property);
    this.currentSelectors[index].change.emit(value);
    if (this.allSet()) {
      this.closeAllSectionSelectors();
      this.allSelectorsSet.emit(this._object);
    } else {
      this.openNextUnset(index);
    }
  }

  openNextUnset(index = -1) {
    while (index < this.currentSelectors.length - 1) {
      const next = this.currentSelectors[index + 1];
      if (!StringUtils.getPropertyRaw(this._object, next.property)) {
        // let angular apply changes on view before opening
        setTimeout(() => this.openSelectorEmitters[next.property].next(true));
        break;
      }
      index++;
    }
  }

  openNextMarked(index = -1) {
    while (index < this.currentSelectors.length - 1) {
      const next = this.currentSelectors[index + 1];
      if (next.openOnInit) {
        // let angular apply changes on view before opening
        setTimeout(() => this.openSelectorEmitters[next.property].next(true));
        break;
      }
      index++;
    }
  }

  refreshSelectors() {
    if (this.selectorChooser) {
      const chosen = this.selectorChooser(this.allSelectors.toArray()).filter((y) => y);
      // deleting properties which became hidden
      this.currentSelectors
        .filter((x) => x)
        .filter((x) => !chosen.find((y) => y.property === x.property))
        .forEach((x) => (this.object[x.property] = undefined));

      this.currentSelectors = chosen;
    }
    this.closeSubSelectors();
  }

  allSet(): boolean {
    return this.currentSelectors && this.currentSelectors.every((x) => this.isSet(x));
  }

  isSet(selector: SectionSelector) {
    return !!StringUtils.getPropertyRaw(this._object, selector.property);
  }

  buildContext(selector: SectionSelector) {
    return {
      object: this._object,
      disabled: this.disabled,
      openEmitter: this.openSelectorEmitters[selector.property],
      onChange: (event) => this.onSelectorChange(event, selector.property),
    };
  }

  getFiller(): Array<any> {
    const diff = this.minGridNumber - this.currentSelectors.length;
    return diff > 0 ? Array(diff) : [];
  }

  closeAllSectionSelectors() {
    console.log('closeAllSectionSelectors');
    this.closeAllSelectors();
    this.closeSubSelectors();
  }

  closeSubSelectors() {
    if (this.allSelectors) {
      this.allSelectors.forEach((s) => s.closeSubSelectors());
    }
  }
}
