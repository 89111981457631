import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {AppConfigService} from '../../../../bonding_shared';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'technical-section',
  templateUrl: './technical-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class TechnicalSectionComponent extends Section {
  self = this;
  manualInvoicingSetByForcedCondition = false;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    public appConfigService: AppConfigService
  ) {
    super(policyContractViewService, appService);
  }

  onForceConditionChange(checked: boolean) {
    if (!checked) {
      this.policy.forceConditionMetDate = undefined;
      if (this.manualInvoicingSetByForcedCondition) {
        this.policy.policyContract.manualInvoicing = false;
      }
    }
    if (checked) {
      if (!this.policy.policyContract.manualInvoicing) {
        this.manualInvoicingSetByForcedCondition = true;
      }
      this.policy.policyContract.manualInvoicing = true;
    }
  }
}
