import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {ImportService} from '../../bonding_shared/services/import.service';
import {DictionaryBaseDto} from '../../bonding_shared';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {ATableComponent} from '../../bonding_shared/components/aku-table/a-table.component';
import {FileImportCriteriaDto, FileImportSimpleDto} from '../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';

@Component({
  selector: 'import-list',
  templateUrl: 'import-list.component.pug',
})
export class ImportListComponent implements AfterViewInit {
  @ViewChild(ATableComponent, {static: true}) fileTable: ATableComponent<FileImportSimpleDto>;
  fileDataProvider: SearchDataProvider<FileImportCriteriaDto, FileImportSimpleDto>;

  private _importTypeId;

  @Input() set importTypeId(importTypeId: number) {
    this._importTypeId = importTypeId;
  }
  @Input() metadata = null;

  @Input() set profileId(profileId: number) {
    this.fileDataProvider.searchCriteria.criteria.profile = profileId ? <DictionaryBaseDto>{id: profileId} : undefined;
  }

  constructor(private importService: ImportService) {
    this.fileDataProvider = BusinessUtils.createFileImportDataProvider(importService);
  }

  ngAfterViewInit(): void {
    this.initCriteria();
    this.refreshFileTable();
  }

  private initCriteria() {
    const criteria = this.fileDataProvider.searchCriteria.criteria;
    criteria.type = this._importTypeId ? <DictionaryBaseDto>{id: this._importTypeId} : undefined;

    if (this.metadata) {
      criteria.metadata = this.metadata;
    }
  }

  refreshFileTable() {
    console.log('File list refresh ...');
    this.fileTable.search();
  }

  downloadFile(file: FileImportSimpleDto, input: boolean) {
    this.importService.downloadFile(
      file.id,
      input,
      input ? file.fileName : file.outputFileName,
      (e) => this.generationErrorCallback(e),
      this.generateCompleteCallback
    );
  }

  private generationErrorCallback(err: string): void {
    console.log('Limit export error', err);
  }

  private generateCompleteCallback(): void {
    console.log('generateCompleteCallback');
  }
}
