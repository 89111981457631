import {Component, Input, ViewChild} from '@angular/core';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table/a-table.component';
import {PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto} from '../../../../bonding_shared/model/dtos';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {AppConfigService} from '../../../../bonding_shared';

@Component({
  selector: 'policy-inquiry-version-list',
  templateUrl: './policy-inquiry-version-list.component.pug',
})
export class PolicyInquiryVersionListComponent {
  @ViewChild('policyInquiryTable', {static: true}) policyInquiryTable: ATableComponent<PolicyInquiryVersionSimpleDto>;

  @Input() pageSize = 20;
  @Input() showClient: boolean = true;
  @Input() registrationNameVisible: boolean = true;

  @Input() set dataProvider(dp: SearchDataProvider<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>) {
    this.policyInquiryTable.dataProvider = dp;
    this.policyInquiryTable.pagination = true;
    this.policyInquiryTable.search();
  }

  constructor(public router: RouterService, public appService: AppConfigService) {}

  isEmpty(): boolean {
    if (!this.policyInquiryTable) {
      return true;
    }
    return this.policyInquiryTable.totalCount < 1;
  }
}
