import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {CompanyContactNoteDto, ContactNoteAndTaskDto, ContactNoteDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ContactNoteService extends AbstractService {
  protected url = this.urlPrefix + 'contactNote';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getContactNote(id: number): Observable<ContactNoteDto> {
    console.log('contact note details: id = ' + id);
    return this.get<ContactNoteDto>(this.url + '/' + id);
  }

  getCrmCompanies(businessObjectTypeId: number, businessObjectId: number): Observable<CompanyContactNoteDto[]> {
    const params = [];
    if (businessObjectTypeId) {
      params.push('businessObjectTypeId=' + businessObjectTypeId);
    }
    if (businessObjectId) {
      params.push('businessObjectId=' + businessObjectId);
    }
    const queryParamsJoined = params.length > 0 ? '?' + params.join('&') : '';
    return this.get<CompanyContactNoteDto[]>(this.url + '/crm' + queryParamsJoined);
  }

  createContactNote(contact: ContactNoteDto): Observable<ContactNoteDto> {
    console.log('crete contact note:' + JSON.stringify(contact));
    return this.put<ContactNoteDto>(contact, this.url);
  }

  createContactNoteAndTask(contact: ContactNoteAndTaskDto): Observable<ContactNoteDto> {
    return this.put1<ContactNoteAndTaskDto, ContactNoteDto>(contact, this.url + '/noteAndTask');
  }

  updateContactNote(contact: ContactNoteDto): Observable<ContactNoteDto> {
    console.log('update contact note: id = ' + contact.id);
    return this.post<ContactNoteDto>(contact, this.url);
  }

  saveContactNote(contact: ContactNoteDto): Observable<ContactNoteDto> {
    if (contact.id > 0) {
      return this.updateContactNote(contact);
    } else {
      return this.createContactNote(contact);
    }
  }

  deleteContactNote(id: number): Observable<ContactNoteDto> {
    console.log('delete contact note: id = ' + id);
    return this.delete<ContactNoteDto>(this.url + '/' + id);
  }
}
