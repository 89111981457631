import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CollectionQuestionnaireAnswerDto} from '../../../bonding_shared/model';
import {NgForm, ValidatorFn, Validators} from '@angular/forms';
import {CollectionQuestionnaire} from '../../../bonding_shared/model/dictionary-ids';
import {LoggedUserService} from '../../../bonding_shared/services';

@Component({
  selector: 'collection-questionnaire',
  templateUrl: 'collection-questionnaire.component.pug',
})
export class CollectionQuestionnaireComponent {
  @Input() formModel: NgForm;
  @Input() questionnaire: CollectionQuestionnaireAnswerDto[];
  @Input() editable: boolean;
  @Input() showErrors: boolean;
  @Input() firstVersion: boolean;
  @Output() contactRequest = new EventEmitter<boolean>();

  readonly exclusiveEdition = [
    CollectionQuestionnaire.INSOLVENCY_BANKRUPTCY,
    CollectionQuestionnaire.INSOLVENCY_LIQUIDATION,
    CollectionQuestionnaire.INSOLVENCY_RESTRUCT,
    CollectionQuestionnaire.LEGAL_PATH,
  ];

  readonly notVisibleInPortal = [CollectionQuestionnaire.NOT_LED_BY_INSURER, CollectionQuestionnaire.ORDER_TO_INSURER];

  constructor(private loggedUserService: LoggedUserService) {}

  isEditableInVersion(question: CollectionQuestionnaireAnswerDto): boolean {
    if (this.firstVersion) {
      return true;
    }
    return question.config.editableInNextVersion;
  }

  isCheckable(question: CollectionQuestionnaireAnswerDto): boolean {
    if (question.question && this.exclusiveEdition && this.exclusiveEdition.includes(question.question.id)) {
      for (let i = 0; i < this.exclusiveEdition.length; i++) {
        if (
          this.exclusiveEdition[i] !== question.question.id &&
          this.questionnaire.find((q) => q.checked && q.question && q.question.id === this.exclusiveEdition[i])
        ) {
          return false;
        }
      }
    }
    if (this.portal) {
      return question.config.editableInPortal && this.isEditableInVersion(question);
    }
    if (!question.config.yesUnique) {
      return true && this.isEditableInVersion(question);
    }
    const checkedYesUnique = this.questionnaire.find((q) => q.checked && q.config.yesUnique);
    return (!checkedYesUnique || checkedYesUnique === question) && this.isEditableInVersion(question);
  }

  isCommentEditable(q: CollectionQuestionnaireAnswerDto): boolean {
    const fresh = this.questionnaire.find((question) => question.question.id === q.question.id);
    return fresh.checked && this.isEditableInVersion(q);
  }

  isHidden(question: CollectionQuestionnaireAnswerDto): boolean {
    if (this.portal && this.notVisibleInPortal.includes(question.question.id)) {
      return true;
    }
    if (!question.config.questionVisibleWhenChecked || question.checked) {
      return false;
    }
    const related = this.questionnaire.find((q) => q.question.id === question.config.questionVisibleWhenChecked);
    return !(related && related.checked);
  }

  commentVisibleAndRequired(question: CollectionQuestionnaireAnswerDto): boolean {
    return this.commentVisible(question) && question.config.commentRequired;
  }

  commentVisible(question: CollectionQuestionnaireAnswerDto): boolean {
    return question.config.commentVisibleAndRequiredAlways || (question.checked && question.config.commentVisible);
  }

  documentVisibleAndRequired(question: CollectionQuestionnaireAnswerDto): boolean {
    return this.documentVisible(question) && question.config.documentRequired;
  }

  documentVisible(question: CollectionQuestionnaireAnswerDto): boolean {
    return question.checked && question.config.documentVisible;
  }

  documentEditable(question: CollectionQuestionnaireAnswerDto): boolean {
    return this.documentVisible(question) && this.isEditableInVersion(question);
  }

  tooltip(question: CollectionQuestionnaireAnswerDto): string {
    return (
      question.question.description ||
      (this.documentVisible(question) ? 'collection.details.documentPlease' : undefined)
    );
  }

  getCommentRequiredValidator(): (CollectionQuestionnaireAnswerDto) => ValidatorFn {
    return (q) => (control) => {
      // q contains unchanged value from backend, don't ask me why
      const fresh = this.questionnaire.find((question) => question.question.id === q.question.id);
      return this.commentVisibleAndRequired(fresh) && this.isCheckable(fresh) && Validators.required(control);
    };
  }

  getDocumentRequiredValidator(): (CollectionQuestionnaireAnswerDto) => ValidatorFn {
    return (q) => (control) => {
      // q contains unchanged value from backend, don't ask me why
      const fresh = this.questionnaire.find((question) => question.question.id === q.question.id);
      return (
        this.documentVisibleAndRequired(fresh) &&
        this.isCheckable(fresh) &&
        fresh.document &&
        Validators.required(control)
      );
    };
  }

  getYesRequiredValidator(): (CollectionQuestionnaireAnswerDto) => ValidatorFn {
    return (q) => (control) => {
      const fresh = this.questionnaire.find((question) => question.question.id === q.question.id);
      return fresh.config.yesRequired && !fresh.checked && !this.isHidden(fresh) && Validators.requiredTrue(control);
    };
  }

  tooltipVisible(question: CollectionQuestionnaireAnswerDto): boolean {
    return question.config.tooltipForQuestion || (question.checked && !!this.tooltip(question));
  }

  checkedClicked(question: CollectionQuestionnaireAnswerDto) {
    if (question.question.id === CollectionQuestionnaire.REQUEST_CONTACT) {
      this.contactRequest.emit(question.checked);
    }
    if (question.question.id === CollectionQuestionnaire.ASYMMETRIC_TRANSACTIONS) {
      this.checkQuestion(CollectionQuestionnaire.ASYMMETRIC_TRANSACTIONS_STATEMENT, question.checked);
    }
    if (question.question.id === CollectionQuestionnaire.ASYMMETRIC_TRANSACTIONS_STATEMENT) {
      this.checkQuestion(CollectionQuestionnaire.ASYMMETRIC_TRANSACTIONS, question.checked);
    }
  }

  private checkQuestion(questionId: number, check: boolean): void {
    const answer = this.questionnaire.find((question) => question.question.id === questionId);
    if (answer) {
      answer.checked = check;
    }
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}
