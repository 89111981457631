import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {BIReportService} from '../../../bonding_shared/services/bi-report.service';
import {BIReportCriteriaDto, BIReportSimpleDto} from '../../../bonding_shared';

@Injectable()
export class BiReportGuiService {
  dataProvider: SearchDataProvider<BIReportCriteriaDto, BIReportSimpleDto>;

  constructor(private service: BIReportService) {
    this.dataProvider = BusinessUtils.createBiReportDataProvider(this.service);
  }
}
