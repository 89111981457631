import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureService} from '../services';
import {Feature} from '../model/dictionary-ids';

@Directive({
  selector: '[feature]',
})
export class FeatureDirective {
  _feature: string | Feature;
  viewInitialized = false;

  @Input() set feature(feature: string | Feature) {
    if (feature && feature !== this._feature) {
      this._feature = feature;
      this.updateView();
    }
  }

  constructor(
    private featureService: FeatureService,
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<any>
  ) {}

  updateView() {
    if (this.viewInitialized) {
      this.viewContainer.clear();
    } else {
      this.viewInitialized = true;
    }
    if (this.featureService.get(this._feature)) {
      this.viewContainer.createEmbeddedView(this.template);
    } else {
      this.viewContainer.clear();
    }
  }
}
