<search-view [labelKey]="captionKey" [objectName]="getInvoiceItemCategoryLabel()" [dataProvider]="dataProvider"
             (searchExecuted)="showAdditionalButtons($event)" [searchModeSwitchable]="false"
             [newHandled]="false" (newExecuted)="newExecuted($event)" [newObjectLabelKey]="newObjectLabelKey"
             [newButtonDisabled] = "newDisabled" [newButtonVisible]="newVisible">
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner">
      <input-row [label]="'invoiceItem.search.clientName' | cattranslate: category"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.client.registrationName" name="registrationName"></input-row>
      <input-row [label]="'invoiceItem.search.clientId' | cattranslate: category" [(ngModel)]="dataProvider.searchCriteria.criteria.client.id" name="id" type="number">
      </input-row>
      <dict-row  [disabled]="productLineDisabled" labelKey="invoiceItem.search.productLine" [(ngModel)]="productLine" (changeItem)="onProductLineChange($event)"
                 dictionary="ProductLine" [hiddenIds]="productLineHiddenIds" name="productLine" [nullLabel]="'invoiceItem.search.all' | translate"></dict-row>
      <date-row labelKey="invoiceItem.search.dateFrom" [(ngModel)]="dataProvider.searchCriteria.criteria.dateFromRange.dateFrom"
                type="date" name="dateFrom"> </date-row>
      <date-row labelKey="invoiceItem.search.dateTo" [(ngModel)]="dataProvider.searchCriteria.criteria.dateToRange.dateTo"
                type="date" name="dateTo"> </date-row>
      <dict-row labelKey="invoiceItem.search.status" [(ngModel)]="dataProvider.searchCriteria.criteria.status"
                dictionary="InvoiceItemStatus" name="type" [nullLabel]="'invoiceItem.search.all' | translate"
                [codeRegexp]="invoiceItemStatusRegexp"></dict-row>
    </div>
    <div class="bon-card-inner bon-label-150">
      <form-row labelKey="invoiceItem.search.invoiced">
        <boolean-combo class="bon-input-no-underline" [(ngModel)]="dataProvider.searchCriteria.criteria.invoiceCriteria.inInvoice"
                       [falseLabel]="'invoiceItem.search.invoicedNo' | translate" [trueLabel]="'invoiceItem.search.invoicedYes' | translate"
                       [nullLabel]="'invoiceItem.search.all' | translate"></boolean-combo>
      </form-row>
      <dict-row labelKey="invoiceItem.search.relatedTo" [(ngModel)]="dataProvider.searchCriteria.criteria.businessObject.relatedTo"
                dictionary="BusinessObjectType" name="relatedTo" [nullLabel]="'invoiceItem.search.all' | translate"></dict-row>
      <input-row labelKey="invoiceItem.search.boDescription" [(ngModel)]="dataProvider.searchCriteria.criteria.businessObjectNumber" name="boDescription"></input-row>
      <input-row labelKey="invoiceItem.search.contractNumber" [(ngModel)]="dataProvider.searchCriteria.criteria.contractNumber" name="contractNumber"></input-row>
      <dict-row labelKey="invoiceItem.search.type" [(ngModel)]="dataProvider.searchCriteria.criteria.type"
                dictionary="InvoiceItemType" name="feeType" [nullLabel]="'invoiceItem.search.all' | translate"></dict-row>
      <dict-row labelKey="invoiceItem.search.subtype" [(ngModel)]="dataProvider.searchCriteria.criteria.subtype"
                dictionary="InvoiceItemSubtype" name="subtype" [nullLabel]="'invoiceItem.search.all' | translate"
                parentDictionary="InvoiceItemType" [parentDictionaryEntryId]="dataProvider.searchCriteria.criteria?.type?.id" [allForEmptyParent]="true"></dict-row>
    </div>
    <div class="bon-card-inner">
      <form-row labelKey="invoiceItem.search.businessUnit">
        <business-unit-id-selector class="bon-input-size" [(ngModel)]="dataProvider.searchCriteria.criteria.businessUnit"
                                   name="businessUnit" [nullLabel]="'invoiceItem.search.all' | translate"></business-unit-id-selector>
      </form-row>
      <input-row [label]="'invoiceItem.search.sourceItemContractNumber'| cattranslate: category"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.sourceInvoiceObject.contractNumber" name="sourceItemContractNumber"></input-row>
      <input-row [label]="'invoiceItem.search.sourceInvoiceNumber'| cattranslate: category"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.sourceInvoiceObject.invoiceNumber" name="sourceInvoiceNumber"></input-row>
    </div>
  </div>
  <div class="additional-buttons">
    <button *ngIf="createInvoiceVisible" class="bon-btn-info" [btnIcon]="'fa-envelope-o'"
            (click)="createInvoiceClicked()" type="button" translate>invoiceItem.search.createInvoice</button>
    <button *ngIf="deleteInvoiceItemsVisible" class="bon-btn-info" [btnIcon]="'fa-envelope-o'"
            (click)="deleteInvoiceItemsClicked()" type="button" translate>invoiceItem.search.deleteInvoiceItems</button>
    <button *ngIf="writeAllOffVisible"  [disabled]="writeAllOffDisabled" [btnIcon]="'fa-download'"
            (click)="openWriteAllOffConfirmDialog()" type="button" class="bon-btn-info" translate>invoiceItem.search.writeAllOff</button>
    <button class="bon-btn-info" [btnIcon]="'fa-file-text-o'"
            (click)="exportClicked()" type="button" [disabled]="exportInProgress || massExportDisabled"
            translate>invoiceItem.search.export</button>
    <img *ngIf="exportInProgress" src="assets/images/loading2.gif"/>
  </div>
  <div class="search-results">
      <a-table #invoiceItemResultList [pagination]="true" [pageSize]="20" [selection]="false"
               [sortable]='!dataProvider.textSearch' [footer]="true">
        <column labelKey="invoiceItem.search.result.title" property="title" [link]="true" sort="title"
                (linkClick)="router.toInvoiceItemDetails(categoryId, $event.id)"></column>
        <column [title]="'invoiceItem.search.result.clientName' | cattranslate: category" property="client.registrationName" sort="client.registrationName" [link]="true"
                (linkClick)="router.toCompanyPreview($event.client.id)"></column>
        <column labelKey="invoiceItem.search.result.contractNumber" property="contractNumber" sort="contractNumber"></column>
        <column [title]="'invoiceItem.search.result.sourceItemClientName' | cattranslate: category" property="sourceClient.registrationName" [link]="true"
                (linkClick)="router.toCompanyPreview($event.sourceClient.id)"></column>
        <column labelKey="invoiceItem.search.result.subtype" property="subtype" dictionary="InvoiceItemSubtype" sort="subtype.name"></column>
        <column labelKey="invoiceItem.search.result.netAmount" property="netAmount" type="number" sort="netAmount" [sumInFooter]="true"></column>
        <column labelKey="invoiceItem.search.result.status" property="status" dictionary="InvoiceItemStatus" sort="status.name"></column>
        <column labelKey="invoiceItem.search.result.invoiceNumber" [link]="true" sort="invoicePosition.invoice.number"
                (linkClick)="router.toInvoiceDetails(categoryId, $event.invoicePosition.invoice.id)">
          <ng-template let-item="item" let-edition="false">{{getInvoiceNumber(item)}}</ng-template>
        </column>
        <column labelKey="invoiceItem.search.result.creationDate" property="creationDate" type="date" sort="creationDate" initialSort="DOWN"></column>
        <column labelKey="invoiceItem.search.result.dateFrom" property="dateFrom" type="date" sort="dateFrom"></column>
        <column labelKey="invoiceItem.search.result.dateTo" property="dateTo" type="date" sort="dateTo"></column>
        <column labelKey="invoiceItem.search.result.treaty">
          <ng-template let-item="item" let-edition="false">
            <ng-container *ngFor="let t of item.treaties; let isLast=last">
              {{t.treaty?.number}}{{isLast ? '' : ', '}}
            </ng-container>
          </ng-template>
        </column>
      </a-table>
  </div>
  <confirm-dialog #confirmDialog></confirm-dialog>
</search-view>

