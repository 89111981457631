import {Component, Input} from '@angular/core';
import {Section} from '../section';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {AppConfigService, DictionaryService} from '../../../../../bonding_shared/services';
import {ControlContainer, NgForm} from '@angular/forms';
import {PolicyClauseDto, PolicyContractVersionDto, StringUtils} from '../../../../../bonding_shared';
import {DictionaryPropertyType} from '../../../../../bonding_shared/model/dictionary-ids';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'kuke-nn-limits-section',
  templateUrl: './kuke-nn-limits-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KukeNnLimitsSectionComponent extends Section {
  self = this;

  _clauses: PolicyClauseDto[];

  items: AutomaticLimits[];

  @Input() set clauses(clauses: PolicyClauseDto[]) {
    this._clauses = clauses;
    this.updateAutoLimitsLists();
  }

  @Input() set policy(policy: PolicyContractVersionDto) {
    super.policy = policy;
    this._clauses = policy && policy.clauses;
    this.updateAutoLimitsLists();
  }

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected dictionaryService: DictionaryService
  ) {
    super(policyContractViewService, appService, null, dictionaryService);
  }

  private updateAutoLimitsLists() {
    const cls = this._clauses;
    if (!cls) {
      return;
    }

    const applyLimitAutomatyczny = (f: Function) =>
      this.dictionaryService
        .getDictionaryEntry('ClauseParameterType', DictionaryPropertyType.CUSTOM_FIELD__LIMIT_AUTOMATYCZNY)
        .pipe(
          map((la) => la.relatedDictionaries['CustomField']),
          filter((la) => !!la),
          map((la) => la.map((d) => d.id))
        )
        .subscribe((la) => f(la));

    const filterLimitFromValues = (limitsDicts: number[]) =>
      cls
        .map((c) => {
          const val = c.values.find((v) => limitsDicts.includes(v.field.id));
          if (val) {
            return <AutomaticLimits>{value: val.value, name: c.type.name, code: StringUtils.shortCode(c.type.code)};
          }
          return null;
        })
        .filter((al) => !!al);

    const assignItems = (limitsDicts: number[]) => (this.items = filterLimitFromValues(limitsDicts));

    applyLimitAutomatyczny(assignItems);
  }
}

interface AutomaticLimits {
  name: string;
  value: string;
  code: string;
}
