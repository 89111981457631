import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  BusinessPropertiesService,
  DictionaryBaseDto,
  LimitBaseDto,
  LimitRequestCriteriaDto,
  LimitRequestDto,
  RouterService,
  SearchDataProvider,
  StringUtils,
} from '../../../../bonding_shared';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';

@Component({
  selector: 'limit-list',
  templateUrl: './limit-list.component.pug',
})
/**
 * Limit request list
 */
export class LimitListComponent implements OnInit, AfterViewInit {
  @Input() pageSize = 20;
  @Input() dataProvider: SearchDataProvider<LimitRequestCriteriaDto, LimitRequestDto>;
  @Input() showId = true;
  @Input() showBuyer = true;
  @Input() showBuyerLink = true;
  @Input() showInsured = true;
  @Input() showContactConsent = false;
  @Input() showCurrentValue = true;
  @Input() showPolicyLink = true;
  @Input() portalMode = false;
  @Input() showBackendErrors = true;
  @Input() showFooter = false;
  @Input() showProductType = false;
  @Input() selectable: boolean;
  @Input() showLastCoverAmount = false;
  @Input() showLastCoverValidTo = false;
  @Input() initSearch = false;

  @ViewChild(ATableComponent, {static: true}) limitTable: ATableComponent<LimitRequestDto>;

  @Output() limitRequestSelected = new EventEmitter<LimitRequestDto>();

  systemCurrency: DictionaryBaseDto;

  constructor(
    public router: RouterService,
    public appService: AppConfigService,
    private businessPropertiesService: BusinessPropertiesService
  ) {}

  ngOnInit() {
    this.setSystemCurrency();
  }

  ngAfterViewInit() {
    if (this.initSearch) {
      this.limitTable.search();
    }
  }

  onLimitRequestSelected(event: LimitRequestDto) {
    this.limitRequestSelected.emit(event);
  }

  onBuyerClicked(event: LimitRequestDto) {
    if (event.limit.buyer.company) {
      this.router.toCompanyPreview(event.limit.buyer.company.id);
    } else if (!this.portalMode) {
      this.router.toThirdPartyDetails(event.limit.buyer.id);
    }
  }

  getBuyerName(limit: LimitBaseDto) {
    const company = limit.buyer.company
      ? limit.buyer.company
      : this.portalMode
      ? limit.buyer.firstCompanyRaw
      : limit.buyer.lastCompanyRaw;
    return BusinessUtils.companyName(company);
  }

  setSystemCurrency() {
    this.businessPropertiesService.getProperties().subscribe((p) => (this.systemCurrency = p.systemCurrency));
  }

  lastCoverStatus(limitRequest: LimitRequestDto): string {
    if (limitRequest && limitRequest.lastCover && limitRequest.lastCover.businessStatus) {
      return limitRequest.lastCover.businessStatus.name;
    }
    return null;
  }

  lastCoverValue(limitRequest: LimitRequestDto): string {
    if (limitRequest && limitRequest.lastCover) {
      const v =
        limitRequest.lastCover.value && limitRequest.lastCover.value.amount
          ? limitRequest.lastCover.value.amount
          : limitRequest.lastCover.proposedValue.amount;
      return StringUtils.formatNumber(v);
    }
    return null;
  }

  search() {
    this.limitTable.search();
  }

  public get credendo(): boolean {
    return this.appService.credendo;
  }
}
