import {Observable} from 'rxjs';
import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {
  DictionaryBaseDto,
  InvoiceNumberingBagCriteriaDto,
  InvoiceNumberingBagDto,
  SearchCriteria,
  SearchResult,
} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class InvoiceNumberingBagService extends AbstractService {
  constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + 'invoiceNumberingBag';
  }

  search(criteria: SearchCriteria<InvoiceNumberingBagCriteriaDto>): Observable<SearchResult<InvoiceNumberingBagDto>> {
    return this.searchByCriteria<InvoiceNumberingBagCriteriaDto, InvoiceNumberingBagDto>(criteria);
  }

  getCorrectionReasons(numberingBagId: number, newClient: boolean): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(
      this.buildUrl(
        this.url,
        'correctionReasons',
        UrlParams.new().add('numberingBagId', numberingBagId).add('newClient', newClient)
      )
    );
  }
}
