import {Component, OnInit} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {AppConfigService} from '../../../../bonding_shared';
import {
  DictionaryProfile,
  FrontingType,
  PdrConvention,
  PolicyContractType,
  PolicyContractTypes,
} from '../../../../bonding_shared/model/dictionary-ids';
import {ControlContainer, NgForm, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {validateFixedMinPremiums} from '../../domain/fixed-min-premiums';

@Component({
  selector: 'premium-section',
  templateUrl: './premium-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class PremiumSectionComponent extends Section implements OnInit {
  self = this;
  readonly FrontingType = FrontingType;
  readonly DictionaryProfile = DictionaryProfile;

  def: RegExp = /^(?:SUBINSURED|MAIN_INSURED_UNGROUPED)$/;
  defWithFront: RegExp = /^(?:SUBINSURED|MAIN_INSURED_UNGROUPED|FRONTING)$/;
  public mehibAcquisitionDiscountAllowedValues = [undefined, 5, 10];

  pdrPercentEditable = true;

  constructor(protected policyContractViewService: PolicyContractViewService, public appService: AppConfigService) {
    super(policyContractViewService, appService);
  }

  mehib() {
    return this.appService.mehib;
  }

  isMehibMLT() {
    return (
      this.policy && this.policy.contractType && PolicyContractTypes.MEHIB_MLT.includes(this.policy.contractType.id)
    );
  }

  ngOnInit(): void {
    // TODO: remove kuke logic
    // if (this.isSectionVisible(this.viewSection.PREMIUM__KUKE_FIXED_MIN_PREMIUMS) && !this.policy.fixedMinPremiums) {
    //   this.policy.fixedMinPremiums = [];
    // }
  }

  onChangeInTurnoverChanged(checked: boolean) {
    if (checked) {
      this.policy.turnoverRates = [];
    } else {
      delete this.policy.turnoverRates;
    }
  }
  isType(policyContractTypeId: PolicyContractType) {
    return this.policy && this.policy.contractType && this.policy.contractType.id === policyContractTypeId;
  }

  isPremiumOnSubinsuredLevelDisabled() {
    return (
      this.frontingOfType(FrontingType.FRONTING) ||
      this.frontingOfType(FrontingType.FRONTING_ASSUMED) ||
      this.isSectionVisible(this.viewSection.PREMIUM__PREMIUM_ON_SUBINSURED_LEVEL_DISABLED, false)
    );
  }

  onPdrConventionChange() {
    if (this.policy.pdrConvention.id === PdrConvention.NO_PDR_CALCULATION) {
      this.policy.pdrPercent = 0;
      this.pdrPercentEditable = false;
    } else {
      this.policy.pdrPercent = null;
      this.pdrPercentEditable = true;
    }
  }

  isPdrDisabled() {
    return (
      this.policy.contractType.id === PolicyContractType.PRESHIPMENT_RISK_G ||
      this.policy.contractType.id === PolicyContractType.FOREIGN_INVESTMENT_B
    );
  }

  isManualInvoicingDisabled() {
    return (
      (this.frontingOfType(FrontingType.ACTIVE_REINSURANCE) && this.mehib()) ||
      (this.policy.forceCondition &&
        !this.policy.forceConditionMetDate &&
        this.policy.policyContract.manualInvoicing === true)
    );
  }

  isPremiumRateDisabled() {
    return this.policy.premiumFixed && !PolicyContractTypes.MEHIB_MLT.includes(this.policy.contractType.id);
  }
}
