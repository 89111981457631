import {Component, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormDialogComponent} from '../../components/form-dialog/form-dialog.component';
import {FormDialogParent} from '../../components/form-dialog/FormDialogParent';
import {LoggedUserService} from '../../services/logged-user.service';
import {UserAvailabilityDto} from '../../model/dtos';
import {GrowlService} from '../../services/growl/growl.service';
import {AbstractLoginService} from '../../../login/login.service';
import {UserService} from '../../services';
import {CustomValidators} from '../../validators';

@Component({
  selector: 'change-absence',
  templateUrl: './change-absence.component.pug',
})
export class ChangeAbsenceComponent extends FormDialogParent {
  @ViewChild(FormDialogComponent, {static: true}) changeAbsenceDialog: FormDialogComponent;

  constructor(
    private loginService: AbstractLoginService,
    private loggedUserService: LoggedUserService,
    private userService: UserService,
    private growlService: GrowlService
  ) {
    super();
    this.form = new UntypedFormGroup({
      absentFrom: new UntypedFormControl('', Validators.required),
      absentTo: new UntypedFormControl('', Validators.required),
    });
  }

  setAbsence() {
    if (!this.form.valid) {
      this.showErrors = true;
      this.changeAbsenceDialog.showErrors = true;
      return;
    }
    const absentFrom = this.form.get('absentFrom').value;
    const absentTo = this.form.get('absentTo').value;
    const changePassDto: UserAvailabilityDto = <UserAvailabilityDto>{from: absentFrom, to: absentTo};
    this.userService.changeAvailability(changePassDto).subscribe(
      (user) => {
        this.loggedUserService.refreshUserLogged(user);
      },
      (error) => {
        this.changeAbsenceDialog.showErrors = true;
        this.changeAbsenceDialog.errors = error;
        this.growlService.error('Error during settings absence.');
      },
      () => {
        this.changeAbsenceDialog.hide();
      }
    );
  }

  openChangeAbsenceDialog() {
    this.form.controls.absentFrom.setValue(this.loggedUserService.getLoggedUserData().absentFrom);
    this.form.controls.absentTo.setValue(this.loggedUserService.getLoggedUserData().absentTo);
    this.changeAbsenceDialog.open('changeAbsence.title').then((result) => {
      if (result) {
        this.setAbsence();
      }
      this.showErrors = false;
      this.form.reset();
    });
  }
}
