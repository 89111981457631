import {StringUtils} from '../utils/string-utils';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'number',
})
export class NumberPipe implements PipeTransform {
  transform(n: number, format?: string) {
    return StringUtils.formatNumber(n, format);
  }
}
