import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SidebarComponent} from './sidebar.component';
import {VersionSummaryModule} from '../version-summary/version-summary.module';
import {CommonModule} from '@angular/common';
import {SharedComponentsModule} from '../shared-components.module';

@NgModule({
  imports: [RouterModule, VersionSummaryModule, CommonModule, SharedComponentsModule],
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
})
export class SidebarModule {}
