<details-view [title]="'company.managers.title' | translate" [objectName]="'company.common.company' | translate" [versionListHidden]="true" [fixedBlockHidden]="false" [parentComponent]="self">

  <div class="information-header">
    <company-info *ngIf="company"  [companyId]="company.id" [showLink]="true">
    </company-info>
  </div>

  <div *ngIf="company" class="details-content">
    <div class="bon-header" style="padding-top:20px;">{{'company.managers.header' | translate}}</div>
    <form role="form">
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <form-row *ngIf="!appConfigService.kuke" labelKey="company.managers.underwriter">
            <item-combo class="bon-input-size" [items]="underwritersForAssignment" nullLabel="{{'company.managers.underwriterComboNullLabel' | translate}}" [presentationMode]="readOnly"
                        [(ngModel)]="underwriter" [ngModelOptions]="{standalone: true}">
              <ng-template let-item="item">{{item | username}}</ng-template>
            </item-combo>
          </form-row>
          <form-row labelKey="company.managers.policyManager">
            <item-combo class="bon-input-size" [items]="policyManagersForAssignment" nullLabel="{{'company.managers.policyManagerComboNullLabel' | translate}}"
                        [presentationMode]="readOnly || !canManagersEdit()" [(ngModel)]="policyManager" [ngModelOptions]="{standalone: true}">
              <ng-template let-item="item">{{item | username}}</ng-template>
            </item-combo>
          </form-row>
          <form-row *ngIf="appConfigService.kuke" labelKey="company.managers.bondingManager">
            <item-combo class="bon-input-size" [items]="bondingManagersForAssignment" nullLabel="{{'company.managers.bondingManagerComboNullLabel' | translate}}"
                        [(ngModel)]="bondingManager" [ngModelOptions]="{standalone: true}"  [presentationMode]="readOnly || !canManagersEdit()">
              <ng-template let-item="item">{{item | username}}</ng-template>
            </item-combo>
          </form-row>
        </div>
        <div class="bon-card-inner"></div>
        <div class="bon-card-inner"></div>
      </div>
    </form>
  </div>
</details-view>
