import {Component, Input} from '@angular/core';
import {Version} from '../../../bonding_shared/model/index';

@Component({
  selector: 'single-version-info',
  templateUrl: './single-version-info.component.pug',
})
export class SingleVersionInfoComponent {
  @Input() version: Version;
}
