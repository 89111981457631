import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {BondingElementaryRight} from '../../../bonding_shared/model/dictionary-ids';
import {AbstractGuard} from '../../../bonding_shared/components/abstract-guard';
import {FeatureService, LoggedUserService} from '../../../bonding_shared';

@Injectable()
export class BondDetailsGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router, private featureService: FeatureService) {
    super(loggedUserService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkRight(state.url, BondingElementaryRight.BOND_VIEW);
  }
}
