import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {AbstractBusinessObjectSelector} from './abstract-business-object-selector';
import {AppConfigService, BusinessObjectService, GrowlService, RouterService} from '../../services';
import {BusinessObjectDto} from '../../model';
import {Observable} from 'rxjs';

const BUSINESS_OBJECT_AUTO_COMPLETE_SELECTOR_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BusinessObjectAutoCompleteSelectorComponent),
  multi: true,
};

@Component({
  selector: 'business-object-auto-complete-selector',
  templateUrl: 'business-object-auto-complete-selector.component.html',
  providers: [BUSINESS_OBJECT_AUTO_COMPLETE_SELECTOR_CONTROL_VALUE_ACCESSOR],
})
export class BusinessObjectAutoCompleteSelectorComponent extends AbstractBusinessObjectSelector implements OnInit {
  @Input() matchFormatted = true;
  @Input() acceptUserInput = true;

  @Input() companyId: number;

  constructor(
    protected businessObjectService: BusinessObjectService,
    public router: RouterService,
    protected growlService: GrowlService,
    public appConfigService: AppConfigService
  ) {
    super(businessObjectService, router, growlService, appConfigService);
  }

  ngOnInit(): void {
    this.relatedTo.valueChanges.subscribe((value) => {
      this.value = undefined;
      this.onChange(undefined);
    });
  }

  onClear() {
    this.value = undefined;
    this.onChange(this.value);
    this.relatedTo.patchValue(undefined);
  }

  autoCompleteSearch(keyword: string): Observable<BusinessObjectDto[]> {
    if (this.companyId && this.relatedTo.value) {
      return this.businessObjectService.getRelatedBusinessObjects(
        this.relatedTo.value.id,
        this.companyId,
        keyword || ''
      );
    }
  }

  changeItem(it: BusinessObjectDto) {
    this.value = it;
    this.onChange(this.value);
  }
}
