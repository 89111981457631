import {Component, OnInit, ViewChild} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {
  BondDto,
  BondVersionCriteriaDto,
  BusinessUnitIdDto,
  ErrorReason,
  LocalDateRange,
  UserDto,
} from '../../bonding_shared/model/dtos';
import {BondGuiService} from './services/bond-gui.service';
import {ComboItem} from '../../bonding_shared/components/combos/string-combo.component';
import {AppConfigService, BondVersionService, FeatureService, LoggedUserService} from '../../bonding_shared/services';
import {BondVersionSimpleDto, BusinessUnitBaseDto} from '../../bonding_shared/model';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {SearchViewComponent} from '../../bonding_shared/components/search';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {UserResponsibleService} from '../../bonding_shared/services/user-responsible.service';
import {BondingElementaryRight, Feature} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'bond-version-search',
  templateUrl: './bond-version-search.component.pug',
})
export class BondVersionSearchComponent extends SearchView implements OnInit {
  readonly BondingElementaryRight = BondingElementaryRight;

  dataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>;
  versionPhases: ComboItem[] = [new ComboItem('ACTIVE', 'Current'), new ComboItem('LAST', 'Last')];
  public showBondSubType: boolean; // Kuke does not use bond subtype

  @ViewChild(SearchViewComponent) searchView: SearchViewComponent<BondVersionCriteriaDto, BondDto>;

  readonly Feature = Feature;

  constructor(
    public router: RouterService,
    private service: BondGuiService,
    private loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    private bondVersionService: BondVersionService,
    private featureService: FeatureService,
    private userResponsibleService: UserResponsibleService
  ) {
    super();
    this.showBondSubType = featureService.get(Feature.BOND_SHOW_SUBTYPE);
    this.dataProvider = service.searchDataProvider;
  }

  ngOnInit() {
    if (!this.dataProvider.searchCriteria.criteria) {
      this.initializeCriteria();
    }
  }

  initializeCriteria() {
    this.dataProvider.searchCriteria.criteria = <BondVersionCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.bond = <BondDto>{};
    this.dataProvider.searchCriteria.criteria.validFrom = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.validTo = <LocalDateRange>{};
    this.dataProvider.textSearch = false;
    this.dataProvider.searchCriteria.criteria.activated = true;
    const user: UserDto = this.loggedUserService.getLoggedUserData();
    if (user && user.businessUnit) {
      this.dataProvider.searchCriteria.criteria.contractBusinessUnit = <BusinessUnitBaseDto>{};
      this.dataProvider.searchCriteria.criteria.contractBusinessUnit.id = user.businessUnit.id;
      this.dataProvider.searchCriteria.criteria.contractBusinessUnit.countryCode = user.businessUnit.countryCode;
    }

    this.dataProvider.searchCriteria.criteria.userResponsible =
      this.userResponsibleService.getUserResponsibleSearchCriteria();
  }

  isRequest(bondVersion: BondVersionSimpleDto) {
    return BusinessUtils.isRequest(bondVersion.status.id);
  }

  buChanged(bu: BusinessUnitIdDto) {
    this.dataProvider.searchCriteria.criteria.type = undefined;
    this.dataProvider.searchCriteria.criteria.subtype = undefined;
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get mehib(): boolean {
    return this.appService.mehib;
  }

  export() {
    this.dataProvider.inProgress = true;
    this.bondVersionService.export(
      this.dataProvider.searchCriteria.criteria,
      'bonds.xlsx',
      (errorMsg) => this.downloadErrorCallback(errorMsg),
      () => {
        this.searchView.serverErrors = null;
        this.dataProvider.inProgress = false;
        this.exportCompleteCallback();
      }
    );
  }

  private downloadErrorCallback(err: string): void {
    const error = <ErrorReason[]>JSON.parse(err);
    console.log('Bond export error', error);
    this.searchView.serverErrors = error;
    this.dataProvider.inProgress = false;
  }

  private exportCompleteCallback(): void {
    console.log('exportCompleteCallback');
  }
}
