import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {GrowlService, RepositoryService, RouterService, SearchDataProvider} from '../../services';
import {
  BackendError,
  DictionaryBaseDto,
  DocumentDescriptionDto,
  RepositoryDocumentCriteriaDto,
  SearchResult,
} from '../../model';
import {ATableComponent} from '../aku-table';

var arrayBuffer: any;

@Component({
  selector: 'repository-document-list',
  templateUrl: './repository-document-list.component.pug',
})
export class RepositoryDocumentListComponent implements AfterViewInit {
  _businessObjectTypeId: number;
  _documentTypeId: number;
  _uploadDocumentTypeId: number;
  _businessObjectId: number;

  selectedFile: File;
  files: any;
  inProgress: boolean;

  @ViewChild('ngForm') ngForm: NgForm;

  @Input() categoryId: number;

  @Input()
  set businessObjectTypeId(value: number) {
    if (this._businessObjectTypeId !== value) {
      this._businessObjectTypeId = value;
      this.refreshDocuments();
    }
  }

  @Input()
  set documentTypeId(value: number) {
    if (this._documentTypeId !== value) {
      this._documentTypeId = value;
      this.refreshDocuments();
    }
  }

  @Input()
  set uploadDocumentTypeId(value: number) {
    if (this._uploadDocumentTypeId !== value) {
      this._uploadDocumentTypeId = value;
      this.refreshDocuments();
    }
  }

  @Input()
  set businessObjectId(value: number) {
    if (this._businessObjectId !== value) {
      this._businessObjectId = value;
      this.refreshDocuments();
    }
  }

  @Output() documentSelect = new EventEmitter<DocumentDescriptionDto>();
  @Output() dataProviderSearchFinished = new EventEmitter<SearchResult<DocumentDescriptionDto>>();
  @Output() errorOccured = new EventEmitter<BackendError>();

  @ViewChild('alfrescoDocumentTable', {static: true}) alfrescoDocumentTable: ATableComponent<DocumentDescriptionDto>;

  selectedItem: DocumentDescriptionDto;

  dataProvider: SearchDataProvider<RepositoryDocumentCriteriaDto, DocumentDescriptionDto>;

  constructor(
    public router: RouterService,
    private alfrescoService: RepositoryService,
    private growlService: GrowlService
  ) {
    this.dataProvider = new SearchDataProvider<RepositoryDocumentCriteriaDto, DocumentDescriptionDto>(alfrescoService);
    this.setupCriteria();
  }

  refreshDocuments() {
    if (this._businessObjectTypeId !== undefined && this._businessObjectId !== undefined) {
      this.setupCriteria();
      this.alfrescoDocumentTable.search();
    }
  }

  setupCriteria() {
    this.criteria = <RepositoryDocumentCriteriaDto>{};
    this.criteria.businessObjectType = <DictionaryBaseDto>{id: this._businessObjectTypeId};
    this.criteria.businessObjectId = this._businessObjectId;
    this.criteria.documentType = <DictionaryBaseDto>{id: this._documentTypeId};
  }

  get criteria(): RepositoryDocumentCriteriaDto {
    return this.dataProvider.searchCriteria.criteria;
  }

  set criteria(c: RepositoryDocumentCriteriaDto) {
    this.dataProvider.searchCriteria.criteria = c;
  }

  getDocument(document: DocumentDescriptionDto) {
    console.log('get document:', document.repositoryId);
    if (this.documentSelect.observers.length > 0) {
      this.documentSelect.emit(document);
    } else {
      this.alfrescoService.getRepositoryDocument(document.repositoryId, document.name);
    }
  }

  ngAfterViewInit() {
    console.log('RepositoryDocumentListComponent::', this.dataProvider);
    this.alfrescoDocumentTable.search();
  }

  upload() {
    if (this.selectedFile !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = (e: any) => {
        arrayBuffer = e.target.result;
        this.send();
      };
    }
  }

  clear() {
    this.selectedFile = undefined;
    this.ngForm.form.reset();
  }

  send() {
    const blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
    this.alfrescoService
      .upload(
        this._businessObjectId,
        this.selectedFile.name,
        this.selectedFile.type,
        this.selectedFile.size,
        this._businessObjectTypeId,
        this._uploadDocumentTypeId,
        blob,
        this.categoryId
      )
      .subscribe({
        next: (_) => {
          this.setupCriteria();
          this.alfrescoDocumentTable.search();
          this.selectedFile = undefined;
          this.ngForm.form.reset();
          this.showUploadedMsg();
        },
        error: (error) => {
          this.selectedFile = undefined;
          this.ngForm.form.reset();
          this.showErrorMsg(error);
        },
      });
  }

  showUploadedMsg() {
    this.growlService.notice('common.fileUploadedMessage');
    this.inProgress = false;
  }

  showErrorMsg(error: BackendError) {
    this.errorOccured.emit(error);
    this.growlService.error('common.fileUploadErrorMessage');
    this.inProgress = false;
  }
}
