import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  AppConfigService,
  BackendError,
  CessionVersionCriteriaDto,
  CessionVersionDto,
  CompanyBaseDto,
  DictionaryBaseDto,
  LocalDateRange,
  PolicyCessionVersionService,
  RouterService,
  SearchDataProvider,
} from '../../bonding_shared';
import {PolicyCessionGuiService} from './services/policy-cession-gui.service';
import {
  BusinessObjectType,
  CessionType,
  PolicyElementaryRight,
  ServiceContactElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'policy-cession-preview',
  templateUrl: 'policy-cession-preview.component.pug',
})
export class PolicyCessionPreviewComponent implements OnInit {
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  readonly PolicyElementaryRight = PolicyElementaryRight;

  cessionVersionDataProvider: SearchDataProvider<CessionVersionCriteriaDto, CessionVersionDto>;
  cessionVersion: CessionVersionDto;
  errors: BackendError;
  objectNotFound = false;

  constructor(
    private cessionVersionService: PolicyCessionVersionService,
    private cessionGuiService: PolicyCessionGuiService,
    private route: ActivatedRoute,
    public router: RouterService,
    public appService: AppConfigService
  ) {}

  get mehib(): boolean {
    return this.appService.mehib;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const id = params['id'];
    this.loadCession(id);
  }

  initializeData(cessionVersionId: number) {
    this.createCessionDataProvider(cessionVersionId);
  }

  loadCession(cessionVersionId: number) {
    this.cessionVersionService.getCessionVersion(cessionVersionId).subscribe(
      (c) => {
        this.cessionVersion = c;
      },
      (error) => (this.errors = error)
    );
  }

  createCessionDataProvider(cessionVersionId: number) {
    this.cessionVersionDataProvider = new SearchDataProvider<CessionVersionCriteriaDto, CessionVersionDto>(
      this.cessionVersionService
    );
    this.cessionVersionDataProvider.textSearch = false;
    this.cessionVersionDataProvider.searchCriteria.criteria = <CessionVersionCriteriaDto>{};
    this.cessionVersionDataProvider.searchCriteria.criteria.cession.type = <DictionaryBaseDto>{};
    this.cessionVersionDataProvider.searchCriteria.criteria.cession.assignee = <CompanyBaseDto>{};
    this.cessionVersionDataProvider.searchCriteria.criteria.cessionValidFromRange = <LocalDateRange>{};
    this.cessionVersionDataProvider.searchCriteria.criteria.cessionValidToRange = <LocalDateRange>{};
  }

  toServiceContacts() {
    this.router.toServiceContacts({boTypeId: BusinessObjectType.CESSION, boId: this.cessionVersion.cession.id});
  }

  get showFullDetails(): boolean {
    return (
      this.cessionVersion &&
      this.cessionVersion.cession.type &&
      this.cessionVersion.cession.type.id &&
      this.cessionVersion.cession.type.id !== CessionType.CHANGE_OF_THE_MAIN_INSURED
    );
  }
}
