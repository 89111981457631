import {Injectable} from '@angular/core';
import {BusinessLogCriteriaDto, BusinessLogDto} from '../../../bonding_shared/model';
import {BusinessLogService, SearchDataProvider} from '../../../bonding_shared/services';

@Injectable()
export class BusinessLogGuiService {
  /**
   * Keeps selected criteria for business log search view
   */
  searchDataProvider: SearchDataProvider<BusinessLogCriteriaDto, BusinessLogDto>;

  constructor(private businessLogService: BusinessLogService) {
    this.searchDataProvider = new SearchDataProvider<BusinessLogCriteriaDto, BusinessLogDto>(businessLogService);
  }

  createDataProvider(): SearchDataProvider<BusinessLogCriteriaDto, BusinessLogDto> {
    return new SearchDataProvider<BusinessLogCriteriaDto, BusinessLogDto>(this.businessLogService);
  }
}
