import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ReportExecutionSearchComponent} from './report-execution-search.component';
import {ReportExecutionSearchGuard} from './_guards';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
  declarations: [ReportExecutionSearchComponent],
  exports: [],
  providers: [ReportExecutionSearchGuard],
})
export class ReportExecutionModule {}
