import {Observable, timer as observableTimer} from 'rxjs';

import {map} from 'rxjs/operators';

export class SessionTimer {
  public sessionTimerObservable: Observable<number>;

  private readonly defaultSessionTime: number = 30 * 60; // in seconds
  private remainingSessionTime: number = this.defaultSessionTime;

  constructor(initialSessionTime: number) {
    this.remainingSessionTime = initialSessionTime;
    this.sessionTimerObservable = observableTimer(0, 1000).pipe(
      map((t) => {
        return --this.remainingSessionTime;
      })
    );
  }

  reset(amount?: number) {
    this.remainingSessionTime = amount ? amount : this.defaultSessionTime;
  }
}
