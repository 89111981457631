import {Component} from '@angular/core';
import {CompanySimpleDto, DictionaryBaseDto, PolicyContractCompanyDto} from '../../../../../bonding_shared/model';
import {Section} from '../section';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {AppConfigService, RouterService} from '../../../../../bonding_shared/services';

@Component({
  selector: 'collection-companies-section',
  templateUrl: './collection-companies-section.component.pug',
})
export class CollectionCompaniesSectionComponent extends Section {
  self = this;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    public router: RouterService
  ) {
    super(policyContractViewService, appService);
  }

  addCollectionCompany(company: CompanySimpleDto) {
    this.policy.collectionCompanies.push(this.collectionCompaniesFactory(company));
  }

  private collectionCompaniesFactory: any = (company: CompanySimpleDto) => {
    return <PolicyContractCompanyDto>{
      role: <DictionaryBaseDto>{id: 28000005, name: 'Collection company'},
      company: company,
    };
  };
}
