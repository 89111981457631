import {Component, Input, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  CompanyBaseDto,
  PolicyExporterDto,
  RouterService,
} from '../../../../../bonding_shared';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';

@Component({
  selector: 'exporters-section',
  templateUrl: './exporters-section.component.pug',
})
export class ExportersSectionComponent extends Section {
  @ViewChild('exportersList', {static: true}) exportersList: ATableComponent<PolicyExporterDto>;
  self = this;

  @Input() showSelected = false;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    public router: RouterService
  ) {
    super(policyContractViewService, appService);
  }

  addPolicyExporter(company: CompanyBaseDto) {
    this.exportersList.onAddItem(<PolicyExporterDto>{company: company, selected: false});
  }
}
