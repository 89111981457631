import {Injectable} from '@angular/core';
import {AbstractService} from '../../bonding_shared/services/abstract.service';
import {ChangePasswordRequestDto, ForgotPasswordRequestDto} from '../../bonding_shared/model/dtos';
import {AppConfigService} from '../../bonding_shared/services/app-config.service';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PasswordResetService extends AbstractService {
  protected url = this.urlPrefix + 'passwordReset';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  remindPassword(dto: ForgotPasswordRequestDto) {
    return this.post<ForgotPasswordRequestDto>(dto, this.url + '/portal');
  }

  remindCesarPassword(dto: ForgotPasswordRequestDto) {
    return this.post<ForgotPasswordRequestDto>(dto, this.url + '/intranet');
  }

  resetPassword(dto: ChangePasswordRequestDto) {
    return this.post<ChangePasswordRequestDto>(dto, this.url + '/change');
  }
}
