import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {ClientCompanyBaseDto, ClientCompanyDto, ThirdPartyBaseDto, ThirdPartyDto} from '../model';
import {Observable} from 'rxjs';

@Injectable()
export class ClientCompanyService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'clientCompany';
  }

  getClientCompany(clientId: number, companyId: number): Observable<ClientCompanyBaseDto> {
    const clientParam = !this.loggedUserService.portal && clientId ? '&clientId=' + clientId : '';
    return this.get(this.url + '/clientCompanyNumber?companyId=' + companyId + clientParam);
  }

  getInitialClientCompany(companyId: number): Observable<ClientCompanyDto> {
    return this.get(this.url + '/initialClientCompany/' + companyId);
  }
}
