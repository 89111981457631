import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  AppConfigService,
  BondVersionService,
  FeatureService,
  RouterService,
  SearchDataProvider,
} from '../../bonding_shared/services';
import {BondVersionCriteriaDto, BondVersionDto, BondVersionSimpleDto, ErrorReason} from '../../bonding_shared/model';
import {BondType, ContractStatus, Feature} from '../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';
import {ComboItem} from '../../bonding_shared/components/combos';
import {SearchViewComponent} from '../../bonding_shared/components/search';
import {PortalContractVersionService, PortalRouterService} from '../services';
import {ClientBondGuiService} from './services';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';

@Component({
  selector: 'clientbond-version-search',
  templateUrl: './clientbond-version-search.component.pug',
})
export class ClientBondVersionSearchComponent implements OnInit {
  @ViewChild(SearchViewComponent)
  set searchView(cmp: SearchViewComponent<any, any>) {
    if (cmp) {
      this._searchView = cmp;
      this._searchView.newButton = this.contractActive;
    }
  }

  get searchView(): SearchViewComponent<any, any> {
    return this._searchView;
  }
  _searchView: SearchViewComponent<any, any>;

  dataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionDto>;
  versionPhases: ComboItem[];
  contractVersionId: number;
  hiddenBondTypes: Set<number> = new Set<number>([
    BondType.ZOLL,
    BondType.ARBEITZEITKONTEN,
    BondType.ABFALLVERBRINGUNG,
    BondType.REKULTIVIERUNG,
    BondType.VERBRAUCHERSICHERHEIT,
  ]);
  showNewSpecialBondButton: boolean;
  private contractActive: boolean;

  constructor(
    private featureService: FeatureService,
    private route: ActivatedRoute,
    public portalRouter: PortalRouterService,
    public router: RouterService,
    private service: ClientBondGuiService,
    private bondService: BondVersionService,
    public appService: AppConfigService,
    translateService: TranslateService,
    private portalContractService: PortalContractVersionService
  ) {
    this.versionPhases = [
      new ComboItem('ACTIVE', translateService.instant('common.current')),
      new ComboItem('LAST', translateService.instant('common.last')),
    ];
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.contractVersionId = +params['contractVersionId'];
    this.portalContractService.getClientContract(this.contractVersionId).subscribe({
      next: (cv) => {
        this.contractActive = cv.businessStatus.id === ContractStatus.CONTRACT_ACTIVE;
        this.showNewSpecialBondButton = this.contractActive && PortalContractVersionService.isContractBooster(cv);
      },
      error: (err) => (this.showNewSpecialBondButton = false),
    });
    this.service.getSearchDataProviderForContractVersion(this.contractVersionId).subscribe((dp) => {
      this.dataProvider = dp;
      setTimeout(() => this.searchView.search());
    });
  }

  isRequest(bondVersion: BondVersionSimpleDto) {
    return BusinessUtils.isRequest(bondVersion.status.id);
  }

  onClickBeneficiaryName(bond: BondVersionSimpleDto) {
    this.router.toCompanyPreview(bond.beneficiaryId);
  }

  onCompanyClick(bond: BondVersionSimpleDto) {
    this.portalRouter.toClientContractPreview(bond.contractVersionId);
  }

  onNewClick(special?: boolean) {
    if (this.featureService.get(Feature.INTRANET_BOND_DETAILS_IN_PORTAL)) {
      this.portalRouter.toBondDetailsIntraStyle(0);
    } else {
      this.portalRouter.toClientBondDetailsNew(this.contractVersionId, special);
    }
  }

  export() {
    this.dataProvider.inProgress = true;
    this.bondService.export(
      this.dataProvider.searchCriteria.criteria,
      'bonds.xlsx',
      (errorMsg) => this.downloadErrorCallback(errorMsg),
      () => {
        this.searchView.serverErrors = null;
        this.dataProvider.inProgress = false;
      }
    );
  }

  private downloadErrorCallback(err: string): void {
    const error = <ErrorReason[]>JSON.parse(err);
    console.log('Bond export error', error);
    this.searchView.serverErrors = error;
    this.dataProvider.inProgress = false;
  }
}
