import {
  BondingContractInquiryVersionSimpleDto,
  BusinessObjectDto,
  BusObjBondVersionRefDto,
  BusObjContractVersionRefDto,
  BusObjMasterPolicyRefDto,
  BusObjPolicyInquiryRefDto,
  BusObjPolicyVersionRefDto,
  BusObjRefDto,
  CompanySimpleDto,
  ContractBaseDto,
  ContractVersionSimpleDto,
  PolicyContractBaseDto,
  PolicyContractVersionSimpleDto,
  PolicyInquiryVersionSimpleDto,
} from '../model';
import {BusinessObjectType} from '../model/dictionary-ids';

export class BusinessObjectUtils {
  static businessObjectToPolicyVersion(bo: BusinessObjectDto): PolicyContractVersionSimpleDto {
    const policyVersion = <PolicyContractVersionSimpleDto>{};
    policyVersion.policyContract = <PolicyContractBaseDto>{};
    policyVersion.policyContract.id = bo.relatedToId;
    policyVersion.policyContract.number = bo.description;
    policyVersion.policyContract.policyYear = (<BusObjPolicyVersionRefDto>bo.reference).policyYear;
    policyVersion.contractType = (<BusObjPolicyVersionRefDto>bo.reference).type;
    policyVersion.client = <CompanySimpleDto>{};
    policyVersion.client.id = (<BusObjPolicyVersionRefDto>bo.reference).companyId;
    return policyVersion;
  }

  static businessObjectToContractVersion(bo: BusinessObjectDto): ContractVersionSimpleDto {
    const contractVersion = <ContractVersionSimpleDto>{};
    contractVersion.contract = <ContractBaseDto>{};
    contractVersion.contract.number = bo.description;
    contractVersion.contract.id = bo.relatedToId;
    contractVersion.client = <CompanySimpleDto>{};
    contractVersion.client.id = (<BusObjContractVersionRefDto>bo.reference).companyId;
    return contractVersion;
  }

  static policyVersionToMasterPolicyRef(pcv: PolicyContractVersionSimpleDto): BusObjMasterPolicyRefDto {
    const ref = <BusObjMasterPolicyRefDto>{};
    ref.masterPolicyId = pcv.policyContract.masterPolicyContract.id;
    ref.lastPolicyVersionType = pcv.policyContract.type;
    ref.policyNumber = pcv.policyContract.number;
    ref.companyId = pcv.client.id;
    return ref;
  }

  static contractVersionToRef(cv: ContractVersionSimpleDto): BusObjContractVersionRefDto {
    const ref = <BusObjContractVersionRefDto>{};
    ref.contractId = cv.contract.id;
    ref.contractNumber = cv.contract.number;
    ref.companyId = cv.client.id;
    return ref;
  }

  static policyInquiryVersionToRef(cv: PolicyInquiryVersionSimpleDto): BusObjPolicyInquiryRefDto {
    const ref = <BusObjPolicyInquiryRefDto>{};
    ref.id = cv.id;
    ref.number = cv.policyInquiry.number;
    ref.companyId = cv.client.company.id;
    ref.registrationName = cv.client.company.registrationName;
    ref.status = cv.status;
    ref.type = cv.productType;
    return ref;
  }

  static bondingContractInquiryVersionToRef(bciv: BondingContractInquiryVersionSimpleDto): BusObjRefDto {
    const ref = <BusObjRefDto>{};
    ref.id = bciv.id;
    ref.number = bciv.inquiry.number;
    ref.companyId = bciv.inquiry.client.company.id;
    ref.registrationName = bciv.inquiry.client.company.registrationName;
    return ref;
  }

  static getNumber(businessObject: BusinessObjectDto): string {
    if (!businessObject || !businessObject.relatedTo) {
      return '';
    }
    switch (businessObject.relatedTo.id) {
      case BusinessObjectType.BOND:
      case BusinessObjectType.BOND_VERSION:
        return (<BusObjBondVersionRefDto>businessObject.reference).bondNumber;

      case BusinessObjectType.CONTRACT:
      case BusinessObjectType.CONTRACT_VERSION:
        return (<BusObjContractVersionRefDto>businessObject.reference).contractNumber;

      default:
        return businessObject.description;
    }
  }

  static getKukeBoRegexp(): RegExp {
    return /^(?:brokerContract|contract|bondingContractInquiry|BondingContractOffer|bond|masterPolicy|policyInquiry|offer|edgeOffer|limit|limitRequest|claim|collection|collectionRequest|company|invoice|user|contactNote|bonusRequest|malusRequest|cession|thirdParty)$/;
  }

  static getCredendoBoRegexp(): RegExp {
    return undefined;
  }

  static equals(b1: BusinessObjectDto, b2: BusinessObjectDto): boolean {
    return b1 && b2 && b1.relatedToId === b2.relatedToId && b1.relatedTo.id === b2.relatedTo.id;
  }
}
