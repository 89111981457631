import {Component, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {ControlContainer, UntypedFormGroup, NgForm} from '@angular/forms';
import {BondPaymentDto, DictionaryBaseDto} from '../../../bonding_shared/model/dtos';
import {AppProperty, Currency} from '../../../bonding_shared/model/dictionary-ids';
import {
  BusinessPropertiesService,
  CurrencyExchangeRateService,
  PropertyService,
} from '../../../bonding_shared/services';
import {ATableComponent} from '../../../bonding_shared/components/aku-table';

@Component({
  selector: 'bond-payment-list',
  templateUrl: 'bond-payment-list.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondPaymentListComponent {
  @ViewChild(ATableComponent) bondPaymentsTable: ATableComponent<BondPaymentDto>;

  @Input() showErrors = false;
  @Input() values: BondPaymentDto[];
  @Input() bondCurrency;
  @Input() listName;

  @Output() addUpdateDelete = new EventEmitter();

  readonly Currency = Currency;
  readonly SYSTEM_CURRENCY_CODE = this.propertyService.properties[AppProperty.SYSTEM_CURRENCY];
  self = this;

  constructor(
    public form: NgForm,
    public businessProperties: BusinessPropertiesService,
    private propertyService: PropertyService,
    private currencyExchangeRateService: CurrencyExchangeRateService
  ) {}

  valueChange(v: BondPaymentDto) {
    this.updateValueInSysCurr(v);
  }

  updateValueInSysCurr(v: BondPaymentDto) {
    v.amountInSysCurr = this.isSysCurr(this.bondCurrency)
      ? v.amount
      : this.currencyExchangeRateService.calculate(v.amount, this.bondCurrency.id);
    const idx = this.bondPaymentsTable.getItemIndex(v);
    const controlIdx = 3;
    (<UntypedFormGroup>(<UntypedFormGroup>this.form.control.controls[this.listName]).controls[idx]).controls[
      controlIdx
    ].setValue(v.amountInSysCurr);
  }

  updateAllValuesInSysCurr() {
    this.values.forEach((v) => this.updateValueInSysCurr(v));
  }

  onAddUpdateDelete() {
    this.addUpdateDelete.emit();
  }

  isSysCurr(currency: DictionaryBaseDto) {
    return currency.code === this.SYSTEM_CURRENCY_CODE;
  }
}
