import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AbstractService, AppConfigService, LoggedUserService} from '../../bonding_shared/services';
import {ServiceNoteDto} from '../../bonding_shared/model';

@Injectable()
export class ServiceNoteService extends AbstractService {
  protected url = this.urlPrefix + 'serviceNote';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  createServiceNote(contact: ServiceNoteDto): Observable<ServiceNoteDto> {
    console.log('crete contact note:' + JSON.stringify(contact));
    return this.put<ServiceNoteDto>(contact, this.url);
  }
}
