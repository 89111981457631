import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {
  BICompanyOrOrderResponse,
  BIOrderReportButtonOption,
  BIOrderRequest,
  BIReportTimely,
  CompanyDto,
  DictionaryBaseDto,
} from '../model';
import {Observable} from 'rxjs';
import {Cacheable} from 'ts-cacheable';
import {BISource} from '../model/dictionary-ids';
import {Injectable} from '@angular/core';

@Injectable()
export class BiService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'bi';
  }

  @Cacheable({maxCacheCount: 10})
  getPossibleBI(countryId: number): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/' + countryId + '/possibleBIs');
  }

  getCompany(externalId: string, biSourceId: BISource): Observable<CompanyDto> {
    return this.get<CompanyDto>(this.url + '/company/' + externalId + '&biSourceId=' + biSourceId);
  }

  getOrOrderCompany(biOrderRequest: BIOrderRequest, biSourceId: BISource): Observable<BICompanyOrOrderResponse> {
    return this.post1<BIOrderRequest, BICompanyOrOrderResponse>(
      biOrderRequest,
      this.url + '/companyOrOrder?biSourceId=' + biSourceId
    );
  }

  getReportTimeliness(company: CompanyDto): Observable<BIReportTimely[]> {
    return this.post1<CompanyDto, BIReportTimely[]>(company, this.url + '/reportTimeliness');
  }

  loadExternalId(biSourceId: number, companyId: number): Observable<CompanyDto> {
    return this.get<CompanyDto>(this.url + '/loadExternalId?biSourceId=' + biSourceId + '&companyId=' + companyId);
  }

  getOrderReportButtonOptions(companyId: number): Observable<BIOrderReportButtonOption[]> {
    return this.get<BIOrderReportButtonOption[]>(this.url + '/orderReportButtonOptions?companyId=' + companyId);
  }

  @Cacheable({maxCacheCount: 10})
  getOrderReportButtonOptionsForCountry(countryId: number): Observable<BIOrderReportButtonOption[]> {
    return this.get<BIOrderReportButtonOption[]>(this.url + '/orderReportButtonOptions?countryId=' + countryId);
  }
}
