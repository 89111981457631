import {Component, Input} from '@angular/core';
import {PolicyContractPreviewDto} from '../../../../bonding_shared/model';
import {AppConfigService} from '../../../../bonding_shared';

@Component({
  selector: 'policy-contract-info',
  templateUrl: './policy-contract-info.component.pug',
})
export class PolicyContractInfoComponent {
  @Input()
  data: PolicyContractPreviewDto;
  @Input()
  longLabel: boolean;

  constructor(private appService: AppConfigService) {}

  mehib() {
    return this.appService.mehib;
  }
}
