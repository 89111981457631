import {InvoiceSearchComponent} from './invoice-search.component';
import {InvoiceDetailsComponent} from './invoice-details.component';

export const InvoiceRoutes = [
  {
    path: 'invoice-search/:category',
    component: InvoiceSearchComponent,
  },
  {
    path: 'invoice-search/:category/:businessObjectTypeId/:businessObjectId',
    component: InvoiceSearchComponent,
  },
  {
    path: 'invoice-details/:category/:id',
    component: InvoiceDetailsComponent,
  },
  {
    path: 'invoice-details/:category/:id/:correctedId/:nullify/:newItemStatusId/:newClientId/:hideSpecification',
    component: InvoiceDetailsComponent,
  },
];
