import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BinaryDownloaderService} from '../../../../../src/client/app/bonding_shared/services/index';
import {AppConfigService} from '../../../../../src/client/app/bonding_shared/services/app-config.service';
import {TranslateService} from '@ngx-translate/core';
import {BondVerifyService} from './bond-verify.service';
import {BondVerifyResultDto} from '../../../../../src/client/app/bonding_shared';

@Component({
  selector: 'bond-verify',
  templateUrl: 'bond-verify.component.html',
})
export class BondVerifyComponent implements OnInit {
  public bondNumber: string;
  public code: string;
  public errorMessage: string;
  pending = false;
  public result: BondVerifyResultDto = null;

  constructor(
    public _router: Router,
    public appConfigService: AppConfigService,
    private translateService: TranslateService,
    private bondVerifyService: BondVerifyService,
    private binaryDownloader: BinaryDownloaderService
  ) {}

  ngOnInit() {}

  doVerify() {
    this.pending = true;
    this.bondVerifyService.verify(this.bondNumber, this.code).subscribe({
      next: (u) => {
        this.errorMessage = '';
        this.result = u;
        this.pending = false;
      },
      error: () => {
        this.pending = false;
        this.handleError(`The ${this.bondNumber} is not present in Credendo system`);
      },
    });
  }

  private handleError(error: any) {
    console.error('error = ', error);
    this.errorMessage = error;
  }

  doDownload() {
    this.pending = true;
    this.bondVerifyService.downloadLink(this.bondNumber, this.code).subscribe({
      next: (link) => {
        console.log('bond verification link', link);

        if (!link) {
          this.handleError(`Certificate empty link.`);
          return;
        }
        const fileName = 'bond-cert-' + link.substring(link.lastIndexOf('/') + 1);
        this.binaryDownloader.download(
          'GET',
          link,
          'application/json',
          'application/octet-stream',
          fileName,
          undefined,
          (_) => this.handleError(`Certificate download failed. <br> Link: ${link}`)
        );
        this.pending = false;
      },
      error: () => this.handleError(`The certificate for ${this.bondNumber} is not present in Credendo system`),
    });
  }
}
