import {Component, forwardRef, Input} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {ClaimAdditionalInformationDto, DictionaryBaseDto} from '../../../bonding_shared';
import {ClaimRiskType, Currency} from '../../../bonding_shared/model/dictionary-ids';

const KUKE_CLAIM_ADDITINAL_INFORMATION_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => KukeClaimAdditionalInformationComponent),
  multi: true,
};
const KUKE_CLAIM_ADDITINAL_INFORMATION_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => KukeClaimAdditionalInformationComponent),
  multi: true,
};

@Component({
  selector: 'kuke-claim-additional-information',
  templateUrl: 'kuke-claim-additional-information.component.pug',
  providers: [KUKE_CLAIM_ADDITINAL_INFORMATION_CONTROL_VALUE_ACCESSOR, KUKE_CLAIM_ADDITINAL_INFORMATION_VALIDATOR],
})
export class KukeClaimAdditionalInformationComponent implements ControlValueAccessor, Validator {
  readonly calculationCurrency: number = Currency.PLN;

  @Input()
  presentationMode = false;
  @Input()
  showErrors = false;

  formGroup: UntypedFormGroup;
  controlVisibilityMatrix: {[key: string]: boolean} = {};
  @Input()
  isInitialVersionCreation = false;

  constructor(formBuilder: UntypedFormBuilder) {
    this.formGroup = formBuilder.group({
      bankAccountHolder: [''],
      bankAccountNumber: [''],
      bankAccountSwiftCode: [''],
      cession: [''],
      cessionAssignee: [''],
      discretionaryCounterparty: [''],
      creditLimitAmount: [''],
      thirdPartyInvolved: [''],
      thirdPartyName: [''],
      reportedBy: [''],
      prodRiskTotalClaimAmount: [''],
      prodRiskTotalClaimAmountCurrency: [{value: this.calculationCurrency, disabled: true}],
      prodRiskDueClaimAmount: [''],
      prodRiskDueClaimAmountCurrency: [{value: this.calculationCurrency, disabled: true}],
      prodRiskCreditInsuranceCollectionExists: [''],
      prodRiskCreditInsuranceCollectionNumber: [''],
      bondRequestForPaymentDocument: [''],
      lossPreventionActionInfo: [''],
      collateralInfo: [''],
    });

    this.formGroup.controls.discretionaryCounterparty.valueChanges.subscribe((value) => {
      const creditLimitAmountCtrl = this.formGroup.controls.creditLimitAmount;
      if (value) {
        creditLimitAmountCtrl.setValidators([Validators.required]);
      } else {
        creditLimitAmountCtrl.clearValidators();
        creditLimitAmountCtrl.patchValue(null);
      }
      creditLimitAmountCtrl.updateValueAndValidity();
    });
    this.formGroup.controls.thirdPartyInvolved.valueChanges.subscribe((value) => {
      const thirdPartyNameCtrl = this.formGroup.controls.thirdPartyName;
      if (value) {
        thirdPartyNameCtrl.setValidators([Validators.required]);
      } else {
        thirdPartyNameCtrl.clearValidators();
        thirdPartyNameCtrl.patchValue(null);
      }
      thirdPartyNameCtrl.updateValueAndValidity();
    });

    this.controlVisibilityMatrix = {
      bankAccountHolder: true,
      bankAccountNumber: true,
      bankAccountSwiftCode: true,
      cession: true,
      cessionAssignee: true,
      discretionaryCounterparty: true,
      creditLimitAmount: true,
      thirdPartyInvolved: true,
      thirdPartyName: true,
      reportedBy: true,
      prodRiskTotalClaimAmount: true,
      prodRiskTotalClaimAmountCurrency: true,
      prodRiskDueClaimAmount: true,
      prodRiskDueClaimAmountCurrency: true,
      prodRiskCreditInsuranceCollectionExists: true,
      prodRiskCreditInsuranceCollectionNumber: true,
      bondRequestForPaymentDocument: true,
      lossPreventionActionInfo: true,
      collateralInfo: true,
    };
  }

  @Input()
  set claimRiskType(claimRiskType: DictionaryBaseDto) {
    switch (claimRiskType?.id) {
      case ClaimRiskType.CREDIT_INSURANCE:
        this.controlVisibilityMatrix['cessionAssignee'] = false;
        this.controlVisibilityMatrix['discretionaryCounterparty'] = false;
        this.controlVisibilityMatrix['creditLimitAmount'] = false;
        this.controlVisibilityMatrix['thirdPartyInvolved'] = false;
        this.controlVisibilityMatrix['thirdPartyName'] = false;
        this.controlVisibilityMatrix['bankAccountNumber'] = false;
        this.controlVisibilityMatrix['bankAccountSwiftCode'] = false;
        this.controlVisibilityMatrix['bankAccountHolder'] = false;
        this.controlVisibilityMatrix['reportedBy'] = false;
        break;
      case ClaimRiskType.PROD_RISK:
        this.controlVisibilityMatrix['cessionAssignee'] = false;
        this.controlVisibilityMatrix['bankAccountNumber'] = false;
        this.controlVisibilityMatrix['bankAccountSwiftCode'] = false;
        this.controlVisibilityMatrix['bankAccountHolder'] = false;
        this.controlVisibilityMatrix['reportedBy'] = false;
        this.controlVisibilityMatrix['prodRiskTotalClaimAmount'] = false;
        this.controlVisibilityMatrix['prodRiskTotalClaimAmountCurrency'] = false;
        this.controlVisibilityMatrix['prodRiskDueClaimAmount'] = false;
        this.controlVisibilityMatrix['prodRiskDueClaimAmountCurrency'] = false;
        this.controlVisibilityMatrix['prodRiskCreditInsuranceCollectionExists'] = false;
        this.controlVisibilityMatrix['prodRiskCreditInsuranceCollectionNumber'] = false;
        this.controlVisibilityMatrix['lossPreventionActionInfo'] = false;
        this.controlVisibilityMatrix['collateralInfo'] = false;
        break;
      case ClaimRiskType.SURETY:
        this.controlVisibilityMatrix['bondRequestForPaymentDocument'] = false;
        this.controlVisibilityMatrix['bankAccountNumber'] = false;
        this.controlVisibilityMatrix['bankAccountSwiftCode'] = false;
        this.controlVisibilityMatrix['bankAccountHolder'] = false;
        this.controlVisibilityMatrix['cession'] = false;
        this.controlVisibilityMatrix['reportedBy'] = false;
        break;
      default:
        console.log('Cannot build claim additional information form. Unknown claim risk type: ' + claimRiskType?.id);
        break;
    }
  }

  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe((value) => {
      const {
        discretionaryCounterparty,
        thirdPartyInvolved,
        prodRiskCreditInsuranceCollectionExists,
        ...changedAdditionalInformation
      } = value;
      fn(changedAdditionalInformation);
    });
  }

  registerOnTouched(fn: any): void {}

  writeValue(additionalInformationDto: ClaimAdditionalInformationDto): void {
    if (additionalInformationDto) {
      const formValue = {
        ...additionalInformationDto,
        discretionaryCounterparty: !!additionalInformationDto.creditLimitAmount,
        thirdPartyInvolved: !!additionalInformationDto.thirdPartyName,
        prodRiskCreditInsuranceCollectionExists: !!additionalInformationDto.prodRiskCreditInsuranceCollectionNumber,
        bondRequestForPaymentDocument:
          this.claimRiskType?.id === ClaimRiskType.SURETY
            ? additionalInformationDto.bondRequestForPaymentDocument
            : null,
      };
      this.formGroup.patchValue(formValue);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.formGroup.valid ? null : {additionalInformationNotValid: true};
  }
}
