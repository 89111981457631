import {Component, Input} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {
  DictionaryBaseDto,
  DictionaryBaseService,
  FormatService,
  QuotationBondDto,
  QuotationDto,
  QuotationLoanDto,
} from '../../../../../bonding_shared';
import {NumberUtils} from '../../../../../bonding_shared/utils/number-utils';
import {QuotationLoanType} from '../../../../../bonding_shared/model/dictionary-ids';
import {DateUtils} from '../../../../../bonding_shared/utils/date-utils';

@Component({
  selector: 'bank-quotation-contract-data',
  templateUrl: './bank-quotation-contract-data.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BankQuotationContractDataComponent {
  readonly REVOLVING_MAXIMUM_PERIOD = 36;
  readonly MINIMUM_PERIOD = 6;
  readonly MAXIMUM_PERIOD = 120;
  readonly SECURE_VALID_FROM_MIN_DATE = DateUtils.addDay(new Date(), -1);

  @Input() showFormErrors = true;
  @Input() disabled = false;

  @Input() set quotation(quotation: QuotationDto) {
    this._quotation = quotation;
    this.recalculateNotSecuredAmount();
  }

  get quotation(): QuotationDto {
    return this._quotation;
  }

  private _quotation: QuotationDto;

  public notSecuredAmount;

  get quotationLoan(): QuotationLoanDto {
    return this.quotation.loans[0];
  }

  constructor(protected dictionaryService: DictionaryBaseService, private formatService: FormatService) {}

  recalculateNotSecuredAmount() {
    this.notSecuredAmount = Math.max(NumberUtils.minus(this.quotationLoan.amount, this.quotation.collateral), 0);
  }

  recalculateNotSecuredAndSecuredAmount() {
    this.recalculateNotSecuredAmount();
    this.quotation.contractMaxTotalLiability = this.notSecuredAmount / 2;
    this.securedAmountChanged();
  }

  existsRevolvingLoan(): boolean {
    return (
      this.quotation &&
      this.quotation.loans &&
      this.quotation.loans.filter((loan) => loan.type && loan.type.id === QuotationLoanType.REVOLVING).length > 0
    );
  }

  get quotationBond(): QuotationBondDto {
    const bondTypes: QuotationBondDto[] = this.quotation.contractBondTypes;
    if (bondTypes && bondTypes.length > 0) {
      return <QuotationBondDto>bondTypes[0];
    }
    return undefined;
  }

  securedAmountChanged() {
    if (this.quotation.contractMaxTotalLiability) {
      this.quotationBond.maxBondLiability = this.quotation.contractMaxTotalLiability;
      this.quotationBond.maxSingleBond = this.quotation.contractMaxTotalLiability;
    }
  }

  onPeriodChange() {
    this.actualizeDates();
  }

  onQuotationLoanChange(type: DictionaryBaseDto) {
    this.quotationLoan.period =
      type.id === QuotationLoanType.REVOLVING ? this.REVOLVING_MAXIMUM_PERIOD : this.MAXIMUM_PERIOD;
  }

  onValidFromChanged(validFrom: Date) {
    this.quotation.contractValidFrom = validFrom;
    if (!validFrom) {
      this.quotation.contractValidTo = null;
    } else {
      this.actualizeDates();
    }
  }

  actualizeDates() {
    if (this.quotation.contractValidFrom) {
      if (!this.quotationLoan.period) {
        this.quotationLoan.period = this.MINIMUM_PERIOD;
      }
      this.quotation.contractValidTo = this.formatService.cutTimeZone(
        DateUtils.addMonth(this.quotation.contractValidFrom, this.quotationLoan.period)
      );
    }
  }
  isAnnexQuotation() {
    return this.quotation.contract && this.quotation.contract.id > 0;
  }
}
