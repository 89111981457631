import {ReportSearchComponent} from './report-search.component';
import {ReportDetailsComponent} from './report-details.component';

export const ReportRoutes = [
  {
    path: 'report-search',
    component: ReportSearchComponent,
  },
  {
    path: 'report-details/:id',
    component: ReportDetailsComponent,
  },
];
