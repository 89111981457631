import {Component} from '@angular/core';
import {KukePolicyProduct} from '../kuke-policy-product';
import {ControlContainer, NgForm} from '@angular/forms';
import {PolicyContractVersionService} from '../../../../../../bonding_shared/services';

@Component({
  selector: 'kom-fe-product',
  templateUrl: './kom-fe-product.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KomFeProductComponent extends KukePolicyProduct {
  constructor(policyContractVersionService: PolicyContractVersionService) {
    super(policyContractVersionService);
  }
}
