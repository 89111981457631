import {zip as observableZip} from 'rxjs';
import {Inject, Injectable, Optional} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GROWL_CONFIG_TOKEN, GrowlConfig} from './growl.config';

@Injectable()
export class GrowlService {
  constructor(
    @Inject(GROWL_CONFIG_TOKEN) @Optional() private config: GrowlConfig,
    private translateService: TranslateService
  ) {}

  public error(msg: string, title?: string, interpolateParams?: Object) {
    this.show(GrowlType.ERROR, msg, title, interpolateParams);
  }
  public notice(msg: string, title?: string, interpolateParams?: Object) {
    this.show(GrowlType.NOTICE, msg, title, interpolateParams);
  }
  public warning(msg: string, title?: string, interpolateParams?: Object) {
    this.show(GrowlType.WARNING, msg, title, interpolateParams);
  }

  public show(growlType: GrowlType, msgKey: string, titleKey?: string, interpolateParams?: Object) {
    console.log('showGrowl:', growlType, msgKey);
    observableZip(
      this.translateService.get(msgKey, interpolateParams),
      this.translateService.get(titleKey ? titleKey : this.title(growlType)),
      (msg: string, tit: string) => ({
        msg,
        tit,
      })
    ).subscribe((translation) => {
      (<any>$).growl[this.type(growlType)]({
        title: translation.tit,
        message: translation.msg,
        location: this.config?.location || 'br',
      });
    });
  }

  title(type: GrowlType): string {
    switch (type) {
      case GrowlType.WARNING:
        return 'Warning!';
      case GrowlType.NOTICE:
        return 'Success!';
      case GrowlType.ERROR:
        return 'Error!';
      default:
        return '';
    }
  }
  type(type: GrowlType): string {
    switch (GrowlType[type]) {
      case GrowlType[GrowlType.WARNING]:
        return 'warning';
      case GrowlType[GrowlType.NOTICE]:
        return 'notice';
      case GrowlType[GrowlType.ERROR]:
        return 'error';
      default:
        return '';
    }
  }
}

export enum GrowlType {
  WARNING,
  NOTICE,
  ERROR,
}
