import {Router} from '@angular/router';
import {BusinessObjectType, CollectionType} from '../bonding_shared/model/dictionary-ids';
import {CollectionVersionService, LimitService, View} from '../bonding_shared/services';
import {CollectionInitialParams} from '../bonding/collection/collection-details.component';
import {ClaimInitialParams} from '../bonding/claim/components/claim-selectors.component';
import {ClientCompanyViewParams} from '../bonding/client-company/components/client-company-details.component';
import {LimitViewParams} from '../bonding/limit/limit-details.component';

export abstract class AbstractRouterService {
  protected constructor(
    protected _router: Router,
    protected collectionVersionService: CollectionVersionService,
    protected limitService: LimitService
  ) {}

  public readonly CollectionType = CollectionType;

  goBack() {
    window.history.back();
  }

  // COLLECTION
  toCollectionSearch() {
    this._router.navigate(['bon/collection-search']);
  }

  toCollectionPayments(parentId: number) {
    this._router.navigate(['bon/collection-payments', parentId]);
  }

  toCollectionPaymentsForInsurer(parentId: number) {
    this._router.navigate(['bon/collection-payments/insurer', parentId]);
  }

  toCollectionPaymentsForInsured(parentId: number) {
    this._router.navigate(['bon/collection-payments/insured', parentId]);
  }

  toCollectionPaymentSchedule(parentId: number) {
    this._router.navigate(['bon/collection-payment-schedule', parentId]);
  }

  toCollectionExternalProviderRelations(parentId: number) {
    this._router.navigate(['bon/collection-external-provider-relations', parentId]);
  }

  toCollectionRequests(requestId: number) {
    this._router.navigate(['bon/collection-requests', requestId]);
  }

  toCollectionRequestsByParentId(parentId: number) {
    this._router.navigate(['bon/collection-requests'], {
      queryParams: {parentId: parentId},
    });
  }

  toCollectionHistory(versionId: number, collectionId: number, collectionNumber: string) {
    this._router.navigate(['bon/collection-history'], {
      queryParams: {versionId: versionId, collectionId: collectionId, collectionNumber: collectionNumber},
    });
  }

  toCollectionDetailsByParentId(parentId: number) {
    this.collectionVersionService
      .getLastVersionSimpleByParentId(parentId)
      .subscribe((ver) => this.toCollectionDetails(ver.id));
  }

  toLimitDetailsByLimitId(limitId: number) {
    this.limitService.getLastLimitRequestByLimitId(limitId).subscribe((req) => this.toLimitDetails(req.id));
  }

  toCollectionDetails(versionId: number) {
    this._router.navigate(['bon/collection-details', versionId]);
  }

  toCollectionDetailsNewUnnamed() {
    this.toCollectionDetailsNew({typeId: CollectionType.CREDIT_INSURANCE_DISCRETIONARY_COVER});
  }

  toCollectionDetailsNewWithLimit() {
    this.toCollectionDetailsNew({typeId: CollectionType.CREDIT_INSURANCE_BUYER_WITH_LIMIT});
  }

  toCollectionDetailsNewUnnamedWithLimit() {
    this.toCollectionDetailsNew({typeId: CollectionType.CREDIT_INSURANCE_DISCRETIONARY_COVER_PLUS_LIMIT});
  }

  toCollectionDetailsNew(params?: CollectionInitialParams) {
    this._router.navigate(['bon/collection-details'], {
      queryParams: params,
    });
  }

  toCollectionRelatedCases(collectionVersionId: number) {
    this._router.navigate(['bon/collection-related-cases', collectionVersionId]);
  }

  // CLAIM
  toClaimSearch() {
    this._router.navigate(['bon/claim-search']);
  }

  toClaimDetailsNew(params?: ClaimInitialParams) {
    this._router.navigate(['bon/claim-details'], {queryParams: params});
  }

  toClaimDetails(id: number) {
    this._router.navigate(['bon/claim-details', id]);
  }

  // Business Object
  goToBusinessObject(relatedToId: number, id: number) {
    switch (relatedToId) {
      case BusinessObjectType.LIMIT_REQUEST:
        this.toLimitDetails(id);
        break;
      case BusinessObjectType.COLLECTION:
        this.toCollectionDetailsByParentId(id);
        break;
      case BusinessObjectType.COLLECTION_VERSION:
        this.toCollectionDetails(id);
        break;
      case BusinessObjectType.COLLECTION_REQUEST:
        this.toCollectionRequests(id);
        break;
      case BusinessObjectType.LIMIT:
        this.toLimitDetailsByLimitId(id);
        break;
    }
  }

  abstract toLimitDetails(id: number);

  toClientOffer(offerId: number) {
    // to force refresh
    this._router.navigate(['bon/client-inquiry-offer-details', offerId], {
      queryParams: {refresh: new Date().getTime()},
    });
  }

  toPbGCalculator() {
    this._router.navigate(['bon/inquiry-details-calculator-pbg-details']);
  }

  toNewPolicyInquiryDetails(typeId: number) {
    this._router.navigate(['bon/new-inquiry/', typeId]);
  }

  toClientCompanyDetailsWithParams(params: ClientCompanyViewParams) {
    this.toViewWithParams('client-company-details', params);
  }

  toViewWithParams(viewName: string, params: any) {
    this._router.navigate(['bon/' + viewName], {queryParams: params});
  }

  toLimitDetailsWithParams(params: LimitViewParams) {
    this._router.navigate(['bon/limit-details'], {
      queryParams: params,
    });
  }

  toCustomView(viewId: number, readFromCache: boolean, returnObjectId: number, newObjId: number) {
    const view = View.get(viewId);
    if (view.navigator) {
      view.navigator(this, returnObjectId, newObjId);
    } else if (returnObjectId) {
      this._router.navigate(['bon/' + View.get(viewId).name, returnObjectId]);
    } else {
      this._router.navigate(['bon/' + View.get(viewId).name, readFromCache]);
    }
  }

  abstract toPolicyContractPreview(versionId: number);

  navigate(componentSelector: string, id: number, params?: any) {
    console.log('navigate: ' + componentSelector + '/' + id, params);
    if (id) {
      this._router.navigate(['bon/' + componentSelector, id]);
    } else {
      this._router.navigate(
        ['bon/' + componentSelector],
        params
          ? {
              queryParams: params,
            }
          : undefined
      );
    }
  }
}
