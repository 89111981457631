import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {PortalRouterService} from '../services/portal-router.service';
import {PolicyContractVersionSimpleDto} from '../../bonding_shared/model/dtos';
import {IdAndNumber, PortalContractVersionService} from '../services/portal-contract-version.service';
import {Subscription} from 'rxjs';
import {ElementaryRight, UserRole} from '../../bonding_shared/model/dictionary-ids';
import {PortalPolicyContractVersionService} from '../services/portal-policy-contract-version.service';

@Component({
  selector: 'portal-sidebar',
  templateUrl: './portal-sidebar.component.pug',
})
export class PortalSidebarComponent implements OnInit, OnDestroy {
  isActive = false;
  policies: PolicyContractVersionSimpleDto[] = [];
  contracts: IdAndNumber[] = [];

  readonly CONTRACTS_COUNT_DISPLAY_LIMIT = 10;
  readonly UserRole = UserRole;

  constructor(
    public loggedUserService: LoggedUserService,
    public router: PortalRouterService,
    private policyService: PortalPolicyContractVersionService,
    private contractService: PortalContractVersionService
  ) {}

  private clientContractsSubscription: Subscription;

  ngOnInit() {
    this.loadPolicies();
    this.loadContracts();
  }

  loadPolicies() {
    this.policyService.getClientPolicies().subscribe({
      next: (policies) => (this.policies = policies.result),
      error: (error) => console.warn('Policy load error: ', error),
    });
  }

  private loadContracts() {
    this.contracts = [];
    if (this.loggedUserService.hasRight(ElementaryRight.PORTAL_CLIENT)) {
      this.clientContractsSubscription = this.contractService.getClientContracts().subscribe({
        next: (contract) => this.contracts.push(contract),
        error: (error) => console.log('Contracts load error: ', error),
      });
    }
  }

  ngOnDestroy(): void {
    if (this.clientContractsSubscription) {
      this.clientContractsSubscription.unsubscribe();
    }
  }
}
