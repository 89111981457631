import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AbstractService, UrlParams} from '../../bonding_shared/services/abstract.service';
import {AppConfigService} from '../../bonding_shared/services/app-config.service';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {
  PolicyContractIdDto,
  PolicyContractTechnicalDetailsDto,
  PolicySectionAdditionalDataDto,
} from '../../bonding_shared/model';

@Injectable()
export class PortalPolicyContractService extends AbstractService {
  protected url = this.urlPrefix + 'portal/policyContract';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  public hasAnyClauseType(id: number, clauseTypeIds: number[]): Observable<boolean> {
    if (!id || !clauseTypeIds) {
      return of(false);
    }
    const params = UrlParams.new().add('clauseTypeIds', clauseTypeIds.join(','));
    return this.get<boolean>(this.buildUrl(this.url + '/' + id + '/hasAnyClauseType/', null, params));
  }

  getPolicySectionAdditionalData(policyContractId: number): Observable<PolicySectionAdditionalDataDto> {
    return this.get<PolicySectionAdditionalDataDto>(this.url + '/policySectionAdditionalData/' + policyContractId);
  }

  getTechnicalDetails(policyContractId: number): Observable<PolicyContractTechnicalDetailsDto> {
    return this.get<PolicyContractTechnicalDetailsDto>(this.url + '/technicalDetails/' + policyContractId);
  }

  getPeers(id: number): Observable<PolicyContractIdDto[]> {
    return this.get<PolicyContractIdDto[]>(this.url + '/peers/' + id);
  }

  isNN(pcId: number, rpId: number): Observable<boolean> {
    return this.get<boolean>(this.url + '/isNN/' + pcId + '/' + rpId);
  }
}
