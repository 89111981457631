import {Component, Input} from '@angular/core';
import {BondTypePerformance, DashboardService, DictionaryBaseDto, StringUtils} from '../../../../bonding_shared';
import {ChartConfiguration, ChartData, ChartType} from 'chart.js';
import {ChartUtils} from '../chart-utils';
import {ApplicationModule} from '../../../../bonding_shared/model/dictionary-ids';
import {map} from 'rxjs/operators';

@Component({
  selector: 'pie-chart',
  templateUrl: 'piechart.component.pug',
})
export class PieChartComponent {
  @Input() set businessUnit(businessUnit: DictionaryBaseDto) {
    this._businessUnit = businessUnit;
    this.loadRestData();
  }

  readonly MAX_ELEMENT_NUM = 10;

  _businessUnit: DictionaryBaseDto;
  bondsNumber: number[] = [];
  bondsValue: number[] = [];
  bondsType: string[] = [];

  initialized = false;

  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = <number>context.dataset.data[context.dataIndex];
            const sum = (<number[]>context.dataset.data).reduce((a, b) => a + b, 0);
            const ratio = value / sum;
            const valueF = StringUtils.formatNumber(value);
            const ratioF = StringUtils.formatNumber(ratio * 100);
            return label + ': ' + valueF + ', ' + ratioF + ' %';
          },
        },
      },
    },
  };
  public pieChartData: ChartData<'pie', number[], string | string[]>;
  public pieChartType: ChartType = 'pie';

  constructor(private service: DashboardService) {}

  loadData() {
    const numElements = this.bondsType.length > this.MAX_ELEMENT_NUM ? this.MAX_ELEMENT_NUM : this.bondsType.length;
    this.pieChartData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            ChartUtils.COLORS[2],
            ChartUtils.COLORS[1],
            ChartUtils.COLORS[0],
            ChartUtils.COLORS[3],
            ChartUtils.COLORS[4],
            ChartUtils.COLORS[5],
            ChartUtils.COLORS[6],
            ChartUtils.COLORS[7],
            ChartUtils.COLORS[8],
            ChartUtils.COLORS[9],
            ChartUtils.COLORS[10],
            ChartUtils.COLORS[11],
            ChartUtils.COLORS[12],
          ],
        },
      ],
    };
    for (let i = 0; i < numElements; i++) {
      this.pieChartData.labels.push(this.bondsType[i]);
      this.pieChartData.datasets[0].data.push(this.bondsNumber[i]);
    }
    this.initialized = true;
  }

  loadRestData() {
    this.initialized = false;
    this.bondsType = [];
    this.bondsNumber = [];
    this.bondsValue = [];
    this.service
      .getTypeChartData(this._businessUnit, ApplicationModule.BONDING)
      .pipe(
        map((results: Array<BondTypePerformance>) => {
          if (results) {
            results.sort((a, b) => (b.bondsNumber > a.bondsNumber ? 1 : -1));
            results.forEach((result) => {
              this.bondsType.push(result.bondTypeLabel);
              this.bondsNumber.push(result.bondsNumber);
              this.bondsValue.push(result.bondsValue);
            });
          }
        })
      )
      .subscribe(
        () => console.log('Empty subscribe'),
        (error) => console.log('Error occurred while getting Time Chart data'),
        () => this.loadData()
      );
  }
}
