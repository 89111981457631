/**
 * Created by siminski on 30.06.2016. Modified by szkrabko non 29.08.2018
 */
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, forwardRef, OnInit} from '@angular/core';
import {DictionaryService} from '../../services';
import {DictBaseComponent} from './dict-base.component';
import {DictionaryBaseDto} from '../../model';

const DICT_COMBO_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DictComboComponent),
  multi: true,
};
@Component({
  selector: 'dict-combo',
  template: `
    <select
      *ngIf="!presentationMode"
      [(ngModel)]="selectedKey"
      (change)="selectedKey = $event.target.value"
      class="bon-select"
      [disabled]="disabled"
      [class.bottom-padding-3]="increaseBottomPadding"
    >
      <option *ngIf="nullLabel || nullLabelKey" value="undefined">
        {{ nullLabel ? nullLabel : (nullLabelKey | translate) }}
      </option>
      <ng-container *ngFor="let item of itemsFiltered">
        <option [value]="optionKey(item)" [class.inactive]="item.active != undefined && !item.active">
          <span *ngIf="!template">{{ itemValue(item) }}</span>
          <span *ngIf="template">
            <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{item: item}"></ng-container>
          </span>
        </option>
      </ng-container>
    </select>
    <ng-container *ngIf="presentationMode">
      <span *ngIf="!template" class="presentation" [class.float-right]="floatRight">{{
        (selectedItem && itemValue(selectedItem)) || nullLabel
      }}</span>
      <span *ngIf="template" class="presentation" [class.float-right]="floatRight">
        <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{item: selectedItem}"></ng-container>
      </span>
    </ng-container>
    <div *ngIf="errorMessage" class="invalid">Error: errorMessage</div>
  `,
  providers: [DICT_COMBO_CONTROL_VALUE_ACCESSOR],
})
export class DictComboComponent extends DictBaseComponent implements ControlValueAccessor, OnInit {
  constructor(dictionaryService: DictionaryService) {
    super(dictionaryService);
  }

  itemValue(item: DictionaryBaseDto) {
    if (this.label === 'codeAndName') {
      return item['code'] + ' - ' + item['name'];
    } else {
      return item[this.label];
    }
  }

  optionKey(item: DictionaryBaseDto) {
    if (item.id) {
      return item.id;
    } else {
      return item.code;
    }
  }
}
