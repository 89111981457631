import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CollectionCostsViewDto, CollectionPaymentsDto} from '../model';
import {CollectionAuditedService} from './collection-audited.service';

@Injectable()
export class CollectionAccountedCostsService extends CollectionAuditedService<CollectionCostsViewDto> {
  protected midUrl = 'accountedCosts';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }
}
