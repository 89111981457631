import {UntypedFormGroup, ValidationErrors} from '@angular/forms';

export function validateFixedMinPremiums(formGroup: UntypedFormGroup): ValidationErrors | null {
  if (Object.keys(formGroup.controls).length === 0) {
    return {
      required: {
        valid: false,
      },
    };
  }
  if (formGroup.get('0').get('1') && formGroup.get('0').get('1').value !== 0) {
    return {
      firstLimitThresholdZero: {
        valid: false,
      },
    };
  }

  // values check
  let curr;
  const isEmpty = (v) => !v && v !== 0;
  for (let i = 0; (curr = formGroup.get(i.toString())); i++) {
    if (Object.keys(curr.controls).length > 1 && (isEmpty(curr.get('0').value) || isEmpty(curr.get('1').value))) {
      return {
        required: {
          valid: false,
        },
      };
    }
    if (Object.keys(curr.controls).length > 1 && (isNaN(curr.get('0').value) || isNaN(curr.get('1').value))) {
      return {
        valueNotPositive: {
          valid: false,
        },
      };
    }
  }
  // threshold check
  let prev: any = formGroup.get('0');
  curr = null;
  for (let i = 1; (curr = formGroup.get(i.toString())); i++) {
    if (Object.keys(curr.controls).length > 1 && Object.keys(prev.controls).length > 1) {
      const sub = curr.get('1').value - prev.get('1').value;
      if (sub < 0.02) {
        return {
          thresholdInvalid: {
            valid: false,
          },
        };
      }
    }
    prev = curr;
  }

  return null;
}
