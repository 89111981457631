import {Observable, of} from 'rxjs';
import {
  DictionaryBaseDto,
  DictionaryDto,
  PolicyInquiryCountryDto,
  PolicyInquiryVersionBaseDto,
  PolicyInquiryVersionDto,
} from '../../../bonding_shared/model';
import {DictionaryService} from '../../../bonding_shared/services';
import {map} from 'rxjs/operators';
import {Country} from '../../../bonding_shared/model/dictionary-ids';
import {WithCountries} from '../services/inquiry-countries-initialiazer.service';

export class InquiryCountries {
  static getProductsCountries(
    dictionaryService: DictionaryService,
    inquiry: PolicyInquiryVersionBaseDto
  ): Observable<DictionaryBaseDto[]> {
    return InquiryCountries.getProductsCountriesById(dictionaryService, inquiry.productType.id);
  }

  static getProductsCountriesById(
    dictionaryService: DictionaryService,
    productTypeId: number
  ): Observable<DictionaryBaseDto[]> {
    return dictionaryService
      .getDictionaryEntry('InquiryProductType', productTypeId)
      .pipe(map((v) => v.relatedDictionaries['Country']));
  }

  static getOtherProductsCountries(
    dictionaryService: DictionaryService,
    filteredType: number
  ): Observable<[DictionaryDto, DictionaryDto[]][]> {
    const dictCache = new Map<number, DictionaryDto>();
    const toIdMapsReduce = (prev, curr) => {
      if (curr.id === filteredType) {
        return prev;
      }
      const countries = curr.relatedDictionaries['Country'];
      if (!countries) {
        return prev;
      }
      for (const country of countries) {
        const countryId = country.id;
        if (!prev.has(countryId)) {
          dictCache.set(countryId, country);
          prev.set(countryId, new Set<number>());
        }
        prev.get(countryId).add(curr.id);
        dictCache.set(curr.id, curr);
      }
      return prev;
    };

    const convertToDicts = (v: Map<number, Set<number>>) => {
      const ret: [DictionaryDto, DictionaryDto[]][] = [];
      for (const key of v.keys()) {
        const country: DictionaryDto = dictCache.get(key);
        const products = [];
        for (const prodTypeId of v.get(key)) {
          products.push(dictCache.get(prodTypeId));
        }
        ret.push([country, products]);
      }
      return ret;
    };

    return dictionaryService.getDictionary('InquiryProductType').pipe(
      map((v) => v.reduce<Map<number, Set<number>>>(toIdMapsReduce, new Map<number, Set<number>>())),
      map(convertToDicts)
    );
  }

  static existsNonMarketCountry(
    dictionaryService: DictionaryService,
    inquiry: PolicyInquiryVersionDto
  ): Observable<boolean> {
    if (!inquiry || !inquiry.countries) {
      return of(false);
    }
    const inqCountryIds = inquiry.countries.map((c) => c.country.id);
    return InquiryCountries.getProductsCountries(dictionaryService, inquiry).pipe(
      map((pcountries) => inqCountryIds.filter((c) => !pcountries.find((f) => f.id === c)).length > 0)
    );
  }

  static countriesSumPlannedTO(inquiry: WithCountries, filterPredicateFn) {
    const ct = inquiry && inquiry.countries;
    if (!ct || ct.length === 0) {
      return 0;
    }
    const isNumber = (value) => typeof value === 'number' && isFinite(value);
    const ctFiltered = ct.filter((v) => isNumber(v.plannedTO)).filter(filterPredicateFn);
    if (ctFiltered.length === 0) {
      return 0;
    }
    return ctFiltered.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.plannedTO;
    }, 0);
  }

  static domesticPredicate = (c: PolicyInquiryCountryDto) => c.plannedTO && c.country && c.country.id === Country.PL;
  static exportPredicate = (c: PolicyInquiryCountryDto) => c.plannedTO && c.country && c.country.id !== Country.PL;
}
