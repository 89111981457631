import {Observable} from 'rxjs';
import {
  DictionaryBaseDto,
  MasterPolicyContractBaseDto,
  Page,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionSimpleDto,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionSimpleDto,
  SearchResult,
  SortBy,
} from '../../../../../bonding_shared/model';
import {PolicyAndInquiryVersionCriteria} from './policy-and-inquiry-version-criteria';
import {DataProvider} from '../../../../../bonding_shared/components/aku-table/data-provider';
import {SearchCriteria} from '../../../../../bonding_shared/model/dtos';
import {InquiryService, PolicyContractVersionService} from '../../../../../bonding_shared/services';
import {BusinessUtils} from '../../../../../bonding_shared/utils/business-utils';

export class PolicyAndInquirySearchDataProvider
  implements DataProvider<PolicyContractVersionSimpleDto | PolicyInquiryVersionSimpleDto>
{
  inProgress = false;
  commonCriteria = new PolicyAndInquiryVersionCriteria();

  inquiryCriteria = BusinessUtils.createPolicyInquiryVersionSearchCriteria();
  policyCriteria = BusinessUtils.createPolicyVersionSearchCriteria();
  fixedClient = false;
  fixedMasterPolicyId: number;
  inquirySearchedPreviously = false;
  inquiryInitialSortBy = <SortBy>{direction: 'DOWN', column: 'id'};
  policyInitialSortBy = <SortBy>{direction: 'DOWN', column: 'mpc.number'};

  constructor(private policyService: PolicyContractVersionService, private inquiryService: InquiryService) {}

  clone(): PolicyAndInquirySearchDataProvider {
    const clone = new PolicyAndInquirySearchDataProvider(this.policyService, this.inquiryService);

    clone.commonCriteria = JSON.parse(JSON.stringify(this.commonCriteria));
    clone.inquiryCriteria = JSON.parse(JSON.stringify(this.inquiryCriteria));
    clone.policyCriteria = JSON.parse(JSON.stringify(this.policyCriteria));

    clone.inquirySearchedPreviously = this.inquirySearchedPreviously;
    clone.inquiryInitialSortBy = this.inquiryInitialSortBy;
    clone.policyInitialSortBy = this.policyInitialSortBy;

    return clone;
  }

  search(
    page?: Page,
    sortBy?: SortBy
  ): Observable<SearchResult<PolicyContractVersionSimpleDto | PolicyInquiryVersionSimpleDto>> {
    console.log('search policies or inquires', this.commonCriteria);
    const service = this.commonCriteria.inquirySearch ? this.inquiryService : this.policyService;
    return service.searchByCriteria(this.getSearchCriteria(page, sortBy));
  }

  getSearchCriteria(
    page?: Page,
    sortBy?: SortBy
  ): SearchCriteria<PolicyInquiryVersionCriteriaDto | PolicyContractVersionCriteriaDto> {
    let searchCriteria: SearchCriteria<PolicyInquiryVersionCriteriaDto | PolicyContractVersionCriteriaDto>;
    if (this.commonCriteria.inquirySearch) {
      this.inquiryCriteria.criteria.policyInquiry.number = this.commonCriteria.number;
      this.inquiryCriteria.criteria.productType = this.commonCriteria.inquiryProductType;
      if (!this.fixedClient) {
        this.inquiryCriteria.criteria.client.id = this.commonCriteria.clientId;
        this.inquiryCriteria.criteria.client.registrationName = this.commonCriteria.clientName;
      }
      searchCriteria = this.inquiryCriteria;
    } else {
      if (this.fixedMasterPolicyId) {
        this.policyCriteria.criteria.policyContract.masterPolicyContract.id = this.fixedMasterPolicyId;
      } else {
        this.policyCriteria.criteria.policyContract.number = this.commonCriteria.number;
        this.policyCriteria.criteria.contractType = this.commonCriteria.policyProductType;
        if (!this.fixedClient) {
          this.policyCriteria.criteria.clientId = this.commonCriteria.clientId;
          this.policyCriteria.criteria.clientName = this.commonCriteria.clientName;
        }
      }
      searchCriteria = this.policyCriteria;
    }
    searchCriteria.sortBy = this.updateAndGetSortBy(sortBy);
    searchCriteria.page = page;
    this.inquirySearchedPreviously = this.commonCriteria.inquirySearch;
    return searchCriteria;
  }

  updateAndGetSortBy(sortBy: SortBy): SortBy {
    const searchSwitched =
      (this.commonCriteria.inquirySearch && !this.inquirySearchedPreviously) ||
      (!this.commonCriteria.inquirySearch && this.inquirySearchedPreviously);
    const initialSort = this.commonCriteria.inquirySearch ? this.inquiryInitialSortBy : this.policyInitialSortBy;
    if (!sortBy) {
      sortBy = <SortBy>{};
    }
    if (searchSwitched || !sortBy.column) {
      sortBy.direction = initialSort.direction;
      sortBy.column = initialSort.column;
    }
    return sortBy;
  }
}
