import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService} from '../../../../../bonding_shared';

@Component({
  selector: 'mehib-covered-transaction-section',
  templateUrl: './mehib-covered-transaction-section.component.pug',
})
export class MehibCoveredTransactionSectionComponent extends Section {
  self = this;

  constructor(protected policyContractViewService: PolicyContractViewService, public appService: AppConfigService) {
    super(policyContractViewService, appService);
  }
}
