import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ComboItem} from '../../bonding_shared/components/combos/index';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PortalUserService} from '../services/portal-user.service';

const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ClientContractComboComponent),
  multi: true,
};

@Component({
  selector: 'client-contract-combo',
  template: `
    <div class="bon-row">
      <label class="bon-label" [translate]="labelKey"></label>
      <string-combo
        class="bon-input-size"
        [nullLabel]="nullLabel"
        [nullLabelKey]="nullLabelKey"
        [(ngModel)]="selectedContractId"
        [items]="contracts"
        (changeItem)="onChange($event)"
      ></string-combo>
    </div>
  `,
  providers: [CONTROL_VALUE_ACCESSOR],
})
export class ClientContractComboComponent implements OnInit, ControlValueAccessor {
  contracts: ComboItem[] = [];
  selectedContractId: number;
  onChangeListener: Function;

  @Input() labelKey: string;
  @Input() nullLabel: string;
  @Input() nullLabelKey: string;
  @Output() changeItem = new EventEmitter<string>();

  constructor(private userService: PortalUserService) {}

  ngOnInit() {
    this.userService
      .getPortalUserContext()
      .subscribe((ctx) => ctx.contracts.forEach((p) => this.contracts.push(new ComboItem(p.id + '', p.number))));
  }

  registerOnChange(fn: any): void {
    this.onChangeListener = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.selectedContractId = obj;
  }

  onChange(masterContractId: string) {
    if (this.onChangeListener) {
      this.onChangeListener(masterContractId);
    }
    this.changeItem.emit(masterContractId);
  }
}
