/**
 * Created by wilk on 18.01.2017.
 */
import {AfterViewChecked, AfterViewInit, Component, ContentChild, ElementRef, Input, Renderer2} from '@angular/core';
import {DetailsView} from './details-view';

import {LoggedUserService} from '../../services';
import {TabView} from 'primeng/tabview';

@Component({
  selector: 'details-view',
  templateUrl: './details-view.component.pug',
})
export class DetailsViewComponent implements AfterViewChecked, AfterViewInit {
  @Input() title: string;
  @Input() objectName: string;
  // Used for translation of backend data model fields
  @Input() modelObjectName: string;
  @Input() parentComponent: DetailsView;
  @Input() versionListHidden = false;
  @Input() buttonsHidden = false;
  @Input() fixedBlockHidden = false;
  @Input() afterVersionsContentHidden = true;
  @Input() versionsSectionTitle = 'Versions';
  @Input() set newVersionCreationPossible(nv: boolean) {
    this._newVersionCreationPossible = nv;
    this.updateUpdateRight();
  }
  @Input() tabViewStyle = false;
  @Input() set updateRightOrFunc(updateRightOrFunc: string | ((o: any) => string)) {
    this._updateRightOrFunc = updateRightOrFunc;
    this.updateUpdateRight();
  }
  @Input() set createRightOrFunc(createRightOrFunc: string | ((o: any) => string)) {
    this._createRightOrFunc = createRightOrFunc;
    this.updateCreateRight();
  }
  @Input() set deleteRightOrFunc(deleteRightOrFunc: string | ((o: any) => string)) {
    this._deleteRightOrFunc = deleteRightOrFunc;
    this.updateDeleteRight();
  }
  @Input() set object(object: any) {
    this._object = object;
    this.updateUpdateRight();
    this.updateCreateRight();
    this.updateDeleteRight();
  }

  public updateRight: string;
  public createRight: string;
  public deleteRight: string;
  _updateRightOrFunc: string | ((o: any) => string);
  _createRightOrFunc: string | ((o: any) => string);
  _deleteRightOrFunc: string | ((o: any) => string);
  _object: any;
  _newVersionCreationPossible = true;
  _saveCancelButtonsHidden = false;

  public canEdit = true;
  public canSaveCancel = true;
  public canCreate = true;
  public canDelete = false;

  @ContentChild(TabView, {static: true}) tabView: TabView;

  _objectCreated = (object: any) => !!object.id;

  @Input() set objectCreated(objectCreated: (object: any) => boolean) {
    this._objectCreated = objectCreated;
    this.updateUpdateRight();
    this.updateCreateRight();
    this.updateDeleteRight();
  }

  @Input() set saveCancelButtonsHidden(saveCancelButtonsHidden: boolean) {
    this._saveCancelButtonsHidden = saveCancelButtonsHidden;
    this.updateUpdateRight();
    this.updateCreateRight();
  }

  constructor(public view: ElementRef, public renderer: Renderer2, public loggedUserService: LoggedUserService) {}

  ngAfterViewChecked() {
    const fixedDiv = this.view.nativeElement.querySelector('.bon-fixed-header');
    const scrollableContentDiv = this.view.nativeElement.querySelector('.scrollable-content');
    if (fixedDiv && scrollableContentDiv) {
      this.renderer.setStyle(scrollableContentDiv, 'margin-top', fixedDiv.offsetHeight + 'px');
    }
  }

  ngAfterViewInit() {
    if (this.tabViewStyle && this.tabView) {
      this.renderer.removeClass(this.tabView.el.nativeElement.firstChild, 'ui-widget');
      const ulElement = this.tabView.el.nativeElement.querySelector('ul');
      this.renderer.addClass(ulElement, 'bon-tab-view');
    }
  }

  private updateUpdateRight(): void {
    if (typeof this._updateRightOrFunc === 'string') {
      this.updateRight = this._updateRightOrFunc;
    } else if (this._object && typeof this._updateRightOrFunc === 'function') {
      this.updateRight = this._updateRightOrFunc(this._object);
    } else {
      return;
    }
    this.updateCanEditSaveCancelCreateDelete();
  }

  private updateCreateRight(): void {
    if (typeof this._createRightOrFunc === 'string') {
      this.createRight = this._createRightOrFunc;
    } else if (this._object && typeof this._createRightOrFunc === 'function') {
      this.createRight = this._createRightOrFunc(this._object);
    } else {
      return;
    }
    this.updateCanEditSaveCancelCreateDelete();
  }

  private updateDeleteRight(): void {
    if (typeof this._deleteRightOrFunc === 'string') {
      this.deleteRight = this._deleteRightOrFunc;
    } else if (this._object && typeof this._deleteRightOrFunc === 'function') {
      this.deleteRight = this._deleteRightOrFunc(this._object);
    } else {
      return;
    }
    this.updateCanEditSaveCancelCreateDelete();
  }

  private updateCanEditSaveCancelCreateDelete(): void {
    if (this._object) {
      this.canEdit = this._objectCreated(this._object) && this.loggedUserService.hasRight(this.updateRight);
      this.canSaveCancel =
        !this._saveCancelButtonsHidden &&
        (this._objectCreated(this._object)
          ? !this.updateRight || this.loggedUserService.hasRight(this.updateRight)
          : !this.createRight || this.loggedUserService.hasRight(this.createRight));
      this.canCreate = this.loggedUserService.hasRight(this.createRight);
      this.canDelete = this._objectCreated(this._object) && this.loggedUserService.hasRight(this.deleteRight);
    }
  }
}
