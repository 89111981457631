import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {TemplateService} from '../../../bonding_shared/services/template.service';
import {TemplateSimpleDto, TemplateCriteriaDto} from '../../../bonding_shared/model/dtos';

@Injectable()
export class TemplateDetailsGuiService {
  businessObjectId: number;

  constructor(private service: TemplateService) {}

  createDataProvider<DocumentCriteriaDto, DocumentSimpleDto>(): SearchDataProvider<
    TemplateCriteriaDto,
    TemplateSimpleDto
  > {
    return new SearchDataProvider<TemplateCriteriaDto, TemplateSimpleDto>(this.service);
  }
}
