import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {CollectionVersionService} from '../../../../bonding_shared/services/collection-version.service';
import {AppConfigService, RouterService, SearchDataProvider} from '../../../../bonding_shared/services';
import {CollectionVersionBaseDto, CollectionVersionCriteriaDto, CompanyBaseDto} from '../../../../bonding_shared/model';
import {CollectionGuiService} from '../../services/collection-gui.service';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table';

@Component({
  selector: 'collection-list',
  templateUrl: 'collection-list.component.pug',
})
export class CollectionListComponent implements AfterViewInit {
  dataProvider: SearchDataProvider<CollectionVersionCriteriaDto, CollectionVersionBaseDto>;
  hideCreditor = false;
  hideDebtor = false;

  @ViewChild(ATableComponent, {static: true}) table: ATableComponent<CollectionVersionBaseDto>;
  @Input() performInitialSearch = true;
  @Input() ellipsis = false;

  @Input()
  set creditorId(id: number) {
    this.dataProvider.searchCriteria.criteria.parent.creditor = <CompanyBaseDto>{id: id};
    this.hideCreditor = true;
  }

  @Input()
  set debtorId(id: number) {
    this.dataProvider.searchCriteria.criteria.parent.debtor = <CompanyBaseDto>{id: id};
    this.hideDebtor = true;
  }

  constructor(public router: RouterService, service: CollectionVersionService, public appConf: AppConfigService) {
    this.dataProvider = CollectionGuiService.createProvider(service);
  }

  ngAfterViewInit(): void {
    if (this.performInitialSearch) {
      this.table.search();
    }
  }

  public isEmpty(): boolean {
    return !this.table || !this.table.items || this.table.items.length < 1;
  }
}
