import {Component, Input} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService, DictionaryService, PolicyContractVersionService} from '../../../../../bonding_shared';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'ecg-scope-of-cover-section',
  templateUrl: './ecg-scope-of-cover-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class EcgScopeOfCoverSectionComponent extends Section {
  self = this;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected appService: AppConfigService,
    protected pcvService: PolicyContractVersionService,
    protected dictionaryService: DictionaryService
  ) {
    super(policyContractViewService, appService, pcvService, dictionaryService);
  }
}
