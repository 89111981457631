import {AuditedViewAbstract} from '../../bonding_shared/components/details-view/audited-view-abstract';
import {
  CompanyService,
  DictionaryService,
  GrowlService,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared/services';
import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {
  CompanyBaseDto,
  CompanyDto,
  DictionaryBaseDto,
  ExternalProviderRelationDto,
  ExternalProviderVersionBaseDto,
} from '../../bonding_shared/model';
import {
  BusinessObjectType,
  ExternalProviderType,
  InformationReportOrderMode,
} from '../../bonding_shared/model/dictionary-ids';
import {CellChangeEvent} from '../../bonding_shared';
import {CompanyExternalProvidersService} from '../../bonding_shared/services/company-external-providers.service';

@Component({
  selector: 'company-external-providers',
  templateUrl: 'company-external-providers.component.pug',
})
export class CompanyExternalProvidersComponent extends AuditedViewAbstract<ExternalProviderRelationDto[]> {
  public company = <CompanyDto>{};
  readonly BusinessObjectType = BusinessObjectType;
  readonly ExternalProviderType = ExternalProviderType;

  constructor(
    route: ActivatedRoute,
    parentService: CompanyExternalProvidersService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    private companyService: CompanyService,
    private dictionaryService: DictionaryService
  ) {
    super(route, parentService, router, translateService, growlService, loggedUserService, 'companyId');
    this.selectorNameList = ['Providers'];
    this.initializeSelectorEmitters(true);
  }

  extraInitialization() {
    super.extraInitialization();
    this.companyService.getCompany(this.id).subscribe((company: CompanyDto) => (this.company = company));
  }

  protected getEmpty(): ExternalProviderRelationDto[] {
    return [];
  }

  addCompany(companyDto: CompanyBaseDto) {
    if (!this.current.map((x) => (x.company ? x.company.id : x.provider.company.id)).includes(companyDto.id)) {
      this.current.push(<ExternalProviderRelationDto>{company: companyDto, orderDate: new Date()});
    }
  }

  addProvider(dto: ExternalProviderVersionBaseDto) {
    if (
      !this.current.map((x) => x.provider && x.provider.id).includes(dto.parent.id) &&
      !this.current.map((x) => x.company && x.company.id).includes(dto.parent.company.id)
    ) {
      this.dictionaryService
        .getDictionaryEntry('InformationReportOrderMode', InformationReportOrderMode.STANDARD)
        .subscribe((dict) => {
          this.current.push(<ExternalProviderRelationDto>{
            vatRate: dto.vatRate,
            provider: dto.parent,
            orderDate: new Date(),
            orderMode: <DictionaryBaseDto>dict,
          });
        });
    }
  }

  onOrderDateChange(event: CellChangeEvent<ExternalProviderRelationDto>) {
    if (event.value > event.item.closingDate) {
      event.item.closingDate = event.value;
    }
  }
}
