import {Component, Input, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  ClaimSimpleDto,
  ClaimVersionCriteriaDto,
  ClaimVersionDto,
  FeatureService,
  LoggedUserService,
  RouterService,
  SearchDataProvider,
} from '../../../../bonding_shared';
import {ClaimElementaryRight, ClaimRiskType, Feature} from '../../../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';

@Component({
  selector: 'claim-list',
  templateUrl: './claim-list.component.pug',
})
export class ClaimListComponent {
  @ViewChild(ATableComponent, {static: true}) claimTable: ATableComponent<ClaimVersionDto>;
  @Input() set dataProvider(dp: SearchDataProvider<ClaimVersionCriteriaDto, ClaimVersionDto>) {
    this.claimTable.dataProvider = dp;
    this.claimTable.pagination = true;
    if (this.performInitialSearch) {
      this.claimTable.search();
    }
  }

  @Input() performInitialSearch = true;
  @Input() showRisk = true;
  @Input() showMainBeneficiary = true;
  @Input() ellipsis = false;
  showLinkToClaim: boolean;
  isPortalUser: boolean;
  public showShortNameIfExists: boolean; // show short company name instead of registration name when it exists on search view

  constructor(
    public router: RouterService,
    public appService: AppConfigService,
    loggedUserService: LoggedUserService,
    private featureService: FeatureService
  ) {
    this.showLinkToClaim = loggedUserService.hasRight(ClaimElementaryRight.CLAIM_VIEW);
    this.isPortalUser = loggedUserService.portal;
    this.showShortNameIfExists = featureService.get(Feature.COMPANY_SHOW_SHORT_NAME_IF_EXISTS);
  }

  selectLabelForPolicyOrContract() {
    if (!this.showRisk) {
      return 'claim.shared.list.policyOrContractRiskClaim';
    } else {
      return 'claim.shared.list.policyOrContract';
    }
  }

  refresh() {
    this.claimTable.search();
  }

  public isEmpty(): boolean {
    if (!this.claimTable) {
      return true;
    }
    return this.claimTable.totalCount < 1;
  }

  policyOrContractPreview(claimVersion: ClaimVersionDto) {
    if (claimVersion.claim.riskType.id === ClaimRiskType.CREDIT_INSURANCE) {
      this.router.toPolicyContractPreviewByPolicyContractId(claimVersion.claim.policyContract.id);
    } else {
      this.router.toContractPreview(claimVersion.claim.contractVersion.id);
    }
  }

  getRiskName(claim: ClaimSimpleDto): string {
    const company = claim.risk ? claim.risk : claim.thirdParty.firstCompanyRaw;
    return BusinessUtils.companyName(company);
  }
}
