import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FilterableBondVersionListComponent} from './filterable-bond-version-list.component';
import {ExtendedContractBondListComponent} from './extended-contract-bond-list.component';
import {AddressTableComponent} from './address-table.component';
import {BondVersionSelectorSimpleComponent} from './bond-version-selector-simple.component';
import {BondCustomFieldsComponent} from './bond-custom-fields.component';
import {BondTypeTariffComponent} from './bond-type-tariff.component';
import {BondSectionSelectorComponent} from './bond-section-selector.component';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
  declarations: [
    FilterableBondVersionListComponent,
    ExtendedContractBondListComponent,
    AddressTableComponent,
    BondVersionSelectorSimpleComponent,
    BondSectionSelectorComponent,
    BondCustomFieldsComponent,
    BondTypeTariffComponent,
  ],
  exports: [
    FilterableBondVersionListComponent,
    ExtendedContractBondListComponent,
    AddressTableComponent,
    BondVersionSelectorSimpleComponent,
    BondSectionSelectorComponent,
    BondCustomFieldsComponent,
    BondTypeTariffComponent,
  ],
})
export class BondSharedModule {}
