import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {DictionaryDto, PolicyClauseDto, PolicyContractVersionBaseDto, PolicyInquiryOfferSimpleDto} from '../model';
import {Cacheable} from 'ts-cacheable';

@Injectable()
export class PolicyClauseTypeService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'policyClauseType';
  }

  @Cacheable()
  public getClauseTypes(gciId: number): Observable<DictionaryDto[]> {
    const params = UrlParams.new().addIf(!!gciId, 'gciId', () => gciId);
    return this.get<DictionaryDto[]>(this.buildUrl(this.url + '/clauseTypes', null, params));
  }
}
