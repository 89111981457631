import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {AppConfigService} from '../../../../bonding_shared/services';

@Component({
  selector: 'documents-section',
  templateUrl: './documents-section.component.pug',
})
export class DocumentsSectionComponent extends Section {
  self = this;

  constructor(protected policyContractViewService: PolicyContractViewService, public appService: AppConfigService) {
    super(policyContractViewService, appService);
  }
}
