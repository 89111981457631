import {Injectable} from '@angular/core';
import {PropertyService} from './property.service';
import {Feature} from '../model/dictionary-ids';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  constructor(private propertyService: PropertyService) {}

  readonly PREFIX = 'feature.';

  get(feature: string | Feature): boolean {
    if (!this.propertyService.properties) {
      throw Error('properties not initialized');
    }
    const key = typeof feature === 'string' ? feature + '' : Feature[feature];
    const prefixedKey = (key.startsWith(this.PREFIX) ? '' : this.PREFIX) + key;
    const v = this.propertyService.properties[prefixedKey];
    if (v === true || v === false) {
      return !!v;
    }
    throw Error('invalid value for feature flag ' + feature + ', should be true or false, but is ' + v);
  }
}
