import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';

import {CountryRatingDetailsComponent} from './country-rating-details.component';
import {CountryRatingSearchComponent} from './country-rating-search.component';

import {PolicyModule} from '../policy/policy.module';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {CountryRatingGuiService} from './services/country-rating-gui.service';
import {CountryRatingSearchGuard} from './_guards';
import {CountryCategoryLimitsDetailsComponent} from './country-category-limits-details.component';
import {CountryCategoryLimitsService} from '../../bonding_shared/services/country-category-limits.service';

@NgModule({
  // TODO review and delete unnecessary imports
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    PolicyModule,
  ],
  declarations: [CountryRatingDetailsComponent, CountryCategoryLimitsDetailsComponent, CountryRatingSearchComponent],
  providers: [CountryRatingGuiService, CountryCategoryLimitsService, CountryRatingSearchGuard],
})
export class CountryRatingModule {}
