import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {
  BonusCalculationDto,
  BonusRequestDto,
  DictionaryBaseDto,
  MalusCalculationDto,
  MalusRequestDto,
  StateTransitionDto,
} from '../model';

@Injectable()
export class BonusMalusRequestService<
  T extends MalusRequestDto | BonusRequestDto,
  C extends BonusCalculationDto | MalusCalculationDto
> extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }
  getNewTemplate(className: string, policyContractId: number, returnTypeId: number): Observable<T> {
    return this.get<T>(this.urlPrefix + className + '/newTemplate/' + policyContractId + '/' + returnTypeId);
  }

  getAllRequests(className: string, masterPolicyId: number): Observable<T[]> {
    return this.get<T[]>(this.urlPrefix + className + '/all/' + masterPolicyId);
  }

  getBonusMalusRequestTypes(className: string): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.urlPrefix + className + '/bonusMalusRequestTypes');
  }

  getCalculation(className: string, policyContractId: number, bonusMalusRequestTypeId: number): Observable<C> {
    const calcUrl = this.urlPrefix + className + '/calculation/' + policyContractId + '/' + bonusMalusRequestTypeId;
    return this.get<C>(calcUrl);
  }

  calculate(className: string, bonusMalusRequest: T): Observable<C> {
    const calcUrl = this.urlPrefix + className + '/calculate';
    return this.post1<T, C>(bonusMalusRequest, calcUrl);
  }

  getTransitions(bonusMalusRequest: T): Observable<Array<StateTransitionDto>> {
    if (!bonusMalusRequest || !bonusMalusRequest.businessStatus) {
      return new Observable<Array<StateTransitionDto>>();
    }
    return this.get<StateTransitionDto[]>(
      this.urlPrefix + 'malusRequest/transition/' + bonusMalusRequest.businessStatus.id
    );
  }

  getTransitionsMap(): Observable<Map<number, Array<StateTransitionDto>>> {
    return this.get<Map<number, Array<StateTransitionDto>>>(this.urlPrefix + 'malusRequest/transition');
  }

  isBonusRequest(className: string): boolean {
    return className && className === 'bonusRequest';
  }

  asBonusRequest(bonusMalusRequest: T): BonusRequestDto {
    return <BonusRequestDto>bonusMalusRequest;
  }

  getBonusMalusRequestById(className: string, id: number): Observable<T> {
    console.log('getById id = ' + id);
    return this.get<T>(this.urlPrefix + className + '/' + id);
  }

  onSave(className: string, object: T): Observable<T> {
    return this.saveObj(object, this.urlPrefix + className);
  }

  onDelete(id: number): Observable<MalusRequestDto> {
    console.log('2. On delete: ' + (this.urlPrefix + 'malusRequest/' + id));
    return this.delete<MalusRequestDto>(this.urlPrefix + 'malusRequest/' + id);
  }

  getPolicyVersionIdByBonusMalusRequestId(id: number): Observable<number> {
    return this.get<number>(this.urlPrefix + 'malusRequest/getPolicyVersionId/' + id);
  }
}
