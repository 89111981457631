import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {AppConfigService} from '../../../../bonding_shared/services';

@Component({
  selector: 'special-clause-section',
  templateUrl: './special-clause-section.component.pug',
})
export class SpecialClauseSectionComponent extends Section {
  constructor(protected policyContractViewService: PolicyContractViewService, public appService: AppConfigService) {
    super(policyContractViewService, appService);
  }
}
