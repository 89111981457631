import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ReportSearchComponent} from './report-search.component';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {ReportDetailsComponent} from './report-details.component';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
  declarations: [ReportSearchComponent, ReportDetailsComponent],
})
export class ReportModule {}
