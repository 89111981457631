import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {
  CompanyIdDto,
  LocalDateRange,
  SalesLeadCriteriaDto,
  SalesLeadDto,
  UserDto,
  UserIdDto,
  UserSimpleDto,
} from '../../../bonding_shared/model/dtos';
import {SalesLeadService} from '../../../bonding_shared/services/sales-lead.service';
import {LoggedUserService} from '../../../bonding_shared/services/logged-user.service';

export class SalesLeadSearchCriteria {
  dataProvider: SearchDataProvider<SalesLeadCriteriaDto, SalesLeadDto>;

  constructor(private _salesLeadService: SalesLeadService, private _loggedUserService: LoggedUserService) {
    this.dataProvider = new SearchDataProvider<SalesLeadCriteriaDto, SalesLeadDto>(_salesLeadService);
    this.dataProvider.searchCriteria.criteria = <SalesLeadCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.client = <CompanyIdDto>{};
    this.dataProvider.searchCriteria.criteria.creationDateRange = <LocalDateRange>{};
    this.dataProvider.textSearch = false;
  }
}
