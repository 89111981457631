import {NgModule} from '@angular/core';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {UserLanguageComponent} from './user-language.component';
import {BrowserModule} from '@angular/platform-browser';
import {BusinessLogService, FeatureService, LanguageService} from '../../bonding_shared/services';
import {FailedLoginAlertComponent} from '../../bonding_shared/components/topnav/failed-login-alert.component';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {PasswordExpiringComponent} from './password-expiring.component';

@NgModule({
  imports: [BsDropdownModule, BrowserModule, SharedComponentsModule],
  declarations: [UserLanguageComponent, FailedLoginAlertComponent, PasswordExpiringComponent],
  exports: [UserLanguageComponent, FailedLoginAlertComponent, PasswordExpiringComponent],
  providers: [LanguageService, BusinessLogService, FeatureService],
})
export class SharedTopNavModule {}
