import {Routes} from '@angular/router';

import {PortalLoginRoutes} from './portal-login/index';
import {PortalRoutes} from '../../../../src/client/app/portal/portal.routes';
import {PageNotFoundComponent, PageNotFoundRoutes} from '../../../../src/client/app/404/index';
import {BondVerifyRoutes} from './bond-verify';

export const portalRoutes: Routes = [
  ...PortalLoginRoutes,
  ...PortalRoutes,
  ...PageNotFoundRoutes,
  ...BondVerifyRoutes,
  {path: '**', component: PageNotFoundComponent},
];
