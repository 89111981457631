import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TaskSearchComponent} from './task-search.component';
import {BrowserModule} from '@angular/platform-browser';
import {TaskGuiService} from './shared/services/task-gui.service';
import {TaskDataComponent} from './components/task-data.component';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TaskListComponent} from './components/task-list.component';
import {TaskDetailsComponent} from './task-details.component';
import {CompanyModule} from '../company/company.module';
import {BrokerContractModule} from '../broker-contract/broker-contract.module';
import {TaskSharedModule} from './shared/task-shared.module';
import {UserOrGroupDirective} from './directives/validators/user-or-group.directive';
import {TaskAuditService} from './services/task-audit.service';
import {DueDateNotBeforeCreationDirective} from './directives/validators/due-date-not-before-creation.directive';
import {TaskAccessGuard} from './components/task-access-guard';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanyModule,
    BrokerContractModule,
    TaskSharedModule,
  ],
  declarations: [
    TaskSearchComponent,
    TaskListComponent,
    TaskDetailsComponent,
    TaskDataComponent,
    UserOrGroupDirective,
    DueDateNotBeforeCreationDirective,
  ],
  providers: [TaskGuiService, TaskAuditService, TaskAccessGuard],
})
export class TaskModule {}
