import {Component, Input} from '@angular/core';
import {OtherCompanyRoleDto} from '../../bonding_shared/model';
import {CompanyService, RouterService} from '../../bonding_shared/services';
import {BusinessObjectType} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'other-company-roles',
  templateUrl: './other-company-roles.component.pug',
})
export class OtherCompanyRolesComponent {
  @Input() set companyId(companyId: number) {
    this.companyService.getOtherCompanyRoles(companyId).subscribe((otherCompanyRoles) => {
      this.otherCompanyRoles = otherCompanyRoles;
    });
  }

  public otherCompanyRoles: OtherCompanyRoleDto[];

  constructor(public router: RouterService, private companyService: CompanyService) {}

  routeToBusinessObject(role: OtherCompanyRoleDto) {
    switch (role.businessObject.relatedTo.id) {
      case BusinessObjectType.POLICY_VERSION:
        this.router.toPolicyContractPreview(role.businessObject.relatedToId);
        break;
      case BusinessObjectType.LIMIT_DECISION:
        this.router.toLimitDecision(role.businessObject.relatedToId);
        break;
    }
  }
}
