import {
  ClaimCalculationDto,
  ClaimCalculationPayoutPreparationDataDto,
  ClaimDto,
  ClaimService,
  GrowlService,
  RouterService,
} from '../../bonding_shared';
import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {ClaimCalculationService} from '../../bonding_shared/services/claim-calculation.service';
import {ActivatedRoute, Params} from '@angular/router';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'claim-calculation-payout-preparation',
  templateUrl: 'claim-calculation-payout-preparation.component.pug',
})
export class ClaimCalculationPayoutPreparationComponent extends DetailsView implements OnInit {
  payoutPreparationData = <ClaimCalculationPayoutPreparationDataDto>{};
  calculation = <ClaimCalculationDto>{};
  claim = <ClaimDto>{};
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  isCompensation: boolean;

  public constructor(
    private _route: ActivatedRoute,
    private service: ClaimCalculationService,
    private claimService: ClaimService,
    public router: RouterService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.newVersionButton = undefined;
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
  }

  ngOnInit(): void {
    this.form = this.ngForm.form;
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    super.initializeView(params);
    this.service.getCalc(+params['calcId']).subscribe((calculation) => {
      this.calculation = calculation;
      this.payoutPreparationData = calculation.payoutPreparationData || <ClaimCalculationPayoutPreparationDataDto>{};
      this.isCompensation = !!this.payoutPreparationData.compensationAmount;
      this.claimService.getClaim(calculation.claim.id).subscribe((claim) => {
        this.claim = claim;
      });
    });
  }

  onSave() {
    this.serverErrors = undefined;
    this.showErrors = false;
    if (!this.isCompensation) {
      this.payoutPreparationData.compensationAmount = null;
    }
    if (this.formValidates()) {
      this.inProgress = true;
      this.calculation.payoutPreparationData = this.payoutPreparationData;
      this.service.saveCalc(this.calculation).subscribe({
        next: (savedCalculation) => {
          this.afterObjectSaved();
          this.calculation = savedCalculation;
          this.payoutPreparationData = savedCalculation.payoutPreparationData;
          this.isCompensation = !!savedCalculation.payoutPreparationData.compensationAmount;
        },
        error: (error) => {
          this.serverErrors = error;
          this.inProgress = false;
        },
      });
    }
  }

  onCancel() {
    super.onCancel(this._route);
  }
}
