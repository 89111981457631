import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  AppConfigService,
  DictionaryBaseDto,
  DictionaryBaseService,
  DocumentService,
  GrowlService,
  InquiryService,
  LoggedUserService,
  PolicyContractPreviewDto,
  PolicyContractVersionService,
  PolicyLimitListService,
  ProductLimitListVersionCriteriaDto,
  ProductLimitListVersionSimpleDto,
  PropertyService,
  RepositoryService,
  RouterService,
  SearchDataProvider,
  TemplateService,
  TemplateSimpleDto,
  UserDto,
} from '../../bonding_shared';
import {PolicyContractViewSection, PolicyContractViewService} from './services/policy-contract-view.service';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {PolicyContractGuiService} from './services/policy-contract-gui.service';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  AppProperty,
  BusinessObjectType,
  ContactNoteElementaryRight,
  DocumentType,
  KukeRepoOperation,
  Language,
  LimitRequestType,
  PolicyContractType,
  PolicyContractTypes,
  PolicyContractVersionStatus,
  PolicyElementaryRight,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
  ServiceNoteElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'policy-contract-preview',
  templateUrl: './policy-contract-preview.component.pug',
})
export class PolicyContractPreviewComponent extends DetailsView implements OnInit {
  data: PolicyContractPreviewDto;
  policyContractVersionId: number;
  policyLimitListDataProvider: SearchDataProvider<ProductLimitListVersionCriteriaDto, ProductLimitListVersionSimpleDto>;
  longLabel = false;
  bonusMalusRequestTypes: DictionaryBaseDto[] = [];
  templates: TemplateSimpleDto[] = [];
  pcvTemplate: TemplateSimpleDto;
  readonly BusinessObjectType = BusinessObjectType;
  readonly LimitRequestType = LimitRequestType;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  readonly PolicyElementaryRight = PolicyElementaryRight;
  readonly PolicyContractVersionStatus = PolicyContractVersionStatus;
  readonly PolicyContractType = PolicyContractType;
  viewSection = PolicyContractViewSection;
  readonly AppProperty = AppProperty;

  statProperties = [
    {name: 'policyContract.preview.stats.policyYear', property: 'policyYearFormatted', datatype: 'policyLink'},
    {name: 'policyContract.preview.stats.policySubYear', property: 'policySubYearFormatted'},
    {name: 'common.validFrom', property: 'validFrom', datatype: 'date'},
    {name: 'common.validTo', property: 'validTo', datatype: 'date'},
    ...(this.mehib
      ? []
      : [{name: 'policyContract.preview.stats.globalLOL', property: 'globalLOL', datatype: 'currency'}]),
    {name: 'policyContract.preview.stats.minPremium', property: 'minPremium', datatype: 'currency'},
    this.kuke
      ? {name: 'policyContract.preview.stats.selfRetention', property: 'selfRetention', datatype: 'percent'}
      : {name: 'policyContract.preview.stats.insuranceCover', property: 'insuranceCover', datatype: 'percent'},
    {name: 'policyContract.preview.stats.premiumPaid', property: 'premiumPaid', datatype: 'currency'},
    {name: 'policyContract.preview.stats.claimsPaid', property: 'claimsPaid', datatype: 'currency'},
    {name: 'policyContract.preview.stats.claimsReserve', property: 'claimsReserves', datatype: 'currency'},
    {name: 'policyContract.preview.stats.claimsPaidCount', property: 'claimsPaidCount'},
    {name: 'policyContract.preview.stats.claimsReserveCount', property: 'claimsReservesCount'},
    ...(this.mehib || this.ecg
      ? []
      : [{name: 'policyContract.preview.stats.aad', property: 'aad', datatype: 'currency'}]),
  ];

  constructor(
    private policyContractVersionService: PolicyContractVersionService,
    private route: ActivatedRoute,
    public router: RouterService,
    private policyContractViewService: PolicyContractViewService,
    private policyLimitListService: PolicyLimitListService,
    private userService: LoggedUserService,
    protected growlService: GrowlService,
    public appService: AppConfigService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected inquiryService: InquiryService,
    protected templateService: TemplateService,
    protected repositoryService: RepositoryService,
    public documentService: DocumentService,
    protected guiService: PolicyContractGuiService,
    private propertyService: PropertyService
  ) {
    super(growlService);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.initializeView(params));
    this.longLabel = this.isLongLabel();
    this.loadTemplates();
  }

  loadTemplates() {
    this.loadTemplatesByType(DocumentType.WZ, BusinessObjectType.POLICY_VERSION);
    this.loadTemplatesByType(DocumentType.WZRU, BusinessObjectType.POLICY_VERSION);
    this.templateService
      .findByType(DocumentType.KUKE_POLICY, BusinessObjectType.POLICY_VERSION, null)
      .subscribe((result) => {
        if (result && result.length > 0) {
          this.pcvTemplate = result[0];
        }
      });
  }

  loadTemplatesByType(type: DocumentType, boType: BusinessObjectType) {
    this.templateService.findByType(type, boType, null).subscribe((result) => {
      if (result !== undefined && result !== null) {
        result.forEach((r) => this.templates.push(r));
      }
    });
  }

  isLongLabel(): boolean {
    const user: UserDto = this.userService.getLoggedUserData();
    return user && user.language && (user.language.id === Language.GERMAN || user.language.id === Language.POLISH);
  }

  initializeView(params: Params) {
    this.policyContractVersionId = +params['id'];
    this.policyContractVersionService.getPreview(this.policyContractVersionId).subscribe((res) => {
      this.data = res;
      if (this.guiService.justCreatedPolicy && this.guiService.justCreatedPolicy.id === this.policyContractVersionId) {
        this.serverErrors = this.guiService.justCreatedPolicy.infos;
      }
      this.guiService.justCreatedPolicy = undefined;
      this.initializePolicyLimitListDataProvider();
      if (this.mehib && PolicyContractTypes.MEHIB_ST.includes(res.contractType.id)) {
        this.statProperties.push({name: 'policyContract.preview.stats.dmIndex', property: 'dmIndex'});
      }
    });
    this.dictionaryBaseService
      .getDictionaryBase('BonusMalusRequestType')
      .subscribe((r) => (this.bonusMalusRequestTypes = r));
  }

  yearTitle(index: number) {
    if (index > 0) {
      return 'CY - ' + index;
    }
    return 'CY';
  }

  style() {
    switch (this.data.stats.length) {
      case 4:
        return 'width: 20%';
      case 3:
        return 'width: 26%';
      case 2:
        return 'width: 40%';
      default:
        return 'width: 80%';
    }
  }

  initializePolicyLimitListDataProvider() {
    this.policyLimitListDataProvider = BusinessUtils.createPolicyLimitListDataProvider(this.policyLimitListService);
    this.policyLimitListDataProvider.textSearch = false;
    this.policyLimitListDataProvider.searchCriteria.criteria.limitList.masterPolicyContract.id =
      this.data.masterPolicyId;
  }

  createNewRenewalInquiry() {
    this.inquiryService.createRenewalInquiry(this.policyContractVersionId).subscribe({
      next: (inquiryVersionId) => this.router.toPolicyInquiryDetails(inquiryVersionId),
      error: () => this.growlService.error('policyContract.preview.newRenewalInquiryError'),
    });
  }

  declarationButtonVisible() {
    return (
      this.isSectionVisible(this.viewSection.TURNOVER_REPORTING, true) &&
      this.data &&
      PolicyContractViewService.policyAtLeastActivatedOrLocked(this.data.businessStatus)
    );
  }

  showCession(): boolean {
    return this.isSectionVisible(PolicyContractViewSection.POLICY_CONTRACT_DATA__CESSION, true);
  }

  outstandings(): boolean {
    return this.data && this.data.contractType && this.data.contractType.id === PolicyContractType.KUKE_KOM_SP;
  }

  onPrintSelected(template: TemplateSimpleDto): void {
    this.router.toDocumentDetailsNewOperations(
      KukeRepoOperation.WORDINGS,
      template.id,
      this.policyContractVersionId,
      template.type.id
    );
  }

  onSigningSelected(template: TemplateSimpleDto): void {
    this.router.toDocumentDetailsNewOperations(
      KukeRepoOperation.SIGNIGS,
      template.id,
      this.policyContractVersionId,
      template.type.id
    );
  }

  toPolicyServiceContacts() {
    this.router.toServiceContacts({
      boTypeId: BusinessObjectType.MASTER_POLICY,
      boId: this.data.masterPolicyId,
      returnBoTypeId: BusinessObjectType.POLICY_VERSION,
      returnBoId: this.policyContractVersionId,
    });
  }

  repoBOTypeId(): number {
    return Number(this.propertyService.properties[AppProperty.BOTYPE_FOR_POLICIES]);
  }

  repoBOId(): number {
    switch (this.propertyService.properties[AppProperty.BOTYPE_FOR_POLICIES]) {
      case BusinessObjectType.MASTER_POLICY:
        return this.data.masterPolicyId;
      case BusinessObjectType.POLICY:
        return this.data.policyId;
      case BusinessObjectType.POLICY_VERSION:
        return this.policyContractVersionId;
    }
  }

  isSectionVisible(
    viewSection: PolicyContractViewSection,
    defaultVisibility = false,
    globalConditionsOfInsuranceId = null
  ) {
    if (this.data && this.data.contractType && this.data.contractType.id) {
      return this.policyContractViewService.isSectionVisible(
        this.data.contractType.id,
        viewSection,
        defaultVisibility,
        globalConditionsOfInsuranceId
      );
    }
  }

  openPolicyPreview() {
    this.router.toPolicyContractReadOnly(this.policyContractVersionId);
  }

  get arePolicyDrawdownsApplicable() {
    return (
      this.data &&
      !this.data.commitment &&
      this.data.contractType &&
      [
        PolicyContractType.BUYER_CREDIT_V,
        PolicyContractType.SUPPLIER_CREDIT_S,
        PolicyContractType.SUPPLIER_CREDIT_DISCOUNTING_KV,
        PolicyContractType.TIED_AID_TAI,
        PolicyContractType.FOREIGN_INVESTMENT_B,
        PolicyContractType.PRESHIPMENT_RISK_G,
      ].includes(this.data.contractType.id)
    );
  }

  get inStatusRejected() {
    return this.selectedPCVInOneOfStatuses([
      PolicyContractVersionStatus.REQUEST_REJECTED,
      PolicyContractVersionStatus.TERMINATION_REJECTED,
    ]);
  }

  private selectedPCVInOneOfStatuses(statuses: PolicyContractVersionStatus[]): boolean {
    return <boolean>statuses.reduce((prev, curr) => prev || this.selectedPCVInStatus(curr), undefined);
  }

  selectedPCVInStatus(status: PolicyContractVersionStatus): boolean {
    return this.data && this.data.status && this.data.status.id === status;
  }

  get masterPolicyId() {
    return this.data?.masterPolicyId;
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get mehib(): boolean {
    return this.appService.mehib;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }
  get credendo(): boolean {
    return this.appService.credendo;
  }
}
