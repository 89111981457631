import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CollectionVersionBaseDto, CollectionVersionCriteriaDto} from '../../../../bonding_shared/model';
import {SearchDataProvider} from '../../../../bonding_shared/services';
import {Subject} from 'rxjs';
import {CollectionGuiService} from '../../services/collection-gui.service';
import {CollectionVersionService} from '../../../../bonding_shared/services/collection-version.service';

@Component({
  selector: 'collection-version-selector',
  templateUrl: 'collection-version-selector.component.pug',
})
export class CollectionVersionSelectorComponent {
  dataProvider: SearchDataProvider<CollectionVersionCriteriaDto, CollectionVersionBaseDto>;

  @Input() labelKey = 'collection.searchVersion';
  @Input() open: Subject<boolean>;
  @Input() searchOnOpen: boolean;
  @Output() selectItem = new EventEmitter<CollectionVersionBaseDto>();

  @Input() set criteria(c: CollectionVersionCriteriaDto) {
    Object.assign(this.dataProvider.searchCriteria.criteria, c);
  }

  constructor(protected service: CollectionVersionService) {
    this.dataProvider = CollectionGuiService.createProvider(service);
  }

  selected(collectionVersion: CollectionVersionBaseDto) {
    this.open.next(false);
    this.selectItem.emit(collectionVersion);
  }
}
