import {BusinessPropertiesDto, DictionaryBaseDto, ReportDefinitionSimpleDto} from '../../model/dtos';
import {
  ElementaryRight,
  FinancialDocumentCategory,
  FINoteCategory,
  InvoiceCategory,
  InvoiceStatus,
} from '../../model/dictionary-ids';
import {BusinessPropertiesService, DictionaryBaseService, LoggedUserService, PropertyService} from '../../services';
import {ActivatedRoute, Params} from '@angular/router';
import {Directive, OnInit} from '@angular/core';
import {DetailsView} from '../details-view/details-view';
import {TranslateService} from '@ngx-translate/core';
import {GrowlService} from '../../services/growl/growl.service';
import {HttpParams} from '@angular/common/http';
import {GenerateReportIfc} from '../../services/abstract-service-interfaces';

/**
 * Common logic for Invoice Category solution
 */

@Directive()
export abstract class AbstractInvoiceDetailsComponent extends DetailsView implements OnInit {
  categoryId: FinancialDocumentCategory;
  invoiceCategoryMap: {[index: string]: DictionaryBaseDto} = {};
  protected appProperties: {[index: string]: any};
  protected businessProperties: BusinessPropertiesDto;

  readonly REMOVE_STATUSES = [
    InvoiceStatus.TO_BE_REMOVED,
    InvoiceStatus.TO_BE_REMOVED_PRESERVING_ITEMS,
    InvoiceStatus.COMM_GESTURE,
  ];

  protected constructor(
    protected propertyService: PropertyService,
    protected businessPropertiesService: BusinessPropertiesService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected route: ActivatedRoute,
    protected loggedUserService: LoggedUserService,
    protected businessReportService: GenerateReportIfc
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.saveButton.disabled = this.createUpdateDisabled;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
    this.loadProperties();
    this.loadInvoiceCategories();
  }

  initializeView(params: Params, force?: boolean) {
    super.initializeView(params, force);
    const categoryStr: string = params['category'];
    this.categoryId = InvoiceCategory[categoryStr] || FINoteCategory[categoryStr];
  }

  get category(): string {
    return InvoiceCategory[this.categoryId] || FINoteCategory[this.categoryId];
  }

  get createUpdateDisabled() {
    return !this.loggedUserService.hasRight(ElementaryRight.INVOICE_CREATE_UPDATE) || this.inProgress;
  }

  private loadProperties() {
    this.appProperties = this.propertyService.properties;
    this.businessPropertiesService.getProperties().subscribe({
      next: (businessProperties) => {
        this.businessProperties = businessProperties;
        this.propertiesLoaded();
      },
      error: (error) => this.downloadErrorCallback(error),
    });
  }

  protected propertiesLoaded() {}

  protected loadInvoiceCategories() {
    this.dictionaryBaseService.getDictionaryBase('FinancialDocumentCategory').subscribe({
      next: (invoiceCategories) => {
        for (const invoiceCategory of invoiceCategories) {
          this.invoiceCategoryMap[invoiceCategory.id] = invoiceCategory;
        }
      },
      error: (error) => {
        console.log('loadInvoiceCategories error: ', error);
      },
    });
  }

  protected onReportDefinitionSelected(reportDefinition: ReportDefinitionSimpleDto, id: number) {
    let params = new HttpParams();
    params = params.append('invoiceId', id + '');
    this.serverErrors = undefined;
    this.businessReportService.generate(reportDefinition.id, reportDefinition.name + '.xlsx', params, (errorMsg) =>
      this.downloadErrorCallback(errorMsg)
    );
  }
}
