import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {CustomValidators} from '../../validators';
import {ValueWithMultiplier} from './shared/value-with-multiplier';

@Directive({
  selector: '[greaterThanValue]',
  providers: [{provide: NG_VALIDATORS, useExisting: GreaterThanValueDirective, multi: true}],
})
export class GreaterThanValueDirective implements Validator {
  @Input('greaterThanValue') greaterThanValue: number | ValueWithMultiplier;

  validate(control: AbstractControl): ValidationErrors | null {
    const f =
      this.greaterThanValue instanceof ValueWithMultiplier
        ? CustomValidators.greaterThanValue(this.greaterThanValue.value, this.greaterThanValue.baseMultiplier)
        : CustomValidators.greaterThanValue(this.greaterThanValue);
    return f(control);
  }
}
