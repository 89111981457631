import {Component, Input, OnInit, SkipSelf} from '@angular/core';
import {
  BondingExternalContractDto,
  BondingContractInquiryVersionCoreDto,
  BondingContractInquiryVersionDto,
  DictionaryBaseDto,
} from '../../../../bonding_shared/model';
import {ContractType, Currency} from '../../../../bonding_shared/model/dictionary-ids';
import {ControlContainer, NgModelGroup} from '@angular/forms';
import {NumberUtils} from '../../../../bonding_shared/utils/number-utils';
import {DictionaryBaseService} from '../../../../bonding_shared/services';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';

@Component({
  selector: 'bonding-contract-inquiry-multi-product',
  templateUrl: './bonding-contract-inquiry-multi-product.pug',
  // The provider makes the component reuse the existing parent ngForm or ngModelGroup
  // https://en.it1352.com/article/1887831.html
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class BondingContractInquiryMultiProductComponent implements OnInit {
  @Input() inquiryVersion: BondingContractInquiryVersionDto;
  @Input() showErrors = false;

  readonly ContractType = ContractType;
  productTypes: DictionaryBaseDto[];

  constructor(private dictionaryBaseService: DictionaryBaseService, public parentModel: NgModelGroup) {}

  ngOnInit() {
    this.dictionaryBaseService.getDictionaryBase('ContractType').subscribe((types) => (this.productTypes = types));
  }

  addOrRemoveProduct(add: boolean, productTypeId: number) {
    if (add) {
      this.addNewProduct(productTypeId);
    } else {
      const product = this.inquiryVersion.versions.filter((p) => p.multiProductType.id === productTypeId)[0];
      NumberUtils.deleteItemFirstOccurrence(this.inquiryVersion.versions, product);
    }
  }

  addNewProduct(productTypeId: number) {
    const defaultCurrency = BusinessUtils.isGSPContract(productTypeId) ? Currency.EUR : Currency.PLN;
    const product = <BondingContractInquiryVersionCoreDto>{
      multiProductType: {id: productTypeId},
      currency: {id: defaultCurrency},
      bondTypes: [],
    };
    if (
      [
        ContractType.GSP_INDIVIDUAL_CONTRACT,
        ContractType.GSP_CREDIT_PAYMENT,
        ContractType.GSP_INDIVIDUAL_PAYMENT,
        ContractType.GSP_GENERAL_PAYMENT,
      ].includes(productTypeId)
    ) {
      product.externalContract = <BondingExternalContractDto>{};
      product.externalContract.consortiumBeneficiaryCompanies = [];
      product.externalContract.consortiumClientCompanies = [];
    }
    this.inquiryVersion.versions.push(product);
  }

  getLabel(productTypeId: number) {
    const type = this.productTypes.filter((t) => t.id === productTypeId)[0];
    return type.name;
  }
}
