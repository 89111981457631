import {Component} from '@angular/core';
import {KukePolicyProduct} from './kuke/kuke-policy-product';
import {ControlContainer, NgForm} from '@angular/forms';
import {PolicyContractVersionService} from '../../../../bonding_shared/services';

@Component({
  selector: 'kuke-policy-product',
  templateUrl: './kuke-policy-product.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KukePolicyProductComponent extends KukePolicyProduct {
  constructor(policyContractVersionService: PolicyContractVersionService) {
    super(policyContractVersionService);
  }
}
