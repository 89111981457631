import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {isNumeric} from '../../utils/number-utils';

@Directive({
  selector: '[lessEqThanOtherField]',
  providers: [{provide: NG_VALIDATORS, useExisting: LessEqThanOtherFieldDirective, multi: true}],
})
export class LessEqThanOtherFieldDirective implements Validator {
  @Input('lessEqThanOtherField') lessEqThanOtherField: any;

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.parent && control.parent.get(this.lessEqThanOtherField.field)) {
      let v: any = control.parent.get(this.lessEqThanOtherField.field).value;
      if (isNumeric(v)) {
        v = <any>v * (this.lessEqThanOtherField.baseMultiplier || 1);
        const controlValue = control.value * (this.lessEqThanOtherField.baseMultiplier || 1);
        return controlValue > v ? {lessOrEqThanValue: {requiredValue: v, actualValue: controlValue}} : null;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
