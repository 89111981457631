import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {DictionaryDto, PolicyClauseDto, PolicyContractVersionBaseDto, PolicyInquiryOfferSimpleDto} from '../model';
import {Cacheable} from 'ts-cacheable';

@Injectable()
export class PolicyClauseService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'policyClause';
  }

  public initialVersion(
    policyVersionId: number,
    offerId: number,
    categoryId: number,
    typeId: number
  ): Observable<PolicyClauseDto> {
    const params = UrlParams.new()
      .add('categoryId', categoryId)
      .addIf(!!typeId, 'typeId', () => typeId);
    const path = policyVersionId ? 'policy/' + policyVersionId : 'offer/' + offerId;
    return this.get<PolicyClauseDto>(this.buildUrl(this.url + '/initialVersion/' + path, null, params));
  }

  public getFields(
    policyVersion: PolicyContractVersionBaseDto,
    offer: PolicyInquiryOfferSimpleDto,
    typeId: number
  ): Observable<DictionaryDto[]> {
    const params = UrlParams.new()
      .addIf(!!policyVersion, 'policyVersionId', () => policyVersion.id)
      .addIf(!!offer, 'offerId', () => offer.id);
    return this.get<DictionaryDto[]>(this.buildUrl(this.url + '/customFields/' + typeId, null, params));
  }

  @Cacheable()
  public getClauseTypes(gciId: number): Observable<DictionaryDto[]> {
    const params = UrlParams.new().addIf(!!gciId, 'gciId', () => gciId);
    return this.get<DictionaryDto[]>(this.buildUrl(this.url + '/clauseTypes', null, params));
  }
}
