<form *ngIf="invoiceItem" #templateForm="ngForm">

  <div class="bon-card-inner-group">
    <div class="bon-card-inner">
      <company-section labelKey="invoiceItem.shared.data.client" [(ngModel)]="invoiceItem.client" #model="ngModel"
                       [clearable]="false" [readOnly]="editionBlocked()"
                       [openSelectorEmitter]="openSelectorEmitters['Client']" [showLink]="true"
                       [readOnly]="false" [control]="model" [showErrors]="showErrors" name="client" [required]="true">
      </company-section>
    </div>
    <div class="bon-card-inner">
      <invoice-contract-link [contractLink]="invoiceItem.contractLink"></invoice-contract-link>
    </div>

    <div class="bon-card-inner">
      <div class="bon-card-group">
        <div class="bon-card-inner bon-label-150">
          <div *ngIf="invoiceItem.sourceItem || invoiceItem.sourceInvoice" class="bon-row">
            <label class="bon-label" translate>invoiceItem.shared.data.sourceItem</label>
            <a *ngIf="invoiceItem.sourceItem" (click)="toInvoiceItem(invoiceItem.sourceItem)" class="a-link">
              {{invoiceItem.sourceItem.creationDate | date }} {{invoiceItem.sourceItem.netAmount}} {{invoiceItem.sourceItem.currency.code}}
            </a>
            <a *ngIf="invoiceItem.sourceInvoice" (click)="toInvoice(invoiceItem.sourceInvoice)" class="a-link" style="margin-left: 15px;">
              {{formatInvoiceNumber(invoiceItem.sourceInvoice)}}
            </a>
          </div>
          <div *ngFor="let childItem of invoiceItem.childItems" class="bon-row">
            <label class="bon-label">{{getInvoiceItemCategoryLabel(childItem.category)}}</label>
            <a (click)="toInvoiceItem(childItem)" class="a-link">
            {{childItem.creationDate | date }} {{childItem.netAmount}} {{childItem.currency.code}}
            </a>
            <a *ngIf="childItem.invoicePosition" (click)="toInvoice(childItem.invoicePosition.invoice)" class="a-link" style="margin-left: 15px;">
              {{formatInvoiceNumber(childItem.invoicePosition.invoice)}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bon-card-group">
    <div class="bon-card-inner">
      <company-selector-simple (selectItem)="onCompanySelect($event)" labelKey="invoiceItem.shared.data.clientSearch"
        [open]="openSelectorEmitters['Client']" [showDuplicates]="false" [searchModeSwitchable]="true">
      </company-selector-simple>
    </div>
  </div>

  <bon-section labelKey="invoiceItem.shared.data.details">
    <div class="bon-card-group">
      <div class="bon-card-inner">
        <input-row labelKey="invoiceItem.shared.data.title" [(ngModel)]="invoiceItem.title"
                   [showErrors]="showErrors" name="title" [required]="true" [disabled]="editionBlocked()">
        </input-row>
        <num-row labelKey="invoiceItem.shared.data.netAmount" [(ngModel)]="invoiceItem.netAmount"
                 [showErrors]="showErrors" name="netAmount"
                 [required]="true" [allowNegative]="true" [disabled]="editionBlocked()"></num-row>
        <dict-row labelKey="invoiceItem.shared.data.currency" [(ngModel)]="invoiceItem.currency" dictionary="Currency"
                  itemLabel="code" [showErrors]="showErrors" name="currency" [required]="true"
                  [disabled]="invoiceItem.invoicePosition"></dict-row>
        <dict-row labelKey="invoiceItem.shared.data.taxCountry" [(ngModel)]="invoiceItem.taxCountry"
                  dictionary="Country" itemLabel="code" nullLabel="-"
                  (changeItem)="taxCountryChanged()" [ngModelOptions]="{standalone: true}"
                  [disabled]="editionBlocked()"></dict-row>
        <form-row *ngIf="provinces && provinces.length > 0" labelKey="invoiceItem.shared.data.province">
          <item-combo class="bon-input-size" [items]="provinces" [(ngModel)]="invoiceItem.province" label="name"
                      nullLabel="-" [ngModelOptions]="{standalone: true}">
          </item-combo>
        </form-row>
        <a-table #itemTaxTable [items]="invoiceItemTaxes" [editable]="!editionBlocked()" groupName="itemTaxTable">
          <column labelKey="invoiceItem.shared.data.taxCategory" property="taxCategory" type="dictionary"
                  dictionary="TaxCategory"
                  [required]="true" [unique]="true"></column>
          <column labelKey="invoiceItem.shared.data.taxRate" property="taxType" type="dictionary" dictionary="TaxType"
                  [required]="true" (cellChange)="taxTypeChanged($event)"></column>
          <column labelKey="invoiceItem.shared.data.taxRateValue">
            <ng-template let-item="item">
              {{formatTax(item)}}
            </ng-template>
          </column>
        </a-table>

      </div>
      <div class="bon-card-inner bon-label-150">
        <dict-row labelKey="invoiceItem.shared.data.status" [(ngModel)]="invoiceItem.status"
                  dictionary="InvoiceItemStatus"
                  [showErrors]="showErrors" name="status" [required]="true" [codeRegexp]="invoiceItemStatusRegexp">
        </dict-row>
        <dict-row labelKey="invoiceItem.shared.data.type" dictionary="InvoiceItemType" [(ngModel)]="invoiceItem.type"
                  name="type"
                  [disabled]="editionBlocked()" (changeItem)="invoiceItemTypeChanged($event)"></dict-row>
        <dict-row labelKey="invoiceItem.shared.data.subtype" dictionary="InvoiceItemSubtype"
                  [(ngModel)]="invoiceItem.subtype" name="subtype" nullLabel="-"
                  parentDictionary="InvoiceItemType" [parentDictionaryEntryId]="invoiceItem?.type?.id"
                  [disabled]="editionBlocked()"></dict-row>
        <date-row labelKey="invoiceItem.shared.data.dateFrom" [(ngModel)]="invoiceItem.dateFrom" name="dateFrom"
                  [ngModelOptions]="{standalone: true}" [disabled]="editionBlocked()">
        </date-row>
        <date-row labelKey="invoiceItem.shared.data.dateTo" [(ngModel)]="invoiceItem.dateTo" name="dateTo"
                  [ngModelOptions]="{standalone: true}" [disabled]="editionBlocked()">
        </date-row>
        <a-table [items]="invoiceItem.treaties" [editable]="!editionBlocked()" [formModel]="form">
          <column labelKey="treaty.section.treaty" property="treaty" type="combo" [comboItems]="treaties" comboLabel="number" [required]="true"></column>
          <column labelKey="treaty.section.proportion" property="proportion" type="number" numberOfDecimalPlaces="6" [required]="true"></column>
        </a-table>

        <button class="btn-sm btn-info btn-rounded" type="button" style="float:right;" (click)="updateTreaties()" translate>invoiceItem.shared.data.calculate</button>

        <form-row labelKey="invoiceItem.shared.data.manualCostCenter" [control]="this.templateForm.controls.costCenter"
                  [showErrors]="showErrors && manualCostCenter">
          <input type="checkbox" [(ngModel)]="manualCostCenter" name="manualCostCenter"
                 (ngModelChange)="manualCostCenterChanged($event)"
                 class="bon-checkbox" style="width: auto; min-width: 30px;">
          <input *ngIf="manualCostCenter" [(ngModel)]="invoiceItem.costCenter" name="costCenter" #model="ngModel"
                 [required]="true"
                 class="bon-input" style="width: calc(100% - 180px);">
        </form-row>
      </div>

      <div class="bon-card-inner bon-label-150">
        <form-row labelKey="invoiceItem.shared.data.businessUnit">
          <business-unit-id-selector class="bon-input-size" [(ngModel)]="invoiceItem.businessUnit" name="businessUnit"
                                     [disabled]="!!invoiceItem.invoicePosition"></business-unit-id-selector>
        </form-row>

        <input-row labelKey="invoiceItem.shared.data.contractNumber" [(ngModel)]="invoiceItem.contractNumber" name="contractNumber"
                   [ngModelOptions]="{standalone: true}" disabled="true">
        </input-row>
        <input-row labelKey="invoiceItem.shared.data.businessObjectNumber" [(ngModel)]="invoiceItem.businessObjectNumber" name="businessObjectNumber"
                   [ngModelOptions]="{standalone: true}" disabled="true">
        </input-row>
        <form-row labelKey="invoiceItem.shared.data.creationDate">{{invoiceItem.creationDate | date}}</form-row>
        <form-row *ngIf="invoiceItem.issueDate" labelKey="invoiceItem.shared.data.issueDate">{{invoiceItem.issueDate | date}}</form-row>
        <form-row labelKey="invoice.shared.data.createdBy">
          <span class="bon-input">{{invoiceItem.createdBy?.fullName}} - {{invoiceItem.source?.name}}</span>
        </form-row>
        <textarea-row labelKey="invoiceItem.shared.data.comment" [(ngModel)]="invoiceItem.comment" maxlength="2048" name="comment"></textarea-row>
      </div>

    </div>
  </bon-section>

  <bon-section labelKey="invoiceItem.shared.data.linkedObject">
    <div class="bon-card-group">
      <div class="bon-card-inner">
        <business-object-selector [(ngModel)]="invoiceItem.businessObject"
                                  name="businessObject"
                                  (ngModelChange)="onBusinessObjectSelect($event)"
                                  [disabled]="editionBlocked()">
        </business-object-selector>
      </div>
      <div class="bon-card-inner">
      </div>
    </div>
  </bon-section>
</form>
