/**
 * Created by siminski on 30.06.2016.
 */
import {map} from 'rxjs/operators';
import {ContentChild, Directive, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {DictionaryService} from '../../services';
import {DictionaryBaseDto, DictionaryDto} from '../../model';
import {DictionaryProfile} from '../../model/dictionary-ids';
import {Observable} from 'rxjs';

@Directive()
export abstract class DictBaseComponent implements OnInit {
  @Input() nullLabel: string;
  @Input() nullLabelKey: string;
  @Input() parentDictionary: string;
  @Input() allForEmptyParent;
  @Input() dictionary: string;
  @Input() disabled: boolean;
  @Input() label: 'name' | 'code' | 'codeAndName' = 'name';
  @Input() increaseBottomPadding = false;
  @Input() presentationMode = false;
  @Input() floatRight = false;
  @Input() externalTemplate: TemplateRef<any>;

  /**
   * The items that will not be shown in a list. This should be be stored as set,
   * i.e.  hiddenIds[12] = true;
   */
  _hiddenIds: Set<number>;

  @Input() set hiddenIds(hiddenIds: Set<number>) {
    if (hiddenIds && this._hiddenIds !== hiddenIds) {
      this._hiddenIds = hiddenIds;
      if (this.items) {
        this.filterEntries();
      }
    }
  }

  get hiddenIds() {
    return this._hiddenIds;
  }

  @Output() changeItem = new EventEmitter<DictionaryBaseDto>();

  get template() {
    return this.externalTemplate || this.internalTemplate;
  }

  @ContentChild(TemplateRef, {static: true})
  set template(template: TemplateRef<any>) {
    this.internalTemplate = template;
  }

  private internalTemplate: TemplateRef<any>;
  private _codeRegexp: RegExp;
  private _nameRegexp: RegExp;
  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  public _selectedKey: any;
  private selectedItem: DictionaryBaseDto;
  // Item set from model. May be possibly incorrect.
  private selectedItemFromExternal: any;
  private entriesInitialized = false;

  public items: DictionaryBaseDto[];
  public itemsFiltered: DictionaryBaseDto[] = [];
  public errorMessage: string;
  private parentEntries: DictionaryDto[];
  private _parentDictionaryEntryId: number;
  private _buId: number;
  private _profileId: DictionaryProfile;
  private _dictionaryUserRoleId: number;

  private comboInitialized = false;

  constructor(private dictService: DictionaryService) {}

  @Input() set entries(entries: DictionaryBaseDto[]) {
    if (entries) {
      this.initEntries(entries);
    }
  }

  @Input() set codeRegexp(codeRegexp: RegExp) {
    if (this._codeRegexp !== codeRegexp) {
      this._codeRegexp = codeRegexp;
      if (this.items) {
        this.filterEntries();
      }
    }
  }

  @Input() set nameRegexp(nameRegexp: RegExp) {
    if (this._nameRegexp !== nameRegexp) {
      this._nameRegexp = nameRegexp;
      if (this.items) {
        this.filterEntries();
      }
    }
  }

  get codeRegexp(): RegExp {
    return this._codeRegexp;
  }

  get nameRegexp(): RegExp {
    return this._nameRegexp;
  }

  @Input() set parentDictionaryEntryId(parentDictionaryEntryId: number) {
    if (this._parentDictionaryEntryId) {
      this.selectedKey = undefined;
      this.selectedItemFromExternal = undefined;
    }
    this._parentDictionaryEntryId = parentDictionaryEntryId;
    this.updateEntriesForParentDictionaryEntryId();
  }

  @Input() set buId(buId: number) {
    this._buId = buId;
    if (this.comboInitialized) {
      this.loadDictionaryEntries();
    }
  }

  @Input() set profileId(profileId: number) {
    this._profileId = profileId;
    if (this.comboInitialized) {
      this.loadDictionaryEntries();
    }
  }

  @Input() set dictionaryUserRoleId(dictionaryUserRoleId: number) {
    this._dictionaryUserRoleId = dictionaryUserRoleId;
    if (this.comboInitialized) {
      this.loadDictionaryEntries();
    }
  }

  ngOnInit() {
    this.loadDictionaryEntries();
    this.comboInitialized = true;
  }

  get selectedKey(): any {
    return this._selectedKey;
  }

  set selectedKey(k: any) {
    if (k !== this._selectedKey) {
      this._selectedKey = k;
      this.selectedItem = this.findSelectedItem(k);
      // Checking if component is used in form context
      if (this.onChangeListeners) {
        this.onChangeListeners(this.selectedItem);
      }
      this.changeItem.emit(this.selectedItem);
    }
  }

  // From ControlValueAccessor interface
  writeValue(it: any): void {
    this.selectedItemFromExternal = <DictionaryDto>it;
    this.updateSelection();
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private updateEntriesForParentDictionaryEntryId() {
    if (this.parentEntries && this._parentDictionaryEntryId) {
      this.initEntries(
        this.parentEntries
          .filter((entry) => entry.id === this._parentDictionaryEntryId)
          .map((entry) => entry.relatedDictionaries[this.dictionary])
          .reduce(function (prev, cur) {
            return prev.concat(cur);
          }, [])
      );
    } else if (this.allForEmptyParent) {
      this.loadAllEntries();
    }
  }

  private createFilteredDictObservable(dictionaryName: string): Observable<DictionaryDto[]> {
    return this._dictionaryUserRoleId
      ? this.dictService.getDictionaryFilteredByUserRole(dictionaryName, this._buId, this._dictionaryUserRoleId)
      : this.dictService.getDictionaryFiltered(dictionaryName, this._buId, this._profileId);
  }

  private loadDictionaryEntries() {
    if (this.parentDictionary) {
      this.createFilteredDictObservable(this.parentDictionary).subscribe(
        (entries) => {
          this.parentEntries = entries;
          this.updateEntriesForParentDictionaryEntryId();
        },
        (error) => (this.errorMessage = error)
      );
    } else {
      this.loadAllEntries();
    }
  }

  private loadAllEntries() {
    if (this.dictionary) {
      this.createFilteredDictObservable(this.dictionary)
        .pipe(
          map((entries) => {
            const baseEntries: DictionaryBaseDto[] = [];
            for (const entry of entries) {
              const outputItem = <DictionaryBaseDto>{};
              outputItem.id = entry.id;
              outputItem.code = entry.code;
              outputItem.active = entry.active;
              outputItem.name = entry.name;
              outputItem.dictName = entry.dictName;
              outputItem.description = entry.description;
              baseEntries.push(outputItem);
            }
            return baseEntries;
          })
        )
        .subscribe({
          next: (entries) => this.initEntries(entries),
          error: (error) => (this.errorMessage = error),
        });
    }
  }

  private initEntries(items: DictionaryBaseDto[]) {
    this.items = items;
    this.entriesInitialized = true;
    this.filterEntries();
  }

  private filterEntries() {
    let itemsFiltered: DictionaryBaseDto[] = [];
    this.itemsFiltered = [];
    if (this.codeRegexp || this.nameRegexp) {
      for (const it of this.items) {
        if (
          (this.codeRegexp && it.code && it.code.match(this.codeRegexp)) ||
          (this.nameRegexp && it.name && it.name.match(this.nameRegexp))
        ) {
          itemsFiltered.push(it);
        }
      }
    } else {
      itemsFiltered = this.items;
    }
    if (this.hiddenIds && this.hiddenIds.size > 0) {
      for (const it of itemsFiltered) {
        if (!this.hiddenIds.has(it.id)) {
          this.itemsFiltered.push(it);
        }
      }
    } else {
      this.itemsFiltered = itemsFiltered;
    }
    this.updateSelection();
  }

  private updateSelection() {
    if (this.selectedItemFromExternal && this.entriesInitialized) {
      const tempItem = this.findSelectedItem(this.selectedItemFromExternal);
      if (tempItem) {
        this.selectedItem = tempItem;
      }
      if (this.selectedItem) {
        this._selectedKey = this.selectedItem.id || this.selectedItem.code;
      } else if (this.selectedItemFromExternal.id) {
        this.items.push(this.selectedItemFromExternal);
        this.filterEntries();
        this._selectedKey = this.selectedItemFromExternal.id;
      }
    } else {
      this.selectedItem = undefined;
      this._selectedKey = undefined;
    }
  }

  private findSelectedItem(k: any): DictionaryBaseDto {
    if (k && this.items) {
      for (const it of this.items) {
        if (k.id && it.id && String(it.id) === String(k.id)) {
          return it;
        }
        if (it.id && String(it.id) === String(k)) {
          return it;
        } else if (it.code === k) {
          return it;
        }
      }
    }
    return undefined;
  }
}
