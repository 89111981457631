/**
 * Created by wilk on 31.12.2018.
 */
import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'error-message-simple',
  template: ` <span class="invalid inline">{{ key | translate }}</span> `,
})
export class ErrorMessageSimpleComponent {
  @HostBinding('style.display') display: string;

  @Input() key = 'errorMessage.required';

  _show = false;
  _invalid = false;

  @Input() set show(s: boolean) {
    this._show = s;
    this.refresh();
  }
  @Input() set invalid(inv: boolean) {
    this._invalid = inv;
    this.refresh();
  }

  refresh() {
    this.display = this._show && this._invalid ? 'block' : 'none';
  }
}
