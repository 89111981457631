<details-view [parentComponent]="self" modelObjectName="invoiceItem" [objectName]="getInvoiceItemCategoryLabel()"
              [title]="getInvoiceItemCategoryLabel()" [versionListHidden]="true"
              [fixedBlockHidden]="false">
  <div class="additional-buttons">
    <button class="bon-btn-info" *ngIf="newBrokerItemButtonVisible" btnIcon="fa-edit"
            (click)="newBrokerItem()" type="button" [disabled]="inProgress || createUpdateDisabled" translate>invoiceItem.details.newBrokerItem</button>
  </div>
  <div class="details-content">
    <invoice-item-data [header]="'Invoice Item Details'" [invoiceItem]="invoiceItem"
                       (errorOccured)="handleError($event)"
                       [categoryId]="categoryId"></invoice-item-data>
  </div>
  <div class="information-header">
    <b class="font-larger a-link" *ngIf="invoiceItem.invoicePosition" style='float: left; padding: 0px 4px;'
       (click)="toInvoice()">
      {{formatInvoiceNumber(invoiceItem.invoicePosition.invoice)}}
    </b>
  </div>

  <confirm-dialog #selectBrokerDialog class="modal-lg"
                  [confirmButtonDisabled]="!selectedBrokerContractRelationVersion"
                  [showCancelButton]="true"
                  [showRejectButton]="false"
  >
    <broker-contract-relation-list *ngIf="this.selectBrokerDialogVisible"
                                  [relatedToTypeId]="relatedToTypeId"
                                   [relatedToId]="relatedToId"
                                   [selection]="true"
                                   [showBrokerColumns]="true"
                                  (selectItem)="brokerContractRelationVersionSelectItem($event)"
    ></broker-contract-relation-list>
  </confirm-dialog>

</details-view>
