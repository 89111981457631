import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
/**
 * Created by wilk on 04.07.2018.
 */
import {DictionaryDto} from '../../model/dtos';
import {TreeNode} from 'primeng/api';
import {DictionaryService} from '../../services/dictionary.service';
import {BusinessUtils} from '../../utils/business-utils';

@Component({
  selector: 'dict-tree',
  template: `
    <p-tree [value]="tree" selectionMode="single" [(selection)]="selectedItem" (onNodeSelect)="nodeSelect($event)">
      <ng-template let-node pTemplate="default">
        <span>{{ node.label }}</span>
      </ng-template>
    </p-tree>
  `,
})
export class DictTreeComponent<T> implements OnInit {
  @Output() selectItem = new EventEmitter<TreeNode>();

  @Input() dictionary: string;
  @Input() showNodesWithoutChildren = false;

  // dictionary nodes can have none dictionary leafs
  // the list of leaf object can be provided as leafObjects
  // the callback function returns
  // - a TreeNode made out of the leaf object if the leaf object is linked to the given dictionary
  // - undefined otherwise
  @Input() leafObjectToTreeNodeCallback: (item: T, dictionaryId: number) => TreeNode;
  @Input() set leafObjects(items: T[]) {
    this._leafObjects = items;
    this.buildTree();
  }

  tree: TreeNode[];
  selectedItem: TreeNode;
  entries: DictionaryDto[];
  _leafObjects: T[];

  static createTreeNode(label: string, data: any, selectable: boolean, icon: string): TreeNode {
    const node: TreeNode = {};
    node.label = label;
    node.data = data;
    node.selectable = selectable;
    node.icon = icon;
    node.children = [];
    return node;
  }

  constructor(private dictService: DictionaryService) {}

  ngOnInit() {
    this.dictService.getDictionary(this.dictionary).subscribe(
      (entries) => this.initDictEntries(entries),
      (error) => console.log(error)
    );
  }

  initDictEntries(entries: DictionaryDto[]) {
    console.log('dict tree entries: ', entries);
    this.entries = entries;
    // parentDictionary is set here because of a problem with hibernate mapping on backend
    BusinessUtils.setParentInDictEntries(this.entries, this.entries, this.dictionary);
    console.log('dict tree entries with parent: ', entries);
    this.buildTree();
  }

  buildTree() {
    if (!this.entries || (this.leafObjectToTreeNodeCallback && !this._leafObjects)) {
      return;
    }
    this.tree = this.childNodesForParentDictEntry(undefined);
    this.tree.forEach((n) => (n.expanded = true));
  }

  createDictTreeNode(e: DictionaryDto): TreeNode {
    const node: TreeNode = DictTreeComponent.createTreeNode(e.name, e.id, false, null);
    node.children = this.childNodesForParentDictEntry(e);
    if (this._leafObjects) {
      node.children.push(
        ...this._leafObjects.map((v) => this.leafObjectToTreeNodeCallback(v, e.id)).filter((n) => !!n)
      );
    }
    return node;
  }

  childNodesForParentDictEntry(parentDict: DictionaryDto): TreeNode[] {
    return this.entries
      .filter(
        (c) =>
          (parentDict && c.parentDictionary && c.parentDictionary.id === parentDict.id) ||
          (!parentDict && !c.parentDictionary)
      )
      .map((c) => this.createDictTreeNode(c))
      .filter((n) => this.showNodesWithoutChildren || n.children.length > 0);
  }

  nodeSelect(event: any) {
    console.log('node selected:', event.node);
    this.selectItem.emit(event.node);
  }
}
