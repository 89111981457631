import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BondVersionDto,
  ContractVersionDto,
  DictionaryBaseDto,
  DictionaryDto,
  DocumentDescriptionDto,
  TemplateSimpleDto,
} from '../../../bonding_shared/model/dtos';
import {BondSubType} from '../../../bonding_shared/model/dictionary-ids';
import {DictionaryService} from '../../../bonding_shared/services/dictionary.service';
import {ClientBondFlowService} from '../domain/clientbond-flow.domain';
import {RepositoryService} from '../../../bonding_shared/services/repository.service';
import {ClientBondGuiService} from '../services/clientbond-gui.service';
import {LoggedUserService} from '../../../bonding_shared/services/logged-user.service';
import {BondVersionService} from '../../../bonding_shared';

@Component({
  selector: 'clientbond-subtype-selector',
  templateUrl: 'clientbond-subtype-selector.component.html',
})
export class ClientBondSubtypeSelectorComponent implements OnInit, AfterViewInit {
  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }

  @Input() set subtypeDisabled(value: boolean) {
    console.log('START subtypeDisabled value = ' + value);
    this._subtypeDisabled = value;
    console.log('START subtypeDisabled  = ' + this._subtypeDisabled);
  }

  @Input() subtype: DictionaryDto;
  @Input() parentBondType: DictionaryBaseDto;
  @Input() selectedContractVersion: ContractVersionDto;
  @Input() canIssue: boolean;
  @Input() showErrors: boolean;
  @Input() customTemplate: TemplateSimpleDto;
  @Input() bondTypeName: string;
  @Input() hideModeCreation = false;
  @Input() isSpecialBond = false;

  @Output() subtypeSelect = new EventEmitter<DictionaryBaseDto>();

  private _disabled: boolean;
  _subtypeDisabled = false;

  customTemplates: TemplateSimpleDto[];
  selectedCustomTemplate: TemplateSimpleDto;

  modeCreationKey = 'Standard';
  modeCreationKeyItems: string[] = ['Standard', 'UserTemplate', 'SpecialText'];

  private openedSpecialTextUpload = true;
  specialTextUploadFile: DocumentDescriptionDto = null;

  private subtypes: DictionaryDto[] = null;
  private subtypeInitialized = false;

  constructor(
    private dictService: DictionaryService,
    private bondFlow: ClientBondFlowService,
    private repositoryService: RepositoryService,
    public guiService: ClientBondGuiService,
    private loggedUserService: LoggedUserService,
    private bondVersionService: BondVersionService
  ) {}

  get disabled(): boolean {
    return this._disabled || this.subtypeInitialized;
  }

  ngOnInit() {
    const b = this.bondFlow.bond;
    if (b) {
      this.initCustomTemplate(b);
    }

    this.dictService.getDictionary('BondSubtype').subscribe((result) => {
      this.subtypes = result;
    });

    // it means bond is created as special
    if (this.isSpecialBond) {
      this.modeCreationKeyItems = ['UserTemplate', 'SpecialText'];
    }
  }

  ngAfterViewInit() {
    this.loadCustomTemplates();
  }

  initCustomTemplate(bond: BondVersionDto) {
    const customTemplate: TemplateSimpleDto = bond.customTemplate;

    this.subtypeInitialized = true;
    if (customTemplate || bond.subtype.id === BondSubType.TECHNICAL_USER_TEMPLATE) {
      this.modeCreationKey = 'UserTemplate';
      this.resetState(customTemplate, null);
    } else if (bond.subtype.id === BondSubType.TECHNICAL_USER_SPECIAL_TEXT) {
      this.modeCreationKey = 'SpecialText';
      this.resetState(null, this.specialTextUploadFile);
      this.bondVersionService.getSpecialTextDto(bond).subscribe((doc) => {
        this.specialTextUploadFile = doc;
      });
    } else {
      this.modeCreationKey = 'Standard';
      this.resetState();
    }
  }

  updateCustomTemplate(bond: BondVersionDto) {
    if (this.modeCreationKey === 'UserTemplate') {
      if (this.selectedCustomTemplate !== undefined && this.selectedCustomTemplate !== null) {
        bond.customTemplate = <TemplateSimpleDto>{};
        bond.customTemplate.id = this.selectedCustomTemplate.id;
        bond.specialTextRepositoryId = null;
        if (bond.customTemplate.language) {
          bond.language = bond.customTemplate.language;
        }
      }
    } else if (this.isSpecialTextMode) {
      bond.customTemplate = null;
      bond.specialTextRepositoryId = this.specialTextUploadFile.repositoryId;
    } else {
      bond.customTemplate = null;
      bond.specialTextRepositoryId = null;
    }
  }

  onSubtypeChange(subtype: DictionaryBaseDto) {
    this.subtypeInitialized = true;
    this.subtypeSelect.emit(subtype);
  }

  showSpeciaTextUpload(): boolean {
    return this.isSpecialTextMode && this.openedSpecialTextUpload && !this.disabled;
  }

  get isSpecialTextMode(): boolean {
    return this.modeCreationKey === 'SpecialText';
  }

  onSpecialTextUploadSelect(doc: DocumentDescriptionDto) {
    this.openedSpecialTextUpload = false;
    this.resetState(null, doc);
    this.onSubtypeSelectById(undefined, BondSubType.TECHNICAL_USER_SPECIAL_TEXT);
  }

  private onSubtypeSelectById(customTemplate: TemplateSimpleDto, subtypeId: number) {
    if (subtypeId) {
      const st = this.subtypes.find((i) => i.id === subtypeId);
      if (customTemplate) {
        this.guiService.setCustomTemplate(customTemplate);
      }
      this.subtypeSelect.emit(st);
      this.subtypeInitialized = true;
    } else {
      this.subtypeSelect.emit(null);
    }
  }

  onChangeCustomTemplate(ct: TemplateSimpleDto) {
    if (ct) {
      this.onSubtypeSelectById(ct, BondSubType.TECHNICAL_USER_TEMPLATE);
    } else {
      this.onSubtypeSelectById(undefined, null);
    }
  }

  onChangeModeCreationKey() {
    this.resetState();
    this.onSubtypeSelectById(undefined, null);
  }

  private resetState(sct: TemplateSimpleDto = null, stf: DocumentDescriptionDto = null) {
    this.selectedCustomTemplate = sct;
    this.specialTextUploadFile = stf;
  }

  private loadCustomTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    // todo: delete (or not) after feedback from business
    dictionarySelectors.push(this.parentBondType);

    const clientId = this.loggedUserService.getLoggedUserData().company.id;
    this.guiService
      .getClientBondCustomTemplates(dictionarySelectors, clientId, this.selectedContractVersion.contract.id)
      .subscribe((result) => {
        this.customTemplates = result;
      });
  }

  getSpecialTextFile() {
    if (this.specialTextUploadFile) {
      if (this.bondFlow.bond.id) {
        this.bondVersionService.getSpecialTextContent(this.bondFlow.bond, this.specialTextUploadFile.name);
      } else {
        this.bondVersionService.getSpecialTextContentFromContract(
          this.bondFlow.bond.contractVersionId,
          this.specialTextUploadFile.repositoryId,
          this.specialTextUploadFile.name
        );
      }
    }
  }
}
