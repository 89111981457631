import {Injectable} from '@angular/core';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {SalesLeadDto, StateTransitionOrGroupDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';
import {AbstractAuditedService} from './abstract-audited.service';

@Injectable()
export class SalesLeadService extends AbstractAuditedService<SalesLeadDto> {
  protected url = this.urlPrefix + 'salesLead';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getTransitions(s: SalesLeadDto): Observable<StateTransitionOrGroupDto[]> {
    return this.get(this.url + '/transitions/' + s.status.id);
  }

  initialVersion(): Observable<SalesLeadDto> {
    return this.get(this.url + '/initialVersion');
  }
}
