import {AuditedViewAbstract} from '../../../../bonding_shared/components/details-view/audited-view-abstract';
import {
  DictionaryBaseDto,
  GrowlService,
  IdDto,
  LoggedUserService,
  PolicyContractPreviewDto,
  PolicyContractVersionService,
  PolicyDrawdownDto,
  PolicyDrawdownVersionDto,
  RouterService,
} from '../../../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {AbstractAuditedService} from '../../../../bonding_shared/services/abstract-audited.service';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';

export abstract class PolicyDrawdownAbstractComponent<T extends ConfirmableElement> extends AuditedViewAbstract<T> {
  policyContractPreview: PolicyContractPreviewDto;
  availableDrawdownTypes: DictionaryBaseDto[];
  drawdownCodeRegExp: RegExp;

  protected constructor(
    route: ActivatedRoute,
    protected auditedService: AbstractAuditedService<T>,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    protected policyContractVersionService: PolicyContractVersionService,
    idParamName: string
  ) {
    super(route, auditedService, router, translateService, growlService, loggedUserService, idParamName);
  }

  initializeView(params: Params) {
    this.clearErrors();
    this.id = +params[this.idParamName];
    if (this.id) {
      this.initWithId();
    } else {
      this._route.queryParams.subscribe((queryParams) => {
        this.policyContractVersionService.getPreviewByMasterPolicyId(+queryParams['masterPolicyId']).subscribe((pc) => {
          this.policyContractPreview = pc;
          this.initWithoutId();
        });
      });
    }
  }

  onLoad() {
    super.onLoad();
    this.policyContractVersionService
      .getPreviewByMasterPolicyId(this.masterPolicyId)
      .subscribe((preview) => (this.policyContractPreview = preview));
  }

  toDrawdownNumber(drawdown: PolicyDrawdownDto): string {
    return drawdown && drawdown.number;
  }

  get masterPolicyId() {
    return this.current?.masterPolicyContract?.id;
  }

  get status() {
    return this.translateService.instant(this.current?.confirmed ? 'policyDrawdown.confirmed' : 'policyDrawdown.draft');
  }

  get sendConfirmationTaskPossible() {
    return (
      this.lastVersionSelected() && !(this.newVersion || this.current.userAllowedToConfirm || this.current.confirmed)
    );
  }

  get confirmationPossible() {
    return (
      this.lastVersionSelected() && this.current.userAllowedToConfirm && !(this.current.confirmed || this.newVersion)
    );
  }

  initializeAvailableDrawdownTypes(drawdownVersions: PolicyDrawdownVersionDto[]) {
    this.availableDrawdownTypes = _.uniqBy(
      drawdownVersions
        .map((dv) => dv.constituents.map((c) => c.type))
        .reduce((accumulator, value) => accumulator.concat(value), []),
      (x) => x.id
    );
    let regexpString = '';
    let index = 0;
    this.availableDrawdownTypes.forEach((adt) => {
      index += 1;
      regexpString += adt.code;
      if (index !== this.availableDrawdownTypes.length) {
        regexpString += '|';
      }
    });
    this.drawdownCodeRegExp = new RegExp(regexpString);
  }
}

interface ConfirmableElement {
  masterPolicyContract: IdDto;
  confirmed: boolean;
  userAllowedToConfirm: boolean;
}
