import {AbstractService} from './abstract.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {RejectManagementDto} from '../model';
import {Observable} from 'rxjs';

@Injectable()
export class RejectManagementService extends AbstractService {
  protected url = this.urlPrefix + 'rejectManagement';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  public getRejectManagement(id: number): Observable<RejectManagementDto> {
    return this.get<RejectManagementDto>(this.url + '/reject/' + id);
  }

  public complete(rejects: RejectManagementDto[]): Observable<RejectManagementDto[]> {
    return this.post<RejectManagementDto[]>(rejects, this.url + '/complete');
  }
}
