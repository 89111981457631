<simple-selector [items]="items" [labelKey]="labelKey" [open]="open" [dataProvider]="dataProvider"
                 [searchModeSwitchable]="searchModeSwitchable" [fixedSearch]="fixedSearch">
    <a-table (selectItem)="selectItem.emit($event)">
      <column property="bond.number" labelKey="common.number">
        <ng-template let-item="item">
          <span [class.a-link]="true">{{item.bond.number}}/{{item.versionNumber}}</span>
        </ng-template>
      </column>
      <column property="value" type="number" class="small-column" labelKey="common.value"></column>
      <column property="currency" dictionary="Currency" dictLabel="code" labelKey="common.currency"></column>
      <column property="valueWithSysCurr" type="number" [sumInFooter]="true" [editable]="false"
              [title]="'common.valueWithCurr' | translate : {currencyCode: SYSTEM_CURRENCY_CODE}"></column>
      <column property="validFrom" type="date" labelKey="common.validFrom"></column>
      <column property="validTo" type="date" labelKey="common.validTo"></column>
    </a-table>
</simple-selector>
