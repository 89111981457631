/*
 * Angular 2 Dropdown Multiselect for Bootstrap
 * Current version: 0.3.0
 *
 * Simon Lindh
 * https://github.com/softsimon/angular-2-dropdown-multiselect
 */

import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  IterableDiffers,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DictionaryBaseService} from '../../services';
import {ConfirmDialogComponent} from '../confirm-dialog';

const MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiselectDropdownComponent),
  multi: true,
};

const MULTISELECT_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MultiselectDropdownComponent),
  multi: true,
};

export interface IMultiSelectOption {
  id: number;
  name: string;
  code?: string;
  description?: string;
  extendedDescription?: string;
}

export interface IMultiSelectSettings {
  pullRight?: boolean;
  enableSearch?: boolean;
  checkedStyle?: 'checkboxes' | 'glyphicon';
  buttonClasses?: string;
  selectionLimit?: number;
  closeOnSelect?: boolean;
  autoUnselect?: boolean;
  showCheckAll?: boolean;
  showUncheckAll?: boolean;
  showToggleCheckAll?: boolean;
  dynamicTitleMaxItems?: number;
  maxHeight?: string;
  minWidth?: string;

  // ids of options to be confirmed when added
  // when the array is undefined no options are to be confirmed
  // when the array is empty all options are to be confirmed
  confirmAdditionIds?: number[];
  numSelectedOff?: boolean;
  whiteSpace?: string;
}

export interface IMultiSelectTexts {
  checkAll?: string;
  uncheckAll?: string;
  toggleCheckAll?: string;
  checked?: string;
  checkedPlural?: string;
  searchPlaceholder?: string;
  defaultTitle?: string;
}

@Pipe({
  name: 'searchFilter',
})
export class MultiSelectSearchFilter implements PipeTransform {
  transform(options: Array<IMultiSelectOption>, args: string): Array<IMultiSelectOption> {
    return options.filter(
      (option: IMultiSelectOption) => option.name.toLowerCase().indexOf((args || '').toLowerCase()) > -1
    );
  }
}

@Component({
  selector: 'ss-multiselect-dropdown',
  providers: [MULTISELECT_VALUE_ACCESSOR, MULTISELECT_VALIDATOR],
  styles: [
    `
      a {
        outline: none !important;
      }
    `,
  ],
  template: `
    <label class="bon-label" *ngIf="labelKey"> {{ labelKey | translate }} </label>
    <div class="btn-group">
      <button
        type="button"
        class="dropdown-toggle"
        [ngClass]="settings.buttonClasses"
        [style.white-space]="settings.whiteSpace"
        (click)="toggleDropdown()"
        [disabled]="disabled"
      >
        {{ title }}&nbsp;<span class="caret"></span>
      </button>
      <ul
        *ngIf="isVisible"
        class="dropdown-menu"
        [class.pull-right]="settings.pullRight"
        [style.max-height]="settings.maxHeight"
        [style.min-width]="settings.minWidth"
        style="display: block; height: auto; overflow-y: auto;"
      >
        <li style="margin: 0px 5px 5px 5px;" *ngIf="settings.enableSearch">
          <div class="input-group input-group-sm">
            <span class="input-group-addon" id="sizing-addon3"><i class="fa fa-search"></i></span>
            <input
              #test
              type="text"
              class="form-control"
              placeholder="{{ texts.searchPlaceholder | translate }}"
              aria-describedby="sizing-addon3"
              [(ngModel)]="searchFilterText"
              [style.min-width]="'calc(' + settings.minWidth + ' - 75px)'"
            />
            <span class="input-group-btn" *ngIf="searchFilterText.length > 0">
              <button class="btn btn-default" type="button" (click)="clearSearch()"><i class="fa fa-times"></i></button>
            </span>
          </div>
        </li>
        <li class="divider" *ngIf="settings.enableSearch"></li>
        <li
          *ngIf="settings.showToggleCheckAll; else noToggleCheckAll"
          style="margin: 0 5px 5px; border-bottom: 1px dashed #c8c7cc; width: 196px;"
        >
          <a href="javascript:;" role="menuitem" tabindex="-1" (click)="toggleCheckAll()">
            <input
              *ngIf="settings.checkedStyle == 'checkboxes'"
              type="checkbox"
              [checked]="isSelectedAll()"
              [indeterminate]="isIndeterminate()"
            />
            <span
              *ngIf="settings.checkedStyle == 'glyphicon'"
              style="width: 16px;"
              class="glyphicon"
              [class.glyphicon-ok]="isSelectedAll()"
              [class.glyphicon-minus]="isIndeterminate()"
            ></span>
            {{ texts.toggleCheckAll | translate }}
          </a>
        </li>
        <ng-template #noToggleCheckAll>
          <li *ngIf="settings.showCheckAll">
            <a href="javascript:;" role="menuitem" tabindex="-1" (click)="checkAll()">
              <span style="width: 16px;" class="glyphicon glyphicon-ok"></span> {{ texts.checkAll | translate }}
            </a>
          </li>
          <li *ngIf="settings.showUncheckAll">
            <a href="javascript:;" role="menuitem" tabindex="-1" (click)="uncheckAll()">
              <span style="width: 16px;" class="glyphicon glyphicon-remove"></span> {{ texts.uncheckAll | translate }}
            </a>
          </li>
        </ng-template>
        <li *ngIf="settings.showCheckAll || settings.showUncheckAll" class="divider"></li>
        <li *ngFor="let option of options | searchFilter: searchFilterText" style="margin: 0px 5px;">
          <a href="javascript:;" role="menuitem" tabindex="-1" (click)="setSelected($event, option)">
            <input
              *ngIf="settings.checkedStyle == 'checkboxes'"
              type="checkbox"
              [checked]="isSelected(option)"
              [disabled]="settings.selectionLimit && !(isSelected(option) || model.length < settings.selectionLimit)"
            />
            <span
              *ngIf="settings.checkedStyle == 'glyphicon'"
              style="width: 16px;"
              class="glyphicon"
              [class.glyphicon-ok]="isSelected(option)"
            ></span>
            {{ option.name }}
          </a>
        </li>
      </ul>
      <confirm-dialog #confirmDialog></confirm-dialog>
    </div>
    <input
      [name]="fieldName + '_size'"
      type="hidden"
      [ngModel]="valuesSize"
      [minValue]="minSize"
      #sizeModel="ngModel"
    />
    <error-message-simple
      [invalid]="sizeModel.hasError('minValue')"
      [show]="showErrors"
      key="Value is required"
    ></error-message-simple>
  `,
})
export class MultiselectDropdownComponent implements OnInit, DoCheck, ControlValueAccessor, Validator {
  @ViewChild('confirmDialog', {static: true}) confirmDialog: ConfirmDialogComponent;

  @Input() showErrors = false;
  @Input() minSize = 0;
  @Input() fieldName = '';
  @Input() options: Array<IMultiSelectOption>;
  @Input() optionsDictName: string;
  @Input() parentDictionaryEntryId: number;
  @Input() optionIds: Array<number>;
  @Input() optionHiddenIds: Array<number>;
  @Input() objectName: string;
  @Input() settings: IMultiSelectSettings;
  @Input() texts: IMultiSelectTexts;
  @Input() disabled = false;
  @Input() labelKey = null;
  @Output() selectionLimitReached = new EventEmitter();
  @Output() changeSelection = new EventEmitter();
  @Output() addItem = new EventEmitter();
  @Output() removeItem = new EventEmitter();
  @Output() addAllItems = new EventEmitter();
  @Output() removeAllItems = new EventEmitter();

  model: any[];
  title: string;
  differ: any;
  numSelected: number = 0;
  isVisible: boolean = false;
  searchFilterText: string = '';
  defaultSettings: IMultiSelectSettings = {
    pullRight: false,
    enableSearch: false,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default',
    selectionLimit: 0,
    closeOnSelect: false,
    autoUnselect: false,
    showCheckAll: false,
    showUncheckAll: false,
    showToggleCheckAll: false,
    dynamicTitleMaxItems: 3,
    maxHeight: '300px',
    minWidth: '260px',
    whiteSpace: 'nowrap',
  };
  defaultTexts: IMultiSelectTexts = {
    checkAll: 'multiselectDropdown.checkAll',
    uncheckAll: 'multiselectDropdown.uncheckAll',
    toggleCheckAll: 'multiselectDropdown.toggleCheckAll',
    checked: 'multiselectDropdown.checked',
    checkedPlural: 'multiselectDropdown.checkedPlural',
    searchPlaceholder: 'multiselectDropdown.searchPlaceholder',
    defaultTitle: 'multiselectDropdown.defaultTitle',
  };

  @HostListener('document: click', ['$event.target'])
  onClick(target: HTMLElement) {
    let parentFound = false;
    while (target !== null && !parentFound) {
      if (target === this.element.nativeElement) {
        parentFound = true;
      }
      target = target.parentElement;
    }
    if (!parentFound) {
      this.isVisible = false;
    }
  }

  onModelChange: Function = (_: any) => {};
  onModelTouched: Function = () => {};

  constructor(
    private element: ElementRef,
    private differs: IterableDiffers,
    private dictionaryService: DictionaryBaseService,
    private translateService: TranslateService
  ) {
    this.differ = differs.find([]).create(null);
  }

  ngOnInit() {
    this.settings = Object.assign(this.defaultSettings, this.settings);
    this.texts = Object.assign(this.defaultTexts, this.texts);
    this.translateService.get(this.texts.defaultTitle).subscribe((text) => (this.title = text));
    if (this.optionsDictName) {
      this.dictionaryService
        .getDictionaryBaseFiltered(this.optionsDictName, null, this.parentDictionaryEntryId, null)
        .subscribe((data) => {
          this.options = data.filter(
            (o) =>
              (!this.optionIds || this.optionIds.includes(o.id)) &&
              (!this.optionHiddenIds || !this.optionHiddenIds.includes(o.id))
          );
          this.updateTitle();
        });
    }
  }

  public validate(formControl: UntypedFormControl): ValidationErrors | any {
    if (this.minSize && this.valuesSize < this.minSize) {
      return {minSize: {min: this.minSize, actual: this.valuesSize, message: 'Minimum length constraint not met.'}};
    }
    return null;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.model = value;
    } else {
      this.model = [];
    }
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  ngDoCheck() {
    const changes = this.differ.diff(this.model);
    if (changes) {
      this.updateNumSelected();
      this.updateTitle();
      this.changeSelection.emit();
    }
  }

  clearSearch() {
    this.searchFilterText = '';
  }

  toggleDropdown() {
    this.isVisible = !this.isVisible;
  }

  isSelected(option: IMultiSelectOption): boolean {
    if (this.objectName) {
      return this.model && this.model.map((o) => this.unwrapOption(o).id).indexOf(option.id) > -1;
    }
    return this.isIdInModel(option.id);
  }

  isSelectedAll(): boolean {
    return this.options.length === this.model.length;
  }

  isIndeterminate(): boolean {
    return this.model.length > 0 && !this.isSelectedAll();
  }

  isIdInModel(id: number): boolean {
    for (let i = 0; i < this.model.length; i++) {
      if (this.model[i].id === id) {
        return true;
      }
    }
    return false;
  }

  getIdIndexInModel(id: number): number {
    for (let i = 0; i < this.model.length; i++) {
      if (this.model[i].id === id) {
        return i;
      }
    }
    return -1;
  }

  pushOption(option: IMultiSelectOption): void {
    const optionWrapper: any = this.wrapOption(option);
    if (this.settings.selectionLimit === 0 || this.model.length < this.settings.selectionLimit) {
      this.model.push(optionWrapper);
    } else {
      if (this.settings.autoUnselect) {
        this.model.push(optionWrapper);
        this.model.shift();
      } else {
        this.selectionLimitReached.emit(this.model.length);
      }
    }
    this.addItem.emit(optionWrapper);
  }

  setSelected(event: Event, option: IMultiSelectOption) {
    if (!this.model) {
      this.model = [];
    }
    let index: any;
    if (this.objectName) {
      index = this.model.map((o) => this.unwrapOption(o).id).indexOf(option.id);
    } else {
      index = this.getIdIndexInModel(option.id);
    }

    if (index > -1) {
      this.removeItem.emit(option);
      this.model.splice(index, 1);
      this.onModelChange(this.model);
    } else {
      this.confirmAddition(option).then((confirmed) => {
        if (!confirmed) {
          return;
        }
        this.pushOption(option);
        this.onModelChange(this.model);
      });
    }

    if (this.settings.closeOnSelect) {
      this.toggleDropdown();
    }
  }

  updateNumSelected() {
    if (!this.settings.numSelectedOff) {
      this.numSelected = (this.model && this.model.length) || 0;
    }
  }

  updateTitle() {
    if (this.numSelected === 0) {
      this.title = this.translateService.instant(this.texts.defaultTitle);
    } else if (this.settings.dynamicTitleMaxItems >= this.numSelected && this.options) {
      if (this.objectName) {
        this.title = this.options
          .filter(
            (option: IMultiSelectOption) =>
              this.model && this.model.map((o) => this.unwrapOption(o).id).indexOf(option.id) > -1
          )
          .map((option: IMultiSelectOption) => option.name)
          .join(', ');
      } else {
        this.title = this.options
          .filter((option: IMultiSelectOption) => this.model && this.getIdIndexInModel(option.id) > -1)
          .map((option: IMultiSelectOption) => option.name)
          .join(', ');
      }
    } else {
      this.title =
        this.numSelected +
        ' ' +
        (this.numSelected === 1
          ? this.translateService.instant(this.texts.checked)
          : this.translateService.instant(this.texts.checkedPlural));
    }
  }

  checkAll() {
    if (this.addAllItems.observers.length === 0) {
      this.clearModel();
      this.options.map((option) => this.model.push(this.wrapOption(option)));
      this.onModelChange(this.model);
    } else {
      this.addAllItems.emit();
    }
  }

  uncheckAll() {
    if (this.removeAllItems.observers.length === 0) {
      this.clearModel();
      this.onModelChange(this.model);
    } else {
      this.removeAllItems.emit();
    }
  }

  toggleCheckAll() {
    if (this.isSelectedAll()) {
      this.uncheckAll();
    } else {
      this.checkAll();
    }
  }

  clearModel() {
    this.model.length = 0;
  }

  private wrapOption(option: IMultiSelectOption): any {
    if (this.objectName) {
      const optionWrapper: any = {};
      const o: IMultiSelectOption = <IMultiSelectOption>{};
      o.id = option.id;
      o.name = option.name;
      o.code = option.code;
      o.description = option.description;
      o.extendedDescription = option.extendedDescription;
      optionWrapper[this.objectName] = o;
      return optionWrapper;
    }
    return option;
  }

  private unwrapOption(optionWrapper: any): IMultiSelectOption {
    return (<any>optionWrapper)[this.objectName];
  }

  private confirmAddition(option: IMultiSelectOption): Promise<boolean> {
    const truePromise = new Promise<boolean>((resolve, reject) => {
      resolve(true);
    });
    if (
      !this.settings.confirmAdditionIds ||
      (this.settings.confirmAdditionIds.length > 0 && !this.settings.confirmAdditionIds.find((id) => option.id === id))
    ) {
      // no popup
      return truePromise;
    }
    // show confirmation popup
    const msg = this.translateService.instant('Are you sure you want to add item');
    const confirmationPromise: Promise<boolean> = this.confirmDialog.open(
      'Confirmation',
      msg + ': ' + option.name + '?'
    );
    return confirmationPromise;
  }

  get valuesSize() {
    return this.model ? this.model.length : 0;
  }
}
