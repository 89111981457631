import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {AbstractService} from './abstract.service';
import {
  PolicyDrawdownVersionBaseDto,
  PolicyDrawdownVersionCriteriaDto,
  PolicyDrawdownVersionDto,
  SearchCriteria,
  StateTransitionDto,
} from '../model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class PolicyDrawdownVersionService extends AbstractService {
  protected url = this.urlPrefix + 'masterPolicyContract/drawdowns/drawdownVersion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  findPolicyDrawdownVersions(policyDrawdownId: number) {
    const crit = <SearchCriteria<PolicyDrawdownVersionCriteriaDto>>{
      criteria: {parent: {id: policyDrawdownId}},
    };
    return this.searchByCriteria(crit).pipe(map((x) => <PolicyDrawdownVersionBaseDto[]>x.result));
  }

  getLastPolicyDrawdownVersion(policyDrawdownId: number): Observable<PolicyDrawdownVersionDto> {
    return this.get(this.url + '/lastVersion/' + policyDrawdownId);
  }

  getInitialVersion(masterPolicyId: number): Observable<PolicyDrawdownVersionDto> {
    return this.get<PolicyDrawdownVersionDto>(this.url + '/initialVersion?masterPolicyId=' + masterPolicyId);
  }

  getApprovedDrawdownsByMasterPolicyId(masterPolicyId: number): Observable<PolicyDrawdownVersionDto[]> {
    return this.get(this.url + '?masterPolicyId=' + masterPolicyId);
  }

  getTransitions(policyDrawdownVersion: PolicyDrawdownVersionDto): Observable<Array<StateTransitionDto>> {
    return this.get<StateTransitionDto[]>(this.url + '/transition/' + policyDrawdownVersion.id);
  }
}
