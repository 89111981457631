import {StringUtils} from '../utils/string-utils';
import {Pipe, PipeTransform} from '@angular/core';
import {AddressDto} from '../model/dtos';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(addr: AddressDto, withCountry?: boolean) {
    return StringUtils.prettyAddress(addr, withCountry);
  }
}
