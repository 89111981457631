import {Injectable} from '@angular/core';
import {AbstractGuard} from '../../../bonding_shared/components/abstract-guard';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AppConfigService, LoggedUserService, PolicyContractVersionService} from '../../../bonding_shared/services';
import {ElementaryRight, UserRole} from '../../../bonding_shared/model/dictionary-ids';

@Injectable()
export class UserSearchGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router, public apiService: AppConfigService) {
    super(loggedUserService, router);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.apiService.kuke) {
      if (this.loggedUserService.hasLoggedUserRole(UserRole.KUKE_SUPERADMIN)) {
        return true;
      }
      if (
        this.loggedUserService.hasRight(ElementaryRight.MY_PROFILE_CATEGORY_USERS_SEARCH) ||
        this.loggedUserService.hasRight(ElementaryRight.EXTERNAL_USER_ACCESS_ONLY)
      ) {
        return true;
      }
      return false;
    }
    return true;
  }
}
