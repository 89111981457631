<div *ngIf="invoiceNumberingBagsResult && invoiceNumberingBagsResult.result">
  <item-combo
    #model="ngModel"
    [ngModel]="selectedNumberingBag"
    [items]="invoiceNumberingBagsResult.result"
    key="id"
    label="name"
    (selectItem)="onChangeItem($event)"
    nullLabel="-"
    [required]="required">
  </item-combo>
  <error-message [control]="model.control" [show]="showErrors"></error-message>
</div>
