import {Component, ViewChild} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {PolicyContractVersionService} from '../../../../../bonding_shared';
import {TurnoverReportingSectionComponent} from '../../sections/kuke/turnover-reporting-section.component';
import {EcgPolicyProduct} from './ecg-policy-product';

@Component({
  selector: 'ecg-top-up-product',
  templateUrl: './ecg-top-up-policy-product.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class EcgTopUpPolicyProductComponent extends EcgPolicyProduct {
  @ViewChild(TurnoverReportingSectionComponent)
  private turnoverReportingSectionComponent: TurnoverReportingSectionComponent;

  constructor(policyContractVersionService: PolicyContractVersionService) {
    super(policyContractVersionService);
  }

  onValidFromChanged(validFrom: Date) {
    this.turnoverReportingSectionComponent.recalculateReportingPeriods(true);
  }
}
