import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConfigService, GrowlService} from '../../../../../src/client/app/bonding_shared/services';
import {ChangePasswordRequestDto, ErrorReason} from '../../../../../src/client/app/bonding_shared/model';
import {PasswordResetService} from '../../../../../src/client/app/portal/services/password-reset.service';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../../../src/client/app/bonding_shared/validators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'reset-password',
  templateUrl: 'reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private dto: ChangePasswordRequestDto = <ChangePasswordRequestDto>{};
  public showErrors = false;
  private paramsSubscription: Subscription;
  public form: FormGroup;
  public serverErrors: ErrorReason[];
  private afterResetMessage = 'Please, log in with your new password';

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    public appConfigService: AppConfigService,
    private passwordResetService: PasswordResetService,
    private growlService: GrowlService,
    private translateService: TranslateService
  ) {
    this.form = new FormGroup(
      {
        newPassword: new FormControl(
          '',
          Validators.compose([Validators.required, CustomValidators.credendoPasswordStrength])
        ),
        retypePassword: new FormControl('', Validators.required),
      },
      CustomValidators.passwordConfirmValidation
    );
  }

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe((params) => {
      this.dto.login = params['login'];
      this.dto.expiryTime = params['expiryTime'];
      this.dto.token = params['token'];
      this.translateService.use(params['lang']);
    });
  }

  ngOnDestroy() {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  resetPassword() {
    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }
    this.dto.newPassword = this.form.get('newPassword').value;
    this.passwordResetService.resetPassword(this.dto).subscribe({
      next: (_) => {
        this.growlService.notice('Your password has been successfully changed');
        this._router.navigate(['login', this.afterResetMessage]);
      },
      error: (error) => {
        this.serverErrors = error;
      },
    });
  }
}
