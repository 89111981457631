import {Injectable} from '@angular/core';
import {AppConfigService, LoggedUserService} from '../../bonding_shared/services';

import {HttpClient} from '@angular/common/http';
import {DeclarationAbstractService} from './declaration-abstract.service';
import {Observable} from 'rxjs';
import {DeclarationCriteriaDto, DeclarationDto, DeclarationStubDto} from '../model';

@Injectable()
export class DeclarationService extends DeclarationAbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'declaration';
  }

  createSendConfirmationTaskActionP07Task(policyContractId: number): Observable<void> {
    return this.postEmpty<void>(this.url + '/createSendConfirmationTaskActionP07Task/' + policyContractId);
  }

  lastDeclarationsConfirmed(criteria: DeclarationCriteriaDto): Observable<boolean> {
    return this.put1<DeclarationCriteriaDto, boolean>(criteria, this.url + '/lastDeclarationsConfirmed');
  }
}
