import {Component, ViewChild} from '@angular/core';
import {TemplateSimpleDto} from '../../bonding_shared/model/index';
import {RouterService} from '../../bonding_shared/services/router-service';
import {TemplateService} from '../../bonding_shared/services/template.service';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {CompanyIdDto, TemplateCriteriaDto} from '../../bonding_shared/model/dtos';
import {ATableComponent} from '../../bonding_shared/components/aku-table';
import {AppConfigService} from '../../bonding_shared';

@Component({
  selector: 'template-search',
  templateUrl: 'template-search.component.html',
})
export class TemplateSearchComponent {
  @ViewChild(ATableComponent, {static: true}) templateTable: ATableComponent<TemplateSimpleDto>;

  searchDataProvider: SearchDataProvider<TemplateCriteriaDto, TemplateSimpleDto>;

  get criteria(): TemplateCriteriaDto {
    return this.searchDataProvider.searchCriteria.criteria;
  }

  set criteria(c: TemplateCriteriaDto) {
    this.searchDataProvider.searchCriteria.criteria = c;
  }

  constructor(
    public router: RouterService,
    private templateService: TemplateService,
    public appService: AppConfigService
  ) {
    this.searchDataProvider = new SearchDataProvider<TemplateCriteriaDto, TemplateSimpleDto>(templateService);
    this.criteria = <TemplateCriteriaDto>{};
    this.criteria.client = <CompanyIdDto>{};
    if (this.appService.kuke) {
      this.criteria.editionAllowed = true;
    }
  }

  gotoTemplateDetail(template: TemplateSimpleDto) {
    this.router.toTemplateDetails(template.id);
  }
}
