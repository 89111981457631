import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {BusinessLogService, UserService} from '../../bonding_shared/services';
import {ConfirmDialogComponent} from '../../bonding_shared/components/confirm-dialog';
import {PortalUserService} from '../../portal/services/portal-user.service';

@Component({
  selector: 'password-expiring',
  templateUrl: './password-expiring.component.pug',
})
export class PasswordExpiringComponent implements OnInit {
  constructor(private userService: UserService) {}

  @ViewChild('passwordExpiringDialog') alertDialog: ConfirmDialogComponent;

  ngOnInit() {
    this.userService.passwordExpiring().subscribe((days) => {
      if (days <= 7) {
        this.alertDialog.openAndExecuteOnConfirm(
          'passwordExpiring.title',
          'passwordExpiring.message',
          undefined,
          undefined,
          {noOfDays: '' + days}
        );
      }
    });
  }
}
