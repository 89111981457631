import {Component, Input} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {LimitRequestDto} from '../../../bonding_shared/model';
import {LimitRequestComponent} from './limit-request.component';
import {DictionaryProfile} from '../../../bonding_shared/model/dictionary-ids';
import {AppConfigService} from '../../../bonding_shared';

@Component({
  selector: 'limit-overdue-payments-section',
  templateUrl: './limit-overdue-payments-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class LimitOverduePaymentsSectionComponent {
  @Input() showErrors: boolean;
  @Input() hidden = false;
  @Input() request: LimitRequestDto;
  @Input() falcon: boolean;
  @Input() expandable: boolean;

  readonly today = new Date();

  currencyDictionaryProfile: DictionaryProfile;

  readonly valueMaxLength = LimitRequestComponent.VALUE_MAX_LENGTH;

  constructor(public appService: AppConfigService) {
    if (this.appService.kuke) {
      this.currencyDictionaryProfile = DictionaryProfile.KUKE_LIMIT_CURRENCY;
    }
  }
}
