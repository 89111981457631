import {Injectable} from '@angular/core';
import {ThirdPartyService} from '../../../bonding_shared/services/thirdparty.service';
import {LoggedUserService} from '../../../bonding_shared/services';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {ClientCompanyBaseDto, ClientCompanyDto} from '../../../bonding_shared/model/dtos';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {ClientCompanyService} from '../../../bonding_shared/services/client-company.service';

@Injectable()
export class ClientCompanyGuiService {
  dataProvider: SearchDataProvider<ClientCompanyBaseDto, ClientCompanyDto>;

  constructor(private ccService: ClientCompanyService, loggedUserService: LoggedUserService) {
    this.dataProvider = BusinessUtils.createClientCompanyDataProvider(ccService);
    this.dataProvider.textSearch = false;
  }
}
