import {Directive} from '@angular/core';
import {UntypedFormGroup, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[massDecisionValidate]',
  providers: [{provide: NG_VALIDATORS, useExisting: LimitMassDecisionValidatorDirective, multi: true}],
})
export class LimitMassDecisionValidatorDirective implements Validator {
  validate(form: UntypedFormGroup): ValidationErrors {
    const amount = form.get('amount');
    const reductionPercent = form.get('reductionPercent');
    const validTo = form.get('validTo');

    if (!amount || !reductionPercent || !validTo) {
      return null;
    }

    const amountProvided = amount.value || amount.value === 0;
    const reductionPercentProvided = reductionPercent.value || reductionPercent.value === 0;

    if (!amountProvided && !reductionPercentProvided && !validTo.value) {
      return {amountOrPercentOrValidToRequired: true};
    }
    if (amountProvided && reductionPercentProvided) {
      return {amountOrPercentNotBoth: true};
    }
    if (reductionPercentProvided && (reductionPercent.value < 0 || reductionPercent.value > 100)) {
      return {wrongPercent: true};
    }
    return null;
  }
}
