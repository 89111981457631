import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {AppConfigService, GrowlService} from '../../../bonding_shared/services';
import {DictionaryBaseDto, LimitBusinessParamPackageDto} from '../../../bonding_shared/model';
import {LimitBusinessParamsService} from '../../../bonding_shared/services/limit-business-params.service';
import {
  ApplicationModule,
  BusinessParamType,
  OECDRating,
  RatingType,
} from '../../../bonding_shared/model/dictionary-ids';
import {StringUtils} from '../../../bonding_shared/utils';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'limit-business-params-details',
  templateUrl: './limit-business-params-details.component.pug',
})
export class LimitBusinessParamsDetailsComponent extends DetailsView implements OnInit {
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  types: DictionaryBaseDto[];
  ratingTypes: DictionaryBaseDto[];
  package = <LimitBusinessParamPackageDto>{
    countryRating: {id: OECDRating.R0},
    type: {id: BusinessParamType.LNT_QUALITATIVE_ASSESSMENT},
    ratingType: {id: RatingType.ST_INSURANCE},
  };

  constructor(
    protected growlService: GrowlService,
    private paramsService: LimitBusinessParamsService,
    private appService: AppConfigService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.newVersionButton = undefined;
    this.hideButtons(false);
  }

  ngOnInit() {
    console.log('LimitBusinessParamsDetailsComponent ngOnInit()...');
    this.form = this.ngForm.form;
    this.paramsService.getTypes().subscribe((t) => (this.types = t));
    this.paramsService.getRatingTypes().subscribe((rt) => (this.ratingTypes = rt));
    this.refreshParams();
  }

  refreshParams() {
    this.paramsService
      .getPackage(this.package.countryRating.id, this.package.type.id, this.package.ratingType?.id)
      .subscribe((p) => (this.package = p));
  }

  onRatingtype(): boolean {
    return this.appService.kuke || this.appService.ecg;
  }
  onCancel() {
    this.refreshParams();
  }

  onSave() {
    this.clearErrors();
    if (!this.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFieldsRecursive(this.form);
      return;
    }
    this.paramsService.update(this.package).subscribe({
      next: (p) => {
        this.package = p;
        this.showSavedMsg();
      },
      error: (error) => {
        this.handleServerError(error);
      },
    });
  }
}
