/**
 * Service for common logic of invoice components.
 *
 */
import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {
  LocalDateRange,
  InvoiceIdDto,
  InvoicePaymentCriteriaDto,
  InvoicePaymentSimpleDto,
} from '../../../bonding_shared/model/dtos';
import {InvoicePaymentService} from '../../../bonding_shared/services/invoice-payment.service';

@Injectable()
export class InvoicePaymentGuiService {
  dataProvider: SearchDataProvider<InvoicePaymentCriteriaDto, InvoicePaymentSimpleDto>;

  constructor(private service: InvoicePaymentService) {}

  public getDataProvider(): SearchDataProvider<InvoicePaymentCriteriaDto, InvoicePaymentSimpleDto> {
    if (!this.dataProvider) {
      this.dataProvider = new SearchDataProvider<InvoicePaymentCriteriaDto, InvoicePaymentSimpleDto>(this.service);
      let criteria = <InvoicePaymentCriteriaDto>{};
      this.dataProvider.searchCriteria.criteria = criteria;

      criteria.financialSystemDateRange = <LocalDateRange>{};
      criteria.correctedDateRange = <LocalDateRange>{};
      criteria.invoice = <InvoiceIdDto>{};
    }
    return this.dataProvider;
  }
}
