import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {BinaryDownloaderService} from './binary-downloader.service';
import {ReportCriteriaDto, ReportDto, ReportSimpleDto, SearchResult} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ReportService extends AbstractService {
  protected url = this.urlPrefix + 'report';
  private _reportCreateCreditUrl = this.url + '/credit/create';
  private _reportCreateDebtorUrl = this.url + '/debtor/create';
  private _reportCreateNbbUrl = this.url + '/nbb/create';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private _binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getReport(id: number): Observable<ReportDto> {
    console.log('report details: id = ' + id);
    return this.get<ReportDto>(this.url + '/' + id);
  }

  createCreditReportById(id: number) {
    console.log('create credit report: id = ' + id);
    this._binaryDownloader.download(
      'GET',
      this.url + '/' + id + '/credit',
      'application/octet-stream',
      'application/octet-stream',
      'credits.xml'
    );
  }

  createDebtorReportById(id: number) {
    console.log('create debtor report: id = ' + id);
    this._binaryDownloader.download(
      'GET',
      this.url + '/' + id + '/debtor',
      'application/octet-stream',
      'application/octet-stream',
      'debtors.xml'
    );
  }

  createCreditReport(year: number, month: number): Observable<string> {
    console.log('create credit report: year = ' + year + ', month = ' + month);
    return this.getAsStr<string>(this._reportCreateCreditUrl + '?year=' + year + '&month=' + month);
  }

  createDebtorReport(year: number, month: number): Observable<string> {
    console.log('create debtor report: year = ' + year + ', month = ' + month);
    return this.getAsStr<string>(this._reportCreateDebtorUrl + '?year=' + year + '&month=' + month);
  }

  createNbbReport(year: number, month: number): Observable<string> {
    console.log('create nbb report: year = ' + year + ', month = ' + month);
    return this.get<string>(this._reportCreateNbbUrl + '?year=' + year + '&month=' + month);
  }

  createXlsxReportById(id: number, outputFileName: string) {
    console.log('create xls report: id = ' + id);
    this._binaryDownloader.download(
      'GET',
      this.url + '/' + id + '/report.xlsx',
      'application/octet-stream',
      'application/octet-stream',
      outputFileName
    );
  }

  searchReports(criteria: ReportCriteriaDto): Observable<SearchResult<ReportDto>> {
    console.log('searchReports: http = ' + this.http);
    return this.post1<ReportCriteriaDto, SearchResult<ReportDto>>(criteria, this.url + '/search');
  }

  updateReport(report: ReportSimpleDto): Observable<ReportSimpleDto> {
    console.log('update report: id = ' + JSON.stringify(report));
    return this.post<ReportSimpleDto>(report, this.url);
  }
}
