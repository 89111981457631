import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PortalLoginComponent} from './portal-login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PasswordResetService} from '../../../../../src/client/app/portal/services/password-reset.service';
import {ResetPasswordComponent} from './reset-password.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedComponentsModule} from '../../../../../src/client/app/bonding_shared/components/shared-components.module';
import {RemindPasswordComponent} from './remind-password.component';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, TranslateModule, SharedComponentsModule],
  declarations: [PortalLoginComponent, ResetPasswordComponent, RemindPasswordComponent],
  exports: [PortalLoginComponent, ResetPasswordComponent, RemindPasswordComponent],
  providers: [PasswordResetService],
})
export class PortalLoginModule {}
