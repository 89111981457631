import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ClaimVersionBaseDto, ClaimVersionCriteriaDto} from '../../../../bonding_shared/model';
import {ClaimService} from '../../../../bonding_shared/services/claim.service';
import {SearchDataProvider} from '../../../../bonding_shared/services';
import {Subject} from 'rxjs';
import {ClaimGuiService} from '../../services/claim-gui.service';
import * as _ from 'lodash';

@Component({
  selector: 'claim-version-selector',
  templateUrl: './claim-version-selector.component.pug',
})
export class ClaimVersionSelectorComponent {
  dataProvider: SearchDataProvider<ClaimVersionCriteriaDto, ClaimVersionBaseDto>;

  @Input() labelKey: string;
  @Input() open: Subject<boolean>;
  @Input() searchOnOpen: boolean;
  @Output() selectItem = new EventEmitter<ClaimVersionBaseDto>();

  @Input() set criteria(c: ClaimVersionCriteriaDto) {
    _.merge(this.dataProvider.searchCriteria.criteria, c);
  }

  constructor(protected service: ClaimService) {
    this.dataProvider = ClaimGuiService.createDataProvider(service);
  }

  selected(claimVersion: ClaimVersionBaseDto) {
    this.open.next(false);
    this.selectItem.emit(claimVersion);
  }
}
