import {AfterViewInit, Component, Input} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {CompanySimpleDto, DictionaryBaseDto, TemplateBaseDto} from '../../../../../bonding_shared/model';
import {
  AppConfigService,
  DictionaryService,
  RouterService,
  TemplateService,
} from '../../../../../bonding_shared/services';
import {ControlContainer, NgForm} from '@angular/forms';
import {CustomButton, CustomButtonEvent} from '../../../../../bonding_shared/components/aku-table/table.component';
import {BusinessObjectType, DocumentType} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'kuke-insureds-section',
  templateUrl: './kuke-insureds-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KukeInsuredsSectionComponent extends Section implements AfterViewInit {
  self = this;

  customButtons: CustomButton[];
  @Input() policyVersionId: number;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected appService: AppConfigService,
    protected dictionaryService: DictionaryService,
    public router: RouterService,
    private templateService: TemplateService
  ) {
    super(policyContractViewService, appService);
  }

  ngAfterViewInit() {
    this.loadTemplates();
  }

  get insureds(): CompanySimpleDto[] {
    return [this._policy.client].concat(this._policy.subinsured.map((i) => i.company));
  }

  initializeDocumentsButton(templates: TemplateBaseDto[]) {
    const documentsButton = <CustomButton>{
      name: 'Draft oświadczeń',
      title: 'Draft oświadczeń',
      class: 'bon-btn-info',
      icon: 'fa-file-text-o',
      alignment: 'right',
      dropdownItems: templates,
    };
    this.customButtons = [documentsButton];
  }

  loadTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    if (this._policy.contractType) {
      dictionarySelectors.push(this._policy.contractType);
    }
    this.templateService
      .findByType(DocumentType.DEC_AGREE_SUBIN, BusinessObjectType.POLICY_VERSION, null, dictionarySelectors)
      .subscribe((templates) => {
        console.log('TEMPLATES for policy statement result length = ' + templates.length);
        if (templates.length > 0) {
          if (this.policyVersionId) {
            this.initializeDocumentsButton([]);
          }
          this.initializeDocumentsButton(templates);
        }
      });
  }

  toDocumentView(event: CustomButtonEvent<CompanySimpleDto>) {
    this.router.toDocumentDetailsNewWithAdditionalValues(
      event.dropdownItem.id,
      this.policyVersionId,
      BusinessObjectType.COMPANY,
      event.item.id
    );
  }
}
