import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Pipe({
  name: 'bonInputCssClasses',
})
export class BonInputCssClassesPipe implements PipeTransform {
  transform(control: AbstractControl, showErrors: boolean, errorClass: string) {
    const cssClasses: {[key: string]: any} = {};
    cssClasses['bon-input'] = true;
    if (control && control.invalid && showErrors) {
      cssClasses[errorClass] = true;
    }
    return cssClasses;
  }
}
