import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../../bonding_shared/components/shared-components.module';
import {CollectionListComponent} from './collection-list.component';
import {CollectionSectionComponent} from './collection-section.component';
import {CollectionVersionSelectorComponent} from './collection-version-selector.component';
import {CollectionSectionSelectorComponent} from './collection-section-selector.component';
import {CompanySharedModule} from '../../../company/components/shared/company-shared.module';
import {PolicyLimitListSharedModule} from '../../../policy-limit-list/shared/policy-limit-list-shared.module';
import {LimitListSectionSelectorComponent} from '../../../policy-limit-list/components/limit-list-section-selector.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    CompanySharedModule,
    PolicyLimitListSharedModule,
  ],
  declarations: [
    CollectionListComponent,
    CollectionSectionComponent,
    CollectionVersionSelectorComponent,
    CollectionSectionSelectorComponent,
  ],
  exports: [
    CollectionListComponent,
    CollectionSectionComponent,
    CollectionVersionSelectorComponent,
    CollectionSectionSelectorComponent,
    LimitListSectionSelectorComponent,
  ],
})
export class CollectionSharedModule {}
