import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ClaimSimplifiedCalculationDto} from '../model';
import {AbstractAuditedService} from './abstract-audited.service';
import {Observable} from 'rxjs';

@Injectable()
export class ClaimSimplifiedCalculationService extends AbstractAuditedService<ClaimSimplifiedCalculationDto> {
  protected url = this.urlPrefix + 'claim/calculation/simplified';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  saveAudited(id: number, dto: ClaimSimplifiedCalculationDto): Observable<ClaimSimplifiedCalculationDto> {
    return this.put(dto, this.build(id));
  }
}
