import {HasId} from '../model/dtos';

export function isPromise(value: any): value is PromiseLike<any> {
  return value && typeof (<any>value).subscribe !== 'function' && typeof (value as any).then === 'function';
}

export class ObjectUtils {
  static equalsId(d1: HasId, d2: HasId): boolean {
    return d1 && d2 ? d1.id === d2.id : !d1 && !d2;
  }
  static equalsIdAndNumber(d1: HasId, id: number): boolean {
    return d1 ? d1.id === id : id === null || id === undefined;
  }
  static getId(d: HasId): number {
    return d ? d.id : undefined;
  }
}
