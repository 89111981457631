import {Component, OnInit} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {GrowlService} from '../../bonding_shared/services';
import {ClaimTreatOrderService} from '../../bonding_shared/services/claim-treat-order.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ClaimBaseDto, ClaimVersionSimpleDto} from '../../bonding_shared/model';

@Component({
  selector: 'claim-treat-order',
  templateUrl: 'claim-treat-order.component.pug',
})
export class ClaimTreatOrderComponent extends DetailsView implements OnInit {
  claims = <ClaimVersionSimpleDto[]>[];
  returnClaim: ClaimBaseDto;

  constructor(
    private _route: ActivatedRoute,
    private service: ClaimTreatOrderService,
    public router: RouterService,
    growlService: GrowlService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.newVersionButton = undefined;
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
  }

  ngOnInit() {
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    super.initializeView(params);
    this.service.getClaims(+params['policyContractId']).subscribe((x) => {
      this.claims = x;
      this._route.queryParams.subscribe((qparams) => this.findReturnClaim(+qparams.returnClaimId));
    });
  }

  findReturnClaim(claimId: number) {
    this.returnClaim = claimId ? this.claims.find((c) => c.claim.id === claimId).claim : undefined;
  }

  onSave() {
    this.clearErrors();
    this.service.update(this.claims).subscribe(
      () => this.afterObjectSaved(),
      (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      }
    );
  }

  onCancel() {
    super.onCancel(this._route);
  }

  changeOrder(c: ClaimBaseDto, op: 'up' | 'down') {
    const i = c.treatOrder;
    const neighbour = op === 'up' ? this.getPrevious(i) : this.getNext(i);
    if (neighbour) {
      c.treatOrder = neighbour.treatOrder;
      neighbour.treatOrder = i;
    }
  }

  getPrevious(i: number) {
    return this.claims
      .map((x) => x.claim)
      .filter((x) => x.treatOrder < i)
      .reduce((c1, c2) => (c1.treatOrder > c2.treatOrder ? c1 : c2));
  }

  getNext(i: number) {
    return this.claims
      .map((x) => x.claim)
      .filter((x) => x.treatOrder > i)
      .reduce((c1, c2) => (c1.treatOrder < c2.treatOrder ? c1 : c2));
  }
}
