import {Injectable} from '@angular/core';
import {BondVersionDto, ContractVersionDto, DictionaryBaseDto} from '../../../bonding_shared/model/dtos';
import {BondSubType, BondType, ContractType} from '../../../bonding_shared/model/dictionary-ids';

@Injectable()
export class ClientBondFlowService {
  private _bond: BondVersionDto;
  private _contractVersion: ContractVersionDto;

  constructor() {}

  get bondSubtype(): DictionaryBaseDto {
    return this._bond ? this._bond.subtype : undefined;
  }

  canPreview(): boolean {
    return this.canSendIssue() && !this.isUserSpecialSubtype();
  }

  canSendRequest(): boolean {
    return this.bondSubtype && !this.canSendIssue();
  }

  canSendIssue(): boolean {
    return this.bondSubtype && this.contractVersion.bondIssuanceInPortal && this.exceedsRateLevel().level < 2;
  }

  setupBond(bond: BondVersionDto) {
    this._bond = bond;
  }

  private isUserSpecialSubtype(): boolean {
    return this.bondSubtype && this.bondSubtype.id === BondSubType.TECHNICAL_USER_SPECIAL_TEXT;
  }

  get bond(): BondVersionDto {
    return this._bond;
  }

  get contractVersion(): ContractVersionDto {
    return this._contractVersion;
  }

  set contractVersion(cv: ContractVersionDto) {
    this._contractVersion = cv;
  }

  public isIndividualContract(): boolean {
    const cv = this.contractVersion;
    return (
      cv &&
      cv.contract &&
      cv.contract.type &&
      ![ContractType.BOOSTER, ContractType.BANK_BOOSTER].includes(cv.contract.type.id)
    );
  }

  public exceedsRateLevel() {
    /* If Auftragsanteteil > than specific value for specific bonds a warning shall appear:

 The bond value exceeds X% of the contract value. Please not that it is very unusual for this type of bond.

 The X value for bond types: BIETUNG 5%, Vertragserfüllung 10%, Mängelansprüche 5%, Ausführung und Mängelansprüche 10%, Dachdecker 15% .
 * */

    if (this.bond.type) {
      const value = this.bond.values[0].value,
        contractValue = this.bond.contractAmount;
      if (value && contractValue) {
        const exceeds = () => value > (rate * contractValue) / 100;
        const typeId = this.bond.type.id;
        const paymentMethodBond = typeId === BondType.ANVORAUSZAHLUNG;

        let rate = 30;
        if (paymentMethodBond && exceeds()) {
          return {level: 2, rate: rate};
        }
        /* The X value for bond types:
          BIETUNG 5%, Vertragserfüllung 10%, Mängelansprüche 5%, Ausführung und Mängelansprüche 10%, Dachdecker 15% */
        const bondValExceeds: {[key: number]: number} = {1000020: 5, 1000014: 10, 1000016: 5, 1000017: 10};
        rate = bondValExceeds[typeId];
        if (rate && exceeds()) {
          return {level: 1, rate: rate};
        }
      }
    }
    return {level: 0, rate: null};
  }
}
