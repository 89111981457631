<div *ngIf="bidBondFixedPercentageSubtype() || bidBondFreePercentageSubtype()">
  <div *ngIf="bidBondFixedPercentageSubtype()" class="bon-row">
      <label class="bon-label" translate="clientbond.shared.itBidBond.fixedPercentage"></label>
      <item-combo class="bon-input-size" [items]="fixedPercentages" nullLabel="-" label="id" [(ngModel)]="fixedPercentage"
                  [ngModelOptions]="{standalone: true}"></item-combo>
  </div>
  <num-row *ngIf="bidBondFreePercentageSubtype()" labelKey="clientbond.shared.itBidBond.freePercentage" [(ngModel)]="bond.freePercentage"
           name="freePercentage" [control]="bondForm.controls.freePercentage" [showErrors]="showErrors"></num-row>
  <form-row labelKey="clientbond.shared.itBidBond.chargesNotSubjectToDiscount" [control]="bondForm.controls.chargesNotSubjectToDiscount"
            [showErrors]="showErrors">
    <div class="value-currency" [class.bon-input-required]="chargesNotSubjectToDiscountModel.hasError('required')">
      <num-input [(ngModel)]="bond.chargesNotSubjectToDiscount" #chargesNotSubjectToDiscountModel="ngModel" maxlength="14"></num-input>
      <span>{{bond.currency.code}}</span>
    </div>
  </form-row>
  <num-row labelKey="clientbond.shared.itBidBond.minDiscountPercentage" [(ngModel)]="bond.minDiscountPercentage" name="minDiscountPercentage"
           [control]="bondForm.controls.minDiscountPercentage" [showErrors]="showErrors"></num-row>
  <num-row labelKey="clientbond.shared.itBidBond.maxDiscountPercentage" [(ngModel)]="bond.maxDiscountPercentage" name="maxDiscountPercentage"
           [control]="bondForm.controls.maxDiscountPercentage" [showErrors]="showErrors">
  </num-row>
</div>
