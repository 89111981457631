import {Component, Input, ViewChild} from '@angular/core';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto} from '../../../../bonding_shared/model/dtos';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table/a-table.component';

@Component({
  selector: 'policy-version-list',
  templateUrl: './policy-version-list.component.pug',
})
export class PolicyVersionListComponent {
  @ViewChild(ATableComponent, {static: true}) policyTable: ATableComponent<PolicyContractVersionSimpleDto>;

  @Input() showClient = true;

  @Input() set dataProvider(dp: SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto>) {
    this.policyTable.dataProvider = dp;
    this.policyTable.pagination = true;
    this.policyTable.search();
  }

  constructor(public router: RouterService) {}

  isEmpty(): boolean {
    if (!this.policyTable) {
      return true;
    }
    return this.policyTable.totalCount < 1;
  }
}
