import {UntypedFormGroup} from '@angular/forms';
import {AddressDto, ContactPersonDto, PhysicalPersonDto} from '../model/dtos';
import {DecimalPipe, registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {UserIdDto} from '../model';

registerLocaleData(localeIt, 'it');

export class StringUtils {
  static SYSTEM_USER_LOGIN = 'isa';
  static NUMBER_FORMAT = '1.2-2';
  static NUMBER_INT_FORMAT = '1.0-0';

  static formatTime(seconds: number): string {
    if (!seconds) {
      return '';
    }
    const min = Math.floor(seconds / 60);
    return min + ':' + this.addZero(seconds - min * 60);
  }

  static formatNumber(num: number, format?: string): string {
    if (num === 0) {
      if (!format || format === StringUtils.NUMBER_FORMAT) {
        return '0,00';
      } else {
        return '0';
      }
    }
    if (!num) {
      return '';
    }
    const formatToUse = format ? format : StringUtils.NUMBER_FORMAT;
    return new DecimalPipe('IT').transform(Number(num), formatToUse);
  }

  static logFormInvalidFields(form: UntypedFormGroup) {
    let invalidFields = '';
    Object.keys(form.value).forEach((f) => {
      const control: any = form.controls[f];
      if (!control.valid) {
        invalidFields += f + ', ';
      }
    });
    console.log('Form fields invalid:' + invalidFields);
  }

  static logFormInvalidFieldsRecursive(group: UntypedFormGroup, indent?: string, showValid?: boolean): string {
    let s = '';
    if (!indent) {
      s = StringUtils.logFormInvalidFieldsRecursive(group, '  ', showValid);
      console.log('Form fields invalid: \n', s.substr(1));
    } else {
      Object.keys(group.value).forEach((f) => {
        const control: any = group.controls[f];
        if ((control && !control.valid) || showValid) {
          const icon: string = control instanceof UntypedFormGroup ? '+ ' : '- ';
          if (control.errors) {
            s += indent + icon + f + ' ' + JSON.stringify(control.errors) + '\n';
          } else {
            s += indent + icon + ' ' + f + '\n';
          }
          if (control instanceof UntypedFormGroup) {
            s += StringUtils.logFormInvalidFieldsRecursive(control, indent + '  ');
          }
        }
      });
    }
    return s;
  }

  static userName(user: UserIdDto | PhysicalPersonDto) {
    if (!user) {
      return '';
    }
    if ((<UserIdDto>user).login && (<UserIdDto>user).login === StringUtils.SYSTEM_USER_LOGIN) {
      return (<UserIdDto>user).name;
    }
    const firstName = (<UserIdDto>user).name ? (<UserIdDto>user).name : (<PhysicalPersonDto>user).firstName;
    const lastName = (<UserIdDto>user).familyName ? (<UserIdDto>user).familyName : (<PhysicalPersonDto>user).lastName;
    return StringUtils.personName(firstName, lastName);
  }

  static personName(firstName: string, lastName: string) {
    const separator = lastName && firstName ? ', ' : '';
    return StringUtils.emptyUndefined(lastName) + separator + StringUtils.emptyUndefined(firstName);
  }

  static displayName(contactPerson: ContactPersonDto) {
    if (!contactPerson) {
      return '';
    }
    if (contactPerson.firstName && contactPerson.lastName) {
      return contactPerson.firstName + ' ' + contactPerson.lastName;
    }
    if (contactPerson.lastName) {
      return contactPerson.lastName;
    }
    if (contactPerson.firstName) {
      return contactPerson.firstName;
    }
    return '';
  }

  static camelCaseToText(camel: string): string {
    let text = camel
      .replace(/([A-ZŻŹĆĄŚĘŁÓŃÁÉÚŐÓÜÖÍ]+)/g, ' $1')
      .replace(/([A-ZŻŹĆĄŚĘŁÓŃÁÉÚŐÓÜÖÍ][a-zzżźćńółęąśáéúőóüöí])/g, ' $1')
      .trim()
      .replace(/\s{2,}/g, ' ');
    text = text.toLocaleLowerCase();
    text = text.replace(/\.([A-ZŻŹĆĄŚĘŁÓŃÁÉÚŐÓÜÖÍa-zzżźćńółęąśáéúőóüöí])/g, ' $1'); // change dot for space when after dot is letter
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  static emptyUndefined(s: string): string {
    return s ? s : '';
  }

  static isEmpty(s: string) {
    return s == null || s.length === 0;
  }

  private static addZero(n: number): string {
    return n < 10 ? '0' + n : n + '';
  }

  static find<T>(list: Array<T>, f: (item: T) => boolean): T {
    const filteredList = list.filter(f);
    return filteredList.length > 0 ? filteredList[0] : undefined;
  }

  static matchSingleGroup(s: string, regExp: RegExp) {
    if (s && regExp) {
      const matches = s.match(regExp);
      if (matches && matches.length === 2) {
        return matches[1];
      }
    }
    return undefined;
  }

  static lowerFirstLetter(txt: String): String {
    if (!txt) {
      return txt;
    }
    return txt.charAt(0).toLowerCase() + txt.slice(1);
  }

  static upperFirstLetter(txt: String): String {
    if (!txt) {
      return txt;
    }
    return txt.charAt(0).toUpperCase() + txt.slice(1);
  }

  static prettyAddress(a: AddressDto, withCountry = true): string {
    if (!a) {
      return '';
    }
    const strNo = a.streetNumber ? ' ' + a.streetNumber : '';
    let text = a.street + strNo + ', ' + (a.zipCode ? a.zipCode + ' ' : '') + a.town;
    if (withCountry && a.country) {
      text += ', ' + a.country.name;
    }
    return text;
  }

  // gets the value of nested property e.g. object['x.y']
  static getPropertyRaw(object: any, property: string): any {
    const parts: string[] = property.split('.');
    let subObj = object;
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      subObj = (<any>subObj)[part];
      if (!subObj && i < parts.length - 1) {
        subObj = <any>{};
      }
    }
    return subObj;
  }

  // sets the value of nested property e.g. object['x.y']='z'
  static setPropertyRaw(object: any, property: string, value: any) {
    const parts: string[] = property.split('.');
    let subObj = object;
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if (i < parts.length - 1) {
        if (!(<any>subObj)[part]) {
          (<any>subObj)[part] = {};
        }
        subObj = (<any>subObj)[part];
      } else {
        (<any>subObj)[part] = value;
      }
    }
  }

  static randomString(
    length: number,
    chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  ): string {
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  static shortCode(code: string): string {
    const parts = code.split('__');
    return parts.length > 0 ? parts[parts.length - 1] : code;
  }

  static isNull(v: any): boolean {
    if (v === 0) {
      return false;
    }
    return !v;
  }

  static booleanValue(v: string | boolean): boolean {
    return v !== 'false' && !!v;
  }
}
