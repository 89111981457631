import {NgModule} from '@angular/core';

import {HtmlEditorComponent} from './html-editor.component';
import {CKEditorModule} from 'ckeditor4-angular';

@NgModule({
  imports: [CKEditorModule],
  declarations: [HtmlEditorComponent],
  exports: [HtmlEditorComponent],
})
export class HtmlEditorModule {}
