import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {
  BrokerClientContractCriteriaDto,
  ContractVersionSimpleDto,
  QuotationCriteriaDto,
  QuotationDto,
} from '../../../bonding_shared/model/dtos';
import {QuotationService} from '../../services/quotation.service';
import {BrokerPortfolioService} from '../../services/broker-portfolio.service';

/**
 * Service for common logic of broker components.
 *
 */

@Injectable()
export class BrokerGuiService {
  /**
   * Keeps selected criteria for contract search view
   */
  searchDataProvider: SearchDataProvider<QuotationCriteriaDto, QuotationDto>;
  portfolioSearchDataProvider: SearchDataProvider<BrokerClientContractCriteriaDto, ContractVersionSimpleDto>;

  constructor(private quotationService: QuotationService, private brokerPortfolioService: BrokerPortfolioService) {
    this.searchDataProvider = new SearchDataProvider<QuotationCriteriaDto, QuotationDto>(quotationService);
    this.portfolioSearchDataProvider = new SearchDataProvider<
      BrokerClientContractCriteriaDto,
      ContractVersionSimpleDto
    >(brokerPortfolioService);
  }

  // isContractRequest(contract: ContractVersion): boolean {
  //   return contract.status.id < ContractStatus.CONTRACT_ACTIVATED;
  // }
  //
  // getContractNumber(contract: ContractVersion): string {
  //   if (contract.id && contract.status) {
  //     if (!this.isContractRequest(contract)) {
  //       return contract.contract.number + '/' + contract.versionNumber;
  //     } else {
  //       return contract.contract.requestNumber + '/' + contract.versionNumber;
  //     }
  //   }
  //   return undefined;
  //
  // }
  //
  // gerContractVersion(contractVersionId: number, contractId: number): Observable<ContractVersion> {
  //   if(contractVersionId && contractVersionId > 0) {
  //     return this.contractVersionService.getContractVersion(contractVersionId);
  //   } else  if(contractId && contractId > 0) {
  //     return this.contractVersionService.getLastContractVersion(contractId);
  //   } else {
  //     return Observable.of(<ContractVersion>{});
  //   }
  // }
  //
  // createDataProvider<ContractVersionCriteriaDto, ContractVersion>(): SearchDataProvider<ContractVersionCriteriaDto, ContractVersion> {
  //   return new SearchDataProvider<ContractVersionCriteriaDto, ContractVersion>(this.contractVersionService);
  // }
}
