import {AppConfigService} from '../../bonding_shared/services/app-config.service';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService} from '../../bonding_shared/services/abstract.service';

@Injectable()
export class PortalCollectionClaimService extends AbstractService {
  protected url = this.urlPrefix + 'portal/collectionClaim';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  validateClaimFromCollection(collectionId: number): Observable<boolean> {
    return this.get<boolean>(this.url + '/validateClaimFromCollection/' + collectionId);
  }
}
