<div class="bon-row" *ngIf="errorMessage && showBackendErrors">
  <backend-error warningsTitle="Search warnings" errorsTitle="Search errors" [errors]="errorMessage" serverErrorPrefix="Search errors"></backend-error>
</div>
<table [class.table]="spacer" [class.table-no-spacer]="!spacer">
  <thead class="table-header">
  <ng-container *ngIf="!hideHeader">
    <tr *ngFor="let i of headerRowIndexes" class="table-header">
      <th *ngFor="let column of columns" (click)="onColumnHeaderClick(column)" [style.width]="column.cellWidth"
          [class.selected]="useSelectedClassForCell(column)" [class.ellipsis]="column.ellipsis">
        <div *ngIf="columnMode && (indexOf(column) === 0 || !((i === 0) && customHeaderLink))" style="text-align: right" >
          {{column.titles[i] | translate}}
        </div>
        <div *ngIf="columnMode && indexOf(column) !== 0 && (i === 0) && customHeaderLink" style="text-align: right">
          <a class="a-link" (click)="onCustomHeaderClick(column)">
            {{column.titles[i] | translate}}
          </a>
        </div>
        <ng-container *ngIf="showColumnTitle(column)">
          <div *ngIf="!columnMode && isColumnSortable(column) && column.isNumber()" style="text-align: right">
            <a class="a-link">
              <i [class]="sortClass(column)"></i>  {{column.title | translate}}
            </a>
          </div>
          <a *ngIf="!columnMode && isColumnSortable(column) && !column.isNumber()" class="a-link">
            <i [class]="sortClass(column)"></i>  {{column.title | translate}}
          </a>
          <div *ngIf="!columnMode && !isColumnSortable(column) && column.isNumber()" style="text-align: right">{{column.title | translate}}</div>
          <div *ngIf="!columnMode && !isColumnSortable(column) && !column.isNumber()">{{column.title | translate}}</div>
        </ng-container>
      </th>
      <th width="{{buttonsCellWidth}}" *ngIf="anyButtonsVisible()">
        <div style="white-space: nowrap; float: right;">
          <div *ngIf="isDeleteAllVisible()" style="white-space: nowrap; display: inline; margin: 0px 1px;">
            <button type="button" (click)="deleteAllClicked()" [disabled]="isRowInEdition()" class="btn-sm btn-rounded btn-danger fa fa-trash">
            </button>
          </div>
          <div *ngIf="isAddVisible()" style="white-space: nowrap; display: inline; margin: 0px 1px;">
            <button type="button" [disabled]="isAddDisabled()" (click)="addItemClicked()" [title]="addButtonLabel" class="btn-sm btn-rounded btn-info fa fa-plus">
            </button>
          </div>
          <div *ngIf="isPasteFromExcelVisible()" style="white-space: nowrap; display: inline; margin: 0px 1px;">
            <button type="button" [disabled]="isPasteFromExcelDisabled()" (click)="onPasteFromExcelClick()" title="Paste from Excel"
                    class="btn-sm btn-rounded btn-info fa fa-list-alt">
            </button>
          </div>
          <div *ngIf="checkAllVisible" >
            <div style="white-space: nowrap; display: inline; margin: 0px 1px;">
              <button type="button" (click)="onUncheckAll()" [title]="'common.button.uncheckAll' | translate" class="btn-sm btn-rounded btn-info fa fa-square-o">
              </button>
            </div>
            <div style="white-space: nowrap; display: inline; margin: 0px 1px;">
              <button type="button" (click)="onCheckAll()" [title]="'common.button.checkAll' | translate" class="btn-sm btn-rounded btn-info fa fa-check-square-o">
              </button>
            </div>
          </div>

        </div>
      </th>
    </tr>
  </ng-container>
  <tr *ngIf="pasteFromExcelWindowVisible">
    <th [attr.colspan]="columns.length + 1">
      <textarea placeholder="Paste data from Excel here" style="width: 100%;"
                (input)="onPasteFromExcel($event.target.value);" rows="1" [bonFocus]="true"></textarea>
    </th>
  </tr>
  </thead>
  <tbody>


  <tr *ngFor="let item of items"
      [class.selected]="useSelectedClass(item)" class="hover-table-row"
      [class.deleted]="item && item['deleted']"
      [class.tr-bottom-border]="rowFormatCallback(item).solidBottomBorder"
      (mouseover)="hoverOverRow(item)">
    <ng-container *ngIf="showRow(item)">
      <td *ngFor="let column of columns" (click)="onRowClick(item, column)" [class.selected]="useSelectedClassForCell(column)"
          [class.allign-right]="column.isType('number')" [ngClass] = "column.class" [style.width]="column.cellWidth"
          class="bon-table-cell" [class.ellipsis]="column.ellipsis"
      >
        <!-- Edit mode -->
        <div *ngIf="isCellInEdition(column, item) && !addDictRow" style="position: relative;">
          <input *ngIf="column.type === 'archetypeCheckbox'" type="checkbox" [checked]="isChecked(item)" (click)="onArchetypeCheckboxClicked(item, column, $event)">
          <input *ngIf="column.isText() && !column.isNumber() && !formModel"
                 [type]="column.type" [value]="getProperty(item, column)"
                 (change)="setProperty(item, column, $event)"
                 [min]="column.autoMinMax && column.min" [max]="column.autoMinMax && column.max"
                 class="bon-table-input" style="width: 100%" (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)"
                 autocomplete="dontyoudare">
          <input *ngIf="column.isText() && !column.isNumber() && formModel"
                 [type]="column.type" [value]="getProperty(item, column)"
                 (change)="setProperty(item, column, $event)"
                 [min]="column.autoMinMax && column.min" [max]="column.autoMinMax && column.max"
                 [formControl]="getControl(item, column)"
                 [ngClass]="getInputCssStyle(getControl(item, column))" style="width: 100%" (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)"
                 autocomplete="dontyoudare">
          <div *ngIf="column.isText() && column.isNumber() && !formModel">
            <num-input [type]="column.numberType" [ngModel]="getProperty(item, column)" [infinity]="column.infinity"
                       (changeValue)="setProperty(item, column, { target: { value: $event }})" [max]="column.autoMinMax && column.getMax(item)"
                       style="display: inline-block;" [class.with-base-multiplier]="column.numberBaseMultiplier === 1000" [min]="column.autoMinMax && column.getMin(item)"
                       (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)" [numberOfDecimalPlaces]="column.numberOfDecimalPlaces" [allowNegative]="column.doesAllowNegativeNumbers(item)"></num-input>
            <div *ngIf="column.numberBaseMultiplier === 1000" style="display: inline-block; font-size: 14px; margin-left: -3px;">.000</div>
          </div>
          <div *ngIf="column.isText() && column.isNumber() && formModel">
            <num-input [type]="column.numberType" [formControl]="getControl(item, column)" [infinity]="column.infinity"
                       (changeValue)="setProperty(item, column, { target: { value: $event }})" [max]="column.autoMinMax && column.getMax(item)"
                       style="display: inline-block;" [class.with-base-multiplier]="column.numberBaseMultiplier === 1000" [min]="column.autoMinMax && column.getMin(item)"
                       (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)" [numberOfDecimalPlaces]="column.numberOfDecimalPlaces" [allowNegative]="column.doesAllowNegativeNumbers(item)"></num-input>
            <div *ngIf="column.numberBaseMultiplier === 1000"
                 style="display: inline-block; font-size: 14px; margin-left: -3px;">.000
            </div>
          </div>

          <input *ngIf="column.isBoolean()" [type]="column.type"
                 [checked]="(getProperty(item, column) || column.checked)"
                 (change)="setProperty(item, column, $event)" (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)"
                 [formControl]="getControl(item, column)">

          <item-combo *ngIf="column.type === 'combo'" [ngModel]="getProperty(item, column)"
                      (selectItem)="setPropertySimple(item, column, $event)" [formControl]="getControl(item, column)"
                      [items]="column.comboItems" [label]="column.comboLabel"
                      [nullLabel]="column.nullLabel" [toStringFunc]="column.toStringFunc"></item-combo>
          <string-combo  *ngIf="column.type === 'stringCombo'" [ngModel]="getProperty(item, column)"
                         (changeItem)="setPropertySimple(item, column, $event)"
                         [items]="column.stringComboItems" [preSelectedValue]="getProperty(item, column)"></string-combo>
          <boolean-combo *ngIf="column.type === 'booleanCombo'" [ngModel]="getProperty(item, column)"
                         [nullLabel]="column.boolComboNullLabel" [falseLabel]="column.boolComboFalseLabel" [trueLabel]="column.boolComboTrueLabel"
                         [allowUndefined]="column.boolComboAllowUndefined" (changeItem)="setPropertySimple(item, column, $event)"></boolean-combo>
          <user-combo *ngIf="column.type === 'userCombo'" [ngModel]="getProperty(item, column)" [nullLabel]="column.userComboNullLabel"
                      [range]="column.userComboRange" (selectItem)="setPropertySimple(item, column, $event)"></user-combo>
          <dict-combo *ngIf="column.isDictionary() && !formModel" [ngModel]="getProperty(item, column)"
                      (changeItem)="setPropertySimple(item, column, $event)"
                      [dictionaryUserRoleId]="column.dictionaryUserRoleId"
                      [dictionary]="column.dictionary" [hiddenIds]="getHiddenIds(item, column)"
                      [parentDictionary]="column.parentDictionary"
                      [parentDictionaryEntryId]="column.parentDictionaryEntryId" [label]="column.dictLabel"
                      class="bon-table-select" (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)"
                      [buId]="column.buId"
                      [nullLabel]="column.nullLabel" [profileId]="column.dictionaryProfileId" [codeRegexp]="column.codeRegexp"></dict-combo>
          <dict-combo *ngIf="column.isDictionary() && formModel" [ngModel]="getProperty(item, column)"
                      (changeItem)="setPropertySimple(item, column, $event)"
                      [dictionaryUserRoleId]="column.dictionaryUserRoleId"
                      [dictionary]="column.dictionary" [hiddenIds]="getHiddenIds(item, column)"
                      [parentDictionary]="column.parentDictionary"
                      [parentDictionaryEntryId]="column.parentDictionaryEntryId" [label]="column.dictLabel"
                      [formControl]="getControl(item, column)" [nullLabel]="column.nullLabel" [profileId]="column.dictionaryProfileId" [codeRegexp]="column.codeRegexp"
                      [ngClass]="getDictComboCssStyle(getControl(item, column))" (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)" [buId]="column.buId"></dict-combo>

          <dict-auto-complete *ngIf="column.isAutoComplete()" [ngModel]="getProperty(item, column)" [dictionary]="column.dictionary"
                              (changeItem)="autoCompleteChange(item, column, $event)" valueFormatter="name" listFormatter="name" [matchFormatted]="true"
                              (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)">
          </dict-auto-complete>

          <div *ngIf="column.isDate()" [style]="'width:' + (column.isType('datetime') ? 170 : 115) + 'px'">
            <date-picker *ngIf="!formModel" [ngModel]="getProperty(item, column)"
                         (changeItem)="setPropertySimple(item, column, $event)"
                         [type]="column.type" (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)"
                         [minDate]="column.getMinDate(column.minDate, item)" [maxDate]="column.getMaxDate(column.maxDate, item)"
                         [notPast]="column.notPast" [notFuture]="column.notFuture"
            >
            </date-picker>
            <date-picker *ngIf="formModel" [ngModel] = "getProperty(item, column)"
                         (changeItem)="setPropertySimple(item, column, $event)"
                         [type]="column.type"
                         [formControl]="getControl(item, column)" (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)"
                         [minDate]="column.getMinDate(column.minDate, item)" [maxDate]="column.getMaxDate(column.maxDate, item)"
                         [notPast]="column.notPast" [notFuture]="column.notFuture"
            >
            </date-picker>

          </div>
          <div *ngIf="column.isCustom()">
            <column-template [column]="column" [item]="item" [edition]="true" [formControlModel]="getControl(item, column)"
                             (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)"></column-template>
          </div>
        </div>

        <!-- The editon of row when dictionary is an item -->
        <div *ngIf="isCellInEdition(column, item) && addDictRow && getColumnIndex(column) == addDictColSpan">
          <dict-combo *ngIf="formModel" class="bon-table-select" [dictionary]="addDictRow" [(ngModel)]="newDictRow"
                      [hiddenIds]="getHiddenIds(item, column)" [formControl]="getControl(item, column)"></dict-combo>
          <dict-combo *ngIf="!formModel" class="bon-table-select" [dictionary]="addDictRow" [(ngModel)]="newDictRow"
                      [hiddenIds]="getHiddenIds(item, column)">
          </dict-combo>
        </div>

        <!-- Read only mode -->
        <div *ngIf="!isCellInEdition(column, item)">
          <ng-container *ngIf="column.template">
               <span *ngIf="column.isLink(item)" [title]="getItemTooltip(column, item)">
                    <a (click)="onLinkClicked(item, column, $event)" class="a-link">
                      <column-template [column]="column" [item]="item" [edition]="false" [formControlModel]="getControl(item, column)"></column-template>
                    </a>
                </span>
               <span *ngIf="!column.isLink(item)" [title]="getItemTooltip(column, item)">
                    <column-template [column]="column" [item]="item" [edition]="false" [formControlModel]="getControl(item, column)"></column-template>
                </span>
          </ng-container>
          <ng-container *ngIf="!column.template">
            <dict-combo *ngIf="column.isType('dictionary') && !formModel" [ngModel]="getProperty(item, column)" [presentationMode]="true"
                        (changeItem)="setPropertySimple(item, column, $event)" [dictionaryUserRoleId]="column.dictionaryUserRoleId"
                        [dictionary]="column.dictionary" [hiddenIds]="getHiddenIds(item, column)" [parentDictionary]="column.parentDictionary"
                        [parentDictionaryEntryId]="column.parentDictionaryEntryId" [label]="column.dictLabel"
                        class="bon-table-select" (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)" [buId]="column.buId"
                        [nullLabel]="column.nullLabel" [profileId]="column.dictionaryProfileId" [codeRegexp]="column.codeRegexp"></dict-combo>
            <dict-combo *ngIf="column.isType('dictionary') && formModel" [ngModel]="getProperty(item, column)" [presentationMode]="true"
                        (changeItem)="setPropertySimple(item, column, $event)" [dictionaryUserRoleId]="column.dictionaryUserRoleId"
                        [dictionary]="column.dictionary" [hiddenIds]="getHiddenIds(item, column)" [parentDictionary]="column.parentDictionary"
                        [parentDictionaryEntryId]="column.parentDictionaryEntryId" [label]="column.dictLabel"
                        [formControl]="getControl(item, column)" [nullLabel]="column.nullLabel" [profileId]="column.dictionaryProfileId" [codeRegexp]="column.codeRegexp"
                        [ngClass]="getDictComboCssStyle(getControl(item, column))" (keyup.enter)="onDone(item)" (keyup.esc)="onCancel(item)" [buId]="column.buId"></dict-combo>
            <boolean-combo *ngIf="column.isType('booleanCombo')" [ngModel]="getProperty(item, column)" [presentationMode]="true"
                           [nullLabel]="column.boolComboNullLabel" [falseLabel]="column.boolComboFalseLabel" [trueLabel]="column.boolComboTrueLabel"></boolean-combo>
            <user-combo *ngIf="column.isType('userCombo')" [ngModel]="getProperty(item, column)" [presentationMode]="true"
                           [nullLabel]="column.userComboNullLabel" [range]="column.userComboRange"></user-combo>
            <span *ngIf="!column.isBoolean() && !column.isLink(item) && column.type !== 'archetypeCheckbox' && !column.isType('dictionary') && !column.isType('booleanCombo') && !column.isType('userCombo')"
                  [title]="getItemTooltip(column, item)">{{getItemText(column, item)}}</span>
            <span *ngIf="!column.isBoolean() && column.isLink(item) && column.type !== 'archetypeCheckbox' && !column.isType('dictionary')" [title]="getItemTooltip(column, item)">
                    <a (click)="onLinkClicked(item, column, $event)" class="a-link">
                      {{getItemText(column, item)}}
                    </a>
            </span>
            <input *ngIf="column.isBoolean() && column.type !== 'archetypeCheckbox'" [type]="column.type"
                   [checked]="getProperty(item, column)" disabled>
            <input *ngIf="column.type === 'archetypeCheckbox'" type="checkbox" [checked]="isChecked(item)"
                   (click)="onArchetypeCheckboxClicked(item, column, $event)" [disabled]="!(editable && isRowEditable(item))">

          </ng-container>


        </div>

        <error-message [control]="getControl(item, column)"
                       [show]="(isRowInEdition(item) && showErrors) || showAllErrors"></error-message>

      </td>

      <!-- Manipulation buttons -->
      <td *ngIf="anyButtonsVisible()" width="{{buttonsCellWidth}}" >
        <div *ngIf="areButtonsVisible(item)" style="float: right; white-space:nowrap;">
          <span *ngIf="!isRowInEdition(item) && customButtons">
            <span  *ngFor="let b of customButtons">
              <button *ngIf="!b.dropdownItems" (click)="onCustomButtonClick(b, item)" title="{{b.title}}"
                      type="button"
                      class="btn-sm btn-rounded {{b.class}}" [btnIcon]="b.icon" [disabled]="!item.id">
                {{b.name}}
              </button>
              <item-dropdown *ngIf="b.dropdownItems" [alignment]="b.alignment" [caption]="b.name" [items]="b.dropdownItems"
                  (menuSelect)="onCustomButtonClick(b, item, $event)" buttonClass="btn-sm bon-btn-info">
              </item-dropdown>
            </span>
          </span>
          <span *ngFor="let b of customHoverButtons">
            <button *ngIf="selection && isRowHover(item) && !isRowInEdition(item) && customButtonVisible(item)"
                    type="button"
                    (click)="onCustomButtonClick(b, item)"
                    title="{{b.title}}" class="btn-sm btn-rounded {{b.class}}">
              {{b.name}}
            </button>
          </span>
          <button type="button" *ngIf="isMoveTopButtonVisible(item)" (click)="onMoveTop(item)" title="Move up"
                  [disabled]="getItemIndex(item) === 0 || isRowInEdition(item)"
                  class="btn-sm btn-info btn-rounded fa fa-angle-double-up">
          </button>
          <button type="button" *ngIf="isDeleteButtonVisible(item)" (click)="onDelete(item)" [disabled]="isDeleteButtonDisabled(item)"
                  [title]="isRowDeletionDisabled(item) && rowDeletionDisabledHint && rowDeletionDisabledHint(item) || ('common.button.delete' | translate)"
                  class="btn-sm btn-rounded btn-danger fa fa-trash">
          </button>
          <button type="button" *ngIf="areConfirmButtonsVisible(item)" (click)="onDone(item)" title="{{'common.button.done' | translate}}"
                  class="btn-sm btn-rounded btn-success fa fa-check-circle" >
          </button>
          <button type="button" *ngIf="areConfirmButtonsVisible(item)" (click)="onCancel(item)" title="{{'common.button.cancel' | translate}}"
                  class="btn-sm btn-rounded btn-warning fa fa-times-circle">
          </button>
          <button type="button" *ngIf="isValidToButtonVisible(item)" (click)="setValidTo(item)" title="{{'common.button.close' | translate}}"
                  class="btn-sm btn-rounded btn-danger fa fa-ban" >
          </button>
        </div>
      </td>
    </ng-container>
  </tr>

  <tr *ngIf="footer" class="table-footer">
    <td *ngFor="let column of columns" [class.allign-right]="column.isType('number')"
        [ngClass] = "column.class">
      <b>{{getFooterValue(column)}}</b>
    </td>
  </tr>
  </tbody>
</table>

<div *ngIf="pagination && totalCount > pageSize" class="text-xs-center">
  <pagination #paginationComponent [itemsPerPage]="pageSize" [totalItems]="totalCount" [(ngModel)]="page"
              (pageChanged)="paginationPageChanged($event)" [maxSize]=5 [rotate]="false" [disabled]="inProgress"
              [boundaryLinks]="true" class="pagination-sm" previousText="&lsaquo;" nextText="&rsaquo;"
              firstText="&laquo;" lastText="&raquo;">
  </pagination>
</div>

<error-message [control]="form" [show]="showAllErrors"></error-message>

<confirm-dialog #pasteFromExcelConfirmDialog [showConfirmButton]="pasteFromExcelResult?.items.length > 0"
                [showRejectButton]="pasteFromExcelResult?.itemsCount > 0" [showCloseButton]="pasteFromExcelResult?.itemsCount === 0">
  <div> {{'table.showingErrors' | translate: {errorsCount:  pasteFromExcelResult?.errorsCount} }}</div>
  <div style="max-height: 600px; overflow-y: auto;">
    <table border="1" width="100%">
      <thead>
        <tr>
          <th style="padding: 2px;">{{'table.rowNumber' | translate}}</th>
          <th style="padding: 2px;">{{'table.columnName' | translate}}</th>
          <th style="padding: 2px;">{{'table.errorMessage' | translate}}</th>
        </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let rowError of pasteFromExcelResult?.rowErrors">
        <tr *ngFor="let colError of rowError.columnErrors; let i = index">
          <td style="padding: 2px;" *ngIf="i === 0" attr.rowspan="{{rowError.columnErrors.length}}">{{rowError.rowNumber}}</td>
          <td style="padding: 2px;">{{colError.columnName}}</td>
          <td style="padding: 2px;">{{colError.errorMessage}}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</confirm-dialog>
