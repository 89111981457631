import {Pipe, PipeTransform} from '@angular/core';
import {UserDto} from '../model';
import {LoggedUserService} from '../services';

@Pipe({
  name: 'hasRight',
})
export class HasRightPipe implements PipeTransform {
  private rights: string[] = [];

  constructor(private loggedUserService: LoggedUserService) {
    const user: UserDto = loggedUserService.getLoggedUserData();
    for (const r of user.rights) {
      this.rights.push(r.code);
    }
  }

  transform(right: string): boolean {
    if (!right) {
      return false;
    }
    const hasAnyRight = right.split(',');
    return this.rights.findIndex((r) => hasAnyRight.includes(r)) !== -1;
  }
}
