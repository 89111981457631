import {Directive} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {Moment} from 'moment';
import * as moment from 'moment';

@Directive({
  selector: '[contractTerminationValidate]',
  providers: [{provide: NG_VALIDATORS, useExisting: ContractTerminationValidatorDirective, multi: true}],
})
export class ContractTerminationValidatorDirective implements Validator {
  validate(form: UntypedFormGroup): ValidationErrors {
    return this.noticePeriodNotLongerThan6Months(form);
  }

  private noticePeriodNotLongerThan6Months(group: UntypedFormGroup): ValidationErrors {
    const noticePeriodValue: UntypedFormControl = <UntypedFormControl>group.get('noticePeriodValue');
    const noticePeriodUnit: UntypedFormControl = <UntypedFormControl>group.get('noticePeriodUnit');

    if (!noticePeriodValue || !noticePeriodUnit) {
      return null;
    }
    const noticePeriodValueVal = noticePeriodValue.value;
    const noticePeriodUnitVal = noticePeriodUnit.value;

    if (!noticePeriodUnitVal || !noticePeriodUnitVal.name) {
      return null;
    }
    return noticePeriodValueVal < 1 || this.isNotLongerThan6Months(noticePeriodValueVal, noticePeriodUnitVal.name)
      ? null
      : {invalidContractTermination: true};
  }

  isNotLongerThan6Months(noticePeriodValue: number, noticePeriodUnitName: string): boolean {
    const calcNotificationDate = this.addToDatePeriod(
      new Date(),
      noticePeriodValue,
      noticePeriodUnitName.toLowerCase()
    );
    const _6MonthLater = this.addToDatePeriod(new Date(), 6, 'month');
    return calcNotificationDate.isSameOrBefore(_6MonthLater);
  }

  private addToDatePeriod(startDate: Date, n: number, unit): Moment {
    return moment(startDate).startOf('day').add(unit, n).endOf(unit);
  }
}
