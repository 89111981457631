<a-table [pagination]="true" [dataProvider]="dataProvider" (selectItem)="emitInvoiceItem($event)"
         (dataProviderSearchFinished)="dataProviderSearchFinished($event)" [selection]="false" [canUnselect]="false">
  <column labelKey="invoiceItem.shared.list.title" property="title" sort="title" [link]="true"
          (linkClick)="routerService.toInvoiceItemDetails(categoryId, $event.id)"></column>
  <column labelKey="invoiceItem.shared.list.businessObjectTypeAndId" (linkClick)="goToBusinessObject($event)" [link]="true">
    <ng-template let-item="item">
      {{item?.businessObject?.relatedTo?.name}} / {{item?.businessObject?.description || item?.businessObject?.relatedToId}}
    </ng-template>
  </column>
  <column labelKey="invoiceItem.shared.list.subtype" property="subtype" sort="subtype.name" dictionary="InvoiceItemSubtype"></column>
  <column labelKey="invoiceItem.shared.list.netAmount" property="netAmount" sort="netAmount" type="number"></column>
  <ng-container *ngFor="let taxCategory of taxCategories">
    <column [title]="taxCategory.name + ' ' + ('invoiceItem.shared.list.taxType' | translate)" [editable]="false">
      <ng-template let-item="item" let-edition="edition">{{formatTaxType(item, taxCategory)}}</ng-template>
    </column>
    <column [title]="taxCategory.name + ' ' + ('invoiceItem.shared.list.raxRateValue' | translate)" [editable]="false">
      <ng-template let-item="item" let-edition="edition">{{formatTax(item.taxes[taxCategory.id])}}</ng-template>
    </column>
  </ng-container>
  <column labelKey="invoiceItem.shared.list.creationDate" property="creationDate" sort="creationDate" initialSort="DOWN" type="date"></column>
  <column labelKey="invoiceItem.shared.list.dateFrom" property="dateFrom" sort="dateFrom" type="date"></column>
  <column labelKey="invoiceItem.shared.list.dateTo" property="dateTo" sort="dateTo" type="date"></column>
  <column *ngIf="showSourceInvoice" labelKey="invoiceItem.shared.list.sourceInvoice.clearingDate" property="sourceInvoice.clearingDate" sort="sourceInvoice.clearingDate" type="date"></column>
</a-table>
<div class="bon-row" *ngIf="isInvoiceAllVisible()">
  <div (click)="invoiceAll()" class="btn btn-secondary">{{'invoiceItem.shared.list.invoiceAll' | cattranslate: parentCategory}}</div>
</div>
