import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SharedComponentsModule} from '../../../../../bonding_shared/components/shared-components.module';
import {CompanySharedModule} from '../company-shared.module';
import {CompanyOrderReportComponent} from './company-order-report.component';
import {CompanySelectorSharedComponent} from './company-selector-shared.component';
import {CompanySelectorBiComponent} from './company-selector-bi.component';
import {CompanySelectorExtendedComponent} from './company-selector-extended.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CompanyPhysicalPersonSelectorComponent} from './company-physical-person-selector.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    CompanySharedModule,
    BsDropdownModule,
  ],
  exports: [CompanySelectorExtendedComponent, CompanySelectorBiComponent, CompanyPhysicalPersonSelectorComponent],
  declarations: [
    CompanySelectorExtendedComponent,
    CompanySelectorBiComponent,
    CompanySelectorSharedComponent,
    CompanyOrderReportComponent,
    CompanyPhysicalPersonSelectorComponent,
  ],
})
export class CompanyExtendedSelectorModule {}
