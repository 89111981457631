import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'multi-item-dropdown',
  template: `
    <div *ngIf="_items && _items.length > 0" class="btn-group" dropdown [class.dropup]="dropup" [isDisabled]="disabled">
      <button type="button" [ngClass]="['dropdown-toggle', buttonClass]" dropdownToggle>
        {{ caption | translate }} <span class="caret"></span>
      </button>
      <ul [class]="'dropdown-menu dropdown-menu-' + alignment" role="menu">
        <li *ngFor="let item of items" role="menuItem" [class.dropdown-submenu]="item.items && item.items.length > 0">
          <a class="dropdown-item bold" (click)="click(item)">{{ label(item) }}</a>
          <ul
            *ngIf="item.items && item.items.length > 0"
            [class]="'dropdown-menu dropdown-menu-' + alignment"
            role="menu"
          >
            <li *ngFor="let item2 of item.items" role="menuitem">
              <a class="dropdown-item" (click)="click(item2)">{{ label(item2) }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  `,
})
export class MultiItemDropdownComponent {
  @Input() caption: string;
  @Input() disabled = false;
  @Input() dropup = false;
  @Input() buttonClass = 'bon-btn-info';
  @Input() alignment: 'left' | 'right' = 'left';
  _items: Item[];

  @Output() menuSelect = new EventEmitter<Item>();

  @Input() set items(ims: Item[] | Set<Item>) {
    this._items = ims instanceof Set ? Array.from(ims) : <Item[]>ims;
  }

  constructor(private translateService: TranslateService) {}

  get items() {
    return this._items;
  }

  click(item: Item) {
    this.menuSelect.emit(item);
  }

  label(item: Item) {
    return item.labelKey ? this.translateService.instant(item.labelKey) : item.label;
  }
}

export class Item {
  id: number;
  parentId: number;
  label: string;
  labelKey: string;
  items: Item[];
}
