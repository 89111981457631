import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {ControlContainer, NgForm} from '@angular/forms';
import {AppConfigService, PolicyContractVersionService} from '../../../../bonding_shared/services';
import {ComboItem} from '../../../../bonding_shared/components/combos';
import {DictionaryProfile} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'policy-contract-data-section',
  templateUrl: './policy-contract-data-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class PolicyContractDataSectionComponent extends Section implements OnInit {
  @Output() validFromChanged = new EventEmitter<Date>();

  readonly DictionaryProfile = DictionaryProfile;
  self = this;

  policySubYears: ComboItem[] = [];
  maxSubYears = 3;

  premiumSubinsured: RegExp;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected policyContractVersionService: PolicyContractVersionService
  ) {
    super(policyContractViewService, appService, policyContractVersionService);
  }

  ngOnInit() {
    this.policySubYears = [];
    for (let i = 1; i <= this.maxSubYears; i++) {
      this.policySubYears.push({label: i + '', value: i + ''});
    }
  }

  onGenerateNumberChange(checked: boolean) {
    if (checked) {
      this.policy.policyContract.number = null;
    }
  }

  updateTurnoverValidity() {
    if (this.policyForm.get('insuredTurnoverDomestic')) {
      this.policyForm.get('insuredTurnoverDomestic').updateValueAndValidity();
    }
    if (this.policyForm.get('insuredTurnoverExport')) {
      this.policyForm.get('insuredTurnoverExport').updateValueAndValidity();
    }
    if (this.policyForm.get('insuredTurnover')) {
      this.policyForm.get('insuredTurnover').updateValueAndValidity();
    }
  }

  onValidFromChanged(validFrom: Date) {
    this.validFromChanged.emit(validFrom);
  }
}
