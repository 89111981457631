import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {ProductLimitListDto, ProductLimitListVersionCriteriaDto} from '../../../bonding_shared/model';
import {RouterService, SearchDataProvider} from '../../../bonding_shared/services';
import {ATableComponent} from '../../../bonding_shared/components/aku-table';
import {PolicyElementaryRight} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'policylimitlist-list',
  templateUrl: './policylimitlist-list.component.pug',
})
/**
 * Policy limit list list
 */
export class PolicyLimitListListComponent implements AfterViewInit {
  readonly PolicyElementaryRight = PolicyElementaryRight;

  @Input() pageSize = 20;
  @Input() dataProvider: SearchDataProvider<ProductLimitListVersionCriteriaDto, ProductLimitListDto>;
  @Input() portalMode = false;
  @Input() pagination = true;
  @Input() showPolicy = true;
  @Input() searchOnInit = false;
  showBackendErrors = true;

  @ViewChild(ATableComponent, {static: true}) table: ATableComponent<ProductLimitListDto>;

  constructor(public router: RouterService) {}

  ngAfterViewInit() {
    if (this.searchOnInit) {
      this.search();
    }
  }

  search() {
    this.table.search();
  }

  toProductDetails(pll: ProductLimitListDto) {
    if (pll.masterPolicyContract) {
      this.router.toPolicyContractPreview(pll.masterPolicyContract.lastVersion.id);
    } else {
      this.router.toPolicyContractPreview(pll.policyInquiry.activatedOrLastVersion.id);
    }
  }

  toClientPreview(pll: ProductLimitListDto) {
    if (pll.masterPolicyContract) {
      this.router.toCompanyPreview(pll.masterPolicyContract.client.id);
    } else {
      this.router.toCompanyPreview(pll.policyInquiry.client.id);
    }
  }
}
