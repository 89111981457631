<form #ngForm="ngForm">
  <bon-section labelKey="clientbond.details.details.title">
    <div class="bon-card-group">
      <div class="bon-card-inner bon-label-150">
        <date-row labelKey="clientbond.details.details.contractDate" [(ngModel)]="bond.contractDate"
                  [showErrors]="showErrors" name="contractDate" [disabled]="!canIssue"></date-row>
        <input-row labelKey="clientbond.details.details.contractReferenceNumber"
                   [(ngModel)]="bond.contractReferenceNumber"
                   [showErrors]="showErrors" name="contractReferenceNumber" maxlength="50"
                   [disabled]="!canIssue"></input-row>
        <form-row labelKey="clientbond.details.details.contractAmount" [control]="contractAmountModel.control"
                  [showErrors]="showErrors">
          <div class="value-currency" [class.bon-input-required]="contractAmountModel.hasError('required')">
            <num-input [(ngModel)]="bond.contractAmount" name="contractAmount" #contractAmountModel="ngModel" required
                       [disabled]="!canIssue"
                       maxlength="14" (changeValue)="contractAmountChange($event)"></num-input>
            <span>{{bond.currency.code}}</span>
          </div>
        </form-row>
        <dict-row *ngIf="isIndividualContract" [(ngModel)]="bond.currency" parentDictionary="CurrencyTable"
                  [parentDictionaryEntryId]="CurrencyTable.A"
                  labelKey="Currency" name="currency" dictionary="Currency" itemLabel="code"
                  [disabled]="!canIssue"></dict-row>
        <div *ngIf="false && guiService.bondValuesVisible">
          <form-row labelKey="clientbond.details.details.bondValues.title">
            <a-table class="bon-table-sm-cells" #bondValues [items]="bond.values" [editable]="canIssue"
                     [formModel]="bondForm" [showAllErrors]="showErrors"
                     [rowDeletableCallback]="isValueDeletable.bind(self)" groupName="valuesTable">
              <column property="validFrom" type="date" cellWidth="35%" [required]="true"
                      labelKey="clientbond.details.details.bondValues.validFrom"></column>
              <column property="reason" cellWidth="25%"
                      labelKey="clientbond.details.details.bondValues.reason"></column>
              <column property="value" type="number" cellWidth="20%" (cellChange)="valueChange($event)"
                      [required]="true"
                      labelKey="clientbond.details.details.bondValues.value"></column>
              <column property="valueInSysCurr" type="number" cellWidth="20%" [editable]="false"
                      [title]="'clientbond.details.details.bondValues.valueWithCurr' | translate : {currencyCode: SYSTEM_CURRENCY_CODE}"></column>
            </a-table>
          </form-row>
        </div>
        <div class="bon-card-inner-group" *ngIf="bond.output.showPayments">
          <form-row labelKey="bond.payments.title">
            <a-table class="bon-table-sm-cells" #bondPayments [items]="bond.payments" [editable]="canIssue"
                     [formModel]="bondForm" [showAllErrors]="showErrors" (addUpdateDelete)="recalculateBondValidTo()"
                     [rowDeletableCallback]="isValueDeletable.bind(self)" groupName="paymentsTable">
              <column property="expectedDate" type="date" cellWidth="35%" [required]="true"
                      labelKey="common.date"></column>
              <column property="amount" type="number" cellWidth="20%" (cellChange)="paymentChange($event)"
                      [required]="true"
                      labelKey="clientbond.details.details.bondValues.value"></column>
              <column property="amountInSysCurr" type="number" cellWidth="20%" [editable]="false"
                      [title]="'clientbond.details.details.bondValues.valueWithCurr' | translate : {currencyCode: SYSTEM_CURRENCY_CODE}"></column>
            </a-table>
          </form-row>
        </div>
        <form-row labelKey="clientbond.details.details.language" *ngIf="showLanguage">
          <dict-combo class="bon-input-size" [(ngModel)]="bond.language" [entries]="languages" name="language"
                      [ngModelOptions]="{standalone: true}" [disabled]="!canIssue || !isLangEditable()"></dict-combo>
        </form-row>
      </div>
      <div class="bon-card-inner bon-label-150">
        <form-row labelKey="clientbond.details.details.validityPeriod"
                  [control]="validityPeriodModel.control"
                  [showErrors]="showErrors">
          <dict-combo #validityPeriodModel="ngModel"
                      [hiddenIds]="validityPeriodComboHiddenIds"
                      class="bon-input-size"
                      [class.bon-input-required]="validityPeriodModel.hasError('chooseRequired')"
                      dictionary="BondValidityPeriod"
                      [(ngModel)]="validityPeriod"
                      name="validityPeriod"
                      [chooseRequired]="true"
                      (changeItem)="onBondValidityPeriodSelect($event)"
                      [disabled]="validityPeriodDisabled || !canIssue"></dict-combo>
        </form-row>
        <date-row labelKey="clientbond.details.details.validFrom" [(ngModel)]="bond.validFrom" [disabled]="!canIssue"
                  [control]="validFromModel.control" [showErrors]="showErrors" #validFromModel="ngModel"
                  name="validFrom" required
                  (changeItem)="onValidFromChanged($event)"></date-row>
        <div *ngIf="!hideValidTo()">
          <date-row labelKey="clientbond.details.details.validTo" [(ngModel)]="bond.bondValidTo"
                    [disabled]="!canIssue || validToDisabled" [required]="canIssue"
                    [showErrors]="showErrors" name="bondValidTo"></date-row>
        </div>
        <checkbox-row *ngIf="bond.id" labelKey="clientbond.details.details.estimatedValidTo"
                      [(ngModel)]="bond.estimatedValidTo" name="estimatedValidTo" readonly="true"></checkbox-row>
        <div *ngIf="visibleCancellationAfterDate">
          <date-row labelKey="clientbond.details.details.terminationAfterDate" [(ngModel)]="bond.cancellationAfterDate"
                    [showErrors]="showErrors" name="cancellationAfterDate" [disabled]="!canIssue"></date-row>
        </div>
        <form-row
          [labelKey]="'clientbond.details.details.' + (guiService.bondValuesVisible ? 'originalValue' : 'value')"
          [control]="valueModel.control"
          [showErrors]="showErrors && !guiService.bondValuesVisible">
          <div class="value-currency" [class.bon-input-required]="valueModel.hasError('required')">
            <num-input [(ngModel)]="bond.values[0].value" name="value" #valueModel="ngModel" required
                       [disabled]="!canIssue || !isSingleValueEditable(false)" maxlength="14"></num-input>
            <span>{{bond.currency.code}}</span></div>
        </form-row>
        <it-bid-bond #itBidBondComponent [bond]="bond" [bondForm]="bondForm" [showErrors]="showErrors">
        </it-bid-bond>
        <ng-container
          *ngIf="!isIndividualContract || bondFlow.contractVersion.contract.businessUnit.countryCode === 'DE'">
          <form-row labelKey="clientbond.details.details.cityOfJurisdiction" *ngIf="isCityFromDict()"
                    [showErrors]="showErrors">
            <geo-dict-auto-complete [(ngModel)]="bond.cityOfJurisdiction"
                                    #model="ngModel"
                                    [class.bon-input-required]="model.hasError('required')"
                                    required
                                    name="cityOfJurisdiction"
                                    geoDict="city"
                                    parentDictionary="country"
                                    [parentDictionaryEntryId]="bond.executionAddress.country.id"
                                    valueFormatter="name"
                                    listFormatter="name"
                                    [matchFormatted]="true"
                                    style="display: inline-block; width: calc(100% - 180px);"
                                    [focus]="false"
                                    [disabled]="!canIssue">
            </geo-dict-auto-complete>
            <error-message [control]="bondForm.controls.cityOfJurisdiction" [show]="showErrors"></error-message>
          </form-row>
          <input-row *ngIf="!isCityFromDict()" labelKey="clientbond.details.details.cityOfJurisdiction" required
                     [(ngModel)]="bond.cityOfJurisdictionText" #cityOfJurisdictionTextModel="ngModel"
                     [showErrors]="showErrors"
                     name="cityOfJurisdictionText" maxlength="60"
                     [class.bon-input-required]="cityOfJurisdictionTextModel.hasError('required')"
                     [disabled]="!canIssue">
          </input-row>
        </ng-container>
        <it-performance-bond #itPerformanceBondComponent *ngIf="bond" [bond]="bond" [bondForm]="bondForm"
                             [showErrors]="showErrors">
        </it-performance-bond>
      </div>
    </div>
  </bon-section>

  <bon-section labelKey="clientbond.details.beneficiary.title" ngModelGroup="beneficiary">
    <div class="custom-bon-section-header-element">
        <span *ngIf="last10Beneficiaries && last10Beneficiaries.length > 0" style="margin-left:50px; font-size: 1rem;"><string-combo
          [(ngModel)]="selectedBeneficiary" [ngModelOptions]="{standalone: true}"
          nullLabelKey="clientbond.details.beneficiary.newBeneficiary" [items]="last10Beneficiaries"
          style="display:inline-block;"
          (changeItem)="onSelectLastBeneficiary($event)"
          dividerKey="clientbond.details.beneficiary.last10beneficiaries"></string-combo>
        </span>
    </div>
    <div class="bon-card-group">
      <div class="bon-card-inner bon-label-150">
<!--        <ng-container *ngIf="!bond.beneficiaryRaw.physicalPersonIndicator">-->
          <text-row *ngIf="bond.beneficiaryName; else beneficiaryRaw"
                    labelKey="clientbond.details.beneficiary.registrationName"
                    [value]="bond.beneficiaryName">
          </text-row>
          <ng-template #beneficiaryRaw>
            <form-row [class.bon-input-required]="beneficiaryRegistrationNameModel.hasError('required')"
                      labelKey="clientbond.details.beneficiary.registrationName"
                      [control]="beneficiaryRegistrationNameModel.control" [showErrors]="showErrors">
              <input class="bon-input"
                     [(ngModel)]="bond.beneficiaryRaw.registrationName" #beneficiaryRegistrationNameModel="ngModel"
                     name="registrationName" [disabled]="!canBeneficiaryEdit()"
                     required/>
            </form-row>
          </ng-template>
<!--        </ng-container>-->
        <input-row *ngIf="isItaly(bond.beneficiaryRaw.registrationAddress.country)"
                   label="PEC" [(ngModel)]="bond.beneficiaryRaw.pec" name="pec" #pecModel="ngModel" emailFormat
                   [control]="pecModel.control" [disabled]="!canBeneficiaryEdit()"
                   [showErrors]="showErrors"></input-row>
      </div>
<!--      <div *ifBUnot="'DE'" class="bon-card-inner bon-label-150">-->
      <div class="bon-card-inner bon-label-150">
        <input-row label="{{nationalIdNumberFormat.numberName | translate}}"
                   [(ngModel)]="bond.beneficiaryRaw.nationalId" name="nationalId"
                   #beneficiaryNationalIdModel="ngModel" [showErrors]="showErrors"
                   [control]="beneficiaryNationalIdModel.control" [disabled]="!canBeneficiaryEdit()"></input-row>
        <input-row label="{{taxNumberFormat.numberName | translate}}"
                   [(ngModel)]="bond.beneficiaryRaw.vatNumber" name="vatNumber"
                   #beneficiaryVatNumberModel="ngModel" [showErrors]="showErrors"
                   [control]="beneficiaryVatNumberModel.control" [disabled]="!canBeneficiaryEdit()"></input-row>
        <input-row label="Stat ID"
                   [(ngModel)]="bond.beneficiaryRaw.statNumber" name="statNumber"
                   #beneficiaryStatNumberModel="ngModel" [showErrors]="showErrors"
                   [control]="beneficiaryStatNumberModel.control" [disabled]="!canBeneficiaryEdit()"></input-row>
      </div>
    </div>
    <!-- * Section: Registration address * -->
    <div>
      <div class="bon-card-group">
        <div class="bon-card-inner bon-row bon-header" style="width: 17%; padding-left: 5px; font-size: 1.15rem;"
             translate="clientbond.details.beneficiary.registrationAddressOfBeneficiary">
        </div>
        <checkbox-row class="bon-card-inner bon-row bon-label-180" style="width: 34%"
                      labelKey="clientbond.details.beneficiary.correspondenceAddress"
                      [(ngModel)]="postalAddress" [ngModelOptions]="{standalone: true}"
                      (changeState)="postalAddressClicked($event)" [disabled]="!canIssue"></checkbox-row>
        <checkbox-row class="bon-card-inner bon-row bon-label-230" style="width: 34%"
                      [labelKey]="getExecutionAddressLabelKey()"
                      [(ngModel)]="contractAddress" [ngModelOptions]="{standalone: true}"
                      (changeState)="executionAddressClicked($event)" [disabled]="!canIssue"></checkbox-row>
        <div class="bon-card-inner bon-row" style="width: 15%"></div>
      </div>
      <div class="bon-card-group" style="margin-left: 5px;">
        <div class="bon-card-inner">
          <address-table [form]="bondForm" controlName="registrationAddress" [oneTypeMode]="true"
                         [addresses]="[bond.beneficiaryRaw.registrationAddress]" [showErrors]="showErrors"
                         [isCountryConst]="isCountryConst()" [editable]="canBeneficiaryEdit()"
                         [countryDictionaryProfileId]="DictionaryProfile.PORTAL_COUNTRY"
                         (countryChange)="onCountryChange()"
                         [zipCodeRequired]="true">
          </address-table>
        </div>
      </div>
    </div>
    <!-- * Section: Correspondence address * -->
    <div *ngIf="postalAddress">
      <div class="bon-card-group bon-card-inner bon-row bon-header" style="padding-left: 35px; font-size: 1.15rem"
           translate="clientbond.details.beneficiary.correspondenceAddressOfBeneficiary">
      </div>
      <div class="bon-card-group" style="margin-left: 5px;">
        <div class="bon-card-inner">
          <address-table #correspondenceAddressComponent [form]="bondForm" controlName="correspondenceAddress"
                         [addresses]="[bond.beneficiaryRaw.correnspondenceAddress]"
                         [showErrors]="showErrors && postalAddress"
                         [isCountryConst]="isCountryConst()" [editable]="canBeneficiaryEdit() && postalAddress"
                         [oneTypeMode]="true"
                         [zipCodeRequired]="true">
          </address-table>
        </div>
      </div>
    </div>
    <!-- * Section: Contract execution address * -->
    <div *ngIf="contractAddress">
      <div class="bon-card-group bon-card-inner bon-row" style="padding-left: 35px; font-size: 1.15rem"
           [translate]="getExecutionAddressLabelKey()">
      </div>
      <div class="bon-card-group" style="margin-left: 5px;">
        <div class="bon-card-inner">
          <address-table #contractAddressComponent [hidden]="!contractAddress" [form]="bondForm"
                         controlName="executionAddress"
                         [addresses]="[bond.executionAddress]" [showErrors]="showErrors && contractAddress"
                         [isCountryConst]="isCountryConst()" [editable]="canBeneficiaryEdit() && contractAddress"
                         (countryChange)="onCountryChange()" [oneTypeMode]="true"
                         [zipCodeRequired]="true">
          </address-table>
        </div>
      </div>
    </div>
    <!-- * Section: Contract subject / Comment * -->
    <div class="bon-card-group">
      <div class="bon-card-inner">
        <label style="width:100%" translate="clientbond.details.beneficiary.contractSubject.title"></label>
        <textarea-row class="required" [(ngModel)]="bond.contractualObligations" rows="2"
                      [disabled]="!canIssue" [maxlength]="getContractSubjectMaxLength()"
                      name="contractualObligations" #contractualObligations="ngModel"
                      [control]="contractualObligations.control" required
                      [showErrors]="showErrors"></textarea-row>
        <label *ngIf="canIssue">
          <span translate="clientbond.details.beneficiary.contractSubject.charactersLeft"></span>
          <span>{{': '}}{{getContractSubjectCounterValue()}}</span>
        </label>
      </div>
      <div class="bon-card-inner">
        <label style="width:100%" translate="common.comment"></label>
        <textarea-row [(ngModel)]="bond.clientComment" rows="2"
                      [disabled]="!canIssue" [maxlength]="4096"
                      name="clientComment"></textarea-row>
        <label *ngIf="canIssue">
          <span translate="common.charactersLeft"></span>
          <span>{{': '}}{{getClientCommentCounterValue()}}</span>
        </label>
      </div>
    </div>
  </bon-section>
  <!-- ************************** -->
  <!-- * Section: Custom fields * -->
  <!-- ************************** -->
  <bon-section labelKey="clientbond.details.customFields" *ngIf="this.bond.subtype && bondSubtypeHasCustomFields()">
    <div class="bon-card-group">
      <div class="bon-card-inner">
        <bond-custom-fields #bondCustomFieldsComponent [originalSubtype]="originalSubtypeCustomTemplate" [bond]="bond"
                            [bondForm]="bondForm" [showErrors]="showErrors"
                            (damageClauseChange)="recalculateBondValidTo()"
                            (timberPaymentDaysChange)="recalculateBondValidTo()"></bond-custom-fields>
      </div>
    </div>
  </bon-section>
  <!-- ****************************-->
  <!-- * Section: Special clauses *-->
  <!-- ****************************-->
  <bon-section *ngIf="(this.availableSpecialClauses?.length > 0) || showSpecialClauses()"
               labelKey="clientbond.details.specialClauses.title" expandable="disabled">
    <div class="bon-card-group">
      <div class="bon-card-inner">
        <div class="bon-row" style="max-height: 130px; width: 40%; overflow-y: auto;">
          <b-table [items]="bond.specialClauses" [editable]="canIssue" buttonsCellWidth="59px;"
                   [autoUpdateValidators]="true" [archetypeEntries]="availableSpecialClauses" [selection]="false">
            <column property="clause" type="archetype" dictionary="BondSpecialClause"
                    labelKey="clientbond.details.specialClauses.clauseName"></column>
            <column type="archetypeCheckbox" cellWidth="5"></column>
          </b-table>
        </div>
      </div>
    </div>
  </bon-section>

  <div *ngIf="!isIndividualContract" class="bon-card-group" style="padding-top: 20px; padding-bottom: 20px;">

    <checkbox-row *ngIf="[ContractType.BOOSTER, ContractType.BANK_BOOSTER].includes(bond.contractType.id)"
                  class="bon-card-inner bon-row bon-label-180" labelKey="clientbond.details.sendToBeneficiaryByEmail"
                  [(ngModel)]="bond.beneficiaryRaw.sendToBeneficiaryByMail" style="width: 25%;"
                  [ngModelOptions]="{standalone: true}" [disabled]="!canIssue"></checkbox-row>
    <ng-container *ifBUnot="['DE','IT']">
      <checkbox-row class="bon-card-inner bon-row bon-label-230" labelKey="clientbond.details.sendToBeneficiaryByPost"
                    [(ngModel)]="bond.beneficiaryRaw.sendToBeneficiaryByPost" style="width: 25%;"
                    [ngModelOptions]="{standalone: true}" [disabled]="!canIssue || sendByPostDisabled"
                    hooverMsg="EUR 25"></checkbox-row>
      <checkbox-row class="bon-card-inner bon-row bon-label-180" labelKey="clientbond.details.sendToClientByPost"
                    [(ngModel)]="bond.beneficiaryRaw.sendToClientByPost" style="width: 25%;" hooverMsg="EUR 25"
                    [ngModelOptions]="{standalone: true}" [disabled]="!canIssue"></checkbox-row>
    </ng-container>
    <ng-container *ifBU="['DE','IT']">
      <checkbox-row class="bon-card-inner bon-row bon-label-230" labelKey="clientbond.details.sendToBeneficiaryByPost"
                    [(ngModel)]="bond.beneficiaryRaw.sendToBeneficiaryByPost" style="width: 25%;"
                    (changeState)="onChangeBeneficiaryPost($event)" hooverMsg="EUR 25"
                    [ngModelOptions]="{standalone: true}" [disabled]="!canIssue"></checkbox-row>
      <checkbox-row class="bon-card-inner bon-row bon-label-180" labelKey="clientbond.details.sendToClientByPost"
                    [(ngModel)]="bond.beneficiaryRaw.sendToClientByPost" style="width: 25%;"
                    (changeState)="onChangeClientPost($event)" hooverMsg="EUR 25"
                    [ngModelOptions]="{standalone: true}" [disabled]="!canIssue"></checkbox-row>
    </ng-container>
    <checkbox-row *ifBU="'IT'" class="bon-card-inner bon-row bon-label-180"
                  labelKey="clientbond.details.requestNotaryAuthentication" style="width: 25%;"
                  [(ngModel)]="bond.requestNotaryAuthentication"
                  [ngModelOptions]="{standalone: true}" [disabled]="!canIssue"></checkbox-row>

  </div>
  <div *ngIf="bond.beneficiaryRaw.sendToBeneficiaryByMail" style="font-size: 1.3rem; padding-left: 30px;"
       translate="clientbond.details.contactPerson"></div>
  <div *ngIf="bond.beneficiaryRaw.sendToBeneficiaryByMail" class="bon-card-group" style="padding-bottom: 20px;">
    <div class="bon-card-inner">
      <fieldset [disabled]="!canIssue" ngModelGroup="contactPerson">
        <table class="table">
          <thead class="table-header">
          <tr class="table-header">
            <th translate="common.title"></th>
            <th translate="common.firstName"></th>
            <th translate="common.lastName"></th>
            <th translate="common.email"></th>
          </tr>
          </thead>
          <tbody>
          <tr class="hover-table-row">
            <td>
              <dict-combo [(ngModel)]="bond.beneficiaryRaw.contactPerson.title" dictionary="Title" name="title"
                          [disabled]="!canIssue"></dict-combo>
            </td>
            <td><input class="bon-table-input required" type="text" required
                       [(ngModel)]="bond.beneficiaryRaw.contactPerson.firstName" name="firstName"
                       #firstNameModel="ngModel">
              <error-message [control]="firstNameModel.control"
                             [show]="showErrors"></error-message>
            </td>
            <td><input class="bon-table-input required" type="text" required
                       [(ngModel)]="bond.beneficiaryRaw.contactPerson.lastName" name="lastName"
                       #lastNameModel="ngModel">
              <error-message [control]="lastNameModel.control"
                             [show]="showErrors"></error-message>
            </td>
            <td><input class="bon-table-input required" type="text" required
                       [(ngModel)]="bond.beneficiaryRaw.contactPerson.email" name="email" #emailModel="ngModel"
                       emailFormat>
              <error-message [control]="emailModel.control"
                             [show]="showErrors"></error-message>
            </td>
          </tr>
          </tbody>
        </table>
      </fieldset>
    </div>
  </div>
  <clientbond-attachments-section *ngIf="isIndividualContract" [bondVersion]="bond" [bondVersionForm]="bondForm"
                                  [showErrors]="showErrors" [showDuringCreation]="true"
                                  collectionName="clientAttachmentDocs"
                                  labelKey="clientbond.details.clientAttachments"></clientbond-attachments-section>
  <clientbond-attachments-section *ngIf="isIndividualContract" [bondVersion]="bond" [bondVersionForm]="bondForm"
                                  [showErrors]="showErrors"
                                  collectionName="bondIssuedDocs"
                                  labelKey="clientbond.details.scans"></clientbond-attachments-section>
</form>
