import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ExternalProviderDetailsComponent} from './external-provider-details.component';
import {ExternalProviderSearchComponent} from './external-provider-search.component';
import {ExternalProviderGuiService} from './services/external-provider-gui.service';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {BusinessUnitModule} from '../business-unit/business-unit.module';
import {ExternalProviderDetailsGuard, ExternalProviderSearchGuard} from './_guards';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    BusinessUnitModule,
  ],
  declarations: [ExternalProviderSearchComponent, ExternalProviderDetailsComponent],
  exports: [],
  providers: [ExternalProviderGuiService, ExternalProviderSearchGuard, ExternalProviderDetailsGuard],
})
export class ExternalProviderModule {}
