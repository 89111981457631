import {Injectable} from '@angular/core';
import {AbstractGuard} from '../../../bonding_shared/components/abstract-guard';
import {LoggedUserService} from '../../../bonding_shared';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {LimitElementaryRight} from '../../../bonding_shared/model/dictionary-ids';

@Injectable()
export class LimitBusinessParamsGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router) {
    super(loggedUserService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const hasRights = this.checkRight(state.url, LimitElementaryRight.LIMIT_LNT_PND_MANAGEMENT);
    console.log('canActivate = ' + hasRights);
    return hasRights;
  }
}
