import {
  AbstractService,
  AppConfigService,
  BinaryDownloaderService,
  LoggedUserService,
} from '../../bonding_shared/services';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  ErrorReason,
  InvoiceDto,
  InvoicePortalSearchResultDto,
  ReportDefinitionSimpleDto,
} from '../../bonding_shared/model';
import {Observable} from 'rxjs';

@Injectable()
export class PortalInvoiceService extends AbstractService {
  protected url = this.urlPrefix + 'portal/invoice';
  protected urlReportDefinitions = this.url + '/reportDefinitions';

  constructor(
    http: HttpClient,
    apiService: AppConfigService,
    loggedUserService: LoggedUserService,
    private binaryDownloaderService: BinaryDownloaderService
  ) {
    super(http, apiService, loggedUserService);
  }

  getInvoice(id: number): Observable<InvoiceDto> {
    return this.get<InvoiceDto>(this.url + '/' + id);
  }

  downloadInvoiceDocument(invoice: InvoicePortalSearchResultDto, errorCallback: (err: ErrorReason[]) => void) {
    this.binaryDownloaderService.download(
      'GET',
      this.url + '/pdf/' + invoice.id,
      'application/json',
      'application/octet-stream',
      invoice.number.replace('/', '_') + '.pdf',
      null,
      errorCallback
    );
  }

  getReportDefinitions(invoiceId: number): Observable<ReportDefinitionSimpleDto[]> {
    return this.get<ReportDefinitionSimpleDto[]>(this.urlReportDefinitions + '/' + invoiceId);
  }
}
