/**
 * Service for common logic of invoice components.
 *
 */
import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {
  CompanyBaseDto,
  DictionaryBaseDto,
  FINoteCriteriaDto,
  FINoteSimpleDto,
  LocalDateRange,
} from '../../../bonding_shared/model/dtos';
import {LoggedUserService} from '../../../bonding_shared/services/logged-user.service';
import {InvoiceCategory} from '../../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';
import {FINoteService} from '../../../bonding_shared/services/fi-note.service';

@Injectable()
export class FINoteGuiService {
  dataProviders: SearchDataProvider<FINoteCriteriaDto, FINoteSimpleDto>[] = [];

  constructor(
    private service: FINoteService,
    private loggedUserService: LoggedUserService,
    private translateService: TranslateService
  ) {}

  public getDataProvider(category: InvoiceCategory): SearchDataProvider<FINoteCriteriaDto, FINoteSimpleDto> {
    let dataProvider = this.dataProviders[category];
    if (!dataProvider) {
      dataProvider = new SearchDataProvider<FINoteCriteriaDto, FINoteSimpleDto>(this.service);
      dataProvider.searchCriteria.criteria = <FINoteCriteriaDto>{};
      dataProvider.searchCriteria.criteria.category = <DictionaryBaseDto>{id: category};
      dataProvider.searchCriteria.criteria.client = <CompanyBaseDto>{};
      dataProvider.searchCriteria.criteria.issueDateRange = <LocalDateRange>{};
      dataProvider.searchCriteria.criteria.creationDateRange = <LocalDateRange>{};
      dataProvider.searchCriteria.criteria.dateFromRange = <LocalDateRange>{};
      dataProvider.searchCriteria.criteria.dateToRange = <LocalDateRange>{};

      dataProvider.textSearch = false;
      this.dataProviders[category] = dataProvider;
    }

    return dataProvider;
  }
}
