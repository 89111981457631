import {Observable} from 'rxjs';
import {AbstractService} from '../bonding_shared/services/abstract.service';
import {GetTokenParamDto, GetTokenResultDto, RefreshTokenRequestDto} from '../bonding_shared/model/dtos';
import {ChangePasswordDto} from '../bonding_shared/model';
import {HttpHeaders} from '@angular/common/http';

export abstract class AbstractLoginService extends AbstractService {
  refreshAccessToken(login: string, refreshToken: string): Observable<GetTokenResultDto> {
    const req: RefreshTokenRequestDto = {login, refreshToken};
    return this.http.post<GetTokenResultDto>(this.url + '/refreshAccessToken', req, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Fingerprint ' + this.getFingerprint(),
      }),
    });
  }

  doLogin(dto: GetTokenParamDto): Observable<GetTokenResultDto> {
    return this.post1<GetTokenParamDto, GetTokenResultDto>(dto, this.url + '/token');
  }

  authenticate(login: string, password: string): Observable<string> {
    return this.post1<GetTokenParamDto, string>(<GetTokenParamDto>{login, password}, this.url + '/authenticate');
  }
}
