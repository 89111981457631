import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {RepositoryDocumentsComponent} from './repository-documents.component';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {ContractSharedModule} from '../bonding-contract/contract/components/shared/contract-shared.module';
import {DocumentModule} from '../document/document.module';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {BusinessObjectInfoModule} from '../business-object-info/business-object-info.module';
import {RepoDocumentListComponent} from './repo-document-list.component';
import {RepositoryDocumentsGuard} from './_guard';
import {RepositoryHealthPageComponent} from './repository-health-page.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    DocumentModule,
    ContractSharedModule,
    BusinessObjectInfoModule,
  ],
  declarations: [RepositoryDocumentsComponent, RepoDocumentListComponent, RepositoryHealthPageComponent],
  providers: [RepositoryDocumentsGuard],
})
export class RepositoryDocumentsModule {}
