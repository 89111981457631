import {Component, Input} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService, RouterService} from '../../../../../bonding_shared/services';
import {DictionaryBaseDto, PolicyContractVersionDto} from '../../../../../bonding_shared/model';
import {StringUtils} from '../../../../../bonding_shared/utils';

@Component({
  selector: 'ecg-policy-clause-section',
  templateUrl: './ecg-policy-clause-section.component.pug',
})
export class EcgPolicyClauseSectionComponent extends Section {
  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public router: RouterService,
    protected appService: AppConfigService,
    public routerService: RouterService
  ) {
    super(policyContractViewService, appService);
  }

  @Input()
  set policy(policy: PolicyContractVersionDto) {
    this._policy = policy;
  }

  get policy() {
    return this._policy;
  }
}
