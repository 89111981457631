import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {TrainingSearchComponent} from './training-search.component';
import {BusinessObjectInfoModule} from '../business-object-info/business-object-info.module';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {HtmlEditorModule} from '../../bonding_shared/components/html-editor/html-editor.module';
import {TrainingService} from '../../bonding_shared/services/training-service.service';
import {TrainingDetailsComponent} from './training-details.componet';
import {UserHolidaySearchComponent} from './user-holiday-search.component';
import {UserHolidayDetailsComponent} from './user-holiday-detalis.component';
import {UserHolidayService} from '../../bonding_shared/services/user-holiday-service';
import {UserHolidaySettingsSearchComponent} from './user-holiday-settings-search.component';
import {UserHolidaySettingsDetailsComponent} from './user-holiday-settings-details.component';
import {UserHolidaySettingsService} from '../../bonding_shared/services/user-holiday-settings-service';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    HtmlEditorModule,
    BusinessObjectInfoModule,
  ],
  declarations: [
    TrainingSearchComponent,
    TrainingDetailsComponent,
    UserHolidaySearchComponent,
    UserHolidayDetailsComponent,
    UserHolidaySettingsSearchComponent,
    UserHolidaySettingsDetailsComponent,
  ],
  exports: [],
  providers: [TrainingService, UserHolidayService, UserHolidaySettingsService],
})
export class HRModule {}
