import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';

const CHECKBOX_ENTITY_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxEntityComponent),
  multi: true,
};

@Component({
  selector: 'checkbox-entity',
  template: `
    <ng-container *ngIf="control">
      <input
        *ngIf="!presentationMode"
        type="checkbox"
        [ngModel]="value"
        (change)="onChangeItem($event)"
        (blur)="onTouchedListeners()"
        [formControl]="control"
        [disabled]="disabled || readonly"
        class="bon-checkbox"
        style="width: auto; min-width: 0; margin-top: 0px"
        [class.no-top-margin]="resetTopMargin"
      />
      <input
        *ngIf="presentationMode"
        type="checkbox"
        [ngModel]="value"
        disabled
        class="bon-checkbox"
        style="width: auto; min-width: 0; margin-top: 0px"
        [class.no-top-margin]="resetTopMargin"
      />
      <error-message [control]="control" [show]="showErrors"></error-message>
    </ng-container>
    <ng-container *ngIf="!control">
      <input
        *ngIf="!presentationMode"
        #model="ngModel"
        type="checkbox"
        [ngModel]="value"
        (change)="onChangeItem($event)"
        (blur)="onTouchedListeners()"
        [disabled]="disabled || readonly"
        class="bon-checkbox"
        style="width: auto; min-width: 0;"
        [class.no-top-margin]="resetTopMargin"
      />
      <input
        *ngIf="presentationMode"
        type="checkbox"
        [ngModel]="value"
        disabled
        class="bon-checkbox"
        style="width: auto; min-width: 0; margin-top: 0px"
        [class.no-top-margin]="resetTopMargin"
      />
      <error-message [control]="model" [show]="showErrors"></error-message>
    </ng-container>
  `,
  providers: [CHECKBOX_ENTITY_CONTROL_VALUE_ACCESSOR],
})
export class CheckboxEntityComponent implements ControlValueAccessor {
  @Input() control: AbstractControl;
  @Input() disabled: any;
  @Input() readonly: any;
  @Input() presentationMode = false;
  @Input() showErrors: boolean;
  @Input() nullable: boolean; // set null instead of false
  @Input() resetTopMargin = false;

  @Output() changeState = new EventEmitter<any>();

  private onChangeListeners: Function;
  public onTouchedListeners: Function;
  public value: boolean;

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onChangeItem(event: any) {
    console.log('checkbox checked: ', event.target.checked);
    this.value = event.target.checked ? true : this.nullable ? null : false;
    this.onChangeListeners(this.value);
    this.changeState.emit(this.value);
  }
}
