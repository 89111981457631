<div *ngIf="isViewChanged()"></div>
<div class="bon-card">
  <div class="bon-fixed-header-no-block">
    <div class="bon-title">{{"businessReport.businessReports" | translate}}</div>
  </div>
  <div style='margin-top: 48px;'>
    <div class="card-block">
      <button [btnIcon]="'fa-edit'" (click)="generate()" type="button" class="bon-btn-info"
              [disabled]="!parameterList._parameterDefinitions || businessReportService.binaryDownloader.pending"> {{"businessReport.generate" | translate}}
      </button>
    </div>
    <backend-error [errors]="serverErrors"></backend-error>
    <form #form="ngForm">
      <div class="bon-card-main-group">
        <div class="bon-card-inner" style="width: 40%">
          <div class="bon-header">{{"businessReport.businessReport" | translate}}</div>
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <dict-tree *ngIf="reportDefinitions" dictionary="ReportCategory" [leafObjects]="reportDefinitions"
                         [leafObjectToTreeNodeCallback]="definitionToTreeNode"
                         (selectItem)="selectDefinition($event)"></dict-tree>
            </div>
          </div>
        </div>
        <div class="bon-card-inner" style="width: 60%">
          <div class="bon-header">{{"businessReport.criteria" | translate}}</div>
          <parameter-list [(ngModel)]="parameterValues" [parameterDefinitions]="parameterDefinitions" name="parameterList" [showErrors]="showErrors"></parameter-list>
        </div>
      </div>
    </form>
  </div>
</div>
