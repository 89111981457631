import {Component, Input, Output, EventEmitter} from '@angular/core';
import {RelatedObjectAction} from './model/related-object-action';

@Component({
  selector: 'related-object-info',
  templateUrl: './related-object-info.component.pug',
})
export class RelatedObjectInfoComponent {
  @Input() title: string;
  @Input() invalid = false;
  @Input() listType: string;
  @Input() searchable = false;
  @Input() readOnly = false;

  @Output() onAction = new EventEmitter<RelatedObjectAction>();

  searchParam: string;

  cardTitle() {
    if (!this.title) {
      return this.listType;
    }
    return this.title;
  }

  activateSelectionList() {
    this.onAction.emit(new RelatedObjectAction(this.listType, RelatedObjectAction.ACTIVATE_LIST));
  }

  onSearch() {
    this.onAction.emit(new RelatedObjectAction(this.listType, RelatedObjectAction.SEARCH, this.searchParam));
  }

  toggleSelectionList() {
    this.onAction.emit(new RelatedObjectAction(this.listType, RelatedObjectAction.TOGGLE_LIST));
  }

  reset() {
    delete this.searchParam;
  }
}
