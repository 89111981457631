import {Component, Input} from '@angular/core';
import {CompanyRatingVersionDto, DictionaryBaseDto, RatingCalcPartDto} from '../../bonding_shared/model/dtos';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'company-rating-calculation',
  templateUrl: './company-rating-calculation.component.pug',
})
export class CompanyRatingCalculationComponent {
  weighted: Grouped[];
  other: {type: DictionaryBaseDto; label: string}[];
  type: DictionaryBaseDto;

  constructor(private translateService: TranslateService) {}

  @Input()
  set ratingVersion(value: CompanyRatingVersionDto) {
    this.type = value.calculationType;

    const parts = value.calculation;
    this.weighted = _.uniqBy(
      parts.map((p) => p.type).filter((t) => t.dictName === 'RatingCalcPartWeighted'),
      'id'
    ).map((type) => {
      const parent = <Grouped>parts.find((p) => !p.subtype && p.type.id === type.id);
      parent.children = parts.filter((p) => p.subtype && p.type.id === type.id);
      return parent;
    });
    this.other = [];
    parts
      .filter((p) => !this.weighted.find((w) => w.id === p.id || !!w.children.find((c) => c.id === p.id)))
      .forEach((other) => {
        const same = this.other.find((o) => other.type.id === o.type.id);
        if (same) {
          same.label += ', ' + this.getLabel(other);
        } else {
          this.other.push({type: other.type, label: this.getLabel(other)});
        }
      });
  }

  private getLabel(p: RatingCalcPartDto): string {
    return p.points ? '' + p.points : p.subtype && p.subtype.name;
  }

  get headerValue() {
    return this.type
      ? `${this.translateService.instant('companyRating.calculation.model')} (${this.type.name})`
      : this.translateService.instant('companyRating.calculation.title');
  }
}
type Grouped = RatingCalcPartDto & {children: RatingCalcPartDto[]};
