<search-view objectName="Document" customTitle="searchView.documentSearch" [atable]="documentList.documentTable"
             [dataProvider]="documentList.searchDataProvider" [searchModeSwitchable]="false" [newButton]="false">
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner" style="width: 30%">
      <input-row labelKey="document.search.templateName" [(ngModel)]="criteria.template.name" name="templateName"></input-row>
      <dict-row labelKey="document.search.documentType" [(ngModel)]="criteria.type" dictionary="GeneratedDocumentType" name="type" nullLabel=" "></dict-row>
      <dict-row labelKey="document.search.relatedObjectType" [(ngModel)]="criteria.businessObject.relatedTo" dictionary="BusinessObjectType" name="botype" nullLabel=" "></dict-row>
      <input-row labelKey="document.search.relatedObjectId" [(ngModel)]="criteria.businessObject.relatedToId" name="relatedToId" type="number"></input-row>
      <input-row labelKey="document.search.company" [(ngModel)]="criteria.companyName" name="companyName"></input-row>
    </div>
    <div class="bon-card-inner bon-label-190" style="width: 50%">
      <date-range-row labelKey="document.search.creationDate" [value]="criteria.creationDateRange"></date-range-row>
      <date-range-row labelKey="document.search.lastSentDate" [value]="criteria.lastSentDateRange"></date-range-row>
    </div>
    <div class="bon-card-inner">
      <form-row labelKey="document.search.businessUnit">
        <business-unit-id-selector class="bon-input-size" nullLabelKey="common.all" [(ngModel)]="criteria.businessUnit" name="businessUnit"></business-unit-id-selector>
      </form-row>
    </div>
  </div>
  <div class="search-results">
    <document-list [pagination]="true"></document-list>
  </div>
  <div class="additional-buttons">
    <button [btnIcon]="'fa-download'" (click)="documentService.downloadZip(getIdList(), zipOutputFileName)" type="button" class="bon-btn-info" translate>document.search.downloadAsZip</button>
  </div>
</search-view>
