import {Component, Input} from '@angular/core';
import {
  BondingContractInquiryVersionCoreDto,
  BondingContractInquiryVersionDto,
  ProductSingleLimitDto,
  LoggedUserService
} from '../../../../bonding_shared';
import {ControlContainer, NgForm} from '@angular/forms';
import {ContractType, DictionaryProfile} from '../../../../bonding_shared/model/dictionary-ids';
import {ListEmitters} from '../../../../bonding_shared/components/details-view/list-emitters';

@Component({
  selector: 'bonding-contract-inquiry-product',
  templateUrl: './bonding-contract-inquiry-product.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondingContractInquiryProductComponent {
  @Input() showErrors = false;
  @Input() multiProduct = false;
  listEmitters: ListEmitters = new ListEmitters();
  _product: BondingContractInquiryVersionDto | BondingContractInquiryVersionCoreDto;
  bondCurrencyDictProfile = DictionaryProfile.KUKE_BOND_CURRENCY;
  ContractType = ContractType;

  @Input() set product(value: BondingContractInquiryVersionDto | BondingContractInquiryVersionCoreDto) {
    this._product = value;
  }

  get product() {
    return this._product;
  }

  constructor(private loggedUserService: LoggedUserService) {}

  get productTypeId() {
    return this.multiProduct
      ? this.product.multiProductType.id
      : (<BondingContractInquiryVersionDto>this.product).inquiry.productType.id;
  }

  showValidTo() {
    return (
      this.productTypeId === ContractType.GSP_INDIVIDUAL_CONTRACT ||
      this.productTypeId === ContractType.GSP_CREDIT_PAYMENT ||
      this.productTypeId === ContractType.GSP_GENERAL_PAYMENT ||
      this.productTypeId === ContractType.GSP_INDIVIDUAL_PAYMENT
    );
  }

  get portal() {
    return this.loggedUserService.portal;
  }

  get singleLimit(): ProductSingleLimitDto {
    // only BondingContractInquiryVersionDto has inquiry
    if (this.product['inquiry']) {
      return this.product['inquiry'].singleLimit;
    }
    return undefined;
  }
}
