import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {
  DictionaryBaseDto,
  DictionaryBaseService,
  LoggedUserService,
  QuotationBondDto,
  QuotationDto,
} from '../../../../../bonding_shared';
import {BondType} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'quotation-contract-data',
  templateUrl: './quotation-contract-data.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class QuotationContractDataComponent implements OnInit {
  @Input() quotation: QuotationDto;
  @Input() contractMaxBondLiabilitySum;
  @Input() showFormErrors = false;
  @Input() disabled = false;

  performanceBond: DictionaryBaseDto;
  self = this;

  constructor(protected loggedUserService: LoggedUserService, protected dictionaryBaseService: DictionaryBaseService) {
    this.dictionaryBaseService
      .getDictionaryEntry('BondType', BondType.PERFORMANCE_BOND_IT)
      .subscribe((d) => (this.performanceBond = d));
  }

  ngOnInit(): void {}

  onValidFromChanged(validFrom: Date) {
    if (!validFrom) {
      this.quotation.contractValidTo = null;
      return;
    }
    this.quotation.contractValidTo = new Date();
    this.quotation.contractValidTo.setFullYear(validFrom.getFullYear() + 1, 11, 31);
  }

  getBusinessUnitId(): number {
    if (this.quotation && this.quotation.businessUnit) {
      return this.quotation.businessUnit.id;
    }
    return this.loggedUserService.getLoggedUserData().businessUnit.id;
  }

  getBrokerRoleId(): number {
    const brokers: DictionaryBaseDto[] = this.loggedUserService.getLoggedUserData().roles;
    if (brokers && brokers.length > 0) {
      return brokers[0].id;
    }
    return undefined;
  }

  onBondListChange() {
    if (!this.quotation || !this.quotation.contractBondTypes) {
      return;
    }
    let maxBondLiabilitySum = 0;
    for (const bondType of this.quotation.contractBondTypes) {
      if (bondType.maxBondLiability) {
        maxBondLiabilitySum += bondType.maxBondLiability;
      }
    }
    this.contractMaxBondLiabilitySum = maxBondLiabilitySum;
  }

  onCheckBidFunction(
    contractBondType: QuotationBondDto,
    checkedTypes: QuotationBondDto[]
  ): {item: QuotationBondDto; value: boolean}[] {
    let performance: QuotationBondDto;
    this.quotation.contractBondTypes.forEach((t) => {
      if (t.bondType.id === BondType.PERFORMANCE_BOND_IT) {
        performance = t;
      }
    });
    if (contractBondType && contractBondType.bondType.id === BondType.BID_BOND_IT) {
      if (!performance) {
        performance = <QuotationBondDto>{};
      }
      performance.bondType = this.performanceBond;
      return [
        {item: contractBondType, value: true},
        {item: performance, value: true},
      ];
    } else {
      return [{item: contractBondType, value: true}];
    }
  }

  onUncheckPerformanceFunction(
    contractBondType: QuotationBondDto,
    checkedTypes: QuotationBondDto[]
  ): {item: QuotationBondDto; value: boolean}[] {
    if (contractBondType && contractBondType.bondType.id === BondType.PERFORMANCE_BOND_IT) {
      const bid = <QuotationBondDto>{
        bondType: <DictionaryBaseDto>{
          id: BondType.BID_BOND_IT,
          name: 'Bid Bond',
          dictName: 'BondType',
        },
      };
      if (checkedTypes && checkedTypes.filter((t) => t.bondType.id === BondType.BID_BOND_IT).length > 0) {
        return [{item: contractBondType, value: undefined}];
      }
    }
    return [{item: contractBondType, value: false}];
  }

  onItalyBidPerformanceBondTypeChange() {
    let bid: QuotationBondDto;
    let performance: QuotationBondDto;
    this.quotation.contractBondTypes.forEach((t) => {
      if (t.bondType.id === BondType.BID_BOND_IT) {
        bid = t;
      }
      if (t.bondType.id === BondType.PERFORMANCE_BOND_IT) {
        performance = t;
      }
    });

    if (bid && performance) {
      if (!performance.maxBondLiability || performance.maxBondLiability < bid.maxBondLiability * 1.5) {
        performance.maxBondLiability = bid.maxBondLiability * 1.5;
      }
      if (!performance.maxSingleBond || performance.maxSingleBond < bid.maxSingleBond * 1.5) {
        performance.maxSingleBond = bid.maxSingleBond * 1.5;
      }
    }
  }

  isBankBooster(): boolean {
    return this.quotation && this.quotation.loans && this.quotation.loans.length > 0;
  }
}
