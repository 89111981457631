import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {BusinessUnitSectionSelectorComponent} from './business-unit-section-selector.component';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, CompanySharedModule],
  declarations: [BusinessUnitSectionSelectorComponent],
  exports: [BusinessUnitSectionSelectorComponent],
})
export class BusinessUnitModule {}
