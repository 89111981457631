import {ChartDataset} from 'chart.js';
import {ChartData} from './chart-data';
import {ChartUtils} from '../chart-utils';

export class SalesBondChartData extends ChartData {
  rightAxis = false;

  newBonds: number[];
  activeContracts: number[];
  newContracts: number[];

  colors = [
    {backgroundColor: ChartUtils.COLORS[0]},
    {backgroundColor: ChartUtils.COLORS[2]},
    {backgroundColor: ChartUtils.COLORS[1]},
  ];

  buildChartData(): ChartDataset[] {
    return [
      {data: this.newBonds, label: 'New bonds', yAxisID: 'yAxisLeft'},
      {data: this.activeContracts, label: 'Active contract', yAxisID: 'yAxisLeft'},
      {data: this.newContracts, label: 'New contracts', yAxisID: 'yAxisLeft'},
    ];
  }

  clear() {
    this.newBonds = [];
    this.activeContracts = [];
    this.newContracts = [];
    this.dates = [];
  }

  mapData(results: Array<any>) {
    if (!results) {
      return;
    }
    results.forEach((result) => {
      this.dates.push(result.month + '-' + result.year);
      this.newBonds.push(<any>result.newBondsNumber);
      this.activeContracts.push(<any>result.activeContracts);
      this.newContracts.push(<any>result.newContracts);
    });
  }
}
