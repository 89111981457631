import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {CustomValidators} from '../../validators';

@Directive({
  selector: '[allowedValues]',
  providers: [{provide: NG_VALIDATORS, useExisting: AllowedValuesDirective, multi: true}],
})
export class AllowedValuesDirective implements Validator {
  @Input('allowedValues') allowedValues: any[];

  validate(control: AbstractControl): ValidationErrors | null {
    const f = CustomValidators.allowedValues(this.allowedValues);
    return f(control);
  }
}
