import {Component, Input} from '@angular/core';
import {
  BusinessPropertiesService,
  DashboardService,
  DictionaryBaseDto,
  SettingsService,
  StringUtils,
} from '../../../../bonding_shared';
import {ChartDataset, ChartOptions} from 'chart.js';
import {ApplicationModule} from '../../../../bonding_shared/model/dictionary-ids';
import {map} from 'rxjs/operators';
import {ChartComponent} from '../chart-component';
import {ChartRestLoader} from '../chart-rest-loader';
import {BondChartData} from './bond-chart-data';
import {ChartData} from './chart-data';
import {PolicyChartData} from './policy-chart-data';
import {ClaimChartData} from './claim-chart-data';
import {SalesBondChartData} from './sales-bond-chart-data';
import {SalesCreditInsuranceChartData} from './sales-credit-insurance-chart-data';
import {LimitChartData} from './limit-chart-data';
import {SalesLeadChartData} from './sales-lead-chart-data';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'time-chart',
  templateUrl: 'timechart.component.pug',
})
export class TimeChartComponent extends ChartComponent implements ChartRestLoader {
  @Input() chartPath: string;
  @Input() extraParams: {[key: string]: string | number | null};

  @Input() set businessUnit(businessUnit: DictionaryBaseDto) {
    this._businessUnit = businessUnit;
    this.setActiveOrDefaultModule();
    this.initialized = false;
    this.initChartData();
    this.initChartOptions();
    this.loadRestData();
  }
  _businessUnit: DictionaryBaseDto;
  chartData: ChartData;
  public chartOptions: ChartOptions;
  public data: ChartDataset[];
  public labels: string[];
  initialized = false;

  constructor(
    private service: DashboardService,
    public businessProps: BusinessPropertiesService,
    public settingsService: SettingsService,
    public translateService: TranslateService
  ) {
    super(businessProps, settingsService);
  }

  initChartOptions() {
    const currency = this.currency;
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const label = context.dataset.label || '';
              const value = <number>context.dataset.data[context.dataIndex];
              const valueF =
                context.dataset.yAxisID === 'yAxisRight' ? StringUtils.formatNumber(value) + ' ' + currency : value;
              return label + ': ' + valueF;
            },
          },
        },
      },
      scales: {
        yAxisLeft: {
          position: 'left',
          type: 'linear',
          beginAtZero: true,
          title: {
            display: true,
            text: this.translateService.instant('dashboard.count'),
          },
        },
        yAxisRight: {
          position: 'right',
          type: 'linear',
          display: this.chartData.rightAxis,
          beginAtZero: true,
          ticks: {
            callback: function (label, index, labels) {
              return StringUtils.formatNumber(<number>label);
            },
          },
          title: {
            display: true,
            text: this.currency,
          },
        },
      },
    };
  }

  loadRestData() {
    this.chartData.clear();
    this.service
      .getTimeChartData(this._businessUnit, this.applicationModuleId, this.chartPath, this.extraParams)
      .pipe(map((results: Array<any>) => this.chartData.mapData(results)))
      .subscribe({
        next: () => console.log('Empty subscribe'),
        error: (error) => console.log('Error occurred while getting Time Chart data', error),
        complete: () => this.loadData(),
      });
  }

  loadData() {
    this.labels = this.chartData.dates;
    this.data = this.chartData.buildChartData();
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].backgroundColor = this.chartData.colors[i].backgroundColor;
    }
    this.initialized = true;
  }

  initChartData() {
    switch (this.applicationModuleId) {
      case ApplicationModule.BONDING:
        this.chartData = new BondChartData();
        break;
      case ApplicationModule.POLICY:
        this.chartData = new PolicyChartData(this.translateService);
        break;
      case ApplicationModule.CLAIM:
        this.chartData = new ClaimChartData(this.translateService);
        break;
      case ApplicationModule.LIMIT:
        this.chartData = new LimitChartData(this.translateService);
        break;
      case ApplicationModule.SALES:
        if (this.chartPath === 'bonds') {
          this.chartData = new SalesBondChartData();
        } else if (this.chartPath === 'leads') {
          this.chartData = new SalesLeadChartData();
        } else {
          this.chartData = new SalesCreditInsuranceChartData();
        }
        break;
      default:
        this.chartData = new BondChartData();
        break;
    }
  }
}
