import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, forwardRef, Input} from '@angular/core';
import {FileUtils} from '../../utils/file-utils';
import {DocumentContentDto} from '../../model';

const REPOSITORY_FILE_SELECT_ROW_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RepositoryFileSelectRowComponent),
  multi: true,
};

@Component({
  selector: 'repository-file-select-row',
  template: `
    <file-select-row
      [ngModel]="value"
      (fileSelected)="mapToRepositoryDto($event)"
      [disabled]="disabled"
      [showErrors]="showErrors"
    ></file-select-row>
  `,
  providers: [REPOSITORY_FILE_SELECT_ROW_CONTROL_VALUE_ACCESSOR],
})
export class RepositoryFileSelectRowComponent implements ControlValueAccessor {
  @Input() disabled: any;
  @Input() showErrors = false;

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  public value: DocumentContentDto;

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  mapToRepositoryDto(file: File) {
    if (file) {
      FileUtils.readDocumentFromFile(file, (document) => {
        this.value = document;
        if (this.onChangeListeners) {
          this.onChangeListeners(document);
        }
      });
    }
  }
}
