import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, ParamMap, Router, RouterStateSnapshot} from '@angular/router';
import {AbstractGuard} from '../../bonding_shared/components/abstract-guard';
import {LoggedUserService} from '../../bonding_shared/services';
import {ElementaryRight} from '../../bonding_shared/model/dictionary-ids';

@Injectable()
export class CompanyRatingGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router) {
    super(loggedUserService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkRight(state.url, ElementaryRight.ACCESS_MODULE_RATING);
  }
}
