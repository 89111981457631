import {Component, OnInit} from '@angular/core';
import {
  SearchCriteria,
  TreatyBaseDto,
  TreatyVersionCriteriaDto,
  TreatyVersionSimpleDto,
} from '../../../../../bonding_shared/model';
import {Section} from '../section';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {
  AppConfigService,
  PropertyService,
  RouterService,
  TreatyVersionService,
} from '../../../../../bonding_shared/services';
import {ControlContainer, NgForm, UntypedFormControl, Validators} from '@angular/forms';
import {
  AppProperty,
  PolicyContractTypes,
  ReinsuranceMode,
  ReinsuranceType,
} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'treaties-section',
  templateUrl: './treaties-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class TreatiesSectionComponent extends Section implements OnInit {
  treaties: TreatyBaseDto[] = [];
  protected appProperties: {[index: string]: any};
  readonly AppProperty = AppProperty;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected propertyService: PropertyService,
    public apiService: AppConfigService,
    protected treatyService: TreatyVersionService,
    public routerService: RouterService
  ) {
    super(policyContractViewService, apiService);
  }

  get treaty(): TreatyBaseDto {
    return this.policyForm.get('treaty').value;
  }

  allowFacultativeTreaty(): boolean {
    return (
      this.policyForm.get('reinsuranceMode').value &&
      (this.policyForm.get('reinsuranceMode').value.id === ReinsuranceMode.FACULTATIVE ||
        this.policyForm.get('reinsuranceMode').value.id === ReinsuranceMode.MIXED)
    );
  }

  ngOnInit(): void {
    if (!this.policyForm.contains('reinsuranceMode')) {
      this.policyForm.addControl(
        'reinsuranceMode',
        new UntypedFormControl(this.policy.reinsuranceMode, Validators.required)
      );
    }

    const treatyCriteria: SearchCriteria<TreatyVersionCriteriaDto> = <SearchCriteria<TreatyVersionCriteriaDto>>{};
    treatyCriteria.criteria = <TreatyVersionCriteriaDto>{};
    treatyCriteria.criteria.lastActivated = true;
    treatyCriteria.criteria.treaty = <TreatyBaseDto>{};
    treatyCriteria.criteria.treaty.reinsuranceTypes = [ReinsuranceType.FAC];
    this.treatyService.searchByCriteria(treatyCriteria).subscribe(
      (treatiesResult) => {
        this.treaties = treatiesResult.result.map((tv) => (<TreatyVersionSimpleDto>tv).treaty);
      },
      (error) => console.log('treaty load error: ', error)
    );

    this.loadAppProperties();
  }

  reinsuranceModeChanged(): void {
    if (this.policyForm.get('treaty')) {
      this.policyForm.get('treaty').setValue(null);
    }
  }

  private loadAppProperties() {
    this.appProperties = this.propertyService.properties;
  }

  get hiddenReinsuranceModeIds() {
    const ids = new Set<number>();
    if (PolicyContractTypes.MEHIB_MLT.includes(this.policy.contractType.id)) {
      ids.add(ReinsuranceMode.STANDARD);
      ids.add(ReinsuranceMode.MIXED);
    }
    return ids;
  }
}
