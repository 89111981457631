import * as moment from 'moment';
import {MomentInput} from 'moment';
import {DatePipe} from '@angular/common';

export class DateUtils {
  private static dateFormat = 'yyyy-MM-dd';

  // ascending order
  static sorter(p?: string): (a, b) => number {
    return (a, b) =>
      moment(p ? a[p] : a).isAfter(p ? b[p] : b) ? 1 : moment(p ? a[p] : a).isBefore(p ? b[p] : b) ? -1 : 0;
  }

  static daysBetween(date1: MomentInput, date2: MomentInput): number {
    return moment(date2).startOf('d').diff(moment(date1).startOf('d'), 'd');
  }

  static daysAfterToday(date: Date): number {
    return this.daysBetween(DateUtils.today(), date);
  }

  static daysBeforeToday(date: Date): number {
    return -this.daysAfterToday(date);
  }

  static inPastNoTime(date: Date): boolean {
    return this.daysBeforeToday(date) > 0;
  }

  static inFutureNoTime(date: Date): boolean {
    return this.daysAfterToday(date) > 0;
  }

  static isToday(date: Date): boolean {
    return moment(date).isSame(DateUtils.today(), 'day');
  }

  static maxNotNull(d1: Date, d2: Date): Date {
    if (!d1) {
      return d2;
    }
    if (!d2) {
      return d1;
    }
    return d1 > d2 ? d1 : d2;
  }

  static minNotNull(d1: Date, d2: Date): Date {
    if (!d1) {
      return d2;
    }
    if (!d2) {
      return d1;
    }
    return d1 > d2 ? d2 : d1;
  }

  static addMonth(start: Date, amount: number): Date {
    return moment.utc(start).add(amount, 'month').toDate(); // without .utc '2023-01-01 01:00:00 + 4 months = '2023-04-30 23:00:00'
  }

  static addDay(start: Date, amount: number): Date {
    return moment.utc(start).add(amount, 'day').toDate();
  }

  static compare(d1: Date, d2: Date): number {
    if (!d1) {
      return -1;
    }

    if (!d2) {
      return 1;
    }

    if (d1 < d2) {
      return -1;
    } else if (d1 > d2) {
      return 1;
    }

    return 0;
  }

  static today(): Date {
    return moment().utc().startOf('day').toDate();
  }

  static toUtc(date: Date, keepLocalTime?: boolean) {
    return moment(date).utc(keepLocalTime).toDate();
  }

  static isDateValid(date: Date) {
    return date && date instanceof Date && !isNaN(date.getTime());
  }

  static format(date: Date): string {
    if (!this.isDateValid(date)) {
      return '';
    }
    return new DatePipe('en').transform(date, this.dateFormat);
  }

  static toDate(v: string): Date {
    if (v.length === 10) {
      // just date, no time - using utc to avoid timezone hour change
      return moment.utc(v).toDate();
    }
    return moment(v).toDate();
  }

  static getCurrentTimestamp(): string {
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    const [{value: month}, , {value: day}, , {value: year}, , {value: hour}, , {value: minute}, , {value: second}] =
      dateFormatter.formatToParts(currentDate);

    return `${year}${month}${day}${hour}${minute}${second}`;
  }
}
