import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClauseDto, CustomFieldValueDto, DictionaryDto} from '../../bonding_shared/model/index';
import {CustomFieldValueType, BusinessObjectType} from '../../bonding_shared/model/dictionary-ids';
import {DictionaryService} from '../../bonding_shared/services/index';
import {TranslateService} from '@ngx-translate/core';
import {ControlContainer, UntypedFormGroup, NgForm} from '@angular/forms';
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';

@Component({
  selector: 'custom-form',
  templateUrl: './custom-form.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class CustomFormComponent implements OnInit {
  static idSeq = 0;
  form: UntypedFormGroup;
  @Input() showErrors = false;
  @Input() sectionHeader: string;
  @Input() formColumnsCount: 1 | 2 | 3 = 2;
  @Input() fieldsObs: Observable<DictionaryDto[]>;
  @Input() tabStyle = false;
  @Input() uniqueClauseType = true;
  @Input() showPreview = false;
  @Input() disabled = false;
  @Input() showTemplate = false;
  @Input() showForm = true;
  @Input() showPreviewHeader = true;

  @Input() removeClauseIcon = false;
  @Output() removeClause = new EventEmitter<{clause: ClauseDto; internalId: number}>();
  @Input() typeIdAsClauseId = true; // should be false if multiple clauses of one type are allowed
  @Input() businessObjectType: BusinessObjectType;

  allFields: DictionaryDto[];
  simpleFields: DictionaryDto[];
  lists: {field: DictionaryDto; subfields: DictionaryDto[]}[] = [];
  clauseInternalId: string;
  _clause: ClauseDto;

  static getClauseGroupName(clauseId: string | number): string {
    return 'clauseFormGroup-' + clauseId;
  }

  @Input() set clause(c: ClauseDto) {
    this._clause = c;
    this.clauseInternalId = c.type.code + '_' + CustomFormComponent.idSeq++;
  }

  get clause() {
    return this._clause;
  }

  constructor(
    protected translateService: TranslateService,
    private dictionaryService: DictionaryService,
    private ngForm: NgForm
  ) {}

  ngOnInit() {
    this.form = this.ngForm.form;
    this.lists = [];
    observableForkJoin(this.dictionaryService.customFields(this.clause.type.id, false), this.fieldsObs).subscribe(
      (res) => {
        const directChildFields = <DictionaryDto[]>res[0];
        this.allFields = <DictionaryDto[]>res[1];
        // direct children are taken from allFields as they can have properties recalculated
        const directChildFieldsCustomized = this.allFields.filter(
          (f) => directChildFields.filter((d) => d.id === f.id).length > 0
        );
        this.simpleFields = directChildFieldsCustomized.filter((f) => f.parentId !== CustomFieldValueType.LIST);
        directChildFieldsCustomized
          .filter((f) => f.parentId === CustomFieldValueType.LIST)
          .forEach((fd) => this.initList(fd));
      }
    );
  }

  initList(listField: DictionaryDto) {
    this.lists.push({field: listField, subfields: this.allFields.filter((f) => this.isChildField(listField, f))});
  }

  isChildField(parent: DictionaryDto, field: DictionaryDto): boolean {
    return parent.relatedDictionaries['CustomField'].filter((child) => child.id === field.id).length > 0;
  }

  initNewListElement(d: DictionaryDto) {
    this.clause.values.push(<CustomFieldValueDto>{field: DictionaryUtils.toBasDto(d), subvalues: []});
  }

  getListElementValues(listFieldId: number) {
    return this.clause ? this.clause.values.filter((v) => v.field.id === listFieldId) : [];
  }

  get clauseId() {
    return this.typeIdAsClauseId ? this.clause.type.id : this.clauseInternalId;
  }

  get expandableSection() {
    if (this.disabled) {
      return this.noFields ? 'disabled-aligned' : false;
    } else {
      return 'disabled-aligned';
    }
  }

  get noFields() {
    return !this.allFields || this.allFields.length < 1;
  }
}
