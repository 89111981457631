/**
 * Service for parsing data copied from Excel, and returning it as list of objects of specific type.
 *
 * @author szkrabko
 */
import {Injectable} from '@angular/core';
import {DictionaryBaseService} from '../../../services';
import {ColumnComponent} from '../column.component';
import {DictionaryBaseDto} from '../../../model';
import {isDateValid} from 'ngx-bootstrap/chronos';
import {StringUtils} from '../../../utils';
import * as moment from 'moment';
import {utc} from 'moment';
import {TranslateService} from '@ngx-translate/core';

/**
 * Main config interface
 * * columnsCount - Amount of columns which input data must have, in order for this config to be applied.
 *                  If empty, will be treated as default config to use, when no specific configs are defined, or when amount of columns
 *                  in the input does not match any other config.
 * * columns      - Configuration for each column in the input data.
 */
export interface IPasteFromExcelConfig {
  columnsCount?: number;
  columns: IPasteFromExcelColumnConfig[];
}

/**
 * Column config interface
 * * type                 - Type of column, can be chosen from available types. Value will be parsed differently based on this type.
 * * property             - Name of the field in output corresponding to this column.
 * * dictionary           - Name of the dictionary (used only if type is set to 'dictionary').
 * * regExp               - Regular expression which should match a single group - used to extract data from the value.
 *                          Used mainly in sub columns of column with type 'multi', to extract data for more than one column
 *                          in the output list, from a single column of the input data.
 * * subColumns           - Nested columns (used only if type is set to 'multi'). Contains a list of columns for the output object,
 *                          for which the data will be taken from one single input column (extracted using 'regExp').
 * * customParseFunction  - Custom function run on value of the column (used only if type is set to 'custom'). All fields of the object
 *                          returned by this function will be copied to output object. Use of it is discouraged - please use
 *                          column of type 'multi' with sub columns when possible.
 */
export interface IPasteFromExcelColumnConfig {
  type: 'text' | 'number' | 'dictionary' | 'checkbox' | 'radio' | 'date' | 'multi' | 'custom' | 'combo';
  property?: string;
  title?: string;
  dictionary?: string;
  regExp?: RegExp;
  subColumns?: IPasteFromExcelColumnConfig[];
  customParseFunction?: Function;
  editable?: boolean;
  comboItems?: {number: string}[];
}

export class PasteFromExcelColumnError {
  constructor(columnName: string, errorMessage: string) {
    this.columnName = columnName;
    this.errorMessage = errorMessage;
  }
  columnName: string;
  errorMessage: string;
}

export class PasteFromExcelRowError {
  constructor(rowNumber: number, columnError: PasteFromExcelColumnError) {
    this.rowNumber = rowNumber;
    this.columnErrors.push(columnError);
  }
  rowNumber: number;
  columnErrors: PasteFromExcelColumnError[] = [];
}

export class PasteFromExcelResult<T> {
  items: T[];
  rowErrors: PasteFromExcelRowError[] = [];
  get itemsCount(): number {
    return this.items.length;
  }
  get errorsCount(): number {
    return this.rowErrors.length;
  }
  get totalCount(): number {
    return this.items.length + this.rowErrors.length;
  }
  hasErrors(): boolean {
    return this.rowErrors.length > 0;
  }
  addError(rowNo: number, colName: string, errorMessage: string) {
    const colError = new PasteFromExcelColumnError(colName, errorMessage);
    const rowError = this.rowErrors.find((re) => re.rowNumber === rowNo);
    if (rowError) {
      rowError.columnErrors.push(colError);
    } else {
      this.rowErrors.push(new PasteFromExcelRowError(rowNo, colError));
    }
  }
}

@Injectable()
export class PasteFromExcelService<T> {
  constructor(private dictionaryBaseService: DictionaryBaseService, private translateService: TranslateService) {}

  private dictionaries: Map<String, DictionaryBaseDto[]> = new Map<String, DictionaryBaseDto[]>();
  private _configs: IPasteFromExcelConfig[];
  private _defaultConfig: IPasteFromExcelConfig;

  get configs(): IPasteFromExcelConfig[] {
    return this._configs || [this._defaultConfig];
  }

  set configs(config: IPasteFromExcelConfig[]) {
    this._configs = config;
    config.forEach((conf) => this.initDictionaries(conf.columns));
  }

  set columns(columns: ColumnComponent<T>[]) {
    const defaultConfig: IPasteFromExcelConfig = <IPasteFromExcelConfig>{
      columns: [],
    };
    for (const col of columns) {
      defaultConfig.columns.push(<IPasteFromExcelColumnConfig>{
        type: col.type,
        property: col.property,
        dictionary: col.dictionary,
        editable: col.editable,
        title: col.title,
      });
    }
    this._defaultConfig = defaultConfig;
    this.initDictionaries(defaultConfig.columns);
  }

  private initDictionaries(columns: IPasteFromExcelColumnConfig[]) {
    for (const col of columns) {
      if (col.type === 'dictionary' && !this.dictionaries.has(col.dictionary)) {
        this.dictionaries.set(col.dictionary, []);
        this.dictionaryBaseService.getDictionaryBase(col.dictionary).subscribe((dict) => {
          this.dictionaries.set(col.dictionary, dict);
        });
      } else if (col.subColumns) {
        this.initDictionaries(col.subColumns);
      }
    }
  }

  private getConfigForColumnsCount(columnsCount: number) {
    return (
      this.configs.find((conf) => conf.columnsCount === columnsCount) ||
      this.configs.find((conf) => !conf.columnsCount) ||
      this._defaultConfig
    );
  }

  private prepareRow(
    cols: string[],
    columnsConfig: IPasteFromExcelColumnConfig[],
    rowNumber: number,
    result: PasteFromExcelResult<T>,
    newRow: T
  ): boolean {
    let valid = true;
    for (const colIndex of Object.keys(cols)) {
      if (columnsConfig[colIndex]) {
        const column = columnsConfig[colIndex];
        const value = StringUtils.matchSingleGroup(cols[colIndex], column.regExp) || cols[colIndex];
        if (column.editable === false) {
          continue;
        }
        switch (column.type) {
          case 'text':
            console.log('new text value');
            newRow[column.property] = value;
            break;
          case 'number':
            console.log('new number value');
            newRow[column.property] = +value.replace(',', '.').replace(/\s/g, '');
            if (isNaN(newRow[column.property])) {
              valid = false;
              // transla
              result.addError(
                rowNumber,
                this.translateService.instant(column.title),
                this.translateService.instant('pasteFromExcel.noParse', {value: value, type: 'number'})
              );
            }
            break;
          case 'dictionary':
            console.log('new dictionary value');
            if (this.dictionaries.has(column.dictionary)) {
              newRow[column.property] = this.dictionaries
                .get(column.dictionary)
                .find((entry) => value === entry.id || value === entry.name || value.toUpperCase() === entry.code);
              if (newRow[column.property] === undefined) {
                valid = false;
                result.addError(
                  rowNumber,
                  this.translateService.instant(column.title),
                  this.translateService.instant('pasteFromExcel.noFind', {value: value, dictionary: column.dictionary})
                );
              }
            }
            break;
          case 'checkbox': // fallthrough
          case 'radio':
            newRow[column.property] = value.toLocaleLowerCase() in [1, 't', 'true', 'y', 'yes'];
            break;
          case 'date':
            const dateFormatRegex = {
              'YYYY-MM-DD': /^\d{4}-\d{1,2}-\d{1,2}$/,
              'YYYY.MM.DD': /^\d{4}\.\d{1,2}\.\d{1,2}$/,
              'DD-MM-YYYY': /^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[0-2])-\d{4}$/,
              'DD.MM.YYYY': /^\d{1,2}\.\d{1,2}\.\d{4}$/,
            };
            for (const [key, regexp] of Object.entries(dateFormatRegex)) {
              if (regexp.test(value)) {
                newRow[column.property] = utc(value, [key, moment.ISO_8601, moment.RFC_2822]).toDate();
              }
            }
            if (!isDateValid(newRow[column.property])) {
              valid = false;
              result.addError(
                rowNumber,
                this.translateService.instant(column.title),
                this.translateService.instant('pasteFromExcel.noParse', {value: value, type: 'date'})
              );
            }
            break;
          case 'multi':
            const subCols: string[] = [];
            let subValid = true;
            for (const subColumn of column.subColumns) {
              const match = StringUtils.matchSingleGroup(value, subColumn.regExp);
              if (match) {
                subCols.push(match);
              } else {
                result.addError(
                  rowNumber,
                  subColumn.property,
                  this.translateService.instant('pasteFromExcel.noMatch', {value: value})
                );
                subValid = false;
              }
            }
            if (subValid) {
              subValid = this.prepareRow(subCols, column.subColumns, rowNumber, result, newRow);
            }
            valid = valid && subValid;
            break;
          case 'combo':
            newRow[column.property] = column.comboItems?.find((i) => i.number === value);
            break;
          case 'custom':
            Object.assign(newRow, column.customParseFunction(value));
            break;
          default:
            newRow[column.property] = value;
        }
      }
    }
    return valid;
  }

  /**
   * Main method of the service.
   *
   * @param {string} data                 input copied from Excel
   * @returns {PasteFromExcelResult<T>}   result object containing list of output objects and list of row errors
   */
  parseData(data: string): PasteFromExcelResult<T> {
    const result = new PasteFromExcelResult<T>();
    const rows = data.split(/\n|\n\r|\r\n|\r/g);
    const items: T[] = [];
    let rowNumber = 0;
    for (const row of rows) {
      rowNumber++;
      if (!row) {
        continue;
      }
      const cols = row.split('\t');
      const newRow: T = <T>{};
      if (this.prepareRow(cols, this.getConfigForColumnsCount(cols.length).columns, rowNumber, result, newRow)) {
        items.push(newRow);
      }
    }
    result.items = items;
    return result;
  }
}
