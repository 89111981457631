import {PolicyContractViewSection, PolicyContractViewService} from '../../services/policy-contract-view.service';
import {
  AppConfigService,
  DictionaryBaseDto,
  DictionaryService,
  IMultiSelectSettings,
  PolicyContractVersionDto,
  PolicyContractVersionService,
  PolicyInquiryVersionDto,
  RelatedObject,
} from '../../../../bonding_shared';
import {Directive, Input} from '@angular/core';
import {ListEmitters} from '../../../../bonding_shared/components/details-view/list-emitters';
import {UntypedFormGroup} from '@angular/forms';
import {NumberUtils} from '../../../../bonding_shared/utils/number-utils';
import {
  Frequency,
  FrontingType,
  PeriodUnit,
  POLICY_CONTRACT_REQUEST_OR_TERMINATION_REQUEST_STATUSES,
  PolicyContractType,
  PolicyContractVersionStatus,
} from '../../../../bonding_shared/model/dictionary-ids';

@Directive()
export class Section extends ListEmitters {
  self = this;
  public viewSection = PolicyContractViewSection;
  public _policy: PolicyContractVersionDto;
  public _inquiry: PolicyInquiryVersionDto;
  private _showErrors: boolean;

  @Input() policyForm: UntypedFormGroup;
  @Input() sectionDisabled = false;
  // emitters
  activeList: string;

  notificationToRestore: DictionaryBaseDto;
  pdPeriodUnitRegexp: RegExp = /^[DM]$/;

  euOrEpRequest = false;

  @Input() set policy(policy: PolicyContractVersionDto) {
    this._policy = policy;
  }

  get policy() {
    return this._policy;
  }

  @Input() set showErrors(value: boolean) {
    this._showErrors = value;
  }

  get showErrors() {
    return this._showErrors;
  }

  @Input() set inquiry(inquiry: PolicyInquiryVersionDto) {
    this._inquiry = inquiry;
  }

  get inquiry(): PolicyInquiryVersionDto {
    return this._inquiry;
  }

  get readonly() {
    return this.sectionDisabled || this.policyContractViewService.readonly;
  }

  get readonlyOrNotFirstVersion() {
    return this.readonly || this.policy.versionNumber !== 1;
  }

  // Special clauses CR i KUKE
  // thresholds discretionary cover
  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
  };

  multiSelectSettingsSearchDisabled: IMultiSelectSettings = {
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
  };

  PolicyContractType = PolicyContractType;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected appService: AppConfigService,
    protected policyContractVersionService?: PolicyContractVersionService,
    protected dictionaryService?: DictionaryService
  ) {
    super();

    this.selectorNameList = [
      RelatedObject.FRONTER,
      RelatedObject.CLIENT,
      RelatedObject.RISK_ASSESSMENT_AGENCY, // CR
      RelatedObject.FL_INSURERS,
      RelatedObject.CO_INSURERS,
      RelatedObject.SUBINSUREDS,
      RelatedObject.SPECIAL_RISKS,
      RelatedObject.COLLECTION_COMPANIES,
      RelatedObject.GUARANTOR, // KUKE
      RelatedObject.EXPORTER,
      RelatedObject.IMPORTER,
      RelatedObject.INVESTOR,
      RelatedObject.SPONSOR,
      RelatedObject.MAIN_CONTRACTOR,
    ];
    this.initializeSelectorEmitters(true);
  }

  // #######################################################################################################################################

  // Technical fronting
  // Risk assessment
  isActive(listType: string) {
    return this.activeList === listType;
  }

  // other standard conditions
  // scope of cover
  onPoliticalRiskChanged(checked: boolean) {
    if (!checked) {
      delete this.policy.politicalRiskValue;
    }
  }

  onProtractedDefaultChanged(checked: boolean) {
    if (!checked) {
      delete this.policy.protractedDefaultValue;
      delete this.policy.protractedDefaultPeriodUnit;
    } else {
      this.dictionaryService
        .getDictionaryEntry('PeriodUnit', PeriodUnit.DAY)
        .subscribe((d) => (this.policy.protractedDefaultPeriodUnit = d));
    }
  }

  shouldShowPercent(a: number, b: number) {
    return NumberUtils.isValidPercent(a, b);
  }

  // #######################################################################################################################################
  // VISIBILITY
  isSectionVisible(
    viewSection: PolicyContractViewSection,
    defaultVisibility = false,
    globalConditionsOfInsuranceId = null
  ) {
    if (this.policy) {
      return this.policyContractViewService.isSectionVisible(
        this.policy.contractType.id,
        viewSection,
        defaultVisibility,
        globalConditionsOfInsuranceId
      );
    }
    if (this.inquiry) {
      return this.policyContractViewService.isSectionVisible(
        this.inquiry.productType.id,
        viewSection,
        defaultVisibility,
        globalConditionsOfInsuranceId
      );
    }
    return false;
  }

  // VISIBILITY
  isRequired(viewSection: PolicyContractViewSection, requiredByDefault = false) {
    return this.isSectionVisible(viewSection, requiredByDefault);
  }

  isEditable(viewSection: PolicyContractViewSection, editableByDefault = true) {
    return this.isSectionVisible(viewSection, editableByDefault);
  }

  // section premium
  // section scope of cover
  // premium instalments
  isCheckboxDisabled(viewSection: PolicyContractViewSection, defaultValue: boolean) {
    return this.isSectionVisible(viewSection, defaultValue);
  }

  onPremiumFixedChanged(checked: boolean) {
    if (checked && !this.policy.changeInTurnover) {
      delete this.policy.premiumRate;
      if (this.appService.appClientConfig === 'credendo') {
        delete this.policy.policyContract.turnoverNotification;
      }
    } else {
      if (this.notificationToRestore) {
        this.policy.policyContract.turnoverNotification = JSON.parse(JSON.stringify(this.notificationToRestore));
      } else {
        this.policy.policyContract.turnoverNotification = <DictionaryBaseDto>{};
        this.policy.policyContract.turnoverNotification.id =
          this.appService.appClientConfig === 'credendo' ? Frequency.YEARLY : Frequency.MONTHLY;
      }
    }
  }

  policyNotActivated() {
    return (
      this.policy.businessStatus &&
      ![
        PolicyContractVersionStatus.BS_ACTIVE,
        PolicyContractVersionStatus.BS_ACTIVE_LOCKED,
        PolicyContractVersionStatus.BS_TERMINATED_CLOSED,
        PolicyContractVersionStatus.BS_OBSOLETE,
      ].includes(this.policy.businessStatus.id)
    );
  }

  policyActive() {
    return this.policy.businessStatus && PolicyContractVersionStatus.BS_ACTIVE === this.policy.businessStatus.id;
  }

  public frontingOfType(frontingType: FrontingType): boolean {
    return (this.appService.credendo || this.appService.mehib) && this.policy.frontingType?.id === frontingType;
  }

  statusAtLeastISSUED() {
    return this.selectedPCVInOneOfStatuses(
      PolicyContractVersionStatus.ISSUED,
      PolicyContractVersionStatus.ACTIVATED,
      PolicyContractVersionStatus.ACTIVATED_LOCKED,
      PolicyContractVersionStatus.TERMINATION_ACTIVATED
    );
  }

  statusAtLeastREQUEST_ACCEPTED() {
    return this.selectedPCVInOneOfStatuses(
      PolicyContractVersionStatus.REQUEST_ACCEPTED,
      PolicyContractVersionStatus.ISSUED,
      PolicyContractVersionStatus.ACTIVATED,
      PolicyContractVersionStatus.ACTIVATED_LOCKED,
      PolicyContractVersionStatus.TERMINATION_ACTIVATED
    );
  }

  isLiveRequestOrTerminationRequest() {
    return this.selectedPCVInOneOfStatuses(...POLICY_CONTRACT_REQUEST_OR_TERMINATION_REQUEST_STATUSES);
  }

  selectedPCVInOneOfStatuses(...statuses: PolicyContractVersionStatus[]) {
    return this.policy && this.policy.status && statuses.includes(this.policy.status.id);
  }

  isType(policyContractTypeId: PolicyContractType) {
    return this.policy && this.policy.contractType && this.policy.contractType.id === policyContractTypeId;
  }
}
