<search-view labelKey="invoicePayment.search.caption" objectName="Payments" [dataProvider]="dataProvider"
             [newHandled]="false" [searchModeSwitchable] = "false" [newButton]="false">
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner bon-label-180">
      <input-row labelKey="invoicePayment.search.invoiceNumber" [(ngModel)]="criteria.invoice.number" name="invoiceNumber"> </input-row>
      <date-row labelKey="invoicePayment.search.financialSystemDateFrom" [(ngModel)]="criteria.financialSystemDateRange.dateFrom" type="date" name="dateFrom"> </date-row>
    </div>

    <div class="bon-card-inner">
      <form-row></form-row>
      <date-row labelKey="invoicePayment.search.financialSystemDateTo" [(ngModel)]="criteria.financialSystemDateRange.dateTo" type="date" name="dateTo"> </date-row>
    </div>

    <div class="bon-card-inner"></div>

  </div>

  <div class="search-results">
      <a-table [pagination]="true" [pageSize]="20" [selection]="false" [sortable]='!dataProvider.textSearch'>
        <column labelKey="invoicePayment.search.result.invoiceNumber" sort="invoice.number" [link]="true" (linkClick)="router.toInvoiceDetails($event.invoice.category.id, $event.invoice.id)">
          <ng-template let-item="item" let-edition="false">{{item.invoice.number ? item.invoice.number : 'empty'}}</ng-template>
        </column>
        <column labelKey="invoicePayment.search.result.financialSystemDate" property="financialSystemDate" sort="financialSystemDate" type="date"></column>
        <column labelKey="invoicePayment.search.result.correctedDate" property="correctedDate" sort="correctedDate" type="date"></column>
        <column labelKey="invoicePayment.search.result.amount" property="amount" sort="amount" type="number"></column>
      </a-table>
  </div>
  <div class="additional-buttons">
  </div>

</search-view>

