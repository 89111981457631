import {AbstractService} from '../../bonding_shared/services/abstract.service';
import {Observable} from 'rxjs';
import {AppConfigService} from '../../bonding_shared/services/app-config.service';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CompanyResponsiblePersonDto, CompanySimpleDto, EmailResponseDto, IdDto} from '../../bonding_shared/model';

export class CompanyAbstractService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getCompanySimple(id: number): Observable<CompanySimpleDto> {
    return this.get<CompanySimpleDto>(this.url + '/simple/' + id);
  }
  getUnderwriter(id: number, roleId: number): Observable<CompanyResponsiblePersonDto> {
    let params = new HttpParams();
    params = params.append('roleId', roleId + '');
    return this.get<CompanyResponsiblePersonDto>(this.url + '/responsible/' + id, params);
  }

  findSalesUser(company: CompanySimpleDto): Observable<any> {
    return this.post<CompanySimpleDto>(company, this.url + '/findSalesUser').pipe();
  }
}
