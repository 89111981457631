import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BatchExecutionDetailsComponent} from './batch-execution-details.component';
import {BatchExecutionsComponent} from './batch-executions.component';
import {BatchGuiService} from './service/batch-gui.service';
import {BatchRunComponent} from './batch-run.component';
import {MigrationRejectService} from './service/migration-reject.service';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
  declarations: [BatchExecutionDetailsComponent, BatchExecutionsComponent, BatchRunComponent],
  providers: [BatchGuiService, MigrationRejectService],
})
export class BatchModule {}
