import {Route} from '@angular/router';
import {PortalLoginComponent} from './portal-login.component';
import {ResetPasswordComponent} from './reset-password.component';
import {RemindPasswordComponent} from './remind-password.component';

export const PortalLoginRoutes: Route[] = [
  {
    path: 'login',
    component: PortalLoginComponent,
  },
  {
    path: 'login/:afterResetMessage',
    component: PortalLoginComponent,
  },
  {
    path: '',
    component: PortalLoginComponent,
  },
  {
    path: 'resetPassword/:login/:expiryTime/:token/:lang',
    component: ResetPasswordComponent,
  },
  {
    path: 'remindPassword',
    component: RemindPasswordComponent,
  },
];
