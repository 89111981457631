import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {DocumentModule} from '../document/document.module';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {ContractSharedModule} from '../bonding-contract/contract/components/shared/contract-shared.module';

import {BannerManagementComponent} from './banner-management.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    DocumentModule,
    ContractSharedModule,
  ],
  declarations: [BannerManagementComponent],
})
export class BannerModule {}
