import {Component, Input, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {
  CollectionDisputedAmountDto,
  CollectionInvoiceDto,
  CollectionPaymentBaseDto,
  CollectionPaymentDto,
  CollectionVersionDto,
} from '../../../bonding_shared/model';
import {ATableComponent, CellChangeEvent} from '../../../bonding_shared/components/aku-table';
import {DocumentService, RouterService} from '../../../bonding_shared/services';
import {
  CollectionPaymentAllocation,
  CollectionType,
  InvoiceCategory,
} from '../../../bonding_shared/model/dictionary-ids';
import * as moment from 'moment';
import {CollectionGuiService} from '../services/collection-gui.service';
import {DateUtils} from '../../../bonding_shared/utils/date-utils';

@Component({
  selector: 'collection-invoices',
  templateUrl: 'collection-invoices.component.pug',
})
export class CollectionInvoicesComponent {
  @ViewChild(ATableComponent) invoiceTable: ATableComponent<CollectionInvoiceDto>;

  @Input() formModel: UntypedFormGroup;
  @Input() portal: boolean;
  @Input() version: CollectionVersionDto;
  @Input() disabled: boolean;
  @Input() showErrors: boolean;
  @Input() paymentPreview: boolean;
  @Input() payments: CollectionPaymentDto[];
  selectedInvoice: CollectionInvoiceDto;

  private _asymmetricTx: boolean;

  @Input() set asymmetricTx(asymmetricTx: boolean) {
    this._asymmetricTx = asymmetricTx;
    if (this.invoiceTable) {
      this.invoiceTable.resetControls();
    }
  }

  get asymmetricTx() {
    return this._asymmetricTx;
  }

  invoiceSpreadsheets = [
    {name: 'collection.invoice.withAsymmetricTransactions', withAsymmetricTransactions: true},
    {name: 'collection.invoice.withoutAsymmetricTransactions', withAsymmetricTransactions: false},
  ];

  public readonly CollectionType = CollectionType;
  public readonly InvoiceCategory = InvoiceCategory;

  constructor(public router: RouterService, private documentService: DocumentService) {}

  invoiceEditable(i: CollectionInvoiceDto): boolean {
    return !i.paidAmount && !i.insurerInvoice;
  }

  isInsurerInvoice(i: CollectionInvoiceDto): boolean {
    return !!i.insurerInvoice;
  }

  get recoveryCI() {
    return this.version.parent.type && this.version.parent.type.id === CollectionType.RECOVERY_CREDIT_INSURANCE;
  }

  onInvoiceIssueDateChange(event: CellChangeEvent<CollectionInvoiceDto>) {
    if (!event.item.deliveryDispatchDate) {
      event.item.deliveryDispatchDate = event.value;
    }
  }

  get allInvoicesInRecovery(): boolean {
    return this.version.collectionInvoices.every((i) => i.inRecovery);
  }

  switchAllInRecovery() {
    const newVal = !this.allInvoicesInRecovery;
    this.version.collectionInvoices.forEach((i) => (i.inRecovery = newVal));
  }

  toInsurerInvoice(i: CollectionInvoiceDto) {
    this.router.toInvoiceDetails(InvoiceCategory.INVOICE, i.insurerInvoice.id);
  }

  onInvoiceDueDateChange(event: CellChangeEvent<CollectionInvoiceDto>) {
    if (this.asymmetricTx && event.item.invoiceDeliveryDate && event.value) {
      const maxDueDate = moment(event.item.invoiceDeliveryDate).add(60, 'days').toDate();
      if (event.value > maxDueDate) {
        event.item.dueDate = maxDueDate;
      }
    }
  }

  onInvoiceDeliveryDateChange(event: CellChangeEvent<CollectionInvoiceDto>) {
    if (this.asymmetricTx && event.item.dueDate && event.value) {
      const maxDueDate = moment(event.value).add(60, 'days').toDate();
      if (event.item.dueDate > maxDueDate) {
        event.item.dueDate = maxDueDate;
      }
    }
  }

  isCreditInsurance(): boolean {
    return CollectionGuiService.isCreditInsurance(this.version.parent);
  }

  isDuePremiums(): boolean {
    return CollectionGuiService.isDuePremiums(this.version.parent);
  }

  selectInvoice(invoice: CollectionInvoiceDto) {
    if (this.paymentPreview) {
      // for non preview mode we prevent setting invoice which blocks showing filtered table
      this.selectedInvoice = invoice;
    }
  }

  get filteredPayments(): CollectionPaymentDto[] {
    return this.payments && this.payments.filter((p) => p.invoice.id === this.selectedInvoice.id);
  }

  updateDisputeAmounts() {
    const disputeCurrencies = this.version.collectionInvoices.map((i) => i.currency);
    disputeCurrencies.forEach((c) => {
      if (!this.version.disputeAmounts.find((da) => da.currency.id === c.id)) {
        const dispAmount = <CollectionDisputedAmountDto>{};
        dispAmount.currency = c;
        dispAmount.disputeAmount = 0;
        this.version.disputeAmounts.push(dispAmount);
      }
    });
    this.version.disputeAmounts = this.version.disputeAmounts.filter((da) =>
      disputeCurrencies.find((c) => c.id === da.currency.id)
    );
  }

  getInvoiceSpreadsheet(withAsymmetricTransactions: boolean) {
    this.documentService.downloadCollectionInvoiceSpreadsheet(withAsymmetricTransactions);
  }

  public getPaidAmountTooltip(i: CollectionInvoiceDto): string {
    return this.generatePaymentSummaryTable(i.payments);
  }

  public getInsurersBalanceAmountTooltip(i: CollectionInvoiceDto): string {
    if (!i.payments || i.payments.length === 0) {
      return 'Brak płatności';
    }
    return this.generatePaymentSummaryTable(
      i.payments.filter((x) => x.allocation.id === CollectionPaymentAllocation.INSURER)
    );
  }

  public getInsuredsBalanceAmountTooltip(i: CollectionInvoiceDto): string {
    if (!i.payments || i.payments.length === 0) {
      return 'Brak płatności';
    }
    return this.generatePaymentSummaryTable(
      i.payments.filter((x) => x.allocation.id === CollectionPaymentAllocation.INSURED)
    );
  }

  private generatePaymentSummaryTable(payments: CollectionPaymentBaseDto[]): string {
    if (!payments || payments.length === 0) {
      return 'Brak płatności';
    }
    let result = '<table>' + '<tr>' + '<th>Data wpłaty</th>' + '<th>Kwota wpłaty</th>' + '<th>Waluta</th>' + '</tr>';
    for (const p of payments) {
      result += '<tr>';
      result += '<td>' + DateUtils.format(p.dateOfPayment) + '</td>';
      result += '<td>' + p.amountGross + '</td>';
      result += '<td>' + p.currency.code + '</td>';
      result += '</tr>';
    }
    result += '</table>';
    return result;
  }
}
