import {BrokerContractSearchComponent} from './broker-contract-search.component';
import {BrokerContractDetailsComponent} from './broker-contract-details.component';
import {BrokerContractPreviewComponent} from './broker-contract-preview.component';
import {BrokerContractRelationDetailsComponent} from './broker-contract-relation-details.component';
import {BrokerContractRepresentativeDetailsComponent} from './broker-contract-representative-details.component';
import {BrokerContractAccessGuard} from './broker-contract-access-guard';
import {BrokerContractRelationAccessGuard} from './broker-contract-relation-access-guard';

export const BrokerContractRoutes = [
  {
    path: 'broker-contract-search',
    component: BrokerContractSearchComponent,
    canActivate: [BrokerContractAccessGuard],
  },
  {
    path: 'broker-contract-details/:mode/:brokerContractVersionId',
    component: BrokerContractDetailsComponent,
    canActivate: [BrokerContractAccessGuard],
  },
  {
    path: 'broker-contract-details/:mode/:brokerContractVersionId/:companyId',
    component: BrokerContractDetailsComponent,
    canActivate: [BrokerContractAccessGuard],
  },
  {
    path: 'broker-contract-preview/:versionId/:brokerContractId',
    component: BrokerContractPreviewComponent,
    canActivate: [BrokerContractAccessGuard],
  },
  {
    path: 'broker-contract-relation-details/:relationVersionId/:brokerContractId/:relatedToId/:relatedToTypeId',
    component: BrokerContractRelationDetailsComponent,
    canActivate: [BrokerContractRelationAccessGuard],
  },
  {
    path: 'broker-contract-representative/:brokerContractId',
    component: BrokerContractRepresentativeDetailsComponent,
  },
];
