import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {
  BrokerContractRepresentativeVersionCriteriaDto,
  BrokerContractRepresentativeVersionDto,
  SearchCriteria,
  SearchResult,
} from '../model';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class BrokerContractRepresentativeService extends AbstractService {
  protected url = this.urlPrefix + 'brokerContractRepresentative';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  public initialVersion(brokerContractId: number): Observable<BrokerContractRepresentativeVersionDto> {
    return this.get<BrokerContractRepresentativeVersionDto>(`${this.url}/initialVersion/${brokerContractId}`);
  }

  public getBrokerContractRepresentativesByBrokerContractId(
    brokerContractId: number
  ): Observable<Array<BrokerContractRepresentativeVersionDto>> {
    const criteria = <SearchCriteria<BrokerContractRepresentativeVersionCriteriaDto>>{
      criteria: {brokerContract: {id: brokerContractId}},
    };
    return this.searchByCriteria(criteria).pipe(
      map(
        (searchResult: SearchResult<BrokerContractRepresentativeVersionDto>) =>
          <BrokerContractRepresentativeVersionDto[]>searchResult.result
      )
    );
  }
}
