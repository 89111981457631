import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, ParamMap, Router, RouterStateSnapshot} from '@angular/router';
import {LoggedUserService, PolicyContractVersionService} from '../../../bonding_shared';
import {PolicyElementaryRight} from '../../../bonding_shared/model/dictionary-ids';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractGuard} from '../../../bonding_shared/components/abstract-guard';

@Injectable()
export class PolicyContractDetailsGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router, private service: PolicyContractVersionService) {
    super(loggedUserService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;
    const params: ParamMap = route.paramMap;
    const id: number = +params.get('id');
    if (id === 0) {
      return new Observable<boolean>((observer) => {
        observer.next(this.checkRight(url, PolicyElementaryRight.POLICY_CREATE));
      });
    }
    return this.service.getPolicyContractVersion(id).pipe(
      map((pcv) => {
        // FIXME Value only for KUKE PRESENTATION, will be replaced with actual implementation
        if (pcv.client.address.zipCode === '02-672') {
          return true;
        }
        return this.checkRight(url, PolicyElementaryRight.POLICY_VIEW);
      })
    );
  }
}
