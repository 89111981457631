import {Component, Input} from '@angular/core';
import {ContractLinkDto} from '../../../bonding_shared/model/dtos';
import {RouterService} from '../../../bonding_shared';

@Component({
  selector: 'invoice-contract-link',
  templateUrl: 'invoice-contract-link.component.html',
})
export class InvoiceContractLinkComponent {
  @Input() contractLink: ContractLinkDto;

  constructor(public router: RouterService) {}

  get bondingContractLink(): boolean {
    return !!this.router.toContractPreviewByContractId;
  }
  toContract() {
    this.router.toContractPreviewByContractId(this.contractLink.contract.id);
  }

  get policyContractLink(): boolean {
    return !!this.router.toPolicyContractPreviewByPolicyContractId;
  }
  toPolicyContract() {
    this.router.toPolicyContractPreviewByPolicyContractId(this.contractLink.policyContract.id);
  }

  get brokerContractLink(): boolean {
    return !!this.router.toBrokerContractPreview;
  }
  toBrokerContract() {
    this.router.toBrokerContractPreview(0, this.contractLink.brokerContract.id);
  }

  get policyInquiryLink(): boolean {
    return !!this.router.toPolicyInquiryDetails;
  }
  toPolicyInquiry() {
    this.router.toPolicyInquiryDetailsByParentId(this.contractLink.policyInquiry.id);
  }
}
