import {Component, Input} from '@angular/core';
import {CollectionVersionBaseDto} from '../../../../bonding_shared/model';
import {RouterService} from '../../../../bonding_shared/services';
import {Subject} from 'rxjs';

@Component({
  selector: 'collection-section',
  template: `
    <selectable-object-section
      labelKey="collection.case"
      [clearable]="false"
      [searchable]="searchable"
      [openSelectorEmitter]="openSelectorEmitter"
    >
      <div class="bon-row" *ngIf="collectionVersion" content="body">
        <b class="a-link col-md-8 font-larger" (click)="router.toCollectionDetails(collectionVersion.id)">
          {{ collectionVersion.parent.number }}</b
        >
        <text-row
          labelKey="common.type"
          [value]="collectionVersion.parent.type.name"
          [alignRight]="true"
          *ngIf="showType"
        ></text-row>
        <text-row
          labelKey="collection.status"
          [value]="collectionVersion.status.name"
          [alignRight]="true"
          *ngIf="showStatus"
        ></text-row>
      </div>
    </selectable-object-section>
    <bon-section [labelKey]="creditorLabelKey" *ngIf="showCreditor">
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <company-info [company]="collectionVersion.parent.creditor" [showLink]="true"></company-info>
        </div>
      </div>
    </bon-section>
  `,
})
export class CollectionSectionComponent {
  @Input() collectionVersion: CollectionVersionBaseDto;
  @Input() openSelectorEmitter: Subject<boolean>;
  @Input() searchable: boolean;
  @Input() showCreditor = false;
  @Input() creditorLabelKey = 'collection.creditor';
  @Input() showType = true;
  @Input() showStatus = true;

  constructor(public router: RouterService) {}
}
