import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService} from '../../../../../bonding_shared/services/app-config.service';

@Component({
  selector: 'policy-collection-section',
  templateUrl: './policy-collection-section.component.pug',
})
export class PolicyCollectionSectionComponent extends Section {
  self = this;

  constructor(protected policyContractViewService: PolicyContractViewService, public appService: AppConfigService) {
    super(policyContractViewService, appService);
  }
}
