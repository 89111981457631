import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {LimitService} from '../../../../bonding_shared/services/limit.service';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {LimitExposureDto, LimitRequestCriteriaDto, LimitRequestDto} from '../../../../bonding_shared/model/dtos';
import {LimitListComponent} from './limit-list.component';
import {LoggedUserService} from '../../../../bonding_shared/services/logged-user.service';
import {ElementaryRight} from '../../../../bonding_shared/model/dictionary-ids';
import {AppConfigService, RouterService} from '../../../../bonding_shared/services';
import {LimitGuiService} from '../services/limit-gui.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'filterable-limit-list',
  templateUrl: './filterable-limit-list.component.pug',
})
export class FilterableLimitListComponent implements AfterViewInit {
  @Input() product = false;
  @Input() showId = true;
  @ViewChild('limitList') limitList: LimitListComponent;

  type: string;

  dataProvider: SearchDataProvider<LimitRequestCriteriaDto, LimitRequestDto>;
  show = false;
  exposure: LimitExposureDto;
  private _buyerId: number;
  private _clientId: number;

  constructor(
    private limitService: LimitService,
    private loggedUserService: LoggedUserService,
    private limitGuiService: LimitGuiService,
    public router: RouterService,
    public appService: AppConfigService,
    private translateService: TranslateService
  ) {
    this.dataProvider = limitGuiService.createDataProvider();
    this.show = loggedUserService.hasRight(ElementaryRight.ACCESS_MODULE_LIMITS);
  }

  public get title(): string {
    if (this._buyerId) {
      return this.translateService.instant('limitSearch.buyerLimits');
    } else {
      return this.translateService.instant('limitSearch.insuredLimits');
    }
  }

  ngAfterViewInit() {
    this.refreshList();
  }

  @Input() set clientId(id: number) {
    if (id && (!this._clientId || id !== this._clientId)) {
      this._clientId = id;
      this.refreshList();
    }
  }

  @Input() set buyerId(id: number) {
    if (id && (!this._buyerId || id !== this._buyerId)) {
      this._buyerId = id;
      this.refreshList();
    }
  }

  refreshList() {
    this.dataProvider.searchCriteria.criteria.insuredId = undefined;
    this.dataProvider.searchCriteria.criteria.buyerCompanyId = undefined;
    if (!this._buyerId) {
      this.dataProvider.searchCriteria.criteria.insuredId = this._clientId;
    } else {
      this.dataProvider.searchCriteria.criteria.buyerCompanyId = this._buyerId;
    }
    if (this.limitList && this.limitList.limitTable) {
      this.limitList.limitTable.search();
    }
    this.exposure = undefined;
    if (this._buyerId) {
      this.limitService.exposureForBuyer(this._buyerId).subscribe((exp) => {
        this.exposure = exp;
        this.type = 'b ' + this._buyerId;
      });
    } else if (this._clientId) {
      this.limitService.exposureForInsured(this._clientId).subscribe((exp) => {
        this.exposure = exp;
        this.type = 'c ' + this._clientId;
      });
    }
  }

  isEmpty(): boolean {
    if (!this.limitList || !this.limitList.limitTable) {
      return true;
    }
    return this.limitList.limitTable.totalCount < 1;
  }

  toLimitSearch() {
    this.limitGuiService.searchCriteria.cleanCriteria();
    if (this._buyerId) {
      this.limitGuiService.searchCriteria.setBuyerId(this._buyerId);
    } else {
      this.limitGuiService.searchCriteria.setInsuredId(this._clientId);
    }
    this.router.toLimitSearch();
  }

  public get mehib(): boolean {
    return this.appService.mehib;
  }
}
