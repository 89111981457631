import {Injectable} from '@angular/core';
import {DictionaryService, SearchDataProvider} from '../../../bonding_shared/services';
import {
  ExternalProviderBaseDto,
  ExternalProviderVersionBaseDto,
  ExternalProviderVersionCriteriaDto,
  ExternalProviderVersionDto,
} from '../../../bonding_shared/model';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {ExternalProviderVersionService} from '../../../bonding_shared/services/external-provider-version.service';

@Injectable()
export class ExternalProviderGuiService {
  dataProvider: SearchDataProviderType;

  constructor(private service: ExternalProviderVersionService) {
    this.dataProvider = this.getDataProvider();
  }

  static getEmptyVersion(): ExternalProviderVersionDto {
    return <ExternalProviderVersionDto>{
      parent: {},
      commissionGroups: [],
    };
  }

  getDataProvider(): SearchDataProviderType {
    const dataProvider = <SearchDataProviderType>BusinessUtils.createDataProvider(this.service);
    dataProvider.searchCriteria.criteria.parent = <ExternalProviderBaseDto>{};
    dataProvider.searchCriteria.criteria.versionPhase = 'LAST';
    return dataProvider;
  }
}
export type SearchDataProviderType = SearchDataProvider<
  ExternalProviderVersionCriteriaDto,
  ExternalProviderVersionBaseDto
>;
