import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService, LoggedUserService, OfferService} from '../../bonding_shared';

@Injectable()
export class PortalOfferService extends OfferService {
  protected url = this.urlPrefix + this.portalPrefix + 'policyInquiryVersion/offer';

  constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }
}
