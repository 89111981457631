/**
 * Created by wilk on 23.03.2017.
 */
import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';

const SEARCH_ROW_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SearchRowComponent),
  multi: true,
};

@Component({
  selector: 'search-row',
  template: `
    <div class="bon-row">
      <label class="bon-label">{{ labelKey ? (labelKey | translate) : label }}</label>
      <div [class.searchable-value]="!presentationMode" [class.bon-input-size]="presentationMode">
        <div
          (click)="onValueClick()"
          [class.search-input-two-icons]="!disabled && value && !presentationMode"
          [class.search-input-one-icon]="(!disabled && !value && !presentationMode) || (value && noClear)"
          [class.search-input-no-icon]="disabled || presentationMode"
        >
          <span *ngIf="!value" style="color: white">--</span>
          <div class="bon-tooltip" *ngIf="value">
            <span [class.a-link]="showLink" [class.presentation]="presentationMode">{{ property }}</span>
            <div *ngIf="showTitle" class="bon-tooltip-content">
              <div class="text-content">
                <div #customTooltipContainer>
                  <ng-content select=".tooltip-content"></ng-content>
                </div>
                <ng-container *ngIf="customTooltipContainer.innerHTML.length === 0">
                  <div>{{ property }}</div>
                  <ng-container *ngIf="addressInTitle">
                    <br />
                    <div>{{ value.address | address }}</div>
                  </ng-container>
                </ng-container>
              </div>
              <i></i>
            </div>
          </div>
        </div>
        <span *ngIf="!opened && !disabled && !presentationMode">
          <i class="fa fa-search" (click)="onOpen()" title="Search"></i>
        </span>
        <span *ngIf="opened && !disabled && !presentationMode">
          <i class="fa fa-times" (click)="onClose()" title="Close"></i>
        </span>
        <span *ngIf="!disabled && value && !presentationMode && !noClear">
          <i class="fa fa-trash-o" (click)="onClear()" title="Clear"></i>
        </span>
      </div>
      <ng-container *ngIf="!control">
        <input #model="ngModel" hidden [ngModel]="value" [required]="required" />
        <error-message [control]="model.control" [show]="showErrors"></error-message>
      </ng-container>
      <ng-container *ngIf="control">
        <error-message [control]="control" [show]="showErrors"></error-message>
      </ng-container>
    </div>
  `,
  providers: [SEARCH_ROW_CONTROL_VALUE_ACCESSOR],
})
export class SearchRowComponent implements ControlValueAccessor, OnInit {
  @Input() label: string;
  @Input() labelKey: string;
  @Input() disabled: any;
  @Input() presentationMode = false;
  @Input() noClear = false;
  @Input() showTitle = true;
  @Input() addressInTitle = false; // to show address in tooltip (useful for company)
  @Input() displayedProperty: string;
  /**
   * to be used instead of displayedProperty to show more complex text
   * e.g. to show policyVersion.policyContract.number / policyVersion.policyContract.policyYear
   */
  @Input() displayedTextFunction: (x: any) => string;
  @Input() showLink = false;
  @Input() openSelectorOnValueClick = true;
  @Input() control: AbstractControl;
  @Input() showErrors: boolean;
  @Input() required: boolean;
  @Input() openSelectorEmitter = new Subject<boolean>();

  @Output() valueClicked = new EventEmitter<void>();
  @Output() searchBoxClear = new EventEmitter<boolean>();

  opened = false;
  value: any;
  private onChangeListeners: Function;
  private onTouchedListeners: Function;

  constructor() {}

  ngOnInit() {
    if (this.openSelectorEmitter) {
      this.openSelectorEmitter.subscribe((event: boolean) => {
        this.opened = event;
      });
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onClear() {
    if (!this.disabled) {
      this.value = undefined;
      this.onChangeListeners(this.value);
      this.searchBoxClear.emit(true);
    }
  }

  onValueClick() {
    this.valueClicked.emit();
    if (this.openSelectorOnValueClick) {
      this.onOpen();
    }
  }

  onOpen() {
    if (!this.disabled && !this.presentationMode) {
      this.openSelectorEmitter.next(true);
      this.opened = true;
    }
  }

  onClose() {
    this.openSelectorEmitter.next(false);
    this.opened = false;
  }

  get property(): string {
    if (this.displayedProperty) {
      const f = new Function('obj', 'return obj.' + this.displayedProperty);
      return f(this.value);
    }
    if (this.displayedTextFunction) {
      return this.displayedTextFunction(this.value);
    }
    return '';
  }
}
