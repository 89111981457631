import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {BinaryDownloaderService} from './binary-downloader.service';
import {Cacheable} from 'ts-cacheable';
import {map} from 'rxjs/operators';
import {DictionaryDto, DocumentDescriptionDto, RepositoryURLDto} from '../model';
import {HealthCheckResultDto} from '../model/repo/repo-types';

@Injectable()
export class RepositoryService extends AbstractService {
  protected url = this.urlPrefix + 'repository';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private _binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getRepositoryDocument(repositoryId: string, outputFileName: string) {
    console.log('get document: repositoryId = ' + repositoryId);
    repositoryId = repositoryId.replace(';', '%3B');
    this._binaryDownloader.download(
      'GET',
      this.url + '/' + repositoryId,
      'application/octet-stream',
      'application/octet-stream',
      outputFileName
    );
  }

  deleteRepositoryDocument(docDescription: DocumentDescriptionDto) {
    return this.delete(this.url + '/' + docDescription.repositoryId).subscribe((o) => true);
  }

  // TODO: Create portal-alfresco.service and move this method there
  getRepositoryDocumentFromPortal(
    businessObjectTypeId: number,
    businessObjectId: number,
    repositoryId: string,
    outputFileName: string
  ) {
    repositoryId = repositoryId.replace(';', '%3B');
    console.log(
      'get document: repositoryId = ' +
        repositoryId +
        '; businessObjectTypeId = ' +
        businessObjectTypeId +
        '; businessObjectId = ' +
        businessObjectId
    );
    this._binaryDownloader.download(
      'GET',
      this.url + '/' + businessObjectTypeId + '/' + businessObjectId + '/' + repositoryId,
      'application/octet-stream',
      'application/octet-stream',
      outputFileName
    );
  }

  getDocumentDescriptionDto(repositoryId: string): Observable<DocumentDescriptionDto> {
    return this.get<DocumentDescriptionDto>(this.url + '/dto/' + repositoryId);
  }

  uploadLogo(companyId: number, fileType: string, length: number, content: Blob): Observable<DocumentDescriptionDto> {
    if (fileType === undefined || fileType === null || fileType.length === 0) {
      fileType = 'application/octet-stream';
    }
    fileType = fileType.replace('/', '%2F');
    return this.postBinary<DocumentDescriptionDto>(
      content,
      this.url + '/uploadLogo' + '?companyId=' + companyId + '&fileType=' + fileType + '&length=' + length
    );
  }

  uploadBanner(fileType: string, length: number, content: Blob): Observable<DocumentDescriptionDto> {
    if (fileType === undefined || fileType === null || fileType.length === 0) {
      fileType = 'application/octet-stream';
    }
    fileType = fileType.replace('/', '%2F');
    return this.postBinary<DocumentDescriptionDto>(
      content,
      this.url + '/uploadBanner' + '?fileType=' + fileType + '&length=' + length
    );
  }

  upload(
    businessObjectId: number,
    fileName: string,
    fileType: string,
    length: number,
    businessObjectTypeId: number,
    documentTypeId: number,
    content: Blob,
    documentCategoryId?: number
  ): Observable<DocumentDescriptionDto> {
    const params = this.prepareParams(
      businessObjectId,
      fileName,
      fileType,
      length,
      businessObjectTypeId,
      documentTypeId,
      content,
      documentCategoryId
    );
    return this.postBinary<DocumentDescriptionDto>(content, this.buildUrl(this.url, 'upload', params));
  }

  proposals(
    businessObjectId: number,
    fileName: string,
    fileType: string,
    length: number,
    businessObjectTypeId: number,
    documentTypeId: number,
    content: Blob,
    documentCategoryId?: number
  ): Observable<DocumentDescriptionDto[]> {
    const params = this.prepareParams(
      businessObjectId,
      fileName,
      fileType,
      length,
      businessObjectTypeId,
      documentTypeId,
      content,
      documentCategoryId
    );
    return this.postBinary<DocumentDescriptionDto[]>(content, this.buildUrl(this.url, 'proposals', params));
  }

  wordings(
    businessObjectId: number,
    fileName: string,
    fileType: string,
    length: number,
    businessObjectTypeId: number,
    documentTypeId: number,
    content: Blob,
    documentCategoryId?: number
  ): Observable<DocumentDescriptionDto[]> {
    const params = this.prepareParams(
      businessObjectId,
      fileName,
      fileType,
      length,
      businessObjectTypeId,
      documentTypeId,
      content,
      documentCategoryId
    );
    return this.postBinary<DocumentDescriptionDto[]>(content, this.buildUrl(this.url, 'wordings', params));
  }

  private prepareParams(
    businessObjectId: number,
    fileName: string,
    fileType: string,
    length: number,
    businessObjectTypeId: number,
    documentTypeId: number,
    content: Blob,
    documentCategoryId?: number
  ): UrlParams {
    if (fileType === undefined || fileType === null || fileType.length === 0) {
      fileType = 'application/octet-stream';
    }
    fileType = fileType.replace('/', '%2F');
    return UrlParams.new()
      .add('businessObjectId', businessObjectId)
      .add('fileName', encodeURIComponent(fileName))
      .add('fileType', encodeURIComponent(fileType))
      .add('length', length)
      .add('businessObjectTypeId', businessObjectTypeId)
      .add('documentTypeId', documentTypeId)
      .add('businessObjectId', businessObjectId)
      .addIf(!!documentCategoryId, 'documentCategoryId', () => documentCategoryId);
  }

  @Cacheable()
  documentTypes(): Observable<Map<number, string>> {
    return this.get<Map<number, string>>(this.url + '/documentTypes');
  }

  documentTypeName(businessObjectTypeId: number): Observable<string> {
    return this.documentTypes().pipe(map((types) => types[businessObjectTypeId]));
  }

  getRepoDocumentTypes(businessObjectTypeId: number, dictionaryName: string): Observable<DictionaryDto[]> {
    return this.get<DictionaryDto[]>(
      this.url + '/repoDocumentTypes/' + dictionaryName + '?boTypeId=' + businessObjectTypeId
    );
  }

  getHealthPage(curl: string): Observable<HealthCheckResultDto> {
    console.log('Health check url: ' + this.url + '/healthCheck');
    return this.post1<RepositoryURLDto, HealthCheckResultDto>(
      <RepositoryURLDto>{callUrl: curl},
      this.url + '/healthCheck'
    );
  }
}
