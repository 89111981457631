/**
 * Created by siminski on 13.07.2016.
 */
import {Component, OnDestroy} from '@angular/core';
import {PasteFromExcelService} from './services/paste-from-excel.service';
import {TableComponent} from './table.component';
import {StringUtils} from '../../utils';
import {UntypedFormBuilder} from '@angular/forms';
import {FormatService} from '../../services';
import {TranslateService} from '@ngx-translate/core';
import {SearchResult} from '../../model';

@Component({
  selector: 'a-table',
  templateUrl: 'table.component.html',
  providers: [PasteFromExcelService],
})
export class ATableComponent<T> extends TableComponent<T> implements OnDestroy {
  private newRow = false;
  // Used to rollback edition
  private rowData = {};

  constructor(
    fb: UntypedFormBuilder,
    pasteFromExcelService: PasteFromExcelService<T>,
    formatService: FormatService,
    translateService: TranslateService
  ) {
    super(fb, pasteFromExcelService, formatService, translateService);
  }

  ngOnDestroy() {
    if (this.formModel) {
      this.formModel.removeControl(this.groupName);
    }
  }

  protected setItemsCallback(items: T[]) {
    this.rowInEdition = false;
    this.newRow = false;
  }

  protected initDictionary(column) {
    column.dictionaryReady.subscribe(() => this.updateHiddenDictItems());
  }

  startEdition(item: T) {
    if (!this.rowInEdition && this.editable) {
      this.rowInEdition = true;
      super.startEdition(item);
    }
  }

  add(item: T) {
    this.rowData = {};
    this.newRow = true;
  }

  onRemoveItem(item: T) {
    console.log('Removing Invoice Item: ', item, ' from position ', this.getItemIndex(item));
    this.items.splice(this.getItemIndex(item), 1);
    this.selectedItem = undefined;
  }

  canSelectRow(item: T): boolean {
    return !this.rowInEdition && (!this.editable || this.isRowEditable(item));
  }

  delete(item: T) {
    if (!this.rowInEdition) {
      if (!(this.rowOnDeleteCallback && this.rowOnDeleteCallback(item, this.items))) {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i] === item) {
            this.items.splice(i, 1);
            break;
          }
        }
      }
      this.updateHiddenDictItems();
    }
  }

  getRowData(item: T): any {
    return this.rowData;
  }

  isRowInEdition(item?: T): boolean {
    return (
      super.isRowInEdition(item) ||
      (this.editable && this.rowInEdition && (item ? this.selectedItem === item : true) && this.isRowEditable(item))
    );
  }

  isDeleteButtonDisabled(item: T): boolean {
    return this.isRowInEdition() || this.isRowDeletionDisabled(item);
  }

  onCancel(item: T) {
    if (this.newRow) {
      this.restoreData(item, true);
      this._items.pop();
      this.itemsCountChange.emit(this._items.length);
    } else {
      this.restoreData(item, false);
    }
    this.rowInEdition = false;
    this.newRow = false;
    this.cancel.emit();
  }

  onDone(item: T) {
    if (this.formModel) {
      const rowGroup = this.getRowControlGroup(item);
      rowGroup.updateValueAndValidity();
      if (rowGroup.valid) {
        this.rowInEdition = false;
        this.newRow = false;
        this.showErrors = false;
      } else {
        StringUtils.logFormInvalidFields(rowGroup);
        this.showErrors = true;
      }
    } else {
      this.rowInEdition = false;
      this.newRow = false;
    }
    // The container for newly added object already exists (was created in addItem method, due to lack of ngModel mapping
    // for dictionaries lists the object is added to collectin manually here, the container is already there for it.
    if (this.addDictRow !== null) {
      this.items[this.items.length - 1] = this.newDictRow;
    }

    if (!this.rowInEdition) {
      this.updateHiddenDictItems();
    }
    this.updateModelFromTemplate();
    this.addUpdateDelete.emit(item);
    this.done.emit(item);
  }

  protected removeRowControlGroup(item: T) {
    if (!this.formModel) {
      return;
    }

    let prevControl = null;
    const itemIndex = this.getItemIndex(item);
    for (let i = this._items.length - 1; i >= itemIndex; i--) {
      const rowNo = '' + i;
      const control = this.form.get(rowNo);
      this.form.removeControl(rowNo);
      if (prevControl != null) {
        this.form.addControl(rowNo, prevControl);
      }
      prevControl = control;
    }

    this.form.updateValueAndValidity();
    this.form.markAsDirty();
  }

  handleSearchResult(searchResult: SearchResult<T>) {
    this.items = searchResult.result;
  }
}
