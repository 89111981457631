import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InvoicePaymentSearchComponent} from './invoice-payment-search.component';
import {InvoicePaymentGuiService} from './services/invoice-payment-gui.service';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
  declarations: [InvoicePaymentSearchComponent],
  exports: [],
  providers: [InvoicePaymentGuiService],
})
export class InvoicePaymentModule {}
