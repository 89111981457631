import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

import {PropertyListComponent} from './property-list.component';

@NgModule({
  imports: [RouterModule, CommonModule],
  declarations: [PropertyListComponent],
  exports: [PropertyListComponent],
})
export class PropertyListModule {}
