import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {PolicyLimitListDetailsComponent} from './components/policylimitlist-details.component';
import {PolicyLimitListSearchComponent} from './components/policylimitlist-search.component';
import {PolicyLimitListGuiService} from './services/policylimitlist-gui.service';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {PolicyContractSharedModule} from '../policy/components/shared/policy-contract-shared.module';
import {CustomFormModule} from '../../common/custom-form/custom-form.module';
import {PolicyLimitListSharedModule} from './shared/policy-limit-list-shared.module';
import {CompanyExtendedSelectorModule} from '../company/components/shared/extended-selectors/company-extended-selector.module';
import {PolicyLimitListDetailsGuard, PolicyLimitListSearchGuard} from './_guards';
import {PolicyLimitListListComponent} from './components/policylimitlist-list.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    CompanySharedModule,
    PolicyLimitListSharedModule,
    PolicyContractSharedModule,
    ReactiveFormsModule,
    CompanyExtendedSelectorModule,
    CustomFormModule,
  ],
  exports: [PolicyLimitListListComponent],
  declarations: [PolicyLimitListDetailsComponent, PolicyLimitListSearchComponent],
  providers: [PolicyLimitListGuiService, PolicyLimitListDetailsGuard, PolicyLimitListSearchGuard],
})
export class PolicyLimitListModule {}
