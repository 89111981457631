import {Injectable} from '@angular/core';
import {AbstractService, AppConfigService, LoggedUserService} from '../../../bonding_shared/services';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BinaryDownloaderService} from '../../../bonding_shared';

@Injectable()
export class MigrationRejectService extends AbstractService {
  protected url = this.urlPrefix + 'batch/migration';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getSuccessCount(id: number): Observable<number> {
    return this.get<number>(this.url + '/successCount/' + id);
  }

  getRejectCount(id: number): Observable<number> {
    return this.get<number>(this.url + '/rejectCount/' + id);
  }

  getRejectXls(id: number, jobName: string) {
    this.binaryDownloader.download(
      'GET',
      this.url + '/migrationRejects_' + id + '.xls',
      'application/json',
      'application/octet-stream',
      jobName + '_rejects_' + id + '.xlsx'
    );
  }
}
