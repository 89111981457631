import {ChartDataset} from 'chart.js';
import {ChartData} from './chart-data';
import {ChartUtils} from '../chart-utils';
import {TranslateService} from '@ngx-translate/core';

export class ClaimChartData extends ChartData {
  activeClaims: number[];
  newClaims: number[];
  reserve: number[];
  newReserve: number[];

  constructor(private translateService: TranslateService) {
    super();
  }

  colors = [
    {backgroundColor: ChartUtils.COLORS[2]},
    {backgroundColor: ChartUtils.COLORS[0]},
    {backgroundColor: ChartUtils.COLORS[1]},
    {backgroundColor: ChartUtils.COLORS[3]},
  ];

  buildChartData(): ChartDataset[] {
    return [
      {
        data: this.activeClaims,
        label: this.translateService.instant('claimsDashboard.activeClaims'),
        type: 'line',
        yAxisID: 'yAxisLeft',
        fill: false,
        borderColor: ChartUtils.COLORS[2],
        pointBorderColor: ChartUtils.COLORS[2],
        pointBackgroundColor: ChartUtils.COLORS[2],
      },
      {data: this.newClaims, label: this.translateService.instant('claimsDashboard.newClaims'), yAxisID: 'yAxisLeft'},
      {data: this.reserve, label: this.translateService.instant('claimsDashboard.reserve'), yAxisID: 'yAxisRight'},
      {
        data: this.newReserve,
        label: this.translateService.instant('claimsDashboard.newReserve'),
        yAxisID: 'yAxisRight',
      },
    ];
  }

  clear() {
    this.activeClaims = [];
    this.newClaims = [];
    this.reserve = [];
    this.newReserve = [];
    this.dates = [];
  }

  mapData(results: Array<any>) {
    if (!results) {
      return;
    }
    results.forEach((result) => {
      this.dates.push(result.month + '-' + result.year);
      this.activeClaims.push(result.activeClaims ? result.activeClaims : 0);
      this.newClaims.push(result.newClaims ? result.newClaims : 0);
      this.reserve.push(result.reserve ? result.reserve : 0);
      this.newReserve.push(result.newReserve ? result.newReserve : 0);
    });
  }
}
