import {Component} from '@angular/core';
import {BlockchainService} from './services/blockchain.service';
import {GrowlService, LoggedUserService} from '../../bonding_shared/services';
import {BlockchainRequestDto} from '../../bonding_shared/model';

declare var TMiningWallet: any;

@Component({
  selector: 'block-chain',
  templateUrl: './blockchain.component.pug',
  providers: [BlockchainService],
})
export class BlockchainComponent {
  constructor(
    private blockchainService: BlockchainService,
    private growlService: GrowlService,
    public loggedUserService: LoggedUserService
  ) {}

  public reference: string;
  inProgress = false;
  walletError = false;

  private activateAccount(jwt: string) {
    this.walletError = false;
    TMiningWallet.API.requestActivation(jwt).then(
      (resp) => {
        const req = <BlockchainRequestDto>{
          address: resp.result.address,
        };
        this.blockchainService.createIdentity(req).subscribe(
          (resp2) => {
            if (resp2.success) {
              this.confirmIdentityCreation(jwt);
            } else {
              this.growlService.error('Could not create identity');
            }
          },
          (error) => {
            this.inProgress = false;
            this.growlService.error('Could not create identity');
          }
        );
      },
      (error) => {
        this.inProgress = false;
        this.walletError = true;
      }
    );
  }

  private confirmIdentityCreation(jwt: string) {
    TMiningWallet.API.confirmActivation(jwt).then(
      (v) => {
        this.blockchainService.confirmIdentityCreation().subscribe(
          (user) => {
            this.loggedUserService.refreshUserLogged(user);
            this.growlService.notice('Account has been activated!');
            this.inProgress = false;
          },
          (error) => (this.inProgress = false)
        );
      },
      (error) => {
        this.inProgress = false;
        this.walletError = true;
      }
    );
  }

  getTokenAndCallWallet(func: Function, arg?: any) {
    this.inProgress = true;
    this.blockchainService.getJWT().subscribe(
      (jwt) => (arg ? func(jwt, arg) : func(jwt)),
      (error) => {
        this.inProgress = false;
      }
    );
  }
}
