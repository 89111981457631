import {
  BrokerContractIdDto,
  ContractIdDto,
  ContractLinkDto,
  DictionaryBaseDto,
  InvoiceCriteriaDto,
  InvoiceDto,
  PolicyContractIdDto,
  TaxIfc,
} from '../model';
import {
  BusinessObjectType,
  ElementaryRight,
  FinancialDocumentCategory,
  FINoteCategory,
  InvoiceCategory,
} from '../model/dictionary-ids';

export class InvoiceUtils {
  private static readonly READ_RIGHTS_NEEDED = new Map([
    [<FinancialDocumentCategory>InvoiceCategory.INVOICE, [ElementaryRight.CLIENT_INVOICE_READ]],
    [<FinancialDocumentCategory>InvoiceCategory.BROKER, [ElementaryRight.INTERMEDIARY_INVOICE_READ]],
    [
      <FinancialDocumentCategory>InvoiceCategory.INTEREST,
      [ElementaryRight.CLIENT_INVOICE_READ, ElementaryRight.ACCESS_MODULE_INTEREST],
    ],
    [<FinancialDocumentCategory>FINoteCategory.INTERMEDIARY_INVOICE, [ElementaryRight.INTERMEDIARY_INVOICE_READ]],
    [<FinancialDocumentCategory>FINoteCategory.REINSURANCE, []],
  ]);

  static equalsTaxRate(tax1: TaxIfc, tax2: TaxIfc): boolean {
    if (tax1 == null && tax2 == null) {
      return true;
    }
    if (tax1 == null || tax2 == null) {
      return false;
    }
    return tax1.taxRateValue === tax2.taxRateValue && tax1.taxNotApplicable === tax2.taxNotApplicable;
  }

  static equalsTaxRates(taxes1: {[index: string]: TaxIfc}, taxes2: {[index: string]: TaxIfc}): boolean {
    if (Object.values(taxes1).length !== Object.values(taxes2).length) {
      return false;
    }
    for (const tax1 of Object.values(taxes1)) {
      const tax2 = taxes2[tax1.taxCategory.id];
      if (!InvoiceUtils.equalsTaxRate(tax1, tax2)) {
        return false;
      }
    }
    return true;
  }

  static getInvoiceStatusRegexp(categoryId: FinancialDocumentCategory) {
    return categoryId === InvoiceCategory.INTEREST ? undefined : /^(?!WRITTEN_OFF).*$/;
  }

  static getInvoiceItemStatusRegexp(categoryId: FinancialDocumentCategory) {
    return categoryId === InvoiceCategory.BROKER ? undefined : /^(?!WRITTEN_OFF).*$/;
  }

  static getNewInvoiceItemStatusRegexp() {
    return /^(MAN).*$/;
  }

  static getReadRightsNeeded(categoryId: FinancialDocumentCategory): ElementaryRight[] {
    return this.READ_RIGHTS_NEEDED.get(categoryId);
  }

  static getInstallmentsCountStr(invoice: InvoiceDto) {
    const ofLabel = '/';
    return Array.from(
      new Set(
        invoice.invoicePositions
          .map((position) => position.invoiceItems)
          .reduce((items1, items2) => items1.concat(items2), [])
          .filter((item) => item.installment || item.installmentsCount)
          .map((item) => item.installment + ofLabel + item.installmentsCount)
      )
    ).join('; ');
  }

  public static fillContractLinkCriteria(
    criteria: InvoiceCriteriaDto,
    businessObjectTypeId: number,
    businessObjectId: number
  ) {
    criteria.contractLink = <ContractLinkDto>{
      policyContract: <PolicyContractIdDto>{},
      contract: <ContractIdDto>{},
      brokerContract: <BrokerContractIdDto>{},
    };

    if (String(businessObjectTypeId) === String(BusinessObjectType.POLICY)) {
      criteria.contractLink.policyContract.id = businessObjectId;
      criteria.contractLink.linkType = <DictionaryBaseDto>{id: businessObjectTypeId};
    } else if (String(businessObjectTypeId) === String(BusinessObjectType.CONTRACT)) {
      criteria.contractLink.contract = <ContractIdDto>{id: businessObjectId};
      criteria.contractLink.linkType = <DictionaryBaseDto>{id: businessObjectTypeId};
    } else if (String(businessObjectTypeId) === String(BusinessObjectType.BROKER_CONTRACT)) {
      criteria.contractLink.brokerContract.id = businessObjectId;
      criteria.contractLink.linkType = <DictionaryBaseDto>{id: businessObjectTypeId};
    } else {
      criteria.contractLink.linkType = undefined;
      criteria.contractLink.policyContract.id = undefined;
      criteria.contractLink.contract.id = undefined;
      criteria.contractLink.brokerContract.id = undefined;
    }
    criteria.contractLink.policyContract.policyYear = undefined;
    criteria.contractLink.policyContract.masterPolicyId = undefined;
  }
}
