import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrokerContractSearchComponent} from './broker-contract-search.component';
import {BrokerContractDetailsComponent} from './broker-contract-details.component';
import {BrokerContractGuiService} from './services/broker-contract-gui.service';
import {ContractSharedModule} from '../bonding-contract/contract/components/shared/contract-shared.module';
import {PolicyContractSharedModule} from '../policy/components/shared/policy-contract-shared.module';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {BrokerContractPreviewComponent} from './broker-contract-preview.component';
import {BrokerContractRelationDetailsComponent} from './broker-contract-relation-details.component';
import {BrokerContractSharedModule} from './components/shared/broker-contract-shared.module';
import {InquirySharedModule} from '../inquiry/shared/inquiry-shared.module';
import {BrokerContractRepresentativeDetailsComponent} from './broker-contract-representative-details.component';
import {BrokerContractAccessGuard} from './broker-contract-access-guard';
import {BrokerContractRelationAccessGuard} from './broker-contract-relation-access-guard';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    ContractSharedModule,
    PolicyContractSharedModule,
    BrokerContractSharedModule,
    InquirySharedModule,
  ],
  declarations: [
    BrokerContractSearchComponent,
    BrokerContractDetailsComponent,
    BrokerContractPreviewComponent,
    BrokerContractRelationDetailsComponent,
    BrokerContractRepresentativeDetailsComponent,
  ],
  exports: [],
  providers: [BrokerContractGuiService, BrokerContractAccessGuard, BrokerContractRelationAccessGuard],
})
export class BrokerContractModule {}
