import {Injectable} from '@angular/core';
import {
  PolicyDrawdownRepaymentScheduleVersionBaseDto,
  PolicyDrawdownRepaymentScheduleVersionCriteriaDto,
  PolicyDrawdownRepaymentScheduleVersionDto,
  SearchCriteria,
  StateTransitionDto,
} from '../model';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {map} from 'rxjs/operators';

@Injectable()
export class PolicyDrawdownRepaymentScheduleVersionService extends AbstractService {
  protected url = this.urlPrefix + 'masterPolicyContract/drawdowns/repaymentScheduleVersion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  findPolicyDrawdownRepaymentScheduleVersions(policyDrawdownRepaymentScheduleId: number) {
    const crit = <SearchCriteria<PolicyDrawdownRepaymentScheduleVersionCriteriaDto>>{
      criteria: {parent: {id: policyDrawdownRepaymentScheduleId}},
    };
    return this.searchByCriteria(crit).pipe(map((x) => <PolicyDrawdownRepaymentScheduleVersionBaseDto[]>x.result));
  }

  findLastDrawdownRepaymentScheduleVersionByMasterPolicyId(
    masterPolicyId: number
  ): Observable<PolicyDrawdownRepaymentScheduleVersionDto> {
    return this.get(this.url + '?masterPolicyId=' + masterPolicyId);
  }

  getLastOrInitVersion(masterPolicyId: number): Observable<PolicyDrawdownRepaymentScheduleVersionDto> {
    return this.get<PolicyDrawdownRepaymentScheduleVersionDto>(
      this.url + '/getLastOrInitVersion?masterPolicyId=' + masterPolicyId
    );
  }

  getTransitions(scheduleVersion: PolicyDrawdownRepaymentScheduleVersionDto): Observable<Array<StateTransitionDto>> {
    return this.get<StateTransitionDto[]>(this.url + '/transition/' + scheduleVersion.id);
  }
}
