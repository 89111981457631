import {Component, OnInit} from '@angular/core';
import {
  DictionaryBaseDto,
  DictionaryDto,
  LegalInsolvencyDto,
  LegalInsolvencyVersionDto,
} from '../../bonding_shared/model';
import {ActivatedRoute} from '@angular/router';
import {
  AppConfigService,
  CompanyService,
  DictionaryBaseService,
  DictionaryService,
  GrowlService,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared/services';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {CompanyLegalInsolvencyService} from '../../bonding_shared/services/company-legal-insolvency.service';
import {CompanyElementaryRight} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'company-legal-insolvency',
  templateUrl: './company-legal-insolvency-details.component.pug',
})
export class CompanyLegalInsolvencyDetailsComponent extends DetailsView implements OnInit {
  companyId: number;
  legalInsolvency: LegalInsolvencyDto;
  legalInsolvencyVersion: LegalInsolvencyVersionDto;
  statuses: DictionaryDto[];
  readOnly = false;

  readonly CompanyElementaryRight = CompanyElementaryRight;

  constructor(
    private route: ActivatedRoute,
    protected insolvencyService: CompanyLegalInsolvencyService,
    protected companyService: CompanyService,
    public appService: AppConfigService,
    public router: RouterService,
    protected growlService: GrowlService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected dictionaryService: DictionaryService,
    protected loggedUserService: LoggedUserService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.newVersionButton.hidden = true;
  }

  ngOnInit(): void {
    this.commonInit<LegalInsolvencyViewParams>(this.route);
    this.dictionaryService.getDictionary('LegalInsolvencyStatus').subscribe((s) => (this.statuses = s));
  }

  initViewWithId(id: number) {
    this.insolvencyService.getById<LegalInsolvencyVersionDto>(id).subscribe((v) => {
      this.legalInsolvencyVersion = v;
      this.companyId = v.legalInsolvency.company.id;
      this.loadVersions();
      this.readOnly = true;
      this.handleButtons();
    });
  }

  initViewWithCustomParams(q: LegalInsolvencyViewParams) {
    this.companyId = +q.companyId;
    this.readOnly = true;
    this.handleButtons();
    this.insolvencyService.getLastVersionByCompanyId(this.companyId).subscribe((ins) => {
      if (ins) {
        this.legalInsolvencyVersion = ins;
        this.initViewWithId(this.legalInsolvencyVersion.id);
      }
    });
  }

  loadVersions() {
    this.insolvencyService.getLegalInsolvency(this.legalInsolvencyVersion.legalInsolvency.id).subscribe((ins) => {
      this.legalInsolvency = ins;
    });
  }

  onSave(): void {
    this.insolvencyService.save<LegalInsolvencyVersionDto>(this.legalInsolvencyVersion).subscribe(
      (v) => {
        this.legalInsolvencyVersion = v;
        this.loadVersions();
        this.readOnly = true;
        this.handleButtons();
      },
      (error) => this.handleServerError(error)
    );
  }

  handleButtons() {
    this.saveButton.hidden = this.readOnly;
    this.cancelButton.hidden = this.readOnly;
  }

  onCancel() {
    super.onCancel();
    this.readOnly = true;
    this.legalInsolvencyVersion = this.legalInsolvency
      ? <LegalInsolvencyVersionDto>this.legalInsolvency.versions[0]
      : null;
    this.handleButtons();
  }

  onNewVersion(status: DictionaryDto, substatus: DictionaryBaseDto) {
    const newVersionObs = this.legalInsolvency
      ? this.insolvencyService.newVersion<LegalInsolvencyVersionDto>(this.legalInsolvency.id)
      : this.insolvencyService.initialVersion(this.companyId);
    newVersionObs.subscribe((v) => {
      this.legalInsolvencyVersion = v;
      this.legalInsolvencyVersion.status = status;
      this.legalInsolvencyVersion.substatus = substatus;
      this.readOnly = false;
      this.handleButtons();
    });
  }

  onSelectVersion(version: LegalInsolvencyVersionDto) {
    if (this.legalInsolvencyVersion && this.legalInsolvencyVersion.id) {
      this.legalInsolvencyVersion = version;
    }
  }
}

export class LegalInsolvencyViewParams {
  // string when reading
  companyId?: string | number;
}
