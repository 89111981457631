import {Component} from '@angular/core';
import {DashboardService} from '../../../../bonding_shared/services/dashboard.service';
import {ChartRestLoader} from '../chart-rest-loader';
import {BusinessPropertiesService} from '../../../../bonding_shared/services/business-properties.service';
import {ApplicationModule} from '../../../../bonding_shared/model/dictionary-ids';
import {VMap} from './vmap';
import {SettingsService} from '../../../../bonding_shared/services';
import {StringUtils} from '../../../../bonding_shared/utils';

@Component({
  selector: 'limit-map',
  template: `<div id="{{mapType}}"  style="height: 400px;"></div>`
})
export class LimitMapComponent extends VMap implements ChartRestLoader {

  constructor(public service: DashboardService, public businessProps: BusinessPropertiesService, public settingsService: SettingsService) {
    super(service, businessProps, settingsService);
    super.applicationModuleId = ApplicationModule.LIMIT;
  }

  onLabelShowFunction(countryData: LimitCountryPerformance, currency: string): (event: any, label: any, code: string) => void {
    function f(event: any, label: any, code: string): void {
      const countryId = label[0].firstChild.textContent?.toLowerCase().replaceAll(' ', '_').replaceAll('\'', '').replaceAll('-', '_');
      console.log(countryId);
      label.html(
        '<div class="jqvmap-label-inner">' +
        ' <div class="jqvmap-label-header" style="display: flex; align-items: center;">' +
        '  <div id="' + countryId + '" class="jqvmap-label-header-flag"></div>' +
        '  <h2>' + ((label[0].firstChild.textContent) ? label[0].firstChild.textContent : label[0].innerHTML) + '</h2>' +
        ' </div>' +
        ' <p>OECD rating: <b>' + (countryData[code]?.oecdRating ?? '-') + '</b></p>' +
        ' <p>Limit exposure: <b>' +
        StringUtils.formatNumber(countryData[code] && countryData[code].exposure ? countryData[code].exposure : 0) + '&nbsp;' + currency + '</b></p>' +
        ' <p>Limit exposure percent: <b>' +
        (countryData[code] && countryData[code].exposurePercent ? countryData[code].exposurePercent : 0) + '</b></p>' +
        ' <p>Limit count: <b>' + (countryData[code] && countryData[code].limitNumber ? countryData[code].limitNumber : 0) + '</b></p>' +
        ' <p>Limits percent: <b>' + (countryData[code] && countryData[code].limitNumberPercent ? countryData[code].limitNumberPercent : 0) + '</b></p>' +
        '</div>'
      );
    }
    return f;
  }

  saveRestResult(result: any) {
    const code: string = result.country.code.toLowerCase();
    this.countryPerformance[code] = {
      exposure: result.exposure,
      exposurePercent: result.exposurePercent,
      limitNumber: result.limitNumber,
      limitNumberPercent: result.limitNumberPercent,
      oecdRating: result.oecdRating.name
    };
  }

  getPerformanceValues (policyCountryPerformance: LimitCountryPerformance): {[key: string]: number} {
    const values: {[key: string]: number} = {};
    for (const k in policyCountryPerformance) {
      if (k) {
        values[k] = policyCountryPerformance[k].exposure;
      }
    }
    return values;
  }
}

export class LimitCountryPerformance {
  [code: string]: {
    exposure: number;
    exposurePercent: number;
    limitNumber: number;
    limitNumberPercent: number;
    oecdRating: string;
  };
}
