/**
 * Created by awilk on 27.10.2016.
 *
 * Generic data provider for a-table.
 * Performs search by text or by criteria
 *
 * See usage in company-search
 *
 */
import {EMPTY, Observable} from 'rxjs';
import {catchError, expand, reduce, share} from 'rxjs/operators';
import {Page, SearchCriteria, SearchResult, SortBy} from '../model';
import {DataProvider} from '../components/aku-table/data-provider';
import {AbstractService} from './abstract.service';
import {TextSearchCriteria} from '../components/search/model/text-search-criteria';

export class SearchDataProvider<C, R> implements DataProvider<R> {
  inProgress = false;
  searchCriteria: SearchCriteria<C> = <SearchCriteria<C>>{};
  textSearchCriteria: TextSearchCriteria = new TextSearchCriteria();
  textSearch = false;
  additionalQueryParams: {key: string; val: string}[];
  lastSearchResult: SearchResult<R>;

  constructor(private service: AbstractService) {}

  clone(): SearchDataProvider<C, R> {
    const clone = new SearchDataProvider<C, R>(this.getService());
    clone.service = this.service;
    clone.searchCriteria = JSON.parse(JSON.stringify(this.searchCriteria), this.service.dateReviver);
    clone.textSearchCriteria = JSON.parse(JSON.stringify(this.textSearchCriteria), this.service.dateReviver);
    clone.textSearch = this.textSearch;
    clone.additionalQueryParams = this.additionalQueryParams;
    return clone;
  }

  copyData(origin: SearchDataProvider<C, R>) {
    this.service = origin.service;
    this.searchCriteria = JSON.parse(JSON.stringify(origin.searchCriteria), this.service.dateReviver);
    this.textSearchCriteria = JSON.parse(JSON.stringify(origin.textSearchCriteria), this.service.dateReviver);
    this.textSearch = origin.textSearch;
    this.additionalQueryParams = origin.additionalQueryParams;
  }

  search(page?: Page, sortBy?: SortBy): Observable<SearchResult<R>> {
    let obs$: Observable<SearchResult<R>>;
    if (this.textSearch) {
      obs$ = this.service.searchByTextCriteria<R>(this.textSearchCriteria, page);
    } else {
      this.searchCriteria.page = page;
      this.searchCriteria.sortBy = sortBy;
      obs$ = this.service.searchByCriteria<C, R>(this.searchCriteria, this.additionalQueryParams);
    }
    obs$ = obs$.pipe(share());
    obs$.subscribe((result) => (this.lastSearchResult = result));
    return obs$;
  }

  /**
   * Executes search many times to retrieve all the data without exception from backend.
   */
  searchAllInLoop(sortBy?: SortBy): Observable<SearchResult<R>> {
    const startPage = <Page>{
      count: AbstractService.MAX_COUNT - 1,
      start: 0,
    };

    return Observable.create((observer) => {
      this.search(startPage, sortBy)
        .pipe(
          expand((searchResult, index) => {
            const nextPage = <Page>{
              count: AbstractService.MAX_COUNT - 1,
              start: (index + 1) * (AbstractService.MAX_COUNT - 1),
            };
            if (searchResult.size >= nextPage.start && searchResult.result.length > 0) {
              return this.search(nextPage, sortBy);
            } else {
              return EMPTY;
            }
          }),
          reduce(
            (accumulator, searchResult) => {
              accumulator.result = accumulator.result.concat(searchResult.result);
              accumulator.size = searchResult.size;
              return accumulator;
            },
            <SearchResult<R>>{
              result: [],
            }
          ),
          catchError((error) => observer.error(error))
        )
        .subscribe((searchResult) => {
          observer.next(searchResult);
          observer.complete();
        });
    });
  }

  switchSearchMode() {
    this.textSearch = !this.textSearch;
  }

  getService() {
    return this.service;
  }

  setService(service: AbstractService) {
    this.service = service;
  }
}
