import {Injectable} from '@angular/core';
import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {
  DictionaryDto,
  EmailResponseDto,
  ErrorReason,
  IdDto,
  LimitBaseDto,
  LimitCoverPlusDto,
  LimitDecisionDto,
  LimitDto,
  LimitExposureDto,
  LimitMassDecisionDto,
  LimitRequestCriteriaDto,
  LimitRequestDto,
  LimitRequestReductionDto,
  LimitSimpleDto,
} from '../model/dtos';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BinaryDownloaderService} from './binary-downloader.service';

@Injectable()
export class LimitService extends AbstractService {
  private limitDecisionUrl = this.urlPrefix + 'limitDecision'; // not used in portal

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'limitRequest';
  }

  getLimitRequest(limitRequestId: number): Observable<LimitRequestDto> {
    return this.get<LimitRequestDto>(this.url + '/' + limitRequestId);
  }

  getLastLimitRequestByLimitId(limitId: number): Observable<IdDto> {
    return this.get<IdDto>(this.url + '/lastRequest/' + limitId);
  }

  getPossibleDecisionTypes(limitRequestId: number): Observable<DictionaryDto[]> {
    return this.get<DictionaryDto[]>(this.url + '/' + limitRequestId + '/decisionTypes');
  }

  getPossibleDecisionAnnotations(limitRequestId: number): Observable<DictionaryDto[]> {
    return this.get<DictionaryDto[]>(this.url + '/' + limitRequestId + '/annotations');
  }

  getLimit(limitId: number) {
    return this.get<LimitDto>(this.url + '/limit/' + limitId);
  }

  getNewLimitDecision(limitRequestId: number, typeId: number): Observable<LimitDecisionDto> {
    const params = UrlParams.new().add('requestId', limitRequestId).add('typeId', typeId);
    return this.get<LimitDecisionDto>(this.buildUrl(this.limitDecisionUrl, 'newDecision', params));
  }

  getNewLimitRequest(limitId: number, typeId: number): Observable<LimitRequestDto> {
    return this.get<LimitRequestDto>(this.url + '/limit/' + limitId + '/newRequest/' + typeId);
  }

  getLimitDecisionProlongation(limitRequestId: number): Observable<LimitDecisionDto> {
    return this.get<LimitDecisionDto>(this.limitDecisionUrl + '/prolongDecision?requestId=' + limitRequestId);
  }

  existingOrInitialRequest(request: LimitRequestDto): Observable<LimitRequestDto> {
    console.log('existingOrInitial limit request: ', request);
    return this.post<LimitRequestDto>(request, this.url + '/existingOrInitial');
  }

  saveLimitRequest(limit: LimitRequestDto): Observable<LimitRequestDto> {
    if (limit.id && limit.id > 0) {
      return this.updateLimitRequest(limit);
    } else {
      return this.createLimitRequest(limit);
    }
  }

  saveLimitDecision(limit: LimitDecisionDto): Observable<LimitDecisionDto> {
    if (limit.id && limit.id > 0) {
      return this.updateLimitDecision(limit);
    } else {
      return this.createLimitDecision(limit);
    }
  }

  cancelLimitDecision(requestId: number): Observable<LimitDecisionDto> {
    console.log('cancel limit decision for request: ', requestId);
    return this.postEmpty<LimitDecisionDto>(this.url + '/' + requestId + '/decision/cancel');
  }

  cancelLimitRequest(requestId: number): Observable<LimitRequestDto> {
    console.log('cancel limit request: ', requestId);
    return this.postEmpty<LimitRequestDto>(this.url + '/' + requestId + '/cancel');
  }

  changeSubstatus(requestId: number, substatusId: number): Observable<LimitRequestDto> {
    console.log('Change limit request : ', requestId, ' substatus: ', substatusId);
    return this.postEmpty<LimitRequestDto>(this.url + '/changeSubstatus/' + requestId + '/' + substatusId);
  }

  cancelCoverProposal(coverId: number): Observable<LimitCoverPlusDto> {
    console.log('cancel cover proposal: ', coverId);
    return this.postEmpty<LimitCoverPlusDto>(this.limitDecisionUrl + '/cover/' + coverId + '/cancel');
  }

  private createLimitRequest(limit: LimitRequestDto): Observable<LimitRequestDto> {
    console.log('crete limit request:', limit);
    return this.put<LimitRequestDto>(limit, this.url);
  }

  private updateLimitRequest(limit: LimitRequestDto): Observable<LimitRequestDto> {
    console.log('update limit request: ', limit);
    return this.post<LimitRequestDto>(limit, this.url);
  }

  private createLimitDecision(limit: LimitDecisionDto): Observable<LimitDecisionDto> {
    console.log('create limit decision:', limit);
    return this.put<LimitDecisionDto>(limit, this.limitDecisionUrl);
  }

  private updateLimitDecision(limit: LimitDecisionDto): Observable<LimitDecisionDto> {
    console.log('update limit decision: ', limit);
    return this.post<LimitDecisionDto>(limit, this.limitDecisionUrl);
  }

  getCover(coverId: number): Observable<LimitCoverPlusDto> {
    return this.get<LimitCoverPlusDto>(this.url + '/cover/' + coverId);
  }
  decideOnCover(cover: LimitCoverPlusDto): Observable<LimitCoverPlusDto> {
    return this.post<LimitCoverPlusDto>(cover, this.url + '/cover/decide');
  }

  export(
    criteria: LimitRequestCriteriaDto,
    outputFileName: string,
    errorCallback: (errMsg: string) => void,
    completeCallback: (f: File) => void
  ) {
    console.log('export ', criteria);
    this.binaryDownloader.download(
      'POST',
      this.url + '/export/',
      'application/json',
      'application/octet-stream',
      outputFileName,
      criteria,
      errorCallback,
      completeCallback
    );
  }

  executeExportAsync(criteria: LimitRequestCriteriaDto) {
    return this.post<LimitRequestCriteriaDto>(criteria, this.url + '/exportAsync');
  }

  massDecision(decision: LimitMassDecisionDto): Observable<LimitMassDecisionDto> {
    console.log('mass decision:', decision);
    return this.post<LimitMassDecisionDto>(decision, this.url + '/massDecision');
  }

  fireAutoDecision(requestId: number): Observable<LimitRequestDto> {
    return this.postEmpty<LimitRequestDto>(this.url + '/' + requestId + '/fireAutoDecision');
  }

  reduceLimit(req: LimitRequestReductionDto) {
    return this.post1<LimitRequestReductionDto, LimitDecisionDto>(req, this.url + '/reduceLimit');
  }

  exposureForBuyer(buyerId: number): Observable<LimitExposureDto> {
    return this.get<LimitExposureDto>(this.url + '/exposure/buyer/' + buyerId);
  }

  exposureForInsured(insuredId: number): Observable<LimitExposureDto> {
    return this.get<LimitExposureDto>(this.url + '/exposure/insured/' + insuredId);
  }

  sendClientEmails(limitRequests: IdDto[], templateId, buyerId): Observable<EmailResponseDto> {
    let params = new HttpParams();
    params = params.append('buyerId', buyerId + '');
    params = params.append('templateId', templateId + '');
    return this.post1<IdDto[], EmailResponseDto>(limitRequests, this.url + '/sendLimitRelatedEmails', params);
  }

  createNewPolicyTask(limit: LimitSimpleDto, annex: boolean) {
    return this.post<LimitBaseDto>(limit, this.url + '/createNewPolicyTask?annex=' + annex);
  }

  generateLimitListReport(
    outputFileName: string,
    errorCallback: ((errMsg: string) => void) | ((errReasons: ErrorReason[]) => void),
    completeCallback: (file: File) => void
  ) {
    this.binaryDownloader.download(
      'GET',
      this.url + '/generateLimitListReport',
      'application/octet-stream',
      'application/octet-stream',
      outputFileName,
      undefined,
      errorCallback,
      completeCallback
    );
  }
}
