import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {ControlContainer, NgForm} from '@angular/forms';
import {AppConfigService, PolicyContractVersionService} from '../../../../../bonding_shared/services';
import {CompanySimpleDto, RelatedObject} from '../../../../../bonding_shared';
import {RatingScale} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'debtor-buyer-ultimate-user-section',
  templateUrl: './debtor-buyer-ultimate-user-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class DebtorBuyerUltimateUserSectionComponent extends Section {
  readonly RatingScale = RatingScale;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected policyContractVersionService: PolicyContractVersionService
  ) {
    super(policyContractViewService, appService, policyContractVersionService);
    this.selectorNameList = [RelatedObject.DEBTOR, RelatedObject.BUYER, RelatedObject.ULTIMATE_USER];
    this.initializeSelectorEmitters(true);
  }

  onSelectDebtor(company: CompanySimpleDto) {
    this.policy.debtor = company;
  }

  onSelectBuyer(company: CompanySimpleDto) {
    this.policy.buyer = company;
  }

  onSelectUltimateUser(company: CompanySimpleDto) {
    this.policy.ultimateUser = company;
  }
}
