import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import {HolidayDto, SearchResult} from '../model';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Cacheable} from 'ts-cacheable';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {Moment} from 'moment';
import {AbstractControl} from '@angular/forms';
import {Country} from '../model/dictionary-ids';

@Injectable()
export class HolidayService extends AbstractService {
  protected url = this.urlPrefix + 'holiday';

  constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  @Cacheable()
  getAll(): Observable<HolidayDto[]> {
    return this.get(this.url).pipe(map((x) => (<SearchResult<HolidayDto>>x).result));
  }

  async isWorkingDay(date: Moment | Date, countryId: number): Promise<boolean> {
    if ([6, 7].includes(moment(date).isoWeekday())) {
      return false;
    }
    const all = await this.getAll().toPromise();
    return !all.some((x) => x.country.id === countryId && moment(x.bankDate).isSame(date, 'date'));
  }

  // Use for kuke collections , change country to non-static for possible other usages
  async validateNotWorkingDay(control: AbstractControl) {
    return control && control.value && (await this.isWorkingDay(control.value, Country.PL))
      ? undefined
      : {notWorkingDay: true};
  }
}
