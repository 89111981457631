/**
 * Created by wilk on 16.11.2016.
 */
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';

const BOOLEAN_COMBO_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BooleanComboComponent),
  multi: true,
};

@Component({
  selector: 'boolean-combo',
  template: `
    <select
      *ngIf="!presentationMode"
      [(ngModel)]="selectedKey"
      (change)="selectedKey = $event.target.value"
      [disabled]="disabled"
      class="bon-select"
    >
      <option value="undefined" *ngIf="allowUndefined">{{ nullLabel | translate }}</option>
      <option value="true">{{ trueLabel | translate }}</option>
      <option value="false">{{ falseLabel | translate }}</option>
    </select>
    <ng-container *ngIf="presentationMode">
      <span *ngIf="_selectedKey === 'undefined'" class="presentation">{{ nullLabel | translate }}</span>
      <span *ngIf="_selectedKey === 'true'" class="presentation">{{ trueLabel | translate }}</span>
      <span *ngIf="_selectedKey === 'false'" class="presentation">{{ falseLabel | translate }}</span>
    </ng-container>
  `,
  providers: [BOOLEAN_COMBO_CONTROL_VALUE_ACCESSOR],
})
export class BooleanComboComponent implements ControlValueAccessor {
  @Input() nullLabel: String = '';
  @Input() falseLabel: String = 'common.no';
  @Input() trueLabel: String = 'common.yes';
  @Input() presentationMode = false;

  @Input() disabled: any;
  @Input() allowUndefined = true;

  @Output() changeItem = new EventEmitter<boolean>();

  private onChangeModel: Function;
  private onTouchedModel: Function;
  private _selectedKey: string;

  get selectedKey(): boolean {
    return this.toBoolean(this._selectedKey);
  }

  set selectedKey(k: boolean) {
    this._selectedKey = String(k);
    const val = this.toBoolean(this._selectedKey);
    this.onChangeModel(val);
    this.changeItem.emit(val);
  }

  toBoolean(s: string) {
    if (s === 'undefined' && this.allowUndefined) {
      return undefined;
    }
    return s === 'true';
  }

  writeValue(it: boolean): void {
    this._selectedKey = it + '';
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedModel = fn;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
