import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DictionaryBaseDto, PolicyInquiryCountryDto} from '../../../bonding_shared/model';
import {InquiryCountries} from '../domain/inquiry-countries';
import {AbstractControl, ControlContainer, NgForm, UntypedFormGroup} from '@angular/forms';
import {
  ATableComponent,
  DictionaryBaseService,
  DictionaryService,
  IMultiSelectSettings,
  IMultiSelectTexts,
  LoggedUserService,
} from '../../../bonding_shared';
import {InquiryCountryInitialiazerService} from '../services/inquiry-countries-initialiazer.service';

@Component({
  selector: 'planned-sales-geographical',
  templateUrl: 'planned-sales-geographical.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class PlannedSalesGeographicalComponent implements OnInit, OnDestroy {
  self = this;

  @ViewChild('countriesTable') countriesTable: ATableComponent<PolicyInquiryCountryDto>;

  @Input() readonly = false;
  @Input() form: UntypedFormGroup;
  @Input() showErrors = false;

  // @Output() countriesInitiated = new EventEmitter<any>();

  get countriesParent() {
    return this.countryInitialiazerService.countriesParent;
  }

  portalMode = false;

  readonly multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    selectionLimit: 1000,
    numSelectedOff: true,
    showCheckAll: true,
    showUncheckAll: true,
  };
  readonly marketSelectTexts: IMultiSelectTexts = {defaultTitle: 'policyInquiry.details.countries.addMarket'};
  readonly notMarketSelectTexts: IMultiSelectTexts = {defaultTitle: 'policyInquiry.details.countries.addNotMarket'};

  constructor(
    protected dictionaryBaseService: DictionaryBaseService,
    protected loggedUserService: LoggedUserService,
    protected dictionaryService: DictionaryService,
    private countryInitialiazerService: InquiryCountryInitialiazerService
  ) {
    this.portalMode = this.loggedUserService.portal;
  }

  private readonly countriesSumZeroValidator = (_: AbstractControl) => {
    return !this.isVisible() || this.countriesSum() > 0 ? null : {countriesSumZero: true};
  };

  ngOnInit(): void {
    this.countriesSort();

    if (this.isVisible()) {
      this.form.addValidators(this.countriesSumZeroValidator);
    }
  }

  ngOnDestroy() {
    if (this.form) {
      this.form.removeValidators(this.countriesSumZeroValidator);
    }
  }

  countriesRemoveItem(country: DictionaryBaseDto) {
    if (country && this.countriesParent && this.countriesParent.countries) {
      this.removeSubCountries(country);
      this.countriesTable.resetControls();
    }
  }

  countriesAllAdd(countries: DictionaryBaseDto[]) {
    if (countries && this.countriesParent && this.countriesParent.countries) {
      const newCountries = [];
      countries
        .filter((c) => this.countriesParent.countries.findIndex((ic) => ic.country.id === c.id) === -1)
        .forEach((c) => {
          const newPc = {country: c} as any;
          this.countriesParent.countries.push(newPc);
          newCountries.push(newPc);
        });
      newCountries.forEach((c) => this.addSubCountries(c));
      this.countriesSort();
    }
  }

  countriesAllRemove(countries: DictionaryBaseDto[]) {
    if (countries && this.countriesParent && this.countriesParent.countries) {
      const newList = this.countriesParent.countries.filter(
        (c) => countries.findIndex((ic) => ic.id === c.country.id) === -1
      );
      const removedCountries = this.countriesParent.countries.filter(
        (c) => newList.findIndex((ic) => ic.country.id === c.country.id) === -1
      );
      this.countriesParent.countries = newList;
      removedCountries.forEach((rc) => this.removeSubCountries(rc.country));
      this.countriesSort();
      this.countriesTable.resetControls();
    }
  }

  countriesAddItem(country: PolicyInquiryCountryDto) {
    if (this.subCountries && country && this.countriesParent && this.countriesParent.countries) {
      this.addSubCountries(country);
      this.countriesSort();
    }
  }

  private countriesSort() {
    this.countryInitialiazerService.countriesSort();
  }

  private addSubCountries(country: PolicyInquiryCountryDto) {
    this.countryInitialiazerService.addSubCountries(country);
  }

  get subCountries() {
    return this.countryInitialiazerService.subCountries;
  }

  private removeSubCountries(country: DictionaryBaseDto) {
    if (!this.subCountries) {
      return;
    }
    let subcs = this.subCountries[country.id];
    if (subcs) {
      subcs = subcs.concat(country.id);
      // Workaroud. Table component does not remove main country if sub contries are removed. It is "additional" remove
      const countries = this.countriesParent.countries;
      for (const subcNumber of subcs) {
        let index;
        if ((index = countries.findIndex((c) => c.country.id === subcNumber)) > -1) {
          countries.splice(index, 1);
        }
      }
    }
  }

  buyersNumber() {
    const ct = this.countriesParent && this.countriesParent.countries;
    if (!ct || ct.length === 0) {
      return null;
    }
    return ct
      .filter((c) => c.numberOfBuyers)
      .reduce((previousValue, currentValue) => {
        return previousValue + currentValue.numberOfBuyers;
      }, 0);
  }

  countriesSum() {
    return InquiryCountries.countriesSumPlannedTO(this.countriesParent, (c) => c.plannedTO);
  }

  get productCountries() {
    return this.countryInitialiazerService.productCountries;
  }

  get filteredProductCountries() {
    return this.countryInitialiazerService.filteredProductCountries;
  }

  isVisible() {
    return this.countriesParent?.countries;
  }
}
