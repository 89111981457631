import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {
  AbstractDeclarationEntryDto,
  BackendError,
  CompanyInDeclarationDto,
  DeclarationBuyerCriteriaDto,
  DeclarationCriteriaDto,
  DeclarationDto,
  DeclarationStubDto,
  DeclarationWithoutEntriesDto,
  ErrorReason,
  isErrorReasons,
  LimitListVersionInDeclarationDto,
  Page,
  PolicyContractSimpleIdDataDto,
  ReportingPeriodDto,
  SearchCriteria,
  SearchResult,
} from '../../../bonding_shared/model';
import {Directive, ViewChild} from '@angular/core';
import {
  AppConfigService,
  DeclarationFileService,
  FormatService,
  GrowlService,
  LimitListType,
  LoggedUserService,
} from '../../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {PolicyContractType, PolicyElementaryRight} from '../../../bonding_shared/model/dictionary-ids';
import {DeclarationAbstractService} from '../../../bonding_shared/services/declaration-abstract.service';
import {DeclarationHistorySectionComponent} from './components/declaration-history-section.component';
import {DeclarationSectionComponent, SessionEntries} from './components/declaration-section.component';
import {DeclarationCriteriaComponent} from './components/declaration-criteria.component';
import {GenerateReportIfc} from '../../../bonding_shared/services/abstract-service-interfaces';
import {HttpParams} from '@angular/common/http';
import {ReportDefinition} from '../../../bonding_shared/model/report-ids';
import {DateUtils} from '../../../bonding_shared/utils/date-utils';
import {BusinessPropertiesService, ConfirmDialogComponent, DictionaryBaseDto} from '../../../bonding_shared';
import * as moment from 'moment';

export interface DeclarationCriteria {
  policiesList: PolicyContractSimpleIdDataDto[]; // only Portal
  selectedPolicy: PolicyContractSimpleIdDataDto;
  lastPolicyContractVersionId: number;
  lateActivationPolicies: LateActivationParams[];

  rpList: ReportingPeriodDto[];
  selectedRp: ReportingPeriodDto;

  llvList: LimitListVersionInDeclarationDto[];
  selectedLlv: LimitListVersionInDeclarationDto;

  decOnList: CompanyInDeclarationDto[];
  selectedDecOn: CompanyInDeclarationDto;

  buyerCriteria: DeclarationBuyerCriteriaDto;
}

export interface DeclarationParams {
  paramRpId: number;
  paramLlvId: number;
  paramDecOnId: number;
}

@Directive()
export abstract class DeclarationDetailsView extends DetailsView {
  @ViewChild(DeclarationHistorySectionComponent, {static: true})
  declarationHistorySectionComponent: DeclarationHistorySectionComponent;
  @ViewChild(DeclarationCriteriaComponent, {static: true}) dcComponent: DeclarationCriteriaComponent;
  @ViewChild(DeclarationSectionComponent, {static: true}) sectionComponent: DeclarationSectionComponent;
  @ViewChild('k8Dialog', {static: true}) k8Dialog: ConfirmDialogComponent;

  declarationSearchResult: SearchResult<DeclarationDto>;
  withBuyerFilter = false;
  searchCriteria: SearchCriteria<DeclarationCriteriaDto>;

  declaration: DeclarationDto;

  dc: DeclarationCriteria;
  previousDeclarationId: number;
  amendment_flag = false;

  edition_flag = false;
  blockButtons_flag = false;
  canUpdate_flag: boolean;
  inProgress_flag = false;
  lastConfirmedDeclaration = false;

  systemCurrency: DictionaryBaseDto;

  protected constructor(
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    private declarationAbstractService: DeclarationAbstractService,
    protected loggedUserService: LoggedUserService,
    protected businessReportService: GenerateReportIfc,
    protected appService: AppConfigService,
    protected businessPropertiesService: BusinessPropertiesService,
    protected formatService: FormatService,
    protected declarationFileService: DeclarationFileService
  ) {
    super(growlService);
    this.ifAbsentInitializeSearchCriteria();
    businessPropertiesService.getProperties().subscribe((p) => (this.systemCurrency = p.systemCurrency));
  }

  set amendmentView(value: boolean) {
    this.amendment_flag = value;
    this.dcComponent.pureAmendmentView = value; // propagate also to declaration criteria component
    this.updateCanUpdateFlag();
  }

  set amendment(value: boolean) {
    this.amendment_flag = value;
    this.dcComponent.amendmentDeclaration = value;
    this.updateCanUpdateFlag();
  }

  get amendment(): boolean {
    return this.amendment_flag;
  }

  outstandings(): boolean {
    return (
      this.dc.selectedPolicy &&
      this.dc.selectedPolicy.productType &&
      this.dc.selectedPolicy.productType.id === PolicyContractType.KUKE_KOM_SP
    );
  }

  blockDeclarationSection() {
    this.sectionComponent.blockSection = true;
  }

  unblockDeclarationSection() {
    this.sectionComponent.blockSection = false;
  }

  unblockPagination() {
    this.sectionComponent.blockPagination = false;
  }

  onFilteringChangedInnerAmendment() {
    this.blockDeclarationSection();
    this.resetSearchCriteriaForFiltering();
    this.declarationAbstractService.initializeAmendmentByCriteria(this.searchCriteria).subscribe({
      next: (decSR) => {
        this.sectionComponent.declarationSearchResult = decSR;
        if (decSR) {
          this.declaration = decSR.result[0];
        } else {
          this.setDeclarationUndefined();
        }
        this.inProgress_flag = false;
        this.unblockDeclarationSection();
      },
      error: () => {
        this.unblockDeclarationSection();
        this.setDeclarationUndefined();
        this.growlService.error('Error while getting declaration amendment!');
      },
    });
  }

  onFilteringChangedInnerDeclaration() {
    this.blockDeclarationSection();
    this.resetSearchCriteriaForFiltering();
    this.declarationAbstractService.findOrInitializeDeclarationByCriteria(this.searchCriteria).subscribe({
      next: (decSR) => {
        this.sectionComponent.declarationSearchResult = decSR;
        if (decSR) {
          this.declaration = decSR.result[0];
        } else {
          this.setDeclarationUndefined();
        }
        this.inProgress_flag = false;
        this.unblockDeclarationSection();
      },
      error: () => {
        this.unblockDeclarationSection();
        this.setDeclarationUndefined();
        this.growlService.error('Error while getting declaration!');
      },
    });
  }

  // ############## SETTING TURNOVER ##############
  initOrFindExistingDeclarationBySearchCriteria(clearSessionEntries = true, reloadAmendmentMode = false) {
    this.blockDeclarationSection();
    this.ifAbsentInitializeSearchCriteria();

    this.declarationAbstractService.findOrInitializeDeclarationByCriteria(this.searchCriteria).subscribe({
      next: (decSR) => {
        this.reloadDeclarationSearchResult(decSR, clearSessionEntries);
        if (reloadAmendmentMode) {
          this.reloadAmendmentModeBasedOnDeclaration();
        }
        if (this.canUpdate()) {
          this.unblockDeclarationSection();
        } else {
          this.blockDeclarationSection();
        }
      },
      error: () => {
        this.blockDeclarationSection();
        this.setDeclarationUndefined();
        this.growlService.error('Error while getting declaration!');
      },
    });
  }

  reloadAmendmentModeBasedOnDeclaration() {
    if (this.declaration) {
      this.resetSearchCriteriaByIdsWithoutPage(
        this.declaration.reportingPeriod.id,
        this.declaration.limitListVersion.id,
        this.declaration.declaredOn.id,
        this.declaration.id
      );
      this.amendment = this.declaration.amendment;
      this.updateCanUpdateFlag();
      const now = new Date();
      this.dcComponent.declarationIsEffective = moment(this.declaration.effectiveDate).isBefore(now);
    }
  }

  reloadDeclarationSearchResult(decSR: SearchResult<DeclarationDto>, clearSessionEntries: boolean) {
    if (clearSessionEntries) {
      this.clearEntryValuesMap();
    }
    this.declarationSearchResult = decSR;
    if (decSR) {
      this.declaration = decSR.result[0];
      this.updateCanUpdateFlag();
      this.declarationSetFinished();
    } else {
      this.setDeclarationUndefined();
    }

    if (!this.declaration || !this.declaration.id) {
      this.unSelectAndApplyFiltersOnDeclarationHistory();
    } else {
      this.setHistorySelection(this.declaration);
    }
    this.unblockPagination();
    this.inProgress_flag = false;
  }

  clearDeclaration() {
    this.declaration = undefined;
    this.declarationSearchResult = undefined;
    this.setHistorySelection(undefined);
    this.updateCanUpdateFlag();
  }

  setDeclarationUndefined() {
    this.clearDeclaration();
    this.inProgress_flag = false;
    this.unblockDeclarationSection();
  }

  clearEntryValuesMap() {
    this.sectionComponent.sessionEntries = new SessionEntries();
  }

  declarationToStub() {
    return <DeclarationStubDto>{
      id: this.declaration.id,
      comment: this.declaration.comment,
      version: this.declaration.version,
      amendment: this.declaration.amendment,
      effectiveDate: this.declaration.effectiveDate,
      reportingPeriodId: this.declaration.reportingPeriod ? this.declaration.reportingPeriod.id : undefined,
      limitListVersionId: this.declaration.limitListVersion ? this.declaration.limitListVersion.id : undefined,
      declaredOnId: this.declaration.declaredOn ? this.declaration.declaredOn.id : undefined,
      domesticNNs: this.declaration.domesticNNs,
      exportNNs: this.declaration.exportNNs,
      entryStubs: this.mapsToEntryStubs(),
    };
  }

  // ############## SEARCH CRITERIA ##############
  public ifAbsentInitializeSearchCriteria() {
    if (!this.searchCriteria || !this.searchCriteria.criteria) {
      this.searchCriteria = <SearchCriteria<DeclarationCriteriaDto>>{};
      this.resetSearchCriteriaWithoutPage(this.dc, this.declaration ? this.declaration.id : undefined);
      this.resetPage();
    }
  }

  resetPage(page?: Page) {
    if (!page) {
      page = this.searchCriteria.page;
    }
    this.searchCriteria.page = page ? page : <Page>{start: 0, count: 10};
  }

  resetSearchCriteriaForDeclarationId(declarationId: number) {
    this.searchCriteria.criteria.basedOnDeclarationId = declarationId;
    this.searchCriteria.criteria.reportingPeriodId = undefined;
    this.searchCriteria.criteria.limitListVersionId = undefined;
    this.searchCriteria.criteria.declaredOnId = undefined;
  }

  // from history
  public resetSearchCriteriaForDeclaration(declaration: DeclarationDto) {
    if (declaration) {
      this.resetSearchCriteriaByBasedOnDeclarationWithFirstPage(declaration.id);
    }
  }

  resetSearchCriteriaForFiltering() {
    this.searchCriteria.criteria.buyerCriteria = this.dc.buyerCriteria;
    this.sectionComponent.resetPageToFirst();
    this.searchCriteria.page = <Page>{
      start: (this.sectionComponent.pce.page - 1) * this.sectionComponent.pce.itemsPerPage,
      count: this.sectionComponent.pce.itemsPerPage,
    };
  }

  resetSearchCriteriaForAmendmentByDeclarationId(baseTId: number) {
    this.resetSearchCriteriaForDeclarationId(baseTId);
  }

  public resetSearchCriteriaWithoutPageAndBaseDeclaration(dc: DeclarationCriteria) {
    this.resetSearchCriteriaWithoutPage(dc, undefined);
  }

  public resetSearchCriteriaWithFirstPage(dc: DeclarationCriteria) {
    this.resetSearchCriteriaWithoutPage(dc, undefined);
    this.sectionComponent.resetPageToFirst();
    this.searchCriteria.page = <Page>{
      start: (this.sectionComponent.pce.page - 1) * this.sectionComponent.pce.itemsPerPage,
      count: this.sectionComponent.pce.itemsPerPage,
    };
  }

  private resetSearchCriteriaWithoutPage(dc: DeclarationCriteria, basedOnDeclarationId: number) {
    this.resetSearchCriteriaByIdsWithoutPage(
      dc && dc.selectedRp ? dc.selectedRp.id : undefined,
      dc && dc.selectedLlv ? dc.selectedLlv.id : undefined,
      dc && dc.selectedDecOn ? dc.selectedDecOn.id : undefined,
      basedOnDeclarationId
    );
  }

  private resetSearchCriteriaByBasedOnDeclarationWithFirstPage(basedOnDeclarationId: number) {
    this.searchCriteria = <SearchCriteria<DeclarationCriteriaDto>>{};
    this.searchCriteria.criteria = <DeclarationCriteriaDto>{
      basedOnDeclarationId: basedOnDeclarationId,
    };

    this.sectionComponent.resetPageToFirst();
    this.searchCriteria.page = <Page>{
      start: (this.sectionComponent.pce.page - 1) * this.sectionComponent.pce.itemsPerPage,
      count: this.sectionComponent.pce.itemsPerPage,
    };
  }

  private resetSearchCriteriaByIdsWithoutPage(
    rpId: number,
    llvId: number,
    decOnId: number,
    basedOnDeclarationId: number
  ) {
    if (!this.searchCriteria || !this.searchCriteria.criteria) {
      this.searchCriteria.criteria = <DeclarationCriteriaDto>{
        reportingPeriodId: rpId,
        limitListVersionId: llvId,
        declaredOnId: decOnId,
        basedOnDeclarationId: basedOnDeclarationId,
      };
      this.resetPage();
    } else {
      this.searchCriteria.criteria.reportingPeriodId = rpId;
      this.searchCriteria.criteria.limitListVersionId = llvId;
      this.searchCriteria.criteria.declaredOnId = decOnId;
      this.searchCriteria.criteria.basedOnDeclarationId = basedOnDeclarationId;
    }
  }

  protected clearBuyerFilter() {
    this.dc.buyerCriteria = <DeclarationBuyerCriteriaDto>{};
    this.resetSearchCriteriaForFiltering();
    this.dcComponent.turnOffFiltering();
  }

  // ############## GUI CONTROL ##############
  public onDeclarationEntrySelect(entry: AbstractDeclarationEntryDto) {
    this.edition_flag =
      this.canUpdate() &&
      (this.loggedUserService.hasRight(PolicyElementaryRight.POLICY_DECLARATION_CREATE_UPDATE) ||
        this.loggedUserService.hasRight(PolicyElementaryRight.POLICY_DECLARATION_AMEND));
    this.blockButtons_flag = true;
  }

  public onDeclarationEntryUnselect() {
    this.blockButtons_flag = false;
  }

  withWarningDialog(): boolean {
    return this.sectionComponent.sessionEntries.isWarningRequired();
  }

  // abstract
  updateCanUpdateFlag() {}

  declarationSetFinished() {}

  canUpdate(): boolean {
    return this.canUpdate_flag;
  }

  edition(): boolean {
    return this.edition_flag;
  }

  inProgressMode(): boolean {
    return this.inProgress_flag;
  }

  get disabledView(): boolean {
    return this.inProgress_flag || this.blockButtons_flag;
  }

  get disabledViewOrDeclarationLocked(): boolean {
    return this.disabledView || this.declaration.locked;
  }

  // ############## ACTION(SAVE, CONFIRM, SAVE_AMENDMENT) DIALOGS HELPER ##############
  protected conditionalDialog(condition: boolean, dialog: Function, nextStep: Function): Function {
    if (condition) {
      return dialog;
    } else {
      return nextStep;
    }
  }

  // ############## HISTORY CONTROL ##############
  setHistorySelection(dec: DeclarationDto) {
    if (this.declarationHistorySectionComponent) {
      if (dec && dec.id) {
        this.declarationHistorySectionComponent.selectItem(dec.id);
      } else {
        this.declarationHistorySectionComponent.unSelect();
      }
    }
  }

  unSelectAndApplyFiltersOnDeclarationHistory() {
    if (this.declarationHistorySectionComponent) {
      this.declarationHistorySectionComponent.unSelect();
      this.applyFiltersOnDeclarationHistory();
    }
  }

  clearDeclarationHistory() {
    if (this.declarationHistorySectionComponent) {
      this.declarationHistorySectionComponent.clearHistory();
    }
  }

  applyFiltersOnDeclarationHistory() {
    if (this.declarationHistorySectionComponent) {
      this.declarationHistorySectionComponent.applyFilters(
        this.dc.selectedRp,
        this.dc.selectedLlv,
        this.dc.selectedDecOn
      );
    }
  }

  public llvMoreThanOne(): boolean {
    // show filter option or not
    return this.dc?.llvList?.length > 1;
  }

  public declaredOnMoreThanZero(): boolean {
    // show filter option or not
    return this.dc?.decOnList?.length > 0;
  }

  private mapsToEntryStubs() {
    let resultArray = [];
    if (this.sectionComponent.sessionEntries.persistedMap) {
      resultArray = resultArray.concat(Array.from(this.sectionComponent.sessionEntries.persistedMap.values()));
    }
    if (this.sectionComponent.sessionEntries.transientMap) {
      this.sectionComponent.sessionEntries.transientMap.forEach(
        (innerMap) => (resultArray = resultArray.concat(Array.from(innerMap.values())))
      );
    }
    return resultArray;
  }

  protected computeLastConfirmedDeclaration(singleFinalConfirmedDeclarationsForPolicy: DeclarationWithoutEntriesDto[]) {
    this.lastConfirmedDeclaration =
      this.declaration &&
      singleFinalConfirmedDeclarationsForPolicy &&
      singleFinalConfirmedDeclarationsForPolicy.findIndex((d) => d.id === this.declaration.id) !== -1;
  }

  downloadDeclarationReport() {
    if (!this.dc || !this.dc.selectedPolicy || !this.dc.selectedPolicy.id) {
      return;
    }

    let params = new HttpParams();
    params = params.append('policyContractId', this.dc.selectedPolicy.id + '');
    if (this.dc.selectedRp) {
      if (this.dc.selectedRp.periodFrom) {
        params = params.append('periodFrom', this.dc.selectedRp.periodFrom.toISOString());
      }
      if (this.dc.selectedRp.periodTo) {
        params = params.append('periodTo', this.dc.selectedRp.periodTo.toISOString());
      }
    }

    const fileName =
      this.translateService.instant('declaration.details.declarationReportFileName', {
        number: this.dc.selectedPolicy.yearNumber,
        periodFrom: this.dc.selectedRp ? DateUtils.format(this.dc.selectedRp.periodFrom) : '',
        periodTo: this.dc.selectedRp ? DateUtils.format(this.dc.selectedRp.periodTo) : '',
      }) + '.xlsx';

    this.serverErrors = undefined;
    this.inProgress_flag = true;
    this.businessReportService.generate(
      ReportDefinition.DECLARATION_REPORT,
      fileName,
      params,
      (errorMsg) => {
        this.inProgress_flag = false;
        this.downloadErrorCallback(errorMsg);
      },
      (file) => (this.inProgress_flag = false)
    );
  }

  exportLimits() {
    const generateCriteria = <SearchCriteria<DeclarationCriteriaDto>>{};
    generateCriteria.criteria = <DeclarationCriteriaDto>{
      reportingPeriodId: this.dc.selectedRp.id,
      limitListVersionId: this.dc.selectedLlv.id,
      declaredOnId: this.dc.selectedDecOn.id,
    };
    this.inProgress_flag = true;
    const fileName = [
      'limits',
      this.dc.selectedPolicy.number,
      this.dc.selectedRp.id,
      this.dc.selectedLlv.id,
      this.formatService.formatDateTime(new Date()),
    ].join(' ');
    this.declarationFileService.generateLimitListFile(
      generateCriteria.criteria,
      LimitListType.ALL_LIMITS,
      fileName + '.xlsx',
      (errorMsg) => this.errorCallback(errorMsg),
      (file) => this.exportCompleteCallback(file)
    );
  }

  protected exportCompleteCallback(file: File): void {
    if (file) {
      this.growlService.notice('File ' + file.name + ' exported!');
    }
    this.inProgress_flag = false;
  }

  protected errorCallback(err: string): void {
    this.serverErrors = <ErrorReason[]>JSON.parse(err);
    this.inProgress_flag = false;
    return;
  }

  showK8Dialog() {
    if (this.appService.kuke) {
      this.k8Dialog.openAndExecuteOnConfirm(
        this.outstandings() ? 'declaration.criteria.k8messageOutstandings' : 'declaration.criteria.k8messageTurnovers',
        undefined,
        () => {},
        undefined,
        undefined
      );
    }
  }

  public get mehib(): boolean {
    return this.appService.mehib;
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }
}

export class LateActivationParams {
  policyNumber: string;
  dates: string;
}
