import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  GrowlService,
  PolicyContractPreviewDto,
  PolicyContractVersionService,
  PolicyDrawdownPreviewDto,
  RouterService,
} from '../../../../bonding_shared';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {PolicyDrawdownPreviewService} from '../../../../bonding_shared/services/policy-drawdown-preview.service';

@Component({
  selector: 'policy-drawdown-summary',
  templateUrl: './policy-drawdown-summary.component.pug',
})
export class PolicyDrawdownSummaryComponent extends DetailsView implements OnInit {
  policyContractPreview: PolicyContractPreviewDto;
  drawdownPreview: PolicyDrawdownPreviewDto;
  masterPolicyId: number;

  constructor(
    private policyContractVersionService: PolicyContractVersionService,
    private policyDrawdownService: PolicyDrawdownPreviewService,
    private route: ActivatedRoute,
    public router: RouterService,
    protected growlService: GrowlService
  ) {
    super(growlService);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.masterPolicyId = +params['masterPolicyId'];
    this.policyContractVersionService.getPreviewByMasterPolicyId(this.masterPolicyId).subscribe((pc) => {
      this.policyContractPreview = pc;
      this.policyDrawdownService
        .getDrawdownPreview(this.policyContractPreview.masterPolicyId)
        .subscribe((d) => (this.drawdownPreview = d));
    });
  }

  paymentsButtonClicked() {
    if (this.drawdownPreview.paymentsId) {
      this.router.toPolicyDrawdownPaymentsDetails(this.drawdownPreview.paymentsId);
    } else {
      this.router.toPolicyDrawdownPaymentsNew(this.masterPolicyId);
    }
  }

  scheduleButtonClicked() {
    this.router.toPolicyDrawdownScheduleDetails(this.masterPolicyId);
  }
}
