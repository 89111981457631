import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';
import {CompanySharedModule} from '../../company/components/shared/company-shared.module';
import {PolicyContractSharedModule} from '../../policy/components/shared/policy-contract-shared.module';
import {PolicyClauseModule} from '../../policy/clause/policy-clause.module';
import {TabViewModule} from 'primeng/tabview';
import {InquiryProductCredendoComponent} from './inquiry-product-credendo.component';
import {InquiryProductKukeComponent} from './inquiry-product-kuke.component';
import {KukeOfferFormComponent} from './kuke-offer-form.component';
import {InquirySharedModule} from '../shared/inquiry-shared.module';
import {InquiryStatementsComponent} from './inquiry-statements.component';
import {InquiryOffersComponent} from './inquiry-offers.component';
import {InquiryOffersVotingComponent} from './inquiry-offers-voting.component';
import {BondSharedModule} from '../../bond/components/shared/bond-shared.module';
import {KukePortalOfferFormComponent} from './kuke-portal-offer-form.component';
import {InquiryProductMehibComponent} from './inquiry-product-mehib.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {InquiryProductCredendoSingleRiskComponent} from './inquiry-product-credendo-single-risk.component';
import {MehibInquiryOffersComponent} from './mehib-inquiry-offers.component';
import {CompanyExtendedSelectorModule} from '../../company/components/shared/extended-selectors/company-extended-selector.module';
import {PolicyModule} from '../../policy/policy.module';
import {DebtorBuyerUltimateUserInquirySectionComponent} from './sections/debtor-buyer-ultimate-user-inquiry-section.component';
import {TransactionPartiesInquirySectionComponent} from './sections/transaction-parties-inquiry-section.component';
import {ExportersInquirySectionComponent} from './sections/exporters-inquiry-section.component';
import {GuarantorsInquirySectionComponent} from './sections/guarantors-inquiry-section.component';
import {InquiryCoInsurersSectionComponent} from './sections/inquiry-co-insurers-section.component';
import {InquiryProductEcgComponent} from './inquiry-product-ecg.component';
import {InquiryProductEcgTopUpComponent} from './inquiry-product-ecg-top-up.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    BsDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    CompanySharedModule,
    PolicyContractSharedModule,
    PolicyClauseModule,
    TabViewModule,
    InquirySharedModule,
    BondSharedModule,
    CompanyExtendedSelectorModule,
    PolicyModule,
  ],
  declarations: [
    InquiryProductCredendoComponent,
    InquiryProductCredendoSingleRiskComponent,
    InquiryProductKukeComponent,
    InquiryProductMehibComponent,
    InquiryProductEcgComponent,
    InquiryProductEcgTopUpComponent,
    KukeOfferFormComponent,
    InquiryStatementsComponent,
    InquiryOffersComponent,
    MehibInquiryOffersComponent,
    InquiryOffersVotingComponent,
    KukePortalOfferFormComponent,
    DebtorBuyerUltimateUserInquirySectionComponent,
    TransactionPartiesInquirySectionComponent,
    ExportersInquirySectionComponent,
    GuarantorsInquirySectionComponent,
    InquiryCoInsurersSectionComponent,
  ],
  exports: [
    InquiryProductCredendoComponent,
    InquiryProductCredendoSingleRiskComponent,
    InquiryProductKukeComponent,
    InquiryProductMehibComponent,
    InquiryProductEcgComponent,
    InquiryProductEcgTopUpComponent,
    KukeOfferFormComponent,
    InquiryStatementsComponent,
    KukePortalOfferFormComponent,
  ],
})
export class InquiryFormsModule {}
