import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DictionaryBaseDto} from '../model';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';

@Injectable()
export class LanguageService extends AbstractService {
  protected url = this.urlPrefix + 'i18n';

  constructor(
    public http: HttpClient,
    private translateService: TranslateService,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getAvailableLanguages(): Observable<string[]> {
    return this.get<string[]>(this.url + '/availableLanguages');
  }

  getDraftPackageLanguages(): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/draftPackageLanguages');
  }

  getBankBoosterLanguages(): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/bankBoosterLanguages');
  }

  getTemplateLanguagesPerSubtype(subtypeId: number, businessUnitId: number) {
    let templateLanguagesUrl = this.url + '/bondTemplateLanguages';
    if (subtypeId) {
      templateLanguagesUrl += '?subtypeId=' + subtypeId;
      return this.get<DictionaryBaseDto[]>(templateLanguagesUrl);
    }
    return this.getLanguages(businessUnitId);
  }

  getLanguages(businessUnitId: number): Observable<DictionaryBaseDto[]> {
    let buLanguagesUrl = this.url + '/buLanguages';
    console.log('start getLanguage(' + businessUnitId + ')');
    if (businessUnitId) {
      buLanguagesUrl += '?businessUnitId=' + businessUnitId;
    }
    return this.get<DictionaryBaseDto[]>(buLanguagesUrl);
  }

  initializeLanguage(): Observable<any> {
    return this.getAvailableLanguages().pipe(
      map((availableLanguages) => this.setBestLanguageForLogin(availableLanguages))
    );
  }

  setBestLanguageForLogin(availableLanguages: string[]) {
    const lang = this.chooseBestLanguageBeforeLogin(availableLanguages);
    return this.translateService.use(lang);
  }

  private chooseBestLanguageBeforeLogin(availableLanguages: string[]): string {
    const ieLanguage = (window.navigator as any).userLanguage;
    let languages = ieLanguage ? [ieLanguage] : [];
    if (navigator.languages) {
      languages = [...navigator.languages, ...languages];
    }
    const defaultLangIndex = availableLanguages.findIndex((l) => l.endsWith('$'));
    if (defaultLangIndex !== -1) {
      availableLanguages[defaultLangIndex] = availableLanguages[defaultLangIndex].slice(0, -1);
    }
    const defaultLang = defaultLangIndex !== -1 ? availableLanguages[defaultLangIndex] : availableLanguages[0];

    return this.findInSupportedLanguages(languages, availableLanguages, defaultLang);
  }

  private findInSupportedLanguages(languages: string[], supportedLanguages: string[], defaultLanguage: string): string {
    for (const lang of languages) {
      const langCode = lang.substring(0, 2);
      if (supportedLanguages.find((l) => l.startsWith(langCode))) {
        return langCode;
      }
    }
    return defaultLanguage;
  }
}
