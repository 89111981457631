import {PolicyLimitListSearchComponent} from './components/policylimitlist-search.component';
import {PolicyLimitListDetailsComponent} from './components/policylimitlist-details.component';
import {PolicyLimitListDetailsGuard, PolicyLimitListSearchGuard} from './_guards';

export const PolicyLimitListRoutes = [
  {
    path: 'policylimitlist-details',
    component: PolicyLimitListDetailsComponent,
    canActivate: [PolicyLimitListDetailsGuard],
  },
  {
    path: 'policylimitlist-details/:id',
    component: PolicyLimitListDetailsComponent,
    canActivate: [PolicyLimitListDetailsGuard],
  },
  {
    path: 'policylimitlist-search/:masterPolicyId',
    component: PolicyLimitListSearchComponent,
    canActivate: [PolicyLimitListSearchGuard],
  },
  {
    path: 'policylimitlist-search/:masterPolicyId/:policyContractVersionId',
    component: PolicyLimitListSearchComponent,
    canActivate: [PolicyLimitListSearchGuard],
  },
  {
    path: 'policylimitlist-search',
    component: PolicyLimitListSearchComponent,
    canActivate: [PolicyLimitListSearchGuard],
  },
];
