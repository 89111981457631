import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AppConfigService,
  DictionaryBaseService,
  FeatureService,
  LoggedUserService,
  RouterService,
  SettingsService,
} from '../../services';
import {DictionaryBaseDto} from '../../model';
import {
  ApplicationModule,
  ClaimElementaryRight,
  CollectionElementaryRight,
  CompanyElementaryRight,
  ElementaryRight,
  Feature,
  InvoiceCategory,
  PolicyElementaryRight,
  SalesRepElementaryRight,
  UserRole,
} from '../../model/dictionary-ids';
import {InvoiceGuiService} from '../../../bonding/invoice/services/invoice-gui.service';
import {InvoiceItemGuiService} from '../../../bonding/invoice-item/services/invoice-item-gui.service';
import * as $ from 'jquery';
import {InvoiceUtils} from '../../utils/invoice-utils';
import PerfectScrollbar from 'perfect-scrollbar';

@Component({
  selector: 'bon-sidebar',
  templateUrl: './sidebar.component.pug',
})
export class SidebarComponent implements OnInit, OnDestroy {
  readonly accessRodo = ElementaryRight.ACCESS_RODO;
  readonly accessFooter = ElementaryRight.ACCESS_FOOTER;
  readonly userSearch = ElementaryRight.MY_PROFILE_CATEGORY_USERS_SEARCH;
  readonly userExternalSearch = ElementaryRight.EXTERNAL_USER_ACCESS_ONLY;
  readonly ElementaryRight = ElementaryRight;
  readonly PolicyElementaryRight = PolicyElementaryRight;
  readonly ClaimElementaryRight = ClaimElementaryRight;
  readonly CollectionElementaryRight = CollectionElementaryRight;
  readonly CompanyElementaryRight = CompanyElementaryRight;
  readonly SalesRepElementaryRight = SalesRepElementaryRight;
  readonly InvoiceCategory = InvoiceCategory;
  readonly UserRole = UserRole;

  invoiceCategories: DictionaryBaseDto[];
  fiNoteCategories: DictionaryBaseDto[];
  module = ApplicationModule;
  scrollbar: PerfectScrollbar;

  showDocumentMenu: boolean;
  showHealthPage = false;

  constructor(
    public settingsService: SettingsService,
    public loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    private dictionaryBaseService: DictionaryBaseService,
    private featureService: FeatureService,
    public router: RouterService,
    private invoiceGuiService: InvoiceGuiService,
    private invoiceItemGuiService: InvoiceItemGuiService
  ) {
    this.settingsService.viewChange.subscribe((moduleId) => {
      this.updateMenuItemsVisibility();
    });
  }

  ngOnInit() {
    this.loadInvoiceCategories();
    this.loadFINoteCategories();
    this.updateMenuItemsVisibility();
    this.initHealtPageVisibility();
    const sidebar: any = $('.sidenav-outer');
    if (sidebar.length > 0) {
      this.scrollbar = new PerfectScrollbar(sidebar[0]);
    }
  }

  ngOnDestroy() {
    if (this.scrollbar) {
      this.scrollbar.destroy();
      this.scrollbar = null;
    }
  }

  initHealtPageVisibility() {
    this.showHealthPage =
      this.settingsService.isModuleActive(this.module.ADMINISTRATION) &&
      this.featureService.get(Feature.REPOSITORY_HEALTH_CHECK);
  }

  updateMenuItemsVisibility() {
    this.showDocumentMenu =
      ((this.appService.credendo || this.appService.mehib) &&
        !this.appService.mehib &&
        !this.settingsService.isModuleActive(
          this.module.ADMINISTRATION,
          this.module.SALES,
          this.module.FINANCE,
          this.module.CLAIM,
          this.module.HR
        )) ||
      (this.appService.kuke &&
        this.settingsService.isModuleActive(this.module.ADMINISTRATION) &&
        (this.loggedUserService.hasRight(this.accessRodo) || this.loggedUserService.hasRight(this.accessFooter))) ||
      (this.appService.mehib && this.settingsService.isModuleActive(this.module.ADMINISTRATION)) ||
      (this.appService.ecg && this.settingsService.isModuleActive(this.module.ADMINISTRATION));
  }

  getInvoiceCategoryLabel(invoiceCategory: DictionaryBaseDto): string {
    return this.invoiceGuiService.getInvoiceCategoryLabel(invoiceCategory, true);
  }

  getInvoiceItemCategoryLabel(invoiceCategory: DictionaryBaseDto): string {
    return this.invoiceItemGuiService.getInvoiceItemCategoryLabel(invoiceCategory, true);
  }

  private loadInvoiceCategories() {
    this.dictionaryBaseService.getDictionaryBase('InvoiceCategory').subscribe(
      (invoiceCategories) => {
        this.invoiceCategories = invoiceCategories.filter((c) =>
          this.loggedUserService.hasAllRights(InvoiceUtils.getReadRightsNeeded(c.id))
        );
      },
      (error) => {
        alert('Error in backend service: ' + JSON.stringify(error));
      }
    );
  }

  private loadFINoteCategories() {
    this.dictionaryBaseService.getDictionaryBase('FINoteCategory').subscribe(
      (fiNoteCategories) => {
        this.fiNoteCategories = fiNoteCategories.filter((c) =>
          this.loggedUserService.hasAllRights(InvoiceUtils.getReadRightsNeeded(c.id))
        );
      },
      (error) => {
        alert('Error in backend service: ' + JSON.stringify(error));
      }
    );
  }

  showPayments(): boolean {
    return (
      this.loggedUserService.hasRight(ElementaryRight[ElementaryRight.CLIENT_INVOICE_READ]) &&
      !this.appService.mehib &&
      !this.appService.ecg
    );
  }

  showInvoiceCategory(invoiceCategory: DictionaryBaseDto): boolean {
    return this.appService.ecg
      ? invoiceCategory.id !== InvoiceCategory.INTEREST
      : invoiceCategory.id === InvoiceCategory.INVOICE || !this.mehib;
  }

  isExternalProvidersVisible() {
    return (
      (!this.appService.mehib && this.settingsService.isModuleActive(this.module.COLLECTION)) ||
      (this.appService.mehib &&
        (this.settingsService.isModuleActive(this.module.LIMIT) ||
          this.settingsService.isModuleActive(this.module.CLAIM)))
    );
  }

  isSalesRepVisible() {
    const activeModuleCondition = this.appService.kuke
      ? this.settingsService.isModuleActive(this.module.SALES, this.module.POLICY, this.module.BONDING)
      : this.settingsService.isModuleActive(this.module.SALES, this.module.POLICY);
    return activeModuleCondition && !this.appService.mehib;
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  get mehib(): boolean {
    return this.appService.mehib;
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }
}
