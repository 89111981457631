import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';
import {ContractDetailsComponent} from './contract-details.component';
import {ContractVersionSearchComponent} from './contract-version-search.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {CompanyModule} from '../../company/company.module';
import {ContractGuiService} from './services/contract-gui.service';
import {ContractPreviewComponent} from './contract-preview.component';
import {ContractSharedModule} from './components/shared/contract-shared.module';
import {CompanySharedModule} from '../../company/components/shared/company-shared.module';
import {BondSharedModule} from '../../bond/components/shared/bond-shared.module';
import {InvoiceSharedModule} from '../../invoice/components/shared/invoice-shared.module';
import {BrokerContractSharedModule} from '../../broker-contract/components/shared/broker-contract-shared.module';
import {DocumentModule} from '../../document/document.module';
import {SalesRepSharedModule} from '../../sales-rep/components/shared/sales-rep-shared.module';
import {ContractTechnicalDetailsComponent} from './contract-technical-details.component';
import {ContractSuspensionDialogComponent} from './components/contract-suspension-dialog.component';
import {ContractTerminationValidatorDirective} from './validators/contract-termination-validator.directive';
import {LimitSharedModule} from '../../limit/shared/limit-shared.module';
import {BondingContractCommonModule} from '../common/bonding-contract-common.module';
import {
  ContractDetailsGuard,
  ContractPreviewGuard,
  ContractSearchGuard,
  ContractTechnicalDetailsGuard,
} from './_guards';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    CompanySharedModule,
    CompanyModule,
    BrokerContractSharedModule,
    SalesRepSharedModule,
    ContractSharedModule,
    BondSharedModule,
    InvoiceSharedModule,
    DocumentModule,
    BondingContractCommonModule,
    LimitSharedModule,
  ],
  declarations: [
    ContractDetailsComponent,
    ContractVersionSearchComponent,
    ContractPreviewComponent,
    ContractTechnicalDetailsComponent,
    ContractSuspensionDialogComponent,
    ContractTerminationValidatorDirective,
  ],
  exports: [ContractDetailsComponent],
  providers: [
    ContractGuiService,
    ContractDetailsGuard,
    ContractPreviewGuard,
    ContractSearchGuard,
    ContractTechnicalDetailsGuard,
  ],
})
export class ContractModule {}
