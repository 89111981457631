import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {
  BondVersionDto,
  ClaimIndemnificationVersionBaseDto,
  ClaimIndemnificationVersionCriteriaDto,
  ClaimIndemnificationVersionDto,
  IdDto,
  PolicyContractIdDto,
  SearchCriteria,
  StateTransitionDto,
} from '../model/dtos';
import {Observable, of as observableOf} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Cacheable} from 'ts-cacheable';
import {SearchDataProvider} from './search-data-provider';

@Injectable()
export class ClaimIndemnificationVersionService extends AbstractService {
  protected url = this.urlPrefix + 'claimIndemnificationVersion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  static createDataProvider(service: ClaimIndemnificationVersionService) {
    const dp = new SearchDataProvider<ClaimIndemnificationVersionCriteriaDto, ClaimIndemnificationVersionBaseDto>(
      service
    );
    dp.searchCriteria.criteria = <ClaimIndemnificationVersionCriteriaDto>{};
    dp.searchCriteria.criteria.last = true;
    dp.textSearch = false;
    return dp;
  }

  getLastByClaim(claimId: number): Observable<ClaimIndemnificationVersionBaseDto> {
    return this.getLastVersions(claimId).pipe(map((x) => x[0]));
  }

  getLastByParent(parentId: number): Observable<ClaimIndemnificationVersionBaseDto> {
    return this.getVersions(parentId).pipe(map((x) => x[0]));
  }

  getLastVersions(claimId: number): Observable<ClaimIndemnificationVersionBaseDto[]> {
    const crit = <SearchCriteria<ClaimIndemnificationVersionCriteriaDto>>{
      criteria: {last: true, parent: {claim: {id: claimId}}},
    };
    return this.searchByCriteria(crit).pipe(map((x) => <ClaimIndemnificationVersionBaseDto[]>x.result));
  }

  getVersions(parentId: number): Observable<ClaimIndemnificationVersionBaseDto[]> {
    const crit = <SearchCriteria<ClaimIndemnificationVersionCriteriaDto>>{criteria: {parent: {id: parentId}}};
    return this.searchByCriteria(crit).pipe(map((x) => <ClaimIndemnificationVersionBaseDto[]>x.result));
  }

  getNewVersion(parentId: number): Observable<ClaimIndemnificationVersionDto> {
    return this.get(this.url + '/newVersion/' + parentId);
  }

  getInitialVersion(claimId: number, policyContractId?: number): Observable<ClaimIndemnificationVersionDto> {
    return this.get(
      this.url + '/initialVersion/' + claimId + (policyContractId ? '?policyContractId=' + policyContractId : '')
    );
  }

  getCalculationPolicyYears(calculationId: number): Observable<PolicyContractIdDto[]> {
    return this.get(this.urlPrefix + 'claimCalculation/policyYears/' + calculationId);
  }

  @Cacheable()
  getTransitionsMap(): Observable<Map<number, Array<StateTransitionDto>>> {
    return this.get<Map<number, Array<StateTransitionDto>>>(this.url + '/transition');
  }

  getTransitions(v: IdDto): Observable<Array<StateTransitionDto>> {
    return super.getTransitionsForObject(v);
  }
}
