import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {
  ContractBaseDto,
  ContractVersionCriteriaDto,
  ContractVersionSimpleDto,
} from '../../../../bonding_shared/model/dtos';
import {PortalRouterService} from '../../../services/portal-router.service';
import {BrokerGuiService} from '../../services/broker-gui.service';
import {SearchViewComponent} from '../../../../bonding_shared/components/search/search-view/search-view.component';

@Component({
  selector: 'broker-portfolio',
  templateUrl: './broker-portfolio.component.pug',
})
export class BrokerPortfolioComponent implements OnInit {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    ContractVersionCriteriaDto,
    ContractVersionSimpleDto
  >;
  dataProvider: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>;

  constructor(public router: PortalRouterService, private service: BrokerGuiService) {
    this.dataProvider = service.portfolioSearchDataProvider;
  }

  ngOnInit() {
    if (!this.dataProvider.searchCriteria.criteria) {
      this.initializeCriteria();
    }
  }

  initializeCriteria() {
    this.dataProvider.searchCriteria.criteria = <ContractVersionCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.contract = <ContractBaseDto>{};
    this.dataProvider.searchCriteria.criteria.brokerContracts = true;
    this.dataProvider.searchCriteria.criteria.brokerRequests = null;
    this.dataProvider.searchCriteria.criteria.withoutRequests = null;
    this.dataProvider.textSearch = false;
  }

  searchForContracts() {
    console.log('BrokerPortflio::searchContracts');
    this.dataProvider.searchCriteria.criteria.brokerContracts = true;
    this.dataProvider.searchCriteria.criteria.brokerRequests = null;
    this.dataProvider.searchCriteria.criteria.withoutRequests = null;
    this.searchView.search();
  }

  searchForRequests() {
    console.log('BrokerPortflio::searchRequests');
    this.dataProvider.searchCriteria.criteria.brokerRequests = true;
    this.dataProvider.searchCriteria.criteria.brokerContracts = null;
    this.dataProvider.searchCriteria.criteria.withoutRequests = null;
    this.searchView.search();
  }
}
