import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {
  ClaimDto,
  ClaimSimpleDto,
  ClaimSurveyQuestionDefinitionDto,
  ClaimVersionBaseDto,
  ClaimVersionCriteriaDto,
  ClaimVersionDto,
  DictionaryBaseDto,
  SearchCriteria,
  SearchResult,
  StateTransitionDto,
  UserSimpleDto,
} from '../model/dtos';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class ClaimService extends AbstractService {
  protected url = this.urlPrefix + this.portalPrefix + 'claimVersion';

  private claimUrl = this.urlPrefix + this.portalPrefix + 'claim';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getClaimVersion(claimVersionId: number): Observable<ClaimVersionDto> {
    return this.get<ClaimVersionDto>(this.url + '/' + claimVersionId);
  }

  getLastClaimVersion(claimId: number, statuses: DictionaryBaseDto[]): Observable<ClaimVersionDto> {
    const criteria = <SearchCriteria<ClaimVersionCriteriaDto>>{
      criteria: {
        sortBy: {
          direction: 'DOWN',
          column: 'versionNumber',
        },
        statuses: statuses,
        claim: {
          id: claimId,
        },
      },
    };
    return this.searchByCriteria(criteria).pipe(map((res) => (<SearchResult<ClaimVersionDto>>res).result[0]));
  }

  getBondClaims(bondVersionId: number): Observable<ClaimVersionDto[]> {
    const criteria = <SearchCriteria<ClaimVersionCriteriaDto>>{
      criteria: {
        bondId: bondVersionId,
      },
    };
    return this.searchByCriteria(criteria).pipe(map((res) => (<SearchResult<ClaimVersionDto>>res).result));
  }

  getClaim(claimId: number) {
    return this.get<ClaimDto>(this.claimUrl + '/' + claimId);
  }

  getClaimSimple(claimId: number) {
    return this.get<ClaimSimpleDto>(this.claimUrl + '/' + claimId + '/simple');
  }

  getClaimSimpleByClaimVersionId(claimVersionId: number) {
    return this.get<ClaimSimpleDto>(this.claimUrl + '/simple?claimVersionId=' + claimVersionId);
  }

  getNewClaimVersion(claimId: number): Observable<ClaimVersionDto> {
    return this.get<ClaimVersionDto>(this.url + '/newVersion/' + claimId);
  }

  getInitialVersionFromBond(bondVersionId: number): Observable<ClaimVersionDto> {
    return this.get<ClaimVersionDto>(this.claimUrl + '/initialVersion/bond?bondVersionId=' + bondVersionId);
  }

  getInitialVersionFromCollection(collectionId: number): Observable<ClaimVersionDto> {
    return this.get<ClaimVersionDto>(this.claimUrl + '/initialVersion/collection?collectionId=' + collectionId);
  }

  getInitialVersionFromPolicy(
    claimRiskTypeId: number,
    buyerId: number,
    insuredId: number,
    policyContractId: number,
    limitListId: number
  ): Observable<ClaimVersionDto> {
    let params = new HttpParams();
    params = params.append('claimRiskTypeId', claimRiskTypeId + '');
    params = params.append('insuredId', insuredId + '');
    params = params.append('policyContractId', policyContractId + '');
    params = params.append('buyerId', buyerId + '');
    if (!!limitListId) {
      params = params.append('limitListId', limitListId + '');
    }
    return this.get<ClaimVersionDto>(this.claimUrl + '/initialVersion/policy', params);
  }

  initialize(claim: ClaimSimpleDto): Observable<ClaimVersionDto> {
    return this.post1<ClaimSimpleDto, ClaimVersionDto>(claim, this.claimUrl + '/initialVersion/claim');
  }

  calculateClaimVersion(claim: ClaimVersionDto): Observable<ClaimVersionDto> {
    return this.post<ClaimVersionDto>(claim, this.url + '/calculate');
  }

  getTransitions(claimVersion: ClaimVersionDto): Observable<Array<StateTransitionDto>> {
    return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + claimVersion.id);
  }

  getTransitionsMap(): Observable<Map<number, Array<StateTransitionDto>>> {
    return this.get<Map<number, Array<StateTransitionDto>>>(this.url + '/transition');
  }

  getAvailableUsersForClaimOfficer(id: number): Observable<SearchResult<UserSimpleDto>> {
    return this.get<SearchResult<UserSimpleDto>>(this.url + '/' + id + '/availableUsersForClaimOfficer');
  }

  getSurveyDefinition(claimVersion: ClaimVersionDto): Observable<ClaimSurveyQuestionDefinitionDto[]> {
    return this.post1<ClaimVersionDto, ClaimSurveyQuestionDefinitionDto[]>(
      claimVersion,
      this.url + '/surveyDefinition'
    );
  }

  getLastVersionByClaimId(claimId: number): Observable<ClaimVersionDto> {
    return this.get<ClaimVersionDto>(this.url + '/lastVersion/' + claimId);
  }

  getVersions(
    parentId: number,
    excludedStatus: number,
    lastVersionPerVersionType: boolean
  ): Observable<ClaimVersionBaseDto[]> {
    const crit = <SearchCriteria<ClaimVersionCriteriaDto>>{
      criteria: {
        claim: {id: parentId},
        excludeStatus: {id: excludedStatus},
        lastVersionPerVersionType: lastVersionPerVersionType,
      },
    };
    return this.searchByCriteria(crit).pipe(map((x) => <ClaimVersionBaseDto[]>x.result));
  }
}
