import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BondVersionCriteriaDto,
  BondVersionDto,
  BondVersionSimpleDto,
  DictionaryBaseDto,
} from '../../../bonding_shared/model/index';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../bonding_shared/validators/index';
import {BondStatus, BondSubType, BondType} from '../../../bonding_shared/model/dictionary-ids';
import {DictionaryBaseService} from '../../../bonding_shared/services/index';
import {BondDto} from '../../../bonding_shared/model/dtos';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {PortalRouterService} from '../../services/portal-router.service';

@Component({
  selector: 'it-performance-bond',
  templateUrl: 'it-performance-bond-component.html',
})
export class ItPerformanceBondComponent extends ListEmitters implements OnInit {
  @Input() bond: BondVersionDto;
  // @Input() contractId: number;
  // selectedBidBond: BondVersionDto;
  @Input() bondForm: UntypedFormGroup;

  @Input() showErrors = false;
  inProgress = false;

  @Output() onValidationError = new EventEmitter<BondVersionDto>();
  @Output() freePercentageProvided = new EventEmitter<BondVersionDto>();

  bondCriteria: BondVersionCriteriaDto;

  constructor(public router: PortalRouterService, public dictionaryService: DictionaryBaseService) {
    super();
    // console.log('ITPerformanceBond constructor this.bond.contractId = ' + this.bond.contractId);
    this.selectorNameList.push('BidBond');
    this.initializeSelectorEmitters(false);
  }

  ngOnInit(): void {
    // this.bond.contractId = this.contractId;
    // console.log('ITPerformanceBon onInit this.bond.contractId = ' + this.bond.contractId);
    this.bondForm.addControl(
      'discountPercentage',
      new UntypedFormControl(
        {value: ''},
        Validators.compose([CustomValidators.minValue(0.0), CustomValidators.maxValue(100.0)])
      )
    );
    this.bondCriteria = <BondVersionCriteriaDto>{};
    this.bondCriteria.bond = <BondDto>{};
    this.bondCriteria.versionPhase = 'ACTIVE';
    this.bondCriteria.type = <DictionaryBaseDto>{id: BondType.BID_BOND_IT};
    this.bondCriteria.contractNumber = this.bond.contractNumber;
    console.log('ITPerformanceBond contractNumber = ' + this.bond.contractNumber);
  }

  @Input()
  set discountPercentage(freePercentage: number) {
    console.log('Set free percentage on: ' + freePercentage);
    if (freePercentage) {
      this.bond.freePercentage = freePercentage;
    }
  }

  performanceBondSubtype(): boolean {
    return this.bond.subtype && this.bond.subtype.id === BondSubType.PERFORMANCE_BOND;
  }

  onSelectBidBond(bv: BondVersionSimpleDto) {
    /* release bv 1: cancel it, then release it*/
    if (bv !== undefined && bv !== null) {
      // this.bond.releasedBidBond = bv;
      this.dictionaryService.getDictionaryEntry('BondStatus', BondStatus.BOND_TO_BE_RELEASED).subscribe((d) => {
        bv.status = d;
        bv.releaseDate = new Date();
        this.bond.releasedBidBond = bv;
      });
    }
  }
}
