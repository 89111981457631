import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {CompanySharedModule} from '../../bonding/company/components/shared/company-shared.module';
import {PolicyContractSharedModule} from '../../bonding/policy/components/shared/policy-contract-shared.module';
import {BrokerContractModule} from '../../bonding/broker-contract/broker-contract.module';
import {ClientTurnoverImportComponent} from './client-turnover-import.component';
import {ClientAmendmentDetailsComponent} from './client-amendment-details.component';
import {DeclarationSharedModule} from '../../bonding/declaration/shared/declaration-shared.module';
import {ClientTurnoverDetailsComponent} from './client-turnover-details.component';
import {
  ClientAmendmentDetailsGuard,
  ClientTurnoverDetailsGuard,
  ClientTurnoverImportGuard,
  ClientAmendmentImportGuard,
  ClientDeclarationHistoryDetailsGuard,
} from './_guards';
import {ClientAmendmentImportComponent} from './client-amendment-import.component';
import {ClientDeclarationHistoryDetailsComponent} from './client-declaration-history-details.component';
import {DeclarationHistoryCriteriaComponent} from './declaration-history-criteria.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    PolicyContractSharedModule,
    BrokerContractModule,
    DeclarationSharedModule,
  ],
  declarations: [
    ClientTurnoverDetailsComponent,
    ClientAmendmentDetailsComponent,
    ClientDeclarationHistoryDetailsComponent,
    ClientTurnoverImportComponent,
    ClientAmendmentImportComponent,
    DeclarationHistoryCriteriaComponent,
  ],
  providers: [
    ClientAmendmentDetailsGuard,
    ClientDeclarationHistoryDetailsGuard,
    ClientTurnoverDetailsGuard,
    ClientTurnoverImportGuard,
    ClientAmendmentImportGuard,
  ],
})
export class ClientDeclarationModule {}
