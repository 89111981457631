<details-view objectName="Task"
              [title]="'task.details.title'"
              [parentComponent]="self"
              [fixedBlockHidden]="false"
              [versionListHidden]="!selectedTask?.id"
              versionsSectionTitle="task.details.history">
  <div class="details-content">
    <task-data *ngIf="selectedTask" [task]="selectedTask"
               [assignmentUnlocked]="assignmentUnlocked"
               [dueDateUnlocked]="dueDateUnlocked"
    ></task-data>
  </div>
  <div class="information-header" *ngIf="selectedTask">
    <b style="display: inline-block; width: 250px;" class="font-larger"> {{selectedTask.title}}</b>
    <b class="id" *ngIf="selectedTask.id"> Id: {{selectedTask.id}}</b>
  </div>
  <div class="additional-buttons">
    <ng-container *hasRight="ElementaryRight[ElementaryRight.TASK_CLOSE]">
      <button (click)="closeTask()" [hidden]="historyMode || !canClose || selectedTask.status.id !== OPEN" class="bon-btn-danger">{{'task.details.close' | translate}}</button>
    </ng-container>
    <ng-container *hasRight="ElementaryRight[ElementaryRight.TASK_CANCEL]">
      <button (click)="cancelTask()" [hidden]="historyMode || !canCancel || selectedTask.status.id !== OPEN" class="bon-btn-warning">{{'task.details.cancel' | translate}}</button>
    </ng-container>
    <ng-container *hasRight="ElementaryRight[ElementaryRight.TASK_CHANGE_ASSIGNMENT]">
      <button (click)="onAssignToMe()" [hidden]="historyMode || hideAssignToMe()" class="bon-btn-info">{{'task.details.assignToMe' | translate}}</button>
    </ng-container>
  </div>
  <div class="versions-content">
    <ng-container *ngIf="selectedTask && selectedTask.id && selectedTask.id > 0">
      <a-table [items]="revisions" (selectItem)="load($event)" [canUnselect]="false" [spacer]="false">
        <column property="changeTimestamp" labelKey="common.changeTime" type="datetime" initialSort="down"></column>
        <column labelKey="common.changedBy">
          <ng-template let-item="item">
            <span>{{item.user | username}}</span>
          </ng-template>
        </column>
      </a-table>
    </ng-container>
  </div>
</details-view>
