import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Currency} from '../../../../../bonding_shared/model/currency';
import {BTableComponent} from '../../../../../bonding_shared/components/aku-table/b-table.component';
import {DictionaryBaseDto, QuotationBondDto} from '../../../../../bonding_shared/model';
import {CellChangeEvent} from '../../../../../bonding_shared/components/aku-table/column.component';
import {ContractType} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'bond-type-list-on-quotation-details',
  templateUrl: './bond-type-list-on-quotation-details.component.pug',
})
export class BondTypeListOnQuotationDetailsComponent<T> {
  @Input() contractBondTypes: QuotationBondDto[] = [];
  @Input() form: UntypedFormGroup;
  @Input() currency: DictionaryBaseDto;
  @Input() showErrors = false;
  @Output() addUpdateDelete = new EventEmitter();
  @Output() selectBondTypeItem = new EventEmitter();
  @Input() editable: boolean;
  @Input() buId: number;
  @Input() dictionaryUserRoleId: number;
  @Input() onCheckFunction: (item: T, items: T[]) => {item: T; value: boolean}[];
  @Input() onUncheckFunction: (item: T, items: T[]) => {item: T; value: boolean}[];
  @Output() onCellChange = new EventEmitter();

  @ViewChild('contractBondTypesList', {static: true}) contractBondTypesList: BTableComponent<QuotationBondDto>;

  public contractTypeBooster = ContractType.BOOSTER;

  private _quotationContractMaxBondLiabilityLimit: number;
  @Input() set quotationContractMaxBondLiabilityLimit(quotationContractMaxBondLiabilityLimit: number) {
    this._quotationContractMaxBondLiabilityLimit = quotationContractMaxBondLiabilityLimit;
    if (quotationContractMaxBondLiabilityLimit && this.contractBondTypesList) {
      this.contractBondTypesList.resetControls();
    }
  }
  get quotationContractMaxBondLiabilityLimit(): number {
    return this._quotationContractMaxBondLiabilityLimit;
  }

  private _quotationContractMaxSingleBondLimit: number;
  @Input() set quotationContractMaxSingleBondLimit(quotationContractMaxSingleBondLimit: number) {
    this._quotationContractMaxSingleBondLimit = quotationContractMaxSingleBondLimit;
    if (quotationContractMaxSingleBondLimit && this.contractBondTypesList) {
      this.contractBondTypesList.resetControls();
    }
  }
  get quotationContractMaxSingleBondLimit(): number {
    return this._quotationContractMaxSingleBondLimit;
  }

  cellChangeCalled(event: CellChangeEvent<number>) {
    this.contractBondTypesList.resetControls();
    this.onCellChange.emit(event);
  }

  onAddUpdateDelete() {
    this.addUpdateDelete.emit();
  }
}
