import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {VersionInfoComponent} from './version-info.component';
import {SingleVersionInfoComponent} from './components/single-version-info.component';
import {PropertyListModule} from '../../bonding_shared/components/property-list/property-list.module';

@NgModule({
  imports: [BrowserModule, PropertyListModule],
  declarations: [VersionInfoComponent, SingleVersionInfoComponent],
})
export class VersionInfoModule {}
