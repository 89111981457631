import {Component, forwardRef, Input} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {ClaimAdditionalInformationDto} from '../../../bonding_shared';

const ECG_CLAIM_ADDITINAL_INFORMATION_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EcgClaimAdditionalInformationComponent),
  multi: true,
};
const ECG_CLAIM_ADDITINAL_INFORMATION_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => EcgClaimAdditionalInformationComponent),
  multi: true,
};

@Component({
  selector: 'ecg-claim-additional-information',
  templateUrl: 'ecg-claim-additional-information.component.pug',
  providers: [ECG_CLAIM_ADDITINAL_INFORMATION_CONTROL_VALUE_ACCESSOR, ECG_CLAIM_ADDITINAL_INFORMATION_VALIDATOR],
})
export class EcgClaimAdditionalInformationComponent implements ControlValueAccessor, Validator {
  @Input()
  presentationMode = false;
  @Input()
  showErrors = false;

  formGroup: UntypedFormGroup;
  controlVisibilityMatrix: {[key: string]: boolean} = {};

  constructor(formBuilder: UntypedFormBuilder) {
    this.formGroup = formBuilder.group({
      discretionaryLimit: [''],
      bankAccountNumber: [''],
      beneficiaryName: [''],
    });

    this.controlVisibilityMatrix = {
      discretionaryLimit: false,
      bankAccountNumber: false,
      beneficiaryName: false,
    };
  }

  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe((value) => {
      const {
        discretionaryCounterparty,
        thirdPartyInvolved,
        prodRiskCreditInsuranceCollectionExists,
        ...changedAdditionalInformation
      } = value;
      fn(changedAdditionalInformation);
    });
  }

  registerOnTouched(fn: any): void {}

  writeValue(additionalInformationDto: ClaimAdditionalInformationDto): void {
    if (additionalInformationDto) {
      this.formGroup.patchValue(additionalInformationDto);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.formGroup.valid ? null : {additionalInformationNotValid: true};
  }
}
