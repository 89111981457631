import {Component, Input} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {LimitRequestDto} from '../../../bonding_shared/model';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';

@Component({
  selector: 'limit-collaterals-section',
  templateUrl: './limit-collaterals-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class LimitCollateralsSectionComponent extends ListEmitters {
  @Input() showErrors: boolean;
  @Input() expandable: boolean;
  @Input() bondingLimit: boolean;

  showBill = false;
  showMortgage = false;
  showCollateralOther = false;
  request: LimitRequestDto;

  @Input() set limitRequest(req: LimitRequestDto) {
    this.request = req;
    this.setCheckboxes();
  }

  setCheckboxes() {
    this.showBill = !!this.request.bill;
    this.showMortgage = !!this.request.mortgageLandRegisterNo;
    this.showCollateralOther = !!this.request.collateralsComment;
  }

  showCheckboxChanged() {
    if (!this.showMortgage) {
      this.request.mortgageLandRegisterNo = undefined;
      this.request.mortgageComment = undefined;
    }
    if (!this.showBill) {
      this.request.bill = undefined;
    }
    if (!this.showCollateralOther) {
      this.request.collateralsComment = undefined;
    }
  }
}
