import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {RouterModule} from '@angular/router';
import {portalRoutes} from './app-portal.routes';
import {PortalLoginModule} from './portal-login/portal-login.module';
import {PageNotFoundModule} from '../../../../src/client/app/404/404.module';
import {SharedModule} from '../../../../src/client/app/shared/shared.module';
import {PortalModule} from '../../../../src/client/app/portal/portal.module';
import {LoginPortalService} from '../../../../src/client/app/portal/services';
import {AbstractLoginService} from '../../../../src/client/app/login/login.service';
import {LoggedUserService} from '../../../../src/client/app/bonding_shared/services';
import {HttpBackend, HttpClientModule} from '@angular/common/http';
import {APP_CONFIG} from '../environments/environment';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {AppPortalComponent} from './app-portal.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BondingModule} from '../../../../src/client/app/bonding/bonding.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GrowlModule} from '../../../../src/client/app/bonding_shared/services/growl/growl.module';
import {BondVerifyModule} from './bond-verify/bond-verify.module';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CustomTranslateCompiler, CustomTranslateLoaderFactory} from '../../../../src/client/app/common/translate';
import {AppInitializerModule} from '../../../../src/client/app/common/initialize';
import {BondingContractOfferAbstractService} from '../../../../src/client/app/bonding_shared/services/bonding-contract-offer-abstract.service';
import {PortalBondingContractOfferService} from '../../../../src/client/app/portal/services/portal-bonding-contract-offer.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule.forRoot(portalRoutes),
    PortalLoginModule,
    PageNotFoundModule,
    BondingModule,
    PortalModule,
    SharedModule.forRoot(),
    GrowlModule.forRoot(),
    BondVerifyModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: CustomTranslateLoaderFactory, deps: [HttpBackend]},
      compiler: {provide: TranslateCompiler, useClass: CustomTranslateCompiler},
      defaultLanguage: 'en',
    }),
    AppInitializerModule,
  ],
  declarations: [AppPortalComponent],
  providers: [
    {
      provide: 'app.config',
      useValue: APP_CONFIG,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: AbstractLoginService,
      useClass: LoginPortalService,
    },
    {
      provide: BondingContractOfferAbstractService,
      useClass: PortalBondingContractOfferService,
    },
    LoggedUserService,
  ],
  bootstrap: [AppPortalComponent],
})
export class AppPortalModule {}
