<details-view [parentComponent]="self" [fixedBlockHidden]="false" [versionListHidden]="true" [objectName]="'Document'" [title]="'document.details.caption'">
  <div class="information-header">
    <business-object-info *ngIf="businessObject()" [objectId]='businessObject()?.relatedToId' [typeId]='businessObject()?.relatedTo.id'></business-object-info>
  </div>
  <div class="details-content">
    <form #documentForm="ngForm">
      <bon-section labelKey="document.details.documentData">
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <form-row labelKey="document.details.templateName">{{document?.template?.name}}</form-row>
            <form-row labelKey="document.details.companyName">{{businessObject()?.reference?.registrationName}}</form-row>
            <form-row labelKey="document.details.lastModifiedDate">{{document?.lastModifiedDate | datetime}}</form-row>
            <form-row labelKey="document.details.lastModifiedBy">
                {{document?.lastModifiedBy?.familyName}}, {{document?.lastModifiedBy?.name}}
            </form-row>
          </div>
          <div class="bon-card-inner">
            <form-row labelKey="document.details.relatedTo">{{document?.businessObject?.description}}</form-row>
            <form-row></form-row>
            <form-row labelKey="document.details.creationDate">{{document?.creationDate | datetime}}</form-row>
            <form-row labelKey="document.details.createdBy">
              {{document?.createdBy?.familyName}}, {{document?.createdBy?.name}}
            </form-row>
            <dict-row *ngIf="document?.copyType?.id" label="Copy for" dictionary="RepoDocumentCategory" [ngModel]="document.copyType"
                      name="copyType" [presentationMode]="true"></dict-row>
          </div>
          <div class="bon-card-inner">
          </div>
        </div>
      </bon-section>

      <bon-section *ngIf="document" labelKey="document.details.content">
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <div class="bon-row">
              <html-editor [(ngModel)]="document.content" (modeChange)="onEditorModeChange($event)" name="content" [height]="200"
                           [readonly]="document.template?.documentEditionBlocked"></html-editor>
            </div>
          </div>
        </div>
      </bon-section>

      <bon-section *ngIf="!appService.mehib" labelKey="document.details.emailAddresses">
        <div class="bon-card-group">
          <div class="bon-card-inner" *ngIf="emailRecipientGroups.length > 0">
            <div><b class="font-larger" translate="document.details.serviceContactsEmailAddresses"></b></div>
            <ng-container *ngFor="let group of emailRecipientGroups">
              <b class="font-larger" *ngIf="group.all.type || group.all.company">
                <span *ngIf="group.all.company">{{group.all.company.registrationName}}</span>
                <span *ngIf="group.all.type"> ({{ group.all.type.name}})</span>
              </b>
              <b-table [items]="group.selected" [archetypeEntries]="group.all.persons" (itemEdition)="updateSendEmailButton()" (addUpdateDelete)="updateSendEmailButton()" [editable]="true" [addButton]="false">
                <column property="person" type="archetype" archetypeLabel="email" labelKey="document.details.emailAddress"></column>
                <column type="archetypeCheckbox" cellWidth="25px"></column>
              </b-table>
            </ng-container>
          </div>
          <div class="bon-card-inner">
            <b class="font-larger" translate="document.details.additionalEmailAddresses"></b>
            <a-table [items]="additionalEmailAddresses" (addUpdateDelete)="updateSendEmailButton()"
                (done)="updateSendEmailButton()" [editable]="true" [formModel]="documentForm.form">
              <column property="email" labelKey="document.details.emailAddress" type="email" required></column>
            </a-table>
          </div>
          <div class="bon-card-inner" *ngIf="emailRecipientGroups.length == 0"></div>
        </div>
      </bon-section>

    </form>
  </div>
  <div class="additional-buttons">
    <bon-button class="bon-button" [button]="sendEmailButton" [inProgress]="inProgress"  defaultClass="bon-btn-warning" ></bon-button>
    <button type="button" (click)="downloadPdfWithoutSending()" class="bon-btn-warning" [disabled]="downloadPdfDisabled()" translate>document.details.downloadPdf</button>
    <button *ngIf="dev" type="button" (click)="generatePdf()" class="bon-btn-warning" [disabled]="!canSave" translate>document.details.generatPdf</button>
    <button *ngIf="isProposals() && showGenerateProposalPdf()" type="button" (click)="generateProposalPdf()" class="bon-btn-warning" [disabled]="!canSave || !docTypeId" translate>document.details.generateProposalPdf</button>
    <button *ngIf="isWordings()" type="button" (click)="generateWordingHtml()" class="bon-btn-warning" [disabled]="!canSave || !docTypeId" translate>document.details.generateWordingHtml</button>
    <button *ngIf="isSigning()" type="button" (click)="generateSigningPdf()" class="bon-btn-warning" [disabled]="!canSave || !docTypeId" translate>document.details.generateSigningPdf</button>
    <button *ngIf="isMarkAsSent()" type="button" (click)="markAsSent()" class="bon-btn-warning" translate>document.details.markAsSent</button>
  </div>
</details-view>
