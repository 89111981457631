/**
 * Created by siminski on 30.06.2016.
 */
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, ContentChild, EventEmitter, forwardRef, Input, Output, TemplateRef} from '@angular/core';

const ITEM_ID_COMBO_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ItemIdComboComponent),
  multi: true,
};

@Component({
  selector: 'item-id-combo',
  template: `
    <select
      *ngIf="!presentationMode"
      [(ngModel)]="selectedKey"
      (change)="selectedKey = $event.target.value"
      [disabled]="disabled"
      class="bon-select"
    >
      <option *ngIf="nullLabel || nullLabelKey" value="undefined">
        {{ nullLabel ? nullLabel : (nullLabelKey | translate) }}
      </option>
      <option *ngFor="let item of items" [value]="item[key]">
        <span *ngIf="!template">{{ item[label] }}</span>
        <span *ngIf="template">
          <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{item: item}"></ng-container>
        </span>
      </option>
    </select>
    <ng-container *ngIf="presentationMode">
      <span *ngIf="!template">{{ (selectedItem && selectedItem[label]) || '' }}</span>
      <span *ngIf="template">
        <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{item: selectedItem}"></ng-container>
      </span>
    </ng-container>
  `,
  providers: [ITEM_ID_COMBO_CONTROL_VALUE_ACCESSOR],
})
export class ItemIdComboComponent<T> implements ControlValueAccessor {
  @Input() key = 'id';
  @Input() label: String;
  @Input() nullLabel: String;
  @Input() nullLabelKey: String;
  @Input() disabled: any;
  @Input() presentationMode = false;

  @Output() changeItem = new EventEmitter<number>();

  @ContentChild(TemplateRef, {static: true}) template: TemplateRef<any>;

  private onChangeModel: Function;
  private onTouchedModel: Function;
  private _items: Array<T>;
  private _selectedKey: number;
  private selectedItem: T;
  private selectedKeyFromExternal: number;

  @Input() set items(items: Array<T>) {
    this._items = items;
    this.updateSelection();
  }

  get items(): Array<T> {
    return this._items;
  }

  get selectedKey(): any {
    return this._selectedKey;
  }

  set selectedKey(k: any) {
    if (k !== this._selectedKey) {
      this._selectedKey = k === 'undefined' ? undefined : k;
      this.selectedItem = this.findSelectedItem(k);
      this.onChangeModel(this._selectedKey);
      this.changeItem.emit(this._selectedKey);
    }
  }

  writeValue(id: number): void {
    this.selectedKeyFromExternal = id;
    this.updateSelection();
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedModel = fn;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private updateSelection() {
    if (this.selectedKeyFromExternal) {
      this.selectedItem = this.findSelectedItem(this._selectedKey);
      this._selectedKey = this.selectedKeyFromExternal;
    } else {
      this.selectedItem = undefined;
      this._selectedKey = undefined;
    }
  }

  private findSelectedItem(k: any): T {
    if (k && this._items) {
      for (const it of this._items) {
        if (String((<any>it)[this.key]) === String(k)) {
          return it;
        }
      }
    }
    return undefined;
  }
}
