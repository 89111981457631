import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {LoggedUserService} from '../services';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public service: LoggedUserService, public router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          const error = err.error;
          if (error instanceof Blob) {
            from(error.text()).pipe(mergeMap((errorMessage) => this.handleError(errorMessage, request, next)));
          } else {
            this.handleError(error, request, next);
          }
        } else {
          return throwError(() => err);
        }
      })
    );
  }

  handleError(error: string, request: HttpRequest<any>, next: HttpHandler) {
    if (['EXPIRED_REFRESH_TOKEN', 'WRONG_REFRESH_TOKEN'].includes(error)) {
      if (this.service._portal) {
        this.router.navigate(['login', {errorCode: 'ERROR'}]);
      } else {
        console.log('UNAUTHORIZED ERROR: ', error);
        this.router.navigate(['login', {errorCode: error}]);
      }
    } else if (error === 'EXPIRED_JWT_TOKEN') {
      this.router.navigate(['login', {errorCode: 'ERROR'}]);
    } else {
      this.router.navigate(['login', {errorCode: 'UNKNOWN'}]);
    }
    this.service.clearToken();
    return null;
  }
}
