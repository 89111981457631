import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PortalSidebarComponent} from './portal-sidebar.component';
import {VersionSummaryModule} from '../../bonding_shared/components/version-summary/version-summary.module';
import {CommonModule} from '@angular/common';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';

@NgModule({
  imports: [RouterModule, VersionSummaryModule, CommonModule, SharedComponentsModule],
  declarations: [PortalSidebarComponent],
  exports: [PortalSidebarComponent],
})
export class PortalSidebarModule {}
