import {from as observableFrom} from 'rxjs';

import {concatMap} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {CompanyService} from '../../bonding_shared/services/company.service';
import {
  DictionaryDto,
  MasterPolicyContractBaseDto,
  MasterPolicyContractDto,
  PolicyContractBaseDto,
  PolicyContractDto,
  PolicyContractTechnicalDetailsAttendantsDto,
  PolicyContractVersionDto,
} from '../../bonding_shared/model/dtos';
import {NumberUtils} from '../../bonding_shared/utils/number-utils';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  PolicyContractViewSection,
  PolicyContractViewService,
} from '../../bonding/policy/services/policy-contract-view.service';
import {PercentPipe} from '@angular/common';
import {PortalRouterService} from '../services/portal-router.service';
import {PolicyContractVersionListComponent} from '../../bonding/policy/components/shared/policy-contract-version-list.component';
import {CountryRatingService} from '../../bonding_shared/services/country-rating.service';
import {DictionaryService} from '../../bonding_shared/services/dictionary.service';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {PortalPolicyContractVersionService} from '../services/portal-policy-contract-version.service';
import {IMultiSelectSettings} from '../../bonding_shared/components/multiselect-dropdown/multiselect-dropdown';
import {AppConfigService} from '../../bonding_shared/services';
import {PolicyContractVersionStatus} from '../../bonding_shared/model/dictionary-ids';
import {InquiryAbstractService} from '../../bonding_shared/services/inquiry-abstract.service';

@Component({
  selector: 'client-policy-preview',
  templateUrl: '../inquiry/client-policy-preview.component.pug',
})
export class ClientPolicyPreviewComponent extends DetailsView implements OnInit {
  @ViewChild(PolicyContractVersionListComponent, {static: true})
  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    selectionLimit: 1000,
  };

  private insuredCountryGroupsOptions: DictionaryDto[];

  contractVersionListComponent: PolicyContractVersionListComponent;

  public selectedPolicyContractVersion: PolicyContractVersionDto;
  masterPolicyContract: MasterPolicyContractDto;

  policyContractTypeSet: boolean;
  versionListReady: boolean;

  public attendants: PolicyContractTechnicalDetailsAttendantsDto;
  viewSection = PolicyContractViewSection;

  private countriesById: DictionaryDto[];
  private countryRatingsByCountryId: number[];

  constructor(
    private _companyService: CompanyService,
    private route: ActivatedRoute,
    private routerService: PortalRouterService,
    private policyContractViewService: PolicyContractViewService,
    private portalPolicyService: PortalPolicyContractVersionService,
    protected growlService: GrowlService,
    private dictionaryService: DictionaryService,
    private countryRatingService: CountryRatingService,
    public appService: AppConfigService,
    protected inquiryService: InquiryAbstractService
  ) {
    super(growlService);

    this.selectedPolicyContractVersion = <PolicyContractVersionDto>{};
    this.selectedPolicyContractVersion.policyContract = <PolicyContractDto>{};
    this.selectedPolicyContractVersion.policyContract.masterPolicyContract = <MasterPolicyContractDto>{};
    this.attendants = <PolicyContractTechnicalDetailsAttendantsDto>{};
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
    this.dictionaryService.getDictionary('Country').subscribe((countries) => {
      this.countriesById = new Array<DictionaryDto>();
      countries.forEach((country) => (this.countriesById[country.id] = country));
    });
    this.countryRatingService
      .getLastCountryRatings()
      .pipe(
        concatMap((ratings) => {
          this.countryRatingsByCountryId = [];
          return observableFrom(ratings);
        })
      )
      .subscribe((ratingVersion) => {
        this.countryRatingsByCountryId[ratingVersion.countryRating.country.id] = +ratingVersion.oecdRating.code;
      });

    this.dictionaryService.getDictionary('EconomicCountryGroup').subscribe((data) => {
      for (const group of data) {
      }
      this.insuredCountryGroupsOptions = data;
    });
  }

  initializeView(params: Params, force?: boolean) {
    const id = +params['id'];
    if (id === this.selectedPolicyContractVersion.id && !force) {
      return;
    }
    this.versionListReady = false;
    const clientId = +params['clientId'];
    console.log('initialize view: contract-details id = ' + id + ', clientId = ' + clientId);
    this.initializeContract(id, clientId);
    this.portalPolicyService.getTechnicalAttendants(id).subscribe(
      (data) => {
        this.attendants = data;
      },
      (error) => {},
      () => {}
    );
  }

  initializeContract(contractVersionId: number, clientId: number) {
    console.log('initializeContract (with version)');
    this.selectedPolicyContractVersion = <PolicyContractVersionDto>{};
    console.log(this.selectedPolicyContractVersion);
    if (contractVersionId > 0) {
      this.policyContractTypeSet = true;
      this.portalPolicyService.getPolicyContractVersion(contractVersionId).subscribe({
        next: (policyContractVersion) => this.setPolicyContractVersion(policyContractVersion),
        error: () => console.log('Something went wrong on policyContractVersionService.getPolicyContractVersion'),
      });
    } else {
      if (clientId && clientId > 0) {
        this.initializeCompany(clientId);
      }
      this.policyContractTypeSet = false;
      this.selectedPolicyContractVersion.policyContract.masterPolicyContract = <MasterPolicyContractBaseDto>{};
      this.selectedPolicyContractVersion.policyContract.policyYear = 1;
      this.selectedPolicyContractVersion.policyContract.policySubYear = 1;
      this.selectedPolicyContractVersion.policyContract.policySubYears = 1;
    }
  }

  initializeCompany(clientId: number) {
    this._companyService.getCompanySimple(clientId).subscribe((company) => {
      this.selectedPolicyContractVersion.client = company;
    });
  }

  setPolicyContract(policyContract: PolicyContractDto, reloadPolicyVersion: boolean) {
    console.log('<<<<<<<<< set Contract >>>>>>>>> ', policyContract);
    if (this.selectedPolicyContractVersion.policyContract.masterPolicyContract.id) {
      this.portalPolicyService
        .getMasterPolicyContract(this.selectedPolicyContractVersion.policyContract.masterPolicyContract.id)
        .subscribe((masterPolicy) => (this.masterPolicyContract = masterPolicy));
    }
  }

  setPolicyContractVersion(policyContractVersion: PolicyContractVersionDto) {
    console.log('<<<<<<<<< set Contract Version >>>>>>>>> ', policyContractVersion);
    this.policyContractTypeSet = true;
    this.selectedPolicyContractVersion = policyContractVersion;

    console.log(this.selectedPolicyContractVersion.validFrom, this.selectedPolicyContractVersion.validTo);
    if (this.contractVersionListComponent) {
      this.contractVersionListComponent.selectedPolicyContractVersion = policyContractVersion;
    }
    if (this.selectedPolicyContractVersion.policyContract.masterPolicyContract.id) {
      this.portalPolicyService
        .getMasterPolicyContract(this.selectedPolicyContractVersion.policyContract.masterPolicyContract.id)
        .subscribe((masterPolicy) => (this.masterPolicyContract = masterPolicy));
    }
  }

  onSelectPolicyContractVersion(policyContractVersion: PolicyContractVersionDto) {
    console.log(
      'onSelectContractVersion :: validFrom: ',
      typeof policyContractVersion.validFrom,
      policyContractVersion.validFrom
    );
    this.portalPolicyService.getPolicyContractVersion(policyContractVersion.id).subscribe((pcv) => {
      this.setPolicyContractVersion(pcv);
      this.routerService.toClientPolicyPreview(this.selectedPolicyContractVersion.id);
    });
  }

  onSelectPolicyContract(policyContract: PolicyContractBaseDto, selectLastPolicyContractVersion?: boolean) {
    console.log('onSelectPolicyContract :: id = ' + policyContract.id);
    this.portalPolicyService.getPolicyContract(policyContract.id).subscribe((pc) => {
      console.log('onSelectPolicyContract ::', pc);
      this.setPolicyContract(pc, true);
      if (selectLastPolicyContractVersion) {
        this.onSelectPolicyContractVersion(pc.versions[pc.versions.length - 1]);
      }
    });
  }

  shouldShowPercent(a: number, b: number) {
    return NumberUtils.isValidPercent(a, b);
  }

  percentIfValid(a: number, b: number) {
    if (NumberUtils.isValidPercent(a, b)) {
      // TODO: locale to be corrected
      return '(' + new PercentPipe('en-us').transform(a / b) + ')';
    }
    return '';
  }

  isSectionVisible(viewSection: PolicyContractViewSection) {
    return this.policyContractViewService.isSectionVisible(
      this.selectedPolicyContractVersion.contractType.id,
      viewSection,
      false
    );
  }

  onChangePolicyVersion(policyContractVersion: PolicyContractVersionDto) {
    this.onSelectPolicyContractVersion(policyContractVersion);
  }

  onChangePolicyYear(policyContract: PolicyContractBaseDto) {
    this.onSelectPolicyContract(policyContract, true);
  }

  setPolicyYear(year: number) {
    if (+year <= this.masterPolicyContract.policyContracts.length) {
      this.onChangePolicyYear(this.masterPolicyContract.policyContracts.find((item) => item.policyYear === +year));
    }
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  createNewAnnexInquiry() {
    this.inProgress = true;
    this.inquiryService.createAnnexInquiry(this.selectedPolicyContractVersion.id).subscribe({
      next: (inquiryVersionId) => this.routerService.toPolicyAnnexInquiryDetails(inquiryVersionId),
      error: (error) => this.handleServerError(error),
      complete: () => (this.inProgress = false),
    });
  }

  get annexInquiryAvailable(): boolean {
    return (
      this.appService.kuke ||
      [PolicyContractVersionStatus.BS_ACTIVE].includes(this.selectedPolicyContractVersion.businessStatus.id)
    );
  }
}
