import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {ControlContainer, NgForm} from '@angular/forms';
import {AppConfigService, PolicyContractVersionService} from '../../../../../bonding_shared/services';
import {CompanySimpleDto, RelatedObject} from '../../../../../bonding_shared';
import {RatingScale} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'transaction-parties-section',
  templateUrl: './transaction-parties-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class TransactionPartiesSectionComponent extends Section {
  readonly RatingScale = RatingScale;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected policyContractVersionService: PolicyContractVersionService
  ) {
    super(policyContractViewService, appService, policyContractVersionService);
    this.selectorNameList = [
      RelatedObject.PROJECT_COMPANY,
      RelatedObject.TARGET_COMPANY,
      RelatedObject.TARGET_COMPANY_OWNER,
    ];
    this.initializeSelectorEmitters(true);
  }

  onSelectProjectCompany(company: CompanySimpleDto) {
    this.policy.projectCompany = company;
  }

  onSelectTargetCompany(company: CompanySimpleDto) {
    this.policy.targetCompany = company;
  }

  onSelectTargetCompanyOwner(company: CompanySimpleDto) {
    this.policy.targetCompanyOwner = company;
  }
}
