import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfigService, LoggedUserService} from '../../bonding_shared/services';
import {BondingContractOfferSimpleDto} from '../../bonding_shared/model';
import {HttpClient} from '@angular/common/http';
import {BondingContractOfferAbstractService} from '../../bonding_shared/services/bonding-contract-offer-abstract.service';

@Injectable()
export class PortalBondingContractOfferService extends BondingContractOfferAbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'bondingContractOffer';
  }

  searchPortalBondingOffers(): Observable<BondingContractOfferSimpleDto[]> {
    this.resetSessionTimer();
    const searchUrl = this.url + '/searchPortalBondingOffers';
    return this.get<BondingContractOfferSimpleDto[]>(searchUrl);
  }
}
