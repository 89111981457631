import {Component, Input, OnInit} from '@angular/core';
import {PolicyContractLossRatioDto} from '../../../../bonding_shared/model';
import {PolicyContractVersionService} from '../../../../bonding_shared/services';

@Component({
  selector: 'policy-loss-ratio',
  templateUrl: './policy-loss-ratio.component.html',
})
export class PolicyLossRatioComponent implements OnInit {
  @Input()
  policyContractVersionId: number;
  @Input()
  skipPaid = false;

  lossRatio: PolicyContractLossRatioDto;

  constructor(private policyContractVersionService: PolicyContractVersionService) {}

  ngOnInit(): void {
    setTimeout((_) => this.loadLossRation(), 500);
  }

  private loadLossRation() {
    this.policyContractVersionService.countLossRatio(this.policyContractVersionId, this.skipPaid).subscribe(
      (lossRation) => (this.lossRatio = lossRation),
      (err) => {
        console.log('Getting loss ratio problem', err);
        this.lossRatio = null;
      }
    );
  }
}
