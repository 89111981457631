import {Component, OnInit, ViewChild} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {InstalmentsWizardComponent} from '../..';
import {AppConfigService, DictionaryService} from '../../../../../bonding_shared/services';
import {ControlContainer, UntypedFormGroup, NgForm} from '@angular/forms';

@Component({
  selector: 'kuke-installments-section',
  templateUrl: './kuke-installments-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class KukeInstallmentsSectionComponent extends Section implements OnInit {
  self = this;

  @ViewChild('instalmentsWizard', {static: true}) instalmentsWizardComponent: InstalmentsWizardComponent;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected dictionaryService: DictionaryService
  ) {
    super(policyContractViewService, appService);
  }

  ngOnInit(): void {}

  getIndex(item) {
    return this.policy.premiumInstalementCalendar.indexOf(item) + 1;
  }

  get calendarEditable(): boolean {
    return !this.readonly;
  }

  updateInsuredsValidity() {
    const calendarFormGroup = <UntypedFormGroup>this.policyForm.controls['insureds'];
    calendarFormGroup.controls['premiumInstalmentCalendar'].setValue(this.policy.premiumInstalementCalendar);
    calendarFormGroup.controls['subinsured'].setValue(this.policy.subinsured);
    if (calendarFormGroup.controls['mainInsuredShare']) {
      calendarFormGroup.controls['mainInsuredShare'].setValue(this.policy.mainInsuredShare);
    }
    calendarFormGroup.updateValueAndValidity({
      onlySelf: false,
      emitEvent: false,
    });
  }
}
