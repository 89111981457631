/**
 * Created by siminski on 09.12.2016.
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {ParameterDefinitionDto, ReportDefinitionSimpleDto, SearchResult} from '../model/dtos';
import {BinaryDownloaderService} from './binary-downloader.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GenerateReportIfc} from './abstract-service-interfaces';

@Injectable()
export class BusinessReportService extends AbstractService implements GenerateReportIfc {
  protected url = this.urlPrefix + 'businessReport';
  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    public binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getAll(moduleId: number): Observable<SearchResult<ReportDefinitionSimpleDto>> {
    if (moduleId) {
      return this.get<SearchResult<ReportDefinitionSimpleDto>>(this.url + '?moduleId=' + moduleId);
    } else {
      return this.get<SearchResult<ReportDefinitionSimpleDto>>(this.url + '/');
    }
  }

  getParameters(definitionId: number): Observable<ParameterDefinitionDto[]> {
    return this.get<ParameterDefinitionDto[]>(this.url + '/parameters/' + definitionId);
  }

  generate(
    definitionId: number,
    outputFileName: string,
    httpParams: HttpParams,
    errorCallback?: (errMsg: any) => void,
    completeCallback?: (file: File) => void
  ) {
    const url = this.url + '/generate/' + definitionId + '/report.xlsx?';
    this.binaryDownloader.download(
      'GET',
      url + this.buildUrlParams(httpParams),
      'application/octet-stream',
      'application/octet-stream',
      outputFileName,
      undefined,
      errorCallback,
      completeCallback
    );
  }

  generateAsync(definitionId: number, outputFileName: string, httpParams: HttpParams) {
    const url = this.url + '/generateAsync/' + definitionId + '/report.xlsx?' + this.buildUrlParams(httpParams);
    return this.get<string>(url);
  }
}
