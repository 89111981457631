import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {Directive} from '@angular/core';
import {DictionaryBaseDto} from '../../../bonding_shared';
import {ClaimDocumentsStatus} from '../../../bonding_shared/model/dictionary-ids';
import {DictionaryUtils} from '../../../bonding_shared/utils/dictionary-utils';

@Directive({
  selector: '[kuke-completeness-check-validator]',
  providers: [{provide: NG_VALIDATORS, useExisting: KukeCompletenessCheckDirective, multi: true}],
})
export class KukeCompletenessCheckDirective implements Validator {
  validate(formGroup: UntypedFormGroup): ValidationErrors | null {
    const completenessCheckAllDocumentsOkSet =
      <Date>formGroup.controls['completenessCheckAllDocumentsOk']?.value !== undefined;
    const isDocumentsCompletedStatusSet = DictionaryUtils.equalsDictAndId(
      <DictionaryBaseDto>formGroup.controls['completenessCheckStatus']?.value,
      ClaimDocumentsStatus.DOCUMENTS_COMPLETED
    );
    return completenessCheckAllDocumentsOkSet === isDocumentsCompletedStatusSet
      ? null
      : {completenessCheckFinalStatusError: true};
  }
}
