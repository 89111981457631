import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoggedUserService, PropertyService} from '../../../../../src/client/app/bonding_shared/services/index';
import {AppConfigService} from '../../../../../src/client/app/bonding_shared/services/app-config.service';
import {RouterService} from '../../../../../src/client/app/bonding_shared/services/router-service';
import {LOGIN_ERROR, UserDto} from '../../../../../src/client/app/bonding_shared/model/dtos';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'login-cmp',
  templateUrl: 'portal-login.component.html',
})
export class PortalLoginComponent implements OnInit {
  public login: string;
  public password: string;
  public errorMessage: string;
  private lastLoggedUserLanguageId: number;
  public afterResetMessage = 'Please, log in';
  pendingLogin = false;

  constructor(
    public _router: Router,
    private _route: ActivatedRoute,
    private _loggedUserService: LoggedUserService,
    public appConfigService: AppConfigService,
    private routerService: RouterService,
    private propertyService: PropertyService,
    private translateService: TranslateService
  ) {}

  goToSelectedView() {
    if (this.lastLoggedUserLanguageId !== undefined && this._loggedUserService.getLoggedUserData() !== undefined) {
      if (this.lastLoggedUserLanguageId !== this._loggedUserService.getLoggedUserData().language.id) {
        this._loggedUserService.setReloadNecessary(true);
      }
    }
    if (this._loggedUserService.redirectUrl) {
      this._router.navigateByUrl(this._loggedUserService.redirectUrl);
    } else {
      this.routerService.toStartPage();
    }
  }

  ngOnInit() {
    this._loggedUserService.clearToken();
    this._route.params.subscribe((params) => {
      const message: string = params['afterResetMessage'];
      if (message && message.length > 0) {
        this.afterResetMessage = message;
      }
      const errorCode: string = params['errorCode'];
      if (errorCode && errorCode.length > 0) {
        this.errorMessage = 'errorCode.' + errorCode;
      }
    });
  }

  doLogin() {
    if (this._loggedUserService.getLoggedUserData() !== undefined) {
      this.lastLoggedUserLanguageId = this._loggedUserService.getLoggedUserData().language.id;
    }
    this.pendingLogin = true;
    if (this.login && this.password) {
      this._loggedUserService.login(this.login, this.password).subscribe({
        next: (u) => {
          this._loggedUserService.sessionTimer.reset();
          this.errorMessage = '';
          this.initPropertiesAndLoadView(u);
        },
        error: (error) => {
          this.pendingLogin = false;
          this.handleError(error);
        },
      });
    } else {
      this.pendingLogin = false;
      this.errorMessage = this.translateService.instant('FILL_BOTH_LOGIN_AND_PASSWORD');
    }
  }

  initPropertiesAndLoadView(user: UserDto) {
    this.translateService.use(user.language.code);
    this.propertyService.initProperties().subscribe({
      next: (value) => {
        this.goToSelectedView();
      },
      error: (error) => {
        this.handleError(error);
      },
    });
  }

  // TODO:: different error msg for status 401 and others
  private handleError(error: any) {
    console.error('Error on login: status = ' + error.status + ', error = ', error);
    if (error instanceof Error) {
      this.errorMessage = this.getLoginErrorMsg(<LOGIN_ERROR>(<Error>error).message);
    } else {
      this.errorMessage = 'Incorrect login or password';
    }
  }

  private getLoginErrorMsg(error: LOGIN_ERROR): string {
    return this.translateService.instant(error);
  }
}
