/**
 * Created by siminski on 09.12.2016.
 */
import {Component, OnInit, ViewChild} from '@angular/core';
import {BusinessReportService} from '../../bonding_shared/services/business-report.service';
import {SettingsService} from '../../bonding_shared/services/settings.service';
import {ParameterDefinitionDto, ReportDefinitionSimpleDto} from '../../bonding_shared/model/dtos';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {TreeNode} from 'primeng/api';
import {DictTreeComponent} from '../../bonding_shared/components/bon-tree/dict-tree.component';
import {NgForm} from '@angular/forms';
import {ParameterListComponent} from '../../bonding_shared/components/parameter-list/parameter-list.component';
import {HttpParams} from '@angular/common/http';
import {AppConfigService} from '../../bonding_shared';

@Component({
  selector: 'business-report-generation',
  templateUrl: 'business-report-generation.component.html',
})
export class BusinessReportGenerationComponent extends DetailsView implements OnInit {
  errorMessage: string;
  reportDefinitions: ReportDefinitionSimpleDto[];
  selectedDefinition: ReportDefinitionSimpleDto;

  parameterDefinitions: ParameterDefinitionDto[];
  parameterValues: any = {};

  applicationModuleId: number;
  lastApplicationModuleId: number;

  @ViewChild(NgForm, {static: true})
  set parentForm(form: NgForm) {
    if (form) {
      this.form = form.form;
    }
  }

  @ViewChild(ParameterListComponent, {static: true}) parameterList: ParameterListComponent;

  constructor(
    public businessReportService: BusinessReportService,
    private settingsService: SettingsService,
    protected growlService: GrowlService,
    private appService: AppConfigService
  ) {
    super(growlService);
  }

  ngOnInit() {
    this.applicationModuleId = this.calculateApplicationModuleId();
    this.lastApplicationModuleId = this.applicationModuleId;
    this.businessReportService.getAll(this.applicationModuleId).subscribe({
      next: (searchResult) => {
        this.reportDefinitions = searchResult.result;
      },
      error: (error) => (this.errorMessage = <any>error),
    });
  }

  refreshDefinitionParams() {
    // this.parameterDefinitions = undefined;
    if (this.selectedDefinition) {
      this.businessReportService.getParameters(this.selectedDefinition.id).subscribe({
        next: (parameterDefinitions) => {
          this.setParameters(parameterDefinitions);
        },
        error: (error) => (this.errorMessage = <any>error),
      });
    }
  }

  setParameters(parameterDefinitions: ParameterDefinitionDto[]) {
    this.serverErrors = '';
    this.showErrors = false;
    console.log('setParameters (parent), parameterDefinitions: ', parameterDefinitions);
    this.parameterDefinitions = parameterDefinitions;
  }

  generate() {
    this.serverErrors = undefined;
    if (!this.selectedDefinition) {
      return;
    }
    this.showErrors = true;
    if (!this.form.valid) {
      return;
    }
    let params = new HttpParams();
    for (const key of Object.keys(this.parameterValues)) {
      const value = this.parameterValues[key];
      console.log('key ' + key + ' value ' + value);
      if (value) {
        const parsedValue = this.parameterList.parse(value);
        params = params.append(key, parsedValue);
      }
    }
    const fileName = this.selectedDefinition.name + '.' + this.selectedDefinition.extension;
    if (this.selectedDefinition.asynchronous) {
      this.businessReportService.generateAsync(this.selectedDefinition.id, fileName, params).subscribe(
        (res) => {
          this.growlService.notice('Report generation started - check report executions tab to download.');
        },
        (error) => {
          console.log('error', error);
          if (error) {
            this.handleServerError(error);
          } else {
            this.growlService.error('Could not generate report');
          }
        }
      );
    } else {
      this.businessReportService.generate(this.selectedDefinition.id, fileName, params, (error) => {
        if (Array.isArray(error)) {
          this.handleServerError(error);
        } else {
          this.downloadErrorCallback(error);
        }
      });
    }
  }

  isViewChanged(): boolean {
    this.applicationModuleId = this.calculateApplicationModuleId();
    let viewChanged = false;
    if (this.lastApplicationModuleId !== this.applicationModuleId) {
      this.lastApplicationModuleId = this.applicationModuleId;
      viewChanged = true;
    }
    if (viewChanged) {
      this.selectedDefinition = undefined;
      this.refreshDefinitionParams();
      this.businessReportService.getAll(this.applicationModuleId).subscribe({
        next: (searchResult) => {
          this.reportDefinitions = searchResult.result;
        },
        error: (error) => (this.errorMessage = <any>error),
      });
    }
    return true;
  }

  selectDefinition(node: TreeNode) {
    this.selectedDefinition = this.reportDefinitions.filter((d) => d.id === node.data)[0];
    this.refreshDefinitionParams();
    console.log('definition selected:', this.selectedDefinition);
  }

  definitionToTreeNode(d: ReportDefinitionSimpleDto, categoryId: number): TreeNode {
    if (d.category.id !== categoryId) {
      return undefined;
    }
    return DictTreeComponent.createTreeNode(d.name, d.id, true, 'fa fa-gear');
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  private calculateApplicationModuleId() {
    const activeModuleIds = this.settingsService.getActiveModuleIds();
    return activeModuleIds.length === 1 ? activeModuleIds[0] : null;
  }
}
