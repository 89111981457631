import {ChartDataset} from 'chart.js';
import {ChartData} from './chart-data';
import {ChartUtils} from '../chart-utils';

export class BondChartData extends ChartData {
  activeContracts: number[] = [];
  newContracts: number[] = [];
  bondsNumber: number[] = [];
  newBondsNumber: number[] = [];
  bondsValue: number[] = [];

  colors = [
    {backgroundColor: ChartUtils.COLORS[0]},
    {backgroundColor: ChartUtils.COLORS[3]},
    {backgroundColor: ChartUtils.COLORS[1]},
    {backgroundColor: ChartUtils.COLORS[2]},
    {backgroundColor: ChartUtils.COLORS[4]},
  ];

  buildChartData(): ChartDataset[] {
    return [
      {data: this.newBondsNumber, label: 'New Bonds', yAxisID: 'yAxisLeft'},
      {data: this.bondsNumber, label: 'Bonds', yAxisID: 'yAxisLeft'},
      {data: this.newContracts, label: 'New Contracts', yAxisID: 'yAxisLeft'},
      {
        data: this.activeContracts,
        label: 'Active Contracts',
        type: 'line',
        yAxisID: 'yAxisLeft',
        fill: false,
        borderColor: ChartUtils.COLORS[2],
        pointBorderColor: ChartUtils.COLORS[2],
        pointBackgroundColor: ChartUtils.COLORS[2],
      },
      {
        data: this.bondsValue,
        label: 'Exposure',
        type: 'line',
        yAxisID: 'yAxisRight',
        fill: false,
        borderColor: ChartUtils.COLORS[4],
        pointBorderColor: ChartUtils.COLORS[4],
        pointBackgroundColor: ChartUtils.COLORS[4],
      },
    ];
  }

  clear() {
    this.activeContracts = [];
    this.newContracts = [];
    this.bondsNumber = [];
    this.newBondsNumber = [];
    this.bondsValue = [];
    this.dates = [];
  }

  mapData(results: Array<any>) {
    if (!results) {
      return;
    }
    results.forEach((result) => {
      this.dates.push(result.month + '-' + result.year);
      this.activeContracts.push(result.activeContracts);
      this.newContracts.push(result.newContracts);
      this.bondsNumber.push(result.bondsNumber);
      this.newBondsNumber.push(result.newBondsNumber);
      this.bondsValue.push(result.bondsValue);
    });
  }
}
