import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {ZipCodeFormatDto} from '../model';

@Injectable()
export class ZipCodeFormatService extends AbstractService {
  private _numberFormatUrl = this.urlPrefix + 'zipcodeformat';

  constructor(public http: HttpClient, appConfigService: AppConfigService, _loggedUserService: LoggedUserService) {
    super(http, appConfigService, _loggedUserService);
  }

  getZipCodeFormat(countryId: number): Observable<ZipCodeFormatDto> {
    console.log('get getZipCodeFormat: countryId = ' + countryId);
    return this.get<ZipCodeFormatDto>(this._numberFormatUrl + '?countryId=' + countryId);
  }
  getAllZipCodeFormats(): Observable<Array<ZipCodeFormatDto>> {
    console.log('get getAllZipCodeFormats');
    return this.get<Array<ZipCodeFormatDto>>(this._numberFormatUrl + '/getAll');
  }
}
