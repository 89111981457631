import {ContractDetailsComponent} from './contract-details.component';
import {ContractPreviewComponent} from './contract-preview.component';
import {ContractTechnicalDetailsComponent} from './contract-technical-details.component';
import {ContractVersionSearchComponent} from './contract-version-search.component';
import {
  ContractDetailsGuard,
  ContractPreviewGuard,
  ContractSearchGuard,
  ContractTechnicalDetailsGuard,
} from './_guards';

export const ContractRoutes = [
  {
    path: 'contract-details/:id',
    component: ContractDetailsComponent,
    canActivate: [ContractDetailsGuard],
  },
  {
    path: 'contract-details',
    component: ContractDetailsComponent,
    canActivate: [ContractDetailsGuard],
  },
  {
    path: 'contract-preview/:contractId',
    component: ContractPreviewComponent,
    canActivate: [ContractPreviewGuard],
  },
  {
    path: 'contract-version-preview/:contractVersionId',
    component: ContractPreviewComponent,
    canActivate: [ContractPreviewGuard],
  },
  {
    path: 'contract-version-search',
    component: ContractVersionSearchComponent,
    canActivate: [ContractSearchGuard],
  },
  {
    path: 'contract-technical-details/:contractId',
    component: ContractTechnicalDetailsComponent,
    canActivate: [ContractTechnicalDetailsGuard],
  },
];
