import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  CompanySimpleDto,
  PolicyContractCoInsurerCompanyDto,
  PolicyContractVersionDto,
  PolicyInquiryCoInsurerCompanyDto,
  PolicyInquiryVersionDto,
  RelatedObject,
  RouterService,
} from '../../../../../bonding_shared';
import {CompanyType} from '../../../../../bonding_shared/model/dictionary-ids';
import {ListEmitters} from '../../../../../bonding_shared/components/details-view/list-emitters';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'common-co-insurers-section',
  templateUrl: './common-co-insurers-section.component.pug',
})
export class CommonCoInsurersSectionComponent extends ListEmitters {
  readonly CompanyType = CompanyType;

  constructor(public router: RouterService) {
    super();
    this.selectorNameList = [RelatedObject.CO_INSURERS];
    this.initializeSelectorEmitters(true);
  }

  @Input() titleKey: string;
  @Input() selectorLabelKey: string;
  @Input() readonly: boolean;
  @Input() showErrors: boolean;
  @Input() parentForm: UntypedFormGroup;
  @Input() parent: PolicyContractVersionDto | PolicyInquiryVersionDto;

  @Output() addCoInsurer = new EventEmitter<CompanySimpleDto>();

  addInsurer(company: CompanySimpleDto) {
    this.addCoInsurer.emit(company);
  }

  toCompanyPreview(coInsurer: PolicyContractCoInsurerCompanyDto | PolicyInquiryCoInsurerCompanyDto) {
    this.router.toCompanyPreview(coInsurer.company.id);
  }
}
