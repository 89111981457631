import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {RouterService} from '../../bonding_shared/services/router-service';
import {ActivatedRoute, Params} from '@angular/router';
import {LimitGuiService} from './shared/services/limit-gui.service';
import {CompanyService} from '../../bonding_shared/services/company.service';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {
  CompanyDto,
  DictionaryBaseDto,
  LimitBuyerMassDecisionDto,
  LimitDecisionCriteriaDto,
  LimitDecisionDto,
  LimitMassDecisionReductionAmountDto,
} from '../../bonding_shared/model/dtos';
import {LimitDecisionType} from '../../bonding_shared/model/dictionary-ids';
import {LimitDecisionService} from '../../bonding_shared/services/limit-decision.service';
import {LimitDecisionListComponent} from './shared/components/limit-decision-list.component';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';

@Component({
  selector: 'buyer-limit-mass-decision',
  templateUrl: './buyer-limit-mass-decision.component.pug',
})
export class BuyerLimitMassDecisionComponent extends DetailsView implements OnInit, AfterViewInit {
  decisionTypes: DictionaryBaseDto[] = [
    <DictionaryBaseDto>{id: LimitDecisionType.RISK_WITHDRAWAL, name: 'Limit withdrawal'},
    <DictionaryBaseDto>{id: LimitDecisionType.REDUCTION, name: 'Limit reduction'},
  ];
  company = <CompanyDto>{};
  decision: LimitBuyerMassDecisionDto;
  decisionPersisted = false;

  @ViewChild(LimitDecisionListComponent, {static: true}) limitList: LimitDecisionListComponent;

  limitDataProvider: SearchDataProvider<LimitDecisionCriteriaDto, LimitDecisionDto>;

  constructor(
    private _route: ActivatedRoute,
    _formBuilder: UntypedFormBuilder,
    public guiService: LimitGuiService,
    private limitService: LimitDecisionService,
    private companyService: CompanyService,
    public router: RouterService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.form = _formBuilder.group({
      // requestAmount: ['', Validators.compose([Validators.required])]
    });
    this.limitDataProvider = new SearchDataProvider<LimitDecisionCriteriaDto, LimitDecisionDto>(limitService);
    this.limitDataProvider.searchCriteria.criteria = <LimitDecisionCriteriaDto>{};
    this.limitDataProvider.searchCriteria.criteria.onlyNonZeroValueDecisions = true;
    this.limitDataProvider.searchCriteria.criteria.presentNow = true;
    this.limitDataProvider.textSearch = false;
    this.decision = <LimitBuyerMassDecisionDto>{};
  }

  ngOnInit() {
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  ngAfterViewInit() {
    this.refreshLimitList(this.company.id);
  }

  initializeView(params: Params) {
    this.company = <CompanyDto>{};
    this.company.id = +params['companyId'];
    this.companyService.getCompany(this.company.id).subscribe((company) => (this.company = company));
  }

  refreshLimitList(companyId: number) {
    this.limitDataProvider.searchCriteria.criteria.buyerId = companyId;
    // if (this.limitList && this.limitList.limitTable) {
    this.limitList.limitTable.search();
  }

  onNewDecision(decision: DictionaryBaseDto) {
    this.decision = <LimitBuyerMassDecisionDto>{};
    this.decision.decisionType = decision;
    this.decision.persist = false;
    this.decision.criteria = this.limitDataProvider.searchCriteria.criteria;
    if (decision.id === LimitDecisionType.REDUCTION) {
      this.decision.reductionAmounts = [];
      this.decision.reductionAmounts[0] = <LimitMassDecisionReductionAmountDto>{};
      this.decision.reductionAmounts[0].lowerLimit = 0;
      this.decision.reductionAmounts[0].reductionPercent = 10;
    }
    this.decisionPersisted = false;
  }

  onSendDecision(persist: boolean) {
    this.serverErrors = undefined;
    this.decision.persist = persist;
    this.limitService.massDecision(this.decision).subscribe({
      next: (decision) => {
        console.log('mass decision returned:', decision);
        this.decision = decision;
        this.decisionPersisted = this.decision.persist;
        if (this.decisionPersisted) {
          this.refreshLimitList(this.company.id);
          this.growlService.notice('Mass decision is done');
        } else {
          this.serverErrors = this.decision.warnings;
        }
      },
      error: (error) => (this.serverErrors = error),
    });
  }

  onCancel() {
    this.decision.decisionType = null;
    super.onCancel(this._route);
  }
}
