import {Component, Input} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService, DictionaryService, PolicyContractVersionService} from '../../../../../bonding_shared';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'mehib-scope-of-cover-section',
  templateUrl: './mehib-scope-of-cover-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class MehibScopeOfCoverSectionComponent extends Section {
  self = this;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected appService: AppConfigService,
    protected pcvService: PolicyContractVersionService,
    protected dictionaryService: DictionaryService
  ) {
    super(policyContractViewService, appService, pcvService, dictionaryService);
  }

  @Input() politicalRiskFixed = true;
  @Input() productionRiskFixed = false;
  commercialRisk: boolean;
  protractedDefaultAllowedValues = [60, 90];

  get productionRiskEditable() {
    return !this.productionRiskFixed && !this.readonly;
  }

  onPoliticalRiskOnlyChanged(checked: boolean) {
    this.policy.commercialRisk = !checked;
  }

  onProductionRiskChanged(checked: boolean) {
    if (!checked) {
      delete this.policy.selfRetentionProductionAndCommercialRisks;
      delete this.policy.gbc;
    }
  }
}
