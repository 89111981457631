import {Component, Input, Output, EventEmitter, ContentChild, TemplateRef} from '@angular/core';

@Component({
  selector: 'item-dropdown',
  template: `
    <div *ngIf="items && _items.length > 0" class="btn-group" dropdown [class.dropup]="dropup" [isDisabled]="disabled">
      <button type="button" [ngClass]="['dropdown-toggle', buttonClass]" dropdownToggle>
        {{ caption | translate }} <span class="caret"></span>
      </button>
      <ul [class]="'dropdown-menu dropdown-menu-' + alignment" role="menu">
        <li *ngFor="let item of items" role="menuitem">
          <a *ngIf="!template" class="dropdown-item" (click)="click(item)">{{ item[label] | translate }}</a>
          <a *ngIf="template" class="dropdown-item" (click)="click(item)">
            <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{item: item}"></ng-container>
          </a>
        </li>
      </ul>
    </div>
  `,
})
export class ItemDropdownComponent<T> {
  @Input() caption: string;
  @Input() disabled = false;
  @Input() dropup = false;
  @Input() label = 'name';
  @Input() buttonClass = 'bon-btn-info';
  @Input() alignment: 'left' | 'right' = 'left';
  _items: T[];

  @ContentChild(TemplateRef, {static: true}) template: TemplateRef<any>;

  @Output() menuSelect = new EventEmitter<T>();

  @Input() set items(ims: T[] | Set<T>) {
    this._items = ims instanceof Set ? Array.from(ims) : <T[]>ims;
  }

  get items() {
    return this._items;
  }

  click(item: T) {
    this.menuSelect.emit(item);
  }
}
