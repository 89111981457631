import {Component} from '@angular/core';
import {DictionaryBaseDto, ReportSimpleDto} from '../../bonding_shared/model/index';
import {RouterService} from '../../bonding_shared/services/router-service';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {LocalDateRange, ReportCriteriaDto} from '../../bonding_shared/model/dtos';
import {ReportService} from '../../bonding_shared/services/report.service';

@Component({
  selector: 'report-search',
  templateUrl: './report-search.component.pug',
})
export class ReportSearchComponent {
  REPORT_TYPE_NBB = 20000001;
  public selectedType = <DictionaryBaseDto>{id: this.REPORT_TYPE_NBB};
  public now: Date = new Date();

  dataProvider: SearchDataProvider<ReportCriteriaDto, ReportSimpleDto>;

  constructor(public router: RouterService, private reportService: ReportService) {
    this.dataProvider = new SearchDataProvider<ReportCriteriaDto, ReportSimpleDto>(reportService);
    this.setupCriteria();
  }

  setupCriteria() {
    this.criteria = <ReportCriteriaDto>{};
    this.criteria.dateFrom = <LocalDateRange>{};
    this.criteria.dateFrom.dateFrom = new Date('2016-01-01');
    this.criteria.dateFrom.dateFrom.setFullYear(this.now.getFullYear());
    this.criteria.dateTo = <LocalDateRange>{};
    // this.criteria.dateTo.dateTo = new Date('2016-12-31');
    // this.criteria.dateTo.dateTo.setFullYear(this.now.getFullYear());
    this.criteria.type = <DictionaryBaseDto>{};
    this.criteria.type.id = this.selectedType.id;
  }

  get criteria(): ReportCriteriaDto {
    return this.dataProvider.searchCriteria.criteria;
  }
  set criteria(c: ReportCriteriaDto) {
    this.dataProvider.searchCriteria.criteria = c;
  }

  getFileDebtors(report: ReportSimpleDto) {
    this.reportService.createDebtorReportById(report.id);
  }

  getFileCredits(report: ReportSimpleDto) {
    this.reportService.createCreditReportById(report.id);
  }

  getXlsxReport(report: ReportSimpleDto) {
    this.reportService.createXlsxReportById(report.id, 'report.xlsx');
  }
}
