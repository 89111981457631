import {Component, OnInit} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService, PolicyContractVersionService} from '../../../../../bonding_shared/services';
import {PolicyContractVersionOrigin} from '../../../../../bonding_shared/model/dictionary-ids';
import {ControlContainer, FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {VisibilityService} from '../../../../inquiry/services/visibility.service';

@Component({
  selector: 'bok-administration-section',
  templateUrl: './bok-administration-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BokAdministrationSectionComponent extends Section implements OnInit {
  self = this;

  fromOffer = false;
  administractionChecking: boolean;
  administractionCheckingForm: FormGroup;

  private pendingInit = false;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected policyContractVersionService: PolicyContractVersionService,
    private visibilityService: VisibilityService,
    private formBuilder: FormBuilder
  ) {
    super(policyContractViewService, appService, policyContractVersionService);

    this.administractionCheckingForm = formBuilder.group({
      compatibleWithCompetence: ['', Validators.required],
      comment: ['', Validators.required],
    });

    this.administractionCheckingForm.valueChanges.subscribe((_) => this.additionalInfoChanged());
  }

  ngOnInit() {
    const origin = this.policy.origin;
    this.fromOffer = origin && origin.id === PolicyContractVersionOrigin.OFFER;
    if (!this.fromOffer) {
      return;
    }
    this.init();
  }

  administractionCheckingChange() {
    if (!this.administractionChecking) {
      this.reset();
    }
    this.administractionCheckingFormAttachment();
  }

  private reset() {
    this.policy.bokAdministration = {comment: null, compatibleWithCompetence: null};
  }

  private administractionCheckingFormAttachment() {
    if (this.policyForm) {
      if (this.administractionChecking) {
        const additionalInfo = this.policy.bokAdministration;
        const setAdministractionForm = (name, val) => this.administractionCheckingForm.get(name).setValue(val);

        try {
          this.pendingInit = true;
          setAdministractionForm('compatibleWithCompetence', additionalInfo.compatibleWithCompetence);
          setAdministractionForm('comment', additionalInfo.comment);
        } finally {
          this.pendingInit = false;
        }

        this.policyForm.addControl('administractionCheckingForm', this.administractionCheckingForm);
      } else {
        this.policyForm.removeControl('administractionCheckingForm');
        this.administractionCheckingForm.reset();
      }
    }
  }

  private init() {
    if (!this.policy.bokAdministration) {
      this.reset();
    }
    const compatibleWithCompetence = this.policy.bokAdministration.compatibleWithCompetence;
    this.administractionChecking = compatibleWithCompetence !== null && compatibleWithCompetence !== undefined;
    this.administractionCheckingFormAttachment();
  }

  additionalInfoChanged() {
    if (this.pendingInit) {
      return;
    }
    const additionalInfo = this.policy.bokAdministration;
    const getVal = (name) => this.administractionCheckingForm.get(name).value;

    additionalInfo.compatibleWithCompetence = getVal('compatibleWithCompetence');
    additionalInfo.comment = getVal('comment');
  }
}
