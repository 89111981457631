import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  ClaimDto,
  ClaimRepaymentScheduleVersionBaseDto,
  ClaimRepaymentScheduleVersionDto,
  ClaimRepaymentScheduleVersionInstalmentDto,
  ClaimService,
  GrowlService,
  RecoveryDto,
  RouterService,
} from '../../bonding_shared';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import {
  ClaimRepaymentScheduleInstalmentType,
  ClaimRepaymentScheduleVersionStatus,
} from '../../bonding_shared/model/dictionary-ids';
import {ClaimRepaymentScheduleVersionService} from '../../bonding_shared/services/claim-repayment-schedule-version.service';
import {combineLatest, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {ClaimRecoveriesService} from '../../bonding_shared/services/claim-recoveries.service';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {IPasteFromExcelColumnConfig} from '../../bonding_shared/components/aku-table/services/paste-from-excel.service';

@Component({
  selector: 'claim-repayment-schedule',
  templateUrl: './claim-repayment-schedule.component.pug',
})
export class ClaimRepaymentScheduleComponent extends DetailsView implements OnInit {
  repaymentScheduleVersion: ClaimRepaymentScheduleVersionDto;
  claim: ClaimDto;
  versions: ClaimRepaymentScheduleVersionBaseDto[];
  recoveries: RecoveryDto;
  summaries: ClaimRepaymentScheduleSummaryRow[];

  pasteDataFromExcelConfig = [
    {
      columns: [
        <IPasteFromExcelColumnConfig>{
          type: 'dictionary',
          property: 'type',
          dictionary: 'ClaimRepaymentScheduleInstalmentType',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'date',
          property: 'repaymentDate',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'number',
          property: 'amount',
        },
        <IPasteFromExcelColumnConfig>{
          type: 'dictionary',
          property: 'currency',
          dictionary: 'Currency',
        },
      ],
    },
  ];

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  public constructor(
    private _route: ActivatedRoute,
    private service: ClaimRepaymentScheduleVersionService,
    private claimRecoveriesService: ClaimRecoveriesService,
    private claimService: ClaimService,
    public router: RouterService,
    protected growlService: GrowlService
  ) {
    super(growlService);
  }

  ngOnInit(): void {
    this.form = this.ngForm.form;
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    super.initializeView(params);
    this._route.queryParams.subscribe((queryParams) => {
      this.fetchData(this.service.getLastOrInitVersion(+queryParams['claimId']));
    });
  }

  private fetchData(resultObs: Observable<ClaimRepaymentScheduleVersionDto>) {
    resultObs
      .pipe(
        mergeMap((repaymentScheduleVersion) => {
          const claimId = repaymentScheduleVersion.parent.claim.id;
          return combineLatest([
            of(repaymentScheduleVersion),
            this.claimService.getClaim(claimId),
            this.claimRecoveriesService.getFromRevision(claimId),
          ]);
        })
      )
      .subscribe({
        next: ([repaymentScheduleVersion, claim, recoveries]) => {
          this.setRepaymentScheduleVersion(repaymentScheduleVersion);
          this.claim = claim;
          this.recoveries = recoveries;
          if (repaymentScheduleVersion.parent.id) {
            this.fetchVersions(repaymentScheduleVersion.parent.id);
          }
          this.recalculateTotals();
        },
        error: (error) => this.handleServerError(error),
      });
  }

  setRepaymentScheduleVersion(repaymentScheduleVersionDto: ClaimRepaymentScheduleVersionDto) {
    this.repaymentScheduleVersion = repaymentScheduleVersionDto;
    this.handleButtons();
  }

  fetchVersions(repaymentScheduleId: number) {
    this.service.findClaimRepaymentScheduleVersions(repaymentScheduleId).subscribe({
      next: (versions) => (this.versions = versions),
      error: (error) => this.handleServerError(error),
    });
  }

  onSelectRepaymentScheduleVersion(repaymentScheduleVersionId: number) {
    this.service
      .getById<ClaimRepaymentScheduleVersionDto>(repaymentScheduleVersionId)
      .subscribe((repaymentScheduleVersion) => this.setRepaymentScheduleVersion(repaymentScheduleVersion));
  }

  onAddEntry(entry: ClaimRepaymentScheduleVersionInstalmentDto) {
    entry.currency = this.repaymentScheduleVersion.parent.currency;
    this.recalculateTotals();
  }

  handleButtons() {
    this.saveButton.hidden = !this.isEditable;
    this.cancelButton.hidden = !this.isEditable;
    this.newVersionButton.hidden = !this.canCreateNewVersion;
  }

  onSave() {
    this.clearErrors();
    if (this.formValidates()) {
      this.inProgress = true;
      this.service.save(this.repaymentScheduleVersion).subscribe({
        next: (savedRepaymentScheduleVersion) => {
          this.afterObjectSaved(null, () => this.router.toClaimRepaymentSchedule(this.claim.id));
          this.setRepaymentScheduleVersion(savedRepaymentScheduleVersion);
          this.fetchVersions(savedRepaymentScheduleVersion.parent.id);
          this.recalculateTotals();
        },
        error: (error) => this.handleServerError(error),
      });
    }
  }

  onCreateNewVersion() {
    super.onCreateNewVersion();
    const scheduleId = this.repaymentScheduleVersion?.parent?.id;
    if (scheduleId) {
      this.service.newVersion<ClaimRepaymentScheduleVersionDto>(scheduleId).subscribe((newVersion) => {
        this.setRepaymentScheduleVersion(newVersion);
        this.fetchVersions(newVersion.parent.id);
        this.recalculateTotals();
      });
    }
  }

  onCancel() {
    super.onCancel();
    this.initializeView(this._route.snapshot.queryParams);
  }

  get isEditable() {
    return (
      !this.repaymentScheduleVersion.id ||
      (this.repaymentScheduleVersion.last &&
        this.repaymentScheduleVersion.status.id === ClaimRepaymentScheduleVersionStatus.REGISTERED)
    );
  }

  get canCreateNewVersion() {
    return this.repaymentScheduleVersion.id && this.repaymentScheduleVersion.last;
  }

  get isApproved() {
    return DictionaryUtils.in(this.repaymentScheduleVersion.status, ClaimRepaymentScheduleVersionStatus.APPROVED);
  }

  private recalculateTotals() {
    if (!!this.recoveries) {
      const totalMainDebtFromInstalments = this.sumInstalmentsOfType(ClaimRepaymentScheduleInstalmentType.MAIN_DEBT);
      const totalContractualInterestFromInstalments = this.sumInstalmentsOfType(
        ClaimRepaymentScheduleInstalmentType.CONTRACTUAL_INTEREST
      );
      const totalLatePaymentInterestFromInstalments = this.sumInstalmentsOfType(
        ClaimRepaymentScheduleInstalmentType.LATE_PAYMENT_INTEREST
      );
      const totalMainDebtRecovered =
        this.recoveries.totalMainDebtAfterIndemnification - this.recoveries.totalMainDebtHaircutAfterIndemnification;
      const totalContractualInterestsRecovered =
        this.recoveries.totalInterestsAfterIndemnification - this.recoveries.totalInterestHaircutAfterIndemnification;
      const totalLatePaymentInterestsRecovered =
        this.recoveries.totalLatePaymentInterestsAfterIndemnification -
        this.recoveries.totalLatePaymentInterestHaircutAfterIndemnification;
      this.summaries = [
        <ClaimRepaymentScheduleSummaryRow>{
          type: ClaimRepaymentScheduleInstalmentType.MAIN_DEBT,
          haircutAmount: this.recoveries.totalMainDebtHaircutAfterIndemnification,
          debtAmount: totalMainDebtFromInstalments,
          openAmount: totalMainDebtFromInstalments - totalMainDebtRecovered,
          recoveredAmount: totalMainDebtRecovered,
        },
        <ClaimRepaymentScheduleSummaryRow>{
          type: ClaimRepaymentScheduleInstalmentType.CONTRACTUAL_INTEREST,
          haircutAmount: this.recoveries.totalInterestHaircutAfterIndemnification,
          debtAmount: totalContractualInterestFromInstalments,
          openAmount: totalContractualInterestFromInstalments - totalContractualInterestsRecovered,
          recoveredAmount: totalContractualInterestsRecovered,
        },
        <ClaimRepaymentScheduleSummaryRow>{
          type: ClaimRepaymentScheduleInstalmentType.LATE_PAYMENT_INTEREST,
          haircutAmount: this.recoveries.totalLatePaymentInterestHaircutAfterIndemnification,
          debtAmount: totalLatePaymentInterestFromInstalments,
          openAmount: totalLatePaymentInterestFromInstalments - totalLatePaymentInterestsRecovered,
          recoveredAmount: totalLatePaymentInterestsRecovered,
        },
      ];
    }
  }

  private sumInstalmentsOfType(instalmentTypeId: number): number {
    if (!!this.repaymentScheduleVersion?.instalments) {
      return this.repaymentScheduleVersion.instalments
        .filter((i) => i.type.id === instalmentTypeId)
        .reduce((total, instalment) => total + instalment.amount, 0);
    }
    return 0;
  }
}

export interface ClaimRepaymentScheduleSummaryRow {
  type: number;
  haircutAmount: number;
  debtAmount: number;
  openAmount: number;
  recoveredAmount: number;
}
