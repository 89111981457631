import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {HooverColor} from '../hover-info';

/**
 * Created by siminski on 02.08.2016.
 */

const NUM_ROW_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumRowComponent),
  multi: true,
};

@Component({
  selector: 'num-row',
  template: `
    <div *ngIf="control" class="bon-row">
      <label class="bon-label">
        {{ labelKey ? (labelKey | translate) : label }}
        <hover-info [message]="hoverMsg" [color]="hoverColor"></hover-info>
      </label>
      <num-input
        [ngModel]="value"
        (changeValue)="onInput($event)"
        [type]="type"
        [disabled]="disabled"
        [allowNegative]="allowNegative"
        [nullLabel]="nullLabel"
        [infinity]="infinity"
        [minVal]="minVal"
        [maxVal]="maxVal"
        [min]="min"
        [max]="max"
        [maxlength]="maxlength"
        [alignLeft]="alignLeft"
        [numberOfDecimalPlaces]="numberOfDecimalPlaces"
        class="bon-input-size"
        [presentationMode]="presentationMode"
        [formatting]="formatting"
      >
      </num-input>
      <error-message [control]="control" [show]="showErrors"></error-message>
    </div>
    <div *ngIf="!control" class="bon-row">
      <label class="bon-label">
        {{ labelKey ? (labelKey | translate) : label }}
        <hover-info [message]="hoverMsg" [color]="hoverColor"></hover-info>
      </label>
      <num-input
        #model="ngModel"
        [ngModel]="value"
        (changeValue)="onInput($event)"
        [type]="type"
        [disabled]="disabled"
        [required]="required"
        [allowNegative]="allowNegative"
        [nullLabel]="nullLabel"
        [infinity]="infinity"
        [minVal]="minVal"
        [maxVal]="maxVal"
        [min]="min"
        [max]="max"
        [maxlength]="maxlength"
        [alignLeft]="alignLeft"
        [numberOfDecimalPlaces]="numberOfDecimalPlaces"
        class="bon-input-size"
        [presentationMode]="presentationMode"
        [formatting]="formatting"
      >
      </num-input>
      <error-message [control]="model" [show]="showErrors"></error-message>
    </div>
    <ng-content></ng-content>
  `,
  providers: [NUM_ROW_CONTROL_VALUE_ACCESSOR],
})
export class NumRowComponent implements ControlValueAccessor {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() labelKey: string;
  @Input() disabled: any;
  @Input() presentationMode = false;
  @Input() showErrors: boolean;
  @Input() required: boolean;
  @Input() errorClass = '';
  @Input() type: 'decimal' | 'integer' = 'decimal';
  @Input() allowNegative = false;
  @Input() nullLabel: string;
  @Input() infinity: boolean;
  @Input() maxVal: number; // for wrong value validation error is shown
  @Input() minVal: number; // for wrong value validation error is shown
  @Input() min: number; // wrong value is changed into min without showing any error
  @Input() max: number; // wrong value is changed into max without showing any error
  @Input() numberOfDecimalPlaces = 2;
  @Input() alignLeft = false;
  @Output() changeValue = new EventEmitter<number>();
  @Input() formatting = true;
  @Input() hoverMsg: string;
  @Input() hoverColor: HooverColor;
  @Input() maxlength = 17;
  public onChangeListeners: Function;
  public onTouchedListeners: Function;
  public value: number;

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onInput(value: number) {
    this.value = value;
    if (this.onChangeListeners) {
      this.onChangeListeners(this.value);
    }
    this.changeValue.emit(this.value);
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private getCssClasses() {
    const cssClasses: {[key: string]: any} = {};
    cssClasses['bon-input'] = true;
    if (this.control && this.control.invalid && this.showErrors) {
      cssClasses[this.errorClass] = true;
    }
    return cssClasses;
  }
}
