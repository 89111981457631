import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'bon-section',
  templateUrl: './bon-section.component.pug',
})
export class BonSectionComponent implements OnInit {
  @Input() title: string;
  @Input() labelKey: string;
  @Input() titleWidth = 'auto';
  @Input() expandable: string | boolean = 'disabled';
  @Input() searchBox = false;
  @Input() searchIcon = false;
  @Input() firstSection = false;
  @Input() tabStyle = false;

  @Input() iconsFloatRight = true;
  // TODO: change searchable to readyOnly
  @Input() searchable = false;
  @Input() canClose = false;
  @Input() clearable = false;

  @Output() search = new EventEmitter<string>();
  @Output() focus = new EventEmitter();

  @Input() openSelectorEmitter = new Subject<boolean>();
  @Output() clearObjectEmitter = new EventEmitter<boolean>();

  opened = false;

  searchText: string;

  ngOnInit() {
    if (this.openSelectorEmitter) {
      this.openSelectorEmitter.subscribe((event: boolean) => {
        this.opened = event;
      });
    }
  }

  onOpen() {
    this.openSelectorEmitter.next(true);
    this.opened = true;
  }
  onClose() {
    this.openSelectorEmitter.next(false);
    this.opened = false;
  }
  onClear() {
    this.clearObjectEmitter.emit(false);
    this.opened = false;
  }

  onSearch() {
    this.search.emit(this.searchText);
  }

  onFocus() {
    this.focus.emit();
  }
}
