import {HomeComponent} from './home.component';

export const HomeRoutes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'home/:contractVersionId',
    component: HomeComponent,
  },
];
