import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClientPolicyComboComponent} from './client-policy-combo.component';
import {ClientInsuredsComboComponent} from './client-insureds-combo.component';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
  declarations: [ClientPolicyComboComponent, ClientInsuredsComboComponent],
  exports: [ClientPolicyComboComponent, ClientInsuredsComboComponent],
})
export class ClientPolicySharedModule {}
