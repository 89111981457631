import {ModuleWithProviders, NgModule} from '@angular/core';
import {GrowlService} from './growl.service';
import {GROWL_CONFIG_TOKEN, GrowlConfig} from './growl.config';

@NgModule()
export class GrowlModule {
  static forRoot(config?: GrowlConfig): ModuleWithProviders<GrowlModule> {
    return {
      ngModule: GrowlModule,
      providers: [GrowlService, {provide: GROWL_CONFIG_TOKEN, useValue: config}],
    };
  }
}
