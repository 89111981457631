import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {InquiryDetailsComponent} from './inquiry-details.component';
import {InquirySearchComponent} from './inquiry-search.component';
import {InquiryGuiService} from './services/inquiry-gui.service';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {InquirySectionComponent} from './components/inquiry-section.component';
import {InquirySelectorByClientComponent} from './components/inquiry-selector-byclient.component';
import {OfferDetailsComponent} from './offer-details.component';

import {BondSharedModule} from '../bond/components/shared/bond-shared.module';
import {KukeOfferDetailsComponent} from './kuke/kuke-offer-details.component';
import {CustomFormModule} from '../../common/custom-form/custom-form.module';
import {PolicyClauseModule} from '../policy/clause/policy-clause.module';
import {InquiryTechnicalDetailsComponent} from './components/inquiry-technical-details.component';
import {BrokerContractSharedModule} from '../broker-contract/components/shared/broker-contract-shared.module';
import {PolicyContractSharedModule} from '../policy/components/shared/policy-contract-shared.module';
import {TabViewModule} from 'primeng/tabview';
import {CalculatorDetailsComponent} from './kuke/calculator/calculator-details.component';
import {CompanyExtendedSelectorModule} from '../company/components/shared/extended-selectors/company-extended-selector.module';
import {SalesRepSharedModule} from '../sales-rep/components/shared/sales-rep-shared.module';
import {InquirySharedModule} from './shared/inquiry-shared.module';
import {VisibilityService} from './services/visibility.service';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AnnexInquiryDetailsComponent} from './annex-inquiry-details.component';
import {RenewalInquiryDetailsComponent} from './renewal-inquiry-details.component';
import {BusinessObjectInfoModule} from '../business-object-info/business-object-info.module';
import {InquiryFormsModule} from './forms/inquiry-forms.module';
import {
  AnnexInquiryDetailsGuard,
  InquiryCalculatorGuard,
  InquiryDetailsGuard,
  InquiryOfferDetailsGuard,
  InquiryTechnicalDetailsGuard,
  RenewalInquiryDetailsGuard,
} from './_guards';
import {CalculatorPbGDetailsComponent} from './kuke/calculator/calculator-pbg-details.component';
import {MehibCalculatorDetailsComponent} from './mehib/calculator/mehib-calculator-details.component';
import {PremiumCalculationResultComponent} from './components/premium-calculation-result.component';
import {EcgCalculatorDetailsComponent} from './ecg/calculator/ecg-calculator-details.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    BondSharedModule,
    PolicyClauseModule,
    CustomFormModule,
    BrokerContractSharedModule,
    CustomFormModule,
    PolicyContractSharedModule,
    TabViewModule,
    CompanyExtendedSelectorModule,
    SalesRepSharedModule,
    InquirySharedModule,
    BsDropdownModule,
    BusinessObjectInfoModule,
    InquiryFormsModule,
  ],
  declarations: [
    InquiryDetailsComponent,
    InquirySearchComponent,
    InquirySelectorByClientComponent,
    InquirySectionComponent,
    AnnexInquiryDetailsComponent,
    RenewalInquiryDetailsComponent,
    OfferDetailsComponent,
    KukeOfferDetailsComponent,
    InquiryTechnicalDetailsComponent,
    CalculatorDetailsComponent,
    CalculatorPbGDetailsComponent,
    MehibCalculatorDetailsComponent,
    PremiumCalculationResultComponent,
    EcgCalculatorDetailsComponent,
  ],
  exports: [InquirySelectorByClientComponent, InquirySectionComponent],
  providers: [
    InquiryGuiService,
    VisibilityService,
    AnnexInquiryDetailsGuard,
    InquiryCalculatorGuard,
    InquiryDetailsGuard,
    InquiryOfferDetailsGuard,
    InquiryTechnicalDetailsGuard,
    RenewalInquiryDetailsGuard,
  ],
})
export class InquiryModule {}
