import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  AppConfigService,
  CompanyGroupDto,
  CompanyGroupVersionDto,
  CompanyWithExposureDto,
  ConfirmDialogComponent,
  GroupService,
  GrowlService,
  LoggedUserService,
  RelatedObject,
  RouterService,
  StringUtils,
} from '../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {GroupGuiService} from './services/group-gui.service';
import {NgForm} from '@angular/forms';
import * as _ from 'lodash';
import {CompanyElementaryRight} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'group-details',
  templateUrl: 'group-details.component.html',
})
export class GroupDetailsComponent extends DetailsView implements OnInit {
  @ViewChild('form', {static: true}) public ngForm: NgForm;
  public groupVersion: CompanyGroupVersionDto = <CompanyGroupVersionDto>{};
  public group: CompanyGroupDto = <CompanyGroupDto>{};
  public deleted = false;
  @ViewChild('deletionConfirm', {static: true}) cancelCoverConfirmDialog: ConfirmDialogComponent;

  readonly CompanyElementaryRight = CompanyElementaryRight;

  constructor(
    private _groupService: GroupService,
    private _groupGuiService: GroupGuiService,
    private _route: ActivatedRoute,
    public appService: AppConfigService,
    protected growlService: GrowlService,
    public routerService: RouterService,
    protected loggedUserService: LoggedUserService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.deleteButton.hidden = false;
    this.selectorNameList = [RelatedObject.GROUP_MEMBER];
    this.initializeSelectorEmitters(true);
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }

  public get mehib(): boolean {
    return this.appService.mehib;
  }

  public get credendo(): boolean {
    return this.appService.credendo;
  }

  public get editable(): boolean {
    return !this.groupVersion.id;
  }

  public get insuranceExposureSum(): number {
    return _.sumBy(
      this.groupVersion.groupCompanies,
      (groupCompany: CompanyWithExposureDto) => groupCompany.insuranceExposure
    );
  }

  public get bondExposureSum(): number {
    return _.sumBy(this.groupVersion.groupCompanies, (groupCompany: CompanyWithExposureDto) => groupCompany.exposure);
  }

  public ngOnInit(): void {
    this.form = this.ngForm.form;
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  public onSave(): void {
    this.save();
  }

  public onCancel(): void {
    super.onCancel();
    this.initializeView({id: this.group.id});
  }

  public initializeView(params: Params): void {
    const id: number = +params['id'];
    this.initializeGroup(id);
  }

  public showSavedMsg(): void {
    this.growlService.notice('Object is saved!');
    this.inProgress = false;
  }

  public onSelectMember(company: CompanyWithExposureDto): void {
    this.clearErrors();
    if (!this.alreadyMember(company.id)) {
      this._groupService.companyTreeAvailableForGroup(company.id, this.groupVersion.companyGroup.id).subscribe({
        next: (list) =>
          list.filter((c) => !this.alreadyMember(c.id)).forEach((c) => this.groupVersion.groupCompanies.push(c)),
        error: (error) => this.handleServerError(error),
      });
    }
  }

  public previewLink(): boolean {
    return this.group.id > 0 && !this.deleted;
  }

  public onSelectVersion(version: CompanyGroupVersionDto): void {
    this.groupVersion = version;
  }

  public onCreateNewVersion(): void {
    this._groupService.newGroupVersion(this.groupVersion.id).subscribe({
      next: (newVersion) => (this.groupVersion = newVersion),
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      },
      complete: () => this.showGroup(),
    });
  }

  private initializeGroup(id: number): void {
    if (id > 0) {
      this.saveButton.disabled = true;
      this._groupService.getById<CompanyGroupDto>(id).subscribe({
        next: (group) => {
          this.onSetGroup(group);
        },
        error: () => console.error('Error on getById (' + id + ')'),
      });
    } else {
      this.onSetGroup(this._groupGuiService.initialCompanyGroup());
    }
  }

  private onSetGroup(group: CompanyGroupDto): void {
    this.group = group;
    if (this.group.companyGroupVersions[this.group.companyGroupVersions.length - 1]) {
      this.groupVersion = this.group.companyGroupVersions[this.group.companyGroupVersions.length - 1];
    } else {
      this._groupGuiService
        .initialCompanyGroupVersion()
        .subscribe((groupVersion) => (this.groupVersion = groupVersion));
    }
    this.handleButtons();
  }

  private alreadyMember(memberId: number): boolean {
    if (this.groupVersion.groupCompanies.some((e) => e.id === memberId)) {
      this.growlService.warning('group.common.alreadyAdded');
      return true;
    }
    return false;
  }

  private save(): void {
    this.clearErrors();
    if (!this.form.valid) {
      this.showErrors = true;
      this.inProgress = false;
      this.showFormError();
      StringUtils.logFormInvalidFields(this.form);
      return;
    }
    this._groupService.save(this.groupVersion).subscribe({
      next: (groupVersion) => this.afterSave(groupVersion),
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      },
      complete: () => this.showGroup(),
    });

    this.handleButtons();
  }

  private afterSave(groupVersion: CompanyGroupVersionDto): void {
    this.saveButton.disabled = true;
    this._groupService.getById<CompanyGroupDto>(groupVersion.companyGroup.id).subscribe({
      next: (group) => {
        this.onSetGroup(group);
      },
      error: () => console.error('Error on getById (' + this.group.id + ')'),
    });
    this.showErrors = false;
    this.showSavedMsg();
  }

  private showGroup(): void {
    this.inProgress = false;
    if (this.serverErrors) {
      return;
    }
    this.handleButtons();
  }

  protected handleButtons(): void {
    this.hideButtons(false);
    this.saveButton.disabled =
      !!this.groupVersion.id || this.groupVersion.id === 0 || !this.canGroupCreate() || !this.canGroupUpdate();
    this.saveButton.hidden =
      !!this.groupVersion.id || this.groupVersion.id === 0 || !this.canGroupCreate() || !this.canGroupUpdate();
    this.cancelButton.hidden = this.saveButton.hidden;
    this.newVersionButton.hidden = !this.saveButton.hidden;
    this.newVersionButton.disabled = !this.canGroupCreate();
    this.deleteButton.hidden = false;
    this.deleteButton.disabled = !this.canGroupRemove();
  }

  canGroupCreate(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_GROUP_CREATE);
  }

  canGroupRemove(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_GROUP_REMOVE);
  }

  canGroupUpdate(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_GROUP_UPDATE);
  }

  onDelete(): void {
    this.cancelCoverConfirmDialog.openAndExecuteOnConfirm(
      'group.details.deleteGroupConfirmPopup.title',
      'group.details.deleteGroupConfirmPopup.msg',
      this.proceedDeletion.bind(this)
    );
  }

  proceedDeletion() {
    console.log('delete group');
    this._groupService.deleteObj(this.groupVersion.companyGroup.id).subscribe({
      next: (group) => {},
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      },
      complete: () => this.routerService.toCompanyGroupSearch(),
    });
  }
}
