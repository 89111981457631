/**
 * Created by szkrabko on 14.09.2018.
 */
import {Component, Input} from '@angular/core';

export interface HoverStyle {
  [color: string]: {
    border: string;
    background: string;
  };
}

@Component({
  selector: 'hover-info',
  template: `
    <ng-container *ngIf="message">
      <img
        [src]="'assets/images/icons/info-32-' + this._color + '.gif'"
        (mouseover)="onHoverChange($event, true)"
        (mousemove)="onHoverChange($event, true)"
        (mouseout)="onHoverChange($event, false)"
      />
      <span
        [ngStyle]="{
          display: hover ? 'inline-block' : 'none',
          left: left,
          top: top,
          border: '2px solid ' + hoverStyle[_color].border,
          background: hoverStyle[_color].background
        }"
      >
        <div *ngIf="!isHtml">{{ message | translate }}</div>
        <div *ngIf="isHtml" [innerHTML]="message"></div>
      </span>
    </ng-container>
  `,
  styles: [
    ':host { z-index: 1000 }',
    'img { margin-left: 5px; width: 16px; height: 16px; }',
    `
      span {
        position: fixed;
        display: none;
        padding: 2px 5px;
        z-index: 1000;
        font-size: 12px;
      }
    `,
  ],
})
export class HoverInfoComponent {
  @Input() message: string;
  @Input() isHtml = false;
  private _color: HooverColor = 'red';

  get color(): HooverColor {
    return this._color;
  }
  @Input()
  set color(value: HooverColor) {
    this._color = value ? value : 'red';
  }

  public left: string;
  public top: string;
  public hover = false;
  public hoverStyle: HoverStyle = {
    red: {border: 'orange', background: 'lightyellow'},
    blue: {border: '#5bc0de', background: '#defdff'},
  };

  onHoverChange(e: any, show: boolean) {
    this.left = e.clientX + 15 + 'px';
    this.top = e.clientY + 15 + 'px';
    this.hover = show;
  }
}
export type HooverColor = 'red' | 'blue';
