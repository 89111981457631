import {AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {GrowlService} from '../../services';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

const FILE_SELECT_ROW_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileSelectRowComponent),
  multi: true,
};

@Component({
  selector: 'file-select-row',
  template: `
    <button
      *ngIf="!openEmitter"
      type="button"
      class="bon-btn-info"
      (click)="openFileSelectionDialog()"
      [disabled]="disabled"
    >
      {{ label ? label : (labelKey | translate) }}
    </button>
    <input
      *ngIf="!control"
      type="file"
      [value]="inputValue"
      (change)="onChange($event)"
      accept="{{ allowedFileTypes }}"
      hidden
      class="file-input"
      [attr.multiple]="multiple ? '' : null"
    />
    <input
      *ngIf="control"
      type="file"
      [value]="inputValue"
      [formControl]="control"
      (change)="onChange($event)"
      accept="{{ allowedFileTypes }}"
      hidden
      class="file-input"
      [attr.multiple]="multiple ? '' : null"
    />
    <label *ngIf="showLabel" class="bon-label" style="width: 100%;padding-bottom: 3px; padding-left: 5px;">
      <div *ngIf="value && value.length > 0; then fileNames; else noFileSelected"></div>
      <ng-template #fileNames>
        <div *ngFor="let item of value">{{ item?.name }}</div>
      </ng-template>
      <ng-template #noFileSelected>{{ 'common.noFileSelected' | translate }}</ng-template>
    </label>
    <error-message [control]="control" [show]="showErrors"></error-message>
  `,
  providers: [FILE_SELECT_ROW_CONTROL_VALUE_ACCESSOR],
})
export class FileSelectRowComponent implements ControlValueAccessor, OnInit {
  static readonly MAX_FILE_BYTES: number = 10485760 * 3; // 30 MB

  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() labelKey = 'common.selectFile';
  @Input() disabled: any;
  @Input() allowedFileTypes: string;
  @Input() showLabel = true;
  @Input() showErrors = false;
  @Input() openEmitter: Subject<boolean>;
  @Input() multiple = false;

  @Output() fileSelected = new EventEmitter<File>();
  @Output() filesSelected = new EventEmitter<File[]>();

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  public inputValue: string;
  public value: File[];

  constructor(
    private growlService: GrowlService,
    public view: ElementRef,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.openEmitter) {
      this.openEmitter.subscribe((event: boolean) => {
        if (event) {
          this.openFileSelectionDialog();
        }
      });
    }
  }

  openFileSelectionDialog() {
    const fileInput = this.view.nativeElement.querySelector('.file-input');
    fileInput.value = '';
    fileInput.click();
  }

  writeValue(obj: any): void {
    if (this.multiple || !obj) {
      this.value = obj;
    } else {
      this.value = [obj];
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onChange(event: any) {
    const files: File[] = [];
    for (let i = 0; i < event.target.files.length; i++) {
      files.push(event.target.files[i]);
    }
    const totalFilesSize = files.map((f) => f.size).reduce((total, size) => total + size, 0);
    if (totalFilesSize > FileSelectRowComponent.MAX_FILE_BYTES) {
      this.translateService.get('errorMessage.fileSizeExceeded').subscribe((message) => {
        this.growlService.error(message + ' ' + FileSelectRowComponent.MAX_FILE_BYTES / 1024 / 1024 + ' MB');
      });
      this.value = [];
      this.inputValue = '';
    } else {
      this.value = files;
    }
    if (this.onChangeListeners) {
      if (this.multiple) {
        this.onChangeListeners(this.value);
      } else {
        this.onChangeListeners(this.value[0]);
      }
    }
    if (this.multiple) {
      this.filesSelected.emit(this.value);
    } else {
      this.fileSelected.emit(this.value[0]);
    }
  }
}
