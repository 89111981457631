import {CompanySimpleDto, PolicyInquiryCompanyDto, ThirdPartyBaseDto} from '../../../bonding_shared';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';

export function fromInqCompanyToCompany(inqCompany: PolicyInquiryCompanyDto): CompanySimpleDto {
  let company: CompanySimpleDto = null;
  if (inqCompany.company && inqCompany.company.id) {
    company = inqCompany.company;
  } else {
    const tpCompany = getThirdPartyCompany(inqCompany.thirdParty);
    if (tpCompany && tpCompany.company && tpCompany.company.id) {
      company = tpCompany.company;
    }
  }
  return company;
}

function getThirdPartyCompany(tp: ThirdPartyBaseDto) {
  return BusinessUtils.getThirdPartyCompany(tp);
}
