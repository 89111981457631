import {AbstractControl} from '@angular/forms';

export class QuotationValidators {
  static tariffCalculated(group: AbstractControl) {
    if (group.dirty) {
      return {tariffNotCalculated: true};
    }
    return null;
  }
}
