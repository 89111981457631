import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

import {VersionSummaryComponent} from './version-summary.component';

@NgModule({
  imports: [RouterModule, CommonModule],
  declarations: [VersionSummaryComponent],
  exports: [VersionSummaryComponent],
})
export class VersionSummaryModule {}
