import {Component, ViewChild} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {
  DictionaryBaseDto,
  InvoiceItemCriteriaDto,
  InvoiceItemDto,
  InvoiceItemSimpleDto,
} from '../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {InvoiceItemGuiService} from './services/invoice-item-gui.service';
import {ATableComponent} from '../../bonding_shared/components/aku-table/a-table.component';
import {InvoiceItemService} from '../../bonding_shared/services/invoice-item.service';
import {
  AppProperty,
  BusinessObjectType,
  InvoiceCategory,
  InvoiceItemStatus,
  ProductLine,
} from '../../bonding_shared/model/dictionary-ids';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchViewComponent} from '../../bonding_shared/components/search/search-view/search-view.component';
import {
  DictionaryBaseService,
  InvoiceCoreService,
  LoggedUserService,
  PropertyService,
  SettingsService,
} from '../../bonding_shared/services';
import {AbstractInvoiceComponent} from '../../bonding_shared/components/shared/abstract-invoice.component';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmDialogComponent} from '../../bonding_shared/components/confirm-dialog';
import {InvoiceItemMassService} from '../../bonding_shared/services/invoice-item-mass.service';

@Component({
  selector: 'invoice-item-search',
  templateUrl: 'invoice-item-search.component.html',
})
export class InvoiceItemSearchComponent extends AbstractInvoiceComponent {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    InvoiceItemCriteriaDto,
    InvoiceItemSimpleDto
  >;
  @ViewChild('invoiceItemResultList', {static: true}) invoiceItemResultList: ATableComponent<InvoiceItemDto>;
  @ViewChild('confirmDialog', {static: true}) confirmDialog: ConfirmDialogComponent;

  dataProvider: SearchDataProvider<InvoiceItemCriteriaDto, InvoiceItemDto>;
  productLine: DictionaryBaseDto;
  productLineHiddenIds: number[];
  // searchedItems : InvoiceItemSimpleDto[];
  deletedItemsCount: number;
  captionKey: string;
  newObjectLabelKey: string;
  productLineDisabled = false;

  readonly INVOICE_BROKER_NEW_BROKER_ITEM_RESTRICTIONS =
    this.propertyService.properties[AppProperty.INVOICE_BROKER_NEW_BROKER_ITEM_RESTRICTIONS];

  constructor(
    public router: RouterService,
    protected route: ActivatedRoute,
    public guiService: InvoiceItemGuiService,
    private invoiceItemService: InvoiceItemService,
    private invoiceItemMassService: InvoiceItemMassService,
    protected growlService: GrowlService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected translateService: TranslateService,
    protected invoiceCoreService: InvoiceCoreService,
    private settingsService: SettingsService,
    protected loggedUserService: LoggedUserService,
    private propertyService: PropertyService
  ) {
    super(dictionaryBaseService, route, translateService, invoiceCoreService, loggedUserService);
  }

  getSearchView(): SearchViewComponent<any, any> {
    return this.searchView;
  }

  protected initializeView(params: Params) {
    const categoryStr: string = params['category'];
    const categoryId = InvoiceCategory[categoryStr];
    this.dataProvider = this.guiService.getDataProvider(categoryId);
    this.initProductLine(categoryId);
    if (this.categoryId !== categoryId) {
      if (this.categoryId) {
        // We search again when category is changed. We must avoid double search (SearchViewComponent executes search after view init).
        this.searchView.newProviderSearch(this.dataProvider);
      }
      this.categoryId = categoryId;
    }

    this.captionKey = this.getCategoryTranslationKey('invoiceItem.search.caption');
    this.newObjectLabelKey = this.getCategoryTranslationKey('invoiceItem.search.newObject');
  }

  initProductLine(categoryId: number) {
    if (!this.productLine) {
      this.productLine = <DictionaryBaseDto>{};
    }

    if (categoryId === InvoiceCategory.INVOICE.valueOf() || categoryId === InvoiceCategory.INTEREST.valueOf()) {
      this.productLineHiddenIds = [ProductLine.BROKER];
    }

    if (categoryId === InvoiceCategory.BROKER.valueOf()) {
      this.productLine.id = ProductLine.BROKER;
      this.productLine.code = 'BRO';
      this.productLine.dictName = 'ProductLine';
      this.productLine.name = 'Broker';
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = [BusinessObjectType.BROKER_CONTRACT];
      this.productLineDisabled = true;
    } else if (this.settingsService.bondingViews) {
      this.productLine.code = 'BON';
      this.productLine.dictName = 'ProductLine';
      this.productLine.id = ProductLine.BONDING;
      this.productLine.name = 'Bonding';
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = [BusinessObjectType.CONTRACT];
    } else if (this.settingsService.policyViews) {
      this.productLine.code = 'INS';
      this.productLine.dictName = 'ProductLine';
      this.productLine.id = ProductLine.INSURANCE;
      this.productLine.name = 'Insurance';
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = [
        BusinessObjectType.POLICY,
        BusinessObjectType.POLICY_INQUIRY,
      ];
    } else {
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = undefined;
    }
  }

  onProductLineChange(p: DictionaryBaseDto) {
    if (p) {
      let typesId;
      if (p.id === ProductLine.BONDING) {
        typesId = [BusinessObjectType.CONTRACT];
      } else if (p.id === ProductLine.INSURANCE) {
        typesId = [BusinessObjectType.POLICY, BusinessObjectType.POLICY_INQUIRY];
      } else if (p.id === ProductLine.BROKER) {
        typesId = [BusinessObjectType.BROKER_CONTRACT];
      }
      console.log('BusinessObjectType typeId = ', typesId);
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = typesId;
    } else {
      this.dataProvider.searchCriteria.criteria.contractLinkTypes = undefined;
    }
  }

  isBonding(): boolean {
    return this.settingsService.bondingViews;
  }

  showAdditionalButtons(searchExecuted: boolean) {
    this.calculateCreateInvoiceVisibility(searchExecuted);
    this.calculateDeleteInvoiceItemsVisibility(searchExecuted);
  }

  calculateCreateInvoiceVisibility(searchExecuted: boolean) {
    const criteria = this.dataProvider.searchCriteria.criteria;
    if (
      criteria.invoiceCriteria.inInvoice === false &&
      (criteria.client.registrationName || criteria.contractNumber) &&
      searchExecuted
    ) {
      this.guiService.createInvoiceIndicator = true;
    } else {
      this.guiService.createInvoiceIndicator = false;
    }
  }

  calculateDeleteInvoiceItemsVisibility(searchExecuted: boolean) {
    if (
      this.dataProvider.searchCriteria.criteria.invoiceCriteria.inInvoice === false &&
      searchExecuted &&
      this.dataProvider.searchCriteria.criteria.businessObject &&
      this.dataProvider.searchCriteria.criteria.businessObjectNumber
    ) {
      this.guiService.deleteInvoiceItemsIndicator = true;
    } else {
      this.guiService.deleteInvoiceItemsIndicator = false;
    }
  }

  protected get totalCount() {
    return this.invoiceItemResultList.totalCount;
  }

  get newVisible() {
    // The broker note should be created from the button on invoice item otherwise it has no link to the source item.
    return this.categoryId !== InvoiceCategory.BROKER || !this.INVOICE_BROKER_NEW_BROKER_ITEM_RESTRICTIONS;
  }

  get createInvoiceVisible() {
    return this.guiService.createInvoiceIndicator && this.totalCount > 0;
  }

  get deleteInvoiceItemsVisible() {
    return this.guiService.deleteInvoiceItemsIndicator && this.totalCount > 0;
  }

  goToBusinessObject(item: InvoiceItemDto) {
    console.log('goToBusinessObject');
    if (item.businessObject.relatedTo.id && item.businessObject.relatedToId) {
      this.router.goToBusinessObject(item.businessObject.relatedTo.id, item.businessObject.relatedToId);
    } else {
      this.growlService.error('Bad link - business object type or id is missing!');
    }
  }

  createInvoiceClicked() {
    console.log('createInvoice');
    this.guiService.trasnsferDataProvider.copyData(this.dataProvider);
    if (this.invoiceItemResultList && this.invoiceItemResultList.items.length > 0) {
      this.guiService.trasnsferDataProvider.searchCriteria.criteria.client = this.invoiceItemResultList.items[0].client;
      this.guiService.trasnsferDataProvider.searchCriteria.criteria.statuses = [
        InvoiceItemStatus.AUTOMATIC,
        InvoiceItemStatus.MANUAL,
      ];
      this.router.toInvoiceCorrection(<InvoiceCategory>this.categoryId, 0);
    } else {
      this.router.toInvoiceDetails(this.categoryId, 0);
    }
  }

  deleteInvoiceItemsClicked() {
    this.confirmDialog.openAndExecuteOnConfirm(
      'invoiceItem.details.removeConfirmation',
      'invoiceItem.details.removeConfirmationMessage',
      this.deleteInvoiceItems.bind(this)
    );
  }

  private deleteInvoiceItems() {
    this.invoiceItemService.searchAndDeleteInvoiceItems(this.dataProvider.searchCriteria).subscribe(
      (result) => (this.deletedItemsCount = result),
      () => console.log('deleteInvoiceItems failure'),
      () => {
        console.log('deleteInvoiceItems finish');
        // this.invoiceItemResultList.search(undefined); // This was for test purposes
        this.invoiceItemResultList.items = new Array(); // clear table without calling backend
        this.invoiceItemResultList.totalCount = 0; // refresh count
        this.growlService.notice('Deleted ' + this.deletedItemsCount + ' Invoice Items!');
      }
    );
  }

  exportClicked() {
    this.exportInProgress = true;
    this.invoiceItemService.export(
      this.dataProvider.searchCriteria.criteria,
      'InvoiceItems.xlsx',
      (errorMsg) => this.downloadErrorCallback(errorMsg),
      () => this.exportCompleteCallback()
    );
  }

  newExecuted(event) {
    this.router.toInvoiceItemDetails(this.categoryId, 0);
  }

  getInvoiceItemCategoryLabel(): string {
    return this.guiService.getInvoiceItemCategoryLabel(this.invoiceCategoryMap[this.categoryId], false);
  }

  getInvoiceNumber(item: InvoiceItemDto): string {
    if (!item || !item.invoicePosition) {
      return '';
    }
    if (item.invoicePosition.invoice.number) {
      return item.invoicePosition.invoice.number;
    } else {
      return this.translateService.instant('invoiceItem.search.result.draft');
    }
  }

  get writeAllOffVisible() {
    return this.categoryId === InvoiceCategory.BROKER;
  }

  openWriteAllOffConfirmDialog() {
    this.confirmDialog.openAndExecuteOnConfirm(
      'invoiceItem.search.writeAllOffConfirmationTitle',
      'invoiceItem.search.writeAllOffConfirmationMessage',
      this.writeAllOff.bind(this)
    );
  }

  writeAllOff() {
    this.searchView.serverErrors = undefined;
    this.searchView.dataProvider.inProgress = true;
    this.invoiceItemMassService.writeAllOff(this.dataProvider.searchCriteria).subscribe(
      (result) => {
        this.growlService.notice('invoiceItem.search.writeAllOffSuccess', '', {count: result});
        this.searchView.dataProvider.inProgress = false;
        this.searchView.search();
      },
      (error) => {
        this.searchView.serverErrors = error;
        this.searchView.dataProvider.inProgress = false;
      }
    );
  }
}
