import {InvoiceItemSearchComponent} from './invoice-item-search.component';
import {InvoiceItemDetailsComponent} from './invoice-item-details.component';

export const InvoiceItemRoutes = [
  {
    path: 'invoice-item-search/:category',
    component: InvoiceItemSearchComponent,
  },
  {
    path: 'invoice-item-details/:category/:id',
    component: InvoiceItemDetailsComponent,
  },
  {
    path: 'invoice-item-details/:category/:id/:relatedToType/:relatedToId/:sourceItemId',
    component: InvoiceItemDetailsComponent,
  },
];
