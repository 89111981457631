import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  ActivitySimpleDto,
  CollectionRequestBaseDto,
  CollectionRequestDecisionDto,
  CollectionRequestDto,
  StateTransitionDto,
} from '../model';

@Injectable()
export class CollectionRequestsService extends AbstractService {
  protected url = this.urlPrefix + 'collectionRequests';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getAll(collectionId: number): Observable<CollectionRequestBaseDto[]> {
    return this.get(this.url + '?collectionId=' + collectionId);
  }

  getRequest(requestId: number): Observable<CollectionRequestDto> {
    return this.getById(requestId);
  }

  saveRequest(collectionId: number, request: CollectionRequestDto): Observable<CollectionRequestDto> {
    request.collection = {id: collectionId};
    return this.post(request, this.url);
  }

  saveDecision(requestId: number, decision: CollectionRequestDecisionDto): Observable<CollectionRequestDecisionDto> {
    return this.post(decision, this.url + '/' + requestId + '/decisions/');
  }

  getDecisionTransitions(
    requestId: number,
    collectionRequestDecision: CollectionRequestDecisionDto
  ): Observable<Map<number, Array<StateTransitionDto>>> {
    return this.get(this.url + '/' + requestId + '/decisions/transition/' + collectionRequestDecision.status.id);
  }

  sendToAcceptance(requestId: number, decisionId: number, userId: number): Observable<ActivitySimpleDto> {
    return this.post1(userId, this.url + '/' + requestId + '/decisions/' + decisionId + '/sendToAcceptance');
  }
}
