import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService} from '../../../../../bonding_shared';

@Component({
  selector: 'ecg-bonus-malus-section',
  templateUrl: './ecg-bonus-malus-section.component.pug',
})
export class EcgBonusMalusSectionComponent extends Section {
  self = this;

  constructor(protected policyContractViewService: PolicyContractViewService, public appService: AppConfigService) {
    super(policyContractViewService, appService);
  }

  bonusesOrMalusesNotEmpty() {
    return this.policy.maluses.length > 0 || this.policy.bonuses.length > 0;
  }
}
