import {Injectable} from '@angular/core';
import {TreatyVersionService} from '../../../bonding_shared/services/treaty-version.service';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {TreatyVersionCriteriaDto, TreatyVersionSimpleDto} from '../../../bonding_shared/model';

@Injectable()
export class TreatyGuiService {
  dataProvider: SearchDataProvider<TreatyVersionCriteriaDto, TreatyVersionSimpleDto>;

  constructor(private treatyService: TreatyVersionService) {
    this.dataProvider = new SearchDataProvider<TreatyVersionCriteriaDto, TreatyVersionSimpleDto>(treatyService);
  }
}
