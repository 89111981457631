import {Injectable} from '@angular/core';
import {AppConfigService} from '../../bonding_shared/services/app-config.service';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {HttpClient} from '@angular/common/http';
import {AbstractService, BinaryDownloaderService} from '../../bonding_shared/services';
import {Observable} from 'rxjs';
import {CommentDto} from '../../bonding_shared/model';

@Injectable()
export class PortalCollectionHistoryService extends AbstractService {
  protected url = this.urlPrefix + 'portal/collectionHistory';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getHistoryComment(collectionId: number): Observable<CommentDto> {
    return this.get<CommentDto>(this.url + '/' + collectionId + '/historyComment');
  }
  getPdf(collectionId: number) {
    console.log('get pdf export of collection history');
    this.binaryDownloader.download(
      'GET',
      this.url + '/' + collectionId + '/collection_history.pdf',
      'application/pdf',
      'application/pdf',
      'Raport z teczki sprawy.pdf'
    );
  }
}
