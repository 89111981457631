import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PasteFromExcelService} from './services/paste-from-excel.service';
import {HasId} from '../../model';
import {BTableComponent} from './b-table.component';
import {ColumnComponent} from './column.component';
import * as _ from 'lodash';

@Component({
  selector: 'selection-table',
  templateUrl: 'table.component.html',
  providers: [PasteFromExcelService],
})
export class SelectionTableComponent<T extends HasId> extends BTableComponent<T> {
  @Input()
  canSelectNew = true;

  @Output()
  unselectItem = new EventEmitter<T>();

  useSelectedClass(item: T): boolean {
    return this.inputItems.includes(item);
  }

  onSelectedRow(item: T, column: ColumnComponent<T>) {
    if (this.inputItems.includes(item)) {
      _.remove(this.inputItems, (v) => v === item);
      this.unselectItem.emit(item);
    } else if (this.canSelectNew) {
      this.inputItems.push(item);
      this.selectItem.emit(item);
    }
  }
}
