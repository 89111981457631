import {from as observableFrom} from 'rxjs';

import {concatMap, filter} from 'rxjs/operators';
import {Component} from '@angular/core';
import {DictionaryBaseService, LanguageService, LoggedUserService, UserService} from '../../bonding_shared/services';
import {DictionaryBaseDto} from '../../bonding_shared';
import {Feature} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'user-language',
  templateUrl: 'user-language.component.html',
})
export class UserLanguageComponent {
  readonly Feature = Feature;

  availableLanguages: string[] = [];
  lang: {[key: string]: string} = {};
  availableLangMap: {[key: string]: string} = {};

  constructor(
    public loggedUserService: LoggedUserService,
    private userService: UserService,
    private dictionaryBaseService: DictionaryBaseService,
    private languageService: LanguageService
  ) {
    this.getAvailableLanguages();
  }

  getAvailableLanguages() {
    this.languageService.getAvailableLanguages().subscribe((result) => {
      this.availableLanguages = result;
      this.availableLanguages.forEach((a) => {
        this.availableLangMap[this.eat$(a)] = a;
      });
      const userLang: DictionaryBaseDto = this.loggedUserService.getLoggedUserData().language;
      if (!this.availableLangMap[userLang.code]) {
        this.availableLangMap[userLang.code] = userLang.code;
      }
      this.setLanguagesTranslations();
    });
  }

  setLanguagesTranslations() {
    this.dictionaryBaseService
      .getDictionaryBase('Language')
      .pipe(
        concatMap((dict) => observableFrom(dict)),
        filter((lang) => this.isTranslationAvailable(lang.code))
      )
      .subscribe((lang) => {
        this.dictionaryBaseService.getDictionaryEntry('Language', lang.id, lang.id).subscribe((nativeLang) => {
          this.lang[this.availableLangMap[nativeLang.code]] = nativeLang.name;
        });
      });
  }

  getUserLang(): string {
    const langIsoCode = this.loggedUserService.getLoggedUserData().language.code;
    if (!this.isTranslationAvailable(langIsoCode)) {
      return 'en';
    }
    return langIsoCode;
  }

  changeLang(langIsoCode: string): void {
    this.userService.changeLanguage(langIsoCode).subscribe((success) => {
      window.location.reload();
    });
  }

  eat$(code: string): string {
    const idx = code.indexOf('$');
    return idx > -1 ? code.substring(0, idx) : code;
  }

  private isTranslationAvailable(langIsoCode: string): boolean {
    return !!this.availableLangMap[langIsoCode];
  }
}
