/**
 * Created by wilk on 22.08.2016.
 */
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {StringUtils} from '../../utils/string-utils';
import {DictionaryService} from '../../services';
import {TranslateService} from '@ngx-translate/core';

const NUM_INPUT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumberInputComponent),
  multi: true,
};

@Component({
  selector: 'num-input',
  template: `
    <input
      *ngIf="!presentationMode"
      [ngModel]="innerValue"
      (change)="onChange($event.target.value)"
      [disabled]="disabled"
      (blur)="onBlur()"
      (focus)="onFocus()"
      type="text"
      (input)="onInput($event)"
      [maxlength]="maxlength"
      (keypress)="keyPressed($event)"
      [required]="required"
      [minValue]="minVal"
      [maxValue]="maxVal"
      class="form-control"
      [ngStyle]="{'text-align': getTextAlign()}"
      style="width: 100%; vertical-align: baseline;"
      autocomplete="dontyoudare"
    />
    <span *ngIf="presentationMode" [ngClass]="getPresentationClass()">{{ innerValue }}</span>
  `,
  providers: [NUM_INPUT_VALUE_ACCESSOR],
})
export class NumberInputComponent implements ControlValueAccessor {
  constructor(public translateService: TranslateService) {}
  @Input() disabled: boolean;
  @Input() maxlength = 17;
  @Input() type: 'decimal' | 'integer' = 'decimal';
  @Input() allowNegative = false;
  @Input() baseMultiplier = 1;
  @Input() maxVal: number; // for wrong value validation error is shown
  @Input() minVal: number; // for wrong value validation error is shown
  @Input() min: number; // wrong value is changed into min without showing any error
  @Input() max: number; // wrong value is changed into max without showing any error
  @Input() numberOfDecimalPlaces = 2;
  @Input() required: boolean;
  @Input() alignLeft: boolean;
  @Input() nullLabel: string;
  @Input() presentationMode = false;
  @Input() set infinity(b: boolean) {
    if (b) {
      this.nullLabel = this.translateService.instant('common.infinity');
    }
  }

  @Input() formatting = true;
  @Output() changeValue = new EventEmitter<number>();

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: Function;
  private onChangeCallback: Function;
  public innerValue = '';
  private editionMode = false;

  private lastSelectionDirection: string;
  private lastSelectionEnd: number;
  private lastSelectionStart: number;

  onInput(ev: any) {
    let eventValue = ev.target.value;
    if (eventValue.match('[.]')) {
      eventValue = eventValue.replace('.', ',');
    }
    if (this.regCheck(eventValue)) {
      this.innerValue = eventValue;
    } else {
      ev.target.value = this.innerValue;
      ev.target.selectionDirection = this.lastSelectionDirection;
      ev.target.selectionEnd = this.lastSelectionEnd;
      ev.target.selectionStart = this.lastSelectionStart;
    }
  }
  // TODO ANGULAR MIGRATION check if it works with removing external toNumber
  onChange(value: string) {
    let valNum = this.toNumber(value);
    if (value) {
      if (isNaN(valNum)) {
        this.innerValue = '0';
      } else if (typeof this.max === 'number' && valNum > this.max) {
        this.innerValue = this.toStringPresentationMode(this.max, true);
        valNum = this.max;
      } else if (typeof this.min === 'number' && valNum < this.min) {
        this.innerValue = this.toStringPresentationMode(this.min, true);
        valNum = this.min;
      }
    }
    if (this.onChangeCallback) {
      this.onChangeCallback(valNum);
    }
    this.changeValue.emit(valNum);
  }

  // Set touched on blur
  onBlur() {
    if (this.editionMode) {
      this.innerValue = this.toStringPresentationMode(this.innerValue);
    }
    this.onTouchedCallback();
  }

  onFocus() {
    if (!this.disabled && !this.editionMode) {
      this.innerValue = this.toStringEditionMode(this.innerValue);
    }
  }

  // From ControlValueAccessor interface
  writeValue(value: number) {
    this.innerValue = this.toStringPresentationMode(value, true);
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: Function) {
    this.onTouchedCallback = fn;
  }

  // Remember selection to rollback change after pressing invalid key
  keyPressed(event: any) {
    this.lastSelectionDirection = event.target.selectionDirection;
    this.lastSelectionEnd = event.target.selectionEnd;
    this.lastSelectionStart = event.target.selectionStart;
  }

  regCheck(v: string): boolean {
    const numberOfDecimalPlaces = this.numberOfDecimalPlaces;
    const endOfDecimalRegexp = `\\d*(?:,\\d{0,${numberOfDecimalPlaces}})?$`;
    const regexp: RegExp = this.allowNegative
      ? this.type === 'integer'
        ? /^-?\d*$/
        : new RegExp('^-?' + endOfDecimalRegexp)
      : this.type === 'integer'
      ? /^\d*$/
      : new RegExp('^' + endOfDecimalRegexp);
    const matches: RegExpMatchArray = v.match(regexp);
    return matches !== null && matches.length > 0;
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private toStringPresentationMode(value: string | number, useBaseMultiplier: boolean = false): string {
    this.editionMode = false;
    if (value === undefined || value === null || value === '') {
      return this.nullLabel;
    }
    let numValue: number;
    if (typeof value === 'string') {
      value = value.replace(',', '.');
      value = value.replace(' ', '');
      numValue = Number(value);
    } else {
      numValue = value;
    }
    if (useBaseMultiplier) {
      numValue /= this.baseMultiplier;
    }

    const numberOfDecimalPlaces = this.numberOfDecimalPlaces;
    const decFormat = `1.${numberOfDecimalPlaces}-${numberOfDecimalPlaces}`;
    if (!this.formatting) {
      return numValue.toString();
    }
    return StringUtils.formatNumber(numValue, this.type === 'integer' ? '1.0-0' : decFormat);
  }

  private toStringEditionMode(value: string): string {
    this.editionMode = true;
    if (!value || value === this.nullLabel) {
      return null;
    }
    if (value.endsWith(',00')) {
      value = value.substring(0, value.length - 3);
    }
    // remove dots
    return value.replace(/\./g, '');
  }

  private toNumber(value: string): number {
    let v = this.toStringEditionMode(value);
    if (!v) {
      return undefined;
    }
    v = v.replace(',', '.');
    return Number(v) * this.baseMultiplier;
  }

  getTextAlign() {
    if (this.alignLeft) {
      return 'left';
    }
    return 'right';
  }

  getPresentationClass() {
    if (this.alignLeft) {
      return 'presentation';
    }
    return 'presentation-num';
  }
}
