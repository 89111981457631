import {Injectable} from '@angular/core';
import {AbstractService} from '../../bonding_shared/services/abstract.service';
import {AppConfigService} from '../../bonding_shared/services/app-config.service';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {Observable} from 'rxjs';
import {PortalBrokerDashboardDto} from '../../bonding_shared/model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class BrokerPortfolioService extends AbstractService {
  protected url = this.urlPrefix + 'portal/brokerPortfolio';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getDashboardStats(): Observable<PortalBrokerDashboardDto> {
    console.log('get dashboard BrokerContractStats: ');
    return this.get<PortalBrokerDashboardDto>(this.url + '/dashboard');
  }
}
