import {BondClauseDto, BondVersionDto} from '../../bonding_shared/model/dtos';
import {TranslateService} from '@ngx-translate/core';
import {ClientBondFlowService} from './domain/clientbond-flow.domain';

export class ClientBondSummaryWarnings {
  constructor(
    private bond: BondVersionDto,
    private translateService: TranslateService,
    private bondFlow: ClientBondFlowService
  ) {}

  getWarnings() {
    const warnings: string[] = [];

    const pushMsg = (msg: string, params?: Object) => {
      this.translateService.get(msg, params).subscribe((trans) => warnings.push(trans));
    };

    if (this.bond.specialClauses) {
      for (const obj of this.bond.specialClauses) {
        const bondClauseDto: BondClauseDto = <BondClauseDto>obj;
        if (bondClauseDto.clause.id === 84000004) {
          pushMsg('BondSummaryWarning.specialClause');
        }
      }
    }
    if (!this.bond.bondValidTo) {
      pushMsg('BondSummaryWarning.notLimitedInTime');
    }

    const {level, rate} = this.bondFlow.exceedsRateLevel();
    if (level === 1) {
      pushMsg('BondSummaryWarning.exceedsRate', {rate: rate});
    }

    return warnings;
  }
}
