import {Component, ViewChild} from '@angular/core';
import {DocumentListComponent} from './components';
import {DocumentCriteriaDto, DocumentService, IdsDto} from '../../bonding_shared';

@Component({
  selector: 'document-search',
  templateUrl: 'document-search.component.html',
})
export class DocumentSearchComponent {
  @ViewChild(DocumentListComponent, {static: true}) documentList: DocumentListComponent;

  zipOutputFileName = 'documents.zip';

  get criteria(): DocumentCriteriaDto {
    return this.documentList.criteria;
  }

  constructor(public documentService: DocumentService) {}

  getIdList() {
    const idList = <IdsDto>{};
    idList.ids = this.documentList.documentTable.items.map(({id}) => id);
    return idList;
  }
}
