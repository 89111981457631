import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FormDialogParent} from '../../../../bonding_shared/components/form-dialog/FormDialogParent';
import {FormDialogComponent} from '../../../../bonding_shared/components/form-dialog';
import {VoteDto} from '../../../../bonding_shared/model';

@Component({
  selector: 'bonding-contract-offer-voting-dialog',
  templateUrl: './bonding-contract-offer-voting-dialog.component.pug',
})
export class BondingContractOfferVotingDialogComponent extends FormDialogParent implements AfterViewInit {
  @ViewChild(FormDialogComponent, {static: true}) formDialogComponent: FormDialogComponent;
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;

  @Input() currencyCode: string;
  @Input() maxAmount: number;

  @Output() voteEmitter = new EventEmitter<VoteDto>();

  vote = <VoteDto>{};

  public ngAfterViewInit(): void {
    this.form = this.ngForm.form;
  }

  openDialog() {
    this.formDialogComponent.open();
  }

  doVote(approve: boolean, onHold: boolean) {
    this.formDialogComponent.hide();
    this.vote.onHold = onHold;
    if (!onHold) {
      this.vote.approve = approve;
    } else {
      this.vote.approve = null;
    }
    this.voteEmitter.emit(this.vote);
  }
}
