import {EventEmitter, Injectable} from '@angular/core';
import {
  DictionaryBaseDto,
  DictionaryDto,
  PolicyInquiryCountryDto,
  PolicyInquiryVersionDto,
} from '../../../bonding_shared/model/dtos';
import {InquiryCountries} from '../domain/inquiry-countries';
import {ReplaySubject, zip} from 'rxjs';
import {Country, InquiryProductType} from '../../../bonding_shared/model/dictionary-ids';
import {DictionaryService} from '../../../bonding_shared';
import {isType} from '../domain/offer-allowance';

@Injectable()
export class InquiryCountryInitialiazerService {
  productCountries: DictionaryBaseDto[] = null;
  filteredProductCountries: DictionaryDto[] = null;

  private countriesAll: DictionaryDto[];
  private _countriesInitialized = new ReplaySubject<DictionaryDto[]>();
  subCountries: {[key: number]: Array<number>} = null;
  private _countriesParent: WithCountries;

  constructor(private dictionaryService: DictionaryService) {
    this.dictionaryService.getDictionary('Country').subscribe((cs) => {
      this.subCountries = cs
        .filter(
          (s) =>
            s.relatedDictionaries && s.relatedDictionaries['Country'] && s.relatedDictionaries['Country'].length > 0
        )
        .reduce((subc, s) => {
          subc[s.id] = s.relatedDictionaries['Country'].map((c) => c.id);
          return subc;
        }, {});
    });

    this.dictionaryService.getDictionary('Country').subscribe((data) => {
      this.countriesAll = data;
      this._countriesInitialized.next(data);
    });
  }

  initMarketNonMarketCountries(
    type: DictionaryBaseDto,
    countriesInitiated: EventEmitter<any>,
    countriesParent: WithCountries,
    stopInitialize: boolean
  ) {
    console.assert(!!type, 'initMarketNonMarketCountries type empty');
    const productCountriesSub = InquiryCountries.getProductsCountriesById(this.dictionaryService, type.id);
    console.assert(!!countriesParent, 'countriesParent empty');
    this._countriesParent = countriesParent;

    zip(productCountriesSub, this._countriesInitialized).subscribe((data) => {
      let productCountries = data[0];
      const countries = data[1];
      const filteredCountries = countries.filter((c) => !productCountries.find((p) => p.id === c.id));

      if (isType(type, InquiryProductType.KUKE_KOM_FE)) {
        productCountries = productCountries.filter((p) => p.id !== Country.PL);
      }

      this.productCountries = productCountries;
      this.filteredProductCountries = filteredCountries;

      if (!stopInitialize && !this._countriesParent.id && this.productCountries) {
        this._countriesParent.countries = this.productCountries.map((pc) => <PolicyInquiryCountryDto>{country: pc});
        this.countriesSort();

        if (countriesInitiated) {
          countriesInitiated.emit();
        }
      }
    });
  }

  countriesSort() {
    if (this.countriesParent && this.countriesParent.countries) {
      this.countriesParent.countries.sort((c1, c2) =>
        c1.country.name.normalize().localeCompare(c2.country.name.normalize())
      );
    }
  }

  get countriesParent() {
    return this._countriesParent;
  }

  addSubCountries(country: PolicyInquiryCountryDto) {
    if (!this.subCountries) {
      return;
    }
    const subcs = this.subCountries[country.country.id];
    if (subcs) {
      const countries = this.countriesParent.countries;
      for (const subcNumber of subcs) {
        if (!countries.find((c) => c.id === subcNumber)) {
          const subcDict = this.countriesAll.find((c) => c.id === subcNumber);
          countries.push(<any>{country: subcDict});
        }
      }
    }
  }
}

export type WithCountries = PolicyInquiryVersionDto;
