import {Injectable} from '@angular/core';
import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {
  EventViewDto,
  OfferDecisionDto,
  PolicyInquiryOfferDto,
  PolicyInquiryOfferEnvelopeDto,
  StateTransitionDto,
} from '../model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class OfferService extends AbstractService {
  protected url = this.urlPrefix + 'policyInquiryVersion/offer';

  constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getTransitions(offerDto: PolicyInquiryOfferDto): Observable<Array<StateTransitionDto>> {
    return this.get<Array<StateTransitionDto>>(
      this.url +
        '/transition/' +
        offerDto.businessStatus.id +
        '/' +
        offerDto.edge +
        '/' +
        (offerDto.type && offerDto.type.id)
    );
  }

  getTransitionsMap(): Observable<Map<number, Array<StateTransitionDto>>> {
    return this.get<Map<number, Array<StateTransitionDto>>>(this.url + '/transition');
  }

  decideOffer(decision: OfferDecisionDto): Observable<PolicyInquiryOfferEnvelopeDto> {
    return this.put1<OfferDecisionDto, PolicyInquiryOfferEnvelopeDto>(decision, this.url + '/decision');
  }

  getDecisions(offerId: number): Observable<OfferDecisionDto[]> {
    return this.get<OfferDecisionDto[]>(this.url + '/' + offerId + '/decisions');
  }

  getHistory(offerId: number): Observable<EventViewDto[]> {
    return this.get<EventViewDto[]>(this.url + '/' + offerId + '/history');
  }

  extendValidityPeriod(offerId: number): Observable<Date> {
    const params = UrlParams.new();
    const url = this.buildUrl(this.url + '/extendValidityPeriod/' + offerId, null, params);
    return this.postEmpty<Date>(url);
  }
}
