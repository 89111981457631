import {Injectable} from '@angular/core';
import {ThirdPartyService} from '../../../bonding_shared/services/thirdparty.service';
import {LoggedUserService} from '../../../bonding_shared/services';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {ThirdPartyBaseDto, ThirdPartyCriteriaDto, ThirdPartyDto} from '../../../bonding_shared/model/dtos';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';

@Injectable()
export class ThirdPartyGuiService {
  dataProvider: SearchDataProvider<ThirdPartyCriteriaDto, ThirdPartyBaseDto>;

  constructor(private thirdPartyService: ThirdPartyService, loggedUserService: LoggedUserService) {
    this.dataProvider = BusinessUtils.createThirdPartyDataProvider(thirdPartyService);
    this.dataProvider.searchCriteria.criteria.onlyProvisional = true;
    this.dataProvider.textSearch = false;
  }
}
