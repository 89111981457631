import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {BondVerifyComponent} from './bond-verify.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {SharedComponentsModule} from '../../../../../src/client/app/bonding_shared/components/shared-components.module';
import {BondVerifyService} from './bond-verify.service';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, TranslateModule, SharedComponentsModule],
  declarations: [BondVerifyComponent],
  exports: [BondVerifyComponent],
  providers: [BondVerifyService],
})
export class BondVerifyModule {}
