import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContractSharedModule} from '../contract/components/shared/contract-shared.module';
import {CompanySharedModule} from '../../company/components/shared/company-shared.module';
import {CompanyExtendedSelectorModule} from '../../company/components/shared/extended-selectors/company-extended-selector.module';
import {TabViewModule} from 'primeng/tabview';
import {CustomFormModule} from '../../../common/custom-form/custom-form.module';
import {BusinessObjectInfoModule} from '../../business-object-info/business-object-info.module';
import {BrokerContractSharedModule} from '../../broker-contract/components/shared/broker-contract-shared.module';
import {SalesRepSharedModule} from '../../sales-rep/components/shared/sales-rep-shared.module';
import {BondingContractOfferDetailsComponent} from './bonding-contract-offer-details.component';
import {BondingContractOfferListComponent} from './components/bonding-contract-offer-list.component';
import {BondingContractCommonModule} from '../common/bonding-contract-common.module';
import {BondingContractOfferVotingSectionComponent} from './components/bonding-contract-offer-voting-section.component';
import {BondingContractOfferVotingDialogComponent} from './components/bonding-contract-offer-voting-dialog.component';
import {DocumentModule} from 'src/client/app/bonding/document/document.module';
import {BondingContractOfferDetailsGuard} from './_guards';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    ContractSharedModule,
    CompanyExtendedSelectorModule,
    TabViewModule,
    CustomFormModule,
    BusinessObjectInfoModule,
    BrokerContractSharedModule,
    SalesRepSharedModule,
    BondingContractCommonModule,
    DocumentModule,
  ],
  declarations: [
    BondingContractOfferDetailsComponent,
    BondingContractOfferListComponent,
    BondingContractOfferVotingSectionComponent,
    BondingContractOfferVotingDialogComponent,
  ],
  exports: [BondingContractOfferListComponent],
  providers: [BondingContractOfferDetailsGuard],
})
export class BondingContractOfferModule {}
