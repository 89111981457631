import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {DocumentService} from '../../../bonding_shared/services/document.service';

@Injectable()
export class DocumentGuiService {
  businessObjectId: number;

  constructor(private service: DocumentService) {}

  createDataProvider<DocumentCriteriaDto, DocumentSimpleDto>(): SearchDataProvider<
    DocumentCriteriaDto,
    DocumentSimpleDto
  > {
    return new SearchDataProvider<DocumentCriteriaDto, DocumentSimpleDto>(this.service);
  }
}
