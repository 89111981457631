import {Component, Input} from '@angular/core';
import {
  AppConfigService,
  BIOrderReportButtonOption,
  BiService,
  CompanyDto,
  CompanyIdentifierDto,
} from '../../../../bonding_shared';
import {BISource, CompanyIdentifierType} from '../../../../bonding_shared/model/dictionary-ids';
import {CompanyGuiService} from '../../services/company-gui.service';

@Component({
  selector: 'order-report-button',
  templateUrl: './order-report-button.component.pug',
})
export class OrderReportButtonComponent {
  private _company: CompanyDto;
  options: BIOrderReportButtonOption[];

  @Input() disabled;
  @Input() loadOrOrderReport: Function;
  @Input() loadExternalId: Function;
  @Input() biSource: BISource;
  @Input() biCountryId: number;
  @Input() inProgress = false;

  get company() {
    return this._company;
  }

  @Input() set company(company: CompanyDto) {
    if (this.biSource && this.biSource !== BISource.INTERNAL.valueOf()) {
      if (company !== this.company) {
        this.prepareOptionsForExternalCompany(company);
      }
    } else if (!this.company || this.company.id !== company.id) {
      this.biIntraService.getOrderReportButtonOptions(company.id).subscribe((options) => (this.options = options));
    }
  }

  constructor(
    public appService: AppConfigService,
    private biIntraService: BiService,
    private companyGUIService: CompanyGuiService
  ) {}

  private prepareOptionsForExternalCompany(company: CompanyDto) {
    const country = this.companyGUIService.getCompanyCountry(company);
    const countryId = this.biCountryId ? this.biCountryId : country ? country.id : undefined;
    if (countryId) {
      this.biIntraService.getOrderReportButtonOptionsForCountry(countryId).subscribe((options) => {
        this.options = options.filter((o) => o.biSource.id === this.biSource);
        this.options.forEach((o) => (o.hasIdentifier = this.biIdentifier(o.biSource.id)));
      });
    }
  }

  private biIdentifier(biSourceId: number): boolean {
    return (
      this.company &&
      this.company.identifiers &&
      this.company.identifiers.some((i) => i.biSource && i.biSource.id === biSourceId)
    );
  }

  canLoadReport(option: BIOrderReportButtonOption): boolean {
    return (
      option.hasIdentifier || !this.company || !this.company.id || this.hasReportIdentifiers() || !this.appService.kuke
    );
  }

  hasReportIdentifiers(): boolean {
    return (
      this.company && this.company.identifiers && !!this.company.identifiers.find((i) => this.isAcceptableIdentifier(i))
    );
  }

  isAcceptableIdentifier(i: CompanyIdentifierDto): boolean {
    return (
      i.biSource.id === this.biSource &&
      (i.type.id === CompanyIdentifierType.EXTERNAL_ID || i.type.id === CompanyIdentifierType.TAX_ID)
    );
  }

  orderReportLabel(biSourceId: number, name: string): string {
    if ((this.appService.credendo || this.appService.mehib) && BISource.BISNODE === biSourceId) {
      return name + ' no validations';
    } else {
      return name;
    }
  }
}
