import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output, Renderer2} from '@angular/core';

// should be used on card or card-simple tag
// makes .card-block element visible/hidden on .card-title click
// adds triangle icon to .card-title element
// .card-block is visible by default
@Directive({
  selector: '[expandableSection]',
})
export class ExpandableSectionDirective implements AfterViewInit {
  @Output() toggleSection = new EventEmitter<boolean>();

  private _expandableSection: string | boolean;
  private _showContent = true;
  private triangle: ElementRef;
  private initialized = false;

  constructor(public card: ElementRef, public renderer: Renderer2) {}

  init() {
    let cardTitleEl = this.card.nativeElement.querySelector('.expandableTitle');
    if (!cardTitleEl) {
      cardTitleEl = this.card.nativeElement.querySelector('.bon-header');
    }
    if (!cardTitleEl) {
      cardTitleEl = this.card.nativeElement.querySelector('.card-title');
    }
    if (!cardTitleEl) {
      cardTitleEl = this.card.nativeElement.querySelector('.bon-header-inner');
    }
    const sectionTitle = cardTitleEl.firstChild.textContent;
    if (this._showContent === undefined || this._showContent === null || this._showContent + '' === '') {
      this._showContent = true;
    }
    console.log(
      'ExpandableSectionDirective title:',
      sectionTitle,
      'show: ' + this._showContent,
      typeof this._showContent
    );
    this.renderer.addClass(cardTitleEl, 'expandable');
    cardTitleEl.innerHTML = '';
    this.triangle = __ngRendererCreateElementHelper(this.renderer, cardTitleEl, 'i');
    this.renderer.addClass(this.triangle, 'fa');
    this.renderer.setStyle(this.triangle, 'width', '9px');
    this.renderer.setStyle(this.triangle, 'margin-right', '4px');
    __ngRendererCreateTextHelper(this.renderer, cardTitleEl, sectionTitle);
    this.renderer.listen(cardTitleEl, 'click', (event: any) => {
      this.toggleCard();
    });
    this.switchTriangle();
    this.switchBlock();
    this.initialized = true;
  }

  ngAfterViewInit() {
    if (this._expandableSection !== 'disabled') {
      this.init();
    }
  }

  // disabled-aligned is different from disabled in that way that it renders the triangle but it makes it invisible
  // so all section titles have the same indent, no matter if they have the triangle switch or not
  @Input() set expandableSection(expandableSection: 'disabled' | 'disabled-aligned' | boolean) {
    this._expandableSection = expandableSection;
    if (!expandableSection) {
      this._showContent = false;
    } else if (typeof expandableSection === 'boolean' || expandableSection === 'disabled-aligned') {
      this._showContent = typeof expandableSection === 'boolean' ? expandableSection : true;
    }
    if (this.initialized) {
      this.switchTriangle();
      this.switchBlock();
    }
  }

  toggleCard() {
    console.log('ExpandableSectionDirective :: onClick card-header');
    this._showContent = !this._showContent;
    this.switchTriangle();
    this.switchBlock();
    this.toggleSection.emit(true);
  }

  switchBlock() {
    const cardBlockEl = this.card.nativeElement.querySelector('.card-block');
    if (cardBlockEl) {
      !this._showContent
        ? this.renderer.addClass(cardBlockEl, 'hidden')
        : this.renderer.removeClass(cardBlockEl, 'hidden');
    }
    const contentElements: NodeList = this.card.nativeElement.querySelectorAll('.expandableContent');
    for (let i = 0; i < contentElements.length; i++) {
      console.log('Content element: ', contentElements.item(i));
      !this._showContent
        ? this.renderer.addClass(contentElements.item(i), 'hidden')
        : this.renderer.removeClass(contentElements.item(i), 'hidden');
    }
  }

  switchTriangle() {
    this._showContent
      ? this.renderer.addClass(this.triangle, 'fa-angle-down')
      : this.renderer.removeClass(this.triangle, 'fa-angle-down');
    !this._showContent
      ? this.renderer.addClass(this.triangle, 'fa-angle-right')
      : this.renderer.removeClass(this.triangle, 'fa-angle-right');
    if (this._expandableSection === 'disabled-aligned') {
      this._showContent
        ? this.renderer.addClass(this.triangle, 'invisible')
        : this.renderer.removeClass(this.triangle, 'invisible');
    }
  }
}

type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
  if (name[0] === ':') {
    const match = name.match(/^:([^:]+):(.+)$/);
    return [match[1], match[2]];
  }
  return ['', name];
}

function __ngRendererCreateElementHelper(
  renderer: AnyDuringRendererMigration,
  parent: AnyDuringRendererMigration,
  namespaceAndName: AnyDuringRendererMigration
) {
  const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
  const node = renderer.createElement(name, namespace);
  if (parent) {
    renderer.appendChild(parent, node);
  }
  return node;
}

function __ngRendererCreateTextHelper(
  renderer: AnyDuringRendererMigration,
  parent: AnyDuringRendererMigration,
  value: AnyDuringRendererMigration
) {
  const node = renderer.createText(value);
  if (parent) {
    renderer.appendChild(parent, node);
  }
  return node;
}
