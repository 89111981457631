import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {TranslateModule} from '@ngx-translate/core';
import {FilterableContractVersionListComponent} from './filterable-contract-version-list.component';
import {ContractVersionListComponent} from './contract-version-list.component';
import {BondTypeListComponent} from './bond-type-list.component';
import {ContractSectionSelectorComponent} from './contract-section-selector.component';
import {CompanySharedModule} from '../../../../company/components/shared/company-shared.module';
import {MinimumDocumentary} from './bond-minimum-documentary.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    TranslateModule,
    CompanySharedModule,
  ],
  declarations: [
    FilterableContractVersionListComponent,
    ContractVersionListComponent,
    BondTypeListComponent,
    ContractSectionSelectorComponent,
    MinimumDocumentary,
  ],
  exports: [
    FilterableContractVersionListComponent,
    ContractVersionListComponent,
    BondTypeListComponent,
    ContractSectionSelectorComponent,
    MinimumDocumentary,
  ],
})
export class ContractSharedModule {}
