import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  BankAccountData,
  CollectionAccountedCostDto,
  CollectionBaseDto,
  CollectionCostsViewDto,
  CollectionPaymentsDto,
  CollectionSimpleDto,
} from '../model';
import {CollectionAuditedService} from './collection-audited.service';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import {BusinessObjectType} from '../model/dictionary-ids';

@Injectable()
export class CollectionCostsInvoicingService extends AbstractService {
  protected url = this.urlPrefix + 'collectionCostsInvoicing';
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getAccountedCosts(id: number): Observable<CollectionAccountedCostDto> {
    return this.get<CollectionAccountedCostDto>(this.url + '/accountedCosts/' + id);
  }

  getCollection(id: number): Observable<CollectionSimpleDto> {
    return this.get<CollectionSimpleDto>(this.url + '/collection/' + id);
  }

  getCollectionBankAccounts(buId: number, currencyId: number): Observable<BankAccountData[]> {
    return this.get<BankAccountData[]>(
      this.url + '/collection/bankAccounts?buId=' + buId + '&currencyId=' + currencyId
    );
  }

  invoiceCosts(costs: CollectionAccountedCostDto): Observable<CollectionAccountedCostDto> {
    console.log('Before sent invoicing request');
    return this.put<CollectionAccountedCostDto>(costs, this.url);
  }

  isInvoiced(id: number): Observable<Boolean> {
    return this.get<Boolean>(
      this.url +
        '/isInvoiced' +
        '?relatedToTypeId=' +
        BusinessObjectType.COLLECTION_ACCOUNTED_COSTS +
        '&relatedToId=' +
        id
    );
  }
}
