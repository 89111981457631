import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {
  CompanyRatingDto,
  CompanyRatingVersionDto,
  CompanyRatingVersionPreviewDto,
  DictionaryBaseDto,
  ManualRatingCalculationResultDto,
} from '../model';
import {HttpClient} from '@angular/common/http';
import {RatingType} from '../model/dictionary-ids';

@Injectable()
export class CompanyRatingService extends AbstractService {
  protected url = this.urlPrefix + 'companyRating';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getCompanyRating(companyId: number, categoryId: number, ratingTypeId: number): Observable<CompanyRatingDto> {
    console.log('ratingTypeId: ', ratingTypeId);
    if (ratingTypeId) {
      return this.get<CompanyRatingDto>(
        this.url + '?companyId=' + companyId + '&ratingCategoryId=' + categoryId + '&ratingTypeId=' + ratingTypeId
      );
    } else {
      return this.get<CompanyRatingDto>(this.url + '?companyId=' + companyId + '&ratingCategoryId=' + categoryId);
    }
  }

  getLastCompanyRatingVersion(
    companyId: number,
    ratingTypeId: number = RatingType.BONDING
  ): Observable<CompanyRatingVersionDto> {
    return this.get<CompanyRatingVersionDto>(
      this.url + '/lastVersion?companyId=' + companyId + '&ratingTypeId=' + ratingTypeId
    );
  }

  getLastCompanyRatingVersionPreviews(companyId: number): Observable<CompanyRatingVersionPreviewDto[]> {
    return this.get<CompanyRatingVersionPreviewDto[]>(this.url + '/lastVersionPreviews?companyId=' + companyId);
  }

  getLimitRatingVersion(companyId: number): Observable<CompanyRatingVersionDto> {
    return this.get<CompanyRatingVersionDto>(this.url + '/limitRating?companyId=' + companyId);
  }

  initialRatingVersion(
    companyId: number,
    categoryId: number,
    ratingTypeId: number
  ): Observable<CompanyRatingVersionDto> {
    return this.get<CompanyRatingVersionDto>(
      this.url +
        '/initialVersion?companyId=' +
        companyId +
        '&categoryId=' +
        categoryId +
        '&ratingTypeId=' +
        ratingTypeId
    );
  }

  newRatingVersion(companyId: number, ratingTypeId: number): Observable<CompanyRatingVersionDto> {
    return this.get<CompanyRatingVersionDto>(
      this.url + '/newVersion?companyId=' + companyId + '&ratingTypeId=' + ratingTypeId
    );
  }

  createRatingVersion(rating: CompanyRatingVersionDto): Observable<CompanyRatingVersionDto> {
    console.log('create rating: ', rating);
    return this.put<CompanyRatingVersionDto>(rating, this.url);
  }

  calculateManualRating(rating: CompanyRatingVersionDto): Observable<ManualRatingCalculationResultDto> {
    return this.put1<CompanyRatingVersionDto, ManualRatingCalculationResultDto>(
      rating,
      this.url + '/calculateManualRating'
    );
  }

  updateRatingVersion(rating: CompanyRatingVersionDto): Observable<CompanyRatingVersionDto> {
    return this.post<CompanyRatingVersionDto>(rating, this.url + '/update');
  }

  calculate(companyId: number, ratingTypeId: number): Observable<CompanyRatingVersionDto> {
    return this.postEmpty(this.url + '/calculate?companyId=' + companyId + '&ratingTypeId=' + ratingTypeId);
  }

  getRatingStatuses(): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/statuses');
  }
  getRatingTypes(parentId: number, companyId: number): Observable<DictionaryBaseDto[]> {
    const params = [];
    if (parentId) {
      params.push('parentId=' + parentId);
    }
    if (companyId) {
      params.push('companyId=' + companyId);
    }
    return this.get<DictionaryBaseDto[]>(this.url + '/ratingTypes' + '?' + params.join('&'));
  }

  getRatingCategories(companyId: number): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/ratingCategories' + '?' + 'companyId=' + companyId);
  }

  getRatingType(categoryId: number): Observable<DictionaryBaseDto> {
    return this.get<DictionaryBaseDto>(this.url + '/ratingType' + '?' + 'categoryId=' + categoryId);
  }
}
