import {Component, OnInit} from '@angular/core';
import {
  AppConfigService,
  FeatureService,
  RepositoryService,
  RouterService,
  SettingsService,
  TemplateService,
} from '../../bonding_shared';
import {HealthCheckResultDto} from '../../bonding_shared/model/repo/repo-types';

@Component({
  selector: 'repo-health-page',
  templateUrl: './repository-health-page.component.pug',
})
export class RepositoryHealthPageComponent implements OnInit {
  healthCheck: HealthCheckResultDto;
  curl: string;
  self = this;

  constructor(
    public repositoryService: RepositoryService,
    public appService: AppConfigService,
    private templateService: TemplateService,
    public router: RouterService,
    public settingsService: SettingsService
  ) {}

  ngOnInit(): void {}

  toAlfrescoHealthPage(curl: string) {
    console.log('GET healt check!');
    this.repositoryService.getHealthPage(curl).subscribe((hp) => (this.healthCheck = hp));
  }
}
