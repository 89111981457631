import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BusinessUnitService, LoggedUserService} from '../../services';
import {BusinessUnitDto, BusinessUnitIdDto, UserDto} from '../../model';
import {StringUtils} from '../../utils';

const BUSINESS_UNIT_ID_SELECTOR_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BusinessUnitIdSelectorComponent),
  multi: true,
};

@Component({
  selector: 'business-unit-id-selector',
  templateUrl: 'business-unit-id-selector.component.html',
  providers: [BUSINESS_UNIT_ID_SELECTOR_CONTROL_VALUE_ACCESSOR],
})
export class BusinessUnitIdSelectorComponent implements ControlValueAccessor, OnInit {
  @Input() nullLabel: String;
  @Input() nullLabelKey: String;
  @Input() disabled = false;
  @Input() userDefaultBU = false;
  @Input() readonly = false;
  @Output() changeItem = new EventEmitter<BusinessUnitIdDto>();
  public form: UntypedFormGroup;
  businessUnitResults: BusinessUnitDto[];
  _selectedBusinessUnit: BusinessUnitDto;

  businessUnit: BusinessUnitDto;
  preSelectedBusinessUnit: BusinessUnitIdDto;

  onChangeModel: Function;
  onTouchedModel: Function;

  get selectedBusinessUnit(): BusinessUnitDto {
    return this._selectedBusinessUnit;
  }

  set selectedBusinessUnit(k: BusinessUnitDto) {
    this._selectedBusinessUnit = k;
  }

  constructor(
    private loggedUserService: LoggedUserService,
    private businessUnitService: BusinessUnitService,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.form = _formBuilder.group({});
  }

  ngOnInit() {
    this.businessUnitService.getBusinessUnits().subscribe(
      (businessUnitResult) => (this.businessUnitResults = businessUnitResult),
      (error) => console.log('Error on businessUnitService.getBusinessUnits ', error),
      () => {
        this.setPreSelectedBU();
        this.setUserDefaultBU();
      }
    );
  }

  setPreSelectedBU() {
    if (this.preSelectedBusinessUnit) {
      const id = this.preSelectedBusinessUnit.id;
      this._selectedBusinessUnit = StringUtils.find(this.businessUnitResults, function (bu) {
        return bu.id === id;
      });
      delete this.preSelectedBusinessUnit;
    }
  }

  setUserDefaultBU() {
    if (this.userDefaultBU) {
      const user: UserDto = this.loggedUserService.getLoggedUserData();
      if (user && user.businessUnit) {
        this.selectedBusinessUnit = user.businessUnit;
      }
    }
  }

  writeValue(item: BusinessUnitIdDto): void {
    if (this.businessUnitResults) {
      if (item) {
        this._selectedBusinessUnit = StringUtils.find(this.businessUnitResults, (bu) => bu.id === item.id);
      } else {
        this._selectedBusinessUnit = undefined;
      }
    } else {
      this.preSelectedBusinessUnit = item;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedModel = fn;
  }

  onChangeItem(event: BusinessUnitDto) {
    this.selectedBusinessUnit = event;
    const buIdDto = event ? <BusinessUnitIdDto>{id: event.id, companyId: event.company.id} : event;
    this.onChangeModel(buIdDto);
    this.changeItem.emit(buIdDto);
  }
}
