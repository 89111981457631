import {StringUtils} from '../utils/string-utils';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'url',
})
export class UrlPipe implements PipeTransform {
  transform(url: String) {
    if (!url || url.startsWith('http')) {
      return url;
    }
    return 'http://' + url;
  }
}
