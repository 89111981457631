import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
@Directive({
  selector: '[bonFocus]',
})
export class BonFocusDirective implements OnInit {
  private _bonFocus: boolean | string;

  @Input() set bonFocus(bonFocus: boolean | string) {
    this._bonFocus = bonFocus;
    switch (bonFocus) {
      case true:
        setTimeout(function () {
          this.focus();
        }, 0);
        break;
      case false:
        setTimeout(function () {
          this.blur();
        }, 0);
        break;
    }
  }

  constructor(public renderer: Renderer2, public elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this._bonFocus === true || this._bonFocus === 'auto') {
      setTimeout(function () {
        this.focus();
      }, 0);
    }
  }

  focus() {
    // TODO ANGULAR delete after confiramtion that solution works
    // Fix according to migration guide
    // this.renderer.invokeElementMethod(this.elementRef.nativeElement, 'focus', []);
    (this.elementRef.nativeElement as any)['focus'].apply(this.elementRef.nativeElement, []);
  }

  blur() {
    // TODO ANGULAR delete after confiramtion that solution works
    // Fix according to migration guide
    // this.renderer.invokeElementMethod(this.elementRef.nativeElement, 'blur', []);
    (this.elementRef.nativeElement as any)['blur'].apply(this.elementRef.nativeElement, []);
  }
}
