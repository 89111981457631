import {Component, Input} from '@angular/core';
import {SectionSelector} from '../../../bonding_shared/components/details-view/section-selector';
import {ProductLimitListVersionCriteriaDto} from '../../../bonding_shared/model';
import {LoggedUserService} from '../../../bonding_shared/services';

@Component({
  selector: 'limit-list-section-selector',
  template: `
    <ng-template #section let-openEmitter="openEmitter" let-object="object" let-disabled="disabled">
      <selectable-object-section
        [labelKey]="sectionLabel"
        [clearable]="false"
        [searchable]="!readOnly && !disabled"
        [openSelectorEmitter]="openEmitter"
      >
        <policy-limit-list-info [policyLimitList]="object[property]" [showFactorer]="false"></policy-limit-list-info>
      </selectable-object-section>
    </ng-template>
    <ng-template #selector let-openEmitter="openEmitter" let-onChange="onChange">
      <policy-limit-list-selector
        (selectItemParent)="onChange($event)"
        label="limitDetails.selectPolicyList"
        [open]="openEmitter"
        [criteria]="criteria"
        [emitParent]="true"
        [fixedSearch]="true"
        [oneResultAutoSelection]="true"
      >
      </policy-limit-list-selector>
    </ng-template>
  `,
  providers: [{provide: SectionSelector, useExisting: LimitListSectionSelectorComponent}],
})
export class LimitListSectionSelectorComponent extends SectionSelector {
  @Input() readOnly = false;
  @Input() criteria: ProductLimitListVersionCriteriaDto;

  constructor(private loggedUserService: LoggedUserService) {
    super();
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}
