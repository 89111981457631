import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {AppConfigService} from '../../../../bonding_shared/services/app-config.service';
import {DictionaryService, RouterService} from '../../../../bonding_shared/services';

@Component({
  selector: 'kuke-limit-list-section',
  templateUrl: './kuke-limit-list-section.component.pug',
})
export class KukeLimitListSectionComponent extends Section {
  self = this;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected appService: AppConfigService,
    protected dictionaryService: DictionaryService,
    public router: RouterService
  ) {
    super(policyContractViewService, appService, undefined, dictionaryService);
  }
}
