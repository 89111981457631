import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    Accept: 'application/html',
    'Access-Control-Allow-Origin': '*',
  }),
};

@Component({
  selector: 'info',
  templateUrl: 'info.component.html',
})
export class InfoComponent implements OnInit {
  private bu: string;
  private path: string;
  infoContent: string = 'Loading';

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit() {
    this.route.params.subscribe((params) => this.initInfoContent(params));
  }

  private initInfoContent(params: Params) {
    this.bu = params['businessUnit'];
    this.path = params['path'];
    const url = 'assets/info/' + this.bu + '/' + this.path + '.html';
    this.http.get(url, {...httpOptions, responseType: 'text'}).subscribe(
      (res) => {
        this.infoContent = res;
      },
      (error) => console.log(error)
    );
  }
}
