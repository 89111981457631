import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {BusinessPropertiesDto} from '../model/dtos';
import {HttpClient} from '@angular/common/http';
import {Cacheable} from 'ts-cacheable';

@Injectable()
export class BusinessPropertiesService extends AbstractService {
  protected url = this.urlPrefix + 'businessProperties';
  properties: BusinessPropertiesDto;

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    super.get<BusinessPropertiesDto>(this.url).subscribe(
      (p) => {
        this.properties = p;
      },
      (e) => console.log('error getting properties', e)
    );
  }

  @Cacheable()
  getProperties(): Observable<BusinessPropertiesDto> {
    return this.get<BusinessPropertiesDto>(this.url);
  }
}
