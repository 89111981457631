import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CustomRevisionEntityDto} from '../model';

export abstract class AbstractAuditedService<T> extends AbstractService {
  protected constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  protected build(id: number): string {
    return id ? this.url + '/' + id : this.url;
  }

  getRevisions(id: number): Observable<CustomRevisionEntityDto[]> {
    return this.get(this.build(id) + '/revisions');
  }

  getFromRevision(id: number, revisionId?: number): Observable<T> {
    return this.get(this.build(id) + (revisionId ? '?revisionId=' + revisionId : ''));
  }

  saveAudited(id: number, dto: T): Observable<T> {
    if (id) {
      return this.post(dto, this.url);
    } else {
      return this.put(dto, this.url);
    }
  }
}
