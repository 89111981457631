import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {FINoteNumberingBagCriteriaDto, FINoteNumberingBagSimpleDto, SearchCriteria, SearchResult} from '../model/dtos';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class FINoteNumberingBagService extends AbstractService {
  constructor(http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + 'fiNoteNumberingBag';
  }

  search(
    criteria: SearchCriteria<FINoteNumberingBagCriteriaDto>
  ): Observable<SearchResult<FINoteNumberingBagSimpleDto>> {
    return this.searchByCriteria<FINoteNumberingBagCriteriaDto, FINoteNumberingBagSimpleDto>(criteria);
  }
}
