import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[dateNotInPast]',
  providers: [{provide: NG_VALIDATORS, useExisting: DateNotInPastDirective, multi: true}],
})
export class DateNotInPastDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const date: Date = <Date>control.value;
    if (typeof date !== 'undefined' && date !== null) {
      const now = new Date();
      const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
      const dueDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
      return dueDate.valueOf() - today.valueOf() >= 0 ? null : {pastDate: true};
    }
    return null;
  }
}
