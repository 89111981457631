import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {EcgClientPolicyPreviewComponent} from './ecg-client-policy-preview.component';
import {PolicyContractViewService} from '../../../../../src/client/app/bonding/policy/services/policy-contract-view.service';
import {SharedComponentsModule} from '../../../../../src/client/app/bonding_shared/components/shared-components.module';
import {CompanySharedModule} from '../../../../../src/client/app/bonding/company/components/shared/company-shared.module';
import {PolicyContractSharedModule} from '../../../../../src/client/app/bonding/policy/components/shared/policy-contract-shared.module';
import {BrokerContractModule} from '../../../../../src/client/app/bonding/broker-contract/broker-contract.module';
import {InquirySharedModule} from '../../../../../src/client/app/bonding/inquiry/shared/inquiry-shared.module';
import {InquiryFormsModule} from '../../../../../src/client/app/bonding/inquiry/forms/inquiry-forms.module';
import {ClientPolicyContractVersionSearchComponent} from './client-policy-contract-version-search.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    PolicyContractSharedModule,
    BrokerContractModule,
    InquirySharedModule,
    InquiryFormsModule,
  ],
  declarations: [EcgClientPolicyPreviewComponent, ClientPolicyContractVersionSearchComponent],
  providers: [PolicyContractViewService],
})
export class EcgClientPolicyModule {}
