import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Version} from '../model/index';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';

@Injectable()
export class VersionInfoService extends AbstractService {
  protected url = this.urlPrefix + 'version';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getVersion(): Observable<Version> {
    return this.get<Version>(this.url);
  }
}
