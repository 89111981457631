import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {LimitListComponent} from './components/limit-list.component';
import {FilterableLimitListComponent} from './components/filterable-limit-list.component';
import {LimitDecisionListComponent} from './components/limit-decision-list.component';
import {LimitGuiService} from './services/limit-gui.service';
import {LimitRequestSelectorComponent} from './components/limit-request-selector.component';
import {PolicyLimitListSharedModule} from '../../policy-limit-list/shared/policy-limit-list-shared.module';
import {ExternalLimitListComponent} from './components/external-limit-list.component';
import {LimitCoverGuiService} from '../../../portal/limit/limit-cover-gui.service';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    PolicyLimitListSharedModule,
  ],
  declarations: [
    LimitListComponent,
    FilterableLimitListComponent,
    LimitDecisionListComponent,
    LimitRequestSelectorComponent,
    ExternalLimitListComponent,
  ],
  exports: [
    LimitListComponent,
    FilterableLimitListComponent,
    LimitDecisionListComponent,
    LimitRequestSelectorComponent,
    ExternalLimitListComponent,
  ],
  providers: [LimitGuiService, LimitCoverGuiService],
})
export class LimitSharedModule {}
