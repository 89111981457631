import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AppConfigService, LoggedUserService} from '../../../bonding_shared';
import {AbstractGuard} from '../../../bonding_shared/components/abstract-guard';
import {ElementaryRight} from '../../../bonding_shared/model/dictionary-ids';

@Injectable()
export class BusinessParamsGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router, public apiService: AppConfigService) {
    super(loggedUserService, router);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return !this.apiService.kuke || this.loggedUserService.hasRight(ElementaryRight.BUSINESS_PARAM_UPDATE);
  }
}
