import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrokerContractRelationListComponent} from './broker-contract-relation-list.component';
import {BrokerContractVersionSelectorComponent} from './broker-contract-version-selector.component';
import {BrokerContractVersionListComponent} from './broker-contract-version-list.component';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule],
  declarations: [
    BrokerContractRelationListComponent,
    BrokerContractVersionSelectorComponent,
    BrokerContractVersionListComponent,
  ],
  exports: [
    BrokerContractRelationListComponent,
    BrokerContractVersionSelectorComponent,
    BrokerContractVersionListComponent,
  ],
})
export class BrokerContractSharedModule {}
