import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ATableComponent} from '../../../bonding_shared/components/aku-table/a-table.component';
import {RouterService} from '../../../bonding_shared/services/router-service';
import {
  CompanyBaseDto,
  Criteria,
  DictionaryBaseDto,
  InvoiceItemCriteriaDto,
  InvoiceItemDto,
  InvoiceItemFINoteCriteriaDto,
  InvoiceItemInvoiceCriteriaDto,
  InvoiceItemSimpleDto,
  SearchResult,
  TaxIfc,
} from '../../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {InvoiceItemService} from '../../../bonding_shared/services/invoice-item.service';
import {
  FINoteCategory,
  InvoiceCategory,
  InvoiceItemStatus,
  InvoiceStatus,
} from '../../../bonding_shared/model/dictionary-ids';
import {InvoiceCoreService} from '../../../bonding_shared/services/invoice-core.service';
import {AbstractService, GrowlService} from '../../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'invoice-item-list',
  templateUrl: 'invoice-item-list.component.html',
})
export class InvoiceItemListComponent implements AfterViewInit {
  @Input() categoryId: InvoiceCategory;
  @Input() parentCategoryId: InvoiceCategory;
  @Input() invoiceCriteria: InvoiceItemInvoiceCriteriaDto;
  @Input() fiNoteCriteria: InvoiceItemFINoteCriteriaDto;
  @Input() showSourceInvoice = false;
  @Input() sourceInvoicePaid: boolean;

  @Output() invoiceItemEmitter = new EventEmitter<InvoiceItemSimpleDto>();
  @Output() invoiceItemsEmitter = new EventEmitter<InvoiceItemSimpleDto[]>();

  @Input() invoiceItemsToBeRemovedFromResults: InvoiceItemSimpleDto[];

  @ViewChild(ATableComponent, {static: true}) invoiceItemTable: ATableComponent<InvoiceItemSimpleDto>;

  dataProvider: SearchDataProvider<InvoiceItemCriteriaDto, InvoiceItemSimpleDto>;

  taxCategoryMap: {[index: string]: DictionaryBaseDto} = {};

  @Input() set relatedToId(relatedToId: number) {
    this._relatedToId = relatedToId;
    this.search();
  }

  private _relatedToId: number;
  private pendingSearch = false;

  constructor(
    public routerService: RouterService,
    private invoiceItemService: InvoiceItemService,
    private invoiceCoreService: InvoiceCoreService,
    private translateService: TranslateService,
    private growlService: GrowlService
  ) {
    this.dataProvider = new SearchDataProvider<InvoiceItemCriteriaDto, InvoiceItemSimpleDto>(this.invoiceItemService);
    this.dataProvider.textSearch = false;
    this.dataProvider.searchCriteria.criteria = <InvoiceItemCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.client = <CompanyBaseDto>{};
  }

  ngAfterViewInit() {
    this.dataProvider.searchCriteria.criteria.category = <DictionaryBaseDto>{id: this.categoryId};
    if (this.pendingSearch && this._relatedToId) {
      this.search();
    }
    // this.invoiceItems = this.invoiceItemTable.items;
  }

  invoiceAll() {
    console.log('Invoice all');
    console.log('atable tems length: ' + this.invoiceItemTable.items.length);
    for (const item of this.invoiceItemTable.items) {
      console.log('The item is: ' + item.id);
    }
    this.invoiceItemsEmitter.emit(this.invoiceItemTable.items);
  }

  search() {
    if (this.invoiceItemTable && this.invoiceItemTable.dataProvider) {
      this.dataProvider.searchCriteria.criteria.ids = <Criteria<number[]>>{negation: true};
      if (this.invoiceItemsToBeRemovedFromResults && this.invoiceItemsToBeRemovedFromResults.length > 0) {
        this.dataProvider.searchCriteria.criteria.ids.value = this.invoiceItemsToBeRemovedFromResults.map((i) => i.id);
        if (this.dataProvider.searchCriteria.criteria.ids.value.length > AbstractService.MAX_IN_COUNT) {
          this.dataProvider.searchCriteria.criteria.ids.value =
            this.dataProvider.searchCriteria.criteria.ids.value.slice(0, AbstractService.MAX_IN_COUNT);
          this.growlService.warning(
            this.translateService.instant('invoiceItem.shared.list.searchWarningInExceeded', {
              count: AbstractService.MAX_IN_COUNT,
            })
          );
        }
      }

      this.dataProvider.searchCriteria.criteria.fiNoteCriteria = this.fiNoteCriteria;
      this.dataProvider.searchCriteria.criteria.invoiceCriteria = this.invoiceCriteria;
      if (this.invoiceCriteria && this.invoiceCriteria.inInvoice) {
        this.dataProvider.searchCriteria.criteria.invoiceStatuses = [
          InvoiceStatus.ISSUED,
          InvoiceStatus.MIGRATION,
          InvoiceStatus.SENT,
          InvoiceStatus.SENT_MANUALLY,
          InvoiceStatus.PAID,
        ];
      }
      this.dataProvider.searchCriteria.criteria.sourceInvoicePaid = this.sourceInvoicePaid;
      this.dataProvider.searchCriteria.criteria.client.id = this._relatedToId;
      this.dataProvider.searchCriteria.criteria.statuses = [InvoiceItemStatus.AUTOMATIC, InvoiceItemStatus.MANUAL];
      this.invoiceItemTable.search();
      this.pendingSearch = false;
    } else {
      this.pendingSearch = true;
    }
  }

  emitInvoiceItem(invoiceItem: InvoiceItemSimpleDto) {
    if (invoiceItem) {
      this.invoiceItemEmitter.emit(invoiceItem);
    }
  }

  dataProviderSearchFinished(searchResult: SearchResult<InvoiceItemSimpleDto>) {
    // this.removeToBeRemovedItemsFromResultList();
    this.taxCategoryMap = {};
    for (const item of searchResult.result) {
      this.addTaxCategoryFromItem(item);
    }
  }

  private addTaxCategoryFromItem(item: InvoiceItemSimpleDto) {
    for (const itemTax of Object.values(item.taxes)) {
      this.taxCategoryMap[itemTax.taxCategory.id] = itemTax.taxCategory;
    }
  }

  goToBusinessObject(event: any) {
    console.log('goToBusinessObject: ', event, ' Not implemented!');
  }

  get taxCategories(): DictionaryBaseDto[] {
    return Object.values(this.taxCategoryMap);
  }

  formatTax(dto: TaxIfc): string {
    return this.invoiceCoreService.formatTax(dto);
  }

  formatTaxType(item: InvoiceItemDto, taxCategory: DictionaryBaseDto): string {
    const tax = item.taxes[taxCategory.id];
    return tax ? tax.taxType.name : '';
  }

  get category(): string {
    return InvoiceCategory[this.categoryId] || FINoteCategory[this.categoryId];
  }

  get parentCategory(): string {
    return InvoiceCategory[this.parentCategoryId] || FINoteCategory[this.parentCategoryId];
  }

  isInvoiceAllVisible(): boolean {
    return this.parentCategoryId !== InvoiceCategory.BROKER;
  }
}
